import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  TextInput,
  Button,
  TouchableOpacity,
  Platform,
} from 'react-native'
import Scroller from '../../components/Scroller'
import PageTitle from '../../components/simple/PageTitle'
import Brand from '../../components/Brand'
import Icons from '@expo/vector-icons/Ionicons'
import NavigationActions from '../../utility/navigationActions'

import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'
import { connect } from 'react-redux'
import KeyboardAvoidingView from '../../components/simple/KeyboardAvoidingView'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'
const KView = Platform.OS === 'android' ? View : KeyboardAvoidingView

import _ from 'lodash'
import branch from '../../config/branch'
import { getKeyboardPaddingStyle } from '../../config/helpers'

const SHOW_EXIT_BUTTON = false

@WithKeyboardInfo
class AuthenticationLayout extends React.Component {
  render() {
    const { screenInfo, layout, platform, style, offset = 0, keyboardInfo } = this.props
    const keyboardPaddingStyle = getKeyboardPaddingStyle(keyboardInfo)
    return (
      <KView
        style={[styles.wrapper]}
        behavior='padding'
        keyboardVerticalOffset={offset}
      >
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={[styles.container, style]}
          keyboardShouldPersistTaps='handled'
          ref={(ref) => {
            this.scrollView = ref
            this.props.innerRef && this.props.innerRef(ref)
          }}
        >
          <View style={[styles.form, keyboardPaddingStyle]}>{this.props.children}</View>
        </ScrollView>
        {this.exitButton()}
      </KView>
    )
  }
  submit() {}
  exitButton() {
    if (!SHOW_EXIT_BUTTON) {
      return null
    }
    return (
      <TouchableOpacity
        style={styles.exitButton}
        onPress={this.props.goToNetwork}
      >
        <Icons name={'ios-close'} size={48} color={colors.text.secondary} />
      </TouchableOpacity>
    )
  }
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
  layout: state.screenInfo.layout,
  platform: state.screenInfo.platform,
})

const mapDispatchToProps = (dispatch) => ({
  goToNetwork: () =>
    NavigationActions.navigate({ routeName: 'Network' }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthenticationLayout)

const styles = stylus({
  wrapper: {
    flex: 1,
    portrait: {
      alignItems: 'stretch',
    },
    landscape: {
      alignItems: 'center',
    },
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    // flexShrink: 1,
    // paddingVertical: 30,

    // backgroundColor: 'orange',
    portrait: {
      alignSelf: 'stretch',
    },
    landscape: {
      // top: -200,
      // maxHeight: 600,
      width: 420,
    },
  },
  logoContainer: {
    // flex: 1,
    marginTop: 50,
    // width: 200,
    // height: 50,
    // flexShrink: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // flexGrow: 1,
    portrait: {
      paddingHorizontal: 20,
    },
  },
  exitButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    // backgroundColor: 'green',
    landscape: {
      paddingTop: 40,
      paddingHorizontal: 100,
    },
    portrait: {
      paddingTop: 20,
      paddingHorizontal: 20,
    },
  },
})
