import React from 'react'
import { View, Text } from 'react-native'
import PropTypes from 'prop-types'
import NavigationActions from '../../../utility/navigationActions'

import Button from '../../../components/simple/Button'
import NamedImage from '../../../components/simple/NamedImage'
import confirm from '../../../utility/confirm'
import colors from '../../../config/colors'
import styles from '../styles'

import _ from 'lodash'
import { OBJECT_STATUSES } from '../../../config/constants'
import { graphql, connect } from '../../../config/connected'
import userSchema from '../../../schema/user'
import shopSchema from '../../../schema/shop'
import withActionSheet from '../../../containers/ActionSheet/withActionSheet'
import {
  openActionSheet,
  getObjectStatusTranslation,
} from '../../../config/helpers'
import i18n from 'i18n-js'
import { ANALYTICS_SHOP_STATUS_CHANGED } from '../../../reducers/analytics'

const mapDispatchToProps = (dispatch) => ({
  goToShop: ({ id, displayName }) => {
    NavigationActions.navigate({ routeName: 'Shop', params: { id, pageTitle: displayName } })
  },
  goToShopReviewFeedback: ({ id, title }) => {
    NavigationActions.navigate({
      routeName: 'ShopReviewFeedback',
      params: { id, title },
    })
  },
  analyticsShopStatusChanged: (shopId, oldStatus, newStatus) => {
    dispatch({
      type: ANALYTICS_SHOP_STATUS_CHANGED,
      data: {
        shopId,
        oldStatus,
        newStatus,
      },
    })
  },
  dispatch,
})

@connect(
  null,
  mapDispatchToProps,
)
@graphql(shopSchema.mutations.updateProducerDetails, {
  name: 'updateProducerDetails',
})
@graphql(userSchema.mutations.updateShopStatus, {
  name: 'updateShopStatus',
})
@withActionSheet
class ShopListItem extends React.Component {
  static propTypes = {
    shop: PropTypes.object,
    imageSize: PropTypes.number,
    showActionSheetWithOptions: PropTypes.func.isRequired,
    goToShop: PropTypes.func.isRequired,
    updateShopStatus: PropTypes.func.isRequired,
    updateProducerDetails: PropTypes.func.isRequired,
    analyticsShopStatusChanged: PropTypes.func.isRequired,
    goToShopReviewFeedback: PropTypes.func.isRequired,
  }

  static defaultProps = {
    imageSize: 50,
  }

  recursion = (startObject) => {
    return _.map(startObject, (val, key) => {
      if (key === '__typename') return null
      if (typeof val === 'object' && val !== null && val !== undefined) {
        return (
          <Text key={key}>
            <Text style={{ fontWeight: 'bold' }}>
              {key + ': {\n'}
              <Text style={{ fontWeight: 'normal' }}>
                {this.recursion(val)}
              </Text>
              {'}\n'}
            </Text>

            {/* <Text>{Object.keys(val).toString()}</Text> */}
          </Text>
        )
      } else {
        return (
          <Text key={key}>
            <Text style={{ fontWeight: 'bold' }}>{key}</Text>
            {': ' + (val === null ? '' : val) + '\n'}
          </Text>
        )
      }
    })
  }

  state = {
    showDetails: false,
  }

  openShopStatusActionSheet = () => {
    const statusOptionsSpec = [
      {
        option: getObjectStatusTranslation(OBJECT_STATUSES.active),
        action: () => this.setShopStatus(OBJECT_STATUSES.active),
      },
      {
        option: getObjectStatusTranslation(OBJECT_STATUSES.inactive),
        action: () => this.setShopStatus(OBJECT_STATUSES.inactive),
      },
      {
        option: getObjectStatusTranslation(OBJECT_STATUSES.needsReview),
        action: () => this.setShopStatus(OBJECT_STATUSES.needsReview),
      },
      {
        option: getObjectStatusTranslation(OBJECT_STATUSES.disabled),
        action: () => this.setShopStatus(OBJECT_STATUSES.disabled),
      },
      // {
      //   option: getObjectStatusTranslation(OBJECT_STATUSES.reported),
      //   action: () => this.setShopStatus(OBJECT_STATUSES.repo rted),
      // },
      {
        option: getObjectStatusTranslation(OBJECT_STATUSES.confirmed),
        action: () => this.setShopStatus(OBJECT_STATUSES.confirmed),
      },
      {
        option: i18n.t('common.cancel'),
        action: _.identity,
      },
    ]
    openActionSheet(this.props.showActionSheetWithOptions, statusOptionsSpec)
  }

  setShopStatus = async (status) => {
    const { shop } = this.props
    const { id, displayName } = shop
    const message = i18n.t(
      'admin.shopManagement.prompts.genericStatusMessage', // Status specific confirmation message to admin.
      {
        status: getObjectStatusTranslation(status),
        shop: displayName,
      },
    )
    const yes = await confirm({
      title: i18n.t('common.confirmAction'),
      message,
    })

    if (!yes) {
      console.log('Admin action canceled.')
      return
    }

    // const disabledStatuses = [
    //   OBJECT_STATUSES.disabled,
    //   OBJECT_STATUSES.inactive,
    //   OBJECT_STATUSES.needsReview,
    // ]
    const visibleStatuses = [OBJECT_STATUSES.active, OBJECT_STATUSES.reported]
    const isApproved = _.includes(visibleStatuses, status)

    if (status === OBJECT_STATUSES.disabled) {
      this.props.goToShopReviewFeedback({ id, title: displayName })
      return
    }

    this.props.updateShopStatus({
      variables: {
        id,
        status,
      },
    })

    this.props.analyticsShopStatusChanged(id, shop.status, status)

    this.props.updateProducerDetails({
      variables: {
        id,
        approved: isApproved,
      },
    })
  }

  render() {
    const { shop, imageSize } = this.props
    return (
      <View style={styles.shopListItemContainer}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <NamedImage
            style={{
              width: imageSize,
              height: imageSize,
              borderRadius: imageSize / 2,
            }}
            width={imageSize}
            heigh={imageSize}
            resizeMode='cover'
            name={shop.profileImage}
          />
          <View style={styles.detailsContainer}>
            <Text style={{ fontWeight: 'bold' }}>{shop.displayName}</Text>
            <Text>Legal Name: {_.get(shop, 'producerDetails.legalName')}</Text>
            <Text>IBAN: {_.get(shop, 'producerDetails.ibanNumber')}</Text>
            <Text>
              Status:{' '}
              <Text
                style={{
                  color:
                    _.get(shop, 'status', 'unknown') === OBJECT_STATUSES.active
                      ? colors.green
                      : colors.red,
                }}
              >
                {getObjectStatusTranslation(_.get(shop, 'status'))}
              </Text>
            </Text>
            <Text
              style={{
                color: _.get(shop, 'producerDetails.approved')
                  ? colors.green
                  : colors.red,
              }}
            >
              {_.get(shop, 'producerDetails.approved')
                ? 'Approved'
                : 'Not Approved'}
            </Text>
            <Text>
              Stripe Connected:{' '}
              {_.get(shop, 'producerDetails.stripeUserId') || 'No'}
            </Text>
            <Text>Fee: {_.get(shop, 'producerDetails.fee')}%</Text>
            <Text>
              Submitted - {new Date(shop.createdAt).toLocaleDateString()}
            </Text>
            {/* <Text>Owner: {shop.owner}</Text> */}
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <Button
            style={[styles.button, styles.buttonShop]}
            label={i18n.t('admin.shopManagement.visit')}
            onPress={() => this.props.goToShop(shop)}
          />

          <Button
            style={[styles.button, styles.buttonShop]}
            label={i18n.t('admin.shopManagement.setStatus')}
            onPress={this.openShopStatusActionSheet}
          />

          <View>
            <Button
              style={[
                styles.button,
                { backgroundColor: colors.primary },
                styles.buttonShop,
              ]}
              label={
                this.state.showDetails
                  ? i18n.t('admin.shopManagement.rawDataHide')
                  : i18n.t('admin.shopManagement.rawData')
              }
              onPress={() => {
                this.setState({ showDetails: !this.state.showDetails })
              }}
            />
            {this.state.showDetails ? this.recursion(shop) : null}
          </View>
        </View>
      </View>
    )
  }
}

export default ShopListItem
