import i18n from 'i18n-js'
import _ from 'lodash'
import React, { Component } from 'react'; //eslint-disable-next-line
import { Platform, Text, View, Picker } from 'react-native'
import ImageCarousel from '../../../../components/ImageCarousel'
import Button from '../../../../components/simple/Button'
import Divider from '../../../../components/simple/Divider'
import ElegantInput from '../../../../components/simple/ElegantInput'
import Switch from '../../../../components/simple/Switch'
import T from '../../../../components/T'
import TagsInput from '../../../../components/TagsInput'
import colors from '../../../../config/colors'
import { stylus } from '../../../../config/styles'
import branch from '../../../../config/branch'
import withActionSheet from '../../../../containers/ActionSheet/withActionSheet'
import { categories as productCategories } from '../../../../localization/en-US/categories.json'
import PropTypes from 'prop-types'
import unitTypes from '../../../../config/unitTypes'
import MyPicker from '../../../../components/simple/Picker'
import sizes from '../../../../config/sizes'
import { getDateTime, numberInputEasify } from '../../../../config/helpers'

@withActionSheet
class ItemInformation extends Component {

  _inputRefs = {}

  onTagPress = (index, targetField) => {
    const { allergens, categories, handleFieldChange, freeFrom, diet } = this.props
    let data
    if (targetField === 'allergens') {
      data = allergens.slice()
    } else if (targetField === 'freeFrom') {
      data = freeFrom.slice()
    } else if (targetField === 'diet') {
      data = diet.slice()
    } else {
      data = categories.slice()
    }

    data.splice(index, 1)
    handleFieldChange(data, targetField)
  }

  renderErrors = () => {
    const hasErrors = !!Object.keys(this.props.errors).length
    if (hasErrors) {
      return (
        <View>
          {_.map(this.props.errors, (value, key) => {
            return (
              <Text key={key} style={{ color: 'red' }}>
                {i18n.t(`shop.add.fields.${key}`) + `: ${value}\n`}
              </Text>
            )
          })}
        </View>
      )
    } else {
      return null
    }
  }

  promptForDelete = (value) => {
    const { handleFieldChange, isInStock, handleSubmit } = this.props
    const options = {
      message: i18n.t('product.edit.actionSheet.messageDelete'),
      options: [i18n.t('common.submit'), i18n.t('common.cancel')],
      destructiveButtonIndex: 0,
      cancelButtonIndex: 1,
    }
    handleFieldChange(true, 'isDiscontinued')

    this.props.showActionSheetWithOptions(options, (index) => {
      if (index === 0) {
        // console.log('fired::', index)
        if (isInStock) {
          handleFieldChange(false, 'isInStock')
          handleFieldChange('0', 'quantity')
        }
        this.props.handleSubmit()
      } else {
        handleFieldChange(false, 'isDiscontinued')
      }
    })
  }

  deliveryLocationsOnlyToggle = (value) => {
    const { handleFieldChange } = this.props
    handleFieldChange(value, 'deliveryLocationsOnly')
  }

  render() {
    const {
      goToNext,
      handleFieldChange,
      title,
      price,
      priceDiscount,
      quantity,
      format,
      itemImages,
      allergens,
      freeFrom,
      diet,
      categories,
      properties,
      errors,
      animate,
      editing,
      isInStock,
      isDiscontinued,
      deliveryLocations,
      deliveryLocationsOnly,
      unitQuantity,
      unitType,
      shopDiscount,
      maxNumberOfCategories,
    } = this.props

    const thumbColor = branch({
      android: colors.primary,
      ios: null,
      web: 'white'
    })
    const destructiveThumbColor = branch({
      android: colors.red,
      ios: null,
      web: 'white'
    })

    const allAlergens = _.map(productCategories.allergens, (allergen, key) => {
      return {
        value: key,
        label: i18n.t(`categories.allergens.${key}`),
      }
    })

    const allFreeFrom = _.map(productCategories.freeFrom, (freeFromItem, key) => {
      return {
        value: key,
        label: i18n.t(`categories.freeFrom.${key}`),
      }
    })
    const allDiets = _.map(productCategories.diet, (_, key) => {
      return {
        value: key,
        label: i18n.t(`categories.diet.${key}`),
      }
    })

    const allCategories = _.map(productCategories.producer, (producer, key) => {
      return {
        value: key,
        label: i18n.t(`categories.producer.${key}`),
      }
    })

    // const allProperties = _.map(
    //   productCategories.properties,
    //   (property, key) => {
    //     return {
    //       value: key,
    //       label: i18n.t(`categories.properties.${key}`),
    //     }
    //   },
    // )

    return (
      <View style={styles.container} onLayout={animate}>
        <ImageCarousel images={itemImages} playMuted={true} playOnce={true} navigation={this.props.navigation} />
        <T t='shop.add.titles.itemInfo' style={styles.titles.header} />
        <View style={styles.padding}>
          {editing ? (
            <>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Switch
                  style={{ marginRight: 5 }}
                  value={isInStock}
                  trackColor={{ false: colors.active, true: colors.primary }}
                  thumbColor={thumbColor}
                  onValueChange={() => {
                    handleFieldChange(!isInStock, 'isInStock')
                    if (isInStock) {
                      handleFieldChange('0', 'quantity')
                    } else {
                      handleFieldChange(false, 'isDiscontinued')
                      handleFieldChange('1', 'quantity')
                    }
                  }}
                />
                <Text>
                  {isInStock
                    ? i18n.t('shop.add.fields.isInStock')
                    : i18n.t('shop.add.fields.isNotInStock')}
                </Text>
              </View>
              <View style={{ height: 5 }} />
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Switch
                  style={{ marginRight: 5 }}
                  trackColor={{ false: colors.active, true: colors.red }}
                  thumbColor={destructiveThumbColor}
                  value={isDiscontinued}
                  onValueChange={this.promptForDelete}
                />
                <Text>{i18n.t('product.edit.discontinueProduct')}</Text>
                {/* <Button
                  style={[
                    styles.button.primary,
                    { backgroundColor: colors.red },
                  ]}
                  label={i18n.t('product.edit.discontinueProduct')}
                  onPress={this.promptForDelete}
                /> */}
              </View>
              <View style={{ height: 5 }} />
              { deliveryLocations ? (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Switch
                    style={{ marginRight: 5 }}
                    trackColor={{ false: colors.active, true: colors.primary }}
                    thumbColor={thumbColor}
                    value={deliveryLocationsOnly}
                    onValueChange={this.deliveryLocationsOnlyToggle}
                  />
                  <Text>{i18n.t('deliveryLocations.productCreation.settingToggle')}</Text>
                </View>
              ) : null }
              <Divider />
            </>
          ) : null}
          <ElegantInput
            reference={input => {
              this._inputRefs['title'] = input
            }}
            error={errors.title}
            multiline
            value={title}
            returnKeyType={'next'}
            label={i18n.t('shop.add.fields.title')}
            onChange={(value) => {
              handleFieldChange(value, 'title')
            }}
            onBlur={(value) => {
              handleFieldChange(value, 'title', true)
            }}
            onSubmitEditing={() => {
              _.invoke(this._inputRefs, 'price.focus')
            }}
          />

          <View style={{ flex: 1 }}>
            <ElegantInput
              containerStyle={{ flex:1 }}
              inputStyle={{ paddingRight: '50%' }}
              error={errors.unitQuantity || errors.unitType}
              value={unitQuantity}
              label={i18n.t('shop.add.fields.unitQuantity')}
              onChange={(value) => {
                handleFieldChange(numberInputEasify(value), 'unitQuantity')
              }}
              onBlur={(value) => {
                handleFieldChange(value, 'unitQuantity', true)
              }}
              keyboardType='decimal-pad'
            />
            <View style={{ position: 'absolute', right: 0, width: '45%'}}>
              <T
                t={'shop.add.fields.unitType'}
                style={[
                  styles.pickerLabel,
                  {
                    color: errors.unitType ? 'rgb(213, 0, 0)' : colors.input.base
                  },
                ]}
              />

              <MyPicker
                style={styles.picker}
                selectedValue={unitType}
                onValueChange={(value) => {
                  handleFieldChange(value, 'unitType')
                }}
                displayValue={_.get(unitTypes[unitType], `${i18n.locale}`, i18n.t('common.select'))}
              >
                <Picker.Item
                  label={i18n.t('common.select')}
                  value={''}
                />

                {Object.keys(unitTypes).map(unit => {
                  return (
                    <Picker.Item
                      label={unitTypes[unit][`${i18n.locale}`]}
                      key={unit}
                      value={unit}
                    />
                  )
                })}
              </MyPicker>
            </View>
          </View>

          <ElegantInput
            reference={input => {
              this._inputRefs['price'] = input
            }}
            error={errors.price}
            value={price}
            label={i18n.t('shop.add.fields.price')}
            onChange={(value) => {
              handleFieldChange(value, 'price')
            }}
            onBlur={(value) => {
              handleFieldChange(value, 'price', true, true)
            }}
            keyboardType='decimal-pad'
            suffix={({tintColor}) => (
              <Text style={{ color: tintColor, fontSize: 20, fontWeight: 'bold'}}>€</Text>
            )}
          />
          <ElegantInput
            error={errors.priceDiscount}
            value={priceDiscount}
            label={i18n.t('shop.add.fields.discountPrice')}
            onChange={(value) => {
              handleFieldChange(value, 'priceDiscount')
            }}
            onBlur={(value) => {
              handleFieldChange(value, 'priceDiscount', true, true)
            }}
            keyboardType='decimal-pad'
            suffix={({tintColor}) => (
              <Text style={{ color: tintColor, fontSize: 20, fontWeight: 'bold'}}>€</Text>
            )}
          />
          {!_.isEmpty(priceDiscount) && shopDiscount && (
            <Text style={styles.footnote}>
              {i18n.t('shop.add.fields.shopDiscountOngoing', {
                percent: shopDiscount.percent,
                endDate: getDateTime(shopDiscount.endDate)
              })}
            </Text>
          )}

          {/* <ElegantInput
            error={errors.quantity}
            value={quantity}
            label={i18n.t('shop.add.fields.quantity')}
            onChange={(value) => {
              handleFieldChange(value, 'quantity')
            }}
            onBlur={(value) => {
              handleFieldChange(value, 'quantity', true)
            }}
            keyboardType='decimal-pad'
          />
          <ElegantInput
            error={errors.format}
            value={format}
            label={i18n.t('shop.add.fields.format')}
            onChange={(value) => {
              handleFieldChange(value, 'format')
            }}
            onBlur={(value) => {
              handleFieldChange(value, 'format', true)
            }}
          />
          // Disabled per client requirement.
          */}

          <Divider hidden />
          <TagsInput
            error={errors.categories}
            tags={allCategories}
            chosen={categories}
            placeholder={i18n.t('shop.add.placeholders.tagsCategories')}
            label={i18n.t('shop.add.fields.categories') + ': '}
            onTagsChange={(value) => {
              handleFieldChange(value, 'categories')
            }}
            onTagPress={(idx) => this.onTagPress(idx, 'categories')}
            max={maxNumberOfCategories}
            //(value) => handleFieldChange(value, 'allergens')
            // onChangeSearch={(value) => handleFieldChange(value, 'allergens')}
          />
          <Divider hidden />
          <TagsInput
            error={errors.diet}
            tags={allDiets}
            chosen={diet}
            placeholder={i18n.t('shop.add.placeholders.tagsDiet')}
            label={i18n.t('shop.add.fields.diet') + ': '}
            onTagsChange={(value) => {
              handleFieldChange(value, 'diet')
            }}
            onTagPress={(idx) => this.onTagPress(idx, 'diet')}
            //(value) => handleFieldChange(value, 'allergens')
            // onChangeSearch={(value) => handleFieldChange(value, 'allergens')}
          />
          <Divider hidden/>
          <TagsInput
            tags={allAlergens}
            chosen={allergens}
            placeholder={i18n.t('shop.add.placeholders.tagsAllergens')}
            label={i18n.t('shop.add.fields.allergens') + ': '}
            onTagsChange={(value) => {
              handleFieldChange(value, 'allergens')
            }}
            onTagPress={(idx) => this.onTagPress(idx, 'allergens')}
            //(value) => handleFieldChange(value, 'allergens')
            // onChangeSearch={(value) => handleFieldChange(value, 'allergens')}
          />
          <Divider hidden />

          <TagsInput
            tags={allFreeFrom}
            chosen={freeFrom}
            placeholder={i18n.t('shop.add.placeholders.tagsFreeFrom')}
            label={i18n.t('shop.add.fields.freeFrom') + ': '}
            onTagsChange={(value) => {
              handleFieldChange(value, 'freeFrom')
            }}
            onTagPress={(idx) => this.onTagPress(idx, 'freeFrom')}
            //(value) => handleFieldChange(value, 'allergens')
            // onChangeSearch={(value) => handleFieldChange(value, 'allergens')}
          />
          <Divider hidden />

          {this.renderErrors()}
          <Button
            disabled={Object.keys(errors).length > 0}
            style={styles.button.primary}
            label={i18n.t('common.next')}
            onPress={goToNext}
          />
          <Divider hidden />
        </View>
      </View>
    )
  }
}

ItemInformation.propTypes = {
  allergens: PropTypes.array,
  categories: PropTypes.array,
  handleFieldChange: PropTypes.func,
  freeFrom: PropTypes.array,
  diet: PropTypes.array,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
}

export default ItemInformation

const styles = stylus({
  container: {},
  titles: {
    header: {
      textAlign: 'center',
      fontSize: 24,
      fontWeight: 'bold',
      marginTop: 20,
    },
    sub: {
      fontSize: 16,
      textAlign: 'center',
      // fontWeight: 'bold',
    },
  },
  button: {
    margin: {
      marginTop: 20,
    },
    primary: {
      backgroundColor: colors.primary,
    },
  },
  padding: {
    paddingHorizontal: 20,
  },
  unitContainer: {
    borderBottomWidth: sizes.px/2,
    borderBottomColor: colors.input.base,
  },
  picker: {
    height: 30,
    borderBottomWidth: 0,
    web: {
      width: '100%',
      color: colors.input.text,
    },
  },
  pickerLabel: {
    marginTop: 5,
    color: colors.input.base,
    fontSize: 12,
    web: {
      marginBottom: 6,
    },
  },
  footnote: {
    color: colors.text.secondary,
    fontSize: 12,
  }
})
