import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'

import Ionicons from '@expo/vector-icons/Ionicons'
import MDCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons'

import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import { getPrice } from '../../config/helpers'

const Coupon = ({
  couponName,
  couponAmount,
  onRevert,
  showRevert,
}) => {
  return (
    <View style={styles.appliedCouponContainer}>
      <View style={styles.appliedCouponLeftContainer}>
        <View style={styles.appliedCouponTagIconContainer}>
          <Ionicons
            name='md-pricetag'
            size={15}
            color='white'
          />
        </View>
        <Text style={styles.appliedCouponText}>
          {couponName}
        </Text>
      </View>
      <View style={styles.appliedCouponRightContainer}>
        <Text style={[styles.appliedCouponAmount, !showRevert && styles.paddingHorizontal]}>-{getPrice(couponAmount)}</Text>
        { showRevert ? (
          <TouchableOpacity onPress={onRevert} style={styles.paddingHorizontal}>
            <MDCommunityIcon
              name='close'
              size={20}
              color={colors.text.main}
            />
          </TouchableOpacity>
        ) : null }
      </View>
      
    </View>
  )
}

export default Coupon

const styles = stylus({
  appliedCouponContainer: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
    paddingLeft: 10,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.text.main,
    borderStyle: 'dashed'
  },
  appliedCouponLeftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  appliedCouponTagIconContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: colors.primary,
    borderRadius: 10,
    borderWidth: 1,
    paddingHorizontal: 4,
    paddingTop: 2,
    borderColor: colors.primary,
  },
  appliedCouponText: {
    marginLeft: 4,
    fontSize: 15,
    fontWeight: '600',
    color: colors.text.main,
  },
  appliedCouponRightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  appliedCouponAmount: {
    fontSize: 17,
    fontWeight: '700',
    color: colors.text.main,
  },
  paddingHorizontal: {
    paddingHorizontal: 10
  }
})
