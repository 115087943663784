import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from 'react-native'
import NavigationActions from '../../utility/navigationActions'

import Settings from './Settings'
import MobileBackButton from '../../components/simple/MobileBackButton'
import i18n from 'i18n-js'

class SettingsPortrait extends React.Component {
  render() {
    return <Settings />
  }
}

SettingsPortrait.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('settings.header'),
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

export default SettingsPortrait
