import React, { Component } from 'react'
import { Text, View } from 'react-native'
import { stylus } from '../../../../config/styles'

import Button from '../../../../components/simple/Button'
import T from '../../../../components/T'
import colors from '../../../../config/colors'
import i18n from 'i18n-js'

export class FullTerms extends Component {
  render() {
    const { goToPage, prevPage } = this.props
    return (
      <View style={styles.container}>
        <T t='onboardingShop.terms.titleTerms' style={styles.title} />
        <T t='onboardingShop.terms.terms' style={styles.body} />

        <T t='onboardingShop.terms.titleData' style={styles.title} />
        <T t='onboardingShop.terms.data' style={styles.body} />

        <T t='onboardingShop.terms.titleCookie' style={styles.title} />
        <T t='onboardingShop.terms.cookie' style={styles.body} />
        <Button
          label={i18n.t('onboardingShop.terms.back')}
          onPress={() => goToPage(prevPage)}
          style={styles.submit}
        />
      </View>
    )
  }
}

export default FullTerms

const styles = stylus({
  container: {
    flex: 1,
    alignItems: 'center',
    padding: 40,
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    margin: 10,
  },
  body: {
    fontSize: 16,
    color: colors.secondary,
    margin: 10,
  },
  submit: {
    backgroundColor: colors.primary,
  },
})
