import React from 'react'
import { Text, View } from 'react-native'

import { stylus } from '../../config/styles'
import T from '../T'
import PropTypes from 'prop-types'

const ErrorMessage = ({ message, t: tMessage, type, tType, style, labelStyle: messageStyle, typeStyle }) => {
  const errorElements = []

  if (tType) {
    errorElements.push(<T key='type' t={tType} style={[styles.type, typeStyle]} />)
  } else if (type) {
    errorElements.push(<Text key='type' style={[styles.type, typeStyle]}>{type}</Text>)
  }

  if (tMessage) {
    errorElements.push(<T key='message' t={tMessage} style={[styles.message, messageStyle]} />)
  } else if (message) {
    errorElements.push(<Text key='message' style={[styles.message, messageStyle]}>{message}</Text>)
  }

  if (errorElements.length === 0) {
    return null
  }

  return (
    <View style={[styles.container, style]}>
      { errorElements }
    </View>
  )
}

ErrorMessage.propTypes = {
  message: PropTypes.string,
  t: PropTypes.string,
  type: PropTypes.string,
  tType: PropTypes.string,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  typeStyle: PropTypes.object,
}

export default ErrorMessage

const styles = stylus({
  container: {
    marginTop: 10,
    // backgroundColor: '#ebaa8c',
    // borderTopColor: '#ebaa8c',
    // borderTopWidth: sizes.px,
    // borderBottomColor: '#ebaa8c',
    // borderBottomWidth: sizes.px,
    // borderRadius: 4,
    // paddingVertical: 6,
    // paddingHorizontal: 12,
  },
  type: {
    color: '#ff2c55',
    fontSize: 16
  },
  message: {
    color: '#ff2c55',
    fontSize: 14,
    textAlign: 'center'
  },
})
