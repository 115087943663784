import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'

import NavigationActions from '../../utility/navigationActions'
import { compose, connect } from '../../config/connected'

import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import { PropTypes } from 'prop-types'
import NamedImage from '../simple/NamedImage'
import _ from 'lodash'
import i18n from 'i18n-js'

const profileImageHeight = 30

// eslint-disable-next-line react/prop-types
const B = (props) => (
  <Text style={{ fontWeight: 'bold' }}>{props.children}</Text>
)

function PostLikes({ post, goToLikes, style }) {
  const firstLikerName = _.get(post, 'last3Likers[0].username') || undefined
  const lastLikers = post.last3Likers
  const count = post.likeCount - 1
  const and = count > 0 ? ` ${i18n.t('post.likesFollowers.and')} ` : ''
  const other =
    count > 0 ? <B>{i18n.t('post.likesFollowers.ending', { count })}</B> : ''
  const marginVertical = firstLikerName === undefined ? 0 : 10

  return (
    post.likeCount !== 0 && (
      <TouchableOpacity onPress={() => goToLikes(post)}>
        <View
          style={{
            marginLeft: 10,
            marginVertical,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {_.isArray(lastLikers) &&
            lastLikers.map((user, index) => (
              <NamedImage
                key={user.id}
                style={{
                  zIndex: -index,
                  marginRight: -12,
                  width: profileImageHeight,
                  height: profileImageHeight,
                  borderWidth: 3,
                  borderColor: '#fff',
                  borderRadius: profileImageHeight / 2,
                }}
                name={user.profileImage}
                placeholder='profile'
                width={50}
                height={50}
                resizeMode='cover'
              />
            ))}

          {firstLikerName && (
            <Text style={[styles.container, style]}>
              {i18n.t('post.likesFollowers.likedBy')} <B>{firstLikerName}</B>
              {and}
              {other}
            </Text>
          )}

          {firstLikerName === undefined && (
            <Text
              style={[
                styles.container,
                style,
                { paddingLeft: 6, fontWeight: 'bold' },
              ]}
            >
              {i18n.t('post.likes', { count: post.likeCount })}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    )
  )
}

PostLikes.propTypes = {
  post: PropTypes.object.isRequired,
  goToLikes: PropTypes.func.isRequired,
  style: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => ({
  goToLikes: ({ id }) =>
    NavigationActions.navigate({
      routeName: 'PostLikesViewer',
      params: { id },
    })
  ,
})

export default compose(
  connect(
    () => ({}),
    mapDispatchToProps,
  ),
)(PostLikes)

const styles = stylus({
  container: {
    marginTop: 8,
    marginBottom: 8,
    fontSize: 13,
    paddingLeft: 16,
    color: colors.text.main,
  },
})
