export default {
  'US': {
    'key': 'US',
    'name': 'United States of America',
    'code': '+1',
    'name_en': 'United States of America',
    'name_it': 'Stati Uniti',
    'name_de': 'Stati Uniti',
  },
  'AU': {
    'key': 'AU',
    'name': 'Australia',
    'code': '+61',
    'name_en': 'Australia',
    'name_it': 'Australia',
    'name_de': 'Australia',
  },
  'CA': {
    'key': 'CA',
    'name': 'Canada',
    'code': '+1',
    'name_en': 'Canada',
    'name_it': 'Canada',
    'name_de': 'Canada',
  },
  'AF': {
    'key': 'AF',
    'name': 'Afghanistan',
    'code': '+93',
    'name_en': 'Afghanistan',
    'name_it': 'Afghanistan',
    'name_de': 'Afghanistan',
  },
  'AL': {
    'key': 'AL',
    'name': 'Albania',
    'code': '+355',
    'name_en': 'Albania',
    'name_it': 'Albania',
    'name_de': 'Albania',
  },
  'AQ': {
    'key': 'AQ',
    'name': 'Antarctica',
    'code': '+672',
    'name_en': 'Antarctica',
    'name_it': 'Antartide',
    'name_de': 'Antartide',
  },
  'DZ': {
    'key': 'DZ',
    'name': 'Algeria',
    'code': '+213',
    'name_en': 'Algeria',
    'name_it': 'Algeria',
    'name_de': 'Algeria',
  },
  'AS': {
    'key': 'AS',
    'name': 'American Samoa',
    'code': '+1',
    'name_en': 'American Samoa',
    'name_it': 'Samoa americane',
    'name_de': 'Samoa americane',
  },
  'AD': {
    'key': 'AD',
    'name': 'Andorra',
    'code': '+376',
    'name_en': 'Andorra',
    'name_it': 'Andorra',
    'name_de': 'Andorra',
  },
  'AO': {
    'key': 'AO',
    'name': 'Angola',
    'code': '+244',
    'name_en': 'Angola',
    'name_it': 'Angola',
    'name_de': 'Angola',
  },
  'AG': {
    'key': 'AG',
    'name': 'Antigua and Barbuda',
    'code': '+1',
    'name_en': 'Antigua and Barbuda',
    'name_it': 'Antigua e Barbuda',
    'name_de': 'Antigua e Barbuda',
  },
  'AZ': {
    'key': 'AZ',
    'name': 'Azerbaijan',
    'code': '+994',
    'name_en': 'Azerbaijan',
    'name_it': 'Azerbaigian',
    'name_de': 'Azerbaigian',
  },
  'AR': {
    'key': 'AR',
    'name': 'Argentina',
    'code': '+54',
    'name_en': 'Argentina',
    'name_it': 'Argentina',
    'name_de': 'Argentina',
  },
  'AT': {
    'key': 'AT',
    'name': 'Austria',
    'code': '+43',
    'name_en': 'Austria',
    'name_it': 'Austria',
    'name_de': 'Austria',
  },
  'BS': {
    'key': 'BS',
    'name': 'Bahamas',
    'code': '+242',
    'name_en': 'Bahamas',
    'name_it': 'Bahamas',
    'name_de': 'Bahamas',
  },
  'BH': {
    'key': 'BH',
    'name': 'Bahrain',
    'code': '+973',
    'name_en': 'Bahrain',
    'name_it': 'Bahrein',
    'name_de': 'Bahrein',
  },
  'BD': {
    'key': 'BD',
    'name': 'Bangladesh',
    'code': '+880',
    'name_en': 'Bangladesh',
    'name_it': 'Bangladesh',
    'name_de': 'Bangladesh',
  },
  'AM': {
    'key': 'AM',
    'name': 'Armenia',
    'code': '+374',
    'name_en': 'Armenia',
    'name_it': 'Armenia',
    'name_de': 'Armenia',
  },
  'BB': {
    'key': 'BB',
    'name': 'Barbados',
    'code': '+246',
    'name_en': 'Barbados',
    'name_it': 'Barbados',
    'name_de': 'Barbados',
  },
  'BE': {
    'key': 'BE',
    'name': 'Belgium',
    'code': '+32',
    'name_en': 'Belgium',
    'name_it': 'Belgio',
    'name_de': 'Belgio',
  },
  'BM': {
    'key': 'BM',
    'name': 'Bermuda',
    'code': '+411',
    'name_en': 'Bermuda',
    'name_it': 'Bermuda',
    'name_de': 'Bermuda',
  },
  'BT': {
    'key': 'BT',
    'name': 'Bhutan',
    'code': '+975',
    'name_en': 'Bhutan',
    'name_it': 'Bhutan',
    'name_de': 'Bhutan',
  },
  'BO': {
    'key': 'BO',
    'name': 'Bolivia (Plurinational State of)',
    'code': '+591',
    'name_en': 'Bolivia (Plurinational State of)',
    'name_it': 'Bolivia',
    'name_de': 'Bolivia',
  },
  'BA': {
    'key': 'BA',
    'name': 'Bosnia and Herzegovina',
    'code': '+387',
    'name_en': 'Bosnia and Herzegovina',
    'name_it': 'Bosnia ed Erzegovina',
    'name_de': 'Bosnia ed Erzegovina',
  },
  'BW': {
    'key': 'BW',
    'name': 'Botswana',
    'code': '+267',
    'name_en': 'Botswana',
    'name_it': 'Botswana',
    'name_de': 'Botswana',
  },
  'BV': {
    'key': 'BV',
    'name': 'Bouvet Island',
    'code': '+1',
    'name_en': 'Bouvet Island',
    'name_it': 'Isola Bouvet',
    'name_de': 'Isola Bouvet',
  },
  'BR': {
    'key': 'BR',
    'name': 'Brazil',
    'code': '+55',
    'name_en': 'Brazil',
    'name_it': 'Brasile',
    'name_de': 'Brasile',
  },
  'BZ': {
    'key': 'BZ',
    'name': 'Belize',
    'code': '+501',
    'name_en': 'Belize',
    'name_it': 'Belize',
    'name_de': 'Belize',
  },
  'IO': {
    'key': 'IO',
    'name': 'British Indian Ocean Territory',
    'code': '+246',
    'name_en': 'British Indian Ocean Territory',
    'name_it': 'Territorio britannico dell\'Oceano Indiano',
    'name_de': 'Territorio britannico dell\'Oceano Indiano',
  },
  'SB': {
    'key': 'SB',
    'name': 'Solomon Islands',
    'code': '+677',
    'name_en': 'Solomon Islands',
    'name_it': 'Isole Salomone',
    'name_de': 'Isole Salomone',
  },
  'VG': {
    'key': 'VG',
    'name': 'Virgin Islands (British)',
    'code': '+284',
    'name_en': 'Virgin Islands (British)',
    'name_it': 'Isole Vergini (Britanniche)',
    'name_de': 'Isole Vergini (Britanniche)',
  },
  'BN': {
    'key': 'BN',
    'name': 'Brunei Darussalam',
    'code': '+673',
    'name_en': 'Brunei Darussalam',
    'name_it': 'Brunei',
    'name_de': 'Brunei',
  },
  'BG': {
    'key': 'BG',
    'name': 'Bulgaria',
    'code': '+359',
    'name_en': 'Bulgaria',
    'name_it': 'Bulgaria',
    'name_de': 'Bulgaria',
  },
  'MM': {
    'key': 'MM',
    'name': 'Myanmar',
    'code': '+95',
    'name_en': 'Myanmar',
    'name_it': 'Birmania',
    'name_de': 'Birmania',
  },
  'BI': {
    'key': 'BI',
    'name': 'Burundi',
    'code': '+257',
    'name_en': 'Burundi',
    'name_it': 'Burundi',
    'name_de': 'Burundi',
  },
  'BY': {
    'key': 'BY',
    'name': 'Belarus',
    'code': '+375',
    'name_en': 'Belarus',
    'name_it': 'Bielorussia',
    'name_de': 'Bielorussia',
  },
  'KH': {
    'key': 'KH',
    'name': 'Cambodia',
    'code': '+855',
    'name_en': 'Cambodia',
    'name_it': 'Cambogia',
    'name_de': 'Cambogia',
  },
  'CM': {
    'key': 'CM',
    'name': 'Cameroon',
    'code': '+237',
    'name_en': 'Cameroon',
    'name_it': 'Camerun',
    'name_de': 'Camerun',
  },
  'CV': {
    'key': 'CV',
    'name': 'Cabo Verde',
    'code': '+238',
    'name_en': 'Cabo Verde',
    'name_it': 'Capo Verde',
    'name_de': 'Capo Verde',
  },
  'KY': {
    'key': 'KY',
    'name': 'Cayman Islands',
    'code': '+345',
    'name_en': 'Cayman Islands',
    'name_it': 'Isole Cayman',
    'name_de': 'Isole Cayman',
  },
  'CF': {
    'key': 'CF',
    'name': 'Central African Republic',
    'code': '+236',
    'name_en': 'Central African Republic',
    'name_it': 'Rep. Centrafricana',
    'name_de': 'Rep. Centrafricana',
  },
  'LK': {
    'key': 'LK',
    'name': 'Sri Lanka',
    'code': '+94',
    'name_en': 'Sri Lanka',
    'name_it': 'Sri Lanka',
    'name_de': 'Sri Lanka',
  },
  'TD': {
    'key': 'TD',
    'name': 'Chad',
    'code': '+235',
    'name_en': 'Chad',
    'name_it': 'Ciad',
    'name_de': 'Ciad',
  },
  'CL': {
    'key': 'CL',
    'name': 'Chile',
    'code': '+56',
    'name_en': 'Chile',
    'name_it': 'Cile',
    'name_de': 'Cile',
  },
  'CN': {
    'key': 'CN',
    'name': 'China',
    'code': '+86',
    'name_en': 'China',
    'name_it': 'Cina',
    'name_de': 'Cina',
  },
  'TW': {
    'key': 'TW',
    'name': 'Taiwan, Province of China',
    'code': '+886',
    'name_en': 'Taiwan, Province of China',
    'name_it': 'Taiwan, provincia cinese',
    'name_de': 'Taiwan, provincia cinese',
  },
  'CX': {
    'key': 'CX',
    'name': 'Christmas Island',
    'code': '+61',
    'name_en': 'Christmas Island',
    'name_it': 'Isola di Natale',
    'name_de': 'Isola di Natale',
  },
  'CC': {
    'key': 'CC',
    'name': 'Cocos (Keeling) Islands',
    'code': '+61',
    'name_en': 'Cocos (Keeling) Islands',
    'name_it': 'Isole Cocos (Keeling)',
    'name_de': 'Isole Cocos (Keeling)',
  },
  'CO': {
    'key': 'CO',
    'name': 'Colombia',
    'code': '+57',
    'name_en': 'Colombia',
    'name_it': 'Colombia',
    'name_de': 'Colombia',
  },
  'KM': {
    'key': 'KM',
    'name': 'Comoros',
    'code': '+269',
    'name_en': 'Comoros',
    'name_it': 'Comore',
    'name_de': 'Comore',
  },
  'YT': {
    'key': 'YT',
    'name': 'Mayotte',
    'code': '+262',
    'name_en': 'Mayotte',
    'name_it': 'Mayotte',
    'name_de': 'Mayotte',
  },
  'CG': {
    'key': 'CG',
    'name': 'Congo',
    'code': '+242',
    'name_en': 'Congo',
    'name_it': 'Rep. del Congo',
    'name_de': 'Rep. del Congo',
  },
  'CD': {
    'key': 'CD',
    'name': 'Congo (Democratic Republic of the)',
    'code': '+243',
    'name_en': 'Congo (Democratic Republic of the)',
    'name_it': 'RD del Congo',
    'name_de': 'RD del Congo',
  },
  'CK': {
    'key': 'CK',
    'name': 'Cook Islands',
    'code': '+682',
    'name_en': 'Cook Islands',
    'name_it': 'Isole Cook',
    'name_de': 'Isole Cook',
  },
  'CR': {
    'key': 'CR',
    'name': 'Costa Rica',
    'code': '+506',
    'name_en': 'Costa Rica',
    'name_it': 'Costa Rica',
    'name_de': 'Costa Rica',
  },
  'HR': {
    'key': 'HR',
    'name': 'Croatia',
    'code': '+385',
    'name_en': 'Croatia',
    'name_it': 'Croazia',
    'name_de': 'Croazia',
  },
  'CU': {
    'key': 'CU',
    'name': 'Cuba',
    'code': '+53',
    'name_en': 'Cuba',
    'name_it': 'Cuba',
    'name_de': 'Cuba',
  },
  'CY': {
    'key': 'CY',
    'name': 'Cyprus',
    'code': '+357',
    'name_en': 'Cyprus',
    'name_it': 'Cipro',
    'name_de': 'Cipro',
  },
  'CZ': {
    'key': 'CZ',
    'name': 'Czech Republic',
    'code': '+420',
    'name_en': 'Czech Republic',
    'name_it': 'Rep. Ceca',
    'name_de': 'Rep. Ceca',
  },
  'BJ': {
    'key': 'BJ',
    'name': 'Benin',
    'code': '+229',
    'name_en': 'Benin',
    'name_it': 'Benin',
    'name_de': 'Benin',
  },
  'DK': {
    'key': 'DK',
    'name': 'Denmark',
    'code': '+45',
    'name_en': 'Denmark',
    'name_it': 'Danimarca',
    'name_de': 'Danimarca',
  },
  'DM': {
    'key': 'DM',
    'name': 'Dominica',
    'code': '+767',
    'name_en': 'Dominica',
    'name_it': 'Dominica',
    'name_de': 'Dominica',
  },
  'DO': {
    'key': 'DO',
    'name': 'Dominican Republic',
    'code': '+1',
    'name_en': 'Dominican Republic',
    'name_it': 'Rep. Dominicana',
    'name_de': 'Rep. Dominicana',
  },
  'EC': {
    'key': 'EC',
    'name': 'Ecuador',
    'code': '+593',
    'name_en': 'Ecuador',
    'name_it': 'Ecuador',
    'name_de': 'Ecuador',
  },
  'SV': {
    'key': 'SV',
    'name': 'El Salvador',
    'code': '+503',
    'name_en': 'El Salvador',
    'name_it': 'El Salvador',
    'name_de': 'El Salvador',
  },
  'GQ': {
    'key': 'GQ',
    'name': 'Equatorial Guinea',
    'code': '+240',
    'name_en': 'Equatorial Guinea',
    'name_it': 'Guinea Equatoriale',
    'name_de': 'Guinea Equatoriale',
  },
  'ET': {
    'key': 'ET',
    'name': 'Ethiopia',
    'code': '+251',
    'name_en': 'Ethiopia',
    'name_it': 'Etiopia',
    'name_de': 'Etiopia',
  },
  'ER': {
    'key': 'ER',
    'name': 'Eritrea',
    'code': '+291',
    'name_en': 'Eritrea',
    'name_it': 'Eritrea',
    'name_de': 'Eritrea',
  },
  'EE': {
    'key': 'EE',
    'name': 'Estonia',
    'code': '+372',
    'name_en': 'Estonia',
    'name_it': 'Estonia',
    'name_de': 'Estonia',
  },
  'FO': {
    'key': 'FO',
    'name': 'Faroe Islands',
    'code': '+298',
    'name_en': 'Faroe Islands',
    'name_it': 'Isole Faroe',
    'name_de': 'Isole Faroe',
  },
  'FK': {
    'key': 'FK',
    'name': 'Falkland Islands (Malvinas)',
    'code': '+500',
    'name_en': 'Falkland Islands (Malvinas)',
    'name_it': 'Isole Falkland (Malvinas)',
    'name_de': 'Isole Falkland (Malvinas)',
  },
  'GS': {
    'key': 'GS',
    'name': 'South Georgia and the South Sandwich Islands',
    'code': '+500',
    'name_en': 'South Georgia and the South Sandwich Islands',
    'name_it': 'Georgia del Sud e Isole Sandwich Meridionali',
    'name_de': 'Georgia del Sud e Isole Sandwich Meridionali',
  },
  'FJ': {
    'key': 'FJ',
    'name': 'Fiji',
    'code': '+679',
    'name_en': 'Fiji',
    'name_it': 'Figi',
    'name_de': 'Figi',
  },
  'FI': {
    'key': 'FI',
    'name': 'Finland',
    'code': '+358',
    'name_en': 'Finland',
    'name_it': 'Finlandia',
    'name_de': 'Finlandia',
  },
  'AX': {
    'key': 'AX',
    'name': 'Åland Islands',
    'code': '+358',
    'name_en': 'Åland Islands',
    'name_it': 'Isole Aland',
    'name_de': 'Isole Aland',
  },
  'FR': {
    'key': 'FR',
    'name': 'France',
    'code': '+33',
    'name_en': 'France',
    'name_it': 'Francia',
    'name_de': 'Francia',
  },
  'GF': {
    'key': 'GF',
    'name': 'French Guiana',
    'code': '+594',
    'name_en': 'French Guiana',
    'name_it': 'Guiana francese',
    'name_de': 'Guiana francese',
  },
  'PF': {
    'key': 'PF',
    'name': 'French Polynesia',
    'code': '+689',
    'name_en': 'French Polynesia',
    'name_it': 'Polinesia francese',
    'name_de': 'Polinesia francese',
  },
  'TF': {
    'key': 'TF',
    'name': 'French Southern Territories',
    'code': '+262',
    'name_en': 'French Southern Territories',
    'name_it': 'Territori della Francia del sud',
    'name_de': 'Territori della Francia del sud',
  },
  'DJ': {
    'key': 'DJ',
    'name': 'Djibouti',
    'code': '+253',
    'name_en': 'Djibouti',
    'name_it': 'Gibuti',
    'name_de': 'Gibuti',
  },
  'GA': {
    'key': 'GA',
    'name': 'Gabon',
    'code': '+241',
    'name_en': 'Gabon',
    'name_it': 'Gabon',
    'name_de': 'Gabon',
  },
  'GE': {
    'key': 'GE',
    'name': 'Georgia',
    'code': '+995',
    'name_en': 'Georgia',
    'name_it': 'Georgia',
    'name_de': 'Georgia',
  },
  'GM': {
    'key': 'GM',
    'name': 'Gambia',
    'code': '+220',
    'name_en': 'Gambia',
    'name_it': 'Gambia',
    'name_de': 'Gambia',
  },
  'PS': {
    'key': 'PS',
    'name': 'Palestine, State of',
    'code': '+970',
    'name_en': 'Palestine, State of',
    'name_it': 'Palestina, Stato di',
    'name_de': 'Palestina, Stato di',
  },
  'DE': {
    'key': 'DE',
    'name': 'Germany',
    'code': '+49',
    'name_en': 'Germany',
    'name_it': 'Germania',
    'name_de': 'Germania',
  },
  'GH': {
    'key': 'GH',
    'name': 'Ghana',
    'code': '+233',
    'name_en': 'Ghana',
    'name_it': 'Ghana',
    'name_de': 'Ghana',
  },
  'GI': {
    'key': 'GI',
    'name': 'Gibraltar',
    'code': '+350',
    'name_en': 'Gibraltar',
    'name_it': 'Gibilterra',
    'name_de': 'Gibilterra',
  },
  'KI': {
    'key': 'KI',
    'name': 'Kiribati',
    'code': '+686',
    'name_en': 'Kiribati',
    'name_it': 'Kiribati',
    'name_de': 'Kiribati',
  },
  'GR': {
    'key': 'GR',
    'name': 'Greece',
    'code': '+30',
    'name_en': 'Greece',
    'name_it': 'Grecia',
    'name_de': 'Grecia',
  },
  'GL': {
    'key': 'GL',
    'name': 'Greenland',
    'code': '+299',
    'name_en': 'Greenland',
    'name_it': 'Groenlandia',
    'name_de': 'Groenlandia',
  },
  'GD': {
    'key': 'GD',
    'name': 'Grenada',
    'code': '+1',
    'name_en': 'Grenada',
    'name_it': 'Grenada',
    'name_de': 'Grenada',
  },
  'GP': {
    'key': 'GP',
    'name': 'Guadeloupe',
    'code': '+590',
    'name_en': 'Guadeloupe',
    'name_it': 'Guadeloupe',
    'name_de': 'Guadeloupe',
  },
  'GU': {
    'key': 'GU',
    'name': 'Guam',
    'code': '+1',
    'name_en': 'Guam',
    'name_it': 'Guam',
    'name_de': 'Guam',
  },
  'GT': {
    'key': 'GT',
    'name': 'Guatemala',
    'code': '+502',
    'name_en': 'Guatemala',
    'name_it': 'Guatemala',
    'name_de': 'Guatemala',
  },
  'GN': {
    'key': 'GN',
    'name': 'Guinea',
    'code': '+224',
    'name_en': 'Guinea',
    'name_it': 'Guinea',
    'name_de': 'Guinea',
  },
  'GY': {
    'key': 'GY',
    'name': 'Guyana',
    'code': '+592',
    'name_en': 'Guyana',
    'name_it': 'Guyana',
    'name_de': 'Guyana',
  },
  'HT': {
    'key': 'HT',
    'name': 'Haiti',
    'code': '+509',
    'name_en': 'Haiti',
    'name_it': 'Haiti',
    'name_de': 'Haiti',
  },
  'HM': {
    'key': 'HM',
    'name': 'Heard Island and McDonald Islands',
    'code': '+672',
    'name_en': 'Heard Island and McDonald Islands',
    'name_it': 'Heard Island e McDonald Islands',
    'name_de': 'Heard Island e McDonald Islands',
  },
  'VA': {
    'key': 'VA',
    'name': 'Holy See',
    'code': '+379',
    'name_en': 'Holy See',
    'name_it': 'Santa Sede',
    'name_de': 'Santa Sede',
  },
  'HN': {
    'key': 'HN',
    'name': 'Honduras',
    'code': '+504',
    'name_en': 'Honduras',
    'name_it': 'Honduras',
    'name_de': 'Honduras',
  },
  'HK': {
    'key': 'HK',
    'name': 'Hong Kong',
    'code': '+852',
    'name_en': 'Hong Kong',
    'name_it': 'Hong Kong',
    'name_de': 'Hong Kong',
  },
  'HU': {
    'key': 'HU',
    'name': 'Hungary',
    'code': '+36',
    'name_en': 'Hungary',
    'name_it': 'Ungheria',
    'name_de': 'Ungheria',
  },
  'IS': {
    'key': 'IS',
    'name': 'Iceland',
    'code': '+354',
    'name_en': 'Iceland',
    'name_it': 'Islanda',
    'name_de': 'Islanda',
  },
  'IN': {
    'key': 'IN',
    'name': 'India',
    'code': '+91',
    'name_en': 'India',
    'name_it': 'India',
    'name_de': 'India',
  },
  'ID': {
    'key': 'ID',
    'name': 'Indonesia',
    'code': '+62',
    'name_en': 'Indonesia',
    'name_it': 'Indonesia',
    'name_de': 'Indonesia',
  },
  'IR': {
    'key': 'IR',
    'name': 'Iran (Islamic Republic of)',
    'code': '+98',
    'name_en': 'Iran (Islamic Republic of)',
    'name_it': 'Iran',
    'name_de': 'Iran',
  },
  'IQ': {
    'key': 'IQ',
    'name': 'Iraq',
    'code': '+964',
    'name_en': 'Iraq',
    'name_it': 'Iraq',
    'name_de': 'Iraq',
  },
  'IE': {
    'key': 'IE',
    'name': 'Ireland',
    'code': '+353',
    'name_en': 'Ireland',
    'name_it': 'Irlanda',
    'name_de': 'Irlanda',
  },
  'IL': {
    'key': 'IL',
    'name': 'Israel',
    'code': '+972',
    'name_en': 'Israel',
    'name_it': 'Israele',
    'name_de': 'Israele',
  },
  'IT': {
    'key': 'IT',
    'name': 'Italy',
    'code': '+39',
    'name_en': 'Italy',
    'name_it': 'Italia',
    'name_de': 'Italia',
  },
  'CI': {
    'key': 'CI',
    'name': 'Côte d\'Ivoire',
    'code': '+225',
    'name_en': 'Côte d\'Ivoire',
    'name_it': 'Costa d\'Avorio',
    'name_de': 'Costa d\'Avorio',
  },
  'JM': {
    'key': 'JM',
    'name': 'Jamaica',
    'code': '+1',
    'name_en': 'Jamaica',
    'name_it': 'Giamaica',
    'name_de': 'Giamaica',
  },
  'JP': {
    'key': 'JP',
    'name': 'Japan',
    'code': '+81',
    'name_en': 'Japan',
    'name_it': 'Giappone',
    'name_de': 'Giappone',
  },
  'KZ': {
    'key': 'KZ',
    'name': 'Kazakhstan',
    'code': '+7',
    'name_en': 'Kazakhstan',
    'name_it': 'Kazakistan',
    'name_de': 'Kazakistan',
  },
  'JO': {
    'key': 'JO',
    'name': 'Jordan',
    'code': '+962',
    'name_en': 'Jordan',
    'name_it': 'Giordania',
    'name_de': 'Giordania',
  },
  'KE': {
    'key': 'KE',
    'name': 'Kenya',
    'code': '+254',
    'name_en': 'Kenya',
    'name_it': 'Kenya',
    'name_de': 'Kenya',
  },
  'KP': {
    'key': 'KP',
    'name': 'Korea (Democratic People\'s Republic of)',
    'code': '+850',
    'name_en': 'Korea (Democratic People\'s Republic of)',
    'name_it': 'Corea del Nord',
    'name_de': 'Corea del Nord',
  },
  'KR': {
    'key': 'KR',
    'name': 'Korea (Republic of)',
    'code': '+82',
    'name_en': 'Korea (Republic of)',
    'name_it': 'Corea del Sud',
    'name_de': 'Corea del Sud',
  },
  'KW': {
    'key': 'KW',
    'name': 'Kuwait',
    'code': '+965',
    'name_en': 'Kuwait',
    'name_it': 'Kuwait',
    'name_de': 'Kuwait',
  },
  'KG': {
    'key': 'KG',
    'name': 'Kyrgyzstan',
    'code': '+996',
    'name_en': 'Kyrgyzstan',
    'name_it': 'Kirghizistan',
    'name_de': 'Kirghizistan',
  },
  'LA': {
    'key': 'LA',
    'name': 'Lao People\'s Democratic Republic',
    'code': '+856',
    'name_en': 'Lao People\'s Democratic Republic',
    'name_it': 'Laos',
    'name_de': 'Laos',
  },
  'LB': {
    'key': 'LB',
    'name': 'Lebanon',
    'code': '+961',
    'name_en': 'Lebanon',
    'name_it': 'Libano',
    'name_de': 'Libano',
  },
  'LS': {
    'key': 'LS',
    'name': 'Lesotho',
    'code': '+266',
    'name_en': 'Lesotho',
    'name_it': 'Lesotho',
    'name_de': 'Lesotho',
  },
  'LV': {
    'key': 'LV',
    'name': 'Latvia',
    'code': '+371',
    'name_en': 'Latvia',
    'name_it': 'Lettonia',
    'name_de': 'Lettonia',
  },
  'LR': {
    'key': 'LR',
    'name': 'Liberia',
    'code': '+231',
    'name_en': 'Liberia',
    'name_it': 'Liberia',
    'name_de': 'Liberia',
  },
  'LY': {
    'key': 'LY',
    'name': 'Libya',
    'code': '+218',
    'name_en': 'Libya',
    'name_it': 'Libia',
    'name_de': 'Libia',
  },
  'LI': {
    'key': 'LI',
    'name': 'Liechtenstein',
    'code': '+423',
    'name_en': 'Liechtenstein',
    'name_it': 'Liechtenstein',
    'name_de': 'Liechtenstein',
  },
  'LT': {
    'key': 'LT',
    'name': 'Lithuania',
    'code': '+370',
    'name_en': 'Lithuania',
    'name_it': 'Lituania',
    'name_de': 'Lituania',
  },
  'LU': {
    'key': 'LU',
    'name': 'Luxembourg',
    'code': '+352',
    'name_en': 'Luxembourg',
    'name_it': 'Lussemburgo',
    'name_de': 'Lussemburgo',
  },
  'MO': {
    'key': 'MO',
    'name': 'Macao',
    'code': '+853',
    'name_en': 'Macao',
    'name_it': 'Macao',
    'name_de': 'Macao',
  },
  'MG': {
    'key': 'MG',
    'name': 'Madagascar',
    'code': '+261',
    'name_en': 'Madagascar',
    'name_it': 'Madagascar',
    'name_de': 'Madagascar',
  },
  'MW': {
    'key': 'MW',
    'name': 'Malawi',
    'code': '+265',
    'name_en': 'Malawi',
    'name_it': 'Malawi',
    'name_de': 'Malawi',
  },
  'MY': {
    'key': 'MY',
    'name': 'Malaysia',
    'code': '+60',
    'name_en': 'Malaysia',
    'name_it': 'Malaysia',
    'name_de': 'Malaysia',
  },
  'MV': {
    'key': 'MV',
    'name': 'Maldives',
    'code': '+960',
    'name_en': 'Maldives',
    'name_it': 'Maldive',
    'name_de': 'Maldive',
  },
  'ML': {
    'key': 'ML',
    'name': 'Mali',
    'code': '+223',
    'name_en': 'Mali',
    'name_it': 'Mali',
    'name_de': 'Mali',
  },
  'MT': {
    'key': 'MT',
    'name': 'Malta',
    'code': '+356',
    'name_en': 'Malta',
    'name_it': 'Malta',
    'name_de': 'Malta',
  },
  'MQ': {
    'key': 'MQ',
    'name': 'Martinique',
    'code': '+596',
    'name_en': 'Martinique',
    'name_it': 'Martinique',
    'name_de': 'Martinique',
  },
  'MR': {
    'key': 'MR',
    'name': 'Mauritania',
    'code': '+222',
    'name_en': 'Mauritania',
    'name_it': 'Mauritania',
    'name_de': 'Mauritania',
  },
  'MU': {
    'key': 'MU',
    'name': 'Mauritius',
    'code': '+230',
    'name_en': 'Mauritius',
    'name_it': 'Mauritius',
    'name_de': 'Mauritius',
  },
  'MX': {
    'key': 'MX',
    'name': 'Mexico',
    'code': '+52',
    'name_en': 'Mexico',
    'name_it': 'Messico',
    'name_de': 'Messico',
  },
  'MC': {
    'key': 'MC',
    'name': 'Monaco',
    'code': '+377',
    'name_en': 'Monaco',
    'name_it': 'Monaco',
    'name_de': 'Monaco',
  },
  'MN': {
    'key': 'MN',
    'name': 'Mongolia',
    'code': '+976',
    'name_en': 'Mongolia',
    'name_it': 'Mongolia',
    'name_de': 'Mongolia',
  },
  'MD': {
    'key': 'MD',
    'name': 'Moldova (Republic of)',
    'code': '+373',
    'name_en': 'Moldova (Republic of)',
    'name_it': 'Moldavia',
    'name_de': 'Moldavia',
  },
  'ME': {
    'key': 'ME',
    'name': 'Montenegro',
    'code': '+382',
    'name_en': 'Montenegro',
    'name_it': 'Montenegro',
    'name_de': 'Montenegro',
  },
  'MS': {
    'key': 'MS',
    'name': 'Montserrat',
    'code': '+1',
    'name_en': 'Montserrat',
    'name_it': 'Montserrat',
    'name_de': 'Montserrat',
  },
  'MA': {
    'key': 'MA',
    'name': 'Morocco',
    'code': '+212',
    'name_en': 'Morocco',
    'name_it': 'Marocco',
    'name_de': 'Marocco',
  },
  'MZ': {
    'key': 'MZ',
    'name': 'Mozambique',
    'code': '+258',
    'name_en': 'Mozambique',
    'name_it': 'Mozambico',
    'name_de': 'Mozambico',
  },
  'OM': {
    'key': 'OM',
    'name': 'Oman',
    'code': '+968',
    'name_en': 'Oman',
    'name_it': 'Oman',
    'name_de': 'Oman',
  },
  'NA': {
    'key': 'NA',
    'name': 'Namibia',
    'code': '+264',
    'name_en': 'Namibia',
    'name_it': 'Namibia',
    'name_de': 'Namibia',
  },
  'NR': {
    'key': 'NR',
    'name': 'Nauru',
    'code': '+674',
    'name_en': 'Nauru',
    'name_it': 'Nauru',
    'name_de': 'Nauru',
  },
  'NP': {
    'key': 'NP',
    'name': 'Nepal',
    'code': '+977',
    'name_en': 'Nepal',
    'name_it': 'Nepal',
    'name_de': 'Nepal',
  },
  'NL': {
    'key': 'NL',
    'name': 'Netherlands',
    'code': '+31',
    'name_en': 'Netherlands',
    'name_it': 'Paesi Bassi',
    'name_de': 'Paesi Bassi',
  },
  'CW': {
    'key': 'CW',
    'name': 'Curaçao',
    'code': '+599',
    'name_en': 'Curaçao',
    'name_it': 'Curacao',
    'name_de': 'Curacao',
  },
  'AW': {
    'key': 'AW',
    'name': 'Aruba',
    'code': '+297',
    'name_en': 'Aruba',
    'name_it': 'Aruba',
    'name_de': 'Aruba',
  },
  'SX': {
    'key': 'SX',
    'name': 'Sint Maarten (Dutch part)',
    'code': '+1',
    'name_en': 'Sint Maarten (Dutch part)',
    'name_it': 'Sint Maarten (parte olandese)',
    'name_de': 'Sint Maarten (parte olandese)',
  },
  'BQ': {
    'key': 'BQ',
    'name': 'Bonaire, Sint Eustatius and Saba',
    'code': '+599',
    'name_en': 'Bonaire, Sint Eustatius and Saba',
    'name_it': 'Bonaire, Sint Eustatius e Saba',
    'name_de': 'Bonaire, Sint Eustatius e Saba',
  },
  'NC': {
    'key': 'NC',
    'name': 'New Caledonia',
    'code': '+687',
    'name_en': 'New Caledonia',
    'name_it': 'Nuova Caledonia',
    'name_de': 'Nuova Caledonia',
  },
  'VU': {
    'key': 'VU',
    'name': 'Vanuatu',
    'code': '+678',
    'name_en': 'Vanuatu',
    'name_it': 'Vanuatu',
    'name_de': 'Vanuatu',
  },
  'NZ': {
    'key': 'NZ',
    'name': 'New Zealand',
    'code': '+64',
    'name_en': 'New Zealand',
    'name_it': 'Nuova Zelanda',
    'name_de': 'Nuova Zelanda',
  },
  'NI': {
    'key': 'NI',
    'name': 'Nicaragua',
    'code': '+505',
    'name_en': 'Nicaragua',
    'name_it': 'Nicaragua',
    'name_de': 'Nicaragua',
  },
  'NE': {
    'key': 'NE',
    'name': 'Niger',
    'code': '+227',
    'name_en': 'Niger',
    'name_it': 'Niger',
    'name_de': 'Niger',
  },
  'NG': {
    'key': 'NG',
    'name': 'Nigeria',
    'code': '+234',
    'name_en': 'Nigeria',
    'name_it': 'Nigeria',
    'name_de': 'Nigeria',
  },
  'NU': {
    'key': 'NU',
    'name': 'Niue',
    'code': '+683',
    'name_en': 'Niue',
    'name_it': 'Niue',
    'name_de': 'Niue',
  },
  'NF': {
    'key': 'NF',
    'name': 'Norfolk Island',
    'code': '+672',
    'name_en': 'Norfolk Island',
    'name_it': 'Isole Norfolk',
    'name_de': 'Isole Norfolk',
  },
  'NO': {
    'key': 'NO',
    'name': 'Norway',
    'code': '+47',
    'name_en': 'Norway',
    'name_it': 'Norvegia',
    'name_de': 'Norvegia',
  },
  'MP': {
    'key': 'MP',
    'name': 'Northern Mariana Islands',
    'code': '+670',
    'name_en': 'Northern Mariana Islands',
    'name_it': 'Isole Marianne settentrionali',
    'name_de': 'Isole Marianne settentrionali',
  },
  'UM': {
    'key': 'UM',
    'name': 'United States Minor Outlying Islands',
    'code': '+1',
    'name_en': 'United States Minor Outlying Islands',
    'name_it': 'Isole minori esterne degli Stati Uniti',
    'name_de': 'Isole minori esterne degli Stati Uniti',
  },
  'FM': {
    'key': 'FM',
    'name': 'Micronesia (Federated States of)',
    'code': '+691',
    'name_en': 'Micronesia (Federated States of)',
    'name_it': 'Micronesia',
    'name_de': 'Micronesia',
  },
  'MH': {
    'key': 'MH',
    'name': 'Marshall Islands',
    'code': '+692',
    'name_en': 'Marshall Islands',
    'name_it': 'Isole Marshall',
    'name_de': 'Isole Marshall',
  },
  'PW': {
    'key': 'PW',
    'name': 'Palau',
    'code': '+680',
    'name_en': 'Palau',
    'name_it': 'Palau',
    'name_de': 'Palau',
  },
  'PK': {
    'key': 'PK',
    'name': 'Pakistan',
    'code': '+92',
    'name_en': 'Pakistan',
    'name_it': 'Pakistan',
    'name_de': 'Pakistan',
  },
  'PA': {
    'key': 'PA',
    'name': 'Panama',
    'code': '+507',
    'name_en': 'Panama',
    'name_it': 'Panama',
    'name_de': 'Panama',
  },
  'PG': {
    'key': 'PG',
    'name': 'Papua New Guinea',
    'code': '+675',
    'name_en': 'Papua New Guinea',
    'name_it': 'Papua Nuova Guinea',
    'name_de': 'Papua Nuova Guinea',
  },
  'PY': {
    'key': 'PY',
    'name': 'Paraguay',
    'code': '+595',
    'name_en': 'Paraguay',
    'name_it': 'Paraguay',
    'name_de': 'Paraguay',
  },
  'PE': {
    'key': 'PE',
    'name': 'Peru',
    'code': '+51',
    'name_en': 'Peru',
    'name_it': 'Perù',
    'name_de': 'Perù',
  },
  'PH': {
    'key': 'PH',
    'name': 'Philippines',
    'code': '+63',
    'name_en': 'Philippines',
    'name_it': 'Filippine',
    'name_de': 'Filippine',
  },
  'PN': {
    'key': 'PN',
    'name': 'Pitcairn',
    'code': '+64',
    'name_en': 'Pitcairn',
    'name_it': 'Pitcairn',
    'name_de': 'Pitcairn',
  },
  'PL': {
    'key': 'PL',
    'name': 'Poland',
    'code': '+48',
    'name_en': 'Poland',
    'name_it': 'Polonia',
    'name_de': 'Polonia',
  },
  'PT': {
    'key': 'PT',
    'name': 'Portugal',
    'code': '+351',
    'name_en': 'Portugal',
    'name_it': 'Portogallo',
    'name_de': 'Portogallo',
  },
  'GW': {
    'key': 'GW',
    'name': 'Guinea-Bissau',
    'code': '+245',
    'name_en': 'Guinea-Bissau',
    'name_it': 'Guinea-Bissau',
    'name_de': 'Guinea-Bissau',
  },
  'TL': {
    'key': 'TL',
    'name': 'Timor-Leste',
    'code': '+670',
    'name_en': 'Timor-Leste',
    'name_it': 'Timor Est',
    'name_de': 'Timor Est',
  },
  'PR': {
    'key': 'PR',
    'name': 'Puerto Rico',
    'code': '+787',
    'name_en': 'Puerto Rico',
    'name_it': 'Puerto Rico',
    'name_de': 'Puerto Rico',
  },
  'QA': {
    'key': 'QA',
    'name': 'Qatar',
    'code': '+974',
    'name_en': 'Qatar',
    'name_it': 'Qatar',
    'name_de': 'Qatar',
  },
  'RE': {
    'key': 'RE',
    'name': 'Réunion',
    'code': '+262',
    'name_en': 'Réunion',
    'name_it': 'Réunion',
    'name_de': 'Réunion',
  },
  'RO': {
    'key': 'RO',
    'name': 'Romania',
    'code': '+40',
    'name_en': 'Romania',
    'name_it': 'Romania',
    'name_de': 'Romania',
  },
  'RU': {
    'key': 'RU',
    'name': 'Russian Federation',
    'code': '+7',
    'name_en': 'Russian Federation',
    'name_it': 'Russia',
    'name_de': 'Russia',
  },
  'RW': {
    'key': 'RW',
    'name': 'Rwanda',
    'code': '+250',
    'name_en': 'Rwanda',
    'name_it': 'Ruanda',
    'name_de': 'Ruanda',
  },
  'BL': {
    'key': 'BL',
    'name': 'Saint Barthélemy',
    'code': '+590',
    'name_en': 'Saint Barthélemy',
    'name_it': 'Saint Barthélemy',
    'name_de': 'Saint Barthélemy',
  },
  'SH': {
    'key': 'SH',
    'name': 'Saint Helena, Ascension and Tristan da Cunha',
    'code': '+290',
    'name_en': 'Saint Helena, Ascension and Tristan da Cunha',
    'name_it': 'Sant\'Elena, Ascensione e Tristan da Cunha',
    'name_de': 'Sant\'Elena, Ascensione e Tristan da Cunha',
  },
  'KN': {
    'key': 'KN',
    'name': 'Saint Kitts and Nevis',
    'code': '+869',
    'name_en': 'Saint Kitts and Nevis',
    'name_it': 'Saint Kitts e Nevis',
    'name_de': 'Saint Kitts e Nevis',
  },
  'AI': {
    'key': 'AI',
    'name': 'Anguilla',
    'code': '+264',
    'name_en': 'Anguilla',
    'name_it': 'Anguilla',
    'name_de': 'Anguilla',
  },
  'LC': {
    'key': 'LC',
    'name': 'Saint Lucia',
    'code': '+758',
    'name_en': 'Saint Lucia',
    'name_it': 'Saint Lucia',
    'name_de': 'Saint Lucia',
  },
  'MF': {
    'key': 'MF',
    'name': 'Saint Martin (French part)',
    'code': '+590',
    'name_en': 'Saint Martin (French part)',
    'name_it': 'Saint Martin (parte francese)',
    'name_de': 'Saint Martin (parte francese)',
  },
  'PM': {
    'key': 'PM',
    'name': 'Saint Pierre and Miquelon',
    'code': '+508',
    'name_en': 'Saint Pierre and Miquelon',
    'name_it': 'Saint Pierre e Miquelon',
    'name_de': 'Saint Pierre e Miquelon',
  },
  'VC': {
    'key': 'VC',
    'name': 'Saint Vincent and the Grenadines',
    'code': '+784',
    'name_en': 'Saint Vincent and the Grenadines',
    'name_it': 'Saint Vincent e Grenadine',
    'name_de': 'Saint Vincent e Grenadine',
  },
  'SM': {
    'key': 'SM',
    'name': 'San Marino',
    'code': '+378',
    'name_en': 'San Marino',
    'name_it': 'San Marino',
    'name_de': 'San Marino',
  },
  'ST': {
    'key': 'ST',
    'name': 'Sao Tome and Principe',
    'code': '+239',
    'name_en': 'Sao Tome and Principe',
    'name_it': 'São Tomé e Príncipe',
    'name_de': 'São Tomé e Príncipe',
  },
  'SA': {
    'key': 'SA',
    'name': 'Saudi Arabia',
    'code': '+966',
    'name_en': 'Saudi Arabia',
    'name_it': 'Arabia Saudita',
    'name_de': 'Arabia Saudita',
  },
  'SN': {
    'key': 'SN',
    'name': 'Senegal',
    'code': '+221',
    'name_en': 'Senegal',
    'name_it': 'Senegal',
    'name_de': 'Senegal',
  },
  'RS': {
    'key': 'RS',
    'name': 'Serbia',
    'code': '+381',
    'name_en': 'Serbia',
    'name_it': 'Serbia',
    'name_de': 'Serbia',
  },
  'SC': {
    'key': 'SC',
    'name': 'Seychelles',
    'code': '+248',
    'name_en': 'Seychelles',
    'name_it': 'Seychelles',
    'name_de': 'Seychelles',
  },
  'SL': {
    'key': 'SL',
    'name': 'Sierra Leone',
    'code': '+232',
    'name_en': 'Sierra Leone',
    'name_it': 'Sierra Leone',
    'name_de': 'Sierra Leone',
  },
  'SG': {
    'key': 'SG',
    'name': 'Singapore',
    'code': '+65',
    'name_en': 'Singapore',
    'name_it': 'Singapore',
    'name_de': 'Singapore',
  },
  'SK': {
    'key': 'SK',
    'name': 'Slovakia',
    'code': '+421',
    'name_en': 'Slovakia',
    'name_it': 'Slovacchia',
    'name_de': 'Slovacchia',
  },
  'VN': {
    'key': 'VN',
    'name': 'Viet Nam',
    'code': '+84',
    'name_en': 'Viet Nam',
    'name_it': 'Vietnam',
    'name_de': 'Vietnam',
  },
  'SI': {
    'key': 'SI',
    'name': 'Slovenia',
    'code': '+386',
    'name_en': 'Slovenia',
    'name_it': 'Slovenia',
    'name_de': 'Slovenia',
  },
  'SO': {
    'key': 'SO',
    'name': 'Somalia',
    'code': '+252',
    'name_en': 'Somalia',
    'name_it': 'Somalia',
    'name_de': 'Somalia',
  },
  'ZA': {
    'key': 'ZA',
    'name': 'South Africa',
    'code': '+27',
    'name_en': 'South Africa',
    'name_it': 'Sudafrica',
    'name_de': 'Sudafrica',
  },
  'ZW': {
    'key': 'ZW',
    'name': 'Zimbabwe',
    'code': '+263',
    'name_en': 'Zimbabwe',
    'name_it': 'Zimbabwe',
    'name_de': 'Zimbabwe',
  },
  'ES': {
    'key': 'ES',
    'name': 'Spain',
    'code': '+34',
    'name_en': 'Spain',
    'name_it': 'Spagna',
    'name_de': 'Spagna',
  },
  'SS': {
    'key': 'SS',
    'name': 'South Sudan',
    'code': '+211',
    'name_en': 'South Sudan',
    'name_it': 'Sudan del Sud',
    'name_de': 'Sudan del Sud',
  },
  'SD': {
    'key': 'SD',
    'name': 'Sudan',
    'code': '+249',
    'name_en': 'Sudan',
    'name_it': 'Sudan',
    'name_de': 'Sudan',
  },
  'EH': {
    'key': 'EH',
    'name': 'Western Sahara',
    'code': '+212',
    'name_en': 'Western Sahara',
    'name_it': 'Sahara occidentale',
    'name_de': 'Sahara occidentale',
  },
  'SR': {
    'key': 'SR',
    'name': 'Suriname',
    'code': '+597',
    'name_en': 'Suriname',
    'name_it': 'Suriname',
    'name_de': 'Suriname',
  },
  'SJ': {
    'key': 'SJ',
    'name': 'Svalbard and Jan Mayen',
    'code': '+47 79',
    'name_en': 'Svalbard and Jan Mayen',
    'name_it': 'Svalbard e Jan Mayen',
    'name_de': 'Svalbard e Jan Mayen',
  },
  'SZ': {
    'key': 'SZ',
    'name': 'Swaziland',
    'code': '+268',
    'name_en': 'Swaziland',
    'name_it': 'Swaziland',
    'name_de': 'Swaziland',
  },
  'SE': {
    'key': 'SE',
    'name': 'Sweden',
    'code': '+46',
    'name_en': 'Sweden',
    'name_it': 'Svezia',
    'name_de': 'Svezia',
  },
  'CH': {
    'key': 'CH',
    'name': 'Switzerland',
    'code': '+41',
    'name_en': 'Switzerland',
    'name_it': 'Svizzera',
    'name_de': 'Svizzera',
  },
  'SY': {
    'key': 'SY',
    'name': 'Syrian Arab Republic',
    'code': '+963',
    'name_en': 'Syrian Arab Republic',
    'name_it': 'Siria',
    'name_de': 'Siria',
  },
  'TJ': {
    'key': 'TJ',
    'name': 'Tajikistan',
    'code': '+992',
    'name_en': 'Tajikistan',
    'name_it': 'Tagikistan',
    'name_de': 'Tagikistan',
  },
  'TH': {
    'key': 'TH',
    'name': 'Thailand',
    'code': '+66',
    'name_en': 'Thailand',
    'name_it': 'Thailandia',
    'name_de': 'Thailandia',
  },
  'TG': {
    'key': 'TG',
    'name': 'Togo',
    'code': '+228',
    'name_en': 'Togo',
    'name_it': 'Togo',
    'name_de': 'Togo',
  },
  'TK': {
    'key': 'TK',
    'name': 'Tokelau',
    'code': '+690',
    'name_en': 'Tokelau',
    'name_it': 'Tokelau',
    'name_de': 'Tokelau',
  },
  'TO': {
    'key': 'TO',
    'name': 'Tonga',
    'code': '+676',
    'name_en': 'Tonga',
    'name_it': 'Tonga',
    'name_de': 'Tonga',
  },
  'TT': {
    'key': 'TT',
    'name': 'Trinidad and Tobago',
    'code': '+868',
    'name_en': 'Trinidad and Tobago',
    'name_it': 'Trinidad e Tobago',
    'name_de': 'Trinidad e Tobago',
  },
  'AE': {
    'key': 'AE',
    'name': 'United Arab Emirates',
    'code': '+971',
    'name_en': 'United Arab Emirates',
    'name_it': 'Emirati Arabi Uniti',
    'name_de': 'Emirati Arabi Uniti',
  },
  'TN': {
    'key': 'TN',
    'name': 'Tunisia',
    'code': '+216',
    'name_en': 'Tunisia',
    'name_it': 'Tunisia',
    'name_de': 'Tunisia',
  },
  'TR': {
    'key': 'TR',
    'name': 'Turkey',
    'code': '+90',
    'name_en': 'Turkey',
    'name_it': 'Turchia',
    'name_de': 'Turchia',
  },
  'TM': {
    'key': 'TM',
    'name': 'Turkmenistan',
    'code': '+993',
    'name_en': 'Turkmenistan',
    'name_it': 'Turkmenistan',
    'name_de': 'Turkmenistan',
  },
  'TC': {
    'key': 'TC',
    'name': 'Turks and Caicos Islands',
    'code': '+649',
    'name_en': 'Turks and Caicos Islands',
    'name_it': 'Isole Turks e Caicos',
    'name_de': 'Isole Turks e Caicos',
  },
  'TV': {
    'key': 'TV',
    'name': 'Tuvalu',
    'code': '+688',
    'name_en': 'Tuvalu',
    'name_it': 'Tuvalu',
    'name_de': 'Tuvalu',
  },
  'UG': {
    'key': 'UG',
    'name': 'Uganda',
    'code': '+256',
    'name_en': 'Uganda',
    'name_it': 'Uganda',
    'name_de': 'Uganda',
  },
  'UA': {
    'key': 'UA',
    'name': 'Ukraine',
    'code': '+380',
    'name_en': 'Ukraine',
    'name_it': 'Ucraina',
    'name_de': 'Ucraina',
  },
  'MK': {
    'key': 'MK',
    'name': 'Macedonia (the former Yugoslav Republic of)',
    'code': '+389',
    'name_en': 'Macedonia (the former Yugoslav Republic of)',
    'name_it': 'Macedonia del Nord',
    'name_de': 'Macedonia del Nord',
  },
  'EG': {
    'key': 'EG',
    'name': 'Egypt',
    'code': '+20',
    'name_en': 'Egypt',
    'name_it': 'Egitto',
    'name_de': 'Egitto',
  },
  'GB': {
    'key': 'GB',
    'name': 'United Kingdom of Great Britain and Northern Ireland',
    'code': '+44',
    'name_en': 'United Kingdom of Great Britain and Northern Ireland',
    'name_it': 'Regno Unito',
    'name_de': 'Regno Unito',
  },
  'GG': {
    'key': 'GG',
    'name': 'Guernsey',
    'code': '+44 1481',
    'name_en': 'Guernsey',
    'name_it': 'Guernsey',
    'name_de': 'Guernsey',
  },
  'JE': {
    'key': 'JE',
    'name': 'Jersey',
    'code': '+44 1534',
    'name_en': 'Jersey',
    'name_it': 'Jersey',
    'name_de': 'Jersey',
  },
  'IM': {
    'key': 'IM',
    'name': 'Isle of Man',
    'code': '+44 1624',
    'name_en': 'Isle of Man',
    'name_it': 'Isola di Man',
    'name_de': 'Isola di Man',
  },
  'TZ': {
    'key': 'TZ',
    'name': 'Tanzania, United Republic of',
    'code': '+255',
    'name_en': 'Tanzania, United Republic of',
    'name_it': 'Tanzania',
    'name_de': 'Tanzania',
  },
  'VI': {
    'key': 'VI',
    'name': 'Virgin Islands (U.S.)',
    'code': '+340',
    'name_en': 'Virgin Islands (U.S.)',
    'name_it': 'Isole Vergini (Stati Uniti)',
    'name_de': 'Isole Vergini (Stati Uniti)',
  },
  'BF': {
    'key': 'BF',
    'name': 'Burkina Faso',
    'code': '+226',
    'name_en': 'Burkina Faso',
    'name_it': 'Burkina Faso',
    'name_de': 'Burkina Faso',
  },
  'UY': {
    'key': 'UY',
    'name': 'Uruguay',
    'code': '+598',
    'name_en': 'Uruguay',
    'name_it': 'Uruguay',
    'name_de': 'Uruguay',
  },
  'UZ': {
    'key': 'UZ',
    'name': 'Uzbekistan',
    'code': '+998',
    'name_en': 'Uzbekistan',
    'name_it': 'Uzbekistan',
    'name_de': 'Uzbekistan',
  },
  'VE': {
    'key': 'VE',
    'name': 'Venezuela (Bolivarian Republic of)',
    'code': '+58',
    'name_en': 'Venezuela (Bolivarian Republic of)',
    'name_it': 'Venezuela',
    'name_de': 'Venezuela',
  },
  'WF': {
    'key': 'WF',
    'name': 'Wallis and Futuna',
    'code': '+681',
    'name_en': 'Wallis and Futuna',
    'name_it': 'Wallis e Futuna',
    'name_de': 'Wallis e Futuna',
  },
  'WS': {
    'key': 'WS',
    'name': 'Samoa',
    'code': '+685',
    'name_en': 'Samoa',
    'name_it': 'Samoa',
    'name_de': 'Samoa',
  },
  'YE': {
    'key': 'YE',
    'name': 'Yemen',
    'code': '+967',
    'name_en': 'Yemen',
    'name_it': 'Yemen',
    'name_de': 'Yemen',
  },
  'ZM': {
    'key': 'ZM',
    'name': 'Zambia',
    'code': '+260',
    'name_en': 'Zambia',
    'name_it': 'Zambia',
    'name_de': 'Zambia',
  },
}
