const VIDEOS = ['ALAssetTypeVideo']

// ios image
// {
//  "filename":"IMG_1307.JPG",
//  "height":4032,
//  "uri":"assets-library://asset/asset.JPG?id=E0387BE8-254C-4449-8406-223B004A115E&ext=JPG",
//  "width":3024,
//  "type":"ALAssetTypePhoto",
// }

// ios video
// {
//  "filename":"IMG_1311.MOV"
//  "height":1920
//  "uri":"assets-library://asset/asset.MOV?id=99571C9E-49E2-466A-A7FF-9DBC4D0D590E&ext=MOV"
//  "width":1080
//  "type":"ALAssetTypeVideo"
// }

// android photo
// {
//  "height":2448,
//  "width":3264,
//  "uri":"content://media/external/images/media/273",
//  "type":"image/jpeg"
// }

// takes the random formats given by different devices and returns consistent mime type
export default function({ type, uri, filename }) {
  if (type === 'ALAssetTypeVideo') {
    // ios video
    return 'video/quicktime'
  } else if (type === 'ALAssetTypePhoto') {
    // ios image
    const extension = filename
      .split('.')
      .pop()
      .toLowerCase()
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg'
      default:
        return `image/${extension}`
    }
  } else {
    // android has the correct type
    return type
  }
}

export function mimeIsVideo({ type, uri, filaneme }) {
  if (VIDEOS.indexOf(type) >= 0) {
    return true
  }
  if (type.substr(0, 5) === 'video') {
    return true
  }
  return false
}
