import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import MobileBackButton from '../../components/simple/MobileBackButton'
import { Status } from '../../components/simple/Status'
import ProfileBanner from '../../components/simple/ProfileBanner'
import Scroller from '../../components/Scroller'

import NavigationActions from '../../utility/navigationActions'
import { graphql, connect } from '../../config/connected'
import schema from './schema'
import i18n from 'i18n-js'
import { isArray } from '../../config/helpers'

import sizes from '../../config/sizes'
import colors from '../../config/colors'
import { stylus } from '../../config/styles'

@graphql(schema.queries.followers, {
  name: 'usersQuery',
  options({ navigation }) {
    return {
      fetchPolicy: 'cache-and-network',
      variables: { userId: navigation.state.params.id },
    }
  },
})
@graphql(schema.queries.followers, {
  name: 'usersQuery',
  options({ navigation }) {
    return {
      fetchPolicy: 'cache-and-network',
      variables: { userId: navigation.state.params.id },
    }
  },
})
@connect(
  ({ screenInfo }) => ({ screenInfo }),
  (dispatch) => ({
    goToProfile: (profile) => {
      NavigationActions.navigate({
        routeName: 'Profile',
        params: { id: profile.id, pageTitle: profile.username },
      })
    },
  }),
)
class Followers extends React.Component {
  getUsers() {
    const { usersQuery } = this.props
    if (usersQuery.loading) {
      return <ActivityIndicator size='large' style={{ paddingVertical: 60 }} />
    }
    const users = usersQuery.followers

    if (!users.length) {
      return null
    }
    return users
  }

  renderUsers() {
    const users = this.getUsers()
    if (!isArray(users)) {
      return users // users is instead a component
    }
    return users.map((user) => (
      <ProfileBanner
        key={user.id}
        profile={user}
        profileOnPress={this.props.goToProfile}
      />
    ))
  }

  render() {
    return (
      <Scroller styles={[styles.usersContainer]}>{this.renderUsers()}</Scroller>
    )
  }
}

export default Followers

Followers.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('common.followers'),
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

const styles = stylus({
  usersContainer: {
    paddingHorizontal: 30,
    paddingTop: 10,
  },
})
