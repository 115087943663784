import React from 'react'
import {
  Button,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
} from 'react-native'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import shared, { stylus } from '../../config/styles'
import { fbLogo } from '../../assets/images'

const FeedLogo = ({ onPress, style }) => {
  return (
    <View style={[styles.container, style]} onPress={onPress}>
      <Image
        source={fbLogo}
        style={styles.graphic}
        resizeMode='contain'
        resizeMethod='scale'
      />
    </View>
  )
}

export default FeedLogo

const styles = stylus({
  container: {
    paddingHorizontal: 8,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    android: {
      width: '100%',
    },
  },
  graphic: { width: 28, height: 28 },
})
