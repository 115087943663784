import React, { Component } from 'react'
import { View, TouchableOpacity, Text} from 'react-native'
import { stylus } from '../../config/styles'
import { PropTypes } from 'prop-types'

function noop() {}

export default class ActionSheet extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node)
    ]).isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      callback: noop,
      options: {
        options: ['Cancel'],
        cancelButtonIndex: 0,
        destructiveButtonIndex: -1,
      },
      show: false,
    }
  }

  callbackWrapper = (index) => {
    return () => {
      this.setState({show: false }, () => this.state.callback(index))
    }
  }

  render() {
    return (
      <View style={{flex: 1}}>
        {React.Children.only(this.props.children)}
        {this.renderActionSheet()}
      </View>
    )
  }

  renderActionSheet() {
    const {
      show,
      options
    } = this.state
    const actions = [...options.options]
    const { cancelButtonIndex, destructiveButtonIndex } = options
    const [cancelButtonText] = actions.splice(cancelButtonIndex, 1)

    const maskShowStyle = show && styles.maskShow 
    const wrapShowStyle = show && styles.wrapShow
    return (
      <View>
        <View style={[styles.mask, maskShowStyle]}>
        </View>
        <View style={[styles.wrap, wrapShowStyle]}>
          <View style={[styles.menu]}>
            {
              actions.map((action, index) => {
                return (
                  <TouchableOpacity key={index} onPress={this.callbackWrapper(index)}>
                    <View style={[styles.menuItem, 
                      index === 0 && styles.menuItemFirst,
                      index === actions.length - 1 && styles.menuItemLast,
                    ]}>
                      <Text style={[
                        styles.menuItemText,
                        index === destructiveButtonIndex && styles.destructiveItemText
                      ]}>{action}</Text>
                    </View>
                  </TouchableOpacity>
                )
              })
            }
          </View>
          <View style={[styles.actions]}>
            <TouchableOpacity key='0' onPress={this.callbackWrapper(cancelButtonIndex)}>
              <View style={[styles.actionItem, styles.actionItemFirst, styles.actionItemLast]}>
                <Text style={[styles.menuItemText, styles.actionItemText]}>{cancelButtonText}</Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }

  showActionSheetWithOptions(options, callback) {
    this.setState({ options, callback, show: true })
  }
}

const styles = stylus({
  all: {
    boxSizing: 'border-box'
  },
  mask: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    backfaceVisibility: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    transitionDuration: '0.3s',
    transitionProperty: 'opacity',
    opacity: 0,
    display: 'none',
  },
   
  maskShow: {
    opacity: 1,
    display: 'block',
  },
   
  wrap: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    padding: 10,
    width: '100%',
    zIndex: 1001,
    transitionDuration: '0.3s',
    transitionProperty: 'all',
    transform: [
      { translateY: '101%' },
    ],
  },
   
  wrapShow: {
    transform: [
      { translateY: '0' },
    ],
  },
   
  menu: {
    borderRadius: 12,
    backgroundColor: '#F9F9F9',
  },
   
  menuItem: {
    position: 'relative',
    height: 57,
    width: '100%',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: 'rgba(0, 0, 0, 0.13)',
  },
   
  menuItemFirst: {
    borderTopWidth: 0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
   
  menuItemLast: {
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
   
  menuItemAfter: {
    position: 'absolute',
    display: 'block',
    bottom: '-100%',
    right: '-100%',
    left: 0,
    top: 0,
    content: '',
    boxSizing: 'border-box',
    pointerEvents: 'none',
    transform: 'scale(0.5)',
    transformOrigin: '0 0',
  },
   
  actions: {
    marginTop: 8,
    borderRadius: 12,
    backgroundColor: '#F9F9F9',
  },
   
  actionItem: {
    height: 57,
    borderRadius: 12,
  },
   
  actionItemFirst: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
   
  actionItemLast: {
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },

  menuItemText: {
    lineHeight: 57,
    textAlign: 'center',
    fontSize: 20,
    color: '#007AFE',
  },
  destructiveItemText: {
    color: '#FF4035'
  },
  actionItemText: {
    fontWeight: 'bold',
  }
})
