import _ from 'lodash'
import emailValidator from 'email-validator'
import i18n from 'i18n-js'

// Validators defined for specific form inputs.
export const validators = {
  string: (options, value) => {
    if (_.isEmpty(value) && !options.optional) {
      return 'required'
    }
  },
  phone: (options, value) => {
    // Regex requires the number in addition to the country code
    // in order to validate the phone number properly.
    const fullNumber = options.countryCode + value
    if (_.isEmpty(value)) {
      return 'errors.phone.required'
    }
    if (
      value &&
      !fullNumber.match(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
      )
    ) {
      return 'errors.phone.invalid'
    }
  },
  firstName: (options, value) => {
    if (value.match(/Foodbarrio/i)) {
      return 'errors.firstName.wrong'
    }
  },
  lastName: (options, value) => {
    if (value.match(/Foodbarrio/i)) {
      return 'errors.lastName.wrong'
    }
  },
  username: (options, value) => {
    if (_.isEmpty(value) && !options.allowEmpty) {
      return 'errors.username.required'
    }
    // Usernames must only contain A-Z and 0-9, dots are allowed too.
    if (value.match(/[^a-z0-9._]+/g)) {
      return 'errors.username.invalid'
    }
    if (value.match(/Foodbarrio/i)) {
      return 'errors.username.taken'
    }
    if (value[0] === '.') {
      return 'errors.username.noStartDot'
    }
  },
  email: (options, value) => {
    if (_.isEmpty(value)) {
      return 'required'
    } else if (!emailValidator.validate(value)) {
      return 'errors.email.invalid'
    }
  },
  countryCode: (options, value) => {
    if (_.isEmpty(value) && !options.optional) {
      return 'errors.country.required'
    }
    if (value.length < 1 && !options.optional) {
      return 'errors.country.required'
    }
  },
}

const getMessage = (result, name) => {
  if (result === 'required') {
    return 'errors.' + name + '.required'
  }
  return result
}

export default function(schema, record) {
  const errors = []

  for (const name in schema) {
    const config = schema[name]
    // Pulls the validation method from the validators object, or
    // defaults to validators.string if unspecified.
    const method = validators[config.type] || validators.string
    const requirementsMet = config.requirementsMet ? config.requirementsMet() : true
    if (!requirementsMet) {
      continue
    }
    const result = method(config, record[name])
    if (result) {
      errors.push({
        message: getMessage(result, name),
        name,
      })
    }
  }
  return errors
}

export function isAlphaNumeric(value) {
  const deburred = _.deburr(value)
  if (deburred.match(/^[a-zA-Z0-9\s]*$/g)) {
    return true
  }

  return false
}

export function isValidTitle(value) {
  const deburred = _.deburr(value)
  if (
    deburred.match(
      /^[a-z0-9$_&\-+()/*"'“”‘’/\\:;!?.,~|•√π÷×¶∆}{=°^¥€¢£%©®™✓>< ]*$/i,
    )
  ) {
    return true
  }
  return false
}

export function isTaggingSafe(value) {
  const deburred = _.deburr(value)
  if (deburred.match(/[@#[\]]*/g)) {
    return false
  }
  return true
}

export function isZip(value) {
  const deburred = _.deburr(value)
  if (deburred.match(/^[a-zA-Z0-9\s-]*$/g)) {
    return true
  }

  return false
}

export function isNumeric(value) {
  if (value.match(/^[0-9+ .,]*$/g)) {
    return true
  }
  return false
}

export function isDecimal(value) {
  if (value === '') {
    return true
  }

  if (value.match(/^[0-9]+\.?[0-9]*$/g)) {
    return true
  }
  return false
}

export function isValidName(value) {
  const deburred = _.deburr(value)
  const result = deburred.match(/^[a-zA-Z\s]*$/g)
  if (result) {
    return true
  }
  return false
}

export function isValidAddress(value) {
  if (_.isEmpty(value)) {
    return true
  } else {
    if (value.match(/[^a-z0-9\u00C0-\u017F-_. ,/\\'"&`“”‘’;:]/i)) {
      return false
    }
  }
  return true
}

export function isValidEmail(value) {
  let error = validators.email(null, value)
  if (error) {
    return false
  }
  return true
}

// based off https://rosettacode.org/wiki/IBAN#JavaScript
export function isValidIbanNumber(value) {
  var ibanLen = {
    NO: 15,
    BE: 16,
    DK: 18,
    FI: 18,
    FO: 18,
    GL: 18,
    NL: 18,
    MK: 19,
    SI: 19,
    AT: 20,
    BA: 20,
    EE: 20,
    KZ: 20,
    LT: 20,
    LU: 20,
    CR: 21,
    CH: 21,
    HR: 21,
    LI: 21,
    LV: 21,
    BG: 22,
    BH: 22,
    DE: 22,
    GB: 22,
    GE: 22,
    IE: 22,
    ME: 22,
    RS: 22,
    AE: 23,
    GI: 23,
    IL: 23,
    AD: 24,
    CZ: 24,
    ES: 24,
    MD: 24,
    PK: 24,
    RO: 24,
    SA: 24,
    SE: 24,
    SK: 24,
    VG: 24,
    TN: 24,
    PT: 25,
    IS: 26,
    TR: 26,
    FR: 27,
    GR: 27,
    IT: 27,
    MC: 27,
    MR: 27,
    SM: 27,
    AL: 28,
    AZ: 28,
    CY: 28,
    DO: 28,
    GT: 28,
    HU: 28,
    LB: 28,
    PL: 28,
    BR: 29,
    PS: 29,
    KW: 30,
    MU: 30,
    MT: 31,
  }

  let iban = value.replace(/\s/g, '')
  if (!iban.match(/^[\dA-Z]+$/)) return false
  var len = iban.length
  if (len != ibanLen[iban.substring(0, 2)]) return false
  const beginning = iban.substring(4)
  const end = iban.substring(0, 4)
  iban = beginning + end
  for (var s = '', i = 0; i < len; i += 1) s += parseInt(iban.charAt(i), 36)
  for (
    var m = s.substring(0, 15) % 97, s = s.substring(15);
    s;
    s = s.substring(13)
  )
    m = (m + s.substring(0, 13)) % 97
  return m == 1
}

export function isPhone(value) {
  if (value.match(/^[0-9+\s-()]*$/g)) {
    return true
  }
  return false
}

export function isPhonePrefix(value) {
  if (value.match(/\+\d{2}$/)) {
    return true
  }
  return false
}

export function isYear(value) {
  if (value.match(/^\d{0,4}$/)) {
    return true
  }
  return false
}

export function isValidCoords(geo) {
  if (geo.lat && geo.lng) {
    return true
  }
  return false
}

export function isWebsite(value) {
  // websites are a bit picky, ensure you are not passing any capitalized characters to it. This is intentional due to restrictions in opening
  // webviews in expo.
  const deburred = _.toLower(_.deburr(value))
  // return true
  if (value === '') {
    return true
  }
  if (deburred.match(/^(?:http(s)?:\/\/)www\.([A-z]+)\.([A-z]{2,})/)) {
    return true
  }
  return false
}

/**
 * Considered to be a field error checking function during field editing and on submit
 * @param {{
 *  [fieldName]: {
 *    requirementsMet: function(fields): boolean
 *    validate!: function(value): boolean
 *    errorName!: string
 *    optional: boolean
 *    onlyOnSubmit: boolean
 *  }
 * }} fieldsConfig
 * @param {string} fieldsPropKey - The key on the props where the form fields are stored
 * @param {string} pathToFields - The path on 'this' where the form fields are stored
 * @param {string} fieldsTransPath  - The beginning of the translation path to the particular set of fields keys
 */
export function bindableFieldErrorFunc(
  fieldsConfig,
  pathToFields,
  removeError,
  setError,
  errorTransPath,
  fieldsTransPath,
) {
  return function(fieldValue, fieldName, checkingOnSubmit, onSetState, alterState) {
    const fields = _.get(this, pathToFields, null)
    if (!fields) {
      return ''
    }
    const errorKeyPrefix = errorTransPath
    const config = fieldsConfig[fieldName]
    if (!config) {
      console.log(
        `No validation configuration for ${fieldName}. So field value: ${fieldValue} is valid`,
      )
      return ''
    }
    const {
      optional,
      onlyOnSubmit,
      validate,
      errorName,
      requirementsMet,
    } = config
    let errorKey = errorName
    const params = {}
    let valid = true
    let fieldEmpty = false
    const requirementsHaveBeenMet = requirementsMet
      ? requirementsMet(fields)
      : true

    if (!fieldValue && checkingOnSubmit && requirementsHaveBeenMet) {
      errorKey = 'required'
      fieldEmpty = true
      valid = false
      params.fieldName = i18n.t(`${fieldsTransPath}.${fieldName}`)
    }
    if (
      (!checkingOnSubmit && onlyOnSubmit) ||
      (fieldEmpty && optional) ||
      (!fieldEmpty &&
        requirementsHaveBeenMet &&
        (valid = validate(fieldValue, fields)))
    ) {
      if (alterState && removeError) {
        removeError(fieldName, onSetState)
      }
      return ''
    } else if (!valid) {
      const transKey = `${errorKeyPrefix}.${errorKey}`
      if (alterState && setError) {
        setError(fieldName, transKey, params, onSetState)
      }
      return i18n.t(transKey, params)
    }
    return ''
  }
}

/**
 * Considered to be an error checking function for a set of fields on submit
 * @param {function} fieldErrorFunc - This should be a bound instance of the return value from bindableFieldErrorFunc
 * @param {string} pathToFields - The path on 'this' where the form fields are stored
 * @param {string} fieldsTransPath  - The beginning of the translation path to the particular set of fields keys
 */
export function bindableFieldErrorsFunc(
  fieldErrorFunc,
  pathToFields,
  fieldsTransPath,
) {
  return function(onSetState, alterState) {
    const fields = _.get(this, pathToFields, null)
    if (!fields) {
      return ''
    }
    let message = ''
    for (const fieldName in fields) {
      const fieldValue = fields[fieldName]
      const fieldError = fieldErrorFunc(fieldValue, fieldName, true, onSetState, alterState)
      if (fieldError) {
        if (fieldError.indexOf('required') === -1) {
          message += `- ${i18n.t(
            `${fieldsTransPath}.${fieldName}`,
          )} : ${fieldError}\n`
        } else {
          message += `- ${fieldError}\n`
        }
      }
    }
    return message
  }
}

export const createYupValidationMethod = (name, testMethod) => {
  return function(message) {
    return this.test({
      name,
      exclusive: false,
      message,
      test: testMethod,
    })
  }
}
