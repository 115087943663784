import React, { Component } from 'react'
import { View, ScrollView } from 'react-native'
import { stylus } from '../../../../config/styles'
import i18n from 'i18n-js'
import T from '../../../../components/T'
import ElegantInput from '../../../../components/simple/ElegantInput'
import colors from '../../../../config/colors'
import Button from '../../../../components/simple/Button'
import {
  isAlphaNumeric,
  isPhonePrefix,
  isPhone,
  isValidName,
  isValidIbanNumber,
  bindableFieldErrorFunc,
  bindableFieldErrorsFunc,
  isValidTitle,
} from '../../../../config/validate'
import KeyboardShift from '../../../../containers/KeyboardShift'
import PaymentInfo from '../../../../components/simple/PaymentInfo'
import {
  SHOP_FIELD_VAT,
  SHOP_FIELD_EU_VAT,
  SHOP_FIELD_LEGAL_NAME,
  SHOP_FIELD_ROLE,
  SHOP_FIELD_BANK_ACCOUNT_HOLDER,
  SHOP_FIELD_COMPANY_PHONE_LAND_PREFIX,
  SHOP_FIELD_COMPANY_PHONE_LAND,
  SHOP_FIELD_COMPANY_PHONE_MOBILE_PREFIX,
  SHOP_FIELD_COMPANY_PHONE_MOBILE,
  SHOP_FIELD_IBAN_NUMBER,
} from '../../../../config/constants'
import alert from '../../../../utility/alert'

const MAX_FIELD_LENGTH = 50

const fieldConfig = {
  [SHOP_FIELD_VAT]: {
    validate: isAlphaNumeric,
    errorName: 'invalidVAT',
  },
  [SHOP_FIELD_EU_VAT]: {
    validate: isAlphaNumeric,
    errorName: 'invalidVAT',
    optional: true,
  },
  [SHOP_FIELD_LEGAL_NAME]: {
    validate: isValidTitle,
    errorName: 'invalidName',
  },
  [SHOP_FIELD_ROLE]: {
    validate: isAlphaNumeric,
    errorName: 'invalidRole',
  },
  [SHOP_FIELD_BANK_ACCOUNT_HOLDER]: {
    requirementsMet: (fields) => fields.transfer,
    validate: isValidTitle,
    errorName: 'invalidName',
  },
  [SHOP_FIELD_IBAN_NUMBER]: {
    requirementsMet: (fields) => fields.transfer,
    validate: isValidIbanNumber,
    errorName: 'invalidIbanNumber',
    onlyOnSubmit: true,
  },
  [SHOP_FIELD_COMPANY_PHONE_LAND_PREFIX]: {
    validate: isPhonePrefix,
    errorName: 'invalidPrefix',
  },
  [SHOP_FIELD_COMPANY_PHONE_LAND]: {
    validate: isPhone,
    errorName: 'invalidPhone',
  },
  [SHOP_FIELD_COMPANY_PHONE_MOBILE_PREFIX]: {
    validate: isPhonePrefix,
    errorName: 'invalidPrefix',
  },
  [SHOP_FIELD_COMPANY_PHONE_MOBILE]: {
    validate: isPhone,
    errorName: 'invalidPhone',
  },
}
export class FinancialInfo extends Component {
  constructor(props) {
    super(props)
    this.fieldError = bindableFieldErrorFunc(
      fieldConfig,
      'props.financialInfo',
      undefined,
      undefined,
      'errors.onboardingShop',
      'onboardingShop.fields',
    ).bind(this)
    this.fieldErrors = bindableFieldErrorsFunc(
      this.fieldError,
      'props.financialInfo',
      'onboardingShop.fields',
    ).bind(this)
  }

  componentDidMount() {
    const { registerScreen } = this.props
    if (registerScreen) {
      registerScreen({
        screen: 'FinancialInfo',
        fieldSetValidator: this.fieldErrors,
      })
    }
  }

  handleSubmit = () => {
    const message = this.fieldErrors()
    if (message) {
      alert({ title: 'Errors', message })
    } else {
      this.props.goToPage('Details')
    }
  }

  handleFieldChange = (value, target) => {
    const error = this.fieldError(value, target)
    this.props.handleFieldChange(value, target, error)
  }

  isValidRole = (value) => {
    return isValidName(value)
  }

  // React.creatRef approach doesn't work on web
  inputRefs = {}

  render() {
    const {
      handleFieldChange,
      financialInfo,
      goToPage,
      errors,
      asView,
    } = this.props
    const _ScrollView = asView ? View : ScrollView
    const _KeyboardShift = asView ? View : KeyboardShift
    return (
      <_ScrollView>
        <_KeyboardShift fluid>
          <View style={styles.container}>
            {/* TODO: Add icons into custom icons for cash and transfer */}
            <T
              t={
                asView
                  ? 'onboardingShop.financialInfo.titleAsView'
                  : 'onboardingShop.financialInfo.title'
              }
              style={styles.title}
            />
            {!asView && (
              <T t='onboardingShop.financialInfo.body' style={styles.body} />
            )}
            <ElegantInput
              returnKeyType='next'
              label={i18n.t('onboardingShop.financialInfo.VAT')}
              value={financialInfo.VAT}
              error={errors.VAT}
              onSubmitEditing={() => this.inputRefs.euVAT.focus()}
              reference={(ref) => (this.inputRefs.VAT = ref)}
              maxLength={15}
              onChange={(value) => {
                this.handleFieldChange(value, SHOP_FIELD_VAT)
              }}
            />
            <ElegantInput
              returnKeyType='next'
              label={i18n.t('onboardingShop.financialInfo.euVAT')}
              value={financialInfo.euVAT}
              error={errors.euVAT}
              onSubmitEditing={() => this.inputRefs.legalName.focus()}
              reference={(ref) => (this.inputRefs.euVAT = ref)}
              maxLength={15}
              onChange={(value) => {
                this.handleFieldChange(value, SHOP_FIELD_EU_VAT)
              }}
            />
            <ElegantInput
              textContentType='organizationName'
              returnKeyType='next'
              label={i18n.t('onboardingShop.financialInfo.legalName')}
              value={financialInfo.legalName}
              error={errors.legalName}
              onSubmitEditing={() => this.inputRefs.companyPhoneLandPrefix.focus()}
              reference={(ref) => (this.inputRefs.legalName = ref)}
              maxLength={MAX_FIELD_LENGTH}
              onChange={(value) => {
                this.handleFieldChange(value, SHOP_FIELD_LEGAL_NAME)
              }}
            />

            {/* //TODO:  Known issue with adding a + at the start of a string for phone numbers  */}
            <View style={{flexDirection: 'row'}}>
              <View style={styles.prefixWrapper}>
                <ElegantInput
                  style={{ width: '100%' }}
                  returnKeyType='next'
                  keyboardType='phone-pad'
                  value={financialInfo.companyPhoneLandPrefix}
                  onSubmitEditing={() => this.inputRefs.companyPhoneLand.focus()}
                  reference={(ref) => (this.inputRefs.companyPhoneLandPrefix = ref)}
                  maxLength={3}
                  onChange={(value) => {
                    this.handleFieldChange(value, SHOP_FIELD_COMPANY_PHONE_LAND_PREFIX)
                  }}
                />
              </View>
              <View style={styles.phoneWrapper}>
                <ElegantInput
                  style={{ width: '100%' }}
                  textContentType='telephoneNumber'
                  returnKeyType='next'
                  keyboardType='phone-pad'
                  label={i18n.t('onboardingShop.financialInfo.companyPhoneLand')}
                  value={financialInfo.companyPhoneLand}
                  error={errors.companyPhoneLand}
                  onSubmitEditing={() => this.inputRefs.companyPhoneMobilePrefix.focus()}
                  reference={(ref) => (this.inputRefs.companyPhoneLand = ref)}
                  maxLength={20}
                  onChange={(value) => {
                    this.handleFieldChange(value, SHOP_FIELD_COMPANY_PHONE_LAND)
                  }}
                />
              </View>
            </View>
            {/* //TODO:  Known issue with adding a + at the start of a string for phone numbers  */}
            <View style={{flexDirection: 'row'}}>
              <View style={styles.prefixWrapper}>
                <ElegantInput
                  style={{ width: '100%' }}
                  returnKeyType='next'
                  keyboardType='phone-pad'
                  value={financialInfo.companyPhoneMobilePrefix}
                  onSubmitEditing={() => this.inputRefs.companyPhoneMobile.focus()}
                  reference={(ref) => (this.inputRefs.companyPhoneMobilePrefix = ref)}
                  maxLength={3}
                  onChange={(value) => {
                    this.handleFieldChange(value, SHOP_FIELD_COMPANY_PHONE_MOBILE_PREFIX)
                  }}
                />
              </View>
              <View style={styles.phoneWrapper}>
                <ElegantInput
                  style={{ width: '100%' }}
                  textContentType='telephoneNumber'
                  returnKeyType='next'
                  keyboardType='phone-pad'
                  label={i18n.t('onboardingShop.financialInfo.companyPhoneMobile')}
                  value={financialInfo.companyPhoneMobile}
                  error={errors.companyPhoneMobile}
                  onSubmitEditing={() => this.inputRefs.role.focus()}
                  reference={(ref) => (this.inputRefs.companyPhoneMobile = ref)}
                  maxLength={20}
                  onChange={(value) => {
                    this.handleFieldChange(value, SHOP_FIELD_COMPANY_PHONE_MOBILE)
                  }}
                />
              </View>
            </View>

            <ElegantInput
              textContentType='jobTitle'
              label={i18n.t('onboardingShop.financialInfo.role')}
              value={financialInfo.role}
              error={errors.role}
              reference={(ref) => (this.inputRefs.role = ref)}
              maxLength={MAX_FIELD_LENGTH}
              onChange={(value) => {
                this.handleFieldChange(value, SHOP_FIELD_ROLE)
              }}
            />
            {!asView && (
              <Button
                label={i18n.t('onboardingShop.next')}
                style={styles.next}
                onPress={() => this.handleSubmit()}
              />
            )}
          </View>
        </_KeyboardShift>
      </_ScrollView>
    )
  }
}

export default FinancialInfo

const styles = stylus({
  container: {
    alignItems: 'center',
    padding: 40,
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    margin: 10,
  },
  paymentContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  checkboxWithIcon: {
    alignItems: 'center',
  },
  paymentText: {
    fontSize: 22,
    fontWeight: 'bold',
    margin: 10,
    marginTop: 60,
  },
  body: {
    fontSize: 16,
    color: colors.text.secondary,
    margin: 10,
  },
  next: {
    width: '100%',
    marginVertical: 20,
    backgroundColor: colors.primary,
  },
  prefixWrapper: {
    width: '15%',
    paddingRight: 10,
  },
  phoneWrapper: {
    width: '85%',
  },
}) /*}
                <Image
                  source={stripeLogo}
                  style={{ width: 96, height: 96 }}
                  resizeMethod='scale'
                  resizeMode='contain'
                />
                <T t='onboardingShop.financialInfo.cash' />
                <Checkbox
                  checked={financialInfo.cash}
                  onPress={() => handleFieldChange(!financialInfo.cash, 'cash')}
                />
              </View>
              <View style={styles.checkboxWithIcon}>
                <Image
                  source={stripeLogo}
                  style={{ width: 96, height: 96 }}
                  resizeMethod='scale'
                  resizeMode='contain'
                />
                <T t='onboardingShop.financialInfo.transfer' />
                <Checkbox
                  checked={financialInfo.transfer}
                  onPress={() =>
                    handleFieldChange(!financialInfo.transfer, 'transfer')
                  }
                />
              </View>
              <View style={styles.checkboxWithIcon}>
                <Image
                  source={stripeLogo}
                  style={{ width: 96, height: 96 }}
                  resizeMethod='scale'
                  resizeMode='contain'
                />
                <Text> </Text>
                <Checkbox
                  checked={financialInfo.stripe}
                  onPress={() =>
                    handleFieldChange(!financialInfo.stripe, 'stripe')
                  }
                />
              </View>
            </View>
*/

/* <View style={styles.paymentContainer}>
              <View style={styles.checkboxWithIcon}>
                {/* <Icons name="ios-add-circle" /> */
