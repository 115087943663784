import React from 'react'
import {
  View,
} from 'react-native'

import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import UploadButton from '../simple/UploadButton'

class AddButton extends React.Component {
  render() {
    const { style, onChange } = this.props
    return (
      <UploadButton onChange={onChange}>
        <View style={styles.container}>
          <Icons name='md-add' size={30} color={colors.chatButtons} />
        </View>
      </UploadButton>
    )
  }
}

export default AddButton

const styles = stylus({
  container: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    ios: {
      paddingBottom: 6.5,
    },
  },
})
