import React from 'react'
import {
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  KeyboardAvoidingView,
} from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import { stylus } from '../../config/styles'
import Button from '../../components/simple/Button'
import ElegantInput from '../../components/simple/ElegantInput'
import { _get, connect, graphql } from '../../config/connected'
import schema from '../../schema/admin'
import userSchema from '../../schema/user'
import colors from '../../config/colors'
import i18n from 'i18n-js'
import MobileBackButton from '../../components/simple/MobileBackButton'
import NamedImage from '../../components/simple/NamedImage'
import sizes from '../../config/sizes'
import branch from '../../config/branch'
import { OBJECT_STATUSES } from '../../config/constants'
import _ from 'lodash'
import { ANALYTICS_SHOP_STATUS_CHANGED } from '../../reducers/analytics'
import PropTypes from 'prop-types'

const mapStateToProps = (state) => ({
  currUserId: state.currentUser.id,
})

@connect(
  mapStateToProps,
  (dispatch) => ({
    analyticsShopStatusChanged: (shopId, oldStatus, newStatus) => {
      dispatch({
        type: ANALYTICS_SHOP_STATUS_CHANGED,
        data: {
          shopId,
          oldStatus,
          newStatus,
        },
      })
    },
  }),
)
@graphql(schema.mutations.updateShopStatus, {
  name: 'updateShopStatus',
})
@graphql(userSchema.queries.getShop, {
  options: (props) => ({
    variables: {
      id: _get(props, 'navigation.state.params.id', ''),
    },
  }),
  props: ({ ownProps, data: { loading, userById, refetch } }) => ({
    ...ownProps,
    shop: userById,
    refetchShop: refetch,
    shopLoading: loading,
  }),
})
@graphql(schema.queries.getMessage, {
  options: (props) => ({
    skip: !_get(props, 'navigation.state.params.messageId', false),
    variables: {
      id: props.navigation.state.params.messageId,
    },
  }),
  props: ({ ownProps, data: { loading, findOneAdminMessage } }) => ({
    ...ownProps,
    messageLoading: loading,
    message: _get(findOneAdminMessage, 'message', ''),
  }),
})
@graphql(schema.mutations.createMessage, { name: 'createMessage' })
class ShopReviewFeedback extends React.Component {
  getSnapshotBeforeUpdate(prevProps) {
    if (!prevProps.message && this.props.message) {
      return this.props.message
    }
    return ''
  }

  componentDidUpdate(pProps, pState, snap) {
    if (snap.length) {
      this.setState({ message: snap })
    }
  }

  state = {
    message: '',
    submitDisabled: false,
  }

  onChange = (data) => {
    this.setState({
      message: data,
    })
  }

  goToEditShop = () => {
    const {
      navigation: { dispatch },
    } = this.props
    const { shop } = this.props
    // NavigationActions.back()

    this.setState({ submitDisabled: false })
    NavigationActions.navigate({
      routeName: 'EditShop',
      params: {
        // ...navigation.state.params,
        id: shop.id,
        shop,
        refreshShopData: this.props.refetchShop,
        reApply: true,
      },
    })
  }

  onSubmit = () => {
    const {
      navigation: { dispatch },
    } = this.props

    this.setState({ submitDisabled: true })
    try {
      const shopId = this.props.navigation.state.params.id
      this.props
        .createMessage({
          variables: {
            message: this.state.message,
            userId: _get(
              this.props,
              'shop.producerDetails.ownership.userId',
              null,
            ),
            shopId,
          },
        })
        .then((message) => {
          this.props
            .updateShopStatus({
              variables: {
                status: OBJECT_STATUSES.disabled,
                id: this.props.navigation.state.params.id,
                messageId: message.data.createAdminMessage.id,
              },
            })
            .then(() => {
              const oldStatus = _.get(this.props, 'shop.status')
              const newStatus = OBJECT_STATUSES.disabled
              this.props.analyticsShopStatusChanged(shopId, oldStatus, newStatus)
              NavigationActions.back()
              // this.setState({ submitDisabled: false })
            })
        })
    } catch (e) {
      this.setState({ error: e.message })
      setTimeout(() => {
        this.setState({ error: undefined })
      }, 10000)
    }
  }

  render() {
    const asViewer = !!_get(
      this.props,
      'navigation.state.params.messageId',
      false,
    )

    return (
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={branch({ android: null, other: 'padding' })}
        keyboardVerticalOffset={
          branch({
            other: sizes.tabBarHeight,
            iphonex: sizes.iphonexTabBarHeight,
          }) + 8
        }
      >
        <ScrollView
          style={styles.container}
          keyboardShouldPersistTaps='handled'
          contentContainerStyle={{
            paddingBottom: branch({
              iphonex: sizes.iphonexTabBarHeight + sizes.iosStatusBarHeight,
              other: sizes.tabBarHeight,
            }),
          }}
        >
          <Text style={styles.shopTitle}>
            {_get(this.props, 'shop.displayName', '...')}
          </Text>
          {asViewer ? (
            <>
              <Text
                style={{
                  alignSelf: 'center',
                  color: colors.text.mainLighter,
                  paddingTop: 6,
                }}
              >
                {i18n.t('admin.shopManagement.shopReview.messageFromAdmins')}
              </Text>

              <Text style={styles.messageBody}>
                {_get(this.props, 'message', '')}
              </Text>
              <Text
                style={{
                  alignSelf: 'center',
                  color: colors.text.mainLighter,
                  paddingBottom: 6,
                }}
              >
                {i18n.t('admin.shopManagement.shopReview.messageEditHint')}
              </Text>
            </>
          ) : (
            <ElegantInput
              multiline
              value={this.state.message}
              onChange={this.onChange}
              label={i18n.t('admin.shopManagement.shopReview.inputTitle')}
              error={_get(this.state, 'error', undefined)}
              scrollEnabled={false}
              maxLength={254}
            />
          )}
          <Button
            disabled={
              (!this.state.message && !asViewer) || this.state.submitDisabled
            }
            style={styles.buttonActive}
            label={
              asViewer
                ? i18n.t('admin.shopManagement.shopReview.editShop')
                : i18n.t('common.submit')
            }
            onPress={asViewer ? this.goToEditShop : this.onSubmit}
          />
        </ScrollView>
      </KeyboardAvoidingView>
    )
  }
}

ShopReviewFeedback.propTypes = {
  updateShopStatus: PropTypes.func,
  refetchShop: PropTypes.func,
  navigation: PropTypes.any,
  message: PropTypes.any,
}

export default ShopReviewFeedback

ShopReviewFeedback.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('admin.shopManagement.shopReview.title'),
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

const styles = stylus({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  header: {},
  textInput: {},
  messageBody: {
    alignSelf: 'center',
    fontSize: 20,
    color: colors.text.main,
    paddingVertical: 18,
  },
  buttonActive: {
    backgroundColor: colors.primary,
  },
  shopTitle: {
    fontSize: 20,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
})
