import React, { Component } from 'react'
import { connect, graphql, withApollo, compose, gql } from '../config/connected'

import schema from '../schema/post'

const WithPostActions = (Composed) =>
  (class extends Component {
    render() {
      return (
        <Composed
          {...this.props}
          likePost={this.likePost}
          unlikePost={this.unlikePost}
          savePost={this.savePost}
          unsavePost={this.unsavePost}
        />
      )
    }
    likePost = async (post) => {
      const result = await this.props.likePost({
        variables: { postId: post.id },
        optimisticResponse: {
          __typename: 'Mutation',
          likePost: {
            ...post,
            iLike: true,
            likeCount: post.likeCount + 1,
          },
        },
      })
    }
    unlikePost = async (post) => {
      const result = await this.props.unlikePost({
        variables: { postId: post.id },
        optimisticResponse: {
          __typename: 'Mutation',
          unlikePost: {
            ...post,
            iLike: false,
            likeCount: post.likeCount - 1,
          },
        },
      })
    }
    savePost = async (post) => {
      const result = await this.props.savePost({
        variables: { postId: post.id },
        optimisticResponse: {
          __typename: 'Mutation',
          savePost: {
            ...post,
            iSave: true,
          },
        },
      })
    }
    unsavePost = async (post) => {
      const result = await this.props.unsavePost({
        variables: { postId: post.id },
        optimisticResponse: {
          __typename: 'Mutation',
          unsavePost: {
            ...post,
            iSave: false,
          },
        },
      })
    }
  })

export default (Composed) =>
  compose(
    graphql(schema.mutations.likePost, {
      name: 'likePost',
    }),
    graphql(schema.mutations.unlikePost, {
      name: 'unlikePost',
    }),
    graphql(schema.mutations.savePost, {
      name: 'savePost',
    }),
    graphql(schema.mutations.unsavePost, {
      name: 'unsavePost',
    }),
    WithPostActions,
  )(Composed)
