import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import MobileBackButton from '../../components/simple/MobileBackButton'
import { Status } from '../../components/simple/Status'
import ProfileBanner from '../../components/simple/ProfileBanner'
import Scroller from '../../components/Scroller'

import NavigationActions from '../../utility/navigationActions'
import { graphql, connect } from '../../config/connected'
import schema from './schema'
import i18n from 'i18n-js'
import { isArray } from '../../config/helpers'

import sizes from '../../config/sizes'
import colors from '../../config/colors'
import { stylus } from '../../config/styles'

@graphql(schema.queries.postLikers, {
  name: 'postLikersHandler',
  options({ navigation }) {
    return {
      fetchPolicy: 'cache-and-network',
      variables: { postId: navigation.state.params.id },
    }
  },
})
@connect(
  ({ screenInfo }) => ({ screenInfo }),
  (dispatch) => ({
    goToProfile: (profile) => {
      NavigationActions.navigate({
        routeName: 'Profile',
        params: { id: profile.id, pageTitle: profile.username },
      })
    },
  }),
)
class PostLikesViewer extends React.Component {
  getLikers() {
    const { postLikersHandler } = this.props
    if (postLikersHandler.loading) {
      return <ActivityIndicator size='large' style={{ paddingVertical: 60 }} />
    }
    const likers = postLikersHandler.postLikers

    if (!likers.length) {
      return <Status message={i18n.t('post.likesViewer.none')} />
    }
    return likers
  }

  renderLikers() {
    const likers = this.getLikers()
    if (!isArray(likers)) {
      return likers // likers is instead a component
    }
    return likers.map((liker) => (
      <ProfileBanner
        key={liker.id}
        profile={liker}
        profileOnPress={this.props.goToProfile}
      />
    ))
  }

  render() {
    return (
      <Scroller style={[styles.likersContainer]}>
        {this.renderLikers()}
      </Scroller>
    )
  }
}

export default PostLikesViewer

PostLikesViewer.navigationOptions = (props) => {
  return {
    headerTitle: 'Likes',
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

const styles = stylus({
  likersContainer: {
    paddingTop: 10,
  },
})
