import React from 'react'
import { View, Text, } from 'react-native'
import colors from '../../config/colors'

export function Status({ message, style = {} }) { 
  return (
    <View style={[{ paddingVertical: 20, backgroundColor: colors.emptyArea }, style]}>
      <Text
        style={{
          alignSelf: 'center',
          fontWeight: '500',
          color: colors.input.text,
          textAlign: 'center'
        }}
      >
        { message }
      </Text>
    </View>
  )
}
