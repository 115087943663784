import { PixelRatio, Platform } from 'react-native'

export default {
  tabBarHeight: 45,
  iphonexTabBarHeight: 80,
  iosStatusBarHeight: 24, 
  maxFeedWidth: 560,
  skinnyBorder: 1 / PixelRatio.get(),
  px: 1 / PixelRatio.get(),
  PHONE_WIDTH: 720,
  TABLET_WIDTH: 720,
  DESKTOP_WIDTH: 1080,
  CONTAINER_WIDTH: 1200,
  LEFT_BAR_WIDTH: 0.23,
  CONTENT_WIDTH: 560,
  MINIMUM_PADDING: 30,
  TOP_BAR_HEIGHT: 56,
}
