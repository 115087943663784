import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import NavigationActions from '../../../utility/navigationActions'

import NamedImage from '../../../components/simple/NamedImage'
import Ratings from '../../../components/simple/Ratings'
import TextInputFB from '../../../components/simple/TextInput'
import Button from '../../../components/simple/Button'

import { getPrice, isNumber, resolveVideoThumbnail, unitPlural } from '../../../config/helpers'
import colors from '../../../config/colors'
import { graphql, connect } from '../../../config/connected'
import schema from '../../../schema/cart'
import checkoutSessionsSchema from '../../../schema/checkoutSession'
import { updateCachedQueryValue } from '../../../utility/apollo'
import i18n from 'i18n-js'

import _ from 'lodash'
import { ANALYTICS_CARTS_WITH_PRODUCTS_CLOSED, ANALYTICS_REMOVED_FROM_CART_EVENT } from '../../../reducers/analytics'

const mapStateToProps = (state, ownProps) => {
  const {
    cartItem: { id },
  } = ownProps
  return {
    screenInfo: state.screenInfo,
  }
}
const mapDispatchToProps = (dispatch) => ({
  goToProduct: (id) => NavigationActions.navigate({ routeName: 'Product', params: { id } }),
  analyticsRemovedFromCart: (productId) => {
    dispatch({
      type: ANALYTICS_REMOVED_FROM_CART_EVENT,
      data: {
        productId,
      },
    })
  },
  analyticsCartsWithProductsClosed: () => {
    process.nextTick(()=>{
      dispatch({
        type: ANALYTICS_CARTS_WITH_PRODUCTS_CLOSED,
        data: {
          closeReason: 'REMOVED_FROM_CART_EVENT',
        },
      })
    })
  },
})

@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@graphql(schema.mutations.deleteCartItem, {
  name: 'deleteCartItem',
})
@graphql(checkoutSessionsSchema.queries.myCheckoutSession, {
  name: 'myCheckoutSessionHandler',
})
@graphql(schema.mutations.saveCartItemForLater, {
  name: 'saveCartItemForLater',
})
@graphql(schema.mutations.updateCartItemQuantity, {
  name: 'updateCartItemQuantity',
})
class CartProduct extends React.Component {
  static propTypes = {
    cartItem: PropTypes.object.isRequired,
    productKey: PropTypes.string.isRequired,
    quantityOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        displayValue: PropTypes.string,
      }),
    ),
    actionsEnabled: PropTypes.bool,
    onAction: PropTypes.func,
    navigateOnImage: PropTypes.bool,
    showQuantity: PropTypes.bool,
    showRatings: PropTypes.bool,
    analyticsRemovedFromCart: PropTypes.func,
    analyticsCartsWithProductsClosed: PropTypes.func,
    updateCartItemQuantity: PropTypes.func,
  }

  constructor(props) {
    super(props)
    const { cartItem } = props
    const { quantity } = cartItem
    this.debouncedUpdateCartItemQuantity = _.debounce(
      this.props.updateCartItemQuantity,
      300,
      { trailing: true, leading: false },
    )
    this.state = { quantity: `${quantity}` }
  }

  componentDidUpdate(prevProps, prevState) {
    const { cartItem: { quantity: currentQuantity } } = this.props
    const { cartItem: { quantity: previousQuantity } } = prevProps
    if (currentQuantity !== previousQuantity) {
      const quantity = currentQuantity === 0 ? '' : `${currentQuantity}`
      this.setState({ quantity })
    }
  }


  image() {
    const { screenInfo, navigateOnImage, productKey } = this.props
    const maxImageWidth = screenInfo.contentWidth * 0.25
    const fileUpload = _.get(this, `props.cartItem.${productKey}.uploads.0`, {})
    const fileUploadName = _.get(fileUpload, 'name', 'placeholder')
    const width = maxImageWidth - 10
    const height = width
    const isVideoCover = (_.invoke(fileUploadName, 'includes', '.mp4') || _.invoke(fileUploadName, 'includes', '.mov')) || false
    const Image = (
      <View
        style={[
          styles.imageContainer,
          { height: maxImageWidth, width: maxImageWidth },
        ]}
      >
        <NamedImage
          width={width}
          height={width}
          style={{
            width,
            height,
            borderRadius: width / 2,
          }}
          name={isVideoCover ? resolveVideoThumbnail(fileUploadName) : fileUploadName}
          fit='crop'
          resizeMethod='scale'
          resizeMode='cover'
        />
      </View>
    )
    if (!navigateOnImage) {
      return Image
    }
    return (
      <TouchableOpacity onPress={this.goToProduct}>{Image}</TouchableOpacity>
    )
  }

  goToProduct = () => {
    const {
      cartItem: { productId },
      goToProduct,
    } = this.props
    goToProduct(productId)
  }

  details() {
    const {
      cartItem: {
        [this.props.productKey]: {
          rating,
          ratingCount,
          name,
          price,
          priceDiscount,
        },
        quantity,
      },
      showRatings: showRatingsElement,
      showQuantity,
      children,
    } = this.props
    const { unitType, unitQuantity } = this.props.cartItem.product
    const averageRating = rating ? rating : 0
    const ratings = ratingCount ? ratingCount : 0
    const productDiscounted = !!priceDiscount && priceDiscount < price
    const hasRating = ratings > 0
    const unitProduct = unitPlural({ unitType, unitQuantity })

    return (
      <View style={[styles.detailsContainer]}>
        <Text numberOfLines={1} style={styles.productNameText}>
          {name}
        </Text>
        {!!unitProduct && (
          <Text style={styles.unitTitle}>
            {unitProduct}
          </Text>
        )}
        {showRatingsElement && hasRating && (
          <Ratings
            containerStyle={styles.ratingsContainer}
            size={15}
            rating={averageRating}
            ratings={ratings}
            showRatings
          />
        )}
        <Text style={styles.productPriceText}>
          <Text style={styles.actualPriceText}>
            {productDiscounted
              ? `${getPrice(priceDiscount)} `
              : getPrice(price)}
          </Text>
          {productDiscounted && (
            <Text numberOfLines={1} style={styles.originalPriceText}>
              {getPrice(price)}
            </Text>
          )}
        </Text>
        {showQuantity && (
          <Text style={styles.quantityText}>{`${i18n.t(
            'order.order.quantity',
          )}: ${quantity}`}</Text>
        )}
        {children}
      </View>
    )
  }

  actions() {
    const {
      screenInfo: { contentWidth },
      actionsEnabled,
    } = this.props
    let { quantity } = this.state
    // console.log('<<<CART PRODUCT QUANTITIES', quantity, cartItem.quantity)
    if (!actionsEnabled) {
      return false
    }
    return (
      <View style={styles.productActionsContainer}>
        <View style={[{ width: contentWidth * 0.2 }]}>
          <TextInputFB
            maxLength={3}
            keyboardType='numeric'
            value={quantity}
            style={[styles.searchInputContainer]}
            inputStyle={[styles.searchInput]}
            onChange={this.updateCartItemQuantity}
          />
          {/* TODO if we decide to do an update button
          <Button
            onPress={this.updateCartItemQuantity}
            label={i18n.t('common.update')}
            labelStyle={styles.buttonLabel}
            style={styles.button}
          /> */}
        </View>
        <TouchableOpacity
          style={[styles.buttonActionContainer]}
          onPress={this.deleteCartItem}
        >
          <Text style={styles.actionText}>{i18n.t('order.cart.remove')}</Text>
        </TouchableOpacity>
        {/* <TouchableOpacity
          style={[styles.buttonActionContainer]}
          onPress={this.saveCartItem}
        >
          <Text style={styles.actionText}>
            {i18n.t('order.cart.saveForLater')}
          </Text>
        </TouchableOpacity> */}
      </View>
    )
  }

  render() {
    const { style } = this.props
    return (
      <View style={style}>
        <View style={styles.productDetailsContainer}>
          {/* Product Details */}
          {this.image()}
          {this.details()}
        </View>
        {/* Product Actions */}
        {this.actions()}
      </View>
    )
  }

  onAction = () => {
    if (this.props.onAction) {
      this.props.onAction()
    }
  }

  deleteCartItem = async () => {
    const id = this.props.cartItem.id
    const variables = { id }
    await this.props.deleteCartItem({
      variables,
      update: this.removeCartItemFromCache,
    })
    this.props.myCheckoutSessionHandler.refetch()
    this.props.analyticsRemovedFromCart(id)
  }

  saveCartItem = async () => {
    const id = this.props.cartItem.id
    const variables = { id }
    await this.props.saveCartItemForLater({
      variables,
      update: this.removeCartItemFromCache,
    })
  }

  updateCartItemQuantity = async (value) => {
    if (value !== '' && !isFinite(value)) {
      return // We should not persist values that are not numbers
    }
    this.setState({ quantity: value })
    if (!value) {
      value = '0' // default for empty
    }
    const id = this.props.cartItem.id
    const quantity = parseInt(value)
    const variables = { id, quantity }
    await this.debouncedUpdateCartItemQuantity({
      variables,
      update: this.updateCartItemInCache,
    })
  }

  removeCartItemFromCache = (store, { data }) => {
    const id = this.props.cartItem.id
    updateCachedQueryValue(store, {
      query: schema.queries.cartItemsByUserId,
      variables: { userId: this.props.cartItem.userId },
      nextValue: (cartItems = []) => {
        const index = cartItems.findIndex((cartItem) => cartItem.id === id)
        cartItems.splice(index, 1)
        if (_.isEmpty(cartItems)) {
          this.props.analyticsCartsWithProductsClosed()
        }
        return cartItems
      },
    })
    this.onAction()
  }

  updateCartItemInCache = (store, { data }) => {
    const { id, quantity } = data.updateCartItemQuantity
    // console.log('<<<CART ITEM - Updating cache with quantity from server', quantity)
    updateCachedQueryValue(store, {
      query: schema.queries.cartItemsByUserId,
      variables: { userId: this.props.cartItem.userId },
      nextValue: (cartItems = []) => {
        const cartItem = cartItems.find((cartItem) => cartItem.id === id)
        cartItem.quantity = quantity
        return cartItems
      },
    })
    this.onAction()
  }
}

export default CartProduct

const styles = StyleSheet.create({
  productDetailsContainer: {
    paddingHorizontal: 20,
    flex: 1,
    flexDirection: 'row',
  },
  imageContainer: {
    borderWidth: 1,
    padding: 4,
    borderColor: '#CCC',
    overflow: 'hidden',
    borderRadius: 9999,
  },
  detailsContainer: {
    paddingLeft: 20,
    flex: 1,
    justifyContent: 'center',
  },
  productNameText: {
    color: colors.text.main,
    fontSize: 15,
    fontWeight: '500',
    marginBottom: 2,
  },
  unitTitle: {
    fontSize: 13,
    marginBottom: 2,
  },
  ratingsContainer: {
    marginBottom: 5,
  },
  productPriceText: {
    marginBottom: 5,
  },
  quantityText: {
    color: colors.text.main,
    fontSize: 15,
    marginBottom: 5,
  },
  actualPriceText: {
    color: 'red',
    fontSize: 15,
    fontWeight: '500',
  },
  originalPriceText: {
    color: colors.text.soft,
    fontSize: 14,
    textDecorationLine: 'line-through',
  },
  productActionsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 20,
  },
  searchInputContainer: {
    backgroundColor: '#F7F7F7',
  },
  searchInput: {
    height: 40,
    fontSize: 15,
    textAlign: 'left',
    color: colors.text.main,
  },
  button: {
    backgroundColor: colors.primary,
    marginBottom: 5,
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  buttonActionContainer: {
    backgroundColor: '#F7F7F7',
    justifyContent: 'center',
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  actionText: {
    color: colors.text.main,
    fontSize: 15,
  },
})
