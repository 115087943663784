import React, { Component } from 'react'
import {
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  ActivityIndicator,
  Platform,
} from 'react-native'
import PropTypes from 'prop-types'
import NamedImage from '../../components/simple/NamedImage'
import ElegantInput from '../../components/simple/ElegantInput'
import Button from '../../components/simple/Button'
import Divider from '../../components/simple/Divider'
import ShopProfileBanner from '../../components/Shop/ShopProfileBanner'
import Icons from '@expo/vector-icons/Ionicons'
import MobileBackButton from '../../components/simple/MobileBackButton'

import NavigationActions from '../../utility/navigationActions'
import { mergeNavOptionsForFeedLogo, getKeyboardPaddingStyle } from '../../config/helpers'
import withKeyboardInfo from '../../containers/withKeyboardInfo'
import withSetProps from '../../containers/withSetProps'

import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import sizes from '../../config/sizes'

import { graphql, connect, _get } from '../../config/connected'
import schema from '../../schema/reviews'
import productSchema from '../../schema/product'
import userSchema from '../../schema/user'

import { REVIEW_OBJECT_TYPE_PRODUCT } from '../../config/constants'

import i18n from 'i18n-js'
import _ from 'lodash'
import produce from 'immer'
import { updateCachedQueryValue } from '../../utility/apollo'
@connect((state) => ({
  screenInfo: state.screenInfo,
}))
@withKeyboardInfo
@withSetProps
@graphql(schema.queries.userReview, {
  name: 'userReviewHandler',
  options({ navigation }) {
    const { objectType, objectId, userId } = navigation.state.params
    return { variables: { userId, objectType, objectId } }
  },
})
@graphql(schema.queries.productById, {
  name: 'productHandler',
  skip: ({ navigation }) =>
    navigation.state.params.objectType !== REVIEW_OBJECT_TYPE_PRODUCT,
  options(props) {
    return {
      variables: {
        id: props.navigation.state.params.objectId,
      },
    }
  },
})
@graphql(schema.mutations.createReview, {
  name: 'createReview',
})
@graphql(schema.mutations.updateReview, {
  name: 'updateReview',
})
@graphql(userSchema.queries.getShop, {
  name: 'refetchShop',
  skip: (props) => !props.fetchUpdatedRatingInfo,
  options: (props) => ({
    fetchPolicy: 'network-only',
    variables: {
      id: _.get(props, 'fetchUpdatedRatingInfo.shopId', ''),
    },
  }),
})
@graphql(productSchema.queries.productById, {
  name: 'refetchProduct',
  skip: (props) => {
    console.log('SHOULD REFETCH PRODUCT?', !!props.fetchUpdatedRatingInfo)
    return !props.fetchUpdatedRatingInfo
  },
  options: (props) => {
    return {
      fetchPolicy: 'network-only',
      variables: { id: _.get(props, 'fetchUpdatedRatingInfo.productId', '') },
    }
  },
})
class Review extends Component {

  constructor(props) {
    super(props)
    this.state = {
      content: { tags: [], value: ''},
      rating: 0,
      buttonDisabled: false,
      reviewLoaded: false,
    }
  }

  static propTypes = {
    userReviewHandler: PropTypes.object.isRequired,
    createReview: PropTypes.func.isRequired,
    updateReview: PropTypes.func.isRequired,
    productHandler: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.populateForm()
  }

  componentDidUpdate(prevProps, prevState) {
    this.populateForm()
    const { fetchUpdatedRatingInfo, refetchShop, refetchProduct } = this.props
    if (this.state.goneBack || !refetchShop || !refetchProduct || !fetchUpdatedRatingInfo) {
      return
    }
    this.setState({ goneBack: true })
    NavigationActions.back()
  }

  populateForm = () => {
    const nextState = { ...this.state }
    if (
      nextState.reviewLoaded === false &&
      this.props.userReviewHandler.loading === false &&
      this.props.userReviewHandler.userReview
    ) {
      const { content, rating } = this.props.userReviewHandler.userReview
      nextState.content = content
      nextState.rating = rating
      nextState.reviewLoaded = true
      this.setState(nextState)
    }
  }

  handleFieldChange = (value, target) => {
    const nextState = (state, props) => {
      return produce(state, draft => {
        _.set(draft, target, value)
      })
    }
    this.setState(nextState)
  }

  getShop = () => {
    const { productHandler } = this.props
    return _get(productHandler, 'productById.profile', null)
  }

  getProduct = () => {
    return this.props.productHandler.productById
  }

  renderRating = () => {
    const { rating } = this.state
    let elements = []

    for (let i = 0; i < 5; i++) {
      elements.push(
        <TouchableOpacity
          key={i}
          onPress={() => {
            this.setState({ rating: i + 1 })
          }}
        >
          <Icons
            name={rating >= i + 1 ? 'ios-star' : 'ios-star-outline'}
            color={colors.primary}
            size={50}
          />
        </TouchableOpacity>,
      )
    }

    return elements
  }

  handleSubmit = async () => {
    this.setState({ buttonDisabled: true })
    const { navigation, userReviewHandler, apollo } = this.props
    const { objectType, objectId, userId } = navigation.state.params

    const { rating, content } = this.state
    const saveReviewKey =
      userReviewHandler.userReview !== null ? 'updateReview' : 'createReview'
    const saveReview = this.props[saveReviewKey]
    const variables = {
      content,
      rating: parseInt(rating),
    }
    if (saveReviewKey === 'updateReview') {
      variables.id = userReviewHandler.userReview.id
    } else {
      variables.objectType = objectType
      variables.objectId = objectId
    }

    try {
      await saveReview({
        variables,
        update: this.updateRatingInfoInCache(saveReviewKey),
      })
      const shop = this.getShop()
      const product = this.getProduct()
      this.props.setProps({
        fetchUpdatedRatingInfo: {
          shopId: shop.id,
          productId: product.id,
        }
      })
    } catch (e) {
      console.log('<<<SAVE REVIEW ERROR', e)
    }
    this.props.navigation.goBack()
  }

  checkShouldAutoScroll = (event) => {
    const { screenInfo } = this.props
    const fuzziness = 100
    const tabBarHeight = branch({
      iphone: sizes.tabBarHeight,
      iphonex: sizes.iphonexTabBarHeight,
      android: sizes.tabBarHeight,
    })
    const shouldScroll =
      event.nativeEvent.contentOffset.y + // Distance scrolled down.
      screenInfo.height - // Height of the screen
      tabBarHeight - // the current tab bar height
        event.nativeEvent.contentSize.height > // Size of the scroll view
      -fuzziness // hit slop to allow auto scrolling, if we're close to the bottom we should automatically scroll to the end.
    this.setState({ shouldAutoScroll: shouldScroll })
  }

  updateRatingInfoInCache = (dataKey) => {
    const shop = this.getShop()
    const product = this.getProduct()
    return (store, { data }) => {
      const review = data[dataKey]
      const reviewForProduct = {
        ...review,
        product: _.pick(product, ['id', 'name', 'uploads', '__typename']),
        '__typename': 'ProductReview',
      }
      const reviewForShop = {
        ...review,
        product: _.pick(product, [
          '__typename',
          'id',
          'name',
          'rating',
          'ratingCount',
          'uploads',
          'profile',
        ]),
        '__typename': 'ProductReview',
      }
      const { objectId, objectType, userId } = review
      // const shopVariables = getQueryVariables('reviewsByShopId', [shop.id], this.props.apollo)
      // const productVariables = getQueryVariables('productsReviews', [product.id], this.props.apollo)
      // console.log('<<<SHOP VARIABLES', shopVariables)
      // console.log('<<<PRODUCT VARIABLES', productVariables)
      updateCachedQueryValue(store, {
        query: schema.queries.userReview,
        variables: { objectId, objectType, userId },
        nextValue: () => review
      })
      updateCachedQueryValue(store, {
        query: schema.queries.reviewsByShopId,
        variables: { shopId: shop.id, objectType: 'product' },
        nextValue: (reviews) => {
          if (!reviews) {
            return reviews
          }
          return this.updateReviewInPlace(reviews, reviewForShop)
        },
        abortOnError: true,
      })
      updateCachedQueryValue(store, {
        query: schema.queries.productsReviews,
        variables: { productId: product.id },
        nextValue: (reviews) => {
          if (!reviews) {
            return reviews
          }
          return this.updateReviewInPlace(reviews, reviewForProduct)
        },
        abortOnError: true,
      })
    }
  }

  updateReviewInPlace(reviews, updatedReview) {
    const reviewIndex = reviews.findIndex(existingReview => existingReview.id ===  updatedReview.id)
    if (reviewIndex !== -1) {
      reviews.splice(reviewIndex, 1, updatedReview)
    } else {
      reviews.push(updatedReview)
    }
    return reviews
  }

  render() {
    const { productHandler, screenInfo, keyboardInfo } = this.props
    const keyboardPaddingStyle = getKeyboardPaddingStyle(keyboardInfo)

    if (productHandler.loading) {
      return (
        <ActivityIndicator size='large' style={{ paddingVertical: 60 }} />
      )
    }

    const Content = (
      <ScrollView
        style={styles.container}
        keyboardShouldPersistTaps='handled'
        contentContainerStyle={styles.contentContainer}
      >
        <ShopProfileBanner style={styles.shopBanner} shop={this.getShop()} />
        <NamedImage
          style={{
            width: screenInfo.width,
            height: screenInfo.width / 1.65,
          }}
          name={_get(
            productHandler,
            'productById.uploads[0].name',
            'failboat',
          )}
          resizeMode='cover'
          resizeMethod='resize'
          width={screenInfo.width}
          height={screenInfo.width / 1.65}
        />
        <Text style={styles.productTitle}>
          {_get(productHandler, 'productById.name', '')}
        </Text>
        <Divider hidden />
        <View style={{ flexDirection: 'row' }}>{this.renderRating()}</View>
        <ElegantInput
          reference={(ref) => this.textInputRef = ref}
          onChange={(value) => {
            this.handleFieldChange(value, 'content.value')
          }}
          onFocus={this.onContentFocus}
          label={i18n.t('common.review')}
          value={this.state.content.value}
          multiline
        />
        <Button
          disabled={
            this.state.content.length === 0 ||
            this.state.rating === 0 ||
            this.state.buttonDisabled
          }
          label={i18n.t('common.done')}
          style={styles.button}
          onPress={this.handleSubmit}
        />
        <View style={[keyboardPaddingStyle]} />
      </ScrollView>
    )
    return (
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        keyboardVerticalOffset={Platform.OS === 'android' ? null : 16}
        behavior={Platform.OS === 'android' ? 'height' : 'padding'}
      >
        { Content }
      </KeyboardAvoidingView>
    )
  }
}

Review.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('common.review'),
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

const styles = stylus({
  container: {
    flex: 1,
    iphonex: {
      marginBottom: sizes.iphonexTabBarHeight,
    },
    native: {
      marginBottom: sizes.tabBarHeight,
    },
  },
  contentContainer: {
    paddingHorizontal: 10,
  },
  button: {
    width: '100%',
    backgroundColor: colors.primary,
  },
  productTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    margin: 10,
  },
  shopBanner: {
    marginVertical: 10,
  },
})
export default Review
