import React from 'react'
import { FlatList, Platform, Text, View, ActivityIndicator } from 'react-native'

import Scroller from '../../components/Scroller'
import MobileBackButton from '../../components/simple/MobileBackButton'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'
import SettingsButton from './SettingsButton'
import NavigationActions from '../../utility/navigationActions'
import TabLabel from '../../components/simple/TabLabel'

import { _get, compose, connect, graphql } from '../../config/connected'

import PostFB from '../../components/PostFB'
import PostRecipe from '../../components/PostFB/PostRecipe'
import Review from '../../components/Review'
import { ProductListView } from '../../components/Product'

import _ from 'lodash'
import i18n from 'i18n-js'

import userSchema from '../../schema/user'
import postSchema from '../../schema/post'
import reviewSchema from '../../schema/reviews'
import orderSchema from '../../schema/orders'

import TopInstaStyle from './TopInstaStyle'
import {
  PROFILE_REFRESH_DONE,
  PROFILE_SET_REFRESH_FUNCTION,
} from '../../reducers/profile'
import { mergeNavOptionsForFeedLogo, getPendingContent } from '../../config/helpers'
import NewChat from '../FeedFB/NewChat'
import PropTypes from 'prop-types'
import conversationSchema from '../../schema/conversation'
import withPreventDoubleClick from '../../containers/withPreventDoubleClick'
import { ROUTE_REPORT_CONTENT_USER_PROFILE } from '../../config/constants'
import { stopAllVideos } from '../../utility/video'
import { getLayout } from '../../routing/selectLayout'

const INITIAL_PAGE_SIZE = 4
const INITIAL_OFFSET = 0
const SUBSEQUENT_PAGE_SIZE = 4

const DATA_KEYS = {
  posts: 'postsByUser',
  recipes: 'recipesByUser',
  reviews: 'reviewsByUser',
  purchases: 'profileOrderItems',
}

const QUERRY_MAPPING = {
  posts: postSchema.queries.postsByUser,
  recipes: postSchema.queries.recipesByUser,
  reviews: reviewSchema.queries.reviewsByUser,
  purchases: orderSchema.queries.profileOrderItems,
}

let navigateToChat = () => {}

@graphql(userSchema.queries.user, {
  name: 'user',
  options: (props) => ({
    variables: {
      id: _get(props, 'navigation.state.params.id', props.currentUser.id),
    },
  }),
})
@graphql(postSchema.queries.postsByUser, {
  name: 'postsByUser',
  props: (props) => {
    if (props.postsByUser.error) {
      console.warn(
        'Profile.js: ',
        _get(props, 'postsByUser.error.message', 'Unable to retrieve error.'),
      )
    }
    return props
  },
  options: (props) => ({
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: _get(props, 'navigation.state.params.id', props.currentUser.id),
      limit: INITIAL_PAGE_SIZE,
      offset: INITIAL_OFFSET,
    },
  }),
})
@graphql(postSchema.queries.recipesByUser, {
  name: 'recipesByUser',
  props: (props) => {
    if (props.recipesByUser.error) {
      console.warn(
        'Profile.js: ',
        _get(props, 'recipesByUser.error.message', 'Unable to retrieve error.'),
      )
    }
    return props
  },
  options: (props) => ({
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: _get(props, 'navigation.state.params.id', props.currentUser.id),
      limit: INITIAL_PAGE_SIZE,
      offset: INITIAL_OFFSET,
    },
  }),
})
@graphql(reviewSchema.queries.reviewsByUser, {
  name: 'reviewsByUser',
  props: (props) => {
    if (props.reviewsByUser.error) {
      console.warn('Profile.js: ', props.reviewsByUser.error.message)
    }
    return props
  },
  options: (props) => ({
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: _get(props, 'navigation.state.params.id', props.currentUser.id),
      limit: INITIAL_PAGE_SIZE,
      offset: INITIAL_OFFSET,
    },
  }),
})
@graphql(orderSchema.queries.profileOrderItems, {
  name: 'profileOrderItems',
  props: (props) => {
    if (props.profileOrderItems.error) {
      console.warn('Profile.js: ', props.profileOrderItems.error.message)
    }
    return props
  },
  options: (props) => ({
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: _get(props, 'navigation.state.params.id', props.currentUser.id),
      limit: INITIAL_PAGE_SIZE,
      offset: INITIAL_OFFSET,
    },
  }),
})
class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
  }
  state = {
    page: 'posts',
    posts: {
      noMore: false,
      limit: INITIAL_PAGE_SIZE,
      offset: INITIAL_OFFSET,
    },
    recipes: {
      noMore: false,
      limit: INITIAL_PAGE_SIZE,
      offset: INITIAL_OFFSET,
    },
    reviews: {
      noMore: false,
      limit: INITIAL_PAGE_SIZE,
      offset: INITIAL_OFFSET,
    },
    purchases: {
      noMore: false,
      limit: INITIAL_PAGE_SIZE,
      offset: INITIAL_OFFSET,
    },
    currentPostItem: 0
  }

  render() {
    return (
      <View style={styles.container}>
        {Platform.OS === 'web' ? this.renderWebList() : this.renderNativeList()}
      </View>
    )
  }

  UNSAFE_componentWillMount() {
    navigateToChat = this._navigateToChat
  }

  componentDidMount() {
    if (getLayout() !== 'web/portrait') {
      this.willBlurListener = this.props.navigation.addListener('willBlur', () => stopAllVideos(this.props.videos))
    }
    this.setPage('posts')
    // alert('mounted')
    this.props.setProfileRefreshFunction(this.refreshList)
  }

  componentWillUnmount() {
    this.willBlurListener && this.willBlurListener.remove()
  }

  componentDidUpdate(prevProps, prevState) {
    const { profile, dispatch } = this.props
    for (const page in profile.refresh) {
      const refresh = profile.refresh[page]
      if (refresh) {
        // console.log('REFRESHING PAGE', page)
        dispatch({
          type: PROFILE_REFRESH_DONE,
          page,
        })
        this.loadData(page)
        break
      }
    }
  }

  handleScroll ({nativeEvent}) {
    if (nativeEvent) {
      const offsetY = nativeEvent.contentOffset.y
      const screenHeight = nativeEvent.layoutMeasurement.height
      const currentScreen = Math.floor(offsetY / screenHeight)

      if(currentScreen !== this.state.currentPostItem) {
        stopAllVideos(this.props.videos)
        this.setState(prev => ({...prev, currentPostItem: currentScreen}))
      }
    }
  }

  refreshList = () => {
    const { page } = this.state
    this.loadData(page)
  }

  listEmptyRender = () => {
    if (this.isLoading()) return null
    const { page } = this.state
    let target = ''
    DATA_KEYS
    switch (page) {
      case 'posts':
        target = 'emptyPosts'
        break
      case 'recipes':
        target = 'emptyRecipes'
        break
      case 'reviews':
        target = 'emptyReviews'
        break
      case 'purchases':
        target = 'emptyProfileOrderItems'
        break
    }
    return (
      <View style={{ width: '100%', alignItems: 'center', paddingTop: 30 }}>
        <Text style={styles.emptyItems}>{i18n.t(`profile.${target}`)}</Text>
      </View>
    )
  }

  renderItem = (item, refreshing) => {
    const { page } = this.state
    // const { item } = items

    switch (page) {
      case 'posts':
        return <PostFB post={item} key={item.id} refreshing={refreshing} navigation={this.props.navigation}/>
      case 'recipes':
        return <PostRecipe post={item} key={item.id} refreshing={refreshing} navigation={this.prop.navigation}/>
      case 'reviews':
        return <Review review={item} key={item.id} showImage />
      case 'purchases':
        return <ProductListView key={item.id} product={item.product} hideShop />
    }
  }

  getItems = () => {
    const { page } = this.state
    const itemsHandler = this.props[DATA_KEYS[page]] || []
    // if (itemsHandler.loading) {
    //   return []
    // }
    const items = itemsHandler[DATA_KEYS[page]]
    return items || []
  }

  isLoading = (target = this.state.page) => {
    return this.props[DATA_KEYS[target]].networkStatus < 7
  }

  loadMore = () => {
    const { page } = this.state
    const handler = this.props[DATA_KEYS[page]]
    const offset = this.state[page].offset
    const newOffset = offset + SUBSEQUENT_PAGE_SIZE
    const loading = this.isLoading()
    const limit = this.state[page].limit

    if (loading) return
    if (this.state[page].noMore) return
    if (handler[DATA_KEYS[page]].length < offset) {
      this.setState({ [page]: { ...this.state[page], noMore: true } })
      return
    }

    this.setState({
      [page]: { ...this.state[page], offset: newOffset },
    })

    handler.fetchMore({
      variables: {
        offset: newOffset,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return handler[DATA_KEYS[page]]
        // console.log(fetchMoreResult)

        const newData = _.uniqBy(
          _.union(prev[DATA_KEYS[page]], fetchMoreResult[DATA_KEYS[page]]),
          'id',
        )
        return { [DATA_KEYS[page]]: newData }
      },
    })
  }

  isMe = () => {
    const { navigation, currentUser } = this.props
    const { id: profileId } = navigation.state.params || {}
    return !profileId || profileId === currentUser.id
  }

  renderTop = () => {
    const {
      goToPosting,
      goToFollowers,
      goToFollowing,
      screenInfo,
      navigation,
      currentUser,
      user: { loading, user },
      client,
      pendingProfileEdit
    } = this.props
    const { displayName, id: profileId, profileImage } =
      navigation.state.params || {}

    // let cash = {}
    // try {
    //   cash = client.readQuery({query: partialProfileQuery, variables: { id: profileId }})
    // } catch (e) {}
    // const user = loading? cash.user : user
    const isMe = this.isMe()
    const finalName = _.get(user, 'displayName', ' – ')
    const username = _.get(user, 'username', ' – ')
    const bio = _.get(user, 'personDetails.bio')
    // const location = _.get(user, 'location.summary')
    return (
      <View>
        <TopInstaStyle
          user={user}
          loading={false}
          name={finalName}
          username={username}
          bio={bio}
          isMe={isMe}
          currentUser={currentUser}
          screenInfo={screenInfo}
          profileId={profileId}
          profileQuery={this.props.user}
          goToFollowers={this.goToFollowers}
          goToFollowing={this.goToFollowing}
          goToReportContent={this.props.goToReportContent}
          localImage={currentUser.profileImageUpload}
          pendingProfileEdit={pendingProfileEdit}
        />
        {this.pageTabs()}
      </View>
    )
  }

  loadData = (page) => {
    const { navigation, currentUser } = this.props
    const profileId = _.get(navigation, 'state.params.id', currentUser.id)

    if (this.isLoading()) {
      return
    }

    try {
      this.props.user.refetch()

      this.props[DATA_KEYS[page]].refetch({
        userId: profileId,
        limit: INITIAL_PAGE_SIZE,
        offset: INITIAL_OFFSET,
      })

      this.setState({
        page,
        [page]: {
          noMore: false,
          limit: INITIAL_PAGE_SIZE,
          offset: INITIAL_OFFSET,
        },
      })
    } catch (e) {
      console.log('<<<PROFILE - loadData - error', e)
    }

    return
  }

  setPage = (page = 'posts') => {
    // If there are no items in any given query, try to load more when we switch pages.
    if (
      _get(
        this.props,
        `this.props.${[DATA_KEYS[page]]}.${[DATA_KEYS[page]]}`,
        [],
      ).length === 0
    ) {
      this.loadData(page)
    }

    this.setState({
      page,
    })
  }

  pageTabs() {
    const { page } = this.state

    return (
      <View
        style={[
          styles.pageTabs,
          this.isMe()
            ? {}
            : {
              justifyContent: 'space-around',
            },
        ]}
      >
        <TabLabel
          label={i18n.t('product.tabLabels.posts')}
          active={page === 'posts'}
          onPress={() => this.setPage('posts')}
          style={styles.tabLabel}
        />
        <TabLabel
          label={i18n.t('product.tabLabels.recipes')}
          active={page === 'recipes'}
          onPress={() => this.setPage('recipes')}
          style={styles.tabLabel}
        />
        <TabLabel
          label={i18n.t('product.tabLabels.reviews')}
          active={page === 'reviews'}
          onPress={() => this.setPage('reviews')}
          style={styles.tabLabel}
        />
      </View>
    )
  }

  renderFooter = () => {
    const loading = this.isLoading()

    return (
      <View style={{ paddingTop: 20, height: 32 }}>
        {loading ? <ActivityIndicator size='large'></ActivityIndicator> : null}
      </View>
    )
  }

  keyExtractor = (item, index) => item.id

  renderNativeList() {
    const {
      screenInfo: {
        paddingLeft,
        paddingRight,
        gutterWidth,
        topBarHeight,
        contentTopPadding,
      },
    } = this.props
    const { page } = this.state
    const refreshing = this.isLoading()

    return (
      <FlatList
        onScroll={this.handleScroll}
        scrollEventThrottle={160}
        contentContainerStyle={styles.listContainerStyle}
        refreshing={false}
        // refreshing={refreshing} I have no idea why, but this is broken as all hell.
        onRefresh={() => this.loadData(page)}
        renderItem={({item}) => this.renderItem(item, refreshing)}
        data={this.getItems()}
        style={{
          flex: 1,
          paddingLeft,
          paddingRight,
          marginTop: topBarHeight,
          marginBottom: sizes.tabBarHeight,
          paddingTop: contentTopPadding,
          paddingBottom: 190,
        }}
        ListHeaderComponent={this.renderTop}
        ListFooterComponent={this.renderFooter}
        ListEmptyComponent={this.listEmptyRender()}
        keyExtractor={this.keyExtractor}
        onEndReachedThreshold={0.9}
        onEndReached={this.loadMore}
        initialNumToRender={4}
      />
    )
  }

  renderWebList() {
    const layout = _.get(this.props, 'screenInfo.layout')
    return (
      <Scroller loadMore={this.loadMore}>
        <View style={styles.feedPadding} />
        {this.renderTop()}
        {this.getItems().map((item) => this.renderItem(item, this.isLoading()))}
      </Scroller>
    )
  }

  me() {
    return (
      _.get(this.props, 'navigation.state.params.id') ===
      this.props.currentUser.id
    )
  }

  goToFollowing = () => {
    const id =
      _.get(this.props, 'navigation.state.params.id') ||
      this.props.currentUser.id
    this.props.goToFollowing({ id })
  }

  goToFollowers = () => {
    const id =
      _.get(this.props, 'navigation.state.params.id') ||
      this.props.currentUser.id
    this.props.goToFollowers({ id })
  }

  _navigateToChat = async () => {
    const { id: profileId } = this.props.navigation.state.params || {}

    const currentUserId = this.props.currentUser.id
    const result = await this.props.startPrivateConversation({
      variables: {
        userIds: [currentUserId, profileId],
      },
    })
    const membershipId = _.get(result, 'data.startPrivateConversation.id')
    const conversationId = _.get(
      result,
      'data.startPrivateConversation.conversation.id',
    )
    const memberships = _.get(
      result,
      'data.startPrivateConversation.conversation.memberships',
    )
    const users = _.map(memberships, 'user')
    NavigationActions.navigate({
      routeName: 'Conversation',
      params: {
        id: membershipId,
        cuid: currentUserId,
        users,
      },
    })
  }
}

const NewChatButton = withPreventDoubleClick(NewChat)

Profile.navigationOptions = (props) => {
  const { navigation } = props
  const params = _.get(navigation, 'state.params') || {}
  const id = _.get(params, 'id')
  if (id) {
    return {
      headerTitle: params.pageTitle,
      headerLeft: () => (
        <MobileBackButton
          label='Back'
          onPress={() => NavigationActions.back()}
        />
      ),
      headerRight: () => (<NewChatButton onPress={navigateToChat} />),
    }
  } else {
    // it is my own profile
    return {
      headerTitle: i18n.t('profile.myProfileHeader'),
      headerRight: () => (
        <SettingsButton
          onPress={() =>
            NavigationActions.navigate({ routeName: 'Settings' })
          }
        />
      ),
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log('<<<PROFILE - currentUser', state.currentUser)
  return {
    screenInfo: state.screenInfo,
    currentUser: state.currentUser,
    profile: state.profile,
    videos: state.video,
    pendingProfileEdit: getPendingContent(state.publishContent, 'user', state.currentUser.id, 'userId')
  }
}

const mapDispatchToProps = (dispatch) => ({
  goToFollowing: ({ id }) =>  NavigationActions.navigate({ routeName: 'Following', params: { id } }),
  goToFollowers: ({ id }) => NavigationActions.navigate({ routeName: 'Followers', params: { id } }),
  goToReportContent: ({ id }) => {
    NavigationActions.navigate({
      routeName: ROUTE_REPORT_CONTENT_USER_PROFILE,
      params: { objectId: id },
    })
  },
  dispatch,
  setProfileRefreshFunction: (func) => {
    dispatch({
      type: PROFILE_SET_REFRESH_FUNCTION,
      payload: {
        refreshProfile: func,
      },
    })
  },
})

Profile.propTypes = {
  startPrivateConversation: PropTypes.func,
  goToChat: PropTypes.func,
  setProfileRefreshFunction: PropTypes.func,
  navigation: PropTypes.object,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(conversationSchema.mutations.startPrivateConversation, {
    name: 'startPrivateConversation',
  }),
)(Profile)

const styles = stylus({
  container: {
    flex: 1,
  },
  listContainerStyle: {
    android: {
      paddingBottom: sizes.tabBarHeight,
    },
    iphonex: {
      paddingBottom: sizes.iphonexTabBarHeight,
    },
  },
  subtitle: {
    fontSize: 14,
    color: colors.text.secondary,
    fontWeight: '400',
  },
  feedSpacer: {
    height: 46,
  },
  pageTabs: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
    marginTop: 12,
  },
  tabLabel: {
    // width: 80,
    // paddingHorizontal: 14,
    flex: 1,
  },
  emptyItems: {
    // backgroundColor: colors.inactive,
    color: colors.text.light,
    fontSize: 22,
    fontWeight: 'bold',
  },
})
