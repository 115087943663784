import React, { Component } from 'react'
import { StyleSheet, Text, View, Image, Platform } from 'react-native'

import { connect } from 'react-redux'
import shared, { stylus } from '../config/styles'
import TopBar from '../routing/landscape/TopBar'
import sizes from '../config/sizes'
import colors from '../config/colors'
import Gradient from '../components/simple/Gradient'

class LandscapeLayout extends React.Component {
  constructor(props) {
    super(props)
  }
  static defaultProps = {
    leftContent: () => {},
    content: () => {},
  }
  render() {
    const { leftBarWidth, contentWidth, topBarHeight } = this.props.screenInfo

    return (
      <View style={styles.outerContainer}>
        <View
          style={[
            styles.content,
            { left: leftBarWidth, right: 0, top: topBarHeight, bottom: 0 },
          ]}
        >
          {this.props.content}
        </View>
        <Gradient
          style={[
            styles.leftBar,
            { width: leftBarWidth, left: 0, top: topBarHeight },
          ]}
          first={colors.landscape.leftGradient.first}
          second={colors.landscape.leftGradient.second}
        >
          {this.props.leftContent}
        </Gradient>
        <TopBar />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({
  // onResize: () => dispatch({ type: 'SCREEN_RESIZE' }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LandscapeLayout)

const styles = stylus({
  outerContainer: {
    flex: 1,
    alignItems: 'center',
  },
  top: {
    position: 'absolute',
  },
  leftBar: {
    backgroundColor: 'transparent',
    position: 'absolute',
    shadowColor: 'rgba(0,0,0,0.07)',
    shadowRadius: 17,
    top: 0,
    bottom: 0,
    left: 0,
  },
  content: {
    borderLeftWidth: sizes.px,
    borderLeftColor: colors.thinLineSoft,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
})
