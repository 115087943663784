import React from 'react'
import {
  StyleSheet,
  TouchableOpacity,
} from 'react-native'

import Icons from '@expo/vector-icons/Ionicons'
import withActionSheet from '../../containers/ActionSheet/withActionSheet'
import withCurrentUser from '../../containers/withCurrentUser'
import { connect, graphql, compose } from '../../config/connected'
import i18n from 'i18n-js'
import { PropTypes } from 'prop-types'
import postSchema from '../../schema/post'
import userSchema from '../../schema/user'

import colors from '../../config/colors'
import NavigationActions from '../../utility/navigationActions'
import { ROUTE_REPORT_CONTENT_POST, SEARCH_OBJECT_RECIPES, ROUTE_REPORT_CONTENT_RECIPE } from '../../config/constants'
import { SEARCH_REFRESH_TYPE } from '../../reducers/search'
import { PROFILE_REFRESH_PAGE } from '../../reducers/profile'
import { openActionSheet } from '../../config/helpers'
import alert from '../../utility/alert'
import _ from 'lodash'

@withActionSheet
@withCurrentUser
class Options extends React.Component {
  static propTypes = {
    showActionSheetWithOptions: PropTypes.func.isRequired,
    deletePost: PropTypes.func.isRequired,
    followUser: PropTypes.func.isRequired,
    unfollowUser: PropTypes.func.isRequired,
    currentUserId: PropTypes.string,
    post: PropTypes.object.isRequired,
    pendingPostEdit: PropTypes.object,
  }

  render() {
    return (
      <TouchableOpacity style={styles.area} onPress={this.showActionSheet}>
        <Icons name='md-more' size={22} color={colors.black} />
      </TouchableOpacity>
    )
  }

  isAdmin = () => {
    const { currentUser } = this.props
    return !currentUser.loading && _.get(currentUser, 'currentUser.isAdmin', false)
  }

  showActionSheet = () => {
    const { currentUserId, post } = this.props
    if (currentUserId === post.userId) {
      this.openActionSheetForMyPost()
    } else {
      this.openActionSheetForOtherPost()
    }
  }

  openActionSheetForMyPost = () => {
    const optionsSpec = [
      // Temporarily disabled editing posts
      { option: this.getMessage('common.edit'), action: () => this.editPost() },
      { option: this.getMessage('common.delete'), action: () => this.deletePost(), destructiveButtonIndex: true },
      { option: this.getMessage('common.cancel'), action: _.identity, cancelButtonIndex: true },
    ]
    openActionSheet(this.props.showActionSheetWithOptions, optionsSpec)
  }

  openActionSheetForOtherPost = () => {
    const { showActionSheetWithOptions, post, dispatch } = this.props
    const followOption = post.user.iFollow ? this.getMessage('common.unfollow') : this.getMessage('common.follow')
    const followAction = post.user.iFollow ? this.unfollowUser : this.followUser
    const routeName = post.type === 'recipe' ? ROUTE_REPORT_CONTENT_RECIPE : ROUTE_REPORT_CONTENT_POST

    const optionsSpec = [
      this.isAdmin() && { option: this.getMessage('common.delete'), action: () => this.deletePost(), destructiveButtonIndex: true },
      { option: followOption, action: () => followAction() },
      { option: this.getMessage('common.report'), action: () => NavigationActions.navigate({ routeName, params: { objectId: post.id } }) },
      { option: this.getMessage('common.cancel'), action: _.identity, cancelButtonIndex: true },
    ].filter(val => val)
    openActionSheet(showActionSheetWithOptions, optionsSpec)
  }

  deletePost = async () => {
    const { post, dispatch } = this.props
    const isRecipe = post.type === 'recipe'
    const method = isRecipe ? 'deleteRecipe' : 'deletePost'
    await this.props[method]({
      variables: {
        postId: post.id,
      },
      refetchQueries: [{
        query: postSchema.queries.newsfeed
      }],
    })
    if (isRecipe) {
      dispatch({ type: SEARCH_REFRESH_TYPE, searchObjectType: SEARCH_OBJECT_RECIPES })
    }

    dispatch({
      type: PROFILE_REFRESH_PAGE,
      page: isRecipe ? 'recipes' : 'posts'
    })
    const activeRoute = NavigationActions.getActiveRoute()
    const routeName = _.get(activeRoute, 'routeName', '')
    const onPostComments = (routeName || '').indexOf('PostComments') !== -1
    const onRecipeDetails = (routeName || '').indexOf('RecipeDetails') !== -1
    const onPostScreen = onPostComments || onRecipeDetails
    if (onPostScreen) {
      NavigationActions.back()
    }
  }

  editPost = async () => {
    const { post: { type, id }, dispatch, pendingPostEdit } = this.props
    const isRecipe = type === 'recipe'
    if (pendingPostEdit) {
      alert({
        message: i18n.t('publishContent.alert.pendingContent')
      })
      return
    }
    NavigationActions.navigate({ routeName: 'EditPost', params: { id, isRecipe } })
  }

  followUser = async() => {
    const { post } = this.props
    await this.props.followUser({
      variables: { id: post.user.id },
      optimisticResponse: {
        __typename: 'Mutation',
        followUser: {
          ...post.user,
          iFollow: true,
          followersCount: post.user.followersCount + 1,
        },
      },
    })
  }

  unfollowUser = async() => {
    const { post } = this.props
    await this.props.unfollowUser({
      variables: { id: post.user.id },
      optimisticResponse: {
        __typename: 'Mutation',
        unfollowUser: {
          ...post.user,
          iFollow: false,
          followersCount: post.user.followersCount - 1,
        },
      },
    })
  }

  getMessage(transPath) {
    return i18n.t(transPath)
  }
}

const mapStateToProps = (state) => ({
  currentUserId: state.currentUser.id,
})
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(postSchema.mutations.deletePost, {
    name: 'deletePost',
  }),
  graphql(postSchema.mutations.deleteRecipe, {
    name: 'deleteRecipe',
  }),
  graphql(userSchema.mutations.followUser, {
    name: 'followUser',
  }),
  graphql(userSchema.mutations.unfollowUser, {
    name: 'unfollowUser',
  }),
)(Options)

const styles = StyleSheet.create({
  area: {
    width: 52,
    height: 52,
    position: 'absolute',
    right: 0,
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
