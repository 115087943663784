import { Platform } from 'react-native'
import deviceDetect from 'device-detect'

export default function isSafari() {
  if (Platform.OS === 'web') {
    const device = deviceDetect()
    // console.log({device})
    return device.browser === 'Safari'
  }
  return false
}
