import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import Scroller from '../../components/Scroller'
import PageTitle from '../../components/simple/PageTitle'
import Action from '../../components/simple/Action'
import ProfileImage from '../../components/Post/ProfileImage'
import ListItem from '../../components/simple/ListItem'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import {
  connect,
  graphql,
  withApollo,
  compose,
  gql,
} from '../../config/connected'
import MobileBackButton from '../../components/simple/MobileBackButton'
import NavigationActions from '../../utility/navigationActions'
import { logout } from '../../utility/apollo'
import BigStatistic from '../../components/simple/BigStatistic'
import Statistic from '../../components/simple/Statistic'
import Metric from '../../components/simple/Metric'
import _ from 'lodash'
import moment from 'moment'

class User extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      refreshing: false,
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      user: { error },
    } = nextProps
    if (error) {
      const message = _.get(error, 'graphQLErrors.0.message') || error.message
      // console.log({ error: message })
      if (message.toLowerCase().indexOf('unauthorized') >= 0) {
        this.props.logout()
      }
    }
  }
  render() {
    return (
      <View style={styles.container}>
        <Scroller
          refreshing={
            this.state.refreshing || this.props.user.networkStatus === 4
          }
          onRefresh={this.refresh}
        >
          {this.content()}
        </Scroller>
      </View>
    )
  }
  content() {
    const {
      user: { loading, adminUser, error },
    } = this.props

    if (adminUser) {
      return this.info()
    }
    if (loading) {
      return <ActivityIndicator size='large' style={{ paddingVertical: 60 }} />
    }
    if (error) {
      return null
    }
  }
  info() {
    const { user } = this.props
    const {
      id,
      name,
      email,
      emailVerified,
      phone,
      phoneVerified,
    } = user.adminUser

    return (
      <View style={styles.innerContainer}>
        <PageTitle text={name} />
        <Metric
          label='EMAIL'
          value={email}
          unit={emailVerified ? ' | verified' : ' | not verified'}
        />
        <Metric
          label='PHONE'
          value={phone}
          unit={phoneVerified ? ' | verified' : ' | not verified'}
        />
        <Action
          label='Login As...'
          onPress={this.loginAs}
          style={{ marginVertical: 20 }}
        />
      </View>
    )
  }
  loginAs = async () => {
    const result = await this.props.ghostToken({
      variables: {
        id: this.props.user.adminUser.id,
      },
    })
    const {
      ghostToken: { token, id },
    } = result.data
    // console.log({ result, token, id })
    this.props.ghost({ token, userId: id })
  }
  refresh = () => {
    this.setState({ refreshing: true })
    this.props.user.refetch()
    setTimeout(() => {
      this.setState({ refreshing: false })
    }, 800)
  }
}

User.navigationOptions = (props) => {
  const { navigation } = props
  const params = _.get(navigation, 'state.params') || {}
  const { id } = params
  return {
    headerTitle: 'User',
    // headerRight: () => (
    //   <Action style={{paddingHorizontal: 15}} label='Clear All' />
    // ),
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

const userQuery = gql`
  query($id: String!) {
    adminUser(id: $id) {
      id
      email
      emailVerified
      name
      phone
      phoneVerified
      identities {
        id
        createdAt
        status
      }
    }
  }
`

const ghostTokenMutation = gql`
  mutation($id: String!) {
    ghostToken(id: $id) {
      token
      id
    }
  }
`

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
  cuid: state.currentUser.id
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  goToEdit: () => NavigationActions.navigate({ routeName: 'Edit' }),
  logout: () => logout(ownProps.cuid),
  ghost: ({ token, userId }) => dispatch({ type: 'GHOST', token, userId }),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(ghostTokenMutation, {
    name: 'ghostToken',
  }),
  graphql(userQuery, {
    name: 'user',
    options: (props) => ({
      variables: { id: props.navigation.state.params.id },
    }),
  }),
)(User)

const styles = stylus({
  container: {
    flex: 1,
  },
  innerContainer: {
    paddingHorizontal: 24,
    paddingTop: 24,
    portrait: {
      paddingHorizontal: 12,
    },
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingVertical: 10,
  },
  title: {
    fontSize: 16,
    color: colors.text.secondary,
    fontWeight: 'normal',
    flexGrow: 0,
  },
})
