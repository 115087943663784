import React from 'react'

import {
  graphql,
  compose,
} from '../../config/connected'
import withCurrentUser from '../../containers/withCurrentUser'
import withConversations from '../../containers/withConversations'
import _ from 'lodash'
import produce from 'immer'
import schema from '../../schema/conversation'
import { client } from '../../containers/withApollo'
import { updateCachedQueryValue } from '../../utility/apollo'

class ChatSubscriptions extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = _.get(nextProps, 'currentUser.currentUser.id')
    if (id !== _.get(this.props, 'currentUser.currentUser.id')) {
      if (id) {
        if (!this.messagesSubscription) {
          console.log('<<<SUBSCRIBING TO CHATS')
          this.subscribeToMessages()
        }
      } else {
        // unsubscribe
        if (this.messagesSubscription) {
          console.log('<<<UNSUBSCRIBING TO CHATS')
          this.messagesSubscription.unsubscribe()
          delete this.messagesSubscription
        }
      }
    }
    // this.subscribeToConversations()
  }
  componentDidUpdate(prevProps) {
    const { conversations, cuid } = this.props
    if (cuid !== prevProps.cuid) {
      conversations.refetch()
    }
  }

  subscribeToMessages() {
    const observable = client.subscribe({
      query: schema.subscriptions.messageAdded,
    })
    this.messagesSubscription = observable.subscribe({
      next: (notification) => {
        // console.log('Received new message', notification)
        const message = _.get(notification, 'data.messageAdded', {})

        const { conversationId } = message
        console.log(`<<<CHAT SUBSCRIPTION - conversation ${conversationId} with new message:`, message)
        try {
          const updatedMemberships = updateCachedQueryValue(client, {
            key: 'conversationMemberships',
            query: schema.queries.conversationMemberships,
            nextValue: (conversationMemberships = []) => {
              return produce(conversationMemberships, allMemberships => {
                const membership = _.find(
                  allMemberships,
                  (item) => item.conversation.id === conversationId,
                )
                if (membership) {
                  console.log(`<<<CHAT SUBSCRIPTION - found membership to update for conversation ${conversationId}`)
                  membership.conversation.lastMessage = message
                  membership.unreadCount++
                  membership.lastTouch = new Date().toISOString()
                  const index = _.findIndex(allMemberships, { id: membership.id })
                  const existing = allMemberships.splice(index, 1)
                  allMemberships.unshift(existing[0])
                }
              })
            }
          })

          const updatedMembership = _.find(
            updatedMemberships || [],
            (item) => item.conversation.id === conversationId,
          )

          let messages
          if (updatedMembership) {
            messages = updateCachedQueryValue(client, {
              key: 'messages',
              abortOnError: true,
              query: schema.queries.messages,
              variables: { membershipId: updatedMembership.id },
              nextValue: (messages = []) => {
                return produce(messages, draft => {
                  if (!_.find(draft, { id: message.id })) {
                    draft.unshift(message)
                  }
                })
              }
            })
            if (messages) {
              console.log(`<<<CHAT SUBSCRIPTION - found messages to update for conversation ${conversationId}`)
            }
          }

          if(!updatedMembership || !messages) {
            console.log(`<<<CHAT SUBSCRIPTION - could not find membership or messages to update for conversation ${conversationId}`)
            this.fetchMembership({ conversationId })
          }
        } catch (e) {
          console.log('<<<CHAT SUBSCRIPTION - error', e)
        }
      },
      error(err) {
        console.log('<<<CHAT SUBSCRIPTION - error subscribing', err)
      },
    })
  }
  fetchMembership = async ({ conversationId }) => {
    const result = await this.props.fetchMembership({
      variables: { conversationId },
    })
    const membership = result.data.fetchMembership
    const memberships = client.readQuery({
      query: schema.queries.conversationMemberships,
    })
    // in case it came in while we were fetching, check again
    const existing = _.find(memberships.conversationMemberships, {
      id: membership.id,
    })
    if (existing) {
      // do nothing
    } else {
      memberships.conversationMemberships.unshift(membership)
      client.writeQuery({
        query: schema.queries.conversationMemberships,
        data: memberships,
      })
    }
  }
  render() {
    return null
  }
}

export default compose(
  withCurrentUser,
  withConversations,
  graphql(schema.mutations.fetchMembership, {
    name: 'fetchMembership',
  }),
)(ChatSubscriptions)
