import { Platform, Dimensions } from 'react-native'

export default function selectLayout(items) {
  const layout = getLayout()
  return items[layout]()
}

export function getLayout() {
  const { width, height } = Dimensions.get('window')
  switch (Platform.OS) {
    case 'web':
      if (width < 720) {
        return 'web/portrait'
      }
      return 'web/landscape'
    case 'ios':
      if (width < 720) {
        return 'ios/portrait'
      }
      return 'web/landscape'
    default:
      if (width < 720) {
        return 'android/portrait'
      } else {
        return 'android/portrait'
      }
  }
}
