import React from 'react'
import { View, Image, Text, TouchableOpacity } from 'react-native'
import Ratings from '../simple/Ratings'
import PropTypes from 'prop-types'
import Icons from '@expo/vector-icons/Ionicons'
import Icon from '../../config/icon'
import { stylus } from '../../config/styles'
import { getPrice, unitPlural, getProductPrice } from '../../config/helpers'
import _ from 'lodash'
import NamedImage from '../simple/NamedImage'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import { _get, connect, compose } from '../../config/connected'
import NavigationActions from '../../utility/navigationActions'
import i18n from 'i18n-js'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'

import { OBJECT_STATUSES } from '../../config/constants'
import Button from '../simple/Button'
import withPreventDoubleClick from '../../containers/withPreventDoubleClick'
const PreventDoubleClickButton = compose(withPreventDoubleClick)(Button)

const SIZE = 50
@connect(
  () => ({}),
  (dispatch) => ({
    goToProduct: (id) => NavigationActions.navigate({ routeName: 'Product', params: { id } }),
  }),
)
class ProductGridView extends React.Component {
  static propTypes = {
    product: PropTypes.shape({
      uploads: PropTypes.any,
      name: PropTypes.string,
      rating: PropTypes.number,
      ratings: PropTypes.number,
      shopName: PropTypes.string,
      price: PropTypes.number,
      priceDiscount: PropTypes.number,
      unitType: PropTypes.string,
      unitQuantity: PropTypes.number,
    }),
    shopName: PropTypes.string,
    width: PropTypes.number.isRequired,
    size: PropTypes.number,
    goToProduct: PropTypes.func.isRequired,
    hideShop: PropTypes.bool,
    hidePrice: PropTypes.bool,
    shopShipCost: PropTypes.bool,
    showAddToCart: PropTypes.bool,
    onAddToCart: PropTypes.func,
  }

  static defaultProps = {
    shopShipCost: false
  }

  onAddToCart = (event) => {
    const { product, onAddToCart } = this.props
    onAddToCart && onAddToCart(event, product)
  }

  goToProduct = () => {
    this.props.goToProduct(this.props.product.id)
  }

  renderPrice = () => {
    const { hidePrice, product } = this.props
    const { priceDiscount, price } = getProductPrice(product)

    if (hidePrice) return null
    let hasPriceDiscount = !!priceDiscount && priceDiscount < price

    if (hasPriceDiscount) {
      return (
        <Text style={styles.priceContainer}>
          <Text style={styles.priceDiscount}>
            {getPrice(priceDiscount) + '  '}
          </Text>
          <Text style={styles.priceDiscountNormal}>
            {getPrice(price)}
          </Text>
        </Text>
      )
    } else {
      return <Text style={styles.price}>{getPrice(price)}</Text>
    }
  }

  renderShippingCost = () => {
    const {profile} = this.props.product
    if (parseInt(profile.standardShippingFee) === 0) {
      return <Text style={styles.shippingCost}>{i18n.t('shop.freeShipping')}</Text>
    }
    return (
      <Text style={styles.shippingCost}>
        {`${i18n.t('shop.tabs.policies.shippingTitle')}: ${getPrice(profile.standardShippingFee)}`}
      </Text>
    )
  }

  render() {
    const {
      uploads,
      name,
      rating,
      ratingCount,
      id,
      isInStock,
      isDiscontinued,
      unitType,
      unitQuantity,
      position,
      profile,
    } = this.props.product

    const shopStatus = _.get(profile, 'status', OBJECT_STATUSES.active)

    const {
      product,
      hideShop = false,
      hidePrice = false,
      sizeMult = 1,
      goToProduct,
      width,
      style,
      isOwner = false,
      shopName,
      shopShipCost,
      showAddToCart,
      drag,
      showOrder,
    } = this.props

    const image = _.find(uploads, i => i.mime !== 'mp4' && i.mime !== 'mov' ) || {
      name: '',
      mime: '',
      width: 5,
      height: 5,
    }
    const outOfStockAndOwner = isOwner && !isInStock
    const unitProduct = unitPlural(product)

    return (
      <TouchableOpacity
        style={[styles.container, style]}
        onPress={this.goToProduct}
        onLongPress={drag}
      >
        <View
          style={{
            // borderWidth: outOfStockAndOwner ? 3 : 1,
            // borderColor: outOfStockAndOwner ? colors.red : colors.primaryDark,
            // padding: 4,
            // borderRadius: 4//SIZE * sizeMult + 10 / 2,
          }}
        >
          <View
            style={{
              width: SIZE * sizeMult,
              height: SIZE * sizeMult,
              borderRadius: 4,//  (SIZE / 2) * sizeMult,
              overflow: 'hidden',
            }}
          >
            <NamedImage
              name={image.name}
              width={SIZE * sizeMult}
              height={SIZE * sizeMult}
              style={{
                height: SIZE * sizeMult,
                borderRadius: 4,//(SIZE / 2) * sizeMult,
                overflow: 'hidden',
              }}
              fit='crop'
              crop='entropy'
              resizeMethod='scale'
              resizeMode='cover'
            />
          </View>
        </View>
        <View
          style={[
            styles.content,
            { width: width - SIZE - 14 /* Margin of style */ },
          ]}
        >
          <Text style={styles.title} numberOfLines={3}>
            {name}
          </Text>
          {!!unitProduct && (
            <Text style={styles.title}>
              {unitProduct}
            </Text>
          )}
          {!hideShop && <Text style={styles.shopName}>{shopName || product.shopName}</Text>}
          {
            !!ratingCount && !!rating && (
              <Ratings
                containerStyle={{marginBottom: 4}}
                rating={rating}
                ratings={ratingCount}
              />
            )
          }
          {(isDiscontinued || isInStock === false || shopStatus !== OBJECT_STATUSES.active) ? (
            <Text style={[styles.outOfStock]}>
              {i18n.t('product.notAvailable')}
            </Text>
          ) : (
            <>
              {this.renderPrice()}
              {shopShipCost && this.renderShippingCost()}

              {showAddToCart && (
                <PreventDoubleClickButton
                  disabled={!isInStock || isDiscontinued}
                  style={[styles.cartButton]}
                  onPress={this.onAddToCart}
                >
                  <Icon
                    name={'cart'}
                    size={20}
                    color={'white'}
                  />
                  <Text style={styles.cartButtonText}>{i18n.t('product.actionButtons.addToCart')}</Text>
                </PreventDoubleClickButton>
              )}
            </>
          )}
        </View>
        {showOrder && (
          <Text style={{
            position: 'absolute',
            right: 10,
            top: 10,
            fontSize: 12,
          }}>{position}º</Text>
        )}
        {drag && (
          <MaterialIcons
            style={{
              position: 'absolute',
              right: 0,
            }} 
            name="drag-handle" 
            size={25} 
            color={colors.text.soft}
          />
        )}
      </TouchableOpacity>
    )
  }
}

const styles = stylus({
  container: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
  },
  imageBorder: {
    borderWidth: 1,
    borderColor: colors.primaryDark,
    padding: 4,
  },
  imageRadius: {
    width: SIZE,
    height: SIZE,
    borderRadius: SIZE / 2,
    overflow: 'hidden',
  },
  image: {
    width: SIZE,
    height: SIZE,
    borderRadius: SIZE / 2,
    overflow: 'hidden',
  },
  content: {
    // flex: 1,
    // paddingVertical: 6,
    marginLeft: 14,
  },
  title: {
    // fontWeight: 'bold',
    color: colors.text.main,
    fontSize: 16,
    lineHeight: 16,
    marginBottom: 4
  },
  shopName: {
    marginBottom: 4,
    fontSize: 13,
    color: colors.text.secondary,
  },
  bookmark: {
    position: 'absolute',
    backgroundColor: 'transparent',
  },
  priceContainer: {
    //marginBottom: 4,
  },
  price: {
    //marginBottom: 4,
    // fontWeight: 'bold',
    fontSize: 22,
    color: colors.text.main,
  },
  priceDiscount: {
    // fontWeight: 'bold',
    fontSize: 22,
    color: colors.text.main,
  },
  priceDiscountNormal: {
    // fontWeight: 'bold',
    fontSize: 14,
    color: colors.text.secondary,
    textDecorationLine: 'line-through',
  },
  shippingCost: {
    marginTop: 4,
    fontSize: 13,
    color: colors.text.secondary,
  },
  outOfStock: {
    color: colors.text.secondary,
  },
  cartButton: {
    padding: 5,
    marginTop: 5,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    alignSelf: 'flex-start',
    backgroundColor: colors.primaryDark
  },
  cartButtonText: {
    marginLeft: 5,
    marginRight: 5,
    color: 'white',
    fontWeight: '600'
  },
})

export default ProductGridView
