import React from 'react'
import { View, Text } from 'react-native'

import colors from '../../../config/colors'
import shared, { stylus } from '../../../config/styles'
import {
  getPrice,
  getTotalPrices,
} from '../../../config/helpers'

import i18n from 'i18n-js'
import _ from 'lodash'

const getApplicationFee = (fee, couponSavings, credit) => {
  if (credit) {
    return credit === couponSavings ? 0 : couponSavings - credit 
  }
  return fee + couponSavings
}

const getFeeReimbursement = (fee, couponSavings, credit) => {
  if (!fee) {
    return 0
  } else if (!credit) {
    return couponSavings
  } else {
    return couponSavings - credit
  }
}

export default function OrderTotals({
  items,
  fees = [],
  refund = 0,
  credit,
  coupon,
  showCouponPercentage,
  refundText,
  refundAsSeparateTotal = false,
  title,
  totalTitle,
  creditTitle,
  netTitle,
  style,
  titleStyle,
  minorTextStyle,
  lineStyle,
  showSubtotal,
  showTitle,
  showFreeShippingMessage,
  forShop,
}) {
  const totals = getTotalPrices(
    items,
    (item) => {
      if (_.get(item, 'productDetails.profile.producerDetails')) {
        return item.productDetails
      } else {
        return item.product
      }
    },
    forShop,
    coupon
  )

  const number = items.reduce((sum, item) => (sum += item.quantity), 0)
  const applicationFeeObject = fees.find(fee => fee.type === 'application_fee') || { amount : 0 }
  const { amount: actualApplicationFee } = applicationFeeObject
  const applicationFee = getApplicationFee(actualApplicationFee, totals.couponSavings, credit)
  const feeReimbursement = getFeeReimbursement(applicationFee, totals.couponSavings, credit)
  const hasNetTotal = fees.length || (refund && !refundAsSeparateTotal)
  const netTotal = totals.total - fees.reduce((total, fee) => total + fee.amount, 0) - refund
  const netTotalNumberSymbol = netTotal < 0 ? '-' : ''
  const shipping =
    totals.amountForFreeShipping === 0
      ? i18n.t('common.free')
      : getPrice(totals.shipping)
  return (
    <View style={style}>
      {showTitle && (
        <Text style={[shared.h5, styles.title, titleStyle]}>{title}</Text>
      )}
      {showSubtotal && (
        <View style={[styles.line, lineStyle]}>
          <Text style={[styles.minorText]}>
            {i18n.t('order.checkout.items', { number })}
          </Text>
          <Text style={[styles.minorText, minorTextStyle]}>
            {getPrice(totals.subTotalAfterDiscounts)}
          </Text>
        </View>
      )}
      <View style={[styles.line, lineStyle]}>
        <Text style={[styles.minorText]}>{i18n.t('order.order.shipping')}</Text>
        <Text style={[styles.minorText, minorTextStyle]}>{shipping}</Text>
      </View>
      {showFreeShippingMessage === true && totals.amountForFreeShipping > 0 && (
        <Text style={styles.subtext}>
          (
          {i18n.t('order.cart.freeShippingInfo', {
            amount: getPrice(totals.amountForFreeShipping),
          })}
          )
        </Text>
      )}
      { totals.couponSavings ? (
        <View style={[styles.line, lineStyle]}>
          <Text style={[styles.minorText]}>{i18n.t('order.orderDetails.platformCoupon')}{ showCouponPercentage ? ` (${coupon.percent}%)` : ''}</Text>
          <Text style={[styles.minorText, minorTextStyle]}>-{getPrice(totals.couponSavings)}</Text>
        </View>
      ) : null }
      <View style={[styles.line, lineStyle]}>
        <Text style={[styles.orderTotalText, styles.title, titleStyle]}>
          {totalTitle}
        </Text>
        <Text style={[shared.h6, styles.totalValue, titleStyle]}>
          {getPrice(totals.total)}
        </Text>
      </View>
      {fees.length ? fees.map((fee, index) => {
        const feeValue = fee.type === 'application_fee' ? applicationFee : fee.amount
        return (
          <View key={index} style={[styles.line, lineStyle]}>
            <Text style={[styles.minorText]}>{fee.description}</Text>
            <Text style={[styles.minorText, minorTextStyle]}>
              {feeValue ? '-' : ''} {getPrice(feeValue)}
            </Text>
          </View>
        )
      }) : null}
      { fees.length && applicationFee && totals.couponSavings ? (
        <View style={[styles.line, lineStyle]}>
          <Text style={[styles.minorText]}>{i18n.t('order.orderDetails.feeReimburse')}</Text>
          <Text style={[styles.minorText, minorTextStyle]}>{getPrice(feeReimbursement)}</Text>
        </View>
      ): null }
      {refund && !refundAsSeparateTotal ? (
        <View style={[styles.line, lineStyle]}>
          <Text style={[styles.minorText]}>{refundText}</Text>
          <Text style={[styles.minorText, minorTextStyle]}>
            - {getPrice(refund)}
          </Text>
        </View>
      ) : null}
      {hasNetTotal ? (
        <View style={[styles.line, lineStyle]}>
          <Text style={[styles.orderTotalText, styles.title, titleStyle]}>
            {netTitle}
          </Text>
          <Text style={[shared.h6, styles.totalValue, titleStyle]}>
            {netTotalNumberSymbol}
            {getPrice(Math.abs(netTotal))}
          </Text>
        </View>
      ) : null}
      {credit ? (
        <View style={[styles.line, lineStyle]}>
          <Text style={[styles.orderTotalText, styles.title, titleStyle]}>
            {creditTitle}
          </Text>
          <Text style={[shared.h6, styles.totalValue, titleStyle]}>
            {netTotalNumberSymbol}
            {getPrice(Math.abs(credit))}
          </Text>
        </View>
      ): null }
      {refund && refundAsSeparateTotal ? (
        <View style={[styles.line, lineStyle]}>
          <Text
            style={[
              styles.orderTotalText,
              styles.title,
              styles.refundTotal,
              titleStyle,
            ]}
          >
            {refundText}
          </Text>
          <Text style={[shared.h6, styles.refundTotal, titleStyle]}>
            {getPrice(refund)}
          </Text>
        </View>
      ) : null}
    </View>
  )
}

const styles = stylus({
  title: {
    fontWeight: 'bold',
    color: colors.text.main,
  },
  orderTotalText: {
    fontSize: 15,
  },
  minorText: {
    fontSize: 14,
    color: colors.text.main,
  },
  subtext: {
    fontSize: 12,
    color: colors.text.main,
  },
  totalValue: {
    color: 'red',
    fontWeight: '600',
  },
  refundTotal: {
    color: 'green',
    fontWeight: '600',
  },
  line: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
