import React from 'react'
import { View } from 'react-native'
import ShopSvgXml from '../../../../assets/svgs/shop-icon-smaller.svg'
import Svg from '../../Svg'

const ShopIcon = ({ style, color, size }) => (
  <View style={style}>
    <Svg width={size} height={size} color={color} xml={ShopSvgXml} />
  </View>
)
export default ShopIcon
