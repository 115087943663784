import React from 'react'
import { StyleSheet, Text, View, StatusBar } from 'react-native'



import Constants from 'expo-constants'

import { connect } from 'react-redux'
import colors from '../../config/colors'

class MyStatusBar extends React.Component {
  render() {
    let {
      platform,
      layout,
      color = 'white',
      hideLayout,
      androidColor = 'transparent',
    } = this.props
    if (hideLayout === layout) {
      return null
    }
    if (platform === 'ios') {
      const height = Constants.statusBarHeight
      // return <StatusBar
      //  animated={true}
      //  backgroundColor={color}
      //    barStyle="dark-content"
      //    hidden={false}
      // />
      return (
        <View style={[styles.container, { height, backgroundColor: color }]} />
      )
    }
    return null
  }
}

const mapStateToProps = (state) => ({
  platform: state.screenInfo.platform,
  layout: state.screenInfo.layout,
})

const mapDispatchToProps = (dispatch) => ({
  // onResize: () => dispatch({ type: 'SCREEN_RESIZE' }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyStatusBar)

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
  },
})
