import { Platform, UIManager } from 'react-native'

import web from './web'

export default () => {
  switch (Platform.OS) {
    case 'web':
      return web()
    case 'ios':
      break
    case 'android':
      UIManager &&
        UIManager.setLayoutAnimationEnabledExperimental &&
        UIManager.setLayoutAnimationEnabledExperimental(true)
      break
  }
}
