import _ from 'lodash'
import React from 'react'
import ListItem from '../../components/simple/ListItem'
import { stylus } from '../../config/styles'
import ProfileImage from '../Post/ProfileImage'


class UserSearchResult extends React.Component {
  render() {
    const { user, goToProfile, first } = this.props

    const { id, name, profileImage, location } = user

    return (
      <ListItem
        style={styles.container}
        forwardArrow={true}
        left={<ProfileImage name={_.get(profileImage, 'name')} />}
        title={name}
        description={_.get(location, 'summary', 'Somewhere')}
        onPress={this.goToProfile}
      />
    )
  }
  goToProfile = () => {
    const { id, name, profileImage, location } = this.props.user
    this.props.goToProfile({
      id,
      name,
      profileImage,
    })
  }
}

export default UserSearchResult

const styles = stylus({
  container: {
    paddingLeft: 14,
  },
})
