import { createIconSet } from '@expo/vector-icons'
import React, { Component } from 'react'
import { Platform, Text } from 'react-native'

const glyphMap = {
  'camera-outline': '\ue800',
  'chat-outline': '\ue801',
  'heart-outline': '\ue802',
  'save-outline': '\ue803',
  'send-outline': '\ue804',
  'cart-outline': '\ue805',
  'home-outline': '\ue806',
  'notifications-outline': '\ue807',
  'profile-outline': '\ue808',
  'search-outline': '\ue809',
  chef: '\ue80a',
  clock: '\ue80b',
  group: '\ue80c',
  cart: '\ue80f',
  home: '\ue810',
  notifications: '\ue811',
  profile: '\ue812',
  search: '\ue813',
  cooktime: '\ue814',
  save: '\ue815',
  heart: '\ue816',
}

let Icon = null

if (Platform.OS === 'web') {
  Icon = class extends Component {
    render() {
      const { name, size, color, style, ...props } = this.props
      let glyph = glyphMap[name] || '?'
      const styleDefaults = {
        fontSize: size,
        color,
      }

      const styleOverrides = {
        fontFamily: 'fb-icons',
        fontWeight: 'normal',
        fontStyle: 'normal',
      }

      props.style = [styleDefaults, style, styleOverrides]
      return <Text {...props}>{glyph}</Text>
    }
  }
} else {
  Icon = createIconSet(glyphMap, 'fb-icons', 'fb-icons.ttf')
}

export default Icon
