import { gql } from '../config/connected'

export default {
  queries: {
    getMessage: gql`
      query($id: String!) {
        findOneAdminMessage(id: $id) {
          id
          userId
          message
        }
      }
    `,
  },
  mutations: {
    ghostToken: gql`
      mutation($id: String!) {
        ghostToken(id: $id) {
          token
          id
        }
      }
    `,
    blockUser: gql`
      mutation($id: String!) {
        blockUser(id: $id)
      }
    `,
    unblockUser: gql`
      mutation($id: String!) {
        unblockUser(id: $id)
      }
    `,
    createMessage: gql`
      mutation($message: String!, $userId: String!) {
        createAdminMessage(message: $message, userId: $userId) {
          id
          userId
          message
          createdBy
          createdAt
          seen
        }
      }
    `,
    updateShopStatus: gql`
      mutation($id: String!, $status: String!, $messageId: String) {
        updateShopStatus(id: $id, status: $status, messageId: $messageId) {
          id
          status
        }
      }
    `,
  }
}
