import React, { Component } from 'react'
import { View, Text } from 'react-native'
import { stylus } from '../../config/styles'
import Brand from '../../components/Brand/'
import colors from '../../config/colors'

import T from '../../components/T'

export class Finish extends Component {
  render() {
    const { screenInfo } = this.props
    return (
      <View style={[styles.container]}>
        <Brand width={screenInfo.width / 2} vertical />
        <T t='onboardingUser.finish.text' style={styles.subtext} />
      </View>
    )
  }
}

export default Finish

const styles = stylus({
  container: {
    marginBottom: 50,
  },
  subtext: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '300',
    color: colors.text.secondary,
  },
})
