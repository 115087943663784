import React from 'react'
import TextInput from './TextInput'


const debug = false

export default class OverrideTextInput extends React.Component {
  state = {
    firstError: true,
    ignoreFirstClean: true,
  }

  valid = (value) => {
    const error = this.props.validate(value)
    return !error
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.value !== this.props.value
  }

  onKeyPress = (e) => {
    const key = e.nativeEvent.key
    this.lastKey = key
    if (key === 'Backspace' && !this.state.firstError) {
      this.setState({
        firstError: true,
        ignoreFirstClean: true,
      }, () => this.onChange(''))
    }
    debug && console.log('Key Press', key)
    if (this.props.onKeyPress) {
      this.props.onKeyPress(e)
    }
  }
  onChange = (value) => {
    const previousValue = this.props.value
    debug && console.log('Cur: ', value, 'Prev: ', previousValue)
    const actualValue = value.substring(previousValue.length)
    debug && console.log('Actual Value', actualValue)
    const cleanedValue = this.props.format(actualValue)
    debug && console.log('Cleaned Value', cleanedValue)
    debug && console.log('First Error', this.state.firstError)
    debug && console.log('Ignore First Clean', this.state.ignoreFirstClean)
    if (this.valid(value)) {
      debug && console.log('Value is Valid')
      if (!this.state.firstError && (cleanedValue.length > 1 || !this.state.ignoreFirstClean)) {
        debug && console.log('Using cleaned value')
        this.props.onChange(cleanedValue)
      } else {
        debug && console.log('Using Cur')
        this.props.onChange(value)
      }
    } else if (this.state.firstError) {
      debug && console.log('Use transformed value for First Error')
      this.setState({ firstError: false }, () => this.onChange(this.props.format(value)))
      return
    } else  {
      debug && console.log('Value is Invalid')
      this.props.onChange(cleanedValue)
    }
    if (!this.state.firstError) {
      this.setState({ ignoreFirstClean: false })
    }
  }

  render() {
    const {
      onChange,
      onKeyPress,
      validate,
      format,
      ...rest
    } = this.props

    return (
      <TextInput
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        { ...rest }
      />
    )
  }
}
