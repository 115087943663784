import React from 'react'
import { View, Text } from 'react-native'

import T from '../../components/T'
import TextInput from '../../components/simple/TextInput'

import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'

import { validators } from '../../config/validate'
import ErrorMessage from '../../components/simple/ErrorMessage'

const Welcome = ({ goToLogin, username, error, onChange, editingUsername, setError, removeError }) => {
  return (
    <View style={styles.container}>
      <T style={styles.title} t='onboardingUser.welcome.title' />
      <Text style={styles.username}>{username}</Text>
      <T style={styles.welcome} t='onboardingUser.welcome.text' />
      {!!editingUsername && (
        <React.Fragment>
          <TextInput
            maxLength={30}
            onChange={(value) => {
              const error = validators.username({ allowEmpty: true }, value)
              if (error) {
                setError('username', error)
              } else {
                removeError('username')
              }
              onChange(value, 'username')
            }}
            value={username}
            style={styles.editUsername}
            inputStyle={styles.inputStyle}
            autoCorrect={false}
            autoCapitalize='none'
          />
          { error ? <ErrorMessage message={error} /> : null }
        </React.Fragment>
      )}
    </View>
  )
}

export default Welcome

const styles = stylus({
  container: {
    alignItems: 'center',
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginVertical: 10,
    color: colors.text.main,
  },
  username: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.text.main,
  },
  welcome: {
    color: colors.text.secondary,
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 10,
  },
  editUsername: {
    marginVertical: 10,
    width: 240,
    paddingHorizontal: 0,
    backgroundColor: colors.input.backgroundFill,
  },
  inputStyle: {
    textAlign: 'center',
    color: colors.input.text,
  },
})
