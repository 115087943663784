import React, { Component, useEffect } from 'react'
import { Text, View } from 'react-native'
import { stylus } from '../../config/styles'
import Icon from '../../config/icon'
import { connect, compose, graphql } from '../../config/connected'
import cartSchema from '../../schema/cart'
import _ from 'lodash'

function CartIcon({
  focused,
  tintColor,
  iconSize,
  updateCart,
  cartItemsByUserIdHandler,
}) {
  const { cartItemsByUserId } = cartItemsByUserIdHandler
  const length = _.get(cartItemsByUserId, 'length', 0)
  return (
    <View>
      <Icon
        name={focused ? 'cart' : 'cart-outline'}
        size={iconSize}
        color={tintColor}
      />
      {!!length && (
        <View style={styles.iconIndicator}>
          <Text style={styles.iconIndicatorText}>{length}</Text>
        </View>
      )}
    </View>
  )
}

const mapStateToProps = (state) => ({
  userId: state.currentUser.id,
})

export default compose(
  connect(mapStateToProps),
  graphql(cartSchema.queries.cartItemsByUserId, {
    name: 'cartItemsByUserIdHandler',
    options: (props) => {
      return { variables: { userId: props.userId } }
    },
  })
)(CartIcon)
// export default CartIcon

const styles = stylus({
  iconIndicator: {
    position: 'absolute',
    left: '35%',
    backgroundColor: 'red',
    borderRadius: 999,
    width: 14,
    height: 14,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconIndicatorText: {
    marginTop: -1,
    color: 'white',
    fontSize: 10,
    fontWeight: 'bold',
  },
})
