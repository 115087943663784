import React from 'react'
import PropTypes from 'prop-types'
import { View, Platform, ActivityIndicator } from 'react-native'
import WebView from '../../simple/CoderaWebView'
import indexHtml from './web/index.inline-html'

import { stylus } from '../../../config/styles'
import colors from '../../../config/colors'

import _ from 'lodash'
import branch from '../../../config/branch'
import { graphql, connect } from '../../../config/connected'
import cartSchema from '../../../schema/cart'
import checkoutSessionSchema from '../../../schema/checkoutSession'
import { CURRENCY, getClient } from '../../../config/helpers'
import { updateCachedQueryValue } from '../../../utility/apollo'
import alert from '../../../utility/alert'
import i18n from 'i18n-js'
import { client } from '../../../containers/withApollo'

const logPrefix = '<<<STRIPE PAYMENT'
const debug = false
const mapStateToProps = (state) => ({
  currentUser: state.currentUser
})

@connect(mapStateToProps)
@graphql(cartSchema.mutations.continueStripePaymentSession, {
  name: 'continueStripePaymentSession'
})
class StripePayment extends React.Component {
  constructor(props) {
    super(props)
    this.state = { stripePaymentSessionsLoaded: false, paymentIntents: [], currentPaymentIntentIndex: 0 }
  }

  componentDidMount() {
    if (!this.state.stripePaymentSessionsLoaded) {
      this.loadStripePaymentSessions()
    }
  }

  async loadStripePaymentSessions() {
    const { shipping, billing } = this.props
    //console.log(billing, { currency: CURRENCY, locale: i18n.locale, shipping, billing })
    const result= await this.props.continueStripePaymentSession({
      variables: { currency: CURRENCY, locale: i18n.locale, shipping, billing },
      update: this.updateCheckoutSessionInCache
    })
    const checkoutSession = _.get(result, 'data.continueStripePaymentSession')
    this.props.onCheckoutStarted()
    if (!checkoutSession) {
      this.props.onCompletion()
    } else {
      const shopsStripeInfo = _.values(checkoutSession.stripeInfo)
      const paymentIntents = checkoutSession.stripePaymentIntents.map((paymentIntent) => {
        paymentIntent.stripeUserId = (shopsStripeInfo.find(stripeInfo => stripeInfo.paymentIntentId === paymentIntent.id) || {}).stripeUserId
        return paymentIntent
      })
      this.setState({ paymentIntents, stripePaymentSessionsLoaded: true })
    }
    
  }

  updateCheckoutSessionInCache = (store, { data }) => {
    const updatedCheckoutSession = data.continueStripePaymentSession
    updateCachedQueryValue(store, {
      query: checkoutSessionSchema.queries.myCheckoutSession,
      nextValue: () => {
        return updatedCheckoutSession
      },
    })
  }

  getCurrentPaymentIntentSecret = () => {
    return _.get(this.state.paymentIntents,`${this.state.currentPaymentIntentIndex}.client_secret`)
  }

  getCurrentPaymentIntentStripeUserId = () => {
    return _.get(this.state.paymentIntents,`${this.state.currentPaymentIntentIndex}.stripeUserId`)
  }

  render() {
    const { style } = this.props
    if (!this.state.stripePaymentSessionsLoaded) {
      return (
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator size="large" color={colors.primary} />
        </View>
      )
    }
    const clientSecret = this.getCurrentPaymentIntentSecret()
    const stripeUserId = this.getCurrentPaymentIntentStripeUserId()
    const html = indexHtml(clientSecret, stripeUserId)
    // console.log(`${logPrefix} - HTML`)
    // console.log(html)
    // console.log(`${logPrefix} - RENDERING`)
    return (
      <View style={[styles.container]}>
        <WebView
          style={[{ flex: 1 }, style]}
          source={{ html, baseUrl: ''}}
          javaScriptEnabled={true}
          useWebKit={branch({ ios: true, other: false })}
          bounces={false}
          originWhitelist={['*']}
          logPrefix={logPrefix}
          onCoderaPostMessage={this.onCoderaPostMessage}
        />
      </View>
    )
  }

  onCoderaPostMessage = async(messageObj) => {
    console.log(`${logPrefix} - TYPE OF MESSAGE - ${messageObj.type}`)
    if(messageObj.type === 'payment_intent') {
      const paymentIntent = messageObj.message
      const dataObj = client.readQuery({ query: checkoutSessionSchema.queries.myCheckoutSession })
      const { myCheckoutSession } = dataObj

      let shopId = null
      for (const key in myCheckoutSession.stripeInfo) {
        const stripeInfo = myCheckoutSession.stripeInfo[key]
        if(stripeInfo.paymentIntentId === paymentIntent.id) {
          shopId = key
          break
        } 
      }

      updateCachedQueryValue(client, {
        query: cartSchema.queries.cartItemsByUserId,
        variables: { userId: this.props.currentUser.id },
        nextValue: (cartItems) => {
          return cartItems.filter((item) => _.get(item, 'profile.id') !== shopId)
        },
      })
      // console.log(`${logPrefix} - PaymentIntent - `, messageObj.message)
    } else if (messageObj.type === 'error') {
      console.log(`${logPrefix} - Error - ${messageObj.message}`)
      if (debug) {
        await this.displayError(messageObj.message)
      }
    }
    if (this.state.currentPaymentIntentIndex + 1 !== this.state.paymentIntents.length) {
      this.setState({ currentPaymentIntentIndex: this.state.currentPaymentIntentIndex + 1})
    } else {
      this.props.onCompletion()
    }
  }

  displayError(message) {
    return alert({ title: i18n.t('common.error'), message })
  }
}

StripePayment.propTypes = {
  style: PropTypes.object,
  shipping: PropTypes.object.isRequired,
  billing: PropTypes.object,
  onCheckoutStarted: PropTypes.func.isRequired,
  onCompletion: PropTypes.func.isRequired,
  onAuthFailure: PropTypes.func.isRequired,
  continueStripePaymentSession: PropTypes.func
}

const styles = stylus({
  container: {
    flex: 1
  },
  activityIndicatorContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
})

export default StripePayment
