import { gql } from '../config/connected'

export const ProductBaseFieldsFragment = gql`
    fragment ProductBaseFieldsFragment on Product {
        id
        name
        stock
        isInStock
        isDiscontinued
        price
        priceDiscount
        quantityUnits
        description
        ingredients
        rating
        ratingCount
        createdAt
        deliveryLocationsOnly
        unitType
        unitQuantity
        position
        shopDiscount
        categories {
            allergens
            categories
            freeFrom
            diet
        }
        uploads {
            name
            mime
            width
            height
        }
        awards {
            description
            images {
                name
                mime
                width
                height
            }
        }
        profile{
            id
            status
            producerDetails{
                id
                freeShippingThreshold
                standardShippingFee
                deliveryLocations
                ownership{
                    id
                    owner{
                        id
                    }
                }
            }
        }
    }
`

export const PostFragment = gql`
    fragment PostFragment on Post{
        id
        userId
        user {
            id
            username
            profileImage
            title
            organization
            organizationId
            iFollow
            justFollowed
            followersCount
        }
        type
        story
        likeCount
        commentCount
        topComments {
            id
            content
            iLike
            createdAt
            likesCount
            user {
                id
                username
                profileImage
                title
                organization
                organizationId
            }
        }
        uploads {
            name
            width
            height
            mime
        }
        iLike
        iSave
        type
        recipe {
            id
            title
            difficulty
            prepTime
            cookTime
            cost
            servings
            ingredients
            instructions
            recipeLink
            info
        }
        mentions{
            type
            productId
            userId
        }
        tags{
            type
            productId
            userId
        }
        products {
            id
            name
            rating
            ratingCount
            price
            priceDiscount
            isInStock
            isDiscontinued
            unitQuantity
            unitType
            uploads {
                name
            }
            profile{
                id
                displayName
                status
            }
        }
        taggedProducts {
            id
            name
            rating
            ratingCount
            price
            priceDiscount
            isInStock
            isDiscontinued
            unitQuantity
            unitType
            uploads {
                name
            }
            profile{
                id
                displayName
                status
            }
        }
        createdAt
        postType
        uploading
    }
`

export const PostLast3LikersFragmnet = gql`
    fragment PostLast3LikersFragmnet on Post{
        last3Likers{
            id,
            profileImage,
            username
        }
    }
`
export const addressFragment = gql`
    fragment AddressFragment on Address{
        address1
        address2
        city
        region
        zip
        country
    }
`
export const userAddressFragment = gql`
    fragment UserAddressFragment on UserAddress{
        id
        firstName
        lastName
        recipient
        address1
        address2
        city
        region
        zip
        country
        phone
        email
        createdAt
        updatedAt
        isDefault
        isBilling
        taxCode
        vatNumber
    }
`

export const userProfileFragment = gql`
    fragment UserFragment on User{
        id
        username
        displayName
        title
        personDetails {
            id
            firstName
            lastName
            bio
            interests
        }
        address{
            ...AddressFragment
        }
        addresses{
            ...UserAddressFragment
        }
        profileImage
        coverPhoto
        email
        phone
        isAdmin
        acceptedTerms
        acceptedTermsAt
        phoneVerified
        emailVerified
        isFoodPro
        isProducer
        blocked
    }
    ${addressFragment}
${userAddressFragment}`

export const couponFieldsFragment = gql`
  fragment CouponFieldsFragment on Coupon {
    name
    description
    percent
    redemptionLimitPerUser
    redemptionLimitPerCoupon
    checkoutAmountLimit
    startDate
    endDate
    createdAt
    expiredAt
    group
  }
`

export const couponFragment = gql`
  fragment CouponFragment on Coupon {
    id
    ...CouponFieldsFragment
  }
  ${couponFieldsFragment}
`

export const stripePaymentIntentFragment = gql`
  fragment StripePaymentIntentFragment on StripePaymentIntent {
    id
    object
    amount
    canceled_at
    client_secret
    created
    currency
    description
    livemode
    last_payment_error
    next_action
    payment_method
    receipt_email
    shipping
    status
  }
`

export const checkoutSessionFragment = gql`
  fragment CheckoutSessionFragment on CheckoutSession {
    id
    userId
    couponId
    stripeInfo
    createdAt
    billingAddressId
    shippingAddressId
    coupon {
      ...CouponFragment
    }
    stripePaymentIntents {
      ...StripePaymentIntentFragment
    }
    billingAddress {
      ...UserAddressFragment
    }
    shippingAddress {
      ...UserAddressFragment
    }
  }
  ${couponFragment}
  ${stripePaymentIntentFragment}
  ${userAddressFragment}
`

export const noticeFragment = gql`
  fragment NoticeFragment on Notice {
    id
    userId
    name
    message
    createdAt
  }
`
