import {
  gql,
} from '../../config/connected'

const reportContentFields = `
  id
  userId
  objectId
  content
  createdAt
  objectType
`

export default {
  queries: {
    reportContentByUserIdForObject: gql`
      query ($userId: String!, $objectType: String!, $objectId: String!) {
        reportContentByUserIdForObject(userId: $userId, objectType: $objectType, objectId: $objectId) {
          ${reportContentFields}
        }
      }
    `
  },
  mutations: {
    createReportContent: gql`
      mutation ($objectType: String!, $objectId: String!, $content: String!) {
        createReportContent(objectType: $objectType, objectId: $objectId, content: $content) {
          ${reportContentFields}
        }
      }
    `,
    updateReportContent: gql`
       mutation ($id: String!, $content: String!) {
         updateReportContent(id: $id, content: $content) {
           ${reportContentFields}
         }
       }
    `
  }
}
