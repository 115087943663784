import _ from 'lodash'

function conversationName({ members, cuid, limit = 3 }) {
  if (!members) {
    return '...'
  }
  const users = _.filter(members, (user) => {
    return user.id !== cuid
  })

  if (users.length === 1) {
    return _.get(users[0], 'displayName', '...')
  }

  if (users.length <= limit) {
    return _.map(users, (user) => {
      return _.get(user, 'displayName', '').split(' ')[0]
    }).join(', ')
  }

  let names =
    _.map([users[0], users[1], users[2]], (user) => {
      return _.get(user, 'displayName', '').split(' ')[0]
    }).join(', ') +
    ' +' +
    (users.length - 3)
  return names
}

function profileImage({ members, cuid }) {
  if (!members) {
    return null
  }
  const users = _.filter(members, (user) => {
    return _.get(user, 'id') !== cuid
  })

  if (users.length === 1) {
    return _.get(users[0], 'profileImage')
  }

  // make this better later
  return _.get(users[0], 'profileImage')
}

function lastMessageSummary({ members, message, cuid }) {
  if (!message) {
    return '...'
  }
  let name = ''
  if (_.get(message, 'user.id') === cuid) {
    name = 'You'
  } else if (members.length > 2) {
    name = _.get(message, 'user.displayName', 'Unknown').split(' ')[0]
  }

  if (message.text) {
    if (name.length) {
      return name + ': ' + message.text
    }
    return message.text
  }
  if (_.get(message, 'uploads.length', 0) > 0) {
    return name + ' sent a file'
  }
}

export default {
  conversationName,
  profileImage,
  lastMessageSummary,
}
