class ReduxStoreHolder {
  _store = null
  constructor() {}
  setStore(store) {
    if (!this._store) {
      this._store = store
    }
  }
  getStore() {
    return this._store
  }
}

const reduxStoreHolder = new ReduxStoreHolder()
export default reduxStoreHolder
