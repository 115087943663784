import i18n from 'i18n-js'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import {
  ActivityIndicator,
  Animated,
  Picker,
  Platform,
  Text,
  View,
  Share,
} from 'react-native'
import Icon from '@expo/vector-icons/Ionicons'
import ActionButton from 'react-native-action-button'
import NavigationActions from '../../utility/navigationActions'
import ExpandingSection from '../../components/ExpandingSection'
import ImageCarousel from '../../components/ImageCarousel'
import LayoutAnimation from '../../components/LayoutAnimation'
import PostFB, { PostRecipe } from '../../components/PostFB'
import Review from '../../components/Review'
import Scroller from '../../components/Scroller'
import ShopProfileBanner from '../../components/Shop/ShopProfileBanner'
import Button from '../../components/simple/Button'
import Divider from '../../components/simple/Divider'
import MobileBackButton from '../../components/simple/MobileBackButton'
import PickerFB from '../../components/simple/Picker'
import Ratings from '../../components/simple/Ratings'
import T from '../../components/T'
import TabView from '../../components/TabView'
import TagText from '../../components/Tagging/TagText'
import branch from '../../config/branch'
import colors from '../../config/colors'
import { _get, compose, connect, graphql } from '../../config/connected'
// import mockData from '../../config/mockData/it'
import { getPrice, getTotalPrices, getContentLink, unitPlural, getPendingContent } from '../../config/helpers'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'
import cartSchema from '../../schema/cart'
import posts from '../../schema/post'
import schema from '../../schema/product'
// import userSchema from '../../addressSchema/user'
import reviewsSchema from '../../schema/reviews'
import alert from '../../utility/alert'
import confirm from '../../utility/confirm'
import { stopAllVideos } from '../../utility/video'
import { getLayout } from '../../routing/selectLayout'
import AwardsCarousel from './AwardsCarousel'
import ProductOptions from './ProductOptions'
import Categories from './Categories'
import {
  ANALYTICS_ADDED_TO_CART_EVENT,
  ANALYTICS_CARTS_WITH_PRODUCTS_CREATED,
  ANALYTICS_PRODUCT_PAGE_VISIT,
} from '../../reducers/analytics'

import withPreventDoubleClick from '../../containers/withPreventDoubleClick'
const PreventDoubleClickButton = compose(withPreventDoubleClick)(Button)

import withCurrentUser from '../../containers/withCurrentUser'
import { MAX_SHOPS_PER_CART } from '../../containers/AddToCart'
import { OBJECT_STATUSES } from '../../config/constants'
import RelatedProductsByShop from './RelatedProductsByShop'

const FEED_INCREMENT = 3
const isWeb = Platform.OS === 'web'

@withCurrentUser
@graphql(posts.queries.productPosts, {
  name: 'productPosts',
  options: (props) => {
    return {
      variables: {
        idList: [props.navigation.state.params.id],
        limit: 1,
      },
    }
  },
})
@graphql(posts.queries.productRecipes, {
  name: 'productRecipes',
  options: (props) => {
    return {
      variables: {
        idList: [props.navigation.state.params.id],
        limit: 1,
      },
    }
  },
})
@graphql(schema.queries.productById, {
  name: 'productHandler',
  options: (props) => {
    return {
      fetchPolicy: 'cache-and-network',
      variables: { id: props.navigation.state.params.id },
    }
  },
})
@connect(
  (state, ownProps) => ({ 
    userId: state.currentUser.id,
    pendingProductEdit: getPendingContent(state.publishContent, 'product', _.get(ownProps, 'navigation.state.params.id'), 'productId')
  }),
  (dispatch, props) => ({
    analyticsProductPageVisit: (productId) => {
      dispatch({
        type: ANALYTICS_PRODUCT_PAGE_VISIT,
        data: {
          productId,
        },
      })
    },
  }),
)
@graphql(cartSchema.queries.cartItemsByUserId, {
  name: 'cartItemsByUserIdHandler',
  options: (props) => {
    return { variables: { userId: props.userId } }
  },
})
@graphql(cartSchema.mutations.createCartItem, { name: 'createCartItem' })
@graphql(cartSchema.mutations.updateCartItemQuantity, {
  name: 'updateCartItemQuantity',
})
@graphql(cartSchema.mutations.deleteCartItem, { name: 'deleteCartItem' })
@graphql(reviewsSchema.queries.productsReviews, {
  name: 'reviewsHandler',
  options(props) {
    return {
      variables: { productId: props.navigation.state.params.id, limit: 3 },
    }
  },
})
class Product extends React.Component {
  state = {
    addToCartAnimation: {
      y: new Animated.Value(0),
      top: new Animated.Value(0),
      opacity: new Animated.Value(0),
      width: new Animated.Value(this.props.screenInfo.width - 24),
      height: new Animated.Value(42),
      radius: new Animated.Value(0),
    },
    quantity: 1,
    selectedQuantity: 1,
    reviewsMore: true,
    recipeMore: true,
    postMore: true,
    awardsCarouselHeightSet: false,
  }

  expandingSectionRefs = []

  static navigationOptions = (props) => {
    const product = { title: '' }
    return {
      headerTitle: _.truncate(product.title, { length: 20 }),
      headerLeft: () => (
        <MobileBackButton
          label='Back'
          onPress={() => NavigationActions.back()}
        />
      ),
    }
  }

  componentDidMount() {
    if (getLayout() !== 'web/portrait') {
      this.willBlurListener = this.props.navigation.addListener('willBlur', () => stopAllVideos(this.props.videos))
    }
    const productId = _.get(this.props, 'navigation.state.params.id')
    _.invoke(this.props, 'analyticsProductPageVisit', productId)
  }

  componentWillUnmount() {
    this.willBlurListener && this.willBlurListener.remove()
  }

  setExpandingSectionRef = (ref, key) => {
    this.expandingSectionRefs[key] = ref
  }


  animate() {
    LayoutAnimation &&
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }

  getReviews = () => {
    return _get(this.props, 'reviewsHandler.productsReviews', [])
  }

  renderReviews = (reviews) => {
    if (!reviews.length) {
      return (
        <Text
          style={[styles.notAvailable, { paddingTop: 100 }]}
          key='noReviews'
        >
          {i18n.t('product.reviewsNotAvailable')}
        </Text>
      )
    }
    return _.map(reviews, (review) => {
      return <Review key={review.id} review={review} />
    })
  }

  fetchMorePosts = () => {
    const offset = _.get(this.props, 'productPosts.productPosts.length') || 0

    this.props.productPosts.fetchMore({
      variables: {
        idList: [this.props.navigation.state.params.id],
        limit: FEED_INCREMENT,
        offset,
      },
      updateQuery: (previous, { fetchMoreResult }) => {
        const newProductPosts = _.get(fetchMoreResult, 'productPosts') || []
        const previousProductPosts = previous.productPosts || []

        if (newProductPosts.length < FEED_INCREMENT) {
          this.setState({ postMore: false })
        }

        return {
          productPosts: _.uniqBy(
            [...newProductPosts, ...previousProductPosts],
            'id',
          ),
        }
      },
    })
  }

  fetchMoreRecipes = () => {
    const offset =
      _.get(this.props, 'productRecipes.productRecipes.length') || 0

    this.props.productRecipes.fetchMore({
      variables: {
        idList: [this.props.navigation.state.params.id],
        offset,
        limit: FEED_INCREMENT,
      },
      updateQuery: (previous, { fetchMoreResult }) => {
        const newReceipes = _.get(fetchMoreResult, 'productRecipes') || []
        const previousReceipes = previous.productRecipes || []

        if (newReceipes.length < FEED_INCREMENT) {
          this.setState({ recipeMore: false })
        }

        return {
          productRecipes: _.uniqBy([...newReceipes, ...previousReceipes], 'id'),
        }
      },
    })
  }

  fetchMoreReviews = () => {
    const offset =
      _.get(this.props, 'reviewsHandler.productsReviews.length') || 0
    this.props.reviewsHandler &&
      this.props.reviewsHandler.fetchMore({
        variables: {
          productId: this.props.navigation.state.params.id,
          limit: FEED_INCREMENT,
          offset,
        },
        updateQuery: (previous, { fetchMoreResult }) => {
          const newReviews = _.get(fetchMoreResult, 'productsReviews') || []
          const previousReviews = previous.productsReviews || []
          if (newReviews.length < FEED_INCREMENT) {
            this.setState({ reviewsMore: false })
          }
          let productsReviews = _.uniqBy(
            [...previousReviews, ...newReviews],
            'id',
          )
          return {
            productsReviews,
          }
        },
      })
  }

  getPosts = (filterType) => {
    let posts
    if (filterType === 'post') {
      posts = _get(this.props, 'productPosts.productPosts', [])

      if (posts.length === 0 && this.state.postMore) {
        this.setState({ postMore: false })
      }
    } else {
      posts = _get(this.props, 'productRecipes.productRecipes', [])

      if (posts.length === 0 && this.state.recipeMore) {
        this.setState({ recipeMore: false })
      }
    }

    let val = posts.slice()

    val.reverse()

    return val
  }

  renderPosts = (posts) => {
    if (!posts.length) {
      return (
        <Text style={[styles.notAvailable, { paddingTop: 100 }]} key='noPosts'>
          {i18n.t('product.postsNotAvailable')}
        </Text>
      )
    }
    return _.map(posts, (post) => {
      return <PostFB user={post.user} post={post} key={post.id} navigation={this.props.navigation} />
    })
  }

  renderRecipes = (recipes) => {
    if (!recipes.length) {
      return (
        <Text
          style={[styles.notAvailable, { paddingTop: 100 }]}
          key='noRecipes'
        >
          {i18n.t('product.recipesNotAvailable')}
        </Text>
      )
    }
    return _.map(recipes, (recipe) => (
      <PostRecipe post={recipe} key={recipe.id} navigation={this.props.navigation}/>
    ))
  }

  renderPricing = () => {
    const product = this.getProduct()
    const { price, priceDiscount, isDiscontinued, isInStock } = product

    switch (true) {
      case isDiscontinued:
        return (
          <Text style={[styles.textItemPrice, styles.textItemUnavailable]}>
            {i18n.t('product.itemDiscontinued')}
          </Text>
        )

      case !isInStock:
        return (
          <Text style={[styles.textItemPrice, styles.textItemUnavailable]}>
            {i18n.t('product.itemOutOfStock')}
          </Text>
        )

      case typeof priceDiscount === 'number' &&
        priceDiscount &&
        priceDiscount < price:
        return (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text
              style={[
                styles.textItemPrice,
                styles.textItemPriceDiscount,
                styles.textNoStrike,
              ]}
            >
              {getPrice(priceDiscount)}
            </Text>
            <Text style={[styles.textItemPrice, styles.textStrike]}>
              {getPrice(price)}
            </Text>
          </View>
        )
      default:
        return <Text style={styles.textItemPrice}>{getPrice(price)}</Text>
    }
  }

  renderSavings = () => {
    // return null
    const product = this.getProduct()

    const { price, priceDiscount, isDiscontinued, isInStock } = product
    // Don't render this if it's inapplicable.
    if (
      (priceDiscount && priceDiscount >= price) ||
      !priceDiscount ||
      isDiscontinued ||
      !isInStock
    ) {
      return null
    }
    const savings = price - priceDiscount
    const percent = Math.round(100 * (savings / price))
    return (
      <T
        style={styles.savings}
        t='product.savings'
        values={{ savings: getPrice(savings), percent }}
      />
    )
  }

  handleAddToCart = async (event) => {
    if (this.state.addingToCart) {
      return
    }
    const pageY = event.nativeEvent.pageY
    const product = this.getProduct()
    const shop = this.getShop()
    const deliveryLocationsOnly = _.get(product, 'deliveryLocationsOnly')
    const deliveryLocations = _.get(shop, 'producerDetails.deliveryLocations')
    if (deliveryLocationsOnly && deliveryLocations) {
      const yes = await confirm({
        title: i18n.t('deliveryLocations.alerts.warningTitle'),
        message: i18n.t('deliveryLocations.alerts.locationRestriction', {
          name: product.name,
          locations: deliveryLocations
        }),
        options: { confirmText: i18n.t('common.continue')}
      })
      if (!yes) {
        console.log('<<<NO - Dont add to cart')
        return
      }
      console.log('<<<YES - Add to cart')
    }

    this.setState({ addingToCart: true })
    const {
      screenInfo,
      createCartItem,
      deleteCartItem,
      updateCartItemQuantity,
      userId,
      navigation,
      cartItemsByUserIdHandler,
    } = this.props

    var cartItem,
      cartItems = await cartItemsByUserIdHandler.cartItemsByUserId,
      productAlreadyInCart,
      alreadyInCartRecord,
      newCartItem = {}

    if (_.isEmpty(cartItems) && cartItemsByUserIdHandler.loading === false) {
      this.props.analyticsCartsWithProductsCreated()
    }

    productAlreadyInCart = _.includes(
      _.map(cartItems, (item) => {
        // console.log(item.productId, navigation.state.params.id)
        if (item.productId === navigation.state.params.id) {
          alreadyInCartRecord = item
          return true
        } else {
          return false
        }
      }),
      true,
    )

    const existsInCart = productAlreadyInCart && alreadyInCartRecord
    //console.log('<<<PRODUCT - Exists in cart already?', existsInCart)
    const stateQuantity = parseFloat(this.state.quantity)

    const ANIMATION_DURATION = 500,
      defaultWidth = screenInfo.width - 24

    //Move the add to cart bubble to the touch point.
    this.state.addToCartAnimation.top.setOffset(
      pageY - sizes.TOP_BAR_HEIGHT - sizes.tabBarHeight,
    )
    this.state.addToCartAnimation.top.setValue(
      this.state.addToCartAnimation.top._value,
    )

    // Prep the element for animation, make it visible.
    this.state.addToCartAnimation.opacity.setValue(1)

    // Run all the animations in parallel
    Animated.parallel([
      Animated.timing(this.state.addToCartAnimation.y, {
        toValue: screenInfo.height - pageY,
        duration: ANIMATION_DURATION,
      }),
      Animated.timing(this.state.addToCartAnimation.opacity, {
        toValue: 0,
        duration: ANIMATION_DURATION,
      }),
      Animated.timing(this.state.addToCartAnimation.width, {
        toValue: 42,
        duration: ANIMATION_DURATION / 2,
      }),
      Animated.timing(this.state.addToCartAnimation.radius, {
        toValue: 100,
        duration: ANIMATION_DURATION,
      }),
    ]).start(() => {
      this.state.addToCartAnimation.width.setValue(defaultWidth)
      this.state.addToCartAnimation.y.setValue(0)
      this.state.addToCartAnimation.radius.setValue(4)
      this.state.addToCartAnimation.opacity.setValue(0)
    })

    if (existsInCart) {
      try {
        const quantity = alreadyInCartRecord.quantity + stateQuantity
        if (quantity > 999) {
          alert({
            title: i18n.t('order.cart.maxQuantityAlertTitle'),
            message: i18n.t('order.cart.maxQuantityAlertMessage', {
              quantity: alreadyInCartRecord.quantity,
            }),
          })
          return
        }
        // console.log('<<<PRODUCT - Updating quantity of product in cart to be', quantity )
        await updateCartItemQuantity({
          variables: {
            id: alreadyInCartRecord.id,
            quantity,
          },
        })
      } catch (e) {
        console.log(e.message)
      }
    } else {
      try {
        const shopsAlreadyInTheCart = cartItems.reduce((shopsAccumulator, currentCartItem) => {
          const { profile } = currentCartItem.product
          const exist = shopsAccumulator.find(profileId => profileId === profile.id)

          if (!exist) {
            shopsAccumulator.push(profile.id)
          }

          return shopsAccumulator
        }, [])
        const currentProductShopId = product.profile.id

        if (shopsAlreadyInTheCart.length >= MAX_SHOPS_PER_CART && !shopsAlreadyInTheCart.includes(currentProductShopId)) {
          alert({
            title: i18n.t('order.cart.maxShopsPerCartAlertTitle'),
            message: i18n.t('order.cart.maxShopsPerCartAlertMessage', {
              max: MAX_SHOPS_PER_CART,
            }),
          })
          this.doneAddingToCart()
          return
        }
      } catch (e) {
        console.log('maxShopPerCartValidation: ' + e.message)
        this.doneAddingToCart()
        return
      }

      //console.log('<<<PRODUCT - Adding product to cart for the first time')
      try {
        cartItem = await createCartItem({
          variables: {
            userId: userId,
            productId: navigation.state.params.id,
          },
        })
        this.props.analyticsAddedToCart(navigation.state.params.id)
      } catch (e) {
        console.log('createCartItem: ' + e.message)
        this.doneAddingToCart()
        return
      }
      //console.log('<<<PRODUCT - Setting product quantity')
      try {
        await updateCartItemQuantity({
          variables: {
            id: cartItem.data.createCartItem.id,
            quantity: parseFloat(this.state.quantity),
          },
        })
      } catch (e) {
        //console.log('<<<PRODUCT - Update quantity failed. Deleting cart item that was just created.')
        try {
          await deleteCartItem({
            variables: {
              id: cartItem.data.createCartItem.id,
            },
          })
        } catch (e) {
          this.doneAddingToCart()
          console.log('deleteCartItem: ' + e.message)
          return
        }
        this.doneAddingToCart()
        console.log('updateCartItemQuantity: ' + e.message)
        return
      }
    }

    await cartItemsByUserIdHandler.refetch()

    this.setState({ quantity: '1' })
    this._handleMinimumOrderAmountReached()
    this.doneAddingToCart()
  }

  doneAddingToCart = () => {
    this.setState({ addingToCart: false })
  }

  handleScroll = (e) => {
    // Keep track of the currently scrolled position and set the value for this.state.addToCartAnimation
    this.state.addToCartAnimation.top.setValue(e.nativeEvent.contentOffset.y)
  }

  getProduct = () => {
    const { productHandler } = this.props
    return _get(productHandler, 'productById', false)
  }

  isLoading = () => {
    const { productHandler } = this.props
    return productHandler.loading
  }

  getShop = () => {
    const { productHandler } = this.props
    return _get(productHandler, 'productById.profile', false)
  }

  renderLoading = () => {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator size='large' />
      </View>
    )
  }

  handleQuantityChange = (e) => {
    const { text } = e.nativeEvent

    if (!text.match(/[^0-9.,]/)) {
      this.setState({ quantity: text })
    }
  }

  renderFloatingButton = () => {
    const isAdmin = _get(this.props.currentUser, 'currentUser.isAdmin', false)
    // return null
    const product = this.getProduct()
    const { userId } = this.props

    if (
      !isAdmin &&
      (_get(product, 'profile.producerDetails.ownership.owner.id', '') !==
        userId ||
        product.isDiscontinued)
    ) {
      return null
    }

    return (
      <ActionButton
        style={{zIndex: 999}}
        size={54}
        spacing={10}
        buttonColor={colors.taggingText}
        offsetY={branch({ other: sizes.tabBarHeight, iphonex: sizes.iphonexTabBarHeight}) + 10}
        offsetX={10}
        btnOutRange='white'
        renderIcon={active => {
          return <Icon name='md-add' size={35} color={active ? 'black' : 'white'} />
        }}
      >
        <ActionButton.Item
          buttonColor={colors.taggingText}
          title={i18n.t('product.actionButtons.editProduct')}
          onPress={this.editProduct}
        >
          <Icon name='md-create' size={35} color='white' />
        </ActionButton.Item>
        <ActionButton.Item
          buttonColor={colors.taggingText}
          title={i18n.t('product.actionButtons.copyProduct')}
          onPress={this.copyProduct}
        >
          <Icon name='ios-copy' size={30} color='white' />
        </ActionButton.Item>
      </ActionButton>
    )
  }

  renderQuantityOptions = (quantityOptions) => {
    let options = []
    if (quantityOptions) {
      options = [...quantityOptions]
    }
    if (options.length === 0) {
      const quantityRange = _.range(1, 31)
      quantityRange.forEach((value) => {
        options.push(this.getQuantityOption(value))
      })
    }
    return options.map((option, index) => (
      <Picker.Item
        style={{ color: colors.text.main }}
        key={index}
        value={option.value}
        label={option.displayValue}
      />
    ))
  }

  getQuantityOption = (value) => {
    return {
      value,
      displayValue: `${value}`,
    }
  }

  setQuantity = (value) => {
    this.setState({ quantity: value })
  }

  setSelectedQuantity = (value) => {
    this.setState({
      quantity: value,
      selectedQuantity: value,
    })
  }

  resetQuantity = () => {
    const { selectedQuantity: quantity } = this.state
    this.setState({ quantity })
  }

  _handleMinimumOrderAmountReached = () => {
    const shop = this.getShop()
    const cartItemsByUserId = _.get(
      this.props,
      'cartItemsByUserIdHandler.cartItemsByUserId',
      [],
    )
    const itemsForStore = cartItemsByUserId.filter(
      (item) => _.get(item, 'product.profile.id') === shop.id,
    )
    const { subtotal } = getTotalPrices(
      itemsForStore,
      (cartItem) => cartItem.product,
    )
    const minimumOrderAmount = _get(
      shop,
      'producerDetails.minimumOrderAmount',
      0,
    )

    const reached = subtotal >= minimumOrderAmount

    if (reached === false) {
      const remain = minimumOrderAmount - subtotal
      alert({
        title: i18n.t('order.cart.minimumOrderNotMet.alertTitle'),
        message: i18n.t('order.cart.minimumOrderNotMet.body', {
          amount: minimumOrderAmount,
          remain,
        }),
      })
    }

    return reached
  }

  productNotAvailable = () => {
    return (
      <View
        style={{
          width: '100%',
          paddingVertical: 25,
          backgroundColor: colors.lightGray,
        }}
      >
        <Text
          style={{
            color: colors.text.mainLighter,
            fontSize: 22,
            fontWeight: 'bold',
            paddingHorizontal: 32,
            textAlign: 'center',
          }}
        >
          {i18n.t('errors.common.notAvailable')}
        </Text>
      </View>
    )
  }

  render() {
    const product = this.getProduct()
    if (!this.isLoading() && !product) {
      return this.productNotAvailable()
    }
    const shop = this.getShop()
    if (this.isLoading()) {
      return this.renderLoading()
    }
    const { quantity, selectedQuantity } = this.state
    const isAndroid = Platform.OS === 'android'
    const dropDownContainerStyle = isAndroid
      ? styles.androidDropdownActionContainer
      : undefined
    const dropdownStyle = isAndroid
      ? styles.androidDropdownAction
      : styles.dropdownAction

    const { screenInfo, pendingProductEdit } = this.props
    const {
      // ratingCount,
      // rating,
      // quantityUnits,
      isInStock,
      isDiscontinued,
      profile,
    } = product

    // const awardsText = _.get(product, 'awards.text')
    const tabLabelStyle = {
      width: screenInfo.contentWidth / 3 - 30,
      textAlign: 'center',
    }

    const minimumOrderAmount =
      _.get(shop, 'producerDetails.minimumOrderAmount') || 0
    const productDescription = _get(product, 'description', '')
    const awardsCarouselHasImagesToShow = _.get(product, 'awards.images', []).length !== 0
    const awardsCarouselHasTextToShow =  _.get(product, 'awards.description')

    const deliveryLocationsOnly = _.get(product, 'deliveryLocationsOnly')
    const deliveryLocations = _.get(shop, 'producerDetails.deliveryLocations')
    const shopStatus = _.get(profile, 'status', OBJECT_STATUSES.active)

    const unitProduct = unitPlural(product)

    return (
      <>
        {this.renderFloatingButton()}
        <Scroller
          onMomentumScrollEnd={this.handleScroll}
          onScrollEndDrag={this.handleScroll}
        >
          <View style={styles.bannerContainer}>
            <ShopProfileBanner style={styles.shopBanner} shop={this.getShop()} />
            <Button
              style={styles.recommend}
              labelStyle={styles.recommendLabel}
              // disabled={_get(product, 'isDiscontinued', false)}
              label={i18n.t('product.recommendButton')}
              onPress={this.shareProduct}
            />
            <ProductOptions product={product} />
          </View>
          <ImageCarousel
            images={_get(product, 'uploads', [])}
            resizeMode='contain'
            fit='contain'
            pendingRefresh={!!pendingProductEdit}
            navigation={this.props.navigation}
          />
          <View style={styles.productDetails}>
            <Text style={styles.productTitle}>
              {_get(product, 'name', '')}
            </Text>
            {!!unitProduct && (
              <Text style={styles.unitTitle}>
                {unitProduct}
              </Text>
            )}
            {this.renderPricing()}
            {this.renderSavings()}
            <Text style={styles.includesTax}>
              {i18n.t('product.includesTax')}
            </Text>
            <Ratings
              size={15}
              ratings={product.ratingCount}
              rating={product.rating}
            />
          </View>
          { deliveryLocations && deliveryLocationsOnly ? (
            <View style={styles.deliveryLocationsContainer}>
              <Text style={styles.deliveryLocationsText}>
                {i18n.t('deliveryLocations.productView.locationRestriction', { locations: deliveryLocations})}
              </Text>
            </View>
          ) : null }
          <View style={styles.actionsContainer}>
            <View style={dropDownContainerStyle}>
              <PickerFB
                style={dropdownStyle}
                displayValue={`${quantity}`}
                selectedValue={quantity}
                onValueChange={branch(
                  {
                    ios: this.setQuantity,
                    android: this.setSelectedQuantity,
                    web: this.setSelectedQuantity,
                  },
                  true,
                )}
                onCancel={branch({ ios: this.resetQuantity }, true)}
                onSubmit={branch({ ios: this.setSelectedQuantity }, true)}
              >
                {this.renderQuantityOptions()}
              </PickerFB>
            </View>
            <PreventDoubleClickButton
              disabled={!isInStock || isDiscontinued  || shopStatus !== OBJECT_STATUSES.active}
              label={i18n.t('product.actionButtons.addToCart')}
              style={[styles.primaryButton, styles.actionsButton]}
              onPress={this.handleAddToCart}
            />
            <Button
              // disabled={!isInStock || isDiscontinued}
              disabled // disabled for now.
              label={i18n.t('product.actionButtons.buyNow')}
              style={[
                styles.buyNow,
                styles.actionsButton,
                styles.actionsDropdown,
              ]}
              onPress={() => {}}
            />
          </View>
          { _.get(productDescription, 'value') ? (
            <ExpandingSection
              size={20}
              label={i18n.t('product.sectionLabels.description')}
              defaultOpen={!isWeb}
              ref={(ref) => this.setExpandingSectionRef(ref, 'description')}
            >
              <Categories name={'diet'} product={product} withDivider={!_.isEmpty(productDescription)}/>
              <TagText content={productDescription}/>
            </ExpandingSection>
          ) : null }
          {  awardsCarouselHasImagesToShow || awardsCarouselHasTextToShow  ? (
            <ExpandingSection
              size={20}
              label={i18n.t('product.sectionLabels.awards')}
              ref={(ref) => this.setExpandingSectionRef(ref, 'awards')}
              initialHeight={ awardsCarouselHasImagesToShow ? this.state.awardsCarouselHeight + ( awardsCarouselHasTextToShow ? 50 : 0 ): 0 }
              alwaysOpen={ awardsCarouselHasImagesToShow && !awardsCarouselHasTextToShow }
            >
              <View>
                <AwardsCarousel
                  images={_get(product, 'awards.images', null)}
                  onLayout={this.onAwardsCarouselLayout}
                  scale={0.5}
                  pendingRefresh={!!pendingProductEdit}
                />
                <Text style={[styles.awardsDescription]}>
                  {_get(product, 'awards.description', 'No Awards.')}
                </Text>
              </View>
            </ExpandingSection>
          ) : null }
          { (_.get(product, 'categories.allergens') || []).length || (_.get(product, 'categories.freeFrom') || []).length || _.get(product, 'ingredients.value') ? (
            <ExpandingSection
              size={20}
              label={i18n.t('product.sectionLabels.ingredients')}
              ref={(ref) => this.setExpandingSectionRef(ref, 'ingredients')}
            >
              <Categories name={'allergens'} product={product} withDivider/>
              <Categories name={'freeFrom'} product={product} withDivider/>
              {/* <Text>{_get(product, 'ingredients', '')}</Text> */}
              <TagText content={_get(product, 'ingredients', '')} />
            </ExpandingSection>
          ) : null }
          <ExpandingSection
            size={20}
            label={i18n.t('product.sectionLabels.policies')}
          >
            <View style={styles.policiesDivider} />
            <Text style={styles.policiesTitle}>
              {i18n.t('product.sectionLabels.shippingTitle')}
            </Text>
            <Text>
              {/* Shipping fees */}
              <Text>
                <Text>{i18n.t('product.shippingFeeLabel')}</Text>
                {getPrice(_get(shop, 'producerDetails.standardShippingFee', ''))}
                {'\n'}
              </Text>
              <Text>
                {(() => {
                  // Conditionally render out the required price for shipping discount value if it exists.
                  const value = _get(
                    shop,
                    'producerDetails.freeShippingThreshold',
                    false,
                  )
                  if (value) {
                    return (
                      <Text>
                        <Text>{i18n.t('product.freeShippingLabel')}</Text>
                        {getPrice(_get(shop, 'producerDetails.freeShippingThreshold', ''))}
                        {'\n'}
                      </Text>
                    )
                  } else {
                    return null
                  }
                })()}
              </Text>
              {minimumOrderAmount > 0 && (
                <Text>
                  <Text>{i18n.t('shop.tabs.policies.minimumOrderAmount')}: </Text>
                  {getPrice(minimumOrderAmount)}
                  {'\n'}
                </Text>
              )}
            </Text>
            <Text>
              {_get(shop, 'producerDetails.policies.shipping.value', '')}
            </Text>
            <View style={styles.policiesDivider} />
            <Text style={styles.policiesTitle}>
              {i18n.t('product.sectionLabels.returnsTitle')}
            </Text>
            <Text>
              {_get(shop, 'producerDetails.policies.returns.value', '')}
            </Text>
          </ExpandingSection>
          <View>
            {/* putting everything after the expanding view inside one parent View fixed the animation performaace issue */}
            <Button
              disabled
              label={i18n.t('product.chatButton')}
              style={[styles.primaryButton, styles.askAQuestion]}
              onPress={() => {}}
            />
            <TabView size={15} titleStyle={tabLabelStyle}>
              <TabView.Tab label={i18n.t('product.tabLabels.posts')}>
                <View>
                  <View>{this.renderPosts(this.getPosts('post'))}</View>
                  <Button
                    disabled={!this.state.postMore}
                    label={i18n.t('product.viewMoreButton')}
                    style={{
                      marginHorizontal: 30,
                      marginVertical: 20,
                      backgroundColor: colors.primary,
                    }}
                    onPress={this.fetchMorePosts}
                  />
                </View>
              </TabView.Tab>
              <TabView.Tab label={i18n.t('product.tabLabels.reviews')}>
                <View>
                  <View>{this.renderReviews(this.getReviews())}</View>
                  <Button
                    disabled={!this.state.reviewsMore}
                    label={i18n.t('product.viewMoreButton')}
                    style={{
                      marginHorizontal: 30,
                      marginVertical: 20,
                      backgroundColor: colors.primary,
                    }}
                    onPress={this.fetchMoreReviews}
                  />
                </View>
              </TabView.Tab>
              <TabView.Tab label={i18n.t('product.tabLabels.recipes')}>
                <View>
                  <View>{this.renderRecipes(this.getPosts('recipe'))}</View>
                  <Button
                    disabled={!this.state.recipeMore}
                    label={i18n.t('product.viewMoreButton')}
                    style={{
                      marginHorizontal: 30,
                      marginVertical: 20,
                      backgroundColor: colors.primary,
                    }}
                    onPress={this.fetchMoreRecipes}
                  />
                </View>
              </TabView.Tab>
            </TabView>
            <View>
              {shop && (
                <>
                  <Divider />
                  <RelatedProductsByShop
                    shop={shop}
                    exceptProduct={product.id}
                  />
                </>
              )}
            </View>
          </View>
          <Animated.View
            pointerEvents='none'
            style={[
              {
                height: this.state.addToCartAnimation.height,
                position: 'absolute',
                top: this.state.addToCartAnimation.top,
                opacity: this.state.addToCartAnimation.opacity,
                width: this.state.addToCartAnimation.width,
                borderRadius: this.state.addToCartAnimation.radius,
                alignSelf: 'center',
                transform: [{ translateY: this.state.addToCartAnimation.y }],
                overflow: 'hidden',
              },
              styles.primaryButton,
            ]}
          />
        </Scroller>
      </>
    )
  }

  editProduct = () => {
    if (this.props.pendingProductEdit) {
      alert({
        message: i18n.t('publishContent.alert.pendingContent')
      })
      return
    }
    this.resetExpandingSectionHeights()
    NavigationActions.navigate({
      routeName: 'CreateProduct',
      params: {
        shopId: _get(this.props, 'productHandler.productById.profile.id', ''),
        // productHandler: props.productHandler,
        productId: _get(this.props, 'productHandler.productById.id', ''),
      },
    })
  }

  copyProduct = () => {
    NavigationActions.navigate({
      routeName: 'CreateProduct',
      params: {
        shopId: _get(this.props, 'productHandler.productById.profile.id', ''),
        // productHandler: props.productHandler,
        productId: _get(this.props, 'productHandler.productById.id', ''),
        copy: true,
      },
    })
  }

  shareProduct = () => {
    const id = _.get(this.props, 'navigation.state.params.id')
    const contentLink = getContentLink('product', id)
    const product = this.getProduct()
    const message = i18n.t('sharing.product', {
      shopName: _.get(product, 'profile.displayName'),
      productName: product.name,
      link: contentLink,
    })
    Share.share({ message })
  }

  onAwardsCarouselLayout = (e) => {
    if (!this.state.awardsCarouselHeightSet) {
      const awardsCarouselHeight = e.nativeEvent.layout.height
      this.setState((prevState) => ({
        ...prevState,
        awardsCarouselHeightSet: true,
        awardsCarouselHeight,
      }))
    }
  }

  resetExpandingSectionHeights = () => {
    for (const key in this.expandingSectionRefs) {
      const ref = this.expandingSectionRefs[key]
      if (!ref) {
        //console.log('<<<PRODUCT - Skipping reset of section -', key)
        continue
      }
      ref.resetDidSetHeightOpen()
    }
  }
}

const styles = stylus({
  bottomPadding: {
    height: sizes.tabBarHeight,
    iphonex: {
      height: sizes.iphonexTabBarHeight,
    },
  },
  bannerContainer: {
    margin: 10,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  shopBanner: {},
  recommend: {
    alignSelf: 'center',
    backgroundColor: colors.primary,
    padding: 8,
  },
  recommendLabel: {
    fontSize: 12,
  },
  productTitle: {
    fontSize: 19,
    marginTop: 12,
    marginBottom: 4,
  },
  unitTitle: {
    fontSize: 14,
    marginBottom: 4,
  },
  notAvailable: {
    color: colors.text.light,
    fontSize: 22,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  textItemPrice: {
    fontSize: 18,
  },
  textItemPriceDiscount: {
    color: colors.text.main,
  },
  textItemUnavailable: {
    color: colors.red,
  },
  textItemVAT: {
    color: colors.inactive,
  },
  textStrike: {
    fontSize: 14,
    color: colors.text.secondary,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
    // marginLeft: 10,
  },
  textNoStrike: {
    textDecorationLine: 'none',
    fontWeight: '500',
    marginRight: 10,
  },
  productDetails: {
    marginHorizontal: 16,
  },
  savings: {
    fontSize: 12,
    color: colors.green,
    marginTop: 4,
  },
  includesTax: {
    fontSize: 12,
    color: colors.text.secondary,
  },
  primaryButton: {
    backgroundColor: colors.primaryDark,
  },
  buyNow: {
    backgroundColor: colors.vino,
  },
  actionsContainer: {
    paddingHorizontal: 16,
    paddingTop: 10,
  },
  actionsButton: {
    marginTop: 10,
  },
  actionsDropdown: {
    marginBottom: 10,
  },
  androidDropdownActionContainer: {
    backgroundColor: '#F7F7F7',
    borderRadius: 5,
  },
  androidDropdownAction: {
    opacity: 1,
  },
  dropdownAction: {
    paddingLeft: 10,
    borderRadius: 5,
    borderBottomWidth: 0,
    backgroundColor: '#F7F7F7',
  },
  bodySectionContainer: {
    paddingVertical: 10,
  },
  bodyTitle: {
    fontWeight: '600',
    fontSize: 18,
    marginBottom: 5,
  },
  awardsDescription: {
    paddingTop: 20,
    // paddingHorizontal: 10,
  },
  policiesTitle: {
    fontSize: 15,
    fontWeight: '600',
  },
  policiesDivider: {
    flex: 1,
    height: 20,
  },
  askAQuestion: {
    marginVertical: 25,
    alignSelf: 'center',
    flex: 0,
  },
  addToCartAnimation: {
    position: 'absolute',
  },
  deliveryLocationsContainer: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  deliveryLocationsText: {
    color: colors.error,
    fontSize: 16,
    fontWeight: '600',
  }
})

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
  videos: state.video,
})

const mapDispatchToProps = (dispatch) => ({
  analyticsAddedToCart: (productId) => {
    dispatch({
      type: ANALYTICS_ADDED_TO_CART_EVENT,
      data: {
        productId,
      },
    })
  },
  analyticsCartsWithProductsCreated: () => {
    dispatch({
      type: ANALYTICS_CARTS_WITH_PRODUCTS_CREATED,
      data: {},
    })
  },
})

Product.propTypes = {
  productRecipes: PropTypes.any,
  productPosts: PropTypes.any,
  reviewsHandler: PropTypes.any,
  navigation: PropTypes.any,
  analyticsAddedToCart: PropTypes.func,
  analyticsCartsWithProductsCreated: PropTypes.func,
  analyticsProductPageVisit: PropTypes.func,
}

// export default Shop

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Product)
