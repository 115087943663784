import _ from 'lodash'

const initialState = {
  shortcode: '',
  users: [],
}

export default function newConversation(state, action) {
  if (!state) {
    return initialState
  }
  let index, newState
  switch (action.type) {
    case 'NewConversation/SET_USERS':
      return {
        ...state,
        users: action.users,
      }
    case 'NewConversation/RESET':
      return initialState
    default:
      return state
  }
}
