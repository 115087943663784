import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { connect } from 'react-redux'
import colors from '../../config/colors'

class Metric extends React.Component {
  render() {
    const { label, style } = this.props
    return (
      <View style={[styles.container, style]}>
        <Text style={styles.label}>{label}</Text>
        {this.content()}
      </View>
    )
  }
  content() {
    const { value, unit, valueStyle } = this.props
    if (value) {
      return (
        <Text style={[styles.metric, valueStyle]}>
          {value}
          {unit && <Text style={styles.unit}>{unit}</Text>}
        </Text>
      )
    } else {
      return this.props.children
    }
  }
  unit() {
    const { unit } = this.props
    i
  }
}

export default Metric

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    paddingRight: 12,
    marginVertical: 7,
  },
  metric: {
    fontSize: 17,
    fontWeight: 'normal',
    color: colors.text.main,
    paddingTop: 2,
  },
  unit: {
    fontSize: 13,
  },
  label: {
    fontSize: 11,
    color: colors.text.secondary,
    fontWeight: '200',
  },
})
