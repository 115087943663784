import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { connect } from 'react-redux'
import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'

class BigStatistic extends React.Component {
  render() {
    const {
      statistic,
      label,
      width,
      unit,
      invert,
      valueStyle,
      dark,
      style,
    } = this.props
    return (
      <View style={[styles.container, style]}>
        <Text
          style={[
            styles.statistic,
            invert && styles.statisticInvert,
            valueStyle,
          ]}
        >
          {statistic}
          {unit && <Text style={styles.unit}>{unit}</Text>}
        </Text>
        <Text
          style={[
            styles.label,
            invert && styles.labelInvert,
            dark && styles.darkLabel,
          ]}
        >
          {label}
        </Text>
      </View>
    )
  }
  unit() {
    const { unit } = this.props
    i
  }
}

export default BigStatistic

const styles = stylus({
  container: {
    alignSelf: 'stretch',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  statistic: {
    fontSize: 42,
    fontWeight: '500',
    color: colors.text.main,
    paddingTop: 2,
    portrait: {
      fontSize: 24,
    },
  },
  statisticInvert: {
    color: 'white',
  },
  label: {
    fontSize: 13,
    color: '#777',
    fontWeight: '200',
    marginTop: -3,
    paddingBottom: 5,
    portrait: {
      fontSize: 12,
    },
  },
  darkLabel: {
    color: '#333',
    fontWeight: 'normal',
  },
  labelInvert: {
    color: 'white',
  },
  unit: {
    fontSize: 18,
    marginLeft: 1,
    portrait: {
      fontSize: 15,
    },
  },
})
