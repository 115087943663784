import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { connect } from 'react-redux'
import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'

class PageTitle extends React.Component {
  render() {
    const { layout, landscape, portrait } = this.props
    if (layout === 'landscape' && landscape !== false) {
      return this.title()
    }
    if (portrait !== false) {
      return this.title()
    }
    return null
  }
  title() {
    const { style, text, layout, size, children, center } = this.props
    return (
      <View
        style={[
          containerStyles.container,
          containerStyles[size],
          center && containerStyles.center,
        ]}
      >
        <Text style={[labelStyles.label, labelStyles[size], style]}>
          {text}
        </Text>
        {children && (
          <View style={[actionStyles.container, actionStyles[size]]}>
            {children}
          </View>
        )}
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  layout: state.screenInfo.layout,
})

const mapDispatchToProps = (dispatch) => ({
  // onResize: () => dispatch({ type: 'SCREEN_RESIZE' }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageTitle)

const containerStyles = stylus({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 10,
    // paddingHorizontal: 14,
    portrait: {},
  },
  big: {
    marginTop: 20,
    marginBottom: 3,
  },
  center: {
    justifyContent: 'center',
  },
})
const labelStyles = stylus({
  label: {
    color: colors.text.main,
    fontSize: 24,
    fontWeight: 'bold',
    flex: 1,
    letterSpacing: -0.5,
  },
  big: {
    fontSize: 28,
    paddingBottom: 7,
    // lineHeight: 42,
  },
})
const actionStyles = stylus({
  container: {},
  big: {
    // paddingTop: 7,
    // marginBottom: -4,
    // lineHeight: 42,
  },
})
