import { AsyncStorage } from 'react-native'
import {
  client as apolloClient,
  resetWebsocket,
} from '../containers/withApollo'

const debug = false

export const USER_SET_PROFILE_IMAGE_UPLOAD = 'currentUser/SET_PROFILE_IMAGE_UPLOAD'
export const USER_UNSET_PROFILE_IMAGE_UPLOAD = 'currentUser/UNSET_PROFILE_IMAGE_UPLOAD'

export default function currentUser(state = {}, action) {
  const { id, firstTime } = action
  switch (action.type) {
    case 'HYDRATE':
      if (action.item === 'currentUser') {
        return {
          ...state,
          id,
        }
      }
      return state
    case 'LOGIN':
      // setTimeout(apolloClient.resetStore, 10) // Why are we killing the store as soon as we login?
      return {
        ...state,
        id,
        firstTime,
      }
    case 'LOGOUT':
      AsyncStorage.clear()
      resetWebsocket(null)
      return {}
    case 'GHOST':
      AsyncStorage.multiSet([
        ['USER_TOKEN', action.token],
        ['USER_ID', action.userId],
        ['GHOST', 'true'],
      ])
      setTimeout(apolloClient.resetStore, 200)
      return {
        id: action.userId,
      }
    case USER_SET_PROFILE_IMAGE_UPLOAD:
      debug && console.log('<<<currentUser - USER_SET_PROFILE_IMAGE_UPLOAD', action.profileImageUpload)
      return {
        ...state,
        profileImageUpload: action.profileImageUpload,
      }
    case USER_UNSET_PROFILE_IMAGE_UPLOAD:
      debug && console.log('<<<currentUser - USET_UNSET_PROFILE_IMAGE_UPLOAD')
      const { profileImageUpload, ...rest } = state
      return rest
    default:
      return state
  }
}
