import React from 'react'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'

import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import T from '../../components/T'

export default ({ onPress, label, containerStyle, labelStyle, t }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.container, containerStyle]}
    >
      <T style={[styles.label, labelStyle]} t={t} />
    </TouchableOpacity>
  )
}

const styles = stylus({
  container: {
    paddingVertical: 30,
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  label: {
    color: colors.text.main,
    fontSize: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
