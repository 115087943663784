import React from 'react'
import FeedLogo from './FeedLogo'
import {
  View,
  FlatList,
  Platform,
  TouchableOpacity,
  Alert
} from 'react-native'
import ActionButton from 'react-native-action-button'
import FAIcon from 'react-native-vector-icons/FontAwesome5'
import Icon from '@expo/vector-icons/Ionicons'
import i18n from 'i18n-js'
import PostFB from '../../components/PostFB'
import TextInputFB from '../../components/simple/TextInput'
import Scroller from '../../components/Scroller'
import NavigationActions from '../../utility/navigationActions'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'
import branch from '../../config/branch'
import {
  connect,
  graphql,
  compose,
} from '../../config/connected'
import _ from 'lodash'
import PostRecipe from '../../components/PostFB/PostRecipe'
import TrendingItems from '../../components/Feed/TrendingItems'
import { client } from '../../containers/withApollo'
import { FEED_SET_REFRESH_FUNCTION } from '../../reducers/feed'
const PAGE_SIZE = 16
import { POST_TYPES } from '../../config/constants'
import schema from '../../schema/post'
import {
  SEARCH_OBJECT_PRODUCTS,
  ROUTE_USER_ORDER_HISTORY,
} from '../../config/constants'
import MapStrip from './MapStrip'
import PendingContent from '../../components/PostFB/PendingContent'
import { stopAllVideos } from '../../utility/video'
import { getLayout } from '../../routing/selectLayout'
import NoticeBar from '../../components/simple/NoticeBar'

const debug = false

const renderHeader = (trendingImageWidth, goToProductMap, setTrendingRef, goToProfile) => {
  return (
    <>
    <MapStrip
      onMapPress={goToProductMap}
    />
    <TrendingItems
      imageWidth={trendingImageWidth}
      key={'trendingItems'}
      innerRef={setTrendingRef}
      goToProfile={goToProfile}
      // loadingHandler={trendingLoadingHandler}
    />
    <PendingContent types={['post', 'recipe']}/>
  </>
  )
}

const renderPost = (item, refreshing) => {
  const PostType = item.type === 'recipe' ? PostRecipe : PostFB
  return <PostType post={item} key={item.id} refreshing={refreshing}/>
}

let goToSearch = () => {}

class FeedFB extends React.Component {
  constructor(props) {
    super(props)
    this.viewabilityConfig = {
      minimumViewTime: 100,
      waitForInteraction: false,
      itemVisiblePercentThreshold: 50,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    const { setFeedRefreshFunction } = this.props
    if (getLayout() !== 'web/portrait') {
      this.onBlurListener = this.props.navigation.addListener('willBlur', () => stopAllVideos(this.props.videos))
      this.onFocusListener = this.props.navigation.addListener('willFocus', (payload) => {
        debug && console.log('FEED PAYLOAD', payload)
        if (_.get(payload, 'state.params.scrollToTop')) {
          this.props.navigation.setParams({ scrollToTop: false })
          if (this.scroller.scrollToOffset) {
            this.scroller.scrollToOffset({ // for Native Flatlist
              offset: 0,
              animated: true,
            })
          } else {
            this.scroller.scrollTo({ x: 0, y: 0, animated: true }) // for WEB Scrollview
          }
        }
      })
    }
    setFeedRefreshFunction(this.refreshFeedFromTabs)
    goToSearch = this.props.goToSearch
    this.newsfeedSubscription = client
      .subscribe({
        query: schema.subscriptions.postAdded,
      })
      .subscribe({
        next(newPost) {
          console.log({ newPost })
        },
        error(err) {
          console.log(`Error Subscribing: ${err.message}`)
        },
      })
  }

  componentWillUnmount() {
    this.onBlurListener && this.onBlurListener.remove()
    this.onFocusListener && this.onFocusListener.remove()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(!_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)) {
      return true
    }
    return false
  }
  trendingImageWidth = (this.props.screenInfo.contentWidth / 3) * 1.25
  state = {
    refreshing: false,
    currentPostItem: 0,
    // isPostsLoaded: false,
    // isTrendingLoaded: false,
  }
  scroller = null

  static navigationOptions = (props) => {
    // For whatever reason the header for this screen is still shown when navigating to other sibling screens in the MyHome stack navigator.
    const activeRoute = NavigationActions.getActiveRoute() || { routeName: '' }
    return {
      headerShown: ['Home', 'Feed'].indexOf(activeRoute.routeName) !== -1 || !activeRoute.routeName,
      header: () => (
        <View style={styles.headerContainer}>
          <View style={styles.header}>
            <FeedLogo style={styles.logo}/>
            <TouchableOpacity style={styles.searchBarContainer} onPress={goToSearch}>
              <TextInputFB
                iconName='ios-search'
                iconStyle={styles.searchBarIcon}
                editable={false}
                placeholder={i18n.t('feed.searchBarTitle')}
                size={10}
                thin
                pointerEvents='none'
                style={styles.searchInputWrapper}
                inputStyle={styles.searchInput}
                underlineColorAndroid='transparent'
              />
            </TouchableOpacity>
            {/* <View style={{flexDirection: 'row'}}>
              <Invite containerStyle={{paddingHorizontal: 0}} />
              <NewChat
                containerStyle={{paddingHorizontal: 0}}
                onPress={() =>
                  dispatch(NavigationActions.navigate({ routeName: 'Chat' }))
                }
              />
            </View> */}
          </View>

        </View>
      ),
    }
  }
  // static getDerivedStateFromProps = (props, state) => {
  //   if(!props.newsfeed.loading && !state.isPostsLoaded) {
  //     return {
  //       isPostsLoaded: true,
  //     }
  //   }
  //   return state
  // }

  loadMore = () => {
    if (this.loadingMore || this.noMore) {
      return
    }
    this.loadingMore = true
    const { dispatch, newsfeed: newsfeedHandler } = this.props
    let last = this.getLastSeen(newsfeedHandler.newsfeed)

    newsfeedHandler.fetchMore({
      variables: { last, limit: PAGE_SIZE },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.loadingMore = false
        if (!fetchMoreResult || fetchMoreResult.newsfeed.length === 0) {
          this.noMore = true
          return previousResult
        }
        const newPosts = _.differenceBy(
          fetchMoreResult.newsfeed,
          previousResult.newsfeed,
          'id',
        )
        return {
          newsfeed: previousResult.newsfeed.concat(newPosts),
        }
      },
    })
  }

  getLastSeen(newsfeed) {
    const lastSeen = {}
    POST_TYPES.forEach((postType) => {
      const seenAt = this.getLastSeenOf(postType, newsfeed)
      if (seenAt) {
        lastSeen[postType] = seenAt
      }
    })
    return lastSeen
  }

  getLastSeenOf(postType, newsfeed) {
    const post = _.findLast(newsfeed, (post) => post.postType === postType)
    return post ? post.createdAt : false
  }

  refresh = () => {
    this.trendingSection && this.trendingSection.refetchTrendingProducts()
    // this is just to smooth things
    this.setState({ refreshing: true })
    this.noMore = false
    this.props.newsfeed.refetch()
    setTimeout(() => {
      this.setState({ refreshing: false })
    }, 800)
  }

  refreshFeedFromTabs = () => {
    if (this.scroller.scrollToOffset) {
      this.scroller.scrollToOffset({ // for Native Flatlist
        offset: 0,
        // viewPosition: 0,
        duration: 500,
        animated: true,
      })
    } else {
      this.scroller.scrollTo({ x: 0, y: 0, animated: true }) // for WEB Scrollview
    }
    this.refresh()
    // console.log('scroller::::', Object.keys(this.scroller))
    // if (this.scroller) {
    // }
  }

  setListRef = (ref) => {
    this.scroller = ref
  }

  setTrendingRef = (ref) => {
    this.trendingSection = ref
  }

  getPosts = () => {
    return _.get(this.props, 'newsfeed.newsfeed', [])
  }

  onNewReviewPress = () => {
    const { currentUser, goToUserOrders } = this.props
    Alert.alert(
      i18n.t('feed.newReview.title'),
      i18n.t('feed.newReview.description'),
      [
        { text: i18n.t('feed.newReview.openOrderHistory'), onPress: () => goToUserOrders(currentUser.id) },
        { text: i18n.t('common.cancel'), onPress: () => {}, style: 'destructive', },
      ],
      { cancelable: true },
    )
  }

  render() {
    return Platform.OS === 'web' ? this.renderWeb() : this.renderNative()
  }

  renderWeb() {
    const { screenInfo } = this.props
    return (
      <Scroller
        loadMore={this.loadMore}
        innerRef={this.setListRef}
      >
        <TrendingItems
          goToProfile={this.props.goToProfile}
          imageWidth={this.trendingImageWidth}
          innerRef={this.setTrendingRef}
        />
        {this.getPosts().map((post) =>
          renderPost(post, this.state.refreshing),
        )}
      </Scroller>
    )
  }
  
  async handleScroll ({nativeEvent}) {
    if (nativeEvent) {
      const offsetY = nativeEvent.contentOffset.y
      const screenHeight = nativeEvent.layoutMeasurement.height
      const currentScreen = Math.floor(offsetY / screenHeight)

      if(currentScreen !== this.state.currentPostItem) {
        stopAllVideos(this.props.videos)
        this.setState(prev => ({...prev, currentPostItem: currentScreen}))
      }
    }
  }
  renderNative() {
    const { screenInfo } = this.props
    const { isPostsLoaded, isTrendingLoaded } = this.state
    const { paddingLeft, paddingRight } = screenInfo
    return (
      <View
        style={styles.container}
        behavior='padding'
        contentContainerStyle={{ flex: 1 }}
        keyboardVerticalOffset={branch({
          iphone: 72,
          android: 81,
          other: 0,
          iphonex: 94,
        })}
      >
        <View style={{ flex: 1 }}>
          <FlatList
            onScroll={this.handleScroll}
            scrollEventThrottle={160}
            data={this.getPosts()}
            renderItem={({item}) => renderPost(item, this.state.refreshing)}
            keyboardDismissMode='on-drag'
            ref={this.setListRef}
            refreshing={
              this.state.refreshing || this.props.newsfeed.networkStatus === 4
            }
            initialNumToRender={1}
            removeClippedSubviews={true}
            onRefresh={this.refresh}
            ListHeaderComponent={renderHeader(this.trendingImageWidth, this.props.goToProductMap, this.setTrendingRef, this.props.goToProfile)}
            keyExtractor={item => item.id}
            contentContainerStyle={{
              paddingLeft,
              paddingRight,
            }}
            onEndReachedThreshold={0.4}
            onEndReached={this.loadMore}
          />
          <ActionButton
            size={54}
            spacing={10}
            buttonColor={colors.taggingText}
            offsetY={10}
            offsetX={10}
            btnOutRange='white'
            renderIcon={active => {
              return <Icon name='md-add' size={35} color={active ? 'black' : 'white'} />
            }}
          >
            <ActionButton.Item
              buttonColor={colors.taggingText}
              title={i18n.t('nav.createPost.newReview')}
              onPress={this.onNewReviewPress}
            >
              <Icon name='ios-star' size={25} color='white' />
            </ActionButton.Item>
            <ActionButton.Item
              buttonColor={colors.taggingText}
              title={i18n.t('nav.createPost.newRecipe')}
              onPress={this.props.goToRecipeCreate}
            >
              <FAIcon name='utensils' size={25} color='white' />
            </ActionButton.Item>
            <ActionButton.Item
              buttonColor={colors.taggingText}
              title={i18n.t('nav.createPost.newPost')}
              onPress={this.props.goToPostCreate}
            >
              <Icon name='ios-camera' size={35} color='white' />
            </ActionButton.Item>
          </ActionButton>
        </View>
        <NoticeBar />
      </View>
    )
  }
}

const styles = stylus({
  container: {
    flex: 1, 
    marginBottom: sizes.tabBarHeight,
    iphonex: {
      marginBottom: sizes.iphonexTabBarHeight,
    }
  },
  feedPadding: {
    padding: 12,
  },
  bottomPadding: {
    height: sizes.tabBarHeight,
    iphonex: {
      height: sizes.iphonexTabBarHeight,
    },
  },
  logo: {
    android: {
      width: 'auto'
    }
  },
  searchBarContainer: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 25,
    marginHorizontal: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.20,
    shadowRadius: 1.4,
    elevation: 4,
  },
  searchInputWrapper: {
    // flex: 1,
    // alignSelf: 'stretch',
    height: 44,
    web: {
      width: '100%',
    },
    borderRadius: 25,
    backgroundColor: 'white',
    paddingRight: 0,
  },
  searchInput: {
    flex: 2,
    height: 37,
    fontSize: 15,
    textAlign: 'left',
    color: colors.text.main,
  },
  searchBarIcon: {
    color: colors.text.main,
  },
  headerContainer: {
    backgroundColor: 'white',
    paddingBottom: 10,
    paddingHorizontal: 10,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    web: {
      paddingTop: 15,
    },
    iphonex: {
      paddingTop: sizes.TOP_BAR_HEIGHT
    },
    android: {
      paddingTop: sizes.iosStatusBarHeight - 20
    },
    paddingTop: sizes.tabBarHeight - 15,
    backgroundColor: 'white',
  }
})

const mapStateToProps = (state) => ({
  location: state.location,
  screenInfo: state.screenInfo,
  currentUser: state.currentUser,
  videos: state.video
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setFeedRefreshFunction: (func) => {
    dispatch({
      type: FEED_SET_REFRESH_FUNCTION,
      payload: { refreshFeed: func },
    })
  },
  goToProfile: () => {
    NavigationActions.navigate({
      routeName: 'EditProfile',
      params: { scrollTo: 'bottom' },
    })
  },
  goToProductMap: () => {
    NavigationActions.navigate({
      routeName: 'Maps',
      params: { objectType: SEARCH_OBJECT_PRODUCTS },
    })
  },
  goToSearch: () => {
    NavigationActions.navigate({
      routeName: 'ObjectSearch',
    })
  },
  goToPostCreate: () => {
    NavigationActions.navigate({
      routeName: 'PostCreate',
      params: {
        backRouteName:'Home',
      }
    })
  },
  goToRecipeCreate: () => {
    NavigationActions.navigate({
      routeName: 'PostCreate',
      params: { 
        isRecipe: true,
        backRouteName:'Home',
      },
    })
  },
  goToUserOrders: (id) => {
    NavigationActions.navigate({
      routeName: ROUTE_USER_ORDER_HISTORY,
      params: { userId: id },
    })
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(schema.queries.newsfeed, {
    name: 'newsfeed',
    options: {
      notifyOnNetworkStatusChange: true,
      variables: {limit: 5},
    },
  }),
)(FeedFB)
