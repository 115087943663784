
import React from 'react'
import { StatusBar, Platform, View, Text } from 'react-native'
import PropTypes from 'prop-types'

import { withCurrentUserOnInterval } from './withCurrentUser'
const currentUserEveryTwoMinutes = withCurrentUserOnInterval(1000 * 60 * 2)
import Modal from '../components/simple/Modal'
import Button from '../components/simple/Button'
import Terms from '../screens/Start/Terms'
import Brand from '../components/Brand/Brand'

import colors from '../config/colors'
import sharedStyles, { stylus } from '../config/styles'
import { graphql, connect } from '../config/connected'
import user from '../schema/user'
import alert from '../utility/alert'

import i18n from 'i18n-js'
@connect((state) => {
  return {
    screenInfo: state.screenInfo || { width: 0 }
  }
})
@currentUserEveryTwoMinutes
@graphql(user.mutations.acceptTerms, { name: 'acceptTerms' })
class TermsModalManager extends React.Component {
  state = {
    showModal: false,
    acceptTerms: false
  }

  static propTypes = {
    screenInfo: PropTypes.object,
    currentUser: PropTypes.object,
    acceptTerms: PropTypes.func
  }

  onChange = (value, field) => {
    this.setState({ [field]: value })
  }

  getUser() {
    const { currentUser: currentUserHandler } = this.props
    if (currentUserHandler.loading || currentUserHandler.error) {
      return
    }
    const { currentUser } = currentUserHandler
    return currentUser
  }

  componentDidUpdate(prevProps, prevState) {
    const user = this.getUser()
    if (!user) {
      return
    }

    if ((user.acceptedTerms !== undefined && user.acceptedTerms === false) && !this.state.showModal) {
      this.setState({ showModal: true })
      return
    }

    // NOTE: if terms were accepted on another device, then we need to hide the modal on this one
    if (user.acceptedTerms && this.state.showModal) {
      this.setState({ acceptTerms: false, showModal: false })
    }
  }

  onAndroidBackButton = () => {
    // TODO: Do nothing since we don't want the Modal to close
  }

  acceptTerms = async () => {
    const user = this.getUser()
    if (!user) {
      return
    }
    if (!this.state.acceptTerms) {
      alert({
        title: i18n.t('onboardingUser.terms.title'),
        message: i18n.t('errors.acceptTerms.unchecked')
      })
      return
    }
    try {
      await this.props.acceptTerms()
      this.setState({ showModal: false, acceptTerms: false })
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { screenInfo } = this.props
    let width = 0
    if (screenInfo) {
      width = screenInfo.width / 2
    }
    return (
      <Modal animationType='slide' visible={this.state.showModal} onRequestClose={this.onAndroidBackButton}>
        { Platform.OS === 'android' ?
          <StatusBar backgroundColor="rgba(0,0,0,0.5)"/>
          : null 
        }
        <View style={styles.contentContainer}>
          <Brand width={width} vertical/>
          <Text style={styles.message}>{i18n.t('onboardingUser.terms.reaccept')}</Text>
          <Terms 
            style={styles.terms}
            onChange={this.onChange}
            state={this.state}
          />
          <Button
            label={i18n.t('common.accept')}
            style={styles.button}
            labelStyle={styles.buttonLabel}
            onPress={this.acceptTerms}
          />
        </View>
      </Modal>
    )
  }
}

const styles = stylus({
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 15,
  },
  message: {
    fontSize: 16,
    color: colors.text.main,
    textAlign: 'center',
    marginTop: 15,
  },
  terms: {
    marginTop: 15,
  },
  button: {
    backgroundColor: colors.primary,
    marginTop: 15,
  },
  buttonLabel: {
    fontSize: 16,
  },
})

export default TermsModalManager
