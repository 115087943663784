import enUS from './en-US'
import itIT from './it-IT'

const messages = {
  en: enUS,
  it: itIT,
}

export default function getLocaleMessages(locale) {
  //TODO: Implement proper i18n fallback locales.
  return messages[locale] || messages.en
}
