import React, { Component } from 'react'
import { Linking, ScrollView, Text, View, Platform } from 'react-native'
import LocationView from '../../../../components/LocationView'
import { stylus } from '../../../../config/styles'
import i18n from 'i18n-js'

import environment from '../../../../config/environment'
import colors from '../../../../config/colors'
import T from '../../../../components/T'
import ElegantInput from '../../../../components/simple/ElegantInput'
import Button from '../../../../components/simple/Button'
import KeyboardShift from '../../../../containers/KeyboardShift'
import {
  bindableFieldErrorFunc,
  bindableFieldErrorsFunc,
  isNumeric,
  isValidCoords,
  isValidEmail,
  isValidTitle,
  isWebsite,
  isYear,
} from '../../../../config/validate'
import services from '../../../../utility/services'
import {
  SHOP_FIELD_EMAIL,
  SHOP_FIELD_FOUNDED,
  SHOP_FIELD_FREE_SHIPPING_THRESHOLD,
  SHOP_FIELD_GEO,
  SHOP_FIELD_MINIMUM_ORDER_AMOUNT,
  SHOP_FIELD_SHOP_NAME,
  SHOP_FIELD_STANDARD_SHIPPING_FEE,
  SHOP_FIELD_WEBSITE,
} from '../../../../config/constants'
import alert from '../../../../utility/alert'

const fieldsConfig = {
  [SHOP_FIELD_SHOP_NAME]: {
    validate: isValidTitle,
    errorName: 'invalidName',
  },
  [SHOP_FIELD_FOUNDED]: {
    validate: isYear,
    errorName: 'invalidYear',
    optional: true,
  },
  // [SHOP_FIELD_ADDRESS]: {
  //   validate: isValidAddress,
  //   errorName: 'invalidAddress',
  // },
  // [SHOP_FIELD_CITY]: {
  //   validate: isValidAddress,
  //   errorName: 'invalidCity',
  // },
  // [SHOP_FIELD_REGION]: {
  //   validate: isValidAddress,
  //   errorName: 'invalidRegion',
  // },
  // [SHOP_FIELD_COUNTRY]: {
  //   validate: isValidName,
  //   errorName: 'invalidCountry',
  // },
  [SHOP_FIELD_GEO]: {
    validate: isValidCoords,
    errorName: 'invalidAddress',
  },
  [SHOP_FIELD_WEBSITE]: {
    validate: isWebsite,
    errorName: 'invalidWebsite',
    optional: true,
  },
  [SHOP_FIELD_EMAIL]: {
    validate: isValidEmail,
    errorName: 'invalidEmail',
    optional: false,
  },
  [SHOP_FIELD_STANDARD_SHIPPING_FEE]: {
    validate: isNumeric,
    errorName: 'invalidNumber',
  },
  [SHOP_FIELD_FREE_SHIPPING_THRESHOLD]: {
    validate: isNumeric,
    errorName: 'invalidNumber',
    optional: true,
  },
  [SHOP_FIELD_MINIMUM_ORDER_AMOUNT]: {
    validate: isNumeric,
    errorName: 'invalidNumber',
    optional: true,
  },
}
export class Details extends Component {
  constructor(props) {
    super(props)
    this.fieldError = bindableFieldErrorFunc(
      fieldsConfig,
      'props.shopInfo',
      undefined,
      undefined,
      'errors.onboardingShop',
      'onboardingShop.fields',
    ).bind(this)
    this.fieldErrors = bindableFieldErrorsFunc(
      this.fieldError,
      'props.shopInfo',
      'onboardingShop.fields',
    ).bind(this)

    this.state = {
      visibleMapModal: false,
      webGeo: {
        // default initial marker position at Spokane - TODO: replace with navigator.geolocation from browser
        lat: 47.658779,
        lng: -117.426048
      }
    }
  }

  componentDidMount() {
    const { registerScreen } = this.props
    if (registerScreen) {
      registerScreen({ screen: 'Details', fieldSetValidator: this.fieldErrors })
    }
    // navigator.geolocation.getCurrentPosition(async position => {
    //   const { latitude: lat, longitude: lng } = position.coords

    // })
  }

  handleSubmit = () => {
    const message = this.fieldErrors()
    if (message) {
      alert({ title: 'Errors', message })
    } else {
      this.props.goToPage('Review')
    }

  }

  handleFieldChange = (value, target) => {
    const error = this.fieldError(value, target)
    this.changeField(value, target, error)
  }

  validateWebsite = () => {
    const { shopInfo } = this.props
    this.handleFieldChange(shopInfo[SHOP_FIELD_WEBSITE], SHOP_FIELD_WEBSITE)
  }

  changeField(value, target) {
    const { handleFieldChange } = this.props
    handleFieldChange(value, target)
  }

  inputRefs = {}

  onLocationSelect = (location) => {
    console.log('onLocationSelect', location)
    this.props.onLocationSelect(location)
    this.setState({visibleMapModal: false})
  }

  onMapButtonPress = async() => {
    const result = await services.getUserLocationAsync()
    if (result === -1) {
      return
    }
    this.setState({visibleMapModal: true})
  }

  onCloseMapPress = () => {
    this.setState({visibleMapModal: false})
  }

  getFormattedAddress = () => {
    const { address, city, region, country, zip, state } = this.state
    return [address, city, region, state, zip, country].filter(Boolean).join(', ')
  }

  renderMapModal = () => {
    const {visibleMapModal, webGeo} = this.state
    const {shopInfo: {geo}} = this.props
    const isWeb = Platform.OS === 'web'

    if (visibleMapModal) {
      return (
        <LocationView
          apiKey={environment.google.geoKey}
          initialLocation={{
            latitude: isWeb ? webGeo.lat : geo.lat,
            longitude: isWeb ? webGeo.lng : geo.lng,
          }}
          address={this.getFormattedAddress()}
          visible={visibleMapModal}
          onClosePress={this.onCloseMapPress}
          onLocationSelect={this.onLocationSelect}
        />
      )
    }
    return null
  }

  render() {
    const MAX_FIELD_LENGTH = 50
    const { shopInfo, goToPage, errors, asView } = this.props
    const { handleFieldChange } = this
    const _ScrollView = asView ? View : ScrollView
    const _KeyboardShift = asView ? View : KeyboardShift
    let buttonLabel = shopInfo.formattedAddress ?
      i18n.t('onboardingShop.details.editShopAddress')
      : i18n.t('onboardingShop.details.setShopAddress')
    return (
      <View style={{flex: 1}}>
        <_ScrollView>
          <_KeyboardShift fluid>
            <View style={styles.container}>
              <T t='onboardingShop.details.title' style={styles.title} />
              {!asView && (
                <T t='onboardingShop.details.body' style={styles.body} />
              )}
              {
                shopInfo.formattedAddress ? (
                  <ElegantInput
                    containerStyle={{marginTop: 16}}
                    editable={false}
                    label={i18n.t('onboardingShop.details.address')}
                    multiline
                    value={shopInfo.formattedAddress}
                  />
                ) : null
              }

              <Button
                label={buttonLabel}
                style={styles.mapButton}
                onPress={this.onMapButtonPress}
              />
              <ElegantInput
                textContentType='organizationName'
                returnKeyType='next'
                onSubmitEditing={() => {
                  this.inputRefs.founded.focus()
                }}
                reference={(ref) => (this.inputRefs.shopName = ref)}
                label={i18n.t('onboardingShop.details.shopName')}
                value={shopInfo.shopName}
                error={errors.shopName}
                maxLength={MAX_FIELD_LENGTH}
                onChange={(value) => {
                  handleFieldChange(value, SHOP_FIELD_SHOP_NAME)
                }}
              />
              <ElegantInput
                returnKeyType='next'
                keyboardType='number-pad'
                onSubmitEditing={() => {
                  this.inputRefs[SHOP_FIELD_EMAIL].focus()
                }}
                reference={(ref) => (this.inputRefs.founded = ref)}
                label={i18n.t('onboardingShop.details.founded')}
                value={shopInfo.founded}
                error={errors.founded}
                maxLength={4}
                onChange={(value) => {
                  handleFieldChange(value, SHOP_FIELD_FOUNDED)
                }}
              />
              {/* <ElegantInput
                textContentType='fullStreetAddress'
                returnKeyType='next'
                onSubmitEditing={() => {
                  this.inputRefs.city.focus()
                }}
                reference={(ref) => (this.inputRefs.address = ref)}
                label={i18n.t('onboardingShop.details.address')}
                value={shopInfo.address}
                error={errors.address}
                maxLength={MAX_FIELD_LENGTH}
                onChange={(value) => {
                  handleFieldChange(value, SHOP_FIELD_ADDRESS)
                }}
              />
              <ElegantInput
                textContentType='addressCity'
                returnKeyType='next'
                onSubmitEditing={() => {
                  this.inputRefs.region.focus()
                }}
                reference={(ref) => (this.inputRefs.city = ref)}
                label={i18n.t('onboardingShop.details.city')}
                value={shopInfo.city}
                error={errors.city}
                maxLength={MAX_FIELD_LENGTH}
                onChange={(value) => {
                  handleFieldChange(value, SHOP_FIELD_CITY)
                }}
              />
              <ElegantInput
                // {...regionDynamicProps}
                textContentType='addressState'
                returnKeyType='next'
                reference={(ref) => (this.inputRefs.region = ref)}
                label={i18n.t('onboardingShop.details.region')}
                value={shopInfo.region}
                error={errors.region}
                maxLength={MAX_FIELD_LENGTH}
                onChange={(value) => {
                  handleFieldChange(value, SHOP_FIELD_REGION)
                }}
              />
              <MyPicker
                style={[styles.picker]}
                label={i18n.t('onboardingShop.details.country')}
                selectedValue={shopInfo.country}
                ref={(ref) => (this.inputRefs.country = ref)}
                onValueChange={(value) =>
                  handleFieldChange(value, SHOP_FIELD_COUNTRY)
                }
                displayValue={_.get(countries[shopInfo.country], `name_${i18n.locale}`)}
              >
                {_.map(countries, (country) => {
                  return <Picker.Item label={country[`name_${i18n.locale}`]} key={country.key} value={country.key}/>
                })}
              </MyPicker> */}
              <ElegantInput
                keyboardType='email-address'
                autoCorrect={false}
                returnKeyType='next'
                autoCapitalize='none'
                label={i18n.t('onboardingShop.details.email')}
                onSubmitEditing={() => {
                  this.inputRefs[SHOP_FIELD_WEBSITE].focus()
                }}
                reference={(ref) => (this.inputRefs[SHOP_FIELD_EMAIL] = ref)}
                value={shopInfo.email}
                error={errors.email}
                maxLength={MAX_FIELD_LENGTH}
                onChange={(value) => {
                  this.changeField(value, SHOP_FIELD_EMAIL)
                }}
              />
              <ElegantInput
                textContentType='URL'
                returnKeyType='next'
                autoCapitalize='none'
                label={i18n.t('onboardingShop.details.website')}
                onSubmitEditing={() => {
                  this.inputRefs[SHOP_FIELD_STANDARD_SHIPPING_FEE].focus()
                }}
                reference={(ref) => (this.inputRefs.website = ref)}
                value={shopInfo.website}
                error={errors.website}
                maxLength={MAX_FIELD_LENGTH}
                onChange={(value) => {
                  this.changeField(value, SHOP_FIELD_WEBSITE)
                }}
                onBlur={() => {
                  this.validateWebsite()
                }}
              />
              <T t='onboardingShop.details.ratesTitle' style={styles.title} />
              <ElegantInput
                keyboardType='decimal-pad'
                returnKeyType='next'
                onSubmitEditing={() => {
                  this.inputRefs[SHOP_FIELD_FREE_SHIPPING_THRESHOLD].focus()
                }}
                reference={(ref) =>
                  (this.inputRefs[SHOP_FIELD_STANDARD_SHIPPING_FEE] = ref)
                }
                label={i18n.t(
                  `onboardingShop.details.${SHOP_FIELD_STANDARD_SHIPPING_FEE}`,
                )}
                value={shopInfo[SHOP_FIELD_STANDARD_SHIPPING_FEE]}
                error={errors[SHOP_FIELD_STANDARD_SHIPPING_FEE]}
                maxLength={15}
                onChange={(value) => {
                  handleFieldChange(value, SHOP_FIELD_STANDARD_SHIPPING_FEE)
                }}
                suffix={({tintColor}) => (
                  <Text style={{ color: tintColor, fontSize: 20, fontWeight: 'bold'}}>€</Text>
                )}
              />
              <ElegantInput
                keyboardType='decimal-pad'
                returnKeyType='next'
                onSubmitEditing={() => {
                  if (!asView) {
                    this.handleSubmit()
                  }
                }}
                reference={(ref) =>
                  (this.inputRefs[SHOP_FIELD_FREE_SHIPPING_THRESHOLD] = ref)
                }
                label={i18n.t(
                  `onboardingShop.details.${SHOP_FIELD_FREE_SHIPPING_THRESHOLD}`,
                )}
                value={shopInfo[SHOP_FIELD_FREE_SHIPPING_THRESHOLD]}
                error={errors[SHOP_FIELD_FREE_SHIPPING_THRESHOLD]}
                maxLength={15}
                onChange={(value) => {
                  handleFieldChange(value, SHOP_FIELD_FREE_SHIPPING_THRESHOLD)
                }}
                suffix={({tintColor}) => (
                  <Text style={{ color: tintColor, fontSize: 20, fontWeight: 'bold'}}>€</Text>
                )}
              />
              <ElegantInput
                keyboardType='decimal-pad'
                returnKeyType='next'
                onSubmitEditing={() => {
                  if (!asView) {
                    this.handleSubmit()
                  }
                }}
                reference={(ref) =>
                  (this.inputRefs[SHOP_FIELD_MINIMUM_ORDER_AMOUNT] = ref)
                }
                label={i18n.t(
                  `onboardingShop.details.${SHOP_FIELD_MINIMUM_ORDER_AMOUNT}`,
                )}
                value={shopInfo[SHOP_FIELD_MINIMUM_ORDER_AMOUNT]}
                error={errors[SHOP_FIELD_MINIMUM_ORDER_AMOUNT]}
                maxLength={15}
                onChange={(value) => {
                  handleFieldChange(value, SHOP_FIELD_MINIMUM_ORDER_AMOUNT)
                }}
                suffix={({tintColor}) => (
                  <Text style={{ color: tintColor, fontSize: 20, fontWeight: 'bold'}}>€</Text>
                )}
              />
              {!asView && (
                <>
                  <Button
                    label={i18n.t('onboardingShop.next')}
                    style={styles.next}
                    onPress={this.handleSubmit}
                  />
                  <Text style={styles.body}>
                    <T t='onboardingShop.details.terms1' />
                    <T
                      t='onboardingShop.details.termsTerms'
                      style={[styles.terms]}
                      onPress={() => Linking.openURL(i18n.t('onboardingShop.details.termsLink'))}
                    />
                    {', '}
                    <T
                      t='onboardingShop.details.termsData'
                      style={[styles.terms]}
                      onPress={() => Linking.openURL(i18n.t('onboardingShop.details.termsDataLink'))}
                    />
                    {', '}
                    <T t='onboardingShop.details.terms2' />
                    <T
                      t='onboardingShop.details.termsCookie'
                      style={[styles.terms]}
                      onPress={() => Linking.openURL(i18n.t('onboardingShop.details.termsCookieLink'))}
                    />
                    .
                  </Text>
                </>
              )}
            </View>
          </_KeyboardShift>
        </_ScrollView>
        {this.renderMapModal()}
      </View>
    )
  }
}

export default Details

const styles = stylus({
  container: {
    alignItems: 'center',
    padding: 40,
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    paddingVertical: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  paymentContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  checkboxWithIcon: {
    alignItems: 'center',
  },
  paymentText: {
    fontSize: 22,
    fontWeight: 'bold',
    margin: 10,
  },
  body: {
    fontSize: 16,
    color: colors.text.secondary,
    margin: 10,
    textAlign: 'center',
  },
  mapButton: {
    width: '100%',
    backgroundColor: colors.primary,
  },
  next: {
    width: '100%',
    marginVertical: 20,
    backgroundColor: colors.primary,
  },
  terms: {
    color: colors.taggingText
  },
  picker: {
    web: {
      width: '100%',
      color: colors.input.text,
    },
    width: '100%',
  },
})
