import { gql } from '../config/connected'

export default {
  queries: {
    algoliaSearch: gql`
      query ($input: AlgoliaSearchInput!, $returnNoResults: Boolean) {
        algoliaSearch(input: $input, returnNoResults: $returnNoResults)
      }
    `
  },
  mutations: {}
}
