import { Platform } from 'react-native'
import { NavigationActions } from '@react-navigation/core'
import { StackActions } from 'react-navigation'
import _ from 'lodash'

import reduxStoreHolder from './reduxStoreHolder'
import {
  ROUTE_ORDER_CONFIRMATION,
  ROUTE_USER_ORDER_HISTORY,
  ROUTE_SHOP_ORDER_HISTORY,
  ROUTE_USER_ORDER_DETAILS,
  ROUTE_SHOP_ORDER_DETAILS,
  ROUTE_USER_OBJECT_TYPE_REVIEW,
} from '../config/constants'
import { notificationRouteNames } from '../routing/routes/NotificationRoutes'

const HIDE_BOTTOM = [
  'Posting',
  'Chat',
  'Conversation',
  'ChooseMedia',
  'PostLikesViewer',
  'PostGallery',
  'PostComments',
  'NewConversation',
  'PostCreate',
  'EditPost',
  'EditProfile',
  'AddressesList',
  'EditUserAddress',
  'FollowersWidgetListViewer',
  'OnboardingShop',
  'RecipeDetails',
  'Shopping',
  'Cart',
  'Shopping',
  'Checkout',
  'ChatWithUser',
  'StripeConnect',
  'AdminManageAliases',
  ROUTE_ORDER_CONFIRMATION,
  ROUTE_USER_ORDER_HISTORY,
  ROUTE_SHOP_ORDER_HISTORY,
  ROUTE_USER_ORDER_DETAILS,
  ROUTE_SHOP_ORDER_DETAILS,
  ROUTE_USER_OBJECT_TYPE_REVIEW,
  ...notificationRouteNames,
]

const notificationScreenPrefix = 'NotificationScreen'

const debug = false
const isWeb = Platform.OS === 'web'
let _navigator

function getNavState() {
  return _.get(_navigator, 'state.nav', {})
}

function getRoute(nav = {}, options = { parentRouteName: undefined, returnParent: false, returnIsTransitioning: false }){
  if (options.returnIsTransitioning && nav.isTransitioning) {
    return nav
  }
  if (Array.isArray(nav.routes) && nav.routes.length > 0 && (options.parentRouteName !== nav.routeName || nav.routeName === undefined)) {
    const childRoute = nav.routes[nav.index]
    if (!childRoute.routes && options.returnParent) {
      return nav
    }
    return getRoute(childRoute, options)
  } else if (!options.returnIsTransitioning && (!options.parentRouteName || options.parentRouteName === nav.routeName)) {
    return nav // is the leaf route or the parent route
  } else {
    return undefined
  }
}

function getLastRouteKey(nav, lastRouteKey = '') {
  if (!nav) {
    return lastRouteKey
  }

  let latestRouteKey = lastRouteKey
  let latestRouteKeyIndex = getNavigationKeyIndex(lastRouteKey)

  const determineLaterRouterKey = (routeKey) => {
    const routeKeyIndex = getNavigationKeyIndex(routeKey)
    if (routeKeyIndex > latestRouteKeyIndex) {
      latestRouteKeyIndex = routeKeyIndex
      latestRouteKey = routeKey
    }
  }

  if (nav.routes) {
    for (const route of nav.routes) {
      const routeKey = getLastRouteKey(route, lastRouteKey)
      determineLaterRouterKey(routeKey)
    }
  } else if (isNavigationKey(nav.key)) {
    determineLaterRouterKey(nav.key)
  }
  
  return latestRouteKey
}

function isNavigationKey(key = '') {
  const [id, idNumber, index] = key.split('-')
  return id === 'id' && idNumber && index
}

function getNavigationKeyIndex(key = '') {
  const [id, idNumber, index] = key.split('-')
  return index ? index * 1 : -1
}

export default {
  setTopLevelNavigator(navigatorRef) {
    _navigator = navigatorRef
    const store = reduxStoreHolder.getStore()
    store.dispatch({ type: 'ROOT_NAVIGATOR_REF_SET' })
  },
  getTopLevelNavigator() {
    return _navigator
  },
  navigate(routeConfig, allowDuringRouteTransition = isWeb ) {
    const transitioningRoute = getRoute(getNavState(), { returnIsTransitioning: true })
    if (transitioningRoute && !allowDuringRouteTransition) {
      debug && console.log('<<<navigationActions - navigate - transition already in progress')
      return // don't navigate if route transition already occurring
    }
    const routeAction = StackActions.push(routeConfig)
    if (!_navigator) return
    _navigator.dispatch(routeAction)
  },
  goTo(routeConfig, allowDuringRouteTransition = isWeb) {
    const transitioningRoute = getRoute(getNavState(), { returnIsTransitioning: true })
    if (transitioningRoute && !allowDuringRouteTransition) {
      debug && console.log('<<<navigationActions - goTo - transition already in progress')
      return // don't navigate if route transition already occurring
    }
    const routeAction = NavigationActions.navigate(routeConfig)
    if (!_navigator) return
    _navigator.dispatch(routeAction)
  },
  replace(params) {
    if (!_navigator) return
    _navigator.dispatch(StackActions.replace(params))
  },
  back() {
    if (isWeb) {
      window.history.back()
    } else {
      if (!_navigator) return
      _navigator.dispatch(NavigationActions.back())
    }
  },
  getNavState,
  getActiveRoute(navState = getNavState(), options) {
    return getRoute(navState, options)
  },
  getRoute: (routeName, customNavState, options = {}) => {
    const updatedOptions = Object.assign(options, { parentRouteName: routeName })
    return getRoute(customNavState || _.get(_navigator, 'state.nav', {}), updatedOptions)
  },
  getNextRouteKey: (nav) => {
    const lastRouteKey = getLastRouteKey(nav)
    let [id, idNumber, index] = lastRouteKey.split('-')
    return [id, idNumber, ++index].join('-')
  },
  dispatch(action) {
    if (!_navigator) return
    _navigator.dispatch(action)
  },
  hideBottom(routeName = _.get(this.getActiveRoute(), 'routeName', '')) {
    return _.includes(HIDE_BOTTOM, routeName)
  },
  getLeafAction(action) {
    let childAction = action
    while (childAction.action) {
      childAction = childAction.action
    }
    return childAction
  },
  getNonNotificationRouteName(routeName) {
    let finalRouteName = routeName
    if (routeName.indexOf(notificationScreenPrefix) !== -1) {
      const startIndex = notificationScreenPrefix.length
      const regularRouteName = routeName.substring(startIndex)
      finalRouteName = regularRouteName
    }
    return finalRouteName
  },
  getNotificationRouteName(routeName) {
    let finalRouteName = routeName
    if (routeName.indexOf(notificationScreenPrefix) === -1) {
      finalRouteName = notificationScreenPrefix + routeName
    }
    return finalRouteName
  },
  didActiveTabChange(prevNavState, currNavState) {
    const prevTabIndex = _.get(prevNavState, 'routes.[1].index', -1)
    const currTabIndex = _.get(currNavState, 'routes.[1].index', -1)
    return prevTabIndex !== currTabIndex
  },
  isInitialTabRoute(navState) {
    const tabState = this.getActiveTabRoute(navState)
    return _.get(tabState, 'index', -1) === 0
  },
  getActiveTabRoute(navState) {
    const tabIndex = _.get(navState, 'routes.[1].index', -1)
    return _.get(navState, `routes.[1].routes[${tabIndex}]`, { index: -1, routes: [], routeName: '' })
  },
}
