import { AsyncStorage, Dimensions, Platform, View } from 'react-native'
import React from 'react'
import Constants from 'expo-constants'
import FeedLogo from '../screens/FeedFB/FeedLogo'
import i18n from 'i18n-js'
import _ from 'lodash'
import moment from 'moment'
import nameToUrl from './nameToUrl'
import branch from './branch'
import * as ImagePicker from 'expo-image-picker'
import * as Permissions from 'expo-permissions'
import environment from './environment'
import colors from './colors'
import { renderComponent, branch as rBranch } from 'recompose'
import unitTypes from './unitTypes'
import reduxStoreHolder from '../utility/reduxStoreHolder'
import * as FileSystem from '../utility/fileSystem'
import alert from '../utility/alert'

import  countries from './countries'

export const CURRENCY = 'EUR'
const LOCALE = 'it-IT'
// TODO: Pull locale from app directly.

// Used to clarify that you are currently viewing a NON-production version of the app.
export const getTabBarIconTintColor = (tintColor, focused) => {
  const deployTarget = environment.environment

  const DEV_TINT_COLOR = colors.primaryContrast
  const QA_TINT_COLOR = colors.green
  const UAT_TINT_COLOR = colors.primary
  const PROD_TINT_COLOR = tintColor
  let returnColor

  switch (deployTarget) {
    case 'development':
      returnColor = DEV_TINT_COLOR
      break
    case 'qa':
      returnColor = QA_TINT_COLOR
      break
    case 'uat':
      returnColor = UAT_TINT_COLOR
      break
    case 'production':
      returnColor = PROD_TINT_COLOR
      break
    default:
      returnColor = PROD_TINT_COLOR
  }

  return returnColor
}

export const iconSize = () => {
  const { width, height } = Dimensions.get('window')
  const size = width > height ? 30 : 34
  return size
}

//https://github.com/react-navigation/react-navigation/issues/253
export const mergeNavOptionsForFeedLogo = (navOptions = {}) => {
  navOptions.headerTitle = () => <FeedLogo />
  return mergeNavOptionsForHeader(navOptions)
}

export const mergeNavOptionsForHeader = (navOptions = {}) => {
  // Mutate navOptions accordingly
  return navOptions
}

export const getFormattedUsername = (username = '') => {
  return _.deburr(username.replace(/[^a-zA-Z0-9_.]/g, '')).toLowerCase()
}

export const getFormattedInviteCode = (username = '') => {
  return _.deburr(username.replace(/^[^A-Za-z0-9]+|[^A-Za-z0-9]+$/g, '')).toLowerCase()
}

export const getPrice = (price) => {
  if (price === undefined || price === null) {
    price = 0
  }
  const rounded = roundTwoPlaces(price)
  // React Native iOS and Web are up to date on supporting toLocaleString
  if (Platform.OS !== 'android') {
    let currency = rounded.toLocaleString(LOCALE, {
      style: 'currency',
      currency: CURRENCY,
    })
    return currency.replace(/\s/g, '')
  }
  // React Native Android support of toLocaleString is not. Due to older version of JSC. So we manually format the price.
  // https://github.com/facebook/react-native/issues/19410
  // TODO: Remove the following when support has finally arrived for it on React Native Android.
  let currency = i18n.l('currency', rounded)
  const parts = currency.split('.')
  let whole = parts[0]
  let fraction = parts[1] || '00'
  whole = _.trimStart(whole, '$')
  whole = whole.replace(',', i18n.t('common.currencySeparator'))
  currency = whole + i18n.t('common.currencyDecimal') + fraction
  if (i18n.locale === 'en') {
    currency = i18n.t('common.currencySymbol') + currency
  } else {
    currency = currency + i18n.t('common.currencySymbol')
  }
  return currency
}

export const getPurchasePrice = (price) => {
  if (price === undefined || price === null) {
    price = 0
  }
  const rounded = roundTwoPlaces(price)
  let currency = i18n.l('currency', rounded)
  const parts = currency.split('.')
  let whole = parts[0]
  let fraction = parts[1] || '00'
  whole = _.trimStart(whole, '$')
  whole = whole.replace(',', i18n.t('common.currencySeparator'))
  currency = whole + i18n.t('common.currencyDecimal') + fraction
  if (i18n.locale === 'en') {
    currency = 'USD' + ' ' + currency
  } else {
    currency = CURRENCY + ' ' + currency
  }
  return currency
}

// NOTE: Price does not include currency symbol or separator. Just the decimal character for the locale and digits
export function fromLocalePriceToRealNumber(localePrice) {
  if (localePrice === '') {
    return null
  }
  // Decimal character should be the last non number character of the string
  const decimalCharacter = _.findLast(
    localePrice.split(''),
    (character) => !isNumber(parseFloat(character)),
  )
  if (!decimalCharacter) {
    return parseFloat(localePrice) // Is whole number. No need to modify string.
  }
  // Clean both sides of the real number before putting whole and fraction back together with EN decimal symbol
  const parts = localePrice.split(decimalCharacter)
  let whole = (parts[0] || '0').replace(/[^0-9]/g, '')
  let fraction = (parts[1] || '00').replace(/[^0-9]/g, '')
  return parseFloat(whole + '.' + fraction)
}

// NOTE: This is from a real number to a locale price where the decimal character is the proper one between the digits
export function fromRealNumberToLocalePrice(realNumber) {
  if (!isNumber(realNumber)) {
    return ''
  }
  const realNumberStr = realNumber.toString()
  const parts = realNumberStr.split('.')
  let whole = parts[0]
  let fraction = parts[1] || '00'
  return whole + i18n.t('common.currencyDecimal') + fraction
}

export const roundTwoPlaces = (number) => {
  if (isString(number)) {
    number = Number.parseFloat(number)
  }
  return Number.parseFloat(number.toFixed(2))
}

export const getNumber = (number = 0) => {
  if (number === null) {
    number = 0
  }
  return number.toLocaleString(LOCALE)
}

export const parseQuantityValueAndUnit = (data) => {
  const unitRegex = /[a-z]*$/gi
  const results = unitRegex.exec(data)
  const unit = results[0]
  const value = data.replace(unitRegex, '')
  return { value, unit }
}

export const getTotalPrices = (items, getProduct, forShop, coupon) => {
  let subtotal = 0
  let discounts = 0
  let shipping = 0
  let previousShop = null
  let previousShopTotal = 0
  let amountForFreeShipping = -1

  for (const item of items) {
    const { quantity } = item
    const product = getProduct(item)
    const { profile } = product
    if (profile.id !== _.get(previousShop, 'id')) {
      if (previousShop) {
        shipping += getShopShipping(previousShop, previousShopTotal)
      }
      previousShop = profile
      previousShopTotal = 0
    }
    let itemTotal = product.price * quantity
    subtotal += itemTotal
    if (product.priceDiscount) {
      const discount = (product.price - product.priceDiscount) * quantity
      itemTotal -= discount
      discounts += discount
    }
    previousShopTotal += itemTotal
  }

  // Get shipping for last shop
  if (previousShop) {
    shipping += getShopShipping(previousShop, previousShopTotal)
    if (forShop) {
      amountForFreeShipping = getShopAmountLeftForFreeShipping(
        previousShop,
        previousShopTotal,
      )
    }
  }
  const subTotalAfterDiscounts = roundTwoPlaces(subtotal - discounts)
  const couponSavings = roundTwoPlaces((_.get(coupon, 'percent', 0) / 100) * subTotalAfterDiscounts)
  const subTotalAfterCoupon = roundTwoPlaces(subTotalAfterDiscounts - couponSavings)
  const total = roundTwoPlaces(subTotalAfterCoupon + shipping)
  const totalWithoutCoupon = roundTwoPlaces(subTotalAfterDiscounts + shipping)
  return {
    subtotal,
    subTotalAfterDiscounts,
    subTotalAfterCoupon,
    discounts,
    couponSavings,
    shipping,
    amountForFreeShipping, // only applies to summary totals for single shop
    total,
    totalWithoutCoupon,
  }
}

export const getShopShipping = (shop, shopTotal) => {
  const amount = getShopAmountLeftForFreeShipping(shop, shopTotal)
  if (amount) {
    return _.get(shop, 'producerDetails.standardShippingFee', 0)
  }
  return 0
}

export const getShopAmountLeftForFreeShipping = (shop, shopTotal) => {
  const freeThreshold = _.get(shop, 'producerDetails.freeShippingThreshold')
  if (freeThreshold) {
    const amount = freeThreshold - shopTotal
    return amount > 0 ? amount : 0
  }
  return -1 // reflects that we still have to pay for shipping
}

// Use the i18n function provided by i18n-js
// Using this funciton makes it impossible to find missing translations
// within the app.

// export const  = (pathToString, stringVariables = {}) => {
//   let translation = ''
//   try {
//     translation = i18n.t(pathToString, stringVariables)
//     if (translation.indexOf('missing') !== -1) {
//       throw new Error(translation)
//     }
//   } catch (e) {
//     // console.log(e)
//     const updatedStringVariables = { ...stringVariables, locale: 'en'}
//     translation =  i18n.t(pathToString, updatedStringVariables)
//   }
//   return translation
// }

export const getUTCDate = (dateString, endOfDay = false) => {
  const localeDate = new Date(dateString)
  if (endOfDay) {
    return localeDate.setUTCHours(23, 59, 59, 999)
  }
  return localeDate.setUTCHours(0, 0, 0, 0)
}

export const getDateTime = (dateString) => {
  return moment(dateString).format('LLL')
}

export const getDate = (dateString) => {
  return moment(dateString).format('LL')
}

export const titleCase = (text) => {
  return text.replace(/\w\S/g, function(t) {
    return t.toUpperCase()
  })
}

const getImageFromName = (image, index) => {
  return {
    ...image,
    index: index ? index : null,
    mime: image.mime,
    width: image.width,
    height: image.height,
    uri: image.uri
      ? image.uri
      : nameToUrl({
        name: image.name,
        width: image.width,
        height: image.height,
      }),
  }
}

export async function getPermission(type) {
  if (!(type in Permissions)) {
    throw new Error(`Invalid permission type requested. Expected one of: [
      'AUDIO_RECORDING'
      'CALENDAR'
      'CAMERA'
      'CAMERA_ROLL'
      'CONTACTS'
      'LOCATION'
      'NOTIFICATIONS'
      'REMINDERS'
      'REMOTE_NOTIFICATIONS'
      'SYSTEM_BRIGHTNESS'
      'USER_FACING_NOTIFICATIONS'
    ]`)
  }

  const token = await Permissions.getAsync(Permissions[type])
  if (token.status === 'granted') {
    return true
  } else {
    return false
  }
}

export async function openCamera() {
  const token = await getPermission('CAMERA')
  let file
  if (token) {
    file = await ImagePicker.launchCameraAsync({
      mediaTypes: 'images',
      quality: 0.2,
    })
  } else {
    throw new Error('Could not access camera: Permission Denied')
  }

  return file
}

export async function openCameraRoll() {
  const token = await getPermission('CAMERA_ROLL')
  let file
  if (token) {
    file = await ImagePicker.launchImageLibraryAsync({
      // mediaTypes: 'images',
      quality: 0.2,
    })
  } else {
    throw new Error('Could not access image library: Permission Denied')
  }

  return file
}

export async function openActionSheet(showActionSheetWithOptions, optionsSpec) {
  const options = optionsSpec.map((spec) => spec.option)
  let cancelButtonIndex = optionsSpec.length - 1
  let destructiveButtonIndex = cancelButtonIndex
  optionsSpec.forEach((spec, index) => {
    if (spec.cancelButtonIndex) {
      cancelButtonIndex = index
      destructiveButtonIndex = cancelButtonIndex
    }
    if (spec.destructiveButtonIndex) {
      destructiveButtonIndex = index
    }
  })
  showActionSheetWithOptions(
    {
      options,
      cancelButtonIndex,
      destructiveButtonIndex
    },
    (buttonIndex) => {
      const action = _.get(optionsSpec, `${buttonIndex}.action`, _.identity)
      action()
    },
  )
}

export function getClient() {
  return branch({
    android: 'expo',
    apk: 'standalone',
    ios: 'expo',
    ipa: 'standalone',
    web: 'browser',
  })
}

export async function getStripeConnectOAuthUrl() {
  let client = getClient()
  const redirectUri = `${environment.stripe.connect.oAuthRedirectUri}/${client}`
  const state = await AsyncStorage.getItem('USER_TOKEN')
  let url = 'https://connect.stripe.com/oauth/authorize?'
  url += 'response_type=code&'
  url += `client_id=${environment.stripe.connect.clientId}&`
  url += 'scope=read_write&'
  url += `redirect_uri=${encodeURIComponent(redirectUri)}&`
  url += `state=${encodeURIComponent(state)}&`
  url += 'always_prompt=true'
  return url
}

export function getContentLink(contentType, id) {
  const client = branch({
    native: 'standalone',
    web: 'standalone',
    expo: 'expo'
  })
  return `${environment.serverEndpoint}/content/${contentType}/${id}/redirectTo/${client}`
}

export function getImages(images) {
  // If we're called in an invalid format, just return an empty array
  if (images.length === 0) {
    // console.warn('getImages(): Unsupported image type or empty array')
    return []
  }

  if (images.length) {
    return _.map(images, (image, index) => {
      return getImageFromName(image, index)
    })
  } else if (typeof images === 'object') {
    return getImageFromName(images)
  }

  console.trace('getImages(): Unsupported image type.')
}

export function getScaledImageDimensions(fileUpload, maxHeight, maxWidth) {
  const { width, height } = fileUpload
  const heightToWidthRatio = height / width
  const maxHeightToWidthRaio = maxHeight / maxWidth
  const widthToHeightRatio = width / height
  const maxWidthToHeightRatio = maxWidth / maxHeight
  const lessThanMaxWidth = width < maxWidth
  const lessThanMaxHeight = height < maxHeight

  let scaledHeight
  let scaledWidth
  if (
    (lessThanMaxWidth && lessThanMaxHeight) ||
    (!lessThanMaxWidth && !lessThanMaxHeight && width > height) ||
    (!lessThanMaxWidth && lessThanMaxHeight)
  ) {
    scaledWidth = maxWidth
    scaledHeight = scaledWidth * heightToWidthRatio
    if (scaledHeight > maxHeight) {
      scaledHeight = scaledWidth * maxHeightToWidthRaio
    }
  } else {
    scaledHeight = maxHeight
    scaledWidth = scaledHeight * widthToHeightRatio
    if (scaledWidth > maxWidth) {
      scaledWidth = scaledHeight * maxWidthToHeightRatio
    }
  }
  return {
    width: scaledWidth,
    height: scaledHeight,
  }
}

export function getImageResizeConfig(imageDimensions, viewDimensions) {
  const containResize = { fit: 'clip' , resizeMode: 'contain' }
  const coverResize = { fit: 'crop', crop: undefined, resizeMode: 'cover' }
  const heightTaller = imageDimensions.height > viewDimensions.height
  const widthWider = imageDimensions.width > viewDimensions.width
  if (heightTaller && widthWider) {
    const heightRatio = imageDimensions.height / viewDimensions.height
    const widthRatio = imageDimensions.width / viewDimensions.width
    if (heightRatio > widthRatio) {
      return containResize
    } else {
      return coverResize
    }
  } else if (heightTaller) {
    return containResize
  } else if (widthWider) {
    return coverResize
  } else {
    return coverResize
  }
}

export function getUploadProgress(upload) {
  if (!upload) {
    return undefined
  } else if (!upload.uploadParts && !_.get(upload, 'id', '').includes('thumbnail')) {
    return upload.progress
  } else {
    const parts = _.get(upload, 'uploadParts') || []
    const progress = Math.floor(parts.reduce((sum, part) => sum + _.get(part, 'signedUpload.progress', 0), 0) / parts.length )
    return Number.isNaN(progress) ? undefined : progress
  }
}

export function getKeyboardPaddingStyle(keyboardInfo) {
  let keyboardHeight = _.get(keyboardInfo, 'event.endCoordinates.height', 0)
  let paddingBottom = 0
  const samsungS10PlusModels = 'SM-G975'
  const redmi8TModel = 'M1908C3XG'
  if (keyboardHeight) {
    // Samsung One UI, also referred to Samsung Experience 10, introduced a new keyboard toolbar
    // whose height is not properly reported by Android, React Native, or Expo.
    // Other models such as Redmi 8T exhibit this. And in some cases for Samsung the toolbar is even taller(i.e. Samsung 10+)
    paddingBottom = branch({
      apk: keyboardHeight,
      samsungOneUi: keyboardHeight + 35,
      models: {
        [samsungS10PlusModels]: keyboardHeight + 50, // Samsung S10+ Models
        [redmi8TModel]: keyboardHeight + 35,
      },
      other: 0,
    })
  }
  if (paddingBottom) {
    return { paddingBottom }
  }
  return null
}

export function getRoutePath(navigationState, path = []) {
  if (!navigationState) {
    console.log('Empty Nav State')
    return path
  }
  // dive into nested navigators
  if (navigationState.routes) {
    const route = navigationState.routes[navigationState.index]
    const { routeName, params } = route
    if (routeName !== 'Network') {
      path.push({ routeName, params })
    }
    return getRoutePath(route, path)
  } else {
    const { routeName, params } = navigationState
    if (routeName !== 'Network') {
      path.push({ routeName, params })
    }
  }
  return path
}

export function routeForShop(actualRoute, shopRoute) {
  return (
    actualRoute === shopRoute ||
    actualRoute === `NotificationScreen${shopRoute}`
  )
}

//Type Checking Methods based on : https://webbjocke.com/javascript-check-data-types/
export function isArray(value) {
  return value && typeof value === 'object' && value.constructor === Array
}

export function isString(value) {
  return typeof value === 'string' || value instanceof String
}

export function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object
}

export function isBoolean(value) {
  return typeof value === 'boolean'
}

export function isNumber(value) {
  return typeof value === 'number' && isFinite(value)
}

export function isFunction (value) {
  return typeof value === 'function'
}

export function isSamsung () {
  return Constants.deviceName.indexOf('SM-') === 0
}

export function objectValuesAreBoolean(obj) {
  let areBoolean = false
  for (const key in obj) {
    areBoolean = isBoolean(obj[key])
    if (!areBoolean) {
      break
    }
  }
  return areBoolean
}

export function jsonStringifyKeyValues(obj ={}) {
  for (const key in obj) {
    const value = obj[key]
    if (isObject(value) || isArray(value)) {
      obj[key] = JSON.stringify(value)
    }
  }
  return obj
}

export function jsonParseKeyValues(obj = {}, keys) {
  for (const key of keys) {
    const value = obj[key]
    if (isString(value)) {
      try {
        obj[key] = JSON.parse(value)
      } catch (e) {
        obj[key] = {}
      }
    }
  }
  return obj
}

export function getFeatureFlag(feature) {
  if (!environment.features.hasOwnProperty(feature)) {
    console.log('<<<FEATURE FLAG DOES NOT EXIST - ', feature)
  }
  return environment.features[feature]
}

export function getObjectStatusTranslation(statusKey) {
  const key = statusKey ? statusKey : 'unknown'
  return i18n.t(`common.objectStatus.${key}`)
}

export const renderWhileLoading = (component, propName = 'data') =>
  rBranch(
    props => {
      return props[propName] && props[propName].loading
    },
    renderComponent(component),
  )

export const getNavParam = (props, paramKey, defaultValue) => _.get(props, `navigation.state.params.${paramKey}`,defaultValue)

export function getAddressObject(address_components) {
  var ShouldBeComponent = {
    home: ['street_number'],
    postal_code: ['postal_code'],
    street: ['street_address', 'route'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    country: ['country'],
  }

  var address = {
    home: '',
    postal_code: '',
    street: '',
    region: '',
    city: '',
    country: '',
  }
  address_components.forEach(component => {
    for (var shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === 'country') {
          address[shouldBe] = component.short_name
        } else {
          address[shouldBe] = component.long_name
        }
      }
    }
  })
  return address
}

export async function handleAddressError(e) {
  const messagePath = _.get(e, 'graphQLErrors.0.message')
  const countries = _.get(e, 'graphQLErrors.0.extensions.exception.countries')
  alert({ title: i18n.t('common.error'), message: i18n.t(messagePath, { countries }) })
}

export const resolveVideoThumbnail = (image) => {
  return `thumbnail-${image.split('.')[0]}.jpg`
}

export const getHomeRoute = () => {
  return Platform.OS === 'web' ? 'MyProfile' : 'MyHome'
}

export const unitPlural = ({ unitQuantity, unitType }, separator = false) => {
  if (!unitQuantity || !unitType) return ''
  if (!unitTypes[unitType]) return ''

  const prefix = separator ? ' - ' : ''

  if ( unitQuantity > 1) {
    return `${prefix}${unitQuantity} ${_.get(unitTypes[unitType], `plural_${i18n.locale}`)}`
  } 
  return `${prefix}${unitQuantity} ${_.get(unitTypes[unitType], `${i18n.locale}`)}`
}

export const getPhonePrefix = phone => {
  return _.includes(phone, '+') ? phone.slice(0, 3) : ''
}

export const getPhoneNoPrefix = phone => {
  return _.includes(phone, '+') ? phone.slice(3) : phone
}

export const getAlertErrorConfig = (e) => {
  const transKey = _.get(e, 'graphQLErrors.0.message') || 'errors.common.network'
  const message = i18n.t(transKey)
  const title = i18n.t('common.error')
  return { title, message}
}


export function getShopDiscount(shopDiscount) {
  if (!shopDiscount) {
    return {
      isValid: false
    }
  }

  const now = new Date()
  const startDate = new Date(shopDiscount.startDate)
  const endDate = new Date(shopDiscount.endDate)
  const { percent } = shopDiscount
  const isValid = startDate < now && now < endDate

  return {
    startDate,
    endDate,
    percent,
    isValid,
  }
}

export const getProductPrice = (product) => {
  const { price, priceDiscount, shopDiscount } = product
  const { isValid, percent } = getShopDiscount(shopDiscount)

  if (isValid) {
    const discount = price * percent / 100
    const shopDiscountPrice = roundTwoPlaces(price - discount)

    return {
      price,
      priceDiscount: shopDiscountPrice,
    }
  }

  return {
    price,
    priceDiscount,
  }
}

export const numberInputEasify = (value, decimals = 2) => {
  if (!value) return ''

  const cleaned = value.replace(/[^\d.,]/g,'').replace(/,/g,'.')
  const parts = cleaned.split('.')

  if (parts.length === 1) {
    const [ integer ] = parts
    return integer
  }

  const decimal = parts.pop().substring(0,decimals)
  const integer = parts.join('')

  return `${integer}.${decimal}`
}

const findContent = (ara, type, id, pathToId)  => ara.find(element => element.contentType === type && _.get(element, pathToId, undefined) === id)
export const getPendingContent = (publishContent, contentType, contentId, pathToId) => {
  const isVideoType = isVideo(contentId)
  const { queued, pending } = publishContent
  return findContent(pending, contentType, contentId, pathToId) || (!isVideoType && findContent(queued, contentType, contentId, pathToId))
}

export const getPendingContentIndexByUpload = (publishContent, upload, key = 'id') => {
  const { pending } = publishContent
  let index = 0
  let foundPendingContent = false
  for (const content of pending) {
    const { allUploads = [] } = content
    const indexOfUpload = allUploads.findIndex(existingUpload => existingUpload[key] === upload[key])
    if (indexOfUpload !== -1) {
      foundPendingContent = true
      break
    }
    index++
  }
  return foundPendingContent ? index : -1
}

export function removeUploadsFromCache(uploads = [], skipRemovalFromReducer)  {
  const store = reduxStoreHolder.getStore()
  for (const upload of uploads) {
    if (!upload) continue
    if (!skipRemovalFromReducer && upload.id) {
      console.log('<<<helpers - removeUploadsFromCache - remove from reducer', upload.id)
      store.dispatch({
        type: 'Upload/REMOVE',
        file: upload
      })
    }
    if (upload.uri) {
      console.log('<<<helpers - removeUploadsFromCache - remove from cache', upload.id)
      FileSystem.deleteAsync(upload.uri, { idempotent: true })
    }
  }
}

export const isVideo =  (name) => ['.mp4', '.mov'].some(indicator => _.invoke(name, 'includes', indicator))
export const isThumbnail = (name) => _.invoke(name, 'includes', 'thumbnail-')

export const getCountryFromPhone = (phone) => {  
  const country = Object.values(countries).find(({code}) => {
    return code === phone.substring(0, code.length)
  })

  return country?.key || 'IT'
}