import React from 'react'
import PropTypes from 'prop-types'
import { ActivityIndicator, FlatList } from 'react-native'
import MobileBackButton from '../../components/simple/MobileBackButton'
import NavigationActions from '../../utility/navigationActions'
import ProfileBanner from '../../components/simple/ProfileBanner'

import commentSchema from '../../schema/comment'
import { renderComponent, branch } from 'recompose'
import { connect, compose, graphql } from '../../config/connected'

const CommentLikesViewer = ({ likers: { commentLikers }, goToProfile }) => {
  return (
    <FlatList
      data={commentLikers}
      renderItem={({ item }) => (
        <ProfileBanner
          key={item.id}
          profile={item}
          profileOnPress={goToProfile}
        />
      )}
      keyExtractor={item => item.id}
    />
  )
}

CommentLikesViewer.propTypes = {
  navigation: PropTypes.any.isRequired,
  likers: PropTypes.any,
  goToProfile: PropTypes.any,
}


const renderWhileLoading = (component, propName) =>
  branch(
    props => props[propName] && props[propName].loading,
    renderComponent(component),
  )

const Loading = () => <ActivityIndicator size='large' style={{ paddingVertical: 60 }}/>

const mapDispatchToProps = (dispatch) => ({
  goToProfile: (profile) => {
    NavigationActions.navigate({
      routeName: 'Profile',
      params: { id: profile.id, pageTitle: profile.username },
    })
  },
})

const composed = compose(
  graphql(commentSchema.queries.commentLikers, {
    name: 'likers',
    options: ({ navigation }) => {
      const { id } = navigation.state.params
      return {
        notifyOnNetworkStatusChange: true,
        variables: {
          id,
        },
      }
    },
  }),
  renderWhileLoading(Loading, 'likers'),
  connect(
    () => ({}),
    mapDispatchToProps,
  ),
)(CommentLikesViewer)

//Todo figure out with hoisting
composed.navigationOptions = (props) => {
  return {
    tabBarVisible: false,
    headerTitle: 'Likes',
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

export default composed
