import React from 'react'
import { Text, View } from 'react-native'
import PropTypes from 'prop-types'

import Icons from '@expo/vector-icons/Ionicons'
import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import _ from 'lodash'

const MAX_STARS = 5

class Ratings extends React.Component {
  static propTypes = {
    ratings: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rating: PropTypes.number,
    size: PropTypes.number,
    style: PropTypes.any,
    containerStyle: PropTypes.any,
    showRatings: PropTypes.bool,
  }

  static defaultProps = {
    size: 14,
    showRatings: true,
  }

  renderStars = () => {
    const { rating, size, style } = this.props,
      amount = Math.round(rating * 2) / 2, // Round ratings to nearest 0.5
      stars = Math.floor(amount) //how many full stars?

    let elements = []

    // Add all full stars
    if (stars >= 1) {
      for (let i = 0; i < stars; i++) {
        elements.push(
          <Icons
            style={[style, styles.star]}
            size={size}
            key={i}
            name='ios-star'
          />,
        )
      }
    }

    // Add a half star if there is one.
    if (amount % 1 > 0 && amount < MAX_STARS) {
      elements.push(
        <Icons
          style={[style, styles.star]}
          size={size}
          key={'half-star'}
          name='ios-star-half'
        />,
      )
    }

    // Add remaining empty stars.
    if (elements.length < MAX_STARS) {
      for (; elements.length < MAX_STARS; ) {
        elements.push(
          <Icons
            style={[style, styles.star]}
            size={size}
            key={`empty-star-${elements.length}`}
            name='ios-star-outline'
          />,
        )
      }
    }

    return elements
  }
  render() {
    const {
      ratings,
      rating,
      size,
      containerStyle,
      ratingsStyle,
      showRatings,
    } = this.props

    let isRatingVisible = typeof ratings === 'number' && ratings > 0
    let areStarsVisible = typeof rating === 'number' && rating > 0

    if (!isRatingVisible && showRatings) return null

    return (
      <View style={[styles.container, containerStyle]}>
        {areStarsVisible && this.renderStars()}
        <Text style={[{ fontSize: size - 2 }, styles.ratings, ratingsStyle]}>
          {showRatings && isRatingVisible && `  ${ratings}`}
        </Text>
      </View>
    )
  }
}

const styles = stylus({
  container: {
    flexDirection: 'row',
  },
  star: {
    color: colors.starRatings,
  },
  ratings: {
    color: colors.text.main,
  },
})

export default Ratings
