import React from 'react'
import { TouchableOpacity, View, Image, Picker } from 'react-native'
import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import T from '../../components/T'
import ElegantInput from '../../components/simple/ElegantInput'
import addPhoneImage from '../../assets/images/onboarding_phone.png'
import countries from '../../config/countries'
import MyPicker from '../../components/simple/Picker'
import _ from 'lodash'

export default function AddPhone({ screenInfo, onChange, getMessage, state }) {
  const size = screenInfo.width / 2.5
  const { phone, phonePrefix } = state
  return (
    <View style={styles.container}>
      <Image
        source={addPhoneImage}
        style={styles.image}
        resizeMethod='scale'
        resizeMode='contain'
      />
      <View style={styles.row}>
        <View style={styles.prefixWrapper}>
          <ElegantInput
            style={{ width: '100%' }}
            keyboardType='phone-pad'
            returnKeyType='next'
            selectTextOnFocus={true}
            onChange={(value) => onChange(value, 'phonePrefix')}
            maxLength={5}
            value={phonePrefix}
            autoCorrect={false}
            label=''
          />
        </View>
        <View style={styles.phoneWrapper}>
          <ElegantInput
            style={{ width: '100%' }}
            label={getMessage('common.phoneNumber')}
            keyboardType='phone-pad'
            returnKeyType='next'
            maxLength={15}
            onChange={(value) => onChange(value, 'phone')}
            value={phone}
            autoCorrect={false}
          />
        </View>
      </View>
      <T t='onboardingUser.confirmPhone.addPhone' style={styles.subtext} />
    </View>
  )
}

const styles = stylus({
  container: {
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  image: {
    width: 120,
    height: 120,
    marginBottom: 30,
  },
  subtext: {
    marginTop: 30,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '300',
    color: colors.text.main,
  },
  row: {
    flexDirection: 'row',
    // alignSelf: 'stretch',
  },
  prefixWrapper: {
    width: 66,
    paddingRight: 10,
  },
  phoneWrapper: {
    width: 140,
  },
})
