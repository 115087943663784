import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import Linking from '../../config/nativeLink'
import Checkbox from '../../components/simple/Checkbox'
import i18n from 'i18n-js'
import PropTypes from 'prop-types'

const TermsBody = () => {
  const termsAndConditionsLink = i18n.t('legal.links.user.termsAndConditions')
  const privacyPolicyLink = i18n.t('legal.links.user.privacyPolicy')
  const cookiePolicyLink = i18n.t('legal.links.user.cookies')

  return (
    <View style={styles.content}>
      {[
        <Text key={0}>{i18n.t('onboardingUser.terms.agreeText1')},</Text>,
        <TouchableOpacity
          key={1}
          onPress={() => Linking.openURL(termsAndConditionsLink)}
        >
          <Text
            accessibilityRole='link'
            target='_blank'
            href={termsAndConditionsLink}
            style={styles.link}
          >
            {i18n.t('onboardingUser.terms.agreeText2')}
          </Text>
        </TouchableOpacity>,
        <Text key={55}>{i18n.t('onboardingUser.terms.agreeText3')}</Text>,
        <TouchableOpacity
          key={2}
          onPress={() => Linking.openURL(privacyPolicyLink)}
        >
          <Text
            accessibilityRole='link'
            target='_blank'
            href={privacyPolicyLink}
            key={3}
            style={styles.link}
          >
            {i18n.t('onboardingUser.terms.agreeText4')}
          </Text>
        </TouchableOpacity>,
        <Text key={4}>{i18n.t('onboardingUser.terms.agreeText5')}</Text>,
        <TouchableOpacity
          key={5}
          onPress={() => Linking.openURL(cookiePolicyLink)}
        >
          <Text
            accessibilityRole='link'
            target='_blank'
            href={cookiePolicyLink}
            style={styles.link}
          >
            {i18n.t('onboardingUser.terms.agreeText6')}
          </Text>
        </TouchableOpacity>,
        <Text key={6}>{i18n.t('onboardingUser.terms.agreeText7')}</Text>,
        <View key={7} style={{ width: '100%' }}>
          <Text style={styles.linebreak}>{'\n'}</Text>
          <Text style={styles.conjunctionText}>
            {i18n.t('onboardingUser.terms.conjunction')}
          </Text>
          <Text style={styles.linebreak}>{'\n'}</Text>
        </View>,
        <Text key={13}>{i18n.t('onboardingUser.terms.agreeText8')}</Text>,
      ]}
    </View>
  )
}

const Terms = ({ onChange, state, style }) => {
  return (
    <View style={[styles.container, style]}>
      <Checkbox
        checked={state.acceptTerms}
        onPress={() => onChange(!state.acceptTerms, 'acceptTerms')}
      />
      <TermsBody/>
    </View>
  )
}

Terms.propTypes = {
  style: PropTypes.any,
  onChange: PropTypes.func,
  getMessage: PropTypes.func,
  state: PropTypes.shape({
    acceptTerms: PropTypes.bool,
  }),
}

export default Terms


const styles = stylus({
  container: {
    alignItems: 'center',
    marginTop: 60,
  },
  header: {
    fontSize: 22,
    fontWeight: 'bold',
    textAlign: 'center',
    color: colors.text.main,
  },
  content: {
    textAlign: 'center',
    fontSize: 15,
    color: colors.text.secondary,
    marginTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  links: {
    marginVertical: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    fontWeight: 'bold',
    fontSize: 15,
    color: colors.text.secondary,
    textDecorationLine: 'underline',
  },
  linkSeparator: {
    width: 1,
    marginHorizontal: 10,
    height: 16,
    borderRightColor: colors.thinLine,
    borderRightWidth: sizes.px,
  },
  agreementWrapper: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  conjunctionText: {
    textAlign: 'center',
  },
  linebreak: {
    height: 10,
  },
  agreementText: {
    fontSize: 13,
    paddingLeft: 14,
    flex: 1,
    color: colors.text.main,
  },
})
