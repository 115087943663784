import React, { Component } from 'react'
import { Text, View, Image, TouchableOpacity } from 'react-native'
import { Camera } from 'expo-camera'
import TabView from '../../components/TabView'
import { stylus } from '../../config/styles'
import mockData from '../../config/mockData'
import {
  connect,
  graphql,
  withApollo,
  compose,
  gql,
} from '../../config/connected'
import _ from 'lodash'
import Scroller from '../../components/Scroller'
import NavigationButton from '../../components/simple/NavigationButton'
import NavigationActions from '../../utility/navigationActions'
import TileImage from './TileImage'

class MediaUpload extends Component {
  state = {
    previewImage: mockData.feed.posts[0].imageSource,
  }
  static navigationOptions = (props) => {
    return {
      headerTitle: '',
      headerLeft: () => (
        <NavigationButton
          label='Back'
          iconAndroid='md-arrow-back'
          iconIos='ios-arrow-back'
          onPress={() => NavigationActions.back()}
        />
      ),
    }
  }
  // testView = () => {
  //  const images = mockData.feed.posts[0].images
  //  const selectedImage = images[Math.floor(Math.random() * images.length)]
  //  return (
  //    <View
  //      style={{
  //        width: this.props.screenInfo.width / 4,
  //        height: this.props.screenInfo.width / 4,
  //        backgroundColor: `rgb(${Math.random() * 255},${Math.random() *
  //          255},${Math.random() * 255})`,
  //      }}
  //    >
  //      <Image
  //        style={{
  //          width: '25%',
  //          height: '25%',
  //        }}
  //        source={selectedImage}
  //        resizeMode="cover"
  //        resizeMethod="scale"
  //      />
  //    </View>
  //  )
  // }
  getGallery = () => {
    const images = mockData.feed.posts[0].images

    const elements = [],
      maxElements = Math.round((Math.random() * 100) / 2)

    for (let i = 0; i < maxElements; i++) {
      let selectedImage = images[Math.floor(Math.random() * images.length)]
      elements.push(
        <TileImage
          onPress={this.setPreviewImage}
          style={{
            width: this.props.screenInfo.width / 4,
            height: this.props.screenInfo.width / 4,
            backgroundColor: `rgb(${Math.random() * 255},${Math.random() *
              255},${Math.random() * 255})`,
          }}
          source={selectedImage}
        />,
      )
    }
    return elements
  }

  setPreviewImage = (previewImage) => {
    this.setState((prev) => ({ ...prev, previewImage }))
  }
  render() {
    const { screenInfo } = this.props
    const { previewImage } = this.state

    return (
      <View style={styles.container}>
        <TabView contentTop>
          <TabView.Tab label='Gallery'>
            <Scroller>
              <View style={styles.galleryContainer}>
                <Image
                  source={previewImage}
                  style={{
                    width: this.props.screenInfo.width,
                    height: this.props.screenInfo.width / 1.618,
                    marginBottom: 20,
                  }}
                />
                {this.getGallery()}
              </View>
            </Scroller>
          </TabView.Tab>
          <TabView.Tab label='Photo'>
            <Text>1</Text>
          </TabView.Tab>
          <TabView.Tab label='Video'>
            <Text>1</Text>
          </TabView.Tab>
        </TabView>
      </View>
    )
  }
}

const styles = stylus({
  container: {
    flex: 1,
  },
  galleryContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

export default compose(connect(mapStateToProps))(MediaUpload)
