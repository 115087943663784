import sizes from '../config/sizes'

const getTabbarHeight = (screenInfo) => {
  let WIDTH = screenInfo.width
  let HEIGHT = screenInfo.height

  if (screenInfo.device === 'desktop') {
    return 0
    // This is the same formula used within styles.js to determine iphonex or regular phone
  } else if (
    (WIDTH === 375 && HEIGHT === 812) ||
      (WIDTH === 414 && HEIGHT === 896)
  ) {
    return sizes.iphonexTabBarHeight
  } else {
    return sizes.tabBarHeight
  }
}
export const getFullOffset = (headerHeight, screenInfo) => {
  let tabHeight = getTabbarHeight(screenInfo)

  // No way to pull this actively within the app right now, so monkey patched.
  const localHeaderHeight = headerHeight
  // Same goes here, but this should also be this size.
  const defaultTabviewTabHeight =
      tabHeight === sizes.iphonexTabBarHeight ? 38 : 0

  return tabHeight + localHeaderHeight + defaultTabviewTabHeight
}
