import React, { PureComponent } from 'react'
import { Text, View } from 'react-native'
import { stylus } from '../../config/styles'
import PropTypes from 'prop-types'
import { connect } from '../../config/connected'
import colors from '../../config/colors'
import { getUploadProgress } from '../../config/helpers'
import i18n from 'i18n-js'

const styles = stylus({
  container: {
    // flex: 1,
    backgroundColor: colors.postUploadingBar,
    height: 20,
    // borderRadius: 10,
    // marginVertical: 5,
    justifyContent: 'center',
  },
  bar: {
    backgroundColor: colors.primary,
    height: '100%',
    position: 'absolute',
    // borderRadius: 10,
  },
  textStyle: {
    fontSize: 12,
    alignSelf: 'center',
    color: 'white',
    fontWeight: 'bold',
  },
})

@connect(({ uploads }) => ({ uploads }))
class UploadProgress extends PureComponent {
  static propTypes = {
    progress: PropTypes.number,
    targetID: PropTypes.string,
    direction: PropTypes.oneOf(['right', 'left', 'up', 'down']),
    style: PropTypes.object,
    barStyle: PropTypes.object,
    textStyle: PropTypes.object,
    showPercent: PropTypes.bool,
    uploads: PropTypes.object,
    disabled: PropTypes.bool,
    onComplete: PropTypes.func,
  }

  static defaultProps = {
    progress: 0,
    targetID: '',
    direction: 'right',
    style: styles.container,
    barStyle: styles.bar,
    textStyle: styles.textStyle,
    showPercent: false,
    disabled: false,
    onComplete: () => {},
  }

  getProgress = () => {
    const { progress, uploads, targetID } = this.props
    return targetID ? getUploadProgress(uploads[targetID]) : progress
  }

  getIsCancelled = () => {
    const { uploads, targetID } = this.props
    return uploads[targetID].state === 'cancelled'
  }

  getStyleHorizontal = () => {
    const { direction } = this.props

    let width = this.getProgress()

    if (this.getIsCancelled()) {
      width = 100
    }

    switch (direction) {
      case 'right':
        return { left: 0, height: '100%', width: `${width}%` }
      case 'left':
        return { right: 0, height: '100%', width: `${width}%` }
      default:
        return
    }
  }

  getStyleVertical = () => {
    const { direction } = this.props

    let height = this.getProgress()

    if (this.getIsCancelled()) {
      height = 100
    }

    switch (direction) {
      case 'up':
        return { bottom: 0, width: '100%', height: `${height}%` }
      case 'down':
        return { top: 0, width: '100%', height: `${height}%` }
      default:
        return
    }
  }

  getUploadProgress = () => {
    const progress = this.getProgress()
    if (this.getIsCancelled()) {
      return i18n.t('errors.uploadFailed')
    }
    if (progress !== 100) {
      return i18n.t('common.uploadingProgress', { progress })
    } else {
      return i18n.t('common.complete')
    }
  }

  onComplete = () => {
    this.props.onComplete()
  }

  getBarStyle = () => {
    return { ...this.getStyleHorizontal(), ...this.getStyleVertical() }
  }

  componentDidUpdate(prevProps) {
    const { progress, uploads, disabled, targetID, onComplete } = this.props
    // If the upload is completed, and the props aren't currently the same as they were last update, go ahead and call onComplete
    const prevUpload = prevProps.uploads[targetID]
    const currUpload = uploads[targetID]
    if (
      !disabled &&
      (
        (prevProps.progress !== progress && progress === 100) ||
        (targetID && prevUpload && currUpload &&
          prevUpload.state !== currUpload.state &&
          currUpload.state === 'complete')
      )
    ) {
      onComplete()
    }
  }

  render() {
    const { style, barStyle, textStyle, disabled } = this.props
    if (disabled || this.getProgress() === undefined) return null
    return (
      <View style={style}>
        <View style={[barStyle, this.getBarStyle(), this.getIsCancelled() && { backgroundColor: '#ff2c55' }]} />
        <Text style={textStyle}>{this.getUploadProgress()}</Text>
      </View>
    )
  }
}

export default UploadProgress
