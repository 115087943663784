import React from 'react'
import { View, Text, TextInput } from 'react-native'
import Cursor from './Cursor'

import { stylus } from '../../config/styles'
import colors from '../../config/colors'

import branch from '../../config/branch'

const duration = 500

export default class FormatTextInput extends React.Component {
  state = {
    selection: { start: 0, end: 0 }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.value !== this.props.value
  }

  onFocus = () => {
    if (this.cursorRef) {
      this.cursorRef.startAnimation()
    }
    if (this.props.onFocus) {
      this.props.onFocus()
    }
  }

  onBlur = () => {
    if (this.cursorRef) {
      this.cursorRef.stopAnimation()
    }
    if (this.props.onBlur) {
      this.props.onBlur()
    }
  }

  onSelectionChange = (e) => {
    const { nativeEvent: { selection, text } } = e
    this.setState({ selection: { start: selection.start, end: selection.end } })
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(e)
    }
  }

  setCursorRef = (ref) => this.cursorRef = ref

  render() {
    const {
      style,
      inputWrapperStyle,
      inputStyle,
      fontSize,
      verticalPadding,
      value,
      onChangeText,
      onFocus,
      onBlur,
      onSelectionChange,
      format,
      ...rest
    } = this.props
    const valueFormatted = format(value)
    const height = (verticalPadding * 2) + (fontSize * 1.4)
    return (
      <View style={[styles.container, { height }]}>
        <View style={[inputWrapperStyle, branch({ android: { position: 'absolute' }, other: {}})]}>
          <TextInput
            style={[inputStyle, { fontSize } ]}
            onChangeText={onChangeText}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSelectionChange={this.onSelectionChange}
            value={value}
            {...rest}
          />
        </View>
        {branch({
          android: (
            <View style={[inputWrapperStyle, styles.inputTextContainer, { height }]} pointerEvents='none'>
              <Text style={[inputStyle, { fontSize }]}>
                {valueFormatted}
              </Text>
              <Cursor ref={this.setCursorRef} color={colors.text.main} duration={duration} fontSize={fontSize}/>
            </View>
          ),
          other: null
        })}
      </View>
    )
  }
}


const styles = stylus({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputTextContainer: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
