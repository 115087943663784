import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

import { getDateTime } from '../../config/helpers'
import colors from '../../config/colors'

const NoticeItem = ({ notice }) => {
  return (
    <View style={{
      padding: 10,
      borderBottomWidth: StyleSheet.hairlineWidth,
    }}>
      <Text style={{ 
        fontWeight: 'bold', 
        fontSize: 16, 
        borderBottomWidth: StyleSheet.hairlineWidth, 
        borderBottomColor: colors.lightGray,
        paddingBottom: 2,
        marginBottom: 2,
      }}>
        {notice.name}
      </Text>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <View>
          <Text style={{ fontSize: 12 }}>{i18n.t('notice.createdBy')}</Text>
          <Text style={{ fontWeight: 'bold'}}>{notice.user.displayName}</Text>
        </View>
        <View style={{ alignItems: 'flex-end'}}>
          <Text style={{ fontSize: 12 }}>{i18n.t('notice.createdAt')}</Text>
          <Text style={{ fontWeight: 'bold'}}>{getDateTime(notice.createdAt)}</Text>
        </View>
      </View>

      <Text style={{ fontSize: 12, marginTop: 5 }}>{i18n.t('notice.message.it')}</Text>
      <Text>{notice.message.it}</Text>
      <Text style={{ fontSize: 12, marginTop: 5 }}>{i18n.t('notice.message.en')}</Text>
      <Text>{notice.message.en}</Text>
    </View>
  )
}
  
NoticeItem.propTypes = {
  notice: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.object,
    createdAt: PropTypes.any,
    user: PropTypes.shape({
      displayName: PropTypes.string
    })
  })
}

export default NoticeItem
