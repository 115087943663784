export const SET_VIDEO_REF = 'SET_VIDEO_REF'
export const REMOVE_VIDEO_REF = 'REMOVE_VIDEO_REF'

const initailState = []

const video = (state = initailState, action) => {
  const { type, payload } = action
  switch(type) {
    case SET_VIDEO_REF:
      return createNewState(state, payload)
    case REMOVE_VIDEO_REF:
      return removeVideo(state, payload)
    default:
      return state
  }
}

const removeVideo = (state, payload) => {
  if (payload && state.length) {
    return state.filter((item) => {
      const { uri } = item.props.source
      const { id } = item.props
      return uri !== payload.props.source.uri && id !== payload.props.id
    })
  }
  return state
}

const createNewState = (state, payload) => {

  if (payload && state.length) {
    let hasVideoRef = false
    state.forEach(item => {
      const { uri } = item.props.source
      const { id } = item.props
      if(uri === payload.props.source.uri && id === payload.props.id) {
        hasVideoRef = true
      }
    })
    return hasVideoRef ? state : [...state, payload]
  } else if (!payload) {
    return state
  }
  return [payload]
}

export default video