import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  Platform,
  View,
  TouchableOpacity,
} from 'react-native'

import Scroller from '../../components/Scroller'
import PageTitle from '../../components/simple/PageTitle'
import Action from '../../components/simple/Action'
import ProfileImage from '../../components/Post/ProfileImage'
import NamedImage from '../../components/simple/NamedImage'
import GroupProfileImage from '../../components/simple/GroupProfileImage'
import ListItem from '../../components/simple/ListItem'
import shared from '../../config/styles'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import Icons from '@expo/vector-icons/Ionicons'
import brandLogoGold from '../../assets/images/fb_gold_logo.png'
import defaultPlaceholder from '../../assets/images/placeholder-image.png'
import { fbLogo } from '../../assets/images'
const logo = fbLogo ? fbLogo : brandLogoGold
import richStringParser from '../../utility/richStringParser'
import i18n from 'i18n-js'
import _ from 'lodash'
import moment from 'moment'
import routeFromNotification from './routeFromNotification'
import postParserInterpolation from './postParserInterpolation'
import { connect, compose, graphql } from '../../config/connected'
import { resolveVideoThumbnail } from '../../config/helpers'

const mapStateToProps = (state) => ({
  userId: state.currentUser.id,
})

const mapDispatchToProps = (dispatch) => ({})

@connect(
  mapStateToProps,
  mapDispatchToProps,
)
class NotificationItem extends React.Component {
  render() {
    const { userId, image, unseen, createdAt, type, data } = this.props.notification
    let isVideoPreview = false
    if (image) {
      isVideoPreview = image.includes('.mp4')
    }

    let Image =  (
      <NamedImage
        style={styles.profileImage}
        name={isVideoPreview ? resolveVideoThumbnail(image) : image}
        asset={data.fromAdmin ? logo : undefined }
        width={50}
        height={50}
        fit='crop'
        crop='faces'
        resizeMode={data.fromAdmin ? 'contain' : 'cover'}
        resizeMethod='scale'
        placeholder='profile'
      />
    )
    if (['newGroupChatMessage', 'newGroupChatImage'].includes(type)) {
      Image =  (
        <GroupProfileImage
          style={{ marginHorizontal: 10 }}
          members={data.members}
          cuid={userId}
          size={50}
        />
      )
    }
    return (
      <TouchableOpacity
        style={[styles.container, unseen && styles.containerUnseen]}
        onPress={this.onPress}
      >
        { Image }
        <View style={styles.content}>
          <View>
            <Text numberOfLines={3}>{this.story()}</Text>
          </View>
          <View style={styles.when}>
            <Text style={[styles.whenText, unseen && styles.whenTextUnseen]}>
              {moment(createdAt).fromNow()}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    )
  }
  story() {
    let { type, data, counter, user } = this.props.notification
    let count = null
    switch (type) {
      case 'newGroupChatMessage':
      case 'reviewReminder_first':
        count = data.count
        break
      case 'reviewReminder_second':
        count = data.count
        break
      case 'newGroupChatImage':
        count = data.othersCount
        break
      default:
        count = counter
    }
    const regardingIndividual = _.get(data, 'regardingIndividual', false)
    const narrative =
      count > 1
        ? i18n.t(`notifications.${type}Many`, {
          ...data,
          count: regardingIndividual ? count:  count - 1,
          counter: regardingIndividual ? count:  count - 1,
        })
        : i18n.t(`notifications.${type}`, { ...data })
    let parsedSections = [
      {
        type: 'literal',
        value: narrative,
      },
    ]
    try {
      parsedSections = richStringParser.parse(narrative)
    } catch (e) {
      console.log(e)
    }
    const sections = postParserInterpolation({
      type,
      data,
      counter: count,
      sections: parsedSections,
    })
    return sections.map((item, index) => {
      return (
        <Text
          key={index}
          style={[styles.section, item.type === 'bold' && styles.sectionBold]}
        >
          {item.value}
        </Text>
      )
    })
  }
  onPress = async () => {
    const { userId } = this.props
    const { id, unseen, type, data } = this.props.notification
    if (unseen) {
      // mark as seen => server
      await this.props.notificationSeen(id)
      this.forceUpdate()
    }
    // based on type & data, go to correct route
    console.log('going to', type, data)
    // this.notificationActionCallback(notification)
    this.props.navigate(routeFromNotification(this.props.notification))
  }
  // notificationActionCallback = (notification) => {
  //   const NOTIFICATION_COMMENT_TYPES = ['mentionCommentUser','mentionCommentShop', 'mentionCommentProduct']
  //   if(NOTIFICATION_COMMENT_TYPES.includes(notification.type)){

  //   }

  // }
}

export default NotificationItem

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 80,
  },
  containerUnseen: {
    backgroundColor: colors.unseenBackground,
  },
  profileImage: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginHorizontal: 10,
    // marginVertical: 11,
  },
  content: {
    flex: 1,
    alignItems: 'flex-start',
    // justifyContent: 'center',
    borderTopColor: colors.thinLine,
    paddingVertical: 10,
    borderTopWidth: sizes.px,
    paddingRight: 14,
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  section: {
    fontSize: 15,
    flex: 1,
    lineHeight: 18,
    color: colors.text.content,
    fontWeight: '300',
  },
  sectionBold: {
    fontWeight: '600',
    color: colors.text.main,
  },
  when: {
    marginTop: 0,
  },
  whenText: {
    fontSize: 13,
    lineHeight: 25,
    color: colors.text.secondary,
    fontWeight: '300',
  },
  whenTextUnseen: {
    fontWeight: '300',
  },
})
