export const stopAllVideos = (videos) => {
  try {
    !!videos.length && videos.forEach(vid => {
      vid.stopAsync()
    })
  } catch (error) {
    console.log(error)
  }
}

export const stopAllVideosById = (videos, id) => {
  try {
    videos.forEach(vid => {
      if (vid.props.source.uri !== id) {
        vid.stopAsync()
      }
    })
  } catch (error) {
    console.log(error)
  }
}