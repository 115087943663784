import React from 'react'
import {
  ScrollView,
  StyleSheet,
  View,
  Platform,
  RefreshControl,
} from 'react-native'

import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import { connect } from 'react-redux'
import _ from 'lodash'

const debug = false

class Scroller extends React.Component {
  static navigationOptions = {
    // title: '',
    header: () => null,
  }
  static defaultProps = {
    loadMore: () => {},
    refresh: () => {},
    debounceTime: 300,
    withPaddingRight: true,
  }
  constructor(props) {
    super(props)
    this.previousDistance = 1000000
    this.previousTime = 0
    this.previousScrollReset = 0
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { horizontal } = nextProps
    const nextScrollReset = _.get(nextProps, 'navigation.state.params.reset', 0)
    if (nextScrollReset > this.previousScrollReset) {
      console.log('scroll to top!')
      const scrollToParams = { animated: true }
      if (horizontal) {
        scrollToParams.x  = 0
      } else {
        scrollToParams.y = 0
      }
      this.scrollView && this.scrollView.scrollTo(scrollToParams)
    }
    this.previousScrollReset = nextScrollReset
  }
  render() {
    const {
      screenInfo,
      style,
      keyboardDismissMode = 'on-drag',
      contentContainerStyle,
      keyboardShouldPersistTaps,
      refreshing = false,
      onRefresh,
      horizontal,
      showsHorizontalScrollIndicator,
      showsVerticalScrollIndicator,
      withPaddingRight
    } = this.props
    const {
      topBarHeight,
      contentWidth,
      gutterWidth,
      leftBarWith,
      paddingLeft,
      paddingRight,
      layout,
    } = screenInfo

    return (
      <ScrollView
        horizontal={horizontal}
        showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
        showsVerticalScrollIndicator={showsVerticalScrollIndicator}
        keyboardDismissMode={keyboardDismissMode}
        keyboardShouldPersistTaps={keyboardShouldPersistTaps}
        onScroll={this.onScroll.bind(this)}
        onMomentumScrollEnd={this.onMomentumScrollEnd}
        onScrollEndDrag={this.onScrollEndDrag}
        scrollEventThrottle={60}
        style={[styles.container, style]}
        ref={(ref) => {
          this.scrollView = ref
          this.props.innerRef && this.props.innerRef(ref)
        }}
        contentContainerStyle={[
          styles.contentContainer,
          {
            // marginLeft: paddingLeft,
            paddingLeft,
            // width: contentWidth,
            // paddingTop: topBarHeight,
          },
          withPaddingRight && { paddingRight },
          contentContainerStyle,
        ]}
        refreshControl={
          onRefresh && (
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          )
        }
      >
        {this.props.children}
      </ScrollView>
    )
  }
  onScroll(e) {
    const { onScroll, loadMore, onEndReached, onEndLeft, fromEndOfViewPercentage, horizontal, debounceTime } = this.props

    onScroll && onScroll(e)
    const { timeStamp } = e
    if (timeStamp - this.previousTime < debounceTime) {
      // android fires updates too often
      return
    }
    const { contentOffset, contentSize, layoutMeasurement } = e.nativeEvent
    let distanceFromEnd = contentSize.height - contentOffset.y - layoutMeasurement.height
    if (horizontal) {
      distanceFromEnd = contentSize.width - contentOffset.x - layoutMeasurement.width
    }

    if (!horizontal && fromEndOfViewPercentage) {
      const distanceCovered = contentOffset.y + layoutMeasurement.height
      const distanceLeft = contentSize.height - distanceCovered
      const maxDistanceLeft = layoutMeasurement.height * (fromEndOfViewPercentage/100)
      if (distanceLeft <= maxDistanceLeft) {
        // console.log('End Reached')
        onEndReached && onEndReached()
      } else {
        // console.log('End left')
        onEndLeft && onEndLeft()
      }
    }


    let loadMoreThreshold = 3 * (horizontal ? layoutMeasurement.width : layoutMeasurement.height)
    let refresh = false
    debug && console.log('<<<SCROLLER - LOAD MORE - CRITERIA', distanceFromEnd, this.previousDistance, loadMoreThreshold)
    if (distanceFromEnd < this.previousDistance) {
      // scrolling down
      if (distanceFromEnd < loadMoreThreshold) {
        // do a refresh
        debug && console.log('<<<SCROLLER - LOAD MORE - NQW')
        loadMore && loadMore()
      }
    }
    // don't log "e.nativeEvent". It will freeze the app
    this.previousDistance = distanceFromEnd
    this.previousTime = timeStamp
  }

  onMomentumScrollEnd = (e) => {
    const { onMomentumScrollEnd } = this.props
    onMomentumScrollEnd && onMomentumScrollEnd(e)
  }

  onScrollEndDrag = (e) => {
    const { onScrollEndDrag } = this.props
    onScrollEndDrag && onScrollEndDrag(e)
  }
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Scroller)

const styles = stylus({
  container: {
    flex: 1,
  },
  contentContainer: {
    borderColor: null,
    borderWidth: 0,
    alignSelf: 'stretch',
    paddingBottom: sizes.tabBarHeight,
    android: {
      // paddingBottom: 0,
    },
    iphonex: {
      paddingBottom: sizes.iphonexTabBarHeight,
    },
  },
})
