import { gql } from '../config/connected'

export const productFields = `id
name
price
priceDiscount
rating
ratingCount
stock
unitQuantity
unitType
uploads {
  name
  mime
}
categories {
  allergens
}
isInStock
isDiscontinued
description
ingredients
awards {
  description
  images {
    width
    height
    name
    mime
  }
}
position
`

export const producerDetailsArgs = `
  $legalName: String
  $description: Json
  $policies: Json
  $established: Int
  $category: String
  $producerType: String
  $method: String
  $website: String
  $payment_cash: Boolean
  $payment_transfer: Boolean
  $payment_stripe: Boolean
  $geo: InputGeo
  $ibanNumber: String
  $bankAccountHolder: String
  $standardShippingFee: Float
  $freeShippingThreshold: Float
  $minimumOrderAmount: Float
  $companyPhoneLand: String
  $companyPhoneMobile: String
  $euVAT: String
  $VAT: String
  $deliveryLocations: String
  $shopDiscount: Json
  $countriesVisibleIn: [String!]
  $standardShippingFeeCountryData: Json
  $freeShippingThresholdCountryData: Json
  $policiesCountryData: Json
`

export const producerDetailsArgsAssignment = `
  description: $description
  legalName: $legalName
  policies: $policies
  established: $established
  category: $category
  producerType: $producerType
  method: $method
  website: $website
  payment_cash: $payment_cash
  payment_transfer: $payment_transfer
  payment_stripe: $payment_stripe
  ibanNumber: $ibanNumber
  bankAccountHolder: $bankAccountHolder
  standardShippingFee: $standardShippingFee
  freeShippingThreshold: $freeShippingThreshold
  minimumOrderAmount: $minimumOrderAmount
  companyPhoneLand: $companyPhoneLand
  companyPhoneMobile: $companyPhoneMobile
  coordinates: $geo
  euVAT: $euVAT
  VAT: $VAT
  deliveryLocations: $deliveryLocations
  shopDiscount: $shopDiscount
  countriesVisibleIn: $countriesVisibleIn
  standardShippingFeeCountryData: $standardShippingFeeCountryData
  freeShippingThresholdCountryData: $freeShippingThresholdCountryData
  policiesCountryData: $policiesCountryData
`

const producerDetailsFields = `
  id
  description
  legalName
  established
  policies {
    shipping
    returns
    payment
  }
  category
  producerType
  method
  website
  ibanNumber
  bankAccountHolder
  standardShippingFee
  freeShippingThreshold
  minimumOrderAmount
  paymentTypes{
    transfer
    cash
    stripe
  }
  companyPhoneLand
  companyPhoneMobile
  euVAT
  VAT
  deliveryLocations
  countriesVisibleIn
  shopDiscount
  standardShippingFeeCountryData
  freeShippingThresholdCountryData
  policiesCountryData
`

export default {
  queries: {
    shopsByIds: gql`
    query($ids: [String]){
      shopsByIds(ids: $ids){
        id
        standardShippingFee
      }
    }`,
    getEmployees: gql`
      query($id: String!) {
        userById(id: $id) {
          id
          producerDetails {
            id
            ownership {
              id
              role
              owner {
                id
                profileImage
                displayName
              }
            }
            stripeConnected
          }
        }
      }
    `,
    myShopId: gql`
      query {
        currentUser {
          id
          ownedProfile {
            id
            profile {
              id
              status
            }
          }
        }
      }
    `,
    productsByUserId: gql`
      query($userId: String!, $limit: Int, $offset: Int) {
        productsByUserId(userId: $userId, limit: $limit, offset: $offset) @connection(key: "productsByUserId", filter: ["userId"]) {
          ${productFields}
          profile{ 
            id
            status
          }
        }
      }
    `,
  },
  mutations: {
    createProduct: gql`
      mutation($userId: String!, $name: String!) {
        createProduct(userId: $userId, name: $name) {
          id
          name
        }
      }
    `,
    updateShopStatus: gql`
      mutation($id: String!, $status: String!, $messageId: String) {
        updateShopStatus(id: $id, status: $status, messageId: $messageId) {
          id
          status
        }
      }
    `,
    updateProducer: gql`
        mutation(
            $id: String!
            $coverPhoto: String
            $displayName: String
            $email: String
            $address1: String
            $city: String
            $country: String
            $region: String
            $state: String
            $zip: String
            ${producerDetailsArgs}
        ) {
            updateUserById(id: $id, displayName: $displayName, email: $email, coverPhoto: $coverPhoto) {
                id
                displayName
                email
                coverPhoto
            }
            updateProducerDetails(
                id: $id
                ${producerDetailsArgsAssignment}
            ) {
              ${producerDetailsFields}
            }
            updateProfileAddress(
                id: $id
                address1: $address1
                city: $city
                country: $country
                region: $region
                state: $state
                zip: $zip
                geo: $geo
            ) {
                address1
                city
                country
                region
                state
            }
        }
    `,
    updateProducerDetails: gql`
      mutation(
        $id: String!
        ${producerDetailsArgs}
      ){
        updateProducerDetails(
          id: $id
          ${producerDetailsArgsAssignment}
        ){
          ${producerDetailsFields}
        }
      }
    `,
    updateProductPositions: gql`
      mutation($shopId: String!, $id: String!, $from: Int!, $to: Int!) {
        updateProductPositions(
          shopId: $shopId, id: $id, from: $from, to: $to
        ) {
          id,
          position
        }
      }
    `,
  },
}
