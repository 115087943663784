import registerElements from './js/registerElements.inline-script'
import cardElements from './js/cardElements.inline-script'
import baseCSS from './css/base.inline-css'
import cardCSS from './css/card.inline-css'
import i18n from 'i18n-js'
import CoderaWebViewHelpersScript from '../../simple/CoderaWebView/CoderaWebViewHelpers'

export default (clientSecret, title, StripeDescriptionHtml) => {
  const registerElementsScript = registerElements(clientSecret)
  const cardElementScript = cardElements()
  const baseStyle = baseCSS()
  const cardStyle = cardCSS()  
  const html = /*syntax: html*/`
  <!DOCTYPE html>
  <html>
    <head>
      <title>${title}</title>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1 maximum-scale=1">
      <script src="https://js.stripe.com/v3/"></script>
      ${CoderaWebViewHelpersScript}
      ${registerElementsScript}
      ${baseStyle}
      ${cardStyle}
      <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet">
      <link href="https://fonts.googleapis.com/css?family=Quicksand" rel="stylesheet">
      <link href="https://fonts.googleapis.com/css?family=Source+Code+Pro" rel="stylesheet">
    </head>
    <body>
      <div class="globalContent">
        <main id='main-tag'>
          <section class="container-lg">
           
            <div class="cell example example5" id="example-5">
              <form id="example5-form">
                <div id="example5-paymentRequest">
                  <!--Stripe paymentRequestButton Element inserted here-->
                </div>
                <fieldset>
                  <legend class="card-only" data-tid="elements_examples.form.pay_with_card">${i18n.t('payment.paymentMethods.labels.legend')}</legend>
                  <legend class="payment-request-available" data-tid="elements_examples.form.enter_card_manually">Or enter card details</legend>
                  <div class="row">
                    <div class="field">
                      <label for="example5-name" data-tid="elements_examples.form.name_label">${i18n.t('payment.paymentMethods.labels.name')}</label>
                      <input id="example5-name" data-tid="elements_examples.form.name_placeholder" class="input" type="text" placeholder="${i18n.t('payment.paymentMethods.placeholders.name')}" required="" autocomplete="name">
                    </div>
                  </div>
                  
                  <!--<div class="row">
                    <div class="field">
                      <label for="example5-email" data-tid="elements_examples.form.email_label">${i18n.t('payment.paymentMethods.labels.email')}</label>
                      <input id="example5-email" data-tid="elements_examples.form.email_placeholder" class="input" type="text" placeholder="janedoe@gmail.com" required="" autocomplete="email">
                    </div>
                    <div class="field">
                      <label for="example5-phone" data-tid="elements_examples.form.phone_label">${i18n.t('payment.paymentMethods.labels.phone')}</label>
                      <input id="example5-phone" data-tid="elements_examples.form.phone_placeholder" class="input" type="text" placeholder="(941) 555-0123" required="" autocomplete="tel">
                    </div>
                  </div>
                  <div data-locale-reversible>
                    <div class="row">
                      <div class="field">
                        <label for="example5-address" data-tid="elements_examples.form.address_label">${i18n.t('payment.paymentMethods.labels.address')}</label>
                        <input id="example5-address" data-tid="elements_examples.form.address_placeholder" class="input" type="text" placeholder="185 Berry St" required="" autocomplete="address-line1">
                      </div>
                    </div>
                    <div class="row" data-locale-reversible>
                      <div class="field">
                        <label for="example5-city" data-tid="elements_examples.form.city_label">${i18n.t('payment.paymentMethods.labels.city')}</label>
                        <input id="example5-city" data-tid="elements_examples.form.city_placeholder" class="input" type="text" placeholder="San Francisco" required="" autocomplete="address-level2">
                      </div>
                      <div class="field">
                        <label for="example5-state" data-tid="elements_examples.form.state_label">${i18n.t('payment.paymentMethods.labels.state')}</label>
                        <input id="example5-state" data-tid="elements_examples.form.state_placeholder" class="input empty" type="text" placeholder="CA" required="" autocomplete="address-level1">
                      </div>
                      <div class="field">
                        <label for="example5-zip" data-tid="elements_examples.form.postal_code_label">${i18n.t('payment.paymentMethods.labels.zip')}</label>
                        <input id="example5-zip" data-tid="elements_examples.form.postal_code_placeholder" class="input empty" type="text" placeholder="94107" required="" autocomplete="postal-code">
                      </div>
                      <div class="field">
                        <label for="example5-country" data-tid="elements_examples.form.country_label">${i18n.t('payment.paymentMethods.labels.country')}</label>
                        <input id="example5-country" data-tid="elements_examples.form.country_placeholder" class="input empty" type="text" placeholder="IT" required="" autocomplete="country">
                      </div>
                    </div>
                  </div>-->
                  <div class="row">
                    <div class="field">
                      <label for="example5-card" data-tid="elements_examples.form.card_label">${i18n.t('payment.paymentMethods.labels.card')}</label>
                      <div id="example5-card" class="input"></div>
                    </div>
                  </div>
                  <button type="submit" data-tid="elements_examples.form.pay_button">${i18n.t('payment.paymentMethods.savePaymentMethod')}</button>
                </fieldset>
                <div class="error" role="alert"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                    <path class="base" fill="#000" d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"></path>
                    <path class="glyph" fill="#FFF" d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"></path>
                  </svg>
                  <span class="message"></span>
                </div>
              </form>
              ${StripeDescriptionHtml}
            </div>
          </section>
        </main>
      </div>
      ${cardElementScript}
      <script>
        function ready(fn) {
          if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"){
            fn();
          } else {
            document.addEventListener('DOMContentLoaded', fn);
          }
        }
        ready(function () {
          // NOTE: This is for ensuring we have a whole page of content displayed so that for a WebView using  WebKit
          // the content is scrollable after the keyboard closes. Stripe content is guaranteed to be at least 300px tall
          document.getElementById('main-tag').style.paddingBottom = (window.innerHeight - 300 + 1) + 'px';
        })
      </script>
    </body>
  </html>
  `
  return html
}
