import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import Icons from '@expo/vector-icons/Ionicons'
import { PropTypes } from 'prop-types'

import _ from 'lodash'
import sharedStyles, { stylus } from '../../config/styles'
import colors from '../../config/colors'
import sizes from '../../config/sizes'

import Modal from '../../components/simple/Modal'
import Checkbox from '../../components/simple/Checkbox'
import Divider from '../../components/simple/Divider'
import Button from '../../components/simple/Button'
import Scroller from '../../components/Scroller/Scroller'
import withAlgoliaSearch from '../../containers/withAlgoliaSearch'

import i18n from 'i18n-js'

@withAlgoliaSearch
class ObjectSearchCriteria extends React.Component {
  static propTypes = {
    searchInput: PropTypes.any,
    closeIconSize: PropTypes.number,
    isVisible: PropTypes.bool.isRequired,
    setVisibility: PropTypes.func.isRequired,
    searchType: PropTypes.string.isRequired,
    objectSearchManager: PropTypes.object.isRequired,
    search: PropTypes.func, // can be used to override default serach based on type and manager
    algoliaSearch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    closeIconSize: 50,
  }

  sortingCriteria() {
    const { objectSearchManager, searchType } = this.props
    const { sorts } = objectSearchManager.getCriteriaFor(searchType)
    const selectedSortName = objectSearchManager.getCurrentSort()
    const elements = []
    for (const sortName in sorts) {
      const sort = sorts[sortName]
      const checked = sortName === selectedSortName
      const onPress = () => {
        objectSearchManager.setSort(sortName)
      }
      elements.push(
        <TouchableOpacity key={sortName} onPress={onPress}>
          <View style={[styles.criteriaOption]}>
            <Text style={[styles.criteriaOptionText]}>{sort.label}</Text>
            <Checkbox size={26} onPress={onPress} checked={checked}/>
          </View>
        </TouchableOpacity>,
      )
    }
    if (elements.length) {
      elements.push(<Divider style={{ marginVertical: 15 }} key='sorting-divider' />)
    }
    return elements
  }

  filteringCriteria() {
    const { objectSearchManager, searchType } = this.props
    const { filters } = objectSearchManager.getCriteriaFor(searchType)
    const enteredFilters = objectSearchManager.getCurrentFiltersFor(searchType)
    const filterOptions = []

    const keys = Object.keys(filters)

    for (const filterName in filters) {
      const index = keys.indexOf(filterName)
      const filter = filters[filterName]

      if (!filter.label || filterName === 'query' ) {
        continue // filters that do not have labels are not user facing
      }

      if (filter.keyLabelPairs) {
        filterOptions.push(
          <Text key={`${filterName}-section`} style={[styles.criteriaTitle]}>
            {filter.label}
          </Text>,
        )
        Object.keys(filter.keyLabelPairs).forEach((filterKey) => {
          const filterField = `${filterName}.${filterKey}`
          const filterLabel = filter.keyLabelPairs[filterKey]
          const filterValue = _.get(enteredFilters, filterField, false)
          const option = this.filterOption(
            filterField,
            filterLabel,
            filterValue,
          )
          filterOptions.push(option)
        })
        if (index < keys.length - 1) {
          filterOptions.push(<Divider style={{ marginVertical: 15 }} key={`${filterName}-divider`}/>)
        }
      } else {
        filterOptions.push(
          this.filterOption(
            filter.param,
            filter.label,
            _.get(enteredFilters, filterName, false),
          ),
        )
      }
    }

    return filterOptions
  }

  filterOption(field, label, value) {
    const onPress = () => {
      this.toggleFilter(field)
    }
    return (
      <TouchableOpacity
        key={`${field}`}
        style={[styles.criteriaOption]}
        onPress={onPress}
      >
        <Text style={[styles.criteriaOptionText]}>{label}</Text>
        <Checkbox size={26} onPress={onPress} checked={value}/>
      </TouchableOpacity>
    )
  }

  render() {
    const { searchInput, isVisible, closeIconSize } = this.props
    return (

      <Modal
        animationType='slide'
        visible={isVisible}
        onRequestClose={this.closeModal}
      >
        <View style={{ flex: 1 }}>
          <Scroller style={[styles.modalContainer]}>
            <View style={[styles.modalHeader]}>
              <TouchableOpacity onPress={this.closeModal}>
                <Icons
                  style={styles.icon}
                  name='ios-close'
                  size={closeIconSize}
                />
              </TouchableOpacity>
              <Text style={[styles.titleText]}>
                {i18n.t('search.filters.general.title')}
              </Text>
              <TouchableOpacity onPress={this.clearCriteria}>
                <Text style={[styles.clearText]}>
                  {i18n.t('search.filters.general.clearAll')}
                </Text>
              </TouchableOpacity>
            </View>
            {searchInput}
            <Divider style={{ marginVertical: 15 }} key='sorting-divider'/>
            <Text style={[styles.criteriaTitle]}>
              {i18n.t('search.filters.general.sortBy')}
            </Text>
            {this.sortingCriteria()}
            {this.filteringCriteria()}
          </Scroller>
          <Button
            label={i18n.t('search.buttons.showResults')}
            labelStyle={styles.buttonLabel}
            style={styles.button}
            onPress={this.showResults}
          />
        </View>
      </Modal>

    )
  }

  clearCriteria = () => {
    const { searchType, objectSearchManager } = this.props
    objectSearchManager.clearCriteriaFor(searchType)
  }

  closeModal = () => {
    const { objectSearchManager, searchType } = this.props
    objectSearchManager.revertToPreviousCriteriaFor(searchType)
    this.props.setVisibility(false)
  }

  toggleFilter = (field) => {
    const { objectSearchManager, searchType } = this.props
    const enteredFilters = objectSearchManager.getCurrentFiltersFor(searchType)

    const value = _.get(enteredFilters, field, false)
    if (!value) {
      objectSearchManager.setFilter(field, true)
    } else {
      objectSearchManager.unsetFilter(field)
    }
  }

  showResults = () => {
    const {
      setVisibility,
      objectSearchManager,
      searchType,
      search,
      algoliaSearch,
    } = this.props
    if (search) {
      search()
    } else {
      objectSearchManager.loadMoreResultsFor(searchType, { algoliaSearch })
    }
    setVisibility(false)
  }
}

export default ObjectSearchCriteria

const styles = stylus({
  icon: {
    paddingTop: 2,
    color: colors.text.main,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clearText: {
    fontSize: 14,
    color: colors.text.main,
  },
  titleText: {
    fontSize: 17,
    fontWeight: '600',
    color: colors.text.main,
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    zIndex: -1
  },
  modalContainer: {
    paddingHorizontal: 15,
    paddingBottom: 0,
    web: {
      paddingTop: 15,
    },
    ios: {
      paddingTop: sizes.iosStatusBarHeight,
    },
    iphonex: {
      paddingTop: sizes.TOP_BAR_HEIGHT,
    },
  },
  criteriaTitle: {
    fontSize: 17,
    fontWeight: '600',
    color: colors.text.main,
    marginBottom: 10,
  },
  criteriaOption: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  criteriaOptionText: {
    fontSize: 15,
    fontWeight: '300',
    color: colors.text.main,
  },
  button: {
    marginHorizontal: 30,
    backgroundColor: colors.primary,
    marginTop: 15,
    marginBottom: 15,
    android: {
      marginBottom: 15,
    },
    iphonex: {
      marginBottom: 30,
    },
  },
  buttonLabel: {
    fontSize: 16,
  },
})
