import React, { Component } from 'react'
import { StyleSheet, View, Image } from 'react-native'

// a very simple solution, could be made better later
const sizeCache = {}

export const precacheImageSize = ({ id, uri }) => {
  if (sizeCache[id]) {
    return
  }
  Image.getSize(uri, (width, height) => {
    sizeCache[id] = { width, height }
  })
}

class ControlledImage extends Component {
  static defaultProps = {
    resizeMode: 'stretch',
    maxHeight: 520,
  }
  // in a flat list, items pop in and out of rendering, causing jaggiezz so we need to persist/cache the measurements
  componentDidMount() {
    const { id, uri } = this.props
    this._mounted = true
    if (sizeCache[id]) {
    } else {
      Image.getSize(uri, (width, height) => {
        if (this._mounted) {
          this.setState({ loading: false, size: { width, height } })
        }
        sizeCache[id] = { width, height }
      })
    }
  }
  componentWillUnmount() {
    this._mounted = false
  }
  constructor(props) {
    super(props)
    const { width, height, containerWidth, id } = this.props
    this.state = {
      loading: sizeCache[id] ? false : true,
      size: sizeCache[id],
      containerWidth,
    }
  }
  measure(e) {
    // console.log('width: ', e.nativeEvent.layout)
    this.setState({
      containerWidth: e.nativeEvent.layout.width,
    })
  }
  render() {
    const { loading, size } = this.state
    if (loading) {
      return null
    }
    const { resizeMode, maxHeight, containerWidth } = this.props
    let actualWidth = containerWidth
    let actualHeight = (containerWidth / size.width) * size.height
    if (actualHeight > maxHeight) {
      const ratio = maxHeight / actualHeight
      actualWidth = actualWidth * ratio
      actualHeight = actualHeight * ratio
    }

    const dimensions = {
      width: actualWidth,
      height: actualHeight,
    }
    return (
      <View
        style={[styles.wrapper, loading && styles.loading]}
        // onLayout={(e) => this.measure(e)}
      >
        <Image
          style={[styles.image, dimensions]}
          resizeMode={'contain'}
          source={{ uri: this.props.uri }}
        />
      </View>
    )
  }
}

export default ControlledImage

const styles = StyleSheet.create({
  loading: {
    height: 300,
  },
  wrapper: {
    alignSelf: 'stretch',
    alignItems: 'center',
    flex: 1,
  },
  image: {
    flex: 1,
  },
})
