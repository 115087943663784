import React from 'react'
import { View } from 'react-native'
import DatePicker from 'react-datepicker'
import i18n from 'i18n-js'
import ElegantInput from '../ElegantInput'
import { getDate } from '../../../config/helpers'

import 'react-datepicker/dist/react-datepicker.css'

export default function ElegantDatePicker({ 
  value, 
  minimumDate,
  onChange,
  ...props 
}) {
  return (
    <DatePicker
      selected={value}
      minDate={minimumDate}
      onChange={date => onChange(date)}
      locale={i18n.locale}
      customInput={(
        <ElegantInput
          {...props}
          value={getDate(value)}
          disabled
        />
      )}
    />
  )
}