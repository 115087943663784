function getBase64Data(uri) {
  if (!uri) {
    return ''
  }
  return uri.split(',')[1]
}

export async function getFileSize(uri) {
  return getBase64Data(uri).length // data after mime type metadata
}

export async function getInfoAsync(fileUri, options) {
  const size = await getFileSize(fileUri)
  return Promise.resolve({
    exists: true,
    isDirectory: false,
    lastModified: 0,
    size,
    uri: fileUri,
    md5: options.md5 ? '' : undefined,
  })
}

export async function readAsStringAsync(uri, options = { length: 0, position: 0 }) {
  if (!options.length) {
    return getBase64Data(uri)
  }
  const indexStart = options.position
  const indexEnd = indexStart + options.length
  return getBase64Data(uri).substring(indexStart, indexEnd)
}

export function writeAsStringAsync(fileUri, contents, options) {
  return Promise.resolve(true)
}

export function deleteAsync(fileUri, options) {
  return Promise.resolve(true)
}

export function moveAsync(options) {
  return Promise.resolve(true)
}

export function copyAsync(options) {
  return Promise.resolve(true)
}

export function makeDirectoryAsync(fileUri, options) {
  return Promise.resolve(true)
}

export function readDirectoryAsync(fileUri, options) {
  return Promise.resolve([])
}

export function downloadAsync(uri, fileUri, options) {
  return Promise.resolve({
    uri: '',
    status: -1,
    headers: {},
    md5: undefined,
  })
}

export function createDownloadResumable(uri, fileUri, options, callback, resumeData) {
  const DownloadResumable = {
    downloadAsync () {
      return Promise.resolve({
        uri: fileUri,
        status: -1,
        headers: {},
        md5: options.md5 ? '' : undefined,
      })
    },
    pauseAsync() {
      return Promise.resolve({
        url: '',
        fileUri: '',
        options: {
          md5: undefined
        },
        resumeData: ''
      })
    },
    resumeAsync () {
      return Promise.resolve({
        uri: '',
        status: -1,
        headers: {},
        md5: undefined,
      })
    },
    savable() {
      return {
        url: '',
        fileUri: '',
        options: {
          md5: undefined
        },
        resumeData: ''
      }
    }
  }
  return DownloadResumable
}

export function getContentUriAsync(fileUri) {
  return Promise.resolve(fileUri)
}

export function getFreeDiskStorageAsync() {
  return Promise.resolve(Number.MAX_SAFE_INTEGER)
}

export function getTotalDiskCapacity() {
  return Promise.resolve(Number.MAX_SAFE_INTEGER)
}

export const documentDirectory = ''
export const cacheDirectory = ''
export const bundledAssets = ''
export const bundleDirectory = ''
