import { Platform } from 'react-native'
import React, { Component } from 'react'
import { Notifications } from 'expo'
import Constants from 'expo-constants'
import * as Permissions from 'expo-permissions'
import { compose, gql, graphql } from '../config/connected'
import withCurrentUser from './withCurrentUser'
import _ from 'lodash'

const debug = false

export class RegisterDevice extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = _.get(nextProps, 'currentUser.currentUser.id')
    if (id !== _.get(this.props, 'currentUser.currentUser.id')) {
      if (id) {
        this.save()
      }
    }
  }
  async save() {
    // the device info to save to the server
    let info

    if (Platform.OS === 'web') {
      const userAgent = navigator.userAgent
      info = {
        userAgent,
        platform: 'web',
      }
    } else {
      // android or ios
      const { status: existingStatus } = await Permissions.getAsync(
        Permissions.NOTIFICATIONS,
      )
      let finalStatus = existingStatus

      if (existingStatus !== 'granted') {
        const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS)
        finalStatus = status
      }

      // TODO, maybe detect client-side if we already saved this device
      // const alreadySaved = await AsyncStorage.getItem('PUSH_TOKEN_SAVED')
      // if (!alreadySaved) {
      //   return
      // }

      let token
      if (finalStatus === 'granted') {
        token = await Notifications.getExpoPushTokenAsync()
      }

      const {
        expoVersion,
        installationId,
        deviceName,
        deviceYearClass,
        platform,
        manifest,
      } = Constants

      info = {
        platform: Platform.OS,
        expoVersion,
        appId: installationId,
        deviceName,
        deviceYearClass: String(deviceYearClass),
        pushToken: token,
      }
      if (Platform.OS === 'ios') {
        const { systemVersion, model, buildNumber } = platform.ios
        info = {
          ...info,
          systemVersion,
          model,
          build: buildNumber ? buildNumber : manifest.version,
        }
      } else if (Platform.OS === 'android') {
        info.build = (_.get(platform, 'android.versionCode') || manifest.version || '').toString() // it's null if app is running in Expo client
      }
    }

    // console.log(info)

    try {
      debug && console.log('SAVING DEVICE NOW')
      const result = await this.props.saveDevice({
        variables: info,
      })
      // await AsyncStorage.setItem('PUSH_TOKEN_SAVED', 'yes')
    } catch (e) {
      console.log({ error: e })
    }
  }

  render() {
    return null
  }
}

const saveDevice = gql`
  mutation(
    $userAgent: String
    $platform: String!
    $expoVersion: String
    $appId: String
    $deviceName: String
    $deviceYearClass: String
    $systemVersion: String
    $model: String
    $build: String
    $pushToken: String
  ) {
    saveDevice(
      userAgent: $userAgent
      platform: $platform
      expoVersion: $expoVersion
      appId: $appId
      deviceName: $deviceName
      deviceYearClass: $deviceYearClass
      systemVersion: $systemVersion
      model: $model
      build: $build
      pushToken: $pushToken
    )
  }
`

const Composed = compose(
  withCurrentUser,
  graphql(saveDevice, {
    name: 'saveDevice',
  }),
)(RegisterDevice)

export default Composed
