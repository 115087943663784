import React from 'react'
import { compose, graphql, connect } from '../../config/connected'
import schema from '../../schema/user'
import onboardingSchema from '../../screens/Start/schema'
import { ANALYTICS_PROFILE_EDIT } from '../../reducers/analytics'
import { USER_UNSET_PROFILE_IMAGE_UPLOAD } from '../../reducers/currentUser.js'
import { updateCachedQueryValue } from '../../utility/apollo'

import _ from 'lodash'
import produce from 'immer'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  analyticsProfileEdit: (oldData, newData) => {
    let diff = Object.keys(newData).reduce((diff, key) => {
      if (oldData[key] === newData[key]) return diff
      return {
        ...diff,
        [key]: newData[key],
      }
    }, {})

    dispatch({
      type: ANALYTICS_PROFILE_EDIT,
      data: {
        oldData,
        newData,
        updatedData: diff
      },
    })
  },
  dispatch,
})

function withPublishUser(Composed) {
  return (compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(schema.mutations.updateProfile, {
      name: 'updateProfile',
    }),
    graphql(onboardingSchema.updateInterests, {
      name: 'updateInterests',
    }),
  )(
    class WithPublishUser extends React.Component {
      publishUser = async(userData) => {
        const { initialValues, values } = userData
        const interests = _.map(values.interests, 'value')
        const profileResult = await this.props.updateProfile({
          variables: values,
          update: (store, { data: { updateProfile } }) => {
            updateCachedQueryValue(store, {
              query: schema.queries.currentUserFullProfile,
              nextValue: (userProfile) => {
                return produce(userProfile, draft => {
                  Object.assign(draft, updateProfile )
                  draft.personDetails.interests = interests
                }) 
              }
            })
          }
        })
        await this.props.updateInterests({
          variables: { interests },
        })

        this.props.analyticsProfileEdit(initialValues, { ...values, interests })
        this.props.dispatch({
          type: USER_UNSET_PROFILE_IMAGE_UPLOAD,
        })
      }

      render() {
        return <Composed
          {...this.props}
          publishUser={this.publishUser}
        />
      }
    }
  ))
}

export default withPublishUser
