import React from 'react'
import { TouchableOpacity } from 'react-native'
import Icons from '@expo/vector-icons/Ionicons'
import PropTypes from 'prop-types'
import colors from '../../config/colors'

const DeleteButton = ({ data, onDelete, right = 15, top = 15 }) => {
  return (
    <TouchableOpacity
      onPress={() => {
        onDelete(data)
      }}
      style={{ position: 'absolute', right, top }}>
      <Icons
        color={colors.primary}
        name={'ios-close-circle'}
        size={32}/>
    </TouchableOpacity>
  )
}

DeleteButton.propTypes = {
  onDelete: PropTypes.func,
  data: PropTypes.shape({
    height: PropTypes.any,
    mime: PropTypes.any,
    name: PropTypes.any,
    width: PropTypes.any,
  }),
  right: PropTypes.number,
  top: PropTypes.number,
}

export default DeleteButton
