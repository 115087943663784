import { connect, graphql, withApollo, compose, gql } from '../config/connected'
import uploads from '../reducers/uploads'

const conversation = `
  id
  lastMessage {
    id
    createdAt
    user {
      id
      username
      displayName
      profileImage
      title
      organization
      organizationId
    }
    text
    upload {
      name
      mime
      width
      height
    }
  }
  memberships {
    id
    user {
      id
      username
      displayName
      profileImage
      title
      organization
      organizationId
    }
  }
`
const conversationMembership = `
  id
  conversation {
    ${conversation}
  }
  unreadCount
  lastTouch
  isOwner
`
const message = `
  id
  createdAt
  conversationId
  user {
    id
    username
    displayName
    profileImage
    title
    organization
    organizationId
  }
  text
  upload {
    name
    mime
    width
    height
  }

`
export default {
  queries: {
    messages: gql`
      query($membershipId: String!, $last: Date, $limit: Int) {
        messages(
          membershipId: $membershipId,
          last: $last,
          limit: $limit
        ) {
          ${message}
        }
      }
    `,
    conversationMembership: gql`
      query($id: String!) {
        conversationMembership(id: $id) {
          ${conversationMembership}
        }
      }
    `,
    conversationMemberships: gql`
      query($last: Date, $limit: Int) {
        conversationMemberships(last: $last, limit: $limit) {
          ${conversationMembership}
        }
      }
    `,
  },
  mutations: {
    startPrivateConversation: gql`
      mutation($userIds: [String!]!) {
        startPrivateConversation(userIds: $userIds) {
          ${conversationMembership}
        }
      }
    `,
    createMessage: gql`
      mutation($membershipId: String!, $text: String, $upload: Json) {
        createMessage(membershipId: $membershipId, text: $text, upload: $upload) {
          ${message}
        }
      }
    `,
    membershipSeen: gql`
      mutation($id: String!) {
        membershipSeen(id: $id) {
          ${conversationMembership}
        }
      }
    `,
    fetchMembership: gql`
      mutation($conversationId: String!) {
        fetchMembership(conversationId: $conversationId) {
          ${conversationMembership}
        }
      }
    `,
  },
  subscriptions: {
    conversationMemberships: gql`
      subscription {
        conversationMemberships {
          ${conversationMembership}
        }
      }
    `,
    messageAdded: gql`
      subscription {
        messageAdded {
          ${message}
        }
      }
    `,
  },
}
