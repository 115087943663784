
import React from 'react'
import { Notifications } from 'expo'
import NavigationActions from '../utility/navigationActions'

import { connect } from '../config/connected'
import branch from '../config/branch'
import routeFromNotification from '../screens/Notifications/routeFromNotification'

import _ from 'lodash'

const mapStateToProps = ({ navigation, currentUser }) => ({ navigation, currentUser })
const mapDispatchToProps = (dispatch) => ({ dispatch })

@connect(mapStateToProps, mapDispatchToProps)
class NotificationManager extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ready: false }
    this.lastPushData = null
  }

  componentDidMount() {
    branch({
      native: () => this.notificationsListener = Notifications.addListener(this.handlePushNotification)
    })
  }

  componentWillUnmount() {
    branch({
      native: () => this.notificationsListener.remove()
    })
  }

  async componentDidUpdate(prevProps, prevState) {
    const { state, props } = this
    // 1. Signal we're ready at the right time
    if (!this.state.ready && this.readyToHandlePushNotification(prevProps, props)) {
      console.log('<<<NOTIFICATION MANAGER - ready')
      this.setState({ ready: true })
    }
    // 2. Once we are ready, handle last push notification that was received when we were not ready
    if (!prevState.ready && this.state.ready && this.lastPushData) {
      this.navigateToRouteByData(this.lastPushData)
    }
  }

  readyToHandlePushNotification = (prevProps, props) => {
    return !_.isEmpty(props.currentUser) && props.navigation.isReady === true
  }
  

  handlePushNotification = ({ origin, data, remote }) => {
    if (!this.state.ready) {
      this.lastPushData = data
      return
    }
    //PUSH NOTIFICATION DATA
    //console.log('<<<Push Notification Data', data)
    if (!remote || origin !== 'selected') {
      return // No handling for local/received notifications. Only for selected remote push notifications.
    }
    this.navigateToRouteByData(data)
  }

  navigateToRouteByData = (data) => {
    const { dispatch } = this.props
    const { type } = data
    const { useGoTo = false, ...route } = routeFromNotification({ type, data, counter: 1, push: true })
    if (useGoTo) {
      NavigationActions.goTo(route)
    } else {
      NavigationActions.navigate(route)
    }
  }

  render() {
    return null
  }
}

export default NotificationManager
