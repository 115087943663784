import React from 'react'
import { Text, View, Image, Dimensions, ScrollView } from 'react-native'

import PropTypes from 'prop-types'
import NavigationActions from '../../utility/navigationActions'
import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'
import { connect } from 'react-redux'
import _ from 'lodash'
import Icons from '@expo/vector-icons/Ionicons'

class TabIndicator extends React.Component {
  static propTypes = {
    length: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    containerStyle: PropTypes.any,
  }

  indicator = (active, key) => {
    if (active) {
      return <View key={key} style={[styles.dot, styles.dotActive]} />
    } else {
      return <View key={key} style={[styles.dot, styles.dotInactive]} />
    }
  }

  renderItems = (length, index) => {
    let items = []

    for (let i = 0; i < length; i++) {
      let active = i === index
      items.push(this.indicator(active, i))
    }
    return items
  }

  render() {
    const { length, index, containerStyle } = this.props
    if (length === 1) {
      return null
    }
    return (
      <View style={[styles.container, containerStyle]}>
        {this.renderItems(length, index)}
      </View>
    )
  }
}

const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    marginHorizontal: 2.5,
  },
  dotActive: {
    backgroundColor: colors.text.main,
  },
  dotInactive: {
    backgroundColor: colors.text.secondary,
  },
}

export default TabIndicator
