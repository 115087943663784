import React, { Component, useEffect } from 'react'
import { Text, View } from 'react-native'
import { stylus } from '../../config/styles'
import Icon from '../../config/icon'
import { connect, compose, graphql } from '../../config/connected'
import gql from 'graphql-tag'
import withCurrentUser from '../../containers/withCurrentUser'
import _ from 'lodash'

function NotificationsIcon({
  focused,
  tintColor,
  iconSize,
  currentUser,
}) {
  const unseenBadge = _.get(currentUser, 'currentUser.unseenBadge.unseenNotifications', 0)
  return (
    <View>
      <Icon
        name={focused ? 'notifications' : 'notifications-outline'}
        size={iconSize}
        color={tintColor}
      />
      {unseenBadge > 0 && (
        <View style={styles.iconIndicator}>
          <Text style={styles.iconIndicatorText}>{unseenBadge}</Text>
        </View>
      )}
    </View>
  )
}

export default compose(withCurrentUser)(NotificationsIcon)

const styles = stylus({
  iconIndicator: {
    position: 'absolute',
    right: 0,
    backgroundColor: 'red',
    borderRadius: 8,
    width: 16,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconIndicatorText: {
    marginTop: -1,
    color: 'white',
    fontSize: 9,
    fontWeight: 'bold',
  },
})
