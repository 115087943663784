import { gql } from '../config/connected'
import { product } from './cart'
import { stripeInfo } from './payment'
import { stripePaymentIntentFragment, couponFieldsFragment } from './fragments'

const fileUpload = `
  name
  mime
  width
  height
`

const address = `
  recipient
  address1
  address2
  city
  region
  zip
  country
  phone
  email
`

export const productDetails = `
  id
  createdAt
  userId
  uploads {
    ${fileUpload}
  }
  name
  categories{
    allergens
  }
  rating
  ratingCount
  stock
  quantityUnits
  price
  priceDiscount
  isDiscontinued
  isInStock
  description
  ingredients
  unitType
  unitQuantity
`

const fulfillment = `
  id
  courier
  trackingNumber
`

const orderItem = `
  id
  orderId
  productId
  quantity
  createdAt
  fulfilledAt
  fulfillment {
    ${fulfillment}
  }
  productDetails {
    ${productDetails}
  }
  product {
    ${product}
  }
`

const order = `
  id
  orderNumber
  checkoutId
  userId
  shopId
  fulfilledAt
  createdAt
  status
  stripeInfo {
    ${stripeInfo}
  }
  coupon {
    ...CouponFieldsFragment
  }
  address {
    ${address}
  },
  billingAddress
  paymentDetails
  credit
`

const producerDetails = `
  id
  description
  legalName
  established
  paymentTypes {
    cash
    transfer
    stripe
  }
  policies {
    shipping
    returns
    payment
  }
  ownership {
    id
    owner {
      id
      displayName
      profileImage
    }
  }
  ibanNumber
  bankAccountHolder
  standardShippingFee
  freeShippingThreshold
  minimumOrderAmount
  category
  producerType
  method
  website
  approved
  stripeConnected
`

const userFields = `
  id
  phone
  displayName
  username
  email
  profileImage
  coverPhoto
`

const withItems = `
  orderItems {
     ${orderItem}
  }
`

const withFulfillments = `
  orderFulfillments {
     ${fulfillment}
  }
`

const withProducerDetails = `
  producerDetails {
    ${producerDetails}
  }
`

const withShop = `
  shop {
    ${userFields}
    ${withProducerDetails}
  }
`

const withUser = `
  user {
    ${userFields}
  }
`

const withStripePaymentIntent = `
  stripePaymentIntent {
    ...StripePaymentIntentFragment
    charges
    balance_transaction
    application_fee_amount
  }
`

export default {
  queries: {
    orderById: gql`
      query($id: String!) {
        orderById(id: $id) {
          ${order}
          ${withItems}
          ${withUser}
          ${withShop}
          ${withStripePaymentIntent}
          ${withFulfillments}
        }
      }
      ${couponFieldsFragment}
      ${stripePaymentIntentFragment}
    `,
    ordersByCheckoutId: gql`
      query($checkoutId: String!) {
        ordersByCheckoutId(checkoutId: $checkoutId) {
          ${order}
          ${withUser}
          ${withShop}
          ${withItems}
          ${withProducerDetails}
          ${withStripePaymentIntent}
        }
      }
      ${couponFieldsFragment}
      ${stripePaymentIntentFragment}
    `,
    ordersByUserIdForUserType: gql`
      ${couponFieldsFragment}

      query($userId: String!, $userType: UserType!, $offset: Int, $limit: Int) {
        ordersByUserIdForUserType(userId: $userId, userType: $userType, offset: $offset, limit: $limit) @connection(key: "ordersByUserIdForUserType", filter: ["userId", "userType"]) {
          ${order}
          ${withItems}
          ${withShop}
          ${withUser}
        }
      }
    `,
    profileOrderItems: gql`
      query($userId: String!, $last: Date, $limit: Int, $offset: Int) {
        profileOrderItems(userId: $userId, last: $last, limit: $limit, offset: $offset) {
          ${orderItem}
        }
      }
    `,
    ordersBySearchTextForUserType: gql`
      ${couponFieldsFragment}

      query($userId: String!, $userType: UserType!, $text: String) {
        ordersBySearchTextForUserType(userId: $userId, userType: $userType, text: $text) {
          ${order}
          ${withItems}
          ${withShop}
          ${withUser}
        }
      }
    `,
  },
  mutations: {
    createOrders: gql`
      mutation($userId: String!, $address: Json!) {
        createOrders(userId: $userId, address: $address, billingAddress: $billingAddress) {
          ${order}
          ${withShop}
          ${withUser}
          ${withStripePaymentIntent}
        }
      }
      ${couponFieldsFragment}
      ${stripePaymentIntentFragment}
    `,
    markOrderItemsFulfilled: gql`
      mutation($orderId: String!, $ids: [String!]!, $courier: String, $trackingNumber: String) {
        markOrderItemsFulfilled(orderId: $orderId, ids: $ids, courier: $courier, trackingNumber: $trackingNumber) {
          ${order}
          ${withUser}
          ${withShop}
          ${withItems}
          ${withStripePaymentIntent}
          ${withFulfillments}
        }
      }
      ${couponFieldsFragment}
      ${stripePaymentIntentFragment}
    `,
    updateOrderFulfillment : gql`
      mutation($orderId: String!, $fulfillmentId: String!, $courier: String, $trackingNumber: String) {
        updateOrderFulfillment(orderId: $orderId, fulfillmentId: $fulfillmentId, courier: $courier, trackingNumber: $trackingNumber) {
          ${order}
          ${withUser}
          ${withShop}
          ${withItems}
          ${withStripePaymentIntent}
          ${withFulfillments}
        }
      }
      ${couponFieldsFragment}
      ${stripePaymentIntentFragment}    
    `,
  },
}
