import React from 'react'
import { Keyboard, KeyboardAvoidingView, Platform, Text, TouchableOpacity, View } from 'react-native'
import NavigationActions from '../../../utility/navigationActions'
import {
  ADDRESS_FIELD_ADDRESS1,
  ADDRESS_FIELD_ADDRESS2,
  ADDRESS_FIELD_CITY,
  ADDRESS_FIELD_COUNTRY,
  ADDRESS_FIELD_REGION,
  ADDRESS_FIELD_ZIP,
  ROUTE_CHOOSE_PAYMENT_METHOD,
} from '../../../config/constants'

import ErrorMessage from '../../../components/simple/ErrorMessage'
import Button from '../../../components/simple/Button'
import MobileBackButton from '../../../components/simple/MobileBackButton'
import ElegantInput from '../../../components/simple/ElegantInput'
import Scroller from '../../../components/Scroller/Scroller'
import withKeyboardInfo from '../../../containers/withKeyboardInfo'
import KeyboardShift from '../../../containers/KeyboardShift'
import { StripePaymentModal } from '../../../components/Stripe/StripePayment'
import StripeElementsCardModal from '../../../components/Stripe/StripeElementsCard/StripeElementsCardModal'
import OrderTotals from '../Order/OrderTotals'
import CartProduct from '../Cart/CartProduct'
import PaymentMethod from '../../Payments/PaymentMethods/PaymentMethod'
import Coupon from '../../../components/Coupon'
import ZocialIcon from '@expo/vector-icons/Zocial'
import MDCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons'

import { connect, graphql } from '../../../config/connected'
import sharedStyles, { stylus } from '../../../config/styles'
import colors from '../../../config/colors'
import sizes from '../../../config/sizes'
import branch from '../../../config/branch'
import { getFeatureFlag, mergeNavOptionsForFeedLogo, getTotalPrices, handleAddressError, getKeyboardPaddingStyle } from '../../../config/helpers'

import schema from '../../../schema/cart'
import orderSchema from '../../../schema/orders'
import paymentScehma from '../../../schema/payment'
import userSchema from '../../../schema/user'
import couponsSchema from '../../../schema/coupon'
import checkoutSessionsSchema from '../../../schema/checkoutSession'
import addressSchema from '../../../schema/address'
import alert from '../../../utility/alert'
import { updateCachedQueryValue } from '../../../utility/apollo'
import { client  } from '../../../containers/withApollo'
import Linking from '../../../config/nativeLink'

import i18n from 'i18n-js'
import _ from 'lodash'
import PropTypes from 'prop-types'
import BillingAddress from './BillingAddress'
import { ANALYTICS_BUY_NOW } from '../../../reducers/analytics'
import AddressSelector from '../../../components/Address/AddressSelector'


const mapStateToProps = (state, ownProps) => {
  const userId = state.currentUser.id
  return {
    userId,
    screenInfo: state.screenInfo,
    billing: state.address.billing,
    shipping: state.address.shipping,
  }
}

function getDefaultShippingAddress(addresses) {
  return _.chain(addresses).filter({ isDefault: true }).first().value() || {}
}

function getDefaultBillingAddress(addresses) {
  let value = _.chain(addresses).filter({ isDefault: true, isBilling: true }).first().value()
  if (_.isEmpty(value)) {
    value = _.chain(addresses).filter({ isBilling: true }).orderBy('updatedAt', 'DESC').first().value()
  }
  return value || {}
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  analyticsBuyNow: () => {
    dispatch({
      type: ANALYTICS_BUY_NOW,
    })
  },
})
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@graphql(userSchema.queries.getUserShop, { name: 'currUserShop' })
@graphql(schema.queries.cartItemsByUserId, {
  name: 'cartItemsByUserIdHandler',
  options({ userId }) {
    return { variables: { userId } }
  },
})
@graphql(checkoutSessionsSchema.queries.myCheckoutSession, {
  name: 'myCheckoutSessionHandler',
})
@graphql(schema.queries.userById, {
  name: 'userByIdHandler',
  options({ userId }) {
    return { variables: { id: userId } }
  },
})
@graphql(paymentScehma.queries.defaultStripePaymentMethod, {
  name: 'defaultStripePaymentMethodHandler',
})
@graphql(orderSchema.mutations.createOrders, {
  name: 'createOrders',
})
@graphql(couponsSchema.mutations.applyCoupon, {
  name: 'applyCoupon'
})
@graphql(couponsSchema.mutations.revertCoupon, {
  name: 'revertCoupon'
})
@withKeyboardInfo
class Checkout extends React.Component {
  constructor(props) {
    super(props)
    // TODO: Removing state for testing Stripe Checkout
    this.state = {
      stripeElementsCardModalVisible: false,
      stripePaymentModalVisible: false,
      shipping: {},
      minimumOrderAmountFail: null,
      billing: {},
      billingIsValid: true,
      showFooterButton: true,
      couponName: '',
    }
    this.allPaymentMethods = {}
    this.allShopsSupportStripe = true
  }

  componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      this._keyboardDidShow,
    )
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      this._keyboardDidHide,
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const prevDisplayPopup =  _.get(prevProps, 'navigation.state.params.displayOrdersFailedPopup', false)
    const currDisplayPopup = _.get(this.props, 'navigation.state.params.displayOrdersFailedPopup', false)
    if (!prevDisplayPopup && currDisplayPopup) {
      this.props.navigation.setParams({ displayOrdersFailedPopup: false })
      alert({
        title: i18n.t('order.checkout.ordersFailedHeader'),
        message: i18n.t('order.checkout.ordersFailed'),
      }) 
    }
  }

  componentWillUnmount() {
    this.keyboardDidShowListener.remove()
    this.keyboardDidHideListener.remove()
  }

  _keyboardDidShow = () => {
    Platform.OS === 'android' && this.setState({showFooterButton: false})
  }

  _keyboardDidHide = () => {
    Platform.OS === 'android' && this.setState({showFooterButton: true})
  }

  cartProductElements = (cartItems = []) => {
    const { myCheckoutSessionHandler: { myCheckoutSession} } = this.props
    const coupon = this.getCoupon()
    const producerTotalTitle = i18n.t('order.checkout.orderTotal')
    const derivedCartElements = []
    let previousShop = null
    let previousShopItems = []
    let number = 1
    const shopIds = []
    const shopOrderError = {}
    cartItems.forEach((cartItem) => {
      const { product } = cartItem
      const { profile: shop } = product
      if (shopIds.indexOf(shop.id) === -1) {
        shopIds.push(shop.id)
        if (getFeatureFlag('stripe')) {
          const stripePaymentIntentId = _.get(myCheckoutSession, `stripeInfo.${shop.id}.paymentIntentId`)
          const paymentIntent = (_.get(myCheckoutSession, 'stripePaymentIntents') || []).find(intent => intent.id === stripePaymentIntentId)
          const last_payment_error = _.get(paymentIntent, 'last_payment_error')
          shopOrderError[shop.id] = last_payment_error
          if (!_.get(shop, 'producerDetails.stripeUserId')) {
            console.log('Does not support stripe')
            this.allShopsSupportStripe = false
          }
        }
      }
    })
    const max = shopIds.length
    cartItems.map((cartItem) => {
      const clonedCartItem = { ...cartItem }
      const { product } = clonedCartItem
      const { profile: shop } = product
      const {
        producerDetails: { paymentTypes },
      } = shop
      for (const paymentType in paymentTypes) {
        const accepted = paymentTypes[paymentType]
        if (accepted) {
          this.allPaymentMethods[paymentType] = true
        }
      }
      if (shop.id !== _.get(previousShop, 'id', -1)) {
        if (previousShop) {
          derivedCartElements.push(
            <OrderTotals
              key={`totals-for-shop-id-${previousShop.id}`}
              items={previousShopItems}
              coupon={coupon}
              shop={previousShop}
              totalTitle={producerTotalTitle}
              style={styles.shopTotalsContainer}
              forShop
              showFreeShippingMessage
              showCouponPercentage
            />,
          )
        }
        derivedCartElements.push(
          <View
            style={styles.sectionHeader}
            key={`header-for-shop-id-${shop.id}`}
          >
            <Text style={[sharedStyles.h5, styles.orderCountTitle]}>
              {i18n.t('order.checkout.orderPosition', { number, max })}
            </Text>
            <Text style={[styles.shopName]}>{shop.displayName}</Text>
            {shopOrderError[shop.id] ? (
              <ErrorMessage
                tType={'order.orderConfirmation.orderFailed'}
                message={shopOrderError[shop.id].message}
              />
            ) : null}
          </View>,
        )
        previousShop = shop
        number++
        previousShopItems = []
      }
      previousShopItems.push(cartItem)
      derivedCartElements.push(
        <CartProduct
          key={`widget-for-cart-item-id-${clonedCartItem.id}`}
          productKey={'product'}
          cartItem={clonedCartItem}
          totalTitle={producerTotalTitle}
          style={styles.cartProduct}
          showQuantity
        />,
      )
    })
    if (previousShop) {
      const producerTotalsStyle = { ...styles.shopTotalsContainer }
      producerTotalsStyle.marginBottom = 20
      derivedCartElements.push(
        <OrderTotals
          key={`totals-for-shop-id-${previousShop.id}`}
          items={previousShopItems}
          coupon={coupon}
          shop={previousShop}
          totalTitle={producerTotalTitle}
          style={producerTotalsStyle}
          forShop
          showFreeShippingMessage
          showCouponPercentage
        />,
      )
    }
    return derivedCartElements
  }

  paymentType(paymentType) {
    let icon = null
    let methodText = null
    switch (paymentType) {
      case 'cash':
        methodText = i18n.t('order.order.paymentMethod.cash')
        icon = (
          <MDCommunityIcon
            key={'cash'}
            style={styles.margin}
            name='cash-multiple'
            color={colors.black}
            size={16}
          />
        )
        break
      case 'transfer':
        methodText = i18n.t('order.order.paymentMethod.transfer')
        icon = (
          <MDCommunityIcon
            key={'transfer'}
            style={styles.margin}
            name='bank'
            color={colors.black}
            size={16}
          />
        )
        break
      case 'stripe':
        methodText = i18n.t('order.order.paymentMethod.stripe')
        icon = (
          <ZocialIcon
            key={'stripe'}
            style={styles.margin}
            name='stripe'
            color={colors.black}
            size={14}
          />
        )
        break
    }
    if (!icon || !methodText) {
      return null
    }
    return (
      <Text key={methodText} style={styles.paymentMethodText}>
        {icon} - {methodText}
      </Text>
    )
  }

  paymentTypes() {
    const paymentMethods = Object.keys(this.allPaymentMethods).sort()
    return paymentMethods.map((method) => this.paymentType(method))
  }

  paymentMethod() {
    const paymentMethodTitle = i18n.t('order.checkout.paymentMethodTitle')
    const {
      defaultStripePaymentMethod: paymentMethod,
    } = this.props.defaultStripePaymentMethodHandler
    return (
      <React.Fragment>
        <View style={styles.paymentMethodTitleContainer}>
          <Text style={[sharedStyles.h5, styles.paymentMethodTitle]}>
            {paymentMethodTitle}
          </Text>
          {!getFeatureFlag('stripe') ||
          this.props.defaultStripePaymentMethodHandler.loading ||
          !paymentMethod ? null : (
              <Text
                onPress={this.changeCardAction}
                style={styles.changeCardAction}
              >
                {' '}
              ({i18n.t('common.change')})
              </Text>
            )}
        </View>
        {getFeatureFlag('stripe') ? this.renderStripe() : this.renderLegacy()}
      </React.Fragment>
    )
  }

  renderApplyCoupon() {
    const { cartItemsByUserId: cartItems } = this.props.cartItemsByUserIdHandler
    const coupon = this.getCoupon()

    if (this.props.myCheckoutSessionHandler.loading) {
      return null
    }
    // console.log('<<<CHECKOUT - session', this.props.myCheckoutSessionHandler.myCheckoutSession)
    // console.log('<<<CHECKOUT - coupon', coupon)
    const totals = getTotalPrices(cartItems, (item) => item.product, undefined, coupon)
    const applyDisabled = this.state.couponName === ''

    return (
      <View style={{ marginTop: 20 }}>
        <Text style={[sharedStyles.h5, {
          fontWeight: '600',
          color: colors.text.main,
        }]}>{i18n.t('coupon.apply.couponTitle')}</Text>
        
        {coupon ? ( 
          <Coupon
            couponName={_.get(coupon, 'name')}
            couponAmount={totals.couponSavings}
            showRevert={true}
            onRevert={this.revertCoupon}
          />
        ) : (
          <>
            <View style={styles.applyCouponContainer} >
              <ElegantInput
                containerStyle={styles.applyCouponInputContainer}
                onChange={this.onCouponNameChange}
                value={this.state.couponName}
                autoCorrect={false}
                placeholder={i18n.t('coupon.apply.placeholder')}
                placeholderTextColor={colors.input.placeholder}
              />
              <Text
                onPress={this.applyCoupon}
                style={[styles.applyCouponAction, applyDisabled && styles.applyCouponActionDisabled]}
              >
                {i18n.t('common.apply')}
              </Text>
            </View>
            { this.state.applyCouponError ? (
              <ErrorMessage t={this.state.applyCouponError} style={styles.applyCouponError} />
            ) : null }
          </>
        )}
      </View>
    )
  }

  onCouponNameChange = (couponName) => {
    this.setState({ couponName, applyCouponError: undefined })
  }

  applyCoupon = async () => {
    if (Platform.OS !== 'web') {
      Keyboard.dismiss()
    }
    if (!this.state.couponName) {
      // this.setState({ applyCouponError: 'coupon.errors.empty'})
      return
    }

    const { cartItemsByUserId: cartItems } = this.props.cartItemsByUserIdHandler
    const totals = getTotalPrices(cartItems, (item) => item.product)

    try {
      await this.props.applyCoupon({
        variables: { name: this.state.couponName, checkoutAmount: totals.subTotalAfterDiscounts },
        update: (store, { data }) => {
          const { applyCoupon: coupon } = data
          updateCachedQueryValue(store, {
            query: checkoutSessionsSchema.queries.myCheckoutSession,
            nextValue: (myCheckoutSession) => {
              myCheckoutSession.couponId = coupon.id
              myCheckoutSession.coupon = coupon
              return myCheckoutSession
            },
          })
        }
      })
      this.setState({ couponName: ''})
    } catch (e) {
      console.log('<<<CHECKOUT - applyCoupon - error', e)
      const applyCouponError = _.get(e, 'graphQLErrors.0.message') || 'errors.common.network'
      this.setState({ applyCouponError })
    }
  }

  revertCoupon = async () => {
    const { myCheckoutSessionHandler: { myCheckoutSession } } = this.props
    await this.props.revertCoupon({
      variables: { checkoutSessionId: myCheckoutSession.id },
      update: (store, { data }) => {
        updateCachedQueryValue(store, {
          query: checkoutSessionsSchema.queries.myCheckoutSession,
          nextValue: (myCheckoutSession) => {
            myCheckoutSession.couponId = null
            myCheckoutSession.coupon = null
            return myCheckoutSession
          },
        })
      }
    })
    this.forceUpdate()
  }

  getCoupon() {
    return _.get(this.props.myCheckoutSessionHandler, 'myCheckoutSession.coupon')
  }

  getAddress(addressColumn) {
    return _.get(this.props.myCheckoutSessionHandler, `myCheckoutSession.${addressColumn}`)
  }

  renderStripe() {
    const {
      defaultStripePaymentMethod: paymentMethod,
    } = this.props.defaultStripePaymentMethodHandler

    if (this.props.defaultStripePaymentMethodHandler.loading) {
      return null
    }

    if (paymentMethod) {
      return (
        <PaymentMethod
          key={paymentMethod.id}
          id={paymentMethod.id}
          style={styles.paymentMethodContainer}
          brand={paymentMethod.card.brand}
          last4={paymentMethod.card.last4}
          expirationMonth={paymentMethod.card.exp_month}
          expirationYear={paymentMethod.card.exp_year}
        />
      )
    }
    return (
      <View style={styles.cardActionContainer}>
        <Button
          style={styles.button}
          label={i18n.t('payment.paymentMethods.addCreditCard')}
          labelStyle={styles.buttonLabel}
          onPress={this.addCardAction}
        />
      </View>
    )
  }
  /* #NF1 - Payment Method Refactoring - No need to display old types right now.*/
  renderLegacy() {
    return (
      <React.Fragment>
        {this.paymentTypes()}
        <Text style={[styles.subtext]}>
          ({i18n.t('order.checkout.paymentMethodSubtext')})
        </Text>
      </React.Fragment>
    )
  }

  _handleBillingSubmit = (values) => {
    this.setState({ billing: values, billingView: true, billingIsValid: true })
  }

  _handleBillingReset = () => {
    this.setState({ billing: {} })
  }

  _handleEditToggle = (mode) => {
    this.setState({ billingView: mode })
  }

  _handleBillingIsValid = (valid) => {
    this.setState({ billingIsValid: valid })
  }

  render() {
    const { showFooterButton } = this.state
    const { cartItemsByUserIdHandler, userByIdHandler, myCheckoutSessionHandler } = this.props
    const termsAndConditionsLink = i18n.t('onboardingShop.details.termsLink')
    if (cartItemsByUserIdHandler.loading || userByIdHandler.loading || myCheckoutSessionHandler.loading) {
      return null
    }
    const { userById: user } = userByIdHandler
    const coupon = this.getCoupon()
    const { cartItemsByUserId: cartItems } = cartItemsByUserIdHandler

    // console.log(producers)
    const sortedCartItems = _.orderBy(
      cartItems,
      [(e) => e.product.profile.id, 'createdAt'],
      ['asc', 'desc'],
    )
    const paymentDetailsTitle = i18n.t('order.checkout.paymentDetailsTitle')
    const orderTotalTitle = i18n.t('order.checkout.paymentTotal')

    const cartProductElements = this.cartProductElements(sortedCartItems)

    const shippingAddress = this.props.shipping ||
      this.getAddress('shippingAddress') ||
      getDefaultShippingAddress(user.addresses)
    
    const billingAddress = this.props.billing ||
      this.getAddress('billingAddress') ||
      getDefaultBillingAddress(user.addresses)

      console.log({ shippingAddress, billingAddress })

    const keyboardPaddingStyle = getKeyboardPaddingStyle(this.props.keyboardInfo)
    return (
      <View style={styles.root}>
        <StripePaymentModal
          shipping={this.state.shipping}
          billing={this.state.billingAddress}
          isVisible={this.state.stripePaymentModalVisible}
          closeAction={this.closeStripePaymentModal}
          onCheckoutStarted={this.onCheckoutStarted}
        />
        <StripeElementsCardModal
          isVisible={this.state.stripeElementsCardModalVisible}
          closeAction={this.closeStripeElementsCardModal}
        />
        <View style={styles.container}>
          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS === 'ios' ? 'padding' : null}
            enabled
            contentContainerStyle={{
              flex: 1,
            }}
            keyboardVerticalOffset={branch({
              iphone: 64,
              iphonex: 64,
              android: 81,
              other: 0,
            })}
          >
            <Scroller style={{ flex: 1 }} contentContainerStyle={styles.contentContainer} keyboardShouldPersistTaps="handled">
              <KeyboardShift fluid extraShiftValue={branch({ apk: sizes.tabBarHeight, other: 0})}>
                <Text style={[styles.title, sharedStyles.h4]}>
                  {i18n.t('order.checkout.title')}
                </Text>
                <AddressSelector type={'shipping'} addressData={shippingAddress} onPress={() => {
                  this.props.navigation.navigate('AddressesList', { select: true, selectType: 'shipping' })
                }}/>
                <AddressSelector type={'billing'} addressData={billingAddress} onPress={() => {
                  this.props.navigation.navigate('AddressesList', { select: true, selectType: 'billing' })
                }}/>
                <OrderTotals
                  style={styles.paymentDetailsContainer}
                  items={sortedCartItems}
                  coupon={coupon}
                  title={paymentDetailsTitle}
                  totalTitle={orderTotalTitle}
                  showSubtotal
                  showTitle
                  showCouponPercentage
                />
                {this.paymentMethod()}
                {this.renderApplyCoupon()}
                <View style={styles.orderBreakdownContainer}>
                  {cartProductElements}
                </View>
                <View style={keyboardPaddingStyle} />
              </KeyboardShift>
            </Scroller>
          </KeyboardAvoidingView>
        </View>
        {
          showFooterButton && (
            <View style={[styles.callToActionContainer, styles.footer]}>
              <View style={styles.callToActionTextContainer}>
                <Text style={styles.termsText}>
                  {i18n.t('order.checkout.terms')}
                </Text>
                <TouchableOpacity
                  key={1}
                  onPress={() => Linking.openURL(termsAndConditionsLink)}
                >
                  <Text
                    accessibilityRole='link'
                    target='_blank'
                    href={termsAndConditionsLink}
                    style={styles.termsLink}
                  >
                    {' '}
                    {i18n.t('order.checkout.termsLinkLabel')}
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.buttonContainer}>
                <Button
                  onPress={this.placeOrder}
                  disabled={this.state.billingIsValid === false}
                  label={i18n.t('order.checkout.buyNow')}
                  labelStyle={styles.buttonLabel}
                  style={styles.button}
                />
              </View>
            </View>
          )
        }
      </View>
    )
  }

  onAddressSave = (address) => {
    this.setState({ address })
  }

  changeCardAction = () => {
    this.goToChoosePaymentMethods()
  }

  addCardAction = () => {
    this.setState({ stripeElementsCardModalVisible: true })
  }

  closeStripeElementsCardModal = () => {
    this.props.defaultStripePaymentMethodHandler.refetch()
    this.setState({ stripeElementsCardModalVisible: false })
  }

  closeStripePaymentModal = () => {
    this.setState({ stripePaymentModalVisible: false }, () => {
      const { cartItemsByUserId: cartItems } = this.props.cartItemsByUserIdHandler
      const shopsProcessed = _.uniq(cartItems.map(item => _.get(item, 'product.profile.id')))
      this.goToOrderConfirmation(this.state.checkoutId, shopsProcessed)
    })
  }

  onCheckoutStarted = () => {
    // TODO: Introduce any flags etc... for while checkout processing is ongoing
  }

  goToChoosePaymentMethods = () => {
    NavigationActions.navigate({
      routeName: ROUTE_CHOOSE_PAYMENT_METHOD,
    }, true)
  }

  getStripeShippingFromAddress = (name, address) => {

    const phone = address.phone !== undefined ? { phone: address.phone } : {}
    const email = address.email !== undefined ? { email: address.email } : {}

    return {
      address: {
        line1: address[ADDRESS_FIELD_ADDRESS1],
        city: address[ADDRESS_FIELD_CITY],
        country: address[ADDRESS_FIELD_COUNTRY],
        line2: address[ADDRESS_FIELD_ADDRESS2],
        postal_code: address[ADDRESS_FIELD_ZIP],
        state: address[ADDRESS_FIELD_REGION],
        ...email, ...phone,
      },
      name,
    }
  }

  _checkBillingErrors = () => {
    const errors = this.state.billing.errors
    if (_.isEmpty(errors)) {
      return false
    }
    const errorFields = Object.keys(this.state.billing.errors)
      .map((key) => i18n.t(`onboardingUser.address.${key}Placeholder`)).join(', \n')
    alert({
      title: i18n.t('common.error'),
      message: i18n.t('errors.fillInFieldsRequest', { list: errorFields }),
    })
    return true
  }

  placeOrder = async () => {
    // if (this.state.billingForceValidate === undefined
    //   && this.state.billing.checkbox === false && !_.isEmpty(this.state.billing.errors)) {

    //   this.setState({ billingForceValidate: true }, this._checkBillingErrors)
    //   return
    // }

    // if (this._checkBillingErrors()) {
    //   return
    // }

    const { userId } = this.props
    const { cartItemsByUserIdHandler: { cartItemsByUserId: cartItems } } = this.props
    const { userById: user } = this.props.userByIdHandler
    // let address = getDefaultShippingAddress(user.addresses)
    const shippingAddress = this.props.shipping || 
      this.getAddress('shippingAddress') ||
      getDefaultShippingAddress(user.addresses)
    const billingAddress = this.props.billing || 
      this.getAddress('billingAddress') ||
      getDefaultBillingAddress(user.addresses)

    if (_.isEmpty(shippingAddress)) {
      alert({
        title: i18n.t('common.error'),
        message: i18n.t('errors.order.checkout.shippingAddressRequired'),
      })
      return
    }
    if (_.isEmpty(billingAddress)) {
      alert({
        title: i18n.t('common.error'),
        message: i18n.t('errors.order.checkout.billingAddressRequired'),
      })
      return
    }

    const { __typename, ...address } = shippingAddress
    try {
      await client.query({
        query: addressSchema.queries.isUserAddressRestricted,
        name: 'isUserAddressRetrictedHandler',
        variables: { address }
      })
    } catch (e) {
      await handleAddressError(e)
      return 
    }

    if (getFeatureFlag('stripe')) {
      // console.log('getFeatureFlag')
      // return
      const {
        defaultStripePaymentMethod: paymentMethod,
      } = this.props.defaultStripePaymentMethodHandler
      if (!paymentMethod) {
        alert({
          title: i18n.t('common.error'),
          message: i18n.t('errors.order.checkout.paymentMethodRequired'),
        })
        return
      }
      if (!this.allShopsSupportStripe) {
        alert({
          title: i18n.t('common.error'),
          message: i18n.t(
            'errors.order.checkout.allShopsSupportStripeRequired',
          ),
        })
        return
      }
      const shipping = this.getStripeShippingFromAddress(
        shippingAddress.recipient || user.displayName,
        shippingAddress,
      )
      const checkoutId = _.get(cartItems, '[0].checkoutId')
      this.setState({ stripePaymentModalVisible: true, shipping, billingAddress, checkoutId })
    } else {
      const variables = { userId, address: shippingAddress }
      try {
        const result = await this.props.createOrders({
          variables,
          update: this.updateOrdersInCache,
        })
        const orders = result.data.createOrders
        this.goToOrderConfirmation(orders[0].checkoutId)
      } catch (e) {
        console.log('Error:', e)
      }
    }

    this.props.analyticsBuyNow()
  }

  updateOrdersInCache = (store, { data }) => {
    const orders = data.createOrders

    // Add order to front of user history
    const nextValueForUser = (data = []) => {
      for (const order of orders) {
        data.unshift(order)
      }
      return data
    }
    updateCachedQueryValue(store, {
      query: orderSchema.queries.ordersByUserIdForUserType,
      variables: { userId: this.props.userId, userType: 'user' },
      nextValue: nextValueForUser,
      abortOnError: true,
    })

    // Clear cart items out
    updateCachedQueryValue(store, {
      query: schema.queries.cartItemsByUserId,
      variables: { userId: this.props.userId },
      nextValue: () => [],
    })

    // Add order to front of shop history if an order was placed to their own shop by the owner
    const shopId = _.get(
      this.props,
      'currUserShop.currentUser.ownedProfile.profile.id',
      '',
    )
    const shopOrder = orders.find((order) => (order.shopId = shopId))
    if (shopOrder) {
      const nextValueForShop = (data = []) => {
        data.unshift(shopOrder)
        return data
      }
      // Add order to front of producer history
      updateCachedQueryValue(store, {
        query: orderSchema.queries.ordersByUserIdForUserType,
        variables: { userId: shopId, userType: 'producer' },
        nextValue: nextValueForShop,
        abortOnError: true,
      })
    }
  }

  goToOrderConfirmation = (checkoutId, shopsProcessed = []) => {
    NavigationActions.navigate({
      routeName: 'OrderConfirmation',
      params: { checkoutId, shopsProcessed },
    }, true)
  }
}

Checkout.propTypes = {
  userId: PropTypes.string,
  navigation: PropTypes.any,
  shipping: PropTypes.any,
  billing: PropTypes.any,
  screenInfo: PropTypes.any,
  defaultStripePaymentMethodHandler: PropTypes.any,
  createOrders: PropTypes.func,
  applyCoupon: PropTypes.func,
  revertCoupon: PropTypes.func,
  analyticsBuyNow: PropTypes.func,
  dispatch: PropTypes.func,
  cartItemsByUserIdHandler: PropTypes.func,
  userByIdHandler: PropTypes.func,
  myCheckoutSessionHandler: PropTypes.func,
}

Checkout.navigationOptions = (props) => {
  return mergeNavOptionsForFeedLogo({
    headerLeft: () => (
      <MobileBackButton
        showLabel
        labelOnly
        color='red'
        label={i18n.t('common.cancel')}
        onPress={() => NavigationActions.back()}
      />
    ),
  })
}

const styles = stylus({
  root: {
    flex: 1,
  },
  container: {
    paddingHorizontal: 10,
    flex: 1,
  },
  footer: {
    paddingBottom: 0,
    android: {
      paddingBottom: 0,
    },
    iphonex: {
      paddingBottom: 35,
    },
  },
  title: {
    color: colors.text.main,
    textAlign: 'center',
    marginTop: 5,
    fontWeight: '600',
  },
  contentContainer: {
    paddingRight: 5,
    paddingBottom: 0,
    android: {
      paddingBottom: 0,
    },
    iphonex: {
      paddingBottom: 0,
    },
  },
  paymentDetailsContainer: {
    marginTop: 20,
  },
  paymentMethodContainer: {
    flex: 1,
  },
  paymentMethodTitleContainer: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  paymentMethodTitle: {
    fontWeight: '600',
    color: colors.text.main,
  },
  paymentMethodText: {
    fontSize: 14,
    color: colors.text.main,
  },
  subtext: {
    fontSize: 12,
    color: colors.text.main,
  },
  applyCouponContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  applyCouponInputContainer: {
    flex: 3,
  },
  applyCouponAction: {
    color: colors.primary,
    fontSize: 17,
    fontWeight: '600',
    height: 24,
    flex: 1,
    alignSelf: 'flex-end',
    textAlign: 'center',
    marginBottom: 10,
  },
  applyCouponActionDisabled: {
    color: colors.text.secondary,
  },
  applyCouponError: {
    marginTop: 0,
  },
  orderBreakdownContainer: {
    marginTop: 20,
    flex: 1,
    flexDirection: 'column',
  },
  cartProduct: {
    marginTop: 20,
  },
  shopName: {
    fontWeight: '600',
    fontSize: 13,
    color: colors.text.main,
    marginTop: 5,
  },
  orderCountTitle: {
    fontWeight: '600',
    color: colors.text.main,
  },
  sectionHeader: {
    flex: 1,
    flexDirection: 'column',
    marginTop: 20,
  },
  shopTotalsContainer: {
    marginTop: 10,
  },
  // Call To Action Section Styling
  callToActionContainer: {
    paddingTop: 10,
    backgroundColor: '#F7F7F7',
    alignItems: 'center',
  },
  callToActionTextContainer: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  termsText: {
    color: colors.text.main,
    fontSize: 10,
    paddingVertical: 5,
  },
  termsLink: {
    color: colors.text.link,
    fontWeight: '500',
    fontSize: 10,
    paddingVertical: 5,
  },
  buttonContainer: {
    width: '100%',
  },
  button: {
    backgroundColor: colors.primary,
    width: '90%',
    marginBottom: 5,
    alignSelf: 'center'
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  cardAction: {
    backgroundColor: colors.primary,
    padding: 5,
    marginTop: 3,
  },
  cardActionContainer: {
    alignItems: 'center',
    marginTop: 10,
  },
  changeCardAction: {
    fontSize: 13,
    fontWeight: 'normal',
    color: colors.text.main,
  },
  stripeDescription: {
    marginBottom: 0,
    marginTop: 15,
    paddingHorizontal: 0
  }
})

export default Checkout
