import React from 'react'
import { TouchableOpacity, View, Image, Picker } from 'react-native'
import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import T from '../../components/T'
import ElegantInput from '../../components/simple/ElegantInput'

const YourInfo = ({ screenInfo, onChange, getMessage, state }) => {
  const size = screenInfo.width / 2.5
  const { firstName, lastName, email } = state
  return (
    <View style={styles.container}>
      <T t='onboardingUser.yourInfo' style={styles.title} />
      <ElegantInput
        style={styles.input}
        label={getMessage('common.firstName')}
        returnKeyType='next'
        onChange={(value) => onChange(value, 'firstName')}
        value={firstName}
        autoCorrect={false}
        autoFocus={true}
      />
      <ElegantInput
        style={styles.input}
        label={getMessage('common.lastName')}
        returnKeyType='next'
        onChange={(value) => onChange(value, 'lastName')}
        value={lastName}
        autoCorrect={false}
      />
      <ElegantInput
        style={styles.input}
        label={getMessage('common.email')}
        keyboardType='email-address'
        autoCapitalize='none'
        returnKeyType='next'
        onChange={(value) => onChange(value, 'email')}
        value={email}
        autoCorrect={false}
      />
    </View>
  )
}

export default YourInfo

const styles = stylus({
  container: {
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.text.main,
  },
  subtext: {
    marginTop: 30,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '300',
    color: colors.text.main,
  },
  input: {
    width: '100%',
    marginVertical: 10,
  },
})
