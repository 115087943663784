import React from 'react'
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Platform,
} from 'react-native'

import { LinearGradient } from 'expo-linear-gradient'

import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import Gradient from './Gradient'

class StrongButton extends React.Component {
  render() {
    const { label, style, labelStyle, onPress, invert, disabled } = this.props

    return (
      <TouchableOpacity
        style={[styles.container, style, disabled && styles.disabled]}
        onPress={disabled ? null : onPress}
        activeOpacity={0.8}
      >
        <Gradient
          first={
            invert
              ? colors.gradientButton.invert.firstBackground
              : colors.gradientButton.firstBackground
          }
          second={
            invert
              ? colors.gradientButton.invert.secondBackground
              : colors.gradientButton.secondBackground
          }
          style={styles.gradient}
        >
          <Text
            style={[
              styles.label,
              labelStyle,
              invert && styles.invert,
              disabled && styles.labelDisabled,
            ]}
          >
            {label}
          </Text>
        </Gradient>
      </TouchableOpacity>
    )
  }
}

export default StrongButton

const styles = stylus({
  container: {
    alignSelf: 'stretch',
    borderRadius: 4,
    overflow: 'hidden',
  },
  gradient: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
  },
  label: {
    color: colors.gradientButton.text,
    backgroundColor: 'transparent',
    fontSize: 17,
  },
  invert: {
    // color: '#000',
    color: colors.gradientButton.invert.text,
  },
  disabled: {
    // opacity: 0.4,
    web: {
      cursor: 'not-allowed',
    },
  },
  labelDisabled: {
    color: 'rgba(255,255,255,0.4)',
  },
})
