import i18n from 'i18n-js'
import PropTypes from 'prop-types'
import React, { Component } from 'react'; //eslint-disable-next-line
import { Text, View } from 'react-native'; // This is not a mistake, LayoutAnimation seems to be required for layouts to work?
import ImageCarousel from '../../../../components/ImageCarousel'
import Button from '../../../../components/simple/Button'
import Divider from '../../../../components/simple/Divider'
import ElegantInput from '../../../../components/simple/ElegantInput'
import UploadButton from '../../../../components/simple/UploadButton'
import T from '../../../../components/T'
import colors from '../../../../config/colors'
import { connect } from '../../../../config/connected'
import { stylus } from '../../../../config/styles'

@connect(({ screenInfo }) => ({ screenInfo }))
class UploadAwards extends Component {
  static propTypes = {
    itemAwards: PropTypes.array,
    screenInfo: PropTypes.object,
    addAward: PropTypes.func,
    removeAward: PropTypes.func,
    uploads: PropTypes.object,
    goToNext: PropTypes.func,
    animate: PropTypes.func,
  }

  // imageCarouselRef = React.createRef()
  currentElementIndex = null

  removeAward = () => {
    const { itemAwards } = this.props
    // const imagesData = this.imageCarouselRef.current.getWrappedInstance().getIndex()
    // const { index } = imagesData
    const currentElement = itemAwards[this.currentElementIndex] // itemAwards[index]
    if (!currentElement) {
      return
    }
    const id = currentElement.hasOwnProperty('name') ? currentElement.name : currentElement.id 
    this.props.removeAward(id)
  }

  imagePlaceholder = () => {
    const { screenInfo, addAward } = this.props
    const width = screenInfo.contentWidth
    const height = (width * 9) / 16
    return (
      <UploadButton onChange={addAward}>
        <View style={[styles.imagePlaceholder, { width, height }]}>
          <Text style={styles.placeholderImageText}>Add an award...</Text>
        </View>
      </UploadButton>
    )
  }

  render() {
    const {
      itemAwards,
      addAward,
      goToNext,
      handleFieldChange,
      awardDetails,
      animate,
    } = this.props
    return (
      <View style={styles.container} onLayout={animate}>
        <ImageCarousel
          images={itemAwards}
          showProgress
          autoScroll
          // onRef={this.imageCarouselRef}
          onIndexChange={(index) => {this.currentElementIndex = index}}
          placeholder={this.imagePlaceholder()}
          playMuted={true}
          playOnce={true}
          navigation={this.props.navigation}
        />
        <View style={styles.padding}>
          <T style={styles.titles.header} t='shop.add.titles.addAward' />
          <T style={styles.titles.sub} t='shop.add.titles.addAwardHint' />
          <UploadButton onChange={addAward}>
            <Button
              disabled
              disabledStyle={[styles.button.primary, styles.button.margin]}
              label={i18n.t('shop.add.buttons.addAward')}
            />
          </UploadButton>
          {itemAwards.length ? (
            <Button
              style={[styles.button.margin]}
              label={i18n.t('shop.add.buttons.removeAward')}
              onPress={this.removeAward}
            />
          ) : null}
          <Divider hidden />
          <ElegantInput
            label={i18n.t('shop.add.fields.awardDetails')}
            value={awardDetails}
            multiline
            onChange={(value) => {
              handleFieldChange(value, 'awardDetails')
            }}
          />
          <Divider hidden />
          <Button
            style={[styles.button.primary]}
            label={i18n.t('common.next')}
            onPress={() => {
              goToNext()
            }}
          />
          <Divider hidden />
        </View>
      </View>
    )
  }
}

export default UploadAwards

const styles = stylus({
  container: {
    width: '100%',
    // backgroundColor: 'green',
  },
  titles: {
    header: {
      textAlign: 'center',
      fontSize: 24,
      fontWeight: 'bold',
      marginTop: 20,
    },
    sub: {
      fontSize: 16,
      textAlign: 'center',
      // fontWeight: 'bold',
    },
  },
  button: {
    margin: {
      marginTop: 20,
    },
    primary: {
      backgroundColor: colors.primary,
    },
  },
  padding: {
    paddingHorizontal: 20,
  },
  imagePlaceholder: {
    backgroundColor: colors.emptyArea,
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeholderImageText: {
    padding: 30,
    fontSize: 16,
    color: colors.text.light,
    fontWeight: '300',
  },
})
