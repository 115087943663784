import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'

import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import PropTypes from 'prop-types'
import Icons from '@expo/vector-icons/Ionicons'

const ListItem = ({
  left,
  title,
  style,
  description,
  forwardArrow,
  leftStyle,
  last,
  first,
  titleStyle,
  onPress,
  soft,
  right,
}) => {

  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <View style={[styles.left, leftStyle]}>{left}</View>
      <View
        style={[
          styles.content,
          soft && styles.soft,
          last && styles.last,
          first && styles.first,
        ]}
      >
        <View style={styles.contentText}>
          <Text style={[styles.title, titleStyle]}>{title}</Text>
          {description && (
            <Text style={styles.description}>{description}</Text>
          )}
        </View>
        {forwardArrow && (
          <Icons
            name={'ios-arrow-forward'}
            size={22}
            color={colors.listItem.chevron}
            style={styles.forward}
          />
        )}
        {right && (
          <View>{right}</View>
        )}
      </View>

    </TouchableOpacity>
  )
}

ListItem.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
  title: PropTypes.string,
  style: PropTypes.object,
  description: PropTypes.string,
  forwardArrow: PropTypes.bool,
  leftStyle: PropTypes.any,
  last: PropTypes.bool,
  first: PropTypes.bool,
  titleStyle: PropTypes.object,
  onPress: PropTypes.func,
  soft: PropTypes.bool,
}

export default React.memo(ListItem)

const styles = stylus({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
  },
  last: {
    borderBottomWidth: 0,
  },
  first: {
    borderTopWidth: 0,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 12,
    paddingVertical: 14,
    borderTopWidth: sizes.px,
    borderTopColor: colors.thinLine,
    // borderTopWidth: 0.5,
    // borderTopColor: colors.thinLine,
  },
  soft: {
    borderTopColor: colors.thinLineSoft,
  },
  contentText: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  title: {
    fontSize: 16,
    color: colors.text.main,
  },
  description: {
    fontSize: 15,
    color: colors.text.secondary,
    fontWeight: '200',
  },
  forward: {
    paddingHorizontal: 15,
    landscape: {
      paddingHorizontal: 22,
    },
  },
  left: {
    alignItems: 'center',
    justifyContent: 'center',
  }
})
