import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Text, TouchableOpacity, View } from 'react-native'

import Modal from '../../simple/Modal'
import StripeElementsCard from './StripeElementsCard'
import Icons from '@expo/vector-icons/Ionicons'

import { stylus } from '../../../config/styles'
import sizes from '../../../config/sizes'
import colors from '../../../config/colors'
import i18n from 'i18n-js'
import { ANALYTICS_CREDIT_CARD_INSERTION } from '../../../reducers/analytics'
import { connect } from '../../../config/connected'

class StripeElementsCardModal extends Component {

  render() {
    const {
      style,
      isVisible,
      paymentMethodId,
    } = this.props
    return (
      <Modal
        animationType='slide'
        visible={isVisible}
        onRequestClose={this.close}
      >
        <View style={[styles.modalContainer, style]}>
          <View style={[styles.modalHeader]}>
            <TouchableOpacity style={styles.iconContainer} onPress={this.close}>
              <Icons
                style={styles.closeIcon}
                name='ios-close'
                size={50}
              />
            </TouchableOpacity>
            <View style={styles.modalTitleContainer}>
              <Text style={styles.modalTitle}>{i18n.t('payment.paymentMethods.enterCardDetails')}</Text>
            </View>
          </View>
          <StripeElementsCard paymentMethodId={paymentMethodId} onSuccess={this.onSuccess} />
        </View>
      </Modal>
    )
  }

  close = () => {
    this.props.closeAction()
  }

  onSuccess = () => {
    this.props.analyticsCreditCardInsertion()
    this.close()
  }
}

StripeElementsCardModal.propTypes = {
  style: PropTypes.object,
  isVisible: PropTypes.bool,
  paymentMethodId: PropTypes.string,
  closeAction: PropTypes.func.isRequired,
  analyticsCreditCardInsertion: PropTypes.func,
}

const styles = stylus({
  modalContainer: {
    flex: 1,
    web: {
      paddingTop: 15,
    },
    ios: {
      paddingTop: sizes.iosStatusBarHeight,
    },
    iphonex: {
      paddingTop: sizes.TOP_BAR_HEIGHT,
    },
  },
  iconContainer: {
    width: 50,
    height: 50,
    paddingLeft: 14,
    justifyContent: 'center'
  },
  closeIcon: {
    color: colors.text.main,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  modalTitleContainer: {
    flex: 1,
    alignItems: 'center',
    paddingRight: 50
  },
  modalTitle: {
    fontSize: 17,
    fontWeight: '700',
    color: colors.text.main,
  },
  stripeInfoContainer: {
    paddingHorizontal: 10,
    marginBottom: 15
  },
  stripeInfoText: {
    fontSize: 14,
    textAlign: 'center',
  },
  bold: {
    fontWeight: '600',
  },
  link: {
    color: colors.text.link,
  }
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  analyticsCreditCardInsertion: () => {
    dispatch({
      type: ANALYTICS_CREDIT_CARD_INSERTION,
      data: {},
    })
  },
})
export default connect(null, mapDispatchToProps)(StripeElementsCardModal)
