// NOTE: ActivityIndicator on Android will not show unless you turn back on these animation settings in Develop Options
// https://stackoverflow.com/a/57050760/1267911

import React from 'react'
import PropTypes from 'prop-types'
import { View, ActivityIndicator } from 'react-native'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'

const ActivityIndicatorOverlay = ({
  animating = false,
  color = colors.primary,
  children,
}) => {
  return (
    <View style={{ flex: 1}}>
      {children}
      {animating ? (
        <View style={styles.foreground}>
          <ActivityIndicator style={styles.indicator} size='large' color={color} />
        </View>
      ) : null }
    </View>
  )
}

ActivityIndicatorOverlay.propTypes = {
  animating: PropTypes.bool,
  color: PropTypes.string,
}

export default ActivityIndicatorOverlay

const styles = stylus({
  foreground: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  indicator: {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 20,
  },
})
