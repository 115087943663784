import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import TouchableBouncer from './TouchableBouncer'
import Icons from '@expo/vector-icons/Ionicons'

import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'

export default ({ disabled, onPress, onPressDisabled, style }) => {
  return (
    <TouchableBouncer
      style={[styles.button, disabled && styles.disabled, style]}
      onPress={disabled ? onPressDisabled : onPress}
    >
      <Icons name='md-arrow-round-up' size={22} color='white' />
    </TouchableBouncer>
  )
}

const styles = stylus({
  button: {
    width: 30,
    height: 30,
    borderRadius: 15,
    ios: {
      width: 31.5,
      height: 31.5,
      borderRadius: 15.75,
      paddingLeft: 0.5,
      paddingTop: 1,
    },
    backgroundColor: colors.sendButton.enabled,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
  },
  disabled: {
    backgroundColor: colors.sendButton.disabled,
  },
})
