import React from 'react'
import { StyleSheet, Image, View, TouchableOpacity } from 'react-native'
import profilePlaceholder from '../../assets/images/placeholder-profile.png'

import colors from '../../config/colors'
import nameToUrl from '../../config/nameToUrl'
import _ from 'lodash'

const GAP = 1

class GroupProfileImage extends React.Component {
  render() {
    const { members, cuid, style, size = 52, onPress } = this.props

    const container = {
      width: size,
      height: size,
      borderRadius: size / 2,
    }
    const borderRadius = size / 2

    const others = _.filter(members, (other) => other.id !== cuid)
    const first = others[0]
    const second = others[1]
    let third = null
    if (others.length > 2) {
      third = others[2]
    } else {
      third = _.find(members, { id: cuid })
    }

    return (
      <TouchableOpacity
        activeOpacity={0.9}
        style={[styles.container, container, style]}
        onPress={this.props.onPress}
      >
        {this.renderFirst({ user: first, size })}
        {this.renderSecond({ user: second, size })}
        {this.renderThird({ user: third, size })}
      </TouchableOpacity>
    )
  }
  renderFirst({ user, size }) {
    const style = {
      left: 0,
      top: 0,
      height: size,
      width: size / 2 - GAP,
      // borderTopLeftRadius: size / 2,
      // borderBottomLeftRadius: size / 2,
    }
    const profileImage = _.get(user, 'profileImage')
    let source = {
      uri: nameToUrl({
        name: profileImage,
        width: style.width,
        height: style.height,
        fit: 'crop',
        crop: 'faces',
      }),
    }
    if (!profileImage) {
      source = profilePlaceholder
    }
    return (
      <Image
        style={[styles.image, style]}
        source={source}
        resizeMode='stretch'
      />
    )
  }
  renderSecond({ user, size }) {
    const style = {
      right: 0,
      top: 0,
      height: size / 2 - GAP,
      width: size / 2 - GAP,
      // borderTopRightRadius: size / 2,
    }
    const profileImage = _.get(user, 'profileImage')
    let source = {
      uri: nameToUrl({
        name: profileImage,
        width: style.width,
        height: style.height,
      }),
    }
    if (!profileImage) {
      source = profilePlaceholder
    }
    return (
      <Image
        style={[styles.image, style]}
        source={source}
        resizeMode='stretch'
      />
    )
  }
  renderThird({ user, size }) {
    const style = {
      right: 0,
      bottom: 0,
      height: size / 2 - GAP,
      width: size / 2 - GAP,
      // borderBottomRightRadius: size / 2,
    }
    const profileImage = _.get(user, 'profileImage')
    let source = {
      uri: nameToUrl({
        name: profileImage,
        width: style.width,
        height: style.height,
      }),
    }
    if (!profileImage) {
      source = profilePlaceholder
    }
    return (
      <Image
        style={[styles.image, style]}
        source={source}
        resizeMode='stretch'
      />
    )
  }
}

export default GroupProfileImage

const styles = StyleSheet.create({
  container: {
    width: 52,
    height: 52,
    position: 'relative',
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
  },
})
