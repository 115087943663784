import { View, Text, Platform } from 'react-native'
import React, { Component } from 'react'
import LoadingMessage from '../components/LoadingMessage'

import loadCurrentUser from '../config/hydration/loadCurrentUser'
import loadFonts from '../config/hydration/loadFonts'
import { connect } from 'react-redux'

export class Hydrate extends Component {
  componentDidMount() {
    // load everything in parallel
    const { loaded, dispatch } = this.props
    loadCurrentUser(dispatch)
    if (Platform.OS !== 'web') {
      loadFonts(loaded.bind(this, 'fonts'))
    } else {
      loaded('fonts')
    }
  }
  render() {
    const {
      hydrated: {
        fonts,
        currentUser,
        // more loaders go here
      },
    } = this.props
    if (fonts && currentUser) {
      return this.props.children
    }
    return <LoadingMessage />
  }
}

const mapStateToProps = (state) => ({
  hydrated: state.hydrated,
})

const mapDispatchToProps = (dispatch) => ({
  // onResize: () => dispatch({ type: 'SCREEN_RESIZE' }),
  loaded: (item) => dispatch({ type: 'HYDRATE', item }),
  dispatch,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Hydrate)
