import { gql } from '../config/connected'
import { ProductBaseFieldsFragment } from './fragments'

export default {
  queries: {
    productsByIdList: gql`
      query($list: [String!]!) {
        productsByIdList(list: $list) {
          id
          name
          uploads {
            id
            name
          }
          price
          priceDiscount
          rating
          uploads {
            name
            mime
            width
            height
          }
          ratingCount
          profile {
            id
            displayName
            status
          }
          unitType
          unitQuantity
        }
      }
    `,
    productsByUserId: gql`
      query($userId: String!, $limit: Int, $offset: Int, $onlyAvailable: Boolean) {
        productsByUserId(userId: $userId, limit: $limit, offset: $offset, onlyAvailable: $onlyAvailable) {
            ...ProductBaseFieldsFragment
        }
      }
      ${ProductBaseFieldsFragment}
    `,
    productsByName: gql`
      query($productName: String!) {
        productsByName(productName: $productName) {
          ...ProductBaseFieldsFragment
          profile {
            id
            displayName
            status
          }
        }
      }
      ${ProductBaseFieldsFragment}
    `,
    productById: gql`
      query($id: String!) {
        productById(id: $id) {
            ...ProductBaseFieldsFragment
            profile {
              id
              displayName
              coverPhoto
              profileImage
              status
              address {
                region
                city
              }
              producerDetails {
                id
                policies {
                  shipping
                  returns
                }
                minimumOrderAmount
                stripeConnected
                deliveryLocations
              }
            }
          }
        }
      ${ProductBaseFieldsFragment}
    `,
  },
  mutations: {
    createProdcut: gql`
      mutation(
        $userId: String!
        $name: String!
        $uploads: [FileUploadInput!]
        $categories: CATEGORIES_INPUT
        $stock: Float
        $quantityUnits: String
        $price: Float!
        $priceDiscount: Float
        $isDiscontinued: Boolean
        $isInStock: Boolean
        $description: Json
        $ingredients: Json
        $awards: AwardInput
        $deliveryLocationsOnly: Boolean
        $unitType: UnitTypeEnum
        $unitQuantity: Float 
      ) {
        createProduct(
          userId: $userId
          name: $name
          uploads: $uploads
          categories: $categories
          stock: $stock
          quantityUnits: $quantityUnits
          isDiscontinued: $isDiscontinued
          isInStock: $isInStock
          price: $price
          priceDiscount: $priceDiscount
          description: $description
          ingredients: $ingredients
          awards: $awards
          deliveryLocationsOnly: $deliveryLocationsOnly
          unitType: $unitType
          unitQuantity: $unitQuantity
        ) {
          id
          userId
          name
        }
      }
    `,
    updateProduct: gql`
      mutation(
        $id: String!
        $uploads: [FileUploadInput]
        $name: String
        $categories: CATEGORIES_INPUT
        $stock: Float
        $quantityUnits: String
        $price: Float
        $priceDiscount: Float
        $isDiscontinued: Boolean
        $isInStock: Boolean
        $description: Json
        $ingredients: Json
        $awards: AwardInput
        $deliveryLocationsOnly: Boolean
        $unitType: UnitTypeEnum
        $unitQuantity: Float 
      ) {
        updateProduct(
          id: $id
          uploads: $uploads
          name: $name
          categories: $categories
          stock: $stock
          quantityUnits: $quantityUnits
          price: $price
          priceDiscount: $priceDiscount
          isDiscontinued: $isDiscontinued
          isInStock: $isInStock
          description: $description
          ingredients: $ingredients
          awards: $awards
          deliveryLocationsOnly: $deliveryLocationsOnly
          unitType: $unitType
          unitQuantity: $unitQuantity
        ) {
            ...ProductBaseFieldsFragment
        }
      }
      ${ProductBaseFieldsFragment}
    `,
    reworkProducts: gql`
      mutation($products: [InputReworkProduct!]!) {
        reworkProducts(products: $products)
      }
    `,
  },
}
