import {
  connect,
  graphql,
  withApollo,
  compose,
  gql,
} from '../../../config/connected'

const user = `
  id
  phone
  displayName
  username
  email
  profileImage
`

const address = `
  address1
  address2
  city
  region
  zip
  state
  country
`

export default {
  createShopUser: gql`
      mutation(
          $displayName: String!
          $role: String!
          $email: String
      ) {
          createShopUser(
              displayName: $displayName
              role: $role
              email: $email
          )
      }
  `,
  updateProfileAddress: gql`
    mutation(
      $id: String!
      $address1: String
      $city: String
      $region: String
      $zip: String
      $state: String
      $country: String
      $geo: InputGeo
    ) {
      updateProfileAddress(
        id: $id
        address1: $address1
        city: $city
        region: $region
        state: $state
        zip: $zip
        country: $country,
        geo: $geo
      ){
        ${address}
      }
    }
  `,
}
