import SettingsLandscape from '../../screens/Settings/SettingsLandscape'
import SettingsPortrait from '../../screens/Settings/SettingsPortrait'
import About from '../../screens/About'
import Support from '../../screens/Support'
import AddressesList from '../../screens/AddresesList/AddressesList'
import EditUserAddress from '../../screens/EditUserAddress/EditUserAddress'
import PickAddress from '../../screens/PickAddress/PickAddress'
import AdminDashboard from '../../admin/Dashboard'
import AdminFilesystemExplorer from '../../admin/Dashboard/FilesystemExplorer'
import AdminDashboardProductRework from '../../admin/Dashboard/DashboardProductRework'
import AdminAliases from '../../admin/Aliases'
import AdminUser from '../../admin/User'
import AdminCoupons from '../../admin/Coupons'
import AdminNotices from '../../admin/Notices'
import AdminCouponsCreate from '../../admin/Coupons/CreateCoupon'
import AdminCouponsUpload from '../../admin/Coupons/CouponUpload'
import ObjectSearch from '../../screens/ObjectSearch'
import ShopReviewFeedback from '../../screens/Admin/ShopReviewFeedback'
import Testing from '../../screens/Test/Test'
import PushNotificationsSettings from '../../screens/PushNotificationsSettings'
import Logout from '../../screens/Logout'

import { getLayout } from '../selectLayout'
const layout = getLayout()

const routes = {
  About: {
    screen: About,
    path: 'about',
  },
  Support: {
    screen: Support,
    path: 'support',
  },
  AddressesList: {
    screen: AddressesList,
    path: 'addresses-list',
  },
  EditUserAddress: {
    screen: EditUserAddress,
    path: 'edit-address',
  },
  PickAddress: {
    screen: PickAddress,
    path: 'pick-address',
    navigationOptions: { ...AddressesList.navigationOptions },
  },
  AdminDashboard: {
    screen: AdminDashboard,
    path: 'admin/dashboard',
  },
  AdminFilesystemExplorer: {
    screen: AdminFilesystemExplorer,
    path: 'admin/filesystem-explorer'
  },
  AdminManageAliases: {
    screen: AdminAliases,
    path: 'admin/aliases/:entityTable/:entityId',
  },
  AdminUsers: {
    screen: ObjectSearch,
    path: 'admin/users',
  },
  AdminUser: {
    screen: AdminUser,
    path: 'admin/user/:id',
  },
  AdminCoupons: {
    screen: AdminCoupons,
    path: 'admin/coupons-list',
  },
  AdminCouponsCreate: {
    screen: AdminCouponsCreate,
    path: 'admin/coupons-create',
  },
  AdminCouponsUpload: {
    screen: AdminCouponsUpload,
    path: 'admin/coupons-upload',
  },
  AdminDashboardProductRework: {
    screen: AdminDashboardProductRework,
    path: 'admin/dashboard/product-rework',
  },
  AdminNotices: {
    screen: AdminNotices,
    path: 'admin/notices-list'
  },
  ShopReviewFeedback: {
    screen: ShopReviewFeedback,
    path: 'ShopReviewFeedback/:id',
  },
  Testing: {
    screen: Testing,
    path: 'testing',
  },
  PushNotifications: {
    screen: PushNotificationsSettings,
    path: 'push-notifications',
  },
  Logout: {
    screen: Logout,
    path: 'logout'
  },
}

if (layout.indexOf('landscape') !== -1) {
  routes.Settings = {
    screen: SettingsLandscape,
    path: '',
    title: 'Settings',
  }
} else {
  routes.Settings =  {
    screen: SettingsPortrait,
    path: '',
    title: 'Settings',
  }
}

export const settingsRoutes = routes
