import React from 'react'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'

import { connect } from 'react-redux'
import colors from '../../config/colors'

class Statistic extends React.Component {
  render() {
    const { statistic, label, unit, style, uppercase, onPress } = this.props
    return (
      <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
        <Text style={styles.statistic}>
          {statistic}
          {unit && <Text style={styles.unit}>{unit}</Text>}
        </Text>
        {label && (
          <Text style={[styles.label, uppercase && styles.uppercase]}>
            {label}
          </Text>
        )}
      </TouchableOpacity>
    )
  }
  unit() {
    const { unit } = this.props
    i
  }
}

export default Statistic

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    // borderWidth: 0.5,
    // borderColor: colors.thinLine,
    // borderRadius: 4,
    alignItems: 'center',
  },
  statistic: {
    fontSize: 20,
    fontWeight: '500',
    color: colors.text.main,
    paddingTop: 2,
  },
  label: {
    fontSize: 11,
    color: colors.text.secondary,
    fontWeight: '200',
    marginTop: -2,
    paddingBottom: 5,
  },
  unit: {
    fontSize: 12,
    marginLeft: 1,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
})
