import { StatusBar, Platform, View, Text, Linking } from 'react-native'
import React from 'react'
import Constants from 'expo-constants'
import i18n from 'i18n-js'
import _ from 'lodash'

import { compose, graphql } from '../config/connected'
import configSchema from '../schema/config'
import { stylus } from '../config/styles'
import colors from '../config/colors'
import Modal from '../components/simple/Modal'
import Button from '../components/simple/Button'

const isWeb = Platform.OS === 'web'
const appVersion = !isWeb ? Constants.nativeAppVersion : null
const appVersionBuild = !isWeb ? Constants.platform[Platform.OS].versionCode || Constants.platform[Platform.OS].buildNumber : null
const { updateLink } = !isWeb ? Constants.manifest.extra : {}

export class CheckAppVersion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      currentVersion: !isWeb ? `${appVersion}/${appVersionBuild}` : null,
      latestVersion: null,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { appVersionCheck } = _.get(nextProps, 'appVersionCheck')

    if (isWeb) return // don't check if web

    const [ appVersion = '', appBuild = 0 ] = (appVersionCheck || '0.0.0/0').split('/')
    const [ curVersion = '', curBuild = 0 ] = this.state.currentVersion.split('/')
    const [appMajor = 0, appMinor = 0, appPatch = 0 ] = appVersion.split('.')
    const [curMajor = 0, curMinor = 0, curPatch = 0 ] = curVersion.split('.')
    console.log('<<<CHECK APP VERSION - LATEST', appMajor, appMinor, appPatch, appBuild)
    console.log('<<<CHECK APP VERSION - CURRENT', curMajor, curMinor, curPatch, curBuild)

    const versionPartsToCheck = [[+curMajor, +appMajor], [+curMinor, +appMinor], [+curPatch, +appPatch], [+curBuild, +appBuild]]
    let isLesser = false
    let isGreater = false

    for (const pair of versionPartsToCheck) {
      if (pair[0] > pair[1]) {
        isGreater = true
        break
      }

      if (pair[0] < pair[1]) {
        isLesser = true
        break
      }
    }
    const upToDate = isGreater || !isLesser
    
    if (appVersionCheck !== this.state.latestVersion) {
      this.setState({ latestVersion: appVersionCheck })
    }
    if (!upToDate) {
      this.setState({ showModal: true })
    }
  }

  onAndroidBackButton = () => {
    // TODO: Do nothing since we don't want the Modal to close
  }

  render() {
    if (isWeb) {
      return null
    }
    return (
      <Modal animationType='slide' visible={this.state.showModal} onRequestClose={this.onAndroidBackButton}>
        { Platform.OS === 'android' ?
          <StatusBar backgroundColor="rgba(0,0,0,0.5)"/>
          : null
        }
        <View style={styles.contentContainer}>
          <Text style={styles.message}>{i18n.t('settings.applicationUpdate')}</Text>
          <Button
            label={i18n.t('common.update')}
            style={styles.button}
            labelStyle={styles.buttonLabel}
            onPress={() => Linking.openURL(updateLink[Platform.OS])}
          />
        </View>
      </Modal>
    )
  }
}

const styles = stylus({
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 15,
  },
  message: {
    fontSize: 16,
    color: colors.text.main,
    textAlign: 'center',
    marginTop: 15,
  },
  button: {
    backgroundColor: colors.primary,
    marginTop: 15,
  },
  buttonLabel: {
    fontSize: 16,
  },
})

const Composed = compose(
  graphql(configSchema.queries.appVersionCheck, {
    name: 'appVersionCheck',
    options() {
      return { variables: { platform: Platform.OS } }
    },
  }),
)(CheckAppVersion)

export default Composed
