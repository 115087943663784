import React from 'react'
import { compose, graphql, connect } from '../../config/connected'
import schema from '../../schema/post'
import { ANALYTICS_PUBLISH_POST_WITH_PRODUCTS_TAGGED } from '../../reducers/analytics'
import { PROFILE_REFRESH_PAGE } from '../../reducers/profile'
import { updateCachedQueryValue } from '../../utility/apollo'

import _ from 'lodash'
import produce from 'immer'

const debug = false
const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  analyticsPublishPostWithProductsTagged: (data) => {
    dispatch({
      type: ANALYTICS_PUBLISH_POST_WITH_PRODUCTS_TAGGED,
      data: data,
    })
  },
  dispatch,
})

function withPublishPost(Composed) {
  return (compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(schema.mutations.deleteUpload, { name: 'deleteImage' }),
    graphql(schema.mutations.createPost, {
      name: 'createPost',
    }),
    graphql(schema.mutations.createRecipe, {
      name: 'createRecipe',
    }),
    graphql(schema.mutations.editPost, {
      name: 'editPost',
    }),
    graphql(schema.mutations.editRecipe, {
      name: 'editRecipe',
    }),
  )(
    class WithPublishPost extends React.Component {
      getPublishMethod(id, isRecipe) {
        let method = isRecipe ? 'createRecipe' : 'createPost'
        if (id) {
          method = isRecipe ? 'editRecipe' : 'editPost'
        }
        return method
      }

      updateNewsfeed = (store, { data }, id, isRecipe) => {
        const method = this.getPublishMethod(id, isRecipe)
        debug && console.log('<<< WITH PUBLISH POST - updateNewsfeed - method', method)
        const post = data[method]
        debug && console.log('<<< WITH PUBLISH POST - updateNewsfeed - post', post)
        updateCachedQueryValue(store, {
          query: schema.queries.newsfeed,
          key: 'newsfeed',
          nextValue: (newsfeed = []) => {
            return produce(newsfeed, draft => {
              if (id) {
                const postIndex = draft.findIndex((post) => post.id === id)
                console.log('<<< WITH PUBLISH POST - found index', postIndex)
                draft[postIndex] = post
              } else {
                draft.unshift({
                  ...post,
                })
              }
            })            
          },
        })
      }

      publishPost = async(postData) => {
        const { dispatch } = this.props
        const { payload, contentType, imagesToDelete } = postData
        const isRecipe = contentType === 'recipe'
        const { id, taggedProducts } = payload
        const method = this.getPublishMethod(id, isRecipe)
        await this.props[method]({
          variables: payload,
          update: (store, response) => this.updateNewsfeed(store, response, id, isRecipe)
        })

        if (_.isEmpty(id) && !_.isEmpty(taggedProducts)) {
          this.props.analyticsPublishPostWithProductsTagged({ isRecipe, taggedProducts })
        }

        dispatch({
          type: PROFILE_REFRESH_PAGE,
          page: isRecipe ? 'recipes' : 'posts',
        })

        Promise.all(imagesToDelete.filter(u => _.has(u, ['name', 'mime'])).map((data) => {
          return _.invoke(this.props, 'deleteImage', {
            variables: {
              postId: id,
              ...data,
            },
          })
        }))
      }

      render() {
        return <Composed
          {...this.props}
          publishPost={this.publishPost}
        />
      }
    }
  ))
}

export default withPublishPost
