import axios from 'axios'
import { Platform } from 'react-native'
import { AUTOCOMPLETE_URL, REVRSE_GEO_CODE_URL, PLACE_DETAIL_URL, WEB_PROXY_URL } from '../config/constants'

export const fetchAddressForLocation = async ({ lat, lng, apiKey }, cancelToken) => {
  if (Platform.OS === 'web') {
    return {
      data: await fetch(`${REVRSE_GEO_CODE_URL}?key=${apiKey}&latlng=${lat},${lng}`).then(res => res.json())
    }
  }

  return await axios.get(`${REVRSE_GEO_CODE_URL}?key=${apiKey}&latlng=${lat},${lng}`, {
    cancelToken,
  })
}

export const fetchAddressByPlaceId = async ({ apiKey, placeId }) => {
  if (Platform.OS === 'web') {
    return {
      data: await fetch(`${WEB_PROXY_URL}${PLACE_DETAIL_URL}?key=${apiKey}&placeid=${placeId}`).then(res => res.json())
    }
  }

  return axios.get(`${PLACE_DETAIL_URL}?key=${apiKey}&placeid=${placeId}`)
}

export const fetchPlacesAutocomplete = async ({ input, key, language, components }, cancelToken) => {
  if (Platform.OS === 'web') {
    let url = new URL(`${WEB_PROXY_URL}${AUTOCOMPLETE_URL}`),
      params = { input, key, language, components }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    return {
      data: await fetch(url).then(res => res.json())
    }
  }

  return await axios
    .get(AUTOCOMPLETE_URL, {
      cancelToken,
      params: {
        input,
        key,
        language,
        components,
      }
    })
}
