import React from 'react'
import { StyleSheet, View } from 'react-native'

import { connect } from 'react-redux'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import Action from '../../components/simple/Action'
import { graphql } from 'react-apollo'
import { compose } from 'recompose'
import gql from 'graphql-tag'
import _ from 'lodash'

import NavigationActions from '../../utility/navigationActions'

import { extractTags } from '../../utility/hashtags'

const SavePost = ({ dispatch, mutate, posting, currentUser }) => (
  <Action
    label='Post'
    onPress={async () => {
      dispatch({ type: 'Posting/IN_PROGRESS' })
      // try {
      const uploadsIds = _.compact(_.map(posting.uploads, 'uploadId'))
      const data = await mutate({
        variables: {
          text: posting.text,
          tags: extractTags(posting.text),
          userId: currentUser.id,
          location: { summary: 'Salt Lake City, UT' },
          uploadsIds,
        },
        refetchQueries: [
          {
            query: gql`
              query {
                allPosts(orderBy: createdAt_DESC, first: 18) {
                  id
                  text
                  location
                  createdAt
                  uploads {
                    name
                    width
                    height
                    contentType
                  }
                  user {
                    id
                    name
                    profileImage {
                      name
                    }
                  }
                }
              }
            `,
          },
        ],
      })
      console.log('got data', data)
      dispatch({ type: 'Posting/RESET' })
      NavigationActions.back()
      // } catch (error) {
      //   console.log('there was an error sending the query', error)
      // }
    }}
    style={{ paddingRight: 12 }}
  />
)

const mapStateToProps = (state) => ({
  posting: state.posting,
  currentUser: state.currentUser,
})
const mapDispatchToProps = (dispatch) => ({})

const submitPost = gql`
  mutation(
    $text: String!
    $userId: ID!
    $location: Json!
    $uploadsIds: [ID!]
    $tags: [String!]
  ) {
    createPost(
      text: $text
      userId: $userId
      location: $location
      uploadsIds: $uploadsIds
      tags: $tags
    ) {
      id
      text
      location
      createdAt
      uploads {
        name
      }
      user {
        name
        profileImage {
          name
        }
      }
    }
  }
`

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(submitPost),
)(SavePost)
