
import React from 'react'
import { View } from 'react-native'
import NavigationActions from '../../utility/navigationActions'

import MobileBackButton from '../../components/simple/MobileBackButton'
import ErrorMessage from '../../components/simple/ErrorMessage'
import StripeConnectButton from '../../components/Stripe/StripeConnectButton'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'

import _ from 'lodash'
import i18n from 'i18n-js'

import {
  connect,
  graphql,
} from '../../config/connected'
import userSchema from '../../schema/user'


const mapStateToProps = (state) => ({
  userId: _.get(state, 'currentUser.id'),
})


@connect(mapStateToProps)
@graphql(userSchema.queries.user, {
  name: 'user',
  skip: (props) => !props.userId,
  options: (props) => ({
    variables: {
      id: props.userId,
    },
  }),
})
@graphql(userSchema.queries.getShop, {
  name: 'getShop',
  skip: (props) => props.user.loading || !_.get(props, 'user.user.ownedProfile.profileId'),
  options: (props) => ({
    variables: { id: _.get(props, 'user.user.ownedProfile.profileId') }
  }),
})
class StripeConnect extends React.Component {
  render() {

    if (!this.readyToRender()) {
      return null
    }

    const { navigation } = this.props
    const error = _.get(navigation, 'state.params.error')
    const errorDescription = _.get(navigation, 'state.params.errorDescription')
    let success = _.get(navigation, 'state.params.success')
    let successDescription = _.get(navigation, 'state.params.successDescription')

    let Status = null
    // NOTE: This is used for now since resetting the nav stack or going back then forward don't seem to work well
    if (this.stripeConnected() && !error && !errorDescription && !success && !successDescription) {
      success = 'oauthSuccess'
      successDescription = 'oauthSuccessDescription'
    }

    if (error || errorDescription) {
      const errorMessageProps = { tType: `errors.stripe.${error}` }
      if (!errorDescription) {
        errorMessageProps.t = `errors.stripe.${error}_description`
      } else {
        errorMessageProps.message = errorDescription
      }
      Status = <ErrorMessage style={styles.message} { ...errorMessageProps } />
    } else if (success || successDescription) {
      const SuccessMessage = ErrorMessage
      Status = <SuccessMessage style={styles.message} tType={`payment.stripeConnect.${success}`} typeStyle={styles.green} t={`payment.stripeConnect.${successDescription}`} labelStyle={styles.green}/>
    }

    return (
      <View style={styles.container}>
        <StripeConnectButton stripeConnected={this.stripeConnected()} />
        { Status }
      </View>
    )
  }

  readyToRender() {
    const { user, getShop, userId} = this.props
    const readyToRender = userId && !user.loading && !getShop.loading && _.get(getShop, 'userById.producerDetails')
    return readyToRender
  }

  stripeConnected() {
    return _.get(this.props, 'getShop.userById.producerDetails.stripeConnected')
  }
}

StripeConnect.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('payment.stripeConnect.title'),
    headerTitleStyle: { alignSelf: 'center', textAlign: 'center' },
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
    headerRight: () => (<View />)

  }
}

const styles = stylus({
  container: {
    flex: 1,
    flexDirection: 'column', 
    justifyContent: 'center',
    marginHorizontal: 15
  },
  button: {
    flexDirection: 'row',
  },
  unconnectedButton: {
    backgroundColor: colors.actions.stripe,
  },
  connectedButton: {
    backgroundColor: colors.green
  },
  buttonLabel: {
    fontSize: 16
  },
  divider: {
    fontSize: 20,
    color: colors.text.main,
    marginHorizontal: 7,
  },
  message: {
    alignItems: 'center'
  },
  green: {
    color: colors.green,
  }
})

export default StripeConnect
