import React, { useCallback } from 'react'
import { KeyboardAvoidingView, Platform, View } from 'react-native'
import PropTypes from 'prop-types'
import shared, { stylus } from '../../config/styles'
import colors from '../../config/colors'
import Scroller from '../../components/Scroller'
import MobileBackButton from '../../components/simple/MobileBackButton'
import KeyboardShift from '../../containers/KeyboardShift'
import branch from '../../config/branch'
import sizes from '../../config/sizes'
import { getKeyboardPaddingStyle } from '../../config/helpers'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'
import { compose, hoistStatics } from 'recompose'
import AddressForm from '../../components/Address/AddressForm'
import _ from 'lodash'
import i18n from 'i18n-js'
import { graphql } from 'react-apollo'
import addressSchema from '../../schema/address'
import userSchema from '../../schema/user'
import update from 'immutability-helper'
import NavigationActions from '../../utility/navigationActions'
import { handleAddressError } from '../../config/helpers'

const EditUserAddress = (props) => {

  const address = props.navigation.getParam('address') || {}

  const keyboardPaddingStyle = getKeyboardPaddingStyle(props.keyboardInfo)

  const onSubmit = useCallback(_.once((values) => {
    if (!_.chain(values).get('id').isEmpty().value()) {
      props.updateAddress({
        variables: {
          id: values.id,
          input: update(values, { $unset: ['id'] }),
          restrictToLocale: !values.isBilling,
        },
        update: (cache, { data: { updateUserAddress } }) => {
          const oldAddresses = _.chain(props)
            .get('profile.currentUser.addresses', [])
            .map(i => ({ ...i, isDefault: false }))
            .value()
          const index = _.findIndex(props.profile.currentUser.addresses, { id: values.id })
          const newAddresses = update(oldAddresses, {
            [index]: { $set: updateUserAddress },
          })
          const updatedProfile = update(props.profile, {
            currentUser: {
              addresses: { $set: newAddresses },
            },
          })
          cache.writeQuery({
            query: userSchema.queries.currentUserFullProfile,
            data: updatedProfile,
          })
        },
      }).then(() => {
        NavigationActions.goTo({ routeName: 'AddressesList' })
      }).catch(async (e) => {
        await handleAddressError(e)
      })
    } else {
      props.addAddress({
        variables: {
          input: update(values, { $unset: ['id'] }),
          restrictToLocale: true,
        },
        update: (cache, { data: { addUserAddress } }) => {
          const oldAddresses = _.chain(props)
            .get('profile.currentUser.addresses', [])
            .map(i => ({ ...i, isDefault: false }))
            .value()
          const updatedProfile = update(props.profile, {
            currentUser: {
              addresses: { $set: [...oldAddresses, addUserAddress] },
            },
          })
          cache.writeQuery({
            query: userSchema.queries.currentUserFullProfile,
            data: updatedProfile,
          })
        },
      }).then(() => {
        NavigationActions.goTo({ routeName: 'AddressesList' })
      }).catch(async (e) => {
        await handleAddressError(e)
      })
    }
  }), [props.profile.currentUser])

  return <KeyboardAvoidingView
    style={styles.outerContainer}
    behavior={Platform.OS === 'android' ? undefined : 'padding'}
    keyboardVerticalOffset={60}
  >
    <Scroller keyboardShouldPersistTaps='always' keyboardDismissMode='none' showsVerticalScrollIndicator={false}>
      <View style={[styles.container]}>
        <AddressForm address={address} onSubmit={onSubmit} user={props?.profile?.currentUser} />
      </View>
    </Scroller>
  </KeyboardAvoidingView>

}

EditUserAddress.propTypes = {
  updateAddress: PropTypes.func,
  addAddress: PropTypes.func,
  keyboardInfo: PropTypes.any,
  navigation: PropTypes.any,
  profile: PropTypes.shape({
    currentUser: PropTypes.shape({
      addresses: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any,
        firstName: PropTypes.any,
        lastName: PropTypes.any,
        recipient: PropTypes.any,
        address1: PropTypes.any,
        address2: PropTypes.any,
        city: PropTypes.any,
        region: PropTypes.any,
        zip: PropTypes.any,
        country: PropTypes.any,
        phone: PropTypes.any,
        email: PropTypes.any,
        createdAt: PropTypes.any,
        updatedAt: PropTypes.any,
        isDefault: PropTypes.any,
      })),
    }),
  }),
  address: PropTypes.shape({
    id: PropTypes.any,
    firstName: PropTypes.any,
    lastName: PropTypes.any,
    recipient: PropTypes.any,
    address1: PropTypes.any,
    address2: PropTypes.any,
    city: PropTypes.any,
    region: PropTypes.any,
    zip: PropTypes.any,
    country: PropTypes.any,
    phone: PropTypes.any,
    email: PropTypes.any,
    createdAt: PropTypes.any,
    updatedAt: PropTypes.any,
    isDefault: PropTypes.any,
  }),
}

const styles = stylus({
  outerContainer: {
    flex: 1,
    paddingHorizontal: 10,
    native: {
      width: '100%',
      height: '100%',
    },
    web: {
      position: 'relative',
    },
  },
  container: {
    flex: 1,
    alignSelf: 'stretch',
    landscape: {
      ...shared.card,
      marginTop: 31,
    },
  },
  input: {
    width: '100%',
    // marginVertical: 10,
  },

  headerContainer: {
    marginVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },

  title: {
    fontWeight: 'bold',
    color: colors.text.main,
  },

  shippingAddressContainer: {
    marginTop: 15,
  },

  button: {
    backgroundColor: colors.primary,
    marginBottom: 5,
    marginTop: 20,
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  countryContainer: {
    alignSelf: 'stretch',
    marginBottom: 10,
  },
  pickerLabel: {
    marginTop: 20,
    color: colors.input.base,
    fontSize: 12,
    web: {
      marginBottom: 6,
    },
  },
  picker: {
    web: {
      width: '100%',
      color: colors.input.text,
    },
  },
})

EditUserAddress.navigationOptions = ({navigation}) => {
  const addressParam = _.get(navigation, 'state.params.address')
  return {
    headerTitle: addressParam ? i18n.t('order.cart.editAddress') : i18n.t('order.cart.addAddress'),
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.goTo({ routeName: 'AddressesList' })}
      />
    )
  }
}

const enhanced = compose(
  WithKeyboardInfo,
  graphql(addressSchema.mutations.updateUserAddress, {
    name: 'updateAddress',
  }),
  graphql(addressSchema.mutations.addUserAddress, {
    name: 'addAddress',
  }),
  graphql(userSchema.queries.currentUserFullProfile, {
    name: 'profile',
  }),
)
export default hoistStatics(enhanced)(EditUserAddress)
