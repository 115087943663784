import React from 'react'
import { Text, View, TouchableOpacity } from 'react-native'

import ProfileImage from '../../components/Post/ProfileImage'
import GroupProfileImage from '../../components/simple/GroupProfileImage'
import shared, { stylus } from '../../config/styles'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import Ago from '../../components/simple/Ago'
import helpers from '../Conversation/helpers'
import _ from 'lodash'
import UserTitle from '../UserTitle/UserTitle'

class ConversationItem extends React.Component {
  render() {
    const { membership, cuid } = this.props
    const { conversation } = membership
    // console.log({conversation})
    const { onPress, selected, landscape } = this.props
    const unread = !selected && membership.unreadCount > 0
    const online = false
    const members = _.map(conversation.memberships, 'user')
    const name = helpers.conversationName({ members, cuid, limit: 6 })
    const profileImage = helpers.profileImage({ members, cuid })
    const isOneToOne = members.length === 2
    const otherMember = members.filter((member) => _.get(member, 'id') !== cuid)[0]
    const titleProps = {
      title: otherMember.title,
      organization: otherMember.organization,
      organizationId: otherMember.organizationId,
    }
    return (
      <TouchableOpacity
        style={[
          styles.container,
          unread && styles.containerUnread,
          selected && styles.selected,
        ]}
        onPress={onPress}
      >
        { isOneToOne ? (
          <ProfileImage
            name={profileImage}
            online={false}
            size={landscape ? 42 : 52}
          />
        ) : (
          <GroupProfileImage
            members={members}
            cuid={cuid}
            size={landscape ? 42 : 52}
          />
        )}
        <View style={styles.content}>
          <View style={styles.contentTop}>
            <Text
              style={[styles.name, unread && styles.nameUnread]}
              numberOfLines={1}
            >
              {name}
            </Text>
            <View style={styles.when} numberOfLines={1}>
              <Ago
                time={_.get(membership, 'lastTouch')}
                style={[styles.whenText, unread && styles.whenTextUnread]}
              />
            </View>
          </View>
          { isOneToOne ? <UserTitle {...titleProps} linkToShop={false} size={13} /> : null }
          <Text
            style={[styles.latest, unread && styles.latestUnread]}
            numberOfLines={1}
          >
            {helpers.lastMessageSummary({
              members,
              message: conversation.lastMessage,
              cuid,
            })}
          </Text>
        </View>
      </TouchableOpacity>
    )
  }
}

export default ConversationItem

const styles = stylus({
  container: {
    flexDirection: 'row',
    paddingLeft: 13,
    alignItems: 'center',
    landscape: {
      paddingLeft: 18,
    },
  },
  selected: {
    borderLeftColor: colors.primary,
    borderLeftWidth: 4,
    paddingLeft: 10,
    landscape: {
      paddingLeft: 14,
    },
  },
  containerUnread: {
    backgroundColor: 'rgba(0,130,255,0.03)',
  },
  content: {
    marginLeft: 14,
    flex: 1,
    borderTopColor: 'rgba(0,0,0,0.1)',
    paddingVertical: 16,
    borderTopWidth: sizes.px,
    paddingRight: 14,
    landscape: {
      marginLeft: 18,
    },
  },
  contentTop: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    fontSize: 15,
    flex: 1,
    color: colors.text.mainLighter,
    fontWeight: '400',
  },
  nameUnread: {
    fontWeight: 'bold',
    color: colors.text.main,
  },
  latest: {
    fontSize: 14,
    color: colors.text.secondary,
    fontWeight: '300',
    lineHeight: 20,
    paddingRight: 10,
  },
  latestUnread: {
    fontWeight: '500',
    color: colors.text.main,
  },
  when: {
    paddingTop: 0,
  },
  whenText: {
    fontSize: 15,
    color: colors.text.secondary,
    fontWeight: '200',
  },
  whenTextUnread: {
    fontWeight: '600',
    color: colors.text.main,
  },
})
