import React, { Component } from 'react'
import { View, Platform, Image } from 'react-native'

import { stylus } from '../config/styles'
import { connect } from 'react-redux'
import colors from '../config/colors'
const bg = require('../assets/images/background-mobile.png')

class BackgroundImage extends Component {
  render() {
    if (colors.fullScreenBackground) {
      const {
        screenInfo: { width, height },
      } = this.props
      return (
        <Image
          source={bg}
          style={[styles.backgroundImage, { width, height }]}
          resizeMode='cover'
        />
      )
    }
    return null
  }
}

const styles = stylus({
  backgroundImage: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
})

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

export default connect(mapStateToProps)(BackgroundImage)
