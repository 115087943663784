import React from 'react'
import { Linking } from 'react-native'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

import { getStripeConnectOAuthUrl } from '../../config/helpers'
import Button from '../../components/simple/Button'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'

const StripeConnectButton = ({ stripeConnected }) => {
  const stripeConnect = async () => {
    const url = await getStripeConnectOAuthUrl()
    Linking.openURL(url)
  }
  return (
    <Button
      disabled={stripeConnected}
      disabledStyle={[styles.button, styles.connectedButton]}
      style={[styles.button, styles.unconnectedButton]}
      labelStyle={styles.buttonLabel}
      label={i18n.t(stripeConnected ? 'payment.stripeConnect.actionCompleted' : 'payment.stripeConnect.action' )}
      childrenAfterLabel={false}
      onPress={stripeConnect}
    />
  )
}

StripeConnectButton.propTypes = {
  stripeConnected: PropTypes.bool
}

const styles = stylus({
  container: {
    flex: 1,
    flexDirection: 'column', 
    justifyContent: 'center',
    marginHorizontal: 15
  },
  button: {
    flexDirection: 'row',
  },
  unconnectedButton: {
    backgroundColor: colors.actions.stripe,
  },
  connectedButton: {
    backgroundColor: colors.green
  },
  buttonLabel: {
    fontSize: 16
  },
  divider: {
    fontSize: 20,
    color: colors.text.main,
    marginHorizontal: 7,
  },
  message: {
    alignItems: 'center'
  },
  green: {
    color: colors.green,
  }
})


export default StripeConnectButton
