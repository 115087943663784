import { gql } from '../config/connected'
import { PostFragment, PostLast3LikersFragmnet } from './fragments'

const post = `
  id
  userId
  user {
    id
    username
    profileImage
    title
    organization
    organizationId
    iFollow
    justFollowed
    followersCount
  }
  type
  story
  likeCount
  commentCount
  topComments {
    id
    content
    iLike
    createdAt
    likesCount
    user {
      id
      username
      profileImage
      title
      organization
      organizationId
    }
  }
  uploads {
    name
    width
    height
    mime
  }
  taggedProducts {
    id
    name
    rating
    ratingCount
    price
    priceDiscount
    uploads {
      name
    }
    profile{
      id
      displayName
      status
    }
  }
  iLike
  iSave
  type
  recipe {
    id
    title
    difficulty
    prepTime
    cookTime
    cost
    servings
    ingredients
    instructions
    recipeLink
  }
  mentions{
    type
    productId
    userId
  }
  tags{
    type
    productId
    userId
  }
  products {
    id
    name
    rating
    ratingCount
    price
    priceDiscount
    uploads {
      name
    }
    profile{
      id
      displayName
      status
    }
  }
  createdAt
`

const recipeSaveParams = `
  $uploads: [FileUploadInput]!,
  $story: Json!,
  $taggedProducts: [String]!,
  $title: String!,
  $ingredients: Json!,
  $instructions: Json!,
  $difficulty: Int!,
  $prepTime: Int!,
  $cookTime: Int!,
  $cost: Int!,
  $servings: Int!,
  $recipeLink: String
`

const recipeSaveAttributes = `
  taggedProducts: $taggedProducts,
  uploads: $uploads,
  story: $story,
  title: $title,
  ingredients: $ingredients,
  instructions: $instructions,
  difficulty: $difficulty,
  prepTime: $prepTime,
  cookTime: $cookTime,
  cost: $cost,
  servings: $servings,
  recipeLink: $recipeLink
`

const last3Likers = `
  last3Likers{
    id,
    profileImage,
    username
  }
`

export default {
  queries: {
    newsfeed: gql`
      query($last: FeedCursor, $limit: Int) {
        newsfeed(last: $last, limit: $limit) @connection(key: "newsfeed") {
            ...PostFragment
            ...PostLast3LikersFragmnet
        }
      }
      ${PostFragment}
      ${PostLast3LikersFragmnet}
    `,
    postsByUser: gql`
      query($userId: String, $last: Date, $limit: Int, $offset: Int) {
        postsByUser(userId: $userId, last: $last, limit: $limit, offset: $offset) {
            ...PostFragment
            ...PostLast3LikersFragmnet
        }
      }
      ${PostFragment}
      ${PostLast3LikersFragmnet}
    `,
    recipesByUser: gql`
      query($userId: String, $last: Date, $limit: Int, $offset: Int) {
        recipesByUser(userId: $userId, last: $last, limit: $limit, offset: $offset) {
            ...PostFragment
        }
      }
      ${PostFragment}
    `,
    post: gql`
      query($id: String!) {
        post(id: $id) {
            ...PostFragment
        }
      }
      ${PostFragment}
    `,
    productPosts: gql`
    query($idList: [String!]!, $limit: Int, $offset: Int){
      productPosts(idList: $idList, limit: $limit, offset: $offset){
          ...PostFragment
      }
    }
    ${PostFragment}
    `,
    productRecipes: gql`
    query($idList: [String!]!, $limit: Int, $offset: Int){
      productRecipes(idList: $idList, limit: $limit, offset: $offset){
          ...PostFragment
      }
    }
    ${PostFragment}
    `,
    postsByMentionType: gql`
    query($id: String!, $type: MENTION_TYPE!, $limit: Int, $offset: Int){
      postsByMentionType(id: $id, type: $type, limit: $limit, offset: $offset){
          ...PostFragment
      }
    }
    ${PostFragment}
    `,
  },
  mutations: {
    createPost: gql`
      mutation(
        $uploads: [FileUploadInput]!,
        $story: Json,
        $taggedProducts: [String]!
      ) {
        createPost(
          uploads: $uploads,
          story: $story,
          taggedProducts: $taggedProducts,
        ) {
          ...PostFragment
          ...PostLast3LikersFragmnet
        }
      }
      ${PostFragment}
      ${PostLast3LikersFragmnet}
    `,
    editPost: gql`
      mutation(
        $id: String!,
        $uploads: [FileUploadInput]!,
        $story: Json,
        $taggedProducts: [String]!
      ) {
        editPost(
          id: $id,
          uploads: $uploads,
          story: $story,
          taggedProducts: $taggedProducts,
        ) {
            ...PostFragment
            ...PostLast3LikersFragmnet
        }
      }
      ${PostFragment}
      ${PostLast3LikersFragmnet}
    `,
    createRecipe: gql`
      mutation(
          $uploads: [FileUploadInput]!,
          $taggedProducts: [String]!,
          $story: Json!,
          $title: String!,
          $ingredients: Json!,
          $instructions: Json!,
          $difficulty: Int!,
          $prepTime: Int!,
          $cookTime: Int!,
          $cost: Int!,
          $servings: Int!,
          $recipeLink: String
          $info: Json
      ) {
        createRecipe(
            uploads: $uploads,
            taggedProducts: $taggedProducts,
            story: $story,
            title: $title,
            ingredients: $ingredients,
            instructions: $instructions,
            difficulty: $difficulty,
            prepTime: $prepTime,
            cookTime: $cookTime,
            cost: $cost,
            servings: $servings,
            recipeLink: $recipeLink
            info: $info
        ) {
            ...PostFragment
            ...PostLast3LikersFragmnet
        }
      }
      ${PostFragment}
      ${PostLast3LikersFragmnet}
    `,
    editRecipe: gql`
      mutation(
          $id: String!,
          $uploads: [FileUploadInput]!,
          $taggedProducts: [String]!,
          $story: Json!,
          $title: String!,
          $ingredients: Json!,
          $instructions: Json!,
          $difficulty: Int!,
          $prepTime: Int!,
          $cookTime: Int!,
          $cost: Int!,
          $servings: Int!,
          $recipeLink: String
          $info: Json
      ) {
        editRecipe(
            id: $id,
            uploads: $uploads,
            taggedProducts: $taggedProducts,
            story: $story,
            title: $title,
            ingredients: $ingredients,
            instructions: $instructions,
            difficulty: $difficulty,
            prepTime: $prepTime,
            cookTime: $cookTime,
            cost: $cost,
            servings: $servings,
            recipeLink: $recipeLink
            info: $info
        ) {
            ...PostFragment
            ...PostLast3LikersFragmnet
        }
      }
      ${PostFragment}
      ${PostLast3LikersFragmnet}
    `,
    likePost: gql`
      mutation($postId: String!) {
        likePost(postId: $postId) {
            ...PostFragment
        }
      }
      ${PostFragment}
    `,
    unlikePost: gql`
      mutation($postId: String!) {
        unlikePost(postId: $postId) {
            ...PostFragment
        }
      }
      ${PostFragment}
    `,
    savePost: gql`
      mutation($postId: String!) {
        savePost(postId: $postId) {
            ...PostFragment
        }
      }
      ${PostFragment}
    `,
    unsavePost: gql`
      mutation($postId: String!) {
        unsavePost(postId: $postId) {
            ...PostFragment
        }
      }
      ${PostFragment}
    `,
    deletePost: gql`
      mutation($postId: String!) {
        deletePost(postId: $postId) {
            ...PostFragment
        }
      }
      ${PostFragment}
    `,
    deleteRecipe: gql`
      mutation($postId: String!) {
        deleteRecipe(postId: $postId) {
            ...PostFragment
        }
      }
      ${PostFragment}
    `,
    deleteUpload: gql`
      mutation($postId: String!, $name: String!, $mime: String!) {
        deletePostUpload(
          postId: $postId
          uploadInput: { name: $name, mime: $mime }
        ) {
          id
        }
      }
    `,
  },
  fields: {
    post,
  },
  subscriptions: {
    postAdded: gql`
      subscription {
        postAdded {
          id
        }
      }
    `,
  },
}
