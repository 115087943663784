import categories from '../../../schema/categories'
import environment from '../../../config/environment'
import { ObjectSearchManager } from './ObjectSearchManager'
import _ from 'lodash'
import i18n from 'i18n-js'

export const SEARCH_MANAGER_PRODUCTS = 'ProductSearchManager'

export const LOAD_PRODUCERS_PRODUCTS = 'load_producers_products'
export const LOAD_PRODUCER_PRODUCTS = 'laod_producer_products'
export const LOAD_SEARCH_PRODUCTS = 'load_search_products'

export const PRODUCT_FILTER_TAGS = 'tags'
export const PRODUCT_FILTER_TAGS_FREE_FROM = 'tagsFreeFrom'
export const PRODUCT_FILTER_TAGS_DIET = 'tagsDiet'
export const PRODUCT_FILTER_QUERY = 'query'
export const PRODUCT_FILTER_PRODUCER_ID = 'producerId'
export const PRODUCT_FILTER_PRODUCER_IDS = 'producerIds'
const PRODUCT_FILTER_IS_DISCONTINUED = 'isDiscontinued'

export const PRODUCT_SORT_PRICE_LOW = 'priceLow'
export const PRODUCT_SORT_PRICE_HIGH = 'priceHigh'
export const PRODUCT_SORT_RATING_HIGH = 'ratingHigh'
export const PRODUCT_SORT_DISTANCE_LOW = 'distanceLow'
export const PRODUCT_SORT_CREATEDAT_DESC = 'createdAtDesc'

export class ProductSearchManager extends ObjectSearchManager {
  constructor(hostRef, options) {
    super(hostRef, options)
    this.initialize()
  }
  initialize() {
    this.searchType = LOAD_SEARCH_PRODUCTS
    this.searchManagerType = SEARCH_MANAGER_PRODUCTS
    this.filterDefinitions = {
      [PRODUCT_FILTER_QUERY]: {
        param: 'query',
        label: i18n.t('search.placeholders.products'),
      },
      [PRODUCT_FILTER_TAGS]: {
        param: 'tagFilters',
        label: i18n.t('search.filters.general.categories'),
        keyLabelPairs: { ...categories().producer },
      },
      [PRODUCT_FILTER_TAGS_FREE_FROM]: {
        param: 'tagFilters',
        label: i18n.t('search.filters.general.freeFrom'),
        keyLabelPairs: { ...categories().freeFrom },
      },
      [PRODUCT_FILTER_TAGS_DIET]: {
        param: 'tagFilters',
        label: i18n.t('search.filters.general.diet'),
        keyLabelPairs: { ...categories().diet },
      },
      [PRODUCT_FILTER_PRODUCER_ID]: { param: 'filters.shopId' },
      [PRODUCT_FILTER_PRODUCER_IDS]: { param: 'filters.shopId' },
      [PRODUCT_FILTER_IS_DISCONTINUED]: { param: 'filters.isDiscontinued' },
    }
    this.searchTypeFilters = {
      [LOAD_PRODUCERS_PRODUCTS]: [
        PRODUCT_FILTER_QUERY,
        PRODUCT_FILTER_TAGS,
        PRODUCT_FILTER_PRODUCER_IDS,
        PRODUCT_FILTER_TAGS_FREE_FROM,
        PRODUCT_FILTER_TAGS_DIET,
      ],
      [LOAD_PRODUCER_PRODUCTS]: [
        PRODUCT_FILTER_QUERY,
        PRODUCT_FILTER_TAGS,
        PRODUCT_FILTER_PRODUCER_ID,
        PRODUCT_FILTER_TAGS_FREE_FROM,
        PRODUCT_FILTER_TAGS_DIET,
      ],
      [LOAD_SEARCH_PRODUCTS]: [
        PRODUCT_FILTER_QUERY,
        PRODUCT_FILTER_TAGS,
        PRODUCT_FILTER_TAGS_FREE_FROM,
        PRODUCT_FILTER_TAGS_DIET],
    }
    this.nonClearableFilters = [
      PRODUCT_FILTER_PRODUCER_IDS,
      PRODUCT_FILTER_PRODUCER_ID,
    ]
    this.alwaysAppliedFilters = {
      isDiscontinued: 0,
    }
    this.sortDefinitions = {
      [PRODUCT_SORT_PRICE_LOW]: {
        label: i18n.t('search.filters.products.priceLowHigh'),
        direction: 'asc',
        replica: 'price',
      },
      [PRODUCT_SORT_PRICE_HIGH]: {
        label: i18n.t('search.filters.products.priceHighLow'),
        direction: 'desc',
        replica: 'price',
      },
      [PRODUCT_SORT_RATING_HIGH]: {
        label: i18n.t('search.filters.general.averageRating'),
        direction: 'desc',
        replica: 'rating',
      },
      [PRODUCT_SORT_DISTANCE_LOW]: {
        label: i18n.t('search.filters.general.distance'),
        direction: 'asc',
        replica: false,
      },
      [PRODUCT_SORT_CREATEDAT_DESC]: {
        label: 'Latest',
        direction: 'desc',
        replica: 'created_at',
      },
    }
    this.searchTypeSorts = {
      [LOAD_PRODUCERS_PRODUCTS]: [
        PRODUCT_SORT_DISTANCE_LOW,
        PRODUCT_SORT_PRICE_HIGH,
        PRODUCT_SORT_PRICE_LOW,
        PRODUCT_SORT_RATING_HIGH,
      ],
      [LOAD_PRODUCER_PRODUCTS]: [],
      [LOAD_SEARCH_PRODUCTS]: [
        PRODUCT_SORT_DISTANCE_LOW,
        PRODUCT_SORT_PRICE_HIGH,
        PRODUCT_SORT_PRICE_LOW,
        PRODUCT_SORT_RATING_HIGH,
      ],
    }
    this.indexName = environment.constants.ALGOLIA_INDEX_PRODUCTS
    this._initialize()
  }

  // Sorting done for products by producers that were used to fetch them on the map
  // TODO: This is a stop gap until we get _geoloc saved/updated for products in algolia
  customSort(searchType, results) {
    // console.log('<<<SEARCH - DOING PRODUCT CUSTOM SORTING')
    const currentSort = this.getCurrentSort()
    let sortedResults = results
    const producerIds = this.getFilter(PRODUCT_FILTER_PRODUCER_IDS)
    if (
      producerIds &&
      producerIds.length &&
      searchType === LOAD_PRODUCERS_PRODUCTS &&
      (!currentSort || currentSort === PRODUCT_SORT_DISTANCE_LOW)
    ) {
      sortedResults = _.sortBy(results, (result) =>
        producerIds.indexOf(result.shopId),
      )
    }
    return sortedResults
  }
}
