import colors from '../config/colors'
import { stylus } from '../config/styles'

let cardStyle = {
  backgroundColor: colors.mainBackground,
}

if (colors.fullScreenBackground) {
  cardStyle = {
    ...cardStyle,
    backgroundColor: 'transparent',
    shadowColor: 'transparent',
  }
}

export default {
  iosStackNavigator: {
    mode: 'card',
    headerMode: 'float',
    defaultNavigationOptions: {
      headerTitleAlign: 'center',
      headerTitleStyle: {
        color: colors.headerTitle,
      },
      cardStyle,
    },
  },
  androidStackNavigator: {
    mode: 'card',
    headerMode: 'float',
    defaultNavigationOptions: {
      headerTitleAlign: 'center',
      cardStyle,
      safeAreaInsets: { top: 0 },
    }
  },
  stackNavigator: {
    defaultNavigationOptions: {
      headerTitleAlign: 'center',
      headerStyle: {
        backgroundColor: colors.topNavigationBackground,
      },
      headerTitleStyle: {
        color: colors.headerTitle,
      },
      cardStyle,
    },
  },
}
