import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  Platform,
  View,
  TouchableOpacity,
} from 'react-native'
import i18n from 'i18n-js'

import Action from '../../components/simple/Action'
import ListItem from '../../components/simple/ListItem'
import shared, { stylus } from '../../config/styles'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import Icons from '@expo/vector-icons/Ionicons'

class Empty extends React.Component {
  render() {
    return (
      <View style={styles.emptyContainer}>
        <Text style={styles.empty}>
          {i18n.t('notifications.emptyNotifications')}
        </Text>
      </View>
    )
  }
}

export default Empty

const styles = stylus({
  container: {},
  emptyContainer: {
    alignItems: 'center',
  },
  empty: {
    paddingVertical: 40,
    paddingHorizontal: 23,
    fontSize: 14,
    color: colors.text.secondary,
  },
})
