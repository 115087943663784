import React from 'react'
import { Clipboard, Text, TouchableOpacity, View } from 'react-native'
import ZocialIcon from '@expo/vector-icons/Zocial'
import MDCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons'

import sharedStyles, { stylus } from '../../../config/styles'
import colors from '../../../config/colors'
import alert from '../../../utility/alert'
import i18n from 'i18n-js'
import { connect } from '../../../config/connected'
import { ANALYTICS_PAYMENT_METHOD_PAGE_LOAD } from '../../../reducers/analytics'
import PropTypes from 'prop-types'

class PaymentMethods extends React.Component {

  componentDidMount() {
    this.props.analyticsPaymentMethodPageLoad()
  }

  paymentTypes(paymentTypes) {
    const icons = []
    for (const key in paymentTypes) {
      const hasPaymentType = paymentTypes[key]
      if (!hasPaymentType) {
        continue
      }
      switch (key) {
        case 'cash':
          icons.push(
            <MDCommunityIcon
              key={'cash'}
              style={styles.margin}
              name='cash-multiple'
              color={colors.black}
              size={16}
            />,
          )
          break
        case 'transfer':
          icons.push(
            <MDCommunityIcon
              key={'transfer'}
              style={styles.margin}
              name='bank'
              color={colors.black}
              size={16}
            />,
          )
          break
        case 'stripe':
          icons.push(
            <ZocialIcon
              key={'stripe'}
              style={styles.margin}
              name='stripe'
              color={colors.black}
              size={14}
            />,
          )
          break
      }
    }
    return <View style={styles.iconSet}>{icons}</View>
  }

  copyIbanNumber = () => {
    console.log('<<<PAYMENT METHOD - Copied iban')
    const { shop } = this.props
    const { producerDetails } = shop
    const { ibanNumber } = producerDetails
    Clipboard.setString(ibanNumber)
    alert({ title: 'Copied', message: 'Copied IBAN to clipboard.' })
  }

  copyBankAccountHolder = () => {
    console.log('<<<PAYMENT METHOD - Copied bank account holder')
    const { shop } = this.props
    const { producerDetails } = shop
    const { bankAccountHolder } = producerDetails
    Clipboard.setString(bankAccountHolder)
    alert({
      title: 'Copied',
      message: 'Copied bank account holder to clipboard.',
    })
  }

  render() {
    const { style, titleStyle, shop, title } = this.props
    const { producerDetails } = shop
    const { paymentTypes, ibanNumber, bankAccountHolder } = producerDetails
    return (
      <View style={style}>
        {title ? (
          <Text style={[sharedStyles.h5, styles.title, titleStyle]}>
            {title}
          </Text>
        ) : null}
        <View style={[styles.row]}>
          <Text style={[styles.paymentInfoText, styles.bold]}>
            {i18n.t('order.order.paymentTypesAccepted')}:{' '}
          </Text>
          {this.paymentTypes(paymentTypes)}
        </View>
        {paymentTypes.transfer && (
          <React.Fragment>
            <TouchableOpacity onPress={this.copyIbanNumber}>
              <Text style={styles.paymentInfoText}>
                <Text style={styles.bold}>{i18n.t('order.order.iban')}: </Text>
                {ibanNumber}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={this.copyBankAccountHolder}>
              <Text style={styles.paymentInfoText}>
                <Text style={styles.bold}>
                  {i18n.t('order.order.bankAccountHolder')}:{' '}
                </Text>
                {bankAccountHolder}
              </Text>
            </TouchableOpacity>
          </React.Fragment>
        )}
      </View>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  analyticsPaymentMethodPageLoad: () => {
    dispatch({
      type: ANALYTICS_PAYMENT_METHOD_PAGE_LOAD,
      data: {},
    })
  },
})

PaymentMethods.propTypes = {
  analyticsPaymentMethodPageLoad: PropTypes.func,
}

export default connect(null, mapDispatchToProps)(PaymentMethods)

const styles = stylus({
  row: {
    flex: 1,
    flexDirection: 'row',
    justifiyContent: 'flex-start',
  },
  bold: {
    fontWeight: '600',
  },
  title: {
    fontWeight: 'bold',
    color: colors.text.main,
  },
  paymentInfoText: {
    color: colors.text.main,
    fontSize: 13,
    marginBottom: 2,
  },
  iconSet: {
    flex: 1,
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
  },
  margin: {
    marginRight: 5,
  },
})
