import React from 'react'
import { Text } from 'react-native'
import PropTypes from 'prop-types'
import { stylus } from '../../config/styles'
import { getPrice } from '../../config/helpers'
import colors from '../../config/colors'

const Price = ({
  priceDiscount,
  hasPriceDiscount,
  price,
  priceStyle,
  originalPriceStyle
}) => (
  <Text style={[styles.priceText, priceStyle]} numberOfLines={1}>
    {`${getPrice(hasPriceDiscount ? priceDiscount : price)} `}
    {hasPriceDiscount && (
      <Text
        style={[
          styles.priceTextOriginal,
          { textDecorationLine: 'line-through' },
          originalPriceStyle
        ]}
      >
        {getPrice(price)}
      </Text>
    )}
  </Text>
)

Price.propTypes = {
  hasPriceDiscount: PropTypes.bool,
  originalPriceStyle: PropTypes.object,
  price: PropTypes.number,
  priceDiscount: PropTypes.number,
  priceStyle: PropTypes.object,
}

Price.defaultProps = {
  originalPriceStyle: {},
  priceStyle: {}
}

const styles = stylus({
  priceTextOriginal: {
    fontSize: 13,
    backgroundColor: 'transparent',
    color: colors.text.secondary,
  },
  priceText: {
    fontSize: 16,
    backgroundColor: 'transparent',
    color: colors.text.main,
  },
})

export default Price
