import React from 'react'
import { View, Text, Platform } from 'react-native'
import * as WebBrowser from 'expo-web-browser'
import ImageCarousel from '../ImageCarousel'
import PostHeader from './PostHeader'
import PostComments from './PostComments'
import PostLikes from './PostLikes'
import PostActionBar from './PostActionBar'
import Button from '../simple/Button'
import RecipeInfoBar from './RecipeInfoBar'
import Gradient from '../simple/Gradient'
import NavigationActions from '../../utility/navigationActions'

import sharedStyles, { stylus } from '../../config/styles'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import _ from 'lodash'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import withActionSheet from '../../containers/ActionSheet/withActionSheet'
import { isWebsite } from '../../config/validate'
import TagText from '../../components/Tagging/TagText'
import { client } from '../../containers/withApollo'
import productSchema from '../../schema/product'

import {
  connect,
  compose,
  _get,
} from '../../config/connected'
import ProductCarousel from '../ProductCarousel/ProductCarousel'
import ProductCarouselItemVertical from '../ProductCarousel/CarouselItemVertical'
import TagsCloud from './TagsCloud'
import { getPendingContent } from '../../config/helpers'

@withActionSheet
class PostRecipe extends React.Component {
  static propTypes = {
    post: PropTypes.object,
    comments: PropTypes.object,
    headerAbove: PropTypes.bool,
    single: PropTypes.bool,
    viewDetails: PropTypes.bool,
    goToComments: PropTypes.func,
    goToDetails: PropTypes.func,
    goToProduct: PropTypes.func,
    pendingPostEdit: PropTypes.object,
  }

  state = {
    recipeIngredientsItems: [],
    fetchingIngredients: false,
  }

  componentDidUpdate(prevProps) {
    this.fetchRecipeIngredients()
  }
  shouldComponentUpdate(nextProps, nextState) {
    if(!_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)) {
      return true
    }
    return false
  }
  carouselOptions = () => {
    const { title } = _get(this.props, 'post.recipe', { title: '...' })
    return (
      <Gradient
        first={'rgba(0, 0, 0, 0)'}
        middle={'rgba(0, 0, 0, 0)'}
        second={'rgba(0,0,0,0.5)'}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
        vertical
      >
        <View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            bottom: 0,
            left: 0,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Text style={[styles.title]}>{title}</Text>
        </View>
      </Gradient>
    )
  }

  goToComments = ({ autoFocus, scrollToComments }) => {
    const { goToComments, post } = this.props
    goToComments({ id: post.id, autoFocus, scrollToComments })
  }

  goToDetails = () => {
    const { goToDetails, post } = this.props
    goToDetails({ id: post.id })
  }

  handleRecipeLinkClick = () => {
    let title = i18n.t('errors.common.thirdPartyLinkDisclaimer')
    let options = [i18n.t('common.iUnderstand'), i18n.t('common.cancel')]
    let cancelButtonIndex = 1
    let destructiveButtonIndex = 0
    this.props.showActionSheetWithOptions(
      {
        title,
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (index) => {
        if (index === 1) {
          return null
        } else {
          this.handleOpenBrowser()
        }
      },
    )
  }

  handleOpenBrowser = async () => {
    const {
      post: {
        recipe: { recipeLink },
      },
    } = this.props

    let isUrl
    try {
      let testUrl = recipeLink.toString()
      // Does this url match the required pattern for opening a new tab / webview?
      isUrl = isWebsite(testUrl)
    } catch (e) {
      // if something goes wrong just bail out and log what happened.
      console.log(e.message)
      return
    }

    if (!isUrl) {
      // if the provided link is a malformed URL, cancel.
      // TODO: Add some sort of feedback that lets the user know that the url isn't working.
      // alert('failed to open link')
      return
    }

    //if we're on web, open a new tab, otherwise let expo open a web browser window.
    if (Platform.OS === 'web') {
      window.open(recipeLink)
      return
    }
    let error
    try {
      error = await WebBrowser.openBrowserAsync(recipeLink)
    } catch (e) {
      console.log(e.message)
    }
  }

  getCommentStartPosition = (e) => {
    // console.log(`${e.nativeEvent.layout.y}`)
    this.setState({ commentStartPosition: e.nativeEvent.layout.y })
    typeof this.props.onCommentsLayout === 'function' &&
      this.props.onCommentsLayout(e)
  }

  fetchRecipeIngredients = async () => {
    const { post } = this.props
    const tags = _get(post, 'recipe.ingredients.tags', [])
    if (
      this.state.fetchingIngredients ||
      post.type !== 'recipe' ||
      !tags.length ||
      this.state.alreadyFetched
    ) {
      return
    }

    let products
    const ingredientsIdList = _.compact(
      _.uniq(
        _.map(tags, (item) => {
          if (item.productId) {
            return item.productId
          }
        }),
      ),
    )

    if (ingredientsIdList.length) {
      this.setState({ fetchingIngredients: true })
      products = await client.query({
        query: productSchema.queries.productsByIdList,
        name: 'specialSauce',
        variables: {
          list: ingredientsIdList,
        },
      })
    }

    // console.log(Object.keys(products))

    this.setState({
      recipeIngredientsItems: _.get(products, 'data.productsByIdList') || [],
      fetchingIngredients: false,
      alreadyFetched: true,
    })
  }
  renderRecipe = (uploads) => {
    const { post, single, viewDetails, comments, screenInfo, pendingPostEdit } = this.props
    const trendingImageWidth = screenInfo.contentWidth / 3
    const { recipeIngredientsItems } = this.state

    const { user, topComments = [], taggedProducts = [] } = post

    let firstComment = null
    if (!viewDetails) {
      if (post.story) {
        firstComment = {
          content: post.story,
          createdAt: post.createdAt,
          user: post.user,
          id: post.id,
        }
      } else if (taggedProducts.length > 0) {
        firstComment = {
          content: {tags: [], value: ''},
          createdAt: post.createdAt,
          user: post.user,
          id: post.id,
        }
      }

    }
    const dietItems = _.get(post, 'recipe.info.diet')
    const showAdvHashtag = !viewDetails && taggedProducts.length > 0
    return (
      <View style={styles.container}>
        <PostHeader post={post} user={user} style={styles.marginVertical} pendingPostEdit={this.props.pendingPostEdit} />
        <ImageCarousel navigation={this.props.navigation} images={uploads} options={this.carouselOptions()} pendingRefresh={!!pendingPostEdit} activityIndicatorOverlayEnabled={false}/>
        <ProductCarousel
          items={taggedProducts.length > 0 ? taggedProducts : recipeIngredientsItems}
          imageSize={trendingImageWidth}
          showPrice
          size='small'
          style={styles.productCarouselContainer}
          imageStyles={[
            styles.carouselImageStyles,
            { borderRadius: 4 }// trendingImageWidth / 2 },
          ]}
          showAddToCart
          contentContainerStyle={styles.productCarousel}
          hasBorder
        />
        <PostActionBar
          post={post}
          goToComments={() => this.goToComments({ scrollToComments: true })}
          style={{ marginTop: 6 }}
        />

        <PostLikes post={post} />

        {/* <Text style={styles.title}>{post.title}</Text> */}
        <View style={{ paddingTop: 6 }}>
          <RecipeInfoBar expanded={viewDetails} recipe={post.recipe} />
        </View>
        {viewDetails ? null : (
          <View style={[styles.padding]}>
            <Button
              label={i18n.t('recipe.viewRecipeButton')}
              labelStyle={styles.buttonLabel}
              style={[styles.button]}
              onPress={this.goToDetails}
            />
          </View>
        )}
        {!viewDetails ? null : (
          <View>
            <View style={{ paddingLeft: 14 }}>
              <Text>{'\n'}</Text>
              <Text>
                <TagText content={post.story} />
                {taggedProducts.length > 0 && <Text style={styles.ghostContent}> #Adv</Text>}
              </Text>

              {/* <Text>
                {'\n'}
                {post.story}
              </Text> */}
              {!!_get(post, 'recipe.recipeLink', '') && viewDetails ? ( // eslint-disable-line
                // eslint disabled because not casting strings to actual booleans can cause failures
                // as react native tries to render the actual value as text causing vague errors
                // to be thrown on android and sometimes ios.
                <Text
                  style={{
                    textDecorationLine: 'underline',
                    color: colors.text.link,
                  }}
                  onPress={this.handleRecipeLinkClick}
                >
                  {'\n' + post.recipe.recipeLink}
                </Text>
              ) : null}
              {!_.isEmpty(dietItems) && (
                <>
                  <Text style={sharedStyles.h4}>
                    {'\n'}
                    {i18n.t('post.labels.diet')}
                  </Text>
                  <TagsCloud items={dietItems} i18nKey={'categories.diet'}/>
                </>
              )}
              <Text style={sharedStyles.h4}>
                {'\n'}
                {i18n.t('post.labels.ingredients')}
              </Text>
              <TagText content={post.recipe.ingredients} />
              <Text style={sharedStyles.h4}>
                {'\n'}
                {i18n.t('post.labels.instructions')}
              </Text>
              <TagText content={post.recipe.instructions} />
            </View>
            <View style={sharedStyles.separator} />
          </View>
        )}
        <PostComments
          onLayout={this.getCommentStartPosition}
          firstComment={firstComment}
          comments={
            single ? comments : { loading: false, comments: topComments }
          }
          commentCount={post.commentCount}
          goToComments={this.goToComments}
          showAdvHashtag={showAdvHashtag}
          single={single}
        />
        {single ? null : <View style={sharedStyles.separator} />}
      </View>
    )
  }
  render() {
    const { post, pendingPostEdit } = this.props
    const pendingUploads = _.get(pendingPostEdit, 'payload.uploads')
    const finalUploads = pendingUploads || post.uploads
    return this.renderRecipe(finalUploads)
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.currentUser,
  screenInfo: state.screenInfo,
  pendingPostEdit: getPendingContent(state.publishContent, 'recipe', ownProps.post.id, 'payload.id')
})

const mapDispatchToProps = (dispatch) => ({
  goToProduct: (id) => NavigationActions.navigate({ routeName: 'Product', params: { id } }),
  goToComments: ({
    id,
    newComment,
    autoFocus = false,
    scrollToComments = false,
  }) =>
    NavigationActions.navigate({
      routeName: 'PostComments',
      params: { id, newComment, autoFocus, scrollToComments },
    })
  ,
  goToDetails: ({ id }) =>
    NavigationActions.navigate({
      routeName: 'RecipeDetails',
      params: { id },
    })
  ,
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PostRecipe)

const styles = stylus({
  container: {},
  marginVertical: {
    marginTop: 6,
    marginBottom: 10
  },
  padding: {
    paddingHorizontal: 16,
  },
  title: {
    marginBottom: 16,
    textAlign: 'left',
    width: '100%',
    paddingLeft: 16,
    textShadowColor: 'black',
    shadowOpacity: 0.5,
    textShadowRadius: 6,
    textShadowOffset: {
      height: 0,
      width: 0,
    },
    // fontWeight: 'bold',
    fontSize: 22,
    fontWeight: 'bold',
    color: 'white',
  },
  button: {
    backgroundColor: colors.primary,
    marginTop: 15,
    marginBottom: 9,
  },
  buttonLabel: {
    fontSize: 16,
  },
  carouselImageStyles: {
    borderWidth: 1,
    padding: 4,
    borderColor: '#CCC',
  },
  productCarousel: {
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 10,
  },
  productCarouselContainer: {
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine
  },
  ghostContent: {
    color: colors.text.secondary,
  },
})
