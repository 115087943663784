import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import PropTypes from 'prop-types'

import NavigationActions from '../../utility/navigationActions'
import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import { connect } from 'react-redux'
import moment from 'moment'
import NamedImage from '../../components/simple/NamedImage'
import PostOptions from './PostOptions'
import UserTitle from '../UserTitle'
import branch from '../../config/branch'
import InstaFollowButton from '../InstaFollowButton'
import _ from 'lodash'
import { graphql } from 'react-apollo'
import { compose } from 'recompose'
import schema from '../../schema/user'

const PostAge = ({ createdAt }) => {
  const date = moment(createdAt)

  if (moment().diff(date, 'day') === 0) {
    const [seconds, setSeconds] = useState(0)

    useEffect(() => {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1)
      }, 10000)
      return () => clearInterval(interval)
    }, [])
  }

  return (
    <Text style={styles.age}>
      {date.fromNow()}
    </Text>
  )
}

PostAge.propTypes = {
  createdAt: PropTypes.any,
}

class PostHeader extends React.Component {
  static propTypes = {
    cuid: PropTypes.string,
    followUser: PropTypes.func,
    unfollowUser: PropTypes.func,
    goToProfile: PropTypes.func,
    style: PropTypes.object,
    post: PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdAt: PropTypes.date,
      user: PropTypes.shape({
        id: PropTypes.string,
        username: PropTypes.string.isRequired,
        title: PropTypes.string,
        organization: PropTypes.string,
        profileImage: PropTypes.string,
      }).isRequired,
    }),
    pendingPostEdit: PropTypes.object,
  }

  _followUser = () => {
    // const following = _.get(user, 'iFollow', null)
    const {
      post: { user },
    } = this.props

    if (user.iFollow === null || user.iFollow === undefined) {
      this.props.followUser({
        variables: { id: user.id },
      })
    } else {
      this.props.unfollowUser({
        variables: { id: user.id },
      })
    }
  }

  renderAge = () => {
    const createdAt = this.props.post.createdAt

    return (
      <Text style={styles.age}>
        {/* <FormattedRelative updateInterval={60 * 1000} value={new Date(createdAt)} /> */}
        {moment(createdAt).fromNow()}
      </Text>
    )
  }

  goToProfile = () => {
    const { id, username } = this.props.post.user
    this.props.goToProfile({ id, pageTitle: username })
  }

  render() {
    const { post, style } = this.props
    const { user } = post
    const { title, profileImage, username, organization, organizationId } = user
    const following = _.get(user, 'iFollow', null)
    let showFollow = user.id !== this.props.cuid && following === null
    showFollow = user.justFollowed ? true : showFollow
    return (
      <>
        <TouchableOpacity
          style={[styles.container, style]}
          //onPress={() => goToShop('2')}
          onPress={this.goToProfile}
        >
          <NamedImage
            style={styles.profileImage}
            name={profileImage}
            width={44}
            height={44}
            fit='crop'
            crop='faces'
            resizeMode='cover'
            resizeMethod='scale'
            placeholder='profile'
          />
          <View style={[styles.textContainer, branch({ android: { marginRight: 15 } })]}>
            <View style={styles.titleContainer}>
              <Text numberOfLines={1} style={[styles.username]}>{`${username}`}</Text>
              {showFollow &&
              <InstaFollowButton onPress={this._followUser} position={'header'} iFollow={following}/>}
            </View>
            <View style={styles.userTitleContainer}>
              <UserTitle title={title} organization={organization} organizationId={organizationId}/>
            </View>
            <PostAge createdAt={this.props.post.createdAt}/>
          </View>
          <PostOptions post={post} pendingPostEdit={this.props.pendingPostEdit}/>
        </TouchableOpacity>
      </>
    )
  }
}

const styles = stylus({
  titleContainer: {
    flex: 0, flexDirection: 'row', alignItems: 'flex-start',
    marginBottom: 2,
  },
  userTitleContainer: {
    marginBottom: 2,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 16,
  },
  profileImage: {
    width: 44,
    height: 44,
    borderRadius: 22,
    marginHorizontal: 8,
    // marginVertical: 11,
  },
  textContainer: {
    flex: 1,
  },
  username: {
    color: colors.text.main,
    fontWeight: '600',
    fontSize: 14,
    flex: 0,
    maxWidth: '50%',
    // flexShrink: 1,
    // flexGrow: 1
  },
  age: {
    color: colors.text.secondary,
    fontSize: 12,
  },
})

const mapStateToProps = (state) => ({
  cuid: state.currentUser.id,
})

const mapDispatchToProps = (dispatch) => ({
  goToShop: ({ id, displayName }) => {
    NavigationActions.navigate({ routeName: 'Shop', params: { id, pageTitle: displayName } })
  },
  goToProfile: ({ id, pageTitle }) => {
    NavigationActions.navigate({
      routeName: 'Profile',
      params: { id, pageTitle },
    })
  },
})


export default compose(
  graphql(schema.mutations.followUser, { name: 'followUser' }),
  graphql(schema.mutations.unfollowUser, { name: 'unfollowUser' }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PostHeader)
