import _ from 'lodash' 
import CommonRouter from './CommonRouter'
import { chatRoutes } from '../routes/ChatRoutes'

const routerActionHandlers = {
  navigate: (initialRouteName, action, state) => {
    if (
      action.type === 'Navigation/NAVIGATE' &&
      action.routeName === initialRouteName
    ) {
      const params = {
        id: _.get(action, 'params.id', 'all'),
      }
      // console.log({action})
      return {
        ...state,
        routes: [{ routeName: initialRouteName, key: 'conversations', params }],
        index: 0,
      }
    }
  }
}

const CurrentNavigator = CommonRouter('Chat', chatRoutes.Conversations, routerActionHandlers)
export default CurrentNavigator

