import React from 'react'
import { Text, TouchableOpacity } from 'react-native'
import colors from '../../../config/colors'
import i18n from 'i18n-js'
import PropTypes from 'prop-types'

const MinimumOrderAmountSign = ({ onPress, amount }) => (
  <TouchableOpacity onPress={onPress}>
    <Text style={{
      fontWeight: 'normal',
      color: colors.taggingText,
    }}>{i18n.t('order.cart.minimumOrderNotMet.title', { amount })}</Text>
  </TouchableOpacity>
)

MinimumOrderAmountSign.propTypes = {
  onPress: PropTypes.func,
  amount: PropTypes.number,
}

export default MinimumOrderAmountSign
