import React from 'react'
import { StyleSheet, View } from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import { compose, connect, graphql } from '../../config/connected'
import { getKeyboardPaddingStyle } from '../../config/helpers'
import withCurrentUser from '../../containers/withCurrentUser'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'
import schema from '../../schema/conversation'
import LayoutAnimation from '../LayoutAnimation'
import ChatInput from './ChatInput'
import MessageList from './MessageList'
import { ROUTE_REPORT_CONTENT_MESSAGE } from '../../config/constants'
import { CONTENT_IS_PENDING } from '../../reducers/publishContent'
import helpers from './helpers'
import _ from 'lodash'

const debug = false 

@WithKeyboardInfo
class Conversation extends React.Component {
  constructor(props) {
    super(props)
    this.loadedNewConversation = true
  }
  render() {
    const { membership, messages, id, defaultMessage, keyboardInfo, currentUser, onBack } = this.props
    const keyboardPaddingStyle = getKeyboardPaddingStyle(keyboardInfo)
    return (
      <View style={[styles.container, keyboardPaddingStyle]}>
        <MessageList
          currentUser={currentUser.currentUser}
          membership={membership}
          messages={messages}
          goToProfile={this.props.goToProfile}
          goToReportContent={this.props.goToReportContent}
        />
        <ChatInput defaultValue={defaultMessage} disabled={membership.loading} send={this.send} onBack={onBack} />
      </View>
    )
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { id, membership } = nextProps
    if (id != this.props.id) {
      this.loadedNewConversation = true
    }

    const record = membership.conversationMembership

    if (record && this.loadedNewConversation) {
      this.loadedNewConversation = false
      if (record.unreadCount > 0) {
        this.membershipSeen()
      }
    }
  }
  membershipSeen = () => {
    const { id, membership } = this.props
    this.props.membershipSeen({
      variables: { id },
    })
  }
  send = async ({ text, imageFile }) => {
    this.animate()
    const {
      currentUser,
      membership,
      users,
      dispatch,
      uploads
    } = this.props
    if (membership.loading) {
      console.log('oops, membership still loading')
      return
    }
    const imageFileId = _.get(imageFile, 'id')
    debug && console.log('<<<CONVERSATION - imageFile id', imageFileId)
    const upload =  uploads[imageFileId]
    debug && console.log('<<<CONVERSATION - imageFile upload', upload)
    const imageFileIsPending = upload && upload.state !== 'complete'
    debug && console.log('<<<CONVERSATION - imageFileIsPending', imageFileIsPending)
    if (membership.conversationMembership) {
      dispatch({
        type: CONTENT_IS_PENDING,
        contentType: 'message',
        pendingUploads: imageFileIsPending ? [upload] : [],
        allUploads: upload ? [upload] : [],
        conversationMembership: membership.conversationMembership,
        imageFile,
        user: currentUser.currentUser || {},
        conversationName: helpers.conversationName({ members: users, cuid: currentUser.id }),
        text,
        createdAt: Date.now(),
      })
    }
  }
  animate() {
    LayoutAnimation &&
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }
}

const mapStateToProps = ({ uploads }) => ({ uploads })

const mapDispatchToProps = (dispatch) => ({
  goToProfile: ({ id, pageTitle }) => {
    NavigationActions.navigate({
      routeName: 'Profile',
      params: { id, pageTitle },
    })
  },
  goToReportContent: ({ id }) => {
    NavigationActions.navigate({ routeName: ROUTE_REPORT_CONTENT_MESSAGE, params: { objectId: id } })
  },
  dispatch,
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCurrentUser,
  graphql(schema.queries.messages, {
    name: 'messages',
    options: (props) => ({
      variables: {
        membershipId: props.id,
        first: 20,
      },
    }),
  }),
  graphql(schema.queries.conversationMembership, {
    name: 'membership',
    options: (props) => ({
      variables: {
        id: props.id,
      },
    }),
  }),
  graphql(schema.mutations.createMessage, {
    name: 'createMessage',
  }),
  graphql(schema.mutations.membershipSeen, {
    name: 'membershipSeen',
  }),
)(Conversation)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
