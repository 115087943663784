import environment from '../../../../config/environment'
import i18n from 'i18n-js'
import colors from '../../../../config/colors'
import branch from '../../../../config/branch'
import CoderaWebViewHelpersScript from '../../../simple/CoderaWebView/CoderaWebViewHelpers'

const content = branch({
  android: /*html*/`
    <div class="android-spinner">
      <svg viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20"></circle>
      </svg>
    </div>
  `,
  ios: /*html*/`
    <div class="ios-spinner">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
      <div class="bar4"></div>
      <div class="bar5"></div>
      <div class="bar6"></div>
      <div class="bar7"></div>
      <div class="bar8"></div>
      <div class="bar9"></div>
      <div class="bar10"></div>
      <div class="bar11"></div>
      <div class="bar12"></div>
    </div>
  `
})

const style = branch({
  android: /*css*/`
    .android-spinner svg {
      width: 3.75em;
      transform-origin: center;
      animation: rotate 2s linear infinite;
    }
    
    .android-spinner circle {
      fill: none;
      stroke: ${colors.primary};
      stroke-width: 2;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
    
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
      }
      100% {
        stroke-dashoffset: -125px;
      }
    }
  `,
  ios: /*css*/`
    div.ios-spinner {
      position: relative;
      width: 54px;
      height: 54px;
      display: inline-block;
    }
    
    div.ios-spinner div {
      width: 6%;
      height: 16%;
      background: ${colors.primary};
      position: absolute;
      left: 49%;
      top: 43%;
      opacity: 0;
      -webkit-border-radius: 50px;
      -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.2);
      -webkit-animation: fade 1s linear infinite;
    }
    
    @-webkit-keyframes fade {
      from {opacity: 1;}
      to {opacity: 0.25;}
    }
    
    div.ios-spinner div.bar1 {
      -webkit-transform:rotate(0deg) translate(0, -130%);
      -webkit-animation-delay: 0s;
    }    
    
    div.ios-spinner div.bar2 {
      -webkit-transform:rotate(30deg) translate(0, -130%); 
      -webkit-animation-delay: -0.9167s;
    }
    
    div.ios-spinner div.bar3 {
      -webkit-transform:rotate(60deg) translate(0, -130%); 
      -webkit-animation-delay: -0.833s;
    }
    div.ios-spinner div.bar4 {
      -webkit-transform:rotate(90deg) translate(0, -130%); 
      -webkit-animation-delay: -0.7497s;
    }
    div.ios-spinner div.bar5 {
      -webkit-transform:rotate(120deg) translate(0, -130%); 
      -webkit-animation-delay: -0.667s;
    }
    div.ios-spinner div.bar6 {
      -webkit-transform:rotate(150deg) translate(0, -130%); 
      -webkit-animation-delay: -0.5837s;
    }
    div.ios-spinner div.bar7 {
      -webkit-transform:rotate(180deg) translate(0, -130%); 
      -webkit-animation-delay: -0.5s;
    }
    div.ios-spinner div.bar8 {
      -webkit-transform:rotate(210deg) translate(0, -130%); 
      -webkit-animation-delay: -0.4167s;
    }
    div.ios-spinner div.bar9 {
      -webkit-transform:rotate(240deg) translate(0, -130%); 
      -webkit-animation-delay: -0.333s;
    }
    div.ios-spinner div.bar10 {
      -webkit-transform:rotate(270deg) translate(0, -130%); 
      -webkit-animation-delay: -0.2497s;
    }
    div.ios-spinner div.bar11 {
      -webkit-transform:rotate(300deg) translate(0, -130%); 
      -webkit-animation-delay: -0.167s;
    }
    div.ios-spinner div.bar12 {
      -webkit-transform:rotate(330deg) translate(0, -130%); 
      -webkit-animation-delay: -0.0833s;
    }
  `
})

export default (clientSecret, stripeUserId) => {
  const html =   /*html*/`
  <!DOCTYPE html>
  <html>
    <head>
      <title>Stripe Payment</title>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1 maximum-scale=1">
      <style>
        html, body {
          height: 100%;
        }
        .container {
          height: 100%;
          display: flex;
          flex: 1;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        ${style}
      </style>
      <script src="https://js.stripe.com/v3/"></script>
      ${CoderaWebViewHelpersScript}
      <script>
        var options = {
          locale: '${i18n.locale}',
          maxNetworkRetries: 2
        }
        var stripeUserId = '${stripeUserId ? stripeUserId: ''}'
        if (stripeUserId) {
          options.stripeAccount = stripeUserId
        }
        var stripe = Stripe('${environment.stripe.publicKey}', options);
        stripe.confirmCardPayment(
          '${clientSecret}'
        ).then(function(result) {
          // Handle result.error or result.paymentIntent
          if (result.error) {
            console.log('CARD PAYMENT ERROR', result.error)
            window.CoderaWebViewHelpers.sendPostMessage({
              type: 'error',
              message: result.error.message
            })
          } else {
            console.log('CARD PAYMENT SUCCESS', result)
            var paymentIntent = result.paymentIntent
            if (paymentIntent.status === 'succeeded') {
              window.CoderaWebViewHelpers.sendPostMessage({
                type: 'payment_intent',
                message: paymentIntent
              })
            }
          }
        })  
      </script>
    </head>
    <body>
      <div class="container">
        ${content}
      </div>
    </body>
  </html>
  `
  return html
}
