import { Platform, Dimensions } from 'react-native'
import Constants from 'expo-constants'
import deviceDetect from 'device-detect'
import _ from 'lodash'

const isWeb = Platform.OS === 'web'
const isNative = !isWeb
const isIos = Platform.OS === 'ios'
const isIpa = isIos && Constants.appOwnership !== 'expo'
const isAndroid = Platform.OS === 'android'
const isApk = isAndroid && Constants.appOwnership !== 'expo'
const isExpo = isNative && Constants.appOwnership === 'expo'
// Samsung One UI, also referred to as Samsung Experience 10, is an Android launcher based on Android Pie(i.e. 9.0)
// The platform version for Android Pie is 28.
// All Samsung device names are type allocation codes that include the prefix "SM-".
const isSamsungOneUi = isApk && Platform.Version >= 28 && Constants.deviceName.indexOf('SM-') === 0
let isSafari = false
let isDesktop = false
let isLandscape = false
let isPortrait = false
let isIphone = false
let isIpad = false
let isNativeTablet = false
let isIphoneX = false

import sizes from './sizes'
import { getFeatureFlag } from './helpers'

let { width, height } = Dimensions.get('window')

if (width > sizes.TABLET_WIDTH) {
  isLandscape = true
  if (isNative) {
    isNativeTablet = true
  }
} else {
  isPortrait = true
}

if (isWeb) {
  const device = deviceDetect()
  if (device.browser === 'Safari') {
    isSafari = true
  }
  if (isLandscape) {
    isDesktop = true
  }
}
if (isIos) {
  if (isLandscape) {
    isIpad = true
  } else {
    isIphone = true
    if (
      (width === 375 && height === 812) || // Iphone X / Xs
      (width === 414 && height === 896) // Iphone Xs MAX / iPhone Xr
    ) {
      isIphoneX = true
    }
  }
}

// console.log({
//   isWeb,
//   isNative,
//   isIos,
//   isAndroid,
//   isSafari,
//   isDesktop,
//   isLandscape,
//   isPortrait,
//   isIphone,
//   isIphoneX,
//   isIpad,
// })

function getValue(item, returnOnly) {
  if (_.isFunction(item) && !returnOnly) {
    return item()
  }
  return item
}

export default function branch(options, returnOnly = false) {
  const {
    desktop,
    web,
    native,
    android,
    apk,
    samsungOneUi,
    ios,
    ipa,
    iphone,
    iphonex,
    ipad,
    portrait,
    landscape,
    safari,
    nativeTablet,
    other,
    expo,
    features = {},
    models = {},
  } = options

  if (web !== undefined && isWeb) {
    return getValue(web, returnOnly)
  }
  if (native !== undefined && isNative) {
    return getValue(native, returnOnly)
  }
  if (desktop !== undefined && isDesktop) {
    return getValue(desktop, returnOnly)
  }
  if (expo && isExpo) {
    return getValue(expo, returnOnly)
  }
  for (const model in models) {
    const value = models[model]
    if (value !== undefined && Constants.deviceName.indexOf(model) === 0) {
      return getValue(value, returnOnly)
    }
  }

  if (samsungOneUi && isSamsungOneUi) {
    return getValue(samsungOneUi, returnOnly)
  }
  if (apk !== undefined && isApk) {
    return getValue(apk, returnOnly)
  }
  if (android !== undefined && isAndroid) {
    return getValue(android, returnOnly)
  }
  if (iphonex !== undefined && isIphoneX) {
    return getValue(iphonex, returnOnly)
  }
  if (iphone !== undefined && isIphone) {
    return getValue(iphone, returnOnly)
  }
  if (ipad !== undefined && isIpad) {
    return getValue(ipad, returnOnly)
  }
  if (ipa !== undefined && isIpa) {
    return getValue(ipa, returnOnly)
  }
  if (ios !== undefined && isIos) {
    return getValue(ios, returnOnly)
  }
  if (portrait !== undefined && isPortrait) {
    return getValue(portrait, returnOnly)
  }
  if (landscape !== undefined && isLandscape) {
    return getValue(landscape, returnOnly)
  }
  if (nativeTablet !== undefined && isNativeTablet) {
    return getValue(nativeTablet, returnOnly)
  }

  for (const feature in features) {
    const value = features[feature]
    if (getFeatureFlag(feature) && value !== undefined) {
      return getValue(value, returnOnly)
    }
  }

  if (other !== undefined) {
    return getValue(other, returnOnly)
  }
  return null
}
