import React from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../config/connected'
import { getFeatureFlag, removeUploadsFromCache } from '../../config/helpers'
import withPublishPost from './withPublishPost'
import withPublishMessage from './withPublishMessage'
import withPublishUser from './withPublishUser'
import withPublishProducer from './withPublishProducer'
import withPublishProduct from './withPublishProduct'

import { CONTENT_IS_PUBLISHING, CONTENT_DONE_PUBLISHING, CONTENT_PUBLISH_ERRORED } from '../../reducers/publishContent'
import { USER_UNSET_PROFILE_IMAGE_UPLOAD } from '../../reducers/currentUser'

import _ from 'lodash'
import i18n from 'i18n-js'

const debug = false
const restoreFailedPublish = getFeatureFlag('restoreFailedPublish')

const mapStateToProps = ({ publishContent }) => ({ queued: publishContent.queued })
@connect(mapStateToProps, (dispatch) => ({ dispatch }))
@withPublishPost
@withPublishMessage
@withPublishUser
@withPublishProducer
@withPublishProduct
class PublishContentManager extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    queued: PropTypes.array,
    publishPost: PropTypes.func,
    publishMessage: PropTypes.func,
    publishUser: PropTypes.func,
    publishProducer: PropTypes.func,
    publishProduct: PropTypes.func,
  }
  async componentDidUpdate(prevProps, prevState) {
    if (this.props.queued.length && (prevProps.queued.length !== this.props.queued.length || prevProps.queued !== this.props.queued)) {
      const { queued } = this.props
      for (const content of queued) {
        if (content.isPublishing) continue
        let callback
        switch (content.contentType) {
          case 'post':
          case 'recipe':
            debug && console.log('<<<PUBLISHING POST')
            callback = this.props.publishPost
            break
          case 'user':
            debug && console.log('<<<PUBLISHING USER')
            callback = this.props.publishUser
            break
          case 'message':
            debug && console.log('<<<PUBLISHING MESSAGE')
            callback = this.props.publishMessage
            break
          case 'producer':
            debug && console.log('<<<PUBLISHING PRODUCER')
            callback = this.props.publishProducer
            break
          case 'product':
            debug && console.log('<<<PUBLISHING PRODUCT')
            callback = this.props.publishProduct
            break
        }

        const contentDescriptor = { id: content.id, type: content.contentType }
        this.props.dispatch({
          type: CONTENT_IS_PUBLISHING,
          contentDescriptor,
        })
        callback(content)
          .then(() => {
            this.props.dispatch({
              type: CONTENT_DONE_PUBLISHING,
              contentDescriptor,
            })
            removeUploadsFromCache(content.allUploads)
          })
          .catch((error) => {
            debug && console.log('<<<PUBLISH CONTENT MANAGER - publish error', error)
            if (content.contentType === 'user') {
              this.props.dispatch({
                type: USER_UNSET_PROFILE_IMAGE_UPLOAD,
              })
            }
            this.props.dispatch({
              type: CONTENT_PUBLISH_ERRORED,
              contentDescriptor,
              error: _.get(error, 'graphQLErrors.0.message') || i18n.t(error.message),
            })
            if (!restoreFailedPublish) {
              removeUploadsFromCache(content.allUploads)
            }
          })
      }
    }
  }

  render() {
    return null
  }
}

export default PublishContentManager
