import i18n from 'i18n-js'
import _ from 'lodash'
import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import LayoutAnimation from '../../components/LayoutAnimation'
import PostCommenting from '../../components/Post/PostCommenting'
import PostRecipe from '../../components/PostFB/PostRecipe'
import Scroller from '../../components/Scroller'
import KeyboardAvoidingView from '../../components/simple/KeyboardAvoidingView'
import MobileBackButton from '../../components/simple/MobileBackButton'
import { Status } from '../../components/simple/Status'
import { TaggingContext } from '../../components/Tagging/withTagging'
import branch from '../../config/branch'
import { compose, connect, graphql } from '../../config/connected'
import { getKeyboardPaddingStyle } from '../../config/helpers'
import { stylus } from '../../config/styles'
import withCurrentUser from '../../containers/withCurrentUser'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'
import commentSchema from '../../schema/comment'
import postSchema from '../../schema/post'

@WithKeyboardInfo
class RecipeDetailsFB extends React.Component {
  state = {
    refreshing: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // this.updateRecipeDetailsTitle()
    // this.scrollToTop()
    if (
      nextProps.comments.loading !== this.props.comments.loading ||
      _.get(nextProps, 'comments.comments.length') !==
        _.get(this.props, 'comments.comments.length')
    ) {
      this.animate()
    }
  }

  updateRecipeDetailsTitle = () => {
    const { setParams } = this.props.navigation
    const { post } = this.props

    if (post.loading) {
      return
    }

    if (!post.post) {
      return
    }

    const recipeTitle = post.post.recipe.title
    if (recipeTitle === this.props.navigation.state.params.title) {
      return
    }
    setParams({ title: recipeTitle })
  }

  render() {
    this.updateRecipeDetailsTitle()
    const { post } = this.props

    if (post.loading) {
      return (
        <View style={styles.loading}>
          <ActivityIndicator size='large' />
        </View>
      )
    }

    if (!post.post) {
      return (
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Status message={i18n.t('post.notAvailable')}/>
        </View>
      )
    }

    return (
      <KeyboardAvoidingView
        style={styles.container}
        behavior={branch({ ios: 'padding' })}
        contentContainerStyle={{ flex: 1 }}
        keyboardVerticalOffset={branch({
          iphonex: 64,
          iphone: 64,
          android: 81,
          other: 0,
        })}
      >
        {branch({
          android: this.renderWeb,
          ios: this.renderNative,
          web: this.renderWeb,
        })}
        {this.bottom()}
      </KeyboardAvoidingView>
    )
  }
  renderWeb = () => {
    const { layout } = this.props.screenInfo
    return (
      <Scroller>
        <View style={styles.feedPadding} />
        {this.renderContent()}
      </Scroller>
    )
  }
  renderContent = () => {
    const { navigation, comments, post } = this.props
    const { type } = post.post
    if (type !== 'recipe') {
      return null
    }
    return (
      <View style={styles.postWrapper}>
        <PostRecipe
          post={post.post}
            single={true}
            viewDetails={true}
            comments={{ loading: comments.loading, comments: comments.comments }}
          />
      </View>
    )
  }

  scrollToComments = () => {
    // The view is rendered inverted and then flipped again to move the pull to update functionality to the bottom of the page.
    if (!this.state.loadedOnceAndScrolled && this.scroller) {
      this.scroller.scrollToEnd({ animated: false })
      this.setState({ loadedOnceAndScrolled: true })
    }
  }
  refresh = () => {
    // this is just to smooth things
    this.setState({ refreshing: true })
    this.props.comments.refetch()
    setTimeout(() => {
      this.setState({ refreshing: false })
    }, 800)
  }

  renderNative = () => {
    // this.scrollToTop()
    const {
      screenInfo: {
        paddingLeft,
        paddingRight,
        gutterWidth,
        topBarHeight,
        contentTopPadding,
      },
    } = this.props

    return (
      <Scroller
        // renderItem={({ item }) => item}
        onRefresh={this.refresh}
        refreshing={this.state.refreshing}
        inverted
        style={{
          flex: 1,
          paddingLeft,
          paddingRight,
          // marginTop: topBarHeight,
          paddingTop: 0,
          transform: [{ scaleY: 1 }],
          // backgroundColor: 'orange',
        }}
        contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 0,
          marginBottom: 0,
        }}
        innerRef={(ref) => {
          this.scroller = ref
        }}
      >
        {this.renderContent()}
      </Scroller>
    )
  }
  bottom = () => {
    const {
      screenInfo: {
        paddingLeft,
        paddingRight,
        gutterWidth,
        topBarHeight,
        contentTopPadding,
      },
      goBack,
      keyboardInfo,
    } = this.props
    const keyboardPaddingStyle = getKeyboardPaddingStyle(keyboardInfo)
    const post = this.props.post.post
    return (
      <TaggingContext.Consumer>
        {(context = {}) => {
          const { taggingHook = {} } = context
          return (
            <View
              style={[
                {
                  paddingLeft,
                  paddingRight,
                  // backgroundColor: 'red',
                },
                keyboardPaddingStyle
              ]}
            >
              <PostCommenting
                id={post.id}
                send={this.send}
                taggingHook={taggingHook}
                navigation={this.props.navigation}
              />
            </View>
          )
        }}
      </TaggingContext.Consumer>
    )
    // onBlur={branch({desktop: null, other: () => goBack})}
  }
  send = async ({ content }) => {
    console.log('sending!', content)
    const { createComment, post } = this.props

    const postId = post.post.id

    let result = await createComment({
      variables: {
        postId,
        content,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createComment: {
          __typename: 'Comment',
          createdAt: new Date().toISOString(),
          id: '-1',
          content,
          user: this.props.currentUser.currentUser,
        },
      },
      update: (store, { data: { createComment } }) => {
        // update comments
        const data = store.readQuery({
          query: commentSchema.queries.comments,
          variables: { postId },
        })
        data.comments.push(createComment)
        store.writeQuery({
          query: commentSchema.queries.comments,
          variables: { postId },
          data,
        })

        // update post comment count
        const postData = store.readQuery({
          query: postSchema.queries.post,
          variables: { id: postId },
        })
        postData.post.commentCount++
        store.writeQuery({
          query: postSchema.queries.post,
          variables: { id: postId },
          data: postData,
        })
      },
    })
  }
  animate() {
    if (this.props.animate != false) {
      LayoutAnimation &&
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    }
  }
}

RecipeDetailsFB.navigationOptions = (props) => {
  const { navigation } = props
  const params = _.get(navigation, 'state.params') || {}
  return {
    headerTitle: params.title || 'Recipe Details',
    headerLeft: () => (
      <MobileBackButton
        label={i18n.t('common.back')}
        onPress={() => NavigationActions.back()}
      />
    ),
    tabBarVisible: false,
  }
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({
  goBack: () => NavigationActions.back(),
})


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCurrentUser,
  graphql(postSchema.queries.post, {
    name: 'post',
    options: (props) => ({
      variables: {
        id: props.navigation.state.params.id,
      },
    }),
  }),
  graphql(commentSchema.queries.comments, {
    name: 'comments',
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        postId: props.navigation.state.params.id,
      },
    }),
  }),
  graphql(commentSchema.mutations.createComment, {
    name: 'createComment',
  }),
)(RecipeDetailsFB)

const styles = stylus({
  container: {
    flex: 1,
  },
  loading: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 60,
  },
  feedPadding: {
    landscape: {
      height: 31,
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
  },
  postWrapper: {
    native: {
      alignSelf: 'stretch',
    },
    ios: {
      transform: [{ scaleY: 1 }], // Invert rendering to get scroll to refresh on bottom of page.
    },
  },
})
