import React, { Component, PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Text,
  View,
  TouchableOpacity,
  Platform,
  Linking,
  Alert,
  ImageBackground
} from 'react-native'
import i18n from 'i18n-js'
import MDCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import services from '../../utility/services'

class MapStrip extends PureComponent {

  onMapPress = async () => {
    const result = await services.getUserLocationAsync()
    if (result === -1) {
      return
    }
    this.props.onMapPress()
  }

  render() {
    return (
      <View style={styles.mapSectionContainer}>
        <Text style={styles.mapTitle}>{i18n.t('feed.mapTitle')}</Text>
        <TouchableOpacity style={styles.mapPlaceholder} onPress={this.onMapPress}>
          <ImageBackground source={require(`../../assets/images/map_background.png`)} 
            style={{
              width: '100%', 
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <MDCommunityIcon
              name='map-marker'
              color={'black'}
              size={35}
            />
          </ImageBackground>
        </TouchableOpacity>        
      </View>
    )
  }
}

MapStrip.propTypes = {
  onMapPress: PropTypes.func,
}
MapStrip.defaultProps = {
  onMapPress: () => {}
}

const styles = stylus({
  mapPlaceholder: {
    backgroundColor: colors.emptyArea,
    width: '100%',
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5
  },
  mapSectionContainer: {
    paddingHorizontal: 12,
    marginTop: 12,
    marginBottom: 20
  },
  mapTitle: {
    color: colors.text.main,
    fontWeight: '600',
    fontSize: 17,
    marginBottom: 12,
  },
  mapSubtitle: {
    color: colors.text.secondary,
    marginBottom: 6,
  }
})

export default MapStrip
