import React from 'react'
import {
  Button,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import shared from '../../config/styles'

export default ({ showLabel, labelOnly,  onPress, style, label, color }) => {
  const shouldShowLabel = showLabel && label
  let extraStyle = { paddingLeft: 14 }
  if (shouldShowLabel) {
    extraStyle = {
      paddingLeft: 8
    }
  }
  let backIcon = null
  if (!labelOnly) {
    backIcon = Platform.OS === 'android' ? (
      <Icons
        name='md-arrow-back'
        size={36}
        color={color || colors.navigation}
        style={{ paddingTop: 3, paddingLeft: 6 }}
      />
    ) : (
      <Icons
        name='ios-arrow-back'
        size={30}
        color={color || colors.navigation}
        style={{ paddingTop: 4 }}
      />
    )
  }
  return (
    <TouchableOpacity style={[styles.container, extraStyle, style]} onPress={onPress}>
      { backIcon }
      {shouldShowLabel && (
        <Text style={[styles.text, color && { color }]}>{label}</Text>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingRight: 22,
    paddingVertical: 2,
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    fontSize: 17,
    color: colors.navigation,
    fontWeight: '400',
    paddingLeft: 6,
    // paddingBottom: 2,
  },
})
