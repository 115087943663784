
import React from 'react'
import { Platform } from 'react-native'
import { Linking } from 'expo'

import _ from 'lodash'
import debounce from 'debounce-promise'
import rfdc from 'rfdc'
const clone = rfdc({ proto: false})
import equal from 'fast-deep-equal'

import { connect } from '../config/connected'
import branch from '../config/branch'
import NavigationActions from '../utility/navigationActions'
import { client } from './withApollo'
import { updateCachedQueryValue } from '../utility/apollo'
import userSchema from '../schema/user'
import { ROUTE_PAYMENT_METHODS } from '../config/constants'

const mapStateToProps = ({ navigation, currentUser}) => ({ navigation, currentUser })
const mapDispatchToProps = (dispatch) => ({ dispatch })
@connect(mapStateToProps, mapDispatchToProps)
class LinkingManager extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ready: false }
  }
  componentDidMount() {
    branch({
      native: () => this.linkingListener = Linking.addEventListener('url', debounce(this.handleLinkObject, 100))
    })
  }

  componentWillUnmount() {
    branch({
      native: () => this.linkingListener && this.linkingListener.remove()
    })
  }


  async componentDidUpdate(prevProps, prevState) {
    const { state, props } = this
    console.log('<<<LINKING MANAGER - Checking for navigation readiness', props.currentUser, props.navigation.isReady)
    if (!this.state.ready && Platform.OS !== 'web' && !_.isEmpty(props.currentUser) && props.navigation.isReady === true) {
      this.setState({ ready: true })
      const url = await Linking.getInitialURL()
      console.log('<<<LINKING MANAGER - Navigating to initial URL', url)
      this.handleLink(url)
    }
  }

  handleLinkObject = (linkObject) => {
    const { url: rawUrl } = linkObject
    const url = _.trim(rawUrl, ' #')
    this.handleLink(url)
  }


  handleLink = async(url) => {
    const { dispatch, nav } = this.props
    const activeRoute = NavigationActions.getActiveRoute() || { routeName: '' }
    const { routeName: activeRouteName, params: activeParams } = activeRoute
    const { queryParams: params } = Linking.parse(url)
    const screenNameMatcher = new RegExp('.+://([a-zA-Z]+).+', 'gi')
    const matches = screenNameMatcher.exec(url)
    const routeName = _.get(matches, '[1]')
    // 3 different approaches for refreshing Stripe Connect screen
    // 1. Callback
    this.screenCallback(routeName, params)
    console.log('<<<LINKING MANAGER - Handling Link', url)
    console.log('<<<LINKING MANAGER - Changes to navigate check involve constructing regex')
    if (activeRouteName !== routeName || !equal(activeParams, params)) {
      console.log('<<<LINKING MANAGER - Navigating to route', routeName, params)
      NavigationActions.navigate({ routeName, params })
    } else {
      // 2. Reset routing stack
      // const routePath = getRoutePath(nav)
      // routePath.pop() // we are going to the last route again, but with new params from the app link
      // routePath.push({ routeName, params })
      // dispatch(NavigationActions.reset({
      //   index: routePath.length - 1,
      //   key: null,
      //   actions: routePath.map(route => NavigationActions.navigate(route))
      // }))
      // 3. Go back and then forward
      // NavigationActions.back()
      // NavigationActions.navigate({ routeName, params })
    }
  }

  screenCallback = (routeName, params) => {
    const { dispatch } = this.props
    if(routeName === 'StripeConnect' && params.success) {
      updateCachedQueryValue(client, {
        query: userSchema.queries.getShop,
        variables: { id: params.id },
        nextValue: (data) => {
          const dataCopy = clone(data)
          _.set(dataCopy, 'producerDetails.stripeConnected', true)
          return dataCopy
        }
      })
    } else if (routeName === 'Checkout') {
      console.log('Do something in Checkout')
    } else if (routeName === ROUTE_PAYMENT_METHODS) {
      console.log('Do something on PaymentMethods')
    }
  }

  render() {
    return null
  }
}

export default LinkingManager
