import { gql } from '../config/connected'
import { userProfileFragment } from './fragments'


const userAddress = `
address{
  address1
  address2
  city
  state
  region
  zip
  country
}`

const profileDetails = `
producerDetails {
  id
  rating
  ratingCount
  description
  legalName
  coordinates {
    lat
    lng
  }
  policies {
    returns
    shipping
    payment
  }
  established
  ownership {
    id
    userId
    profileId
  }
  category
  producerType
  method
  minimumOrderAmount
  standardShippingFee
  freeShippingThreshold
  paymentTypes {
    cash
    stripe
    transfer
  }
  ibanNumber
  bankAccountHolder
  website
  approved
  companyPhoneLand
  companyPhoneMobile
  euVAT
  VAT
  stripeConnected
  stripeUserId
  fee
  deliveryLocations
  shopDiscount
  countriesVisibleIn
  standardShippingFeeCountryData
  freeShippingThresholdCountryData
  policiesCountryData
}
`

const userProfile = `
  id
  username
  displayName
  title
  personDetails {
    id
    firstName
    lastName
    bio
    interests
  }
  ${userAddress}
  profileImage
  coverPhoto
  email
  phone
  isAdmin
  acceptedTerms
  acceptedTermsAt
  phoneVerified
  emailVerified
  isFoodPro
  isProducer
  blocked
`

const shopProfile = `
  id
  username
  displayName
  profileImage
  coverPhoto
  email
  phone
  isAdmin
  acceptedTerms
  acceptedTermsAt
  phoneVerified
  emailVerified
  isFoodPro
  isProducer
  blocked
  createdAt
  status
  followersCount
  iFollow
  iFollowType
  ${userAddress}
  ${profileDetails}
  personDetails {
    id
    firstName
    lastName
    bio
    interests
  }
  last3Followers {
    id
    username
    profileImage
  }
  followers {
    id
    displayName
    profileImage
  }
`

export default {
  types: `
  input UnseenBadgeInput {
    unseenNotifications: Int
  }
`,
  queries: {
    allUsers: gql`
      query {
        allUsers {
          id
          username
          displayName
          profileImage
        }
      }
    `,
    user: gql`
      query($id: String) {
        user(id: $id) {
          id
          username
          displayName
          title
          organization
          organizationId
          profileImage
          coverPhoto
          iFollow
          postCount
          followersCount
          followingCount
          isAdmin
          status
          personDetails {
            id
            bio
          }
          ownedProfile {
            id
            profileId
            profile {
              id
              displayName
              status
            }
          }
        }
      }
    `,
    currentUserFullProfile: gql`
      query($restrictToLocale: Boolean){
        currentUser(restrictToLocale: $restrictToLocale) {
            ...UserFragment
        }
      }
      ${userProfileFragment}
    `,
    getShop: gql`
      query($id: String!) {
        userById(id: $id) {
          ${shopProfile}
        }
      }
    `,
    getUserShop: gql`
      query {
        currentUser {
          id
          ownedProfile {
            id
            profile {
              ${shopProfile}
            }
          }
        }
      }
    `,
    usersByUsername: gql`
      query($username: String!, $filterForTagging: Boolean) {
        usersByUsername(username: $username, filterForTagging: $filterForTagging) {
          ${userProfile}
        }
      }
    `,
    userByAlias: gql`
      query($alias: String!) {
        userByAlias(alias: $alias) {
          ${userProfile}
        }
      }
    `,
    shopsByName: gql`
    query($shopName: String!, $limit: Int){
      shopsByName(shopName: $shopName, limit: $limit){
        ${shopProfile}
      }
    }`,
    shopsByAdminSearchParams: gql`
      query($searchParams: Json!, $page: Int!, $limit: Int!) {
        shopsByAdminSearchParams(searchParams: $searchParams, page: $page, limit: $limit) {
          ${shopProfile}
        }
      }
    `,
    notificationSettings: gql`
      query($userId: String!) {
        userById(id: $userId) {
          id
          notificationSettings {
            push
          }
        }
      }
    `,
  },
  mutations: {
    updateShopStatus: gql`
      mutation($id: String!, $status: String!) {
        updateShopStatus(id: $id, status: $status) {
          id
          status
        }
      }
    `,
    followUser: gql`
      mutation($id: String!, $type: FOLLOW_TYPE) {
        followUser(id: $id, type: $type) {
          id
          username
          displayName
          profileImage
          coverPhoto
          justFollowed
          iFollow
          iFollowType
          postCount
          followersCount
          followingCount
          followers {
            id
            displayName
            profileImage
          }
        }
      }
    `,
    unfollowUser: gql`
      mutation($id: String!) {
        unfollowUser(id: $id) {
          id
          username
          displayName
          profileImage
          coverPhoto
          iFollow
          iFollowType
          postCount
          followersCount
          followingCount
          followers {
            id
            displayName
            profileImage
          }
        }
      }
    `,
    updateUserById: gql`
      mutation(
        $id: String!
        $email: String
        $username: String
        $phone: String
        $password: String
        $locale: String
        $profileImage: String
        $coverPhoto: String
        $displayName: String
        $unseenBadge: UnseenBadge
        $isProducer: Boolean
        $isFoodPro: Boolean
        $isAdmin: Boolean
        $emailVerified: Boolean
        $phoneVerified: Boolean
        $acceptedTerms: Boolean
        $blocked: Boolean
        $status: String
      ) {
        updateUserById(
          id: $id
          email: $email
          username: $username
          phone: $phone
          password: $password
          locale: $locale
          profileImage: $profileImage
          coverPhoto: $coverPhoto
          displayName: $displayName
          unseenBadge: $unseenBadge
          isProducer: $isProducer
          isFoodPro: $isFoodPro
          isAdmin: $isAdmin
          emailVerified: $emailVerified
          phoneVerified: $phoneVerified
          acceptedTerms: $acceptedTerms
          blocked: $blocked
          status: $status
        ) {
          id
          email
          username
          phone
          password
          locale
          profileImage
          coverPhoto
          displayName
          unseenBadge {
            unseenNotifications
          }
          isProducer
          isFoodPro
          isAdmin
          emailVerified
          phoneVerified
          acceptedTerms
          blocked
          status
        }
      }
    `,
    updateProfile: gql`
      mutation(
        $profileImage: String,
        $firstName: String!,
        $lastName: String!,
        $username: String!,
        $title: String,
        $phone: String!,
        $email: String!
        $bio: String
      ) {
        updateProfile(
          profileImage: $profileImage,
          firstName: $firstName,
          lastName: $lastName,
          username: $username,
          title: $title,
          phone: $phone
          email: $email
          bio: $bio
        ) {
          ${userProfile}
        }
      }
    `,
    updateAddress: gql`
      mutation(
        $address1: String
        $address2: String
        $city: String
        $region: String
        $zip: String
        $country: String
      ) {
        updateAddress(
          address1: $address1
          address2: $address2
          city: $city
          region: $region
          zip: $zip
          country: $country
        ) {
          id
        }
      }
    `,
    updateCoverPhoto: gql`
      mutation($id: String!, $fileID: String!) {
        updateCoverPhoto(id: $id, fileID: $fileID)
      }
    `,
    updateProfileAddress: gql`
      mutation(
        $id: String!
        $address1: String
        $city: String
        $country: String
        $region: String
      ) {
        updateProfileAddress(
          id: $id
          address1: $address1
          city: $city
          country: $country
          region: $region
        ) {
          id
          address1
          city
          country
          region
        }
      }
    `,
    acceptTerms: gql`
      mutation {
        acceptTerms {
          id
          username
          displayName
          profileImage
          email
          phone
          unseenBadge {
            unseenNotifications
          }
          isAdmin
          acceptedTerms
          acceptedTermsAt
        }
      }
    `,
    resetTerms: gql`
    mutation($id: String!) {
      resetTerms(id: $id) {
        ${userProfile}
      }
    }
    `,
    resetAllTerms: gql`
      mutation {
        resetAllTerms {
          id
          acceptedTerms
        }
      }
    `,
    setUserBlock: gql`
      mutation($id: String!, $blocked: Boolean!) {
        setUserBlock(id: $id, blocked: $blocked) {
          ${userProfile}
        }
      }
    `,
    updateNotificationSettings: gql`
      mutation($pushSettings: Json) {
        updateNotificationSettings(input: { push: $pushSettings }) {
          id
          notificationSettings {
            push
          }
        }
      }
    `,
  },
}
