import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import NavigationActions from '../../../utility/navigationActions'

import Button from '../../../components/simple/Button'
import NamedImage from '../../../components/simple/NamedImage'

import sizes from '../../../config/sizes'
import colors from '../../../config/colors'
import styles from '../styles'

import _ from 'lodash'
import { graphql, connect } from '../../../config/connected'
import userSchema from '../../../schema/user'
import adminSchema from '../../../schema/admin'
import withActionSheet from '../../../containers/ActionSheet/withActionSheet'
import { openActionSheet } from '../../../config/helpers'

const mapDispatchToProps = (dispatch) => ({
  goToProfile: ({ id, pageTitle }) => {
    NavigationActions.navigate({
      routeName: 'Profile',
      params: { id, pageTitle },
    })
  },
  goToAliases: ({ id }) => {
    NavigationActions.navigate({
      routeName: 'AdminManageAliases',
      params: { entityId: id, entityTable: 'users' },
    })
  },
  goToFeed: () => {
    NavigationActions.navigate({
      routeName: 'Home',
    })
  },
  dispatch,
})

@connect(null, mapDispatchToProps)
@graphql(userSchema.mutations.setUserBlock, {
  name: 'setUserBlock',
})
@graphql(userSchema.mutations.resetAllTerms, {
  name: 'resetAllTerms',
})
@graphql(userSchema.mutations.resetTerms, {
  name: 'resetTerms',
})
@graphql(userSchema.queries.usersByUsername, {
  name: 'usersByUsername',
  options: {
    variables: {
      username: '',
    },
  },
})
@graphql(adminSchema.mutations.ghostToken, {
  name: 'ghostToken',
})
@withActionSheet
class UserListItem extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
    imageSize: PropTypes.number,
    showActionSheetWithOptions: PropTypes.func.isRequired,
    goToProfile: PropTypes.func.isRequired,
    goToAliases: PropTypes.func.isRequired,
    goToFeed: PropTypes.func.isRequired,
    setUserBlock: PropTypes.func.isRequired,
    resetTerms: PropTypes.func.isRequired,
    ghostToken: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    imageSize: 50,
  }

  setUserBlock = (user) => {
    this.props.setUserBlock({
      variables: { id: user.id, blocked: !user.blocked },
    })
  }

  resetTerms = (user) => {
    this.props.resetTerms({ variables: { id: user.id } })
  }

  loginAsUser = async (user) => {
    const { dispatch, ghostToken, goToFeed } = this.props
    const result = await ghostToken({
      variables: {
        id: user.id,
      },
    })
    const {
      ghostToken: { token, id: userId },
    } = result.data
    dispatch({ type: 'GHOST', token, userId })
    setTimeout(() => goToFeed(), 300)
  }

  render() {
    const {
      user,
      goToProfile,
      imageSize,
    } = this.props
    return (
      <View
        style={{
          paddingVertical: 6,
          width: '100%',
          borderColor: 'white',
          borderBottomColor: colors.emptyArea,
          borderWidth: sizes.px,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <TouchableOpacity
          style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}
          onPress={() =>
            goToProfile({
              id: user.id,
              pageTitle: 'ADMIN - ' + user.username,
            })
          }
        >
          <NamedImage
            style={{
              width: imageSize,
              height: imageSize,
              borderRadius: imageSize / 2,
            }}
            name={user.profileImage}
            width={50}
            height={50}
            resizeMode='cover'
          />
          <View style={styles.detailsContainer}>
            <Text>{user.username || user.displayName}</Text>
            <Text style={{ color: !user.blocked ? colors.green : colors.red }}>
              {!user.blocked ? 'Not Blocked' : 'Blocked'}
            </Text>
            <Text
              style={{ color: user.acceptedTerms ? colors.green : colors.red }}
            >
              {user.acceptedTerms ? 'Accepted Terms' : 'Not Accepted Terms'}
            </Text>
            <Text
              style={{
                color: user.phoneVerified ? colors.green : colors.black,
              }}
            >
              {user.phoneVerified ? 'Verified Phone' : 'Unverified Phone'}
            </Text>
            <Text
              style={{
                color: user.emailVerified ? colors.green : colors.black,
              }}
            >
              {user.emailVerified ? 'Verified Email' : 'Unverified Email'}
            </Text>
            {user.isProducer && <Text>Producer</Text>}
            {user.isFoodPro && <Text>Food Professional</Text>}
          </View>
        </TouchableOpacity>
        <View style={{ justifyContent: 'space-between' }}>
          <Button
            label='Actions'
            style={[
              styles.button,
              {
                // paddingLeft: 'auto',
                backgroundColor: colors.primary,
              },
            ]}
            onPress={this.openActions}
          />
        </View>
      </View>
    )
  }

  openActions = () => {
    const {
      user,
      goToAliases,
      showActionSheetWithOptions,
    } = this.props
    const optionsSpec = [
      { option: 'Login As', action: () => this.loginAsUser(user) },
      { option: user.blocked ? 'Unblock' : 'Block', action: () => this.setUserBlock(user) },
      { option: 'Reset Terms', action: () => this.resetTerms(user) },
      { option: 'Manage Aliases', action: () => goToAliases(user) },
      { option: 'Close', action: _.identity, cancelButtonIndex: true },
    ]
    openActionSheet(showActionSheetWithOptions, optionsSpec)
  }
}

export default UserListItem
