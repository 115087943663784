import React, { useCallback, useReducer, useEffect } from 'react'
import {
  ActivityIndicator,
  ActivityIndicatorComponent,
  FlatList,
  KeyboardAvoidingView,
  Platform,
  Text,
  View,
} from 'react-native'

import MobileBackButton from '../../components/simple/MobileBackButton'
import { Status } from '../../components/simple/Status'
import TextInput from '../../components/simple/TextInput'
import Order from './Order'
import NavigationActions from '../../utility/navigationActions'

import sharedStyles, { stylus } from '../../config/styles'
import colors from '../../config/colors'
import { connect, graphql } from '../../config/connected'
import schema from '../../schema/orders'
import { ROUTE_SHOP_ORDER_DETAILS, ROUTE_SHOP_ORDER_HISTORY, ROUTE_USER_ORDER_DETAILS } from '../../config/constants'
import { mergeNavOptionsForFeedLogo, routeForShop } from '../../config/helpers'
import i18n from 'i18n-js'
import PropTypes from 'prop-types'
import { compose, hoistStatics, withProps } from 'recompose'
import _ from 'lodash'
import { withApollo } from 'react-apollo'

function Item({ order, route, entity, goToOrderDetailsFunction, userId }) {
  const forShop = routeForShop(route, ROUTE_SHOP_ORDER_HISTORY)
  const entityObject = forShop ? order.user : order.shop
  const props = {
    key: order.id,
    style: styles.order,
    entity: forShop
      ? i18n.t('order.order.entity.customer')
      : i18n.t('order.order.entity.shop'),
    entityIdentifier: forShop ? entityObject.username : entityObject.displayName,
    entityImage: entityObject.profileImage || entityObject.coverPhoto,
    order,
    navigationAction: goToOrderDetailsFunction(order.id, userId, route),
    showOrderNumber: true,
  }
  return <Order key={order.id} {...props} />
}

function Header({ loading }) {
  return loading && <Status message={i18n.t('order.orderHistory.status.loading')}/>
}

const ITEMS_PER_PAGE = 5
const ITEMS_OFFSET = 0
const INITIAL_STATE = {
  text: null,
  searchResult: null,
  searchText: null,
  allLoaded: false,
  orderLimit: ITEMS_PER_PAGE
}
function reducer(state, action) {
  switch (action.type) {
    case 'search':
      return { ...state, text: action.text }
    case 'result':
      return { ...state, searchResult: action.searchResult }
    case 'reset':
      return INITIAL_STATE
    case 'allLoaded':
      return {...state, allLoaded: action.allLoaded}
    case 'updateOrderLimit':
      return { ...state, orderLimit: action.orderLimit }
    case 'lastPageReset':
      return { ...state, allLoaded: false, orderLimit: ITEMS_PER_PAGE }
    default:
      throw new Error()
  }
}

function OrderHistory(props) {
  const { entity, userOrdersHandler: handler, userId, routeName, userType } = props
  const [state, dispatch] = useReducer(
    reducer,
    INITIAL_STATE,
  )

  const orders = state.searchResult || handler.ordersByUserIdForUserType
  const search = useCallback(_.debounce(async (text) => {
    const { data: { ordersBySearchTextForUserType } } = await props.client.query({
      query: schema.queries.ordersBySearchTextForUserType,
      variables: {
        userId, userType, text,
        offset: ITEMS_OFFSET,
        limit: ITEMS_PER_PAGE,
      },
    })

    dispatch({ type: 'result', searchResult: ordersBySearchTextForUserType })

  }, 1000), [])

  useEffect(() => {
    const forShop = routeForShop(routeName, ROUTE_SHOP_ORDER_HISTORY)
    if (forShop) {
      dispatch({ type: 'lastPageReset' })
      handler.refetch()
    }
  }, [])

  return (
    <>
      <Text style={[styles.orderHistoryHeader, sharedStyles.h4]}>
        {i18n.t(`order.orderHistory.${entity}.title`)}
      </Text>
      <TextInput
        style={styles.searchInputContainer}
        inputStyle={styles.searchInput}
        value={state.searchText}
        placeholder={i18n.t('order.orderHistory.searchBy')}
        onClear={() => {
          dispatch({ type: 'reset' })
        }}
        onChange={(value) => {
          if (_.isEmpty(value)) {
            dispatch({ type: 'reset' })
            search.cancel()
          } else {
            dispatch({ type: 'search', text: value })
            search(value, state)
          }
        }}
      />
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        keyboardVerticalOffset={
          Platform.OS === 'android' ? undefined : 80
        }
        behavior={Platform.OS === 'android' ? undefined : 'padding'}
        contentContainerStyle={{ flex: 1 }}
      >

        <FlatList
          data={orders || []}
          refreshing={handler.networkStatus === 4}
          onRefresh={() => {
            if (_.isEmpty(state.searchResult)) {
              dispatch({ type: 'lastPageReset' })
              handler.refetch()
            }
          }}
          renderItem={({ item }) =>
            <Item
              order={item}
              route={routeName}
              entity={entity}
              userId={userId}
              goToOrderDetailsFunction={props.goToOrderDetailsFunction}
            />}
          keyExtractor={item => item.id}
          onEndReached={async () => {
            if (!state.allLoaded) {
              
              await handler.fetchMore({
                variables: {
                  offset: ITEMS_OFFSET,
                  limit: state.orderLimit + ITEMS_PER_PAGE
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  if (fetchMoreResult.ordersByUserIdForUserType.length < state.orderLimit) {
                    dispatch({ type: 'allLoaded', allLoaded: true })
                  }
                  dispatch({ type: 'updateOrderLimit', orderLimit: state.orderLimit + ITEMS_PER_PAGE })
                  return Object.assign({}, prev, {
                    ordersByUserIdForUserType: _.uniqBy([...prev.ordersByUserIdForUserType, ...fetchMoreResult.ordersByUserIdForUserType], 'id'),
                  })
                },
              })
            }
          }}
          ListHeaderComponent={<Header loading={handler.loading && _.isEmpty(orders)}/>}
          ListFooterComponent={<Footer loading={handler.loading && !_.isEmpty(orders)}/>}
        />
      </KeyboardAvoidingView>
    </>
  )

}

function Footer({ loading }) {
  return loading && <View>
    <ActivityIndicator style={{ marginVertical: 16 }} size="large" color="#000"/>
  </View>
}

OrderHistory.navigationOptions = (props) => {
  return mergeNavOptionsForFeedLogo({
    headerLeft: () => (
      <MobileBackButton
        label={i18n.t('common.back')}
        onPress={() => NavigationActions.back()}
      />
    ),
  })
}

OrderHistory.propTypes = {
  navigation: PropTypes.any,
  screenInfo: PropTypes.any,
  entity: PropTypes.any,
  userOrdersHandler: PropTypes.object,
  userId: PropTypes.string,
  routeName: PropTypes.string,
  userType: PropTypes.string,
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  goToOrderDetailsFunction: (orderId, userId, routeName) => {
    return () => {
      let orderDetailsRouteName = ROUTE_USER_ORDER_DETAILS
      if (routeForShop(routeName, ROUTE_SHOP_ORDER_HISTORY)) {
        orderDetailsRouteName = ROUTE_SHOP_ORDER_DETAILS
      }
      NavigationActions.navigate({
        routeName: orderDetailsRouteName,
        params: {
          userId: userId,
          id: orderId,
        },
      })
    }
  },
})
const mapStateToProps = (state) => ({
  userId: state.currentUser.id,
  screenInfo: state.screenInfo,
})


const enchanced = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApollo,
  withProps(({ navigation }) => {
    const routeName = navigation.state.routeName
    const userType = routeForShop(
      routeName,
      ROUTE_SHOP_ORDER_HISTORY,
    )
      ? 'producer'
      : 'user'
    const entity = routeForShop(routeName, ROUTE_SHOP_ORDER_HISTORY)
      ? 'shop'
      : 'customer'

    const userId = navigation.state.params.userId

    return { userType, entity, userId, routeName }
  }),
  graphql(schema.queries.ordersByUserIdForUserType, {
    name: 'userOrdersHandler',
    options({ navigation, userType }) {
      return {
        // fetchPolicy: 'cache-and-network',
        variables: { userId: navigation.state.params.userId, userType, limit: ITEMS_PER_PAGE, offset: ITEMS_OFFSET },
        notifyOnNetworkStatusChange: true,
      }
    },
  }),
)

export default hoistStatics(enchanced)(OrderHistory)

const styles = stylus({
  contentContainer: {
    flex: 1,
  },
  order: {
    borderBottomWidth: 2,
    borderBottomColor: colors.thinLine,
    paddingTop: 10,
    paddingBottom: 20,
    marginHorizontal: 10,
  },
  // searchInput: {
  //   flex: 1,
  //   color: colors.text.main
  // },
  // searchInputContainer: {
  //   backgroundColor: 'white',
  //   borderRadius: 6,
  //   borderColor: '#7f7f7f',
  //   borderWidth: 2,
  //   marginHorizontal: 10,
  //   marginBottom: 10,
  // },
  searchInputContainer: {
    backgroundColor: colors.input.backgroundFill,
    web: {
      width: '100%',
    },
    marginHorizontal: 10,
    marginBottom: 10,
  },
  searchInput: {
    flex: 1,
    height: 40,
    fontSize: 18,
    textAlign: 'left',
    color: colors.text.main,
  },
  orderHistoryHeader: {
    color: colors.text.main,
    textAlign: 'center',
    fontWeight: '600',
    marginVertical: 10,
  },
})
