import React from 'react'
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

import NavigationActions from '../../utility/navigationActions'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import shared, { stylus } from '../../config/styles'
import { connect, compose } from '../../config/connected'
import _ from 'lodash'
import moment from 'moment'
import Icons from '@expo/vector-icons/Ionicons'
import NamedImage from '../../components/simple/NamedImage'
import TextInput from '../../components/simple/TextInput'
import Comment from '../Comment'
import withCurrentUser from '../../containers/withCurrentUser'
import T from '../../components/T'

const MAX_PREVIEW_POSTS = 3

class PostComments extends React.Component {
  static propTypes = {
    showAdvHashtag: PropTypes.bool,
    // comments: PropTypes.arrayOf(PropTypes.object),
    navigation: PropTypes.any,
  }

  state = {
    value: '',
    placeholder: i18n.t('comment.inputPlaceholder'),
  }

  renderComments = (comments, firstComment) => {
    const routeName = _.get(this.props, 'navigation.state.routeName')
    const { goToProfile, showAdvHashtag } = this.props
    const onPostComments = (routeName || '').indexOf('PostComments') !== -1
    const onRecipeDetails = (routeName || '').indexOf('RecipeDetails') !== -1
    return _.map(comments, (comment, idx) => {
      return (
        <Comment
          expanded={onPostComments || onRecipeDetails}
          ghostText={showAdvHashtag && idx === 0 ? '#Adv' : null}
          key={comment.id}
          comment={comment}
          goToProfile={goToProfile}/>
      )
    })
  }

  maybeShowViewAll = () => {
    const { single, commentCount, goToComments, firstComment } = this.props
    const count = commentCount + (firstComment ? 1 : 0)
    if (single) {
      return null
    }
    if (commentCount > 1) {
      return (
        <TouchableOpacity
          style={styles.viewAllComments}
          onPress={() => goToComments({ scrollToComments: true })}
        >
          <T
            t='post.viewAllComments'
            values={{ commentCount: count }}
            style={styles.viewAllText}
          />
        </TouchableOpacity>
      )
    } else {
      return <View style={styles.viewAllVoid} />
    }
  }

  handleChange = (string) => {
    this.setState({
      value: string,
    })
  }

  getRightElement = () => {
    return (
      <TouchableOpacity style={{ paddingRight: 6 }} onPress={() => {}}>
        <Text style={styles.commentPostText}>
          <T t='comment.postButton' />
        </Text>
      </TouchableOpacity>
    )
  }

  onFocus = () => {}

  newComment() {
    const { style, single, currentUser, goToComments } = this.props
    const { value, placeholder } = this.state
    if (single) {
      return null
    }
    return (
      <View style={[styles.commentContainer, styles.marginVertical]}>
        <TouchableOpacity onPress={null}>
          <NamedImage
            name={_.get(currentUser, 'currentUser.profileImage')}
            loading={currentUser.loading}
            style={styles.commentImage}
            fit='crop'
            crop='faces'
            width={36}
            height={36}
            placeholder='profile'
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.fakeInput}
          onPress={() => goToComments({ autoFocus: true })}
          activeOpacity={0.8}
        >
          <T t='comment.inputPlaceholder' style={styles.fakeInputPlaceholder} />
        </TouchableOpacity>
      </View>
    )
  }

  render() {
    const { comments, firstComment, style, ...rest } = this.props
    const allComments = comments.loading
      ? []
      : comments.comments
        ? [...comments.comments]
        : []
    if (firstComment) {
      allComments.unshift(firstComment)
    }
    if (comments.loading) {
      return <ActivityIndicator size='large' />
    }
    return (
      <View style={[styles.container, style]} {...rest}>
        {this.renderComments(allComments)}
        {this.maybeShowViewAll()}
        {this.newComment()}
      </View>
    )
  }
}

const styles = stylus({
  container: {
    // marginTop: 4,
    paddingBottom: 1,
  },
  commentImage: {
    width: 32,
    height: 32,
    borderRadius: 16,
    marginRight: 11,
    marginLeft: 12,
  },
  commentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 16,
  },
  commentInputInner: {
    height: 36,
    paddingTop: 0,
    paddingBottom: 0,
    // paddingLeft: 0
  },
  commentText: {
    fontSize: 15,
    color: '#BBB',
  },
  viewAllComments: {
    paddingLeft: 55,
    paddingBottom: 16,
    paddingTop: 7,
  },
  viewAllText: {
    fontSize: 14,
    color: colors.text.secondary,
  },
  viewAllVoid: {
    height: 9,
  },
  commentPostText: {
    color: colors.primary,
    fontWeight: '500',
  },
  fakeInput: {
    height: 36,
    flex: 1,
    borderRadius: 18,
    borderColor: colors.thinLine,
    borderWidth: sizes.px,
    justifyContent: 'center',
    paddingHorizontal: 14,
  },
  fakeInputPlaceholder: {
    color: colors.input.placeholder,
    fontSize: 15,
  },
})

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  goToProfile: ({ id, pageTitle }) => {
    NavigationActions.navigate({
      routeName: 'Profile',
      params: { id, pageTitle },
    })
  },
})

export default compose(
  withCurrentUser,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PostComments)
