import _ from 'lodash'

const initialState = {
  open: false,
  recordId: null,
  recordType: null,
}

export default function reactionPicker(state, action) {
  if (!state) {
    return initialState
  }
  let index, newState
  switch (action.type) {
    case 'ReactionPicker/OPEN':
      return {
        ...state,
        open: true,
        recordId: action.recordId,
        recordType: action.recordType,
      }
    case 'ReactionPicker/CLOSE':
      return initialState
    default:
      return state
  }
}
