import admin from './en-US/admin'
import categories from './en-US/categories'
import comment from './en-US/comment'
import common from './en-US/common'
import coupon from './en-US/coupon'
import deliveryLocations from './en-US/deliveryLocations'
import errors from './en-US/errors'
import feed from './en-US/feed'
import followers from './en-US/followers'
import legal from './en-US/legal'
import nav from './en-US/nav'
import notifications from './en-US/notifications'
import onboardingShop from './en-US/onboardingShop'
import onboardingUser from './en-US/onboardingUser'
import order from './en-US/order'
import payment from './en-US/payment'
import post from './en-US/post'
import product from './en-US/product'
import profile from './en-US/profile'
import publishContent from './en-US/publishContent'
import recipe from './en-US/recipe'
import shop from './en-US/shop'
import address from './en-US/address'
import search from './en-US/search'
import services from './en-US/services'
import settings from './en-US/settings'
import sharing from './en-US/sharing'
import tagging from './en-US/tagging'
import upload from './en-US/upload'
import notice from './en-US/notice'

const messages = {
  ...admin,
  ...categories,
  ...comment,
  ...common,
  ...coupon,
  ...deliveryLocations,
  ...errors,
  ...feed,
  ...followers,
  ...legal,
  ...nav,
  ...notifications,
  ...onboardingShop,
  ...onboardingUser,
  ...order,
  ...address,
  ...payment,
  ...post,
  ...product,
  ...profile,
  ...publishContent,
  ...recipe,
  ...shop,
  ...search,
  ...services,
  ...settings,
  ...tagging,
  ...upload,
  ...sharing,
  ...notice,
}

export default messages
