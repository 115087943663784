import { StyleSheet, Dimensions, Platform } from 'react-native'
import Constants from 'expo-constants'
const PHONE_WIDTH = 600
const { width: WIDTH, height: HEIGHT } = Dimensions.get('window')
const LAYOUT = WIDTH < PHONE_WIDTH ? 'portrait' : 'landscape'
const PLATFORM = Platform.OS
let is_iphonex = false
if (PLATFORM === 'ios') {
  if (WIDTH === 375 && HEIGHT === 812) {
    is_iphonex = true
  } else if (WIDTH === 414 && HEIGHT === 896) {
    is_iphonex = true
  }
}

// console.log({PLATFORM, LAYOUT, WIDTH})

import colors from './colors'
import sizes from './sizes'
import _ from 'lodash'

export const select = (styles, name, modifier) => {
  return styles[`name${_.capitalize(modifier)}`]
}

const layoutSelector = (styles) => {
  return _.mapValues(styles, (item) => {
    let results = item
    const portrait = item.portrait
    if (portrait && LAYOUT === 'portrait') {
      results = { ...item, ...portrait }
    }
    delete results.portrait
    const landscape = item.landscape
    if (landscape && LAYOUT === 'landscape') {
      results = { ...results, ...landscape }
    }
    delete results.landscape
    return results
  })
}

const platformSelector = (styles) => {
  return _.mapValues(styles, (item) => {
    let results = item
    const web = item.web
    if (web && PLATFORM === 'web') {
      results = { ...results, ...web }
    }
    delete results.web
    const native = item.native
    if (native && (PLATFORM === 'ios' || PLATFORM === 'android')) {
      results = { ...results, ...native }
    }
    delete results.native
    const expo = item.expo
    if (expo && Constants.appOwnership === 'expo') {
      results = { ...results, ...expo }
    }
    delete results.expo
    const ios = item.ios
    if (ios && PLATFORM === 'ios') {
      results = { ...results, ...ios }
    }
    delete results.ios
    const android = item.android
    if (android && PLATFORM === 'android') {
      results = { ...results, ...android }
    }
    delete results.android
    return results
  })
}

const comboSelector = (styles) => {
  return _.mapValues(styles, (item) => {
    let results = item
    const tablet = item.tablet
    if (tablet && PLATFORM !== 'web' && LAYOUT === 'landscape') {
      results = { ...results, ...tablet }
    }
    delete results.tablet

    const desktop = item.desktop
    if (desktop && (PLATFORM === 'web' && LAYOUT === 'landscape')) {
      results = { ...results, ...desktop }
    }
    delete results.desktop

    const ipad = item.ipad
    if (ipad && PLATFORM === 'ios' && LAYOUT === 'landscape') {
      results = { ...results, ...ipad }
    }
    delete results.ipad

    const mobileWeb = item.mobileWeb
    if (mobileWeb && PLATFORM === 'web' && LAYOUT === 'portrait') {
      results = { ...results, ...mobileWeb }
    }
    delete results.mobileWeb

    const iphonex = item.iphonex
    if (iphonex && is_iphonex) {
      results = { ...results, ...iphonex }
    }
    delete results.iphonex

    return results
  })
}

export const stylus = (styles) => {
  return comboSelector(platformSelector(layoutSelector(styles)))
}

export default stylus({
  card: {
    backgroundColor: colors.card.background,
    borderWidth: sizes.px,
    borderColor: colors.card.border,
    borderRadius: 6,
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 3 },
    shadowOpacity: 0.05,
    shadowRadius: 10,
  },
  actionLabel: {
    fontSize: 17,
    color: colors.primaryContrast,
    fontWeight: '500',
  },
  screenPadding: {
    paddingHorizontal: 24,
    portrait: {
      paddingHorizontal: 12,
    },
  },
  separator: {
    height: 6,
    marginVertical: 15,
    borderBottomColor: colors.thinLine,
    borderBottomWidth: sizes.px,
  },
  h1: { fontSize: 40 },
  h2: { fontSize: 34 },
  h3: { fontSize: 28 },
  h4: { fontSize: 22 },
  h5: { fontSize: 18 },
  h6: { fontSize: 16 },
  // Following styles are for screens that don't have a header, where android needs no padding top for StatusBar
  tabbedScreen: {
    web: {
      paddingTop: 15,
    },
    ios: {
      paddingTop: sizes.iosStatusBarHeight,
    },
    iphonex: {
      paddingTop: sizes.TOP_BAR_HEIGHT,
      paddingBottom: sizes.iphonexTabBarHeight,
    },
    paddingBottom: sizes.tabBarHeight,
  },
  noTabScreen: {
    web: {
      paddingTop: 15,
    },
    ios: {
      paddingTop: sizes.iosStatusBarHeight,
    },
    iphonex: {
      paddingTop: sizes.TOP_BAR_HEIGHT,
    },
  },
  footer: {
    iphonex: {
      paddingBottom: 35,
    },
  },
})
