import Notifications from '../../screens/Notifications'
import Shop from '../../screens/Shop'
import Product from '../../screens/Product'
import PostCommentsRoute from '../../screens/PostCommentsRoute'
import RecipeDetailsFB from '../../screens/RecipeDetailsFB/RecipeDetailsFB'
import ConversationPortrait from '../../screens/ConversationPortrait'
import Profile from '../../screens/Profile'
import OrderHistory from '../../screens/OrderHistory'
import OrderDetails from '../../screens/OrderDetails'
import ShopReviewFeedback from '../../screens/Admin/ShopReviewFeedback'
import Dashboard from '../../admin/Dashboard/Dashboard'

import {
  ROUTE_SHOP_ORDER_HISTORY,
  ROUTE_USER_ORDER_DETAILS,
  ROUTE_SHOP_ORDER_DETAILS,
} from '../../config/constants'

export const notificationRoutes = {
  Notifications: {
    screen: Notifications,
    path: '',
    
  },
  NotificationScreenShop: {
    screen: Shop,
    path: 'shop/:id',
    
  },
  NotificationScreenProduct: {
    screen: Product,
    path: 'conversations/:id',
    
  },
  NotificationScreenPostComments: {
    screen: PostCommentsRoute,
    path: 'post/:id/comments',
    
  },
  NotificationScreenRecipeDetails: {
    screen: RecipeDetailsFB,
    path: 'recipe/:id',
    
  },
  NotificationScreenConversation: {
    screen: ConversationPortrait,
    path: 'conversations/:id',
    
  },
  NotificationScreenProfile: {
    screen: Profile,
    path: 'profile/:id',
    
  },
  //NOTE: MyProfile does not need a new route
  [`NotificationScreen${ROUTE_SHOP_ORDER_DETAILS}`]: {
    screen: OrderDetails,
    path: 'shops/:userId/orders-details/:id',
    
  },
  [`NotificationScreen${ROUTE_SHOP_ORDER_HISTORY}`]: {
    screen: OrderHistory,
    path: 'shops/:userId/order-history',
    
  },
  [`NotificationScreen${ROUTE_USER_ORDER_DETAILS}`]: {
    screen: OrderDetails,
    path: 'users/:userId/orders-details/:id',
    
  },
  NotificationScreenShopReviewFeedback:{
    screen: ShopReviewFeedback,
    path: 'ShopReviewFeedback/:id',
    
  },
  NotificationScreenAdminDashboard: {
    screen: Dashboard,
    path: 'admin/dashboard',
    
  },
}
export let notificationRouteNames = Object.keys(notificationRoutes)
