import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from 'react-native'
import i18n from 'i18n-js'

import Settings from './Settings'

class SettingsLandscape extends React.Component {
  render() {
    return <Settings />
  }
}

SettingsLandscape.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('settings.header'),
  }
}

export default SettingsLandscape
