import React, { Component } from 'react'
import {
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Animated,
  KeyboardAvoidingView,
  Keyboard,
  Platform,
  BackHandler,
  FlatList
} from 'react-native'
import i18n from 'i18n-js'
import PropTypes from 'prop-types'
import NamedImage from '../simple/NamedImage'
import Button from '../simple/Button'
import ElegantInput from '../simple/ElegantInput'
import T from '../../components/T'
import _ from 'lodash'
import user from '../../schema/user'
import product from '../../schema/product'
import { compose, gql, graphql, connect } from '../../config/connected'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'
import Icons from '@expo/vector-icons/Ionicons'
import Divider from '../../components/simple/Divider'
import branch from '../../config/branch'
import { _get } from '../../config/connected'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'
import { getKeyboardPaddingStyle, resolveVideoThumbnail } from '../../config/helpers'
import moment from 'moment'

export const TaggingContext = React.createContext()

const INVALID_CHARACTERS = [' ', '@', '[', ']']
const INITIAL_TAG_TYPES = { shopProducts: true, users: true, products: false }

const withTagging = (Wrapped) => {
  @connect((state) => ({ screenInfo: state.screenInfo }))
  @graphql(user.queries.usersByUsername, {
    name: 'usersByUsername',
    options: {
      variables: {
        username: '$$$',
      },
    },
  })
  @graphql(product.queries.productsByUserId, {
    name: 'productsByUserId',
    options: {
      variables: {
        userId: 'cf4876d8-221b-4472-bcdb-685117403285',
      },
    },
  })
  @graphql(product.queries.productsByName, {
    name: 'productsByName',
    options: {
      variables: {
        productName: 'cf4876d8-221b-4472-bcdb-685117403285',
      },
    },
  })
  @WithKeyboardInfo
  class withTagging extends Component {

    static displayName = 'withTagging'

    state = {
      tagTypes: INITIAL_TAG_TYPES,
      tagging: false,
      searchString: '',
      insertString: '',
      opacity: new Animated.Value(0),
      bottomPadding: new Animated.Value(0),
    }
    inputRef = React.createRef()

    componentDidMount() {
      this.keyboardDidShowListener = Keyboard.addListener(
        'keyboardWillShow',
        this._keyboardDidShow,
      )
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardWillHide',
        this._keyboardDidHide,
      )
      if (Platform.OS === 'android') {
        this.watchBackButton()
      }
    }

    componentWillUnmount() {
      this.keyboardDidShowListener.remove()
      this.keyboardDidHideListener.remove()
      if (Platform.OS === 'android') {
        this.backHandler.remove()
      }
    }

    watchBackButton() {
      this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
        const {tagging} = this.state
        if (tagging) {
          this.cancelTagging()
          return true
        }
        return false
      })
    }

    _keyboardDidHide = () => {
      Animated.timing(this.state.bottomPadding, {
        duration: 250,
        toValue: 40,
      }).start()
    }

    _keyboardDidShow = () => {
      Animated.timing(this.state.bottomPadding, {
        duration: 250,
        toValue: 0,
      }).start()
    }

    refetchUsers = _.debounce((variables) => this.queryRequest('usersByUsername', variables), 200).bind(this)
    refetchProducts = _.debounce((variables) => this.queryRequest('productsByUserId', variables), 200).bind(this)
    refetchProductsByName = _.debounce((variables) => this.queryRequest('productsByName', variables), 200).bind(this)

    queryRequest = async (queryHandlerName, variables) => {
      const result = await this.props[queryHandlerName].refetch(variables)
      this.props[queryHandlerName].updateQuery((previousResult, { fetchMoreResult, queryVariables}) => ({
        [queryHandlerName]: _.get(result, `data.${queryHandlerName}`, [])
      }))
    }

    taggingHook = (callback, tagTypes = {}) => {
      this.finishCallback = callback
      this.setState(
        prev => ({ tagging: true, tagTypes: {...prev.tagTypes, ...tagTypes}}),
        () => this.inputRef.current.focus()
      )
      this.setVisibility(1)
    }

    setTargetRef = (targetRef) => {
      this.targetRef = targetRef
    }

    finishTagging = () => {
      this.handleAddTag()
      this.setState({
        tagTypes: INITIAL_TAG_TYPES,
        tagging: false,
        activeUser: undefined,
        activeProduct: undefined,
        searchString: '',
        insertString: '',
      })
      this.listRef.scrollTo({ x: 0, y: 0, animated: false })
      this.inputRef.current.blur()
    }

    handleSearchChange = (value) => {
      this.setState({ searchString: value }, () => {
        const {searchString, tagTypes} = this.state
        if (tagTypes.products) {
          searchString.length > 2 && this.refetchProductsByName({ productName: searchString })
        } else {
          this.refetchUsers({ username: searchString, filterForTagging: true })
        }
      })
    }

    handleAddTag = () => {
      this.finishCallback({
        value: this.state.insertString + ' ', // TODO: Work around for special characters interrupting tags.
        userId: this.state.activeUser,
        productId: this.state.activeProduct,
        createdAt: moment().utc(false).toISOString(),
      })
    }

    cancelTagging = () => {
      const {showProducts} = this.state
      if (showProducts) {
        this.listRef.scrollTo({ x: 0, animated: true })
        this.setState({showProducts: false, tagTypes: INITIAL_TAG_TYPES})
      } else {
        this.setVisibility(0, () => {
          this.inputRef.current.blur()
          this.setState(
            {
              tagTypes: INITIAL_TAG_TYPES,
              tagging: false,
              activeUser: undefined,
              activeProduct: undefined,
              searchString: '',
              insertString: '',
            },
            () => {
              this.inputRef.current.clear()
            },
          )
          this.listRef.scrollTo({ x: 0, y: 0, animated: false })
        })
      }
    }

    handleKeypress = ({ nativeEvent: { key } }) => {
      return
      _.forEach(INVALID_CHARACTERS, (char) => {
        if (key.includes(char)) {
          this.cancelTagging()
        }
      })
    }

    getUserString = (userString) => {
      if (typeof userString !== 'string') {
        console.log(
          'Invalid Value passed, expected type string, got type ' +
            typeof userString,
        )
        return ''
      }
      return userString //.replace(/\s/g, '_')
    }

    toggleUser = (idx, userString, isShop) => {
      const tagUserString = this.getUserString(userString)

      if (this.state.activeUser === idx) {
        if (isShop) {
          this.setState(
            {
              activeUser: idx,
              insertString: '#' + tagUserString,
            },
            () => {
              this.setVisibility(0, () => this.finishTagging())
            },
          )
        } else {
          this.setState({ activeUser: 0, insertString: '' })
        }
      } else {
        this.setState({
          activeUser: idx,
          insertString: isShop ? '#' + tagUserString : '@' + tagUserString,
        })
      }

      this.refetchProducts({ userId: idx })
    }

    directTagShop = (idx, userString) => {
      const username = this.getUserString(userString)
      this.setState(
        {
          activeUser: idx,
          insertString: `@[${username}]`,
        },
        () => {
          this.setVisibility(0, () => this.finishTagging())
        },
      )
    }

    directTagUser = (idx, userString, isShop) => {
      const username = this.getUserString(userString)
      this.setState(
        {
          activeUser: idx,
          insertString: isShop ? '#' + username : '@' + username,
        },
        () => {
          this.setVisibility(0, () => this.finishTagging())
        },
      )
    }

    directTagProduct = (idx, productString) => {
      const { activeUser } = this.state
      const {
        usersByUsername: { usersByUsername },
      } = this.props

      const shopname = usersByUsername.find((item) => {
        if (item.id === activeUser) {
          return true
        }
      }).displayName
      const tagName = this.getUserString(shopname)

      this.setState(
        {
          activeProduct: idx,
          insertString: `@[${tagName} | ${productString}]`,
        },
        () => {
          this.setVisibility(0, () => {
            this.finishTagging()
          })
        },
      )
    }

    toggleProduct = (idx, productString) => {
      const { searchString, activeUser } = this.state

      const {
        usersByUsername: { usersByUsername },
      } = this.props

      const shopname = usersByUsername.find((item) => {
        if (item.id === activeUser) {
          return true
        }
      }).displayName

      const tagName = this.getUserString(shopname)

      if (!searchString.includes(productString)) {
        if (this.state.activeProduct === idx) {
          this.setState({
            activeProduct: 0,
            insertString: '',
            activeUser: 0,
          })
          this.listRef.scrollTo({ x: 0, y: 0 })
        } else {
          this.setState({
            activeProduct: idx,
            insertString: `#${tagName}[${productString}]`,
          })
        }
      }
    }

    tagProduct = (item) => {
      this.finishCallback(item)
      this.setVisibility(0, () => {
        this.setState({
          tagTypes: INITIAL_TAG_TYPES,
          tagging: false,
          activeUser: undefined,
          activeProduct: undefined,
          searchString: '',
          insertString: '',
        })
        this.listRef.scrollTo({ x: 0, y: 0, animated: false })
        this.inputRef.current.blur()
        this.props.productsByName.updateQuery(() => ({
          productsByName: []
        }))
      })
    }

    scrollToProducts = (idx, userString) => {
      const username = this.getUserString(userString)
      this.refetchProducts({ userId: idx })
      this.setState({
        showProducts: true,
        activeUser: idx,
        insertString: '#' + username,
      })
      this.listRef.scrollToEnd()
    }

    renderUsers = () => {
      const {
        usersByUsername: { usersByUsername },
      } = this.props

      const { tagTypes } = this.state

      if (!usersByUsername || !usersByUsername.length) {
        return (
          <View>
            <T t='tagging.searchUsers' />
          </View>
        )
      }
      const users = [...usersByUsername]
      const newUsers = _.map(users, item => {
        const { profileImage, coverPhoto } = item
        const isVideoCover = ['.mp4', '.mov'].some(indicator => _.invoke(profileImage, 'includes', indicator))
        const newProfileImage = isVideoCover ? resolveVideoThumbnail(profileImage) : profileImage || coverPhoto
        return { ...item, profileImage: newProfileImage }
      })
      return _.map(newUsers, (item) => {
        const isShop = !item.username
        return (
          <TaggingItem
            canTagProducts={tagTypes.shopProducts}
            isShop={isShop}
            item={item}
            onSelectShop={this.directTagShop}
            onSelectUser={this.directTagUser}
            onGoToProducts={this.scrollToProducts}
            key={item.id}
          />
        )
      })
    }

    renderShopProducts = () => {
      const { insertString } = this.state
      const {
        productsByUserId: { productsByUserId },
        usersByUsername: { usersByUsername },
      } = this.props

      const shop =
        usersByUsername &&
        usersByUsername.find((user) => {
          if (user.id === this.state.activeUser) {
            return true
          }
        })

      if (!productsByUserId || !productsByUserId.length) {
        return null
      }

      return _.compact(
        _.map(productsByUserId, (item) => {
          if (item.isDiscontinued) {
            return null
          }
          return <Product
            backgroundColor={_.includes(insertString, item.name)
              ? colors.active
              : null}
            item={item}
            shopName={shop ? shop.displayName : null}
            onPress={() => {
              this.directTagProduct(item.id, item.name)
            }}
          />

        }),
      )
    }

    setVisibility = (toValue = 1, callback) => {
      Animated.timing(this.state.opacity, {
        duration: 250,
        toValue,
      }).start(() => {
        callback && callback()
      })
    }

    renderInput = () => {
      const OS = Platform.OS
      const {tagTypes} = this.state
      let label = tagTypes.products ? i18n.t('tagging.searchProducts') : i18n.t('tagging.search')
      if (OS === 'android') {
        return (
          <View style={[styles.input, { width: '100%' }]}>
            <KeyboardAvoidingView behavior='padding'>
              <ElegantInput
                style={[
                  styles.input,
                  {
                    // bottom: 0,
                    paddingBottom: this.state.bottomPadding,
                    // justifySelf: 'flex-end',
                    // position: 'absolute',
                    // height: 20,
                  },
                ]}
                label={label}
                value={this.state.searchString}
                onChange={this.handleSearchChange}
                reference={this.inputRef}
                onKeyPress={this.handleKeypress}
                onSubmitEditing={() => {}}
                disabled={!this.state.tagging}
              />
              {/* {!!this.state.bottomPadding && (
                <Animated.View style={{ height: this.state.bottomPadding }} />
              )} */}
            </KeyboardAvoidingView>
          </View>
        )
      } else {
        return (
          <KeyboardAvoidingView behavior='padding' style={styles.input}>
            <ElegantInput
              style={[
                styles.input,
                {
                  // bottom: 0,
                  paddingBottom: this.state.bottomPadding,
                  // justifySelf: 'flex-end',
                  // position: 'absolute',
                  // height: 20,
                },
              ]}
              label={label}
              value={this.state.searchString}
              onChange={this.handleSearchChange}
              reference={this.inputRef}
              onKeyPress={this.handleKeypress}
              onSubmitEditing={() => {}}
              disabled={!this.state.tagging}
            />
            {/* {!!this.state.bottomPadding && (
              <Animated.View style={{ height: this.state.bottomPadding }} />
            )} */}
          </KeyboardAvoidingView>
        )
      }
    }
    renderProductItem = ({item}) => {
      if (item.isDiscontinued) {
        return null
      }
      return <Product
        backgroundColor={null}
        item={item}
        shopName={_get(item, 'profile.displayName')}
        onPress={() => this.tagProduct(item)}
      />
    }
    keyExtractor = (item) => item.id
    renderProducts = () => {
      const {
        productsByName: {productsByName},
      } = this.props
      if (!productsByName || !productsByName.length) {
        return null
      }
      return (
        <FlatList
          data={productsByName}
          keyExtractor={this.keyExtractor}
          keyboardShouldPersistTaps='handled'
          renderItem={this.renderProductItem}
        />
      )
    }

    render() {
      const {
        screenInfo,
        productsByUserId,
        usersByUsername,
        keyboardInfo,
        ...rest
      } = this.props
      const { tagTypes={} } = this.state
      const itemWidth = screenInfo.contentWidth - 20
      const OS = Platform.OS
      const keyboardPaddingStyle = getKeyboardPaddingStyle(keyboardInfo)
      return (
        <>
          <TaggingContext.Provider value={{ taggingHook: this.taggingHook }}>
            <Wrapped taggingHook={this.taggingHook} {...rest} />
          </TaggingContext.Provider>

          <Animated.View
            pointerEvents={this.state.tagging ? 'auto' : 'none'} // Bug in current version of react native that causes
            // absolutely positiioned elements and display none on android to not actually cause the view to not render.
            style={[
              styles.container,
              {
                opacity: this.state.opacity,
              },
            ]}
          >
            <View style={styles.taggingHeader}>
              <TouchableOpacity onPress={this.cancelTagging}>
                <Icons
                  name={branch({
                    android: 'md-arrow-back',
                    ios: 'ios-arrow-back',
                    web: 'ios-arrow-back',
                  })}
                  size={branch({
                    android: 37,
                    ios: 30,
                    web: 30,
                  })}
                />
              </TouchableOpacity>
            </View>
            {this.renderInput()}
            <ScrollView
              style={{
                width: '100%',
                flexGrow: 1,
                // backgroundColor: 'purple',
              }}
              horizontal
              pagingEnabled
              keyboardShouldPersistTaps='handled'
              ref={(ref) => (this.listRef = ref)}
            >
              {tagTypes.products ?
                (
                  this.renderProducts()
                ) : (
                  <>
                  <ScrollView
                    keyboardShouldPersistTaps='handled'
                    style={{
                      width: itemWidth,
                      marginVertical: 10,
                    }}
                  >
                    {this.renderUsers()}
                  </ScrollView>
                  <ScrollView
                    style={{ width: itemWidth, marginVertical: 10 }}
                    keyboardShouldPersistTaps='handled'
                  >
                    {this.renderShopProducts()}
                  </ScrollView>
                  </>
                )
              }
            </ScrollView>
            <View style={[keyboardPaddingStyle]}/>
          </Animated.View>
        </>
      )
    }
  }
  return withTagging
}

export default withTagging

@graphql(product.queries.productsByUserId, {
  name: 'productsByUserId',
  skip: (props) => !props.isShop,
  options: (props) => {
    return {
      variables: {
        userId: props.item.id,
      },
    }
  },
})
class TaggingItem extends Component {
  onGoToProducts = () => {
    const { item } = this.props
    this.props.onGoToProducts && this.props.onGoToProducts(item.id, item.displayName)
  }

  onSelectUser = (idx, userString) => {
    this.props.onSelectUser && this.props.onSelectUser(idx, userString)
  }

  onSelectShop = (idx, userString) => {
    this.props.onSelectShop && this.props.onSelectShop(idx, userString)
  }

  onItemSelect = () => {
    const { item, isShop, productsByUserId = false } = this.props
    if (isShop) {
      this.onSelectShop(
        item.id,
        isShop ? item.displayName : item.username,
      )
    } else {
      this.onSelectUser(
        item.id,
        isShop ? item.displayName : item.username,
        isShop,
      )
    }
  }

  render() {
    const { item, isShop, productsByUserId = false, canTagProducts = true } = this.props

    let allProductsDiscontinued = []
    _.forEach(_.get(productsByUserId, 'productsByUserId', []), (item) => {
      allProductsDiscontinued.push(item.isDiscontinued)
    })
    allProductsDiscontinued = allProductsDiscontinued.every((value) => value)

    const hasProducts = !!(
      productsByUserId &&
      productsByUserId.productsByUserId &&
      productsByUserId.productsByUserId.length &&
      productsByUserId.productsByUserId &&
      !allProductsDiscontinued
    ) // Causes invarient violation / text outside of text element if not cast to bool

    return (
      <TouchableOpacity
        key={item.id}
        style={{
          flexDirection: 'row',
          paddingVertical: 5,
        }}
        onPress={this.onItemSelect}
      >
        <View
          style={{
            borderRadius: 999,
            marginHorizontal: 5,
            overflow: 'hidden',
            borderWidth: 1,
            borderColor: isShop ? colors.primary : 'transparent',
            width: 42,
            height: 42,
          }}
        >
          <View
            style={{
              flex: 1,
              margin: 2,
              borderRadius: 999,
              // width: 40,
              // height: 40,
              overflow: 'hidden',
            }}
          >
            <NamedImage
              name={item.profileImage}
              style={{
                flex: 1,
              }}
              placeholder='profile'
            />
          </View>
        </View>
        <View>
          <Text
            style={{
              color: isShop ? colors.primary : colors.text.main,
            }}
          >
            {item.displayName}
          </Text>
          {isShop && (
            <View style={styles.buttonsContainer}>
              <Button
                label={i18n.t('tagging.tagShop')}
                labelStyle={styles.buttonLabel}
                style={[styles.button, {marginRight: 5}]}
                onPress={this.onItemSelect}
              />
              {canTagProducts && hasProducts && (
                <Button
                  label={i18n.t('tagging.tagProducts')}
                  labelStyle={styles.buttonLabel}
                  style={[styles.button]}
                  onPress={this.onGoToProducts}
                />
              )}
            </View>
          )}
          {item.username && (
            <Text
              style={{
                color: colors.text.light,
                fontSize: 14,
              }}
            >
              @{item.username}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    )
  }
}

function Product ({item, backgroundColor, onPress, shopName}) {

  return (
    <TouchableOpacity
      key={item.id}
      style={{
        flexDirection: 'row',
        paddingVertical: 5,
        backgroundColor,
      }}
      onPress={onPress}
    >
      <View
        style={{
          width: 40,
          height: 40,
          borderRadius: 40,
          marginHorizontal: 5,
          overflow: 'hidden',
        }}
        onPress={onPress}
      >
        <NamedImage
          name={_get(item, 'uploads[0].name', '')}
          style={{
            flex: 1,
          }}
        />
      </View>
      <View>
        <Text>{item.name}</Text>
        {shopName && (
          <Text
            style={{
              color: colors.text.light,
              fontSize: 14,
            }}
          >
            {shopName}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  )
}

Product.propTypes = {
  item: PropTypes.object,
  backgroundColor: PropTypes.string,
  shopName: PropTypes.string,
  onPress: PropTypes.func,
}

const styles = stylus({
  container: {
    // flex: 1,
    paddingHorizontal: 10,
    paddingTop: 0,
    android: { paddingTop: 18 },
    ios: { paddingTop: 0 },
    iphonex: { paddingTop: sizes.TOP_BAR_HEIGHT },
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: colors.mainBackground,
    // backgroundColor: 'red',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 10,
  },
  taggingHeader: {
    width: '100%',
    ios: { paddingLeft: 4, marginTop: 27 },
    iphonex: { paddingLeft: 4, marginTop: -4 },
    android: { paddingLeft: 9, marginTop: -7 },
    web: { paddingLeft: 0, marginTop: 0 },
  },
  input: {
    backgroundColor: 'white',
    // bottom: 0, position: 'absolute', marginTop: 50
  },
  buttonsContainer: {
    flexDirection: 'row',
    marginTop: 4
  },
  button: {
    alignSelf: 'center',
    backgroundColor: colors.primary,
    padding: 4,
    width: 85
  },
  buttonLabel: {
    fontSize: 10,
    color: 'white',
    fontWeight: '600',
  },
})
