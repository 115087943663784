/* eslint-disable indent */
import React, { useRef } from 'react'
import { Picker, Platform, Text, View } from 'react-native'
import ElegantInput from '../simple/ElegantInput'
import { stylus } from '../../config/styles'
import { useFormik, withFormik } from 'formik'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import { compose, hoistStatics } from 'recompose'
import Button from '../simple/Button'
import colors from '../../config/colors'
import _ from 'lodash'
import T from '../T'
import MyPicker from '../simple/Picker'
import Switch from '../simple/Switch'
import countries from '../../config/countries'
import branch from '../../config/branch'
import guessCountry from '../../utility/guessCountry'
import { AddressSchema } from '../../validation'
import { getCountryFromPhone } from '../../config/helpers'

const placeholdersPath = 'onboardingUser.address'

const AddressForm = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleBlur,
  handleSubmit,
  forceValidate,
  submitCount,
  isValid,
}) => {

  const refs = useRef(new Map())
  const thumbColor = branch({
    android: colors.primary,
    ios: null,
    web: 'white'
  })

  return (
    <View>
      <ElegantInput
        key={'firstName'}
        style={styles.input}
        label={i18n.t('common.firstName')}
        returnKeyType='next'
        onChange={handleChange('firstName')}
        onBlur={handleBlur('firstName')}
        blurOnSubmit={false}
        value={values['firstName']}
        error={touched['firstName'] && errors['firstName']}
        autoCorrect={false}
        onSubmitEditing={() => _.invoke(refs.current.get('lastName'), 'focus')}
      />
      <ElegantInput
        key={'lastName'}
        reference={ref => refs.current.set('lastName', ref)}
        style={styles.input}
        label={i18n.t('common.lastName')}
        returnKeyType='next'
        onChange={handleChange('lastName')}
        onBlur={handleBlur('lastName')}
        blurOnSubmit={false}
        value={values['lastName']}
        error={(touched['lastName'] || forceValidate) && errors['lastName']}
        autoCorrect={false}
        onSubmitEditing={() => _.invoke(refs.current.get('address1'),'focus')}
      />
      <ElegantInput
        key={'address1'}
        reference={ref => refs.current.set('address1', ref)}
        onSubmitEditing={() => _.invoke(refs.current.get('address2'),'focus')}
        style={styles.input}
        label={i18n.t(`${placeholdersPath}.address1Placeholder`)}
        returnKeyType='next'
        onChange={handleChange('address1')}
        onBlur={handleBlur('address1')}
        blurOnSubmit={false}
        value={values['address1']}
        error={(touched['address1'] || forceValidate) && errors['address1']}
        autoCorrect={false}
      />
      <ElegantInput
        key={'address2'}
        reference={ref => refs.current.set('address2', ref)}
        onSubmitEditing={() => _.invoke(refs.current.get('city'),'focus')}
        style={styles.input}
        label={i18n.t(`${placeholdersPath}.address2Placeholder`)}
        returnKeyType='next'
        onChange={handleChange('address2')}
        onBlur={handleBlur('address2')}
        blurOnSubmit={false}
        value={values['address2']}
        error={(touched['address2'] || forceValidate) && errors['address2']}
        autoCorrect={false}
      />
      <ElegantInput
        key={'city'}
        reference={ref => refs.current.set('city', ref)}
        onSubmitEditing={() => _.invoke(refs.current.get('region'),'focus')}
        style={styles.input}
        label={i18n.t(`${placeholdersPath}.cityPlaceholder`)}
        returnKeyType='next'
        onChange={handleChange('city')}
        onBlur={handleBlur('city')}
        blurOnSubmit={false}
        value={values['city']}
        error={(touched['city'] || forceValidate) && errors['city']}
        autoCorrect={false}
      />
      <ElegantInput
        key={'region'}
        reference={ref => refs.current.set('region', ref)}
        onSubmitEditing={() => _.invoke(refs.current.get('zip'),'focus')}
        style={styles.input}
        label={i18n.t(`${placeholdersPath}.regionPlaceholder`)}
        returnKeyType='next'
        onChange={handleChange('region')}
        onBlur={handleBlur('region')}
        blurOnSubmit={false}
        value={values['region']}
        error={(touched['region'] || forceValidate) && errors['region']}
        autoCorrect={false}
      />
      <ElegantInput
        key={'zip'}
        reference={ref => refs.current.set('zip', ref)}
        // onSubmitEditing={() => _.invoke(refs.current.get('taxCode'), 'focus')}
        style={styles.input}
        label={i18n.t(`${placeholdersPath}.zipPlaceholder`)}
        returnKeyType='next'
        onChange={handleChange('zip')}
        onBlur={handleBlur('zip')}
        value={values['zip']}
        error={(touched['zip'] || forceValidate) && errors['zip']}
        autoCorrect={false}
      />
      <View style={styles.countryContainer}>
        <T
          t={`${placeholdersPath}.countryPlaceholder`}
          style={styles.pickerLabel}
        />
        <MyPicker
          style={styles.picker}
          selectedValue={values['country']}
          onValueChange={(value) =>
            setFieldValue('country', value)
          }
          displayValue={_.get(countries, `${values['country']}.name_${i18n.locale}`)}
        >
          {_.map(countries, (country) => {
            return <Picker.Item label={country[`name_${i18n.locale}`]} key={country.key} value={country.key}/>
          })}
        </MyPicker>
      </View>
      <ElegantInput
        key={'email'}
        onSubmitEditing={() => _.invoke(refs.current.get('phone'), 'focus')}
        reference={ref => refs.current.set('email', ref)}
        style={styles.input}
        label={i18n.t(`${placeholdersPath}.emailPlaceholder`)}
        returnKeyType='next'
        onChange={(value) => {
          setFieldValue('email', (value || '').toLowerCase())
        }}
        onBlur={handleBlur('email')}
        blurOnSubmit={false}
        value={values['email']}
        error={(touched['email'] || forceValidate) && errors['email']}
        autoCorrect={false}
        autoCapitalize={'none'}
      />
      <ElegantInput
        key={'phone'}
        reference={ref => refs.current.set('phone', ref)}
        style={styles.input}
        label={i18n.t(`${placeholdersPath}.phonePlaceholder`)}
        returnKeyType='next'
        onChange={handleChange('phone')}
        onBlur={handleBlur('phone')}
        value={values['phone']}
        error={(touched['phone'] || forceValidate) && errors['phone']}
        autoCorrect={false}
      />
      <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 12, justifyContent: 'space-between' }}>
        <Text style={{ marginRight: 5, fontSize: 17 }}>{i18n.t(`${placeholdersPath}.useAsBilling`)}</Text>
        <Switch
          trackColor={{ false: colors.active, true: colors.primary }}
          thumbColor={thumbColor}
          value={values['isBilling']}
          onValueChange={(value) => {
            setFieldValue('isBilling', value)
          }}
        />
      </View>
      <ElegantInput
        key={'taxCode'}
        reference={ref => refs.current.set('taxCode', ref)}
        onSubmitEditing={() => _.invoke(refs.current.get('vatNumber'), 'focus')}
        style={styles.input}
        label={i18n.t(`${placeholdersPath}.taxCodePlaceholder`)}
        returnKeyType='next'
        onChange={handleChange('taxCode')}
        onBlur={handleBlur('taxCode')}
        blurOnSubmit={false}
        value={values['taxCode']}
        error={submitCount > 0 && errors['taxCode']}
        autoCorrect={false}
      />
      <ElegantInput
        key={'vatNumber'}
        reference={ref => refs.current.set('vatNumber', ref)}
        // onSubmitEditing={()=>_.invoke(refs.current.get('vatNumber'),'focus')}
        style={styles.input}
        label={i18n.t(`${placeholdersPath}.vatNumberPlaceholder`)}
        returnKeyType='next'
        onChange={handleChange('vatNumber')}
        onBlur={handleBlur('vatNumber')}
        value={values['vatNumber']}
        error={submitCount > 0 && errors['vatNumber']}
        autoCorrect={false}
      />

      <Button
        // disabled={!isValid}
        label={
          i18n.t('order.cart.saveAddress')
        }
        labelStyle={styles.buttonLabel}
        style={styles.button}
        onPress={handleSubmit}
      />
    </View>
  )

}

AddressForm.defaultProps = { view: false }

AddressForm.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  validateForm: PropTypes.func,
  resetForm: PropTypes.func,
  setFieldValue: PropTypes.func,
  onChange: PropTypes.func,
  editToggle: PropTypes.func,
  forceValidate: PropTypes.bool,
  view: PropTypes.bool,
  isValid: PropTypes.bool,
  submitCount: PropTypes.number,
  address: PropTypes.any,
  onSubmit: PropTypes.func,
}

export default compose(
  withFormik({
    validationSchema: ({ user }) => {
      const country = getCountryFromPhone(user?.phone) 
      return AddressSchema(country)
    },
    validateOnBlur: true,
    mapPropsToValues: ({ address = {} }) => ({
      id: address.id,
      isBilling: address.isBilling || false,
      taxCode: address.taxCode || '',
      vatNumber: address.vatNumber || '',
      firstName: address.firstName || '',
      lastName: address.lastName || '',
      address1: address.address1 || '',
      address2: address.address2 || '',
      city: address.city || '',
      region: address.region || '',
      zip: address.zip || '',
      country: address.country || guessCountry() || 'US',
      email: (address.email || '').toLowerCase(),
      phone: address.phone || '',
    }),
    handleSubmit: (values, { props }) => {
      // console.log('<<<handleSubmit')
      props.onSubmit(values)
    },
  }))(AddressForm)

const styles = stylus({

  input: {
    width: '100%',
    // marginVertical: 10,
  },

  headerContainer: {
    marginVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },

  title: {
    fontWeight: 'bold',
    color: colors.text.main,
  },

  shippingAddressContainer: {
    marginTop: 15,
  },

  button: {
    flex: 1,
    marginHorizontal: 6,
    backgroundColor: colors.primary,
    marginBottom: 5,
    marginTop: 20,
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  countryContainer: {
    alignSelf: 'stretch',
    marginBottom: 10
  },
  pickerLabel: {
    marginTop: 20,
    color: colors.input.base,
    fontSize: 12,
    web: {
      marginBottom: 6,
    },
  },
  picker: {
    web: {
      width: '100%',
      color: colors.input.text,
    },
  },
})
