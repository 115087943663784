import categories from '../localization/en-US/categories.json'
import _ from 'lodash'
import i18n from 'i18n-js'
import environment from './environment'
//ROUTES//

//CATEGORIES////////////////////////////////////////////////////////////
export const CATEGORIES_PRODUCER = new Set(
  _.map(categories.categories.producer, (item, key) => key),
)
export const CATEGORIES_PROFESSIONAL = new Set(
  _.map(categories.categories.professional, (item, key) => key),
)
export const CATEGORIES_ALLERGENS = new Set(
  _.map(categories.categories.allergens, (item, key) => key),
)
export const CATEGORIES_PROPERTIES = new Set(
  _.map(categories.categories.properties, (item, key) => key),
)
export const CATEGORIES_METHOD = new Set(
  _.map(categories.categories.method, (item, key) => key),
)

//ORDERS////////////////////////////////////////////////////////////////
export const ROUTE_ORDER_CONFIRMATION = 'OrderConfirmation'
export const ROUTE_USER_ORDER_HISTORY = 'UserOrderHistory'
export const ROUTE_SHOP_ORDER_HISTORY = 'ShopOrderHistory'
export const ROUTE_USER_ORDER_DETAILS = 'UserOrderDetails'
export const ROUTE_SHOP_ORDER_DETAILS = 'ShopOrderDetails'
////////////////////////////////////////////////////////////////////////

//SHOPPING//////////////////////////////////////////////////////////////
export const ROUTE_PAYMENT_METHODS = 'PaymentMethods'
export const ROUTE_CHOOSE_PAYMENT_METHOD = 'ChooosePaymentMethod'
////////////////////////////////////////////////////////////////////////

//REVIEWS///////////////////////////////////////////////////////////////
export const ROUTE_USER_OBJECT_TYPE_REVIEW = 'UserObjectTypeReview'
////////////////////////////////////////////////////////////////////////

//REPORT CONTENT////////////////////////////////////////////////////////
export const ROUTE_REPORT_CONTENT_POST = 'ReportPostContent'
export const ROUTE_REPORT_CONTENT_RECIPE = 'ReportRecipeContent'
export const ROUTE_REPORT_CONTENT_PRODUCT = 'ReportProductContent'
export const ROUTE_REPORT_CONTENT_COMMENT = 'ReportCommentContent'
export const ROUTE_REPORT_CONTENT_USER_PROFILE = 'ReportUserProfileContent'
export const ROUTE_REPORT_CONTENT_ORGANIZATION_PROFILE =
  'ReportOrganizationProfileContent'
export const ROUTE_REPORT_CONTENT_REVIEW = 'ReportReviewContent'
export const ROUTE_REPORT_CONTENT_MESSAGE = 'ReportMessageContent'
export const ROUTE_REPORT_CONTENT_CONVERSATION = 'ReportConversationContent'
////////////////////////////////////////////////////////////////////////

//POST TYPES//
export const POST_TYPE_FOLLOWING = 'following'
export const POST_TYPE_SUGGESTED = 'suggested'
export const POST_TYPES = [POST_TYPE_FOLLOWING, POST_TYPE_SUGGESTED]

//REVIEW TYPES//
export const REVIEW_OBJECT_TYPE_PRODUCT = 'product'
export const REVIEW_OBJECT_TYPE_ORGANIZATION = 'organization'

//ONBOARDING FIELDS
export const SHOP_FIELD_DISPLAY_NAME = 'displayName'
export const SHOP_FIELD_VAT = 'VAT'
export const SHOP_FIELD_EU_VAT = 'euVAT'
export const SHOP_FIELD_LEGAL_NAME = 'legalName'
export const SHOP_FIELD_COMPANY_PHONE_LAND_PREFIX = 'companyPhoneLandPrefix'
export const SHOP_FIELD_COMPANY_PHONE_LAND = 'companyPhoneLand'
export const SHOP_FIELD_COMPANY_PHONE_MOBILE_PREFIX = 'companyPhoneMobilePrefix'
export const SHOP_FIELD_COMPANY_PHONE_MOBILE = 'companyPhoneMobile'
export const SHOP_FIELD_ROLE = 'role'
export const SHOP_FIELD_BANK_ACCOUNT_HOLDER = 'bankAccountHolder'
export const SHOP_FIELD_IBAN_NUMBER = 'ibanNumber'
export const SHOP_FIELD_STANDARD_SHIPPING_FEE = 'standardShippingFee'
export const SHOP_FIELD_FREE_SHIPPING_THRESHOLD = 'freeShippingThreshold'
export const SHOP_FIELD_MINIMUM_ORDER_AMOUNT = 'minimumOrderAmount'
export const SHOP_FIELD_SHOP_NAME = 'shopName'
export const SHOP_FIELD_FOUNDED = 'founded'
export const SHOP_FIELD_ADDRESS = 'address'
export const SHOP_FIELD_CITY = 'city'
export const SHOP_FIELD_REGION = 'region'
export const SHOP_FIELD_COUNTRY = 'country'
export const SHOP_FIELD_WEBSITE = 'website'
export const SHOP_FIELD_EMAIL = 'email'
export const SHOP_FIELD_GEO = 'geo'

//ADDRESS FIELDS
export const ADDRESS_FIELD_ADDRESS1 = 'address1'
export const ADDRESS_FIELD_ADDRESS2 = 'address2'
export const ADDRESS_FIELD_CITY = 'city'
export const ADDRESS_FIELD_REGION = 'region'
export const ADDRESS_FIELD_STATE = 'state'
export const ADDRESS_FIELD_ZIP = 'zip'
export const ADDRESS_FIELD_COUNTRY = 'country'
export const ADDRESS_FIELD_RECIPIENT = 'recipient'
export const ADDRESS_FIELD_EMAIL = 'email'
export const ADDRESS_FIELD_PHONE = 'phone'

//OBJECT SEARCH
export const SEARCH_OBJECT_PRODUCTS = 'products'
export const SEARCH_OBJECT_PRODUCERS = 'producers'
export const SEARCH_OBJECT_PEOPLE = 'people'
export const SEARCH_OBJECT_RECIPES = 'recipes'
export const SEARCH_OBJECTS = [
  SEARCH_OBJECT_PRODUCTS,
  SEARCH_OBJECT_PRODUCERS,
  SEARCH_OBJECT_PEOPLE,
  SEARCH_OBJECT_RECIPES,
]

export const SEARCH_OBJECT_PAGE_SIZE = 15

//LEGAL LINKS///////////////////////////////////////////////////////////////
//VENDOR
export const VENDOR_RULES_LINK =
  'https://foodbarrio-prod.netlify.app/legal/Regolamento_per_il_venditore.pdf'
export const SERVICE_LEVEL_AGREEMENT_LINK =
  'https://foodbarrio-prod.netlify.app/legal/Standard_livello_di_servizio_del_venditore.pdf'
export const TARIFFS_COMMISSIONS_LINK =
  'https://foodbarrio-prod.netlify.app/legal/Regolamento_su_tariffe_e_commissioni.pdf'
export const SALEABLE_FOOD_RULES_LINK =
  'https://foodbarrio-prod.netlify.app/legal/Regolamento_su_generi_alimentari.pdf'
//USERS
export const TERMS_AND_CONDITIONS_LINK =
  'https://foodbarrio-prod.netlify.app/legal/Condizioni_d_uso.pdf'
export const PRIVACY_POLICY_LINK =
  'https://foodbarrio-prod.netlify.app/legal/Informativa_sulla_privacy.pdf'
export const COOKIES_LINK =
  'https://foodbarrio-prod.netlify.app/legal/Regolamento_su_cookie.pdf'
//MISC
export const CUSTOMER_RULES_LINK =
  'https://foodbarrio-prod.netlify.app/legal/Regolamento_per_l_acquirente.pdf'
export const COMMUNITY_RULES_LINK =
  'https://foodbarrio-prod.netlify.app/legal/Regolamento_della_community.pdf'
export const ELECTRONIC_COMMUNICATIONS_LINK =
  'https://foodbarrio-prod.netlify.app/legal/Regolamento_sulle_comunicazioni_elettroniche.pdf'
export const PRIVACY_POLICY =
  'https://foodbarrio-prod.netlify.app/legal/Regolamento_su_cookie.pdf'
////////////////////////////////////////////////////////////////////////

//OBJECT STATUSES////////////////////////////////////////////////////////////
//Should remain in sync with server constants.
export const OBJECT_STATUSES = {
  active: 'active', //Standard status of item, available in app.
  inactive: 'inactive', // Disabled by user, either shop is paused, product is discontinued, etc. Does not show up in searches, queries, etc but can still be accessed by an admin or owner.
  reported: 'reported', // Item has been reported by a user and needs to be reviewed by an admin. Does not affect visibility.
  disabled: 'disabled', // Item has been disabled by an admin, does not show up in queries or searches. May only be accessed by an admin.
  needsReview: 'needsReview', // Item is temporarely disabled pending review by ADMIN user. Does not show up in searches, queries, etc but can still be accessed by an admin or owner.
  confirmed: 'confirmed', // Item has been confirmed by an admin, but it does not show up in queries or searches. May only be accessed by content creator or amdin.
  unknown: 'unknown', // Status could not be determined
}

export const STATUS_VISIBILITY = {
  userVisible: [OBJECT_STATUSES.active, OBJECT_STATUSES.reported],
  ownerVisible: [
    OBJECT_STATUSES.active,
    OBJECT_STATUSES.reported,
    OBJECT_STATUSES.confirmed,
    OBJECT_STATUSES.inactive,
  ],
  adminVisible: [
    OBJECT_STATUSES.active,
    OBJECT_STATUSES.reported,
    OBJECT_STATUSES.confirmed,
    OBJECT_STATUSES.needsReview,
    OBJECT_STATUSES.inactive,
    OBJECT_STATUSES.disabled,
    OBJECT_STATUSES.unknown,
    OBJECT_STATUSES.needsReview,
  ],
  ownerTaggingVisible: [
    OBJECT_STATUSES.needsReview,
    OBJECT_STATUSES.disabled,
    OBJECT_STATUSES.inactive,
    OBJECT_STATUSES.confirmed,
  ]
}


export const REVRSE_GEO_CODE_URL = 'https://maps.googleapis.com/maps/api/geocode/json'
export const PLACE_DETAIL_URL = 'https://maps.googleapis.com/maps/api/place/details/json'
export const AUTOCOMPLETE_URL = 'https://maps.googleapis.com/maps/api/place/autocomplete/json'
export const WEB_PROXY_URL = "https://cors-anywhere.herokuapp.com/"

// Permissions Constants
export const PERMISSION_STATUS_UNDETERMINED = 'undetermined'
export const PERMISSION_STATUS_DENIED = 'denied'
export const PERMISSION_STATUS_GRANTED = 'granted'

// Media Constants
export const MEDIA_MAX_UPLOAD_ERRORS = environment.maxMediaUploadErrors
