import React from 'react'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'

import { connect } from 'react-redux'
import colors from '../config/colors'
import shared, { stylus } from '../config/styles'

class LandscapeModal extends React.Component {
  render() {
    const {
      screenInfo: { contentWidth, gutterWidth, paddingLeft, topBarHeight },
    } = this.props

    return (
      <TouchableOpacity style={styles.container} onPress={this.props.close}>
        <TouchableOpacity
          activeOpacity={0.95}
          style={[
            styles.modal,
            { top: topBarHeight + 31, left: paddingLeft, width: contentWidth },
          ]}
          onPress={null}
        >
          {this.props.children}
        </TouchableOpacity>
      </TouchableOpacity>
    )
  }
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LandscapeModal)

const styles = stylus({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,0.9)',
    // backgroundColor: 'rgba(0,0,0,0.6)',
    // zIndex: 100,
  },
  modal: {
    backgroundColor: 'white',
    // height: 400,
    ...shared.card,
    // paddingHorizontal: 8,
    position: 'absolute',
    shadowRadius: 24,
    shadowColor: 'black',
    shadowOpacity: 0.25,
    native: {
      shadowOffset: { width: 1, height: 12 },
    },
  },
})
