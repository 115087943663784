import React from 'react'
import { TouchableOpacity, View, Image, Picker, Text } from 'react-native'
import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import T from '../../components/T'
import TextInput from '../../components/simple/TextInput'
import _ from 'lodash'

export default function VerifyPhone({
  screenInfo,
  onChange,
  getMessage,
  state,
  formattedPhone,
  goBack,
}) {
  const size = screenInfo.width / 2.5
  const { confirmationCode } = state
  return (
    <View style={styles.container}>
      <T t='onboardingUser.confirmPhone.enterCode' style={styles.title} />
      <T
        t='onboardingUser.confirmPhone.enterCodeSubtext'
        style={styles.subtext}
      />
      <Text style={styles.phone}>{formattedPhone}</Text>
      <TextInput
        placeholder={getMessage('common.confirmationCode')}
        keyboardType='phone-pad'
        inputStyle={styles.input}
        style={styles.inputWrapper}
        returnKeyType='next'
        onChange={(value) => onChange(value, 'confirmationCode')}
        value={confirmationCode}
        autoCorrect={false}
        autoFocus={true}
      />
      <TouchableOpacity style={styles.requestNew} onPress={goBack}>
        <T t='common.resendConfirmationCode' style={styles.requestNewText} />
      </TouchableOpacity>
    </View>
  )
}

const styles = stylus({
  container: {
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.text.main,
  },
  requestNew: {
    paddingTop: 2,
  },
  input: {
    textAlign: 'center',
    padding: 0,
    fontSize: 18,
    color: colors.input.text,
    web: {
      width: '100%',
    },
  },
  inputWrapper: {
    paddingHorizontal: 0,
    width: 220,
    backgroundColor: colors.input.backgroundFill,
  },
  subtext: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '300',
    color: colors.text.main,
  },
  phone: {
    marginTop: 4,
    marginBottom: 20,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '500',
    color: colors.text.main,
  },
  requestNewText: {
    fontWeight: 'bold',
    color: colors.text.secondary,
    textDecorationLine: 'underline',
    marginTop: 10,
  },
})
