import React from 'react'
import { View, Text, ScrollView, KeyboardAvoidingView, Platform } from 'react-native'
import Button from '../../components/simple/Button'
import sharedStyles, { stylus } from '../../config/styles'
import { connect, graphql } from '../../config/connected'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import i18n from 'i18n-js'
import { ROUTE_REPORT_CONTENT_POST, ROUTE_REPORT_CONTENT_PRODUCT, ROUTE_REPORT_CONTENT_COMMENT, ROUTE_REPORT_CONTENT_USER_PROFILE, ROUTE_REPORT_CONTENT_ORGANIZATION_PROFILE, ROUTE_REPORT_CONTENT_REVIEW, ROUTE_REPORT_CONTENT_MESSAGE, ROUTE_REPORT_CONTENT_RECIPE, ROUTE_REPORT_CONTENT_CONVERSATION} from '../../config/constants'
import LineBreak from '../../components/simple/LineBreak'
import MobileBackButton from '../../components/simple/MobileBackButton'
import NavigationActions from '../../utility/navigationActions'
import schema from './schema'
import ElegantInput from '../../components/simple/ElegantInput'
import { updateCachedQueryValue } from '../../utility/apollo'
import { mergeNavOptionsForFeedLogo, getKeyboardPaddingStyle } from '../../config/helpers'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'

const reportContentRouteToObjectType = {
  [ROUTE_REPORT_CONTENT_POST]: 'post',
  [ROUTE_REPORT_CONTENT_RECIPE]: 'recipe',
  [ROUTE_REPORT_CONTENT_PRODUCT]: 'product',
  [ROUTE_REPORT_CONTENT_COMMENT]: 'comment',
  [ROUTE_REPORT_CONTENT_USER_PROFILE]: 'userProfile',
  [ROUTE_REPORT_CONTENT_ORGANIZATION_PROFILE]: 'organizationProfile',
  [ROUTE_REPORT_CONTENT_REVIEW]: 'review',
  [ROUTE_REPORT_CONTENT_MESSAGE]: 'message',
  [ROUTE_REPORT_CONTENT_CONVERSATION]: 'conversation'
}

const mapStateToProps = (state) => ({ userId: state.currentUser.id })
const mapDispatchToProps = (dispatch) => ({ goBack: () =>  NavigationActions.back() })

@connect(mapStateToProps, mapDispatchToProps)
@graphql(schema.mutations.createReportContent, {
  name: 'createReportContent',
})
@WithKeyboardInfo
class ReportContent extends React.Component {
  state = {
    content: '',
  }

  getContentType() {
    const { navigation } = this.props
    let objectType = reportContentRouteToObjectType[navigation.state.routeName]
    return i18n.t(`common.${objectType}`)
  }

  render() {
    const contentType = this.getContentType()
    const report = i18n.t('common.report')
    const reportContentType = `${report} ${contentType}`
    const keyboardPaddingStyle = getKeyboardPaddingStyle(this.props.keyboardInfo)
    return (
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'android' ? 'height' : 'padding'}
        keyboardVerticalOffset={Platform.OS === 'android' ? null : 16}
      >
        <ScrollView
          style={styles.container}
          contentContainerStyle={styles.contentContainer}
          keyboardShouldPersistTaps='handled'
        >
          <Text style={[styles.title, sharedStyles.h4]}>{reportContentType}</Text>
          <ElegantInput
            multiline
            value={this.state.content}
            placeholder={`${reportContentType}...`}
            onChange={this.onChangeText}
          />
          <LineBreak />
          <Button
            label={reportContentType}
            labelStyle={styles.buttonLabel}
            style={styles.button}
            onPress={this.submitReport}
          />
          <View style={[keyboardPaddingStyle]} />
        </ScrollView>
      </KeyboardAvoidingView>
    )
  }

  onChangeText = (content) => {
    this.setState({
      content,
    })
  }

  submitReport  = async () => {
    const { createReportContent, navigation, goBack, userId } = this.props
    const { content } = this.state 
    const objectType = reportContentRouteToObjectType[navigation.state.routeName]
    const { objectId } = navigation.state.params

    const key = 'createReportContent'
    const updateReportContentInCache = (store, { data }) => {
      updateCachedQueryValue(store, {
        query: schema.queries.reportContentByUserIdForObject,
        variables: { objectId, objectType, userId },
        nextValue: () => data[key]
      })
    }

    await createReportContent({
      variables: { objectType, objectId, content },
      update: updateReportContentInCache
    })
    goBack()
  }
}

ReportContent.navigationOptions = (props) => {
  return mergeNavOptionsForFeedLogo({
    headerLeft: () => (
      <MobileBackButton
        label={i18n.t('common.back')}
        onPress={() => NavigationActions.back()}
      />
    ),
  })
}

export default ReportContent

const styles = stylus({
  container: {
    flex: 1,
    iphonex: {
      marginBottom: sizes.iphonexTabBarHeight,
    },
    native: {
      marginBottom: sizes.tabBarHeight,
    },
  },
  contentContainer: {
    paddingHorizontal: 30,
  },
  title: {
    color: colors.text.main,
    textAlign: 'center',
    marginBottom: 30,
    marginTop: 20,
    fontWeight: '600',
  },
  button: {
    backgroundColor: colors.primary,
    marginBottom: 9,
  },
  buttonLabel: {
    fontSize: 16,
  },
})

