import numeral from 'numeral'

export default {
  usd: (number) => {
    return numeral(number).format('0,0.00')
  },
  dollars: (number) => {
    return numeral(number).format('0,0')
  },
  shortest: (number, places = 2) => {
    if (number < 1) {
      return Number(number).toFixed(places)
    }
    if (number < 1000) {
      return number
    }
    if (number < 10000) {
      return numeral(number / 1000).format('0.0') + 'k'
    }
    if (number < 1000000) {
      return numeral(number / 1000).format('0') + 'k'
    }
    return numeral(number / 1000000).format('0.0') + 'm'
  },
}
