import { gql } from '../config/connected'
import { couponFragment } from './fragments'

export default {
  queries: {
    getAllCoupons: gql`
      query($limit: Int, $offset: Int) {
        coupons(limit: $limit, offset: $offset) {
          ...CouponFragment
          redemptions
        }
      }
      ${couponFragment}
    `,
    couponGroupDetails: gql`
      query($group: String!) {
        couponGroupDetails(group: $group) {
          ...CouponFragment
        }
      }
      ${couponFragment}
    `,
  },
  mutations: {
    applyCoupon: gql`
      mutation($name: String!, $checkoutAmount: Float!) {
        applyCoupon(name: $name, checkoutAmount: $checkoutAmount) {
          ...CouponFragment
        }
      }
      ${couponFragment}
    `,
    revertCoupon: gql`
      mutation($checkoutSessionId: String!) {
        revertCoupon(checkoutSessionId: $checkoutSessionId) {
          ...CouponFragment
        }
      }
      ${couponFragment}
    `,
    createCoupon: gql`
      mutation($input: InputCoupon!) {
        createCoupon(input: $input) {
          ...CouponFragment
        }
      }
      ${couponFragment}
    `,
    updateCoupon: gql`
      mutation($id: String!, $input: InputCoupon!) {
        updateCoupon(id: $id, input: $input) {
          ...CouponFragment
        }
      }
      ${couponFragment}
    `,
    deleteCoupon: gql`
      mutation($couponId: String!) {
        deleteCoupon(couponId: $couponId) {
          ...CouponFragment
        }
      }
      ${couponFragment}
    `,
    disableCoupon: gql`
      mutation($couponId: String!) {
        disableCoupon(couponId: $couponId) {
          ...CouponFragment
        }
      }
      ${couponFragment}
    `,
    enableCoupon: gql`
      mutation($couponId: String!) {
        enableCoupon(couponId: $couponId) {
          ...CouponFragment
        }
      }
      ${couponFragment}
    `,
    uploadManyCoupons: gql`
      mutation($coupons: [InputCoupon!]!) {
        uploadManyCoupons(coupons: $coupons)
      }
    `,
  }
}
