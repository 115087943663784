import i18n from 'i18n-js'
import React from 'react'
import { ActivityIndicator, FlatList, View, Platform } from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import LayoutAnimation from '../../components/LayoutAnimation'
import PostCommenting from '../../components/Post/PostCommenting'
import PostFB from '../../components/PostFB'
import PostRecipe from '../../components/PostFB/PostRecipe'
import Scroller from '../../components/Scroller'
import KeyboardAvoidingView from '../../components/simple/KeyboardAvoidingView'
import MobileBackButton from '../../components/simple/MobileBackButton'
import { Status } from '../../components/simple/Status'
import branch from '../../config/branch'
import { compose, connect, graphql } from '../../config/connected'
import { getKeyboardPaddingStyle } from '../../config/helpers'
import { stylus } from '../../config/styles'
import withCurrentUser from '../../containers/withCurrentUser'
import withKeyboardInfo from '../../containers/withKeyboardInfo'
import commentSchema from '../../schema/comment'
import postSchema from '../../schema/post'
import { updateCachedQueryValue } from '../../utility/apollo'

import _ from 'lodash'
import produce from 'immer'

@withKeyboardInfo
class PostComments extends React.Component {
  state = {
    refreshing: false,
  }

  static displayName = 'PostCommentsRoute'

  render() {
    const { post } = this.props

    if (post.loading) {
      return (
        <View style={styles.loading}>
          <ActivityIndicator size='large' />
        </View>
      )
    }

    if (!post.post) {
      return (
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Status message={i18n.t('post.notAvailable')} />
        </View>
      )
    }

    return (
      <KeyboardAvoidingView
        style={styles.container}
        behavior={branch({ ios: 'padding' })}
        contentContainerStyle={{ flex: 1 }}
        keyboardVerticalOffset={branch({
          iphonex: 64,
          iphone: 64,
          android: 81,
          other: 0,
        })}
      >
        {branch({
          android: this.renderNative,
          ios: this.renderNative,
          web: this.renderWeb,
        })}
        {this.bottom()}
      </KeyboardAvoidingView>
    )
  }
  renderWeb = () => {
    const { layout } = this.props.screenInfo
    return (
      <Scroller>
        <View style={styles.feedPadding} />
        {this.renderContent()}
      </Scroller>
    )
  }
  renderContent = () => {
    const { navigation, comments, post } = this.props
    const { type } = post.post
    const PostType = type === 'recipe' ? PostRecipe : PostFB
    return (
      <View style={styles.postWrapper}>
        <PostType
          post={post.post}
          single={true}
          comments={{ loading: comments.loading, comments: comments.comments }}
        />
      </View>
    )
  }
  refresh = () => {
    // this is just to smooth things
    this.setState({ refreshing: true })
    this.props.comments.refetch()
    setTimeout(() => {
      this.setState({ refreshing: false })
    }, 800)
  }
  renderNative = () => {
    const {
      screenInfo: {
        paddingLeft,
        paddingRight,
        gutterWidth,
        topBarHeight,
        contentTopPadding,
      },
    } = this.props

    return (
      <FlatList
        data={[]}
        onRefresh={this.refresh}
        refreshing={this.state.refreshing}
        inverted={Platform.OS === 'android'}
        style={{
          flex: 1,
          paddingLeft,
          paddingRight,
          marginTop: topBarHeight,
          paddingTop: 0,
          ...branch({
            native: { transform: [{ scaleY: -1 }] },
            other: {},
          }),
          // backgroundColor: 'orange',
        }}
        ref={(ref) => {
          this.scrollComponent = ref
        }}
        ListHeaderComponent={this.renderContent}
        keyExtractor={this.keyExtractor}
        keyboardShouldPersistTaps='always'
      />
    )
  }
  bottom() {
    const {
      screenInfo: {
        paddingLeft,
        paddingRight,
        gutterWidth,
        topBarHeight,
        contentTopPadding,
      },
      goBack,
      keyboardInfo,
    } = this.props
    const keyboardPaddingStyle = getKeyboardPaddingStyle(keyboardInfo)
    const post = this.props.post.post
    return (
      <View
        style={[
          {
            paddingLeft,
            paddingRight,
          },
          keyboardPaddingStyle,
        ]}
      >
        <PostCommenting
          id={post.id}
          send={this.send}
          containerStyle={styles.postCommentingContainer}
          navigation={this.props.navigation}
        />
      </View>
    )
    // onBlur={branch({desktop: null, other: () => goBack})}
  }
  send = async ({ content }) => {
    // alert('sending!', content)
    const { createComment, post } = this.props

    const postId = post.post.id

    let result = await createComment({
      variables: {
        postId,
        content,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createComment: {
          __typename: 'Comment',
          createdAt: new Date().toISOString(),
          id: '-1',
          iLike: false,
          likesCount: 0,
          content,
          user: this.props.currentUser.currentUser,
        },
      },
      update: (store, { data: { createComment } }) => {
        updateCachedQueryValue(store, {
          query: commentSchema.queries.comments,
          variables: { postId },
          nextValue: (comments = []) => {
            return produce(comments, draft => {
              draft.push(createComment)
            })
          }
        })

        updateCachedQueryValue(store, {
          query: postSchema.queries.post,
          variables: { id: postId },
          nextValue: (post) => {
            return produce(post, draft => {
              draft.commentCount++
            })
          }
        })
      },
    })
  }
  animate() {
    if (this.props.animate != false) {
      LayoutAnimation &&
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    }
  }
}

PostComments.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('common.comments'),
    headerLeft: () => (
      <MobileBackButton
        label={i18n.t('common.back')}
        onPress={() => NavigationActions.back()}
      />
    ),
    tabBarVisible: false,
  }
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({
  goBack: () => NavigationActions.back(),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCurrentUser,
  graphql(postSchema.queries.post, {
    name: 'post',
    options: (props) => ({
      variables: {
        id: props.navigation.state.params.id,
      },
    }),
  }),
  graphql(commentSchema.queries.comments, {
    name: 'comments',
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        postId: props.navigation.state.params.id,
      },
    }),
  }),
  graphql(commentSchema.mutations.createComment, {
    name: 'createComment',
  }),
)(PostComments)

const styles = stylus({
  container: {
    flex: 1,
  },
  postCommentingContainer: {
    backgroundColor: 'white',
  },
  loading: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 60,
  },
  feedPadding: {
    landscape: {
      height: 31,
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
  },
  postWrapper: {
    native: {
      alignSelf: 'stretch',
    },
    ios: {
      transform: [{ scaleY: -1 }],
    },
  },
})
