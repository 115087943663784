import React from 'react'
import PropTypes from 'prop-types'
import { Animated, Platform, Text, TouchableOpacity, UIManager, View, ViewPropTypes } from 'react-native'
import Entypo from '@expo/vector-icons/Entypo'
import i18n from 'i18n-js'
import { CancelToken } from 'axios'
import Events from 'react-native-simple-events'
import MapView from '../MapView'
import unescape from 'lodash/unescape'
import alert from '../../utility/alert'
import services from '../../utility/services'
import { fetchAddressByPlaceId, fetchAddressForLocation } from '../../utility/location'
import Modal from '../../components/simple/Modal'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import AutoCompleteInput from './AutoCompleteInput'
import { stylus } from '../../config/styles'
import _ from 'lodash'
import { pure } from 'recompose'

const PureMap = pure(MapView)
const DEFAULT_DELTA = { latitudeDelta: 0.015, longitudeDelta: 0.0121 }

const adrFields = {
  'country-name': 'country',
  'street-address': 'address',
  'locality': 'city',
  'postal-code': 'zip',
  'region': 'region'
}

export default class LocationView extends React.Component {
  static propTypes = {
    apiKey: PropTypes.string.isRequired,
    debounceDuration: PropTypes.number,
    initialLocation: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }).isRequired,
    markerColor: PropTypes.string,
    visible: PropTypes.bool,
    actionButtonStyle: ViewPropTypes.style,
    actionTextStyle: Text.propTypes.style,
    actionText: PropTypes.string,
    address: PropTypes.string,
    components: PropTypes.arrayOf(PropTypes.string),
    onClosePress: PropTypes.func,
    onLocationSelect: PropTypes.func,
  }

  static defaultProps = {
    markerColor: 'black',
    actionText: 'DONE',
    onLocationSelect: () => ({}),
    onClosePress: () => {},
    debounceDuration: 300,
    components: [],
  }

  _input = React.createRef()
  _map = React.createRef()

  state = {
    isPlaceSelected: true,
    gotInitialLocation: false,
    inputScale: new Animated.Value(1),
    inFocus: false,
    formatted_address: '',
    isLocationSet: false,
    region: {
      ...DEFAULT_DELTA,
      // ...this.props.initialLocation,
    },
  }

  constructor(props) {
    super(props)
    if (Platform.OS === 'android') {
      UIManager.setLayoutAnimationEnabledExperimental && UIManager.setLayoutAnimationEnabledExperimental(true)
    }
  }


  _findLocationByRegions = _.debounce(async (region) => {
    const { latitude: lat, longitude: lng, longitudeDelta, latitudeDelta } = region
    let response = await fetchAddressForLocation({ lat, lng, apiKey: this.props.apiKey })
    const place_id = _.get(response, 'data.results[0].place_id')
    if (place_id) {
      let placeResponse = await fetchAddressByPlaceId({ apiKey: this.props.apiKey, placeId: place_id })
      const { adr_address, formatted_address, address_components } = placeResponse.data.result
      let parsedAddress = this.parseAdr(adr_address)
      let {state, region} = this.getRegionAndState(address_components)

      let location = {
        latitude: lat,
        longitude: lng,
        address: {
          ...parsedAddress,
          state,
          region
        },
        formattedAddress: formatted_address,
        longitudeDelta,
        latitudeDelta,
      }
      this.setState({ location, formatted_address, isPlaceSelected: true })
    }
  }, 500)

  componentWillUnmount() {
    Events.rm('InputBlur', this.constructor.displayName)
    Events.rm('InputFocus', this.constructor.displayName)
    Events.rm('PlaceSelected', this.constructor.displayName)
  }

  componentDidMount() {
    Events.listen('InputBlur', this.constructor.displayName, this._onTextBlur)
    Events.listen('InputFocus', this.constructor.displayName, this._onTextFocus)
    Events.listen('PlaceSelected', this.constructor.displayName, this._onPlaceSelected)
    const {initialLocation} = this.props
    // console.log('LOCATION VIEW - Initial Location', initialLocation, this.props.address)
    if (initialLocation && initialLocation.latitude && initialLocation.longitude) {
      this.setState({
        region: { ...DEFAULT_DELTA, ...initialLocation },
        formatted_address: this.props.address,
      })
    } else {
      this.getLocationAsync()
    }
  }

  isLocationSet = () => {
    const { initialLocation } = this.props
    const { isLocationSet } = this.state
    return isLocationSet || !!(initialLocation && initialLocation.latitude && initialLocation.longitude)
  }

  _onMapRegionChangeComplete = async region => {
    // console.log('<<<LOCATION VIEW - Responding to region change', this.isLocationSet(), this.state.isPlaceSelected, region)
    if (this.isLocationSet && this.state.isPlaceSelected === true) {
      // console.log('<<<_onMapRegionChangeComplete')
      this._findLocationByRegions(region)
    }
  }

  onMapPress = () => {
    this._input.current.blur()
  }

  getLocationAsync = async () => {
    const location = await services.getUserLocationAsync(true)
    if (location) {
      this.setState({ region: {...DEFAULT_DELTA, ...location.coords}, isLocationSet: true })
    } else {
      this.setState({ region: { ...DEFAULT_DELTA }, isLocationSet: true })
    }

  }

  _setRegion = (region, animate = false) => {
    this.setState({region: { ...this.state.region, ...region }})
    if (animate) this._map.current.animateToRegion(this.state.region)
  }

  parseAdr = (adr) => { // ar - html span tags
    let address = {}
    // gett field name from class
    let types = adr.match(/(?:class=")(.*?)(?=")/g).map(val => {
      let type = val.replace(/class="/g, '')
      return adrFields[type] || type
    })
    // get text values from span tags
    let values = adr.match(/(?:">)(.*?)(?=<\/)/g).map(val => unescape(val.replace(/">/g, '')))

    // format field names
    types.forEach((type, idx) => address[type] = values[idx])
    return address
  }

  getRegionAndState = (address_components) => {
    let stateObj = address_components.find(comp => {
      return comp.types.find(type => type === 'administrative_area_level_1')
    })
    let regionObj = address_components.find(comp => {
      return comp.types.find(type => type === 'administrative_area_level_2')
    })
    return {
      state: stateObj ? stateObj.long_name : '',
      region: regionObj ? regionObj.long_name : '',
    }
  }

  _onPlaceSelected = async placeId => {
    this._input.current.blur()
    let { data } = await fetchAddressByPlaceId({apiKey: this.props.apiKey, placeId})
    const { geometry, adr_address, formatted_address, address_components } = data.result
    let parsedAddress = this.parseAdr(adr_address || '')
    let {state, region} = this.getRegionAndState(address_components)

    const { lat, lng } = geometry.location
    let location = {
      latitude: lat,
      longitude: lng,
      address: {
        ...parsedAddress,
        state,
        region
      },
      formattedAddress: formatted_address
    }
    this.setState({
      location,
      region: {
        ...DEFAULT_DELTA,
        ...this.state.region,
        latitude: lat,
        longitude: lng
      },
      formatted_address
    })

    // fixme find a better solution
    setTimeout(() => {
      this.setState({
        isPlaceSelected: true,
      })
    }, 500)
  }

  onAddressInputChange = () => {
    // console.log('<<<onAddressInputChange')
    this.setState({isPlaceSelected: false})
  }

  onLocationSelect = () => {
    const {location, isPlaceSelected, formatted_address: formattedAddress} = this.state
    if (isPlaceSelected) {
      this.props.onLocationSelect({ ...location, formattedAddress })
    } else {
      alert({ title: 'Errors', message: i18n.t('onboardingShop.details.selectValidAddress') })
    }
  }

  onClosePress = () => {
    this.props.onClosePress()
  }

  renderCloseButton = () => {
    return (
      <TouchableOpacity style={styles.closeButton} onPress={this.onClosePress}>
        <Icons name='ios-close' size={50} color={colors.text.main}/>
      </TouchableOpacity>
    )
  }

  doneDisabled = () => !this.state.formatted_address

  render() {
    let { region } = this.state

    return (
      <Modal
        animationType='slide'
        visible={this.props.visible}
      >
        <View style={{flex: 1}}>
          <View style={styles.container}>
            <View style={{ position: 'relative', flex: 1 }}>
              <PureMap
                showsUserLocation
                ref={this._map}
                style={styles.mapView}
                region={region}
                provider='google'
                showsMyLocationButton={true}
                onPress={this.onMapPress}
                onRegionChangeComplete={this._onMapRegionChangeComplete}
              />
              <Entypo
                name={'location-pin'}
                size={30}
                color={this.props.markerColor}
                style={styles.mapMarker}
              />
            </View>
            {this.renderCloseButton()}
            <View style={styles.fullWidthContainer}>
              <AutoCompleteInput
                ref={this._input}
                apiKey={this.props.apiKey}
                key={this.state.formatted_address}
                style={styles.input}
                text={this.state.formatted_address}
                debounceDuration={this.props.debounceDuration}
                components={this.props.components}
                onAddressInputChange={this.onAddressInputChange}
                isPlaceSelected={this.state.isPlaceSelected}
              />
            </View>
            <TouchableOpacity
              disabled={this.doneDisabled()}
              style={[styles.actionButton, this.props.actionButtonStyle, this.doneDisabled() && styles.disabled]}
              onPress={this.onLocationSelect}
            >
              <View>
                <Text style={[styles.actionText, this.props.actionTextStyle]}>{i18n.t('onboardingShop.details.done')}</Text>
              </View>
            </TouchableOpacity>
            {this.props.children}
          </View>
        </View>
      </Modal>
    )
  }
}

const styles = stylus({
  container: {
    flex: 1,
    // paddingBottom: sizes.tabBarHeight,
    // iphonex: {
    //   paddingBottom: sizes.iphonexTabBarHeight,
    // },
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  mapMarker: {
    backgroundColor: 'transparent',
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: -15,
    marginTop: -15,
    iphonex: {
      marginTop: -5,
    },
  },
  mapView: {
    flex: 1,
    // ...StyleSheet.absoluteFillObject,
  },
  fullWidthContainer: {
    position: 'absolute',
    width: '100%',
    top: 60,
    iphonex: {
      top: 80,
    },
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    ios: {
      top: 10,
    },
    iphonex: {
      top: 30,
    },
    right: 10,
    padding: 2,
    // backgroundColor: colors.primary,
    // borderRadius: 5
  },
  input: {
    width: '80%',
    padding: 5,
  },
  currentLocBtn: {
    backgroundColor: colors.primary,
    padding: 5,
    borderRadius: 6,
    position: 'absolute',
    bottom: 70,
    right: 10,
  },
  actionButton: {
    backgroundColor: colors.primary,
    padding: 10,
    // position: 'absolute',
    // bottom: sizes.tabBarHeight -10,
    // iphonex: {
    //   bottom: sizes.iphonexTabBarHeight - 50,
    // },
    // left: 10,
    // right: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    margin: 10,
    iphonex: {
      marginBottom: 30,
    },
  },
  disabled: {
    backgroundColor: '#E6E7E8',
  },
  actionText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '600'
  },
})
