import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import {
  connect,
  graphql,
  withApollo,
  compose,
  gql,
} from '../../config/connected'
import withCurrentUser, {
  query as currentUserQuery,
} from '../../containers/withCurrentUser'
import _ from 'lodash'
import schema from '../../schema/notification'
import { client } from '../../containers/withApollo'
import { updateCachedQueryValue } from '../../utility/apollo'
import produce from 'immer'

const debug = false

class NotificationSubscriptions extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = _.get(nextProps, 'currentUser.currentUser.id')
    if (id !== _.get(this.props, 'currentUser.currentUser.id')) {
      if (id) {
        if (!this.notificationSubscription) {
          // console.log('SUBSCRIBING TO NOTIFICATIONS')
          this.subscribeToNotifications()
        }
      } else {
        // unsubscribe
        if (this.notificationSubscription) {
          // console.log('UNSUBSCRIBING TO NOTIFICATIONS')
          this.notificationSubscription.unsubscribe()
          delete this.notificationSubscription
        }
      }
    }
  }
  subscribeToNotifications() {
    const observable = client.subscribe({
      query: schema.subscriptions.notificationAdded,
    })
    this.notificationSubscription = observable.subscribe({
      next: (message) => {
        // console.log('Received new notification', message)
        const notification = _.get(message, 'data.notificationAdded')
        const unseenBadge = notification.unseenBadge.unseenNotifications
        try {
          // 1. Update notifications list
          updateCachedQueryValue(client, {
            query: schema.queries.myNotifications,
            nextValue: (myNotifications = []) => {
              myNotifications.unshift(notification)
              return _.uniqBy(myNotifications, 'id')
            },
            abortOnError: true
          })
          // 2. Update unseen badge
          updateCachedQueryValue(client, {
            query: currentUserQuery,
            nextValue: (currentUser = {}) => {
              return produce(currentUser, draft => { draft.unseenBadge.unseenNotifications = unseenBadge } )
            }
          })
        } catch (e) {
          debug && console.log('<<<NOTIFICATIONS SUBSCRIPTION - error', e)
        }
      },
      error(err) {
        debug && console.log(`<<<NOTIFICATIONS SUBSCRIPTION - error subscribing`, err)
      },
    })
  }
  render() {
    return null
  }
}

export default compose(withCurrentUser)(NotificationSubscriptions)
