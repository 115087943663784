import React from 'react'
import { ActivityIndicator, View, Platform, FlatList } from 'react-native'

import NavigationActions from '../../utility/navigationActions'
import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'
import {
  connect,
  graphql,
  withApollo,
  compose,
  gql,
} from '../../config/connected'
import MessageInput from '../../components/MessageInput'
import _ from 'lodash'
import PageTitle from '../../components/simple/PageTitle'
import Compose from '../../components/Compose'
import Scroller from '../../components/Scroller'
import StatusBar from '../../components/simple/StatusBar'
import Posting from '../Posting'
import LandscapeModal from '../../layouts/LandscapeModal'
import branch from '../../config/branch'
import PostFB from '../../components/PostFB'
import PostRecipe from '../../components/PostFB/PostRecipe'

const PAGE_SIZE = 15

class Feed extends React.Component {
  static navigationOptions = {
    header: () => null,
  }
  constructor(props) {
    super(props)
    this.state = {
      refreshing: false,
    }
  }
  render() {
    // <PageTitle text='Social Feed' portrait={false} />
    return (
      <View style={styles.container}>
        {Platform.OS === 'web' ? this.renderWebList() : this.renderNativeList()}
      </View>
    )
  }
  loadMore = () => {
    // if (this.loadingMore || this.noMore) { return }
    // this.loadingMore = true
    // const { data } = this.props
    // const after = _.get(_.last(data.allPosts), 'id')
    // // console.log('load more', {after})
    // data.fetchMore({
    //   variables: { after },
    //   updateQuery: (previousResult, { fetchMoreResult }) => {
    //     this.loadingMore = false
    //     if (!fetchMoreResult || fetchMoreResult.allPosts.length === 0) {
    //       this.noMore = true
    //       return previousResult
    //     }
    //     return {
    //       allPosts: previousResult.allPosts.concat(fetchMoreResult.allPosts),
    //     }
    //   },
    // })
  }
  refresh = () => {
    // this is just to smooth things
    this.setState({ refreshing: true })
    this.noMore = false
    this.props.data.refetch()
    setTimeout(() => {
      this.setState({ refreshing: false })
    }, 800)
  }
  renderPost = ({ item }) => {
    const PostType = item.type === 'recipe' ? PostRecipe : PostFB
    return <PostType post={item} />
  }
  scroll = (offset) => {
    // console.log('scroll to', offset)
    this.scrollComponent.scrollToOffset({ animated: true, offset: offset })
  }
  getPosts = () => {
    return _.get(this.props, 'data.allPosts', [])
  }
  renderFooter = () => {
    if (Platform.OS === 'ios') {
      return <View style={styles.iosBottomSpace} />
    }
    return null
  }
  keyExtractor = (item, index) => item.id
  renderNativeList() {
    const {
      screenInfo: {
        paddingLeft,
        paddingRight,
        gutterWidth,
        topBarHeight,
        contentTopPadding,
      },
    } = this.props

    return (
      <FlatList
        renderItem={this.renderPost}
        data={this.getPosts()}
        style={{
          flex: 1,
          paddingLeft,
          paddingRight,
          marginTop: topBarHeight,
          paddingTop: contentTopPadding,
          paddingBottom: 190,
        }}
        ref={(ref) => {
          this.scrollComponent = ref
        }}
        refreshing={
          this.state.refreshing || this.props.data.networkStatus === 4
        }
        onRefresh={this.refresh}
        ListFooterComponent={this.renderFooter}
        keyExtractor={this.keyExtractor}
        onEndReachedThreshold={4}
        onEndReached={this.loadMore}
        initialNumToRender={2}
        scrollEventThrottle={700}
      />
    )
  }
  renderWebList() {
    const layout = _.get(this.props, 'screenInfo.layout')
    return (
      <Scroller loadMore={this.loadMore}>
        <View style={styles.feedPadding} />
        {this.getPosts().map((item) => this.renderPost({ item }))}
      </Scroller>
    )
  }
}

const taggedPostsQuery = gql`
  query($ids: [ID!]) {
    allPosts(filter: { id_in: $ids }) {
      id
      text
      location
      createdAt
      uploads {
        name
        width
        height
        contentType
      }
      user {
        id
        name
        profileImage {
          name
        }
        backgroundImage {
          name
        }
      }
      postReactions {
        id
        symbol
        user {
          id
          name
          profileImage {
            name
          }
        }
      }
      _postReactionsMeta {
        count
      }
      _commentsMeta {
        count
      }
    }
  }
`

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({
  goToPosting: () =>
    NavigationActions.navigate({ routeName: 'Posting' }),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(taggedPostsQuery, {
    options: (props) => ({
      variables: {
        ids: props.postIds,
      },
    }),
  }),
)(Feed)

const styles = stylus({
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
  },
  feedPadding: {
    landscape: {
      height: 31,
    },
  },
  landscapeTitleText: {
    fontSize: 28,
    color: '#444',
    fontWeight: '400',
  },
  iosBottomSpace: {
    height: 46,
  },
})
