import React, { Component } from 'react'
import { Text, View, Image } from 'react-native'
import T from '../T'
import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import stripeLogo from '../../assets/images/stripe_logo_black.png'
import Checkbox from './Checkbox'
import PropTypes from 'prop-types'

class PaymentInfo extends Component {
  static defaultProps = {
    cash: false,
    transfer: false,
    stripe: false,
    handleFieldChange: () => {},
  }

  static propTypes = {
    cash: PropTypes.bool,
    transfer: PropTypes.bool,
    stripe: PropTypes.bool,
    handleFieldChange: PropTypes.func,
  }

  render() {
    const { cash, transfer, stripe, handleFieldChange } = this.props

    return (
      <View style={styles.container}>
        <View style={styles.checkboxWithIcon}>
          {/* <Icons name="ios-add-circle" /> */}
          <Image
            source={stripeLogo}
            style={{ width: 96, height: 96, opacity: 0 }}
            resizeMethod='scale'
            resizeMode='contain'
          />
          <T t='onboardingShop.financialInfo.cash' />
          <Checkbox
            checked={cash}
            onPress={() => handleFieldChange(!cash, 'cash')}
          />
        </View>
        <View style={styles.checkboxWithIcon}>
          <Image
            source={stripeLogo}
            style={{ width: 96, height: 96, opacity: 0 }}
            resizeMethod='scale'
            resizeMode='contain'
          />
          <T t='onboardingShop.financialInfo.transfer' />
          <Checkbox
            checked={transfer}
            onPress={() => handleFieldChange(!transfer, 'transfer')}
          />
        </View>
        <View style={styles.checkboxWithIcon}>
          <Image
            source={stripeLogo}
            style={{ width: 96, height: 96 }}
            resizeMethod='scale'
            resizeMode='contain'
          />
          <Text> </Text>
          <Checkbox
            checked={stripe}
            onPress={() => handleFieldChange(!stripe, 'stripe')}
          />
        </View>
      </View>
    )
  }
}

export default PaymentInfo

const styles = stylus({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  checkboxWithIcon: {
    alignItems: 'center',
  },
  paymentText: {
    fontSize: 22,
    fontWeight: 'bold',
    margin: 10,
    marginTop: 60,
  },
})
