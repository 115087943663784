
import { stylus } from '../../../config/styles'
import { Text, Picker } from 'react-native'
import React from 'react'
import colors from '../../../config/colors'

const PickerNotiOS = React.forwardRef(({label, ...rest}, ref) => {
  return (
    <React.Fragment>
      {label ? (
        <Text style={[styles.label]}>
          {label}
        </Text>
      ) : null}
      <Picker ref={ref} {...rest} />
    </React.Fragment>
  )
})

export default PickerNotiOS

const styles = stylus({
  label: {
    marginTop: 10,
    fontSize: 12,
    color: colors.text.light,
    alignSelf: 'flex-start',
  },
})
