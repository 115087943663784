import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import Icon from '../../config/icon'
import shared from '../../config/styles'

export default ({ onPress }) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Icons
        name='ios-menu'
        size={30}
        color={colors.navigation}
        style={styles.icon}
      />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
  },
  icon: {},
})
