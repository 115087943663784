import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import NamedImage from '../../../components/simple/NamedImage'
import RecipeInfoBar from '../../../components/PostFB/RecipeInfoBar'
import TagText from '../../../components/Tagging/TagText'


import { stylus } from '../../../config/styles'
import { connect } from '../../../config/connected'
import colors from '../../../config/colors'
import sizes from '../../../config/sizes'
import _ from 'lodash'

const marginRight = 15

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo
})

@connect(mapStateToProps)
class RecipeResult extends React.Component {
  render() {
    const { post, maxWidth } = this.props
    const { uploads, story, recipe } = post
    const { title } = recipe
    const maxComponentWidth = maxWidth - marginRight
    const maxImageWidth = maxComponentWidth * 0.30
    const maxImageHeight = maxImageWidth
    const maxDetailsWidth = maxComponentWidth - maxImageWidth
    const width = maxImageWidth - 10
    const height = width

    const fileUpload = uploads.filter(item => !['.mp4', '.mov', 'thumbnail-'].some(indicator => _.invoke(item.name, 'includes', indicator)) )[0]
    return (
      <TouchableOpacity style={styles.container} onPress={this.recipeOnPress}>
        <View style={[ styles.imageContainer, { width: maxImageWidth, height: maxImageHeight}]}>
          <NamedImage
            name={!fileUpload ? 'placeholder' : fileUpload.name}
            width={width}
            height={width}
            style={{
              width,
              height,
              borderRadius: 4, // width/2,
            }}
            fit='crop'
            resizeMethod='scale'
            resizeMode='cover'
          />
        </View>
        <View style={[styles.detailsContainer, { width: maxDetailsWidth }]}>
          <Text numberOfLines={1} style={[styles.title]}>{title}</Text>
          <TagText content={story} numberOfLines={2} style={[styles.story]}/>
          <RecipeInfoBar search textSize={14} iconSize={20} containerStyle={styles.infoContainer} recipe={recipe} />
        </View>
      </TouchableOpacity>
    )
  }

  recipeOnPress = () => {
    this.props.onPress(this.props.post)
  }
}

export default RecipeResult

const styles = stylus({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5
  },
  imageContainer: {
    // marginRight,
    // borderWidth: 1,
    // padding: 4,
    // borderColor: '#CCC',
    // overflow: 'hidden',
    // borderRadius: 9999,
  },
  placeholder: {
    backgroundColor: colors.input.placeholder
  },
  detailsContainer: {
    flex: 1,
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
    paddingBottom: 10
  },
  title: {
    fontSize: 14,
    fontWeight: '600',
    color: colors.text.main
  },
  story:  {
    fontSize: 13,
    color: colors.text.secondary
  },
  infoContainer: {
    paddingLeft: 0,
    paddingRight: 0,
  }
})
