const debug = false

function getResponseHeaders(xhr) {
  // Get the raw header string
  var headers = xhr.getAllResponseHeaders()
  // Create a map of header names to values
  var headerMap = {}

  if (!headers) {
    return headerMap
  }

  // Convert the header string into an array
  // of individual headers
  var arr = headers.trim().split(/[\r\n]+/)

  arr.forEach(function (line) {
    var parts = line.split(': ')
    var header = parts.shift()
    var value = parts.join(': ')
    headerMap[header] = value
  })
  return headerMap
}

export default function uploadImageS3({
  id,
  partNumber,
  byteArray,
  uri,
  signed,
  privacy,
  onProgress = () => {},
  onError = () => {},
  onLoad = () => {},
  onTimeout = () => {},
}) {
 
  return new Promise(async (resolve, reject) => {
    try {
      const { signedRequest, mime, name } = signed
      const xhr = new XMLHttpRequest()
      xhr.open('PUT', signedRequest)

      xhr.onreadystatechange = () => {
        const { readyState, status, response } = xhr
        const responseHeaders = getResponseHeaders(xhr)
        debug && console.log('<<<UPLOAD IMAGE - readyState', readyState)
        debug && console.log('<<<UPLOAD IMAGE - status', status)
        debug && console.log('<<<UPLOAD IMAGE - response', response)
        debug && console.log('<<<UPLOAD IMAGE - responseHeaders', JSON.stringify(responseHeaders))

        if (readyState === 4) {
          if (status === 200) {
            resolve({ status, response, responseHeaders })
          } else {
            reject({ status, response, responseHeaders })
          }
        }
      }
      let previousProgress = 0
      xhr.upload.addEventListener(
        'progress',
        function(event) {
          let progress = Math.floor((event.loaded / event.total) * 100)
          if (progress > previousProgress) {
            previousProgress = progress
            onProgress && onProgress({ id, partNumber, progress })
          }
        },
        false,
      )

      xhr.upload.addEventListener('timeout', onTimeout)
      xhr.upload.addEventListener('load', onLoad)

      xhr.onerror = (err) => {
        if (onError) {
          onError(err)
        }
      }
      if (privacy) {
        xhr.setRequestHeader('X-Amz-ACL', privacy || 'public-read')
      }
      xhr.setRequestHeader('Content-Type', mime)
      if (byteArray) {
        xhr.setRequestHeader('Content-Encoding', 'base64')
        xhr.send(byteArray)
      } else if (uri) {
        xhr.send({ uri, type: mime, name })
      } else {
        console.log('<<<UPLOAD IMAGE - error - did not provide data to perform upload', name)
      }
    } catch (e) {
      reject(e)
    }
  })
}
