import i18n from 'i18n-js'

export default function() {
  const language = i18n.locale
  return (
    {
      en: 'US',
      'en-US': 'US',
      it: 'IT',
      'it-IT': 'IT',
    }[language] || 'IT'
  )
}
