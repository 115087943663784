import React from 'react'
import { View, Image } from 'react-native'

import { stylus } from '../../config/styles'
import colors from '../../config/colors'

const graphicLogo = require('../../assets/images/fb_black_logo.png')
import { fbLogo } from '../../assets/images'
import T from '../../components/T'
import ErrorMessage from '../../components/simple/ErrorMessage'
import TextInput from '../../components/simple/TextInput'
import Cursor from '../../components/simple/Cursor'
import FormatTextInput from '../../components/simple/FormatTextInput'
import OverrideTextInput from '../../components/simple/OverrideTextInput'

import { getFormattedUsername } from '../../config/helpers'
import { validators } from '../../config/validate'
import i18n from 'i18n-js'

const imageContainerSize = 100
const imageSize = imageContainerSize - 29 //- 10 // downsize the image as necessary to create a ring


export default class InviteCode extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.inviteCode !== this.props.inviteCode || nextProps.error !== this.props.error
  }

  onChange = (value) => {
    // this.validate(value)
    this.props.onChange(value, 'inviteCode')
  }

  validate = (value) => {
    const errorTransString = validators.username({ allowEmpty: true }, value)
    const error = errorTransString ? i18n.t(errorTransString) : null
    // console.log('Invite Code Error: ', error)
    if (error) {
      this.props.setError('inviteCode', error)
    } else {
      this.props.removeError('inviteCode')
    }
  }

  render() {
    const {
      getMessage,
      inviteCode,
      onChange,
      error
    } = this.props
    return (
      <View style={styles.container}>
        <View style={styles.imageContainer}>
          <Image
            source={fbLogo}
            style={styles.image}
            resizeMode='contain'
            resizeMethod='scale'
          />
        </View>
        <T t='onboardingUser.inviteCode.title' style={styles.title} />
        <T
          t='onboardingUser.inviteCode.titleSubtext'
          style={styles.subtext}
        />
        <View style={styles.marginBump}>
          <TextInput
            maxLength={30}
            placeholder={getMessage('onboardingUser.inviteCode.placeholder')}
            inputStyle={styles.inputText}
            style={styles.inputWrapper}
            returnKeyType='next'
            onChange={this.onChange}
            value={inviteCode}
            autoCorrect={false}
            autoFocus={true}
            autoCapitalize='none'
          />
          {/* <OverrideTextInput
            maxLength={30}
            placeholder={getMessage('onboardingUser.inviteCode.placeholder')}
            inputStyle={styles.inputText}
            style={styles.inputWrapper}
            returnKeyType='next'
            onChange={this.onChange}
            value={inviteCode}
            autoCorrect={false}
            autoFocus={true}
            format={getFormattedUsername}
            validate={this.validate}
          /> */}
        </View>
        { error ? <ErrorMessage message={error} /> : null }
        {/* <FormatTextInput
          style={styles.marginBump}
          inputWrapperStyle={styles.inputWrapper}
          inputStyle={styles.input}
          placeholder={getMessage('onboardingUser.inviteCode.placeholder')}
          onChangeText={onChange}
          value={inviteCode}
          maxLength={30}
          autoCorrect={false}
          autoFocus={true}
          selectTextOnFocus={false}
          returnKeyType='next'
          selection={{ start: inviteCode.length, end: inviteCode.length}}
          fontSize={18}
          verticalPadding={10}
          format={getFormattedUsername}
        /> */}
      </View>
    )
  }
}


const styles = stylus({
  container: {
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  title: {
    marginTop: 20,
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.text.main,
  },
  marginBump: {
    marginTop: 10,
  },
  input: {
    textAlign: 'center',
    color: colors.input.text,
    web: {
      width: '100%',
    },
  },
  inputWrapper: {
    borderRadius: 5,
    width: 220,
    backgroundColor: colors.input.backgroundFill,
  },
  inputText: {
    textAlign: 'center',
    padding: 0,
    fontSize: 18,
    color: colors.input.text,
    web: {
      width: '100%',
    },
  },
  subtext: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '300',
    color: colors.text.main,
  },
  image: {
    width: imageSize,
    height: imageSize,
    borderRadius: imageSize / 2
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: imageContainerSize,
    width: imageContainerSize,
    borderWidth: 1,
    borderColor: '#CCC',
    borderRadius: imageContainerSize / 2,
    padding: 4,
    overflow: 'hidden',
  },
})
