// https://www.geodatasource.com/developers/javascript
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'ME' is meters                                         :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export function distance({ latitude: lat1, longitude: lon1}, { latitude: lat2, longitude: lon2}, unit) {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0
  }
  else {
    const radlat1 = Math.PI * lat1/180
    const radlat2 = Math.PI * lat2/180
    const theta = lon1-lon2
    const radtheta = Math.PI * theta/180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit==='K') { dist = dist * 1.609344 }
    if (unit==='ME') { dist = dist * 1609.344 }
    if (unit==='N') { dist = dist * 0.8684 }
    return dist
  }
}

export function getLatitudeDelta(radiusInKm) {
  return (radiusInKm / 111.1) * 2
}

export function getLongitudeDelta(latitude, radiusInKm) {
  const kmInLongitudeDegree = 111.320 * Math.cos( latitude / 180.0 * Math.PI)
  return (radiusInKm / kmInLongitudeDegree) * 2
}

// Distance from right edge in meters is to be used as radius for geo search
export const getRadiusFromRegion = (region) => {
  return getDistanceFromRightEdge(region) * 1000 // needs to be in meters
}

// Distance between top edge and center point in km
export const getDistanceFromRightEdge = (region, unit = 'K') => {
  const { latitude, longitude, longitudeDelta } = region
  const eastPoint = {
    longitude: longitude + longitudeDelta/2,
    latitude
  }
  const distanceInKm = distance(region, eastPoint, unit)
  //console.log(`Distance from right in km: ${distanceInKm}`)
  return distanceInKm
}

// https://github.com/react-native-community/react-native-maps/issues/705
export const getZoomLevelForLngDelta = (lngDelta) => {
  return Math.round(Math.log(360 / lngDelta) / Math.LN2)
}
