import React, { Component } from 'react'
import { connect, graphql, compose } from '../../config/connected'
import schema from '../../schema/cart'
import ListItem from './ListItem'
import i18n from 'i18n-js'

function CartLink(props) {
  if (props.cartItems.loading) {
    return <ListItem {...props} title={'Cart'} />
  }
  let count = 0
  const cartItems = props.cartItems.cartItemsByUserId
  if (cartItems && cartItems.length) {
    count = cartItems.length
  }
  return (
    <ListItem
      {...props}
      title={i18n.t('order.cart.titleSettings', { count })}
    />
  )
}

//`Cart (${count} items)`
const mapStateToProps = (state) => ({
  userId: state.currentUser.id,
})

export default compose(
  connect(mapStateToProps),
  graphql(schema.queries.cartItemsByUserId, {
    name: 'cartItems',
    options({ userId }) {
      return { variables: { userId } }
    },
  }),
)(CartLink)
