export default function({ type, data, counter, sections }) {
  switch (type) {
    case 'newComment':
      return sections.map((s) => ({
        ...s,
        value: s.value.replace('<% comment %>', data.comment),
      }))
    default:
      return sections
  }
}
