import React from 'react'
import { Text, View } from 'react-native'
import Button from '../../components/simple/Button'
import Brand from '../../components/Brand/Brand'
import Divider from '../../components/simple/Divider'
import Scroller from '../../components/Scroller'
import NamedImage from '../../components/simple/NamedImage'
import PaymentMethod from '../Payments/PaymentMethods/PaymentMethod'
import sharedStyles, { stylus } from '../../config/styles'
import colors from '../../config/colors'
import { connect, graphql } from '../../config/connected'
import schema from '../../schema/cart'
import ordersSchema from '../../schema/orders'
import checkoutSessionsSchema from '../../schema/checkoutSession'
import { getFeatureFlag, getPrice, getTotalPrices, resolveVideoThumbnail } from '../../config/helpers'
import { updateCachedQueryValue } from '../../utility/apollo'
import { client } from '../../containers/withApollo'
import NavigationActions from '../../utility/navigationActions'
import { ROUTE_USER_ORDER_HISTORY } from '../../config/constants'
import i18n from 'i18n-js'
import _ from 'lodash'
import ErrorMessage from '../../components/simple/ErrorMessage'
import { ANALYTICS_CARTS_WITH_PRODUCTS_CLOSED } from '../../reducers/analytics'
import PropTypes from 'prop-types'

const imageSize = 44
const mapStateToProps = (state) => ({
  userId: state.currentUser.id,
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  analyticsCartsWithProductsClosed: () => {
    dispatch({
      type: ANALYTICS_CARTS_WITH_PRODUCTS_CLOSED,
      data: {},
    })
  },
})

@connect(mapStateToProps, mapDispatchToProps)
@graphql(checkoutSessionsSchema.queries.myCheckoutSession, {
  name: 'myCheckoutSessionHandler',
  options: { fetchPolicy: 'network-only' }
})
@graphql(ordersSchema.queries.ordersByCheckoutId, {
  name: 'ordersByCheckoutIdHandler',
  options({ navigation }) {
    const { checkoutId } = navigation.state.params
    return { variables: { checkoutId }, fetchPolicy: 'network-only' }
  },
})
@graphql(schema.queries.userById, {
  name: 'userByIdHandler',
  options({ userId }) {
    return { variables: { id: userId } }
  },
})
@graphql(schema.queries.cartItemsByUserId, {
  name: 'cartItemsByUserIdHandler',
  options({ userId }) {
    return { variables: { userId }, fetchPolicy: 'network-only' }
  },
})
class OrderConfirmation extends React.Component {
  static navigationOptions = (props) => {
    return {
      headerLeft: null,
      headerTitle: i18n.t('order.orderConfirmation.title')
    }
  }

  componentDidMount() {
    this.props.analyticsCartsWithProductsClosed()
  }

  getOrders() {
    const { ordersByCheckoutIdHandler, cartItemsByUserIdHandler, navigation } = this.props
    const shopsProcessed = _.get(navigation, 'state.params.shopsProcessed')
    const { ordersByCheckoutId: orders } = ordersByCheckoutIdHandler
    const { cartItemsByUserId: cartItems } = cartItemsByUserIdHandler
    const { myCheckoutSessionHandler: { myCheckoutSession } } = this.props

    const shopCartItems = {}
    for (const cartItem of cartItems) {
      const { product } = cartItem
      const { userId: shopId } = product
      if (shopCartItems[shopId] === undefined) {
        shopCartItems[shopId] = []
      }
      shopCartItems[shopId].push(cartItem)
    }

    const ordersCopy = [...orders]

    if (getFeatureFlag('stripe')) {
      for (const shopId in shopCartItems) {
        const orderItems = shopCartItems[shopId]
        const shop = _.get(orderItems[0], 'product.profile')
        const stripePaymentIntentId = _.get(myCheckoutSession, `stripeInfo.${shop.id}.paymentIntentId`)
        const paymentIntent = (_.get(myCheckoutSession, 'stripePaymentIntents') || []).find(intent => intent.id === stripePaymentIntentId)
        const last_payment_error = _.get(paymentIntent, 'last_payment_error')
        const order = {
          orderItems,
          shop,
          shopId,
          last_payment_error
        }
        ordersCopy.push(order)
      }
    }

    return ordersCopy.filter(order => shopsProcessed.includes(order.shopId))
  }

  ordersHaveErrors = (orders) => {
    let haveErrors = false
    for (const order of orders) {
      if (order.last_payment_error) {
        haveErrors = true
        break
      }
    }
    return haveErrors
  }

  allOrdersErrored = (orders) => {
    let allOrdersErrored = true
    for (const order of orders) {
      if (!order.last_payment_error) {
        allOrdersErrored = false
        break
      }
    }
    return allOrdersErrored
  }

  renderShops(orders) {
    const derivedShops = []
    let previousShopId = null
    for (const order of orders) {
      const { shopId, shop, orderItems, orderNumber, last_payment_error, stripePaymentIntent = {} } = order
      if (previousShopId) {
        derivedShops.push(
          <Divider
            key={`divider-for-shop-id-${previousShopId}`}
            style={styles.divider}
          />,
        )
      }
      const { displayName, profileImage, coverPhoto } = shop
      const totals = getTotalPrices(orderItems, (item) => item.product, undefined, order.coupon)
      const latestCharge = _.get(stripePaymentIntent, 'charges.data.[0]', {})
      const paymentMethod = latestCharge.payment_method_details
      const paymentMethodId = latestCharge.payment_method
      const isVideoCover = ['.mp4', '.mov'].some(indicator => _.invoke(coverPhoto, 'includes', indicator))

      derivedShops.push(
        <View key={`header-for-shop-id-${shopId}`}>
          <Text style={[styles.shopName, sharedStyles.h6]}>{displayName}</Text>
          <View style={styles.row}>
            <View style={styles.left}>
              <NamedImage
                style={[
                  styles.profileImage,
                  { height: imageSize, width: imageSize },
                ]}
                name={isVideoCover ? resolveVideoThumbnail(coverPhoto) : profileImage || coverPhoto}
                width={imageSize}
                height={imageSize}
                fit='crop'
                crop='faces'
                resizeMode='cover'
                resizeMethod='scale'
                placeholder='profile'
              />
            </View>
            <View style={styles.right}>
              { last_payment_error ?
                <ErrorMessage tType={'order.orderConfirmation.orderFailed'} message={last_payment_error.message}/>
                : null
              }
              { orderNumber ?
                <Text style={styles.orderNumberText}>
                  {i18n.t('order.order.orderNumber')}
                  {orderNumber}
                </Text>
                : null
              }
              {/* <PaymentMethods shop={shop} /> */}
              {paymentMethod && paymentMethodId && (
                <PaymentMethod
                  actionColumnStyle={styles.paymentActionColumn}
                  iconContainerStyle={styles.paymentCardContainer}
                  key={paymentMethodId}
                  id={paymentMethodId}
                  style={styles.paymentMethodContainer}
                  brand={paymentMethod.card.brand}
                  last4={paymentMethod.card.last4}
                  expirationMonth={paymentMethod.card.exp_month}
                  expirationYear={paymentMethod.card.exp_year}
                />
              )}
              { last_payment_error ? null :
                <View style={[styles.row, { justifyContent: 'center' }]}>
                  <Text
                    style={[styles.paymentAmountText, sharedStyles.h6, { flex: 1 }]}
                  >
                    {i18n.t('order.order.paymentAmount', {
                      amount: getPrice(totals.total),
                    })}
                  </Text>
                </View>
              }
            </View>
          </View>
        </View>,
      )
      previousShopId = shopId
    }

    return derivedShops
  }

  render() {
    console.log('<<<RENDERING ORDER CONFIRMATION')
    const {
      screenInfo,
      ordersByCheckoutIdHandler,
      userByIdHandler,
      cartItemsByUserIdHandler,
      myCheckoutSessionHandler,
    } = this.props
    if (ordersByCheckoutIdHandler.loading || userByIdHandler.loading || cartItemsByUserIdHandler.loading || myCheckoutSessionHandler.loading) {
      return null
    }

    const orders = this.getOrders()
    const haveErrors = this.ordersHaveErrors(orders)
    const { userById: user } = userByIdHandler
    return (
      <Scroller style={[styles.contentContainer]}>
        <Brand width={screenInfo.width / 2} vertical />
        <View style={[styles.messageContainer, styles.lineBreak]}>
          { haveErrors ? (
            <Text style={[styles.message]}>
              {i18n.t('order.orderConfirmation.ordersFailed', { email: user.email })}
            </Text>
          ) : (
            <React.Fragment>
              <Text style={[styles.message]}>
                {i18n.t('order.orderConfirmation.confirmationStatement')}
              </Text>
              <Text style={[styles.message, styles.lineBreak]}>
                {i18n.t('order.orderConfirmation.confirmationSentToEmail', {
                  email: user.email,
                })}
              </Text>
            </React.Fragment>
          )}
         
        </View>
        {this.renderShops(orders)}
        <View style={[styles.lineBreak]}>
          <Button
            label={i18n.t('order.orderConfirmation.done')}
            labelStyle={styles.buttonLabel}
            style={styles.button}
            onPress={haveErrors ? this.goToCheckout : this.goToOrderHistory}
          />
        </View>
      </Scroller>
    )
  }

  goToOrderHistory = async() => {
    const { userId, cartItemsByUserIdHandler } = this.props
    await cartItemsByUserIdHandler.refetch()
    await this.refreshOrderHistory()
    // NOTE: Following back actions are necessary to send us back before setting a path to the Order History screen
    NavigationActions.back() // back to Checkout
    NavigationActions.back() // back to Cart
    NavigationActions.back() // back to Settings or route before switching to Cart tab
    NavigationActions.goTo({ routeName: 'Settings' }, true) // go to Settings if not already there
    NavigationActions.navigate({
      routeName: ROUTE_USER_ORDER_HISTORY,
      params: { userId },
    }, true)
  }

  goToCheckout = async() => {
    await this.props.cartItemsByUserIdHandler.refetch()
    await this.refreshOrderHistory()
    NavigationActions.goTo({ routeName: 'Checkout', params: { displayOrdersFailedPopup: true } })
  }

  refreshOrderHistory = async() => {
    const { userId } = this.props
    const fetchMoreResult = await client.query({
      query: ordersSchema.queries.ordersByUserIdForUserType,
      variables: {
        userId,
        userType: 'user',
        offset: 0,
        limit: 5,
      },
      fetchPolicy: 'network-only'
    })
    updateCachedQueryValue(client, {
      query: ordersSchema.queries.ordersByUserIdForUserType,
      variables: { userId, userType: 'user' },
      nextValue: (previousOrders) => {
        const fetchedOrders = _.get(fetchMoreResult, 'data.ordersByUserIdForUserType', [])
        const newOrders = _.differenceBy(fetchedOrders, previousOrders, 'id')
        return newOrders.concat(previousOrders)
      }
    })
  }
}

OrderConfirmation.propTypes = {
  analyticsCartsWithProductsClosed: PropTypes.func,
}

export default OrderConfirmation

const styles = stylus({
  contentContainer: {
    flex: 1,
    paddingHorizontal: 35,
  },
  messageContainer: {
    flex: 1,
    flexDirection: 'column',
    marginBottom: 20,
  },
  message: {
    fontSize: 14,
    color: colors.text.main,
    textAlign: 'center',
  },
  callToActionText: {
    color: 'red',
    fontWeight: '600',
  },
  fulfillmentText: {
    fontSize: 12,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
  },
  left: {
    flex: 1,
  },
  right: {
    flex: 4,
  },
  profileImage: {
    borderRadius: 22,
  },
  shopName: {
    fontWeight: '600',
    marginBottom: 2,
  },
  orderNumberText: {
    color: colors.text.secondary,
    fontSize: 12,
    marginBottom: 2,
  },
  margin: {
    marginRight: 5,
  },
  iconSet: {
    flex: 1,
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
  },
  paymentInfoText: {
    color: colors.text.main,
    fontSize: 13,
    marginBottom: 2,
  },
  paymentAmountText: {
    color: 'green',
    fontWeight: '600',
    textAlign: 'left',
  },
  paymentMethodContainer: {
    marginVertical: 10,
    flex: 1,
  },
  paymentActionColumn: {
    flex: 0
  },
  paymentCardContainer: {
    alignItems: 'flex-start'
  },
  lineBreak: {
    marginTop: 20,
  },
  bold: {
    fontWeight: '600',
  },
  divider: {
    marginVertical: 10,
  },
  button: {
    backgroundColor: colors.primary,
    marginBottom: 9,
  },
  buttonLabel: {
    fontSize: 16,
  },
})
