import i18n from 'i18n-js'
import _ from 'lodash'
import React from 'react'
import { ActivityIndicator, KeyboardAvoidingView, Platform, Text, View, InteractionManager, BackHandler } from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import { hoistStatics, withProps } from 'recompose'
import Scroller from '../../components/Scroller'
import Action from '../../components/simple/Action'
import HorizontalInput from '../../components/simple/HorizontalInput'
import UploadButton from '../../components/simple/UploadButton'
import TagsInput from '../../components/TagsInput'
import branch from '../../config/branch'
import colors from '../../config/colors'
import { CATEGORIES_PRODUCER, CATEGORIES_PROFESSIONAL } from '../../config/constants'
import { getKeyboardPaddingStyle, renderWhileLoading, removeUploadsFromCache } from '../../config/helpers'
import { connect, graphql, compose } from '../../config/connected'
import sizes from '../../config/sizes'
import shared, { stylus } from '../../config/styles'
import KeyboardShift from '../../containers/KeyboardShift'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'
import schema from '../../schema/user'
import onboardingSchema from '../../screens/Start/schema'
import ProfileImage from '../Profile/ProfileImage'
import { ANALYTICS_PROFILE_EDIT } from '../../reducers/analytics'
import { USER_SET_PROFILE_IMAGE_UPLOAD, USER_UNSET_PROFILE_IMAGE_UPLOAD } from '../../reducers/currentUser'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import { EditProfileSchema } from '../../validation'
import Button from '../../components/simple/Button'
import { CONTENT_IS_PENDING } from '../../reducers/publishContent'

const debug = false

// const ADDRESS_MAX_LENGTH = 45

// const ASPECT_RATIOS = {
//   landscape: 0.4,
//   portrait: 0.48,
// }
// const CENTER_IMAGE_SIZE = 118
const isWeb = Platform.OS === 'web'

const Loading = () => <ActivityIndicator style={{ flex: 1, width: '100%' }} size='large'/>

const mapInterests = (interests) => {
  // const { interests } = this.state
  return _.map(interests, (item) => {
    return {
      label: i18n.t(`categories.${getCategoryTagLabel(item)}.${item}`),
      value: item,
    }
  })
}

const navigationOptionsCallbacks = {
  saveAction: () => {},
  back: () => {}
}

class EditProfile extends React.Component {

  inputs = new Map()

  constructor(props) {
    super(props)
    if (Platform.OS === 'android') {
      this.watchBackButton()
    }
  }

  watchBackButton() {
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      this.back()
      return true
    })
  }

  componentDidMount() {
    navigationOptionsCallbacks.saveAction = this.props.handleSubmit
    navigationOptionsCallbacks.back = this.back
  }

  componentWillUnmount() {
    this.backHandler && this.backHandler.remove()
  }

  back = () => {
    if (this.props.values.profileImage) {
      const upload = this.props.uploads[this.props.values.profileImage]
      removeUploadsFromCache([upload])
      this.props.dispatch({
        type: USER_UNSET_PROFILE_IMAGE_UPLOAD,
      })
    }
    NavigationActions.back()
  }

  setListRef = (ref) => {
    this.scroller = ref
  }

  renderError() {
    if (!_.chain(this.props).get('errors.server').isEmpty().value()) {
      setTimeout(() => {
        this.props.setErrors({ server: null })
      }, 3000)
      return (
        <View style={styles.toast}>
          {/*<T t={error} style={styles.toastText} />*/}
          <Text style={styles.toastText}>{this.props.errors.server}</Text>
        </View>
      )

    }
    return null
  }

  render() {
    const {
      keyboardInfo,
    } = this.props

    const keyboardPaddingStyle = getKeyboardPaddingStyle(keyboardInfo)

    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      setFieldValue
    } = this.props

    return (
      <KeyboardAvoidingView
        style={styles.outerContainer}
        behavior={Platform.OS === 'android' ? undefined : 'padding'}
        keyboardVerticalOffset={60}
      >
        <Scroller
          keyboardDismissMode='none'
          innerRef={this.setListRef}
        >
          <KeyboardShift fluid extraShiftValue={branch({ apk: sizes.tabBarHeight, other: 0})}>
            <View style={[styles.container, keyboardPaddingStyle]}>
              <View style={styles.profileImageArea}>
                <UploadButton
                  style={styles.profileUpload}
                  onChange={this.onChangeProfileImage}
                >
                  <ProfileImage
                    profileImage={values.profileImage}
                    localImage={this.props.currentUser.profileImageUpload}
                  />
                  <Text style={styles.profileImageLink}>
                    {i18n.t('common.changeProfilePhoto')}
                  </Text>
                </UploadButton>
              </View>
              <HorizontalInput
                label={i18n.t('common.bio')}
                placeholder=' '
                autoCapitalize='none'
                onChange={handleChange('bio')}
                value={values.bio}
                inputStyle={styles.bioInput}
                multiline
                numberOfLines={10}
                maxLength={150}
                onBlur={handleBlur('bio')}
              />
              <HorizontalInput
                label={i18n.t('common.firstName')}
                autoCapitalize='words'
                returnKeyType='done'
                onChange={handleChange('firstName')}
                value={values.firstName}
                autoCorrect={false}
                error={touched.firstName && errors.firstName}
                reference={(ref) => this.inputs.set('firstName', ref)}
                first
                onBlur={handleBlur('firstName')}
              />
              <HorizontalInput
                label={i18n.t('common.lastName')}
                autoCapitalize='words'
                returnKeyType='done'
                onChange={handleChange('lastName')}
                value={values.lastName}
                error={touched.lastName && errors.lastName}
                autoCorrect={false}
                reference={(ref) => this.inputs.set('lastName', ref)}
                onBlur={handleBlur('lastName')}
              />
              <HorizontalInput
                label={i18n.t('common.username')}
                autoCapitalize='none'
                returnKeyType='done'
                maxLength={30}
                onChange={handleChange('username')}
                value={values.username}
                error={touched.username && errors.username}
                autoCorrect={false}
                reference={(ref) => this.inputs.set('username', ref)}
                onBlur={handleBlur('username')}
              />
              {_.isEmpty(values.title) ??
                <HorizontalInput
                  label={i18n.t('common.title')}
                  autoCapitalize='words'
                  returnKeyType='done'
                  maxLength={50}
                  onChange={handleChange('title')}
                  value={values.title}
                  autoCorrect={false}
                  reference={(ref) => this.inputs.set('title', ref)}
                  onBlur={handleBlur('title')}
                />
              }

              <HorizontalInput
                label={i18n.t('common.phone')}
                keyboardType='phone-pad'
                value={values.phone}
                returnKeyType='done'
                onChange={handleChange('phone')}
                autoCorrect={false}
                reference={(ref) => this.inputs.set('phone', ref)}
                editable={false} // Users cannot change their phone number
                inputStyle={{ color: colors.disabled }}
                onBlur={handleBlur('phone')}
              />
              <HorizontalInput
                label={i18n.t('common.email')}
                keyboardType='email-address'
                autoCapitalize='none'
                returnKeyType='done'
                onChange={handleChange('email')}
                value={values.email}
                error={touched.email && errors.email}
                autoCorrect={false}
                reference={(ref) => this.inputs.set('email', ref)}
                onBlur={handleBlur('email')}
              />

              <InterestsTagsInput
                onChange={(value) => {
                  setFieldValue('interests', value)
                }}
                productInterests={values.interests}
              />
            </View>
          </KeyboardShift>
          { isWeb ? (
            <View style={styles.formFooter} >
              <Button
                key='cancelButton'
                style={styles.cancelAction}
                label={i18n.t('common.cancel')}
                labelStyle={[styles.actionLabel, styles.cancelActionLabel]}
                onPress={() => NavigationActions.back()}
              />
              <Button
                key='doneButton'
                style={styles.doneAction}
                label={i18n.t('common.done')}
                labelStyle={styles.actionLabel}
                onPress={navigationOptionsCallbacks.saveAction}
              />
            </View>
          ) : null }
        </Scroller>
        {this.renderError()}
      </KeyboardAvoidingView>
    )
  }


  onChangeProfileImage = (file) => {
    const previousUpload = this.props.uploads[this.props.values.profileImage]
    debug && console.log('<<<EDIT PROFILE - onChangeProfileImage - previousUpload', previousUpload)
    this.props.queueUpload(file)
    this.props.dispatch({
      type: USER_SET_PROFILE_IMAGE_UPLOAD,
      profileImageUpload: file,
    })
    this.props.setFieldValue('profileImage', file.id)
    if (previousUpload) {
      removeUploadsFromCache([previousUpload])
    }
  }
}

function InterestsTagsInput({ onChange, productInterests }) {
  const categories = [...CATEGORIES_PRODUCER, ...CATEGORIES_PROFESSIONAL]
  const onTagPress = (index) => {
    let data
    data = productInterests.slice()
    data.splice(index, 1)
    // onChange(data, targetField)
    onChange(data)
  }

  const tags = _.map(categories, (value) => {
    return {
      value,
      label: i18n.t(`categories.${getCategoryTagLabel(value)}.${value}`),
    }
  })

  return (
    <TagsInput
      // error={errors.categories}
      tags={tags}
      chosen={productInterests || []}
      placeholder={i18n.t('shop.add.placeholders.tagsCategories')}
      label={i18n.t('shop.add.fields.categories') + ': '}
      onTagsChange={(value) => {
        onChange(value, 'interests')
      }}
      onTagPress={(idx) => onTagPress(idx, 'interests')}
    />
  )
}

InterestsTagsInput.propTypes = {
  onChange: PropTypes.func,
  productInterests: PropTypes.any,
}

function getCategoryTagLabel(key) {
  if (CATEGORIES_PRODUCER.has(key)) {
    return 'producer'
  } else {
    return 'professional'
  }
}

EditProfile.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('common.editProfile'),
    headerLeft: () => (
      <Action
        label={i18n.t('common.cancel')}
        color={colors.text.main}
        light
        style={{ paddingHorizontal: 12 }}
        onPress={() => navigationOptionsCallbacks.back() }
      />
    ),
    headerRight: () => (
      <Action
        label={i18n.t('common.done')}
        light
        style={{ paddingHorizontal: 12 }}
        onPress={() => navigationOptionsCallbacks.saveAction()}
      />
    ),
  }
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
  currentUser: state.currentUser,
  uploads: state.uploads,
})

const mapDispatchToProps = (dispatch) => ({
  goBack: () => NavigationActions.back(),
  queueUpload: (file) => {
    dispatch({
      type: 'Upload/ADD',
      file,
    })
  },
  analyticsProfileEdit: (oldData, newData) => {
    let diff = Object.keys(newData).reduce((diff, key) => {
      if (oldData[key] === newData[key]) return diff
      return {
        ...diff,
        [key]: newData[key],
      }
    }, {})

    dispatch({
      type: ANALYTICS_PROFILE_EDIT,
      data: {
        oldData,
        newData,
        updatedData: diff
      },
    })
  },
  dispatch,
})

EditProfile.propTypes = {
  analyticsProfileEdit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  queueUpload: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  navigation: PropTypes.any.isRequired,
  screenInfo: PropTypes.any.isRequired,
  keyboardInfo: PropTypes.any.isRequired,
  values: PropTypes.any.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
}

const enhance = compose(
  graphql(schema.queries.currentUserFullProfile, {
    name: 'profile',
  }),
  graphql(schema.mutations.updateProfile, {
    name: 'updateProfile',
  }),
  graphql(schema.mutations.updateAddress, {
    name: 'updateAddress',
  }),
  graphql(onboardingSchema.updateInterests, {
    name: 'updateInterests',
  }),
  renderWhileLoading(Loading, 'profile'),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  WithKeyboardInfo,
  withProps((props) => {
    const id = _.get(props, 'profile.currentUser.id')
    if (id) {
      const user = props.profile.currentUser
      const personDetails = user.personDetails

      const initialValues = {
        profileImage: _.get(props, 'currentUser.profileImageUpload.id') || user.profileImage,
        firstName: personDetails && personDetails.firstName,
        lastName: personDetails && personDetails.lastName,
        username: user.username,
        title: user.title,
        phone: user.phone,
        email: user.email,
        bio: personDetails && personDetails.bio,
        interests: mapInterests(personDetails.interests),
      }
      return { initialValues, previousValues: { ...initialValues }, previousProfileImage: user.profileImage}
    }

    return {}
  }),
  withFormik({
    mapPropsToValues: (props) => props.initialValues,
    validationSchema: EditProfileSchema,
    handleSubmit: async (values, { props, setErrors }) => {
      debug && console.log('<<<EDIT PROFILE - handleSubmit')
      const upload = props.uploads[values.profileImage]
      debug && console.log('<<<EDIT PROFILE - upload', upload)
      debug && console.log('<<<EDIT PROFILE - old vs new profileImage', props.previousProfileImage, 'vs', values.profileImage)
      const hasPendingUploads = props.previousProfileImage !== values.profileImage && upload && upload.state !== 'complete'
      debug && console.log('<<<EDIT PROFILE - handleSubmit - has pending uploads?', hasPendingUploads)
      props.dispatch({
        type: CONTENT_IS_PENDING,
        contentType: 'user',
        pendingUploads: hasPendingUploads ? [upload] : [],
        allUploads: upload ? [upload] : [],
        initialValues: { ...props.previousValues, profileImage: props.previousProfileImage },
        values,
        userId: _.get(props, 'currentUser.id'),
      })
      NavigationActions.back()
    },
    displayName: 'Edit profile',
  }),
)

export default hoistStatics(enhance)(EditProfile)

const styles = stylus({
  outerContainer: {
    flex: 1,
    native: {
      // position: 'absolute',
      // left: 0,
      // top: 0,
      width: '100%',
      height: '100%',
    },
    web: {
      position: 'relative',
    },
  },
  container: {
    flex: 1,
    alignSelf: 'stretch',
    landscape: {
      ...shared.card,
      marginTop: 31,
    },
  },
  profileImageArea: {
    paddingVertical: 14,
    backgroundColor: colors.emptyArea,
    alignItems: 'center',
  },
  profileUpload: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  profileImageLink: {
    paddingTop: 12,
    color: colors.text.link,
  },
  toast: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 12,
    paddingVertical: 12,
    backgroundColor: colors.toast.background,
  },
  toastText: {
    color: colors.toast.text,
    fontSize: 14,
  },
  bioInput: {
    height: 80,
    // backgroundColor: 'green',
  },
  formFooter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 10
  },
  actionLabel: {
    fontSize: 16
  },
  cancelActionLabel: {
    color: colors.text.main
  },
  cancelAction: {
    backgroundColor: colors.button.background,
    marginRight: 10,
  },
  doneAction: {
    backgroundColor: colors.primary,
    marginRight: 20,
  }
})
