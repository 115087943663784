import React from 'react'
import { Text } from 'react-native'
import PropTypes from 'prop-types'

class Cursor extends React.Component {
  state = {
    blinkingCursorColor: 'transparent'
  }

  static defaultProps = {
    fontSize: 18,
    duration: 500,
  }

  blinkingCursorTimer = -1

  componentWillUnmout() {
    this.stopAnimation()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.blinkingCursorColor !== this.state.blinkingCursorColor
  }

  start = () => {
    this.setState({ blinkingCursorColor: this.props.color}, () =>  this.startBlinkingCursorAnimation())
  }

  stop = () => {
    this.setState({ blinkingCursorColor: 'transparent'})
    this.stopBlinkingCursorAnimation()
  }

  startAnimation = () => {
    this.blinkerCursorTimer = setInterval(() => {
      if( this.animatedTextRef )
      {
        const blinkingCursorColor = this.state.blinkingCursorColor === 'transparent' ? this.props.color : 'transparent'
        this.setState({ blinkingCursorColor })
      }
    }, this.props.duration)
  }
  stopAnimation = () => {
    clearInterval( this.blinkerCursorTimer )
    this.blinkerCursorTimer = -1
  }

  setAnimatedTextRef = (ref) => this.animatedTextRef = ref

  render() {
    const { fontSize } = this.props
    return <Text ref={this.setAnimatedTextRef} style = {{ fontSize, color: this.state.blinkingCursorColor }}>|</Text>
  }
}

Cursor.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.number,
  duration: PropTypes.number
}


export default Cursor
