import React from 'react'
import { Platform, View } from 'react-native'
import { TextField } from 'react-native-material-textfield'

import TextInput from '../ReturnKeyCompatibilityTextInput'

import colors from '../../config/colors'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'

class Input extends React.PureComponent {
  state = {
    visible: !! this.props.value,
    focused: false,
  }
  
  render() {
    const {
      containerStyle,
      inputStyle,
      suffixContainerStyle,
      onChange,
      onBlur,
      reference,
      onSelectionChange,
      onKeyPress,
      suffix: Suffix,
      error,
      hiddenLabel = false,
      ...props
    } = this.props


    return (
      <View style={[styles.container, containerStyle]}>
        <TextInput
          {...props}
          error={error}
          stateChangeHandler='onChangeText'
          Input={TextField}
          style={[styles.input, inputStyle, !!Suffix && { paddingRight: 30 }]}
          textColor={colors.input.text}
          baseColor={colors.input.base}
          underlineColorAndroid='transparent'
          tintColor={colors.input.tint}
          labelPadding={3}
          labelHeight={hiddenLabel ? 0 : 20}
          onChange={(e) => {
            if (Platform.OS === 'web') {
              onChange(e.target.value)
            }
          }}
          onFocus={() => this.setState({ visible: true, focused: true })}
          onBlur={(props) => {
            this.setState({ visible: !!this.props.value, focused: false })
            onBlur && onBlur(props)
          }}
          onKeyPress={onKeyPress}
          onChangeText={onChange}
          onSelectionChange={onSelectionChange}
          lineWidth={sizes.px}
          reference={reference}
          affixTextStyle={styles.affixTextStyle}
          inputContainerPadding={6}
        />
        
        {Suffix && this.state.visible && (this.state.focused || this.props.value) ? (
          <View style={styles.suffix}>
            <Suffix tintColor={error ? 'rgb(213, 0, 0)' : colors.input.base} />
          </View>
        ) : null }
      </View>
    )
  }
}
export default Input

const styles = stylus({
  container: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
  },
  input: {
    fontSize: 17,
  },
  affixTextStyle: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  suffix: {
    position: 'absolute',
    // backgroundColor: '#ff0', 
    right: 0,
    top: 0,
    height: 50,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 6,
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
})
