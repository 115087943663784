import React, { useState, useEffect, useMemo } from 'react'
import { SafeAreaView, View, FlatList, TouchableOpacity, Text, Alert, ActivityIndicator } from 'react-native'
import * as FileSystem from 'expo-file-system'
import { Ionicons } from '@expo/vector-icons'

import NavigationActions from '../../utility/navigationActions'
import MobileBackButton from '../../components/simple/MobileBackButton'

const HIDDEN_FOLDERS = ['36.0.0']

const FilesystemExplorer = () => {
  const [loading, setLoading] = useState(true)
  const [files, setFiles] = useState([])
  const [current, setCurrent] = useState('/')

  const _root = []

  async function readFilesystem() {
    _root.push({
      ...await FileSystem.getInfoAsync(FileSystem.documentDirectory),
      uri: FileSystem.documentDirectory,
      alias: 'Files',
      breadcrumb: '/'
    })

    _root.push({
      ...await FileSystem.getInfoAsync(FileSystem.cacheDirectory),
      uri: FileSystem.cacheDirectory,
      alias: 'Cache',
      breadcrumb: '/'
    })

    if (FileSystem.bundledAssets) {
      const info = await FileSystem.getInfoAsync(FileSystem.bundledAssets)
      if (info.exists) {
        _root.push({
          ...info,
          uri: FileSystem.bundledAssets,
          alias: 'Bundled Assets',
          breadcrumb: '/'
        })  
      }
    }

    if (FileSystem.bundleDirectory) {
      const info = await FileSystem.getInfoAsync(FileSystem.bundleDirectory)
      if (info.exists) {
        _root.push({
          ...info,
          uri: FileSystem.bundleDirectory,
          alias: 'Bundle Directory',
          breadcrumb: '/'
        })
      }
    }

    setFiles(_root)
    setLoading(false)
  }

  useEffect(() => {
    readFilesystem()
  }, [])

  const total = useMemo(() => files
    .filter(({ breadcrumb }) => breadcrumb === current )
    .reduce((total, item) => ({
      files: total.files + 1,
      size: total.size + item.size,
    }), { files: 0, size: 0 }), [current, files])
  
  async function handleEnter(directory) {
    const newBreadcrumb = directory.breadcrumb + directory.alias + '/' 
    setCurrent(newBreadcrumb)
    setLoading(true)

    const folderContent = (await readDirectotyWithInfos(directory) || []).sort(({ isDirectory: a }, { isDirectory: b }) => (a === b) ? 0 : a ? -1 : 1 )

    setFiles([...files.filter(({ breadcrumb }) => breadcrumb !== newBreadcrumb), ...folderContent])
    setLoading(false)
  }

  function handleBack() {
    const breadcrumbs = current.split('/').filter(b => !!b)

    breadcrumbs.pop()

    if (breadcrumbs.length === 0) {
      setCurrent('/')   
    } else {
      setCurrent('/' + breadcrumbs.join('/') + '/')
    }
  }

  return (
    <SafeAreaView style={{flex: 1, marginBottom: 45}}>
      <View style={{ marginHorizontal: 5, marginVertical: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <Text style={{ fontWeight: 'bold'}}>{current}</Text>
        {current !== '/' && (
          <TouchableOpacity style={{marginRight: 10}} onPress={handleBack}>
            <Ionicons name="md-arrow-back" size={24} color="black" />
          </TouchableOpacity>
        )}
      </View>
      <FlatList
        ListEmptyComponent={() => (
          <>
            {loading ? (
              <ActivityIndicator size="large" />
            ) : (
              <Text>Nothing here</Text>
            )}
          </>    
        )}
        style={{ flex: 1, marginHorizontal: 5 }}
        data={files.filter(({ breadcrumb }) => breadcrumb === current )}
        keyExtractor={(item, index) => String(index)}
        renderItem={({item}) => (
          <View>
            {item.isDirectory ? (
              <TouchableOpacity
                style={{ 
                  width: '100%',
                  padding: 5, 
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
                onPress={() => handleEnter(item)}
              >
                <Ionicons name="md-folder" size={24} color="black" style={{ marginRight: 10 }} />
                <Text style={{ fontSize: 14 }}>{item.alias}</Text>
              </TouchableOpacity>
              
            ) : (
              <TouchableOpacity style={{ width: '100%', marginBottom: 10, padding: 5 }} onPress={() => {}}>
                <Text style={{ fontSize: 12 }}>{item.alias}</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Text style={{ fontSize: 12 }}>{unixToDate(item.modificationTime).toDateString()}</Text>
                  <Text style={{ fontWeight: 'bold' }}>{prettySize(item.size || 0)}</Text>
                </View>
              </TouchableOpacity>
            )}
          </View>
        )}
      />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: 5,
          paddingVertical: 10,
          backgroundColor: '#eee',
        }}
      >
        <Text>{total.files} Files</Text>
        <Text style={{fontWeight: 'bold'}}>{prettySize(total.size || 0)}</Text>
      </View>
    </SafeAreaView>
  )
}

const unixToDate = (unix) => {
  return new Date(unix * 1000)
}

const readDirectotyWithInfos = async (directory) => {
  const contents = await FileSystem.readDirectoryAsync(directory.uri)
  if (!contents || contents.length === 0) {
    return []
  }

  return Promise.all(contents.filter(content => !HIDDEN_FOLDERS.includes(content)).map(async content => {
    const info = await FileSystem.getInfoAsync(directory.uri + content, { size: false })
    if (info.exists) {
      return {
        ...info,
        alias: content,
        uri: info.isDirectory ? normalize(info.uri) : info.uri,
        breadcrumb: directory.breadcrumb + directory.alias + '/',
      }
    }
  }))
}

const normalize = (directory) => directory.slice(-1) === '/' ? directory : directory + '/'

function prettySize(bytes, separator = '', postFix = '') {
  if (bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1)
    return `${(bytes / (1024 ** i)).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`
  }
  return ''
}

FilesystemExplorer.navigationOptions = ({ navigation }) => {
  return {
    title: 'Filesystem Explorer',
    headerLeft: () => (
      <MobileBackButton onPress={() => {
        NavigationActions.back()
      }} />
    ),
  }
}

export default FilesystemExplorer