export const NOTIFICATIONS_SET_REFRESH_FUNCTION =
  'notifications/SET_REFRESH_FUNCTION'

export default function notifications(state = {}, action) {
  switch (action.type) {
    case NOTIFICATIONS_SET_REFRESH_FUNCTION:
      return {
        ...state,
        refreshNotifications: action.payload.refreshNotifications,
      }
    default:
      return state
  }
}
