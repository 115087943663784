import React, { Component } from 'react'
import { Keyboard } from 'react-native'

const WithKeyboardInfo = (Composed) =>
  (class extends Component {
    state = {
      visible: false,
      event: null
    }

    componentDidMount() {
      this.keyboardEventListeners = [
        Keyboard.addListener('keyboardDidShow', this.onKeyboardShown),
        Keyboard.addListener('keyboardDidHide', this.onKeyboardHidden)
      ]
    }
  
    componentWillUnmount() {
      this.keyboardEventListeners && this.keyboardEventListeners.forEach((eventListener) => eventListener.remove())
    }
  
    onKeyboardShown = (e) => {
      // console.log('<<<IS KEYBOARD VISIBLE - Keyboard is visible ')
      this.setState({ visible: true, event: e })
    }
  
    onKeyboardHidden = (e) => {
      // console.log('<<<IS KEYBOARD VISIBLE - Keyboard is hidden ')
      this.setState({ visible: false, event: e })
    }

    render() {
      return (
        <Composed
          {...this.props}
          keyboardInfo={this.state}
        />
      )
    }
  })

export default WithKeyboardInfo
