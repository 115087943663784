import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { stylus } from '../../config/styles'
import colors from '../../config/colors.js'
import Icons from '@expo/vector-icons/Ionicons'
import PropTypes from 'prop-types'
import sizes from '../../config/sizes'
import TextInput from '../ReturnKeyCompatibilityTextInput/ReturnKeyCompatibilityTextInput'

class TextInputFB extends React.Component {
  static propTypes = {
    size: PropTypes.number,
    onPress: PropTypes.func,
    onClear: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    iconName: PropTypes.string,
    thin: PropTypes.bool,
  }

  static defaultProps = {
    size: 14,
    thin: false,
    pointerEvents: 'auto',
    iconStyle: {}
  }

  getRightElement = () => {
    const { rightElement, onClear, size } = this.props
    const iconSize = size * 1.8

    if (rightElement) {
      return rightElement
    } else if (onClear) {
      return (
        <TouchableOpacity onPress={onClear}>
          <Icons style={styles.icon} name='ios-close' size={iconSize} />
        </TouchableOpacity>
      )
    } else {
      return null
    }
  }

  render() {
    const {
      size,
      style,
      inputStyle,
      onPress,
      onClear,
      onChange,
      value,
      placeholder,
      iconName,
      iconStyle,
      thin,
      rightElement,
      pointerEvents,
      ...rest
    } = this.props

    const iconSize = size * 1.8
    let LeftIcon = <Icons style={[styles.icon, iconStyle]} name={iconName} size={iconSize} />
    if (onPress) {
      LeftIcon = (
        <TouchableOpacity style={styles.iconContainer} onPress={onPress}>
          <Icons style={[styles.icon, iconStyle]} name={iconName} size={iconSize} />
        </TouchableOpacity>
      )
    }


    return (
      <View
        style={[
          styles.container,
          !iconName && styles.withoutIcon,
          thin && styles.thin,
          style,
        ]}
        pointerEvents={pointerEvents}
      >
        {iconName && LeftIcon}
        <TextInput
          {...rest}
          style={[styles.input, { fontSize: size }, inputStyle]}
          value={value}
          onChangeText={onChange}
          underlineColorAndroid='transparent'
          placeholder={placeholder}
          placeholderTextColor={colors.input.placeholder}
        />
        {value ? this.getRightElement() : null}
      </View>
    )
  }
}

const styles = stylus({
  container: {
    flexDirection: 'row',
    borderRadius: 5,
    backgroundColor: colors.active,
    alignItems: 'center',
    paddingRight: 6,
  },
  thin: {
    // flex: 1, This property value can cause unintended flex in the column direction
    backgroundColor: 'transparent',
    alignItems: 'center',
    borderWidth: sizes.px,
    borderColor: colors.thinLine,
    borderRadius: 16,
  },
  withoutIcon: {
    paddingHorizontal: 10,
  },
  input: {
    paddingVertical: 10,
    flex: 1,
    fontSize: 15,
    marginBottom: -1,
    paddingHorizontal: 0,
    color: colors.input.text,
  },
  icon: {
    paddingHorizontal: 12,
    paddingTop: 2,
    color: colors.input.placeholder,
  },
})

export default TextInputFB
