import environment from '../../../config/environment'
import { ObjectSearchManager } from './ObjectSearchManager'
import i18n from 'i18n-js'

export const SEARCH_MANAGER_PEOPLE = 'PeopleSearchManager'

export const LOAD_SEARCH_PEOPLE = 'load_search_people'

export const PEOPLE_FILTER_QUERY = 'query'

export const PEOPLE_SORT_USERNAME_ASC = 'usernameAsc'
export const PEOPLE_SORT_USERNAME_DESC = 'usernameDesc'
export const PEOPLE_SORT_NAME_ASC = 'nameAsc'
export const PEOPLE_SORT_NAME_DESC = 'nameDesc'
export const PEOPLE_SORT_CREATEDAT_ASC = 'createdAtAsc'
export const PEOPLE_SORT_CREATEDAT_DESC = 'createdAtDesc'

export class PeopleSearchManager extends ObjectSearchManager {
  constructor(hostRef,  options) {
    super(hostRef, options)
    this.initialize()
  }
  initialize() {
    this.searchType = LOAD_SEARCH_PEOPLE
    this.searchManagerType = SEARCH_MANAGER_PEOPLE
    this.filterDefinitions = {
      [PEOPLE_FILTER_QUERY]: {
        param: 'query',
        label: i18n.t('search.placeholders.products'),
      },
    }
    this.searchTypeFilters = {
      [LOAD_SEARCH_PEOPLE]: [PEOPLE_FILTER_QUERY],
    }
    this.sortDefinitions = {
      [PEOPLE_SORT_USERNAME_ASC]: {
        label: i18n.t('search.filters.people.usernameAsc'),
        direction: 'asc',
        replica: 'username',
      },
      [PEOPLE_SORT_USERNAME_DESC]: {
        label: i18n.t('search.filters.people.usernameDes'),
        direction: 'desc',
        replica: 'username',
      },
      [PEOPLE_SORT_NAME_ASC]: {
        label: i18n.t('search.filters.people.nameAsc'),
        direction: 'asc',
        replica: 'display_name',
      },
      [PEOPLE_SORT_NAME_DESC]: {
        label: i18n.t('search.filters.people.nameDes'),
        direction: 'desc',
        replica: 'display_name',
      },
      [PEOPLE_SORT_CREATEDAT_ASC]: {
        label: i18n.t('search.filters.people.joinedDateAsc'),
        direction: 'asc',
        replica: 'created_at',
      },
      [PEOPLE_SORT_CREATEDAT_DESC]: {
        label: i18n.t('search.filters.people.joinedDateDes'),
        direction: 'desc',
        replica: 'created_at',
      },
    }
    this.searchTypeSorts = {
      [LOAD_SEARCH_PEOPLE]: [
        PEOPLE_SORT_USERNAME_ASC,
        PEOPLE_SORT_USERNAME_DESC,
        PEOPLE_SORT_NAME_ASC,
        PEOPLE_SORT_NAME_DESC,
        PEOPLE_SORT_CREATEDAT_ASC,
        PEOPLE_SORT_CREATEDAT_DESC,
      ],
    }
    this.indexName = environment.constants.ALGOLIA_INDEX_USERS
    this._initialize()
  }
}
