//NOTE: Based on StartButton component
import React from 'react'
import { TouchableOpacity, Text } from 'react-native'
import PropTypes from 'prop-types'
import NavigationActions from '../../utility/navigationActions'

import schema from '../../schema/conversation'
import { connect, graphql } from '../../config/connected'
import _ from 'lodash'

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
})
@graphql(schema.mutations.startPrivateConversation, {
  name: 'startPrivateConversation',
})
@connect(mapStateToProps)
class ChatWithUser extends React.Component {
  static propTypes = {
    navigation: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    defaultMessage: PropTypes.string,
    containerStyle: PropTypes.object,
    labelStyle: PropTypes.object,
  }

  static defaultProps = {
    defaultMessage: ''
  }

  constructor(props) {
    super(props)
    this.startDebounced = _.debounce(this.start, 1500, { leading: true, trailing: false }).bind(this)
  }
  render() {
    const {label, containerStyle, labelStyle} = this.props
    return (
      <TouchableOpacity style={containerStyle} onPress={this.startDebounced}>
        <Text style={labelStyle}>{label}</Text>
      </TouchableOpacity>
    )
  }

  start = async () => {
    const {
      navigation: { dispatch },
      currentUser,
      user,
      defaultMessage,
    } = this.props

    const users = [user] 
    const userIds = [user.id, currentUser.id]

    const result = await this.props.startPrivateConversation({
      variables: {
        userIds,
      },
      update: (store, { data }) => {
        const newMembership = data.startPrivateConversation
        // update conversation list
        const membershipsData = store.readQuery({
          query: schema.queries.conversationMemberships,
        })
        const allMemberships = membershipsData.conversationMemberships
        const existing = _.find(allMemberships, { id: newMembership.id })
        if (existing) {
          // do nothing
        } else {
          allMemberships.unshift(newMembership)
          store.writeQuery({
            query: schema.queries.conversationMemberships,
            data: membershipsData,
          })
        }
      },
    })
    const membershipId = _.get(result, 'data.startPrivateConversation.id')
    NavigationActions.navigate({ routeName: 'Conversation', params: { defaultMessage, id: membershipId, users } })
  }
}

export default ChatWithUser
