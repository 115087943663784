import React, { Component } from 'react'
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TextInput,
  TouchableOpacity,
} from 'react-native'

import colors from '../../config/colors'
import { stylus } from '../../config/styles'

class TopButton extends React.Component {
  render() {
    const { label, onPress, before, after, children, active } = this.props
    return (
      <TouchableOpacity
        style={[styles.container, active && styles.active]}
        onPress={onPress}
      >
        {before}
        <Text style={[styles.label, active && styles.labelActive]}>
          {label}
        </Text>
        {after}
      </TouchableOpacity>
    )
  }
}

const styles = stylus({
  container: {
    backgroundColor: 'transparent',
    paddingHorizontal: 18,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    ios: {
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
    },
  },
  active: {
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
  label: {
    color: colors.landscapeTopBar.text,
    fontSize: 13,
  },
})

export default TopButton
