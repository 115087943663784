import { gql } from '../config/connected'

const stripePaymentMethod = `
  id
  billing_details {
    name
  }
  card {
    brand
    exp_month
    exp_year
    funding
    last4
  }
  created
  default
`

export const stripeInfo = `
  stripeUserId
  paymentIntentId
  originalPaymentMethodId
`

export default {
  queries: {
    listStripeCustomerPaymentMethods: gql`
      query {
        listStripeCustomerPaymentMethods {
          ${stripePaymentMethod}
        }
      }
    `,
    defaultStripePaymentMethod: gql`
      query {
        defaultStripePaymentMethod {
          ${stripePaymentMethod}
        }
      }
    `
  },
  mutations: {
    createStripeProductsCheckoutSession: gql`
      mutation($currency: String!, $client: String!, $shopId: String!) {
        createStripeProductsCheckoutSession(currency: $currency, client: $client, shopId: $shopId)
      }
    `,
    createStripeSetupIntent: gql`
      mutation($client: String!, $paymentMethodId: String) {
        createStripeSetupIntent(client: $client, paymentMethodId: $paymentMethodId)
      }
    `,
    saveStripePaymentMethod: gql`
      mutation($paymentMethodId: String!) {
        saveStripePaymentMethod(paymentMethodId: $paymentMethodId) {
          ${stripePaymentMethod}
        }
      }
    `,
    deleteStripePaymentMethod: gql`
      mutation($paymentMethodId: String!) {
        deleteStripePaymentMethod(paymentMethodId: $paymentMethodId) {
          deletedId
          newDefaultId
        }
      }
    `,
    makeDefaultStripePaymentMethod: gql`
      mutation($paymentMethodId: String!) {
        makeDefaultStripePaymentMethod(paymentMethodId: $paymentMethodId) {
          ${stripePaymentMethod}
        }
      }
    `,
    createStripeCustomerForOrganization: gql`
      mutation($organizationId: String!) {
        createStripeCustomerForOrganization(organizationId: $organizationId)
      }
    `
  },
}
