import React from 'react'
import { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import { Text, TouchableOpacity, View } from 'react-native'
import ProfileImage from '../Post/ProfileImage'
import { PropTypes } from 'prop-types'
import UserTitle from '../UserTitle/UserTitle'
import InstaFollowButton from '../InstaFollowButton'
import { graphql } from 'react-apollo'
import schema from '../../schema/user'
import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'

const ProfileBanner = ({ profile, profileOnPress, contentStyle, extraContentStyle, children, handleFollowUser, currentUser, followButtonHidden }) => {
  const { title, organization, organizationId } = profile

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={[styles.profile]}
      key={profile.id}
      onPress={() => profileOnPress(profile)}
    >
      <ProfileImage size={42} name={profile.profileImage} />
      <View style={[styles.profileContentContainer]}> 
        <View style={[styles.profileContent, contentStyle, {width: '100%'}]}>
          <Text numberOfLines={1} style={styles.profileName}>{profile.displayName}</Text>
          <Text numberOfLines={1} style={styles.username}>{profile.username}</Text>
          <UserTitle title={title} organization={organization} organizationId={organizationId} size={13} />
        </View>
        <View style={[styles.extraProfileContent, extraContentStyle]}>
          { children }
        </View>
      </View>
      {
        currentUser.id !== profile.id && followButtonHidden !== true &&
        <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
          <InstaFollowButton onPress={handleFollowUser} position={'list'} iFollow={profile.iFollow}/>
        </View>
      }
    </TouchableOpacity>
  )
}
ProfileBanner.propTypes = {
  followButtonHidden: PropTypes.bool,
  profile: PropTypes.object,
  currentUser: PropTypes.object,
  profileOnPress: PropTypes.func,
  handleFollowUser: PropTypes.func,
  contentStyle: PropTypes.object,
  extraContentStyle: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.node)
}
ProfileBanner.defaultProps = {
  followButtonHidden: false
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
})

export default compose(
  graphql(schema.mutations.followUser, { name: 'followUser' }),
  graphql(schema.mutations.unfollowUser, { name: 'unfollowUser' }),
  withPropsOnChange(
    (props, nextProps) => props.profile.iFollow !== nextProps.profile.iFollow,
    ({ profile, followUser, unfollowUser }) => ({
      handleFollowUser: () => {
        if (profile.iFollow === undefined || profile.iFollow === null) {
          followUser({
            variables: { id: profile.id },
          })
        } else {
          unfollowUser({
            variables: { id: profile.id },
          })
        }
      },
    })),
  connect(mapStateToProps),
)(ProfileBanner)



const styles = stylus({
  profile: {
    paddingHorizontal: 10,
    // paddingVertical: 8,
    height: 79,
    flexDirection: 'row',
    alignItems: 'center',
  },
  profileContentContainer: {
    height: 79,
    marginLeft: 10,
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
    flex: 1,
    // maxWidth: '40%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  profileContent: {
    flexBasis: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    // paddingBottom: 10,
  },
  extraProfileContent: {
    marginRight: 10,
  },
  profileName: {
    fontWeight: '500',
    color: colors.text.main,
    fontSize: 15,
  },
  username: {
    fontSize: 14,
    color: colors.text.secondary,
  },
})
