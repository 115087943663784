export const FEED_REFRESH = 'feed/REFRESH'
export const FEED_SET_REFRESH_FUNCTION = 'feed/SET_REFRESH_FUNCTION'
export const FEED_REFRESH_DONE = 'feed/REFRESH_DONE'
// export const FEED_REFRESH_ERROR = 'feed/REFRESH_ERROR'

export default function feed(state = {}, action) {
  switch (action.type) {
    case FEED_REFRESH:
      if (state.refreshFeed) {
        state.refreshFeed()
      }
      return state
    case FEED_REFRESH_DONE:
      // setup for future use.
      return state
    case FEED_SET_REFRESH_FUNCTION:
      return { ...state, refreshFeed: action.payload.refreshFeed }
    default:
      return state
  }
}
