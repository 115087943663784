import React, { Component } from 'react'
import { AsyncStorage } from 'react-native'
import withCurrentUser from './withCurrentUser'
import { connect } from '../config/connected'
import { logout } from '../utility/apollo'

@withCurrentUser
@connect(null, (dispatch) => ({dispatch}))
class BlockedUserManager extends Component {

  componentDidMount() {
    this.checkIfBlocked()
  }
  
  componentDidUpdate() {
    this.checkIfBlocked()
  }

  async checkIfBlocked() {
    const user = this.getUser()
    const { dispatch } = this.props
    const ghost = await AsyncStorage.getItem('GHOST')
    if (user && user.blocked && !ghost) {
      logout(user.id)
    }
  }

  getUser() {
    const { currentUser: currentUserHandler } = this.props
    if (currentUserHandler.loading || currentUserHandler.error) {
      return
    }
    const { currentUser } = currentUserHandler
    return currentUser
  }
  
  render() {
    return null
  }
}

export default BlockedUserManager
