import React from 'react'
import {
  Button,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  View,
} from 'react-native'

import { connect } from 'react-redux'
import colors from '../../config/colors'
import isSafari from '../../config/isSafari'

class Blurry extends React.Component {
  render() {
    const {
      style,
      tint = 'light',
      intensity = 99,
      backgroundColor = 'rgba(255,255,255,0.93)',
      ...others
    } = this.props

    const safari = {
      WebkitBackdropFilter: 'blur(15px)',
      backgroundColor: 'rgba(255,255,255,0.8)',
    }
    const other = {
      backgroundColor,
      WebkitBackdropFilter: 'blur(15px)',
    }

    return <View style={[isSafari() ? safari : other, style]} {...others} />
  }
}

export default Blurry
