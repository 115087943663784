import i18n from 'i18n-js'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Platform, Text, View, ActivityIndicator } from 'react-native'
import ImageCarousel from '../../../../components/ImageCarousel'
import Button from '../../../../components/simple/Button'
import Divider from '../../../../components/simple/Divider'
import UploadButton from '../../../../components/simple/UploadButton'
import T from '../../../../components/T'
import colors from '../../../../config/colors'
import { connect } from '../../../../config/connected'
import { stylus } from '../../../../config/styles'

@connect(({ screenInfo }) => ({ screenInfo }))
class UploadImages extends PureComponent {
  static propTypes = {
    itemImages: PropTypes.array,
    screenInfo: PropTypes.object,
    addImage: PropTypes.func,
    removeImage: PropTypes.func,
    uploads: PropTypes.object,
    isUploading: PropTypes.func,
  }

  // imageCarouselRef = React.createRef()
  currentElementIndex = null

  removeImage = () => {
    // const imagesData = this.imageCarouselRef.current.getWrappedInstance().getIndex()
    const { itemImages } = this.props
    const currentElement = itemImages[this.currentElementIndex] //imagesData.images[imagesData.index]
    if (!currentElement) {
      console.log('Could not find image to remove')
      return
    }
    const id = currentElement.hasOwnProperty('name') ? currentElement.name : currentElement.id
    this.props.removeImage(id)
  }

  imagePlaceholder = () => {
    const { screenInfo, addImage } = this.props
    const width = screenInfo.contentWidth
    const height = (width * 9) / 16
    return (
      <UploadButton
        onChange={addImage}
        allowVideo={true}
        showLoader={this.showVideoLoader}
        hideLoader={this.hideVideoLoader}
      >
        <View style={[styles.imagePlaceholder, { width, height }]}>
          <Text style={styles.placeholderImageText}>
            {i18n.t('upload.photo')}
          </Text>
        </View>
      </UploadButton>
    )
  }

  state = {
    videoLoaded: false,
    showVideoLoader: false,
  }

  showVideoLoader = () => {
    this.setState({videoLoaded: false, showVideoLoader: true})
  }
  hideVideoLoader = () => {
    this.setState({videoLoaded: true, showVideoLoader: false})
  }

  render() {
    const { videoLoaded, showVideoLoader } = this.state
    const {
      itemImages,
      addImage,
      goToNext,
      errors,
      animate,
      isUploading,
      screenInfo,
      screen
    } = this.props
    const isAndroid = Platform.OS === 'android'
    // animate() // This tanks JS performance, don't do this.
    return (
      <View style={styles.container} onLayout={animate}>
        <ImageCarousel
          images={itemImages}
          showProgress
          autoScroll
          currentScreen={screen}
          // onRef={this.imageCarouselRef}
          onIndexChange={(index) => {this.currentElementIndex = index}}
          placeholder={this.imagePlaceholder()}
          playMuted={true}
          playOnce={true}
          navigation={this.props.navigation}
        />
        <View style={[styles.padding, {position: 'relative'}]}>
          <T style={styles.titles.header} t='shop.add.titles.addPhoto' />
          <T style={styles.titles.sub} t='shop.add.titles.addPhotoHint' />
          <UploadButton
            onChange={addImage}
            allowVideo={true}
            showLoader={this.showVideoLoader}
            hideLoader={this.hideVideoLoader}
            // disabled={isUploading() && isAndroid}
          >
            <Button
              disabled
              disabledStyle={[
                // isUploading() && isAndroid
                // ? styles.button.disabled
                // : styles.button.primary,
                styles.button.primary,
                styles.button.margin,
              ]}
              label={i18n.t('shop.add.buttons.addPhoto')}
            />
          </UploadButton>
          {itemImages.length ? (
            <Button
              style={[styles.button.margin]}
              label={i18n.t('shop.add.buttons.removePhoto')}
              onPress={this.removeImage}
            />
          ) : null}
          <Divider hidden />
          <Divider hidden />
          <Button
            disabled={itemImages.length <= 0 || Object.keys(errors).length > 0} // User must upload at least one image to create a product.
            style={[styles.button.primary]}
            label={i18n.t('common.next')}
            onPress={() => {
              goToNext()
            }}
          />
          <Divider hidden />
          {(showVideoLoader && !videoLoaded) && (
            <ActivityIndicator
              size='large'
              style={{
                position: 'absolute',
                flex: 1,
                width: screenInfo.contentWidth,
                heigh: screenInfo.height
              }}
            />
          )}
        </View>
      </View>
    )
  }
}

export default UploadImages

const styles = stylus({
  container: {
    // flex: 1,
    // backgroundColor: 'green',
  },
  titles: {
    header: {
      textAlign: 'center',
      fontSize: 24,
      fontWeight: 'bold',
      marginTop: 20,
    },
    sub: {
      fontSize: 16,
      textAlign: 'center',
      // fontWeight: 'bold',
    },
  },
  button: {
    margin: {
      marginTop: 20,
    },
    primary: {
      backgroundColor: colors.primary,
    },
    disabled: {
      backgroundColor: colors.disabled,
    },
  },
  padding: {
    paddingHorizontal: 20,
  },
  imagePlaceholder: {
    backgroundColor: colors.emptyArea,
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeholderImageText: {
    padding: 30,
    fontSize: 16,
    color: colors.text.light,
    fontWeight: '300',
  },
})
