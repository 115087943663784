import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'

class BlankMessage extends React.Component {
  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>{this.props.message}</Text>
      </View>
    )
  }
}

export default BlankMessage

const styles = stylus({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 50,
  },
  text: {
    color: '#CCC',
    fontSize: 20,
    fontWeight: '300',
  },
})
