import React from 'react'
import { Text } from 'react-native'
import moment from 'moment'

class Ago extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      time: moment(props.time).fromNow(true),
    }
  }
  componentDidMount() {
    const { interval = 30000 } = this.props
    this.interval = setInterval(this.update.bind(this), interval)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.time != this.props.time) {
      this.setState({
        time: moment(nextProps.time).fromNow(true),
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  // We're using this method because of a weird bug
  // where autobinding doesn't seem to work w/ straight this.forceUpdate
  update() {
    this.setState({
      time: moment(this.props.time).fromNow(true),
    })
  }

  render() {
    const { style } = this.props
    return <Text style={style}>{this.state.time}</Text>
  }
}

export default Ago
