import React from 'react'
import { TouchableOpacity, View, Image, Picker } from 'react-native'
import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import T from '../../components/T'
import ElegantInput from '../../components/simple/ElegantInput'
import _ from 'lodash'
import countries from '../../config/countries'
import MyPicker from '../../components/simple/Picker'
import { ADDRESS_FIELD_ADDRESS1, ADDRESS_FIELD_ADDRESS2, ADDRESS_FIELD_CITY, ADDRESS_FIELD_REGION, ADDRESS_FIELD_ZIP, ADDRESS_FIELD_COUNTRY } from '../../config/constants'
import i18n from 'i18n-js'

export default class AddAddress extends React.Component {
  render() {
    const { style, placeholdersPath, titlePath, subtextPath, screenInfo, onChange, getMessage, state, errors } = this.props
    const { address1, address2, city, region, zip, country } = state
    return (
      <View style={[styles.container, style]}>
        <T t={titlePath} style={styles.title} />
        <T t={subtextPath} style={styles.subtext} />
        <ElegantInput
          style={styles.input}
          label={getMessage(`${placeholdersPath}.address1Placeholder`)}
          returnKeyType='next'
          onChange={(value) => onChange(value, ADDRESS_FIELD_ADDRESS1)}
          value={address1}
          error={errors[ADDRESS_FIELD_ADDRESS1]}
          autoCorrect={false}
        />
        <ElegantInput
          style={styles.input}
          label={getMessage( `${placeholdersPath}.address2Placeholder`)}
          returnKeyType='next'
          onChange={(value) => onChange(value, ADDRESS_FIELD_ADDRESS2)}
          value={address2}
          error={errors[ADDRESS_FIELD_ADDRESS2]}
          autoCorrect={false}
        />
        <ElegantInput
          style={styles.input}
          label={getMessage(`${placeholdersPath}.cityPlaceholder`)}
          returnKeyType='next'
          onChange={(value) => onChange(value, ADDRESS_FIELD_CITY)}
          value={city}
          error={errors[ADDRESS_FIELD_CITY]}
          autoCorrect={false}
        />
        <ElegantInput
          style={styles.input}
          label={getMessage(`${placeholdersPath}.statePlaceholder`)}
          returnKeyType='next'
          onChange={(value) => onChange(value, ADDRESS_FIELD_REGION)}
          value={region}
          error={errors[ADDRESS_FIELD_REGION]}
          autoCorrect={false}
        />
        <ElegantInput
          style={styles.input}
          label={getMessage(`${placeholdersPath}.zipPlaceholder`)}
          returnKeyType='next'
          onChange={(value) => onChange(value, ADDRESS_FIELD_ZIP)}
          value={zip}
          error={errors[ADDRESS_FIELD_ZIP]}
          autoCorrect={false}
        />
        <View style={styles.countryContainer}>
          <T
            t={`${placeholdersPath}.countryPlaceholder`}
            style={styles.pickerLabel}
          />
          <MyPicker
            style={styles.picker}
            selectedValue={country}
            onValueChange={(value) => onChange(value, ADDRESS_FIELD_COUNTRY)}
            displayValue={_.get(countries[country], `name_${i18n.locale}`)}
          >
            {_.map(countries, (country) => {
              return <Picker.Item label={country[`name_${i18n.locale}`]} key={country.key} value={country.key}/>
            })}
          </MyPicker>
        </View>
      </View>
    )
  }
}

const styles = stylus({
  container: {
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.text.main,
  },
  subtext: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '300',
    color: colors.text.secondary,
    marginBottom: 10,
  },
  input: {
    width: '100%',
    // marginVertical: 10,
  },
  pickerLabel: {
    marginTop: 20,
    color: colors.input.base,
    fontSize: 12,
    web: {
      marginBottom: 6,
    },
  },
  picker: {
    web: {
      width: '100%',
      color: colors.input.text,
    },
  },
  countryContainer: {
    alignSelf: 'stretch',
  },
})
