import React from 'react'
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native'

import shared, { stylus } from '../../config/styles'
import { connect } from 'react-redux'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
// import Image from '../Image'
import Icon from '../../config/icon'
import Icons from '@expo/vector-icons/Ionicons'
import withCurrentUser from '../../containers/withCurrentUser'
import NamedImage from '../../components/simple/NamedImage'
import { compose } from 'recompose'
import _ from 'lodash'

const ActionItem = ({ icon, color, text, onPress }) => (
  <View style={styles.actionBarItem}>
    <Icons name={icon} size={26} color={color} />
    <Text style={styles.actionBarItemText}>{text}</Text>
  </View>
)

class Compose extends React.Component {
  render() {
    const { platform, goToPosting, currentUser } = this.props

    return (
      <View style={[styles.compose, platform === 'web' && web.compose]}>
        <TouchableOpacity style={styles.top} onPress={goToPosting}>
          <NamedImage
            style={[styles.profile]}
            resizeMode='cover'
            name={_.get(currentUser, 'User.profileImage.name')}
            placeholder='profile'
            width={52}
            height={52}
            loading={currentUser.loading}
          />
          <Text style={styles.fakeInput}>What's up?</Text>
        </TouchableOpacity>
        <View style={styles.actionBar}>
          <ActionItem
            icon='ios-camera'
            color={colors.actions.photo}
            text='Photo'
            onPress={this.addPhoto.bind(this)}
          />
          <ActionItem
            icon='ios-pin'
            color={colors.actions.location}
            text='Check In'
            onPress={this.addCheckIn.bind(this)}
          />
          <ActionItem
            icon='ios-person-add'
            color={colors.actions.with}
            text='With'
            onPress={this.addMentions.bind(this)}
          />
        </View>
      </View>
    )

    // <ActionItem icon='picture' color={colors.actions.photo} text='Photo' onPress={this.addPhoto.bind(this)} />
    // <ActionItem icon='location' color={colors.actions.location} text='Check In' onPress={this.addCheckIn.bind(this)} />
    // <ActionItem icon='follow' color={colors.actions.with} text='With' onPress={this.addMentions.bind(this)} />
  }
  addPhoto() {}
  addCheckIn() {}
  addMentions() {}
}

const mapStateToProps = (state) => ({
  layout: state.screenInfo.layout,
  platform: state.screenInfo.platform,
})

const mapDispatchToProps = (dispatch) => ({
  // onResize: () => dispatch({ type: 'SCREEN_RESIZE' }),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCurrentUser,
)(Compose)

const styles = stylus({
  text: {
    alignSelf: 'stretch',
    textAlign: 'center',
  },
  compose: {
    landscape: {
      ...shared.card,
    },
    paddingTop: 15,
    alignItems: 'stretch',
    alignSelf: 'stretch',
    marginBottom: 13,
    flex: 1,
  },
  top: {
    paddingHorizontal: 13,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  profile: {
    width: 52,
    height: 52,
    borderRadius: 26,
  },
  fakeInput: {
    flex: 1,
    color: colors.placeholder,
    fontSize: 18,
    marginLeft: 12,
  },
  actionBar: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopColor: colors.thinLine,
    borderTopWidth: sizes.px,
    marginTop: 10,
  },
  actionBarItem: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
  },
  actionBarItemText: {
    paddingLeft: 10,
    fontSize: 14,
    color: '#666',
    fontWeight: '500',
  },
  image: {
    flex: 1,
  },
})

const web = {
  compose: {
    cursor: 'pointer',
  },
}
