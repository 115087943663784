console.disableYellowBox = true
import React from 'react'
import { Platform, StatusBar, View, AsyncStorage } from 'react-native'
import withApollo from '../../containers/withApollo'
import { Provider } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { stylus } from '../../config/styles'
import { applyMiddleware, compose, createStore } from 'redux'
import { enableScreens } from 'react-native-screens'

const isWeb = Platform.OS === 'web'

if (!isWeb) {
  enableScreens()
}

import { composeWithDevTools } from 'redux-devtools-extension'
import getLocaleMessages from '../../localization/getLocaleMessages'
import i18n from 'i18n-js'
import * as Localization from 'expo-localization'

import AppReducer from '../../reducers'
import AppWithNavigationState from '../../routing/AuthenticationRouter'

import config from '../../config/general'
import { setup as sentrySetup } from '../../config/sentry'
import Hydrate from '../../containers/Hydrate'
import ClearCacheManager from '../../containers/ClearCacheManager'
import CheckAppVersion from '../../containers/CheckAppVersion'
import FullScreen from '../../layouts/FullScreen'
import UploadManager from '../../containers/UploadManager'
import PublishContentManager from '../../containers/PublishContentManager'
import NotificationManager from '../../containers/NotificationManager'
import TermsModalManager from '../../containers/TermsModalManager'
import BlockedUserManager from '../../containers/BlockedUserManager'
import ChatSubscriptions from '../../components/Conversation/ChatSubscriptions'
import NotificationSubscriptions from '../Notifications/Subscriptions'
import NoticeSubscriptions from '../../admin/Notices/Subscriptions'
import RegisterDevice from '../../containers/RegisterDevice'
import AddToCartProvider from '../../containers/AddToCart'

import moment from 'moment' //Don't forget to load supported locales! English is include by default.
import 'moment/locale/it'
import ActionSheetProvider from '../../containers/ActionSheet/ActionSheetProvider'
import reduxStoreHolder from '../../utility/reduxStoreHolder'
import LinkingManager from '../../containers/LinkingManager'
import { analyticsMiddleWare } from '../../middlewares/analyticMiddleware'
import AppStateManager from '../../containers/AppStateManager/AppStateManager'

import LoadingMessage from '../../components/LoadingMessage'

//Init locale data for supported locales.
// i18n JS integration
i18n.translations = {
  en: getLocaleMessages('en'),
  it: getLocaleMessages('it'),
}

if (process.env.NODE_ENV === 'development') {
  // const localeOverride = 'it'
  const localeOverride = 'en'
  moment.locale(localeOverride)
  i18n.locale = localeOverride // Development only
} else {
  let locale
  if (isWeb) {
    locale =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage
  } else {
    locale = Localization.locale
  }
  locale = locale.slice(0, 2)
  if (!['en', 'it'].includes(locale)) {
    locale = 'it'
  }

  i18n.locale = locale
  moment.locale(locale)
}
// END OF LOCALIZATION

config()
sentrySetup()
// NOTE: Below statement is simply to test that Sentry is working.
// Sentry.captureMessage('Sentry has been installed!')

// const composeEnhancers = composeWithDevTools({realtime: true, port: 8000})
const composeEnhancers = composeWithDevTools
const composer = isWeb ? compose : composeEnhancers

const persistConfig = {
  timeout: null,
  key: 'REDUX_STORE',
  storage: AsyncStorage,
  whitelist: ['publishContent', 'uploads'],
  throttle: 1000,
}

let FinalReducer = AppReducer
if (!isWeb) {
  FinalReducer = persistReducer(persistConfig, AppReducer)
}

const store = createStore(
  FinalReducer,
  {}, // initial state
  composer(
    applyMiddleware(
      analyticsMiddleWare,
    ),
    // If you are using the devToolsExtension, you can add it here also
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' &&
      isWeb
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f,
    // (f) => f,
  ),
)

let finalStore = store
if (!isWeb) {
  finalStore = persistStore(store)
}
reduxStoreHolder.setStore(finalStore)

const PersistGateOrView = !isWeb ? PersistGate : View
const persistGateOrViewProps = !isWeb ? { persistor: finalStore, loading: () => <LoadingMessage />} : {}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hydrated: false,
    }
  }
  UNSAFE_componentWillMount() {
    return this.hydrate()
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    // this.setState({ hasError: true })
    // You can also log the error to an error reporting service
    console.log(error, info)
  }

  async hydrate() {}

  render() {
    // console.log(process.env.NODE_ENV  )
    return (
      <ActionSheetProvider style={{ flex: 1 }}>
        <View style={[styles.safeArea]}>
          <Provider store={store}>
            <PersistGateOrView {...persistGateOrViewProps}>
              <AddToCartProvider>
                <FullScreen>
                  <Hydrate>
                    <AppWithNavigationState />
                  </Hydrate>
                  <ClearCacheManager />
                  <CheckAppVersion />
                  <UploadManager />
                  <PublishContentManager />
                  <NotificationManager />
                  <TermsModalManager />
                  <BlockedUserManager />
                  <LinkingManager />
                  <ChatSubscriptions />
                  <NotificationSubscriptions />
                  <NoticeSubscriptions />
                  <RegisterDevice />
                  <StatusBar backgroundColor='white' barStyle='dark-content' />
                  <AppStateManager />
                </FullScreen>
              </AddToCartProvider>
            </PersistGateOrView>
          </Provider>
        </View>
      </ActionSheetProvider>
    )
  }
}

const styles = stylus({
  app: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  safeArea: {
    flex: 1,
    // backgroundColor: '#fff',
    // ipad: {
    //  backgroundColor: '#fbfbfb',
    // },
  },
})

export default withApollo(App)
