import React from 'react'
import { View, Text } from 'react-native'
import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import T from '../../components/T'
import TextInput from '../../components/simple/TextInput'
import Divider from '../../components/simple/Divider'
import Checkbox from '../../components/simple/Checkbox'

export default ({ state, onChange, getMessage }) => {
  const { isProducer, isFoodPro } = state
  return (
    <View style={styles.container}>
      <T style={styles.title} t='onboardingUser.producer.title' />
      <T style={styles.subtext} t='onboardingUser.producer.text' />
      <View style={styles.content}>
        <T style={styles.subtitle} t='onboardingUser.producer.labelProducer' />
        <T
          style={styles.copy}
          t='onboardingUser.producer.descriptionProducer'
        />
        <Checkbox
          onPress={() => onChange(!isProducer, 'isProducer')}
          checked={isProducer}
          labelStyle={styles.labelStyle}
          label={getMessage('onboardingUser.producer.checkboxProducer')}
          size={26}
        />
        <Divider />
        <T
          style={styles.subtitle}
          t='onboardingUser.producer.labelProfessional'
        />
        <T
          style={styles.copy}
          t='onboardingUser.producer.descriptionProfessional'
        />
        <Checkbox
          onPress={() => onChange(!isFoodPro, 'isFoodPro')}
          checked={isFoodPro}
          labelStyle={styles.labelStyle}
          label={getMessage('onboardingUser.producer.checkboxProfessional')}
          size={26}
        />
      </View>
    </View>
  )
}

const styles = stylus({
  container: {
    alignItems: 'center',
  },
  content: {
    marginTop: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    color: colors.text.main,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: '600',
    // marginTop: 10,
    color: colors.text.main,
  },
  subtext: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '300',
    color: colors.text.secondary,
    marginBottom: 10,
  },
  copy: {
    fontSize: 14,
    marginBottom: 10,
    fontWeight: '300',
    color: colors.text.content,
  },
  labelStyle: {
    color: colors.text.main,
    fontSize: 16,
  },
})
