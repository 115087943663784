import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { View, Text, Animated } from 'react-native'
import i18n from 'i18n-js'
import _ from 'lodash'
import alert from '../utility/alert'
import { _get, compose, connect, graphql, withApollo } from '../config/connected'
import { getTotalPrices } from '../config/helpers'
import colors from '../config/colors'
import sizes from '../config/sizes'
import { stylus } from '../config/styles'
import cartSchema from '../schema/cart'
import schema from '../schema/product'

import { ANALYTICS_ADDED_TO_CART_EVENT, ANALYTICS_CARTS_WITH_PRODUCTS_CREATED } from '../reducers/analytics'

export const MAX_SHOPS_PER_CART = 5
const AddToCartContext = React.createContext(null)
export const AddToCartProvider = AddToCartContext.Provider
export const AddToCartConsumer = AddToCartContext.Consumer

@graphql(cartSchema.queries.cartItemsByUserId, {
  name: 'cartItemsByUserIdHandler',
  options: (props) => {
    return { variables: { userId: props.userId } }
  },
})
@graphql(cartSchema.mutations.createCartItem, { name: 'createCartItem' })
@graphql(cartSchema.mutations.updateCartItemQuantity, {
  name: 'updateCartItemQuantity',
})
@graphql(cartSchema.mutations.deleteCartItem, { name: 'deleteCartItem' })
class AddToCart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addToCartAnimation: {
        y: new Animated.Value(0),
        top: new Animated.Value(0),
        opacity: new Animated.Value(0),
        width: new Animated.Value(this.props.screenInfo.width - 24),
        height: new Animated.Value(42),
        radius: new Animated.Value(0),
      },
      onAddToCart: this.handleAddToCart
    }
  }
  handleAddToCart = async (event, product) => {
    if (this.addingToCart) {
      return
    }
    const productId = product.id
    const deliveryLocations = _.get(product, 'deliveryLocations')
    if (deliveryLocations) {
      await alert({
        title: i18n.t('deliveryLocations.alerts.warningTitle'),
        message: i18n.t('deliveryLocations.alerts.locationRestriction', {
          name: product.name,
          locations: deliveryLocations
        }),
        options: { confirmText: i18n.t('common.continue')}
      })
    }
    
    this.addingToCart = true
    const {
      screenInfo,
      createCartItem,
      deleteCartItem,
      updateCartItemQuantity,
      userId,
      cartItemsByUserIdHandler,
    } = this.props
    const nativeEvent = event.nativeEvent

    var cartItem,
      cartItems = await cartItemsByUserIdHandler.cartItemsByUserId,
      productAlreadyInCart,
      alreadyInCartRecord

    if (_.isEmpty(cartItems) && cartItemsByUserIdHandler.loading === false) {
      this.props.analyticsCartsWithProductsCreated()
    }

    productAlreadyInCart = _.includes(
      _.map(cartItems, (item) => {
        // console.log(item.productId, navigation.state.params.id)
        if (item.productId === productId) {
          alreadyInCartRecord = item
          return true
        } else {
          return false
        }
      }),
      true,
    )

    const existsInCart = productAlreadyInCart && alreadyInCartRecord
    //console.log('<<<PRODUCT - Exists in cart already?', existsInCart)
    const stateQuantity = 1
    const ANIMATION_DURATION = 500,
    defaultWidth = screenInfo.width - 24

    //Move the add to cart bubble to the touch point.
    // this.state.addToCartAnimation.top.setOffset(
    //   nativeEvent.pageY - sizes.TOP_BAR_HEIGHT - sizes.tabBarHeight,
    // )
    this.state.addToCartAnimation.top.setValue(
      nativeEvent.pageY - 10
    )

    // Prep the element for animation, make it visible.
    this.state.addToCartAnimation.opacity.setValue(1)

    // Run all the animations in parallel
    Animated.parallel([
      Animated.timing(this.state.addToCartAnimation.y, {
        toValue: screenInfo.height - nativeEvent.pageY,
        duration: ANIMATION_DURATION,
      }),
      Animated.timing(this.state.addToCartAnimation.opacity, {
        toValue: 0,
        duration: ANIMATION_DURATION,
      }),
      Animated.timing(this.state.addToCartAnimation.width, {
        toValue: 42,
        duration: ANIMATION_DURATION / 2,
      }),
      Animated.timing(this.state.addToCartAnimation.radius, {
        toValue: 100,
        duration: ANIMATION_DURATION,
      }),
    ]).start(() => {
      this.state.addToCartAnimation.width.setValue(defaultWidth)
      this.state.addToCartAnimation.y.setValue(0)
      this.state.addToCartAnimation.radius.setValue(4)
      this.state.addToCartAnimation.opacity.setValue(0)
    })
    if (existsInCart) {
      try {
        const quantity = alreadyInCartRecord.quantity + stateQuantity
        if (quantity > 999) {
          alert({
            title: i18n.t('order.cart.maxQuantityAlertTitle'),
            message: i18n.t('order.cart.maxQuantityAlertMessage', {
              quantity: alreadyInCartRecord.quantity,
            }),
          })
          return
        }
        // console.log('<<<PRODUCT - Updating quantity of product in cart to be', quantity )
        await updateCartItemQuantity({
          variables: {
            id: alreadyInCartRecord.id,
            quantity,
          },
        })
      } catch (e) {
        console.log(e.message)
      }
    } else {
      try {
        const shopsAlreadyInTheCart = cartItems.reduce((shopsAccumulator, currentCartItem) => {
          const { profile } = currentCartItem.product
          const exist = shopsAccumulator.find(profileId => profileId === profile.id)

          if (!exist) {
            shopsAccumulator.push(profile.id)
          }

          return shopsAccumulator
        }, [])
        const currentProductShopId = product.profile.id

        if (shopsAlreadyInTheCart.length >= MAX_SHOPS_PER_CART && !shopsAlreadyInTheCart.includes(currentProductShopId)) {
          alert({
            title: i18n.t('order.cart.maxShopsPerCartAlertTitle'),
            message: i18n.t('order.cart.maxShopsPerCartAlertMessage', {
              max: MAX_SHOPS_PER_CART,
            }),
          })
          this.doneAddingToCart()
          return
        }
      } catch (e) {
        console.log('maxShopPerCartValidation: ' + e.message)
        this.doneAddingToCart()
        return
      }

      //console.log('<<<PRODUCT - Adding product to cart for the first time')
      try {
        cartItem = await createCartItem({
          variables: {
            userId: userId,
            productId: productId,
          },
        })
        this.props.analyticsAddedToCart(productId)
      } catch (e) {
        console.log('createCartItem: ' + e.message)
        this.doneAddingToCart()
        return
      }
      //console.log('<<<PRODUCT - Setting product quantity')
      try {
        await updateCartItemQuantity({
          variables: {
            id: cartItem.data.createCartItem.id,
            quantity: 1,
          },
        })
      } catch (e) {
        //console.log('<<<PRODUCT - Update quantity failed. Deleting cart item that was just created.')
        try {
          await deleteCartItem({
            variables: {
              id: cartItem.data.createCartItem.id,
            },
          })
        } catch (e) {
          this.doneAddingToCart()
          console.log('deleteCartItem: ' + e.message)
          return
        }
        this.doneAddingToCart()
        console.log('updateCartItemQuantity: ' + e.message)
        return
      }
    }

    await cartItemsByUserIdHandler.refetch()
    this._handleMinimumOrderAmountReached(productId)
    this.doneAddingToCart()
  }

  _handleMinimumOrderAmountReached = async (productId) => {
    const { client } = this.props
    const response = await client.query({
      query: schema.queries.productById,
      name: 'productHandler',
      variables: { id: productId },
    })

    const shop = _.get(response, 'data.productById.profile')

    const cartItemsByUserId = _.get(
      this.props,
      'cartItemsByUserIdHandler.cartItemsByUserId',
      [],
    )
    const itemsForStore = cartItemsByUserId.filter(
      (item) => _.get(item, 'product.profile.id') === shop.id,
    )
    const { subtotal } = getTotalPrices(
      itemsForStore,
      (cartItem) => cartItem.product,
    )
    const minimumOrderAmount = _get(
      shop,
      'producerDetails.minimumOrderAmount',
      0,
    )

    const reached = subtotal >= minimumOrderAmount

    if (reached === false) {
      const remain = minimumOrderAmount - subtotal
      alert({
        title: i18n.t('order.cart.minimumOrderNotMet.alertTitle'),
        message: i18n.t('order.cart.minimumOrderNotMet.body', {
          amount: minimumOrderAmount,
          remain,
        }),
      })
    }

    return reached
  }

  doneAddingToCart = () => {
    this.addingToCart = false
  }
  render() {
    return (
      <AddToCartProvider value={this.state.onAddToCart}>
        {this.props.children}
        <Animated.View
          pointerEvents='none'
          style={[
            {
              height: this.state.addToCartAnimation.height,
              position: 'absolute',
              top: this.state.addToCartAnimation.top,
              opacity: this.state.addToCartAnimation.opacity,
              width: this.state.addToCartAnimation.width,
              borderRadius: this.state.addToCartAnimation.radius,
              alignSelf: 'center',
              transform: [{ translateY: this.state.addToCartAnimation.y }],
              overflow: 'hidden',
            },
            styles.primaryButton,
          ]}
        />
      </AddToCartProvider>
    )
  }
}


const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
  userId: state.currentUser.id
})

const mapDispatchToProps = (dispatch) => ({
  analyticsAddedToCart: (productId) => {
    dispatch({
      type: ANALYTICS_ADDED_TO_CART_EVENT,
      data: {
        productId,
      },
    })
  },
  analyticsCartsWithProductsCreated: () => {
    dispatch({
      type: ANALYTICS_CARTS_WITH_PRODUCTS_CREATED,
      data: {},
    })
  },
})

AddToCart.propTypes = {
  children: PropTypes.node,
  cartItemsByUserIdHandler: PropTypes.object,
  screenInfo: PropTypes.object,
  userId: PropTypes.string,
  analyticsCartsWithProductsCreated: PropTypes.func,
  analyticsAddedToCart: PropTypes.func,
  createCartItem: PropTypes.func,
  deleteCartItem: PropTypes.func,
  updateCartItemQuantity: PropTypes.func,
}

const styles = stylus({
  primaryButton: {
    backgroundColor: colors.primaryDark,
  },

})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withApollo,
)(AddToCart)
