import React, { Component } from 'react'
import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  TextInput,
  TouchableOpacity,
  Animated,
} from 'react-native'

import NavigationActions from '../../utility/navigationActions'

import {
  connect,
  graphql,
  withApollo,
  compose,
  gql,
} from '../../config/connected'
import Blurry from '../../components/simple/Blurry'
import Icon from '../../config/icon'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import TopButton from '../../components/simple/TopButton'
import Brand from '../../components/Brand'
import sizes from '../../config/sizes'
import shared, { stylus } from '../../config/styles'
import Gradient from '../../components/simple/Gradient'
import Color from 'color'
import Notifications from './Notifications'
import Profile from './Profile'
import withCurrentUser from '../../containers/withCurrentUser'

const BACK_WIDTH = 26

class TopBar extends React.Component {
  constructor(props) {
    super(props)
    this.backAnimation = new Animated.Value(this.props.showBack ? 1 : 0)
    this.state = {
      notificationsOpen: false,
      profileOpen: false,
    }
  }
  static defaultProps = {
    leftContent: () => {},
    content: () => {},
  }

  render() {
    const {
      navigation,
      screenInfo,
      dispatch,
      headerTitle,
      currentUser: { loading, currentUser, error },
    } = this.props

    const {
      width,
      height,
      layout,
      platform,
      leftBarWidth,
      topBarHeight,
    } = screenInfo

    const { notificationsOpen, profileOpen } = this.state

    const name = currentUser ? currentUser.name : '...'

    return [
      <Gradient
        first={colors.landscape.topGradient.first}
        middle={colors.landscape.topGradient.second}
        second={colors.landscape.topGradient.third}
        key='1'
        style={[
          styles.container,
          { height: topBarHeight },
          platform !== 'web' && styles.containerNative,
        ]}
      >
        <View style={[styles.logoContainer, { marginTop: 12 }]}>
          <Brand white />
        </View>
        <View style={[styles.topButtonWrapper]}>
          { /* Commented out for now for 1389 until later when we will look to make improvements to UX
          <TopButton
            label='Notifications'
            onPress={this.toggleNotifications}
            before={
              <Icons
                name={'ios-notifications'}
                size={24}
                color={colors.landscapeTopBar.icon}
                style={{ paddingRight: 6 }}
              />
            }
            after={
              <Icons
                name={'ios-arrow-down'}
                size={16}
                color={colors.landscapeTopBar.icon}
                style={{ paddingTop: 4, paddingLeft: 8 }}
              />
            }
            active={notificationsOpen}
          />
          */}
          {currentUser ? (
            <TopButton
              label={name}
              onPress={this.toggleProfile}
              before={
                <Icons
                  name={'ios-contact'}
                  size={22}
                  color={colors.landscapeTopBar.icon}
                  style={{ paddingRight: 6 }}
                />
              }
              after={
                <Icons
                  name={'ios-arrow-down'}
                  size={16}
                  color={colors.landscapeTopBar.icon}
                  style={{ paddingTop: 4, paddingLeft: 8 }}
                />
              }
              active={profileOpen}
            />
          ) : (
            this.loginSignup()
          )}
        </View>
      </Gradient>,
      notificationsOpen ? <Notifications key='2' /> : null,
      profileOpen ? <Profile key='3' toggle={this.toggleProfile} /> : null,
    ]
  }
  loginSignup() {
    return [
      <TopButton
        label='Login'
        key='login'
        onPress={this.props.goToStart}
        before={
          <Icons
            name={'ios-log-in'}
            size={22}
            color={colors.landscapeTopBar.icon}
            style={{ paddingRight: 6 }}
          />
        }
      />,
      <TopButton
        label='Signup'
        key='signup'
        onPress={this.props.goToStart}
        before={
          <Icons
            name={'ios-contact'}
            size={22}
            color={colors.landscapeTopBar.icon}
            style={{ paddingRight: 6 }}
          />
        }
      />,
    ]
  }
  goToChat() {
    NavigationActions.navigate({
      routeName: 'Chat',
      action: NavigationActions.navigate({
        routeName: 'Conversations',
        params: { id: 'all' },
      }),
    })
  }
  toggleNotifications = () => {
    this.setState({
      notificationsOpen: !this.state.notificationsOpen,
      profileOpen: false,
    })
  }
  toggleProfile = () => {
    this.setState({
      profileOpen: !this.state.profileOpen,
      notificationsOpen: false,
    })
  }
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({
  goToStart: () => NavigationActions.navigate({ routeName: 'Start' }),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCurrentUser,
)(TopBar)

const styles = stylus({
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
    left: 0,
    flexDirection: 'row',
    alignItems: 'stretch',
    // backgroundColor: 'rgba(255,255,255,0.995)',
    // backgroundColor: '#fbfbfb',
    // backgroundColor: '#fdfbff',
    // backgroundColor: 'rgba(255,248,248,0.92)',
    borderBottomColor: 'rgba(0,0,0,0.1)',
    borderBottomWidth: sizes.px,
    // justifyContent: 'flex-start',
    // paddingHorizontal: 30,
    // shadowColor: 'rgba(0,0,0,0.04)',
    // shadowRadius: 2,
    // paddingVertical: 8,
    // '-webkit-backdrop-filter': 'blur(12px)', // someday :(
  },
  containerNative: {
    paddingTop: 12,
  },
  logoContainer: {
    paddingLeft: 16,
  },
  topButtonWrapper: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingRight: 28,
    flex: 1,
    justifyContent: 'flex-end',
  },
})
