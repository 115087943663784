import twitter from 'twitter-text'
import _ from 'lodash'

export function extractTags(text) {
  return twitter.extractHashtags(text)
}

export function parseText(text) {
  const hashtags = twitter.extractHashtags(text)
  const mentions = twitter.extractMentions(text)
  const urls = twitter.extractUrls(text)
  let results = [
    {
      type: 'text',
      value: text,
    },
  ]

  if (hashtags) {
    hashtags.forEach(function(hashtag) {
      results.forEach((result, i) => {
        if (result.type === 'text') {
          const sections = result.value.split('#' + hashtag)
          let split = []
          sections.forEach((section, j) => {
            split.push({
              type: 'text',
              value: section,
            })
            if (j < sections.length - 1) {
              split.push({
                type: 'tag',
                value: hashtag,
              })
            }
          })
          results[i] = split
          results = _.flatten(results)
        }
      })
    })
  }

  return results

  // if (mentions) {
  //   mentions.forEach(function (mention) {
  //     mention = '@' + mention
  //     tweet = tweet.replace(mention, mentionTemplate({ mention: mention }))
  //   })
  // }

  // if (urls) {
  //   urls.forEach(function (url) {
  //     tweet = tweet.replace(url, urlTemplate({ url: url }))
  //   })
  // }
}
