import React, { useState, useMemo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { View, Keyboard, Platform, Text, Switch } from 'react-native'
import { stylus } from '../../config/styles'
import { compose } from 'recompose'
import { withFormik } from 'formik'
import colors from '../../config/colors'
import { CouponSchema } from '../../validation/index'
import ElegantInput from '../../components/simple/ElegantInput'
import i18n from 'i18n-js'
import DateTimePicker from '@react-native-community/datetimepicker'
import { TouchableOpacity } from 'react-native-gesture-handler'
import Button from '../../components/simple/Button'
import { getDateTime } from '../../config/helpers'
import branchSwitch from '../../config/branch'
import _ from 'lodash'
import Divider from '../../components/simple/Divider'
import Icons from '@expo/vector-icons/Ionicons'
import moment from 'moment'
import { client } from '../../containers/withApollo'
import couponSchema from '../../schema/coupon'

const oneDayTime = 86400000

const CouponForm = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleBlur,
  handleSubmit,
  coupon,
}) => {
  const [startDate, setStartDate] = useState(coupon.startDate ? new Date(coupon.startDate) : new Date(moment().startOf('day')))
  const [endDate, setEndDate] = useState(coupon.endDate ? new Date(coupon.endDate) : new Date(moment().endOf('day').add(1, 'day')))
  const [dateModeStart, setDateModeStart] = useState(true)
  const [dateModeEnd, setDateModeEnd] = useState(true)
  const [showStartDatePicker, setShowStartDatePicker] = useState(false)
  const [showEndDatePicker, setShowEndDatePicker] = useState(false)

  const percentRef = useRef(null)
  const checkoutLimitRef = useRef(null)

  const [groupSwitchEnabled, setGroupSwitchEnabled] = useState(!!coupon.group)
  
  const nameToGroup = useMemo(() => {
    const [name,...rest] = _.get(values, 'name','').split('0')
    return {
      enable: !!rest.join() && !!name && name.length === 3, 
      name: name.toUpperCase()
    }
  }, [values.name])

  useEffect(() => {
    if (coupon.name === _.get(values, 'name','')) return

    if (groupSwitchEnabled && !nameToGroup.enable && nameToGroup.name !== coupon.group) {
      groupToggle()
    } else if (!groupSwitchEnabled && nameToGroup.enable) {
      groupToggle()
    }
  }, [nameToGroup])

  const groupToggle = () => {
    if (!nameToGroup.enable && !groupSwitchEnabled) return

    if (groupSwitchEnabled) {
      setFieldValue('group', '')
    } else {
      setFieldValue('group', nameToGroup.name)
      groupInformation(nameToGroup.name)
    }
    setGroupSwitchEnabled(!groupSwitchEnabled)
  }

  const groupInformation = async (groupName) => {
    if(!groupName) return

    const { data } = await client.query({
      query: couponSchema.queries.couponGroupDetails,
      name: 'couponGroupDetails',
      variables: {
        group: groupName
      },
    })

    if (data.couponGroupDetails) {
      const { couponGroupDetails } = data
      if (couponGroupDetails.redemptionLimitPerUser) {
        setFieldValue('redemptionLimitPerUser', couponGroupDetails.redemptionLimitPerUser.toString())
      }
      if (couponGroupDetails.redemptionLimitPerCoupon) {
        setFieldValue('redemptionLimitPerCoupon', couponGroupDetails.redemptionLimitPerCoupon.toString())
      }
    }
  }
  const isUsed = !!coupon.redemptions

  const onStartChange = (selectedDate) => {
    Platform.OS === 'android' ? hidePickers() : false
    setStartDate(selectedDate)
  }
  const onEndChange = (selectedDate) => {
    Platform.OS === 'android' ? hidePickers() : false
    setEndDate(selectedDate)
  }
  const hidePickers = () => {
    setShowStartDatePicker(false)
    setShowEndDatePicker(false)
  }

  const startPickerMemo = useMemo(() => {
    return showStartDatePicker && (
      <DateTimePicker
        testID="dateTimePicker"
        value={startDate}
        mode={dateModeStart ? 'date' : 'time'}
        minimumDate={new Date().setHours(0,0,0,0)}
        is24Hour={true}
        display="default"
        onChange={(event, selectedDate) => {
          const currentDate = selectedDate || startDate
          setFieldValue('startDate', currentDate)
          onStartChange(currentDate)
          if (currentDate.getTime() > endDate.getTime()) {
            const diff = moment(currentDate).diff(endDate, 'days') + 1
            const newEndDate = new Date(endDate.getTime() + oneDayTime * diff)
            setFieldValue('endDate', newEndDate)
            onEndChange(newEndDate)
          }
        }}
      />
    )
  }, [startDate, showStartDatePicker, dateModeStart])
  const endPickerMemo = useMemo(() => {
    return showEndDatePicker && (
      <DateTimePicker
        testID="dateTimePicker"
        value={endDate}
        mode={dateModeEnd ? 'date' : 'time'}
        minimumDate={new Date().setHours(0,0,0,0)}
        is24Hour={true}
        display="default"
        onChange={(event, selectedDate) => {
          const currentDate = selectedDate || endDate
          setFieldValue('endDate', currentDate)
          onEndChange(currentDate)
          if (currentDate.getTime() < startDate.getTime()) {
            const diff = moment(startDate).diff(currentDate, 'days') + 1
            const newStartDate = new Date(startDate.getTime() - oneDayTime * diff)
            setFieldValue('startDate', newStartDate)
            onStartChange(newStartDate)
          }
        }}
      />
    )
  }, [endDate, showEndDatePicker, dateModeEnd])
  return (
    <View>
      <ElegantInput
        key={'name'}
        style={styles.input}
        label={i18n.t('coupon.create.name')}
        returnKeyType='next'
        onChange={handleChange('name')}
        onBlur={handleBlur('name')}
        value={values['name']}
        error={touched['name'] && errors['name']}
        autoCorrect={false}
        onFocus={hidePickers}
        disabled={isUsed}
      />

      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Text>{i18n.t('coupon.create.group')}</Text>
        <Switch
          style={{ marginRight: 5 }}
          trackColor={{ false: colors.active, true: colors.primary }}
          thumbColor={thumbColor}
          value={groupSwitchEnabled}
          onValueChange={groupToggle}
        />
      </View>

      {groupSwitchEnabled ? (
        <ElegantInput
          key={'group'}
          style={[styles.input]}
          hiddenLabel
          value={values['group']}
          disabled
        />
      ) : (
        <Divider style={{marginVertical: 10}} />
      )}

      <ElegantInput
        keyboardType={'decimal-pad'}
        key={'percent'}
        style={styles.input}
        label={i18n.t('coupon.create.percent')}
        returnKeyType='next'
        onChange={handleChange('percent')}
        onBlur={() => {
          if (percentRef.current) {
            const valueWithoutComa = percentRef.current.props.value.replace(',', '.')
            setFieldValue('percent', valueWithoutComa)  
          }
        }}
        value={values['percent']}
        error={touched['percent'] && errors['percent']}
        autoCorrect={false}
        onFocus={hidePickers}
        ref={percentRef}
      />
      <View style={styles.dateTimeContainer}>
        <View style={styles.fieldDate}>
          <TouchableOpacity
            onPress={ () => {
              Keyboard.dismiss()
              setShowEndDatePicker(false)

              if (showStartDatePicker && !dateModeStart) {
                setDateModeStart(true)
                return
              }
              setDateModeStart(true)
              setShowStartDatePicker(!showStartDatePicker)
            }}
          >
            <ElegantInput
              key={'startDate'}
              style={styles.input}
              label={i18n.t('coupon.create.dateStart')}
              returnKeyType='next'
              onChange={handleChange('startDate')}
              onBlur={handleBlur('startDate')}
              value={getDateTime(startDate)}
              error={touched['startDate'] && errors['startDate']}
              autoCorrect={false}
              disabled
            />
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          style={styles.clockButton}
          onPress={() => {
            Keyboard.dismiss()
            setShowEndDatePicker(false)
            if (showStartDatePicker && dateModeStart) {
              setDateModeStart(false)
              return
            }
            setDateModeStart(false)
            setShowStartDatePicker(!showStartDatePicker)
          }}
        >
          <Icons name='md-clock' size={30} color={colors.disabled} />
        </TouchableOpacity>
      </View>
      {startPickerMemo}
      <View style={styles.dateTimeContainer}>
        <View style={styles.fieldDate}>
          <TouchableOpacity
            onPress={() => {
              Keyboard.dismiss()
              setShowStartDatePicker(false)
              if (showEndDatePicker && !dateModeEnd) {
                setDateModeEnd(true)
                return
              }  
              setDateModeEnd(true)
              setShowEndDatePicker(!showEndDatePicker)
            }}
          >
            <ElegantInput
              keyboardType={'decimal-pad'}
              key={'endDate'}
              style={styles.input}
              label={i18n.t('coupon.create.dateEnd')}
              returnKeyType='next'
              onChange={handleChange('endDate')}
              onBlur={handleBlur('endDate')}
              value={getDateTime(endDate)}
              error={touched['endDate'] && errors['endDate']}
              autoCorrect={false}
              disabled
            />
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          style={styles.clockButton}
          onPress={() => {
            Keyboard.dismiss()
            setShowStartDatePicker(false)
            if (showEndDatePicker && dateModeEnd) {
              setDateModeEnd(false)
              return
            }
            setDateModeEnd(false)
            setShowEndDatePicker(!showEndDatePicker)
          }}
        >
          <Icons name='md-clock' size={30} color={colors.disabled} />
        </TouchableOpacity>
      </View>
      {endPickerMemo}
      <ElegantInput
        keyboardType={'number-pad'}
        key={'redemptionLimitPerUser'}
        style={styles.input}
        label={i18n.t('coupon.create.redemptionLimitPerUser')}
        returnKeyType='next'
        onChange={handleChange('redemptionLimitPerUser')}
        onBlur={handleBlur('redemptionLimitPerUser')}
        value={values['redemptionLimitPerUser']}
        error={touched['redemptionLimitPerUser'] && errors['redemptionLimitPerUser']}
        autoCorrect={false}
        onFocus={hidePickers}
      />
      <ElegantInput
        keyboardType={'number-pad'}
        key={'redemptionLimitPerCoupon'}
        style={styles.input}
        label={i18n.t('coupon.create.redemptionLimitPerCoupon')}
        returnKeyType='next'
        onChange={handleChange('redemptionLimitPerCoupon')}
        onBlur={handleBlur('redemptionLimitPerCoupon')}
        value={values['redemptionLimitPerCoupon']}
        error={touched['redemptionLimitPerCoupon'] && errors['redemptionLimitPerCoupon']}
        autoCorrect={false}
        onFocus={hidePickers}
      />
      <ElegantInput
        keyboardType={'decimal-pad'}
        key={'checkoutAmountLimit'}
        style={styles.input}
        label={i18n.t('coupon.create.checkoutAmountLimit')}
        returnKeyType='next'
        onChange={handleChange('checkoutAmountLimit')}
        onBlur={() => {
          const valueWithoutComa = checkoutLimitRef.current.props.value.replace(',', '.')
          setFieldValue('checkoutAmountLimit', valueWithoutComa)
        }}
        value={values['checkoutAmountLimit']}
        error={touched['checkoutAmountLimit'] && errors['checkoutAmountLimit']}
        autoCorrect={false}
        onFocus={hidePickers}
        ref={checkoutLimitRef}
        suffix={({tintColor}) => (
          <Text style={{ color: tintColor, fontSize: 20, fontWeight: 'bold'}}>€</Text>
        )}
      />
      <ElegantInput
        key={'description'}
        style={styles.input}
        label={i18n.t('coupon.create.description')}
        returnKeyType='next'
        onChange={handleChange('description')}
        onBlur={handleBlur('description')}
        value={values['description']}
        error={touched['description'] && errors['description']}
        autoCorrect={false}
        onFocus={hidePickers}
      />
      <Button
        label={
          i18n.t('coupon.create.submitButton')
        }
        labelStyle={styles.buttonLabel}
        style={styles.button}
        onPress={handleSubmit}
      />
    </View>
  )
}

CouponForm.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.string,
    checkoutAmountLimit: PropTypes.number,
    description: PropTypes.string,
    createdAt: PropTypes.number,
    expiredAt: PropTypes.any,
    name: PropTypes.string,
    percent: PropTypes.number,
    redemptionLimitPerUser: PropTypes.number,
    redemptionLimitPerCoupon: PropTypes.number,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    group: PropTypes.string,
    redemptions: PropTypes.number,
  }),
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  validateForm: PropTypes.func,
  resetForm: PropTypes.func,
  setFieldValue: PropTypes.func,
  onChange: PropTypes.func,
  editToggle: PropTypes.func,
  forceValidate: PropTypes.bool,
  view: PropTypes.bool,
  isValid: PropTypes.bool,
  submitCount: PropTypes.number,
  address: PropTypes.any,
  onSubmit: PropTypes.func,
}
const styles = stylus({
  input: {
    width: '100%',
  },
  button: {
    flex: 1,
    marginHorizontal: 6,
    backgroundColor: colors.primary,
    marginBottom: 5,
    marginTop: 20,
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  dateTimeContainer: { 
    flex: 1, 
    flexDirection: 'row' 
  },
  fieldDate: {
    flex: 3
  },
  clockButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: '100%'
  }
})

const thumbColor = branchSwitch({
  android: colors.primary,
  ios: null,
  web: 'white'
})

export default compose(
  withFormik({
    validationSchema: CouponSchema,
    validateOnBlur: true,
    mapPropsToValues: ({ coupon = {} }) => ({
      id: coupon.id,
      checkoutAmountLimit: coupon.checkoutAmountLimit ? coupon.checkoutAmountLimit.toString() : '200',
      description: coupon.description || '',
      expiredAt: coupon.expiredAt || null,
      name: coupon.name || '',
      percent: coupon.percent ? coupon.percent.toString() : '10',
      redemptionLimitPerUser: coupon.redemptionLimitPerUser ? coupon.redemptionLimitPerUser.toString() : '1',
      redemptionLimitPerCoupon: coupon.redemptionLimitPerCoupon ? coupon.redemptionLimitPerCoupon.toString() : '1',
      startDate: coupon.startDate ? new Date(coupon.startDate) : moment().startOf('day'),
      endDate: coupon.endDate ? new Date(coupon.endDate).toLocaleDateString() : moment().endOf('day').add(1, 'day'),
      group: coupon.group || ''
    }),
    handleSubmit: (values, { props }) => {
      props.onSubmit({
        ...values,
        checkoutAmountLimit: Number.parseFloat(values.checkoutAmountLimit),
        redemptionLimitPerUser: Number.parseInt(values.redemptionLimitPerUser),
        redemptionLimitPerCoupon: Number.parseInt(values.redemptionLimitPerCoupon),
        percent: Number.parseFloat(values.percent),
        endDate: moment.parseZone(values.endDate).utc(), //getUTCDate(values.endDate, true)
        startDate: moment.parseZone(values.startDate).utc(), //getUTCDate(values.startDate)
      })
    },
  }))(CouponForm)
