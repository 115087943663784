
import i18n from 'i18n-js'
import colors from '../../../../config/colors'

export default function () {
  const js = /*syntax:javascript*/`
      try {
        var elements = stripe.elements({
          locale: '${i18n.locale}',
          // locale: 'it',
        });
        /**
         * Card Element
         */
        var card = elements.create("card", {
          iconStyle: "solid",
          style: {
            base: {
              iconColor: "${colors.text.main}",
              color: "${colors.text.main}",
              fontWeight: 400,
              fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
              fontSize: "16px",
              fontSmoothing: "antialiased",
      
              "::placeholder": {
                color: "${colors.text.secondary}"
              },
              ":-webkit-autofill": {
                color: "${colors.primary}"
              }
            },
            invalid: {
              iconColor: "${colors.error}",
              color: "${colors.error}"
            }
          }
        });
        card.mount("#example5-card");

        registerElements([card], "example5");
      } catch (e) {
        window.CoderaWebViewHelpers.sendPostMessage({
          type: 'debug',
          message: 'MOUNTING CARD'
        })
        window.CoderaWebViewHelpers.sendPostMessage({
          type: 'error',
          message: e
        })
      } 
  `
  return `
    <script>
      ${js}
    </script>
  `
}
