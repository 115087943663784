import { gql } from '../config/connected'
import { checkoutSessionFragment, userAddressFragment } from './fragments'

export default {
  queries: {
    myCheckoutSession: gql`
      query {
        myCheckoutSession {
          ...CheckoutSessionFragment
        }
      }
      ${checkoutSessionFragment}
    `
  },
  mutations: {
    saveCheckoutSessionAddress: gql`
      mutation($addressId: String!, $addressType: AddressTypeEnum!) {
        saveCheckoutSessionAddress(addressId: $addressId, addressType: $addressType) {
          ...UserAddressFragment
        }
      }
      ${userAddressFragment}
    `
  }
}
