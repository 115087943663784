import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'

import TextInput from './TextInput'
import Button from './Button'
import Scroller from '../Scroller'
import KeyboardShift from '../../containers/KeyboardShift'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'
import Ionicons from '@expo/vector-icons/Ionicons'
import ErrorMessage from './ErrorMessage'

import shared, { stylus } from '../../config/styles'
import colors from '../../config/colors'
import sizes from '../../config/sizes'

import i18n from 'i18n-js'

const FieldCollection = WithKeyboardInfo(({ collection, fieldValue = '', fieldError, placeholder, onChangeCallback, onSubmitCallback, onRemoveCallback }) => {
  // console.log('Field Collection', collection)
  return (
    <React.Fragment>
      <Scroller
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
        withPaddingRight={false}
      >
        <KeyboardShift fluid>
          { collection.map((fieldValue, index) => (
            <View style={[styles.fieldContainer]} key={index}>
              <Text style={[styles.field]}>{fieldValue}</Text>
              <TouchableOpacity onPress={() => onRemoveCallback(index)}>
                <Ionicons
                  style={[styles.icon]}
                  name='ios-trash'
                  size={20}
                />
              </TouchableOpacity>
            </View>
          ))}
        </KeyboardShift>
      </Scroller>
      <View style={[styles.footerContainer]}>
        <TextInput style={styles.footerField} placeholder={placeholder} value={fieldValue} onChange={onChangeCallback} autoCorrect={false} autoCapitalize='none'/>
        <Button disabled={!!fieldError} style={styles.footerButton} labelStyle={styles.footerButtonLabel} label={i18n.t('common.submit')} onPress={onSubmitCallback}/>
      </View>
      { fieldError ? <ErrorMessage message={fieldError} /> : null }
    </React.Fragment>
  )
})

const styles = stylus({
  container: {
    // flex: 1,
  },
  contentContainer: {
  },
  fieldContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
    marginBottom: 5,
  },
  field: {
    fontSize: 15,
    fontWeight: 'bold',
    color: colors.text.main
  },
  icon: {
    color: colors.error
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerField: {
    flex: 3,
    fontSize: 15,
    color: colors.text.main,
    marginRight: 10,
  },
  footerButton: {
    flex: 1,
    backgroundColor: colors.primary
  },
  footerButtonLabel: {
    fontSize: 15
  }
})



export default FieldCollection
