import React from 'react'
import { View, Text, Image } from 'react-native'
import PropTypes from 'prop-types'
import Icons from '@expo/vector-icons/Ionicons'
import MCIcons from '@expo/vector-icons/MaterialCommunityIcons'
import { stylus } from '../../config/styles'
import i18n from 'i18n-js'
import stripeLogo from '../../assets/images/stripe_logo_black.png'

class PaymentTypes extends React.Component {
  static defaultProps = {
    cash: false,
    transfer: false,
    stripe: false,
  }
  static propTypes = {
    cash: PropTypes.bool,
    transfer: PropTypes.bool,
    stripe: PropTypes.bool,
  }

  render() {
    const { cash, transfer, stripe } = this.props
    return (
      <View style={styles.container}>
        <View style={styles.iconLabelContainer}>
          <MCIcons
            name='credit-card'
            size={54}
            style={{ marginTop: -8, marginBottom: -3 }}
          />
          <View>
            <Text style={styles.labelText}>
              {i18n.t('shop.tabs.policies.creditCardsAccepted')}
            </Text>
          </View>
        </View>
      </View>
    )
  }
}
const styles = stylus({
  container: {
    flexDirection: 'row',
  },
  containerText: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconLabelContainer: {
    alignItems: 'center',
    padding: 20,
  },
  icon: {
    position: 'absolute',
    left: -16,
    top: -8,
    backgroundColor: 'transparent',
  },
  labelText: {
    backgroundColor: 'transparent',
  },
})
export default PaymentTypes
