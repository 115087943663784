import React, { Component } from 'react'
import { View, StyleSheet, Platform, Dimensions } from 'react-native'

import { stylus } from '../config/styles'
import debounce from 'debounce'
import { connect } from 'react-redux'
import colors from '../config/colors'
import BackgroundImage from './BackgroundImage'

class FullScreen extends Component {
  constructor(props) {
    super(props)
    // this.watch()
  }
  UNSAFE_componentWillMount() {
    // const debounced = debounce(this.props.onResize, 30)
    Dimensions.addEventListener('change', this.resize)
  }
  componentWillUnmount() {
    Dimensions.removeEventListener('change', this.resize)
  }
  resize = () => {
    // console.log('resize')
    this.props.onResize()
  }
  watch() {
    switch (Platform.OS) {
      case 'web':
        const debounced = debounce(this.props.onResize, 30)
        if (window.onresize) {
          window.onresize(debounced)
        } else {
          window.addEventListener('resize', debounced, false)
        }
        break
      case 'ios':
        // TODO
        // Dimensions.addEventListener('change', debounced)
        // setInterval(this.props.onResize, 1000)
        break
      case 'android':
        Dimensions.addEventListener('change', debounced)
        // TODO
        break
    }
  }

  render() {
    const { height } = this.props
    return (
      <View style={[{ height }, styles.fullScreen]}>
        <BackgroundImage />
        {this.props.children}
      </View>
    )
  }
}

const styles = stylus({
  fullScreen: {
    web: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
    native: {
      height: null,
    },
    flex: 1,
    backgroundColor: colors.mainBackground,
  },
})

const mapStateToProps = (state) => ({
  height: state.screenInfo.height,
})

const mapDispatchToProps = (dispatch) => ({
  onResize: () => dispatch({ type: 'SCREEN_RESIZE' }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FullScreen)
