import _ from 'lodash'
const initialState = {
  text: '',
  uploads: [],
}
export default function posting(state, action) {
  if (!state) {
    return initialState
  }
  let index, newState
  switch (action.type) {
    case 'Posting/SET_TEXT':
      return {
        ...state,
        text: action.text,
      }
    case 'Posting/IN_PROGRESS':
      return {
        ...state,
        inProgress: true,
      }
    case 'Posting/APPEND_UPLOAD':
      return {
        ...state,
        uploads: [
          ...state.uploads,
          {
            ...action.record,
            state: 'chosen',
            progress: 0,
          },
        ],
      }
    case 'Posting/REMOVE_UPLOAD':
      return {
        ...state,
        uploads: [
          ...state.uploads.slice(0, action.index),
          ...state.uploads.slice(action.index + 1),
        ],
      }
    case 'Posting/ADD_IMAGE':
      return {
        ...state,
        upload: {
          ...state.upload,
          state: 'saved',
          id: action.id,
          name: action.name,
        },
      }
    case 'Posting/REMOVE_IMAGE':
      return {
        ...state,
        upload: {},
      }
    case 'Posting/RESET':
      return initialState
    default:
      return state
  }
}
