import React from 'react'
import { compose, graphql, connect } from '../../config/connected'
import schema from '../../schema/shop'
import userSchema from '../../schema/user'
import { ANALYTICS_SHOP_EDITED, ANALYTICS_SHOP_STATUS_CHANGED } from '../../reducers/analytics'
import { updateCachedQueryValue } from '../../utility/apollo'
import { OBJECT_STATUSES } from '../../config/constants'

import _ from 'lodash'
import produce from 'immer'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  analyticsShopStatusChanged: (shopId, oldStatus, newStatus) => {
    dispatch({
      type: ANALYTICS_SHOP_STATUS_CHANGED,
      data: {
        shopId,
        oldStatus,
        newStatus,
      },
    })
  },
  analyticsShopEdited: (shopId, oldData, newData) => {

    let diff = Object.keys(newData).reduce((diff, key) => {
      if (oldData[key] === newData[key]) return diff
      return {
        ...diff,
        [key]: newData[key],
      }
    }, {})

    dispatch({
      type: ANALYTICS_SHOP_EDITED,
      data: {
        shopId,
        updatedData: diff,
      },
    })
  },
  dispatch,
})

function withPublishProducer(Composed) {
  return (compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(schema.mutations.updateProducer, {
      name: 'updateProducer',
    }),
    graphql(schema.mutations.updateShopStatus, {
      name: 'updateShopStatus',
    })
  )(
    class WithPublishProducer extends React.Component {
      publishProducer = async(producerData) => {
        const { payload, shop } = producerData
        this.props.updateProducer({
          variables: payload,
          update: (store, { data }) => {
            updateCachedQueryValue(store, {
              query: userSchema.queries.getShop,
              variables: { id: shop.id },
              nextValue: (shop) => {
                return produce(shop, draft => {
                  draft.coverPhoto = payload.coverPhoto
                })
              }
            })
          },
          refetchQueries: [{
            query: userSchema.queries.getShop,
            variables: { id: shop.id },
          }],
        }).then(() => {
          let newStatus = shop.status

          if (shop.status === OBJECT_STATUSES.disabled) {
            this.props.updateShopStatus({
              variables: {
                status: OBJECT_STATUSES.needsReview,
                id: shop.id,
              },
            })
            newStatus = OBJECT_STATUSES.needsReview
            this.props.analyticsShopStatusChanged(shop.id, OBJECT_STATUSES.disabled, newStatus)
          }
          const oldData = { ...this.initialData, status: shop.status }
          const keys = Object.keys(oldData)
          const newData = _.pick(this.state, keys)
          this.props.analyticsShopEdited(shop.id, oldData, newData)
        })
      }

      render() {
        return <Composed
          {...this.props}
          publishProducer={this.publishProducer}
        />
      }
    }
  ))
}

export default withPublishProducer
