import ObjectSearch from '../../screens/ObjectSearch'
import Maps from '../../screens/Maps'

export const searchRoutes = {
  ObjectSearch: {
    screen: ObjectSearch,
    path: 'search',
    
  },
  Maps: {
    screen: Maps,
    path: 'maps/:objectType',
    
  },
}
