import React from 'react'
import { Text, View, Image, Dimensions, TouchableOpacity, ActivityIndicator } from 'react-native'
import PropTypes from 'prop-types'

import shared, { stylus } from '../../config/styles'

class Button extends React.Component {
  static propTypes = {
    onPress: PropTypes.func,
    disabled: PropTypes.bool,
    childrenAfterLabel: PropTypes.bool,
    label: PropTypes.string,
    style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    labelStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    disabledStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    disabledStyleLabel: PropTypes.object,
    showLoader: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    label: '',
    childrenAfterLabel: true,
    showLoader: false,
  }

  handlePress = (e) => {
    const { onPress, disabled } = this.props
    if (!disabled) {
      onPress(e)
    }
  }
  render() {
    const {
      label,
      style,
      labelStyle,
      disabled,
      childrenAfterLabel,
      disabledStyle,
      disabledStyleLabel,
      showLoader,
      ...rest
    } = this.props
    return (
      <TouchableOpacity
        disabled={disabled}
        style={[
          styles.button,
          style,
          disabled && styles.disabledStyle,
          disabled && disabledStyle,
        ]}
        onPress={this.handlePress}
        activeOpacity={0.75}
        {...rest}
      >
        { !childrenAfterLabel ? this.props.children : null }
        {!showLoader ? (
          <Text
            style={[styles.label, labelStyle, disabled && disabledStyleLabel]}
          >
            {label}
          </Text>
        ) : <ActivityIndicator size='small' style={{  }} />
        }
        { childrenAfterLabel ? this.props.children : null }
      </TouchableOpacity>
    )
  }
}

const styles = stylus({
  button: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#D1D3D4',
    borderRadius: 5,
  },
  label: {
    fontWeight: '600',
    fontSize: 14,
    color: 'white',
    backgroundColor: 'transparent',
  },
  disabledStyle: {
    backgroundColor: '#E6E7E8',
  },
})

export default Button
