
import environment from '../../../../config/environment'
import i18n from 'i18n-js'

export default function (clientSecret) {
  const js = /*syntax:javascript*/`
    try {
      var stripe = Stripe('${environment.stripe.publicKey}', { locale: '${i18n.locale}' });
      
      function registerElements(elements, exampleName) {
        var formClass = '.' + exampleName;
        var example = document.querySelector(formClass);
      
        var form = example.querySelector('form');
        var error = form.querySelector('.error');
        var errorMessage = error.querySelector('.message');
      
        function enableInputs() {
          Array.prototype.forEach.call(
            form.querySelectorAll(
              "input[type='text'], input[type='email'], input[type='tel']"
            ),
            function(input) {
              input.removeAttribute('disabled');
            }
          );
        }
      
        function disableInputs() {
          Array.prototype.forEach.call(
            form.querySelectorAll(
              "input[type='text'], input[type='email'], input[type='tel']"
            ),
            function(input) {
              input.setAttribute('disabled', 'true');
            }
          );
        }
      
        function triggerBrowserValidation() {
          // The only way to trigger HTML5 form validation UI is to fake a user submit
          // event.
          var submit = document.createElement('input');
          submit.type = 'submit';
          submit.style.display = 'none';
          form.appendChild(submit);
          submit.click();
          submit.remove();
        }
      
        // Listen for errors from each Element, and show error messages in the UI.
        var savedErrors = {};
        elements.forEach(function(element, idx) {
          element.on('change', window.CoderaWebViewHelpers.catchError(function(event) {
            if (event.error) {
              error.classList.add('visible');
              savedErrors[idx] = event.error.message;
              errorMessage.innerText = event.error.message;
            } else {
              savedErrors[idx] = null;
      
              // Loop over the saved errors and find the first one, if any.
              var nextError = Object.keys(savedErrors)
                .sort()
                .reduce(function(maybeFoundError, key) {
                  return maybeFoundError || savedErrors[key];
                }, null);
      
              if (nextError) {
                // Now that they've fixed the current error, show another one.
                errorMessage.innerText = nextError;
              } else {
                // The user fixed the last error; no more errors.
                error.classList.remove('visible');
              }
            }
          }, 'INPUT ON CHANGE'));
        });
      
        // Listen on the form's 'submit' handler...
        form.addEventListener('submit', window.CoderaWebViewHelpers.catchError(function(e) {
          e.preventDefault();
          window.CoderaWebViewHelpers.sendPostMessage({
            type: 'debug',
            message: 'EXECUTING SUBMIT CALLBACK'
          })
          // Trigger HTML5 validation UI on the form if any of the inputs fail
          // validation.
          var plainInputsValid = true;
          Array.prototype.forEach.call(form.querySelectorAll('input'), function(
            input
          ) {
            if (input.checkValidity && !input.checkValidity()) {
              plainInputsValid = false;
            }
          });
          if (!plainInputsValid) {
            triggerBrowserValidation();
          }
      
          // Show a loading screen...
          example.classList.add('submitting');
      
          // Disable all inputs.
          disableInputs();
      
          // Gather additional customer data we may have collected in our form.
          var name = form.querySelector('#' + exampleName + '-name').value.trim();
          // var email = form.querySelector('#' + exampleName + '-email').value.trim();
          // var phone = form.querySelector('#' + exampleName + '-phone').value.trim();
          // var line1 = form.querySelector('#' + exampleName + '-address').value.trim();
          // var city = form.querySelector('#' + exampleName + '-city').value.trim();
          // var state = form.querySelector('#' + exampleName + '-state').value.trim();
          // var postal_code = form.querySelector('#' + exampleName + '-zip').value;
          // var country = form.querySelector('#' + exampleName + '-country').value.trim();
          var billing_details = {
            name: name,
            // email: email,
            // phone: phone,
            // address: {
            //   line1: line1,
            //   city: city,
            //   state: state,
            //   // postal_code: postal_code,
            //   country: country
            // },
          }

          stripe.handleCardSetup(
            '${clientSecret}',
            elements[0], // must be 'card' or 'cardNumber' element, elements[0] is 'cardNumber' element
            {
              payment_method_data: {
                billing_details: billing_details
              }
            }
          ).then(function(result) {
            example.classList.remove('submitting');
            if (result.error) {
              enableInputs();
              console.log('CARD SETUP ERROR', result.error)
              window.CoderaWebViewHelpers.sendPostMessage({
                type: 'debug',
                message: 'CARD SETUP ERROR'
              })
              window.CoderaWebViewHelpers.sendPostMessage({
                type: 'error',
                message: result.error.message
              })
            } else {
              console.log('CARD SETUP SUCCESS', result)
              window.CoderaWebViewHelpers.sendPostMessage({
                type: 'debug',
                message: 'CARD SETUP SUCCESS'
              })
              const setupIntent = result.setupIntent
              if (setupIntent.status === 'succeeded') {
                window.CoderaWebViewHelpers.sendPostMessage({
                  type: 'payment_method',
                  message: setupIntent.payment_method
                })
              }
            }
          })
          .catch(function (error) {
            console.log('CARD SETUP - Other ERROR', error.message)
            window.CoderaWebViewHelpers.sendPostMessage({
              type: 'debug',
              message: 'CARD SETUP OTHER ERROR'
            })
            window.CoderaWebViewHelpers.sendPostMessage({
              type: 'error',
              message: error.message
            })
          });
        }, 'FORM SUBMIT'));
      }
    } catch (e) {
      window.CoderaWebViewHelpers.sendPostMessage({
        type: 'debug',
        message: 'GENERAL REGISTER ELEMENTS ERROR'
      })
      window.CoderaWebViewHelpers.sendPostMessage({
        type: 'error',
        message: e
      })
    }
  `
  return `
    <script>
      ${js}
    </script>
  `
}
