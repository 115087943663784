import { gql } from '../../config/connected'

const userFields = `
  id
  username
  displayName
  profileImage
  title
  organization
  organizationId
  iFollow
`

export default {
  queries: {
    postLikers: gql`
      query($postId: String!) {
        postLikers(postId: $postId) {
          ${userFields}
        }
      }
    `,
  }
}
