import FeedFB from '../../screens/FeedFB'
import Profile from '../../screens/Profile'
import Posting from '../../screens/Posting'
import EditProfile from '../../screens/EditProfile'
import PostGallery from '../../screens/PostGallery'
import PostCommentsRoute from '../../screens/PostCommentsRoute'
import PostTags from '../../screens/PostTags'
import PostCreate from '../../screens/PostCreate'
import MediaUpload from '../../screens/MediaUpload'
import Followers from '../../screens/Followers'
import Following from '../../screens/Following'
import RecipeDetailsFB from '../../screens/RecipeDetailsFB/RecipeDetailsFB'
import PostLikesViewer from '../../screens/PostLikesViewer/PostLikesViewer'
import FollowersWidgetListViewer from '../../screens/FollowersWigetListView/FollowersWidgetListViewer'
import CommentLikesViewer from '../../screens/CommentLikesViewer/CommentLikesViewer'

export const socialRoutes = {
  Feed: {
    screen: FeedFB,
    path: '',
     
  },
  Profile: {
    screen: Profile,
    path: 'profile/:id',
    
  },
  EditProfile: {
    screen: EditProfile,
    path: 'update-info',
    
  },
  Posting: {
    screen: Posting,
    path: 'new-post',
    
  },
  PostLikesViewer: {
    screen: PostLikesViewer,
    path: 'post/:id/likes',
    tabBarVisible: false,
    
  },
  CommentLikesViewer: {
    screen: CommentLikesViewer,
    path: 'comment/:id/likes',
    tabBarVisible: false,
    
  },
  PostGallery: {
    screen: PostGallery,
    path: 'post/:id/gallery',
    tabBarVisible: false,
    
  },
  PostComments: {
    screen: PostCommentsRoute,
    path: 'post/:id/comments',
    tabBarVisible: false,
    
  },
  RecipeDetails: {
    screen: RecipeDetailsFB,
    path: 'recipe/:id',
    tabBarVisible: false,
    
  },
  PostTags: {
    screen: PostTags,
    path: 'tag/:tag',
    
  },
  PostCreate: {
    screen: PostCreate,
    path: 'post/new',
    
  },
  EditPost: {
    screen: PostCreate,
    path: 'post/edit/{id}',
    
  },
  FollowersWidgetListViewer: {
    screen: FollowersWidgetListViewer,
    path: 'follows/list/{id}',
    
  },
  Followers: {
    screen: Followers,
    path: 'followers/:id',
    
  },
  Following: {
    screen: Following,
    path: 'following/:id',
    
  },
  MediaUpload: {
    screen: MediaUpload,
    path: 'mediaUpload',
    
  },
}
