import ReportContent from '../../screens/ReportContent/ReportContent'
import {
  ROUTE_REPORT_CONTENT_COMMENT,
  ROUTE_REPORT_CONTENT_CONVERSATION,
  ROUTE_REPORT_CONTENT_MESSAGE,
  ROUTE_REPORT_CONTENT_ORGANIZATION_PROFILE,
  ROUTE_REPORT_CONTENT_POST,
  ROUTE_REPORT_CONTENT_PRODUCT,
  ROUTE_REPORT_CONTENT_RECIPE,
  ROUTE_REPORT_CONTENT_REVIEW,
  ROUTE_REPORT_CONTENT_USER_PROFILE,
} from '../../config/constants'

export const reportContentRoutes = {
  [ROUTE_REPORT_CONTENT_POST]: {
    screen: ReportContent,
    path: 'posts/:objectId/report-content',
    
  },
  [ROUTE_REPORT_CONTENT_RECIPE]: {
    screen: ReportContent,
    path: 'recipes/:objectId/report-content',
    
  },
  [ROUTE_REPORT_CONTENT_PRODUCT]: {
    screen: ReportContent,
    path: 'products/:objectId/report-content',
    
  },
  [ROUTE_REPORT_CONTENT_COMMENT]: {
    screen: ReportContent,
    path: 'comments/:objectId/report-content',
    
  },
  [ROUTE_REPORT_CONTENT_USER_PROFILE]: {
    screen: ReportContent,
    path: 'users/:objectId/report-content',
    
  },
  [ROUTE_REPORT_CONTENT_ORGANIZATION_PROFILE]: {
    screen: ReportContent,
    path: 'organizations/:objectId/report-content',
    
  },
  [ROUTE_REPORT_CONTENT_REVIEW]: {
    screen: ReportContent,
    path: 'reviews/:objectId/report-content',
    
  },
  [ROUTE_REPORT_CONTENT_MESSAGE]: {
    screen: ReportContent,
    path: 'messages/:objectId/report-content',
    
  },
  [ROUTE_REPORT_CONTENT_CONVERSATION]: {
    screen: ReportContent,
    path: 'conversations/:objectId/report-content',
    
  },
}
