import { updateCachedQueryValue } from '../../utility/apollo'
import ordersSchema from '../../schema/orders'

export const updateOrdersInCacheFunc = (loggedInUserId, producerUserId) => {
  return (store, { data }) => {
    const updatedOrder = data.markOrderItemsFulfilled || data.updateOrderFulfillment

    updateCachedQueryValue(store, {
      query: ordersSchema.queries.orderById,
      variables: { id: updatedOrder.id },
      nextValue: () => updatedOrder
    })

    updateCachedQueryValue(store, {
      query: ordersSchema.queries.ordersByUserIdForUserType,
      variables: { userId: producerUserId, userType: 'producer' },
      nextValue: upodateOrderInPlaceFunc(updatedOrder)
    })
  
    const { userId } = updatedOrder
    if (userId === loggedInUserId) {
      updateCachedQueryValue(store, {
        query: ordersSchema.queries.ordersByUserIdForUserType,
        variables: { userId, userType: 'user' },
        nextValue: upodateOrderInPlaceFunc(updatedOrder),
      })
    }
  }
}

const upodateOrderInPlaceFunc = (updatedOrder) => {
  return (orders = []) => {
    const orderIndex = orders.findIndex(order => order.id ===  updatedOrder.id)
    if (orderIndex !== -1) {
      orders.splice(orderIndex, 1, updatedOrder)
    }
    return orders
  }
}
