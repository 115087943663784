import { _get } from '../../config/connected'
import _ from 'lodash'
import { Text, View } from 'react-native'
import i18n from 'i18n-js'
import Divider from '../../components/simple/Divider'
import PropTypes from 'prop-types'
import React from 'react'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'

const Categories = ({ product, name, withDivider }) => {
  // const product = this.getProduct()
  const items = _get(product, `categories.${name}`, [])
  // Bail out if there are no allergens and render nothing.
  if (items.length === 0) {
    return null
  }

  const allergenTags = _.map(items, (value, index) => {
    return (
      <View key={index} style={styles.allergenWarningTag}>
        <Text style={styles.allergenWarningTagText}>
          {i18n.t(`categories.${name}.${value}`)}
        </Text>
      </View>
    )
  })

  return (
    <View>
      <Text style={styles.allergenWarningTitle}>
        {i18n.t(`product.sectionLabels.${name}`) + ':'}
      </Text>
      <View style={styles.allergenWarningContainer}>{allergenTags}</View>
      {withDivider && <Divider style={{ marginVertical: 10 }} hidden/>}
    </View>
  )
}

Categories.propTypes = {
  product: PropTypes.any,
  withDivider: PropTypes.bool,
  name: PropTypes.oneOf(['allergens', 'freeFrom', 'diet']),
}

const styles = stylus({
  allergenWarningTitle: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  allergenWarningContainer: {
    // paddingVertical: 4,
    // paddingHorizontal: 2,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  allergenWarningTag: {
    borderRadius: 5,
    margin: 2,
    paddingVertical: 3,
    paddingHorizontal: 6,
    backgroundColor: colors.emptyArea,
  },
  allergenWarningTagText: {
    color: colors.text.main,
    fontSize: 12,
  },
})

export default React.memo(Categories)
