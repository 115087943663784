import React from 'react'
import {
  Button,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'

export default ({ onPress, style }) => (
  <View style={[styles.container, style]} onPress={onPress}>
    <Icons name='ios-arrow-back' size={36} color={colors.navigation} />
  </View>
)

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
