import React from 'react'
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  FlatList,
  Platform,
} from 'react-native'

import NavigationActions from '../../utility/navigationActions'
import MobileBackButton from '../../components/simple/MobileBackButton'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'
import ControlledImage from '../../components/Image'
import Scroller from '../../components/Scroller'

class PostGallery extends React.Component {
  render() {
    const { post } = this.props

    if (post.loading) {
      return (
        <View style={styles.container}>
          <ActivityIndicator size='large' />
        </View>
      )
    }

    return (
      <View style={styles.container}>
        {Platform.OS === 'web' ? this.renderWebList() : this.renderNativeList()}
      </View>
    )
  }
  renderItem = ({ item }) => {
    const { contentWidth, height } = this.props.screenInfo
    return (
      <View style={styles.galleryItem}>
        <ControlledImage
          key={item.name}
          resizeMode='stretch'
          id={item.name}
          name={item.name}
          width={item.width}
          height={item.height}
          containerWidth={contentWidth}
          maxHeight={height * 0.9}
        />
      </View>
    )
  }
  getItems = () => {
    return _.get(this.props, 'post.Post.uploads', [])
  }
  keyExtractor = (item, index) => item.name
  renderSeparator = () => <View style={styles.seperator} />
  renderFooter = () => {
    if (Platform.OS === 'ios') {
      return <View style={styles.iosBottomSpace} />
    }
    return null
  }
  renderNativeList() {
    const {
      paddingLeft,
      paddingRight,
      gutterWidth,
      topBarHeight,
      contentTopPadding,
    } = this.props.screenInfo

    return (
      <FlatList
        renderItem={this.renderItem}
        data={this.getItems()}
        style={{
          flex: 1,
          paddingLeft,
          paddingRight,
          marginTop: topBarHeight,
          paddingTop: contentTopPadding,
        }}
        // ItemSeparatorComponent={this.renderSeparator}
        // ListHeaderComponent={ this.renderCompose }
        ListFooterComponent={this.renderFooter}
        keyExtractor={this.keyExtractor}
        initialNumToRender={3}
      />
    )
  }
  renderWebList() {
    return (
      <Scroller loadMore={this.loadMore}>
        <View style={styles.feedPadding} />
        {this.getItems().map((item) => this.renderItem({ item }))}
      </Scroller>
    )
  }
}

PostGallery.navigationOptions = (props) => {
  return {
    headerTitle: 'Post Images',
    headerBackTitle: 'Back',
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({})

const postQuery = gql`
  query($id: ID!) {
    Post(id: $id) {
      id
      text
      location
      createdAt
      uploads {
        name
        width
        height
        contentType
      }
      user {
        id
        name
        profileImage {
          name
        }
        backgroundImage {
          name
        }
      }
    }
  }
`

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(postQuery, {
    name: 'post',
    options: (props) => ({
      variables: {
        id: props.navigation.state.params.id,
      },
    }),
  }),
)(PostGallery)

const styles = stylus({
  container: {
    flex: 1,
  },
  galleryItem: {
    marginBottom: 8,
    landscape: {
      marginBottom: 14,
    },
  },
  feedPadding: {
    landscape: {
      height: 31,
    },
  },
  seperator: {
    height: 8,
  },
  iosBottomSpace: {
    landscape: {
      height: 30,
    },
  },
})
