import React from 'react'
import PropTypes from 'prop-types'
import { View, Text } from 'react-native'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import NamedImage from '../../components/simple/NamedImage'
import { resolveVideoThumbnail } from '../../config/helpers' 
import _ from 'lodash'
import i18n from 'i18n-js'

class PostUploading extends React.Component {
  render() {
    const { uploadName, uploadText } = this.props
    let name = uploadName
    const isVideo = ['.mp4', '.mov'].some(indicator => _.invoke(uploadName, 'includes', indicator))
    if (isVideo) {
      name = resolveVideoThumbnail(uploadName)
    }
    return (
      <View style={styles.container}>
        <NamedImage
          style={styles.image}
          name={name}
          width={36}
          height={36}
        />
        <Text numberOfLines={1} style={styles.text}>{_.truncate(uploadText, { length: 35 })}</Text>
      </View>
    )
  }
}

PostUploading.propTypes = {
  uploadName: PropTypes.string,
  uploadText: PropTypes.string,
}

export default PostUploading

const styles = stylus({
  container: {
    backgroundColor: colors.postUploadingBar,
    paddingHorizontal: 12,
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    width: 36,
    height: 36,
    borderRadius: 5,
    marginRight: 14,
  },
  text: {
    color: colors.text.main,
    fontSize: 14,
  },
})
