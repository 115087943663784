import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Text, View } from 'react-native'

import Modal from '../../simple/Modal'
import StripePayment from './StripePayment'

import { stylus } from '../../../config/styles'
import sizes from '../../../config/sizes'
import colors from '../../../config/colors'
import i18n from 'i18n-js'

class StripePaymentModal extends Component {

  render() {
    const {
      style,
      isVisible,
      shipping,
      billing,
      onCheckoutStarted
    } = this.props
    return (
      <Modal
        animationType='slide'
        visible={isVisible}
        onRequestClose={this.doNothing}
      >
        <View style={[styles.modalContainer, style]}>
          {/* <View style={[styles.modalHeader]}>
            <TouchableOpacity onPress={this.close}>
              <Icons
                style={styles.closeIcon}
                name='ios-close'
                size={50}
              />
            </TouchableOpacity>
          </View> */}
          <View style={styles.modalTitleContainer}>
            <Text style={styles.modalTitle}>{i18n.t('order.checkout.processing.title')}</Text>
          </View>
          <StripePayment shipping={shipping} billing={billing} onCheckoutStarted={onCheckoutStarted} onAuthFailure={this.onAuthFailure} onCompletion={this.onCompletion} />
        </View>
      </Modal>
    )
  }

  doNothing = () => {
    // we do not want the Android back button to close the modal
  }

  close = () => {
    this.props.closeAction()
  }

  onCompletion = () => {
    this.close()
  }

  onAuthFailure = () => {
    // TODO: Provide Option to Skip Once
  }
}

StripePaymentModal.propTypes = {
  style: PropTypes.object,
  shipping: PropTypes.object.isRequired,
  billing: PropTypes.object,
  isVisible: PropTypes.bool,
  closeAction: PropTypes.func.isRequired,
  onCheckoutStarted: PropTypes.func.isRequired,
}

export default StripePaymentModal

const styles = stylus({
  modalContainer: {
    flex: 1,
    web: {
      paddingTop: 15,
    },
    ios: {
      paddingTop: sizes.iosStatusBarHeight,
    },
    iphonex: {
      paddingTop: sizes.TOP_BAR_HEIGHT,
    },
  },
  closeIcon: {
    marginLeft: 15,
    color: colors.text.main,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  modalTitleContainer: {
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 17,
    fontWeight: '600',
    color: colors.text.main,
  }
})
