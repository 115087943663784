import React, { useRef } from 'react'
import { Text, TextInput, Platform, View, TouchableOpacity, Clipboard, Animated } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { PropTypes } from 'prop-types'
import i18n from 'i18n-js'

const TextSelectable = ({ children, copyText = '', ...rest }) => {
  const fadeText = useRef(new Animated.Value(1)).current
  const fadeCopy = useRef(new Animated.Value(0)).current

  function handleCopy() {
    Clipboard.setString(copyText)

    Animated.sequence([
      Animated.timing(fadeText, { toValue: 0.5, duration: 300 }),
      Animated.timing(fadeCopy, { toValue: 1, duration: 0 }),
      Animated.parallel([
        Animated.timing(fadeCopy, { toValue: 0, duration: 800 }),
        Animated.timing(fadeText, { toValue: 1, duration: 200, delay: 200 })
      ])
    ]).start()
  }

  return (
    <Animated.View style={{
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}>
      <Animated.View style={{ 
        flex: 1,
        opacity: fadeText, 
      }}>
        {Platform.OS === 'ios' ? (
          <TextInput
            {...rest}
            editable={false}
            multiline
          >
            {children}
          </TextInput>
        ) : (
          <Text
            {...rest}
            selectable
          >
            {children}
          </Text>          
        )}
      </Animated.View>
      {!!copyText && (
        <TouchableOpacity
          onPress={handleCopy}
          hitSlop={{top: 10, bottom: 10, left: 10, right: 10}}
        >
          <Animated.Text style={{
            position: 'absolute',
            opacity: fadeCopy,
            width: 100,
            textAlign: 'right', alignSelf: 'stretch',
            right: 20,
            bottom: fadeCopy.interpolate({
              inputRange: [0,1],
              outputRange: [40, 0],
            }),
          }}>{i18n.t('common.copied')}</Animated.Text>

          <MaterialCommunityIcons
            style={{ marginLeft: 10 }}
            name="content-copy"
            size={20}
            color="black"
          />
        </TouchableOpacity>
      )}
    </Animated.View>
  )
}

TextSelectable.propTypes = {
  children: PropTypes.string,
  copyText: PropTypes.string
}

export default TextSelectable