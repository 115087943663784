import _ from 'lodash'

export function _get(object, path, defaultVal) {
  const returnVal = _.get(object, path, null)

  if (returnVal === null) {
    return defaultVal
  } else {
    return returnVal
  }
}

export { graphql, withApollo } from 'react-apollo'
export gql from 'graphql-tag'
export { compose } from 'recompose'
export { connect } from 'react-redux'
