import _ from 'lodash'
import React from 'react'

const withPreventDoubleClick = (WrappedComponent) => {

  class PreventDoubleClick extends React.PureComponent {

    debouncedOnPress = (event) => {
      this.props.onPress && this.props.onPress(event)
    }

    onPress = _.debounce(this.debouncedOnPress, 300, { leading: true, trailing: false })

    render() {
      return <WrappedComponent {...this.props} onPress={this.onPress}/>
    }
  }

  PreventDoubleClick.displayName = `withPreventDoubleClick(${WrappedComponent.displayName || WrappedComponent.name})`
  return PreventDoubleClick
}

export default withPreventDoubleClick
