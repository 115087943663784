const CoderaWebViewHelpersScript = /*syntax: javascript*/`
  <script>
    var sendDebugMessages = true
    window.CoderaWebViewHelpers = {}

    /**HELPERS**/
    window.CoderaWebViewHelpers.eventFire = function(el, etype){
      if (el.fireEvent) {
        el.fireEvent('on' + etype);
      } else {
        var evObj = document.createEvent('Events');
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
      }
    }

    window.CoderaWebViewHelpers.sendDebugMessage = function(messageObj) {
      if (!sendDebugMessages) {
        return
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(messageObj));
    }

    window.CoderaWebViewHelpers.sendPostMessage = function(messageObj) {
      window.ReactNativeWebView.postMessage(JSON.stringify(messageObj));
    }

    window.onerror = function(message, source, lineNumber, columnNumber, error) {
      window.CoderaWebViewHelpers.sendPostMessage({
        type: 'error',
        message: {
          message: message,
          source: source,
          lineNumber: lineNumber,
          columnNumber: columnNumber,
          error: error
        }
      })
    }

    window.CoderaWebViewHelpers.sendOpenWebsiteMessage = function(website) {
      window.CoderaWebViewHelpers.sendPostMessage({
        type: 'openWebsite',
        message: {
          website: website
        }
      })
    }

    window.CoderaWebViewHelpers.catchError = function(callback, context) {
      return function(...args) {
        try {
          callback(...args)
        } catch (e) {
          window.CoderaWebViewHelpers.sendPostMessage({
            type: 'debug',
            message: 'Window Level Error Handling'
          })
          window.CoderaWebViewHelpers.sendPostMessage({
            type: 'debug',
            message: context
          })
          window.CoderaWebViewHelpers.sendPostMessage({
            type: 'error',
            message: e
          })
        }
      }
    }
  </script>
`
export default CoderaWebViewHelpersScript
