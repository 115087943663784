import React from 'react'
import {
  Button,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import shared from '../../config/styles'

const NavigationButton = ({
  onPress,
  style,
  label,
  color,
  iconIos,
  iconAndroid,
}) => {
  const showLabel = Platform.OS !== 'android' && label
  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      {Platform.OS === 'android' ? (
        <Icons
          name={iconAndroid}
          size={36}
          color={color || colors.navigation}
          style={{ paddingTop: 3, paddingLeft: 6 }}
        />
      ) : (
        <Icons
          name={iconIos}
          size={36}
          color={color || colors.navigation}
          style={{ paddingTop: 3 }}
        />
      )}
      {showLabel && (
        <Text style={[styles.text, color && { color }]}>{label}</Text>
      )}
    </TouchableOpacity>
  )
}

export default NavigationButton

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    fontSize: 17,
    color: colors.navigation,
    fontWeight: '400',
    paddingLeft: 6,
    // paddingBottom: 2,
  },
})
