import React from 'react'
import {
  Button,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  View,
  Keyboard,
} from 'react-native'
import { StackActions } from 'react-navigation'
import { NavigationActions as RNNavigationActions } from '@react-navigation/core'
// console.log('<<<STACK ACTIONS', StackActions)
import NavigationActions from '../../utility/navigationActions'
import { Notifications } from 'expo'

import { connect } from 'react-redux'

import isMobileSafari from '../../config/isMobileSafari'
import Blurry from '../../components/simple/Blurry'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import shared, { stylus } from '../../config/styles'
import withActionSheet from '../../containers/ActionSheet/withActionSheet'
import { client } from '../../containers/withApollo'
import withKeyboardInfo from '../../containers/withKeyboardInfo'
import notificationSchema from '../../schema/notification'
import { query as currentUserQuery } from '../../containers/withCurrentUser'
import i18n from 'i18n-js'
import _ from 'lodash'

const debug = false

const tabBarBlur = false
const showLabels = false
const Container = tabBarBlur ? Blurry : View

@withActionSheet
@withKeyboardInfo
@connect((state) => ({
  // TODO: Remove functions from redux store as they are not seriallizable. This doesn't cause issues with the app
  // but can cause debugging functionality of redux dev tools to not always work properly.
  refreshFeed: state.feed.refreshFeed,
  refreshNotifications: state.notifications.refreshNotifications,
  refreshProfile: state.profile.refreshProfile,
  resetSearch: state.search.resetSearch,
}))
class TabBar extends React.Component {
  constructor(props) {
    super(props)
  }

  getMessage(message) {
    return i18n.t(message)
  }

  getActiveTabIndex = () => {
    return _.get(this.props, 'navigation.state.index', -1)
  }

  getInitialRouteName = () => {
    const tabRouterState = _.get(this.props, 'navigation.state', {})
    return _.get(tabRouterState, `routes[${tabRouterState.index}].routes[0].routeName`)
  }

  getActiveStackRouteIndex = () => {
    const tabRouterState = _.get(this.props, 'navigation.state', {})
    const stackRouterState = tabRouterState.routes[tabRouterState.index]
    return stackRouterState.index
  }

  navigate = (route) => {
    const activeTabIndex = this.getActiveTabIndex()
    const isInitialRouteAlready = this.getActiveStackRouteIndex() === 0
    debug && console.log('<<<MAIN TABS - isInitialRouteAlready?', isInitialRouteAlready)
    debug && console.log('<<<MAIN TABS  - Tab router state - ', _.get(this.props, 'navigation.state', {}))
    debug && console.log('<<<MAIN TABS - Tab route to navigate to -', route)
    // If we're already on the "home" tab and the the active route is the feed, refresh and scroll to top.
    if (route.routeName === 'MyHome' && activeTabIndex === 0) {
      this.props.refreshFeed && this.props.refreshFeed() // This function is put into redux store from FeedFB.js
    } else if (route.routeName === 'MyProfile' && activeTabIndex === 4) {
      this.props.refreshProfile && this.props.refreshProfile()
    } else if (route.routeName === 'MyNotifications') {
      debug && console.log('<<<MAIN TABS - Go to Route')
      if (Platform.OS === 'ios') {
        try {
          Notifications && Notifications.setBadgeNumberAsync(0)
        } catch (e) {
          console.log('<<<MAIN TABS - Clear Badge Number Error', e)
        }
      }
      
      client.mutate({
        mutation: notificationSchema.mutations.clearUnseenBadge,
        update: (store, { data }) => {
          const currentUser = store.readQuery({ query: currentUserQuery })
          if (currentUser.currentUser) {
            currentUser.currentUser.unseenBadge.unseenNotifications = 0
            store.writeQuery({
              query: currentUserQuery,
              data: currentUser,
            })
          }
        },
      })
      this.props.refreshNotifications && this.props.refreshNotifications()
      if (activeTabIndex !== 3) {
        debug && console.log('<<<MAIN TABS - Going to Notifications')
        NavigationActions.goTo({ routeName: route.routeName})
        return
      }
    } else {
      debug && console.log('<<<MAIN TABS - Going to Route')
      NavigationActions.goTo({ routeName: route.routeName })
      return
    }

    // if we didn't navigate to another tab or we're not at the initial screen of the current tab, go to initial stack route
    if (!isInitialRouteAlready) {
      debug && console.log('<<<MAIN TABS - Going to initial route of tab')
      const initialRouteName = this.getInitialRouteName()
      const resetAction = StackActions.reset({
        index: 0,
        actions: [RNNavigationActions.navigate({ routeName: initialRouteName })],
      })
      NavigationActions.dispatch(resetAction)
    }
  }

  render() {
    const {
      navigation,
      keyboardInfo,
    } = this.props
    const activeTabIndex = this.getActiveTabIndex()
    const { routes } = navigation.state
    const { router } = navigation

    const visibilityStyle = {}
    if ((keyboardInfo.visible && Platform.OS === 'android') || NavigationActions.hideBottom()) {
      const { height } = Dimensions.get('window')
      visibilityStyle.top = height
    }
    return (
      <View style={[styles.tabContainer, visibilityStyle]}>
        <Container
          style={[
            styles.innerTabContainer,
            {
              backgroundColor: tabBarBlur
                ? 'rgba(255,255,255,0.96)'
                : colors.mainTabs.background,
            },
          ]}
        >
          {routes.map((route, index) => {
            const screenNavigation = {
              state: route,
            }
            const { tabBarLabel, tabBarIcon } = router.getScreenOptions(
              screenNavigation,
              {},
            )
            const active = index === activeTabIndex
            const color = active
              ? colors.mainTabs.active
              : colors.mainTabs.inactive
            return (
              <TouchableOpacity
                onPress={() => this.navigate(route)}
                activeOpacity={0.7}
                style={[styles.tab, isMobileSafari() && styles.tabMobileSafari]}
                key={route.routeName}
              >
                {tabBarIcon({ tintColor: color, focused: active })}
                {showLabels && (
                  <Text style={[styles.tabLabel, { color }]}>
                    {tabBarLabel}
                  </Text>
                )}
              </TouchableOpacity>
            )
          })}
        </Container>
      </View>
    )
  }
}

export default TabBar

const styles = stylus({
  container: {
    // marginTop: Platform.OS === 'ios' ? 20 : 0,
    flex: 1,
    position: 'relative',
  },
  visible: {
    opacity: 1,
    transform: [{ translateX: 0 }],
  },
  screen: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    transform: [{ translateX: -Dimensions.get('window').width }],
  },
  tabContainer: {
    backgroundColor: colors.mainTabs.background,
    height: sizes.tabBarHeight,
    iphonex: {
      height: sizes.iphonexTabBarHeight,
    },
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderTopWidth: sizes.px,
    borderTopColor: colors.mainTabs.borderTop,
    web: {
      shadowColor: '#000',
      shadowRadius: 2,
      shadowOpacity: 0.09,
      borderTopWidth: 0,
    },
  },
  innerTabContainer: {
    flexDirection: 'row',
    flex: 1,
    iphonex: {
      paddingBottom: 28,
    },
    web: {
      backgroundColor: tabBarBlur ? 'transparent' : colors.mainTabs.background,
    },
  },
  tab: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    android: {
      paddingBottom: showLabels ? 0 : 5,
    },
    // paddingTop: 2,
  },
  tabMobileSafari: {
    paddingBottom: 3,
  },
  tabLabel: {
    fontSize: 10,
    marginTop: -4,
  },
  selected: {
    textShadowColor: colors.active,
    textShadowRadius: 10,
  },
})
