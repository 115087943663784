import { combineReducers } from 'redux'
import getScreenInfo from '../config/screenInfo'

import currentUser from './currentUser'
import hydrated from './hydrated'
import posting from './posting'
import reactionPicker from './reactionPicker'
import search from './search'
import profile from './profile'
import newConversation from './newConversation'
import uploads from './uploads'
import feed from './feed'
import notifications from './notifications'
import navigation from './navigation'
import address from './address'
import video from './video'
import publishContent from './publishContent'
import location from './location'

function screenInfo(state, action) {
  if (!state) {
    return getScreenInfo()
  }
  switch (action.type) {
    case 'SCREEN_RESIZE':
      return getScreenInfo()
    default:
      return state
  }
}

function mockUser(state, action) {
  if (!state) {
    return 1
  }

  switch (action.type) {
    case 'CYCLE_MOCK_USER':
      return (state + 1) % 6
    default:
      return state
  }
}

const normalReducers = combineReducers({
  screenInfo,
  posting,
  uploads,
  newConversation,
  reactionPicker,
  mockUser,
  currentUser,
  hydrated,
  search,
  profile,
  feed,
  notifications,
  navigation,
  address,
  video,
  publishContent,
  location,
})

function authChecker(state, action, previousState) {
  // switch(action.type) {
  //   case "RESTRICTED_ACTION": {
  //     return {
  //       // specifically pass state.b as an additional argument
  //       // intercept some actions based on authorization
  //       // a : handleSpecialCaseForA(state.a, action, state.b),
  //       // b : sliceReducerB(state.b, action)
  //     }
  //   }
  //   default:
  //     return state
  // }
  const { currentUser } = state
  return {
    ...state,
  }
}

const AppReducer = (state, action) => {
  const intermediateState = normalReducers(state, action)
  const finalState = authChecker(intermediateState, action, state)
  return finalState
}

export default AppReducer
