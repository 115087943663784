import React from 'react'
import { StyleSheet, Platform, BackHandler } from 'react-native'


import MobileBackButton from '../../components/simple/MobileBackButton'
import MoreOptions from '../../components/simple/MoreOptions'

import Conversation from '../../components/Conversation/Conversation'
import KeyboardAvoidingView from '../../components/simple/KeyboardAvoidingView'
import _ from 'lodash'
import branch from '../../config/branch'
import helpers from '../../components/Conversation/helpers'

import NavigationActions from '../../utility/navigationActions'
import i18n from 'i18n-js'
import { ROUTE_REPORT_CONTENT_CONVERSATION } from '../../config/constants'
import { graphql, connect } from '../../config/connected'
import conversationSchema from '../../schema/conversation'

const navigationOptionsCallbacks = {
  back: () => {}
}

@connect( ({ currentUser }) => ({ cuid: currentUser.id }) )
@graphql(conversationSchema.queries.conversationMembership, { 
  name: 'conversationMembershipHandler',
  skip: (props) => _.get(props.navigation, 'state.params.users', false),
  options: (props) => ({ variables: { id: _.get(props.navigation, 'state.params.id', '') } })
})
class ConversationPortrait extends React.Component {
  componentDidMount() {
    const { conversationMembershipHandler: currHandler = {}, navigation } = this.props
    navigationOptionsCallbacks.back = this.back
    const navUsers =  _.get(navigation, 'state.params.users')
    if (!currHandler.loading && !navUsers) {
      const fetchedUsers = _.map(_.get(currHandler, 'conversationMembership.conversation.memberships', []), 'user')
      navigation.setParams({ users: fetchedUsers })
    }
    if (Platform.OS === 'android') {
      this.watchBackButton()
    }
  }

  componentWillUnmount() {
    this.backHandler && this.backHandler.remove()
  }


  watchBackButton() {
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      this.back()
      return true
    })
  }

  onBack = () => {}

  setOnBack = (onBack) => {
    this.onBack = onBack
  }

  back = () => {
    this.onBack()
    NavigationActions.back()
  }

  componentDidUpdate(prevProps) {
    const { conversationMembershipHandler: prevHandler = {} } = prevProps
    const { conversationMembershipHandler: currHandler = {}, navigation, cuid } = this.props
    const navUsers =  _.get(navigation, 'state.params.users')
    const navCuid = _.get(navigation, 'state.params.cuid')
    if (!navUsers && prevHandler.loading && !currHandler.loading) {
      // console.log('<<<CONVERSATION PORTRAIT - Setting fetched users')
      const fetchedUsers = _.map(_.get(currHandler, 'conversationMembership.conversation.memberships', []), 'user')
      navigation.setParams({ users: fetchedUsers })
    }
    if (!navCuid) {
      navigation.setParams({ cuid })
    }
  }

  render() {
    const { navigation } = this.props
    const users = _.get(navigation, 'state.params.users')
    const conversationMembershipId = _.get(navigation, 'state.params.id')
    const defaultMessage = _.get(navigation, 'state.params.defaultMessage')
    return (
      <KeyboardAvoidingView
        behavior={branch({ ios: 'padding', other: null })}
        keyboardVerticalOffset={branch({
          iphone: 64,
          iphonex: 64,
          android: 81,
          other: 0,
        })}
        contentContainerStyle={{ flex: 1 }}
        style={[styles.container]}
      >
        <Conversation
          id={conversationMembershipId}
          users={users}
          defaultMessage={defaultMessage}
          onBack={this.setOnBack}
        />
      </KeyboardAvoidingView>
    )
  }
}

ConversationPortrait.navigationOptions = (props) => {
  const { navigation } = props
  const params = _.get(navigation, 'state.params') || {}
  let { id, users, cuid } = params
  if (Platform.OS === 'web' && users !== undefined) {
    users = JSON.parse(users)
  }
  const moreOptions = []
  const goToReportContent =  () => NavigationActions.navigate({ routeName: ROUTE_REPORT_CONTENT_CONVERSATION, params: { objectId: id } })
  moreOptions.push({ label: i18n.t('common.report'), action: goToReportContent })
  // console.log(`<<<CONVERSATION PORTRAIT - Rendering title with ${_.get(users, 'length', 0)} members`)

  return {
    headerTitle: _.truncate(
      helpers.conversationName({ members: users, cuid }),
      { length: 26 },
    ),
    headerBackTitle: 'Back',
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => navigationOptionsCallbacks.back() }
      />
    ),
    headerRight: () => (
      <MoreOptions
        style={styles.moreOptions}
        content={{ id: id || -1 }}
        options={moreOptions}
      />
    )
  }
}

export default ConversationPortrait

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  moreOptions: {
    paddingRight: 14
  },
})
