import produce from 'immer'
import uuid from 'uuid/v4'
import { getFeatureFlag, getPendingContentIndexByUpload } from '../config/helpers'
import alert from '../utility/alert'
import i18n from 'i18n-js'
import _ from 'lodash'

export const CONTENT_IS_PENDING = 'publishContent/CONTENT_IS_PENDING'
export const CONTENT_IS_PUBLISHING = 'publishContent/CONTENT_IS_PUBLISHING'
export const CONTENT_DONE_PUBLISHING = 'publishContent/CONTENT_DONE_PUBLISHING'
export const CONTENT_PUBLISH_ERRORED = 'publishContent/CONTENT_PUBLISH_ERRORED'
export const CONTENT_PUBLISH_RETRY = 'publishContent/CONTENT_PUBLISH_RETRY'
export const CONTENT_PUBLISH_CANCELLED = 'publishContent/CONTENT_PUBLISH_CANCELLED'

const debug = false
const restoreFailedPublish = getFeatureFlag('restoreFailedPublish')

function publishFailedAlert(content) {
  let name = ''
  switch(content.contentType) {
    case 'post':
      name = _.get(content, 'payload.story.value')
      break
    case 'recipe':
      name = _.get(content, 'payload.title')
      break
    case 'user':
      name = _.get(content, 'values.displayName')
      break
    case 'message':
      name = _.get(content, 'conversationName')
      break
    case 'producer':
      name = _.get(content, 'payload.displayName')
      break
    case 'product':
      name = _.get(content, 'payload.name')
      break
    case 'media':
      name = content.id
  }
  alert({
    title: i18n.t('errors.publishContent.errorPrefix'),
    message: i18n.t(`errors.publishContent.errorTarget.${content.contentType}`, { name })
  })
}

export default function(state = { queued: [], pending: [] }, action) {
  const { type, contentDescriptor, error, upload, ...rest } = action
  switch(type) {
    case CONTENT_IS_PENDING:
      debug && console.log('<<< publishContent - CONTENT_IS_PENDING')
      return produce(state, draft => {
        const content = { id: uuid(), ...rest}
        const hasCancelledUpload = rest.pendingUploads.some(upload => upload.state === 'cancelled')
        if (hasCancelledUpload) {
          debug && console.log('<<< publishContent - CONTENT_IS_PENDING - Has cancelled upload')
          publishFailedAlert(content)
        } else if (rest.pendingUploads.length === 0) {
          debug && console.log('<<< publishContent - CONTENT_IS_PENDING - No pendingUploads')
          draft.queued.push(content)
        } else {
          debug && console.log('<<< publishContent - CONTENT_IS_PENDING - Has pendingUploads')
          draft.pending.push(content)
        }
      })
    case 'Upload/COMPLETE':
      debug && console.log('<<< publishContent - Upload/COMPLETE - updating pendingUploads for pending content')
      return produce(state, draft => {
        let index = 0
        let indexToRemove = undefined
        for (const content of draft.pending) {
          const { pendingUploads } = content
          const indexOfUpload = pendingUploads.findIndex(upload => upload.id === rest.id)
          debug && console.log('<<< publishContent - Upload/COMPLETE - number of pending uploads', pendingUploads.length)
          if (indexOfUpload !== -1) {
            debug && console.log('<<< publishContent - Upload/COMPLETE - pending upload complete')
            pendingUploads.splice(indexOfUpload, 1)
            if (pendingUploads.length === 0) {
              debug && console.log('<<< publishContent - Upload/COMPLETE - pending content getting queued')
              draft.queued.push(content)
              indexToRemove = index
            }
            break
          }
          index++
        }
        if (indexToRemove !== undefined) {
          draft.pending.splice(indexToRemove, 1)
        }
      })
    case CONTENT_PUBLISH_CANCELLED:
      debug && console.log('<<< publishContent - CONTENT_PUBLISH_CANCELLED - removing pendingContent with upload id', upload.id, ' in pendingUploads')
      return produce(state, draft => {  
        const pendingContentIndex = getPendingContentIndexByUpload(draft, upload)
        if (pendingContentIndex !== -1) {
          const [removedContent] = draft.pending.splice(pendingContentIndex, 1)
          debug && console.log('<<< publishContent - CONTENT_PUBLISH_CANCELLED - pending content removed')
          publishFailedAlert(removedContent)
        }
      })
    case CONTENT_IS_PUBLISHING:
      debug && console.log('<<< publishContent - CONTENT_IS_PUBLISHING - content is publishing')
      return produce(state, draft => {
        for (const content of draft.queued) {
          if (content.contentType === contentDescriptor.type && content.id === contentDescriptor.id) {
            content.isPublishing = true
            break
          }
        }
      })
    case CONTENT_DONE_PUBLISHING:
      debug && console.log('<<< publishContent - CONTENT_DONE_PUBLISHING - content done publishing')
      return produce(state, draft => {
        const indexOfContent = draft.queued.findIndex(content => content.contentType === contentDescriptor.type && content.id === contentDescriptor.id)
        if (indexOfContent !== -1) {
          draft.queued.splice(indexOfContent, 1)
        }
      })
    case CONTENT_PUBLISH_ERRORED:
      debug && console.log('<<< publishContent - CONTENT_PUBLISH_ERRORED - content publish errored')
      return produce(state, draft => {
        const indexOfContent = draft.queued.findIndex(content => content.contentType === contentDescriptor.type && content.id === contentDescriptor.id)
        if (indexOfContent !== -1) {
          const [content] = draft.queued.splice(indexOfContent, 1)
          if (restoreFailedPublish) {
            content.error = error
            draft.pending.push(content)
          } else {
            publishFailedAlert(content)
          }
        }
      })
    case CONTENT_PUBLISH_RETRY:
      debug && console.log('<<< publishContent - CONTENT_PUBLISH_RETRY - content publish retry')
      return produce(state, draft => {
        const indexOfContent = draft.queued.findIndex(content => content.contentType === contentDescriptor.type && content.id === contentDescriptor.id)
        if (indexOfContent !== -1) {
          const [content] = draft.pending.splice(indexOfContent, 1)
          delete content.error
          draft.queued.push(content)
        } 
      })
  }

  return state
}
