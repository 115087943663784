import React from 'react'
import PropTypes from 'prop-types'
import { Text, View, FlatList, TouchableOpacity, Platform } from 'react-native'
import { ListItem } from 'react-native-elements'
import Icons from '@expo/vector-icons/Ionicons'
import MDCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons'
import Modal from '../simple/Modal'

import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import countries from '../../config/countries'

import _ from 'lodash'
import i18n from 'i18n-js'

const isWeb = Platform.OS === 'web'

class CountryPicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      visible: false,
      countriesToPickFrom : _.values(props.countryWhiteList.length ? _.pick(countries, props.countryWhiteList) : countries)
    }
  }

  renderLeftIcon = () => {
    return (
      <Icons
        color='green'
        name='ios-checkmark-circle'
        size={22}
      />
    )
  }

  renderRightIcon = () => {
    return (
      <MDCommunityIcon
        name='chevron-right'
        size={22}
      />
    )
  }



  renderCountryItem = ({ item: country }) => (
    <ListItem
      leftIcon={isWeb && this.props.selectedCountry === country.key && this.renderLeftIcon()}
      rightIcon={isWeb && this.props.selectedCountry !== country.key && this.renderRightIcon()}
      chevron={!isWeb && this.props.selectedCountry !== country.key}
      checkmark={!isWeb && this.props.selectedCountry === country.key}
      bottomDivider
      title={country[`name_${i18n.locale}`]}
      key={country.key}
      onPress={() => this.props.selectedCountry !== country.key ? this.onCountryChange(country.key) : null }
    />
  )

  keyExtractor = (country, index) => {
    return index + '-' + country
  }

  render() {
    const { style, selectedCountry } = this.props
    return (
      <React.Fragment>
        <TouchableOpacity style={[styles.display, style]} onPress={this.showModal}>
          <Text
            style={[
              styles.displayValue,
            ]}
          >
            {selectedCountry}
          </Text>
          <Icons
            name={'ios-arrow-down'}
            size={20}
            color='rgba(0,0,0,0.5)'
            style={{ paddingTop: 4, paddingRight: 8 }}
          />
        </TouchableOpacity>
        { !isWeb || (isWeb && this.state.visible) ? (
          <Modal visible={this.state.visible} onDismiss={this.hideModal} onRequestclose={this.hideModal}>
            <View style={[styles.modalContainer]}>
              <View style={[styles.modalHeader]}>
                <TouchableOpacity style={styles.iconContainer} onPress={this.hideModal}>
                  <Icons
                    style={styles.closeIcon}
                    name='ios-close'
                    size={50}
                  />
                </TouchableOpacity>
                <View style={styles.modalTitleContainer}>
                  <Text style={styles.modalTitle}>{i18n.t('shop.edit.titles.selectCountry')}</Text>
                </View>
              </View>
              <FlatList
                keyExtractor={this.keyExtractor}
                data={this.state.countriesToPickFrom}
                renderItem={this.renderCountryItem}
              />
            </View>
          </Modal>
        ) : null }
      </React.Fragment>
    )
  }

  showModal = () => {
    this.setState({ visible: true })
  }

  hideModal = () => {
    this.setState({ visible: false })
  }

  onCountryChange = (country) => {
    this.props.onCountryChange(country)
    this.hideModal()
  }
}

CountryPicker.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  countryWhiteList: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(countries))),
  selectedCountry: PropTypes.oneOf(Object.keys(countries)),
  onCountryChange: PropTypes.func,
}

export default CountryPicker

const styles = stylus({
  display: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.input.border,
    paddingBottom: 5,
    alignSelf: 'stretch',
  },
  displayValue: {
    fontSize: 17,
    color: colors.input.text,
  },
  modalContainer: {
    flex: 1,
  },
  iconContainer: {
    width: 50,
    height: 50,
    paddingLeft: 14,
    justifyContent: 'center'
  },
  closeIcon: {
    color: colors.text.main,
  },
  modalHeader: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  modalTitleContainer: {
    flex: 1,
    alignItems: 'center',
    paddingRight: 50
  },
  modalTitle: {
    fontSize: 17,
    fontWeight: '700',
    color: colors.text.main,
  },
})
