import React from 'react'
import {
  Button,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  View,
} from 'react-native'
import NavigationActions from '../../utility/navigationActions'

import Blurry from '../../components/simple/Blurry'
import Icon from '../../config/icon'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import shared, { stylus } from '../../config/styles'

import { connect, compose } from '../../config/connected'

import _ from 'lodash'

const Header = ({ navigation, activeIndex, options }) => {
  const { dispatch, state } = navigation
  return (
    <View style={styles.header}>
      <View style={styles.innerHeader}>
        <View style={styles.headerLeft}>{options.headerLeft}</View>
        <View style={styles.headerCenter}>
          {typeof options.headerTitle === 'string' ? (
            <Text style={styles.headerTitle}>{options.headerTitle}</Text>
          ) : (
            options.headerTitle
          )}
        </View>
        <TouchableOpacity style={styles.headerRight}>
          {options.headerRight}
        </TouchableOpacity>
      </View>
    </View>
  )
}

const LandscapeStack = ({ navigation, navigationReducerState, ...props }) => {
  const { dispatch, state, router } = navigation
  const routes = _.get(state, 'routes', [])
  const index = _.get(state, 'index', -1)


  const screenNavigation = {
    state: index === -1 ? state : routes[index],
    dispatch,
  }
  const options = router.getScreenOptions(screenNavigation, {})
  return (
    <View style={styles.container}>
      {routes.map((route, i) => {
        const Screen = router.getComponentForRouteName(route.routeName)
        return (
          <View key={i} style={[styles.screen, i === index && styles.visible]}>
            <Screen
              navigation={{
                ...navigation,
                state: routes[i],
              }}
              {...props}
            />
          </View>
        )
      })}
      {options.header !== null && (
        <Header
          navigation={navigation}
          router={router}
          activeIndex={index}
          options={options}
        />
      )}
    </View>
  )
}

const mapStateToProps = ({ navigation }) => ({ navigationReducerState: navigation })

export default compose(
  connect(mapStateToProps)
)(LandscapeStack)

const styles = stylus({
  container: {
    flex: 1,
    position: 'relative',
  },
  visible: {
    opacity: 1,
    transform: [{ translateX: 0 }],
  },
  screen: {
    position: 'absolute',
    top: 0,
    paddingTop: 64,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    transform: [{ translateX: -Dimensions.get('window').width }],
  },
  header: {
    height: 64,
    backgroundColor: colors.landscape.stackHeaderBackground,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.06,
    shadowRadius: 12,

    // position: 'absolute',
    // top: 0,
    // left: 0,
    // right: 0,
    // borderBottomWidth: 0.5,
    // borderBottomColor: 'rgba(0,0,0,0.4)',
    // shadowColor: '#000',
    // shadowRadius: 2,
    // shadowOpacity: 0.03,
    // web: {
    //   backgroundColor: 'rgba(255,255,255,0.96)',
    // }
  },
  innerHeader: {
    flexDirection: 'row',
    flex: 1,
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerLeft: {
    // width: 10,
    paddingLeft: 12,
    paddingRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerLeftInside: {
    // marginRight: 'auto',
  },
  headerCenter: {
    // flex: 0.5,
    flex: 1,
    // alignItems: 'center',
  },
  headerTitle: {
    color: colors.headerTitle,
    fontSize: 26,
    fontWeight: '500',
    // alignItems: 'center',
  },
  headerRight: {
    // width: 10,
    flexDirection: 'row-reverse',
    paddingRight: 12,
  },
})
