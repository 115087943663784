import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import ProfileAction from '../../screens/Profile/ProfileAction'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'

const InstaFollowButton = ({ onPress, position = 'header', iFollow = null }) => {
  const prefix = position === 'header' ? '• ' : ''
  const text = iFollow === null ? i18n.t('common.follow') : i18n.t('common.following')
  const unfollowStyle = iFollow === null ? {} : styles[`${position}Unfollow`]
  const unfollowTextStyle = iFollow === null ? {} : styles[`${position}UnfollowText`]
  return (
    <ProfileAction
      label={`${prefix}${text}`}
      onPress={onPress}
      style={{ ...styles[position], ...unfollowStyle }}
      // style={{ width: 96, borderWidth: 0, borderRadius: 5, backgroundColor: '#5395E9' }}
      textStyle={{ ...styles[`${position}Text`], ...unfollowTextStyle }}
    />
  )
}

InstaFollowButton.propTypes = {
  iFollow: PropTypes.bool,
  onPress: PropTypes.func,
  position: PropTypes.oneOf(['header', 'list']),
}
const styles = stylus({
  header: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    padding: 0,
    height: 'auto',
    marginHorizontal: 0,
    paddingHorizontal: 3,
    // flex: 5,
    flexGrow: 2,
    // flexShrink: 5,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  headerUnfollow: {},
  headerUnfollowText: {
    color: '#000',
  },
  headerText: {
    color: colors.taggingText,
    fontWeight: 'bold',
  },
  list: {
    backgroundColor: colors.primary,
    borderWidth: 0,
    padding: 0,
    height: 30,
    marginHorizontal: 0,
    paddingHorizontal: 10,
    borderRadius: 5,
    width: 130,
    alignItems: 'center',
    justifyContent: 'center',
  },
  listText: {
    // fontSize: 15,
    color: '#fff',
    fontWeight: 'bold',
  },
  listUnfollow: {
    backgroundColor: 'transparent',
    borderColor: colors.primary,
    borderWidth: 1,
  },
  listUnfollowText: {
    color: '#000',
  },
})

export default InstaFollowButton
