import React, { useState } from 'react'
import { SafeAreaView, ScrollView, View, Text, Alert, ActivityIndicator, FlatList, TouchableOpacity } from 'react-native'
import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'
import { get, chunk } from 'lodash'
import { compose, hoistStatics } from 'recompose'
import { graphql } from 'react-apollo'
import PropTypes from 'prop-types'
import { Ionicons } from '@expo/vector-icons'
import Papaparse from 'papaparse'

import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import { isNumeric } from '../../config/validate'
import NavigationActions from '../../utility/navigationActions'

import Modal from '../../components/simple/Modal'
import Button from '../../components/simple/Button'
import MobileBackButton from '../../components/simple/MobileBackButton'

import productSchema from '../../schema/product'


const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i

function DashboardProductRework({ reworkProducts }) {
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [read, setRead] = useState(null)
  const [result, setResult] = useState(null)

  const [modals, setModals] = useState({
    duplications: false,
    validations: false,
    notExists: false,
    errors: false,
    exceptions: false,
  })

  async function handleChooseFile() {
    const picker = await DocumentPicker.getDocumentAsync()

    switch (picker.type) {
      case 'success':
        if (!picker.name.match(/(.csv)$/)) {
          Alert.alert('Error', 'Error selecting CSV file')
          break
        }

        setFile(picker)
        break
      case 'cancel':
        break
      default:
        Alert.alert('Error', 'Error selecting CSV file')
    }

    // "name": "IMG_20200408_150046.jpg",
    // "size": 200410,
    // "type": "success",
    // "uri": "file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540foodbarrio%252Ffoodbarrio-dev/DocumentPicker/68aaf7a5-0ba6-4749-98de-8bfa8f116bc3.jpg"
  }

  async function handleReadFile() {
    setLoading(true)
    const csvString = await FileSystem.readAsStringAsync(file.uri)

    try {
      const { data: [arrayHeader, ...lines] } = Papaparse.parse(csvString, { skipEmptyLines: true })

      const requiredColumns = ['id', 'name', 'unitQuantity', 'unitType']

      const header = arrayHeader.reduce((object, column, index) => {
        object[column] = index
        return object
      }, {}) 

      requiredColumns.map(column => {
        if (!header.hasOwnProperty(column)) {
          throw Error(`Column ${column} is required and is missing`)
        }
      })

      const products = {}
      const duplications = []
      const validations = []

      for (const columns of lines) {
        const id = columns[header.id]

        if (!id || !id.match(uuidPattern)) {
          validations.push({
            field: 'id',
            value: id,
            problem: 'uuid format invalid',
            line: columns.join(';')
          })
          continue
        }

        if (products.hasOwnProperty(id)) {
          duplications.push(columns.join(';'))
          continue
        }

        const name = columns[header.name]

        if (!name) {
          validations.push({
            field: 'id',
            value: id,
            problem: 'name is required',
            line: columns.join(';')
          })
          continue
        }

        const unitQuantity = columns[header.unitQuantity]

        if (!isNumeric(unitQuantity) || +unitQuantity <= 0) {
          validations.push({
            field: 'unitQuantity',
            value: unitQuantity,
            problem: 'number format invalid',
            line: columns.join(';')
          })
          continue
        }  

        const unitType = columns[header.unitType].toLowerCase()

        if (!unitType || !['kg', 'unit', 'g', 'l'].includes(unitType)) {
          validations.push({
            field: 'unitType',
            value: unitType,
            problem: 'unit type invalid',
            line: columns.join(';')
          })
          continue
        }  

        const textCategories = get(columns, String(header.categories), null)
        let categories = null

        if (textCategories) {
          try {
            categories = JSON.parse(textCategories)
          } catch (e) {
            validations.push({
              field: 'categories',
              value: categories,
              problem: 'JSON format invalid',
              line: columns.join(';')
            })
            continue  
          }
        }

        const product = {
          id,
          name,
          unitQuantity: +unitQuantity,
          unitType,
          categories,
        }

        products[name] = product
      }

      setRead({
        products,
        duplications,
        validations
      })
    } catch (err){
      Alert.alert('Error', err.message)
    }

    setLoading(false)
  }

  async function handleUpload() {
    setLoading(true)

    const calls = chunk(Object.values(read.products), 50)
    const _result = {
      errors: [],
      notExists: [],
      success: 0,
      exceptions: [],
    }

    for (const call of calls) {
      try {
        const { data } = await reworkProducts({
          variables: {
            products: call,
          }
        })

        _result.errors = [..._result.errors, ...data.reworkProducts.errors]
        _result.notExists = [..._result.notExists, ...data.reworkProducts.notExists]
        _result.success += data.reworkProducts.success

      } catch (err) {
        _result.exceptions.push(err.message)
      }
    }

    setResult(_result)

    setLoading(false)
  }

  function renderResetButton() {
    return (
      <Button
        label="Reset"
        disabled={loading}
        style={[styles.button, { backgroundColor: colors.red }]}
        onPress={() => {
          setFile(null)
          setRead(null)
          setResult(null)          
        }}
      />
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Text style={styles.title}>Instructions to prepare CSV File</Text>
        <Text>First line need to have columns name</Text>
        <View style={styles.columns}>
          <Column type="string" required format="uuid">id</Column>
          <Column type="string" required>name</Column>
          <Column type="string" format="JSON" >categories</Column>
          <Column type="float" required>unitQuantity</Column>
          <Column type="string" required format="kg unit g l">unitType</Column>
        </View>
        <Text>CSV parse and first validations are made in the client</Text>

        {!file && (
          <Button
            label="Choose file to upload"
            style={styles.button}
            onPress={handleChooseFile}
          />
        )}

        {file && (
          <>
            <View style={styles.center}>
              <Text style={styles.bold}>{file.name}</Text>
              <Text>{prettySize(file.size)}</Text>
            </View>

            {! read && !loading && (
              <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                <Button
                  label="Process file"
                  style={styles.button}
                  onPress={handleReadFile}
                />
                {renderResetButton()}
              </View>
            )}
          </>
        )}
        
        {read && (
          <>
            <View style={styles.center}>
              <Text>
                <Text style={styles.bold}>{Object.keys(read.products).length}</Text> Valid products
              </Text>
              <TouchableOpacity onPress={() => setModals({...modals, duplications: true})} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <Text>
                  <Text style={styles.bold}>{read.duplications.length}</Text> duplications
                </Text>
                {read.duplications.length > 0 && (
                  <Ionicons name="md-open" size={20} color="black" style={{marginLeft: 15}} />
                )}
              </TouchableOpacity>

              {read.duplications.length > 0 && (
                <Details
                  visible={modals.duplications}
                  setVisibilty={() => setModals({...modals, duplications: false})}
                  title="Duplications"
                >
                  <FlatList
                    data={read.duplications}
                    keyExtractor={(item, index) => String(index)}
                    renderItem={({item}) => (
                      <View style={styles.item}>
                        <Text>{item}</Text>
                      </View>
                    )}
                  />
                </Details>
              )}
    
              <TouchableOpacity
                onPress={() => setModals({...modals, validations: true})}
                style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
              >
                <Text>
                  <Text style={styles.bold}>{read.validations.length}</Text> validations fails
                </Text>
                {read.validations.length > 0 && (
                  <Ionicons name="md-open" size={20} color="black" style={{marginLeft: 15}} />
                )}
              </TouchableOpacity>

              {read.validations.length > 0 && (
                <Details
                  visible={modals.validations}
                  setVisibilty={() => setModals({...modals, validations: false})}
                  title="Validations"
                >
                  <FlatList
                    data={read.validations}
                    keyExtractor={(item, index) => String(index)}
                    renderItem={({item}) => (
                      <View style={styles.item}>
                        <Text>
                          <Text style={{fontWeight: 'bold'}}>{item.field}</Text>: &quot;{item.value}&quot;  {item.problem}
                        </Text>
                        <Text>{item.line}</Text>
                      </View>
                    )}
                  />
                </Details>
              )}
            </View>

            {!result&& !loading && (
              <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                <Button
                  label="Upload"
                  style={styles.button}
                  onPress={handleUpload}
                />
                {renderResetButton()}
              </View>
            )}
          </>
        )}

        {result && (
          <>
            <View style={styles.center}>
              <Text>
                <Text style={styles.bold}>{result.success}</Text> updated products
              </Text>

              <TouchableOpacity
                onPress={() => setModals({...modals, notExists: true})}
                style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
              >
                <Text>
                  <Text style={styles.bold}>{result.notExists.length}</Text> do not exists and have been skipped
                </Text>
                {result.notExists.length > 0 && (
                  <Ionicons name="md-open" size={20} color="black" style={{marginLeft: 15}} />
                )}
              </TouchableOpacity>

              {result.notExists.length > 0 && (
                <Details
                  visible={modals.notExists}
                  setVisibilty={() => setModals({...modals, notExists: false})}
                  title="Do not exists and have been skipped"
                >
                  <FlatList
                    data={result.notExists}
                    keyExtractor={(item, index) => String(index)}
                    renderItem={({item}) => (
                      <View style={styles.item}>
                        <Text>
                          {item}
                        </Text>
                      </View>
                    )}
                  />
                </Details>
              )}

              <TouchableOpacity
                onPress={() => setModals({...modals, errors: true})}
                style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
              >
                <Text>
                  <Text style={styles.bold}>{result.errors.length}</Text> errors
                </Text>
                {result.errors.length > 0 && (
                  <Ionicons name="md-open" size={20} color="black" style={{marginLeft: 15}} />
                )}
              </TouchableOpacity>

              {result.errors.length > 0 && (
                <Details
                  visible={modals.errors}
                  setVisibilty={() => setModals({...modals, errors: false})}
                  title="Errors"
                >
                  <FlatList
                    data={result.errors}
                    keyExtractor={(item, index) => String(index)}
                    renderItem={({item}) => (
                      <View style={styles.item}>
                        <Text style={{ fontWeight: 'bold'}}>
                          {item.name}
                        </Text>
                        <Text>{item.error}</Text>
                      </View>
                    )}
                  />
                </Details>
              )}

              {result.exceptions.length > 0 && (
                <>
                  <TouchableOpacity
                    onPress={() => setModals({...modals, exceptions: true})}
                    style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
                  >
                    <Text style={{ color: '#f00'}}>
                      <Text style={styles.bold}>{result.exceptions.length}</Text> exceptions
                    </Text>
                    {result.exceptions.length > 0 && (
                      <Ionicons name="md-open" size={20} color="black" style={{marginLeft: 15}} />
                    )}
                  </TouchableOpacity>


                  <Details
                    visible={modals.exceptions}
                    setVisibilty={() => setModals({...modals, exceptions: false})}
                    title="Exceptions"
                  >
                    <FlatList
                      data={result.exceptions}
                      keyExtractor={(item, index) => String(index)}
                      renderItem={({item}) => (
                        <View style={styles.item}>
                          <Text>{item}</Text>
                        </View>
                      )}
                    />
                  </Details>
                </>
              )}
            </View>
            {renderResetButton()}
          </>
        )}

        {loading && <ActivityIndicator size="large" color={colors.primary} />}
      </ScrollView>
    </SafeAreaView>
  )
}

function Details({ visible, setVisibilty, title, children }) {
  return (
    <Modal
      animationType='slide'
      visible={visible}
      onRequestClose={setVisibilty}
    >
      <View style={{ flex: 1, marginVertical: 10, marginHorizontal: 10 }}>
        <Text style={[styles.title, {marginBottom: 5}]}>{title}</Text>
        {children}
        <Button
          label="Close"
          style={styles.button}
          onPress={setVisibilty}
        />
      </View>
    </Modal>
  )
}

function Column({ children, type, required, format, defaultValue}) {
  return (
    <View style={styles.column}>
      <Text style={{ fontWeight: 'bold'}}>{children}</Text>
      <View style={{flexDirection: 'row'}}>
        <Text style={{ marginRight: 5}}>{type}</Text>        
        <Text>[</Text>
        {required && <Text style={{ marginHorizontal: 2, color: '#600'}}>required</Text>}
        {format && <Text style={{ marginHorizontal: 2, color: '#006'}}>format: {format}</Text>}
        {defaultValue && <Text style={{ marginHorizontal: 2, color: '#666'}}>default: {defaultValue}</Text>}
        {!required && <Text style={{ marginHorizontal: 2, color: '#060'}}>optional</Text>}
        <Text>]</Text>
      </View>
    </View>
  )
}

const styles = stylus({
  container: {
    flex: 1,
    paddingHorizontal: 10,
    native: {
      width: '100%',
      height: '100%',
    }
  },
  columns: {
    paddingHorizontal: 5,
    marginBottom: 15,
  },
  column: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 2,
  },
  center: { 
    width: '100%', 
    marginTop: 15, 
    justifyContent: 'center', 
    alignItems: 'center',
    borderWidth: 0.5,
    paddingVertical: 10,
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: 16,
    marginVertical: 10,
  },
  bold: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  button: {
    backgroundColor: colors.primary,
    marginBottom: 10,
    marginTop: 20,
    marginHorizontal: 20,
    alignSelf: 'center'
  },

  item: {
    marginVertical: 2,
    paddingVertical: 2,
    paddingHorizontal: 5,
    backgroundColor: '#eee',
  },
})

function prettySize(bytes, separator = '', postFix = '') {
  if (bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1)
    return `${(bytes / (1024 ** i)).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`
  }
  return ''
}

DashboardProductRework.propTypes = {
  reworkProducts: PropTypes.func.isRequired
}

DashboardProductRework.navigationOptions = ({ navigation }) => {
  return {
    title: 'Product Rework',
    headerLeft: () => (<MobileBackButton onPress={() => {
        NavigationActions.back()
      }} />),
    }
}

const enhanced = compose(
  graphql(productSchema.mutations.reworkProducts, {
    name: 'reworkProducts',
  }),
)
export default hoistStatics(enhanced)(DashboardProductRework)
