import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import PropTypes from 'prop-types'
import Icons from '@expo/vector-icons/Ionicons'

class Checkbox extends React.Component {
  static propTypes = {
    size: PropTypes.number,
    checked: PropTypes.bool,
  }

  static defaultProps = {
    checked: false,
    size: 32,
  }

  renderCheckbox() {
    const { size, checked, ...rest } = this.props
    const iconSize = size * 1.5
    return (
      <View
        style={[
          styles.box,
          {
            width: size,
            height: size,
            borderColor: checked
              ? colors.checkbox.checked
              : colors.checkbox.unchecked,
          },
        ]}
      >
        {checked ? (
          <Icons
            style={[styles.icon, { height: iconSize }]}
            name='ios-checkmark'
            size={iconSize}
          />
        ) : null}
      </View>
    )
  }
  renderLabel() {
    const { label, labelStyle } = this.props
    if (label) {
      return <Text style={[styles.label, labelStyle]}>{label}</Text>
    }
    return null
  }
  renderPreLabel() {
    const { leftLabel, labelStyle } = this.props
    if (leftLabel) {
      return <Text style={[styles.leftLabel, labelStyle]}>{leftLabel}</Text>
    }
    return null
  }
  render() {
    const { style, onPress } = this.props
    return (
      <TouchableOpacity onPress={onPress} style={[styles.container, style]}>
        {this.renderPreLabel()}
        {this.renderCheckbox()}
        {this.renderLabel()}
      </TouchableOpacity>
    )
  }
}

const styles = stylus({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  box: {
    borderWidth: 1.5,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: 'transparent',
    color: colors.primary,
    web: {
      marginTop: -3,
    },
  },
  label: {
    fontSize: 14,
    paddingLeft: 6,
  },
  leftLabel: {
    flex: 1,
    paddingRight: 12,
  },
})

export default Checkbox
