import categories from '../../../schema/categories'
import environment from '../../../config/environment'
import { ObjectSearchManager } from './ObjectSearchManager'
import i18n from 'i18n-js'

export const SEARCH_MANAGER_PRODUCERS = 'ProducerSearchManager'

export const LOAD_MAP_PRODUCERS = 'load_map_producers'
export const LOAD_SEARCH_PRODUCERS = 'load_search_producers'

export const PRODUCER_FILTER_TAGS = 'tags'
export const PRODUCER_FILTER_QUERY = 'query'
export const PRODUCER_FILTER_REGION = 'region'
export const PRODUCER_FILTER_RADIUS = 'radius'
export const PRODUCER_SORT_RATING_HIGH = 'ratingHigh'
export const PRODUCER_SORT_DISTANCE_LOW = 'distanceLow'
export const PRODUCER_SORT_CREATEDAT_DESC = 'createdAtDesc'
export class ProducerSearchManager extends ObjectSearchManager {
  constructor(hostRef, options) {
    super(hostRef, options)
    this.initialize()
  }
  initialize() {
    this.searchType = LOAD_SEARCH_PRODUCERS
    this.searchManagerType = SEARCH_MANAGER_PRODUCERS
    this.filterDefinitions = {
      [PRODUCER_FILTER_QUERY]: {
        param: 'query',
        label: i18n.t('search.placeholders.products'),
      },
      [PRODUCER_FILTER_TAGS]: {
        param: 'tagFilters',
        label: i18n.t('search.filters.general.categories'),
        keyLabelPairs: categories().producer,
      },
      [PRODUCER_FILTER_REGION]: { param: 'aroundLatLng' },
      [PRODUCER_FILTER_RADIUS]: { param: 'aroundRadius' },
    }
    this.searchTypeFilters = {
      [LOAD_MAP_PRODUCERS]: [
        PRODUCER_FILTER_QUERY,
        PRODUCER_FILTER_TAGS,
        PRODUCER_FILTER_REGION,
        PRODUCER_FILTER_RADIUS,
      ],
      [LOAD_SEARCH_PRODUCERS]: [PRODUCER_FILTER_QUERY, PRODUCER_FILTER_TAGS],
    }
    this.nonClearableFilters = [PRODUCER_FILTER_REGION, PRODUCER_FILTER_RADIUS]
    this.sortDefinitions = {
      [PRODUCER_SORT_RATING_HIGH]: {
        label: i18n.t('search.filters.general.averageRating'),
        direction: 'desc',
        replica: 'rating',
      },
      [PRODUCER_SORT_DISTANCE_LOW]: {
        label: i18n.t('search.filters.general.distance'),
        direction: 'asc',
        replica: false,
      },
      [PRODUCER_SORT_CREATEDAT_DESC]: {
        label: 'Latest',
        direction: 'desc',
        replica: 'created_at',
      },
    }
    this.searchTypeSorts = {
      [LOAD_MAP_PRODUCERS]: [
        PRODUCER_SORT_RATING_HIGH,
        PRODUCER_SORT_DISTANCE_LOW,
      ],
      [LOAD_SEARCH_PRODUCERS]: [
        PRODUCER_SORT_RATING_HIGH,
        PRODUCER_SORT_DISTANCE_LOW,
      ],
    }
    this.indexName = environment.constants.ALGOLIA_INDEX_PRODUCERS
    this._initialize()
  }
}
