import React from 'react'
import { Text, View } from 'react-native'
import PropTypes from 'prop-types'
// import NamedImage from '../../components/simple/NamedImage'
import { stylus } from '../../config/styles'
import _ from '../../screens/Profile/TopInstaStyle'
import ProfileImage from '../../screens/Profile/ProfileImage'
import { connect } from 'react-redux'

const ASPECT_RATIOS = {
  landscape: 0.4,
  portrait: 0.48,
}

class UserHeaderFB extends React.Component {
  static propTypes = {
    profileImage: PropTypes.string,
    displayName: PropTypes.string.isRequired,
    role: PropTypes.string,
    showRole: PropTypes.bool,
    profileSize: PropTypes.number,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    screenInfo: PropTypes.object,
    children: PropTypes.any,
  }

  static defaultProps = {
    profileSize: 90,
  }

  render() {
    const { style, profileSize, role, profileImage, displayName, screenInfo } = this.props
    const { contentWidth, layout } = screenInfo
    const coverDimensions = {
      width: contentWidth,
      height: Math.ceil(contentWidth * ASPECT_RATIOS[layout]),
    }

    // const profileStyle = {
    //   height: profileSize,
    //   width: profileSize,
    //   borderRadius: profileSize / 2,
    // }

    return (
      <View style={[styles.container, style]}>
        {/*<NamedImage*/}
        {/*  style={profileStyle}*/}
        {/*  name={profileImage}*/}
        {/*  width={profileSize}*/}
        {/*  height={profileSize}*/}
        {/*  fit='crop'*/}
        {/*  crop='faces'*/}
        {/*  resizeMode='cover'*/}
        {/*  resizeMethod='scale'*/}
        {/*  placeholder='profile'*/}
        {/*/>*/}
        <ProfileImage
          profileImage={profileImage}
          containerWidth={contentWidth}
          coverDimensions={coverDimensions}
          allowEdit={false}
          imageSize={92}
        />
        <View style={styles.textContainer}>
          <Text style={styles.username}>{displayName}</Text>
          {role ? <Text style={styles.role}>{role}</Text> : null}
          { this.props.children }
        </View>
      </View>
    )
  }
}

const styles = stylus({
  container: {
    margin: 0,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textContainer: {
    flex: 1,
    paddingLeft: 20,
    alignSelf: 'flex-start',
  },
  username: {
    fontWeight: '600',
    fontSize: 18,
  },
  role: {
    color: '#888',
    fontSize: 14,
    // fontWeight: 'bold',
  },
})

export default connect(
  state => ({
    screenInfo: state.screenInfo,
  }),
)(UserHeaderFB)
