import { gql } from '../config/connected'
import { noticeFragment } from './fragments'

export default {
  queries: {
    getAllNotices: gql`
      query($limit: Int, $offset: Int) {
        notices(limit: $limit, offset: $offset) {
          ...NoticeFragment
          user {
              displayName
          }
        }
      }
      ${noticeFragment}
    `,
  },
  mutations: {
    createNotice: gql`
      mutation($name: String!, $message: Json!) {
        createNotice(name: $name, message: $message) {
          ...NoticeFragment
        }
      }
      ${noticeFragment}
    `,
    closeNotice: gql`
      mutation {
        closeNotice {
          id
          lastNotice {
            id
          }
        }
      }
    `,
  },
  subscriptions: {
    noticeAdded: gql`
      subscription {
        noticeAdded {
          id
          name
          message
        }
      }
    `,
  },
}
