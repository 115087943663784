import Icons from '@expo/vector-icons/Ionicons'
// const algoliasearch = require('algoliasearch/reactnative')(environment.algolia.project, environment.algolia.token)
import AlgoliaSearchHelper from 'algoliasearch-helper'
import * as ImagePicker from 'expo-image-picker'
import gql from 'graphql-tag'
import _ from 'lodash'
import React from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import { ActivityIndicator, Image, Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { connect, compose, graphql } from '../../config/connected'
import NavigationActions from '../../utility/navigationActions'
import LayoutAnimation from '../../components/LayoutAnimation'
import TextInput from '../../components/ReturnKeyCompatibilityTextInput/ReturnKeyCompatibilityTextInput'
import UserSearchResult from '../../components/Search/UserSearchResult'
import CircularProgress from '../../components/simple/CircularProgress'
import KeyboardAvoidingView from '../../components/simple/KeyboardAvoidingView'
import MobileBackButton from '../../components/simple/MobileBackButton'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'
import { mimeIsVideo } from '../../utility/determineMimeType'
import uploadImage from '../../utility/uploadImage'
import SavePost from './SavePost'

class Posting extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputStyle: {},
      focused: false,
      trackingMentions: false,
      textSelection: {
        start: 0,
        end: 0,
      },
      userResults: {},
    }
    this.inputBalancer = 0
    this.textSelection = {
      start: 0,
      end: 0,
    }
    this.textValue = ''
  }
  UNSAFE_componentWillMount() {
    const userHelper = (this.userHelper = AlgoliaSearchHelper(
      algoliasearch,
      'Users',
    ))
    userHelper.on('result', (result) => {
      console.log('user result', result)
      const { userResults } = this.state
      userResults[result.query] = result
      this.setState({
        userResults,
        userSearching: false,
      })
    })
  }

  render() {
    const { platform, layout, posting } = this.props
    return (
      <View style={styles.container}>
        {Platform.OS === 'web' ? this.webInput() : this.nativeInput()}
        <KeyboardAvoidingView
          behavior='padding'
          keyboardVerticalOffset={80}
          style={{ flex: 1 }}
        >
          <ScrollView
            style={styles.content}
            keyboardDismissMode='interactive'
            keyboardShouldPersistTaps='handled'
          >
            {this.extras()}
          </ScrollView>
        </KeyboardAvoidingView>
        {this.posting()}
      </View>
    )
  }
  extras() {
    const { userQuery } = this.state
    if (userQuery) {
      return <View>{this.renderMentions()}</View>
    } else {
      return (
        <View>
          {this.renderImages()}
          {this.renderLocation()}
        </View>
      )
    }
  }
  webInput() {
    return (
      <View style={{ marginTop: 200 }}>
        <TextareaAutosize
          onChange={this.onChange.bind(this)}
          ref={(ref) => (this.textInput = ref)}
          placeholder="What's up?"
        />
      </View>
    )
  }

  nativeInput() {
    const { inputStyle } = this.state
    const { posting } = this.props
    return (
      <TextInput
        multiline={true}
        onChangeText={this.onChangeText.bind(this)}
        // onContentSizeChange={this.onContentSizeChange.bind(this)}
        keyboardType='twitter'
        onFocus={this.onFocus.bind(this)}
        onBlur={this.onBlur.bind(this)}
        ref={(ref) => (this.textInput = ref)}
        value={posting.text}
        placeholder="What's up?"
        placeholderTextColor={colors.placeholder}
        onSelectionChange={this.onSelectionChange}
        // returnKeyType='done'
        style={[styles.composeInput, inputStyle]}
        underlineColorAndroid='transparent'
      />
    )
  }
  posting() {
    const { posting } = this.props
    if (posting.inProgress) {
      return (
        <View style={styles.overlay}>
          <ActivityIndicator size='large' />
        </View>
      )
    }
    return null
  }
  renderImages() {
    const {
      contentWidth,
      posting: { uploads },
    } = this.props
    console.log(uploads)
    if (uploads && uploads.length > 0) {
      const perRow = Math.min(uploads.length, 3)
      const imageSize = (contentWidth - 5 * (perRow + 1)) / perRow
      return (
        <View style={styles.imagesContainer}>
          {uploads.map((record) => {
            return (
              <Image
                source={{ uri: record.uri }}
                style={[
                  styles.imagePreview,
                  { width: imageSize, height: imageSize },
                ]}
                key={record.uri}
              >
                {record.state === 'uploading' &&
                  this.imageProgress({
                    progress: record.progress,
                    size: imageSize * 0.25,
                  })}
                {mimeIsVideo(record) && this.videoIndicator()}
              </Image>
            )
          })}
        </View>
      )
    }
  }
  imageProgress({ progress, size }) {
    if (Platform.OS === 'ios') {
      return <CircularProgress percentage={progress} size={size} />
    }
    return <ActivityIndicator size='large' />
  }
  videoIndicator() {
    return null
  }
  renderLocation() {
    return <Text>Add location :)</Text>
  }
  bottomActions() {
    if (this.state.focused && Platform.OS === 'ios') {
      return (
        <TouchableOpacity
          style={styles.bottomActionsCondensed}
          onPress={this.causeBlur.bind(this)}
        >
          <Icons
            name='ios-camera'
            size={26}
            color={colors.actions.photo}
            style={styles.condensedActionIcon}
          />
          <Icons
            name='ios-pin'
            size={26}
            color={colors.actions.location}
            style={styles.condensedActionIcon}
          />
          <Icons
            name='ios-person-add'
            size={26}
            color={colors.actions.with}
            style={styles.condensedActionIcon}
          />
        </TouchableOpacity>
      )
    }
    return (
      <View>
        <ActionItem
          icon='ios-camera'
          color={colors.actions.photo}
          label='Photo/Video'
          onPress={this.props.goToChooseMedia}
        />
        <ActionItem
          icon='ios-pin'
          color={colors.actions.location}
          label='Check In'
          onPress={this.addCheckIn.bind(this)}
        />
        <ActionItem
          icon='ios-person-add'
          color={colors.actions.with}
          label='With'
          onPress={this.addMentions.bind(this)}
          style={styles.last}
        />
      </View>
    )
  }
  causeBlur() {
    this.textInput.blur()
  }
  onFocus() {
    this.animate()
    this.setState({
      focused: true,
    })
  }
  onBlur() {
    this.animate()
    this.setState({
      focused: false,
    })
  }
  addPhoto = async () => {
    // only 1 at a time for now
    if (this.props.posting.upload.localPath) {
      return
    }

    let result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      mediaType: 'video',
    })
    console.log({ result })
    this.handleChosenImage(result)
  }
  handleChosenImage = async (pickerResult) => {
    let uploadResult
    let result
    if (!pickerResult.uri) {
      return
    }
    // try {
    let uriParts = pickerResult.uri.split('.')
    let contentType = uriParts[uriParts.length - 1]
    this.props.uploadingImage({ localPath: pickerResult.uri })
    uploadResult = await this.props.createUpload({
      variables: {
        contentType: `image/${contentType}`,
        width: pickerResult.width,
        height: pickerResult.height,
      },
    })
    const upload = uploadResult.data.createUpload
    // console.log({upload})
    result = await uploadImage(pickerResult.uri, upload)
    this.props.addImage(upload)
    // console.log({result})
    // } catch(e) {
    //   console.log({result})
    //   console.log({e})
    //   alert('There was an error uploading your image. Please try again.')
    // } finally {
    //   this.setState({uploading: false})
    // }
  }
  onSelectionChange = (event) => {
    const {
      nativeEvent: {
        selection: { start, end },
      },
    } = event
    this.textSelection = [start, end]
    // if (this.inputBalancer === 0) {
    //   this.inputBalancer = 1
    // } else {
    this.handleMentions()
    setTimeout(this.handleMentions.bind(this), 120)
    // this.inputBalancer = 0
    // }
    console.log({ start, end })
    this.setState({
      textSelection: { start, end },
    })
  }
  addCheckIn() {}
  addMentions() {}
  setFontSize(length) {
    let size = 20
    if (length > 50) {
      size = 19
    }
    if (length > 100) {
      size = 18
    }
    if (length > 150) {
      size = 17
    }
    if (length > 200) {
      size = 15
    }
    this.setState({
      inputStyle: {
        fontSize: size,
      },
    })
  }
  onChangeText(text) {
    // native handler
    this.textValue = text
    // if (this.inputBalancer === 0) {
    //   this.inputBalancer = 1
    // } else {
    this.handleMentions()
    setTimeout(this.handleMentions.bind(this), 120)
    //   this.inputBalancer = 0
    // }
    // console.log({text})
    this.setFontSize(text.length)
    this.props.onChangeText({ text })
  }
  onChange(event) {
    // web handler
    const text = event.target.value
    this.onChangeText(text)
  }
  identifyKeyword(text) {
    if (this.trackingMentions) {
      const pattern = new RegExp('\\B@[a-z0-9_-]+|\\B@', 'gi')
      const keywordArray = text.match(pattern)
      if (keywordArray && !!keywordArray.length) {
        const lastKeyword = keywordArray[keywordArray.length - 1]
        this.updateSuggestions(lastKeyword)
      }
    }
  }
  updateSuggestions(lastKeyword) {
    console.log('mention', lastKeyword)
  }
  handleMentions() {
    const { textSelection, textValue } = this
    const lastCharacter = textValue.substr(textSelection[0] - 1, 1)
    const previousCharacter = textValue.substr(textSelection[0] - 2, 1)
    const isBoundary = previousCharacter.trim().length === 0
    this.keyword = null
    const expression = /(^|\W)@[\w-_.]+/gi
    const matches = []
    while ((match = expression.exec(textValue)) != null) {
      const value = match[0].trim()
      // the match might start with a space, handle that
      const index =
        value === match[0].toString() ? match.index : match.index + 1
      if (value.length > 0) {
        matches.push({
          index,
          actualIndex: match.index,
          value,
        })
      }
    }

    if (
      lastCharacter === '@' &&
      (previousCharacter === ' ' || textSelection[0] === 1)
    ) {
      this.keyword = '@'
      this.insertionPoint = textSelection[0]
    } else {
      const chosen = _.find(matches, (match) => {
        return (
          textSelection[0] >= match.index &&
          textSelection[0] < match.index + match.value.length + 1
        )
      })
      if (chosen) {
        this.keyword = chosen.value
        this.insertionPoint = chosen.index
      } else {
        this.keyword = null
        this.insertionPoint = null
      }
    }

    this.setState({
      userQuery: this.keyword,
      userSearching: true,
    })
    if (this.keyword) {
      this.userHelper.setQuery(this.keyword).search()
    }

    //
    console.log({
      textSelection,
      textValue,
      matches,
      lastCharacter,
      keyword: this.keyword,
      insertionPoint: this.insertionPoint,
    })
    // if (lastCharacter === '@' && isBoundary ) {
    //   this.startTracking()
    // } else if (lastCharacter === ' ' && this.state.trackingMentions || textValue === "") {
    //   this.stopTracking()
    // }
    // this.identifyKeyword({textValue, textSelection[0], end, lastCharacter})
  }
  // renderMentions() {
  //   const { userQuery } = this.state
  //   return <View>
  //     <Text>{ userQuery }</Text>
  //   </View>
  // }
  chooseUser = ({ id, name, profileImage }) => {
    console.log('chosen', { id, name, profileImage, name, profileImage })
    const text = this.textValue
    const start = this.insertionPoint + 1
    const finish = this.insertionPoint + this.keyword.length + 1
    const newText = [text.slice(0, start), name + ' ', text.slice(finish)].join(
      '',
    )
    this.onChangeText(newText)
  }
  renderMentions() {
    const { userResults, userQuery, userSearching } = this.state

    const hits = _.get(userResults, `${userQuery}.hits`, [])
    if (hits.length) {
      return hits.map((user, index) => {
        return (
          <UserSearchResult
            user={user}
            key={user.id}
            goToProfile={this.chooseUser}
            first={index === 0}
          />
        )
      })
    } else if (userSearching) {
      return (
        <View style={styles.loadingUsers}>
          <ActivityIndicator size='large' />
        </View>
      )
    }
    return (
      <View style={styles.noResults}>
        <Text>No users match that username</Text>
      </View>
    )
  }
  onContentSizeChange(e) {
    const { width, height } = e.nativeEvent.contentSize
    // console.log('onContentSizeChange', {width, height})
    const newHeight = Math.max(height, MINIMUM_INPUT_HEIGHT)
    this.animate()
    this.setState({
      inputStyle: { height: newHeight },
    })
  }
  animate() {
    LayoutAnimation &&
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }
}

Posting.navigationOptions = (props) => {
  const { navigation } = props
  const { dispatch } = navigation
  return {
    headerTitle: 'New Post',
    headerBackTitle: 'Cancel',
    headerRight: () => (<SavePost dispatch={dispatch} />),
    headerLeft: () => (
      <MobileBackButton
        label='Cancel'
        onPress={() => NavigationActions.back()}
      />
    ),
    tabBarVisible: false,
  }
}

const mapStateToProps = (state) => ({
  layout: state.screenInfo.layout,
  contentWidth: state.screenInfo.contentWidth,
  platform: state.screenInfo.platform,
  posting: state.posting,
})

const createUpload = gql`
  mutation($contentType: String!, $width: Int, $height: Int) {
    createUpload(contentType: $contentType, width: $width, height: $height) {
      name
      contentType
      signedRequest
      id
      width
      height
    }
  }
`

const mapDispatchToProps = (dispatch) => ({
  onChangeText: ({ text }) =>
    dispatch({
      type: 'Posting/SET_TEXT',
      text,
    }),
  addImage: ({ id, name }) =>
    dispatch({
      type: 'Posting/ADD_IMAGE',
      id,
      name,
    }),
  uploadingImage: ({ localPath }) =>
    dispatch({
      type: 'Posting/UPLOADING_IMAGE',
      localPath,
    }),
  removeImage: () =>
    dispatch({
      type: 'Posting/REMOVE_IMAGE',
    }),
  goToChooseMedia: () =>
    NavigationActions.navigate({ routeName: 'ChooseMedia' }),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(createUpload, {
    name: 'createUpload',
  }),
)(Posting)

const styles = stylus({
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  content: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    borderTopWidth: sizes.px,
    borderTopColor: colors.thinLine,
  },
  actionsContainer: {
    backgroundColor: 'white',
    borderTopColor: colors.thinLine,
    borderTopWidth: sizes.px,
  },
  composeInput: {
    color: colors.input.text,
    backgroundColor: 'white',
    fontSize: 20,
    paddingVertical: 16,
    paddingHorizontal: 16,
    height: 120,
  },
  composeInputWeb: {
    marginTop: 15,
    paddingVertical: 0,
  },
  actionItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionItemIcon: {
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionItemLabel: {
    borderBottomColor: colors.thinLine,
    borderBottomWidth: sizes.px,
    paddingVertical: 14,
    flex: 1,
  },
  actionItemLabelText: {
    color: '#777',
    fontSize: 16,
  },
  bottomActionsCondensed: {
    paddingVertical: 10,
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  last: {
    borderBottomWidth: 0,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressBarWrapper: {
    width: 280,
    height: 10,
    backgroundColor: 'white',
    shadowColor: 'rgba(0,0,0,0.05)',
    shadowRadius: 10,
    borderRadius: 5,
    overflow: 'hidden',
  },
  progressBar: {
    flex: 1,
  },
  imagesContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 5,
    flexWrap: 'wrap',
  },
  imagePreview: {
    width: 100,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
    marginBottom: 5,
  },
})
