import React from 'react'
import { Text, View } from 'react-native'

import colors from '../../config/colors'
import sizes from '../../config/sizes'
import shared, { stylus } from '../../config/styles'

class Section extends React.PureComponent {
  render() {
    const { style, children, ...rest } = this.props
    return (
      <View style={[styles.container, style]} {...rest}>
        {children}
      </View>
    )
  }
}

export default Section

const styles = stylus({
  container: {
    // backgroundColor: '#fff',
    borderTopWidth: sizes.px,
    borderBottomWidth: sizes.px,
    borderColor: colors.thinLine,
  },
})
