import React from 'react'
import { Text, View } from 'react-native'
import PropTypes from 'prop-types'
import shared, { stylus } from '../../config/styles'

class Tab extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
  }
  render() {
    const { label, style, containerWidth } = this.props
    return (
      <View style={[styles.container, style, { width: containerWidth }]}>
        {this.props.children}
      </View>
    )
  }
}

const styles = stylus({
  container: {
    // flex: 1,
    // flexShrink: 1,
  },
})

export default Tab
