import React from 'react'
import PropTypes from 'prop-types'
import { View, Platform, ActivityIndicator, Linking } from 'react-native'
import WebView from '../../simple/CoderaWebView'
import card from './card.inline-html'

import { stylus } from '../../../config/styles'
import colors from '../../../config/colors'

import _ from 'lodash'
import branch from '../../../config/branch'
import { graphql } from '../../../config/connected'
import paymentSchema from '../../../schema/payment'
import configSchema from '../../../schema/config'
import { getClient, isString } from '../../../config/helpers'
import { updateCachedQueryValue } from '../../../utility/apollo'
import alert from '../../../utility/alert'
import i18n from 'i18n-js'

const debug = false
const htmlDebug = false
const title = 'Stripe Card'
const logPrefix = '<<<STRIPE ELEMENTS CARD'
@graphql(paymentSchema.mutations.createStripeSetupIntent, {
  name: 'createStripeSetupIntent',
})
@graphql(paymentSchema.mutations.saveStripePaymentMethod, {
  name: 'saveStripePaymentMethod',
})
@graphql(configSchema.queries.stripeAuthLinks, {
  name: 'stripeAuthLinks',
})
class StripeElementsCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = { clientSecretLoaded: false, done: false, hasPageLoaded: false }
    this.webViewRef = null
  }

  componentDidMount() {
    if (!this.state.clientSecretLoaded) {
      this.createSetupIntent()
    }
  }

  reference = (ref) => {
    if (ref) {
      this.webViewRef = ref
    }
  }

  async createSetupIntent() {
    const client = getClient()
    const result = await this.props.createStripeSetupIntent({ variables: { client }})
    const clientSecret = _.get(result, 'data.createStripeSetupIntent')
    this.setState({ clientSecret, clientSecretLoaded: true })
  }

  render() {
    const { style } = this.props
    if (!this.state.clientSecretLoaded || this.props.stripeAuthLinks.loading) {
      return (
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator size="large" color={colors.primary} />
        </View>
      )
    }
    const StripeDescriptionHtml = /*syntax:html*/`
      <div style="padding-left: 10px; padding-right: 10px; margin-bottom: 15px; margin-top: 50px; text-align: center;">
        <span style="font-size: 14px;">
          <span>
            ${i18n.t('payment.stripeInfo.part1')}
          </span>
          <a
            href="javascript:void(0)"
            style="color: ${colors.text.link}"
            onclick="window.CoderaWebViewHelpers.sendOpenWebsiteMessage('${i18n.t('payment.stripeInfo.link')}')"
          >
            Stripe
          </a>
          <span>
            ${i18n.t('payment.stripeInfo.part2')}
          </span>
        </span>
      </div>
    `
    const html = card(this.state.clientSecret, title, StripeDescriptionHtml)
    htmlDebug && console.log(`${logPrefix} - HTML`, html)
    return (
      <View style={[styles.container]}>
        <WebView
          style={[{ flex: 1 }, style]}
          source={{ html, baseUrl: 'https://www.foodbarrio.com/' }}
          javaScriptEnabled={true}
          useWebKit={branch({ ios: true, other: false })}
          bounces={false}
          originWhitelist={['*']}
          logPrefix={logPrefix}
          onShouldStartLoadWithRequest={this.onShouldStartLoadWithRequest}
          // onNavigationStateChange ={this.onShouldStartLoadWithRequest}
          onCoderaPostMessage={this.onCoderaPostMessage}
          ref={this.reference}
        />
      </View>
    )
  }

  onShouldStartLoadWithRequest = (navState) => {
    const allowedNavStateChanges = _.get(this.props, 'stripeAuthLinks.stripeAuthLinks', [])
    debug && console.log(`${logPrefix} - NAV STATE - `, JSON.stringify(navState))
    if (!this.state.hasPageLoaded || allowedNavStateChanges.some(partialUrl => navState.url.indexOf(partialUrl) === 0)) {
      if (navState.title === title && !navState.loading && !this.state.hasPageLoaded) {
        debug && console.log(`${logPrefix} - PAGE HAS LOADED`)
        this.setState({ hasPageLoaded: true })
      }
      return true
    } else {
      this.webViewRef.stopLoading()
      return false
    }
  }

  onCoderaPostMessage = (messageObj) => {
    debug && console.log(`${logPrefix} - TYPE OF MESSAGE - ${messageObj.type}`)
    if(messageObj.type === 'payment_method') {
      const paymentMethodId = messageObj.message
      // console.log(`${logPrefix} - PaymentMethodId - ${paymentMethodId}`)
      this.setState({ clientSecretLoaded: false }, async() => {
        try {
          const res = await this.props.saveStripePaymentMethod({
            variables: { paymentMethodId },
            update: this.updatePaymentMethodsInCache
          })

          const cardAlreadyExist = !!res.data.saveStripePaymentMethod.id

          if (!cardAlreadyExist) {
            this.createSetupIntent()
            this.displayError(i18n.t('errors.stripe.card_exist'))
          } else {
            this.setState({clientSecret: null})
            this.props.onSuccess()
          }
        } catch (e) {
          this.displayError(e.message)
        }
      })
    } else if (messageObj.type === 'debug') {
      console.log(`${logPrefix} - DEBUG - `, messageObj.message)
    } else if (messageObj.type === 'error') {
      let errorMessage = messageObj.message
      if (!isString(errorMessage)) {
        errorMessage = JSON.stringify(errorMessage)
      }
      this.displayError(errorMessage)
    } else if (messageObj.type === 'openWebsite') {
      Linking.openURL(messageObj.message.website)
    }
  }

  displayError(message) {
    alert({ title: i18n.t('common.error'), message })
  }

  updatePaymentMethodsInCache = (store, { data }) => {
    const { id } = data.saveStripePaymentMethod
    updateCachedQueryValue(store, {
      query: paymentSchema.queries.listStripeCustomerPaymentMethods,
      variables: {},
      nextValue: (paymentMethods = []) => {
        if(!id) {
          return paymentMethods
        }
        const paymentMethodIndex = paymentMethods.findIndex(paymentMethod => paymentMethod.id ===  id)
        if (paymentMethodIndex !== -1) {
          paymentMethods.splice(paymentMethodIndex, 1, data.saveStripePaymentMethod)
        } else {
          paymentMethods.push(data.saveStripePaymentMethod)
        }
        return paymentMethods
      }
    })
    updateCachedQueryValue(store, {
      query: paymentSchema.queries.defaultStripePaymentMethod,
      variables: {},
      nextValue: (paymentMethod) => {
        console.log('PAYMENT METHOD', paymentMethod)
        if (!paymentMethod) {
          return data.saveStripePaymentMethod
        }
        return paymentMethod
      }
    })
  }
}

StripeElementsCard.propTypes = {
  style: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
}

const styles = stylus({
  container: {
    flex: 1
  },
  activityIndicatorContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
})

export default StripeElementsCard

