import _ from 'lodash'
import { getActiveChildNavigationOptions } from 'react-navigation'
import { createNavigator, StackRouter } from '@react-navigation/core'
import { createStackNavigator } from 'react-navigation-stack'
import NavigationActions from '../../utility/navigationActions'
import Stack from '../portrait/Stack'
import LandscapeStack from '../landscape/LandscapeStack'
import selectLayout from '../selectLayout'
import config from '../config'
import allRoutes from '../routes'

const { stackNavigator } = config

const defaultRouterActionHandlers = {
  back: (initialRouteName, action, state) => {
    if (state && action.type === 'Navigation/BACK') {
      if (state.index === 1 && state.routes[0].routeName !== initialRouteName) {
        return {
          ...state,
          routes: [{ routeName: initialRouteName, key: 'Init' }],
          index: 0,
        }
      }
    }
  },
  navigate: (initialRouteName, action, state) => {
    if (
      action.type === 'Navigation/NAVIGATE' &&
      action.routeName === initialRouteName
    ) {
      return {
        ...state,
        routes: [{ routeName: initialRouteName, key: 'Init' }],
        index: 0,
      }
    }
  },
  replace: (initialRouteName, action, state) => {
    if (state && action.type === 'Navigation/REPLACE') {
      const routes = state.routes.slice(0, state.routes.length - 1)
      routes.push(action)
      return {
        ...state,
        routes,
        index: routes.length - 1,
      }
    }
  }
}

export default function(initialRouteName, initialRoute, routerActionHandlers = {}) {

  const finalRouterActionHandlers = { ...defaultRouterActionHandlers, ...routerActionHandlers }
  const routes = {
    [initialRouteName]: {
      ...initialRoute,
      path: '',
    },
    ...allRoutes
  }

  const navigationOptions = ({ navigation, screenProps }) => {
    // Invalid navigation props was passed. I think this is caused by the custom override logic in AuthenticationRouter in getStateForAction router override.
    if (!navigation.navigate) {
      return undefined
    }
    const childNavigationOptions = getActiveChildNavigationOptions(navigation, screenProps)
    const activeRoute = NavigationActions.getActiveRoute(navigation.state) || { routeName: '' }
    // console.log('<<<COMMON ROUTER - navigationOptions for ', activeRoute)
    const tabBarVisible = !NavigationActions.hideBottom(activeRoute.routeName)
    const finalOptions = { tabBarVisible, ...childNavigationOptions }
    // console.log('<<<COMMON ROUTER - Determined Active Child Navigation Options', finalOptions)
    return finalOptions
  }

  const commonNavigatorOptions = {
    initialRouteName,
    navigationOptions,
    ...stackNavigator,
  }

  const CurrentNavigator = selectLayout({
    'web/portrait': () => createNavigator(Stack, StackRouter(routes), commonNavigatorOptions),
    'web/landscape': () => createNavigator(LandscapeStack, StackRouter(routes), commonNavigatorOptions),
    'ios/portrait': () => createStackNavigator(routes, { ...commonNavigatorOptions, ...config.iosStackNavigator }),
    'ios/landscape': () => createNavigator(LandscapeStack, StackRouter(routes), commonNavigatorOptions),
    'android/portrait': () => createStackNavigator(routes,  { ...commonNavigatorOptions, ...config.androidStackNavigator }),
    'android/landscape': () => createNavigator(LandscapeStack, StackRouter(routes), commonNavigatorOptions),
  })

  const defaultGetStateForAction = CurrentNavigator.router.getStateForAction
  CurrentNavigator.router.getStateForAction = (action, state) => {
    for (const handlerName in finalRouterActionHandlers) {
      const handler = finalRouterActionHandlers[handlerName]
      const handlerState = handler(initialRouteName, action, state)
      if (handlerState) {
        return handlerState
      }
    }
    let nextState = state
    try {
      nextState = defaultGetStateForAction(action, state)
    } catch (e) {
      console.log('<<<COMMON ROUTER - getStateForAction error - ', e)
    }
    return nextState
  }

  return CurrentNavigator
}
