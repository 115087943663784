import { chatRoutes } from './ChatRoutes'
import { ecommerceRoutes } from './EcommerceRoutes'
import { notificationRoutes } from './NotificationRoutes'
import { reviewRoutes } from './ReviewRoutes'
import { reportContentRoutes } from './ReportContentRoutes'
import { searchRoutes } from './SearchRoutes'
import { settingsRoutes } from './SettingsRoutes'
import { socialRoutes } from './SocialRoutes'

const routes = {
  ...chatRoutes,
  ...ecommerceRoutes,
  ...notificationRoutes,
  ...reviewRoutes,
  ...reportContentRoutes,
  ...searchRoutes,
  ...socialRoutes,
  ...settingsRoutes,
}

export default routes
