import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity } from 'react-native'
import Icon from '../../config/icon'
import colors from '../../config/colors'
import _ from 'lodash'

LikeButton.propTypes = {
  iLike: PropTypes.bool,
  iconSize: Number,
  style: PropTypes.object,
  name: PropTypes.string,
  onPress: PropTypes.func,
  hitSlop: PropTypes.object,
  options: PropTypes.shape({
    unlikedColor: PropTypes.string,
  }),
}

function LikeButton({ iLike = true, iconSize = 16, style = {}, onPress, name, options = {}, hitSlop }) {

  if (!onPress) {
    const [state, setState] = useState({ iLike })
    iLike = state.iLike
    onPress = () => {
      setState({ iLike: !iLike })
    }
  }

  return (
    <TouchableOpacity hitSlop={hitSlop} style={style} onPress={onPress} name={name}>
      <Icon
        name={iLike ? 'heart' : 'heart-outline'}
        size={iconSize}
        color={iLike ? colors.liked : options.unlikedColor || colors.icon.strong}
      />
    </TouchableOpacity>
  )
}

export default LikeButton
