import React from 'react'
import { View, Platform } from 'react-native'
import WebView from 'react-native-webview'

import { stylus } from '../../../config/styles'
import _ from 'lodash'

const debug = false

class CoderaWebView extends React.Component {
  constructor(props) {
    super(props)
    this.webViewRef = null
  }

  reference = (ref) => {
    const { forwardedRef } = this.props
    if (forwardedRef) {
      forwardedRef(ref)
    }
    const webViewRef = _.get(ref, 'webViewRef')
    this.webViewRef = webViewRef
  }

  render () {
    const { onMessage, onLoadStart, onLoadEnd, onError, onNavigationStateChange, forwardedRef, onCoderaPostMessage, logPrefix, renderError, ...rest } = this.props
    return (
      <View style={[styles.container]}>
        <WebView
          useWebKit={Platform.OS === 'ios'}
          ref={this.reference}
          onMessage={this.onMessageDefault}
          onLoadStart={onLoadStart || this.onLoadStartDefault}
          onLoadEnd={onLoadEnd || this.onLoadEndDefault}
          onError={onError || this.onErrorDefault}
          renderError={renderError || this.renderErrorDefault}
          onNavigationStateChange={onNavigationStateChange || this.onNavigationStateChangeDefault }
          {...rest}
        />
      </View>
    )
  }

  onNavigationStateChangeDefault = (navState) => {
    debug && console.log(`${this.props.logPrefix} - NAV STATE - `, JSON.stringify(navState))
  }

  renderErrorDefault = (errorDomain, errorCode, errorDescription) => {
    debug && console.log(`${this.props.logPrefix} - ERROR DOMAIN`, errorDomain)
    debug && console.log(`${this.props.logPrefix} - ERROR CODE`, errorCode)
    debug && console.log(`${this.props.logPrefix} - ERROR DESCRIPTION`, errorDescription)
    return null
  }

  onLoadStartDefault = () => {
    debug && console.log(`${this.props.logPrefix} - LOAD START`)
  }
  
  onLoadEndDefault = () => {
    debug && console.log(`${this.props.logPrefix} - LOAD END`)
  }
  
  onErrorDefault = (err) => {
    debug && console.log(`${this.props.logPrefix} - ERROR: `, err)
  }

  onMessageDefault = (event) => {
    // NOTE: Handling for allowing standard onMessage callback to be used
    const { onMessage } = this.props
    if (onMessage) {
      onMessage(event)
    }
    const messageObj = JSON.parse(_.get(event, 'nativeEvent.data', {}))
    this.handleCoderaPostMessage(messageObj)
  }

  handleCoderaPostMessage = (messageObj) => {
    const { onCoderaPostMessage, logPrefix } = this.props
    // console.log(`${logPrefix} - CODERA POST MESSAGE: `, messageObj)
    // console.log(`${logPrefix} - CODERA POST TYPE: `, messageObj.type)
    // console.log(`${logPrefix} - CODERA POST Content: `, messageObj.message)
    if (!messageObj.type || !messageObj.message) {
      console.log(`${logPrefix} - Invalid Codera Post Message. Skip handling.`)
      console.log(messageObj)
    } else if (onCoderaPostMessage) {
      onCoderaPostMessage(messageObj)
    }
  }
}

CoderaWebView.defaultProps = {
  injectedJavascript: '',
  logPrefix: 'WEBVIEW LOG'
}

export default React.forwardRef((props, ref) => <CoderaWebView forwardedRef={ref} {...props} />)

const styles = stylus({
  container: {
    flex: 1,
    alignItems: 'stretch',
    overflow: 'hidden',
  },
})

