import React, { Component } from 'react'
import { graphql, compose } from '../config/connected' 
import search from '../schema/search'
const debug = false

const withAlgoliaSearch = (Composed) => {
  class WithAlgoliaSearch extends Component {    
    algoliaSearch = async (index, params) => {
      const indexName = index.indexName
      //TODO: Make filterBy configurable 
      const query = { indexName, params, filterBy: 'shopStatus' }
      const input = { indexQueries: [query] }
      debug && console.log('<<<ALGOLIA SEARCH INPUT VARIABLE', input)
      try {
        const graphQLResponse = await this.props.algoliaSearch.fetchMore({
          variables: { input, returnNoResults: false },
          updateQuery: (previousResult, { fetchMoreResult }) => ({
            algoliaSearch: fetchMoreResult.algoliaSearch,
          }),
        })
        debug && console.log('<<<ALGOLIA SEARCH GRAPHQL RESPONSE', graphQLResponse)
        return graphQLResponse.data.algoliaSearch
      } catch (e) {
        console.log('WithAlgoliaSearch - error', e)
        return []
      }
    }
    render() {
      return (
        <Composed
          {...this.props}
          algoliaSearch={this.algoliaSearch}
        />
      )
    }
  }
  return compose (
    graphql(search.queries.algoliaSearch, {
      name: 'algoliaSearch',
      options: {
        variables: {
          input: {},
          returnNoResults: true
        },
        fetchPolicy: 'network-only'
      }
    })
  )(WithAlgoliaSearch)
}

export default withAlgoliaSearch
