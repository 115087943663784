import React from 'react'
import { View, Platform } from 'react-native'

import { LinearGradient } from 'expo-linear-gradient'

import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'

let Grad = LinearGradient
if (Platform.OS === 'web') {
  Grad = View
}

class Gradient extends React.Component {
  render() {
    const {
      style,
      children,
      first = '#ae00ff',
      second = '#7e00ff',
      middle,
      diagonal,
      vertical,
    } = this.props
    const c = [first]
    if (middle) {
      c.push(middle)
    }
    c.push(second)
    return (
      <Grad
        // colors={['#ae00ff', '#7e00ff']}
        //colors={['#333', '#666']}
        // colors={['#fff', 'rgb(194, 185, 208)']}
        // colors={['rgb(141, 119, 148)', '#7e00ff']}
        colors={c}
        start={diagonal ? [0, 1] : [0, 0]}
        end={vertical ? [0, 1] : [1, 0]}
        style={[
          Platform.OS === 'web' && {
            backgroundImage: `linear-gradient(to ${
              vertical ? 'bottom' : 'right'
            }, ${first} 0%, ${middle ? middle + ' 50%,' : ''} ${second} 100%)`,
          },
          style,
        ]}
      >
        {children}
      </Grad>
    )
  }
}

export default Gradient

// const styles = stylus({
//   gradient: {
//     web: {
//       // background: 'linear-gradient(to right, #ae00ff 0%,#7e00ff 100%)'
//       // background: 'linear-gradient(to right, #333 0%,#666 100%)'
//       // background: 'linear-gradient(to right, #fff 0%,rgb(194, 185, 208) 100%)'
//       background: 'linear-gradient(to right, rgb(141, 119, 148) 0%,#7e00ff 100%)'
//     }
//   },
// })
