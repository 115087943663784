import React from 'react'
import {
  Platform,
  Text,
  TouchableOpacity,
  Dimensions,
  View,
} from 'react-native'
import { Notifications } from 'expo'
import { StackActions } from 'react-navigation'

import NavigationActions from '../../utility/navigationActions'
import LandscapeLayout from '../../layouts/LandscapeLayout'

import colors from '../../config/colors'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'
import titles from '../../config/titles'

import withTagging from '../../components/Tagging/withTagging'
import { client } from '../../containers/withApollo'
import { query as currentUserQuery } from '../../containers/withCurrentUser'
import notificationSchema from '../../schema/notification'
import { compose, connect } from '../../config/connected'
import reduxStoreHolder from '../../utility/reduxStoreHolder'
import useForceUpdate from '../../hooks/useForceUpdate'

import i18n from 'i18n-js'
import _ from 'lodash'
import produce from 'immer'

const debug = false

const getActiveTabIndex = (navigation) => {
  return _.get(navigation, 'state.index', -1)
}

const getInitialRouteName = (navigation) => {
  const tabRouterState = _.get(navigation, 'state', {})
  return _.get(tabRouterState, `routes[${tabRouterState.index}].routes[0].routeName`)
}

const getActiveStackRouteIndex = (navigation) => {
  const tabRouterState = _.get(navigation, 'state', {})
  const stackRouterState = tabRouterState.routes[tabRouterState.index]
  return stackRouterState.index
}

/**
* Returns boolean that indicates whether or not forceUpdate should occurr for tabs
*/
const navigate = async (props, route) => {
  const activeTabIndex = getActiveTabIndex(props.navigation)
  const isInitialRouteAlready = getActiveStackRouteIndex(props.navigation) === 0
  debug && console.log('<<<LANDSCAPE TABS - isInitialRouteAlready?', isInitialRouteAlready)
  debug && console.log('<<<LANDSCAPE TABS  - Tab router state - ', _.get(props, 'navigation.state', {}))
  debug && console.log('<<<LANDSCAPE TABS - Tab route to navigate to -', route)
  // If we're already on the "home" tab and the the active route is the feed, refresh and scroll to top.
  if (route.routeName === 'MyProfile' && activeTabIndex === 0) {
    props.refreshProfile && props.refreshProfile()
  } else if (route.routeName === 'Chat' && activeTabIndex === 1) {
    // todo
  } else if (route.routeName === 'MyNotifications') {
    debug && console.log('<<<LANDSCAPE TABS - Go to Route')
    if (Platform.OS === 'ios') {
      try {
        Notifications && Notifications.setBadgeNumberAsync(0)
      } catch (e) {
        console.log('<<<LANDSCAPE TABS - Clear Badge Number Error', e)
      }
    }
    
    await client.mutate({
      mutation: notificationSchema.mutations.clearUnseenBadge,
      update: (store, { data }) => {
        const currentUser = store.readQuery({ query: currentUserQuery })
        if (currentUser.currentUser) {
          const nextCurrentUser = produce(currentUser.currentUser, draft => {
            draft.unseenBadge.unseenNotifications = 0
          })
          currentUser.currentUser = nextCurrentUser
          store.writeQuery({
            query: currentUserQuery,
            data: currentUser,
          })
        }
      },
    })

    props.refreshNotifications && props.refreshNotifications()
    if (activeTabIndex !== 2) {
      debug && console.log('<<<LANDSCAPE TABS - Going to Notifications')
      props.navigation.navigate({ routeName: route.routeName})
      return true
    }
  } else if (route.routeName === 'MySettings' && activeTabIndex === 3) {
    // todo
  } else {
    debug && console.log('<<<LANDSCAPE TABS - Going to Route')
    props.navigation.navigate({ routeName: route.routeName })
    return false
  }

  // if we didn't navigate to another tab or we're not at the initial screen of the current tab, go to initial stack route
  if (!isInitialRouteAlready) {
    debug && console.log('<<<LANDSCAPE TABS - Going to initial route of tab')
    const initialRouteName = getInitialRouteName(props.navigation)
    const reduxStore = reduxStoreHolder.getStore()
    reduxStore.dispatch({ type: 'RESET_FORWARD_STATES' })
    const resetAction = StackActions.reset({
      index: 0,
      actions: [props.navigation.navigate({ routeName: initialRouteName })],
    })
    NavigationActions.dispatch(resetAction)
  }
  return false
}

const LeftBarBase = (props) => {
  const forceUpdate = useForceUpdate()
  const { navigation } = props
  const { routes } = navigation.state
  const { dispatch, router } = navigation
  const activeIndex = getActiveTabIndex(navigation)
  return (
    <View style={styles.leftBarContainer}>
      <View style={styles.routesContainer}>
        {routes.map((route, index) => {
          const screenNavigation = {
            state: route,
            dispatch,
          }
          const { tabBarLabel, tabBarIcon, skipLink } = router.getScreenOptions(
            screenNavigation,
            {},
          )
          if (skipLink) {
            return null
          }
          const active = index === activeIndex
          const color = active
            ? colors.mainTabs.active
            : colors.mainTabs.inactive
          return (
            <TouchableOpacity
              onPress={async () => {
                const shouldForceUpdate = await navigate(props, route)
                if (shouldForceUpdate) {
                  forceUpdate()
                }
              }}
              activeOpacity={0.7}
              style={[styles.tab]}
              key={route.routeName}
            >
              <View style={styles.tabIcon}>
                {tabBarIcon({ tintColor: color, focused: active })}
              </View>
              <View
                style={[styles.tabLabel, index === routes.length - 1 && null]}
              >
                <Text
                  style={[styles.tabLabelText, active && styles.tabLabelActive]}
                >
                  {i18n.t(titles[tabBarLabel]) || tabBarLabel}
                </Text>
              </View>
            </TouchableOpacity>
          )
        })}
      </View>
    </View>
  )
}

const LeftBar = compose(
  connect((state) => ({
    // TODO: Remove functions from redux store as they are not seriallizable. This doesn't cause issues with the app
    // but can cause debugging functionality of redux dev tools to not always work properly.
    refreshFeed: state.feed.refreshFeed,
    refreshNotifications: state.notifications.refreshNotifications,
    refreshProfile: state.profile.refreshProfile,
    resetSearch: state.search.resetSearch,
  }))  
)(LeftBarBase)

const LandscapeTabLayout = ({ navigation }) => {
  const { routes, index } = navigation.state
  const { router } = navigation

  return (
    <LandscapeLayout
      leftContent={
        <LeftBar navigation={navigation} />
      }
      content={routes.map((route, i) => {
        if (i === index) {
          const Screen = router.getComponentForRouteName(route.routeName)
          const childRouter = router.childRouters[route.routeName]
          return (
            <View
              key={i}
              style={[styles.screen, i === index && styles.visible]}
            >
              <Screen
                navigation={{
                  ...navigation,
                  router: childRouter,
                  state: routes[i],
                }}
              />
            </View>
          )
        }
      })}
    />
  )
}

export default withTagging(LandscapeTabLayout)

const styles = stylus({
  leftBarContainer: {
    flex: 1,
    flexBasis: 'auto',
  },
  routesContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingTop: 16,
    ios: {
      paddingTop: 15,
    },
    alignItems: 'stretch',
    flexBasis: 'auto',
  },
  visible: {
    opacity: 1,
    transform: [{ translateX: 0 }],
  },
  screen: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    transform: [{ translateX: -Dimensions.get('window').width * 2 }],
  },
  tab: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 26,
    backgroundColor: 'transparent',
  },
  tabLabel: {
    borderBottomColor: colors.thinLineSoft,
    borderBottomWidth: sizes.px,
    marginLeft: 15,
    flex: 1,
    paddingVertical: 15,
  },
  tabLabelText: {
    fontSize: 16,
    color: colors.landscape.tabLabelInactive,
  },
  tabLabelActive: {
    color: colors.landscape.tabLabelActive,
  },
  navigationTitle: {
    marginLeft: 28,
    borderBottomColor: colors.thinLine,
    borderBottomWidth: sizes.px,
    paddingVertical: 8,
    marginTop: 10,
  },
  noBorder: {
    borderBottomWidth: 0,
  },
  tabIcon: {
    width: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  navigationTitleText: {
    fontSize: 19,
    color: '#333',
    fontWeight: '500',
  },
  bottomLinks: {
    // backgroundColor: 'blue',
    paddingLeft: 28,
    paddingBottom: 12,
  },
  bottomLink: {
    paddingVertical: 10,
    flexBasis: 'auto',
    // flex: 1,
    backgroundColor: 'transparent',
    borderBottomColor: colors.thinLine,
    borderBottomWidth: sizes.px,
  },
  bottomLinkText: {
    fontSize: 13,
    color: colors.inactive,
  },
})
