import ItemDetails from './screens/ItemDetails'
import ItemInformation from './screens/ItemInformation'
import UploadAwards from './screens/UploadAwards'
import UploadImages from './screens/UploadImages'

export default {
  ItemDetails,
  ItemInformation,
  UploadAwards,
  UploadImages,
}
