import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { _get } from '../config/connected'

export const query = gql`
  query {
    currentUser {
      id
      username
      displayName
      profileImage
      email
      phone
      organizationId
      unseenBadge {
        unseenNotifications
      }
      isAdmin
      acceptedTerms
      acceptedTermsAt
      blocked
      lastNotice {
        id
        name
        message
      }
    }
  }
`
// idVerified
const mapStateToProps = (state) => ({
  cuid: state.currentUser.id,
  token: state.currentUser.token,
})

const mapDispatchToProps = (dispatch) => ({
  // onResize: () => dispatch({ type: 'SCREEN_RESIZE' }),
})

export const withCurrentUserOnInterval = (pollInterval = 1000 * 60 * 10) => (
  (Composed) =>
    compose(
      connect(
        mapStateToProps,
        mapDispatchToProps,
      ),
      graphql(query, {
        name: 'currentUser',
        props: (props) => {
          if (_get(props, 'currentUser.error', false)) {
            console.log(
              _get(
                props,
                'currentUser.error',
                'withCurrentUser: Failed to load failure...',
              ),
            )
          }
          return props
        },
        options: {
          pollInterval
        },
      }),
    )(Composed)
)

export default withCurrentUserOnInterval()
