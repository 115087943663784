import React from 'react'
import { Platform } from 'react-native'
import * as FileSystem from '../utility/fileSystem'

import { connect } from '../config/connected'
import { getPendingContentIndexByUpload } from '../config/helpers'

const debug = false

async function removeFilesFromCacheDirectory(cachePath, publishContent) {
  try {
    const fileNames = await FileSystem.readDirectoryAsync(cachePath)
    for (const fileName of fileNames) {
      const uri = `${cachePath}/${fileName}`
      const pendingContentIndex = getPendingContentIndexByUpload(publishContent, { uri }, 'uri')
      if (pendingContentIndex === -1) {
        debug && console.log(`<<<CLEAR CACHE MANAGER - deleting file`, uri)
        FileSystem.deleteAsync(uri, { idempotent: true })
      }
    }
  } catch (e) {
    console.log(`<<<CLEAR CACHE MANAGER - ${cachePath} - error`, e)
  }
}

@connect(({ publishContent }) => ({
  publishContent,
}))
class ClearCacheManager extends React.PureComponent {
  async componentDidMount() {
    if (Platform.OS === 'web') {
      return // no ImagePicker cache to clear for web???
    }
    setTimeout(async () => {
      const { publishContent } = this.props
      const imagePickerCachePath = FileSystem.cacheDirectory + 'ImagePicker'
      const imageManipulatorCachePath = FileSystem.cacheDirectory + 'ImageManipulator'
      const videoThumbnailsCachePath = FileSystem.cacheDirectory + 'VideoThumbnails'
      
      await removeFilesFromCacheDirectory(imagePickerCachePath, publishContent)
      await removeFilesFromCacheDirectory(imageManipulatorCachePath, publishContent)
      await removeFilesFromCacheDirectory(videoThumbnailsCachePath, publishContent)
    }, 500)
  }

  render() {
    return null
  }
}

export default ClearCacheManager
