import {
  ROUTE_SHOP_ORDER_DETAILS,
  ROUTE_USER_ORDER_DETAILS,
  ROUTE_SHOP_ORDER_HISTORY,
  ROUTE_USER_OBJECT_TYPE_REVIEW,
} from '../../config/constants'

// NOTE: push by default is set to true because we want to go back to the notifications screen
export default function({ type, data, counter, push = true }) {
  const ShopRouteName = push ? 'NotificationScreenShop' : 'Shop'
  const PostCommentsRouteName = push
    ? 'NotificationScreenPostComments'
    : 'PostComments'
  const RecipeDetailsRouteName = push
    ? 'NotificationScreenRecipeDetails'
    : 'RecipeDetails'
  const ProfileRouteName = push ? 'NotificationScreenProfile' : 'Profile'
  const ShopOrderHistoryRouteName = push
    ? `NotificationScreen${ROUTE_SHOP_ORDER_HISTORY}`
    : ROUTE_SHOP_ORDER_HISTORY
  const ShopOrderDetailsRouteName = push
    ? `NotificationScreen${ROUTE_SHOP_ORDER_DETAILS}`
    : ROUTE_SHOP_ORDER_DETAILS
  const UserOrderDetailsRouteName = push
    ? `NotificationScreen${ROUTE_USER_ORDER_DETAILS}`
    : ROUTE_USER_ORDER_DETAILS
  const ProductRouteName = push ? 'NotificationScreenProduct' : 'Product'
  const ConversationRouteName = push
    ? 'NotificationScreenConversation'
    : 'Conversation'
  const ShopReviewFeedbackRouteName = push ? 'NotificationScreenShopReviewFeedback' : 'ShopReviewFeedback'
  const AdminDashboardRouteName = push ? 'NotificationScreenAdminDashboard' : 'AdminDashboard'
  switch (type) {
    case 'shopApproved':
    case 'shopConfirmed':
    case 'newShopApplication':
    case 'mentionShop':
    case 'shopPublished':
      return {
        routeName: ShopRouteName,
        params: { id: data.shopId },
      }
    case 'newShopApplicationAdmin':
      return { routeName: AdminDashboardRouteName, params: { query: data.shopName, tab: 'Shops', time: (new Date()).getTime() } }
    case 'shopDenied':
      return {
        routeName: ShopReviewFeedbackRouteName,
        params: { id: data.shopId, messageId: data.messageId },
      }
    case 'newPost':
    case 'postLiked':
    case 'commentLiked':
    case 'mentionPostProduct':
    case 'mentionPostShop':
    case 'mentionPostShopFollowing':
    case 'mentionPost':
      return {
        routeName: PostCommentsRouteName,
        params: { id: data.postId },
      }
    case 'newFollower':
      if (counter === 1) {
        return {
          routeName: ProfileRouteName,
          params: { id: data.actorId, pageTitle: data.actorName },
        }
      } else {
        return {
          routeName: 'MyProfile',
        }
      }
    case 'placedOrderProducer':
    case 'fulfilledOrderProducer':
      if (counter === 1) {
        const inAppAction = {
          routeName: ShopOrderDetailsRouteName,
          params: { id: data.orderId, userId: data.userId },
        }
        return inAppAction
      } else {
        return {
          routeName: ShopOrderHistoryRouteName,
          params: { userId: data.userId },
        }
      }
    case 'placedOrderConsumer':
    case 'orderUpdatedConsumer':
    case 'fulfilledOrderConsumer':
    case 'fulfilledOrderConsumer_partial':
    case 'fulfilledOrderConsumer_fulfilled':
    case 'fulfilledOrderConsumer_updated':
      return {
        routeName: UserOrderDetailsRouteName,
        params: { userId: data.userId, id: data.orderId },
      }
    case 'newProductReviewProducer':
      return {
        routeName: ProductRouteName,
        params: { id: data.objectId },
      }
    case 'newChatMessage':
    case 'newChatImage':
    case 'newGroupChatMessage':
    case 'newGroupChatImage':
      return {
        routeName: ConversationRouteName,
        params: { id: data.membershipId },
      }
    case 'newRecipe':
    case 'mentionRecipeProduct':
    case 'mentionRecipeShop':
    case 'mentionRecipe':
    case 'mentionRecipeShopFollowing':
      return {
        routeName: RecipeDetailsRouteName,
        params: { id: data.postId },
      }
    case 'mentionCommentUser':
    case 'mentionCommentShop':
    case 'mentionCommentProduct':
    case 'newComment':
      if (data.postType === 'recipe') {
        return {
          routeName: RecipeDetailsRouteName,
          params: { id: data.postId },
        }
      } else {
        return {
          routeName: PostCommentsRouteName,
          params: { id: data.postId },
        }
      }
    case 'mentionProfile':
      return {
        routeName: ProfileRouteName,
        params: { id: data.profileId },
      }
    case 'orderFulfillmentReminderProducer':
      return {
        routeName: ShopOrderDetailsRouteName,
        params: { id: data.orderId, userId: data.userId },
      }
    case 'abandonedShoppingCart_oneHourAfter':
      return {
        routeName: 'Cart',
      }
    case 'abandonedShoppingCart_oneDayAfter':
      return {
        routeName: 'Cart',
      }
    case 'abandonedShoppingCart_threeDaysAfter':
      return {
        routeName: 'Cart',
      }
    case 'notice':
      return {
        routeName: 'MyHome',
        useGoTo: true,
      }
    case 'reviewReminder_first':
      return {
        routeName: UserOrderDetailsRouteName,
        params: { userId: data.userId, id: data.orderId, goToItems: true },
      }
    case 'reviewReminder_second':
      return {
        routeName: UserOrderDetailsRouteName,
        params: { userId: data.userId, id: data.orderId, goToItems: true },
      }
    default:
      return {}
  }
}
