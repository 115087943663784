import { Platform } from 'react-native'
import deviceDetect from 'device-detect'

export default function isMobileSafari() {
  if (Platform.OS === 'web') {
    const device = deviceDetect()
    switch (device.device) {
      case 'iPhone':
      case 'iPad':
        if (device.browser === 'Safari') {
          return true
        }
        break
    }
  }
  return false
}
