import { Text, View, TouchableOpacity } from 'react-native'
import colors from '../../config/colors'
import React from 'react'
import Icons from '@expo/vector-icons/Ionicons'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import _ from 'lodash'
import sharedStyles from '../../config/styles'

const typesText = {
  billing: () => i18n.t('common.billTo'),
  shipping: () => i18n.t('common.shipTo'),
}

const AddressSelector = (props) => {
  const { type = 'shipping', addressData } = props
  const { zip, address1, address2, city, region, country, firstName, lastName } = addressData
  const userAddress = `${address1}, ${address2 ? address2 + ',' : ''} ${city}, ${region}, ${zip}, ${country}`
  const userName = `${firstName} ${lastName}`

  return <TouchableOpacity
    onPress={props.onPress}
    style={[
      { marginVertical: 10 },
      props.style,
    ]}>
    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      <Text style={[sharedStyles.h5, {
        fontWeight: '600',
        color: colors.text.main,
      }]}>{typesText[type]()}</Text>
      <View style={{ flexDirection: 'row', alignItems: 'center',  position: 'relative', top: 25, }}>
        <Icons
          name={'ios-arrow-forward'}
          size={22}
          color={colors.listItem.chevron}
        />
      </View>
    </View>
    {!_.isEmpty(addressData) ? (
      <View style={{flexDirection: 'column'}}>
        <Text style={{fontWeight: 'bold'}}>{userName}</Text>
        <Text
          numberOfLines={1}
          ellipsizeMode={'tail'}
          style={{
            paddingRight: 30,
          }}
        >{userAddress}</Text>
      </View>
    ): (
      <View style={{ height: 20 }} />
    )}

  </TouchableOpacity>
}

AddressSelector.defaultProps = {
  addressData: {
    zip: '',
    address1: '',
    city: '',
    region: '',
    country: '',
    firstName: '',
    lastName: '',
  },
}

AddressSelector.propTypes = {
  type: PropTypes.oneOf(['billing', 'shipping']).isRequired,
  // address: PropTypes.string.isRequired,
  addressData: PropTypes.shape({
    zip: PropTypes.string,
    address1: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    country: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  onPress: PropTypes.func,
  style: PropTypes.any,
}

export default AddressSelector
