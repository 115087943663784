import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import PropTypes from 'prop-types'
import Button from '../../components/simple/Button'
import Ratings from '../../components/simple/Ratings'
import CartProduct from '../Shopping/Cart/CartProduct'
import TextSelectable from '../../components/simple/TextSelectable'

import colors from '../../config/colors'
import { graphql, compose, connect } from '../../config/connected'
import reviews from '../../schema/reviews'
import {
  ROUTE_SHOP_ORDER_DETAILS,
  ROUTE_USER_OBJECT_TYPE_REVIEW,
  REVIEW_OBJECT_TYPE_PRODUCT,
} from '../../config/constants'
import { routeForShop } from '../../config/helpers'

import i18n from 'i18n-js'

class OrderItem extends React.Component {

  static propTypes = {
    orderItem: PropTypes.shape({
      productId: PropTypes.string,
      fulfillment: PropTypes.shape({
        courier: PropTypes.string,
        trackingNumber: PropTypes.string,
      }),
    }),
    partialFulfillmentItems: PropTypes.array,
    addToPartialFulfillment: PropTypes.func,
    removeFromPartialFulfillment: PropTypes.func,
  }

  renderItemAction(orderItem) {
    const {
      navigation: {
        state: { routeName },
      },
      userReviewHandler,
      partialFulfillmentItems,
      addToPartialFulfillment,
      removeFromPartialFulfillment,
    } = this.props

    const alreadyTogether = partialFulfillmentItems.includes(orderItem.id) 
    const isItemFulfilled = !!orderItem.fulfilledAt

    if (routeForShop(routeName, ROUTE_SHOP_ORDER_DETAILS)) {
      return (
        <View style={styles.delivery}>

          {!isItemFulfilled && !alreadyTogether && (
            <TouchableOpacity
              onPress={() => {
                addToPartialFulfillment(orderItem.id)
              }}
              hitSlop={{top: 10, bottom: 10, left: 10, right: 10}}
            >
              <MaterialCommunityIcons
                name="checkbox-blank-outline"
                size={20}
                color="black"
              />
            </TouchableOpacity>
          )}

          {!isItemFulfilled && alreadyTogether && (
            <TouchableOpacity
              onPress={() => {
                removeFromPartialFulfillment(orderItem.id)
              }}
              hitSlop={{top: 10, bottom: 10, left: 10, right: 10}}
            >
              <MaterialCommunityIcons
                name="checkbox-marked-outline"
                size={20}
                color="black"
              />
            </TouchableOpacity>
          )}

          {isItemFulfilled && ( 
            <MaterialCommunityIcons
              name="truck-check"
              size={20}
              color={colors.primary}
            />
          )}
        </View>
      )
    } else {
      if (isItemFulfilled) {
        if (userReviewHandler.loading) {
          return null // wait till review fetched
        }
        if (userReviewHandler.userReview) {
          const { rating } = userReviewHandler.userReview
          return (
            <TouchableOpacity onPress={this.writeReview}>
              <Ratings
                rating={rating}
                showRatings={false}
                containerStyle={styles.ratingsContainer}
                style={styles.ratingStar}
              />
            </TouchableOpacity>
          )
        }
        return (
          <Button
            style={styles.reviewAction}
            label={i18n.t('order.orderDetails.item.writeReview')}
            labelStyle={styles.reviewActionText}
            onPress={this.writeReview}
          />
        )
      }
      return (
        <Text style={[styles.unfulfilledStatusText]}>
          {i18n.t('order.order.statuses.unfulfilled')}
        </Text>
      )
    }
  }

  render() {
    const { orderItem } = this.props
    const { fulfillment } = orderItem

    return (
      <View style={styles.detailsCell}>
        <CartProduct
          navigateOnImage
          productKey={'productDetails'}
          style={styles.cartProduct}
          cartItem={orderItem}
          showQuantity
        >
          {fulfillment && (
            <View style={{marginBottom: 10}}>
              <Text style={styles.quantityText}>{`${i18n.t('order.orderDetails.courier')}: ${fulfillment.courier}`}</Text>
              <TextSelectable
                copyText={fulfillment.trackingNumber}
                style={{
                  textDecorationLine: 'underline',
                }}
              >
                {`${i18n.t('order.orderDetails.trackingNumber')}: ${fulfillment.trackingNumber}`}
              </TextSelectable>
            </View>
          )}

          {this.renderItemAction(orderItem)}
        </CartProduct>
      </View>
    )
  }

  writeReview = async () => {
    const { loggedInUserId } = this.props
    await this.props.navigation.navigate(ROUTE_USER_OBJECT_TYPE_REVIEW, {
      objectType: REVIEW_OBJECT_TYPE_PRODUCT,
      objectId: this.props.orderItem.productId,
      userId: loggedInUserId,
    })
  }
}
const mapStateToProps = (state) => ({
  loggedInUserId: state.currentUser.id,
})
export default compose(
  connect(mapStateToProps),
  graphql(reviews.queries.userReview, {
    name: 'userReviewHandler',
    skip: ({ navigation }) =>
      routeForShop(navigation.state.routeName, ROUTE_SHOP_ORDER_DETAILS),
    options({ navigation, orderItem }) {
      const { userId } = navigation.state.params
      return {
        variables: {
          userId,
          objectType: 'product',
          objectId: orderItem.productId,
        },
      }
    },
  }),
)(OrderItem)

const styles = StyleSheet.create({
  cartProduct: {
    marginBottom: 20,
  },
  delivery: {
    position: 'absolute',
    right: 0,
  },
  reviewAction: {
    backgroundColor: colors.primary,
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
  },
  reviewActionText: {
    fontSize: 15,
    color: 'white',
  },
  ratingsContainer: {
    borderWidth: 1,
    padding: 4,
    borderRadius: 5,
    borderColor: colors.primary,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ratingStar: {
    marginHorizontal: 4,
  },
  unfulfilledStatusText: {
    textAlign: 'left',
    fontWeight: '500',
    fontSize: 15,
    color: 'red',
  },
})
