import React from 'react'

/* eslint-disable-next-line */
const withShareInvite = Component => (props) => {


  return (
    <Component {...props} onOpenSharingSheet={() => {
    }}/>
  )
}

export default withShareInvite


