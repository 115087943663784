import { gql } from '../../config/connected'

const userFields = `
  id
  username
  displayName
  profileImage
  title
  organization
  organizationId
  iFollow
`

export default {
  queries: {
    followers: gql`
      query($userId: String!) {
        followers(userId: $userId) {
          ${userFields}
        }
      }
    `,
  },
}
