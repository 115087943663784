import React, { Component } from 'react'
import { Text, View, TouchableOpacity, Image } from 'react-native'
import colors from '../../config/colors'

class TileImage extends Component {
  state = { active: false }

  toggleActive = () => {
    const { onPress, source } = this.props
    onPress ? onPress(source) : null
    this.setState((prev) => ({ active: !prev.active }))
  }
  render() {
    const { style, onPress, ...props } = this.props
    const { active } = this.state
    return (
      <TouchableOpacity onPress={this.toggleActive}>
        <Image
          {...props}
          style={[
            {
              borderColor: active ? colors.primary : 'transparent',
              borderWidth: 3,
            },
            style,
          ]}
        />
      </TouchableOpacity>
    )
  }
}

export default TileImage
