import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Icons from '@expo/vector-icons/Ionicons'
import i18n from 'i18n-js'
import { getDateTime, getPrice } from '../../config/helpers'

import colors from '../../config/colors'
function CouponItem({ coupon, onEdit, onDelete, onDisable, onEnable }) {
  const { name, id, description, percent, startDate, endDate, expiredAt: disabledAt, checkoutAmountLimit, redemptionLimitPerUser, redemptionLimitPerCoupon, redemptions, group } = coupon
  const now = new Date()
  const expired = now.getTime() >= new Date(endDate).getTime()
  const iconName = disabledAt || expired ? 'md-close-circle' : 'md-checkmark-circle'

  return (
    <TouchableOpacity style={styles.itemContainer}>
      <View style={[styles.row, { width: 50, justifyContent: 'center' }]}>
        <Icons name={iconName} size={22} color={disabledAt || expired ? "red" : "green"}/>
      </View>
      <View style={{ flex: 1, marginRight: 20 }}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ fontWeight: 'bold'}}>{name}</Text>
          {!!group && <Text style={{ color: colors.text.light, marginLeft: 5}}>{group}</Text>}
          {expired &&
            <Text style={{ marginLeft: 10, color: colors.taggingText }}>{i18n.t('common.expired')}</Text>
          } 
        </View>
        <Text>{i18n.t('coupon.create.percent')}: {percent}%</Text>
        <Text>{description}</Text>
        <Text>{i18n.t('coupon.create.dateStart')}: {getDateTime(new Date(startDate))}</Text>
        <Text>{i18n.t('coupon.create.dateEnd')}: {getDateTime(new Date(endDate))}</Text>
        <Text>
          {i18n.t('coupon.create.checkoutAmountLimit')}: {getPrice(checkoutAmountLimit)}
        </Text>
        {!!redemptionLimitPerUser && <Text>{i18n.t('coupon.create.redemptionLimitPerUser')}: {String(redemptionLimitPerUser)}</Text>}
        {!!redemptionLimitPerCoupon && <Text>{i18n.t('coupon.create.redemptionLimitPerCoupon')}: {String(redemptionLimitPerCoupon)}</Text>}
        <View style={[styles.row, { flex: 1 }]}>
          <View style={[styles.row, { flex: 1, justifyContent: 'flex-end' }]}>
            <TouchableOpacity style={styles.row} onPress={() => onEdit(coupon)}>
              <Icons name={'md-create'} size={16} style={{ color: colors.taggingText }} />
              <Text style={[styles.ml3, { color: colors.taggingText }]}>{i18n.t('common.edit')}</Text>
            </TouchableOpacity>

            {redemptions && !disabledAt && (
              <TouchableOpacity style={[styles.row, styles.ml10]} onPress={() => onDisable(id)}>
                <Icons name={'md-eye-off'} style={{ color: colors.red }} size={16} />
                <Text style={[styles.ml3, { color: colors.red }]}>{i18n.t('common.disable')}</Text>
              </TouchableOpacity>        
            )}
            {redemptions && disabledAt && (
              <TouchableOpacity style={[styles.row, styles.ml10]} onPress={() => onEnable(id)}>
                <Icons name={'md-eye'} style={{ color: colors.green }} size={16} />
                <Text style={[styles.ml3, { color: colors.green }]}>{i18n.t('common.enable')}</Text>
              </TouchableOpacity>        
            )}
            {!redemptions &&  (
              <TouchableOpacity style={[styles.row, styles.ml10]} onPress={() => onDelete(id)}>
                <Icons name={'md-trash'} style={{ color: colors.red }} size={16} />
                <Text style={[styles.ml3, { color: colors.red }]}>{i18n.t('common.delete')}</Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}

CouponItem.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDisable: PropTypes.func,
  onEnable: PropTypes.func,
  coupon: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    percent: PropTypes.number,
    startDate: PropTypes.date,
    endDate: PropTypes.date,
    redemptionLimitPerUser: PropTypes.any,
    redemptionLimitPerCoupon: PropTypes.any,
    expiredAt: PropTypes.date,
    checkoutAmountLimit: PropTypes.number,
    redemptions: PropTypes.number,
    group: PropTypes.string
  }),
}

export default CouponItem

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    paddingTop: 20,
  },
  ml3: {
    marginLeft: 3,
  },
  ml10: {
    marginLeft: 10,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
})
