import gql from 'graphql-tag'

const queries = {
  user: gql`
    query($id: String!) {
      userById(id: $id) {
        id
        status
      }
    }
  `,
}

export default { queries }
