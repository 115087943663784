import React from 'react'
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from 'react-native'
import schema from '../../schema/conversation'
// import Icon from '../../config/icon'
import Icons from '@expo/vector-icons/Ionicons'

import withActionSheet from '../../containers/ActionSheet/withActionSheet'

import withCurrentUser from '../../containers/withCurrentUser'
import colors from '../../config/colors'
import {
  gql,
  connect,
  graphql,
  compose,
  withApollo,
} from '../../config/connected'
import _ from 'lodash'

class Options extends React.Component {
  render() {
    const { style } = this.props
    return (
      <TouchableOpacity
        style={[styles.area, style]}
        onPress={this.showActionSheet}
      >
        <Icons name='ios-more' size={28} color={colors.contrast} />
      </TouchableOpacity>
    )
  }
  showActionSheet = () => {
    const { cuid } = this.props

    // if (currentUser.id === userId) {
    this.openActionSheetForMyConversation()
    // } else {
    //   this.openActionSheetForOtherPost()
    // }
  }
  openActionSheetForMyConversation = () => {
    // Same interface as https://facebook.github.io/react-native/docs/actionsheetios.html
    let options = ['Delete Conversation', 'Cancel']
    let destructiveButtonIndex = 0
    let cancelButtonIndex = 1
    this.props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        // console.log('action sheet', buttonIndex)
        switch (buttonIndex) {
            case 0:
              return this.deleteConversation()
            default:
              return null
        }
      },
    )
  }
  // openActionSheetForOtherPost = () => {
  //   let options = ['Unfollow', 'Report this post', 'Cancel']
  //   let destructiveButtonIndex = 1
  //   let cancelButtonIndex = 2
  //   this.props.showActionSheetWithOptions({
  //     options,
  //     cancelButtonIndex,
  //     destructiveButtonIndex,
  //   }, buttonIndex => {
  //     // console.log('action sheet', buttonIndex)
  //   })
  // }
  deleteConversation = async () => {
    // this.props.showActivityIndicator()
    let result
    const { conversationId, cuid, client } = this.props
    // try {
    result = await this.props.deleteConversation({
      variables: {
        id: conversationId,
      },
    })
    // update conversation list
    const conversationsData = client.readQuery({
      query: queries.conversations,
      variables: { userId: cuid },
    })
    const allConversations = conversationsData.allConversations
    const index = _.findIndex(allConversations, { id: conversationId })

    // move to beginning
    const existing = allConversations.splice(index, 1)
    console.log({ index, existing, allConversations })
    client.writeQuery({
      query: queries.conversations,
      variables: { userId: cuid },
      data: conversationsData,
    })
    this.props.afterDelete()
  }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({})

const deleteConversation = gql`
  mutation($id: ID!) {
    deleteConversation(id: $id) {
      id
    }
  }
`

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(deleteConversation, {
    name: 'deleteConversation',
  }),
  withCurrentUser,
  withApollo,
  withActionSheet,
)(Options)

// export default ActionSheetConnected

const styles = StyleSheet.create({
  area: {
    width: 36,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
