import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import Statistic from '../../components/simple/Statistic'
import { connect } from 'react-redux'
import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'
import i18n from 'i18n-js'

class Statistics extends React.Component {
  render() {
    const {
      posts,
      followers,
      following,
      showSeperators,
      containerStyle,
      goToFollowers,
      goToFollowing,
      children
    } = this.props

    return (
      <View style={[styles.container, containerStyle]}>
        {showSeperators && <View style={styles.statisticSpacer} />}
        <Statistic
          statistic={posts}
          label={i18n.t('common.posts')}
          style={styles.statistic}
          uppercase
        />
        {showSeperators && <View style={styles.statisticSpacer} />}
        <Statistic
          statistic={followers}
          label={i18n.t('common.followers')}
          style={styles.statistic}
          uppercase
          onPress={goToFollowers}
        />
        {showSeperators && <View style={styles.statisticSpacer} />}
        <Statistic
          statistic={following}
          label={i18n.t('common.followed')}
          style={styles.statistic}
          uppercase
          onPress={goToFollowing}
        />
        { children }
        <View style={styles.statisticSpacer} />
      </View>
    )
  }
}

export default Statistics

const styles = stylus({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  statisticSeparator: {
    width: 1,
    marginHorizontal: 20,
    height: 20,
    backgroundColor: colors.thinLineSoft,
  },
  statisticSpacer: {
    width: 1,
    height: 18,
  },
  statistic: {
    width: 70,
  },
})
