import React from 'react'
import {
  Button,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import Icon from '../../config/icon'
import shared from '../../config/styles'

export default ({ onPress, containerStyle = {} }) => {
  return (
    <TouchableOpacity style={[styles.container, containerStyle]} onPress={onPress}>
      <Icon
        name='send-outline'
        size={34}
        color={colors.navigation}
        style={{ paddingTop: 3, paddingLeft: 6 }}
      />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    alignItems: 'center',
    flexDirection: 'row',
  },
})
