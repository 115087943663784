import React  from 'react'
import { Platform, BackHandler } from 'react-native'
import {
  createSwitchNavigator,
} from '@react-navigation/core'
import { createAppContainer } from '@react-navigation/native'
import { createBrowserApp } from '@react-navigation/web'

import MainRouter from './MainRouter'
import Start from '../screens/Start'

import NavigationActions from '../utility/navigationActions'
import { getAuthToken } from '../containers/withApollo'
import withTagging from '../components/Tagging/withTagging'
import { jsonStringifyKeyValues } from '../config/helpers'
import reduxStoreHolder from '../utility/reduxStoreHolder'

import _ from 'lodash'

const debug = false
const isWeb = Platform.OS === 'web'

const routes = {
  Start: {
    screen: Start,
    path: 'start',
    title: 'Start',
  },
  Network: {
    screen: MainRouter,
    path: '',
  },
}

const BasicRootNavigator = createSwitchNavigator(routes, { initialRouteName: 'Start',navigationOptions: {} })
const CustomRootNavigator = (props) => (<BasicRootNavigator {...props} />)
CustomRootNavigator.router = {
  ...BasicRootNavigator.router,
  getStateForAction: (action, lastState) => {
    debug && console.log('<<<CUSTOM ROOT NAVIGATOR - lastState', lastState)
    let pushBackState = true
    const reduxStore = reduxStoreHolder.getStore()
    debug && console.log('<<<CUSTOM ROOT NAVIGATOR - action', action)
    const lastActiveRoute = NavigationActions.getActiveRoute(lastState)
    debug && console.log('<<<CUSTOM ROOT NAVIGATOR - getStateForAction - Active Route Before Action ', lastActiveRoute)

    if (isWeb) {
      jsonStringifyKeyValues(action.params)
    }

    let nextState = BasicRootNavigator.router.getStateForAction(action, lastState)
    debug && console.log('<<<CUSTOM ROOT NAVIGATOR - nextState', nextState)
    const tabChanged = NavigationActions.didActiveTabChange(lastState, nextState)

    if (nextState) {
      const isInitialTabRoute = NavigationActions.isInitialTabRoute(lastState, _.get(lastActiveRoute,'routeName'))
      const activeRoute = NavigationActions.getActiveRoute(nextState)
      debug && console.log('<<<CUSTOM ROOT NAVIGATOR - getStateForAction - Active Route After Action ', activeRoute)
      if (lastState === undefined && action.type === 'Navigation/INIT' && getAuthToken()) {
        debug && console.log('CUSTOM ROOT NAVIGATOR - Going Home')
        _.set(nextState, 'index', 1) // Order of Switch Navigator Routes = [Start, Network] - So index of 1 for logged in user
      } else if (!getAuthToken() && action.type === 'Navigation/NAVIGATE' && activeRoute.routeName !== 'Start') {
        debug && console.log('<<<CUSTOM ROOT NAVIGATOR - Unauthenticated - Cannot navigate to ', activeRoute.routeName)
        if (lastState === undefined) {
          _.set(nextState, 'index', 0) // Order of Switch Navigator Routes = [Start, Network] - So index of 0 for un-aunthenticated user
        } else {
          nextState = lastState
        }
      // Bug in Browser App Container on web that needs custom handling for browser back and forward buttons
      } else if (isWeb && getAuthToken() && action.type === 'Navigation/NAVIGATE' && action.routeName === 'Network' && action.action) {
        const backStates = _.get(reduxStore.getState(), `navigation.backStates`, [])
        const forwardStates = _.get(reduxStore.getState(), `navigation.forwardStates`, [])

        let targetRouteInParentStack = false
        const parentRoute = NavigationActions.getActiveRoute(lastState, { returnParent: true })
        if (parentRoute) {
          const leafAction = NavigationActions.getLeafAction(action)
          const targetRouteName = NavigationActions.getNonNotificationRouteName(leafAction.routeName)
          const notificationRouteName = NavigationActions.getNotificationRouteName(leafAction.routeName)
          targetRouteInParentStack = _.get(parentRoute, 'routes', []).some(route => route.routeName === targetRouteName || route.routeName === notificationRouteName)
        }
        
        if (backStates.length && (tabChanged || targetRouteInParentStack)) {
          pushBackState = false
          nextState = _.last(backStates)
          reduxStore.dispatch({
            type: 'PUSH_FORWARD_STATE',
            forwardState: lastState,
          })
          reduxStore.dispatch({ type: 'POP_BACK_STATE'})
        } else if (forwardStates.length) {
          nextState = _.last(forwardStates)
          reduxStore.dispatch({ type: 'POP_FORWARD_STATE'})
        }
      }
    }

    try {
      if (isWeb && pushBackState && action.type !== 'Navigation/SET_PARAMS' && lastState !== undefined) {
        reduxStore.dispatch({
          type: 'PUSH_BACK_STATE',
          backState: lastState,
        })
      }
    } catch (e)  {
      debug && console.log('<<<AUTHENTICATION ROUTER - getStateForAction - redux store dispatch error', e)
    }
 
    debug && console.log('<<<CUSDTOM ROOT NAVIGATOR - getStateForAction - After Custom Logic', nextState)
    return nextState
  }
}
export const RootNavigator = CustomRootNavigator
const AppContainer =  isWeb ? createBrowserApp(CustomRootNavigator) : createAppContainer(CustomRootNavigator)

class AppWithNavigationState extends React.Component {

  constructor(props) {
    super(props)
    if (Platform.OS === 'android') {
      this.watchBackButton()
    }
  }
  render() {
    return (
      <AppContainer
        ref={NavigationActions.setTopLevelNavigator}
        onNavigationStateChange={this.onNavigationStateChange}
      />
    )
  }

  onNavigationStateChange = (prevState, newState, action) => {
    // console.log('<<<APP CONTAINER - STATE CHANGE -', action)
  }

  watchBackButton() {
    BackHandler.addEventListener('hardwareBackPress', () => {
      const nav = NavigationActions.getNavState()
      const { routes: rootRoutes, index: rootIndex } = nav
      const networkRouter= rootRoutes[1]
      let goingBackToStart = false
      if (networkRouter && rootIndex > 0) {
        const {routes } = networkRouter
        goingBackToStart = !routes.some(tabRouter => {
          return tabRouter.index > 0
        })
       
      }
      if (!goingBackToStart) {
        NavigationActions.back()
        return true
      }
      console.log('<<<AUTHENTICATION ROUTER - Minimizing App')
      return false
    })
  }
}

export default withTagging(AppWithNavigationState)
