import environment from '../../../config/environment'
import { ObjectSearchManager } from './ObjectSearchManager'
import i18n from 'i18n-js'
import categories from '../../../schema/categories'

export const SEARCH_MANAGER_RECIPES = 'RecipeSearchManager'

export const LOAD_SEARCH_RECIPES = 'load_search_recipes'

export const RECIPE_FILTER_QUERY = 'query'
export const RECIPE_FILTER_TAGS_DIET = 'tagsDiet'

export const RECIPE_SORT_DIFFICULTY_ASC = 'difficultyAsc'
export const RECIPE_SORT_DIFFICULTY_DESC = 'difficultyDesc'
export const RECIPE_SORT_FEEDS_ASC = 'feedsAsc'
export const RECIPE_SORT_FEEDS_DESC = 'feedsDesc'
export const RECIPE_SORT_PREP_TIME_ASC = 'prepTimeAsc'
export const RECIPE_SORT_PREP_TIME_DESC = 'prepTimeDesc'
export const RECIPE_SORT_COOK_TIME_ASC = 'cookTimeAsc'
export const RECIPE_SORT_COOK_TIME_DESC = 'cookTimeDesc'
export const RECIPE_SORT_COST_ASC = 'costAsc'
export const RECIPE_SORT_COST_DESC = 'costDesc'
export const RECIPE_SORT_CREATEDAT_DESC = 'createdAtDesc'
export class RecipeSearchManager extends ObjectSearchManager {
  constructor(hostRef, options) {
    super(hostRef, options)
    this.initialize()
  }
  initialize() {
    this.searchType = LOAD_SEARCH_RECIPES
    this.searchManagerType = SEARCH_MANAGER_RECIPES
    this.filterDefinitions = {
      [RECIPE_FILTER_QUERY]: {
        param: 'query',
        label: i18n.t('search.placeholders.recipes'),
      },
      [RECIPE_FILTER_TAGS_DIET]: {
        param: 'tagFilters',
        label: i18n.t('search.filters.general.diet'),
        keyLabelPairs: { ...categories().diet },
      },
    }
    this.searchTypeFilters = {
      [LOAD_SEARCH_RECIPES]: [RECIPE_FILTER_QUERY, RECIPE_FILTER_TAGS_DIET],
    }
    this.sortDefinitions = {
      [RECIPE_SORT_DIFFICULTY_ASC]: {
        label: i18n.t('search.filters.recipes.diffAsc'),
        direction: 'asc',
        replica: 'difficulty',
      },
      [RECIPE_SORT_DIFFICULTY_DESC]: {
        label: i18n.t('search.filters.recipes.diffDes'),
        direction: 'desc',
        replica: 'difficulty',
      },
      [RECIPE_SORT_FEEDS_ASC]: {
        label: i18n.t('search.filters.recipes.servingsAsc'),
        direction: 'asc',
        replica: 'feeds',
      },
      [RECIPE_SORT_FEEDS_DESC]: {
        label: i18n.t('search.filters.recipes.servingsDes'),
        direction: 'desc',
        replica: 'feeds',
      },
      [RECIPE_SORT_PREP_TIME_ASC]: {
        label: i18n.t('search.filters.recipes.prepTimeAsc'),
        direction: 'asc',
        replica: 'prep',
      },
      [RECIPE_SORT_PREP_TIME_DESC]: {
        label: i18n.t('search.filters.recipes.prepTimeDes'),
        direction: 'desc',
        replica: 'prep',
      },
      [RECIPE_SORT_COOK_TIME_ASC]: {
        label: i18n.t('search.filters.recipes.cookTimeAsc'),
        direction: 'asc',
        replica: 'cook',
      },
      [RECIPE_SORT_COOK_TIME_DESC]: {
        field: 'cookTime',
        label: i18n.t('search.filters.recipes.cookTimeDes'),
        direction: 'desc',
        replica: 'cook',
      },
      [RECIPE_SORT_COST_ASC]: {
        label: i18n.t('search.filters.recipes.priceAsc'),
        direction: 'asc',
        replica: 'cost',
      },
      [RECIPE_SORT_COST_DESC]: {
        label: i18n.t('search.filters.recipes.priceDes'),
        direction: 'desc',
        replica: 'cost',
      },
      [RECIPE_SORT_CREATEDAT_DESC]: {
        label: 'Latest',
        direction: 'desc',
        replica: 'created_at',
      },
    }
    this.searchTypeSorts = {
      [LOAD_SEARCH_RECIPES]: [
        RECIPE_SORT_DIFFICULTY_ASC,
        RECIPE_SORT_DIFFICULTY_DESC,
        RECIPE_SORT_FEEDS_ASC,
        RECIPE_SORT_FEEDS_DESC,
        RECIPE_SORT_PREP_TIME_ASC,
        RECIPE_SORT_PREP_TIME_DESC,
        RECIPE_SORT_COOK_TIME_ASC,
        RECIPE_SORT_COOK_TIME_DESC,
        RECIPE_SORT_COST_ASC,
        RECIPE_SORT_COST_DESC,
      ],
    }
    this.indexName = environment.constants.ALGOLIA_INDEX_RECIPES
    this._initialize()
  }
}
