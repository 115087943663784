import React from 'react'
import {
  Text,
  View,
  TouchableOpacity,
} from 'react-native'

import PropTypes from 'prop-types'
import _ from 'lodash'
import NavigationActions from '../../utility/navigationActions'
import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import { getPrice, unitPlural } from '../../config/helpers'
import Ratings from '../../components/simple/Ratings'
import Price from '../../components/simple/Price'
import NamedImage from '../../components/simple/NamedImage'
import { _get, compose, connect } from '../../config/connected'
import Icon from '../../config/icon'
import i18n from 'i18n-js'
import withPreventDoubleClick from '../../containers/withPreventDoubleClick'
import { OBJECT_STATUSES } from '../../config/constants'

const SIZE = 50
const PreventDoubleClickButton = compose(withPreventDoubleClick)(TouchableOpacity)
@connect(
  (state) => ({}),
  (dispatch) => ({
    goToProduct: (id) =>  NavigationActions.navigate({ routeName: 'Product', params: { id } }),
  }),
)
class CarouselItem extends React.Component {
  static propTypes = {
    goToProduct: PropTypes.func.isRequired,
    showAddToCart: PropTypes.bool,
    item: PropTypes.object.isRequired,
    showShop: PropTypes.bool,
    width: PropTypes.number,
    onAddToCart: PropTypes.func,
  }

  onAddToCart = (event) => {
    const { item, onAddToCart } = this.props
    onAddToCart && onAddToCart(event, item)
  }

  render() {
    const { item, width, goToProduct, showShop, showPrice, imageStyles, showAddToCart } = this.props
    const {ratingCount, rating, price, priceDiscount, isInStock, isDiscontinued, profile } = item
    let hasPriceDiscount = !!priceDiscount && priceDiscount < price
    const coverPhoto = _.find(item.uploads, i => i.mime !== 'mp4' && i.mime !== 'mov')
    const shopStatus = _.get(profile, 'status', OBJECT_STATUSES.active)
    const itemNotAvailable = !isInStock || isDiscontinued  || shopStatus !== OBJECT_STATUSES.active
    const unitProduct = unitPlural(item)

    return (
      <TouchableOpacity
        onPress={() => goToProduct(item.id)}
        style={[styles.container, { maxWidth: width }]}
      >
        <View style={styles.circleOutline}>
          <NamedImage
            name={coverPhoto ? coverPhoto.name : null}
            width={SIZE}
            height={SIZE}
            style={[styles.image, imageStyles]}
            fit='crop'
            resizeMethod='scale'
            resizeMode='cover'
          />
        </View>
        <View
          style={[styles.content, width ? { maxWidth: width - SIZE - 22 } : {flex: 1}]}
        >
          <View style={styles.productInfo}>
            <Text style={styles.title} numberOfLines={2}>
              {_get(item, 'name', '')}
            </Text>
            {!!unitProduct && (
              <Text style={styles.title}>
                {unitProduct}
              </Text>
            )}
            {showShop && (
              <Text style={styles.shopName} numberOfLines={1}>
                {_get(item, 'profile.displayName', '')}
              </Text>
            )}
            {!!ratingCount && !!rating && (
              <View style={styles.ratingContainer}>
                <Ratings
                  ratings={ratingCount}
                  rating={rating}
                />
              </View>
            )}
            {showPrice && (
              <Price
                hasPriceDiscount={hasPriceDiscount}
                price={price}
                priceDiscount={priceDiscount}
              />
            )}
          </View>
          {showAddToCart && (
            <PreventDoubleClickButton
              disabled={itemNotAvailable}
              style={[
                { backgroundColor: itemNotAvailable ? colors.lightGray : colors.primaryDark },
                styles.cartButton
              ]}
              onPress={this.onAddToCart}
            >
              {!itemNotAvailable && (
                <Icon
                  name={'cart'}
                  size={20}
                  color={'white'}
                />
              )}
              <Text style={styles.cartButtonText}>{i18n.t(`product.actionButtons.${itemNotAvailable ? 'outOfStock' : 'addToCart'}`)}</Text>
            </PreventDoubleClickButton>
          )}
        </View>
      </TouchableOpacity>
    )
  }
}

const styles = stylus({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 8,
  },
  image: {
    width: SIZE,
    height: SIZE,
    borderRadius: 4 ,// SIZE / 2,
    overflow: 'hidden',
  },
  circleOutline: {
    // padding: 4,
    // borderRadius: 4, // SIZE + 4,
    // borderColor: colors.primaryDark,
    // borderWidth: 1,
    alignSelf: 'flex-start',
    marginTop: 3,
  },
  content: {
    justifyContent: 'space-between',
    paddingLeft: 10,
    minHeight: SIZE,
  },
  productInfo: {
    // justifyContent: 'space-around',
    flex: 1
  },
  title: {
    fontSize: 14,
    fontWeight: '600',
    color: colors.text.main,
  },
  shopName: {
    fontSize: 14,
    color: colors.text.main,
  },
  bookmark: {
    position: 'absolute',
    top: 0,
    right: 5,
    backgroundColor: 'transparent',
  },
  ratingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ratings: {
    fontSize: 13,
    color: colors.text.main,
  },
  cartButton: {
    padding: 5,
    marginTop: 5,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    alignSelf: 'flex-start'
  },
  cartButtonText: {
    marginLeft: 5,
    marginRight: 5,
    color: 'white',
    fontWeight: '600'
  },
})

export default CarouselItem
