export const LOCATION_UPDATE = 'location/UPDATE'

const DEFAULT_VALUE = {
  latitude: null,
  longitude: null,
}

export default function location(state = DEFAULT_VALUE, action) {
  switch (action.type) {
    case LOCATION_UPDATE:
      return {
        ...state,
        latitude: action.data.latitude,
        longitude: action.data.longitude,
      }
  }

  return state
}