import React, { Component } from 'react'
import { Text, View, TouchableOpacity } from 'react-native'
import { stylus } from '../../config/styles'
import Icon from '../../config/icon'
import { connect } from '../../config/connected'
import Icons from '@expo/vector-icons/Ionicons'
import CartIcon from './CartIcon'
import colors from '../../config/colors'

export default function TabLabel({ label, active, onPress, style }) {
  return (
    <TouchableOpacity
      style={[styles.container, active && styles.active, style]}
      onPress={onPress}
    >
      <Text style={[styles.label, active && styles.labelActive]}>{label}</Text>
    </TouchableOpacity>
  )
}

const styles = stylus({
  container: {
    alignItems: 'center',
    paddingVertical: 7,
    borderBottomWidth: 1,
    borderBottomColor: 'transparent',
  },
  active: {
    borderBottomWidth: 1,
    borderBottomColor: colors.text.main,
  },
  label: {
    color: colors.text.main,
  },
  labelActive: {
    fontWeight: 'bold',
    color: colors.tabView.activeText,
  },
})
