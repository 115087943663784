import React from 'react'
import { InteractionManager, Alert, Platform } from 'react-native'
import * as Network from 'expo-network'
import i18n from 'i18n-js'
import Re from 're'
import base64JS from 'base64-js'
import rfdc from 'rfdc'
const clone = rfdc({ proto: false })

function getBackOffRe() {
  return new Re({
    retries: 7 + (60 * 24), // 24 hours
    strategy : {
      type: Re.STRATEGIES.EXPONENTIAL,
      initial: 1000, // 1 sec
      base: 2,
      max: 60000, // 1 min
    }
  })
}

function getAvailabilityRe() {
  return new Re({
    retries: 30, // 5 minutes
    strategy: {
      type: Re.STRATEGIES.CONSTANT,
      initial: 10000, // 10 sec
    }
  })
}

import gql from 'graphql-tag'
import _ from 'lodash'
import uploadImage from '../utility/uploadImage'
import { compose, connect, graphql } from '../config/connected'
import { getFileSize, readAsStringAsync } from '../utility/fileSystem'
import environment from '../config/environment'
import { CONTENT_PUBLISH_CANCELLED } from '../reducers/publishContent'
import { USER_UNSET_PROFILE_IMAGE_UPLOAD } from '../reducers/currentUser'
import { MEDIA_MAX_UPLOAD_ERRORS } from '../config/constants'
import { getPendingContentIndexByUpload, removeUploadsFromCache } from '../config/helpers'
import alert from '../utility/alert'

const debug = false

const fiveMB = numberOfMbInBytes(5)
const tenMB = numberOfMbInBytes(10)
const defaultNumberOfParts = 1

function numberOfMbInBytes(number) {
  return 1024 * 1024 * number
}

function getNumberOfParts(size) {
  let numberOfParts = defaultNumberOfParts
  let sizeCandidate = size / numberOfParts
  while(sizeCandidate > tenMB) {
    numberOfParts++
    sizeCandidate = size / numberOfParts
  }
  return numberOfParts
}

function getPartSize(size, numberOfParts) {
  const partSize = Math.ceil(size/numberOfParts)
  if (partSize < fiveMB) {
    return fiveMB
  }
  return partSize
}

function allPartsComplete(upload, partNumber = undefined) {
  const uploadParts = _.get(upload, 'uploadParts') || []
  return uploadParts.every(uploadPart => uploadPart.signedUpload.state === 'complete' || uploadPart.partNumber === partNumber)
} 

class UploadManager extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploads: [],
      canShowError: false, // AZ1566 - we are no longer showing error prompt, partially becauase network loss errors are handled, may bring it back for giving actions to address upload error
      haveConnection: true,
    }
    this.inProgress = {}
  }
  componentDidMount() {
    setTimeout(async () => {
      debug && console.log('<<<MOUNTING UPLOAD MANAGER AT', new Date())
      const { uploads = {} } = this.props
      debug && console.log('<<<UPLOAD MANAGER - Num of uploads', Object.keys(uploads).length)
      for (const id in uploads) {
        const upload = uploads[id]
        this.inProgress[id] = true
        if (upload.state === 'cancelled') {
          this.cancelContentPublish(upload, true)
        } else if (getPendingContentIndexByUpload(this.props.publishContent, upload) === -1) {
          removeUploadsFromCache([upload])
        }
      }

      for (const id in uploads) {
        const upload = uploads[id]
        const actionKey = this.determineRetryAction(upload)
        if (!actionKey) {
          continue // don't do anything if no action to take
        }
        const clonedUpload = clone(upload)
        if (actionKey === 'uploadFile' && upload.state !== 'uploading') {
          this.props.uploadStarted({ id: clonedUpload.id })
        }
        debug && console.log('<<<UPLOAD MANAGER - MOUNT ACTION -', actionKey, 'for ', clonedUpload.id)
        const action = this[actionKey]
        action(clonedUpload)
      }
    }, 500)
  }

  needSignedUrls = (upload) => {
    let needSignedUrls = true
    if (upload.uploadType === 'multipart' && upload.uploadParts) {
      needSignedUrls = false
    }
    if (upload.uploadType === 'single' && upload.signed) {
      needSignedUrls = false
    }
    return needSignedUrls
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.uploadKeysNotInProgress(nextProps).length > 0
  }

  uploadKeysNotInProgress(uploads) {
    const uploadKeys = Object.keys(uploads)
    const uploadKeysInProgress = Object.keys(this.inProgress)
    return _.difference(uploadKeys, uploadKeysInProgress)
  }

  componentDidUpdate(prevProps, prevState) {
    const current = this.props.uploads
    const existing = prevProps.uploads

    const keysNotInProgress = this.uploadKeysNotInProgress(current)
    if (keysNotInProgress.length === 0) {
      return
    }

    const next = _.pick(current, keysNotInProgress)
    for (let id in next) {
      const existingUpload = existing[id]
      const upload = next[id]
      if (!upload || !this.state.haveConnection || upload.state === 'complete') {
        continue
      }
      if (upload.state === 'cancelled' && _.get(existingUpload, 'state') !== 'cancelled') {
        this.cancelContentPublish(upload)
        continue
      }

      const needSignedUrls = this.needSignedUrls(upload)
      const readyToRetry =  _.get(upload, 'state') === 'retry' 
      const readyToComplete = allPartsComplete(upload)
      const readyToCheck = _.get(upload, 'state') === 'pendingAvailability'
      // debug && console.log('<<<UPLOAD MANAGER - componentDidUpdate - state', id, _.get(upload, 'state'))
      // debug && console.log('<<<UPLOAD MANAGER - componentDidUpdate - readyToRetry for', id, readyToRetry)
      // debug && console.log('<<<UPLOAD MANAGER - componentDidUpdate - readyToComplete for', id, readyToRetry)
      if (!existingUpload || (readyToRetry && needSignedUrls)) {
        debug && console.log('<<<UPLOAD MANAGER - componentDidUpdate - startUpload', id)
        this.startUpload(clone(upload))
      } else if (readyToComplete || (upload.state === 'uploadPartsComplete' && (existingUpload.state !== 'uploadPartsComplete' || readyToRetry))) {
        debug && console.log('<<<UPLOAD MANAGER - componentDidUpdate - completeFileUpload', id)
        this.completeFileUpload(clone(upload))
      } else if (readyToRetry) {
        debug && console.log('<<<UPLOAD MANAGER - componentDidUpdate - retry uploadFile', id)
        this.props.uploadStarted({ id: upload.id })
        this.uploadFile(clone(upload))
      } else if (readyToCheck) {
        this.uploadComplete(clone(upload))
      }
    }
  }

  getUploadClone = (id) => {
    if (!this.props.uploads[id]) {
      return null
    }
    return clone(this.props.uploads[id])
  }

  determineRetryAction = (upload)  => {
    const readyToComplete = allPartsComplete(upload)
    const needSignedUrls = this.needSignedUrls(upload)
    const reattemptUpload = upload.state === 'uploading' || upload.state === 'retry'
    debug && console.log('<<<UPLOAD MANAGER - determineRetryAction - for id', upload.id ,' in state', upload.state)
    if (reattemptUpload && !needSignedUrls) {
      return 'uploadFile'
    } else if (upload.state === 'pending' || (reattemptUpload && needSignedUrls) ) {
      return 'startUpload'
    } else if (upload.state === 'uploadPartsComplete') {
      if (!readyToComplete) {
        return 'uploadFile'
      } else {
        return 'completeFileUpload'
      }
    } else if (upload.state === 'pendingAvailability') {
      return 'uploadComplete'
    }
  }

  retryUploadForConnectionLossOrError = async(upload, { partNumber, error }) => {
    if (!upload) return

    let networkState
    try {
      networkState = await Network.getNetworkStateAsync()
    } catch (e) {
      console.log('<<<retryUploadForConnectionLossOrError - cant get network state', e)
    }

    const isInternetReachable = _.get(networkState, 'isInternetReachable', false)
    // if there's a connection loss try to regain connection and mark upload for retry
    if (!isInternetReachable) {
      console.log('<<<UPLOAD MANAGER - retryUploadForConnectionLossOrError - retry newtork connection', upload.id, partNumber)
      this.retryNetworkConnection()
      delete this.inProgress[upload.id]
      this.props.uploadRetry({ id: upload.id })
    // there was an error and we need to retry
    } else {
      debug && console.log('<<<UPLOAD MANAGER - retryUploadForConnectionLossOrError - retry', upload.id, partNumber)
      const backgroundError = _.get(error, 'status') === 0 // lost connection for app being in background
      debug && console.log('<<<UPLOAD MANAGER - retryUploadForConnectionLossOrError - Was background error?', backgroundError)
      let numberOfErrors = upload.numberOfErrors || 0
      const delay = backgroundError ? 0 : Math.pow(2, numberOfErrors) * 4000
      let newNumberOfErrors = backgroundError ? numberOfErrors : numberOfErrors + 1 // background errors we don't count.
      const fileCouldNotBeRead = _.get(error, 'message', '').indexOf('could not be read') !== -1 
      if (newNumberOfErrors >= MEDIA_MAX_UPLOAD_ERRORS || fileCouldNotBeRead) {
        delete this.inProgress[upload.id]
        this.props.uploadCancel(upload)
        return
      }

      setTimeout(() => {
        debug && console.log('<<<UPLOAD MANAGER - newNumberOfErrors for ', upload.id, ': ', newNumberOfErrors)
        delete this.inProgress[upload.id]
        this.props.uploadRetry({ id: upload.id, numberOfErrors: newNumberOfErrors })
      }, delay)
    }
  }

  startUpload = async (file) => {
    if (!file) return

    this.inProgress[file.id] = true
    try {
      const updatedFile = await this.setupUpload(file)
      this.uploadFile(updatedFile)
    } catch (e) {
      debug && console.log('<<<UPLOAD MANAGER - startUpload error -', e)
      const upload = this.getUploadClone(file.id)
      if (!upload) return
      this.retryUploadForConnectionLossOrError(upload, { error: e })
    }
  }

  setupUpload = (file) => {
    if (!file) return

    this.inProgress[file.id] = true
    this.props.uploadStarted({ id: file.id })
    if (file.uploadType === 'multipart') {
      return this.setupMultipartUplaod(file)
    } else {
      return this.setupSingleUpload(file)
    }
  }

  setupMultipartUplaod = async(file) => {
    if (!file) return

    const fileSize = await getFileSize(file.uri)
    if (!fileSize) {
      this.cancelContentPublish(file)
      return
    }
    const numberOfParts = getNumberOfParts(fileSize)
    const partSize = getPartSize(fileSize, numberOfParts)
    debug && console.log('Upload File Uri', file.uri)
    debug && console.log('Upload File Size: ', fileSize)
    debug && console.log('Upload Number Of Parts: ', numberOfParts)
    debug && console.log('Upload Part Size', partSize)

    // debug && console.log('<<<UPLOAD IMAGE - reading file now', new Date())
    // const blob = await readAsStringAsync(file.uri, { position: 0, length: fileSize })
    // debug && console.log('<<<UPLOAD IMAGE - reading file done', new Date())
    // return 

    const uploadIdResult = await this.props.createMultipartUpload({
      variables: {
        mime: file.mime,
        name: file.id,
      }
    })
    const uploadId = uploadIdResult.data.createMultipartUpload

    const uploadPartsResult = await this.props.signUploadParts({
      variables: {
        mime: file.mime,
        name: file.id,
        uploadId,
        numberOfParts,
      }
    })
    const uploadParts = clone(uploadPartsResult.data.signUploadParts)
    this.props.attachMultipartMetadata({ id: file.id, uploadParts, uploadId, partSize })

    return { uploadParts, partSize, uploadId, fileSize, ...file }
  }

  setupSingleUpload = async(file) => {
    if (!file) return

    const signResult = await this.props.signUpload({
      variables: {
        mime: file.mime,
        name: file.id,
      },
    })
    file.signed = clone(signResult.data.signUpload)
    return file
  }

  uploadFile = async (file) => {
    if (!file) return

    this.inProgress[file.id] = true
    if (file.uploadType === 'multipart') {
      return this.uploadMultipartFile(file)
    } else {
      return this.uploadSingleFile(file)
    }
  }

  uploadMultipartFile = async(file) => {
    if (!file) return

    const { uploadParts, partSize } = file
    debug && console.log('<<<UPLOAD MANAGER - uploadMultipartPartFile - Parts', uploadParts.length)
   
    for (const uploadPart of uploadParts) {
      if (uploadPart.signedUpload.state === 'complete' && uploadPart.eTag) {
        debug && console.log('<<<UPLOAD MANAGER - Upload id', file.id ,'and Part ' + uploadPart.partNumber, 'already completed')
        continue
      }
      const { partNumber, signedUpload } = uploadPart
      const position = partSize * (partNumber - 1)
      try {
        debug && console.log('<<<UPLOAD MANAGER - reading file now from', position, 'to', position + partSize, new Date())
        const binaryBlob = await readAsStringAsync(file.uri, { position, length: partSize })
        debug && console.log('<<<UPLOAD MANAGER - reading file done', new Date())
        debug && console.log('<<<UPLOAD MANAGER - start decoding binaryBlob', new Date())
        const byteArray = base64JS.toByteArray(binaryBlob)
        debug && console.log('<<UPLOAD MANAGER - byteArraySlice length', byteArray.length)
        debug && console.log('<<<UPLOAD MANAGER - done decoding binaryBlob', new Date())
        const result = await uploadImage({
          id: file.id,
          partNumber,
          signed: signedUpload,
          byteArray,
          onProgress: this.debouncedProgress,
          onError: this.onError.bind(this, file),
        })
        debug && console.log('<<<UPLOAD MANAGER - uploadMultipartPartFile result status', result.status)
        if (result.status == 200) {
          const eTag = _.get(result, 'responseHeaders.etag') || _.get(result, 'responseHeaders.Etag') || _.get(result, 'responseHeaders.ETag')
          debug && console.log('<<<UPLOAD MANAGER - uploadMultipartPartFile result - Upload id', file.id ,'and Part ' + partNumber + ' ETag', eTag)
          const upload = this.getUploadClone(file.id)
          if (!upload) return
          this.props.uploadPartComplete({ id: file.id, partNumber, eTag: eTag || uploadPart.eTag })
          if(allPartsComplete(upload, partNumber)) {
            this.completeFileUpload(upload)
          }
        }
      } catch (error) {
        debug && console.log('<<<UPLOAD MANAGER - uploadMultipartPartFile result -', 'Part ' + partNumber + ' Error', error)
        debug && console.log('<<<UPLOAD MANAGER - uploadMultipartPartFile initiating part retry')
        const upload = this.getUploadClone(file.id)
        if (!upload) return
        if (error.status === 404) {
          this.uploadComplete(upload)
        } else {
          this.retryUploadForConnectionLossOrError(upload, { error, partNumber })
        }
        break
      }
    }
  }

  uploadSingleFile = async(file) => {
    if (!file) return

    let byteArray
    if (Platform.OS === 'web') {
      const binaryBlob = await readAsStringAsync(file.uri)
      debug && console.log('<<<UPLOAD MANAGER - WEB - reading file done', new Date())
      debug && console.log('<<<UPLOAD MANAGER - WEB - start decoding binaryBlob', new Date())
      byteArray = base64JS.toByteArray(binaryBlob)
    }
    return uploadImage({
      id: file.id,
      uri: file.uri,
      byteArray,
      signed: file.signed,
      onProgress: this.uploadProgress,
      privacy: 'private',
    }).then(result => {
      debug && console.log('<<<UPLOAD MANAGER - uploadSingleFile result status', result.status)
      const upload = this.getUploadClone(file.id)
      if (!upload) return
      if (result.status == 200) {
        this.uploadComplete(upload)
      }
    }).catch(error => {
      const upload = this.getUploadClone(file.id)
      if (!upload) return
      debug && console.log('<<<UPLOAD MANAGER - uploadSingleFile result - Error', error)
      debug && console.log('<<<UPLOAD MANAGER - uploadSingleFile initiating upload retry')
      this.retryUploadForConnectionLossOrError(upload, { error })
    })
    
  }

  retryNetworkConnection = () => {
    if (!this.state.haveConnection) {
      return
    }
    this.setState({ haveConnection: false })
    debug && console.log('<<<UPLOAD MANAGER - Retrying Network Connection')
    const backoffRe = getBackOffRe()
    backoffRe.try(async(retryCount, done) => {
      try {
        debug && console.log('<<<UPLOAD MANAGER - retryNetworkConnection - Attempting to reach server for', retryCount,'th time')
        // const { data, status, statusText, config, request } = await axios.get(environment.serverEndpoint)
        // debug && console.log(data, status, statusText, config, request)
        const networkState = await Network.getNetworkStateAsync()
        debug && console.log('<<<UPLOAD MANAGER - networkState', networkState)
        if (networkState.isInternetReachable) {
          done(null)
        } else {
          done('<<<UPLOAD MANAGER - Network Error - Internet is not reachable')
        }
      } catch (e) {
        debug && console.log('<<<UPLOAD MANAGER - retryNetworkConnection - Attempt to reach server failed')
        done(e)
      }
    }, this.retryNetworkConnectionDone)
  }

  retryNetworkConnectionDone = (err)  => {
    debug && console.log('<<<UPLOAD MANAGER - Retrying Network Connection Done')
    if (!err) {
      debug && console.log('<<<UPLOAD MANAGER - retryNetworkConnectionDone - we have a connection!')
      this.setState({ haveConnection: true })
    }
  }

  completeFileUpload = async(upload) => {
    if (!upload && upload.state !== 'complete') return

    this.inProgress[upload.id] = true
    try {
      await this.props.completeMultipartUpload({
        variables: {
          name: upload.id,
          mime: upload.mime,
          uploadId: upload.uploadId,
          uploadParts: upload.uploadParts.map( ({ partNumber, eTag }) => ({ PartNumber: partNumber, ETag: eTag }) )
        }
      })
      this.uploadComplete(upload)
    } catch (e) {
      debug && console.log('<<<UPLOAD MANAGER - completeMultipartUpload error', e.message, e.extraInfo, e.networkError)
      const file = this.getUploadClone(upload.id)
      if (!file) return
      // multipart uploadId can no longer be found because it was already completed. so complete action doesn't need to be performed.
      if (e.message.indexOf('completed') !== -1) {
        this.uploadComplete(file)
      // some other error occurred so we should retry
      } else {
        this.retryUploadForConnectionLossOrError(file, { error: e })
      }
    }
  }

  uploadComplete = (upload) => {
    if (!upload) return

    this.inProgress[upload.id] = true
    const { id, file: { type } } = upload
    debug && console.log('<<<UPLOAD MANAGER - uploadComplete', id , type)
    if (type === 'video') {
      this.props.uploadPendingAvailability({ id })
      const availabilityRe = getAvailabilityRe()
      availabilityRe.try(async(retryCount, done) => {
        let cdnAssetName = id.split('.')[0]
        const url = `https://${environment.videoCDNId}.cloudfront.net/assets/${cdnAssetName}/HLS/${cdnAssetName}.m3u8`
        debug && console.log(`<<<UPLOAD MANAGER - CDN Fetch ${retryCount + 1} for`, url, 'at', new Date())
        const statusPromise = new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest()
          xhr.timeout = 8000
          xhr.open('HEAD', url, true)
          xhr.onreadystatechange = () => {
            if (xhr.readyState === xhr.DONE) {
              resolve(xhr.status)
            }
          }
          xhr.send()
        })
        try {
          const status = await statusPromise
          done(!([200, 0].includes(status)), status)
        } catch (e) {
          console.log('<<<UPLOAD MANAGER - HEAD request error', e)
        }
      }, (error, status) => {
        debug && console.log('<<<UPLOAD MANAGER - CDN Fetch - has error?', error)
        if (!error && status === 200) {
          this.props.uploadComplete({ id })
        } else if (!error && status === 0) {
          const uploadClone = this.getUploadClone(upload.id)
          if (!uploadClone) return
          this.retryUploadForConnectionLossOrError(uploadClone, { status })
        }
      })
    } else {
      // delete this.inProgress[id]
      this.props.uploadComplete({ id })
    }
  }

  onError = (file, error) => {
    debug && console.log('<<<UPLOAD MANAGER - onError type', typeof error)
    debug && console.log('<<<UPLOAD MANAGER - onError', error)
    if (this.state.canShowError) {
      this.setState(
        {canShowError: false},
        () => {
          Alert.alert(
            i18n.t('errors.uploadFailed'),
            environment.environment === 'dev' ? error : '',
            [
              { text: 'OK', onPress: () =>  {
                this.setState({canShowError: true})
              } },
            ],
            { cancelable: false }
          )
        }
      )
    }
  }
  uploadProgress = ({ id, partNumber, progress }) => {
    InteractionManager.runAfterInteractions(() => {
      this.props.uploadProgress({ id, partNumber, progress })
    })
  }
  debouncedProgress = _.debounce(this.uploadProgress, 1000, { leading: true, trailing: false })
  cancelContentPublish = async (upload, mounting) => {
    if (!upload) return

    this.inProgress[upload.id] = true

    if (_.get(this.props, 'currentUser.profileImageUpload.id') === upload.id) {
      this.props.dispatch({
        type: USER_UNSET_PROFILE_IMAGE_UPLOAD,
      })
    }
    
    const contentIndex = getPendingContentIndexByUpload(this.props.publishContent, upload)
    if (contentIndex !== -1) {
      const content = this.props.publishContent.pending[contentIndex]
      debug && console.log('<<<UPLOAD MANAGER - cancelContentPublish - cancel it', upload)
      this.props.dispatch({
        type: CONTENT_PUBLISH_CANCELLED,
        upload
      })
      removeUploadsFromCache(content.allUploads)
    } else {
      if (!mounting) {
        alert({
          title: i18n.t('common.error'),
          message: i18n.t('errors.uploadFailed'),
        })
      }
      debug && console.log('<<<UPLOAD MANAGER - cancelContentPublish - just remove uploads')
      removeUploadsFromCache([upload], !mounting)
    }
  }
  render() {
    return null
  }
}

const mapStateToProps = (state) => ({
  platform: state.screenInfo.platform,
  uploads: state.uploads,
  currentUser: state.currentUser,
  publishContent: state.publishContent,
})

const signUpload = gql`
  mutation($name: String!, $mime: String!) {
    signUpload(name: $name, mime: $mime) {
      name
      mime
      signedRequest
    }
  }
`

const createMultipartUpload = gql`
  mutation($name: String!, $mime: String!) {
    createMultipartUpload(name: $name, mime: $mime)
  }
`

const signUploadParts = gql`
  mutation($name: String!, $mime: String!, $numberOfParts: Int!, $uploadId: String!) {
    signUploadParts(name: $name, mime: $mime, numberOfParts: $numberOfParts, uploadId: $uploadId) {
      partNumber
      eTag
      signedUpload {
        name
        mime
        signedRequest
      }
    }
  }
`

const completeMultipartUpload = gql`
  mutation($name: String!, $mime: String!, $uploadParts: [UploadPartInput!]!, $uploadId: String!) {
    completeMultipartUpload(name: $name, mime: $mime, uploadParts: $uploadParts, uploadId: $uploadId)
  }
`

const mapDispatchToProps = (dispatch) => ({
  uploadStarted: ({ id }) =>
    dispatch({
      type: 'Upload/STARTED',
      id,
    }),
  uploadComplete: ({ id }) =>
    dispatch({
      type: 'Upload/COMPLETE',
      id,
    }),
  uploadPendingAvailability: ({ id }) =>
    dispatch({
      type: 'Upload/PENDING_AVAILABILITY',
      id,
    }),
  uploadProgress: ({ id, partNumber, progress }) =>
    dispatch({
      type: partNumber ? 'Upload/PART_PROGRESS' : 'Upload/PROGRESS',
      id,
      partNumber,
      progress,
    }),
  uploadRetry: ({ id, numberOfErrors }) =>
    dispatch({
      type: 'Upload/RETRY',
      id,
      numberOfErrors,
    }),
  uploadPartComplete: ({ id, partNumber, eTag}) =>
    dispatch({
      type: 'Upload/PART_COMPLETE',
      id,
      partNumber,
      eTag,
    }),
  uploadPartRetry: ({ id, partNumber }) => {
    dispatch({
      type: 'Upload/PART_RETRY',
      id,
      partNumber,
    })
  },
  attachMultipartMetadata: ({ id, uploadParts, uploadId, partSize }) => {
    dispatch({
      type: 'Upload/ATTACH_MULTIPART_METADATA',
      id,
      uploadParts,
      uploadId,
      partSize,
    })
  },
  uploadCancel: ({ id }) => {
    dispatch({
      type: 'Upload/CANCEL',
      id,
    })
  },
  dispatch,
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(signUpload, {
    name: 'signUpload',
  }),
  graphql(createMultipartUpload, {
    name: 'createMultipartUpload'
  }),
  graphql(signUploadParts, {
    name: 'signUploadParts'
  }),
  graphql(completeMultipartUpload, {
    name: 'completeMultipartUpload'
  })
)(UploadManager)
