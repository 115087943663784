import _ from 'lodash'
import { StyleSheet, Text, View } from 'react-native'
import i18n from 'i18n-js'
import PropTypes from 'prop-types'
import colors from '../../config/colors'
import React from 'react'

const TagsCloud = ({ items, i18nKey }) => {

  if (!_.isArray(items) || _.isEmpty(items)) {
    return null
  }

  return (
    <View style={styles.allergenWarningContainer}>
      {items.map((value, index) => (
        <View key={index} style={styles.allergenWarningTag}>
          <Text style={styles.allergenWarningTagText}>
            {i18nKey ? i18n.t(`${i18nKey}.${value}`) : value}
          </Text>
        </View>
      ))}
    </View>
  )
}

TagsCloud.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  i18nKey: PropTypes.string,
}

const styles = StyleSheet.create({
  allergenWarningContainer: {
    // paddingVertical: 4,
    // paddingHorizontal: 2,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  allergenWarningTag: {
    borderRadius: 5,
    margin: 2,
    paddingVertical: 3,
    paddingHorizontal: 6,
    backgroundColor: colors.emptyArea,
  },
  allergenWarningTagText: {
    color: colors.text.main,
    fontSize: 12,
  },
})

export default React.memo(TagsCloud)
