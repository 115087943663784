import React, { useCallback, useEffect, useRef } from 'react'
import { ActivityIndicator, FlatList, StyleSheet, Text, TouchableOpacity, View, SafeAreaView } from 'react-native'
import { graphql } from 'react-apollo'
import usePrevious from '../../hooks/usePrevious'
import schema from '../../schema/user'
import addressSchema from '../../schema/address'
import checkoutSessionSchema from '../../schema/checkoutSession'
import { compose, hoistStatics } from 'recompose'
import { renderWhileLoading } from '../../config/helpers'
import NavigationActions from '../../utility/navigationActions'
import PropTypes from 'prop-types'
import Icons from '@expo/vector-icons/Ionicons'
import _ from 'lodash'
import MobileBackButton from '../../components/simple/MobileBackButton'
import update from 'immutability-helper'
import colors from '../../config/colors'
import Button from '../../components/simple/Button'
import i18n from 'i18n-js'
import { connect } from 'react-redux'
import { ADDRESS_SET_BILLING, ADDRESS_SET_SHIPPING } from '../../reducers/address'

const Loading = () => <ActivityIndicator style={{ flex: 1, width: '100%' }} size='large'/>

const debug = false

function Item(props) {
  const { zip, isDefault, city, region, country, lastName, firstName, address2, address1, phone, id } = props.address
  const iconMode = isDefault ? 'on' : 'off'
  const iconName = props.isSelect ? `ios-radio-button-${iconMode}` : 'ios-pin'

  return (
    <TouchableOpacity disabled={!props.isSelect} onPress={() => {
      props.setAddressDefault(props.address)
    }} style={styles.itemContainer}>
      <View style={[styles.row, { width: 50, justifyContent: 'center' }]}>
        <Icons name={iconName} size={22}/>
      </View>
      <View style={{ flex: 1, marginRight: 20 }}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={[styles.addressText]}>{firstName} {lastName}</Text>
          {isDefault ? (
            <Text style={{ marginLeft: 10, color: colors.taggingText }}>{!props.isSelect ? 'Default' : ''}</Text>
          ) : (
            <TouchableOpacity onPress={() => props.onSetDefault(props.address)}>
              <Text style={{ marginLeft: 10, color: colors.taggingText }}>{!props.isSelect ? 'Set Default' : ''}</Text>
            </TouchableOpacity>
          )}
        </View>
        <Text style={[styles.addressText]}>{address1}</Text>
        {!_.chain(address2).trim().isEmpty().value() ? <Text>{address2}</Text> : null }
        <Text style={[styles.addressText]}>{city}, {region} {zip}</Text>
        <Text style={[styles.addressText]}>{country}</Text>
        <Text style={[styles.addressText]}>{`${i18n.t('common.phone')}: `}{phone}</Text>
        <View style={[styles.row, { flex: 1 }]}>
          <View style={[styles.row, { flex: 1, justifyContent: 'flex-end' }]}>
            <TouchableOpacity style={styles.row} onPress={() => {
              props.onEdit(props.address)
            }}>
              <Icons name={'md-create'} size={16} style={{ color: colors.taggingText }}/>
              <Text style={[styles.ml3, { color: colors.taggingText }]}>Edit</Text>
            </TouchableOpacity>
            {props.hideDelete !== true && <TouchableOpacity style={[styles.row, styles.ml10]} onPress={() => {
              props.onDelete(id)
            }}>
              <Icons name={'md-trash'} style={{ color: colors.red }} size={16}/>
              <Text style={[styles.ml3, { color: colors.red }]}>Delete</Text>
            </TouchableOpacity>}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}

Item.propTypes = {
  selectType: PropTypes.oneOf(['shipping', 'billing']),
  onEdit: PropTypes.func,
  setAddressDefault: PropTypes.func,
  onDelete: PropTypes.func,
  isSelect: PropTypes.bool,
  hideDelete: PropTypes.bool,
  address: PropTypes.shape({
    id: PropTypes.any,
    firstName: PropTypes.any,
    lastName: PropTypes.any,
    recipient: PropTypes.any,
    address1: PropTypes.any,
    address2: PropTypes.any,
    city: PropTypes.any,
    region: PropTypes.any,
    zip: PropTypes.any,
    country: PropTypes.any,
    phone: PropTypes.any,
    email: PropTypes.any,
    createdAt: PropTypes.any,
    updatedAt: PropTypes.any,
    isDefault: PropTypes.any,
  }),
}

const AddressesList = ({
  profile, navigation, deleteUserAddress, updateAddress, billing,
  shipping, setBillingAddress, setShippingAddress, saveCheckoutSessionAddress
}) => {
  const activeRoute = NavigationActions.getActiveRoute() || { routeName: '' }
  const previousActiveRoute = usePrevious(activeRoute)
  const firstRouteRef = useRef(null)

  const didMountRef = useRef(false)
  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true
      firstRouteRef.current = activeRoute
      debug && console.log('First Route is ', activeRoute.routeName)
    }
  })

  const isSelect = navigation.getParam('select') || false
  const selectType = navigation.getParam('selectType') || 'shipping'

  const setAddressSelected = useCallback(_.throttle((address) => {
    if (isSelect && selectType === 'shipping') {
      setShippingAddress(address)
      saveCheckoutSessionAddress({ variables: {
        addressId: address.id,
        addressType: selectType
      }})
    } else if (isSelect && selectType === 'billing') {
      setBillingAddress(address)
      saveCheckoutSessionAddress({ variables: {
        addressId: address.id,
        addressType: selectType
      }})
    }
  }, 1000))

  // filtering billing addresses
  let addresses = _.chain(profile.currentUser.addresses || []).filter(i => {
    return selectType === 'billing' ? i.isBilling === true : true
  }).map(i => ({ ...i })).value()

  // for address selections
  if (!_.isEmpty(addresses) && _.filter(addresses, { isDefault: true }).length === 0) {
    addresses = _.chain(addresses).orderBy(['updatedAt'], ['DESC']).value()
    addresses = update(addresses, {
      0: {
        isDefault: { $set: true },
      },
    })
  }
  const selectedAddress = selectType === 'shipping' ? shipping : billing
  // for address selections
  if (isSelect && !_.isEmpty(addresses) && selectedAddress) {
    addresses = _.map(addresses, i => ({ ...i, isDefault: false }))
    addresses = update(addresses, {
      [_.findIndex(addresses, { id: selectedAddress.id })]: {
        isDefault: { $set: true },
      },
    })
  }

  const sortedAddresses = addresses.sort((address1, address2) => {
    if (address1.isDefault) {
      return -1
    } else if (address2.isDefault) {
      return 1
    }
    return 0
  })

  return <SafeAreaView style={{ flex: 1 }}>
    <FlatList
      refreshing={profile.networkStatus === 4}
      onRefresh={profile.refetch}
      data={sortedAddresses}
      renderItem={({ item }) => <Item
        isSelect={isSelect}
        hideDelete={addresses.length === 1}
        setAddressDefault={setAddressSelected}
        selectType={selectType}
        address={item}
        onDelete={(id) => {
          deleteUserAddress({
            variables: {
              id,
            },
            update: (cache) => {
              const newAddresses = _.chain(profile)
                .get('currentUser.addresses', [])
                .map((i) => ({ ...i }))
                .filter(i => i.id !== id)
                .orderBy(['updatedAt'], ['DESC'])
                .value();
              (newAddresses[0] || {}).isDefault = true
              cache.writeQuery({
                query: schema.queries.currentUserFullProfile,
                data: update(profile, {
                  currentUser: {
                    addresses: { $set: newAddresses },
                  },
                }),
              })
            },
          })
        }}
        onEdit={(address) => {
          navigation.navigate('EditUserAddress', { address })
        }}
        onSetDefault={(address) => {
          updateAddress({
            variables: {
              id: address.id,
              input: update(address, { $unset: ['id', 'recipient', 'createdAt', 'updatedAt',  '__typename'] }),
            },
            update: (cache, { data: { updateUserAddress } }) => {
              const oldAddresses = _.chain(profile)
                .get('currentUser.addresses', [])
                .map(i => ({ ...i, isDefault: false }))
                .value()
              const index = _.findIndex(profile.currentUser.addresses, { id: address.id })
              const newAddresses = update(oldAddresses, {
                [index]: { $set: updateUserAddress },
              })
              const updatedProfile = update(profile, {
                currentUser: {
                  addresses: { $set: newAddresses },
                },
              })
              cache.writeQuery({
                query: schema.queries.currentUserFullProfile,
                data: updatedProfile,
              })
            },
          })

        }}
      />}
      keyExtractor={item => item.id}
    />
    <Button
      label={i18n.t('common.addNewAddress')}
      labelStyle={styles.buttonLabel}
      style={styles.button}
      onPress={() => {
        const currentActiveRoute = NavigationActions.getActiveRoute() || { routeName: '' }
        const alreadyAtInitialRoute = currentActiveRoute.routeName === _.get(firstRouteRef, 'current.routeName')
        if (alreadyAtInitialRoute) {
          NavigationActions.navigate({ routeName: 'EditUserAddress'})
        }
      }}
    />
  </SafeAreaView>
}

AddressesList.propTypes = {
  navigation: PropTypes.any,
  billing: PropTypes.any,
  shipping: PropTypes.any,
  deleteUserAddress: PropTypes.func,
  setBillingAddress: PropTypes.func,
  setShippingAddress: PropTypes.func,
  saveCheckoutSessionAddress: PropTypes.func,
  updateAddress: PropTypes.func,
  profile: PropTypes.shape({
    refetch: PropTypes.func,
    networkStatus: PropTypes.number,
    currentUser: PropTypes.shape({
      addresses: PropTypes.arrayOf(PropTypes.shape({
        recipient: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        region: PropTypes.string,
        zip: PropTypes.string,
        country: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      })),
    },)
  }),
}

AddressesList.navigationOptions = ({ navigation }) => {
  return {
    headerTitle: i18n.t('settings.sectionLinks.addresses'),
    headerLeft: () => (<MobileBackButton onPress={() => {
      NavigationActions.back()
    }} color={colors.black}/>),
  }
}

const enchanced = compose(
  connect((state) => ({
    billing: state.address.billing,
    shipping: state.address.shipping,
  }), (dispatch) => ({
    setBillingAddress: (address) => {
      dispatch({
        type: ADDRESS_SET_BILLING,
        address,
      })
    },
    setShippingAddress: (address) => {
      dispatch({
        type: ADDRESS_SET_SHIPPING,
        address,
      })
    },
  })),
  graphql(schema.queries.currentUserFullProfile, {
    name: 'profile',
    options: (props) => {
      const restrictToLocale = props.route.params.selectType === 'shipping'
      return {
        variables: { restrictToLocale },
        fetchPolicy: restrictToLocale ? 'network-only' : 'cache',
      }
    }
  }),
  graphql(addressSchema.mutations.updateUserAddress, {
    name: 'updateAddress',
  }),
  graphql(addressSchema.mutations.deleteUserAddress, {
    name: 'deleteUserAddress',
  }),
  graphql(addressSchema.mutations.deleteUserAddress, {
    name: 'deleteUserAddress',
  }),
  graphql(checkoutSessionSchema.mutations.saveCheckoutSessionAddress, {
    name: 'saveCheckoutSessionAddress',
  }),
  renderWhileLoading(Loading, 'profile'),
)

export default hoistStatics(enchanced)(AddressesList)


const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    paddingTop: 20,
  },
  addressText: {
  },
  ml3: {
    marginLeft: 3,
  },
  ml10: {
    marginLeft: 10,
  },
  row: {
    flexDirection: 'row',
  },
  button: {
    backgroundColor: colors.primary,
    marginBottom: 10,
    marginTop: 20,
    marginHorizontal: 16,
    alignSelf: "center"
  },
})
