import React, { Component } from 'react'
import { Text, View } from 'react-native'
import { connect, graphql } from '../../../config/connected'
import colors from '../../../config/colors'

import CreateProduct from '../../../screens/Product/create/CreateProduct'

class EditProduct extends Component {
  render() {
    return (
      <CreateProduct
        navigation={this.props.navigation}
        productId={this.props.navigation.state.params.id}
      />
    )
  }
}

export default EditProduct
