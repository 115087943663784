import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  Platform,
  View,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
} from 'react-native'

import NavigationActions from '../../utility/navigationActions'
import withConversations from '../../containers/withConversations'
import withCurrentUser from '../../containers/withCurrentUser'
import { compose, connect } from '../../config/connected'


import Scroller from '../../components/Scroller'
import PageTitle from '../../components/simple/PageTitle'
import Action from '../../components/simple/Action'
import ProfileImage from '../../components/Post/ProfileImage'
import ListItem from '../../components/simple/ListItem'
import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import StatusBar from '../../components/simple/StatusBar'
import ConversationItem from '../../components/ConversationList/ConversationItem'
import Conversation from '../../components/Conversation/Conversation'
import ChatInput from '../../components/Conversation/ChatInput'
import _ from 'lodash'
import BlankMessage from '../../components/simple/BlankMessage'
import KeyboardAvoidingView from '../../components/simple/KeyboardAvoidingView'
import ChatSubscriptions from '../../components/Conversation/ChatSubscriptions'
import helpers from '../../components/Conversation/helpers'
import NewConversation from '../NewConversation'
import StartButton from '../NewConversation/StartButton'
import Options from '../../components/Conversation/Options'

class ChatLandscape extends React.Component {
  render() {
    const { screenInfo, navigation } = this.props
    const {
      width,
      height,
      device,
      layout,
      platform,
      paddingLeft,
      paddingRight,
      leftBarWidth,
      rightBarWidth,
      outerPadding,
      topBarHeight,
      gutterWidth,
      contentWidth,
    } = screenInfo

    const panelWidth = contentWidth + rightBarWidth
    const leftWidth = panelWidth * 0.4
    const shortcode = _.get(navigation, 'state.params.shortcode', 'all')

    return (
      <KeyboardAvoidingView
        behavior='padding'
        keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 0}
        style={[
          styles.container,
          {
            left: paddingLeft,
            right: outerPadding + 22,
            top: topBarHeight + 30,
            bottom: 30,
          },
        ]}
      >
        <View style={[styles.left, { width: leftWidth }]}>
          <Text style={styles.chatTitle}>Conversations</Text>
          <View style={styles.leftContent}>
            <Scroller
              loadMore={this.loadMore.bind(this)}
              contentContainerStyle={{
                marginLeft: 0,
                width: leftWidth,
                paddingTop: 0,
              }}
            >
              {this.conversations({ selected: shortcode })}
            </Scroller>
          </View>
          <Action
            label='New Conversation'
            style={styles.newConversation}
            onPress={this.props.goToNewConversation}
          >
            <Icons
              name='md-add'
              size={18}
              color={colors.primaryContrast}
              style={{ paddingRight: 8, paddingTop: 1 }}
            />
          </Action>
        </View>
        <View style={[styles.right]}>{this.content(shortcode)}</View>
        <ChatSubscriptions />
      </KeyboardAvoidingView>
    )
  }
  content(shortcode) {
    if (shortcode === 'all') {
      return this.blank()
    }
    if (shortcode === 'new') {
      return this.newConversation()
    }
    return this.currentConversation(shortcode)
  }
  newConversation() {
    return (
      <View style={styles.rightContent}>
        <View style={styles.conversationTitle}>
          <Text numberOfLines={1} style={styles.conversationTitleLabel}>
            New Conversation
          </Text>
          <StartButton navigation={this.props.navigation} landscape />
        </View>
        <NewConversation />
      </View>
    )
  }
  currentConversation(shortcode) {
    const { navigation, cuid } = this.props
    const { state } = navigation
    const { params } = state
    const { users, id } = params
    const title = helpers.conversationName({ members: users, cuid, limit: 5 })
    return (
      <View style={styles.rightContent}>
        <View style={styles.conversationTitle}>
          <Text numberOfLines={1} style={styles.conversationTitleLabel}>
            {title}
          </Text>
          <Action>
            <Icons
              name='ios-search'
              size={22}
              color={colors.primaryContrast}
              style={{ paddingRight: 18 }}
            />
          </Action>
          <Action>
            <Icons
              name='ios-attach'
              size={22}
              color={colors.primaryContrast}
              style={{ paddingRight: 18 }}
            />
          </Action>
          <Action>
            <Icons
              name='ios-information-circle-outline'
              size={21}
              color={colors.primaryContrast}
              style={{ paddingRight: 18 }}
            />
          </Action>
          {id && (
            <Options
              shortcode={shortcode}
              conversationId={id}
              afterDelete={this.props.reset}
              style={{ paddingRight: 18 }}
            />
          )}
        </View>
        <Conversation shortcode={shortcode} users={users} landscape />
      </View>
    )
  }
  blank() {
    return <BlankMessage message='No Conversation Selected...' />
  }
  loadMore() {}
  conversations({ selected }) {
    const { conversations, cuid } = this.props
    if (conversations.loading) {
      return <ActivityIndicator size='large' style={{ marginTop: 20 }} />
    }
    if (conversations.conversationMemberships.length === 0) {
      return <BlankMessage message='No Conversations yet 😉' />
    }
    return conversations.conversationMemberships.map((membership) => {
      return (
        <ConversationItem
          membership={membership}
          cuid={cuid}
          onPress={() =>
            this.props.goToConversation({
              users: _.map(membership.conversation.memberships, 'user'),
              id: membership.id,
            })
          }
          key={membership.id}
          selected={membership.id === selected}
        />
      )
    })
  }
}

const headerStyle = () => {
  if (Platform.OS === 'android') {
    return {
      marginTop: 200,
    }
  }
  return null
}

ChatLandscape.navigationOptions = (props) => {
  return {}
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch, props) => ({
  goToConversation: ({ users, id }) =>
    props.navigation.setParams({ shortcode: id, users, id })
  ,
  reset: () =>
    props.navigation.setParams({ shortcode: 'all', users: null, id: null })
  ,
  goToNewConversation: () =>
    props.navigation.setParams({ shortcode: 'new', users: [] })
  ,
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCurrentUser,
  withConversations,
)(ChatLandscape)

const styles = stylus({
  container: {
    landscape: {
      ...shared.card,
    },
    flex: 1,
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'stretch',
    overflow: 'hidden',
  },
  left: {
    alignItems: 'stretch',
  },
  leftContent: {
    flex: 1,
  },
  right: {
    borderLeftColor: colors.thinLine,
    borderLeftWidth: sizes.px,
    flex: 1,
    // backgroundColor: 'red',
  },
  chatTitle: {
    fontWeight: 'bold',
    color: '#4A4A4A',
    fontSize: 18,
    paddingVertical: 16,
    paddingLeft: 18,
  },
  newConversation: {
    paddingLeft: 32,
    height: 50,
    justifyContent: 'flex-start',
    alignItems: 'center',
    // borderTopColor: colors.thinLine,
    // borderTopWidth: sizes.px,
  },
  rightContent: {
    flex: 1,
  },
  inputWrapper: {
    height: 74,
    paddingHorizontal: 14,
  },
  composeInput: {
    flex: 1,
  },
  conversationTitleLabel: {
    fontWeight: 'bold',
    color: '#4A4A4A',
    fontSize: 18,
    paddingVertical: 16,
    paddingLeft: 18,
    marginRight: 3,
    flex: 1,
  },
  conversationTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
