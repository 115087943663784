import { FlatList, StyleSheet } from 'react-native'
import React, { useCallback } from 'react'
import ListItem from '../../components/simple/ListItem'
import { compose, gql, graphql } from '../../config/connected'
import PropTypes from 'prop-types'
import Button from '../../components/simple/Button'
import colors from '../../config/colors'
import withCurrentUser from '../../containers/withCurrentUser'
import alert from '../../utility/alert'
import _ from 'lodash'

const reports = [
  { key: 'STICKINESS_REPORT', title: 'Stickiness Report' },
  { key: 'ACTIVITY_REPORT', title: 'Activity Report' },
  { key: 'SALES_REPORT', title: 'Sales Report' },
  { key: 'USER_ACTIVITY_REPORT', title: 'User Activity Report' },
  { key: 'ALGOLIA_POPULAR_SEARCHES', title: 'Algolia Popular Searches' },
  { key: 'ALGOLIA_NO_RESULTS_SEARCHES', title: 'Algolia NoResults Searches' },
  { key: 'SHOP_ACTIVITY_REPORT', title: 'Shop Activity Report' },
]

const Item = ({ item, generateReport, email }) => {

  const onPress = useCallback(() => {
    generateReport({
      variables: {
        type: item.key,
      },
    }).catch(console.log)
    alert({ title: 'Info', message: `Report was sent to ${email}` })
  })

  return (
    <ListItem
      style={{ flex: 1 }}
      right={<Button style={styles.button} onPress={onPress} label={'Email Report'}/>}
      title={item.title}
    />
  )
}

Item.propTypes = {
  email: PropTypes.string,
  item: PropTypes.object,
  generateReport: PropTypes.fun,
}

const DashboardReports = ({ generateReport, currentUser }) => {
  if (currentUser.loading) {
    return null
  }
  const email = _.get(currentUser, 'currentUser.email')
  return <FlatList
    style={{ flex: 1 }}
    data={reports}
    renderItem={({ item }) => <Item key={`${item.key}`} item={item} generateReport={generateReport} email={email}/>}
    keyExtractor={item => item.id}
  />
}

DashboardReports.propTypes = {
  generateReport: PropTypes.func,
  currentUser: PropTypes.shape({
    currentUser: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
}

const mutation = gql`
    mutation generateReport($type: REPORT_TYPE){
        generateReport(type: $type)
    }
`

const styles = StyleSheet.create({
  button: { backgroundColor: colors.primary, marginRight: 10 },
})

export default compose(
  withCurrentUser,
  graphql(mutation, {
    name: 'generateReport',
  }),
)(DashboardReports)
