import React from 'react'
import {
  Button,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  View,
} from 'react-native'
import NavigationActions from '../../utility/navigationActions'

import isMobileSafari from '../../config/isMobileSafari'
import Icons from '@expo/vector-icons/Ionicons'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import Blurry from '../../components/simple/Blurry'
import BackButton from '../../components/simple/BackButton'
import Action from '../../components/simple/Action'
import shared, { stylus } from '../../config/styles'

const Header = ({ navigation, router, activeIndex, options }) => {
  const { dispatch, state } = navigation
  return (
    <View style={styles.header}>
      <View style={styles.innerHeader}>
        <View style={styles.headerLeft}>{options.headerLeft}</View>
        <View style={styles.headerCenter}>
          <Text style={styles.headerTitle}>{options.headerTitle}</Text>
        </View>
        <View style={styles.headerRight}>{options.headerRight}</View>
      </View>
    </View>
  )
}

const StackView = ({ navigation, ...props }) => {
  const { dispatch, state, router } = navigation
  const { routes, index } = state

  const screenNavigation = {
    state: routes[index],
    dispatch,
  }
  const options = router.getScreenOptions(screenNavigation, {})
  return (
    <View style={styles.container}>
      {routes.map((route, i) => {
        const Screen = router.getComponentForRouteName(route.routeName)
        return (
          <View key={i} style={[styles.screen, i === index && styles.visible]}>
            <Screen
              navigation={{
                dispatch,
                state: routes[i],
              }}
              {...props}
            />
          </View>
        )
      })}
      {options.header !== null && (
        <Header
          navigation={navigation}
          router={router}
          activeIndex={index}
          options={options}
        />
      )}
    </View>
  )
}

export default StackView

const TOP_BAR_HEIGHT = 50

const styles = stylus({
  container: {
    flex: 1,
    position: 'relative',
    ios: {
      marginTop: 20,
    },
  },
  visible: {
    opacity: 1,
    transform: [{ translateX: 0 }],
  },
  screen: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    paddingTop: TOP_BAR_HEIGHT,
    transform: [{ translateX: -Dimensions.get('window').width }],
  },
  header: {
    height: TOP_BAR_HEIGHT,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    borderBottomWidth: sizes.px,
    borderBottomColor: 'rgba(0,0,0,0.1)',
    // shadowColor: '#000',
    // shadowRadius: 2,
    // shadowOpacity: 0.03,
    backgroundColor: colors.topNavigationBackground,
  },
  innerHeader: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerLeft: {
    width: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerLeftInside: {
    marginRight: 'auto',
  },
  headerCenter: {
    flex: 0.5,
    alignItems: 'center',
  },
  headerTitle: {
    color: colors.headerTitle,
    fontSize: 17,
    fontWeight: '500',
    alignItems: 'center',
  },
  headerRight: {
    width: 10,
    flexDirection: 'row-reverse',
  },
  backButton: {
    paddingHorizontal: 6,
    flex: 1,
    justifyContent: 'center',
  },
})
