import React from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { _get } from '../../config/connected'
import i18n from 'i18n-js'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import PropTypes from 'prop-types'

const ShopDescription = ({ shop, handleScrollToStories }) => {

  return (
    <>
      <Text
        numberOfLines={4}
        style={[styles.padding, styles.description]}
      >
        {_get(
          shop,
          'producerDetails.description.value',
          i18n.t('shop.tabs.shop.noBio'),
        )}
      </Text>
      <TouchableOpacity onPress={handleScrollToStories}>
        <Text
          style={[
            styles.shopTabViewMore,
            { marginBottom: 20 },
          ]}
        >
          {i18n.t('common.viewMore')}
        </Text>
      </TouchableOpacity>
    </>
  )
}

ShopDescription.propTypes = {
  shop: PropTypes.any.isRequired,
  handleScrollToStories: PropTypes.func.isRequired,
}

const styles = stylus({
  shopTabViewMore: {
    color: colors.primary,
    fontSize: 15,
    textDecorationLine: 'underline',
    marginLeft: 16,
    alignSelf: 'center',
    // margin: 20,
  },
  description: {
    fontSize: 16,
    paddingTop: 20,
    paddingHorizontal: 16,
    color: colors.text.main,
  },
  padding: {
    paddingHorizontal: 16,
  },
})

export default ShopDescription
