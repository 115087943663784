/* eslint-disable indent */
import React, { Component, useState, useEffect } from 'react'
import { Text, View } from 'react-native'
import sharedStyles, { stylus } from '../../../config/styles'
import Checkbox from '../../../components/simple/Checkbox'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import colors from '../../../config/colors'
import AddressView from '../../../components/Address/AddressView'
import AddressForm from '../../../components/Address/AddressForm'
import _ from 'lodash'

const EditCancelToggle = ({ value = false, onChange, style }) => {
  const edit = <Text onPress={() => {
    onChange(false)
  }} style={[styles.edit, style]}>
    ({i18n.t('order.checkout.editAddress')})
  </Text>

  const cancel = <Text onPress={() => {
    onChange(true)
  }} style={[styles.edit, style]}>
    ({i18n.t('common.cancel')})
  </Text>


  return value === true ? edit : cancel
}

const BillingAddress = (props) => {
  let {
    address,
    onSubmit,
    onResetBillingAddress,
    editToggle,
    view,
  } = props

  const [state, setState] = useState({ checkbox: true })

  useEffect(() => {
    _.invoke(this.props, 'onValidate', state.checkbox)
  }, [state.checkbox])

  return <View>
      <View style={styles.headerContainer}>
        <Text style={[sharedStyles.h5, styles.title]}>
          {i18n.t('order.checkout.billingAddressTitle')}
        </Text>
        {!state.checkbox &&
        <EditCancelToggle
          style={{ marginLeft: 5 }}
          onChange={(val) => {
            if (view === false && state.checkbox === false && _.isEmpty(address)) {
              setState({ checkbox: true })
            } else {
              editToggle(val)
            }
          }}
          value={view}
        />
        }
      </View>


      <Checkbox
        checked={state.checkbox}
        label={i18n.t('order.checkout.useShippingAddress')}
        onPress={() => {
          if (state.checkbox === false) {
            onResetBillingAddress()
            editToggle(false)
            setState({ checkbox: true })
          } else {
            setState({ checkbox: !state.checkbox })
          }
        }}
      />

    {!state.checkbox && view !== true && (
        <AddressForm
          address={address}
          onSubmit={onSubmit}
        />
    )}

    {!state.checkbox && view === true && (
        <AddressView
          address={address}
          style={styles.shippingAddressContainer}
          titleStyle={sharedStyles.h6}
          hideTitle={true}
        />
      )}

    </View>

}


BillingAddress.defaultProps = { view: false }

BillingAddress.propTypes = {
  address: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  validateForm: PropTypes.func,
  resetForm: PropTypes.func,
  setFieldValue: PropTypes.func,
  onChange: PropTypes.func,
  editToggle: PropTypes.func,
  forceValidate: PropTypes.bool,
  view: PropTypes.bool,
  isValid: PropTypes.bool,
  submitCount: PropTypes.number,
  onResetBillingAddress: PropTypes.func,
  onSubmit: PropTypes.func,
  onValidate: PropTypes.func,
}

export default BillingAddress

const styles = stylus({

  input: {
    width: '100%',
    // marginVertical: 10,
  },

  headerContainer: {
    marginVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },

  title: {
    fontWeight: 'bold',
    color: colors.text.main,
  },

  shippingAddressContainer: {
    marginTop: 15,
  },

  button: {
    backgroundColor: colors.primary,
    marginBottom: 5,
    marginTop: 20,
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  countryContainer: {
    alignSelf: 'stretch',
    marginBottom: 10
  },
  pickerLabel: {
    marginTop: 20,
    color: colors.input.base,
    fontSize: 12,
    web: {
      marginBottom: 6,
    },
  },
  picker: {
    web: {
      width: '100%',
      color: colors.input.text,
    },
  },
})

