import _ from 'lodash'
import produce from 'immer'
import { isVideo } from '../config/helpers'

const debug = false
const initialState = {}

export default function uploads(state, action) {
  if (!state) {
    return initialState
  }
  const { type, file, progress, id, partNumber, uploadParts, uploadId, partSize, eTag, numberOfErrors } = action
  const existing = state[id]
  const date = new Date()
  switch (type) {
    case 'Upload/STARTED':
      if (existing) {
        debug && console.log('<<<uploads - STARTED', id, 'at', date)
        return {
          ...state,
          [id]: {
            ...state[id],
            state: 'uploading',
          },
        }
      }
      return state
    case 'Upload/ATTACH_MULTIPART_METADATA':
      return produce(state, draft => {
        const exists = draft[id]
        if (exists) {
          debug && console.log('<<<uploads - ATTACH_MULTIPART_METADATA', id, 'at', date)
          exists.uploadId = uploadId
          exists.partSize = partSize
          exists.uploadParts = uploadParts
        }
      })
    case 'Upload/COMPLETE':
      if (existing) {
        debug && console.log('<<<uploads - COMPLETE', id, 'at', date)
        return produce(state, draft => {
          draft[id].state = 'complete'
        })
      }
      return state
    case 'Upload/RETRY':
      return produce(state, draft => {
        const exists = draft[id]
        if (exists) {
          debug && console.log('<<<uploads - RETRY', id, 'at', date)
          exists.state = 'retry'
          exists.numberOfErrors = numberOfErrors
        }
      })
    case 'Upload/CANCEL':
      return produce(state, draft => {
        const exists = draft[id]
        if (exists) {
          debug && console.log('<<<uploads - CANCEL', id, 'at', date)
          exists.state = 'cancelled'
        }
      })
    case 'Upload/PENDING_AVAILABILITY':
      return produce(state, draft => {
        const existing = draft[id]
        if (existing) {
          debug && console.log('<<<uploads - PENDING_AVAILABILITY', id, 'at', date)
          existing.state = 'pendingAvailability'
        }
      })
    case "Upload/PART_COMPLETE":
      return produce(state, draft => {
        const exists = draft[id]
        if (exists && exists.uploadParts) {
          debug && console.log('<<<uploads - PART_COMPLETE', id, partNumber, 'at', date)
          const part = exists.uploadParts.find(uploadPart => uploadPart.partNumber === partNumber)
          if (part) {
            debug && console.log('<<<uploads - PART_COMPLETE - found part', id, partNumber, 'at', date)
            Object.assign(part.signedUpload, {
              state: 'complete',
              progress: 100,
            })
            part.eTag = eTag
            if (exists.uploadParts.every(uploadPart => uploadPart.signedUpload.state === 'complete', 'at', date)) {
              debug && console.log('<<<uploads - PART_COMPLETE - all parts complete', id, partNumber)
              exists.state = 'uploadPartsComplete'
            }
          }
        } else {
          debug && console.log('<<< uploads - PART_COMPLETE - No upload parts for ', id)
        }
      })
    case "Upload/PART_RETRY":
      return produce(state, draft => {
        const exists = draft[id]
        if (exists) {
          const part = exists.uploadParts.find(uploadPart => uploadPart.partNumber === partNumber)
          if (part) {
            debug && console.log('<<<uploads - RETRY PART - found', id, 'at', date)
            part.signedUpload.state = 'retry'
            if (exists.state !== 'retry') {
              exists.state = 'retry'
            }
          }
        }
      })       
    case 'Upload/PROGRESS':
      if (existing) {
        return {
          ...state,
          [id]: {
            ...existing,
            progress,
          },
        }
      }
      return state
    case 'Upload/PART_PROGRESS':
      if (existing) {
        return produce(state, draft => {
          const part = draft[id].uploadParts.find(part => part.partNumber === partNumber)
          if (part) {
            part.signedUpload.progress = progress
          }
        })
      }
      return state
    case 'Upload/ADD':
      debug && console.log('<<<uploads - ADD', file.id, 'at', date)
      const isVideoUpload = isVideo(file.id)
      const uploadType = isVideoUpload ? 'multipart' : 'single'
      return produce(state, draft => {
        draft[file.id] = {
          uploadType,
          file: file.file,
          uri: file.uri,
          id: file.id,
          mime: file.mime,
          progress: 0,
          state: 'pending',
          createdAt: new Date(),
        }
      })
    case 'Upload/COMPLETE_ALL':
      return {
        ..._.mapKeys(state, (value, key) => {
          value.progress = 100
          value.state = 'complete'
          return key
        })
      }
    case 'Upload/REMOVE':
      return produce(state, draft => {
        delete draft[file.id]
      })
    case 'Upload/RESET':
      return initialState
    default:
      return state
  }
}
