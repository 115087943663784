import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import PropTypes from 'prop-types'
import PostUploading from './PostUploading'
import ErrorMessage from '../simple/ErrorMessage'
import Message from '../Conversation/Message'
import { connect } from '../../config/connected'
import { stylus } from '../../config/styles'
import { getFeatureFlag } from '../../config/helpers'
import _ from 'lodash'
import i18n from 'i18n-js'

const restoreFailedPublish = getFeatureFlag('restoreFailedPublish')
const mapStateToProps = ({ publishContent }) => ({ pending: publishContent.pending })

@connect(mapStateToProps)
class PendingContent extends React.Component {

  render() {
    const { pending = [], types = [], filter } = this.props
    let filteredContent = pending
    if (types.length) {
      filteredContent = pending.filter(content => types.includes(content.contentType))
    }
    return (
      <View>
        { filteredContent.filter(filter).map(content => this.renderContent(content)) }
      </View>
    )
  }

  renderContent = (content) => {
    let name, text
    const errorMessage = i18n.t('publishContent.alert.pressToEdit') + `(${content.error})`
    const showError = content.error && restoreFailedPublish
    switch(content.contentType) {
      case 'post':
      case 'recipe':
        name = _.get(content, 'files[0].id')  || _.get(content, 'payload.uploads[0].name')
        text = i18n.t('common.posting') + ': ' + (_.get(content, 'payload.title') || _.get(content, 'payload.story.value'))
        break
      case 'message':
        name =  _.get(content, 'imageFile.id') || _.get(content, 'user.profileImage')
        text = _.get(content, 'text') || i18n.t('publishContent.alert.message')
        return <Message message={{
          user: content.user,
          text: content.text,
          upload: content.imageFile,
          createdAt: content.createdAt,
        }} pending={true} />
      case 'product':
        name =  _.get(content, 'payload.uploads[0].name')
        text =  i18n.t('common.product') + ': ' + _.get(content, 'payload.name')
        break
      case 'user':
      case 'producer':
        if (showError ) {
          return <ErrorMessage style={styles.error} labelStyle={styles.errorLabel} message={errorMessage}/>
        } else {
          return null
        }
    }
    if (showError) {
      text = errorMessage
    }
    return (
      <TouchableOpacity onPress={() => this.goToContent(content)} >
        <PostUploading key={name} uploadName={name} uploadText={text} />
      </TouchableOpacity>
    )
  }

  goToContent = (content) => {
    if (!content.error || !restoreFailedPublish) {
      return
    }
    switch(content.contentType) {
      case 'post':
        NavigationActions.navigate({ routeName: 'PostCreate', params: { content } })
        break
      case 'recipe':
        NavigationActions.navigate({ routeName: 'PostCreate', params: { isRecipe: true, content } })
        break
      case 'message':
        NavigationActions.navigate({ 
          routeName: 'Conversation',
          params: {
            id: content.conversationMembership.id,
            users: _.map(content.conversationMembership.conversation.memberships, 'user'),
          }
        })
        break
      case 'user':
        NavigationActions.navigate({ routeName: 'Profile', params: { content }})
        break
      case 'product':
        NavigationActions.navigate({ routeName: content.payload.id ? 'EditProduct' : 'CreateProduct', params: { content } })
        break
      case 'producer':
        NavigationActions.navigate({ routeName: 'EditShop', params: { content }})
        break
    }
  }
}

PendingContent.defaultProps = {
  filter: (content) => content
}

PendingContent.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string),
  filter: PropTypes.func,
  pending: PropTypes.array
}

const styles = stylus({
  errorLabel: {
    color: 'white'
  },
  error: {
    backgroundColor: '#ff2c55'
  }
})

export default PendingContent
