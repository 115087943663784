import Icons from '@expo/vector-icons/MaterialIcons'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Text, TextInput, TouchableOpacity, View } from 'react-native'
import stringScore from 'string-score'
import KeyboardAvoidingView from '../../components/simple/KeyboardAvoidingView'
import branch from '../../config/branch'
import colors from '../../config/colors'
import { _get } from '../../config/connected'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'
import LayoutAnimation from '../LayoutAnimation/'
import NamedImage from '../simple/NamedImage'

/**
 * @param tags Array of Objects in shape {value: String, label: String }
 *
 * @param chosen Array of Objects in shape {value: String, label: String }
 *
 * @param onTagsChange Callback fired every time tags change from a component action
 *
 * @param onTagPress Callback fired with the index of the tag pressed
 */

class TagsInput extends Component {
  static propTypes = {
    label: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        image: PropTypes.shape({
          name: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
          mime: PropTypes.oneOf(['image/jpg', 'image/jpeg', 'image/png']),
        }),
      }).isRequired,
    ),
    max: PropTypes.number,
  }

  state = {
    // chosen: [],
    searchQuery: '',
    markedForDeletion: false,
  }

  render() {
    const { error } = this.props
    return (
      <KeyboardAvoidingView
        style={styles.container}
        keyboardVerticalOffset={branch({ iphone: 120, android: 81, other: 0 })}
      >
        {!!error && <Text style={{ color: 'red' }}>{error}</Text>}
        {this.filter()}
        {this.results()}
      </KeyboardAvoidingView>
    )
  }

  handleOnTagPress = (index) => {
    const { chosen, onTagPress, onTagsChange } = this.props
    let newChosen = chosen

    onTagPress && onTagPress(index)
    // newChosen.splice(index, 1)
    // onTagsChange && onTagsChange(newChosen)
  }

  chosen() {
    const { chosen } = this.props
    const { markedForDeletion } = this.state

    if (chosen.length === 0) {
      return null
    }

    return chosen.map((item, index) => {
      const selected = markedForDeletion && index === chosen.length - 1
      return (
        <TouchableOpacity
          style={[styles.chosenLabel, selected && styles.chosenLabelSelected]}
          key={index}
          onPress={() => {
            this.handleOnTagPress(index)
          }}
        >
          <Icons
            name='close'
            style={styles.chosenLabelIcon}
            color={selected ? colors.text.invert : colors.text.main}
            size={14}
          />
          <Text
            style={[
              styles.chosenLabelName,
              selected && styles.chosenLabelNameSelected,
            ]}
          >
            {_get(item, 'label', '')}
          </Text>
        </TouchableOpacity>
      )
    })
  }

  filter() {
    const { label, placeholder } = this.props
    return (
      <View style={styles.inputContainer}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={styles.to}>{label || ''}</Text>

          <TextInput
            placeholder={placeholder || ''}
            style={styles.input}
            maxLength={50}
            value={this.state.searchQuery}
            onChangeText={this.onChangeSearch.bind(this)}
            ref={(ref) => (this.input = ref)}
            underlineColorAndroid='transparent'
            // autoFocus={true}
            onKeyPress={this.onKeyPress}
            autoCorrect={false}
            returnKeyType='done'
            selectTextOnFocus={true}
            // clearButtonMode='always'
          />
        </View>
        {this.chosen()}
      </View>
    )
  }

  onKeyPress = (event) => {
    const {
      nativeEvent: { key },
    } = event

    const { chosen, onChangeSearch, onTagPress } = this.props
    const { markedForDeletion, searchQuery } = this.state

    if (key === 'Backspace' && searchQuery === '' && chosen.length > 0) {
      if (markedForDeletion) {
        this.setState(
          {
            markedForDeletion: false,
          },
          () => {
            this.props.onTagsChange(chosen)
            onTagPress(chosen.length - 1)
          },
        )
        // onChangeSearch && onChangeSearch(chosen.slice(0, -1))
      } else {
        this.setState(
          {
            markedForDeletion: true,
          },
          () => this.props.onTagsChange(chosen),
        )
      }
    }
  }

  onChangeSearch = (searchQuery) => {
    const { onChangeSearch } = this.props

    if (searchQuery !== '') {
      this.setState({ markedForDeletion: false, searchQuery })
    } else {
      this.setState({ searchQuery })
    }
    onChangeSearch && onChangeSearch(searchQuery)
  }

  results() {
    const { tags, chosen } = this.props
    if (chosen.length === tags.length) {
      return null
    }
    let results = this.resultsContent()

    if (results.length) {
      return (
        <View
          style={styles.resultsContainer}
          // keyboardShouldPersistTaps='handled'
          // contentContainerStyle={styles.resultsContainer}
        >
          {this.resultsContent()}
        </View>
      )
    } else {
      return null
    }
  }

  resultsContent() {
    const { tags, chosen, max } = this.props
    const { searchQuery } = this.state
    const chosenTags = _.map(chosen, 'label')
    const disabled = !!max && chosen?.length >= max
    let results = tags

    if (searchQuery.length) {
      const sorted = _.map(results, (item) => {
        const score = 1 - stringScore(item.label, searchQuery)
        return {
          ...item,
          score,
        }
      })

      const filtered = _.filter(sorted, (item) => {
        return item.score < 0.8
      })
      results = filtered
    }

    results = _.filter(results, (item) => {
      return [...chosenTags].indexOf(item.label) < 0
    })

    return results.map((item) => {
      return this.item(item, disabled)
    })
  }

  item(item, disabled) {
    return (
      <TouchableOpacity
        activeOpacity={0.8}
        style={styles.tag}
        key={item.label}
        onPress={() => this.selectItem(item)}
        disabled={disabled}
      >
        {item.image && (
          <NamedImage size={42} name={_get(item, 'image.name', '')} />
        )}
        <Icons
          name={disabled ? 'block' : 'add'}
          style={{ marginRight: 5, top: 1 }}
          size={14}
          color={disabled ? '#F59359' : '#4e4e4e'}
        />
        <Text style={styles.tagText}>{item.label}</Text>
        {/* <Text style={styles.username}>{item.username}</Text> */}
      </TouchableOpacity>
    )
  }

  selectItem(item) {
    const { chosen, max } = this.props

    if (max && chosen?.length >= max) {
      return
    }

    const newChosen = [...chosen, item]
    this.setState(
      {
        // searchQuery: '',
        markedForDeletion: false,
      },
      () => this.props.onTagsChange(newChosen),
    )
  }
  animate() {
    LayoutAnimation &&
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }
}

export default TagsInput

const styles = stylus({
  container: {
    // alignItems: 'stretch',
    // flex: 1,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
    flexWrap: 'wrap',
    // paddingVertical: 5,
    paddingHorizontal: 5,
  },
  to: {
    fontSize: 15,
    color: colors.text.soft,
    paddingLeft: 6,
    marginRight: 3,
    // backgroundColor: 'orange',
  },
  input: {
    fontSize: 16,
    height: 40,
    paddingHorizontal: 8,
    color: colors.text.main,
    flex: 1,
    minWidth: 100,
    // backgroundColor: 'orange',
  },
  resultsContainer: {
    marginVertical: 5,
    flexDirection: 'row',
    backgroundColor: colors.emptyArea,
    flexWrap: 'wrap',
    borderRadius: 5,
    padding: 2.5,
    // alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tag: {
    marginHorizontal: 2,
    flexDirection: 'row',
    marginVertical: 2.5,
    paddingVertical: 3,
    paddingHorizontal: 8,
    backgroundColor: colors.tagBackground,
    borderRadius: 5,
    alignItems: 'center',
    // jusifyContent: 'center',
  },
  tagText: {
    // backgroundColor: 'red',
    fontWeight: '500',
    color: colors.text.main,
    fontSize: 15,
  },
  username: {
    fontSize: 14,
    color: colors.text.secondary,
  },
  chosen: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingTop: 10,
    flexWrap: 'wrap',
  },
  chosenLabel: {
    borderRadius: 4,
    backgroundColor: colors.tagLabel,
    paddingHorizontal: 8,
    paddingVertical: 5,
    marginHorizontal: 3,
    marginVertical: 3,
    flexDirection: 'row',
    alignItems: 'center',
  },
  chosenLabelIcon: { marginRight: 5, top: 1 },
  chosenLabelSelected: {
    backgroundColor: colors.primary,
  },
  chosenLabelName: {
    color: colors.text.mainLighter,
    fontSize: 14,
  },
  chosenLabelNameSelected: {
    color: colors.text.invert,
  },
})
