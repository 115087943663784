import admin from './it-IT/admin'
import categories from './it-IT/categories'
import comment from './it-IT/comment'
import common from './it-IT/common'
import coupon from './it-IT/coupon'
import deliveryLocations from './it-IT/deliveryLocations'
import errors from './it-IT/errors'
import feed from './it-IT/feed'
import followers from './it-IT/followers'
import legal from './it-IT/legal'
import nav from './it-IT/nav'
import notifications from './it-IT/notifications'
import onboardingShop from './it-IT/onboardingShop'
import onboardingUser from './it-IT/onboardingUser'
import order from './it-IT/order'
import payment from './it-IT/payment'
import post from './it-IT/post'
import product from './it-IT/product'
import profile from './it-IT/profile'
import publishContent from './it-IT/publishContent'
import recipe from './it-IT/recipe'
import shop from './it-IT/shop'
import address from './it-IT/address'
import search from './it-IT/search'
import services from './it-IT/services'
import settings from './it-IT/settings'
import sharing from './it-IT/sharing'
import tagging from './it-IT/tagging'
import upload from './it-IT/upload'
import notice from './it-IT/notice'

const messages = {
  ...admin,
  ...categories,
  ...comment,
  ...common,
  ...coupon,
  ...deliveryLocations,
  ...errors,
  ...feed,
  ...followers,
  ...legal,
  ...nav,
  ...notifications,
  ...onboardingShop,
  ...onboardingUser,
  ...order,
  ...address,
  ...payment,
  ...post,
  ...product,
  ...profile,
  ...publishContent,
  ...recipe,
  ...shop,
  ...search,
  ...services,
  ...settings,
  ...tagging,
  ...upload,
  ...sharing,
  ...notice,
}

export default messages
