import React from 'react'
import { View } from 'react-native'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'
import PropTypes from 'prop-types'

const Divider = ({ hidden, width, style, small, right, margin }) => {
  if (right) {
    return <View style={styles.right} />
  }
  return (
    <View
      style={[
        styles.large,
        width && width > 0 ? { width } : null,
        small && styles.small,
        margin === 'none' ? { marginVertical: 0 } : null,
        hidden ? styles.hidden : null,
        style,
      ]}
    />
  )
}

Divider.propTypes = {
  right: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  small: PropTypes.bool,
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hidden: PropTypes.bool,
  style: PropTypes.object,
}

const styles = stylus({
  large: {
    alignSelf: 'stretch',
    height: 1,
    borderBottomColor: colors.thinLine,
    borderBottomWidth: sizes.px,
    marginVertical: 20,
  },
  small: {
    height: 1,
    borderBottomColor: colors.thinLine,
    borderBottomWidth: sizes.px,
    width: 125,
  },
  right: {
    marginLeft: 16,
    height: 1,
    borderBottomColor: colors.thinLine,
    borderBottomWidth: sizes.px,
    marginVertical: 20,
  },
  hidden: {
    opacity: 0,
  },
})

export default Divider
