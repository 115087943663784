import React from 'react'
import { KeyboardAvoidingView, Platform, Text, View } from 'react-native'
import NavigationActions from '../../../utility/navigationActions'
import Button from '../../../components/simple/Button'
import MobileBackButton from '../../../components/simple/MobileBackButton'
import Scroller from '../../../components/Scroller/Scroller'
import { Status } from '../../../components/simple/Status'
import ShopHeader from '../../../components/Cart/ShopHeader'
import CartProduct from './CartProduct'
import withKeyboardInfo from '../../../containers/withKeyboardInfo'
import KeyboardShift from '../../../containers/KeyboardShift'
import { connect, graphql } from '../../../config/connected'
import sharedStyles, { stylus } from '../../../config/styles'
import colors from '../../../config/colors'
import sizes from '../../../config/sizes'
import branch from '../../../config/branch'
import { getPrice, getTotalPrices, mergeNavOptionsForFeedLogo, getKeyboardPaddingStyle } from '../../../config/helpers'
import schema from '../../../schema/cart'
import checkoutSessionsSchema from '../../../schema/checkoutSession'

import i18n from 'i18n-js'
import _ from 'lodash'
import alert from '../../../utility/alert'
import confirm from '../../../utility/confirm'
import PropTypes from 'prop-types'
import MinimumOrderAmountSign from './MinimumOrderAmountSign'
import { ANALYTICS_PROCEED_TO_CHECKOUT } from '../../../reducers/analytics'

const mapStateToProps = (state, ownProps) => {
  const userId = state.currentUser.id
  return {
    userId,
    screenInfo: state.screenInfo,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  analyticsProceedToCheckout: () => {
    dispatch({
      type: ANALYTICS_PROCEED_TO_CHECKOUT,
      data: {},
    })
  },
})

@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@graphql(schema.queries.cartItemsByUserId, {
  name: 'cartItemsByUserIdHandler',
  options({ userId }) {
    return { variables: { userId }, fetchPolicy: 'cache-and-network' }
  },
})
@graphql(schema.queries.userById, {
  name: 'userByIdHandler',
  options({ userId }) {
    return { variables: { id: userId } }
  },
})
@graphql(checkoutSessionsSchema.queries.myCheckoutSession, {
  name: 'myCheckoutSessionHandler',
})
@withKeyboardInfo
class Cart extends React.Component {
  state = {
    shouldShift: true,
    isOrderAmountNotReached: undefined,
  }

  componentDidMount = () => {
    this.didFocusSubscription = this.props.navigation.addListener('didFocus', this.onScreenFocus)
  }

  componentWillUnmount = () => {
    this.didFocusSubscription && this.didFocusSubscription.remove()
  }

  onScreenFocus = () => {
    this.props.cartItemsByUserIdHandler.refetch()
  }

  _minimumAmountNotMetHandler = ({ minimumOrderAmount, remain }) => {
    alert({
      title: i18n.t('order.cart.minimumOrderNotMet.alertTitle'),
      message: i18n.t('order.cart.minimumOrderNotMet.body', { amount: minimumOrderAmount, remain }),
    })
  }

  cartProductElements = (cartItems) => {
    const derivedCartElements = []
    // const { navigation } = this.props
    let previousShop = null
    cartItems.map((cartItem, index) => {
      const clonedCartItem = { ...cartItem }
      const { product } = clonedCartItem
      const { profile: shop } = product
      if (shop.id !== _.get(previousShop, 'id', -1)) {
        // const shopOwner = _.get(shop, 'producerDetails.ownership.owner')
        const itemsPerShop = cartItems.filter(item => _.get(item, 'product.profile.id') === shop.id)
        const { subtotal } = getTotalPrices(itemsPerShop, (cartItem) => cartItem.product, false)
        const minimumOrderAmount = _.get(shop, 'producerDetails.minimumOrderAmount', 0)
        const isOrderAmountNotReached = subtotal < minimumOrderAmount

        derivedCartElements.push(
          <View key={`header-for-shop-id-${shop.id}`}>
            <View
              style={styles.sectionHeader}
            >
              <View style={styles.sectionHeaderLeft}>
                <ShopHeader
                  style={styles.shop}
                  imageWrapperStyle={styles.shopImageWrapper}
                  detailsStyle={{ justifyContent: 'center' }}
                  nameStyle={styles.shopName}
                  user={shop}>
                  {isOrderAmountNotReached ? <MinimumOrderAmountSign amount={minimumOrderAmount} onPress={() => {
                    const remain = minimumOrderAmount - subtotal
                    this._minimumAmountNotMetHandler({ minimumOrderAmount, remain })
                  }}/> : null }
                </ShopHeader>

              </View>
              {/* <View style={styles.sectionHeaderRight}>
              <ChatWithUser
                navigation={navigation}
                label={i18n.t('order.order.contactShop')}
                labelStyle={{ color: colors.text.soft }}
                user={shopOwner}
              />
            </View> */}
            </View>
            {/*{isOrderAmountNotReached && <>*/}
            {/*  <View style={{ height: 1, marginVertical: 10, backgroundColor: colors.lightGray, flex: 1 }}/>*/}

            {/*  <Text>{i18n.t('order.cart.minimumOrderNotMet.body', { amount: minimumOrderAmount })}</Text>*/}
            {/*</>}*/}

          </View>,
        )
        previousShop = shop
      }
      const cartProductStyle = { ...styles.cartProduct }
      if (index === cartItems.length - 1) {
        cartProductStyle.marginBottom = 20
      }
      derivedCartElements.push(
        <CartProduct
          key={`widget-for-cart-item-id-${clonedCartItem.id}`}
          productKey='product'
          cartItem={clonedCartItem}
          style={cartProductStyle}
          showRatings={true}
          onAction={this.onAction}
          actionsEnabled
        />,
      )
      // derivedCartElements.push(<CartItem key={`widget-for-cart-item-id-${clonedCartItem.id}`} cartItem={clonedCartItem} style={styles.cartProduct} />)
    })

    return derivedCartElements
  }

  onAction = () => {
    this.forceUpdate()
  }

  getSortedCartItems = () => {
    const { cartItemsByUserIdHandler } = this.props
    if (cartItemsByUserIdHandler.loading) {
      return null
    }
    // return cartItems
    const { cartItemsByUserId: cartItems } = cartItemsByUserIdHandler
    return _.orderBy(
      cartItems,
      [(e) => e.product.profile.id, 'createdAt'],
      ['asc', 'desc'],
    )
  }


  render() {
    const sortedCartItems = this.getSortedCartItems()
    if (!sortedCartItems) {
      return null
    }
    const totals = getTotalPrices(
      sortedCartItems,
      (cartItem) => cartItem.product,
      false,
    )

    let minimumOrderAmount = 0
    let remain = 0
    const gropedByStore = _.groupBy(sortedCartItems, 'product.profile.id')
    const producers = _.chain(sortedCartItems)
      .map('product.profile.producerDetails')
      .mapKeys('id')
      .value()

    for (const key of Object.keys(gropedByStore)) {
      const { subtotal } = getTotalPrices(
        gropedByStore[key],
        (item) => item.product,
        true,
      )
      minimumOrderAmount =
        _.get(producers, `${key}.minimumOrderAmount`) || 0
      remain = minimumOrderAmount - subtotal
      if (subtotal >= minimumOrderAmount) {
        minimumOrderAmount = 0
      } else break
    }

    const numberOfItems = sortedCartItems.reduce(
      (sum, cartItem) => sum + cartItem.quantity,
      0,
    )

    const proceedAction = minimumOrderAmount === 0 ? this.goToCheckout : () => {
      this._minimumAmountNotMetHandler({ minimumOrderAmount, remain })
    }

    const keyboardPaddingStyle = getKeyboardPaddingStyle(this.props.keyboardInfo)

    return !sortedCartItems.length ? (
      <View style={[styles.container, styles.statusContainer]}>
        <Status message={i18n.t('order.cart.status.noItems')} />
      </View>
    ) : (
      <React.Fragment>
        <View style={[styles.container]}>
          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS === 'android' ? undefined : 'padding'}
            contentContainerStyle={{ flex: 1 }}
          >
            <Scroller
              contentContainerStyle={styles.contentContainer}
              debounceTime={50}
              onEndReached={this.freezeContentShift}
              onEndLeft={this.unfreezeContentShift}
              fromEndOfViewPercentage={50}
            >
              <KeyboardShift fluid extraShiftValue={branch({ apk: sizes.tabBarHeight, other: 0})}>
                <Text style={[styles.title, sharedStyles.h4]}>
                  {i18n.t('order.cart.title')}
                </Text>
                {this.cartProductElements(sortedCartItems)}
                <View style={keyboardPaddingStyle} />
              </KeyboardShift>
            </Scroller>
          </KeyboardAvoidingView>
        </View>
        <View style={[styles.footer, styles.callToActionContainer]}>
          <View style={styles.callToActionTextContainer}>
            <Text style={styles.totalText}>
              {i18n.t('order.order.totalBeforeShipping', {
                number: numberOfItems,
              })}
              :{' '}
            </Text>
            <Text style={styles.totalValueText}>
              {getPrice(totals.subTotalAfterDiscounts)}
            </Text>
          </View>
          <View style={styles.buttonContainer}>
            <Button
              onPress={proceedAction}
              label={i18n.t('order.cart.proceedToCheckout')}
              labelStyle={styles.buttonLabel}
              style={styles.button}
            />
          </View>
        </View>
      </React.Fragment>
    )
  }

  freezeContentShift = () => {
    if (Platform.OS === 'android' && this.state.shouldShift === true) {
      this.setState({ shouldShift: false })
    }
  }

  unfreezeContentShift = () => {
    if (Platform.OS === 'android' && this.state.shouldShift === false) {
      this.setState({ shouldShift: true })
    }
  }

  goToCheckout = async() => {
    const sortedCartItems = this.getSortedCartItems()
    const hasItemsWithoutQuantity = sortedCartItems.some(
      (item) => item.quantity === 0,
    )
    if (hasItemsWithoutQuantity) {
      alert({
        title: i18n.t('order.cart.noQuantityAlertTitle'),
        message: i18n.t('order.cart.noQuantityAlertMessage'),
      })
      return
    }

    for (const cartItem of sortedCartItems) {
      const { product } = cartItem
      const deliveryLocationsOnly = _.get(product, 'deliveryLocationsOnly')
      const deliveryLocations = _.get(product, 'profile.producerDetails.deliveryLocations')
      if (deliveryLocations && deliveryLocationsOnly) {
        const yes = await confirm({
          title: i18n.t('deliveryLocations.alerts.warningTitle'),
          message: i18n.t('deliveryLocations.alerts.locationRestriction', {
            name: product.name,
            locations: deliveryLocations
          }),
          options: { confirmText: i18n.t('common.continue')}
        })
        if (!yes) {
          return
        }
      }
    }

    const { dispatch } = this.props
    this.props.myCheckoutSessionHandler.refetch()
    NavigationActions.navigate({ routeName: 'Checkout' })
    this.props.analyticsProceedToCheckout()
  }
}
Cart.navigationOptions = (props) => {
  return mergeNavOptionsForFeedLogo({
    headerLeft: () => (
      <MobileBackButton
        label={i18n.t('common.back')}
        onPress={() => NavigationActions.back()}
      />
    ),
  })
}

Cart.propTypes = {
  dispatch: PropTypes.func,
  analyticsProceedToCheckout: PropTypes.func,
  cartItemsByUserIdHandler: PropTypes.func,
  navigation: PropTypes.object,
}

export default Cart

const styles = stylus({
  container: {
    paddingHorizontal: 10,
    flex: 1,
  },
  footer: {
    paddingBottom: 0,
    android: {
      paddingBottom: 0,
    },
    iphonex: {
      paddingBottom: 35,
    },
  },
  title: {
    color: colors.text.main,
    textAlign: 'center',
    marginTop: 5,
    fontWeight: '600',
  },
  statusContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentContainer: {
    paddingBottom: 0,
    android: {
      paddingBottom: 0,
    },
    iphonex: {
      paddingBottom: 0,
    },
  },
  // Section Header
  sectionHeader: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  sectionHeaderLeft: {
    flex: 7,
    flexDirection: 'column',
  },
  sectionHeaderRight: {
    flex: 3,
    justifyContent: 'center',
  },
  shop: {
    paddingHorizontal: 0,
  },
  shopImageWrapper: {
    paddingHorizontal: 0,
    paddingRight: 8,
  },
  shopName: {
    fontSize: 15,
  },
  // Product Styling
  cartProduct: {
    marginTop: 20,
  },
  // Call To Section Styling
  callToActionContainer: {
    paddingTop: 10,
    backgroundColor: '#F7F7F7',
    alignItems: 'center',
  },
  callToActionTextContainer: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  totalText: {
    color: colors.text.main,
    fontSize: 16,
  },
  totalValueText: {
    color: 'red',
    fontWeight: '500',
    fontSize: 16,
  },
  buttonContainer: {
    width: '100%',
  },
  button: {
    backgroundColor: colors.primary,
    width: '90%',
    marginBottom: 5,
    alignSelf: 'center'
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
})
