import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import PropTypes from 'prop-types'
import ViewMoreText from 'react-native-view-more-text'
import Ratings from '../../components/simple/Ratings'
import Divider from '../../components/simple/Divider'
import moment from 'moment'
import NamedImage from '../../components/simple/NamedImage'
import CarouselItem from '../ProductCarousel/CarouselItem'
import { _get, connect } from '../../config/connected'

import _ from 'lodash'
import i18n from 'i18n-js'
import MoreOptions from '../simple/MoreOptions'
import { ROUTE_REPORT_CONTENT_REVIEW } from '../../config/constants'
import UserTitle from '../UserTitle/UserTitle'

class Review extends React.Component {
  static propTypes = {
    review: PropTypes.object,
    showImage: PropTypes.bool,
  }

  state = {
    isOpen: false,
    afterLayout: false
  }

  toggleOpen = () => {
    this.setState((prevState) => {
      return { isOpen: !prevState.isOpen }
    })
  }

  onReviewLayout = (event) => {
    const {width} = event.nativeEvent.layout
    const {afterLayout} = this.state
    if (width > 0 && !afterLayout) this.setState({afterLayout: true})
  }

  renderViewMore(onPress) {
    return (
      <Text style={styles.expandButton} onPress={onPress}>{i18n.t('comment.viewMore')}</Text>
    )
  }

  renderViewLess(onPress) {
    return (
      <Text style={styles.expandButton} onPress={onPress}>{i18n.t('comment.viewLess')}</Text>
    )
  }

  render() {
    const { currentUserId, review, screenInfo, goToProduct, goToReportContent, showImage = false } = this.props
    const { user } = review
    const { title, profileImage, username, organization, organizationId } = user
    // const imageWidth = screenInfo.contentWidth * 0.3
    // const imageHeight = screenInfo.contentWidth * 0.2

    let comment = _.get(review, 'content.value', '')
    const moreOptions = []
    if (review.userId !== currentUserId) {
      moreOptions.push({ label: i18n.t('common.report'), action: goToReportContent })
    }
    return (
      <View style={styles.container} onLayout={this.onReviewLayout}>
        <TouchableOpacity onPress={this.goToProfile}>
          <NamedImage
            style={styles.profileImage}
            name={_get(user, 'profileImage', '')}
            width={44}
            height={44}
            fit='crop'
            crop='faces'
            placeholder='profile'
          />
        </TouchableOpacity>
        <View style={styles.content}>
          <TouchableOpacity style={styles.header} onPress={this.goToProfile}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text style={styles.nameContainer}>
                <Text style={styles.username}>{user.username} </Text>
                <Text style={styles.age}>{moment(review.createdAt).from()}</Text>
              </Text>
              <MoreOptions content={review} options={moreOptions} size={18}/>
            </View>
            <UserTitle title={title} organization={organization} organizationId={organizationId} size={12}/>
          </TouchableOpacity>
          {_.isNumber(review.rating) && review.rating > 0 &&
          <Ratings
            rating={review.rating}
            ratings={null}
            showRatings={false}
            containerStyle={styles.ratings}
          />
          }
          {!_.isEmpty(_.trim(comment)) && <ViewMoreText
            key={this.state.afterLayout}
            numberOfLines={3}
            renderViewMore={this.renderViewMore}
            renderViewLess={this.renderViewLess}
            textStyle={styles.reviewText}
          >
            <Text>{comment}</Text>
          </ViewMoreText>}
          {/* //TODO: Remember to add actual product linking here */}
          <View style={styles.productContainer}>
            {showImage ? (
              <CarouselItem
                item={_get(review, 'product', null)}
                goToProduct={goToProduct}
                image={_get(review, 'product.uploads[0].name', '')}
                {...review.product}
                // showRatings
                // showPrice
                showShop
                width={null}
              />
            ) : null}
          </View>
          <Divider margin='none' />
        </View>
      </View>
    )
  }

  goToProfile = () => {
    const user = this.props.review.user
    this.props.goToProfile({
      id: user.id,
      pageTitle: user.username,
    })
  }
}

const mapStateToProps = (state) => {
  return { screenInfo: state.screenInfo, currentUserId: state.currentUser.id }
}

const mapDispatchToProps = (dispatch) => ({
  goToProduct: (id) => NavigationActions.navigate({ routeName: 'Product', params: { id } }),
  goToProfile: ({ id, pageTitle }) => {
    NavigationActions.navigate({
      routeName: 'Profile',
      params: { id, pageTitle },
    })
  },
  goToReportContent: ({ id }) => {
    NavigationActions.navigate({ routeName: ROUTE_REPORT_CONTENT_REVIEW, params: { objectId: id } })
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Review)

const styles = stylus({
  container: {
    flexDirection: 'row',
    paddingTop: 16,
  },
  header: {
    flexDirection: 'column',
  },
  content: {
    flex: 1,
  },
  productContainer: {
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 20,
    alignItems: 'center',
  },
  profileImage: {
    marginHorizontal: 10,
    width: 44,
    height: 44,
    borderRadius: 22,
  },
  productImage: {
    marginRight: 16,
  },
  productTextContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  shopName: {
    fontSize: 13,
    fontWeight: '600',
    color: colors.text.main,
  },
  productTextTitle: {
    fontSize: 17,
    color: colors.text.main,
  },
  ratings: {
    paddingVertical: 5,
  },
  username: {
    fontSize: 15,
    fontWeight: '600',
    color: colors.text.main,
  },
  age: {
    color: colors.text.secondary,
    fontStyle: 'italic',
    fontSize: 12,
    fontWeight: '300',
  },
  reviewText: {
    paddingRight: 16,
    color: colors.text.content,
  },
  expandButton: {
    marginTop: 3,
    color: colors.taggingText
  }
})
