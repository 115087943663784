
import { Platform, Linking } from 'react-native'
import i18n from 'i18n-js'
import * as Permissions from 'expo-permissions'
import * as Location from 'expo-location'
import { PERMISSION_STATUS_DENIED, PERMISSION_STATUS_GRANTED } from '../config/constants'
import alert from './alert'

const debug = false
const alertDebug = false
const options = { cancelable: true }

async function getUserLocationAsync(returnImmediately = false) {
  try {
    // 1. Ask for device Location to be turned on
    debug && console.log('<<<SERVICES - getUserLocationAsync - making sure location services are enabled')
    alertDebug && await alert({ title: 'getUserLocationAsync', message: ' making sure location services are enabled' })
    let isLocationTurnedOn = await Location.hasServicesEnabledAsync()
    let { status: previousStatus } = await Location.getPermissionsAsync()
    if (returnImmediately && (!isLocationTurnedOn || previousStatus !== PERMISSION_STATUS_GRANTED)) {
      return
    }

    if (!isLocationTurnedOn) {
      if (Platform.OS === 'android') {
        await Location.enableNetworkProviderAsync()
      } else if (Platform.OS === 'ios') {
        const goToSettings = await alert({
          title: i18n.t('services.location.enable.description'),
          buttons: [
            { text: i18n.t('common.settings'), onPressValue: true },
            { text: i18n.t('common.cancel'), onPressValue: false, },
          ],
          options,
        })
        if (goToSettings) {
          Linking.openURL('App-Prefs:')
          return -1// do not attempt any further actions for obtaining location, will be done when user revists location action
        }
        return
      }
    }
    isLocationTurnedOn = await Location.hasServicesEnabledAsync()
    if (!isLocationTurnedOn) {
      debug && console.log('<<<SERVICES - getUserLocationAsync - location is not turned on')
      alertDebug && await alert({ title: 'getUserLocationAsync', message: 'location is not turned on' })
      return // if the user still refused to turn on their location take no further actions
    }

    //2. Ask for app's permission to use location
    debug && console.log('<<<SERVICES - getUserLocationAsync - making sure location services are permitted', previousStatus)
    alertDebug && await alert({ title: 'getUserLocationAsync', message: `current status: ${previousStatus}` })
    if (Platform.OS === 'ios' && previousStatus === PERMISSION_STATUS_DENIED) {
      debug && console.log('<<<SERVICES - getUserLocationAsync - custom ios permission prompt')
      alertDebug && await alert({ title: 'getUserLocationAsync', message: 'custom ios permission prompt' })
      const goToAppSettings = await alert({
        title: i18n.t('services.location.allow.description'),
        buttons: [
          { text: i18n.t('common.settings'), onPressValue: true },
          { text: i18n.t('common.cancel'), onPressValue: false, },
        ],
        options,
      })
      if (goToAppSettings) {
        Linking.openURL('app-settings:')
        return -1 // do not attempt any further actions for obtaining location, will be done when user revists location action
      }
      return
    } else if (previousStatus !== PERMISSION_STATUS_GRANTED) {
      debug && console.log('<<<SERVICES - getUserLocationAsync - system permission prompt')
      alertDebug && await alert({ title: 'getUserLocationAsync', message: 'system permission prompt' })
      let { status } = await Location.requestPermissionsAsync()
      if (status === PERMISSION_STATUS_DENIED ) {
        debug && console.log('<<<SERVICES - getUserLocationAsync - permission denied')
        alertDebug && await alert({ title: 'getUserLocationAsync', message: 'permission denied' })
        return // if permission not granted, do not attempt any further actions
      }
    }

    //3. Retrieve location
    debug && console.log('<<<SERVICES - getUserLocationAsync - retrieving location')
    alertDebug && await alert({ title: 'getUserLocationAsync', message: 'retrieving location' })
    return Location.getCurrentPositionAsync({})
  } catch (e) {
    debug && console.log('getUserLocationAsync error', e.message)
    alertDebug && await alert({ title: 'getUserLocationAsync error', message: e.message })
  }
}

export default {
  getUserLocationAsync,
}
