import React from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import FontAwesome from '@expo/vector-icons/FontAwesome'
import Octicons from '@expo/vector-icons/Octicons'
import MDIcons from '@expo/vector-icons/MaterialCommunityIcons'
import NamedImage from '../../components/simple/NamedImage'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'

export default class ProducerMapMarker extends React.Component {
  static propTypes = {
    producer: PropTypes.object,
    active: PropTypes.bool,
  }

  shouldComponentUpdate(prevProps) {
    return prevProps.active !== this.props.active
  }
  
  render() {
    const { producer, active } = this.props
    const { profileImage, coverPhoto } = producer
    const imageSize = 25
    const iconSize = 32
    // Attempt at image overlayed the map marker icon top portion
    // return (
    //   <View style={styles.container}>
    //     <FontAwesome size={iconSize} name='map-marker' color={active ? colors.primary : colors.text.main }/>
    //     <NamedImage
    //       style={[styles.profileImage, { height: imageSize, width: imageSize }]}
    //       name={profileImage || coverPhoto}
    //       width={imageSize}
    //       height={imageSize}
    //       fit='crop'
    //       crop='faces'
    //       resizeMode='cover'
    //       resizeMethod='scale'
    //     />
    //   </View>
    // )

    // return (
    //   <NamedImage
    //     style={[styles.profileImage, { height: imageSize, width: imageSize }]}
    //     name={profileImage || coverPhoto}
    //     width={imageSize}
    //     height={imageSize}
    //     fit='crop'
    //     crop='faces'
    //     resizeMode='cover'
    //     resizeMethod='scale'
    //   />
    // )

    // return <Octicons
    //   size={iconSize} 
    //   name='location'
    //   style={{ backgroundColor: active ? colors.primary : colors.text.invert }}
    //   color={active ? colors.primary : colors.text.main }
    // />

    // return <FontAwesome size={iconSize} name='map-marker' color={active ? colors.primary : colors.text.main }/>
    return <MDIcons name='map-marker' size={iconSize} color={active ? colors.primary : colors.text.main} />
  }
}

const styles = stylus({
  container: {
    flex: 1,
  },
  marker: {

  },
  profileImage: {
    borderRadius: 10,
    position: 'absolute',
    top: 0,
    zIndex: 1,
  },
})
