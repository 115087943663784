import React from 'react'
import {
  StyleSheet,
  TouchableOpacity,
} from 'react-native'

import Icons from '@expo/vector-icons/Ionicons'
import withActionSheet from '../../containers/ActionSheet/withActionSheet'
import { connect, graphql, compose } from '../../config/connected'
import i18n from 'i18n-js'
import { PropTypes } from 'prop-types'

import colors from '../../config/colors'
import NavigationActions from '../../utility/navigationActions'
import { ROUTE_REPORT_CONTENT_PRODUCT } from '../../config/constants'

@withActionSheet
class ProductOptions extends React.Component {

  static propTypes = {
    showActionSheetWithOptions: PropTypes.func.isRequired,
    currentUserId: PropTypes.string,
    product: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  render() {
    return (
      <TouchableOpacity style={styles.area} onPress={this.showActionSheet}>
        <Icons name='md-more' size={22} color={colors.black} />
      </TouchableOpacity>
    )
  }

  showActionSheet = () => {
    const { showActionSheetWithOptions, product, dispatch } = this.props
    let options = [
      this.getMessage('common.report'),
      this.getMessage('common.cancel')
    ]
    const reportIndex = 0
    const cancelButtonIndex = 1
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case reportIndex:
            NavigationActions.navigate({ routeName: ROUTE_REPORT_CONTENT_PRODUCT, params: { objectId: product.id } })
            break
          case cancelButtonIndex:
            break
        }
      },
    )
  }

  getMessage(transPath) {
    return i18n.t(transPath)
  }
}

const mapStateToProps = (state) => ({
  currentUserId: state.currentUser.id
})
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ProductOptions)

const styles = StyleSheet.create({
  // area: {
  //   width: 52,
  //   height: 52,
  //   position: 'absolute',
  //   right: 0,
  //   top: 0,
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
  area: {
    paddingLeft: 20,
  }
})
