import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Text, View, Image, ScrollView, Linking, PixelRatio } from 'react-native'
import i18n from 'i18n-js'
import { stylus } from '../../../../config/styles'
import sizes from '../../../../config/sizes'
import { connect, compose } from '../../../../config/connected'
import colors from '../../../../config/colors'
import {
  VENDOR_RULES_LINK,
  SERVICE_LEVEL_AGREEMENT_LINK,
  TARIFFS_COMMISSIONS_LINK,
  SALEABLE_FOOD_RULES_LINK,
} from '../../../../config/constants'
import NavigationActions from '../../../../utility/navigationActions'
import Button from '../../../../components/simple/Button'
import MobileBackButton from '../../../../components/simple/MobileBackButton'
import Checkbox from '../../../../components/simple/Checkbox'
import {
  onboardingPhone,
  onboardingPen,
  onboardingShop,
} from '../../../../assets/images'
import T from '../../../../components/T'

class Start extends Component {
  state = {
    isChecked: false,
  }
  static propTypes = {
    goToPage: PropTypes.func,
  }
  static navigationOptions = (props) => {
    return {
      headerTitle: 'Admin Dashboard',
      headerLeft: () => (
        <MobileBackButton
          label='Back'
          onPress={() => NavigationActions.back()}
        />
      ),
    }
  }

  onSubmit = () => {
    this.props.goToPage('FinancialInfo')
  }

  toggleCheckbox = () => {
    this.setState((prevState) => ({ isChecked: !prevState.isChecked }))
  }

  renderLinks = () => {
    return (
      <Text style={styles.subPointText}>
        <T t='onboardingShop.start.rules.part1' />
        <T
          accessibilityRole='link'
          target='_blank'
          href={VENDOR_RULES_LINK}
          t='onboardingShop.start.rules.vendorRules'
          style={styles.textLink}
          onPress={() => Linking.openURL(VENDOR_RULES_LINK)}
        />
        <T t='onboardingShop.start.rules.part2' />
        <T
          accessibilityRole='link'
          target='_blank'
          href={SERVICE_LEVEL_AGREEMENT_LINK}
          t='onboardingShop.start.rules.serviceLevel'
          style={styles.textLink}
          onPress={() => Linking.openURL(SERVICE_LEVEL_AGREEMENT_LINK)}
        />
        <T t='onboardingShop.start.rules.part3' />
        <T
          accessibilityRole='link'
          target='_blank'
          href={TARIFFS_COMMISSIONS_LINK}
          t='onboardingShop.start.rules.tariffsCommissions'
          style={styles.textLink}
          onPress={() => Linking.openURL(TARIFFS_COMMISSIONS_LINK)}
        />
        <T t='onboardingShop.start.rules.part4' />
        <T
          accessibilityRole='link'
          target='_blank'
          href={SALEABLE_FOOD_RULES_LINK}
          t='onboardingShop.start.rules.saleableFoodRules'
          style={styles.textLink}
          onPress={() => Linking.openURL(SALEABLE_FOOD_RULES_LINK)}
        />
        <Text>.</Text>
      </Text>
    )
  }

  render() {
    return (
      <View style={{flex: 1}}>
        <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer} >
          <View style={{ flex: 1 }}>
            <T style={[styles.textTitle]} t='onboardingShop.start.title' />
            <View style={styles.row}>
              <View style={styles.numberContainer}>
                <Text style={styles.number}>1.</Text>
              </View>
              <View style={styles.textContainer}>
                <T style={styles.pointText} t='onboardingShop.start.first' />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.numberContainer}>
                <Text style={styles.number}>2.</Text>
              </View>
              <View style={styles.textContainer}>
                <T style={styles.pointText} t='onboardingShop.start.second' />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.numberContainer}>
                <Text style={styles.number}>3.</Text>
              </View>
              <View style={styles.textContainer}>
                <T style={styles.pointText} t='onboardingShop.start.third' />
              </View>
            </View>
          </View>
          <View>
            <View style={[styles.row, styles.footerRow]}>
              <View style={[styles.textContainer, { paddingLeft: 46 }]}>
                {this.renderLinks()}
              </View>
            </View>
            <View style={[styles.row, styles.footerRow]}>
              <Checkbox
                checked={this.state.isChecked}
                onPress={this.toggleCheckbox}
                style={styles.checkbox}
              />
              <View style={styles.textContainer}>
                <T
                  t='onboardingShop.start.approval'
                  style={styles.subPointText}
                />
              </View>
            </View>
            <View style={styles.buttonContainer}>
              <Button
                onPress={this.onSubmit}
                disabled={this.state.isChecked === false}
                label={i18n.t('onboardingShop.start.submit')}
                labelStyle={styles.buttonLabel}
                style={styles.button}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

export default compose(
  connect(mapStateToProps),
)(Start)

const styles = stylus({
  container: {
    flex: 1,
    paddingTop: 40,
    paddingBottom: 30,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 15,
    paddingHorizontal: 15,
  },
  footerRow: {
    marginBottom: 20,
  },
  numberContainer: {
    minWidth: 15,
    marginRight: 14,
  },
  number: {
    fontSize: 16,
  },
  contentContainer: {
    android: {
      paddingBottom: sizes.tabBarHeight,
    },
    iphonex: {
      paddingBottom: sizes.iphonexTabBarHeight,
    },
  },
  textContainer: {
    flex: 1,
  },
  pointText: {
    fontSize: 16,
  },
  textLink: {
    color: colors.text.link,
    fontSize: 12,
  },
  subPointText: {
    fontSize: 12,
  },
  icon: {
    position: 'absolute',
    top: 2,
    left: -45,
    width: 30,
    height: 30,
  },
  checkbox: {
    marginRight: 14,
  },
  buttonContainer: {
    marginTop: 20,
    alignItems: 'center',
  },
  button: {
    paddingHorizontal: 45,
    backgroundColor: colors.primary,
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  textTitle: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 40,
    marginHorizontal: 60,
  },
})
