import React from 'react'
import { compose, graphql, connect } from '../../config/connected'
import productSchema from '../../schema/product'
import shopSchema from '../../schema/shop'
import { ANALYTICS_PRODUCT_EDITED } from '../../reducers/analytics'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  analyticsProductEdited: (productId, oldData, newData) => {
    let diff = Object.keys(newData).reduce((diff, key) => {
      if (oldData[key] === newData[key]) return diff
      return {
        ...diff,
        [key]: newData[key],
      }
    }, {})

    dispatch({
      type: ANALYTICS_PRODUCT_EDITED,
      data: {
        productId,
        updatedData: diff,
      },
    })
  },
  dispatch,
})

function withPublishProduct(Composed) {
  return (compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(productSchema.mutations.createProdcut, { name: 'createProduct' }),
    graphql(productSchema.mutations.updateProduct, { name: 'updateProduct' })
  )(
    class WithPublishProduct extends React.Component {
      publishProduct = async(productData) => {
        const { productId, shopId, payload, initialData, editedData } = productData
        const callback = productId ? this.props.updateProduct : this.props.createProduct

        await callback({
          variables: payload,
          refetchQueries: [
            {
              query: shopSchema.queries.productsByUserId,
              variables: { userId: shopId },
            },
          ],
        })

        if (productId) {
          this.props.analyticsProductEdited(productId, initialData, editedData)
        }
      }

      render() {
        return <Composed
          {...this.props}
          publishProduct={this.publishProduct}
        />
      }
    }
  ))
}

export default withPublishProduct
