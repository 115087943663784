import React from 'react'
import { View, Text, Platform, KeyboardAvoidingView } from 'react-native'
import NavigationActions from '../../utility/navigationActions'

import MobileBackButton from '../../components/simple/MobileBackButton'
import FieldCollection from '../../components/simple/FieldCollection'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'

import _ from 'lodash'
import produce from 'immer'
import i18n from 'i18n-js'

import {
  graphql,
  connect
} from '../../config/connected'
import aliasSchema from '../../schema/alias'
import userSchema from '../../schema/user'
import { updateCachedQueryValue } from '../../utility/apollo'
import alert from '../../utility/alert'
import confirm from '../../utility/confirm'
import { stylus } from '../../config/styles'
import screenInfo from '../../config/screenInfo'
import { getFormattedUsername, getKeyboardPaddingStyle } from '../../config/helpers'
import { validators } from '../../config/validate'

const isAndroid = Platform.OS === 'android'
const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo
})
@graphql(aliasSchema.queries.aliases, {
  name: 'aliasesHandler',
  options: ({ navigation }) => {
    const entityTable = _.get(navigation, 'state.params.entityTable')
    const entityId = _.get(navigation, 'state.params.entityId')
    return {
      variables: { entityTable, entityId }
    }
  }
})
@graphql(aliasSchema.mutations.createAlias, {
  name: 'createAlias'
})
@graphql(aliasSchema.mutations.deleteAlias, {
  name: 'deleteAlias'
})
@graphql(userSchema.queries.userByAlias, {
  name: 'userByAliasHandler',
  options: {
    variables: { alias: '' },
  },
})
@WithKeyboardInfo
@connect(mapStateToProps)
class Aliases extends React.Component {
  constructor(props) {
    super(props)
    this.state = { createAliasValue: '', errors: {}}
  }
  render() {
    const { aliasesHandler, keyboardInfo, screenInfo } = this.props
    if (aliasesHandler.loading) {
      return null
    }
    const { aliases } = aliasesHandler
    const collection = aliases.map(alias => alias.alias)
    const paddingStyle = getKeyboardPaddingStyle(keyboardInfo)
    return (
      <KeyboardAvoidingView
        style={styles.outerContainer}
        behavior={Platform.OS === 'android' ? undefined : 'padding'}
        keyboardVerticalOffset={60}
      >
        <View style={[styles.container]}>
          <FieldCollection 
            collection={collection}
            placeholder={i18n.t('admin.manageAliases.placeholder')}
            fieldValue={this.state.createAliasValue}
            fieldError={this.state.errors.createAliasValue}
            onChangeCallback={this.onCreateAliasChange}
            onRemoveCallback={this.deleteAlias}
            onSubmitCallback={this.createAlias}
          />
          <View style={paddingStyle}></View>
        </View>
      </KeyboardAvoidingView>
    )
  }

  onCreateAliasChange = (value) => {
    const errorTransString = validators.username({ allowEmpty: false }, value)
    const error = errorTransString ? i18n.t(errorTransString) : null
    this.setState((prevState) => {
      const nextState = produce(prevState, draft => {
        if (error) {
          _.set(draft, 'errors.createAliasValue', error)
        } else {
          _.unset(draft, 'errors.createAliasValue')
        }
        if ((!isAndroid && !error) || isAndroid) {
          _.set(draft, 'createAliasValue', value)
        }
      })
      return nextState
    })
  }

  deleteAlias = async (index) => {
    const { aliasesHandler, deleteAlias, navigation } = this.props
    const { aliases } = aliasesHandler
    const aliasToDelete = aliases[index]

    const shouldDeleteAlias = await confirm({
      title: i18n.t('admin.manageAliases.prompts.deleteAlias.title'),
      message: i18n.t('admin.manageAliases.prompts.deleteAlias.message', { alias: aliasToDelete.alias }),
    })
    if (!shouldDeleteAlias) {
      return
    }

    const entityTable = _.get(navigation, 'state.params.entityTable')
    const entityId = _.get(navigation, 'state.params.entityId')
    await deleteAlias({
      variables: { id: aliasToDelete.id },
      update: (store, { data }) => {
        updateCachedQueryValue(store, {
          query: aliasSchema.queries.aliases,
          variables: { entityId, entityTable },
          nextValue: (aliases = []) => {
            const index = aliases.findIndex((alias) => alias.id == aliasToDelete.id)
            aliases.splice(index, 1)
            return aliases
          },
        })
      }
    })
  }

  createAlias = async() => {
    const { createAlias, navigation, userByAliasHandler } = this.props
    const { createAliasValue } = this.state

    if (!createAliasValue) {
      alert({title: i18n.t('common.error'), message: i18n.t('admin.manageAliases.errors.emptyAlias') })
      return
    }
    const result = await userByAliasHandler.refetch({ alias: createAliasValue })
    const user = _.get(result, 'data.userByAlias')

    if (user) {
      alert({
        title: i18n.t('common.error'),
        message: i18n.t('admin.manageAliases.errors.aliasAlreadyExists')
      })
      return
    }

    const entityTable = _.get(navigation, 'state.params.entityTable')
    const entityId = _.get(navigation, 'state.params.entityId')
    await createAlias({
      variables: {
        entityId,
        entityTable,
        alias: createAliasValue,
      },
      update: (store, { data }) => {
        const { createAlias: alias } = data
        updateCachedQueryValue(store, {
          query: aliasSchema.queries.aliases,
          variables: { entityId, entityTable },
          nextValue: (aliases = []) => {
            aliases.push(alias)
            return aliases
          },
        })
      }
    })
  }

}

Aliases.navigationOptions = (props) => {
  return {
    headerTitle: 'Aliases',
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

const screenInfoObj = screenInfo()
const styles = stylus({
  outerContainer: {
    native: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
    web: {
      position: 'relative',
    },
  },
  container: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15,
    paddingTop: 15,
    desktop: {
      paddingRight: screenInfoObj.paddingRight || 15,
      paddingBottom: 25
    },
  }
})

export default Aliases
