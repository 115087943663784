import React, { Component } from 'react'
import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  TextInput,
  TouchableOpacity,
  Animated,
} from 'react-native'

import NavigationActions from '../../utility/navigationActions'

import { connect } from 'react-redux'
import Blurry from '../../components/simple/Blurry'
import Icon from '../../config/icon'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import TopButton from '../../components/simple/TopButton'
import Brand from '../../components/Brand'
import sizes from '../../config/sizes'
import shared, { stylus } from '../../config/styles'
import Gradient from '../../components/simple/Gradient'
import Color from 'color'
import Empty from '../../screens/Notifications/Empty'

class Notifications extends React.Component {
  render() {
    const { screenInfo } = this.props
    return (
      <View style={styles.container}>
        <Empty />
      </View>
    )
  }
}

const styles = stylus({
  container: {
    right: 120,
    top: 56,
    position: 'absolute',
    width: 420,
    minHeight: 200,
    borderRadius: 8,
    borderTopRightRadius: 0,
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 4 },
    shadowOpacity: 0.15,
    shadowRadius: 18,
    justifyContent: 'center',
    android: {
      borderWidth: sizes.px,
      borderColor: colors.androidCardBorder,
      // elevation: 0.5,
    },
    ios: {
      shadowOpacity: 0.18,
      shadowRadius: 10,
      top: 56 + 13,
    },
  },
})

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({
  // onResize: () => dispatch({ type: 'SCREEN_RESIZE' }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications)
