import React from 'react'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'

import { connect } from 'react-redux'
import colors from '../../config/colors'
import shared from '../../config/styles'

class Action extends React.Component {
  render() {
    const {
      label,
      style,
      onPress,
      onPressDisabled,
      children,
      disabled = false,
      after,
      labelStyle,
      color,
      light,
    } = this.props
    return (
      <TouchableOpacity
        style={[styles.container, style]}
        onPress={disabled ? onPressDisabled : onPress}
      >
        {children}
        {label && (
          <Text
            numberOfLines={1}
            style={[
              styles.label,
              labelStyle,
              color && { color },
              disabled && styles.disabled,
              light && styles.light,
            ]}
          >
            {label}
          </Text>
        )}
        {after}
      </TouchableOpacity>
    )
  }
}

export default Action

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    // flex: 1,
    ...shared.actionLabel,
  },
  disabled: {
    color: '#BBB',
  },
  light: {
    fontWeight: '400',
    fontSize: 15,
  },
})
