import React from 'react'
import ReactNativeSwitch from './ReactNativeSwitch'

export default ({ style, disabled, ios_backgroundColor, onChange, onValueChange, thumbColor, trackColor, value,...props}) => {
  console.log('<<<SWITCH - colors', thumbColor, trackColor)
  const size = 30
  return (<ReactNativeSwitch
    style={style}
    value={value}
    onValueChange={onValueChange}
    disabled={disabled}
    circleSize={size}
    barHeight={size + 4}
    circleBorderWidth={0}
    backgroundActive={trackColor.true}
    backgroundInactive={trackColor.false}
    circleActiveColor={thumbColor}
    circleInActiveColor={thumbColor}
    innerCircleStyle={{}} // style for inner animated circle for what you (may) be rendering inside the circle
    outerCircleStyle={{ }} // style for outer animated circle
    renderActiveText={false}
    renderInActiveText={false}
    switchLeftPx={3} // denominator for logic when sliding to TRUE position. Higher number = more space from RIGHT of the circle to END of the slider
    switchRightPx={3} // denominator for logic when sliding to FALSE position. Higher number = more space from LEFT of the circle to BEGINNING of the slider
    switchWidthMultiplier={2} // multipled by the `circleSize` prop to calculate total width of the Switch
    switchBorderRadius={size} // Sets the border Radius of the switch slider. If unset, it remains the circleSize.
  />)
}
