import React from 'react'
import PropTypes from 'prop-types'
import { Platform, View, TextInput } from 'react-native'

import _ from 'lodash'
import { isFunction, isSamsung } from '../../config/helpers'

class ReturnKeyCompatilityTextInput extends React.PureComponent {
  ref = null
  selection = { start: 0, end: 0 }
  text = ''
  debug = false

  static propTypes = {
    stateChangeHandler: PropTypes.string,
    Input: PropTypes.any
  }

  static defaultProps = {
    stateChangeHandler: 'onChangeText',
    Input: TextInput
  }

  onRefChange = (ref) => {
    if (ref) {
      this.ref = ref
    }

    this.props.reference && this.props.reference(ref)
  }

  determineRefValue = () => {
    const passedRef = this.props.reference
    if (passedRef === undefined || isFunction(passedRef)) {
      return this.onRefChange
    } else {
      return passedRef
    }
  }

  onSelectionChange = (event) => {
    this.selection = _.get(event, 'nativeEvent.selection')
    this.props.onSelectionChange && this.props.onSelectionChange(event)
  }


  onChange = (event) => {
    this.props.onChange && this.props.onChange(event)  
  }

  onChangeText = (text) => {
    this.props.onChangeText && this.props.onChangeText(text)
  }

  onChangeTextConvert = (text) => {
    this.onChange({ nativeEvent: { text }})
  }

  stateChangeHandler = () => {
    if (this.props.stateChangeHandler === 'onChangeText') {
      return  this.props.onChangeText
    } else if (this.props.stateChangeHandler === 'onChange') {
      return this.onChangeTextConvert
    }
    return undefined
  }

  shouldAddNewline(key, stateChangeHandler) {
    return key === 'Enter' && !!stateChangeHandler && this.props.multiline && isSamsung()
  }

  onKeyPress = (event) => {
    this.props.onKeyPress && this.props.onKeyPress(event)
    const key = _.get(event, 'nativeEvent.key')
    const stateChangeHandler = this.stateChangeHandler()
    const shouldAddNewLine = this.shouldAddNewline(key, stateChangeHandler)
    this.debug && console.log('<<< RETURN KEY - shouldAddNewLine', shouldAddNewLine)
    if (shouldAddNewLine) {
      const selection = { ...this.selection}
      const text = [this.props.value.slice(0, selection.start), '\n', this.props.value.slice(selection.end)].join('')
      const setNativeProps = _.get(this.ref, 'input.setNativeProps')
      if (setNativeProps) {
        setNativeProps({ selection: {
          start: this.selection.start + 1,
          end: this.selection.end + 1
        }})
      }
      stateChangeHandler(text)
    }
  }

  render() {
    const {
      Input,
      stateChangeHandler,
      onChange,
      onChangeText,
      reference,
      onSelectionChange,
      onKeyPress,
      ...props
    } = this.props
    return (
      <Input
        {...props}
        onKeyPress={this.onKeyPress}
        onChange={this.onChange}
        onChangeText={this.onChangeText}
        onSelectionChange={this.onSelectionChange}
        ref={this.determineRefValue()}
      />
    )
  }
}

export default ReturnKeyCompatilityTextInput
