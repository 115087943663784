import environment from './environment'
import { PixelRatio } from 'react-native'

// const PX = 0.15
const PX = PixelRatio.get() || 1
const maxResolution = {
  w: 1920,
  h: 1080
}

const buildUrl = ({
  name,
  width,
  height,
  maxHeight,
  fit,
  crop,
  auto = 'compress,format',
  isAsset = false,
  zoomView = false,
  quality = '80'
}) => {
  let options = []
  let imgixUri = isAsset ? environment.imgixAssetsUrl : environment.imgixUrl
  let result = imgixUri + name

  if (quality) {
    options.push(`q=${quality}`)
  }
  if (!zoomView) {
    if (width) {
      options.push(`w=${width * PX}`)
    }
    if (maxHeight) {
      options.push(`h=${maxHeight * PX}`)
      options.push('fit=max')
    } else if (height) {
      options.push(`h=${height * PX}`)
    }
  } else {
    // zoomView param for fetching imgix images with higher resolution. Not in use currently.
    options.push(`w=${width < maxResolution.w ? width : maxResolution.w}`)
    options.push(`h=${height < maxResolution.h ? height : maxResolution.h}`)
  }
  if (fit) {
    options.push(`fit=${fit}`)
  }
  if (crop) {
    options.push(`crop=${crop}`)
  }
  if (auto) {
    options.push(`auto=${auto}`)
  }
  if (options.length > 0) {
    result = result + '?' + options.join('&')
  }

  return result
}

export default buildUrl
