import '../db/index'
import { analyticsDb } from '../db'
import _ from 'lodash'
import Constants from 'expo-constants'
import { Platform } from 'react-native'

export const analyticsMiddleWare = ({ getState }) => next => action => {
  next(action)

  if (Platform.OS !== 'web' && action.type.match(/^analytics/)) {
    const state = getState()

    const currentUserId = _.get(state, 'currentUser.id')
    const appId = Constants.installationId
    const { longitude, latitude } = state.location

    const data = {
      currentUserId,
      appId,
      longitude,
      latitude,
      ...action.data,
    }

    if (!_.isEmpty(data.currentUserId)) {
      analyticsDb.addRecord(action.type.replace('analytics/', ''), data)
    }
  }
}
