import { AsyncStorage } from 'react-native'

import { resetWebsocket } from '../../containers/withApollo'

async function loadCurrentUser(dispatch) {
  const stored = await AsyncStorage.multiGet(['USER_TOKEN', 'USER_ID'])
  const token = stored[0][1]
  const id = stored[1][1]
  resetWebsocket(token)
  dispatch({
    type: 'HYDRATE',
    item: 'currentUser',
    token,
    id,
  })
}

export default loadCurrentUser
