import React, { Component } from 'react'
import {
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
} from 'react-native'
import { stylus } from '../../config/styles'
import Button from '../../components/simple/Button'
import i18n from 'i18n-js'
import colors from '../../config/colors'
import ElegantInput from '../../components/simple/ElegantInput'
import ElegantInputWithTagging from '../../components/ElegantInputWithTagging'
import { TaggingContext } from '../../components/Tagging/withTagging'
import sizes from '../../config/sizes'
import Divider from '../../components/simple/Divider'
import NavigationActions from '../../utility/navigationActions'
import { jsonParseKeyValues } from '../../config/helpers'
import _ from 'lodash'

const isWeb = Platform.OS === 'web'

export class EditFieldFullscreen extends Component {
  state = {
    value: this.props.navigation.state.params.currentValue,
  }

  handleDone = () => {
    const { handleFieldChange, fieldName } = this.props.navigation.state.params
    const { value } = this.state

    handleFieldChange(value, fieldName)
    NavigationActions.back()
  }

  updateValue = (value) => {
    this.setState({ value })
  }

  render() {
    const { fieldName } = this.props.navigation.state.params

    return (
      <TaggingContext.Consumer>
        {(context = {}) => {
          const { taggingHook = {} } = context
          return (
            <KeyboardAvoidingView
              style={{ flex: 1 }}
              behavior={Platform.OS === 'android' ? 'height' : 'padding'}
              contentContainerStyle={{ paddingHorizontal: 10 }}
            >
              <ScrollView
                style={styles.container}
                contentContainerStyle={{
                  paddingBottom: 50,
                  paddingHorizontal: 30,
                }}
                keyboardShouldPersistTaps='handled'
              >
                <View style={styles.headers.container}>
                  <Text style={styles.headers.title}>
                    {i18n.t('shop.edit.titles.editField', {
                      fieldName: i18n.t(`shop.edit.fields.${fieldName}`),
                    })}
                  </Text>
                  <Text style={styles.headers.sub}>
                    {i18n.t('shop.edit.titles.editFieldHintText')}
                  </Text>
                </View>
                <ElegantInputWithTagging
                  defaultTags={
                    this.props.navigation.state.params.currentValue.tags
                  }
                  defaultValue={
                    this.props.navigation.state.params.currentValue.value
                  }
                  taggingHook={taggingHook}
                  onChange={this.updateValue}
                />
                <Button
                  label={i18n.t('common.done')}
                  style={styles.buttons.save}
                  onPress={this.handleDone}
                />
                <Button
                  onPress={() => NavigationActions.back()}
                  label={i18n.t('common.cancel')}
                  style={styles.buttons.cancel}
                />
              </ScrollView>
            </KeyboardAvoidingView>
          )
        }}
      </TaggingContext.Consumer>
    )
  }
}
EditFieldFullscreen.navigationOptions = (props) => {
  if (isWeb) {
    const params = _.get(props, 'navigation.state.params', {})
    _.set(props, 'navigation.state.params', jsonParseKeyValues(params, ['currentValue']))
  }
  return {}
}

export default EditFieldFullscreen
const styles = stylus({
  container: {
    flex: 1,
    // backgroundColor: 'green',
    // paddingHorizontal: 30,
    iphonex: {
      marginBottom: sizes.iphonexTabBarHeight,
    },
    native: {
      marginBottom: sizes.tabBarHeight,
    },
  },
  headers: {
    container: {
      marginVertical: 40,
    },
    title: {
      marginBottom: 20,
      textAlign: 'center',
      fontSize: 22,
      fontWeight: 'bold',
    },
    sub: {
      textAlign: 'center',
      fontSize: 16,
      color: colors.text.light,
    },
  },
  buttons: {
    save: {
      marginVertical: 10,
      backgroundColor: colors.primary,
    },
    cancel: {
      marginVertical: 10,
    },
  },
})
