import _ from 'lodash'
import React from 'react'
import { ActivityIndicator, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import stringScore from 'string-score'
import LayoutAnimation from '../../components/LayoutAnimation'
import ProfileImage from '../../components/Post/ProfileImage'
import KeyboardAvoidingView from '../../components/simple/KeyboardAvoidingView'
import MobileBackButton from '../../components/simple/MobileBackButton'
import branch from '../../config/branch'
import colors from '../../config/colors'
import { compose, connect, graphql } from '../../config/connected'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'
import withCurrentUser from '../../containers/withCurrentUser'
import userSchema from '../../schema/user'
import StartButton from './StartButton'

class NewConversation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      chosen: [],
      searchQuery: '',
      markedForDeletion: false,
    }
  }
  render() {
    return (
      <KeyboardAvoidingView
        style={styles.container}
        keyboardVerticalOffset={branch({ iphone: 120, android: 81, other: 0 })}
      >
        {this.userFilter()}
        {this.results()}
      </KeyboardAvoidingView>
    )
  }
  chosen() {
    const { chosen, markedForDeletion } = this.state
    if (chosen.length === 0) {
      return null
    }
    return chosen.map((person, index) => {
      const selected = markedForDeletion && index === chosen.length - 1
      return (
        <View
          style={[styles.chosenPerson, selected && styles.chosenPersonSelected]}
          key={person.id}
        >
          <Text
            style={[
              styles.chosenPersonName,
              selected && styles.chosenPersonNameSelected,
            ]}
          >
            {person.displayName + ','}
          </Text>
        </View>
      )
    })
  }
  userFilter() {
    return (
      <View style={styles.inputContainer}>
        <Text style={styles.to}>To:</Text>
        {this.chosen()}
        <TextInput
          style={styles.input}
          maxLength={50}
          value={this.state.searchQuery}
          onChangeText={this.onChange.bind(this)}
          ref={(ref) => (this.input = ref)}
          underlineColorAndroid='transparent'
          autoFocus={true}
          onKeyPress={this.onKeyPress}
          autoCorrect={false}
          returnKeyType='done'
          selectTextOnFocus={true}
          // clearButtonMode='always'
        />
      </View>
    )
  }
  onKeyPress = (event) => {
    console.log('keypress')
    const {
      nativeEvent: { key },
    } = event
    const { searchQuery, markedForDeletion, chosen } = this.state
    if (key === 'Backspace' && searchQuery === '' && chosen.length > 0) {
      if (markedForDeletion) {
        this.setState({
          markedForDeletion: false,
          chosen: chosen.slice(0, -1), //remove last item
        })
      } else {
        this.setState({
          markedForDeletion: true,
        })
      }
    }
  }
  onChange = (searchQuery) => {
    const newState = { searchQuery }
    if (searchQuery !== '') {
      newState.markedForDeletion = false
    }
    this.setState(newState)
  }
  results() {
    return (
      <ScrollView
        style={styles.resultsContainer}
        keyboardShouldPersistTaps='handled'
      >
        {this.resultsContent()}
      </ScrollView>
    )
  }
  resultsContent() {
    const { allUsers, cuid } = this.props
    if (allUsers.loading) {
      return <ActivityIndicator size='large' style={{ marginVertical: 30 }} />
    }
    const { searchQuery, chosen } = this.state
    const chosenIds = _.map(chosen, 'id')
    let results = allUsers.allUsers
    if (searchQuery.length) {
      const sorted = _.map(results, (person) => {
        const score =
          1 -
          stringScore(person.displayName + ' ' + person.username, searchQuery)
        return {
          score,
          person,
        }
      })
      const filtered = _.filter(sorted, (item) => {
        return item.score < 0.8
      })
      results = _.map(filtered, 'person')
    }
    results = _.filter(results, (person) => {
      return [...chosenIds, cuid].indexOf(person.id) < 0
    })
    return results.map((person) => {
      return this.person(person)
    })
  }
  person(person) {
    return (
      <TouchableOpacity
        activeOpacity={0.8}
        style={styles.person}
        key={person.id}
        onPress={() => this.selectPerson(person)}
      >
        <ProfileImage size={42} name={person.profileImage} />
        <View style={styles.personContent}>
          <Text style={styles.personName}>{person.displayName}</Text>
          <Text style={styles.username}>{person.username}</Text>
        </View>
      </TouchableOpacity>
    )
  }
  selectPerson(person) {
    const { chosen } = this.state
    const newChosen = [...chosen, person]
    this.setState({
      searchQuery: '',
      markedForDeletion: false,
      chosen: newChosen,
    })
    this.props.setUsers(newChosen)
    // this.preloadConversation(newChosen)
    // this.animate()
  }
  animate() {
    LayoutAnimation &&
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }
}

NewConversation.navigationOptions = (props) => {
  const { navigation } = props
  return {
    headerTitle: 'New Conversation',
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
    headerRight: () => (<StartButton navigation={navigation} />),
  }
}

const mapStateToProps = (state) => ({
  // currentUser: state.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  setUsers: (users) => dispatch({ type: 'NewConversation/SET_USERS', users }),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCurrentUser,
  graphql(userSchema.queries.allUsers, {
    name: 'allUsers',
  }),
)(NewConversation)

const styles = stylus({
  container: {
    alignItems: 'stretch',
    flex: 1,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
    flexWrap: 'wrap',
    paddingVertical: 5,
    paddingHorizontal: 5,
  },
  to: {
    fontSize: 15,
    color: colors.text.soft,
    paddingLeft: 6,
    marginRight: 3,
    // backgroundColor: 'orange',
  },
  input: {
    fontSize: 16,
    height: 40,
    paddingHorizontal: 8,
    color: colors.text.main,
    flex: 1,
    minWidth: 100,
    // backgroundColor: 'orange',
  },
  resultsContainer: {
    flex: 1,
  },
  person: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    height: 54,
  },
  personContent: {
    marginLeft: 10,
    height: 54,
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
    flex: 1,
    justifyContent: 'center',
  },
  personName: {
    fontWeight: '500',
    color: colors.text.main,
    fontSize: 15,
  },
  username: {
    fontSize: 14,
    color: colors.text.secondary,
  },
  chosen: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingTop: 10,
    flexWrap: 'wrap',
  },
  chosenPerson: {
    borderRadius: 4,
    backgroundColor: colors.tagLabel,
    paddingHorizontal: 8,
    paddingVertical: 5,
    marginHorizontal: 3,
    marginVertical: 3,
  },
  chosenPersonSelected: {
    backgroundColor: colors.primary,
  },
  chosenPersonName: {
    color: colors.text.mainLighter,
    fontSize: 14,
  },
  chosenPersonNameSelected: {
    color: colors.text.invert,
  },
})
