import * as Sentry from '@sentry/browser'
import environment from '../environment'
export function setup() {
  if (!environment.sentryDsn) {
    return
  }
  Sentry.init({ 
    dsn: environment.sentryDsn,
    environment: `web-${environment.environment}`
  })
}

export default Sentry
