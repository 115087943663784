import i18n from 'i18n-js'

export default () => ({
  producer: {
    meat: i18n.t('categories.producer.meat'),
    fish: i18n.t('categories.producer.fish'),
    cheeses: i18n.t('categories.producer.cheeses'),
    vegetables: i18n.t('categories.producer.vegetables'),
    fruits: i18n.t('categories.producer.fruits'),
    mushrooms: i18n.t('categories.producer.mushrooms'),
    coldCuts: i18n.t('categories.producer.coldCuts'),
    conserve: i18n.t('categories.producer.conserve'),
    oil: i18n.t('categories.producer.oil'),
    wine: i18n.t('categories.producer.wine'),
    spiritsLiqueurs: i18n.t('categories.producer.spiritsLiqueurs'),
    coffeeInfusions: i18n.t('categories.producer.coffeeInfusions'),
    condimentsSpices: i18n.t('categories.producer.condimentsSpices'),
    legumes: i18n.t('categories.producer.legumes'),
    flours: i18n.t('categories.producer.flours'),
    pastaRice: i18n.t('categories.producer.pastaRice'),
    cereals: i18n.t('categories.producer.cereals'),
    desserts: i18n.t('categories.producer.desserts'),
    bakeryProducts: i18n.t('categories.producer.bakeryProducts'),
    driedFruit: i18n.t('categories.producer.driedFruit'),
    jamsMustards: i18n.t('categories.producer.jamsMustards'),
    honey: i18n.t('categories.producer.honey'),
    beerCider: i18n.t('categories.producer.beerCider'),
    drinks: i18n.t('categories.producer.drinks'),
    boxes: i18n.t('categories.producer.boxes')
  },
  diet: {
    vegetarian: i18n.t('categories.diet.vegetarian'),
    vegan: i18n.t('categories.diet.vegan'),
    pescetarian: i18n.t('categories.diet.pescetarian'),
    dairyFree: i18n.t('categories.diet.dairyFree'),
    glutenFree: i18n.t('categories.diet.glutenFree'),
    lowHistamine: i18n.t('categories.diet.lowHistamine'),
    lowSugar: i18n.t('categories.diet.lowSugar'),
    paleo: i18n.t('categories.diet.paleo'),
    ketogenic: i18n.t('categories.diet.ketogenic'),
    hyperprotein: i18n.t('categories.diet.hyperprotein'),
    lowCarb: i18n.t('categories.diet.lowCarb'),
    lowFat: i18n.t('categories.diet.lowFat'),
    lowFodmap: i18n.t('categories.diet.lowFodmap'),
    rawFood: i18n.t('categories.diet.rawFood'),
  },
  freeFrom: {
    withoutDyes: i18n.t('categories.freeFrom.withoutDyes'),
    withoutAddedFlavors: i18n.t('categories.freeFrom.withoutAddedFlavors'),
    withNoAddedSugar: i18n.t('categories.freeFrom.withNoAddedSugar'),
    sugarFree: i18n.t('categories.freeFrom.sugarFree'),
    noPreservatives: i18n.t('categories.freeFrom.noPreservatives'),
    noTransFats: i18n.t('categories.freeFrom.noTransFats'),
    lactoseFree: i18n.t('categories.freeFrom.lactoseFree'),
    glutenFree: i18n.t('categories.freeFrom.glutenFree'),
    palmOilFree: i18n.t('categories.freeFrom.palmOilFree'),
    alcoholFree: i18n.t('categories.freeFrom.alcoholFree'),
    sulphitesFree: i18n.t('categories.freeFrom.sulphitesFree'),
    noYeast: i18n.t('categories.freeFrom.noYeast'),
    lowNichelContent: i18n.t('categories.freeFrom.lowNichelContent'),
    noAspartame: i18n.t('categories.freeFrom.noAspartame'),
    noAdditives: i18n.t('categories.freeFrom.noAdditives'),
    saltFree: i18n.t('categories.freeFrom.saltFree'),
    noSaturatedFats: i18n.t('categories.freeFrom.noSaturatedFats'),
  },
  professional: {
    chef: i18n.t('categories.professional.chef'),
    foodInfluencer: i18n.t('categories.professional.foodInfluencer'),
    foodPhotographer: i18n.t('categories.professional.foodPhotographer'),
    nutritionist: i18n.t('categories.professional.nutritionist'),
    restaurateur: i18n.t('categories.professional.restaurateur'),
    sommelier: i18n.t('categories.professional.sommelier'),
  },
  properties: {
    withoutDyes: i18n.t('categories.properties.withoutDyes'),
    withoutAddedFlavors: i18n.t('categories.properties.withoutAddedFlavors'),
    withNoAddedSugar: i18n.t('categories.properties.withNoAddedSugar'),
    sugarFree: i18n.t('categories.properties.sugarFree'),
    noPreservative: i18n.t('categories.properties.noPreservative'),
    noTransFats: i18n.t('categories.properties.noTransFats'),
    lactoseFree: i18n.t('categories.properties.lactoseFree'),
    glutenFree: i18n.t('categories.properties.glutenFree'),
    palmOilFree: i18n.t('categories.properties.palmOilFree'),
    alcoholFree: i18n.t('categories.properties.alcoholFree'),
    sulphitesFree: i18n.t('categories.properties.sulphitesFree'),
    gluten: i18n.t('categories.properties.gluten'),
    shellfish: i18n.t('categories.properties.shellfish'),
    eggs: i18n.t('categories.properties.eggs'),
    fish: i18n.t('categories.properties.fish'),
    peanuts: i18n.t('categories.properties.peanuts'),
    soy: i18n.t('categories.properties.soy'),
    milk: i18n.t('categories.properties.milk'),
    nuts: i18n.t('categories.properties.nuts'),
    celeryAndDerivatives: i18n.t('categories.properties.celeryAndDerivatives'),
    mustardAndDerivatives: i18n.t(
      'categories.properties.mustardAndDerivatives',
    ),
    sesameSeeds: i18n.t('categories.properties.sesameSeeds'),
    sulfurDioxideAndSulphites: i18n.t(
      'categories.properties.sulfurDioxideAndSulphites',
    ),
    lupine: i18n.t('categories.properties.lupine'),
    clams: i18n.t('categories.properties.clams'),
    peaches: i18n.t('categories.properties.peaches'),
    strawberries: i18n.t('categories.properties.strawberries'),
    plums: i18n.t('categories.properties.plums'),
    kiwi: i18n.t('categories.properties.kiwi'),
    yeasts: i18n.t('categories.properties.yeasts'),
    wheat: i18n.t('categories.properties.wheat'),
    raspberries: i18n.t('categories.properties.raspberries'),
    DOP: i18n.t('categories.properties.DOP'),
    IGP: i18n.t('categories.properties.IGP'),
    STG: i18n.t('categories.properties.STG'),
    DOC: i18n.t('categories.properties.DOC'),
    DOCG: i18n.t('categories.properties.DOCG'),
    bio: i18n.t('categories.properties.bio'),
    HACCP: i18n.t('categories.properties.HACCP'),
    ISO22000: i18n.t('categories.properties.ISO22000'),
    IFS: i18n.t('categories.properties.IFS'),
    BRC: i18n.t('categories.properties.BRC'),
    slowFood: i18n.t('categories.properties.slowFood'),
    vegan: i18n.t('categories.properties.vegan'),
    nessuna: i18n.t('categories.properties.nessuna'),
    fresh: i18n.t('categories.properties.fresh'),
    refrigerated: i18n.t('categories.properties.refrigerated'),
    hydroCooling: i18n.t('categories.properties.hydroCooling'),
    vacuumCooling: i18n.t('categories.properties.vacuumCooling'),
    deepFrozen: i18n.t('categories.properties.deepFrozen'),
    frozen: i18n.t('categories.properties.frozen'),
    blastChilling: i18n.t('categories.properties.blastChilling'),
    asepticallyCanned: i18n.t('categories.properties.asepticallyCanned'),
    pasteurised: i18n.t('categories.properties.pasteurised'),
    UHT: i18n.t('categories.properties.UHT'),
    vacuumPacked: i18n.t('categories.properties.vacuumPacked'),
    controlledAtmosphereCAP: i18n.t(
      'categories.properties.controlledAtmosphereCAP',
    ),
    protectiveAtmosphereMAP: i18n.t(
      'categories.properties.protectiveAtmosphereMAP',
    ),
    inOil: i18n.t('categories.properties.inOil'),
    sunDried: i18n.t('categories.properties.sunDried'),
    inSalt: i18n.t('categories.properties.inSalt'),
    brine: i18n.t('categories.properties.brine'),
    dehydrated: i18n.t('categories.properties.dehydrated'),
    lyophilized: i18n.t('categories.properties.lyophilized'),
    coldConcentration: i18n.t('categories.properties.coldConcentration'),
    hotConcentration: i18n.t('categories.properties.hotConcentration'),
    candying: i18n.t('categories.properties.candying'),
    inSugar: i18n.t('categories.properties.inSugar'),
    additives: i18n.t('categories.properties.additives'),
    smoked: i18n.t('categories.properties.smoked'),
    inAlcohol: i18n.t('categories.properties.inAlcohol'),
    fermentation: i18n.t('categories.properties.fermentation'),
    pickled: i18n.t('categories.properties.pickled'),
    sousVide: i18n.t('categories.properties.sousVide'),
    radiated: i18n.t('categories.properties.radiated'),
    microwavesTreatment: i18n.t('categories.properties.microwavesTreatment'),
    ultravioletsTreatment: i18n.t(
      'categories.properties.ultravioletsTreatment',
    ),
    sterilized: i18n.t('categories.properties.sterilized'),
    maturing: i18n.t('categories.properties.maturing'),
    packagedInAsepsis: i18n.t('categories.properties.packagedInAsepsis'),
    pascalizedHHP: i18n.t('categories.properties.pascalizedHHP'),
    omnivorous: i18n.t('categories.properties.omnivorous'),
    vegetarian: i18n.t('categories.properties.vegetarian'),
    handicraft: i18n.t('categories.properties.handicraft'),
    fairTrade: i18n.t('categories.properties.fairTrade'),
    industrial: i18n.t('categories.properties.industrial'),
    biodynamic: i18n.t('categories.properties.biodynamic'),
    integrated: i18n.t('categories.properties.integrated'),
    symbiotic: i18n.t('categories.properties.symbiotic'),
    kosher: i18n.t('categories.properties.kosher'),
    halal: i18n.t('categories.properties.halal'),
    paper: i18n.t('categories.properties.paper'),
    metal: i18n.t('categories.properties.metal'),
    bioPlastic: i18n.t('categories.properties.bioPlastic'),
    notRecyclable: i18n.t('categories.properties.notRecyclable'),
    glass: i18n.t('categories.properties.glass'),
    privateChef: i18n.t('categories.properties.privateChef'),
    cookingCourses: i18n.t('categories.properties.cookingCourses'),
    consultancy: i18n.t('categories.properties.consultancy'),
    chefAtHome: i18n.t('categories.properties.chefAtHome'),
    posts: i18n.t('categories.properties.posts'),
    blog: i18n.t('categories.properties.blog'),
    photo: i18n.t('categories.properties.photo'),
    video: i18n.t('categories.properties.video'),
    courses: i18n.t('categories.properties.courses'),
    homeVisit: i18n.t('categories.properties.homeVisit'),
    foodPlans: i18n.t('categories.properties.foodPlans'),
    foodDelivery: i18n.t('categories.properties.foodDelivery'),
    catering: i18n.t('categories.properties.catering'),
    events: i18n.t('categories.properties.events'),
    servicesInRestaurants: i18n.t(
      'categories.properties.servicesInRestaurants',
    ),
  },
  method: {
    organic: i18n.t('categories.method.organic'),
    traditional: i18n.t('categories.method.traditional'),
  },
})
