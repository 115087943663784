import React, { useCallback, useEffect, useState } from 'react'
import { ActivityIndicator,  StyleSheet, SafeAreaView, FlatList, View } from 'react-native'
import PropTypes from 'prop-types'
import { compose, hoistStatics } from 'recompose'
import { graphql } from 'react-apollo'
import i18n from 'i18n-js'
import Icons from '@expo/vector-icons/Ionicons'
import couponSchema from '../../schema/coupon'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import Action from '../../components/simple/Action'
import CouponItem from './CouponItem'
import MobileBackButton from '../../components/simple/MobileBackButton'
import NavigationActions from '../../utility/navigationActions'
import alert from '../../utility/alert'
import _ from 'lodash'
import { getAlertErrorConfig  } from '../../config/helpers'

const INITIAL_PAGE_SIZE = 20
const SUBSEQUENT_PAGE_SIZE = 20
const INITIAL_OFFSET = 0

const CouponsList = ({ getAllCoupons, navigation, deleteCoupon, disableCoupon, enableCoupon }) => {
  const [offset, setOffset] = useState(INITIAL_OFFSET)
  const [noMore, setNoMore] = useState(false);

  useEffect(() => {
    const { params } = navigation.state
    if (params && params.doRefetch) {
      refresh()
    }
  }, [navigation, refresh])

  const refresh = useCallback(() => {
    getAllCoupons.refetch()
    setOffset(0)
    setNoMore(false)
  }, [getAllCoupons])

  function isLoading()  {
    return getAllCoupons.networkStatus < 7
  }

  function loadMore() {
    if (isLoading()) return
    if (noMore) return
    if (getAllCoupons.coupons.length < offset) {
      setNoMore(true)
      return
    }
    const newOffset = offset + SUBSEQUENT_PAGE_SIZE
    
    setOffset(newOffset)

    getAllCoupons.fetchMore({
      variables: {
        offset: newOffset
      }, 
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        
        const newData = _.uniqBy(
          _.union(prev.coupons, fetchMoreResult.coupons),
          'id',
        )
        return { coupons: newData }
      },
    })
  }

  return <SafeAreaView style={styles.container}>
    <FlatList
      refreshing={
        getAllCoupons.refreshing || getAllCoupons.networkStatus === 4
      }
      onRefresh={refresh}
      data={getAllCoupons.coupons}
      renderItem={({ item }) => <CouponItem
        coupon={item}
        onDelete={couponId => {
          deleteCoupon({
            variables: {couponId}
          }).then(() => {
            getAllCoupons.refetch()
          }).catch(err => {
            const alertConfig = getAlertErrorConfig(err)
            alert(alertConfig)
          })
        }}
        onDisable={couponId => disableCoupon({ variables: { couponId }})}
        onEnable={couponId => enableCoupon({ variables: { couponId }})}
        onEdit={coupon => {
          navigation.navigate('AdminCouponsCreate', { coupon })
        }}

      />}
      keyExtractor={item => item.id}
      onEndReachedThreshold={0.4}
      onEndReached={loadMore}
      ListFooterComponent={() =>
        isLoading() && <ActivityIndicator style={{ marginBottom: 50}} size="small" />
      }
    />
  </SafeAreaView>
}

CouponsList.propTypes = {
  navigation: PropTypes.any,
  getAllCoupons: PropTypes.any,
  deleteCoupon: PropTypes.func.isRequired,
  disableCoupon: PropTypes.func.isRequired,
  enableCoupon: PropTypes.func.isRequired,
}

CouponsList.navigationOptions = ({ navigation }) => {
  return {
    title: i18n.t('settings.sectionLinks.coupons'),
    headerLeft: () => (<MobileBackButton onPress={() => {
      NavigationActions.back()
    }} />),
    headerRight: () => (
      <View style={{ flexDirection: 'row' }}>
        <Action
          style={{ paddingRight: 22 }}
          onPress={() =>
            NavigationActions.navigate({
              routeName: 'AdminCouponsUpload',
            })
          }
        >
          <Icons name='ios-cloud-upload' size={22} color={colors.navigation} />
        </Action>
        <Action
          style={{ paddingRight: 22 }}
          onPress={() =>
            NavigationActions.navigate({
              routeName: 'AdminCouponsCreate',
            })
          }
        >
          <Icons name='ios-add' size={36} color={colors.navigation} />
        </Action>
      </View>
    ),
  }
}

const enchanced = compose(
  graphql(couponSchema.queries.getAllCoupons, {
    name: 'getAllCoupons',
    options: (props) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        limit: INITIAL_PAGE_SIZE,
        offset: INITIAL_OFFSET,
      },
    }),
  }),
  graphql(couponSchema.mutations.deleteCoupon, {
    name: 'deleteCoupon',
  }),
  graphql(couponSchema.mutations.disableCoupon, {
    name: 'disableCoupon',
  }),
  graphql(couponSchema.mutations.enableCoupon, {
    name: 'enableCoupon',
  }),
)

export default hoistStatics(enchanced)(CouponsList)

const styles = StyleSheet.create({
  container: { 
    flex: 1, 
    marginBottom: sizes.tabBarHeight
  }
})
