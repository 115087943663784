import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  Platform,
  View,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
} from 'react-native'

import { compose, connect } from '../../config/connected'
import NavigationActions from '../../utility/navigationActions'
import withConversations from '../../containers/withConversations'


import Scroller from '../../components/Scroller'
import PageTitle from '../../components/simple/PageTitle'
import Action from '../../components/simple/Action'
import ProfileImage from '../../components/Post/ProfileImage'
import ListItem from '../../components/simple/ListItem'
import shared from '../../config/styles'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import StatusBar from '../../components/simple/StatusBar'
import ConversationItem from '../../components/ConversationList/ConversationItem'
import BlankMessage from '../../components/simple/BlankMessage'
import MobileBackButton from '../../components/simple/MobileBackButton'
import _ from 'lodash'
import i18n from 'i18n-js'

class ConversationListPortrait extends React.Component {
  state = {
    refreshing: false,
  }

  componentDidUpdate(prevProps) {
    const { conversations, cuid } = this.props
    if (cuid !== prevProps.cuid) {
      conversations.refetch()
    }
  }
  render() {
    // <StatusBar color='white' hideLayout='landscape'/>
    return <View style={styles.container}>{this.conversations()}</View>
  }
  loadMore = () => {}
  refresh = () => {
    // this is just to smooth things
    this.setState({ refreshing: true })
    this.noMore = false
    this.props.conversations.refetch()
    setTimeout(() => {
      this.setState({ refreshing: false })
    }, 800)
  }
  conversations() {
    return Platform.OS === 'web' ? this.renderWeb() : this.renderNative()
  }
  getItems() {
    const items = _.get(this.props.conversations, 'conversationMemberships', [])
    // ignore conversation memberships with no messages in them
    const populated = _.filter(items, (c) => c.conversation.lastMessage)
    return populated
  }

  keyExtractor = (item) => item.id

  renderItem = ({ item }) => {
    const { cuid } = this.props
    return (
      <ConversationItem
        membership={item}
        cuid={cuid}
        onPress={() =>
          this.props.goToConversation({
            id: item.id,
            users: _.map(item.conversation.memberships, 'user'),
            cuid,
          })
        }
        key={item.id}
      />
    )
  }
  renderNative() {
    return (
      <FlatList
        renderItem={this.renderItem}
        keyExtractor={this.keyExtractor}
        data={this.getItems()}
        refreshing={this.state.refreshing}
        onRefresh={this.refresh}
      />
    )
  }
  renderWeb() {
    const { screenInfo } = this.props
    return (
      <Scroller loadMore={this.loadMore}>
        {this.getItems().map((item) => this.renderItem({ item }))}
      </Scroller>
    )
  }
  loading() {
    return (
      <View style={styles.loading}>
        <ActivityIndicator size='large' />
      </View>
    )
  }
}

ConversationListPortrait.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('common.chat'),
    headerRight: () => (
      <Action
        style={{ paddingHorizontal: 15 }}
        onPress={() =>
          NavigationActions.navigate({ routeName: 'NewConversation' })
        }
      >
        <Icons name='md-add' size={30} color={colors.navigation} />
      </Action>
    ),
    headerLeft: () => (
      <MobileBackButton
        label={i18n.t('common.back')}
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

const mapStateToProps = (state) => ({
  // screenInfo: state.screenInfo,
  cuid: state.currentUser.id,
})

const mapDispatchToProps = (dispatch) => ({
  goToConversation: ({ users, cuid, id }) =>
    NavigationActions.navigate({
      routeName: 'Conversation',
      params: { users, cuid, id },
    })
  ,
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withConversations,
)(ConversationListPortrait)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
