import React, { Component } from 'react'
import { connect, compose } from '../../config/connected'
import { Platform, View } from 'react-native'
import NamedImage from '../simple/NamedImage'
import UploadProgress from '../simple/UploadProgress'
import { property } from 'lodash'
import { stylus } from '../../config/styles'
import T from '../T'
import colors from '../../config/colors'
const OS = Platform.OS

const MessageImage = ({ image, key = 'key', screenInfo, upload, pending }) => {
  const { id, name, width, height } = image
  const mediaId = name || id
  const imageIsUploading = (upload && upload.state !== 'complete' ) || pending

  const maxHeight = 540
  let scaledWidth = screenInfo.contentWidth * 0.75
  let scaledHeight = (scaledWidth / width) * height
  if (scaledHeight > maxHeight) {
    const ratio = maxHeight / scaledHeight
    scaledWidth *= ratio
    scaledHeight *= ratio
  }
  return (
    <View style={styles.container}>
      {/* FB:AZ#1698 imageIsUploading && mediaId ? <UploadProgress targetID={mediaId} key={mediaId} /> : null */}
      <NamedImage
        style={[
          styles.image,
          { width: scaledWidth, height: scaledHeight },
        ]}
        width={scaledWidth}
        height={scaledHeight}
        name={mediaId}
        uri={image.uri}
        opacity={imageIsUploading ? 0.5 : undefined}
        crop='entropy'
        resizeMethod='scale'
        resizeMode='contain'
      />
    </View>
  )
}

const mapStateToProps = ({ screenInfo, uploads }, ownProps) => ({
  screenInfo,
  upload: uploads && uploads[ownProps.mediaId]
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(connect(
  mapStateToProps,
  mapDispatchToProps,
))(MessageImage)

const styles = stylus({
  container: {
    marginTop: 6,
  },
  image: {
    borderRadius: 1,
  },
  text: {
    color: colors.text.main,
    fontSize: 14,
  },
})
