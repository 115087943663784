import React from 'react'
import PropTypes from 'prop-types'

const Svg = ({ style = {}, height = '24', width = '24', xml }) => {
  return (
    <img style={style} src={xml} height={height} width={width} />
  )
}

Svg.propTypes = {
  style: PropTypes.object,
  height: PropTypes.string,
  width: PropTypes.string,
  source: PropTypes.string.isRequired
}

export default Svg
