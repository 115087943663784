export default function normalize(arr) {
  let byId = {}
  let allIds = []
  arr.forEach(item => {
    allIds.push(item.id)
    byId[item.id] = item
  })
  return {
    byId,
    allIds
  }
}