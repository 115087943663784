import produce from 'immer'
import algoliasearch from '../../../utility/algolia'
import _ from 'lodash'
import { isArray, isBoolean, isNumber, isObject } from '../../../config/helpers'
import { SEARCH_OBJECT_PAGE_SIZE } from '../../../config/constants'
import rfdc from 'rfdc'
import equal from 'fast-deep-equal'

const clone = rfdc({ proto: false })

const debug = false
const LOAD_PRODUCERS_PRODUCTS = 'load_producers_products'
export const LOAD_MAP_PRODUCERS = 'load_map_producers'

// it was copied gere because import cause crash
const LOAD_SEARCH_PEOPLE = 'load_search_people'

export class ObjectSearchManager {
  constructor(hostRef, options = { pageSizeOverride: SEARCH_OBJECT_PAGE_SIZE }) {
    this.hostRef = hostRef
    this.pageSize = options.pageSizeOverride || SEARCH_OBJECT_PAGE_SIZE
    this.searchManagerType = ''
    this.searchTypeFilters = {}
    this.alwaysAppliedFilters = {}
    this.filterDefinitions = {}
    this.searchTypeSorts = {}
    this.sortDefinitions = {}
    this.indexName = ''
    this.indexes = {}
    this.nonClearableFilters = []
    this.paramTransforms = {
      // tags is a key value pair object. Keys are the tags
      tagFilters: (tags, key, searchParams) => [ Object.keys(tags) ],
      // region is a react native maps region
      aroundLatLng: (region, key, searchParams) => {
        return `${region.latitude}, ${region.longitude}`
      },
      aroundRadius: (floatRadius, key, searchParams) => {
        return Math.round(floatRadius) // must be int for algolia
      }
    }
  }

  exportSearchState = (searchType) => {
    const clientState = this.getState()
    return {
      previousFilters: this.getPreviousFiltersFor(searchType),
      filters: this.getCurrentFiltersFor(searchType),
      previousSort: this.getPreviousSort(),
      sort: this.getCurrentSort(),
      searchTypes: _.get(clientState, this.pathTo('searchTypes'))
    }
  }

  importSearchState = ({ previousFilters, filters, previousSort, sort, searchTypes }) => {
    const nextState = (state, props) => {
      return produce(state, draft => {
        previousFilters && _.set(draft, this.pathTo('previousFilters'), previousFilters)
        filters && _.set(draft,this.pathTo('filters'), filters)
        previousSort && _.set(draft,this.pathTo('previousSort'), previousSort)
        sort && _.set(draft,this.pathTo('sort'), sort)
        searchTypes && _.set(draft,this.pathTo('searchTypes'), searchTypes)
      })
    }
    return this.promisifiedSetState(nextState)
  }

  getState() {
    return this.hostRef.state
  }

  setState(nextState, onSetState) {
    return this.hostRef.setState(nextState, onSetState)
  }

  _initialize = () => {
    const  initedIndex = algoliasearch.initIndex(this.indexName)
    //1. Setup Default Index
    const objectTypeIndexes = this.indexes = { default: initedIndex, sort: {} }
    //2. Setup Sort Indexes
    Object.keys(this.sortDefinitions).forEach((key) => {
      const sort = this.sortDefinitions[key]
      const { replica, direction } = sort
      if (!replica) {
        return // there is no replica for this sort. Based on secondary rules
      }
      const replicaIndexName = `${this.indexName}_${replica}_${direction}`
      const initedReplicaIndex = objectTypeIndexes.sort[key] = algoliasearch.initIndex(replicaIndexName)
    })
  }

  getPrimaryIndex() {
    return this.indexes.default
  }

  getSortIndex(key) {
    return this.indexes.sort[key]
  }

  pathTo(key) {
    return `${this.searchManagerType}.${key}`
  }

  pathToSearchTypeFor(key, searchType) {
    return this.pathTo(`searchTypes.${searchType}.${key}`)
  }

  pathToResultsFor(searchType) {
    return this.pathToSearchTypeFor('results', searchType)
  }

  pathToExecutionsFor(searchType) {
    return this.pathToSearchTypeFor('executions', searchType)
  }

  pathToFilter(key) {
    return this.pathTo(`filters.${key}`)
  }

  promisifiedSetState = (nextState, resolveValueCallback = () => true ) => {
    return new Promise((resolve, reject) => {
      this.setState(nextState, () => resolve(resolveValueCallback()))
    })
  }

  getFilterCountFor = (searchType) => {
    const filters = this.getCurrentFiltersFor(searchType)
    let count = 0
    for (const filterName in filters) {
      if (!this.filterDefinitions[filterName].label) {
        continue // Don't account for non-visible programmatically applied filters like 'region'
      }
      const value = filters[filterName]
      // TODO: Messes with cycle between Map and Search View
      if (filterName === 'query' && !value) {
        continue // Empty query string is not an actual filter
      }
      if (isObject(value)) {
        count += Object.keys(value).length
      } else {
        count += 1
      }
    }
    return count
  }

  getCriteriaCountFor = (searchType) => {
    let filterCount = this.getFilterCountFor(searchType)
    if (this.getCurrentSort()) {
      filterCount++
    }
    return filterCount
  }

  getCurrentFiltersFor = (searchType) => this._getFilters('filters', searchType)

  getPreviousFiltersFor = (searchType) => this._getFilters('previousFilters', searchType)

  _getFilters = (filterContext, searchType) => {
    const clientState = this.getState()
    const filters = _.get(clientState, this.pathTo(filterContext), {})
    return _.pick(filters, this.searchTypeFilters[searchType])
  }

  getFilterCriteriaFor = (searchType) => {
    return _.pick(this.filterDefinitions, this.searchTypeFilters[searchType])
  }

  getSortCriteriaFor = (searchType) => {
    return _.pick(this.sortDefinitions, this.searchTypeSorts[searchType])
  }

  setFilter = (key, value) => {
    const nextState = (state, props) => {
      return produce(state, draft => {
        _.set(draft, this.pathToFilter(key), value)
      })
    }
    return this.promisifiedSetState(nextState)
  }
  unsetFilter = (key) => {
    const nextState = (state, props) => {
      return produce(state, draft => {
        _.unset(draft, this.pathToFilter(key))
      })
    }
    return this.promisifiedSetState(nextState)
  }

  getFilter = (key) => {
    const clientState = this.getState()
    return _.get(clientState, this.pathToFilter(key), undefined)
  }

  setSort = (key) => {
    const nextState = (state, props) => {
      return produce(state, draft => {
        _.set(draft, this.pathTo('sort'), key)
      })
    }
    return this.promisifiedSetState(nextState)
  }

  getCurrentSort = () => this._getSort('sort')

  getPreviousSort = () => this._getSort('previousSort')

  _getSort = (sortContext) => {
    const clientState = this.getState()
    return _.get(clientState, this.pathTo(sortContext), undefined)
  }

  clearCriteriaFor = (searchType) => {
    const filters = this.getCurrentFiltersFor(searchType)
    const nextState = (state, props) => {
      return produce(state, draft => {
        for (const filterName in filters)  {
          if (this.nonClearableFilters.indexOf(filterName) !== -1) {
            continue // skip
          }
          _.unset(draft, this.pathToFilter(filterName))
        }
        _.set(draft, this.pathTo('sort'), '')
      })
    }
    return this.promisifiedSetState(nextState)
  }

  clearResultsFor = (searchType) => {
    const nextState = (state, props) => {
      return produce(state, (draft) => {
        _.set(draft, this.pathToResultsFor(searchType), [])
        _.set(draft, this.pathToSearchTypeFor('loadMore', searchType), true)
        _.set(draft, this.pathToExecutionsFor(searchType), 0)
      })
    }
    return this.promisifiedSetState(nextState)
  }

  clearPreviousCriteria = () => {
    const nextState = (state, props) => {
      return produce(state, draft => {
        _.set(draft, this.pathTo('previousFilters'), null)
        _.set(draft, this.pathTo('previousSort'), null)
      })
    }
    return this.promisifiedSetState(nextState)

  }

  getExecutionsFor(searchType) {
    return _.get(this.getState(), this.pathToExecutionsFor(searchType)) || 0
  }

  revertToPreviousCriteriaFor = (searchType) => {
    const previousFilters = this.getPreviousFiltersFor(searchType)
    const filters = this.getFilterCriteriaFor(searchType)
    const nextState = (state, props) => {
      return produce(state, draft => {
        for (const filterName in filters)  {
          if (this.nonClearableFilters.indexOf(filterName) !== -1) {
            continue // skip
          }
          const previousValue = previousFilters[filterName]
          if (previousValue !== undefined) {
            _.set(draft, this.pathToFilter(filterName), previousValue)
          } else {
            _.unset(draft, this.pathToFilter(filterName))
          }
        }
        const previousSort = this.getPreviousSort()
        _.set(draft, this.pathTo('sort'), previousSort)
      })
    }
    return this.promisifiedSetState(nextState)
  }

  getCriteriaFor = (searchType) => {
    return {
      filters: this.getFilterCriteriaFor(searchType),
      sorts: this.getSortCriteriaFor(searchType),
    }
  }

  getResultsFor = (searchType) => {
    const clientState = this.getState()
    return _.get(clientState, this.pathToResultsFor(searchType), [])
  }

  setResultsFor = (searchType, results) => {
    const nextState = (state, props) => {
      return produce(state, draft => {
        _.set(draft, this.pathToResultsFor(searchType), results)
      })
    }
    return this.promisifiedSetState(nextState)
  }

  loadMoreFor = (searchType) => {
    const clientState = this.getState()
    return _.get(clientState, this.pathToSearchTypeFor('loadMore', searchType), true)
  }

  loadMoreResultsFor = async(searchType, options = {}) => {
    if (searchType === LOAD_SEARCH_PEOPLE && this.getCriteriaCountFor(searchType) === 0) {
      return null
    }

    const { defaultSort, algoliaSearch } = options
    debug && console.log('LOAD MORE RESULTS FOR', searchType)
    const searchChanged = this._searchChangedFor(searchType)
    if (!this.loadMoreFor(searchType) && !searchChanged) {
      debug && console.log('<<<SEARCH - No more results. Returning early.')
      return this.getResultsFor(searchType)
    }
    const filters = this.getCurrentFiltersFor(searchType)
    const currentSort = this.getCurrentSort()
    const searchParams = {}

    searchParams.hitsPerPage = this.pageSize

    //1. Resolve index based on if sort was selected or not
    let index = this.indexes.default
    const sort = currentSort || defaultSort
    if (sort && sort !== 'distanceLow') {
      index = this.indexes.sort[sort]
    }

    // 2. Setup search params
    this._attachFiltersToSearchParams(filters, searchParams)
    this._attachFiltersToSearchParams(this.alwaysAppliedFilters, searchParams)

    searchParams.page = this._getNextPageFor(searchType)
     console.log('<<<PARAMS FOR ALGOLIA SEARCH', searchParams)
    //3. Get hits and update state
    debug && console.log('<<<SEARCH - Search Params', searchParams)
    debug && console.log('<<<SEARCH - Requesting results for', searchType)
    let response = null
    if (algoliaSearch) {
      response = await algoliaSearch(index, searchParams)
    } else {
      response = await index.search(searchParams)
    }
    //console.log('<<<NUMBER OF HITS', response.hits.length)
    const nextState = (state, props) => {
      let draft = clone(state)
      const results = _.get(draft, this.pathToResultsFor(searchType), [])
      const page = this._getNextPageFor(searchType)
      let executions = _.get(draft, this.pathToExecutionsFor(searchType), 0)
      if (searchChanged) {
        //console.log('<<<SEARCH CHANGED - SAVE CHANGES AS PREVIOUS AND RESET EXECUTIONS AND LOAD MORE ')
        const prevFilters = _.get(draft, this.pathTo('filters'))
        const prevSort = _.get(draft, this.pathTo('sort'))
        _.set(draft, this.pathTo('previousFilters'), clone(prevFilters))
        _.set(draft, this.pathTo('previousSort'), clone(prevSort))
        _.set(draft, this.pathToSearchTypeFor('loadMore', searchType), true)
        executions = 0
      }

      let newResults = this._updatePaginatedResultsFor(executions, searchChanged, results, response.hits, page)

      if (newResults !== false) {
        if (this.customSort && !defaultSort) {
          newResults = this.customSort(searchType, newResults)
        }
        _.set(draft, this.pathToResultsFor(searchType), newResults)
      } else {
        _.set(draft, this.pathToSearchTypeFor('loadMore', searchType), false)
      }
      _.set(draft, this.pathToExecutionsFor(searchType), ++executions)
      _.set(draft, `${searchType}.searchParams`, searchParams)
      _.set(draft, `${searchType}.nbHits`, response.nbHits)
      return draft
    }
    return this.promisifiedSetState(nextState, () => {
      const clientState = this.getState()
      return _.get(clientState, this.pathToResultsFor(searchType), [])
    })
  }

  _attachFiltersToSearchParams = (filters, searchParams) => {
    for (const key in filters) {
      const definition = this.filterDefinitions[key]
      let value = filters[key]
      let attach = _.isObject(value) ? !_.isEmpty(value) : true
      // skip empty filter groups
      if (attach) {
        if (this.paramTransforms[definition.param]) {
          value = this.paramTransforms[definition.param](value, searchParams)
        }
        if (definition.param.indexOf('filters.') !== -1) {
          const param = definition.param.split('.')[1]
          value = this._filterTransform(value, param, searchParams)
          if (value) {
            _.set(searchParams, 'filters', value)
          }
        } else if (definition.param === 'query') {
          _.set(searchParams, definition.param, _.trim(value))
        } else if (definition.param === 'tagFilters') {
        let prevVal = _.get(searchParams, definition.param) || []
        prevVal = _.isArray(prevVal) ? prevVal : []
        prevVal = prevVal.filter(i => !_.isEmpty(i))
        if (!_.isEmpty(value)) {
          _.set(searchParams, definition.param, [...prevVal, ...value])
        }
      } else {
          _.set(searchParams, definition.param, value)
        }
      }
    }
  }

  _searchChangedFor = (searchType) => {
    const previousFilters = this.getPreviousFiltersFor(searchType)
    const currentFilters = this.getCurrentFiltersFor(searchType)
    const previousSort = this.getPreviousSort()
    const currentSort = this.getCurrentSort()
    return !equal(previousFilters, currentFilters) || !equal(previousSort, currentSort)
  }

  _getNextPageFor = (searchType) => {
    let page = 0
    const clientState = this.getState()
    const results = _.get(clientState, this.pathToResultsFor(searchType), [])
    // Get next page if the search hasn't changed
    if (!this._searchChangedFor(searchType)) {
      page = Math.floor(results.length / this.pageSize)
    }
    return page
  }

  _updatePaginatedResultsFor = (searchExecutions, searchChanged, currentResults, newResults, page) => {
    //console.log('PAGINATION - DID SEARCH CHANGE?', searchChanged)
    // Always replace results if search has changed or we are still at page 0
    if (page === 0) {
      debug && console.log('<<<SEARCH - Still on page 0')
      if (searchExecutions && !searchChanged) {
        //console.log('<<<PAGINATION - STILL ON PAGE 0 WITH NO CHANGE.')
        return false
      }
      //console.log('<<<PAGINATION - RESULTS FOR PAGE 0')
      return newResults
    } else {
      debug && console.log('<<<SEARCH - Still on page > 0')
      // Only add new results that are not previously loaded
      const uniqueResults = _.differenceBy(newResults, _.takeRight(currentResults, this.pageSize), 'id')
      if (!searchChanged && uniqueResults.length === 0) {
        //console.log('<<<PAGINATION - BEYOND PAGE 1 BUT NO NEW RESULTS')
        return false // no more updates to paginated results since search has been executed multiple times
      }
      //console.log('<<<PAGINGATION - RESULTS FOR PAGE ', page)
      return currentResults.concat(uniqueResults)
    }
  }

  _filterTransform = (value, key, searchParams) => {
    let filters = searchParams.filters || ''
    let filter = ''
    if (isArray(value) && value.length) {
      filter = `(${value.map(item => this._resolveAlgoliaFilter(key, item)).join(' OR ')})`
    } else {
      filter = this._resolveAlgoliaFilter(key, value)
    }
    if (filters) {
      filters += ' AND '
    }
    filters += filter
    return filters
  }

  _resolveAlgoliaFilter = (key, value, operator = this._defaultOp(value)) => {
    let filter = `${key}${operator}`
    if (isNumber(value) || isBoolean(value)) {
      filter += value
    } else {
      filter += `'${value}'`
    }
    return filter
  }

  _defaultOp = (value) => {
    if (isNumber(value) || isBoolean(value)) {
      return '='
    }
    return ':'
  }
}
