import React from 'react'
import { Text, View, ActivityIndicator, Picker, ScrollView } from 'react-native'
import NavigationActions from '../../utility/navigationActions'

import Scroller from '../../components/Scroller'
import MobileBackButton from '../../components/simple/MobileBackButton'
import MyPicker from '../../components/simple/Picker/index'
import TabView from '../../components/TabView'
import SearchComponent from '../../components/Search'
import Button from '../../components/simple/Button'
import Divider from '../../components/simple/Divider'
import UserListItem from './components/UserListItem'
import ShopListItem from './components/ShopListItem'
import ListItem from '../../components/simple/ListItem'
import withActionSheet from '../../containers/ActionSheet/withActionSheet'

import styles from './styles'
import colors from '../../config/colors'

import i18n from 'i18n-js'
import _ from 'lodash'
import produce from 'immer'
import equal from 'fast-deep-equal'

import { compose, connect, gql, graphql } from '../../config/connected'
import userSchema from '../../schema/user'
import adminSchema from '../../schema/admin'
import DashboardReports from './DashboardReports'

import { getObjectStatusTranslation, getNavParam } from '../../config/helpers'
import { OBJECT_STATUSES } from '../../config/constants'
import branch from '../../config/branch'

const IMAGE_SIZE = 50
const INITIAL_PAGE_LIMIT = 10

const initialFetchShopsVariables = {
  searchParams: {
    displayName: '',
    status: 'all',
  },
  page: 1,
  limit: INITIAL_PAGE_LIMIT,
}
const TABS = {
  users: 'Users',
  shops: 'Shops',
  reports: 'Reports',
  misc: 'Misc',
}

@graphql(userSchema.mutations.resetAllTerms, {
  name: 'resetAllTerms',
})
@graphql(userSchema.queries.shopsByAdminSearchParams, {
  name: 'shopsByAdminSearchParams',
  options: (props) => {
    const tab = getNavParam(props, 'tab')
    const shopName = getNavParam(props, 'query')
    if (tab === TABS.shops) {
      initialFetchShopsVariables.searchParams.displayName = shopName
    }
    return {
      variables: initialFetchShopsVariables
    }
  },
})
@graphql(userSchema.queries.usersByUsername, {
  name: 'usersByUsername',
  options: {
    variables: {
      username: '',
    },
  },
})
@withActionSheet
class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    const activeTab = _.get(props, 'navigation.state.params.tab', TABS.users)
    this.state = {
      usernameSearchString: '',
      shopNameSearchString: initialFetchShopsVariables.searchParams.displayName,
      shopStatusSearchFilter: 'all',
      activeTab,
      tabs: {
        [TABS.shops]: {
          page: 1,
          limit: INITIAL_PAGE_LIMIT,
          noMore: false,
          previousSearchParams: initialFetchShopsVariables.searchParams,
          loadMore: this.loadMoreShops,
          loading: false,
          refreshing: false,
          firstLoad: true,
          queryName: 'shopsByAdminSearchParams',
          latestAt: null,
        },
        [TABS.users]: {
          page: 1,
          limit: INITIAL_PAGE_LIMIT,
          noMore: false,
          previousSearchParams: {},
          loadMore: this.refetchUsers,
          loading: false,
          refreshing: false,
          firstLoad: true,
          queryName: 'usersByUsername',
          latestAt: null,
        },
        [TABS.reports]: {},
        [TABS.misc]: {},
      },
    }
    this.lastSubmittedStatus = 'all'
    this.statusPickerOptions = [
      {
        label: getObjectStatusTranslation(OBJECT_STATUSES.active),
        value: OBJECT_STATUSES.active,
      },
      {
        label: getObjectStatusTranslation(OBJECT_STATUSES.inactive),
        value: OBJECT_STATUSES.inactive,
      },
      {
        label: getObjectStatusTranslation(OBJECT_STATUSES.needsReview),
        value: OBJECT_STATUSES.needsReview,
      },
      {
        label: getObjectStatusTranslation(OBJECT_STATUSES.disabled),
        value: OBJECT_STATUSES.disabled,
      },
      {
        label: getObjectStatusTranslation(OBJECT_STATUSES.reported),
        value: OBJECT_STATUSES.reported,
      },
      {
        label: getObjectStatusTranslation(OBJECT_STATUSES.confirmed),
        value: OBJECT_STATUSES.confirmed,
      },
    ]
  }

  componentDidMount() {
    this.refetchUsers()
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('<<<CURRENT SHOP NAME', this.state.shopNameSearchString)
    const currentNavTime = _.get(this.props, 'time')
    const currentNavQuery = _.get(this.props, 'query')
    const currentNavTab = _.get(this.props, 'tab')
    const previousNavTime = _.get(prevProps, 'time')
    if (currentNavTime !== previousNavTime) {
      const nextState = { activeTab: currentNavTab }
      if (currentNavTab === TABS.shops) {
        nextState.shopNameSearchString = currentNavQuery
      }
      if (currentNavTab === TABS.users) {
        nextState.usernameSearchString = currentNavQuery
      }
      this.setState(nextState, () => {
        this.updateActiveTabStateValues({
          noMore: false,
          firstLoad: true,
        })
      })
    }
  }

  setTabViewRef = (ref) => {
    this.tabViewRef = ref
  }

  renderUsers = () => {
    const {
      usersByUsername: { usersByUsername },
    } = this.props

    if (this.isRefreshing()) return <ActivityIndicator />

    if (usersByUsername && !usersByUsername.length) return null
    const returnList = _.map(usersByUsername, (user) => {
      if (!user.username) return
      return <UserListItem user={user} key={user.id} imageSize={IMAGE_SIZE} />
    })
    return returnList
  }

  renderShops = () => {
    const {
      shopsByAdminSearchParams: { shopsByAdminSearchParams },
    } = this.props

    if (this.isRefreshing()) return <ActivityIndicator />

    const returnList = _.map(shopsByAdminSearchParams, (shop) => {
      return <ShopListItem shop={shop} key={shop.id} {...this.props} />
    })

    return returnList
  }

  render() {
    return (
      <View style={styles.container}>
        <Scroller
          loadMore={this.loadMore}
          refreshing={
            this.state.refreshing //|| this.props.something.networkStatus === 4
          }
          onRefresh={this.refresh}
        >
          {this.content()}
        </Scroller>
      </View>
    )
  }

  shopStatusDisplayValue = () => {
    if (this.state.shopStatusSearchFilter === 'all') {
      return i18n.t('common.all')
    }
    return getObjectStatusTranslation(this.state.shopStatusSearchFilter)
  }

  content() {
    const onStatusChange = branch(
      {
        ios: this.onShopStatusChange,
        other: this.onShopStatusSubmit,
      },
      true,
    )
    const initialTab = getNavParam(this.props, 'tab')
    const time = getNavParam(this.props, 'time')
    return (
      <View style={styles.innerContainer}>
        <TabView ref={this.setTabViewRef} style={{ flex: 1 }} onTabPress={this.onTabPress} initialTab={initialTab} time={time}>
          <TabView.Tab label={TABS.users} style={styles.tabBody}>
            {/* <TextInput style={{ width: '100%', height: 20 }} label='test' /> */}
            <Text style={styles.textTitle}>
              {i18n.t('admin.tabs.users.hint')}
            </Text>

            <SearchComponent
              value={this.state.usernameSearchString}
              autoCapitalize='none'
              placeholder={i18n.t('admin.tabs.users.placeholders.search')}
              onChange={(value) => {
                this.handleFieldChange('usernameSearchString', value)
                this.refetchUsers(value)
              }}
              onClear={() => {
                this.handleFieldChange('usernameSearchString', '')
                this.refetchUsers('')
              }}
            />
            {/* <Text>Hi</Text> */}
            {this.renderUsers()}
            <Button
              onPress={this.resetAllTerms}
              label='Ask All Users To Reaccept Terms'
              style={{ backgroundColor: colors.red, marginVertical: 20 }}
            />
          </TabView.Tab>
          <TabView.Tab label={TABS.shops}>
            <Text style={styles.textTitle}>
              {i18n.t('admin.tabs.shops.hint')}
            </Text>
            <View>
              <SearchComponent
                value={this.state.shopNameSearchString}
                autoCapitalize='none'
                placeholder={i18n.t('admin.tabs.shops.placeholders.search')}
                onChange={(value) => {
                  this.handleFieldChange(
                    'shopNameSearchString',
                    value,
                    this.loadMoreShops,
                  )
                }}
                onClear={() => {
                  this.handleFieldChange(
                    'shopNameSearchString',
                    '',
                    this.loadMoreShops,
                  )
                }}
              />
              <View style={styles.picker}>
                <MyPicker
                  label={i18n.t('common.status')}
                  style={styles.picker}
                  selectedValue={this.state.shopStatusSearchFilter}
                  onValueChange={onStatusChange}
                  onCancel={this.onShopStatusCancel}
                  onSubmit={this.onShopStatusSubmit}
                  displayValue={this.shopStatusDisplayValue()}
                >
                  <Picker.Item
                    key={0}
                    label={i18n.t('common.all')}
                    value={'all'}
                  />
                  {this.statusPickerOptions.map((itemProps, index) => {
                    return <Picker.Item key={index + 1} {...itemProps} />
                  })}
                </MyPicker>
              </View>
            </View>
            <Divider hidden />
            {this.renderShops()}
          </TabView.Tab>
          <TabView.Tab label={TABS.reports}>
            <DashboardReports/>
          </TabView.Tab>
          <TabView.Tab label={TABS.misc}>
            <Text style={styles.textTitle}>
              {i18n.t('admin.tabs.misc.hint')}
            </Text>
            <ScrollView style={{ flex: 1 }}>
              <ListItem
                style={{ flex: 1 }}
                right={
                  <Button
                    style={{ backgroundColor: colors.primary, marginRight: 10 }}
                    onPress={() => NavigationActions.navigate({ routeName: 'AdminFilesystemExplorer' })}
                    label={'Open'}
                  />
                }
                title="Filesystem explorer"
              />
              <ListItem
                style={{ flex: 1 }}
                right={
                  <Button
                    style={{ backgroundColor: colors.primary, marginRight: 10 }}
                    onPress={() => NavigationActions.navigate({ routeName: 'AdminDashboardProductRework' })}
                    label={'Open'}
                  />
                }
                title="Product Rework"
              />
            </ScrollView>
          </TabView.Tab>
        </TabView>
      </View>
    )
  }

  onTabPress = (tab) => {
    this.setState({ activeTab: tab })
  }

  updateActiveTabStateValues = (keyValuePairs, onSetState) => {
    const { activeTab } = this.state
    this.setState((prevState) => this.getNextActiveTabState(keyValuePairs, prevState), onSetState)
  }

  getNextActiveTabState(keyValuePairs, prevState) {
    const nextState = produce(prevState, (draft) => {
      for (const key in keyValuePairs) {
        const value = keyValuePairs[key]
        _.set(draft, `tabs.${prevState.activeTab}.${key}`, value)
      }
    })
    return nextState
  }

  loadMore = () => {
    const { tabs, activeTab } = this.state
    const loadMore = _.get(tabs, `${activeTab}.loadMore`, _.identity)
    return loadMore()
  }

  refetchUsers = _.debounce(() => {
    const { usernameSearchString } = this.state
    const { usersByUsername } = this.props

    usersByUsername.refetch({ username: usernameSearchString })
  }, 200)

  loadMoreShops = _.debounce(async () => {
    const {
      shopNameSearchString,
      shopStatusSearchFilter,
      tabs,
      activeTab,
    } = this.state
    const {
      page,
      limit,
      noMore,
      previousSearchParams,
      refreshing,
      queryName,
    } = tabs[activeTab]
    const searchParams = {
      displayName: shopNameSearchString,
      status: shopStatusSearchFilter,
    }
    const replaceResults =
      !equal(searchParams, previousSearchParams) || refreshing

    const requestTime = new Date()

    if ((noMore && !refreshing && !replaceResults)) {
      return
    }

    this.updateActiveTabStateValues({
      loading: true,
      refreshing: replaceResults,
      firstLoad: false,
      previousSearchParams: searchParams,
      latestAt: requestTime,
    })
    const nextPage = replaceResults ? 1 : page + 1
    const variables = { searchParams, page: nextPage, limit }

    return this.props[queryName].fetchMore({
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { tabs, activeTab } = this.state
        const { latestAt } = tabs[activeTab]
        if (requestTime < latestAt) {
          return previousResult
        }
        let previousShops = previousResult[queryName]
        if (replaceResults) {
          previousShops = []
        }
        const fetchedShops = _.get(fetchMoreResult, queryName, [])
        const noMore = !replaceResults && fetchedShops.length === 0
        this.updateActiveTabStateValues({
          noMore,
          loading: false,
          refreshing: false,
        })
        if (noMore) {
          return { [queryName]: previousShops }
        }
        this.updateActiveTabStateValues({ page: nextPage })
        const newShops = _.differenceBy(fetchedShops, previousShops, 'id')

        return {
          [queryName]: previousShops.concat(newShops),
        }
      },
    })
  }, 200)

  isRefreshing = () => {
    const { tabs, activeTab } = this.state
    const { queryName, firstLoad, refreshing } = tabs[activeTab]
    return (firstLoad && this.props[queryName].loading) || refreshing
  }

  handleFieldChange = (target, value, onSetState = _.identity) => {
    this.setState({ [target]: value }, onSetState)
  }

  resetAllTerms = async () => {
    await this.props.resetAllTerms()
    this.refetchUsers()
  }

  onShopStatusChange = (status, onSetState = _.identity) => {
    this.setState({ shopStatusSearchFilter: status }, onSetState)
  }

  onShopStatusCancel = () => {
    this.onShopStatusChange(this.lastSubmittedStatus)
  }

  onShopStatusSubmit = (status) => {
    this.lastSubmittedStatus = status
    this.onShopStatusChange(status, this.loadMoreShops)
  }

  refresh = () => {
    const { tabs, activeTab } = this.state
    const { refreshing } = tabs[activeTab]
    if (refreshing) {
      return
    }
    this.updateActiveTabStateValues(
      {
        refreshing: true,
      },
      async () => {
        await this.loadMore()
        this.updateActiveTabStateValues({ refreshing: false })
      },
    )
  }
}

Dashboard.navigationOptions = (props) => {
  return {
    headerTitle: 'Admin Dashboard',
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

export default Dashboard
