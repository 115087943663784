import React from 'react'
import { View, FlatList } from 'react-native'

import NavigationActions from '../../utility/navigationActions'
import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import _ from 'lodash'
import CarouselItem from './CarouselItem'
import CarouselItemVertical from './CarouselItemVertical'
import {
  connect,
  compose,
  _get,
} from '../../config/connected'
import {AddToCartConsumer} from '../../containers/AddToCart'

class ProductCarousel extends React.Component {

  renderItems = (item) => {
    const { screenInfo, showPrice, showTitle, showShop, showAddToCart, imageStyles } = this.props
    const width = screenInfo.contentWidth * 0.55
    const itemProps = { showPrice, showTitle, showShop, width, imageStyles, showAddToCart, item }
    return  <AddToCartConsumer key={item.id}>
      {(onAddToCart) => {
        return <CarouselItem {...itemProps} onAddToCart={onAddToCart} />
      }}

    </AddToCartConsumer>
  }
  renderItemsLarge = (item, index) => {
    const {
      showPrice,
      showTitle,
      showShop,
      shopName,
      textStyles,
      imageStyles,
      hasBorder,
    } = this.props
    const showInfo = { showPrice, showTitle, showShop }
    const { screenInfo, imageSize } = this.props
    if (item.special) {
      return item.element
    }
    return (
      <CarouselItemVertical
        containerStyle={index === 0 ? {marginLeft: 10} : {marginLeft: 5}}
        key={item.id}
        item={item}
        {...showInfo}
        shopName={_get(item, 'profile.displayName', shopName)}
        textStyles={textStyles}
        imageStyles={imageStyles}
        imageSize={imageSize}
        hasBorder={hasBorder}
      />
    )
  }

  render() {
    const { items, size, style, contentContainerStyle, ...rest } = this.props
    if (!items || items.length === 0) {
      return null
    }
    return (
      <View style={[styles.container, style]}>
        <FlatList
          {...rest}
          data={items}
          showsHorizontalScrollIndicator={false}
          renderItem={({item, index}) => size === 'small' ? this.renderItems(item) : this.renderItemsLarge(item, index)}
          horizontal={true}
          initialNumToRender={4}
          style={[
            styles.contentContainerStyle,
            contentContainerStyle,
            styles.carousel
          ]}
          keyExtractor={(item) => item.id}
        />
      </View>
    )
  }
}

const styles = stylus({
  container: {
    flex: 1,
  },
  carousel: {
    flex: 1,
    flexDirection: 'row',
  },
  contentContainerStyle: {
    paddingVertical: 6,
  },
  itemTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    color: colors.text.main,
  },
})

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({
  goToProduct: (id) => NavigationActions.navigate({ routeName: 'Product', params: { id } }),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ProductCarousel)
