import React from 'react'
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from 'react-native'

import NamedImage from '../../components/simple/NamedImage'
import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'
import * as ImagePicker from 'expo-image-picker'
import uploadImage from '../../utility/uploadImage'

import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { compose } from 'recompose'
import gql from 'graphql-tag'

class CoverPhoto extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { dimensions } = this.props
    return (
      <TouchableOpacity
        onPress={this.onPress}
        style={[styles.container, dimensions]}
      >
        {this.image()}
      </TouchableOpacity>
    )
  }
  image() {
    const { coverPhoto, loading, layout, dimensions } = this.props
    const { uploading, saving, localImage } = this.state
    if (uploading || saving) {
      return (
        <Image
          style={[styles.image, dimensions]}
          resizeMode='cover'
          source={{ uri: localImage }}
        >
          <ActivityIndicator size='large' />
        </Image>
      )
    } else {
      if (coverPhoto) {
        return (
          <NamedImage
            style={[styles.image, dimensions]}
            resizeMode='cover'
            name={coverPhoto}
            width={dimensions.width}
            height={dimensions.height}
            loading={loading}
            fit='crop'
            crop='entropy'
          />
        )
      } else {
        return null
      }
    }
  }
  
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  // goBack: () => NavigationActions.back(),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CoverPhoto)

const styles = stylus({
  container: {
    backgroundColor: colors.emptyArea,
  },
  image: {
    paddingTop: 40,
    flex: 1,
    landscape: {
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      borderBottomRightRadius: 1,
      borderBottomLeftRadius: 1,
    },
  },
})
