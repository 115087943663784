import React, { useCallback } from 'react'
import { FlatList, SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import ElegantInput from '../../components/simple/ElegantInput'
import i18n from 'i18n-js'
import _ from 'lodash'
import { useFormik } from 'formik'
import { AUTOCOMPLETE_URL } from '../../config/constants'
import useAxios from 'axios-hooks'
import environment from '../../config/environment'
import PropTypes from 'prop-types'
import Icons from '@expo/vector-icons/Ionicons'

function Item({ description, place_id, onPress }) {
  return (
    <TouchableOpacity onPress={() => {
      onPress(place_id)
    }}>
      <View style={styles.item}>
        <Text style={styles.title}>{description}</Text>
      </View>
    </TouchableOpacity>
  )
}

const params = {
  key: environment.google.geoKey,
  language: 'en',
  // components: this.props.components.join('|'),
}

const PickAddress = ({ onSubmit, onClose }) => {

  const [{ data, loading }, execute] = useAxios({
    url: AUTOCOMPLETE_URL,
    method: 'GET',
  }, { manual: true })

  const getData = useCallback(_.debounce((values) => {
    execute({
      params: {
        ...params,
        input: values.address,
      },
    })
  }, 1000), [])

  const formik = useFormik({
    initialValues: {
      address: '',
    },
    validate: (values) => {
      getData(values)
      return {}
    },
  })

  // console.log('\n\n\n\n\n\n', JSON.stringify(data))
  // if (error) {
  //   console.warn(error)
  // }

  const predictions = _.get(data, 'predictions')

  return <SafeAreaView style={styles.container}>
    <TouchableOpacity style={styles.iconContainer} onPress={onClose}>
      <Icons
        style={styles.closeIcon}
        name='ios-close'
        size={50}
      />
    </TouchableOpacity>
    <View style={{ paddingHorizontal: 10, flex: 1 }}>
      <ElegantInput
        key={'address'}
        style={styles.input}
        label={i18n.t('onboardingUser.address.title')}
        returnKeyType='done'
        onChange={formik.handleChange('address')}
        value={formik.values['address']}
        autoCorrect={false}
      />
      <FlatList
        style={{ flex: 1 }}
        refreshing={loading}
        data={predictions || []}
        renderItem={({ item }) => <Item {...item} onPress={onSubmit}/>}
        keyExtractor={item => item.id}
        ListFooterComponent={() => {
          if (predictions && _.isEmpty(predictions)) {
            return <Text style={{ textAlign: 'center', marginTop: 14, fontSize: 20 }}>Nothing found</Text>
          }
          return <></>
        }}
      />
    </View>
  </SafeAreaView>
}

PickAddress.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  iconContainer: {
    width: 50,
    height: 50,
    paddingLeft: 14,
    justifyContent: 'center',
  },
  item: {
    padding: 5,
    marginVertical: 2,
    marginHorizontal: 2,
  },
  title: {
    fontSize: 15,
  },
})


export default PickAddress
