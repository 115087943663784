import React, { useState, useEffect } from 'react'
import { StyleSheet, View, Text, KeyboardAvoidingView, Platform } from 'react-native'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import { compose } from 'recompose'
import TextTicker from 'react-native-text-ticker'

import colors from '../../config/colors'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'
import Modal from '../../components/simple/Modal'
import ElegantInput from '../../components/simple/ElegantInput'
import Button from '../../components/simple/Button'

const CreateNotice = ({ formIsVisible, onClose, onSubmit, maxMessageLength = 160 }) => {
  const [name, setName] = useState('')
  const [italian, setItalian] = useState('')
  const [english, setEnglish] = useState('')
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState('form')

  useEffect(() => {
    setLoading(false)
  }, [formIsVisible])

  function handleCloseForm() {
    setName('')
    setItalian('')
    setEnglish('')
    setShow('form')
    onClose()
  }

  async function handleSubmit() {
    setLoading(true)

    const data ={
      name,
      message: {
        it: italian,
        en: english,
      },
    }

    const success = await onSubmit(data)

    if (success) {
      handleCloseForm()      
    }

    setLoading(false)
  }

  return (
    <Modal
      animationType='slide'
      visible={formIsVisible}
      onRequestClose={handleCloseForm}
    >
      <KeyboardAvoidingView
        style={styles.modalContainer}
        behavior={Platform.OS === 'android' ? undefined : 'padding'}      
      >
        <Text style={styles.modalTitle}>{i18n.t('notice.create')}</Text>
        {show === 'form' && (
          <>
            <ElegantInput
              label={i18n.t('notice.name')}
              value={name}
              onChange={setName}
            />
            <ElegantInput
              label={i18n.t('notice.message.it')}
              value={italian}
              onChange={setItalian}
              multiline
              maxLength={maxMessageLength}
              characterRestriction={maxMessageLength}
            />
            <ElegantInput
              label={i18n.t('notice.message.en')}
              value={english}
              onChange={setEnglish}
              multiline
              maxLength={maxMessageLength}
              characterRestriction={maxMessageLength}
            />

            <View style={styles.modalButtonContainer}>
              <Button
                style={styles.modalButtonCancel}
                label={i18n.t('common.cancel')}
                onPress={handleCloseForm}
              />
              <Button
                style={styles.modalButtonConfirm}
                label={i18n.t('common.continue')}
                onPress={() => setShow('confirmation')}
                disabled={!name || !italian || !english}
                showLoader={loading}
              />
            </View>
          </>
        )}
        {show === 'confirmation' && (
          <>
            <Text style={styles.messageTitle}>{i18n.t('notice.message.it')}</Text>
            <View style={styles.messageContainer}>
              <TextTicker
                style={styles.message}
                loop
                repeatSpacer={100}
              >
                {italian}
              </TextTicker>
            </View>

            <Text style={styles.messageTitle}>{i18n.t('notice.message.en')}</Text>
            <View style={styles.messageContainer}>
              <TextTicker
                style={styles.message}
                loop
                repeatSpacer={100}
              >
                {english}
              </TextTicker>
            </View>

            <Text style={[styles.modalTitle, { marginTop: 30 }]}>{i18n.t('notice.confirmation')}</Text>

            <View style={styles.modalButtonContainer}>
              <Button
                style={styles.modalButtonCancel}
                label={i18n.t('common.back')}
                onPress={() => {
                  if (!loading) {
                    setShow('form')
                  }
                }}
              />
              <Button
                style={styles.modalButtonConfirm}
                label={i18n.t('common.send')}
                onPress={handleSubmit}
                disabled={loading}
                showLoader={loading}
              />
            </View>

          </>
        )}
      </KeyboardAvoidingView>
    </Modal>
  )
}

CreateNotice.propTypes = {
  formIsVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  maxMessageLength: PropTypes.number,
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 15,
  },
  modalTitle: {
    fontSize: 16,
    color: colors.text.main,
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 20,
  },
  modalButtonContainer: {
    marginTop: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalButtonCancel: {
    backgroundColor: colors.red,
    width: '48%',
  },
  modalButtonConfirm: {
    backgroundColor: colors.primary,
    width: '48%',
  },
  messageTitle: {
    fontSize: 14,
    marginTop: 15,
    marginBottom: 5,
  },
  message: {
    color: '#fff',
  },
  messageContainer: {
    height: 30,
    width: '100%',
    backgroundColor: colors.red,
    flexDirection: 'row',
    alignItems: 'center',

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
})

const enhanced = compose(WithKeyboardInfo)

export default compose(enhanced)(CreateNotice)
