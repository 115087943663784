import React from 'react'
import { View, Text } from 'react-native'
import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import T from '../../components/T'
import TextInput from '../../components/simple/TextInput'
import Divider from '../../components/simple/Divider'
import Checkbox from '../../components/simple/Checkbox'
import TagsInput from '../../components/TagsInput'
import {
  CATEGORIES_PRODUCER,
  CATEGORIES_PROFESSIONAL,
} from '../../config/constants'
import _ from 'lodash'
import i18n from 'i18n-js'

export default AddInterests

function AddInterests({ producerInterests, productInterests, onChange }) {
  return (
    <View style={styles.container}>
      <T style={styles.title} t='onboardingUser.interests.title' />
      <T style={styles.subtext} t='onboardingUser.interests.text' />
      {/* <T style={styles.subtitle} t='onboardingUser.interests.headerProducers' />
      {renderProducerInterestsTagsInput(onChange, producerInterests)}
      <Divider /> */}
      {/* <T style={styles.subtitle} t='onboardingUser.interests.headerProducts' /> */}
      {renderProductInterestsTagsInput(onChange, productInterests)}
    </View>
  )
}

AddInterests.name = 'AddInterests'

function renderProducerInterestsTagsInput(onChange, producerInterests) {
  const categories = [...CATEGORIES_PRODUCER]
  const onTagPress = (index, targetField) => {
    let data
    data = producerInterests.slice()

    data.splice(index, 1)
    onChange(data, targetField)
  }
  const tags = _.map(categories, (value) => {
    return { value, label: i18n.t(`categories.producer.${value}`) }
  })

  return (
    <TagsInput
      // error={errors.categories}
      tags={tags}
      chosen={producerInterests}
      placeholder={i18n.t('shop.add.placeholders.tagsCategories')}
      label={i18n.t('shop.add.fields.categories') + ': '}
      onTagsChange={(value) => {
        onChange(value, 'producerInterests')
      }}
      onTagPress={(idx) => onTagPress(idx, 'producerInterests')}
    />
  )
}

function getCategoryTagLabel(key) {
  if (CATEGORIES_PRODUCER.has(key)) {
    return 'producer'
  } else {
    return 'professional'
  }
}

function renderProductInterestsTagsInput(onChange, productInterests) {
  const categories = [...CATEGORIES_PRODUCER, ...CATEGORIES_PROFESSIONAL]
  const onTagPress = (index, targetField) => {
    let data
    data = productInterests.slice()
    data.splice(index, 1)
    onChange(data, targetField)
  }
  const tags = _.map(categories, (value) => {
    return {
      value,
      label: i18n.t(`categories.${getCategoryTagLabel(value)}.${value}`),
    }
  })

  return (
    <TagsInput
      // error={errors.categories}
      tags={tags}
      chosen={productInterests}
      placeholder={i18n.t('shop.add.placeholders.tagsCategories')}
      label={i18n.t('shop.add.fields.categories') + ': '}
      onTagsChange={(value) => {
        onChange(value, 'productInterests')
      }}
      onTagPress={(idx) => onTagPress(idx, 'productInterests')}
    />
  )
}

const styles = stylus({
  container: {
    // alignItems: 'center',
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    // marginTop: 36,
    marginBottom: 10,
    textAlign: 'center',
    color: colors.text.main,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
    color: colors.text.main,
  },
  subtext: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '300',
    color: colors.text.secondary,
    marginBottom: 20,
  },
  interestItem: {
    paddingVertical: 2,
    color: colors.text.main,
  },
  labelStyle: {
    flex: 1,
    fontSize: 16,
  },
})
