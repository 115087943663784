import React, { Component } from 'react'
import { Text } from 'react-native'
import _ from 'lodash'
// import { withNavigation } from '@react-navigation/core'
import { connect } from '../../config/connected'
import colors from '../../config/colors'
import NavigationActions from '../../utility/navigationActions'
import PropTypes from 'prop-types'

import { isArray } from '../../config/helpers'
import { ANALYTICS_TAG_TAPPED } from '../../reducers/analytics'
import i18n from 'i18n-js'

const mapState = () => ({})
const mapDispatch = (dispatch) => ({
  analyticsTagTapped: (data) => {
    return dispatch({
      type: ANALYTICS_TAG_TAPPED,
      data,
    })
  },
  goToProduct: (id) => NavigationActions.navigate({ routeName: 'Product', params: { id } }),
  goToShop: (id, pageTitle) => NavigationActions.navigate({ routeName: 'Shop', params: { id, pageTitle } }),
  goToProfile: (id, pageTitle) => NavigationActions.navigate({ routeName: 'Profile', params: { id, pageTitle } }),
})

// @[I food you | Cheese]
// @flavio

@connect(
  mapState,
  mapDispatch,
)
class TagText extends Component {
  parseTags = (str) => {
    const objects = []
    // const tagRegExp = /(@[a-z0-9._\u00C0-\u017F]+)|(:?(@\[[a-z0-9. _\u00C0-\u017F]+)(?: \| )?(?:([a-z0-9 \u00C0-\u017F]+\])))/im

    const tagRegExp = /(@[a-z0-9$_&\-+()/*"'“”‘’/\\:;!?.,~|•√π÷×¶∆}{=°^¥€¢£%©®™✓><\u00C0-\u017F]+)|(:?(@\[[a-z0-9$_&\-+()/*"'“”‘’/\\:;!?.,~|•√π÷×¶∆}{=°^¥€¢£%©®™✓>< \u00C0-\u017F]+)(?: \| )?(?:([a-z0-9$_&\-+()/*"'“”‘’/\\:;!?.,~|•√π÷×¶∆}{=°^¥€¢£%©®™✓>< \u00C0-\u017F]+\])))/im

    while (str) {
      const exec = tagRegExp.exec(str)

      const [match, user, product] = exec || []

      if (exec === null) {
        objects.push({ type: 'text', text: str })
        break
      } else {
        const textPrecedingTag = str.substring(0, exec.index)

        if (textPrecedingTag) {
          objects.push({ type: 'text', text: textPrecedingTag })
        }

        const tagObj = { type: 'tag', text: match }
        if (user) tagObj.user = user
        if (product)
          tagObj.product = product.replace(/^\[/, '').replace(/\]$/, '')

        objects.push(tagObj)
      }

      const start = exec.index + match.length
      const next = str.substring(start)

      str = next
    }

    return objects
  }

  getUserId = (tagString) => {
    const { content } = this.props
    // const newString = tagString.replace('@', '')
    const newString = tagString
    let tags = []

    if (content.tags) {
      tags = _.forEach(content.tags, (tagItem) => {
        if (tagItem.value.includes(newString)) {
          return tagItem.userId
        }
      })
    }
    return tags
  }

  renderText = () => {
    let str = _.trim(this.props.content.value)
    const parsedContent = this.parseTags(str)

    return _.map(parsedContent, (item, idx) => {
      let tags = _.get(this, 'props.content.tags', [])
      if (!isArray(tags)) {
        tags = []
      }

      let tagData = tags.find((tag) => {
        return item.text === tag.value
      })

      let userId, productId, isShop, userDeleted, pageTitle

      if (tagData) {
        userId = tagData.userId
        productId = tagData.productId
        isShop = item.text.includes('[')
        userDeleted = tagData.userDeleted
        pageTitle = tagData.user || ''
      }

      if (item.type === 'tag' && userId && tagData.userDeleted === true) {
        return (
          <Text
            key={idx}
            style={{
              color: colors.disabled,
            }}
          >
            @{i18n.t('common.anonymous')}
          </Text>
        )
      } else if (item.type === 'tag' && userId) {
        return (
          <Text
            key={idx}
            style={{
              color: colors.taggingText,
              // textDecorationLine: 'underline',
              // fontWeight: 'bold',
            }}
            onPress={() => {
              this.handleTagPress(userId, productId, isShop, pageTitle)
            }}
          >
            {item.text}
          </Text>
        )
      } else {
        return <Text key={idx}>{item.text}</Text>
      }
    })
  }

  handleTagPress = (userId, productId, isShop, pageTitle) => {
    const { goToProduct, goToShop, goToProfile } = this.props
    this.props.analyticsTagTapped({ userId, productId, isShop })
    if (productId) {
      goToProduct(productId)
    } else if (isShop && !productId) {
      goToShop(userId, pageTitle)
    } else {
      goToProfile(userId, pageTitle)
    }
  }

  render() {
    const {
      style,
      content,
      goToProduct,
      goToShop,
      goToProfile,
      ...textProps
    } = this.props

    if (!content || !content.value) {
      return null
    }

    return (
      <Text {...textProps} style={style}>
        {this.renderText()}
      </Text>
    )
  }
}

TagText.propTypes = {
  analyticsTagTapped: PropTypes.func,
}

export default TagText
