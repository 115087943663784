import {
  gql,
} from '../config/connected'

const address = `
  address1
  address2
  city
  region
  zip
  country
`

const userFields = `
  id
  phone
  displayName
  username
  email
  profileImage
  address {
    ${address}
  }
  isAdmin
  unseenBadge {
    unseenNotifications
  }
  acceptedTerms
  acceptedTermsAt
`

const personDetails = `
  id
  firstName
  lastName
  bio
  interests
`
export default {
  createNewUser: gql`
  mutation($email: String!, $phone: String!, $username: String!, $password: String, $locale: String, $profileImage: String, $coverPhoto: String, $displayName: String!, $isProducer: Boolean, $isFoodPro: Boolean, $verificationData: Json!, $address1: String, $address2: String, $city: String, $region: String, $zip: String, $country: String, $firstName: String, $lastName: String, $interests: Json, $inviterId: String){
    createNewUser(email: $email, phone: $phone, username: $username, password: $password, locale: $locale, profileImage: $profileImage, coverPhoto: $coverPhoto, displayName: $displayName, isProducer: $isProducer, isFoodPro: $isFoodPro, verificationData: $verificationData, address1: $address1, address2: $address2, city: $city, region: $region, zip: $zip, country: $country, firstName: $firstName, lastName: $lastName, interests: $interests, inviterId: $inviterId){
      ${userFields}
    }
  }`,
  userByAlias: gql`
  query($alias: String!){
    userByAlias(alias: $alias){
      ${userFields}
    }
  }
  `,
  userByPhone: gql`
    query($phone: String!) {
      userByPhone(phone: $phone){
        ${userFields}
      }
    }
  `,
  userByEmail: gql`
  query($email: String!){
    userByEmail(email: $email){
      ${userFields}
    }
  }`,
  suggestUsername: gql`
    mutation($firstName: String!, $lastName: String!) {
      suggestUsername(firstName: $firstName, lastName: $lastName)
    }
  `,
  signupByPhone: gql`
    mutation($phone: String!) {
      signupByPhone(phone: $phone)
    }
  `,
  requestLoginCode: gql`
    mutation($phone: String!) {
      requestLoginCode(phone: $phone)
    }
  `,
  validateSignUpPhone: gql`
    mutation($phone: String!) {
      validateSignUpPhone(phone: $phone)
    }
  `,
  createVerification: gql`
    mutation($phone: String!, $locale: String, $forExistingUser: Boolean!) {
      createVerification(phone: $phone, locale: $locale, forExistingUser: $forExistingUser) {
        id
        code
        seed
        phone
      }
    }
  `,
  validateVerification: gql`
    mutation($phone: String!, $code: String!) {
      validateVerification(phone: $phone, code: $code) {
        id
        code
        seed
        phone
      }
    }
  `,
  verifyPhone: gql`
    mutation($phone: String!, $code: String!, $seed: String!) {
      verifyPhone(phone: $phone, code: $code, seed: $seed) {
        ${userFields}
        token
      }
    }
  `,
  login: gql`
    mutation($phone: String!, $code: String!, $seed: String!) {
      login(phone: $phone, code: $code, seed: $seed) {
        ${userFields}
        token
      }
    }
  `,
  updateNameAndEmail: gql`
    mutation($firstName: String!, $lastName: String!, $email: String!) {
      updateNameAndEmail(firstName: $firstName, lastName: $lastName, email: $email) {
        ${userFields}
      }
    }
  `,
  updateProfileImage: gql`
    mutation($name: String!) {
      updateProfileImage(name: $name) {
        ${userFields}
      }
    }
  `,
  updateAddress: gql`
    mutation($address1: String, $address2: String, $city: String, $region: String, $zip: String, $country: String!) {
      updateAddress(address1: $address1, address2: $address2, city: $city, region: $region, zip: $zip, country: $country) {
        ${userFields}
      }
    }
  `,
  updateUsername: gql`
    mutation($username: String!) {
      updateUsername(username: $username) {
        ${userFields}
      }
    }
  `,
  updateInterests: gql`
    mutation($interests: Json) {
      updateInterests(interests: $interests) {
        ${personDetails}
      }
    }
  `,
  updateUserTypes: gql`
    mutation($isProducer: Boolean!, $isFoodPro: Boolean!) {
      updateUserTypes(isProducer: $isProducer, isFoodPro: $isFoodPro) {
        ${userFields}
      }
    }
  `,
  finishOnboarding: gql`
    mutation($na: Boolean) {
      finishOnboarding(na: $na) {
        na
      }
    }
  `,
}
