import React, { Component } from 'react'
import { View } from 'react-native'
import PostHeader from './PostHeader'
import PostActionBar from './PostActionBar'
import PostComments from './PostComments'
import PostLikes from './PostLikes'
import ImageCarousel from '../ImageCarousel'
import ProductCarousel from '../ProductCarousel'
import PropTypes from 'prop-types'
import NavigationActions from '../../utility/navigationActions'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import sharedStyles, { stylus } from '../../config/styles'

import _ from 'lodash'
import {
  connect,
  compose,
} from '../../config/connected'
import { getPendingContent } from '../../config/helpers'

class PostFB extends Component {
  static propTypes = {
    comments: PropTypes.shape({
      comments: PropTypes.any,
      loading: PropTypes.bool
    }),
    post: PropTypes.shape({
      story: PropTypes.object,
      id: PropTypes.string,
      likeCount: PropTypes.number,
      single: PropTypes.bool,
      user: PropTypes.shape({
        username: PropTypes.string.isRequired,
        profileImage: PropTypes.string,
        title: PropTypes.string,
      }),
    }).isRequired,
    goToProduct: PropTypes.func.isRequired,
    goToComments: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      id: PropTypes.string,
    }),
  }

  goToComments = ({ autoFocus }) => {
    const { goToComments, post } = this.props
    goToComments({ id: post.id, autoFocus })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)) {
      return true
    }
    return false
  }

  renderPosts = (uploads) => {
    const { goToProduct, single, comments, post, pendingPostEdit } = this.props
    const { user, topComments = [], products, taggedProducts = [] } = post
    let taggedItems = []
    if (taggedProducts.length > 0) {
      taggedItems = (_.uniqBy(taggedProducts, 'id'))
    } else {
      taggedItems = (_.uniqBy(products, 'id'))
    }

    let firstComment = null
    if (post.story) {
      firstComment = {
        content: post.story,
        createdAt: post.createdAt,
        user: post.user,
        id: post.id,
      }
    } else if (taggedProducts.length > 0) {
      firstComment = {
        content: { tags: [], value: '' },
        createdAt: post.createdAt,
        user: post.user,
        id: post.id,
      }
    }

    return <View style={styles.container}>
      <PostHeader
        post={post}
        style={styles.marginVertical}
        profileSize={32}
        showAge
        showMenu
        showTitle
        pendingPostEdit={pendingPostEdit}
      />
      <ImageCarousel
        pullup
        navigation={this.props.navigation}
        images={uploads}
        pendingRefresh={!!pendingPostEdit}
        activityIndicatorOverlayEnabled={false}
      />
      <ProductCarousel
        size='small'
        items={taggedItems}
        showShop
        showPrice
        showAddToCart
        goToProduct={goToProduct}
        style={styles.productCarouselContainer}
        contentContainerStyle={styles.productCarousel}
      />
      {taggedItems.length === 0 && <View style={styles.productVoid} />}
      <PostActionBar
        goToComments={() => this.goToComments({ scrollToComments: true })}
        post={post}
      />
      <PostLikes post={post} />
      <PostComments
        firstComment={firstComment}
        comments={
          single ? comments : { loading: false, comments: topComments }
        }
        commentCount={post.commentCount}
        goToComments={this.goToComments}
        showAdvHashtag={taggedProducts.length > 0}
        single={single}
      />
      {single ? null : <View style={sharedStyles.separator} />}
    </View>
  }

  render() {
    const { post, pendingPostEdit } = this.props
    const pendingUploads = _.get(pendingPostEdit, 'payload.uploads')
    const finalUploads = pendingUploads || post.uploads
    return this.renderPosts(finalUploads)
  }
}

const styles = stylus({
  container: {},
  body: {
    paddingHorizontal: 8,
  },
  marginVertical: {
    marginTop: 6,
    marginBottom: 10
  },
  productCarousel: {
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 10,
  },
  productCarouselContainer: {
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
    marginBottom: 7
  },
  productVoid: {
    height: 10,
  },
})

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.currentUser,
    screenInfo: state.screenInfo,
    pendingPostEdit: getPendingContent(state.publishContent, 'post', ownProps.post.id, 'payload.id')
  }
}

const mapDispatchToProps = (dispatch) => ({
  goToProduct: (id) => NavigationActions.navigate({ routeName: 'Product', params: { id } }),
  goToComments: ({
    id,
    newComment,
    autoFocus = false,
    scrollToComments = false,
  }) =>
    NavigationActions.navigate({
      routeName: 'PostComments',
      params: { id, newComment, autoFocus, scrollToComments },
    })
  ,
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PostFB)
