export const ADDRESS_SET_SHIPPING = 'address/SET_SHIPPING'
export const ADDRESS_SET_BILLING = 'address/SET_BILLING'


export default function address(state = {}, action) {
  switch (action.type) {
    case ADDRESS_SET_BILLING:
      return { ...state, billing: action.address }
    case ADDRESS_SET_SHIPPING:
      return { ...state, shipping: action.address }
    default:
      return state
  }
}
