import Review from '../../screens/Review'
import { ROUTE_USER_OBJECT_TYPE_REVIEW } from '../../config/constants'

export const reviewRoutes = {
  [ROUTE_USER_OBJECT_TYPE_REVIEW]: {
    screen: Review,
    path: 'review/:userId/:objectType/:objectId',
    title: '',
    
  },
}
