import React from 'react'
import { ScrollView, Image, TouchableOpacity, ActivityIndicator } from 'react-native'
import Icons from '@expo/vector-icons/Ionicons'
import ImageViewer from 'react-native-image-zoom-viewer'

import PropTypes from 'prop-types'
import { connect } from '../../config/connected'
import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'
import nameToUrl from '../../config/nameToUrl'
import _ from 'lodash'
import NamedImage from '../../components/simple/NamedImage'
import Modal from '../../components/simple/Modal'
import ActivityIndicatorOverlay from '../../components/simple/ActivityIndicatorOverlay'

const aspectRatio = 1.618

class Awards extends React.Component {
  static propTypes = {
    scale: PropTypes.number,
    images: PropTypes.array,
    contentWidth: PropTypes.number,
    onLayout: PropTypes.func,
    pendingRefresh: PropTypes.bool,
  }
  static defaultProps = {
    scale: 0.75,
    pendingEdit: false,
  }
  state = {
    showImageViewer: false,
    modalImageIdx: 0
  }
  getImageStyle = (idx, length) => {
    const start = idx === 0,
      end = idx === length - 1
    switch (true) {
      case start:
        return styles.imageStart
      case end:
        return styles.imageEnd
      default:
        return styles.image
    }
  }
  renderImages = () => {
    const { images, pendingRefresh } = this.props
    if (!images || images.length === 0) {
      return null
    }
    // const adjustedWidth = contentWidth * scale
    return _.map(images, (image, idx) => {
      const { width, height } = this.getRatio(image)
      const style = { height, width }
      return (
        <ActivityIndicatorOverlay animating={pendingRefresh} key={idx}>
          <TouchableOpacity
            onPress={() => this.openModalImageViewer(idx)}
          >
            <NamedImage
              height={height}
              width={width}
              name={image.name}
              style={[this.getImageStyle(idx, images.length), style]}
              contentContainerStyle={styles.contentContainer}
              fit='contain'
              resizeMode='cover'
              resizeMethod='scale'
              opacity={pendingRefresh ? 0.5 : 1}
            />
          </TouchableOpacity>
        </ActivityIndicatorOverlay>
      )
    })
  }

  getRatio = img => {
    const { contentWidth } = this.props
    const niceHeight = contentWidth / 2.5

    let aspect = img.width / img.height
    let width = niceHeight * aspect
    let height = niceHeight
    if (width > contentWidth / 2) {
      const ratio = contentWidth / 2 / width
      width = width * ratio
      height = height * ratio
    }
    return { width, height }
  }

  renderCloseButton = () => {
    return (
      <TouchableOpacity style={styles.closeButton} onPress={() => this.setState({showImageViewer:false})}>
        <Icons name='ios-close' size={50} color={'white'}/>
      </TouchableOpacity>
    )
  }

  openModalImageViewer = (idx) => {
    this.setState({
      showImageViewer: true,
      modalImageIdx: idx
    })
  }

  imageModalViewer = () => {
    const { showImageViewer, modalImageIdx } = this.state
    const { images } = this.props

    const imageUrls = _.map(images, item => {
      const { width, height } = this.getRatio(item)
      return {url: item.uri || nameToUrl({
        name: item.name,
        fit: 'contain',
        height,
        width,
      })}
    })

    return (
      <Modal
        visible={showImageViewer}
        onRequestClose={() => this.setState({showImageViewer:false})}
      >
        <ImageViewer
          imageUrls={imageUrls}
          backgroundColor={'rgba(0,0,0,0.8)'}
          renderHeader={() => this.renderCloseButton()}
          loadingRender={() => <ActivityIndicator />}
          index={modalImageIdx}
        />
      </Modal>
    )
  }

  render() {
    return (
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollInner}
        onLayout={this.props.onLayout}
      >
        {this.renderImages()}
        {this.state.showImageViewer && this.imageModalViewer()}
      </ScrollView>
    )
  }
}

const styles = stylus({
  scrollInner: {
    // paddingHorizontal: 16,
    alignItems: 'center',
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  image: {
    borderRadius: 2,
    marginHorizontal: 10,
  },
  imageStart: {
    borderRadius: 2,
    marginRight: 10,
  },
  imageEnd: {
    borderRadius: 2,
    marginLeft: 10,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    ios: {
      top: 10,
    },
    iphonex: {
      top: 30,
    },
    right: 10,
    padding: 2,
    zIndex: 999
  },
})

const mapStateToProps = (state) => ({
  contentWidth: state.screenInfo.contentWidth,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Awards)
