import Start from './Start'
import FinancialInfo from './FinancialInfo'
import Details from './Details'
import FullTerms from './FullTerms'
import Review from './Review' // Shop is created in DB at this screen, under submitShop function.
import Finish from './Finish'

const Screens = {
  Start,
  FinancialInfo,
  Review,
  Details,
  FullTerms,
  Finish,
}
export default Screens
