// import FontAwesome from "react-native-vector-icons/fonts/FontAwesome.ttf"
// import material from "react-native-vector-icons/fonts/MaterialIcons.ttf"

// other faults like this ↓
// import Entypo from "react-native-vector-icons/fonts/Entypo.ttf"
// import Octicons from "react-native-vector-icons/fonts/Octicons.ttf"
import Ionicons from '@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf'
import Icons from '../assets/fonts/es-font-1.ttf'
import FBIcons from '../assets/fonts/fb-icons.ttf'
import Zocial from '@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Zocial.ttf'
import Octicons from '@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Octicons.ttf'
import FontAwesome from '@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome.ttf'
import FontAwesome5 from '@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf'
import MaterialCommunityIcons from '@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'
import _ from 'lodash'
import colors from './colors'

window._ = _

export default function() {
  // generate required css
  const styles = [
    '@font-face { font-family: "FontAwesome";src:url('+FontAwesome+'); }',
    '@font-face { font-family: "FontAwesome5";src:url('+FontAwesome5+'); }',
    // "@font-face { font-family: \"Material Icons\";src:url("+material+"); }",
    '@font-face { font-family: "font-1";src:url(' + Icons + '); }',
    '@font-face { font-family: "fb-icons";src:url(' + FBIcons + '); }',
    '@font-face { font-family: "zocial";src:url(' + Zocial + '); }',
    '@font-face { font-family: "Octicons";src:url(' + Octicons + '); }',
    '@font-face { font-family: "Material Design Icons";src:url(' + MaterialCommunityIcons + '); }',
    '@font-face { font-family: "Ionicons";src:url(' + Ionicons + '); }',
    `html, body, #react-root {
      font-family: roboto, "Helvetica Neue",'lucida grande',tahoma,verdana,arial,sans-serif;
      width: 100vw;
      height: 100vh;
      position: fixed;
      overflow: hidden;
    }
    :focus {
      outline-color: transparent;
      outline-style: none;
    }
    ::-webkit-input-placeholder {
      font-weight: normal;
      color: ${colors.input.placeholder};
    }

    :-moz-placeholder { /* Firefox 18- */
      font-weight: normal;
      color: ${colors.input.placeholder};
    }

    ::-moz-placeholder {  /* Firefox 19+ */
      font-weight: normal;
      color: ${colors.input.placeholder};
    }

    :-ms-input-placeholder {
      font-weight: normal;
      color: ${colors.input.placeholder};
    }

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${colors.webScrollbarTrack};
      border-radius: 0px;
    }

    ::-webkit-scrollbar-thumb {
      border-bottom: none;
      border-top: none;
      background: ${colors.webScrollbarThumb};
      width: 14px;
    }
    `,
    // "@font-face { font-family: \"Entypo\";src:url("+Entypo+"); }",
    // "@font-face { font-family: \"Octicons\";src:url("+Octicons+"); }",
  ]

  // create stylesheets
  styles.forEach((i) => {
    const style = document.createElement('style')
    style.type = 'text/css'
    if (style.styleSheet) {
      style.styleSheet.cssText = i
    } else {
      style.appendChild(document.createTextNode(i))
    }

    // inject stylesheet
    document.head.appendChild(style)
  })
}
