import i18n from 'i18n-js'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { View } from 'react-native'
import { graphql, connect, compose } from '../../config/connected'
import NavigationActions from '../../utility/navigationActions'
import LayoutAnimation from '../../components/LayoutAnimation'
import { stylus } from '../../config/styles'
import schema from '../../schema/user'
import ProfileAction from './ProfileAction'
import { STATUS_VISIBILITY, OBJECT_STATUSES } from '../../config/constants'
import { _get } from '../../config/connected'
import { OBJECT } from 'graphql/language/kinds'
import { ANALYTICS_FOLLOWING_SHOP } from '../../reducers/analytics'
import withSetProps from '../../containers/withSetProps'
import alert from '../../utility/alert'

const PROFILE_STYLE = 'insta'
const debug = false

class ProfileActions extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    const previouslyLoading = _.get(prevProps, 'getShop.loading', false) 
    const currentlyLoading = _.get(this.props, 'getShop.loading', false)
    debug && console.log('Was Loading?', previouslyLoading, 'Is Loading?', currentlyLoading)
    if (previouslyLoading && !currentlyLoading) {
      debug && console.log('DONE FETCHING SHOP!')
      this.props.setProps({ fetchUpdatedShopInfo: undefined })
    }
  }
  render() {
    return this.props.isMe ? this.me() : this.other()
  }

  getShop() {
    return _.get(this.props, 'profileQuery.user.ownedProfile.profile', null)
  }

  me() {
    const { goToEdit } = this.props
    const shop = this.getShop()
    const shopStatus = _get(shop, 'status', OBJECT_STATUSES.unknown)
    //<ProfileAction
    //  label={i18n.t('common.privacy')}
    //  icon='ios-lock-outline'
    //  style={{
    //    // paddingLeft: 11,
    //    width: 130,
    //  }}
    ///>
    return (
      <View style={[styles.container]}>
        <ProfileAction
          label={i18n.t('common.editProfile')}
          icon='ios-contact'
          onPress={goToEdit}
          style={{ width: PROFILE_STYLE === 'insta' ? 96 : 130 }}
        />
        {_.includes(STATUS_VISIBILITY.ownerVisible, shopStatus) &&
          this.userShop()}
      </View>
    )
    //<ProfileAction label='more' icon='ios-more' style={{paddingLeft: 10}} />
  }

  userShop() {
    const shop = this.getShop()
    if (!shop) return null
    // if (!shop) return null
    return (
      <ProfileAction
        label={i18n.t('settings.sectionLinks.myShop')}
        onPress={this.goToMyShop}
        style={{ width: PROFILE_STYLE === 'insta' ? 96 : 130 }}
      />
    )
  }

  goToMyShop = () => {
    const myShop = this.getShop()
    this.props.goToShop({
      id: myShop.id,
      pageTitle: myShop.displayName,
    })
  }

  other() {
    const shop = this.getShop()
    // const { currentUser, client } = this.props

    return (
      <View style={[styles.container]}>
        {this.renderFollowing()}
        {_.includes(
          STATUS_VISIBILITY.userVisible,
          _get(shop, 'status', OBJECT_STATUSES.unknown),
        ) && this.userShop()}
      </View>
    )
    //{this.renderChat()}
    //<ProfileAction
    //  label={i18n.t('common.more')}
    //  icon='ios-more'
    //  style={{ width: 96 }}
    ///>
  }

  renderFollowing() {
    const following = _.get(this.props, 'profileQuery.user.iFollow')
    return (
      <ProfileAction
        label={i18n.t(following ? 'common.following' : 'common.follow')}
        icon={
          following ? 'ios-checkmark-circle-outline' : 'ios-add-circle-outline'
        }
        onPress={following ? this.unfollowUser : this.followUser}
        style={{ width: 96 }}
      />
    )
  }
  renderChat() {
    return (
      <ProfileAction
        label={i18n.t('common.chat')}
        icon='ios-chatbubbles'
        style={{ width: 96 }}
      />
    )
  }
  followUser = async () => {
    this.animate()
    const { profileId: id, profileQuery, setProps } = this.props
    const followersCount = _.get(profileQuery, 'user.followersCount', 0)
    const result = await this.props.followUser({
      variables: { id },
      optimisticResponse: {
        __typename: 'Mutation',
        followUser: {
          ...profileQuery.user,
          iFollow: true,
          followersCount: followersCount + 1,
        },
      },
    })
    this.props.analyticsFollowingShop(id)
    const shopId = _.get(this.getShop(), 'id')
    if (shopId) {
      this.props.setProps({ fetchUpdatedShopInfo: { shopId } })
    }
  }
  unfollowUser = async () => {
    this.animate()
    const { currentUser, profileId: id, profileQuery } = this.props
    const followersCount = _.get(profileQuery, 'user.followersCount', 0)

    const result = await this.props.unfollowUser({
      variables: { id },
      optimisticResponse: {
        __typename: 'Mutation',
        unfollowUser: {
          ...profileQuery.user,
          iFollow: false,
          followersCount: followersCount - 1,
        },
      },
    })
    const shopId = _.get(this.getShop(), 'id')
    if (shopId) {
      this.props.setProps({ fetchUpdatedShopInfo: { shopId } })
    }
  }

  animate() {
    LayoutAnimation &&
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  goToEdit: () => {
    if (ownProps.pendingProfileEdit) {
      alert({
        message: i18n.t('publishContent.alert.pendingContent')
      })
      return
    }
    NavigationActions.navigate({ routeName: 'EditProfile' })
  },
  analyticsFollowingShop: (shopId) => {
    dispatch({
      type: ANALYTICS_FOLLOWING_SHOP,
      data: {
        shopId,
      },
    })
  },
  goToShop: ({ id, pageTitle }) =>
    NavigationActions.navigate({
      routeName: 'Shop',
      params: { id, pageTitle },
    })
  ,
})

ProfileActions.propTypes = {
  isMe: PropTypes.bool,
  profileQuery: PropTypes.object,
  goToShop: PropTypes.func,
  goToEdit: PropTypes.func,
  unfollowUser: PropTypes.func,
  followUser: PropTypes.func,
  currentUser: PropTypes.object,
  client: PropTypes.object,
  goToChat: PropTypes.func,
  startPrivateConversation: PropTypes.func,
  profileId: PropTypes.string,
  pendingProfileEdit: PropTypes.object,
}

export default compose(
  withSetProps,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(schema.mutations.followUser, { name: 'followUser' }),
  graphql(schema.mutations.unfollowUser, { name: 'unfollowUser' }),
  graphql(schema.queries.getShop, {
    name: 'getShop',
    skip: (props) => { debug && console.log('fetchUpdatedShopInfo', props.fetchUpdatedShopInfo); return !props.fetchUpdatedShopInfo },
    options: (props) => {
      const id =_.get(props, 'fetchUpdatedShopInfo.shopId', '')
      debug && console.log('FETCHING SHOP', id)
      return {
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      }
    },
  }),
)(ProfileActions)

const styles = stylus({
  container: {
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    paddingHorizontal: 30,
    landscape: {
      paddingHorizontal: 80,
    },
  },
})
