import React from 'react'
import produce from 'immer'
import { get } from 'lodash'

import { compose } from '../../config/connected'
import withCurrentUser, { query as currentUserQuery } from '../../containers/withCurrentUser'
import { client } from '../../containers/withApollo'
import { updateCachedQueryValue } from '../../utility/apollo'

import schema from '../../schema/notice'

const debug = false

class NoticeSubscriptions extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = get(nextProps, 'currentUser.currentUser.id')
    if (id !== get(this.props, 'currentUser.currentUser.id')) {
      if (id) {
        if (!this.noticeSubscription) {
          this.subscribeToNotices()
        }
      } else {
        if (this.noticeSubscription) {
          this.noticeSubscription.unsubscribe()
          delete this.noticeSubscription
        }
      }
    }
  }
  subscribeToNotices() {
    const observable = client.subscribe({
      query: schema.subscriptions.noticeAdded,
    })
    this.noticeSubscription = observable.subscribe({
      next: (message) => {
        const notice = get(message, 'data.noticeAdded')

        try {
          updateCachedQueryValue(client, {
            query: currentUserQuery,
            nextValue: (currentUser = {}) => {
              return produce(currentUser, draft => { 
                draft.lastNotice = notice 
              })
            }
          })
        } catch (e) {
          debug && console.log('<<<NOTICES SUBSCRIPTION - error', e)
        }
      },
      error(err) {
        debug && console.log(`<<<NOTICES SUBSCRIPTION - error subscribing`, err)
      },
    })
  }
  render() {
    return null
  }
}

export default compose(withCurrentUser)(NoticeSubscriptions)
