import React, { Component } from 'react'
import { Text, View, ScrollView } from 'react-native'

import colors from '../../../../config/colors'
import { stylus } from '../../../../config/styles'
import i18n from 'i18n-js'
import { graphql } from '../../../../config/connected'
import schema from '../schema'
import shopSchema from '../../../../schema/shop'
import startSchema from '../../../../schema/startScreenSchema'
import paymentSchema from '../../../../schema/payment'

import _ from 'lodash'

// import stripeLogo from '../../../../assets/images/stripe_logo_black.png'
// import ElegantInput from '../../../../components/simple/ElegantInput'
import T from '../../../../components/T'
import Checkbox from '../../../../components/simple/Checkbox'
import Button from '../../../../components/simple/Button'
import Details from './Details'
import FinancialInfo from './FinancialInfo'
import KeyboardShift from '../../../../containers/KeyboardShift'
import alert from '../../../../utility/alert'
import { fromLocalePriceToRealNumber } from '../../../../config/helpers'
import {
  SHOP_FIELD_VAT,
  SHOP_FIELD_EU_VAT,
  SHOP_FIELD_COMPANY_PHONE_LAND_PREFIX,
  SHOP_FIELD_COMPANY_PHONE_LAND,
  SHOP_FIELD_COMPANY_PHONE_MOBILE_PREFIX,
  SHOP_FIELD_COMPANY_PHONE_MOBILE,
  SHOP_FIELD_EMAIL,
  OBJECT_STATUSES,
} from '../../../../config/constants'

@graphql(schema.createShopUser, { name: 'createShopUser' })
@graphql(schema.updateProfileAddress, { name: 'updateProfileAddress' })
@graphql(shopSchema.mutations.updateProducerDetails, { name: 'updateProducerDetails' })
@graphql(paymentSchema.mutations.createStripeCustomerForOrganization, { name: 'createStripeCustomerForOrganization' })
@graphql(startSchema.userByEmail, {
  name: 'userByEmail',
  options: {
    variables: { email: '' },
  },
})
class Review extends Component {
  state = {
    acceptedTerms: false,
    screensRegistered: [],
  }

  constructor(props) {
    super(props)
    this.fieldSetValidators = []
  }

  handleAgree = () => {
    this.setState((prevState) => ({ acceptedTerms: !prevState.acceptedTerms }))
  }

  registerScreen = ({ screen, fieldSetValidator }) => {
    if (this.state.screensRegistered.indexOf(screen) === -1) {
      this.fieldSetValidators.push(fieldSetValidator)
      this.setState({
        screensRegistered: [...this.state.screensRegistered, screen],
      })
    }
  }

  anyFieldErrors() {
    let message = ''
    this.fieldSetValidators.forEach((validator) => (message += validator()))
    if (message) {
      alert({ title: 'Errors', message })
    }
    return message
  }

  setGeneralError = (errorMessage, options) => {
    const { setError, removeError } = this.props
    setError('generalError', errorMessage, options)
    setTimeout(() => {
      removeError('generalError')
    }, 10000)
  }

  submitShop = async () => {
    const { shopInfo, financialInfo, goToPage, setError, userByEmail} = this.props
    let shopId
    if (this.anyFieldErrors()) {
      return
    }

    const unicEmail = `${shopInfo.email.replace('@', '+foodbarrio@')}`
    const emailResult = await userByEmail.refetch({ email: unicEmail, })
    const userWithEmail = emailResult.data.userByEmail

    if (userWithEmail) {
      setError(SHOP_FIELD_EMAIL, 'errors.email.taken', {}, () => {
        alert({ title: i18n.t('common.error'), message: this.state.errors[SHOP_FIELD_EMAIL] })
      })
      return
    }

    try {
      let result = await this.props.createShopUser({
        variables: {
          displayName: shopInfo.shopName,
          role: financialInfo.role,
          email: unicEmail,
        },
      })
      shopId = result.data.createShopUser
    } catch (error) {
      console.log(error.message)
      this.setGeneralError('errors.onboardingShop.graphQLError')
      return
    }

    try {
      const address = await this.props.updateProfileAddress({
        variables: {
          id: shopId,
          address1: shopInfo.address,
          city: shopInfo.city,
          region: shopInfo.region,
          zip: shopInfo.zip,
          state: shopInfo.state,
          country: shopInfo.country,
          geo: shopInfo.geo,
        },
      })
    } catch (error) {
      console.log('error.message', error.message)
      this.setGeneralError('errors.onboardingShop.graphQLError')
      return
    }

    const freeShippingFloat = fromLocalePriceToRealNumber(
      shopInfo.freeShippingThreshold,
    )
    const freeShippingThreshold = isFinite(freeShippingFloat)
      ? freeShippingFloat
      : null
    try {
      const details = await this.props.updateProducerDetails({
        variables: {
          id: shopId,
          legalName: financialInfo.legalName,
          established: parseInt(shopInfo.founded),
          payment_cash: financialInfo.cash,
          payment_transfer: financialInfo.transfer,
          payment_stripe: financialInfo.stripe,
          website: shopInfo.website,
          ibanNumber: financialInfo.ibanNumber,
          bankAccountHolder: financialInfo.bankAccountHolder,
          standardShippingFeeCountryData: {
            'IT': { value: fromLocalePriceToRealNumber(shopInfo.standardShippingFee), currency: 'EUR' },
          },
          minimumOrderAmount: fromLocalePriceToRealNumber(
            shopInfo.minimumOrderAmount,
          ),
          freeShippingThresholdCountryData: { 'IT': { value: freeShippingThreshold, currency: 'EUR' } },
          companyPhoneLand: financialInfo[SHOP_FIELD_COMPANY_PHONE_LAND_PREFIX] + financialInfo[SHOP_FIELD_COMPANY_PHONE_LAND],
          companyPhoneMobile: financialInfo[SHOP_FIELD_COMPANY_PHONE_MOBILE_PREFIX] + financialInfo[SHOP_FIELD_COMPANY_PHONE_MOBILE],
          euVAT: financialInfo[SHOP_FIELD_EU_VAT],
          VAT: financialInfo[SHOP_FIELD_VAT],
          countriesVisibleIn: ['IT'],
          policies: { 'IT': { returns: { value: '', tags: [] }, shipping: { value: '', tags: [] } } },
        },
      })
      const stripeCustomer = await this.props.createStripeCustomerForOrganization(
        {
          variables: { organizationId: shopId },
        },
      )
    } catch (error) {
      console.log(error.message)
      this.setGeneralError('errors.onboardingShop.graphQLError')
      return
    }

    goToPage('Finish')
  }

  render() {
    const MAX_FIELD_LENGTH = 50
    const { acceptedTerms } = this.state
    const {
      financialInfo,
      handleFieldChange,
      shopInfo,
      goToPage,
      agreedToTerms,
      errors,
    } = this.props
    return (
      <ScrollView>
        <KeyboardShift fluid>
          <View style={styles.container}>
            <View style={styles.padding}>
              <T t='onboardingShop.review.title' style={styles.title} />
              <T t='onboardingShop.review.body' style={styles.body} />
            </View>
            <FinancialInfo
              registerScreen={this.registerScreen}
              {...this.props}
              asView={true}
            />
            <Details
              registerScreen={this.registerScreen}
              {...this.props}
              asView={true}
            />
            <View style={[styles.padding, { alignItems: 'center' }]}>
              <Checkbox onPress={this.handleAgree} checked={acceptedTerms} />
              <Text style={styles.terms}>
                <T t='onboardingShop.review.terms1' />
                <T t='onboardingShop.review.foodbarrio' />
                <T t='onboardingShop.review.terms2' />
              </Text>
              <Button
                disabled={!acceptedTerms}
                label={i18n.t('onboardingShop.review.submit')}
                style={styles.submit}
                onPress={this.submitShop}
              />
              <Text style={{ color: 'red', marginBottom: 40 }}>
                {errors.generalError && errors.generalError}
              </Text>
            </View>
          </View>
        </KeyboardShift>
      </ScrollView>
    )
  }
}

export default Review

const styles = stylus({
  container: {
    // flex: 1,
    // alignItems: 'center',
    // padding: 40,
    textAlign: 'center',
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    margin: 10,
    textAlign: 'center',
  },
  padding: {
    paddingHorizontal: 40,
  },
  body: {
    fontSize: 16,
    color: colors.secondary,
    margin: 10,
    textAlign: 'center',
  },
  next: {
    width: '100%',
    marginVertical: 20,
    backgroundColor: colors.primary,
  },
  bold: {
    fontWeight: 'bold',
  },
  terms: {
    paddingTop: 20,
    color: colors.text.light,
    textAlign: 'center',
  },
  submit: {
    width: '100%',
    marginVertical: 40,
    backgroundColor: colors.primary,
  },
  reviewHeader: {
    alignItems: 'center',
    padding: 40,
  },
})
