export const imagesArray = [
  {
    name: 'salmon.jpg',
    mime: 'image/jpg',
    width: 500,
    height: 200,
  },
  {
    name: 'hamburger.jpg',
    mime: 'image/jpg',
    width: 500,
    height: 200,
  },
  {
    name: 'burrito.jpg',
    mime: 'image/jpg',
    width: 500,
    height: 200,
  },
  {
    uri: 'http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4',
    mime: 'video/mp4',
    width: 500,
    height: 200,
  },
]

export const imagesArray2 = [
  {
    name: 'expo-demo/post-image-1.jpg',
    mime: 'image/jpg',
    width: 500,
    height: 200,
  },
  {
    name: 'expo-demo/post-image-2.jpg',
    mime: 'image/jpg',
    width: 500,
    height: 200,
  },
  {
    name: 'expo-demo/post-image-3.jpg',
    mime: 'image/jpg',
    width: 500,
    height: 200,
  },
]

export const imagesArray3 = [
  {
    name: 'expo-demo/post-image-4.jpg',
    mime: 'image/jpg',
    width: 500,
    height: 200,
  },
  {
    name: 'expo-demo/post-image-5.jpg',
    mime: 'image/jpg',
    width: 500,
    height: 200,
  },
  {
    name: 'expo-demo/post-image-6.jpg',
    mime: 'image/jpg',
    width: 500,
    height: 200,
  },
]

export const imagesArrayRecipe = [
  {
    name: 'expo-demo/recipe-1.jpg',
    mime: 'image/jpg',
    width: 500,
    height: 200,
  },
  {
    name: 'expo-demo/recipe-2.jpg',
    mime: 'image/jpg',
    width: 500,
    height: 200,
  },
  {
    name: 'expo-demo/recipe-3.jpg',
    mime: 'image/jpg',
    width: 500,
    height: 200,
  },
]

export const user1 = {
  id: 'g6qZFHz5ao',
  username: 'George.512',
  title: 'Owner',
  shopId: '2NjioDMO08',
  shopName: "George's Shop", // probably denormalized in practice
  profileImage: { name: 'profile1.jpg', mime: 'image/jpg' },
}

export const imageUploadPlaceholder = {
  name: 'placeholder-image.png',
  mime: 'image/png',
}

export const user2 = {
  id: 'ajeSM2G1a7',
  username: 'Anna.361',
  title: '',
  shopId: '',
  shopName: '',
  profileImage: { name: 'profile2.jpg', mime: 'image/jpg' },
}

export const user3 = {
  id: 'vABCs1Wtjt',
  username: 'Nicole.361',
  title: 'Owner',
  shopId: 'ac2E98jlF5',
  shopName: "Nicoles's Castle",
  profileImage: { name: 'profile3.jpg', mime: 'image/jpg' },
}

const userOlea = {
    id: 'vAB42Cs1asdWtjt',
    username: 'olio_depalo',
    title: 'Owner',
    shopId: 'ac2E98jlF5',
    shopName: 'Olea',
    profileImage: { name: 'profile3.jpg', mime: 'image/jpg' },
  },
  userTorricellia = {
    id: 'vAB42Cs1asdWtjt',
    username: 'Jo',
    title: 'Owner',
    shopId: 'ac2E98jlF5',
    shopName: 'La Torricellia',
    profileImage: { name: 'profile3.jpg', mime: 'image/jpg' },
  }

const comment1 = {
  content: 'Nice stuff!!',
  id: 'mfsdfoh12',
  likes: 0,
  imageSource: null,
  user: { ...user2 },
  createdOn: '2018-10-09T08:26:02.290Z',
}

const comment2 = {
  content:
    'I found this really NEET company logo from somewhere on the internets.',
  id: 'AMSDjfQWM56',
  likes: 0,
  imageSource: null,
  user: { ...user3 },
  createdOn: '2018-10-09T05:44:02.290Z',
}

const comment3 = {
  content:
    'I think that this really needs to be an extremely long comment just to see how this layout handles this.',
  id: 'asdFIPr329',
  likes: 0,
  imageSource: null,
  user: { ...user1 },
  createdOn: '2018-09-09T08:44:02.290Z',
}

const laTorricellaTaggedItems = [
  {
    id: 'dalsk51d',
    image: {
      name: 'wine-bottle.jpg',
      mime: 'image/jpg',
    },
    title: 'Barolo Prassenda',
    shopName: 'La Torricella',
    rating: 4.543,
    ratings: 467,
    price: 3.95,
    priceDiscount: '',
  },
]

const oleaTaggedItems = [
  {
    id: 'dalsk51d',
    image: {
      name: 'statue.jpg',
      mime: 'image/jpg',
    },
    title: 'Giove',
    shopName: 'Oleà',
    rating: 4.543,
    ratings: 467,
    price: 3.95,
    priceDiscount: '',
  },
  {
    id: 'asdgh34g5',
    image: {
      name: 'perseus.jpg',
      mime: 'image/jpg',
    },
    title: 'Perseo',
    shopName: 'Oleà',
    rating: 3.895,
    ratings: 124,
    price: 9.95,
    priceDiscount: 4.95,
  },
  {
    id: 'g3j65dh6',
    image: {
      name: 'painting.jpg',
      mime: 'image/jpg',
    },
    title: 'Danae',
    shopName: 'Oleà',
    rating: 4.543,
    ratings: 212,
    price: 9.95,
    priceDiscount: 4.95,
  },
]

const taggedItems = [
  {
    id: 'dalsk51d',
    image: { name: 'cheese.jpg', mime: 'image/jpg' },
    title: "Flavio's Mystery Cheese",
    shopName: "Flavio's Shop",
    rating: 4.543,
    ratings: 391,
    price: 9.95,
    priceDiscount: 4.95,
  },
  {
    id: 'asdgh34g5',
    image: { name: 'cheese2.jpg', mime: 'image/jpg' },
    title: "Flavio's Assorted Cheese",
    shopName: "Flavio's Shop",
    rating: 3.895,
    ratings: 124,
    price: 9.95,
    priceDiscount: 4.95,
  },
  {
    id: 'g3j65dh6',
    image: { name: 'cheese3.jpg', mime: 'image/jpg' },
    title: "Flavio's Swiss",
    shopName: "Flavio's Shop",
    rating: 3.543,
    ratings: 612,
    price: 9.95,
    priceDiscount: 4.95,
  },
]

const user = {
  profileImage: { name: 'profile4.jpg', mime: 'image/jpg' },
  createdOn: '2018-08-09T08:44:02.290Z',
  id: 'sdajio032jq',
  username: 'George',
  title: 'Owner',
  shopName: 'La Toricella',
  shopId: 'jjf2903g7',
}

const product = {
  id: 'EOGi5ikf64',
  createdOn: '2018-08-09T08:44:02.290Z',
  images: imagesArray,
  category: 'Cheese',
  rating: 3.743,
  ratings: 251,
  shopId: 'flv8402',
  shopName: "Flavio's Shop",
  title: "Flavio's Parmesan",
  stock: 5123,
  quantityUnits: 'kg',
  price: 14.94,
  priceDiscount: 9.95,
  isDiscontinued: false,
  isInStock: true,
  description:
    'This cheese is hand distilled in the finest coal mines of southern Italy. Originally created by God himself, and passed down to Flavio through years of practice.',
  awards: {
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id lobortis purus. Ut non ex vitae lorem luctus posuere. Morbi faucibus, turpis non aliquet mollis, eros magna ultrices nunc, in rutrum ex nulla eget mi. Nunc faucibus, lectus a tempor aliquam, odio ipsum scelerisque leo, ut egestas velit tortor eget lectus. Ut sit amet sagittis mauris. Vivamus ultrices, lorem nec commodo feugiat, leo ligula imperdiet ligula, ut fermentum magna nibh vitae ligula. Curabitur quis leo et dolor aliquet euismod at ut leo. Nunc feugiat eu lorem a pretium. Fusce vitae pulvinar ligula, et rutrum est. Praesent mattis, nulla eget egestas porttitor, arcu mi sollicitudin mi, tincidunt tincidunt sem ipsum in enim. Cras at libero ante. Suspendisse nec auctor erat, eget venenatis lacus. Proin consequat massa a leo aliquam luctus. Duis sed sem ante.',
    images: imagesArray,
  },
  ingredients: 'Milk, Rennit, Sugar, Spice, & Everything Nice',
  reviews: [
    'djfo301jhg0',
    'fj10938h',
    'gfj91hf0',
    'fj10gr2l',
    'g902gh',
    'fj30g193',
  ],
  posts: ['fj403i1', 'jg0g094', 'pwub9478g', 'ew94x0zR', '103ijgpown'],
  recipes: ['gk3kdf', 'fj392gj', 'sdj198hg', 'kg018ghbnbn'],
}

const reviews = [
  {
    id: 'asdj199123h',
    createdOn: '2018-08-09T08:44:02.290Z',
    rating: 3.492,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    productId: '123if5id4fs',
    posterId: '939fdf123',
    product: product,
    // TESTING ONLY, product will be pulled from DB in production.
  },
  {
    id: 'djg03u2',
    createdOn: '2018-08-09T08:44:02.290Z',
    rating: 4.92,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    productId: '123if5id4fs',
    posterId: '939fdf123',
    product: product,
    // TESTING ONLY, product will be pulled from DB in production.
  },
  {
    id: 'fmkgj3092',
    createdOn: '2018-08-09T08:44:02.290Z',
    rating: 2.348,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    productId: '123if5id4fs',
    posterId: '939fdf123',
    product: product,
    // TESTING ONLY, product will be pulled from DB in production.
  },
]

const shops = [
  {
    id: 'flv8402',
    profileImage: { name: 'profile1.jpg', mime: 'image/jpg' },
    createdOn: '2018-08-09T08:44:02.290Z',
    shopName: 'La Torricella',
    ownerId: 'ajeSM2G1a7',
    category: 'Winery',
    style: 'Products',
    phoneNumber: '+39 06 580 3926',
    methods: 'Organic',
    location: {
      address: '9556 Lomitas Ave',
      city: 'Beverly Hills',
      region: 'California',
      zip: '90210',
      country: 'United States',
      locale: 'en-US',
    },
    coverPhoto: {
      name: 'expo-demo/hills.jpg',
      mime: 'image/jpg',
    },
    products: ['EOGi5ikf64', 'dFi30fr48', 'FJoe03uf871', 'Xjf03jd21'],
    payment: { cash: true, transfer: true, stripe: true },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id lobortis purus. Ut non ex vitae lorem luctus posuere. Morbi faucibus, turpis non aliquet mollis, eros magna ultrices nunc, in rutrum ex nulla eget mi. Nunc faucibus, lectus a tempor aliquam, odio ipsum scelerisque leo, ut egestas velit tortor eget lectus. Ut sit amet sagittis mauris. Vivamus ultrices, lorem nec commodo feugiat, leo ligula imperdiet ligula, ut fermentum magna nibh vitae ligula. Curabitur quis leo et dolor aliquet euismod at ut leo. Nunc feugiat eu lorem a pretium. Fusce vitae pulvinar ligula, et rutrum est. Praesent mattis, nulla eget egestas porttitor, arcu mi sollicitudin mi, tincidunt tincidunt sem ipsum in enim. Cras at libero ante. Suspendisse nec auctor erat, eget venenatis lacus. Proin consequat massa a leo aliquam luctus. Duis sed sem ante.',
    policies: {
      returnExchange: 'Some basic return exchange policy goes here',
      shipping: 'Basic shipping policy goes here.',
      payment: 'You give us money and nobody gets stabbed.',
    },
  },
]

const mockData = {
  reviews,
  user,
  userArray: [user1, user2, user3],
  shops,
  product,
  imageUploadPlaceholder,
  productArray: [product, product, product],
  cartItem: {
    category: 'Cheese',
    rating: 4.623,
    title: "Flavio's Parmesan",
    quantity: 25,
    quantityUnits: 'kg',
    price: 14.94,
    priceDiscount: 9.95,
  },
  feed: {
    specialPosts: [
      {
        images: imagesArray,
        content: 'Oggi si inizia la vendemmia della Barbera!',
        id: '12414',
        likes: 25,
        imageSource: { name: 'profile1.jpg', mime: 'image/jpg' },
        user: user1,
        createdOn: '2018-08-09T08:44:02.290Z',
        comments: [comment2, comment1, comment3],
        taggedItems: taggedItems,
      },
      {
        images: imagesArray,
        content: 'to be provided later',
        id: '12345612',
        likes: 55,
        imageSource: { name: 'profile1.jpg', mime: 'image/jpg' },
        user: userOlea,
        createdOn: '2018-08-09T08:44:02.290Z',
        comments: [],
        taggedItems: oleaTaggedItems,
      },
      {
        images: [
          {
            name: 'wine-bottle-front.jpg',
            mime: 'image/jpg',
          },
          {
            name: 'wine-bottles.jpg',
            mime: 'image/jpg',
          },
          {
            name: 'wine-bottle-cluster.jpg',
            mime: 'image/jpg',
          },
        ],
        content:
          "Uno dei vini migliori che abbia provato quest'anno! @latorricella;baroloprassenda è spettacolare. Cin Cin!",
        id: '158ug',
        likes: 25,
        imageSource: { name: 'profile1.jpg', mime: 'image/jpg' },
        user: userTorricellia,
        createdOn: '2018-08-09T08:44:02.290Z',
        comments: [comment2, comment1, comment3],
        taggedItems: laTorricellaTaggedItems,
      },
    ],
    posts: [
      {
        images: imagesArray,
        content: 'I made a post, yay!',
        id: 'fkjew21',
        likes: 25,
        imageSource: { name: 'profile1.jpg', mime: 'image/jpg' },
        user: user3,
        createdOn: '2018-10-20T08:44:02.290Z',
        comments: [comment2, comment1, comment3],
        taggedItems: taggedItems,
        type: 'post',
      },
      {
        images: imagesArray2,
        content: 'Stuff n things',
        id: 'ads12',
        likes: 25,
        imageSource: { name: 'profile1.jpg', mime: 'image/jpg' },
        user: user3,
        createdOn: '2018-10-20T08:44:02.290Z',
        comments: [comment2, comment1, comment3],
        taggedItems: taggedItems,
        type: 'post',
      },
      {
        images: imagesArray3,
        content: 'I made a post, yay!',
        id: 'dfqg2',
        likes: 25,
        imageSource: { name: 'profile1.jpg', mime: 'image/jpg' },
        user: user3,
        createdOn: '2018-10-20T08:44:02.290Z',
        comments: [comment2, comment1, comment3],
        taggedItems: taggedItems,
        type: 'post',
      },
    ],
    recipes: [
      {
        id: 'fjio230g',
        images: imagesArrayRecipe,
        content:
          'Praesent vehicula mattis luctus. Curabitur finibus facilisis eros non tempor. Praesent ac suscipit ante. Integer sem odio, interdum eu mi in, cursus condimentum libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id enim diam.',
        title: 'Ultimate Moscow Mule',
        likes: 25,
        type: 'recipe',
        user: { ...user3 },
        createdOn: '2018-08-09T08:44:02.290Z',
        comments: [comment3, comment1, comment2],
        taggedItems: taggedItems,
        ingredients:
          'Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In mauris neque, tincidunt id egestas nec, condimentum id eros. Phasellus sollicitudin ex sit amet leo auctor, ac gravida urna iaculis. Aliquam ullamcorper justo non libero tempor, vel imperdiet lacus cursus. Morbi at elementum neque. Integer id dignissim magna. Donec rhoncus venenatis faucibus. Pellentesque ultrices pellentesque velit quis aliquam.',
        instructions:
          'Praesent vehicula mattis luctus. Curabitur finibus facilisis eros non tempor. Praesent ac suscipit ante. Integer sem odio, interdum eu mi in, cursus condimentum libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id enim diam. Quisque id ex enim. Etiam nisl sem, mollis a purus non, sollicitudin posuere massa. Pellentesque sodales ligula a felis tempor iaculis. Fusce pretium, elit vehicula pharetra gravida, orci lectus ultricies orci, ac pellentesque ante est a sem.',
        details: {
          difficulty: 3,
          prepTime: 10,
          cookTime: 25,
          cost: 2,
          servings: 4,
        },
      },
    ],
  },
}

export default mockData
