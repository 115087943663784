import { Linking, Platform } from 'react-native'

export default {
  openURL(url) {
    if (Platform.OS === 'web') {
      return
    }
    Linking.openURL(url)
  },
}
