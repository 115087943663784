import React from 'react'
import { Text, View, TouchableOpacity } from 'react-native'

import T from '../../components/T'
import i18n from 'i18n-js'
import withCurrentUser from '../../containers/withCurrentUser'
import Icon from '../../config/icon'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import NamedImage from '../../components/simple/NamedImage'
import branch from '../../config/branch'
import _ from 'lodash'
import ElegantInputWithTagging from '../../components/ElegantInputWithTagging'
import { TaggingContext } from '../../components/Tagging/withTagging'
import { _get } from '../../config/connected'

class PostCommenting extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: { value: '', tags: [] },
    }
  }
  render() {
    const { onBlur, currentUser, containerStyle } = this.props
    const autoFocus = _get(
      this.props,
      'navigation.state.params.autoFocus',
      false,
    )
    return (
      <TaggingContext.Consumer>
        {(context) => {
          const taggingHook = _get(context, 'taggingHook', () => {})
          return (
            <View style={[styles.container, containerStyle]}>
              <NamedImage
                style={styles.profile}
                resizeMode='cover'
                name={_.get(currentUser, 'currentUser.profileImage')}
                placeholder='profile'
                width={32}
                height={32}
              />
              <View style={styles.inputWrapper}>
                <ElegantInputWithTagging
                  asTextArea
                  placeholder={i18n.t('comment.inputPlaceholder')}
                  onChange={this.onChange}
                  autoFocus={autoFocus}
                  returnKeyType={branch({ android: 'none', ios: 'default' })}
                  nativeStyle={styles.nativeInputStyle}
                  webStyle={styles.webInputStyle}
                  // autoFocus={branch({ android: false, other: true })}
                  minHeight={30}
                  defaultContent={this.state.content}
                  reference={(ref) => (this.input = ref)}
                  onBlur={onBlur}
                  taggingHook={taggingHook}
                  truncate={{
                    length: 2194,
                    omission: '',
                  }}
                />
                {/* <TextArea
              placeholder={i18n.t('comment.inputPlaceholder')}
              onChangeText={this.onChangeText}
              returnKeyType={branch({ android: 'none', ios: 'default' })}
              nativeStyle={styles.nativeInputStyle}
              webStyle={styles.webInputStyle}
              autoFocus={branch({ android: false, other: true })}
              minHeight={30}
              value={this.state.content}
              ref={(ref) => (this.input = ref)}
              onBlur={onBlur}
            /> */}
                {this.sendButton()}
              </View>
            </View>
          )
        }}
      </TaggingContext.Consumer>
    )
  }

  onChange = (content) => {
    this.setState({ content })
  }

  sendButton() {
    const disabled = this.state.content.value.length === 0
    {
      /*return <SendButton disabled={disabled} onPress={this.send} />*/
    }
    // if (disabled) {return null}
    return (
      <TouchableOpacity style={styles.sendContainer} onPress={this.send}>
        <Text
          style={[
            styles.commentPostText,
            disabled && styles.commentPostTextDisabled,
          ]}
        >
          <T t='comment.postButton' />
        </Text>
      </TouchableOpacity>
    )
  }

  send = () => {
    const content = this.state.content
    if (content.value.length) {
      this.props.send({ content })

      this.setState({ content: { tags: [], value: '' } }, () => {
        this.input.clear()
        // alert(Object.keys(this.input.props))
      })
    }
  }
}

const mapStateToProps = (state) => ({
  // mockUser: mockData.allUsers[state.mockUser],
})
const mapDispatchToProps = (dispatch) => ({})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCurrentUser,
)(PostCommenting)

const styles = stylus({
  container: {
    // borderTopColor: '#F0F0F0',
    // borderTopColor: '#EEE',
    // borderTopWidth: sizes.px,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    // backgroundColor: '#FAFAFA',
    paddingTop: 5,
    paddingBottom: 7,
    landscape: {
      paddingVertical: 12,
    },
    tablet: {
      marginTop: 10,
      overflow: 'hidden',
      // borderBottomLeftRadius: 0,
      // borderBottomRightRadius: 0,
    },
    iphonex: {
      paddingBottom: 34,
    },
  },
  profile: {
    width: 32,
    height: 32,
    borderRadius: 16,
    marginRight: 12,
  },
  content: {
    flex: 1,
  },
  inputWrapper: {
    flex: 1,
    backgroundColor: colors.input.textArea,
    borderColor: colors.input.textAreaBorder,
    borderWidth: sizes.px,
    flexDirection: 'row',
    alignItems: 'flex-end',
    // paddingVertical: 5,
    paddingRight: 4,
    borderRadius: 19,
  },
  nativeInputStyle: {
    flex: 1,
    paddingRight: 11,
    paddingLeft: 12,
    color: colors.input.text,
    ios: {
      paddingTop: 8,
      paddingBottom: 5,
    },
    android: {
      paddingBottom: 2,
    },
    marginTop: 0,
    fontSize: 15,
    // backgroundColor: 'orange',
  },
  webInputStyle: {
    flex: 1,
    paddingLeft: 0,
    marginLeft: 12,
    marginBottom: 4,
    background: 'transparent',
    color: colors.input.text,
  },
  sendContainer: {
    paddingRight: 6,
    // paddingVertical: 6,
    height: 34,
    android: {
      paddingBottom: 1,
    },
    justifyContent: 'center',
  },
  commentPostText: {
    color: colors.primary,
    fontWeight: '500',
  },
  commentPostTextDisabled: {
    color: colors.text.secondary,
  },
})
