import React from 'react'
import { Platform, Text, View, Image } from 'react-native'
import Constants from 'expo-constants'
import moment from 'moment'
import NavigationActions from '../../utility/navigationActions'
import Icons from '@expo/vector-icons/Ionicons'
import Octicon from '@expo/vector-icons/Octicons'
import Zocial from '@expo/vector-icons/Zocial'
import FontAwesome from '@expo/vector-icons/FontAwesome'
import MCIcons from '@expo/vector-icons/MaterialCommunityIcons'
import withShareInvite from '../../containers/withShareInvite'
import Scroller from '../../components/Scroller'
import ListItem from '../../components/simple/ListItem'
import Section from '../../components/simple/Section'
import CartLink from '../../components/simple/CartLink'
import StrongButton from '../../components/simple/StrongButton'
import T from '../../components/T'

import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import { fbLogo } from '../../assets/images'

import _ from 'lodash'
import i18n from 'i18n-js'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import userSchema from '../../schema/user'
import { _get } from '../../config/connected'
import {
  OBJECT_STATUSES,
  ROUTE_PAYMENT_METHODS,
  ROUTE_SHOP_ORDER_HISTORY,
  ROUTE_USER_ORDER_HISTORY,
} from '../../config/constants'
import { getFeatureFlag, iconSize } from '../../config/helpers'
import { logout } from '../../utility/apollo'
import Icon from '../../config/icon'
import ShopIcon from '../../components/simple/Svg/local/ShopIcon'
import PropTypes from 'prop-types'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'

const isWeb = Platform.OS === 'web'
const appVersion = !isWeb ? Constants.nativeAppVersion : null
const appVersionBuild = !isWeb ? Constants.platform[Platform.OS].versionCode || Constants.platform[Platform.OS].buildNumber : null

class Settings extends React.Component {
  constructor(props) {
    super(props)
    if (getFeatureFlag('stripe')) {
      if (Platform.OS !== 'web') { // Temporarily disabled for web app (AZ#1315)
        this.options.account.splice(2, 0, {
          title: i18n.t('settings.sectionLinks.paymentMethods'),
          icon: 'credit-card-multiple',
          iconComponent: MCIcons,
          onPress: this.props.goToPaymentMethods,
        })
      }

      this.options.shop.push({
        title: i18n.t('settings.sectionLinks.stripeConnect'),
        icon: 'stripe',
        iconComponent: Zocial,
        onPress: this.props.goToStripeConnect,
      })
    }
  }

  /**
   * Setting Item Data Structure
   *
   * {
   * 	title: text in the link
   *  icon: icon next to text in the link
   *  onPress: function to fire on link being pressed
   *  iconComponent: component that renders icon(p.s. Ionicons is the default)
   *  component?: component that renders link(p.s. ListItem is default)
   * }
   */
  optionalAccountLinks = Platform.OS === 'web' ? [] : [{ // Temporarily disabled for web app (AZ#1315)
    title: i18n.t('settings.sectionLinks.addresses'),
    icon: 'place',
    iconComponent: MaterialIcons,
    size: iconSize(),
    onPress: () => {
      this.props.goToAddressesList()
    },
  }, {
    title: i18n.t('settings.sectionLinks.cart'),
    icon: 'cart-outline',
    iconComponent: Icon,
    size: iconSize(),
    component: CartLink, // Cart link needs data from Cart state
    onPress: () => {
      this.goToCart()
    },
  }, {
    title: i18n.t('settings.sectionLinks.userOrders'),
    icon: 'checklist',
    iconComponent: Octicon,
    onPress: () => {
      this.goToUserOrders()
    },
  }]

  options = {
    account: [
      ...this.optionalAccountLinks,
      {
        title: i18n.t('settings.sectionLinks.pushNotifications'),
        icon: 'notifications-outline',
        iconComponent: Icon,
        size: iconSize(),
        onPress: this.props.goToPushNotifications,
      },
      {
        title: i18n.t('settings.sectionLinks.logout'),
        icon: 'ios-power',
        onPress: () => {
          this.props.logout(_.get(this.props, 'cuid'))
        },
      },
      // {
      // 	title: 'Maps',
      // 	icon: 'ios-map',
      // 	onPress: this.props.goToMaps,
      // },
    ],
    noShop: [
      {
        title: i18n.t('settings.sectionLinks.applyForShop'),
        iconComponent: ShopIcon,
        onPress: () => {
          this.props.goToShopOnboarding(this.props.currUserShop.refetch)
        },
      },
    ],
    shop: [
      {
        title: i18n.t('settings.sectionLinks.shop'),
        iconComponent: ShopIcon,
        onPress: () => {
          this.props.goToShop(
            this.props.currUserShopHandler.currentUser.ownedProfile.profile.id,
            this.props.currUserShopHandler.currentUser.ownedProfile.profile
              .displayName,
          )
        },
      },
      {
        title: i18n.t('settings.sectionLinks.shopOrders'),
        icon: 'checklist',
        iconComponent: Octicon,
        onPress: () => {
          this.gotoShopOrders()
        },
      },
    ],
    info: [
      {
        title: i18n.t('settings.sectionLinks.aboutUs'),
        icon: 'ios-information-circle-outline',
        onPress: this.props.goToAbout,
      },
      {
        title: i18n.t('settings.sectionLinks.support'),
        icon: 'ios-help-buoy',
        onPress: this.props.goToSupport,
      },
      // Tell a Friend - Don't care to show on web since we are not addressing making it socially functional for the general users till later sprint
      !isWeb && {
        onPress: this.props.onOpenSharingSheet,
        forwardArrow: false,
        title: i18n.t('sharing.tellFriend'),
        icon: 'heart',
        iconColor: colors.liked,
        iconComponent: Icon,
      },
    ],
    adminSettings: [
      {
        title: 'Dashboard',
        icon: 'ios-speedometer',
        onPress: this.props.goToAdminDashboard,
      },
      // ID Verification - Was from previous project
      // {
      //   title: 'ID Verification',
      //   icon: 'ios-checkmark',
      //   onPress: this.props.goToPendingVerification,
      // },
      // Pending Verifications - Alternative to Admin Dashboard Shops tab for looking at pending shop applications
      // {
      //   title: i18n.t('admin.tabs.verifications.title'),
      //   icon: 'ios-checkmark',
      //   onPress: this.props.goToPendingVerification,
      // },
      // Testing Screen - Simply for developers to quickly test things
      // {
      //   title: 'Testing Screen',
      //   icon: 'ios-checkmark',
      //   onPress: this.props.goToTesting,
      // },
      // Manage Users - Alternative to Admin Dashboard Users tab
      // {
      //   title: 'Manage Users',
      //   icon: 'group',
      //   iconComponent: FontAwesome,
      //   onPress: this.props.goToManageUsers,
      // },
      // Coupon Management - Not including for Web unless there is time in Sprint 11 to address add/edit issues for task 1409
      !isWeb && {
        title: 'Coupons',
        icon: 'ticket-percent',
        iconComponent: MCIcons ,
        onPress: this.props.goToCoupons,
      },
      !isWeb && {
        title: i18n.t('settings.sectionLinks.notices'),
        icon: 'message-alert',
        iconComponent: MCIcons ,
        onPress: this.props.goToNotices,
      },
    ],
  }

  state = { currentUser: null }

  componentDidMount() {
    const { currUserShopHandler, currentUserHandler } = this.props
    if (!currUserShopHandler.loading) {
      currUserShopHandler.refetch()
    }
    if (!currentUserHandler.loading) {
      currentUserHandler.refetch()
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <Scroller>
          <View style={styles.wrapper}>
            {this.info()}
            {this.admin()}
            {this.account()}
            {this.shop()}
            {this.login()}
            {/* <View>
              <Text style={{ fontSize: 16, color: 'red' }}>Stripe Connect Redirect App URL: {Linking.makeUrl('stripe-connect')}</Text>
            </View> */}
          </View>
          {!isWeb && (
            <View style={styles.appVersion}>
              <Image
                source={fbLogo}
                style={styles.graphic}
                resizeMode='contain'
                resizeMethod='scale'
              />
              <Text style={styles.subLabel}>
                v.{appVersion}/{appVersionBuild} ({moment.utc(Constants.manifest.extra.publishTime).format('YYYYMMDD-HHmmss')})
              </Text>
            </View>
          )}
          <View
            style={[
              Platform.OS === 'android' && {
                paddingBottom: sizes.tabBarHeight,
              },
            ]}
          />
        </Scroller>
      </View>
    )
  }

  settingsSection(settings) {
    return (
      <Section>
        {settings.filter(item => item).map((item, index) =>  // Temporarily disabled for web app (filtering out null values, generated by web app checks above) (AZ#1315)
          this.settings(item, index, settings.length),
        )}
      </Section>
    )
  }

  settings(item, index, length) {
    const { layout } = this.props
    if (item.layout && item.layout !== layout) {
      return null
    }
    let { forwardArrow = true } = item
    let Icon = Icons
    if (item.iconComponent) {
      Icon = item.iconComponent
    }
    let size = 28
    if (item.size) {
      size = item.size
    }
    let listItemComponentProps = {
      leftStyle: { width: 46 },
      title: item.title,
      titleStyle: { fontWeight: '500' },
      forwardArrow,
      onPress: item.onPress,
      key: index,
      last: index === length - 1,
      first: index === 0,
      soft: true,
    }
    if (item.icon) {
      listItemComponentProps.left = (
        <Icon
          name={item.icon}
          size={size}
          color={item.iconColor || colors.text.main}
        />
      )
    }

    const ListItemComponent = item.component ? item.component : ListItem
    return <ListItemComponent {...listItemComponentProps} />
  }

  info() {
    return (
      <View>
        <View style={styles.spacer}>
          <T t='settings.sectionTitles.info' style={styles.subLabel} />
        </View>
        {this.settingsSection(this.options.info)}
      </View>
    )
  }
  account() {
    // const { ownedProfile } = this.props.data.currentUser
    const { currentUser, loading } = this.props.currentUserHandler
    if (!loading && _get(currentUser, 'id', false)) {
      return (
        <View>
          <View style={styles.spacer}>
            <T t='settings.sectionTitles.account' style={styles.subLabel} />
          </View>
          {this.settingsSection(this.options.account)}
        </View>
      )
    }
    return null
  }

  login() {
    const {
      currentUserHandler: { currentUser, loading },
    } = this.props
    if (!loading && !_get(currentUser, 'id', false)) {
      return (
        <View style={styles.loginContainer}>
          <StrongButton
            onPress={this.props.goToStart}
            label={'Login / Sign Up'}
            style={[styles.button, { marginTop: 0 }]}
          />
        </View>
      )
    } else {
      return null
    }
  }

  admin() {
    const { currentUser, loading } = this.props.currentUserHandler
    if (!loading && currentUser && currentUser.isAdmin) {
      return (
        <View>
          <View style={styles.spacer}>
            <T t='settings.sectionTitles.admin' style={styles.subLabel} />
          </View>
          {this.settingsSection(this.options.adminSettings)}
        </View>
      )
    }
    return null
  }

  shop() {
    const { currUserShopHandler } = this.props
    const { currentUserHandler } = this.props
    const { shop, noShop } = this.options
    let noShopMutationSAFE = noShop.slice()
    let shopMutationSAFE = shop.slice()

    if (currUserShopHandler.loading || currentUserHandler.loading) {
      return null
    }

    const hasShop = !!_get(
      currUserShopHandler,
      'currentUser.ownedProfile.profile.id',
      false,
    )

    const shopStatus = hasShop
      ? _get(currUserShopHandler, 'currentUser.ownedProfile.profile.status', 'noShop')
      : 'noShop'

    //Disabled pending details of shop review workflow.
    // Needs review is only true if it is user actionable review items. Does not trigger for complete disables or reported status.
    // const needsReview = _.includes([OBJECT_STATUSES.needsReview], shopStatus)
    const needsReview = false

    let optionsToRender = []

    // Set which options in the shop section to render
    switch (shopStatus) {
      case 'noShop':
        optionsToRender = noShop
        break
      // "GO TO MY SHOP"
      case OBJECT_STATUSES.confirmed:
      case OBJECT_STATUSES.active:
      case OBJECT_STATUSES.reported:
        shopMutationSAFE[0].title = i18n.t('settings.sectionLinks.shop')
        optionsToRender = shopMutationSAFE
        break
      case OBJECT_STATUSES.needsReview:
        shopMutationSAFE[0].title = i18n.t(
          'settings.sectionLinks.shopPendingApproval',
        )
        shopMutationSAFE[0].onPress = () => {}
        optionsToRender = [shopMutationSAFE[0]]
        break
      case OBJECT_STATUSES.inactive:
        shopMutationSAFE[0].title = i18n.t('settings.sectionLinks.shopPaused')
        optionsToRender = shopMutationSAFE
        break
      case OBJECT_STATUSES.disabled:
        // NOTE: Users should be able to re-apply after there shop application has been rejected
        // TODO: Implement logic necessary to ready a user to apply again for a shop
        const userShop = _.get(currUserShopHandler, 'currentUser.ownedProfile.profile', {})
        noShopMutationSAFE[0].title = i18n.t('settings.sectionLinks.reApply')
        noShopMutationSAFE[0].onPress = () => this.props.goToEditShop(userShop, currUserShopHandler.refetch)
        optionsToRender = noShopMutationSAFE
        break
      default:
        optionsToRender = null
        break
    }

    return (
      <View>
        <View style={styles.spacer}>
          <Text style={styles.subLabel}>
            <Text>
              {i18n.t('settings.sectionTitles.myShop') +
                (needsReview
                  ? i18n.t('settings.sectionTitles.requiresAction')
                  : '')}
            </Text>
          </Text>
        </View>
        {this.settingsSection(optionsToRender)}
      </View>
    )
  }

  goToCart = () => {
    NavigationActions.navigate({ routeName: 'Cart' })
  }

  goToUserOrders = () => {
    const { currentUserHandler: currentUser } = this.props
    NavigationActions.navigate({
      routeName: ROUTE_USER_ORDER_HISTORY,
      params: { userId: currentUser.id },
    })
  }

  gotoShopOrders = () => {
    const shopId = _.get(
      this.props,
      'currUserShopHandler.currentUser.ownedProfile.profile.id',
      '',
    )
    if (!shopId) {
      console.error('User does not own Shop')
      return
    }
    NavigationActions.navigate({
      routeName: ROUTE_SHOP_ORDER_HISTORY,
      params: { userId: shopId },
    })
  }
}

Settings.propTypes = {
  goToPaymentMethods: PropTypes.func,
  goToStripeConnect: PropTypes.func,
  goToShop: PropTypes.func,
  goToAddressesList: PropTypes.func,
  goToPushNotifications: PropTypes.func,
  logout: PropTypes.func,
  goToShopOnboarding: PropTypes.func,
  goToAbout: PropTypes.func,
  goToSupport: PropTypes.func,
  onOpenSharingSheet: PropTypes.func,
  goToAdminDashboard: PropTypes.func,
  goToPendingVerification: PropTypes.func,
  goToTesting: PropTypes.func,
  goToManageUsers: PropTypes.func,
  goToCoupons: PropTypes.func,
  goToNotices: PropTypes.func,
  goToEditShop: PropTypes.func,
  goToStart: PropTypes.func,
  dispatch: PropTypes.func,
  currentUser: PropTypes.any,
  currUserShop: PropTypes.any,
  layout: PropTypes.any,
}

const mapStateToProps = (state) => ({
  layout: state.screenInfo.layout,
  // currentUser: state.currentUser,
  cuid: _.get(state, 'currentUser.id'),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  logout: (userId) => { logout(userId) },
  goToEditProfile: ({ id, name, profileImage }) =>
    NavigationActions.navigate({
      routeName: 'EditProfile',
      params: { id, name, profileImage },
    })
  ,
  goToAddressesList: () =>
    NavigationActions.navigate({
      routeName: 'AddressesList',
    })
  ,
  goToContact: () =>
    NavigationActions.navigate({ routeName: 'Contact' }),
  goToAbout: () => NavigationActions.navigate({ routeName: 'About' }),
  goToPushNotifications: () =>
    NavigationActions.navigate({ routeName: 'PushNotifications' }),
  goToSupport: () =>
    NavigationActions.navigate({ routeName: 'Support' }),
  goToAdminDashboard: () =>
    NavigationActions.navigate({ routeName: 'AdminDashboard' }),
  goToAdminUsers: () =>
    NavigationActions.navigate({ routeName: 'AdminUsers' }),
  goToStart: () => NavigationActions.navigate({ routeName: 'Start' }),
  goToMaps: () => {
    NavigationActions.navigate({ routeName: 'Maps' })
  },
  goToShopOnboarding: (refetchSettings) => {
    NavigationActions.navigate({
      routeName: 'OnboardingShop',
      params: {
        refetchSettings,
      },
    })
  },
  goToEditShop: (shop, refreshShopData) => {
    NavigationActions.navigate({
      routeName: 'EditShop',
      params: {
        id: shop.id,
        shop,
        refreshShopData,
        reApply: true
      },
    })
  },
  goToShop: (id, title) => {
    NavigationActions.navigate({
      routeName: 'Shop',
      params: {
        id,
        title,
      },
    })
  },
  goToManageUsers: () => {
    NavigationActions.navigate({ routeName: 'AdminUsers' })
  },
  goToStripeConnect: () => {
    NavigationActions.navigate({ routeName: 'StripeConnect' })
  },
  goToPaymentMethods: () => NavigationActions.navigate({ routeName: ROUTE_PAYMENT_METHODS }),
  goToCoupons: () => {
    NavigationActions.navigate({ routeName: 'AdminCoupons' })
  },
  goToNotices: () => {
    NavigationActions.navigate({ routeName: 'AdminNotices'})
  }
})

const Composed = compose(
  graphql(userSchema.queries.getUserShop, { name: 'currUserShopHandler', options: { fetchPolicy: 'network-only' } }),
  graphql(userSchema.queries.currentUserFullProfile, { name: 'currentUserHandler', options: { fetchPolicy: 'network-only' } }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  // withCurrentUser,
  withShareInvite,
  withHandlers({
    handleGoToProfile: (props) => () => {
      const { id, name, profileImage } = props.currentUserHandler.currentUser
      props.goToEditProfile({ id, name, profileImage })
    },
  }),
)(Settings)

export default Composed
const styles = stylus({
  container: {
    flex: 1,
  },
  wrapper: {
    desktop: {
      paddingTop: 7,
    },
    ipad: {
      paddingTop: 9.5,
    },
    // paddingBottom: 8,
    // paddingTop: 15,
  },
  spacer: {
    // backgroundColor: '#F8F8F8',
    marginTop: 17,
    paddingBottom: 8,
    marginLeft: 24,
  },
  subLabel: {
    fontSize: 13,
    color: colors.text.secondary,
    alignItems: 'center',
    // paddingHorizontal: 15,
  },
  loginContainer: {
    maxWidth: 600,
    marginTop: 36,
    landscape: {
      paddingHorizontal: 22,
    },
    portrait: {
      paddingHorizontal: 12,
    },
  },
  inviteItemContent: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  inviteItemText: {
    fontSize: 16,
    color: colors.text.main,
    marginRight: 6,
    fontWeight: '500',
  },
  appVersion: {
    flex: 1,
    paddingTop: 24,
    alignItems: 'center',
  },
  graphic: { height: 70 },

})
