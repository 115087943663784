import React, { useState } from 'react'
import { SafeAreaView, View, Text, Alert, ActivityIndicator, FlatList, TouchableOpacity, ScrollView } from 'react-native'
import { HeaderBackButton } from 'react-navigation-stack'
import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'
import moment from 'moment'
import i18n from 'i18n-js'
import { get, chunk } from 'lodash'
import { compose, hoistStatics } from 'recompose'
import { graphql } from 'react-apollo'
import PropTypes from 'prop-types'
import { Ionicons } from '@expo/vector-icons'

import Modal from '../../components/simple/Modal'
import couponSchema from '../../schema/coupon'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'
import colors from '../../config/colors'
import NavigationActions from '../../utility/navigationActions'
import Button from '../../components/simple/Button'
import { stylus } from '../../config/styles'
import { isNumeric } from '../../config/validate'
import sizes from '../../config/sizes'

function CouponUpload({ uploadManyCoupons }) {
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [read, setRead] = useState(null)
  const [result, setResult] = useState(null)

  const [modals, setModals] = useState({
    duplications: false,
    validations: false,
    exists: false,
    errors: false,
    exceptions: false, 
  })

  async function handleChooseFile() {
    const picker = await DocumentPicker.getDocumentAsync()

    switch (picker.type) {
      case 'success':
        if (!picker.name.match(/(.csv)$/)) {
          Alert.alert('Error', 'Error selecting CSV file')
          break
        }

        setFile(picker)
        break
      case 'cancel':
        break
      default:
        Alert.alert('Error', 'Error selecting CSV file')
    }

    // "name": "IMG_20200408_150046.jpg",
    // "size": 200410,
    // "type": "success",
    // "uri": "file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540foodbarrio%252Ffoodbarrio-dev/DocumentPicker/68aaf7a5-0ba6-4749-98de-8bfa8f116bc3.jpg"
  }

  async function handleReadFile() {
    setLoading(true)
    const content = await FileSystem.readAsStringAsync(file.uri)

    try {
      const [stringHeader, ...lines] = content.split(/\r?\n/)

      const requiredColumns = ['name', 'description', 'percent', 'startDate', 'endDate']

      const header = (stringHeader.split(';')).reduce((object,column,index) => {
        object[column] = index
        return object
      }, {}) 

      requiredColumns.map(column => {
        if (!header.hasOwnProperty(column)) {
          throw Error(`Column ${column} is required and is missing`)
        }
      })

      const coupons = {}
      const duplications = []
      const validations = []

      for (const line of lines) {
        if (!line) continue

        const columns = line.split(';')

        const name = columns[header.name]

        if (coupons.hasOwnProperty(name)) {
          duplications.push(line)
          continue
        }

        const startDate = columns[header.startDate]

        if (!startDate.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/)) {
          validations.push({
            field: 'startDate',
            value: startDate,
            problem: 'format invalid',
            line
          })
          continue
        }

        const endDate = columns[header.endDate]

        if (!endDate.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/)) {
          validations.push({
            field: 'endDate',
            value: endDate,
            problem: 'format invalid',
            line
          })
          continue
        }

        const numbers = ['percent', 'redemptionLimitPerUser', 'redemptionLimitPerCoupon', 'checkoutAmountLimit'].reduce((valid, column) => {
          if (header.hasOwnProperty(column)) {
            if (!isNumeric(columns[header[column]])) {
              validations.push({
                field: column,
                value: columns[header[column]],
                problem: 'format invalid',
                line
              })
              valid = false
            }  
          }
          return valid
        }, true)

        if (!numbers) {
          continue
        }

        let group = get(columns, String(header.group), null)
        if (group) {
          group = group.toUpperCase()
          const [first,...rest] = name.split('0')

          if (!rest.join() && !first && first.length !== 3 || first.toUpperCase() !== group.toUpperCase()) {
            validations.push({
              field: 'group',
              value: group,
              problem: 'format invalid',
              line
            })
            continue
          }
        }

        const coupon = {
          name,
          description: columns[header.description],
          percent: +columns[header.percent],
          startDate: moment(startDate).startOf('day').toDate(),
          endDate: moment(endDate).endOf('day').toDate(),
          redemptionLimitPerUser: +get(columns, `${header.redemptionLimitPerUser}`) || 1,
          redemptionLimitPerCoupon:  +get(columns, `${header.redemptionLimitPerCoupon}`) || 1,
          checkoutAmountLimit: +get(columns, `${header.checkoutAmountLimit}`) || 200,
          group,
        }

        coupons[name] = coupon
      }

      setRead({
        coupons,
        duplications,
        validations
      })
    } catch (err){
      Alert.alert('Error', err.message)
    }

    setLoading(false)
  }

  async function handleUpload() {

    setLoading(true)

    const calls = chunk(Object.values(read.coupons), 200)
    const _result = {
      errors: [],
      exists: [],
      success: 0,
      exceptions: []
    }

    for (const call of calls) {
      try {
        const { data } = await uploadManyCoupons({
          variables: {
            coupons: call,
          }
        })

        _result.errors = [..._result.errors, ...data.uploadManyCoupons.errors]
        _result.exists = [..._result.exists, ...data.uploadManyCoupons.exists]
        _result.success += data.uploadManyCoupons.success

      } catch (err) {
        _result.exceptions.push(err.message)
      }
    }

    setResult(_result)

    setLoading(false)
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <Text style={styles.title}>Instructions to prepare CSV File</Text>
        <Text>First line need to have columns name and all lines <Text style={styles.bold}>;</Text> separator</Text>
        <View style={styles.columns}>
          <Column type="string" required>name</Column>
          <Column type="string" required>description</Column>
          <Column type="float" required>percent</Column>
          <Column type="integer" defaultValue={1}>redemptionLimitPerUser</Column>
          <Column type="integer" defaultValue={1}>redemptionLimitPerCoupon</Column>
          <Column type="float" defaultValue={200}>checkoutAmountLimit</Column>
          <Column type="date" required format="YYYY-MM-DD">startDate</Column>
          <Column type="date" required format="YYYY-MM-DD">endDate</Column>
          <Column format="[ggg/0/rest] of name">group</Column>
        </View>
        <Text>CSV parse and first validations are made in the client</Text>

        {!file && (
          <Button
            label="Choose file to upload"
            style={styles.button}
            onPress={handleChooseFile}
          />
        )}

        {file && (
          <>
            <View style={styles.center}>
              <Text style={styles.bold}>{file.name}</Text>
              <Text>{prettySize(file.size)}</Text>
            </View>

            {! read && !loading && (
              <Button
                label="Process file"
                style={styles.button}
                onPress={handleReadFile}
              />
            )}
          </>
        )}
        
        {read && (
          <>
            <View style={styles.center}>
              <Text>
                <Text style={styles.bold}>{Object.keys(read.coupons).length}</Text> Valid coupons
              </Text>
              <TouchableOpacity onPress={() => setModals({...modals, duplications: true})} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <Text>
                  <Text style={styles.bold}>{read.duplications.length}</Text> duplications
                </Text>
                {read.duplications.length > 0 && (
                  <Ionicons name="md-open" size={20} color="black" style={{marginLeft: 15}} />
                )}
              </TouchableOpacity>

              {read.duplications.length > 0 && (
                <Details
                  visible={modals.duplications}
                  setVisibilty={() => setModals({...modals, duplications: false})}
                  title="Duplications"
                >
                  <FlatList
                    data={read.duplications}
                    keyExtractor={(item, index) => String(index)}
                    renderItem={({item}) => (
                      <View style={styles.item}>
                        <Text>{item}</Text>
                      </View>
                    )}
                  />
                </Details>
              )}
    
              <TouchableOpacity
                onPress={() => setModals({...modals, validations: true})}
                style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
              >
                <Text>
                  <Text style={styles.bold}>{read.validations.length}</Text> validations fails
                </Text>
                {read.validations.length > 0 && (
                  <Ionicons name="md-open" size={20} color="black" style={{marginLeft: 15}} />
                )}
              </TouchableOpacity>

              {read.validations.length > 0 && (
                <Details
                  visible={modals.validations}
                  setVisibilty={() => setModals({...modals, validations: false})}
                  title="Validations"
                >
                  <FlatList
                    data={read.validations}
                    keyExtractor={(item, index) => String(index)}
                    renderItem={({item}) => (
                      <View style={styles.item}>
                        <Text>
                          <Text style={{fontWeight: 'bold'}}>{item.field}</Text>: &quot;{item.value}&quot;  {item.problem}
                        </Text>
                        <Text>{item.line}</Text>
                      </View>
                    )}
                  />
                </Details>
              )}
            </View>

            {!result&& !loading && (
              <Button
                label="Upload"
                style={styles.button}
                onPress={handleUpload}
              />
            )}
          </>
        )}

        {result && (
          <View style={styles.center}>
            <Text>
              <Text style={styles.bold}>{result.success}</Text> new coupons
            </Text>

            <TouchableOpacity
              onPress={() => setModals({...modals, exists: true})}
              style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
            >
              <Text>
                <Text style={styles.bold}>{result.exists.length}</Text> duplicate(s) have been skipped
              </Text>
              {result.exists.length > 0 && (
                <Ionicons name="md-open" size={20} color="black" style={{marginLeft: 15}} />
              )}
            </TouchableOpacity>

            {result.exists.length > 0 && (
              <Details
                visible={modals.exists}
                setVisibilty={() => setModals({...modals, exists: false})}
                title="Duplicate(s) have been skipped"
              >
                <FlatList
                  data={result.exists}
                  keyExtractor={(item, index) => String(index)}
                  renderItem={({item}) => (
                    <View style={styles.item}>
                      <Text>
                        {item}
                      </Text>
                    </View>
                  )}
                />
              </Details>
            )}

            <TouchableOpacity
              onPress={() => setModals({...modals, errors: true})}
              style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
            >
              <Text>
                <Text style={styles.bold}>{result.errors.length}</Text> errors
              </Text>
              {result.errors.length > 0 && (
                <Ionicons name="md-open" size={20} color="black" style={{marginLeft: 15}} />
              )}
            </TouchableOpacity>

            {result.errors.length > 0 && (
              <Details
                visible={modals.errors}
                setVisibilty={() => setModals({...modals, errors: false})}
                title="Errors"
              >
                <FlatList
                  data={result.errors}
                  keyExtractor={(item, index) => String(index)}
                  renderItem={({item}) => (
                    <View style={styles.item}>
                      <Text style={{ fontWeight: 'bold'}}>
                        {item.name}
                      </Text>
                      <Text>{item.error}</Text>
                    </View>
                  )}
                />
              </Details>
            )}

            {result.exceptions.length > 0 && (
              <>
                <TouchableOpacity
                  onPress={() => setModals({...modals, exceptions: true})}
                  style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
                >
                  <Text style={{ color: '#f00'}}>
                    <Text style={styles.bold}>{result.exceptions.length}</Text> exceptions
                  </Text>
                  {result.exceptions.length > 0 && (
                    <Ionicons name="md-open" size={20} color="black" style={{marginLeft: 15}} />
                  )}
                </TouchableOpacity>


                <Details
                  visible={modals.exceptions}
                  setVisibilty={() => setModals({...modals, exceptions: false})}
                  title="Exceptions"
                >
                  <FlatList
                    data={result.exceptions}
                    keyExtractor={(item, index) => String(index)}
                    renderItem={({item}) => (
                      <View style={styles.item}>
                        <Text>{item}</Text>
                      </View>
                    )}
                  />
                </Details>
              </>
            )}

          </View>
        )}

        {loading && <ActivityIndicator size="large" color={colors.primary} />}
      </ScrollView>
    </SafeAreaView>
  )
}

function Details({ visible, setVisibilty, title, children }) {
  return (
    <Modal
      animationType='slide'
      visible={visible}
      onRequestClose={setVisibilty}
    >
      <View style={{ flex: 1, marginVertical: 10, marginHorizontal: 10 }}>
        <Text style={[styles.title, {marginBottom: 5}]}>{title}</Text>
        {children}
        <Button
          label="Close"
          style={styles.button}
          onPress={setVisibilty}
        />
      </View>
    </Modal>
  )
}

function Column({ children, type, required, format, defaultValue}) {
  return (
    <View style={styles.column}>
      <Text style={{ fontWeight: 'bold'}}>{children}</Text>
      <View style={{flexDirection: 'row'}}>
        <Text style={{ marginRight: 5}}>{type}</Text>        
        <Text>[</Text>
        {required && <Text style={{ marginHorizontal: 2, color: '#600'}}>required</Text>}
        {format && <Text style={{ marginHorizontal: 2, color: '#006'}}>format: {format}</Text>}
        {defaultValue && <Text style={{ marginHorizontal: 2, color: '#666'}}>default: {defaultValue}</Text>}
        {!required && <Text style={{ marginHorizontal: 2, color: '#060'}}>optional</Text>}
        <Text>]</Text>
      </View>
    </View>
  )
}

const styles = stylus({
  container: {
    flex: 1,
    paddingHorizontal: 10,
    native: {
      width: '100%',
      height: '100%',
    },
    paddingBottom: sizes.tabBarHeight,
    iphonex: {
      paddingBottom: sizes.iphonexTabBarHeight,
    },
  },
  columns: {
    paddingHorizontal: 5,
    marginBottom: 15,
  },
  column: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 2,
  },
  center: { 
    width: '100%', 
    marginTop: 15, 
    justifyContent: 'center', 
    alignItems: 'center',
    borderWidth: 0.5,
    paddingVertical: 10,
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: 16,
    marginVertical: 10,
  },
  bold: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  button: {
    backgroundColor: colors.primary,
    marginBottom: 10,
    marginTop: 20,
    marginHorizontal: 16,
    alignSelf: 'center'
  },

  item: {
    marginVertical: 2,
    paddingVertical: 2,
    paddingHorizontal: 5,
    backgroundColor: '#eee',
  },
})

function prettySize(bytes, separator = '', postFix = '') {
  if (bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1)
    return `${(bytes / (1024 ** i)).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`
  }
  return ''
}

CouponUpload.propTypes = {
  uploadManyCoupons: PropTypes.func.isRequired
}

CouponUpload.navigationOptions = ({ navigation }) => {
  return {
    title: i18n.t('settings.sectionLinks.uploadCoupons'),
    headerLeft: () => (
      <HeaderBackButton
        onPress={() => {
        NavigationActions.back()
        }}
        tintColor={colors.black}
      />
    ),
  }
}

const enhanced = compose(
  WithKeyboardInfo,
  graphql(couponSchema.mutations.uploadManyCoupons, {
    name: 'uploadManyCoupons',
  }),
)
export default hoistStatics(enhanced)(CouponUpload)
