import React from 'react'
import { View, Text } from 'react-native'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import T from '../../components/T'
import NamedImage from '../../components/simple/NamedImage'

const imageContainerSize = 100
const imageSize = imageContainerSize - 10

export default function AcceptedInvitation({
  state,
  getMessage
}) {
  const { inviter } = state
  return (
    <View style={styles.container}>
      <View style={[styles.imageContainer]}>
        <NamedImage
          style={styles.profileImage}
          name={inviter.profileImage}
          width={imageSize}
          height={imageSize}
          fit='crop'
          crop='faces'
          resizeMode='cover'
          resizeMethod='scale'
          placeholder='profile'
        />
      </View>
      <Text style={styles.usernameText}>{inviter.username}</Text>
      <T t='onboardingUser.acceptedInvitation.title' style={[styles.title, styles.marginBump]} />
      <T
        t='onboardingUser.acceptedInvitation.titleSubtext'
        style={styles.subtext}
      />
    </View>
  )
}

const styles = stylus({
  container: {
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  marginBump: {
    marginTop: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.text.main,
  },
  usernameText: {
    fontSize: 18,
    color: colors.text.main,
    marginTop: 10,
  },
  subtext: {
    marginTop: 10,
    fontSize: 15,
    fontWeight: '300',
    textAlign: 'center',
    color: colors.text.main,
  },
  profileImage: {
    width: imageSize,
    height: imageSize,
    borderRadius: imageSize / 2,
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: imageContainerSize,
    width: imageContainerSize,
    borderWidth: 1,
    borderColor: '#CCC',
    borderRadius: imageContainerSize / 2,
    padding: 4,
    overflow: 'hidden',
  },
})
