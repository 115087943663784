import React from 'react'
import {
  TouchableOpacity,
  Platform,
  Keyboard
} from 'react-native'
import PropTypes from 'prop-types'
import * as Permissions from 'expo-permissions'
import * as ImagePicker from 'expo-image-picker'
import * as ImageManipulator from 'expo-image-manipulator'
import * as VideoThumbnails from 'expo-video-thumbnails'
import Constants from 'expo-constants'
import withActionSheet from '../../containers/ActionSheet/withActionSheet'
import withCurrentUser from '../../containers/withCurrentUser'
import alert from '../../utility/alert'
import branch from '../../config/branch'
import environment from '../../config/environment'
import { getFeatureFlag, isThumbnail } from '../../config/helpers'

import uuid from 'uuid/v4'
import i18n from 'i18n-js'
import mimeResolver from 'mime'
import _ from 'lodash'

const videoUpload = getFeatureFlag('videoUpload')
const debug = false
const isWeb = Platform.OS === 'web'

const iosPermissionErrorCode = 'E_MISSING_PERMISSION'
const androidPermissionErrorCode = 'E_UNKNOWN_ERROR'
const videoSizeError = 'FILE_SIZE_ERROR'
const videoDurationError = 'VIDEO_DURATION_ERROR'
const permissionErrorCodes = [
  iosPermissionErrorCode,
  androidPermissionErrorCode,
  videoSizeError,
  videoDurationError
]
const isGranted = (permissionResult) => permissionResult.status === 'granted' || isWeb
// data:image/jpeg;base64,ads94589qdjksf9w34rjfdkf
const getWebInlineUriMime = (uri) => {
  const uriMinusData = uri.split(':')[1]
  return uriMinusData.split(';')[0]
}

const getExtension = (uri) => {
  if (isWeb) {
    const mime = getWebInlineUriMime(uri)
    return mimeResolver.getExtension(mime)
  }
  const uriParts = uri.split('.')
  return _.toLower(uriParts[uriParts.length - 1])
}

const getMime = (uri) => {
  if (isWeb) {
    return getWebInlineUriMime(uri)
  }
  const extension = getExtension(uri)
  return mimeResolver.getType(extension)
}

const getWebImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = file.uri
    img.onload = () => {
      const width = img.naturalWidth
      const height = img.naturalHeight
      resolve({ width, height })
    }
  })
}

const isVideo = (uri) => {
  const mime = getMime(uri)
  return mime.indexOf('video/') === 0
}

const isImage = (uri) => {
  const mime = getMime(uri)
  return mime.indexOf('image/') === 0
}

const showPromptForError = (error, translation) => {
  const { code } = error
  const requiresPermissions = permissionErrorCodes.indexOf(code) !== -1
  if (requiresPermissions) {
    alert({
      title: i18n.t('common.error'),
      message: i18n.t(translation, {
        app: branch({
          expo: 'Expo',
          other: Constants.manifest.name,
        }),
        camera: i18n.t('common.camera'),
        cameraRoll: branch({
          ios: i18n.t('common.photos'),
          android: i18n.t('common.storage')
        }),
        maxDuration: environment.videoUploadLimits.maxDuration / 1000
      })
    })
  }
}

@withActionSheet
@withCurrentUser
class UploadButton extends React.Component {
  componentDidMount() {
    // this.getCameraPermissions
  }
  render() {
    const { style, disabled, children, onChange, onLocalImage } = this.props
    return (
      <TouchableOpacity
        style={style}
        onPress={disabled ? null : this.openActionSheet}
      >
        {children}
      </TouchableOpacity>
    )
  }
  openActionSheet = async () => {
    if (isWeb) {
      return this.launchLibrary('All')
    }

    const isShopOwner = _.get(this.props, 'currentUser.currentUser.organizationId', false)
    const isAdmin = _.get(this.props, 'currentUser.currentUser.isAdmin', false)
    const correctUserType = isShopOwner || isAdmin
    const { allowVideo, allowImage } = this.props
    Keyboard.dismiss()
    const camera = i18n.t('upload.photo')
    const photo = i18n.t('upload.library')
    const video = i18n.t('upload.video')
    const options = []

    allowImage && options.push(...[camera, photo])

    videoUpload && correctUserType && allowVideo && options.push(video)
    options.push(i18n.t('common.cancel'))

    const cancelButtonIndex = options.length-1

    this.props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (idxPressed) => {
        switch (idxPressed) {
          case options.indexOf(camera):
            return this.launchCamera()
          case options.indexOf(photo):
            return this.launchLibrary('Images')
          case options.indexOf(video):
            return this.launchLibrary('Videos')
          default:
            return null
        }
      },
    )
  }

  launchCamera = async () => {
    // NOTE: This defaults to true since for Android launching the Camera triggers permission prompts
    let granted = true

    // iOS Step 1 //
    // Camera and Camera Roll permissions must always be requested on iOS
    // iOS will only show each permission request system prompt only once.
    // If permission prompts are not appearing, uninstall
    if (Platform.OS === 'ios') {
      const result = await Permissions.askAsync(Permissions.CAMERA, Permissions.CAMERA_ROLL)
      debug && console.log('<<<UPLOAD BUTTON - CAMERA PERMISSION', result)
      granted = isGranted(result)
    }

    let file = null
    try {
      // Android Step 1 & 2 //
      // Camera and Camera Roll permission request system prompts will be shown
      // each time we attempt to launch the camera as long as "Don't show again" has not been selected.
      // If "Don't show again" has been selected, toggle the permissions to enabled and then back to disabled to see system prompts again.
      // iOS Step 2 //
      // Launch the camera since we have already asked for permissions in iOS Step 1
      file = await ImagePicker.launchCameraAsync({
        mediaTypes: 'Images',
        quality: 0.2,
      })
    } catch (e) {
      debug && console.log('<<<UPLOAD BUTTON - CAMERA ERROR', e)
      for (const key in e) {
        const value = e[key]
        debug && console.log(`<<<UPLOAD BUTTON - CAMERA ERROR - ${key}`, value)
      }
      // iOS & Android
      // If the error's code matches the expected for Android/iOS then an app prompt will be displayed
      // telling the user to enable the required permissions from their device's Settings app
      showPromptForError(e, 'upload.cameraPermissionPrompt')
      return
    }

    debug && console.log('<<<UPLOAD BUTTON - CAMERA FILE', file)

    if (granted && !file.cancelled) {
      this.handleResult(file)
    }
  }
  hideLoader = () => {
    this.props.hideLoader && this.props.hideLoader()
  }
  launchLibrary = async (mediaTypes) => {
    const result = await Permissions.askAsync(Permissions.CAMERA_ROLL)
    const granted = isGranted(result)
    debug && console.log('<<<UPLOAD BUTTON - CAMERA ROLL PERMISSION', result)

    if (Platform.OS === 'android' && !granted) {
      showPromptForError({ code: androidPermissionErrorCode }, 'upload.cameraRollPermissionPrompt')
      return
    }
    this.props.showLoader && this.props.showLoader()

    let file = null
    try {
      file = await ImagePicker.launchImageLibraryAsync({
        mediaTypes,
        quality: 1,
      })
    } catch (e) {
      debug && console.log('<<<UPLOAD BUTTON - CAMERA ROLL ERROR', e)
      this.hideLoader()
      showPromptForError(e, 'upload.cameraRollPermissionPrompt')
      return
    }
    debug && console.log('<<<UPLOAD BUTTON - CAMERA ROLL FILE', file)

    if (granted && !file.cancelled) {
      try {
        (mediaTypes == 'Videos' || isVideo(file.uri)) && await this.validateVideoSize(file.uri, file.duration)
        this.handleResult(file)
      } catch (e) {
        this.hideLoader()
        showPromptForError({code: e.code}, e.message)
      }
    } else {
      this.hideLoader()
    }
  }

  validateVideoSize = async (fileUri, duration) => {
    // const totalMaxSize = Math.ceil(duration/1000) * environment.videoUploadLimits.maxSizePerSecond
    // const info = await FileSystem.getInfoAsync(fileUri, {size:true})
    if (duration > environment.videoUploadLimits.maxDuration) throw {code: videoDurationError, message: 'upload.fileTooLong'}
    // if (info.size > totalMaxSize) throw {code: videoSizeError, message: 'upload.fileTooBig'}
  }

  getVideoThumbnailAndroid = async (file, parentVideoId) => {
    const { uri, width, height } = await ImageManipulator.manipulateAsync(
      file.uri,
      [],
      { compress: 0.5, format: ImageManipulator.SaveFormat.JPEG }
    )
    debug && console.log('Android/Web Thumbnail Dimensions', width, height)
    const uriParts = uri.split('.')
    const extension = _.toLower(uriParts[uriParts.length - 1])
    const thumbnail = {
      uri,
      parentVideoURI: file.uri,
      canceled: false,
      id: `thumbnail-${parentVideoId}.${extension}`,
      width,
      height,
    }
    await this.handleResult(thumbnail)
    return thumbnail
  }

  getVideoThumbnailIOS = async (file, parentVideoId) => {
    const { uri, width, height } = await VideoThumbnails.getThumbnailAsync(file.uri, {time: 1000, compress: 0.5})
    debug && console.log('iOS Thumbnail Dimensions', width, height)
    const uriParts = uri.split('.')
    const extension = _.toLower(uriParts[uriParts.length - 1])
    const thumbnail = {
      uri,
      parentVideoURI: file.uri,
      canceled: false,
      id: `thumbnail-${parentVideoId}.${extension}`,
      width,
      height,
    }
    await this.handleResult(thumbnail)
    return thumbnail
  }

  handleResult = async file => {
    if (file.uri && !file.cancelled) {
      const extension = getExtension(file.uri)
      let mime = getMime(file.uri)
      // This is a mime type override for videos uploaded to s3 which should always result in mp4
      const id = uuid()
      if (isWeb && isImage(file.uri)) {
        const dimensions = await getWebImageDimensions(file)
        file = { ...file, ...dimensions }
      }

      let thumbnail
      if (isVideo(file.uri)) {
        mime = 'video/mp4'
        thumbnail = await (Platform.OS === 'android' || Platform.OS === 'web' ? this.getVideoThumbnailAndroid(file, id) : this.getVideoThumbnailIOS(file, id))
      }
      debug && console.log('<<<UPLOAD BUTTON - thumbnail', thumbnail)
      file.mime = mime
      if (!file.id) file.id = `${id}.${extension}`
      debug && console.log('<<<UPLOAD BUTTON - handleResult for - ', file.id)
      // should only call hideLoader once, when thumbnail and video are handled
      this.props.onChange({
        file, // this is reduntant but need to make it same as web version
        mime,
        id: file.id,
        uri: file.uri,
        width: thumbnail ? thumbnail.width : file.width,
        height: thumbnail ? thumbnail.height : file.height,
      })

      if (!isThumbnail(file.id)) {
        this.hideLoader()
      }
    }
  }
}

UploadButton.propTypes = {
  allowVideo: PropTypes.bool,
  allowImage: PropTypes.bool
}
UploadButton.defaultProps = {
  allowVideo: false,
  allowImage: true
}
export default UploadButton
