import React from 'react'
import { PropTypes } from 'prop-types'
import { View, Text, TouchableOpacity } from 'react-native'
import NavigationActions from '../../utility/navigationActions'

import Svg from '../../components/simple/Svg'
import T from '../T'
import ShopSvgXml from '../../assets/svgs/shop-icon-smaller.svg'

import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import { connect, _get } from '../../config/connected'
import branch from '../../config/branch'
import schema from './schema'
import { graphql } from '../../config/connected'
import { OBJECT_STATUSES } from '../../config/constants'
import _ from 'lodash'

const visibleStates = [OBJECT_STATUSES.active, OBJECT_STATUSES.reported]
const mapDispatchToProps = (dispatch) => ({ dispatch })

@connect(
  null,
  mapDispatchToProps,
)
@graphql(schema.queries.user, {
  name: 'user',
  options: (props) => ({ variables: { id: props.organizationId || '' } }),
})
class UserTitle extends React.PureComponent {
  shouldRender = () => {
    return _.includes(
      visibleStates,
      _get(this.props.user, 'userById.status', 'disabled'),
      null,
    )
  }
  render() {
    if (!this.shouldRender()) return null
    const {
      style,
      title,
      organization,
      organizationId,
      linkToShop = true,
      size = 12,
      color = colors.text.secondary,
      deleted = false
    } = this.props
    if (!title || !organization || !organizationId) return null // no title to render if one is missing
    const mergedStyle = {
      ...styles.icon,
      ...branch({ android: { paddingTop: 3 }, other: {} }),
    }
    const ShopIcon = (
      <Svg style={mergedStyle} width={size} height={size} xml={ShopSvgXml} />
    )
    const Container = linkToShop ? TouchableOpacity : View
    const props = { style: [styles.container, style] }
    if (linkToShop && deleted === false) props.onPress = this.goToShop
    return (
      <Container {...props} >
        {/* { ShopIcon } */}
        <Text style={{ fontSize: size, color, flex: 1}} numberOfLines={1}>
          <Text>{`${title} `}</Text>
          <T t='common.at' />
          <Text
            style={[linkToShop && styles.organizationText]}
          >{` ${organization}`}</Text>
        </Text>
      </Container>
    )
  }
  goToShop = () => {
    const { dispatch, organizationId, organization } = this.props
    NavigationActions.navigate({
      routeName: 'Shop',
      params: { id: organizationId, pageTitle: organization},
    })
  }
}

UserTitle.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  organization: PropTypes.string,
  organizationId: PropTypes.string,
  linkToShop: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
}

export default UserTitle

const styles = stylus({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 2,
  },
  organizationText: {
    color: colors.taggingText,
  },
})
