import React from 'react'
import { Platform } from 'react-native'
import TextareaAutosize from 'react-autosize-textarea'
import _ from 'lodash'

import branch from '../../config/branch'
import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import LayoutAnimation from '../LayoutAnimation'
import TextInput from '../ReturnKeyCompatibilityTextInput/ReturnKeyCompatibilityTextInput'

const debug = false

class TextArea extends React.Component {
  constructor(props) {
    super(props)
    const { minHeight = 12 } = this.props

    this.state = {
      inputStyle: { height: minHeight },
      initialHeight: undefined,
      lastHeight: 0,
      lastValue: undefined,
      currentValue: undefined,
    }
  }
  componentDidMount() {
    if (this.props.autoFocus === true) {
      this.input.focus()
    }
    this.mountedAt = new Date()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value: lastValue } = prevProps
    const { value: currentValue } = this.props
    if (lastValue !== currentValue) {
      const stateChanges = { lastValue, currentValue }
      if (lastValue !== '' && currentValue === '') {
        debug && console.log('<<<TEXT AREA - componentDidUpdate - Allow change to initial height')
        debug && console.log('<<<TEXT AREA - componentDidUpdate - Last Value', lastValue)
        debug && console.log('<<<TEXT AREA - componentDidUpdate - Current Value', currentValue)
        stateChanges.allowChangeToInitialHeight = true
      }
      this.setState(stateChanges)
    }
  }

  render() {
    return branch({
      web: this.webInput,
      native: this.nativeInput,
    })
  }
  webInput = () => {
    const { placeholder, webStyle = {} } = this.props

    return (
      <TextareaAutosize
        onChange={this.onHtmlInputChange}
        placeholder={placeholder}
        style={{
          width: '100%',
          borderColor: 'transparent',
          borderWidth: 0,
          padding: '6px 10px',
          fontSize: '14px',
          resize: 'none',
          ...webStyle,
        }}
        innerRef={(ref) => (this.input = ref)}
      />
    )
  }

  reference=(ref) => {
    this.input = ref
  }

  nativeInput = () => {
    const { inputStyle } = this.state
    const {
      onFocus,
      onBlur,
      placeholder,
      placeholderTextColor = colors.input.placeholder,
      returnKeyType = 'done',
      nativeStyle,
      ...rest
    } = this.props

    return (
      <TextInput
        {...rest}
        reference={this.reference}
        multiline={true}
        onChangeText={this.onChangeText}
        onContentSizeChange={this.onContentSizeChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        returnKeyType={returnKeyType}
        style={[styles.nativeInput, inputStyle, nativeStyle]}
        underlineColorAndroid='transparent'
      />
    )
  }
  clear = () => {
    this.props.onClear && this.props.onClear()
    branch({
      web: () => (this.input.value = ''),
      native: () => this.input.clear(),
    })
  }
  onChangeText = (value) => {
    // native handler
    this.props.onChangeText && this.props.onChangeText(value)
  }
  onHtmlInputChange = (event) => {
    // web handler
    this.props.onChange && this.props.onChange({ nativeEvent: { text: event.target.value } })
    this.props.onChangeText && this.props.onChangeText(event.target.value)
  }
  onContentSizeChange = (e) => {
    const twoSecondsElapsed = new Date() - this.mountedAt > 2000
    const { height } = e.nativeEvent.contentSize
    const { initialHeight, allowChangeToInitialHeight, lastHeight, lastValue, currentValue } = this.state
    debug && console.log('<<<TEXT AREA - onContentSizeChange - Last Value', lastValue)
    debug && console.log('<<<TEXT AREA - onContentSizeChange - Current Value', currentValue)
    debug && console.log('<<<TEXT AREA - onContentSizeChange - Last vs Current Height', lastHeight, height)
    debug && console.log('<<<TEXT AREA - onContentSizeChange - Initial Height', initialHeight)
    const heightsAreSame = Math.round(initialHeight) === Math.round(height)
    const isDuplicateInitialHeightEvent = heightsAreSame && !allowChangeToInitialHeight
    const heightIsBiggerButTextIsNot = _.get(currentValue, 'length', 0) === 0 && height > lastHeight
    const valueIsEmptyButHeightIsNotInitial = _.get(currentValue, 'length', 0) === 0 && height != initialHeight
    if (Platform.OS === 'android' && initialHeight && (isDuplicateInitialHeightEvent || heightIsBiggerButTextIsNot || valueIsEmptyButHeightIsNotInitial) ) {
      if ((heightIsBiggerButTextIsNot || valueIsEmptyButHeightIsNotInitial ) && allowChangeToInitialHeight) {
        debug && console.log('<<<TEXT AREA - onContentSizeChange - Setting height back to initial')
        this.setState({ allowChangeToInitialHeight: false, lastHeight: initialHeight, inputStyle: { height: initialHeight + 5 } }, () => {
          if (twoSecondsElapsed) {
            this.animate()
          }
        })
      } else {
        // There is a bug with TextInput on React Native 61 on Android 
        // where clearing the text causes a cascade of duplicate events, 
        // indicating the initial height of the TextInput.
        // So if that is the case we simply return so that we don't animate or adjust the height.
        debug && console.log('<<<TEXT AREA - Ignoring duplicate content size change event')
      }
      return 
    }
    // if (height < min)
    const { maxHeight = 1000000, minHeight = 12 } = this.props
    const newHeight = Math.min(Math.max(height, minHeight), maxHeight)
    debug && console.log('<<<TEXT AREA - onContentSizeChange - New Height', newHeight)
    if (twoSecondsElapsed) {
      // easy fix for too many animations during initial render
      this.animate()
    }
    const extraStateChanges = {}
    if (!initialHeight) {
      extraStateChanges.initialHeight = height
    }
    if (allowChangeToInitialHeight && heightsAreSame) {
      debug && console.log('<<<TEXT AREA - onContentSizeChange - Disallow change to initial height')
      extraStateChanges.allowChangeToInitialHeight = false
    }
    this.setState({
      ...extraStateChanges,
      lastHeight: newHeight,
      inputStyle: { height: newHeight + 5 },
    })
  }
  animate() {
    if (this.props.animate != false) {
      LayoutAnimation &&
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    }
  }
}

export default TextArea

const styles = stylus({
  container: {
    flexDirection: 'row',
  },
  nativeInput: {
    maxHeight: 200
  },
})
