import React from 'react'
import { Dimensions } from 'react-native'
import {
  TabRouter,
  createNavigator,
} from '@react-navigation/core'
import { createBottomTabNavigator } from 'react-navigation-tabs'

import Icon from '../config/icon'
import Icons from '@expo/vector-icons/Ionicons'

import SearchRouter from './sections/SearchRouter'
import HomeRouter from './sections/FeedRouter'
import ShoppingRouter from './sections/ShoppingRouter'
import SettingsRouter from './sections/SettingsRouter'
import MyProfileRouter from './sections/MyProfileRouter'
import NotificationsRouter from './sections/NotificationsRouter'

import ChatRouter from './sections/ChatRouter'
import selectLayout from './selectLayout'
import LandscapeTabLayout from './landscape/LandscapeTabLayout'
import MainTabs from './portrait/MainTabs'
import CartIcon from '../components/simple/CartIcon'
import NotificationsIcon from '../components/simple/NotificationsIcon'

import { getTabBarIconTintColor, iconSize, getHomeRoute } from '../config/helpers'

// Refer to AZ#1315 feature branch to see how some of the routes were disabled for web app
const webRoutes = {
  MyProfile: {
    screen: MyProfileRouter,
    path: 'profile',
    navigationOptions: {
      tabBarLabel: 'Profile',
      tabBarIcon: ({ tintColor, focused }) => (
        <Icon
          name={focused ? 'profile' : 'profile-outline'}
          size={34}
          color={getTabBarIconTintColor(tintColor, focused)}
          style={{ margin: -3 }}
        />
      ),
    },
  },
  Chat: {
    screen: ChatRouter,
    path: 'chat',
    navigationOptions: {
      tabBarLabel: 'Chat',
      tabBarIcon: ({ tintColor, focused }) => (
        <Icon
          name='send-outline'
          size={iconSize()}
          color={getTabBarIconTintColor(tintColor, focused)}
          style={{ margin: -3 }}
        />
      ),
    },
  },
  MyNotifications: {
    screen: NotificationsRouter,
    path: 'notifications',
    navigationOptions: {
      tabBarLabel: 'Notifications',
      tabBarIcon: ({ tintColor, focused }) => (
        <NotificationsIcon
          tintColor={getTabBarIconTintColor(tintColor, focused)}
          focused={focused}
          iconSize={iconSize()}
        />
      ),
    },
  },
  MySettings: {
    screen: SettingsRouter,
    path: 'settings',
    navigationOptions: {
      tabBarLabel: 'Settings',
      tabBarIcon: ({ tintColor, focused }) => (
        // <Icon name={focused ? 'more' : 'more'} size={20} color={tintColor} />
        <Icons
          name={'ios-settings'}
          size={31}
          color={getTabBarIconTintColor(tintColor, focused)}
          style={{ margin: -3 }}
        />
      ),
    },
  },
}

const landscapeRoutes = {
  MyHome: {
    screen: HomeRouter,
    path: 'home',
    title: 'Home',
    navigationOptions: {
      tabBarLabel: 'Home',
      tabBarIcon: ({ tintColor, focused }) => (
        <Icon
          name={focused ? 'home' : 'home-outline'}
          size={32}
          color={getTabBarIconTintColor(tintColor, focused)}
          style={{ margin: -3 }}
        />
      ),
    },
  },
  MySearch: {
    screen: SearchRouter,
    path: 'search',
    title: 'Search',
    navigationOptions: {
      tabBarLabel: 'Search',
      tabBarIcon: ({ tintColor, focused }) => (
        <Icon
          name={focused ? 'search' : 'search-outline'}
          size={36}
          color={getTabBarIconTintColor(tintColor, focused)}
          style={{ margin: -3 }}
        />
      ),
    },
  },
  MyShopping: {
    screen: ShoppingRouter,
    path: 'shopping',
    title: 'Shopping',
    navigationOptions: {
      tabBarLabel: 'Shopping',
      tabBarIcon: ({ tintColor, focused }) => (
        <Icon
          name={focused ? 'cart' : 'cart-outline'}
          size={32}
          color={getTabBarIconTintColor(tintColor, focused)}
          style={{ margin: -3 }}
        />
      ),
    },
  },
  MyProfile: {
    screen: MyProfileRouter,
    path: 'profile',
    title: 'My Profile',
    navigationOptions: {
      tabBarLabel: 'My Profile',
      tabBarIcon: ({ tintColor, focused }) => (
        <Icon
          name={focused ? 'profile' : 'profile-outline'}
          size={34}
          color={getTabBarIconTintColor(tintColor, focused)}
          style={{ margin: -3 }}
        />
      ),
    },
  },
  // Chat: {
  //   screen: ChatRouter,
  //   path: 'chat',
  //   navigationOptions: {
  //     tabBarLabel: 'Chat',
  //     skipLink: true,
  //     tabBarIcon: ({ tintColor, focused }) => (
  //       // <Icon name={focused ? 'chat' : 'chat'} size={22} color={tintColor} />
  //       <Icon
  //         name={'chat-outline'}
  //         size={32}
  //         color={getTabBarIconTintColor(tintColor, focused)}
  //       />
  //     ),
  //   },
  // },
  MySettings: {
    screen: SettingsRouter,
    path: 'settings',
    navigationOptions: {
      tabBarLabel: 'Settings',
      tabBarIcon: ({ tintColor, focused }) => (
        // <Icon name={focused ? 'more' : 'more'} size={20} color={tintColor} />
        <Icons
          name={'ios-settings'}
          size={31}
          color={getTabBarIconTintColor(tintColor, focused)}
          style={{ margin: -3 }}
        />
      ),
    },
  },
}

const mobileRoutes = {
  MyHome: {
    screen: HomeRouter,
    path: 'home',
    navigationOptions: {
      tabBarLabel: 'Home',
      tabBarIcon: ({ tintColor, focused }) => (
        <Icon
          name={focused ? 'home' : 'home-outline'}
          size={iconSize()}
          color={getTabBarIconTintColor(tintColor, focused)}
        />
      ),
    },
  },
  Chat: {
    screen: ChatRouter,
    path: 'chat',
    navigationOptions: {
      tabBarLabel: 'Chat',
      tabBarIcon: ({ tintColor, focused }) => (
        // <Icon name={focused ? 'chat' : 'chat-outline'} size={22} color={tintColor} />
        <Icon
          name='send-outline'
          size={iconSize()}
          color={getTabBarIconTintColor(tintColor, focused)}
          style={{ paddingTop: 2 }}
        />
      ),
    },
  },
  Shopping: {
    screen: ShoppingRouter,
    path: 'cart',
    navigationOptions: {
      tabBarLabel: 'My Cart',
      tabBarIcon: ({ tintColor, focused }) => (
        <CartIcon
          tintColor={getTabBarIconTintColor(tintColor, focused)}
          focused={focused}
          iconSize={iconSize()}
        />
      ),
    },
  },
  MyNotifications: {
    screen: NotificationsRouter,
    path: 'notifications',
    navigationOptions: {
      tabBarLabel: 'Alerts',
      tabBarIcon: ({ tintColor, focused }) => (
        <NotificationsIcon
          tintColor={getTabBarIconTintColor(tintColor, focused)}
          focused={focused}
          iconSize={iconSize()}
        />
      ),
    },
  },
  MyProfile: {
    screen: MyProfileRouter,
    path: 'profile',
    navigationOptions: {
      tabBarLabel: 'Profile',
      tabBarIcon: ({ tintColor, focused }) => (
        // <Icon name={focused ? 'more' : 'more'} size={22} color={tintColor} />
        <Icon
          name={focused ? 'profile' : 'profile-outline'}
          size={iconSize()}
          color={getTabBarIconTintColor(tintColor, focused)}
        />
      ),
    },
  },
  // NotFound: {
  //   screen: More,
  //   path: '/not-found',
  // },
}
const initialRouteName = getHomeRoute()
const commonOptions = { initialRouteName }

const CurrentNavigator = selectLayout({
  // Refer to AZ#1315 feature branch to see how some of the routes were disabled for web app
  'web/portrait': () => createBottomTabNavigator(mobileRoutes, { tabBarComponent: MainTabs, ...commonOptions }),
  'web/landscape': () => createNavigator(LandscapeTabLayout, TabRouter(webRoutes), commonOptions),
  'ios/portrait': () => createBottomTabNavigator(mobileRoutes, { tabBarComponent: MainTabs, ...commonOptions }),
  'ios/landscape': () => createNavigator(LandscapeTabLayout, TabRouter(landscapeRoutes), commonOptions),
  'android/portrait': () => createBottomTabNavigator(mobileRoutes, { tabBarComponent: MainTabs, ...commonOptions }),
  'android/landscape': () => createNavigator(LandscapeTabLayout, TabRouter(landscapeRoutes), commonOptions),
})

export default CurrentNavigator
