import React from 'react'
import { connect, graphql, withApollo, compose, gql } from '../config/connected'

import schema from '../schema/conversation'

export default (Composed) =>
  compose(
    graphql(schema.queries.conversationMemberships, {
      name: 'conversations',
      options: (props) => ({
        variables: {},
        fetchPolicy: 'cache-and-network',
      }),
    }),
  )(Composed)
