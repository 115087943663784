import React from 'react'
import { View, TouchableOpacity, Text } from 'react-native'
import NamedImage from '../../components/simple/NamedImage'

import sharedStyles, { stylus } from '../../config/styles'
import colors from '../../config/colors'
import { connect, compose } from '../../config/connected'
import { getDate, resolveVideoThumbnail } from '../../config/helpers'
import i18n from 'i18n-js'
import _ from 'lodash'
const imageMargin = 10

const Order = ({
  entity,
  entityIdentifier,
  entityImage,
  order,
  navigationAction,
  navigateOnImage,
  showOrderNumber,
  screenInfo,
  style,
}) => {
  const { orderNumber, createdAt: orderDate, status } = order
  const leftFlex = 3
  let middleFlex = 7
  let rightFlex = 0
  if (navigationAction) {
    middleFlex = 6
    rightFlex = 1
  }

  const isVideoCover = ['.mp4', '.mov'].some(indicator => _.invoke(entityImage, 'includes', indicator))
  const newEntityImage = isVideoCover ? resolveVideoThumbnail(entityImage) : entityImage
  const imageSize = screenInfo.contentWidth * 0.25 - 2 * imageMargin
  const shouldNavigateOnImage = navigationAction && navigateOnImage
  const leftFlexStyle = { flex: leftFlex }
  const Image = (
    <View
      style={[!shouldNavigateOnImage && leftFlexStyle, styles.imageWrapper]}
    >
      <NamedImage
        style={[
          { borderRadius: imageSize / 2, height: imageSize, width: imageSize },
        ]}
        name={newEntityImage}
        width={imageSize}
        height={imageSize}
        fit='crop'
        crop='faces'
        resizeMode='cover'
        resizeMethod='scale'
        placeholder='profile'
      />
    </View>
  )
  let OrderComponent = (
    <View style={[styles.row, style]}>
      {shouldNavigateOnImage ? (
        <View style={leftFlexStyle}>
          <TouchableOpacity onPress={navigationAction}>
            {Image}
          </TouchableOpacity>
        </View>
      ) : (
        Image
      )}
      <View style={[{ flex: middleFlex }, styles.details]}>
        {showOrderNumber ? (
          <Text style={[styles.text, styles.bold, styles.orderNumberText]}>
            {i18n.t('order.order.orderNumber')}
            {orderNumber}
          </Text>
        ) : null}
        <Text numberOfLines={1} style={[styles.text]}>
          {entity}: <Text style={[styles.bold]}>{entityIdentifier}</Text>
        </Text>
        <Text style={[styles.text]}>
          {i18n.t('order.order.orderDate')}: {getDate(orderDate)}
        </Text>
        <Text style={[styles.text]}>
          {i18n.t('order.order.status')}:{' '}
          <Text style={[styles.bold, styles[`${status}StatusText`]]}>
            {i18n.t(`order.order.statuses.${status}`)}
          </Text>
        </Text>
      </View>
      {navigationAction && !navigateOnImage ? (
        <View style={[{ flex: rightFlex }, styles.navTextContainer]}>
          <Text style={styles.navText}>{'>'}</Text>
        </View>
      ) : null}
    </View>
  )
  if (navigationAction && !navigateOnImage) {
    return (
      <TouchableOpacity onPress={navigationAction}>
        {OrderComponent}
      </TouchableOpacity>
    )
  }
  return OrderComponent
}

const mapStateToProps = (state) => ({
  userId: state.currentUser.id,
  screenInfo: state.screenInfo,
})
export default compose(connect(mapStateToProps))(Order)

const styles = stylus({
  row: {
    flex: 1,
    flexDirection: 'row',
    justifiyContent: 'flex-start',
  },
  text: {
    marginTop: 2,
    color: '#808080',
    fontSize: 15,
  },
  bold: {
    fontWeight: '600',
  },
  imageWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  details: {
    flexDirection: 'column',
  },
  orderNumberText: {
    color: colors.text.main,
  },
  unfulfilledStatusText: {
    color: 'red',
  },
  fulfilledStatusText: {
    color: 'green',
  },
  navTextContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  navText: {
    color: colors.text.main,
    fontSize: 15,
  },
})
