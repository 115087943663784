import produce from 'immer'
const defaultState = {
  refresh: {
    posts: false,
    recipes: false,
    reviews: false,
    purchases: false,
  },
}
export const PROFILE_SET_REFRESH_FUNCTION = 'profile/SET_REFRESH_FUNCTION'
export const PROFILE_REFRESH_PAGE = 'Profile/REFRESH_PAGE'
export const PROFILE_REFRESH_DONE = 'Profile/REFRESH_DONE'
export default function profile(state = defaultState, action) {
  const { type, page } = action
  switch (type) {
    case PROFILE_SET_REFRESH_FUNCTION:
      // alert(typeof action.payload.refreshProfile)
      return {
        ...state,
        refreshProfile: action.payload.refreshProfile,
      }
    case PROFILE_REFRESH_PAGE:
      // return state
      return produce(state, (draft) => {
        draft.refresh[page] = true
      })
    case PROFILE_REFRESH_DONE:
      // return state
      return produce(state, (draft) => {
        draft.refresh[page] = false
      })
    default:
      return state
  }
}
