import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  Platform,
  View,
  TouchableOpacity,
  ListView,
  TouchableHighlight,
  FlatList,
  ActivityIndicator,
} from 'react-native'

import Scroller from '../../components/Scroller'
import PageTitle from '../../components/simple/PageTitle'
import Action from '../../components/simple/Action'
import ProfileImage from '../../components/Post/ProfileImage'
import ListItem from '../../components/simple/ListItem'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import StatusBar from '../../components/simple/StatusBar'
import MobileBackButton from '../../components/simple/MobileBackButton'
import PendingContent from '../../components/PostFB/PendingContent'
import Message from './Message'
import {
  connect,
  graphql,
  withApollo,
  compose,
  gql,
} from '../../config/connected'
import _ from 'lodash'
import shared, { stylus } from '../../config/styles'

const PAGE_SIZE = 20

import NavigationActions from '../../utility/navigationActions'

class MessageList extends React.Component {
  state = {
    refreshing: false,
  }
  constructor(props) {
    super(props)
    this.state = {
      refreshing: false,
    }
  }
  render() {
    const { membership, messages } = this.props
    if (messages.messages) {
      if (Platform.OS === 'web') {
        return this.renderWeb()
      }
      return this.renderNative()
    }
    return this.loading()
  }
  loading() {
    return (
      <View style={styles.loading}>
        <ActivityIndicator size='large' />
      </View>
    )
  }
  refresh = () => {
    // this is just to smooth things
    this.setState({ refreshing: true })
    this.noMore = false
    this.props.messages.refetch()
    setTimeout(() => {
      this.setState({ refreshing: false })
    }, 800)
  }
  getMessages() {
    return _.get(this.props.messages, 'messages', [])
  }
  keyExtractor = (item, index) => item.id
  renderNative() {
    return (
      <FlatList
        renderItem={this.renderItem}
        keyExtractor={this.keyExtractor}
        data={this.getMessages()}
        style={{
          flex: 1,
        }}
        inverted
        refreshing={this.state.refreshing}
        onRefresh={this.refresh.bind(this)}
        ListHeaderComponent={this.renderPendingContent}
      />
    )
  }
  renderWeb() {
    const { landscape } = this.props
    return (
      <Scroller
        loadMore={this.loadMore.bind(this)}
        contentContainerStyle={{
          paddingLeft: 0,
          paddingRight: 0,
          width: null,
          paddingTop: 0,
          marginLeft: 0,
          alignItems: 'flex-end',
        }}
      >
        {this.getMessages()
          .map((message, i) => (
            <Message key={message.id} message={message} landscape={landscape} />
          ))
          .reverse()}
        { this.renderPendingContent() }
      </Scroller>
    )
  }

  renderPendingContent = () => {
    return <PendingContent types={['message']} filter={this.filterPendingContent} />
  }

  filterPendingContent = (content) => {
    const { membership } = this.props
    return content.conversationMembership.id === _.get(membership, 'conversationMembership.id')
  }

  renderItem = ({ item }) => {
    const { landscape, goToProfile, goToReportContent, currentUser } = this.props
    return (
      <View style={styles.flatListItem}>
        <Message
          currentUser={currentUser}
          message={item}
          landscape={landscape}
          goToProfile={goToProfile}
          goToReportContent={goToReportContent}
        />
      </View>
    )
  }
  loadMore() {
    this.setState({
      refreshing: true,
    })
    setTimeout(() => {
      this.setState({
        refreshing: false,
      })
    }, 1800)
  }
}

export default MessageList

const styles = stylus({
  container: {
    flex: 1,
    // backgroundColor: 'orange',
  },
  flatListItem: {
    alignSelf: 'stretch',
  },
  loading: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
