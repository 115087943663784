import React from 'react'
import PropTypes from 'prop-types'
import { View, Text } from 'react-native'

import T from '../../../components/T'
import FontAwesome from '@expo/vector-icons/FontAwesome'

import { stylus } from '../../../config/styles'
import colors from '../../../config/colors'

import _ from 'lodash'

class PaymentMethod extends React.Component {
  render() {
    const { style, actionColumnStyle, iconContainerStyle, brand, last4, fundingType, expirationMonth, expirationYear, nameOnCard, defaultCard, children } = this.props
    return (
      <View style={[styles.container, style]}>
        <View style={[styles.column, styles.iconColumn, iconContainerStyle]}>
          <FontAwesome size={40} name={`cc-${brand}`} />
        </View>
        <View style={[styles.column, styles.textColumn ]}>
          <Text style={[styles.text]}>{`${this.getBrandTitleCase()} ...${last4}`}</Text>
          { fundingType ? <Text style={[styles.text]}>{`${this.getFundingTitleCase()}`}</Text> : null}
          <Text style={[styles.text]}>{`Exp.: ${expirationMonth} / ${expirationYear}`}</Text>
          { nameOnCard ? <Text style={[styles.text]}>{nameOnCard}</Text> : null }
          { defaultCard ? (
            <T t='payment.paymentMethods.defaultCard' style={[styles.finePrint]} />
          ) : null }
        </View>
        <View style={[styles.column, styles.actionColumn, actionColumnStyle]}>
          { children }
        </View>
      </View>
    )
  }

  getBrandTitleCase = () => {
    switch(this.props.brand) {
      case 'mastercard':
        return 'Master Card'
      case 'amex':
        return 'American Express'
      default:
        return _.startCase(this.props.brand)
    }
  }

  getFundingTitleCase = () => {
    return _.startCase(this.props.fundingType)
  }
}

PaymentMethod.propTypes = {
  style: PropTypes.object,
  iconContainerStyle: PropTypes.object,
  actionColumnStyle: PropTypes.object,
  id: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  last4: PropTypes.string.isRequired,
  expirationMonth: PropTypes.number.isRequired,
  expirationYear: PropTypes.number.isRequired,
  nameOnCard: PropTypes.string,
  fundingType: PropTypes.string,
  defaultCard: PropTypes.bool,
}

const styles = stylus({
  container: {
    flex: 1,
    flexDirection: 'row'
  },
  iconColumn: {
    flex: 4,
    alignItems: 'center',
    paddingLeft: 0,
  },
  textColumn: {
    flex: 9,
    alignItems: 'flex-start',
  },
  actionColumn: {
    flex: 7,
    alignItems: 'stretch',
    paddingRight: 3,
  },
  column: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 3,
  },
  text: {
    fontSize: 16,
    color: colors.text.main
  },
  finePrint: {
    fontSize: 14,
    color: colors.text.secondary
  },
})

export default PaymentMethod
