import React from 'react'
import { PropTypes } from 'prop-types'
import NavigationActions from '../../../utility/navigationActions'
import Button from '../../../components/simple/Button'
import ProfileBanner from '../../../components/simple/ProfileBanner'

import { stylus } from '../../../config/styles'
import colors from '../../../config/colors'

import { graphql, connect } from '../../../config/connected'
import adminSchema from '../../../schema/admin'
import i18n from 'i18n-js'
import _ from 'lodash'

const mapDispatchToProps = (dispatch) => ({ dispatch })

@connect(null, mapDispatchToProps)
@graphql(adminSchema.mutations.ghostToken, {
  name: 'ghostToken',
})
@graphql(adminSchema.mutations.blockUser, {
  name: 'blockUser',
})
@graphql(adminSchema.mutations.unblockUser, {
  name: 'unblockUser',
})
class PeopleResult extends React.Component {

  static propTypes = {
    forAdmin: PropTypes.bool,
    profile: PropTypes.object,
    profileOnPress: PropTypes.func,
    adminActionOnPress: PropTypes.func,
  }

  loginAsUser = async () => {
    const { dispatch, profile } = this.props
    const result = await this.props.ghostToken({
      variables: {
        id: profile.id,
      },
    })
    const {
      ghostToken: { token, id: userId },
    } = result.data
    // console.log({ result, token, id })
    dispatch({ type: 'GHOST', token, userId })
    setTimeout(NavigationActions.navigate({
      routeName: 'Feed'
    }), 300)
  }

  blockUser = async () => {
    const { blockUser, profile: { id }, adminActionOnPress } = this.props
    await blockUser({ variables: { id } })
    adminActionOnPress()
  }

  unblockUser = async() => {
    const { unblockUser, profile: { id }, adminActionOnPress } = this.props
    await unblockUser({ variables: { id } })
    adminActionOnPress()
  }

  render() {
    const { forAdmin, ...rest } = this.props
    let extraContentStyle = forAdmin ? styles.extraContent : {}
    return (
      <ProfileBanner { ...rest } followButtonHidden={true} extraContentStyle={extraContentStyle}>
        { forAdmin ?
          <React.Fragment>
            <Button
              onPress={this.loginAsUser}
              label={i18n.t('admin.manageUsers.loginAsUser')}
              labelStyle={styles.buttonLabel}
              style={styles.buttonLoginAs}
            />
            { rest.profile.blocked ?
              <Button
                onPress={this.unblockUser}
                label={i18n.t('admin.manageUsers.unblockUser')}
                labelStyle={styles.buttonLabel}
                style={styles.buttonUnblock}
              />
              :
              <Button
                onPress={this.blockUser}
                label={i18n.t('admin.manageUsers.blockUser')}
                labelStyle={styles.buttonLabel}
                style={styles.buttonBlock}
              />
            }
          </React.Fragment>
          : null }
      </ProfileBanner>
    )
  }
}

export default PeopleResult

const styles = stylus({
  extraContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  buttonLoginAs: {
    backgroundColor: colors.primary,
    marginRight: 5,
  },
  buttonBlock: {
    backgroundColor: 'red'
  },
  buttonUnblock: {
    backgroundColor: 'green'
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
})
