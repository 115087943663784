

import React from 'react'
import { Text, View } from 'react-native'
import PropTypes from 'prop-types'
import shared, { stylus } from '../../config/styles'
import _ from 'lodash'
import colors from '../../config/colors'
import i18n from 'i18n-js'

const AddressView = ({ address, titleStyle, style, title }) => {

  const {
    recipient,
    firstName,
    lastName,
    address1,
    address2,
    city,
    region,
    zip,
    country,
    email,
    phone,
    taxCode,
    vatNumber,
  } = address


  return (
    <View style={[style]}>
      {title && <Text style={[shared.h5, styles.title, titleStyle]}>
        {title}
      </Text>}
      <Text>
        <Text>
          {recipient}
          {'\n'}
        </Text>
        <Text>
          {address1}
          {'\n'}
        </Text>
        {address2 ? (
          <Text>
            {address2}
            {'\n'}
          </Text>
        ) : null}
        <Text>
          {city}, {region} {zip}
          {'\n'}
        </Text>
        <Text>
          {country}
          {'\n'}
        </Text>
        {!_.isEmpty(email) && (
          <Text>
            {email}
            {'\n'}
          </Text>
        )}
        {!_.isEmpty(phone) && (
          <Text>
            {phone}
            {'\n'}
          </Text>
        )}
        {!_.isEmpty(taxCode) ?
          <Text>
            <Text style={{ fontWeight: 'bold' }}>{i18n.t('onboardingUser.address.taxCodePlaceholder')}: </Text>
            {taxCode}
            {'\n'}
          </Text>
          : null 
        }
        {!_.isEmpty(vatNumber) ?
          <Text>
            <Text style={{ fontWeight: 'bold' }}>{i18n.t('onboardingUser.address.vatNumberPlaceholder')}: </Text>
            {vatNumber}
            {'\n'}
          </Text>
          : null
        }
      </Text>
    </View>
  )
}

AddressView.propTypes = {
  address: PropTypes.object,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
  style: PropTypes.object,
}

export default AddressView

const styles = stylus({

  title: {
    fontWeight: 'bold',
    color: colors.text.main,
  },

})
