import { connect, graphql, withApollo, compose, gql } from '../config/connected'

const comment = `
id
content
user {
  id
  username
  profileImage
  title
  organization
  organizationId
}
createdAt
deletedAt
`

const commentFragment = gql`
    fragment CommentFragment on Comment {
        id
        content
        user {
            id
            username
            profileImage
            title
            organization
            organizationId
        }
        iLike
        createdAt
        likesCount
        deletedAt
    }
`

export default {
  queries: {
    comments: gql`
      query($postId: String!) {
        comments(postId: $postId) {
            ...CommentFragment
        }
      }
      ${commentFragment}
    `,
    commentLikers: gql`
        query ($id: String!){
            commentLikers(id: $id){
                id,
                profileImage,
                username
                displayName
            }
        }
    `,
  },
  mutations: {
    createComment: gql`
      mutation($postId: String!, $content: Json!) {
        createComment(postId: $postId, content: $content) {
            ...CommentFragment
        }
      }
      ${commentFragment}
    `,
    likeComment:
    gql`
        mutation ($commentId: String!){
            likeComment(id: $commentId){
                ...CommentFragment
            }
        }
        ${commentFragment}
    `,
    unlikeComment:
    gql`
        mutation ($commentId: String!){
            unlikeComment(id: $commentId){
                ...CommentFragment
            }
        }
        ${commentFragment}
    `,
    deleteComment: gql`
      mutation($commentId: String!){
        deleteComment(id: $commentId){
          commentId
          postId
        }
      }
    `,
  },
  fields: {
    comment,
  },
}
