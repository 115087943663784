import React, { Component } from 'react'
import { View, Text, Slider as NativeSlider, Platform } from 'react-native'
import WebSlider from 'react-native-slider'
import PropTypes from 'prop-types'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'

const OS = Platform.OS
class Slider extends Component {
  static propTypes = {
    value: PropTypes.any,
    min: PropTypes.number,
    max: PropTypes.number,
    minTint: PropTypes.string,
    maxTint: PropTypes.string,
    step: PropTypes.number,
    label: PropTypes.string,
    valueLabel: PropTypes.string,
    icon: PropTypes.object,
    onChange: PropTypes.func,
  }

  render() {
    const Slider = OS === 'web' ? WebSlider : NativeSlider

    const {
      min,
      max,
      minTint,
      maxTint,
      step,
      label,
      valueLabel,
      icon,
      onChange,
      value,
    } = this.props

    return (
      <View style={styles.container}>
        <View style={{ width: '33.3%', flexDirection: 'row', marginRight: 20 }}>
          {React.cloneElement(icon)}
          <Text
            numberOfLines={2}
            ellipsizeMode='tail'
            style={[
              styles.label,
              { marginTop: 'auto', marginBottom: 'auto', marginRight: 10 },
            ]}
          >
            {label + ':' || ''}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.sliderLabel}>{valueLabel || ''}</Text>
          <Slider
            minimumTrackTintColor={minTint}
            maximumTrackTintColor={maxTint}
            onValueChange={onChange}
            step={step}
            minimumValue={min}
            maximumValue={max}
            value={value}
          />
        </View>
      </View>
    )
  }
}

const styles = stylus({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontWeight: 'bold',
    color: colors.text.light,
    ios: {
      paddingBottom: 10,
    },
    android: {
      paddingBottom: 10,
    },
  },
  sliderLabel: {
    color: colors.text.light,
    alignSelf: 'center',
  },
})

export default Slider
