import {
  SERVER_ENDPOINT,
  GRAPHQL_ENDPOINT,
  IMGIX_URL,
  IMGIX_ASSETS_URL,
  SENTRY_DSN,
  STRIPE_CONNECT_CLIENT_ID,
  STRIPE_CONNECT_O_AUTH_REDIRECT_URI,
  STRIPE_PUBLIC_KEY,
  FEATURES_FOLLOW_SHOP,
  FEATURES_INVITE_CODE,
  FEATURES_STRIPE,
  FEATURES_REPORT_CONTENT,
  FEATURES_SIGNUP_OPTIONS,
  FEATURES_SCORES,
  FEATURES_VIDEO_UPLOAD,
  FEATURES_RESTORE_FAILED_PUBLISH,
  ALGOLIA_INDEX_RECIPES,
  ALGOLIA_INDEX_USERS,
  ALGOLIA_INDEX_PRODUCERS,
  ALGOLIA_INDEX_PRODUCTS,
  ALGOLIA_PROJECT,
  ALGOLIA_TOKEN,
  ENVIRONMENT,
  GOOGLE_MAPS_API_KEY,
  MAX_VIDEO_DURATION,
  VIDEO_CDN_ID,
  MAX_PRODUCERS_IN_MAP,
  MEDIA_MAX_UPLOAD_ERRORS,
} from 'react-native-dotenv'
import * as Yup from 'yup'
import _ from 'lodash'
import Constants from 'expo-constants'
import manifest from '../../app.json'

if (_.isEmpty(Constants.manifest)) {
  Constants.manifest = manifest.expo
}
console.log('<<<environment - Constants -', Constants)


export const configSchema = Yup.object().shape({
  environment: Yup.string().oneOf(['development', 'qa', 'uat', 'production']).required(),
  serverEndpoint: Yup.string().required(),
  graphqlEndpoint: Yup.string().required(),
  algolia: Yup.object().shape({
    project: Yup.string().required(),
    token: Yup.string().required(),
  }),
  constants: Yup.object().shape({
    ALGOLIA_INDEX_RECIPES: Yup.string(),
    ALGOLIA_INDEX_USERS: Yup.string(),
    ALGOLIA_INDEX_PRODUCERS: Yup.string(),
    ALGOLIA_INDEX_PRODUCTS: Yup.string(),
  }),
  imgixUrl: Yup.string().required(),
  sentryDsn: Yup.string().when('environment', {
    is: 'production',
    then: Yup.string().url().required(),
  }),
  stripe: Yup.object().shape({
    connect: Yup.object().shape({
      oAuthRedirectUri: Yup.string(),
      clientId: Yup.string(),
    }),
    publicKey: Yup.string(),
  }),
  features: Yup.object().shape({
    followShop: Yup.boolean(),
    inviteCode: Yup.boolean(),
    stripe: Yup.boolean(),
    reportContent: Yup.boolean(),
    signupOptions: Yup.boolean(),
    scores: Yup.boolean(),
    videoUpload: Yup.boolean(),
    restoreFailedPublish: Yup.boolean(),
  }),
  company: Yup.string().required(),
  companyLower: Yup.string().required(),
  companyPhoneNumber: Yup.string().required(),
  companySupportEmail: Yup.string().email().required(),
  companyPhoneNumberUrl: Yup.string().required(),
  companyWebsiteDisplay: Yup.string().required(),
  companyWebsiteUrl: Yup.string().url().required(),
  maxMediaUploadErrors: Yup.number().positive().required(),
  map: Yup.object().shape({
    maxProducers: Yup.number().positive().required()
  }),
  videoUploadLimits: Yup.object().shape({
    maxDuration: Yup.number().positive().required()
  }),
})

const config = {
  environment: ENVIRONMENT,
  serverEndpoint: SERVER_ENDPOINT,
  graphqlEndpoint: GRAPHQL_ENDPOINT,
  algolia: {
    project: ALGOLIA_PROJECT,
    token: ALGOLIA_TOKEN,
  },
  constants: {
    ALGOLIA_INDEX_RECIPES,
    ALGOLIA_INDEX_USERS,
    ALGOLIA_INDEX_PRODUCERS,
    ALGOLIA_INDEX_PRODUCTS,
  },
  imgixUrl: IMGIX_URL,
  imgixAssetsUrl: IMGIX_ASSETS_URL,
  sentryDsn: SENTRY_DSN,
  stripe: {
    connect: {
      oAuthRedirectUri:
      STRIPE_CONNECT_O_AUTH_REDIRECT_URI,
      clientId: STRIPE_CONNECT_CLIENT_ID,
    },
    publicKey: STRIPE_PUBLIC_KEY,
  },
  features: {
    followShop: JSON.parse(FEATURES_FOLLOW_SHOP) || false,
    inviteCode: JSON.parse(FEATURES_INVITE_CODE) || false,
    stripe: JSON.parse(FEATURES_STRIPE) || false,
    reportContent: JSON.parse(FEATURES_REPORT_CONTENT) || false,
    signupOptions: JSON.parse(FEATURES_SIGNUP_OPTIONS) || false,
    scores: JSON.parse(FEATURES_SCORES) || false,
    videoUpload: JSON.parse(FEATURES_VIDEO_UPLOAD) || false,
    restoreFailedPublish: JSON.parse(FEATURES_RESTORE_FAILED_PUBLISH) || false,
  },
  google: {
    geoKey: GOOGLE_MAPS_API_KEY,
  },
  videoUploadLimits: {
    maxDuration: +MAX_VIDEO_DURATION,
  },
  map: {
    maxProducers: +MAX_PRODUCERS_IN_MAP || 200,
  },
  maxMediaUploadErrors: +MEDIA_MAX_UPLOAD_ERRORS || 5,
  videoCDNId: VIDEO_CDN_ID,
  company: 'Foodbarrio',
  companyLower: 'foodbarrio',
  companyPhoneNumber: '+1 (696) 111-9999',
  companySupportEmail: 'hello@foodbarrio.com',
  companyPhoneNumberUrl: 'tel:+1-696-111-9999',
  companyWebsiteDisplay: 'www.foodbarrio.com',
  companyWebsiteUrl: 'https://www.foodbarrio.com',
  ...Constants.manifest.extra,
}

export default config
