import Icons from '@expo/vector-icons/Ionicons'
import i18n from 'i18n-js'
import produce from 'immer'
import _ from 'lodash'
import React from 'react'
import { FlatList, Keyboard, KeyboardAvoidingView, Platform, Text, TouchableOpacity, View, TextInput, ActivityIndicator, BackHandler } from 'react-native'
import { Video, Audio } from 'expo-av'
import NavigationActions from '../../utility/navigationActions'
import AddPhotoSvgXml from '../../assets/svgs/add-a-photo-thin-outline.svg'
import ElegantInputWithTagging from '../../components/ElegantInputWithTagging'
import ImageCarousel from '../../components/ImageCarousel'
import LayoutAnimation from '../../components/LayoutAnimation'
import Scroller from '../../components/Scroller'
import Divider from '../../components/simple/Divider'
import ElegantInput from '../../components/simple/ElegantInput'
import ErrorMessage from '../../components/simple/ErrorMessage'
import MobileBackButton from '../../components/simple/MobileBackButton'
import Slider from '../../components/simple/Slider'
import Svg from '../../components/simple/Svg'
import UploadButton from '../../components/simple/UploadButton'
import DeleteButton from '../../components/simple/DeleteButton'
import { TaggingContext } from '../../components/Tagging/withTagging'
import branch from '../../config/branch'
import colors from '../../config/colors'
import { compose, connect, graphql } from '../../config/connected'
import { getKeyboardPaddingStyle, isString, removeUploadsFromCache } from '../../config/helpers'
import Icon from '../../config/icon'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'
import { isWebsite } from '../../config/validate'
import environment from '../../config/environment'
import withKeyboardInfo from '../../containers/withKeyboardInfo'
import schema from '../../schema/post'
import withPreventDoubleClick from '../../containers/withPreventDoubleClick'
import { ANALYTICS_PUBLISH_POST_WITH_PRODUCTS_TAGGED } from '../../reducers/analytics'
import { CONTENT_IS_PENDING } from '../../reducers/publishContent'
import TagsInput from '../../components/TagsInput'
import { categories as productCategories } from '../../localization/en-US/categories'
import { withNavigationFocus } from 'react-navigation'

const debug = true
const OS = Platform.OS
const AddAPhotoOutlineIcon = (
  <Svg
    width='27'
    height='27'
    xml={AddPhotoSvgXml}
  />
)
const bottomBarHeight = sizes.tabBarHeight + 20

let handleImageFunc = (file) => {}
const handleImage = function(file) {
  handleImageFunc(file)
}

let showVideoLoaderFunc = () => {}
const showVideoLoader = function() {
  showVideoLoaderFunc()
}

let hideVideoLoaderFunc = () => {}
const hideVideoLoader = function() {
  hideVideoLoaderFunc()
}

const TouchableDebounced = compose(withPreventDoubleClick)(TouchableOpacity)
const requiredPostFields = {
  post: ['story'],
  recipe: ['title', 'ingredients', 'instructions', 'story']
}

const navigationOptionsCallbacks = {
  back: () => {}
}

@withNavigationFocus
@withKeyboardInfo
class PostCreate extends React.Component {
  state = {
    id: null,
    story: '',
    title: '',
    ingredients: '',
    instructions: '',
    pickerOpen: true,
    previewHeight: this.props.screenInfo.width,
    uploads: [],
    recipeLink: '',
    difficulty: 2,
    prepTime: 5,
    cookTime: 0,
    cost: 1,
    servings: 1,
    errors: {},
    initialValues: false,
    keyboardOpen: false,
    imagesToDelete: [],
    taggedProducts: [],
    diet: [],
    videoLoaded: false,
    showVideoLoader: false,
    firstTimeShowLoader: true,
    files: [],
    pendingUploads: [],
    isPublishing: false,
    sentPublishRequest: false,
    pendingCancelPublish: false,
    uploadsQueued: false,
  }

  constructor(props) {
    super(props)
    if (Platform.OS === 'android') {
      this.watchBackButton()
    }
  }

  watchBackButton() {
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      this.back()
      return true
    })
  }

  componentWillMount() {
    Audio.setAudioModeAsync({
      allowsRecordingIOS: false,
      interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: true,
      interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
      playThroughEarpieceAndroid: false,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const shouldLoadContent = this.shouldLoadContent()
    if (shouldLoadContent) {
      this.loadContent()
    }
  }

  shouldLoadContent = () => {
    const { postHandler } = this.props
    const { initialValues } = this.state
    return !initialValues && postHandler && !postHandler.loading
  }

  loadContent = () => {
    const { postHandler } = this.props
    const { post } = postHandler
    const { story, uploads, recipe, taggedProducts = [] } = post
    const nextState = produce(this.state, (draft) => {
      draft.story = story
      // console.log(uploads)
      draft.taggedProducts = taggedProducts
      draft.uploads = uploads

      if (recipe) {
        draft.title = recipe.title ? recipe.title : draft.title
        draft.ingredients = recipe.ingredients
          ? recipe.ingredients
          : draft.ingredients
        draft.instructions = recipe.instructions
          ? recipe.instructions
          : draft.instructions
        draft.recipeLink = recipe.recipeLink
          ? recipe.recipeLink
          : draft.recipeLink
        draft.difficulty = recipe.difficulty
          ? recipe.difficulty
          : draft.difficulty
        draft.diet = (_.get(recipe, 'info.diet', draft.diet) || []).map(i => ({
          value: i,
          label: i18n.t(`categories.diet.${i}`),
        }))
        draft.prepTime = recipe.prepTime ? recipe.prepTime : draft.prepTime
        draft.cookTime = recipe.cookTime ? recipe.cookTime : draft.cookTime
        draft.cost = recipe.cost ? recipe.cost : draft.cost
        draft.servings = recipe.servings ? recipe.servings : draft.servings
      }
      draft.initialValues = true
    })

    this.setState(nextState)
  }

  onTagPress = (index, targetField) => {
    const { diet } = this.state
    let data
    if (targetField === 'diet') {
      data = diet.slice()
    }

    data.splice(index, 1)
    this.onChange(data, targetField)
  }

  setPreviewHeight = (aspectRatio) => {
    const { width } = this.props.screenInfo,
      targetHeight = width * aspectRatio
    this.setState({ previewHeight: targetHeight })
  }

  handleImage = (file) => {
    debug && console.log('<<<POST CREATE - handleImage', file)
    const nextState = produce(this.state, (draft) => {
      draft.errors.uploads = null
    })
    this.setState(nextState)
    if (file.file.type == 'video') {
      // this.addVideo(file)
      this.setState(prevState => ({ uploads: [...prevState.uploads, file], files: [...prevState.files, file], pendingUploads: [...prevState.pendingUploads, file]}))
    } else {
      // this.addImage(file)
      this.props.queueUpload(file)
      this.setState(prevState => ({ uploads: [...prevState.uploads, file], pendingUploads: [...prevState.pendingUploads, file]}))
    }
  }
  handleUploadFiles = () => {
    const { files } = this.state
    if (files.length) {
      files.forEach(file => {
        this.props.queueUpload(file)
      })
    }
  }

  showVideoLoader = () => {
    if (this.state.firstTimeShowLoader || (this.state.videoLoaded && !this.state.showVideoLoader)) {
      console.log('<<<POST CREATE - showVideoLoader')
      this.setState({ firstTimeShowLoader: false, videoLoaded: false, showVideoLoader: true})
    }
  }
  hideVideoLoader = () => {
    if (!this.state.videoLoaded && this.state.showVideoLoader) {
      console.log('<<<POST CREATE - hideVideoLoader')
      this.setState({videoLoaded: true, showVideoLoader: false})
    }
  }

  renderPreview = () => {
    const { uploads, videoLoaded, showVideoLoader } = this.state
    const { screenInfo } = this.props

    const sizeStyles = {
      height: this.state.previewHeight,
      width: screenInfo.contentWidth,
    }
    return (
      <>
        <ImageCarousel
          style={{...sizeStyles}}
          images={uploads}
          playMuted={true}
          playOnce={true}
          showProgress
          action={this.carouselAction()}
          autoScroll
          contentWidth={screenInfo.contentWidth}
          placeholder={this.imagePlaceholder()}
          deleteButton={(data, right, top) => (
            <DeleteButton data={data} onDelete={this.handleDelete} right={right} top={top} />
          )}
          navigation={this.props.navigation}
        />
        {(showVideoLoader && !videoLoaded) && this.loggingActivityIndicator() && (
          <ActivityIndicator
            size='large'
            style={{
              position: 'absolute',
              flex: 1,
              width: screenInfo.contentWidth,
              height: screenInfo.height
            }}
          />
        )}
        </>
    )
  }

  loggingActivityIndicator = () => {
    debug && console.log('<<<POST CREATE - showing activity indicator')
    return true
  }

  componentDidMount() {
    navigationOptionsCallbacks.back = this.back
    handleImageFunc = this.handleImage
    showVideoLoaderFunc = this.showVideoLoader
    hideVideoLoaderFunc = this.hideVideoLoader
    this.keyboardWillShowListener = Keyboard.addListener(
      'keyboardWillShow',
      this.keyboardWillShow,
    )
    this.keyboardWillHideListener = Keyboard.addListener(
      'keyboardWillHide',
      this.keyboardWillHide,
    )
  }

  back = () => {
    removeUploadsFromCache(this.state.pendingUploads)
    NavigationActions.back()
  }


  componentWillUnmount() {
    this.backHandler && this.backHandler.remove()
    this.keyboardWillShowListener.remove()
    this.keyboardWillHideListener.remove()
  }

  keyboardWillShow = () => {
    this.setState({ keyboardOpen: true })
  }

  keyboardWillHide = () => {
    this.setState({ keyboardOpen: false })
  }

  toggleRecipe = () => {
    this.animate()
    this.setState((prev) => ({
      isRecipe: !prev.isRecipe,
      errors: {},
    }))
  }

  isRecipe() {
    return _.get(this.props, 'navigation.state.params.isRecipe')
  }

  getPublishMethod() {
    const { navigation } = this.props
    const id = _.get(navigation, 'state.params.id')
    const isRecipe = this.isRecipe()
    let method = isRecipe ? 'createRecipe' : 'createPost'
    if (id) {
      method = isRecipe ? 'editRecipe' : 'editPost'
    }
    return method
  }

  isEdit() {
    return _.get(this.props, 'navigation.state.params.id')
  }

  modifyWebLink = (link = '') => {
    let newLink = ''
    switch (true) {
      case !!link.match(/^https:\/\//i):
        return link
      case !!link.match(/^http:\/\//i):
        newLink = link.replace(/(^http:\/\/)/i, 'https://')
        return newLink
      default:
        return (newLink = 'https://' + link)
    }
  }

  handleFieldBlur = (value, target) => {
    switch (target) {
      case 'recipeLink':
        return isWebsite(value)
      default:
        return true
    }
  }

  keyExtractor = (item) => item.id

  renderTaggedProducts = () => {
    const { taggedProducts } = this.state
    return (
      <FlatList
        data={taggedProducts}
        horizontal
        keyExtractor={this.keyExtractor}
        renderItem={this.renderTaggedProduct}
      />
    )
  }

  renderProductTagButton = (taggingHook) => {
    const {errors} = this.state
    return (
      <View style={styles.tagButtonContainer}>
        <TouchableOpacity
          label='Tag Products'
          style={styles.tagButton}
          onPress={() => {
            taggingHook(this.onProductTag, {products: true})
          }}
        >
          <Icons style={styles.searchIcon} name={'ios-search'} size={30} />
          <Text style={styles.tagButtonText}>{i18n.t('post.tagButton')}</Text>
        </TouchableOpacity>
        <Text style={styles.tagDescription}>{i18n.t('post.tagDescription')}</Text>
        <ErrorMessage
          labelStyle={styles.taggedProductErrorText}
          t={errors.taggedProducts ? errors.taggedProducts : null}
          style={styles.taggedProductsError}
        />
      </View>
    )
  }

  renderRecipeOptions = () => {
    const {
      title,
      story,
      ingredients,
      instructions,
      difficulty,
      prepTime,
      cookTime,
      cost,
      servings,
      recipeLink,
      errors,
      initialValues,
      diet,
    } = this.state
    const { postHandler } = this.props

    if (postHandler && !initialValues) {
      return null
    }

    const allDiets = _.map(productCategories.diet, (_, key) => {
      return {
        value: key,
        label: i18n.t(`categories.diet.${key}`),
      }
    })

    return (
      <View>
        <ElegantInput
          label={i18n.t('post.labels.recipe')}
          error={errors.title ? i18n.t(errors.title) : null}
          onChange={(value) => this.onChange(value, 'title')}
          value={title}
          baseColor={baseColor}
          returnKeyType='next'
        />
        <ElegantInput
          label={i18n.t('post.labels.recipeLink')}
          onChange={(value) => {
            // TODO: Add more robust URI handling. URLs are pretty restrictive as it stands.
            // const passedValue = _.toLower(value)
            this.onChange(value, 'recipeLink')
          }}
          value={recipeLink}
          baseColor={baseColor}
          auto
          autoCapitalize='none'
          error={errors.recipeLink ? i18n.t(errors.recipeLink) : null}
          onBlur={() => {
            const filteredLink = this.modifyWebLink(recipeLink)
            let error = null
            if (
              recipeLink.length > 0 &&
              !this.handleFieldBlur(filteredLink, 'recipeLink')
            ) {
              error = 'errors.website.invalid'
            } else {
              if (recipeLink.length > 0) {
                this.setState({ recipeLink: filteredLink })
              }
            }
            const nextState = produce(this.state, (draft) => {
              draft.errors['recipeLink'] = error
            })
            this.setState(nextState)
          }}
        />
        <TaggingContext.Consumer>
          {(context = {}) => {
            const { taggingHook = () => {} } = context
            return (
              <>
                <ElegantInputWithTagging
                  label={i18n.t('post.labels.story')}
                  defaultContent={story}
                  error={errors.story ? i18n.t(errors.story) : null}
                  taggingHook={(data) => taggingHook(data, {shopProducts: false})}
                  onChange={(value) => this.onChange(value, 'story')}
                  baseColor={baseColor}
                />
                <TagsInput
                  error={errors.diet && i18n.t(errors.diet)}
                  tags={allDiets}
                  chosen={diet}
                  placeholder={i18n.t('shop.add.placeholders.tagsDiet')}
                  label={i18n.t('shop.add.fields.diet') + ': '}
                  onTagsChange={(value) => this.onChange(value, 'diet')}
                  onTagPress={(idx) => this.onTagPress(idx, 'diet')}
                  //(value) => handleFieldChange(value, 'allergens')
                  // onChangeSearch={(value) => handleFieldChange(value, 'allergens')}
                />
                <ElegantInputWithTagging
                  label={i18n.t('post.labels.ingredients')}
                  defaultContent={ingredients}
                  error={errors.ingredients ? i18n.t(errors.ingredients) : null}
                  taggingHook={taggingHook}
                  onChange={(value) => this.onChange(value, 'ingredients')}
                  baseColor={baseColor}
                />
                <ElegantInputWithTagging
                  label={i18n.t('post.labels.instructions')}
                  defaultContent={instructions}
                  error={errors.instructions ? i18n.t(errors.instructions) : null}
                  taggingHook={taggingHook}
                  onChange={(value) => this.onChange(value, 'instructions')}
                  baseColor={baseColor}
                />
                {this.renderProductTagButton(taggingHook)}
              </>
            )
          }
        }
        </TaggingContext.Consumer>
        {this.renderTaggedProducts()}
        <Divider hidden/>
        <Slider
          label={i18n.t('post.labels.difficulty')}
          valueLabel={i18n.t(`post.sliderValues.difficulty.${difficulty}`)}
          value={this.state.difficulty}
          step={1}
          minTint={colors.primary}
          maxTint={colors.primary}
          min={2}
          max={4}
          icon={
            <Icon name='chef' size={30} style={{ margin: 10, marginTop: 15 }} />
          }
          onChange={(value) => this.onChange(value, 'difficulty')}
        />
        <Slider
          label={i18n.t('post.labels.prepTime')}
          valueLabel={i18n.t('post.sliderValues.prepTime', {
            count: this.state.prepTime,
          })}
          value={this.state.prepTime}
          step={5}
          minTint={colors.primary}
          maxTint={colors.primary}
          min={5}
          max={180}
          icon={
            <Icon
              name='clock'
              size={30}
              style={{ margin: 10, marginTop: 15 }}
            />
          }
          onChange={(value) => this.onChange(value, 'prepTime')}
        />
        <Slider
          label={i18n.t('post.labels.cookTime')}
          valueLabel={i18n.t('post.sliderValues.cookTime', {
            count: parseInt(this.state.cookTime),
          })}
          step={5}
          minTint={colors.primary}
          maxTint={colors.primary}
          min={0}
          max={240}
          icon={
            <Icon
              name='cooktime'
              size={30}
              style={{ margin: 10, marginTop: 15 }}
            />
          }
          onChange={(value) => this.onChange(value, 'cookTime')}
        />
        <Slider
          label={i18n.t('post.labels.cost')}
          valueLabel={i18n.t(`post.sliderValues.cost.${this.state.cost}`)}
          value={this.state.cost}
          step={1}
          minTint={colors.primary}
          maxTint={colors.primary}
          min={1}
          max={3}
          icon={
            <Text style={{ fontSize: 30, paddingHorizontal: 15.25 }}>€</Text>
          }
          onChange={(value) => this.onChange(value, 'cost')}
        />
        <Slider
          label={i18n.t('post.labels.servings')}
          valueLabel={i18n.t('post.sliderValues.servings', {
            count: this.state.servings,
          })}
          value={this.state.servings}
          step={1}
          minTint={colors.primary}
          maxTint={colors.primary}
          min={1}
          max={20}
          icon={
            <Icon
              name='group'
              size={30}
              style={{ margin: 10, marginTop: 15 }}
            />
          }
          onChange={(value) => this.onChange(value, 'servings')}
        />
      </View>
    )
  }

  onProductTag = (product) => {
    let isExisting = this.state.taggedProducts.find(prod => prod.id === product.id)
    if (!isExisting) {
      this.setState(prevState => ({
        taggedProducts: [
          ...prevState.taggedProducts,
          product
        ],
        errors: {
          ...prevState.errors,
          taggedProducts: null
        }
      }))
    }
  }

  renderTaggedProduct = ({item}) => {
    const {name, id} = item
    return (
      <View style={[
        styles.taggedProductContainer,
        {width: this.props.screenInfo.width * (3/4)}
      ]}>
        <Text
          style={styles.taggedProductText}
          numberOfLines={1}>{name}</Text>
        <TouchableOpacity
          style={{marginLeft: 10}}
          onPress={() => {
            this.setState(prevState => ({
              taggedProducts: prevState.taggedProducts.filter(p => p.id !== id)
            }))
          }}
        >
          <Icons
            style={styles.closeIcon}
            name='md-close'
            size={20}
          />
        </TouchableOpacity>

      </View>
    )
  }

  renderPostOptions() {
    const { story, initialValues, errors } = this.state
    const { postHandler } = this.props
    if (postHandler && !initialValues) {
      return null
    }
    return (
      <View>
        <TaggingContext.Consumer>
          {(context = {}) => {
            const { taggingHook = () => {} } = context
            return (
              <>
              <ElegantInputWithTagging
                scrollEnabled={false}
                defaultContent={story}
                error={errors.story ? i18n.t(errors.story) : null}
                label={i18n.t('post.labels.story')}
                taggingHook={(data) => taggingHook(data, {shopProducts: false})}
                onChange={(value) => this.onChange(value, 'story')}
                baseColor={baseColor}
              />
              {this.renderProductTagButton(taggingHook)}
              </>
            )
          }
        }
        </TaggingContext.Consumer>
        {this.renderTaggedProducts()}
      </View>
    )
  }

  carouselAction = () => {
    return (
      <TouchableOpacity
        onPress={() => {}}
        style={styles.carouselActionContainer}
      >
        <Icons style={styles.carouselActionIcon} name='ios-close' size={56} />
      </TouchableOpacity>
    )
  }

  imagePlaceholder = () => {
    const { screenInfo } = this.props
    const width = screenInfo.contentWidth
    const height = (width * 9) / 16
    return (
      <View style={[styles.imagePlaceholder, { width, height }]}>
        <UploadButton onChange={handleImage} allowVideo={true} showLoader={this.showVideoLoader} hideLoader={this.hideVideoLoader}>
          <Text style={styles.placeholderImageText}>
            {i18n.t('upload.photo')}
          </Text>
        </UploadButton>
      </View>
    )
  }

  renderIOSSubmit = () => {
    const isRecipe = this.isRecipe()
    const isEdit = this.isEdit()
    const style = branch({
      iphonex: {
        paddingBottom: this.state.keyboardOpen ? 10 : bottomBarHeight / 2,
      },
      iphone: {
        paddingBottom: this.state.keyboardOpen ? 18 : bottomBarHeight / 6,
      },
      other: {},
    })
    const extraPadding = _.get(style, 'paddingBottom', 0)
    const heightStyle = { height: bottomBarHeight + extraPadding }
    return (
      <KeyboardAvoidingView
        behavior='position'
        style={[styles.callToActionContainer, heightStyle]}
        keyboardVerticalOffset={-225} // Weird bug causes keyboard elements to fly all over the screen if not set.
        contentContainerStyle={{
          flex: 1,
        }}
      >
        {/* <View style={styles.callToActionContainer}> */}
        <View style={[styles.buttonPublishContainer, heightStyle, style]}>
          <TouchableOpacity
            style={styles.buttonPublish}
            activeOpacity={0.75}
            onPress={this.publish}
          >
            <Text style={styles.buttonPublishLabel}>
              {this.getSubmitText(isRecipe, isEdit)}
            </Text>
          </TouchableOpacity>
        </View>
        {/* </View> */}
      </KeyboardAvoidingView>
    )
  }

  renderAndroidSubmit = () => {
    const isRecipe = this.isRecipe()
    const isEdit = this.isEdit()
    return (
      <KeyboardAvoidingView
        // behavior='position'
        style={[styles.callToActionContainer]}
        contentContainerStyle={{ flex: 1 }}
      >
        <View
          style={[
            styles.buttonPublishContainer,
            // { top: sizes.TOP_BAR_HEIGHT + bottomBarHeight * 2 },
          ]}
        >
          <TouchableDebounced
            style={styles.buttonPublish}
            activeOpacity={0.75}
            onPress={this.publish}
          >
            <Text style={styles.buttonPublishLabel}>
              {this.getSubmitText(isRecipe, isEdit)}
            </Text>
          </TouchableDebounced>
        </View>
      </KeyboardAvoidingView>
    )
  }

  scrollToBottom = () => {
    // this.scrollView && this.scrollView.scrollToEnd({ animated: true })
  }

  filterOutUpload = (files, id) => {
    return _.filter(files, file => file.name ? !file.name.includes(id) : !file.id.includes(id))
  }

  handleDelete = (data) => {
    let id = data.name ? data.name : data.id
    id = id.replace('thumbnail-', '').split('.')[0]
    const newUploads = this.filterOutUpload(this.state.uploads, id)
    const newPendingUploads = this.filterOutUpload(this.state.pendingUploads, id)
    const uploadsToRemove =  _.filter(this.state.pendingUploads, file => file.name ? file.name.includes(id) : file.id.includes(id))

    const newState = { uploads: newUploads, pendingUploads: newPendingUploads, imagesToDelete: [...this.state.imagesToDelete, data] }
    this.setState(newState, () => {
      removeUploadsFromCache(uploadsToRemove)
    })
  }

  renderSubmit = () => {
    return Platform.OS === 'android'
      ? this.renderAndroidSubmit()
      : this.renderIOSSubmit()
  }

  render() {
    const { uploads, story, errors, keyboardOpen } = this.state
    const { screenInfo, postHandler, keyboardInfo } = this.props
    const keyboardPaddingStyle = getKeyboardPaddingStyle(keyboardInfo)
    const isRecipe = this.isRecipe()
    const isEdit = this.isEdit()
    return (
      <KeyboardAvoidingView
        style={{ flex: 1, alignItems: 'center' }}
        keyboardVerticalOffset={
          Platform.OS === 'android' ? undefined : sizes.TOP_BAR_HEIGHT
        }
        behavior={Platform.OS === 'android' ? undefined : 'padding'}
        // keyboardVerticalOffset={-500}
        contentContainerStyle={{ flex: 1 }}
      >
        <View>
          <Scroller
            keyboardDismissMode='none'
            keyboardShouldPersistTaps='handled'
            innerRef={(r) => (this.scrollView = r)}
          >
            {this.renderPreview()}
            <View style={styles.bodyContainer}>
              <ErrorMessage
                labelStyle={styles.errorText}
                t={errors.uploads ? errors.uploads : null}
                style={styles.error}
              />
              {!isRecipe && this.renderPostOptions()}
              {isRecipe && this.renderRecipeOptions()}
              {/* {this.errors()} */}
              <ErrorMessage
                labelStyle={styles.errorText}
                t={errors.publish ? errors.publish : null}
                style={styles.error}
              />
            </View>
            {Platform.OS === 'android' ? (
              <View // android handles layout differently than ios and requires a spacer at the bottom of the page to layout properly.
                style={[{
                  height: bottomBarHeight,
                  width: 50,
                  // backgroundColor: 'green',
                }, keyboardPaddingStyle]}
              />
            ) : null}
          </Scroller>
          {this.renderSubmit()}
          <View style={[keyboardPaddingStyle]} />
        </View>
      </KeyboardAvoidingView>
    )
  }

  getSubmitText = (isRecipe, isEdit) => {
    if (this.state.isPublishing) {
      return i18n.t('common.posting')
    }
    if (isRecipe) {
      return isEdit ? i18n.t('post.save') : i18n.t('post.publish')
    } else {
      return isEdit ? i18n.t('post.save') : i18n.t('post.publish')
    }
  }

  onChange = (value, name) => {
    this.animate()
    let newValue = value

    if (name === 'code') {
      newValue = value.replace(/[^0-9]/g, '')
    }
    const nextState = produce(this.state, (draft) => {
      draft[name] = newValue
      draft.errors[name] = null
    })
    this.setState(nextState)
  }

  animate() {
    LayoutAnimation &&
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }

  publish = () => {
    if (OS !== 'web') {
      Keyboard.dismiss()
    }

    if (this.state.isPublishing) return

    if (this.hasErrors()) return
    this.handleUploadFiles()
    this.setState({isPublishing: true }, () => {
      this.publishContent()
    })
  }

  goBack = () => {
    const backRouteName = _.get(this.props.navigation, 'state.params.backRouteName')

    if (backRouteName) {
      NavigationActions.goTo({
        routeName: backRouteName,
        params: { scrollToTop: true },
      })
    } else {
      NavigationActions.back()
    }
  }

  publishContent = async () => {
    const { navigation, dispatch } = this.props
    const { story, uploads, taggedProducts } = this.state

    const id = _.get(navigation, 'state.params.id')
    const isRecipe = this.isRecipe()

    const allUploads = [...uploads]
    const formattedUploads = allUploads.map((u) => ({
      name: u.name ? u.name : u.id,
      width: u.width,
      height: u.height,
      mime: u.mime,
    }))
    let payload = {
      id,
      taggedProducts: taggedProducts.map(p => p.id),
      story,
      uploads: formattedUploads,
    }
    if (isRecipe) {
      const {
        title,
        ingredients,
        instructions,
        difficulty,
        prepTime,
        cookTime,
        cost,
        servings,
        recipeLink,
        diet,
      } = this.state

      payload = {
        ...payload,
        title,
        ingredients,
        instructions,
        difficulty: Number(difficulty),
        prepTime: Number(prepTime),
        cookTime: Number(cookTime),
        cost: Number(cost),
        servings: Number(servings),
        recipeLink,
        info: { diet: (diet || []).map(i => i.value) },
      }
    }
    dispatch({
      type: CONTENT_IS_PENDING,
      contentType: isRecipe ? 'recipe' : 'post',
      pendingUploads: this.state.pendingUploads.filter(file => !!this.props.uploads[file.id] && this.props.uploads[file.id].state !== 'complete').map(file => this.props.uploads[file.id]),
      allUploads: [...this.state.pendingUploads],
      isRecipe,
      payload,
      imagesToDelete: this.state.imagesToDelete,
    })
    this.goBack()
  }

  errors() {
    const { errors } = this.state
    return (
      <View>
        {Object.keys(errors).map((key) => (
          <ErrorMessage
            key={`error-${key}`}
            t={errors[key]}
            style={styles.error}
          />
        ))}
      </View>
    )
  }

  hasErrors = () => {
    const isRecipe = this.isRecipe()
    let hasErrors = false
    const nextState = produce(this.state, (draft) => {
      const requiredFields = isRecipe ? requiredPostFields.recipe : requiredPostFields.post
      requiredFields.forEach((field) => {
        let value = draft[field]
        if (field === 'diet') {
          value = draft[field]
        } else if (value && !isString(value)) {
          value = value.value // tagged fields correspond to an object with a value property
        }
        if (_.isEmpty(value)) {
          hasErrors = true
          draft.errors[field] = `errors.recipeDetails.${field}.required`
        } else {
          draft.errors[field] = null
        }
      })
      const { uploads, taggedProducts, recipeLink } = draft

      if (isRecipe && _.isString(recipeLink) && recipeLink.trim().length > 0) {
        if(!isWebsite(recipeLink)) {
          hasErrors = true
          draft.errors['recipeLink'] = 'errors.website.invalid'
        } else {
          draft.errors['recipeLink'] = null
        }
      }
      if (taggedProducts.length === 0) {
        hasErrors = true
        draft.errors['taggedProducts'] = 'errors.taggedProducts.required'
      } else {
        draft.errors['taggedProducts'] = null
      }
      if (uploads.length === 0) {
        hasErrors = true
        draft.errors['uploads'] = 'errors.postUploads.required'
      } else {
        draft.errors['uploads'] = null
      }
      draft.errors['publish'] = null
    })
    this.setState(nextState)
    return hasErrors
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    screenInfo: state.screenInfo,
    uploads: state.uploads,
    lastSeen: state.lastSeen,
  }
}

const mapDispatchToProps = (dispatch) => ({
  analyticsPublishPostWithProductsTagged: (data) => {
    dispatch({
      type: ANALYTICS_PUBLISH_POST_WITH_PRODUCTS_TAGGED,
      data: data,
    })
  },
  queueUpload: (file) => {
    dispatch({
      type: 'Upload/ADD',
      file,
    })
  },
  dispatch,
})

PostCreate.navigationOptions = (props) => {
  const { navigation } = props
  const { state } = navigation
  const isRecipe = _.get(state, 'params.isRecipe')
  const id = _.get(state, 'params.id')
  let headerTitle = i18n.t(isRecipe ? 'recipe.new' : 'post.new')
  if (id) {
    headerTitle = i18n.t(isRecipe ? 'recipe.edit' : 'post.edit')
  }

  return {
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => navigationOptionsCallbacks.back() }
      />
    ),
    headerTitle,
    headerRight: () => (
      <UploadButton
        onChange={handleImage}
        showLoader={showVideoLoader}
        hideLoader={hideVideoLoader}
        allowVideo={true}
        style={styles.cameraButton}
      >
        {AddAPhotoOutlineIcon}
      </UploadButton>
    ),
    tabBarVisible: false,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(schema.mutations.createPost, {
    name: 'createPost',
  }),
  graphql(schema.mutations.createRecipe, {
    name: 'createRecipe',
  }),
  graphql(schema.mutations.editPost, {
    name: 'editPost',
  }),
  graphql(schema.mutations.editRecipe, {
    name: 'editRecipe',
  }),
  graphql(schema.mutations.deleteUpload, { name: 'deleteImage' }),
  graphql(schema.queries.post, {
    name: 'postHandler',
    skip: ({ navigation }) =>
      _.get(navigation, 'state.params.id', undefined) === undefined,
    options({ navigation }) {
      const id = _.get(navigation, 'state.params.id')
      return { variables: { id } }
    },
  }),
)(PostCreate)

const baseColor = '#BBB'

const styles = stylus({
  container: {
    flex: 1,
  },
  bodyContainer: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 4,
    // backgroundColor: 'red',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginVertical: 12,
  },
  input: {
    // minHeight: 150,
  },
  callToActionContainer: {
    width: '100%',
    height: bottomBarHeight,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonPublish: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: colors.primary,
    borderRadius: 5,
    width: '90%',
  },
  buttonPublishContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    height: bottomBarHeight,
    justifyContent: 'center',
    bottom: -1, // Fills in small empty area above the keyboard.
    backgroundColor: colors.emptyArea,
    padding: 10,
  },
  buttonPublishLabel: {
    fontWeight: '600',
    fontSize: 14,
    color: 'white',
    backgroundColor: 'transparent',
  },
  carouselActionContainer: {
    position: 'absolute',
    top: 0,
    right: 10,
  },
  carouselActionIcon: {
    backgroundColor: 'transparent',
    color: 'white',
    fontWeight: 'bold',
  },
  recipeToggle: {
    position: 'absolute',
    top: 8,
    right: 16,
  },
  toggleContainer: {
    marginTop: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: sizes.px,
    borderBottomColor: baseColor,
    paddingVertical: 10,
  },
  toggleLabel: {
    fontSize: 14,
    fontWeight: '500',
    flex: 1,
  },
  cameraButton: {
    width: 45,
    paddingRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  imagePlaceholder: {
    backgroundColor: colors.emptyArea,
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeholderImageText: {
    padding: 30,
    fontSize: 16,
    color: colors.text.light,
    fontWeight: '300',
  },
  errorText: {
    color: colors.input.error
  },
  tagButtonContainer: {
    marginBottom: 18
  },
  tagButton: {
    flexDirection: 'row',
    marginTop: 5,
    alignItems: 'center'
  },
  tagButtonText: {
    fontSize: 16,
    color: colors.taggingText
  },
  searchIcon: {
    paddingRight: 12,
    paddingTop: 2,
    color: colors.taggingText,
  },
  taggedProductContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderColor: colors.androidCardBorder,
    borderWidth: 1,
    borderRadius: 5,
    paddingVertical: 2,
    paddingHorizontal: 10,
    marginBottom: 10,
    marginRight: 10,
  },
  taggedProductText: {
    flex: 1,
    fontSize: 13,
    color: colors.text.light
  },
  closeIcon: {
    color: colors.androidCardBorder,
    paddingTop: 2
  },
  tagDescription: {
    fontSize: 13,
    color: colors.text.light,
    paddingLeft: 35,
    marginTop: -6
  },
  taggedProductsError: {
    textAlign: 'left',
    marginTop: 2,
    paddingLeft: 35,
  },
  taggedProductErrorText: {
    textAlign: 'left',
    color: colors.input.error,
    fontSize: 12
  }
})
