import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { ActivityIndicator,  StyleSheet, SafeAreaView, FlatList, View, Text } from 'react-native'
import PropTypes from 'prop-types'
import { compose, hoistStatics } from 'recompose'
import { graphql } from 'react-apollo'
import i18n from 'i18n-js'
import { Ionicons } from '@expo/vector-icons'
import _ from 'lodash'

import sizes from '../../config/sizes'
import colors from '../../config/colors'
import { getAlertErrorConfig } from '../../config/helpers'
import noticeSchema from '../../schema/notice'
import configSchema from '../../schema/config'
import NavigationActions from '../../utility/navigationActions'
import alert from '../../utility/alert'
import Action from '../../components/simple/Action'
import MobileBackButton from '../../components/simple/MobileBackButton'

import NoticeItem from './NoticeItem'
import CreateNotice from './CreateNotice'

const INITIAL_PAGE_SIZE = 20
const SUBSEQUENT_PAGE_SIZE = 20
const INITIAL_OFFSET = 0

let openCreateModalFromNavigationHeaderBar = () => {}

const NoticesList = ({ getAllNotices, maxNoticeLength, createNotice , navigation }) => {
  const [offset, setOffset] = useState(INITIAL_OFFSET)
  const [noMore, setNoMore] = useState(false)
  const [createModalVisible, setCreateModalVisibility] = useState(false)

  const maxNoticeMessageLength = useMemo(() => {
    return _.get(maxNoticeLength, 'maxNoticeLength', 160)
  }, [maxNoticeLength.maxNoticeLength])

  useEffect(() => {
    const { params } = navigation.state
    if (params && params.doRefetch) {
      refresh()
    }
  }, [navigation, refresh])

  openCreateModalFromNavigationHeaderBar = () => {
    setCreateModalVisibility(true)
  }

  const refresh = useCallback(() => {
    getAllNotices.refetch()
    setOffset(0)
    setNoMore(false)
  }, [getAllNotices])

  function isLoading()  {
    return getAllNotices.networkStatus < 7
  }

  function loadMore() {
    if (isLoading()) return
    if (noMore) return
    if (getAllNotices.notices.length < offset) {
      setNoMore(true)
      return
    }
    const newOffset = offset + SUBSEQUENT_PAGE_SIZE
    
    setOffset(newOffset)

    getAllNotices.fetchMore({
      variables: {
        offset: newOffset
      }, 
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        
        const newData = _.uniqBy(
          _.union(prev.notices, fetchMoreResult.notices),
          'id',
        )
        return { notices: newData }
      },
    })
  }

  async function handleCreateNoticeSubmit({ name, message }) {
    try {
      await createNotice({
        variables: {
          name,
          message,
        },
      })
      refresh()
      return true
    } catch (e) {
      const alertConfig = getAlertErrorConfig(e)
      await alert(alertConfig)
      return false
    }
  }

  return <SafeAreaView style={styles.container}>
    <FlatList
      refreshing={
        getAllNotices.refreshing || getAllNotices.networkStatus === 4
      }
      onRefresh={refresh}
      data={getAllNotices.notices}
      renderItem={({ item }) => <NoticeItem notice={item} />}
      keyExtractor={item => item.id}
      onEndReachedThreshold={0.4}
      onEndReached={loadMore}
      ListFooterComponent={() =>
        isLoading() && <ActivityIndicator style={{ marginBottom: 50}} size="small" />
      }
    />
    <CreateNotice
      formIsVisible={createModalVisible}
      maxMessageLength={maxNoticeMessageLength}
      onClose={() => setCreateModalVisibility(false)}
      onSubmit={handleCreateNoticeSubmit}
    />
  </SafeAreaView>
}

NoticesList.propTypes = {
  navigation: PropTypes.any,
  getAllNotices: PropTypes.any,
  maxNoticeLength: PropTypes.any,
  createNotice: PropTypes.func
}

NoticesList.navigationOptions = ({ navigation }) => {
  return {
    title: i18n.t('settings.sectionLinks.notices'),
    headerLeft: () => (<MobileBackButton onPress={() => {
      NavigationActions.back()
    }} />),
    headerRight: () => (
      <Action
        style={{ paddingRight: 22 }}
        onPress={openCreateModalFromNavigationHeaderBar}
      >
        <Ionicons name='ios-add' size={36} color={colors.navigation} />
      </Action>
    ),
  }
}

const enchanced = compose(
  graphql(noticeSchema.queries.getAllNotices, {
    name: 'getAllNotices',
    options: (props) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        limit: INITIAL_PAGE_SIZE,
        offset: INITIAL_OFFSET,
      },
    }),
  }),
  graphql(configSchema.queries.maxNoticeLength, {
    name: 'maxNoticeLength',
  }),
  graphql(noticeSchema.mutations.createNotice, {
    name: 'createNotice',
  }),
)

export default hoistStatics(enchanced)(NoticesList)

const styles = StyleSheet.create({
  container: { 
    flex: 1, 
    marginBottom: sizes.tabBarHeight
  }
})
