import React from 'react'
import { View, Text , TouchableOpacity } from 'react-native'
import NamedImage from '../../../components/simple/NamedImage'
import { stylus } from '../../../config/styles'
import { getScaledImageDimensions, getPrice, unitPlural, getProductPrice } from '../../../config/helpers'
import colors from '../../../config/colors'
import Ratings from '../../../components/simple/Ratings'
import Price from '../../../components/simple/Price'
import sizes from '../../../config/sizes'
import PropTypes from 'prop-types'
import _ from 'lodash'
export const maxResultWidth = (contentMaxWidth) => (contentMaxWidth * 0.3)
const maxResultImageHeight = (imageWidth) => imageWidth * 0.7
// NOTE: Theory is that maxResultHeight should be 2 times the image height, seeing as the image height tends to always be a bit taller than the details
// NOTE: Normally that would be: maxWidth * 1/2 * 7/10 * 2. Since 2 times 1/2 is 1, we can make it simply: maxWidth * 7/10
export const maxResultHeight = (contentMaxWidth) => contentMaxWidth * 0.53//0.63 // making it slightly shorter

class ProductResult extends React.Component {

  static propTypes = {
    product: PropTypes.object,
    onPress: PropTypes.func,
    showBottomBorder: PropTypes.bool,
    marginLeft: PropTypes.number,
    marginRight: PropTypes.number,
    contentMaxWidth: PropTypes.number,
    twoColumnWrap: PropTypes.bool,
    index: PropTypes.number,
    active: PropTypes.bool,
  }

  static defaultProps = {
    twoColumnWrap: false,
    showBottomBorder: true,
    active: false,
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.active !== this.props.active || nextProps.index !== this.props.index
  }

  getMaxItemWidth() {
    const { contentMaxWidth, marginLeft, twoColumnWrap } = this.props
    let maxWidth = maxResultWidth(contentMaxWidth)
    if (twoColumnWrap) {
      // Item that is in a two column wrap only has left margin if it is the end item(i.e. second item in row of the wrap).
      // To fit 2 items in a 2 column wrap, both items must have a width reduction by half the marginLeft that will be applied to the second item.
      maxWidth = (contentMaxWidth-(marginLeft/3)) * 0.29// NOTE: Must still be slightly less than 0.3
    }
    return maxWidth
  }

  getItemContainerStyles(maxItemWidth) {
    const { index, marginLeft, marginRight, twoColumnWrap } = this.props
    const containerStyles = [styles.container, { flexBasis: maxItemWidth }]
    if ((!twoColumnWrap || index % 3 !== 0) && marginLeft) {
      // Item that is in a two column wrap only has left margin if it is the end item(i.e. second item in row of the wrap aka odd item).
      containerStyles.push({ marginLeft })
    }
    if (!twoColumnWrap && marginRight) {
      containerStyles.push({ marginRight })
    }
    return containerStyles
  }

  image(maxImageWidth) {
    const fileUpload = _.get(this, 'props.product.uploads.0', {})
    const fileUploadName = _.get(fileUpload, 'name', 'placeholder')
    const width = maxImageWidth - 10
    const height = width
    return (

      <View style={[ styles.imageContainer, { height: maxImageWidth, width: maxImageWidth} ]}>
        <NamedImage
          width={width}
          height={width}
          style={{
            width,
            height,
            borderRadius: 4 // width/2,
          }}
          name={fileUploadName}
          fit='crop'
          resizeMethod='scale'
          resizeMode='cover'
        />
      </View>
    )
  }

  details(maxItemWidth) {
    const { product, showBottomBorder, active } = this.props
    let { name, profile, rating, ratings } = product
    const { priceDiscount, price } = getProductPrice(product)
    let hasPriceDiscount = !!priceDiscount && priceDiscount < price

    if (ratings === undefined) {
      ratings = 0
    }
    if (rating === undefined) {
      rating = 0
    }

    const unitProduct = unitPlural(product)

    return (
      <View style={[showBottomBorder && styles.detailsBorder, active && styles.active, styles.details, { width: maxItemWidth }]}>
        <Text numberOfLines={1} style={[styles.productName]}>{name}</Text>
        {!!unitProduct && (
          <Text style={styles.unitProduct}>
            {unitProduct}
          </Text>
        )}
        <Text numberOfLines={1} style={[styles.shopName]}>{profile.displayName}</Text>
        <Price
          hasPriceDiscount={hasPriceDiscount}
          price={price}
          priceDiscount={priceDiscount}
        />
        <Ratings size={13} rating={rating} ratings={ratings} showRatings={true}/>
      </View>
    )
  }

  render() {
    const maxItemWidth = this.getMaxItemWidth()
    const containerStyles = this.getItemContainerStyles(maxItemWidth)
    return (
      <View style={containerStyles}>
        <TouchableOpacity style={styles.touchable} onPress={this.productOnPress}>
          { this.image(maxItemWidth) }
          { this.details(maxItemWidth) }
        </TouchableOpacity>
      </View>
    )
  }

  productOnPress = () => {
    this.props.onPress(this.props.product)
  }
}

export default ProductResult

const styles = stylus({
  container: {
    marginBottom: 10,
  },
  imageContainer: {
    // borderWidth: 1,
    // padding: 4,
    // borderColor: '#CCC',
    // overflow: 'hidden',
    // borderRadius: 9999,
  },
  active: {
    borderBottomWidth: 2,
    borderBottomColor: colors.primary,
  },
  touchable: {
    flexDirection: 'column',
  },
  details: {
    paddingBottom: 10,
    paddingTop: 5,
  },
  detailsBorder: {
    borderBottomWidth: 2 * sizes.px,
    borderBottomColor: colors.thinLine,
  },
  productName: {
    fontSize: 14,
    fontWeight: '600',
    color: colors.text.main
  },
  unitProduct: {
    fontSize: 13,
    color: colors.text.main,
  },
  shopName:  {
    fontSize: 13,
    color: colors.text.main
  }
})
