import React from 'react'
import { View, Text } from 'react-native'
import Icons from '@expo/vector-icons/Ionicons'
import Icon from '../../config/icon'
import { stylus } from '../../config/styles'
import i18n from 'i18n-js'
import colors from '../../config/colors'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'

class RecipeInfoBar extends React.Component {
  static propTypes = {
    recipe: PropTypes.object,
    expanded: PropTypes.bool, // for displaying label of aspect
    wrapped: PropTypes.bool, // for displaying aspects wrapping onto succeeding lines
    grid: PropTypes.bool, // for displaying a 2 row representation of the aspects
    search: PropTypes.bool,
    screenInfo: PropTypes.object,
    containerStyle: PropTypes.object,
    textSize: PropTypes.number,
    iconSize: PropTypes.number,
  }

  static defaultProps = {
    expanded: false,
    wrapped: false,
    itemGrid: false,
    textSize: 16,
    iconSize: 22,
  }
  getCost = (cost) => {
    let elements = ''
    for (let i = 0; i < Math.min(cost, 3); i++) {
      elements = elements + '€'
      //TODO: Future - Add better support for different currencies.
    }
    return elements
  }

  render() {
    const {
      recipe,
      wrapped,
      expanded,
      grid,
      search,
      screenInfo,
      containerStyle,
      textSize,
      iconSize,
    } = this.props
    const expandedIconContainer = {
      width: Math.floor(screenInfo.contentWidth * 0.45),
    }
    const { difficulty, prepTime, cookTime, cost, servings } = recipe
    const containerStyles = [styles.container, containerStyle]
    const iconContainerStyles = [styles.iconContainer]
    if (expanded) {
      containerStyles.push(styles.expandedContainer)
      iconContainerStyles.push(expandedIconContainer)
    }
    if (wrapped) {
      containerStyles.push(styles.wrapItemsContainer)
      iconContainerStyles.push(styles.wrapItemsIconContainer)
    }
    const textStyles = [styles.labelText, { fontSize: textSize }]

    const difficultyAspect = {
      iconName: 'chef',
      textValue: `${
        expanded ? `${i18n.t('post.labels.difficulty')}: ` : ''
      }${i18n.t(`recipe.difficulty.${difficulty}`)}`,
      textStyles,
    }
    const prepTimeAspect = {
      iconName: 'clock',
      textValue: `${
        expanded ? `${i18n.t('post.labels.prepTime')}: ` : ''
      }${prepTime}m`,
      textStyles,
    }

    const cookTimeAspect = {
      iconName: 'cooktime',
      textValue: `${
        expanded ? `${i18n.t('post.labels.cookTime')}: ` : ''
      }${cookTime}m`,
      textStyles,
    }

    const costAspect = {
      textStyles,
      textValue: `${
        expanded ? ` ${i18n.t('post.labels.cost')}: ` : ''
      }${this.getCost(cost)}`,
    }

    const servingsAspect = {
      iconName: 'group',
      textValue: `${
        expanded ? `${i18n.t('post.labels.servings')}: ` : ''
      }${servings}`,
      textStyles,
    }

    const singleRow = [
      difficultyAspect,
      prepTimeAspect,
      cookTimeAspect,
      costAspect,
      servingsAspect,
    ]
    const searchRow = { difficultyAspect, prepTimeAspect, cookTimeAspect }
    const row1 = [difficultyAspect, costAspect, servingsAspect]
    const row2 = [prepTimeAspect, cookTimeAspect]
    if (grid) {
      return this.renderTwoRows(
        row1,
        row2,
        containerStyles,
        iconContainerStyles,
        iconSize,
      )
    } else if (search) {
      return this.renderSearchRow(
        searchRow,
        containerStyles,
        iconContainerStyles,
        iconSize,
      )
    }
    return this.renderSingleRow(
      singleRow,
      containerStyles,
      iconContainerStyles,
      iconSize,
    )
  }

  renderSingleRow = (row, containerStyles, iconContainerStyles, iconSize) => (
    <View style={containerStyles}>
      {row.map((aspect, index) =>
        this.renderAspectInfo(aspect, iconContainerStyles, iconSize, index),
      )}
    </View>
  )

  renderTwoRows = (
    row1,
    row2,
    containerStyles,
    iconContainerStyles,
    iconSize,
  ) => (
    <View>
      <View style={[containerStyles, { justifyContent: 'space-around' }]}>
        {row2.map((aspect, index) =>
          this.renderAspectInfo(aspect, iconContainerStyles, iconSize, index),
        )}
      </View>
      <View style={[containerStyles, { justifyContent: 'space-around' }]}>
        {row1.map((aspect, index) =>
          this.renderAspectInfo(aspect, iconContainerStyles, iconSize, index),
        )}
      </View>
    </View>
  )

  renderSearchRow = (
    searchRow,
    containerStyles,
    iconContainerStyles,
    iconSize,
  ) => {
    const difficultyContainerStyle = [iconContainerStyles, { flex: 4 }]
    const prepTimeContainerStyle = [iconContainerStyles, { flex: 3 }]
    const cookTimeContainerStyle = [iconContainerStyles, { flex: 3 }]
    return (
      <View style={containerStyles}>
        {this.renderAspectInfo(
          searchRow.difficultyAspect,
          difficultyContainerStyle,
          iconSize,
          0,
        )}
        {this.renderAspectInfo(
          searchRow.prepTimeAspect,
          prepTimeContainerStyle,
          iconSize,
          1,
        )}
        {this.renderAspectInfo(
          searchRow.cookTimeAspect,
          cookTimeContainerStyle,
          iconSize,
          2,
        )}
      </View>
    )
  }

  renderAspectInfo = (
    { textStyles, iconName, textValue },
    iconContainerStyles,
    iconSize,
    index,
  ) => (
    <View key={index} style={iconContainerStyles}>
      {iconName && (
        <Icon
          style={styles.icon}
          name={iconName}
          size={iconSize}
          color={colors.icon.strong}
        />
      )}
      <Text style={textStyles}>{textValue}</Text>
    </View>
  )
}

const styles = stylus({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 16,
    paddingLeft: 14,
    marginTop: 1,
  },
  expandedContainer: {
    flexWrap: 'wrap',
  },
  wrapItemsContainer: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  wrapItemsIconContainer: {
    marginRight: 5,
  },
  icon: {
    backgroundColor: 'transparent',
    marginRight: 6,
  },
  labelText: {
    backgroundColor: 'transparent',
    color: colors.text.main,
  },
  iconContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecipeInfoBar)
