import React from 'react'
import { Platform, Text, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import Icons from '@expo/vector-icons/Ionicons'

let TouchableBounce = TouchableOpacity
if (Platform.OS !== 'web') {
  TouchableBounce = require('react-native/Libraries/Components/Touchable/TouchableBounce')
}

const SHOW_ICONS = false

const ProfileAction = ({ icon, label, onPress, size = 20, style, textStyle }) => (
  <TouchableBounce
    style={[styles.container, SHOW_ICONS && styles.containerWithIcons, style]}
    onPress={onPress}
  >
    {SHOW_ICONS && (
      <Icons
        name={icon}
        size={size}
        color={colors.text.main}
        style={{ paddingTop: 1 }}
      />
    )}
    <Text style={[styles.label, SHOW_ICONS && styles.labelWithIcons, textStyle]}>
      {label}
    </Text>
  </TouchableBounce>
)

ProfileAction.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.number,
  platform: PropTypes.string,
  style: PropTypes.object,
  textStyle: PropTypes.object,
  onPress: PropTypes.func,
}

export default ProfileAction

const styles = stylus({
  container: {
    height: 30,
    borderRadius: 15,
    borderColor: colors.text.secondary,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    // paddingVertical: 2,
    flexDirection: 'row',
    marginHorizontal: 6,
  },
  containerWithIcons: {
    paddingLeft: 8,
  },
  label: {
    color: colors.text.main,
    fontSize: 13,
    android: {
      paddingBottom: 1,
    },
    // paddingBottom: 1,
    // web: {
    //  paddingBottom: 0,
    // },
  },
  labelWithIcons: {
    marginLeft: 6,
  },
})
