import React from 'react'
import {
  Text,
  View,
  ActivityIndicator,
} from 'react-native'

import { logout } from '../../utility/apollo'

import {
  connect,
  compose,
} from '../../config/connected'
import withCurrentUser from '../../containers/withCurrentUser'
import ListItem from '../../components/simple/ListItem'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'

class Profile extends React.Component {
  render() {
    const { screenInfo, currentUser } = this.props

    return <View style={styles.container}>{this.content()}</View>
  }
  content() {
    const {
      currentUser: { currentUser, loading, error },
    } = this.props
    if (loading || !currentUser) {
      return <ActivityIndicator style={{ marginVertical: 20 }} />
    }
    return (
      <View style={styles.innerContainer}>
        <Text style={styles.name}>{currentUser.name || '...'}</Text>
        <Text style={styles.email}>
          {currentUser.email || 'unable to connect to network'}
        </Text>
        <View style={styles.bottomLinks}>
          <ListItem
            left={<Icons name='ios-power' size={20} color='#333' />}
            leftStyle={{ width: 36 }}
            title='Sign out'
            titleStyle={{ fontSize: 15 }}
            last={true}
            first={false}
            onPress={this.logout}
            soft
          />
          <ListItem
            leftStyle={{ width: 36 }}
            title='Sign out'
            titleStyle={{ fontSize: 15 }}
            last={true}
            first={false}
            onPress={this.logout}
            soft
          />
        </View>
      </View>
    )
  }
  logout = () => {
    this.props.toggle()
    this.props.logout()
  }
}

const styles = stylus({
  container: {
    right: 29,
    top: 56,
    position: 'absolute',
    width: 300,
    // height: 400,
    borderRadius: 8,
    borderTopRightRadius: 0,
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 4 },
    shadowOpacity: 0.15,
    shadowRadius: 18,
    paddingTop: 40,
    alignItems: 'center',
    android: {
      borderWidth: sizes.px,
      borderColor: colors.androidCardBorder,
      // elevation: 0.5,
    },
    ios: {
      shadowOpacity: 0.18,
      shadowRadius: 10,
      top: 56 + 13,
    },
  },
  name: {
    fontSize: 17,
    fontWeight: '400',
    paddingBottom: 7,
  },
  email: {
    fontSize: 14,
    fontWeight: '300',
    color: colors.text.secondary,
  },
  innerContainer: {
    flex: 1,
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  bottomLinks: {
    marginTop: 40,
    flex: 1,
    alignSelf: 'stretch',
  },
})

const mapStateToProps = (state) => ({
  cuid: state.currentUser.id,
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: () => logout(ownProps.cuid),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCurrentUser,
)(Profile)
