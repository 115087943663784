import React from 'react'
import { View, Text } from 'react-native'
import { stylus } from '../config/styles'

export default function LoadingMessage() {
  return (
    <View style={styles.container}>
      <Text>Loading your experience...</Text>
    </View>
  )
}

const styles = stylus({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
