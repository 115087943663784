import React from 'react'
import { Image, Text, View, TouchableOpacity } from 'react-native'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import { fbLogoText } from '../../assets/images'
const brandLogo = require('../../assets/images/brand.png')
const brandVertical = require('../../assets/images/brand-vertical-small.png')
const brandLogoWhite = require('../../assets/images/brand-white.png')
const brandBlackText = require('../../assets/images/brand-black-text.png')
const brandLogoBlack = require('../../assets/images/fb_black_logo.png')

const RATIO = 1.111666667

class Brand extends React.Component {
  render() {
    const { white, vertical, width } = this.props

    if (vertical) {
      return (
        <TouchableOpacity style={styles.container}>
          <Image
            source={fbLogoText}
            style={[{ width, height: width * RATIO }, styles.image]}
            resizeMode='contain'
          />
        </TouchableOpacity>
      )
    }
    return (
      <TouchableOpacity style={styles.container}>
        <Image
          source={white ? brandLogoWhite : brandLogo}
          style={{ width }}
          resizeMode='contain'
        />
      </TouchableOpacity>
    )
  }
}

export default Brand

const styles = stylus({
  container: {
    alignItems: 'center',
  },
  image: {
    landscape: {
      width: 200,
      height: 200 * RATIO,
    },
  },
})
