import React, { Component } from 'react'
import { Text, View, Image } from 'react-native'

import brand from '../../../../assets/images/brand.png'
import { stylus } from '../../../../config/styles'
import colors from '../../../../config/colors'
import T from '../../../../components/T'
import Button from '../../../../components/simple/Button'
import NavigationActions from '../../../../utility/navigationActions'
import i18n from 'i18n-js'

export class Finish extends Component {
  handleSubmit = () => {
    const { navigation } = this.props
    navigation.state.params.refetchSettings()
    NavigationActions.back()
  }

  render() {
    return (
      <View style={styles.container}>
        <Image
          source={brand}
          style={styles.brand}
          resizeMode='contain'
          resizeMethod='scale'
        />
        <T t='onboardingShop.finish.body' style={styles.body} />
        <Button
          style={styles.submit}
          label={i18n.t('onboardingShop.finish.submit')}
          onPress={this.handleSubmit}
        />
      </View>
    )
  }
}

export default Finish

const styles = stylus({
  container: {
    flex: 1,
    alignItems: 'center',
    margin: 44.5, // Bottom tab bar default height
    paddingBottom: 40,
    justifyContent: 'center',
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    margin: 10,
  },
  brand: {
    width: '100%',
  },
  body: {
    fontSize: 16,
    color: colors.secondary,
    margin: 10,
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  submit: {
    width: '100%',
    marginVertical: 40,
    backgroundColor: colors.primary,
  },
})
