import {
  client as apolloClient,
} from '../containers/withApollo'
import { ANALYTICS_LOGOUT } from '../reducers/analytics'
import reduxStoreHolder from './reduxStoreHolder'
import NavigationActions from './navigationActions'
import * as FileSystem from 'expo-file-system'

import _ from 'lodash'

const debug = false

export function updateCachedQueryValue(client, {
  key = '',
  query,
  variables = {},
  nextValue,
  abortOnError,
}) {
  const querySignature = { query, variables }
  let data = undefined
  let dataKey = key
  try {
    const dataObj = client.readQuery(querySignature)
    const dataObjKeys = Object.getOwnPropertyNames(dataObj)
    if (dataObjKeys.length !== 1) {
      throw new Error('There should only be one data key on the data object returned from readQuery')
    }
    if (!dataKey) {
      dataKey = dataObjKeys[0]
    }
    data = dataObj[dataKey]
    debug && console.log('<<<updateCachedQueryValue - data', data)
  } catch(e) {
    const invariantError = (e.toString()).indexOf('Invariant Violation') === 0
    if (invariantError) {
      console.log('<<<updateCachedQueryValue - invariant error')
    } else {
      console.log('<<<updateCachedQueryValue - error', e)
    }
    if (!dataKey && !invariantError) {
      dataKey = getRootQueryNameFromError(e)
    }
    if (abortOnError || !dataKey) {
      return
    }
  }

  const nextData = nextValue(data)

  debug && console.log('<<<updateCachedQueryValue - nextData', nextData)

  client.writeQuery({
    ...querySignature,
    data: { [dataKey]: nextData},
  })

  return nextData
}

export function getRootQueryNameFromError(error) {
  const queryNameRegex = /.*find field ([a-zA-Z]+).*/gi
  const results = queryNameRegex.exec(error.message)
  if (!results) {
    console.log('apollo-react error has changed. Update function to parse queryName correctly.')
    return ''
  }
  return results[1] //second match is the group that we defined in the regex expression
}


export function getQueryVariables(query, ids, apolloState) {
  const queryVariablesMatcher = new RegExp('^[a-zA-Z0-9]+(.+)$', 'gi')
  const queriesMap = _.get(apolloState, 'data.ROOT_QUERY', {})
  const queryKeys = Object.keys(queriesMap)
  for (const key of queryKeys) {
    if (key.indexOf(query) !== -1 && ids.every((id) => key.indexOf(id) !== -1)) {
      const matches = queryVariablesMatcher.exec(key)
      if (!matches || matches.length <= 1) {
        return {}
      }
      const cleanedMatch = _.trim(_.trim(matches[1], '('), ')')
      return JSON.parse(cleanedMatch || '{}')
    } 
  }
  return {}
}

export const logout = (userId = null) => {
  NavigationActions.navigate({ routeName: 'Logout', params: { userId  }})
}

export const performLogout = async (userId) => {
  const reduxStore = reduxStoreHolder.getStore()

  createLogoutLog(userId)

  reduxStore.dispatch({ type: 'LOGOUT' })

  try {
    await apolloClient.clearStore()
    await apolloClient.cache.reset()  
  } catch (err) {
    console.log(err)
  }

  await removeCacheDirectoryContent()
  await removeDocumentDirectoryContent()
}

const createLogoutLog = (userId) => {
  if (!userId) return
  const reduxStore = reduxStoreHolder.getStore()
  return reduxStore.dispatch({
    type: ANALYTICS_LOGOUT,
    data: {
      currentUserId: userId,
    },
  })
}

const removeCacheDirectoryContent = async () => {
  const contents = await FileSystem.readDirectoryAsync(FileSystem.cacheDirectory)

  await Promise.all(contents.map(async (content) => 
    await FileSystem.deleteAsync(FileSystem.cacheDirectory + content )
  ))
}

const removeDocumentDirectoryContent = async () => {
  const deletableDocuments = ['okhttp']
  const contents = await FileSystem.readDirectoryAsync(FileSystem.documentDirectory)

  await Promise.all(contents
    .filter(content => deletableDocuments.includes(content))
    .map(async (content) => 
      await FileSystem.deleteAsync(FileSystem.documentDirectory + content )
    ))
}
