import React from 'react'
import {
  Text,
  View,
  Image,
  Dimensions,
  ScrollView,
  Platform,
  TouchableOpacity,
  Alert,
  TouchableWithoutFeedback,
  ActivityIndicator
} from 'react-native'
import TabIndicator from '../TabIndicator'

import PropTypes from 'prop-types'
import shared, { stylus } from '../../config/styles'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Video as ExpoVideo, Audio } from 'expo-av'
import NamedImage from '../../components/simple/NamedImage'
import UploadProgress from '../../components/simple/UploadProgress'
import ActivityIndicatorOverlay from '../../components/simple/ActivityIndicatorOverlay'
import nameToUrl from '../../config/nameToUrl'
import Icons from '@expo/vector-icons/Ionicons'
import environment from '../../config/environment'
import { getImageResizeConfig, isVideo } from '../../config/helpers'
import { ScreenOrientation } from 'expo'
import { SET_VIDEO_REF, REMOVE_VIDEO_REF } from '../../reducers/video'
import { stopAllVideos, stopAllVideosById } from '../../utility/video'
import Modal from '../simple/Modal'
import ImageViewer from 'react-native-image-zoom-viewer'

const debug = false
const OS = Platform.OS

let Video = ExpoVideo
if (OS === 'web') {
  Video = () => null
}

const aspectRatio = 1.618

const MIME_TYPES = {
  video: new Set(['video/mp4']),
  image: new Set(['image/jpg', 'image/jpeg', 'image/png']),
}

class ImageCarousel extends React.Component {
  state = {
    index: 0,
    length: this.props.images.length,
    images: [],
    shouldPlay: true,
    showImageViewer: false
  }

  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.any),
    removeItem: PropTypes.func,
    options: PropTypes.object,
    autoScroll: PropTypes.bool,
    imageStyle: PropTypes.object,
    screenInfo: PropTypes.object,
    style: PropTypes.object,
    deleteButton: PropTypes.any,
    showProgress: PropTypes.bool,
    playOnce: PropTypes.bool,
    playMuted: PropTypes.bool,
    pendingRefresh: PropTypes.bool,
    activityIndicatorOverlayEnabled: PropTypes.bool,
  }

  static defaultProps = {
    onIndexChange: () => {},
    showProgress: false,
    activityIndicatorOverlayEnabled: true,
  }

  placeHolderImage = {
    uri: nameToUrl({
      name: '35accafd-8306-4800-b735-42e9d4eb7eaa.jpg',
      height: 200,
      width: 300,
    }),
    mime: 'image/jpg',
    width: 300,
    height: 400,
  }

  renderCloseButton = () => {
    return (
      <TouchableOpacity style={styles.closeButton} onPress={() => this.setState({showImageViewer:false})}>
        <Icons name='ios-close' size={50} color={'white'}/>
      </TouchableOpacity>
    )
  }

  openModalImageViewer = (idx) => {
    this.setState({
      showImageViewer: true,
      modalImageIdx: idx
    })
  }

  imageModalViewer = (idx) => {
    const { images, showImageViewer, modalImageIdx } = this.state
    const { fit = 'crop' } = this.props
    const localImages = images.length ? images : this.props.images

    const modalImages = _.filter(localImages, image => {
      let mime = this.resolveMime(image.mime)
      return MIME_TYPES.image.has(mime)
    })

    const imageUrls = _.map(modalImages, item => {
      return {
        name: item.name,
        url: item.uri || nameToUrl({
          name: item.name,
          fit: fit,
          crop: 'entropy',
          width: this.props.screenInfo.contentWidth,
          height: this.props.screenInfo.contentWidth * this.getRatio(),
        })
      }
    })

    return (
      <Modal
        visible={showImageViewer}
        onRequestClose={() => this.setState({showImageViewer:false})}
      >
        <ImageViewer
          imageUrls={imageUrls}
          backgroundColor={'rgba(0,0,0,0.8)'}
          renderHeader={() => this.renderCloseButton()}
          loadingRender={() => <ActivityIndicator />}
          index={modalImageIdx}
        />
      </Modal>
    )
  }

  setImages = () => {
    const newImages = this.props.images.map((item, index, arr) => {
      const itemName = item.name || item.id
      if(item.mime.includes('mp4')) {
        const thumb = _.find(arr, element => {
          if (!element) return false
          const elementName = element.name || element.id 
          return elementName.includes('thumbnail') && elementName.includes(itemName.split('.')[0])
        })
        return {
          ...item,
          isReady: false,
          shouldPlay: false,
          thumb
        }
      }
      if(itemName && itemName.includes('thumbnail')) {
        return null
      }
      return item
    }).filter(item => item)

    debug && console.log('<<<IMAGE CAROUSEL - setImages', isVideo(_.get(newImages, '[0].id')) ? 'isVideo' : 'isThumbnail')
    // debug && console.log('<<<IMAGE CAROUSEL - setImages - newImages', newImages)

    return newImages
  }

  componentDidMount() {
    const { onIndexChange } = this.props
    onIndexChange(0)
    const images = this.setImages()
    this.setState((prev) => {
      return {
        ...prev,
        images
      }
    })

    Audio.setAudioModeAsync({
      allowsRecordingIOS: false,
      interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: true,
      interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
      playThroughEarpieceAndroid: false,
    })
  }

  imageContainer = React.createRef()

  videos = {}

  getIndex = () => {
    return {
      index: this.state.index,
      images: this.state.images,
    }
  }

  goToImage = (target) => {
    // Accepts, "next", "prev", "ARRAY_INDEX" as targets
    const targetAsIndex = parseInt(target)
    const { length, index } = this.state
    const { onIndexChange } = this.props

    switch (true) {
      case target === 'next' && index !== length - 1:
        this.setState({ index: index + 1 })
        onIndexChange(index + 1)
        break

      case target === 'prev' && index > 0:
        this.setState({ index: index - 1 })
        onIndexChange(index - 1)
        break

      case !isNaN(targetAsIndex):
        if (targetAsIndex >= length) {
          console.log(
            `Image Carousel: Target scrollTo index of {${targetAsIndex}} out of range.`,
          )
          return
        }
        this.setState({ index: targetAsIndex })
        onIndexChange(targetAsIndex)
        break

      default:
        console.log(
          `Image Carousel: Cannot parse target {${target}} to valid target. Only 'next', 'prev' and integers are valid`,
        )
        return
    }
  }

  renderOptions = (idx) => {
    const { options, screenInfo } = this.props

    if (OS !== 'web') {
      return options
    } else {
      return (
        // Scrollview covers area larger than display, this view centers all content on the visible area
        <View style={[styles.imageOptionsContainer, { alignItems: 'center' }]}>
          {/*
          This view creates a box the size of the visible area, allowing alignment of all children
          with standard styling methods
          */}
          <View
            style={{
              width: screenInfo.contentWidth,
              height: '100%',
              justifyContent: 'center',
            }}
          >
            {/* padding for easier click target */}
            <View style={{ width: '100%', flexDirection: 'row' }}>
              <TouchableOpacity
                style={{ paddingHorizontal: 20 }}
                onPress={() => {
                  this.goToImage('prev')
                }}
              >
                <Icons name='ios-arrow-back' color='white' size={32} />
              </TouchableOpacity>

              {/* padding for easier click target */}
              <TouchableOpacity
                style={{ paddingHorizontal: 20, marginLeft: 'auto' }}
                onPress={() => {
                  this.goToImage('next')
                }}
              >
                <Icons
                  style={{ transform: [{ rotateY: '180deg' }] }}
                  name='ios-arrow-back'
                  color='white'
                  size={32}
                />
              </TouchableOpacity>
            </View>
            {idx === 0 && !!options && options}
            <View style={{ width: screenInfo.contentWidth }} />
          </View>
        </View>
      )
    }
  }

  getImages = () => {
    const { screenInfo, options, imageStyle, placeholder } = this.props
    const { images } = this.state
    const imageHeight = screenInfo.contentWidth
    const localImages = images.length ? images : this.props.images
    let elements
    if (localImages.length <= 0) {
      placeholder
        ? (elements = placeholder)
        : (elements = (
          <Image
            source={this.placeHolderImage}
            resizeMethod='scale'
            resizeMode='cover'
            style={[
              {
                width: screenInfo.contentWidth,
                height: imageHeight,
              },
              imageStyle,
            ]}
          />
        ))

      return elements
    } else {
      debug && console.log('<<<IMAGE CAROUSEL - getImages', images.length ? 'from State' : 'from Props', isVideo(_.get(localImages, '[0].id')) ? 'isVideo' : 'isThumbnail')
      //debug && console.log('<<<IMAGE CAROUSEL - getImages - localImages', localImages)
      return this.renderImagesWithOptions(localImages)
    }
  }

  getRatio = () => {
    const { images } = this.props
    let newImages = [...images]

    let anyWide = false

    newImages.forEach((value, index, self) => {
      if (typeof value !== 'object') {
        self.splice(index, 1)
      }
    })

    newImages.forEach((value) => {
      if (!anyWide && value.width > value.height) {
        anyWide = true
      }
    })

    if (anyWide) {
      return 0.625
    } else {
      return 1.25
    }
  }

  resolveMime = (mime) => {
    if (!mime.includes('/')) {
      if (!mime.includes('mp4')) {
        return 'image/' + mime
      } else {
        return 'video/' + mime
      }
    } else {
      return mime
    }
  }

  fetchCDNVideo = async file => {
    let cdnAssetName = file.name ? file.name.split('.')[0] : file.id.split('.')[0]
    cdnAssetName = cdnAssetName.includes('thumbnail-') ? cdnAssetName.split('thumbnail-')[1] : cdnAssetName
    const url = `https://${environment.videoCDNId}.cloudfront.net/assets/${cdnAssetName}/HLS/${cdnAssetName}.m3u8`
    console.log('<<<IMAGE CAROUSEL - CDN URL', url)
    const status = await (new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.timeout = 8000
      xhr.open('HEAD', url, true)
      xhr.onreadystatechange = () => {
        if (xhr.readyState === xhr.DONE) {
          resolve(xhr.status)
        }
      }
      xhr.send()
    }))
    return status === 200
  }

  handleOnPress = async (image, _imgs) => {
    const copyImages = [..._imgs]
    let isLoadedVideo = true
    stopAllVideos(this.props.videos)
    const newImageState = await copyImages.map(async (item) => {
      if(image.name === item.name) {
        const isReady = await this.fetchCDNVideo(image)
        item.isReady = isReady
        item.shouldPlay = true
        if (!isReady) {
          isLoadedVideo = isReady
        }
        return item
      }
      return item
    })
    Promise.all(newImageState).then(res => {
      this.setState(prevState => ({
        ...prevState,
        images: res
      }),
      () => {
        if(!isLoadedVideo) {
          Alert.alert(
            'Video is being processed. Please wait...',
            '',
            [
              { text: 'OK', onPress: () => console.log('OK Pressed') },
            ],
            { cancelable: false }
          )
        }
      })
    }).catch((e) => {
      console.log(e)

    })
  }

  renderVideoUri = file => {
    const isDeviceCache = _.has(file, 'uri')
    const cdnAssetName = file.name ? file.name.split('.')[0] : file.id.split('.')[0]
    return isDeviceCache ? file.uri : `https://${environment.videoCDNId}.cloudfront.net/assets/${cdnAssetName}/HLS/${cdnAssetName}.m3u8`
  }
  renderThumbnailImage = (withIcon, image, idx, imageArray, video ) => {
    const _image = video || image
    const {
      screenInfo,
      imageStyle,
      fit = 'crop',
      crop = 'entropy',
      resizeMode = 'cover',
      deleteButton,
    } = this.props

    const width = screenInfo.contentWidth
    const height = width * this.getRatio()
    const derivedResizeConfig = getImageResizeConfig(image, { width, height})
    const defaultResizeConfig = { fit, crop, resizeMode }
    const finalResizeConfig = { ...defaultResizeConfig, ...derivedResizeConfig }
    //debug && console.log('<<<IMAGE CAROUSEL - renderThumbnailImage - finalResizeConfig', finalResizeConfig)
    //debug && console.log('<<<IMAGE CAROUSEL - renderThumbnailImage - width/height', width, height)
    return (
      <View
        key={idx + 'view'}
        style={[styles.thumbnailBackground, OS === 'web ' ? { width: screenInfo.contentWidth } : null]}
      >
        <TouchableOpacity
          onPress={() => this.handleOnPress(_image, imageArray)}
        >
          <View>
            <NamedImage
              style={
                (imageStyle,
                {
                  height,
                  width,
                  position: 'relative',
                })
              }
              width={width}
              height={height}
              key={idx}
              name={image.name}
              { ...finalResizeConfig }
              resizeMethod='scale'
              opacity={withIcon && !this.props.pendingRefresh ? 1 : 0.5}
            />
            {withIcon && !this.props.pendingRefresh && <View style={{
              height: screenInfo.contentWidth * this.getRatio(),
              width: screenInfo.contentWidth,
              flex: 1,
              position: 'absolute',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Icons name='ios-play-circle' color='white' size={64} />
            </View>}
            {this.renderOptions(idx)}
            {deleteButton && deleteButton(_image)}
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  renderImagesWithOptions = (_imgs) => {
    const {
      screenInfo,
      options,
      imageStyle,
      placeholder,
      resizeMode = 'cover',
      videoResizeMode = 'cover',
      fit = 'crop',
      currentScreen,
      showProgress,
      playOnce,
      playMuted,
      pendingRefresh,
      activityIndicatorOverlayEnabled,
    } = this.props

    const imageHeight = screenInfo.contentWidth
    let elements = _.map(_imgs, (image, idx, arr) => {
      // Catch for images uploaded from device / demo purposes
      let Media = (
        <View
          style={OS === 'web ' ? { width: screenInfo.contentWidth } : null}
        >
          <Image
            style={{
              width: screenInfo.contentWidth,
              height: imageHeight,
            }}
            source={image}
            resizeMethod='scale'
            resizeMode='cover'
          />
          {idx === 0 && !!options && options}
        </View>
      )
      let mediaId = null
      if(image) {
        let mime = this.resolveMime(image.mime)
        if (typeof image !== 'object') {
          return
        }
        const itemName = image.name ? 'name' : 'id'
        //console.log('<<<IMAGE CAROUSEL - renderImagesWithOptions - mime', mime)
        //console.log('<<<IMAGE CAROUSEL - renderImagesWithOptions - name', image[itemName])
        if(arr.length === 1 && image[itemName].includes('thumbnail-')) {
          debug && console.log('<<<IMAGE CAROUSEL - renderThumbnailImage')
          const withIcon = false
          Media = this.renderThumbnailImage(withIcon, image, idx, arr)
          mediaId = image.id || image.name
        } else if (MIME_TYPES.image.has(mime)) {
          //console.log('<<<IMAGE CAROUSEL - renderImagesWithOptions - image')
          Media = (
            <TouchableOpacity
              onPress={() => this.openModalImageViewer(idx)}
              delayPressIn={50}
            >
              <View
                style={OS === 'web ' ? { width: screenInfo.contentWidth } : null}
              >
                <NamedImage
                  style={
                    (imageStyle,
                    {
                      height: screenInfo.contentWidth * this.getRatio(),
                      width: screenInfo.contentWidth,
                    })
                  }
                  width={screenInfo.contentWidth}
                  height={screenInfo.contentWidth * this.getRatio()}
                  name={image.name}
                  uri={image.uri}
                  fit={fit}
                  crop='entropy'
                  resizeMethod='scale'
                  opacity={this.props.pendingRefresh ? 0.5 : 1}
                  resizeMode={resizeMode} />
                {this.renderOptions(idx)}
                {this.props.deleteButton && this.props.deleteButton(image)}
              </View>
            </TouchableOpacity>
          )
          mediaId = image.id || image.name
        } else if (MIME_TYPES.video.has(mime)) {
          // console.log('<<<IMAGE CAROUSEL - has video mime')
          if(image.uri || image.isReady) {
            const uri = this.renderVideoUri(image)
            // console.log('<<<IMAGE CAROUSEL - renderImagesWithOptions - video')
            Media = (
              <TouchableWithoutFeedback
                style={OS === 'web ' ? { width: screenInfo.contentWidth } : null}
                onPress={() => stopAllVideosById(this.props.videos, uri)}
              >
                <View style={styles.videoBackground}>
                  <Video
                    source={{uri}}
                    rate={1.0}
                    id={this.props.navigation.state.routeName}
                    volume={1.0}
                    resizeMode={videoResizeMode}
                    shouldPlay={true}
                    onFullscreenUpdate={(status) => {
                      if(Platform.OS === 'android') {
                        const { fullscreenUpdate } = status
                        if (fullscreenUpdate === 1) {
                          ScreenOrientation.unlockAsync()
                        } else if (fullscreenUpdate === 3) {
                          ScreenOrientation.lockAsync(ScreenOrientation.Orientation.PORTRAIT_UP)
                        }
                      }
                    }}
                    useNativeControls={true}
                    isLooping={!playOnce}
                    isMuted={playMuted}
                    ref={(ref) => {
                      this.videos[idx] = ref
                      this.videoRef = ref
                      this.props.setVideoRef(ref)
                    }}
                    style={{
                      width: screenInfo.contentWidth,
                      height: screenInfo.contentWidth * this.getRatio(),
                    }}
                  />
                  {this.props.deleteButton && this.props.deleteButton(image, 15, Platform.OS === 'ios' ? 40 : 15)}
                </View>
                {/* {idx === 0 && !!options && options} */}
              </TouchableWithoutFeedback>
            )
            mediaId = image.id || image.name
          } else if(image.thumb) {
            //console.log('<<<IMAGE CAROUSEL - renderImagesWithOptions - thumbnail fallback')
            const withIcon = true
            Media = this.renderThumbnailImage(withIcon, image.thumb, idx, arr, image)
            mediaId = image.thumb.id || image.thumb.name
          }

        }

        return (
          <View style={{ flex: 1 }} key={idx + 'view'}>
            <ActivityIndicatorOverlay animating={activityIndicatorOverlayEnabled && pendingRefresh}>
              { showProgress && mediaId ? <UploadProgress targetID={mediaId} key={mediaId} /> : null }
              { Media }
            </ActivityIndicatorOverlay>
          </View>
        )
      }

    })

    return elements
  }

  handleScroll = (event) => {
    const { screenInfo, onIndexChange } = this.props
    const x = event.nativeEvent.contentOffset.x
    let positions = [],
      { length } = this.state

    // Create array of absolute position of each image based on 100% of the screen width
    for (let i = 0; i < length; i++) {
      positions.push(i * screenInfo.contentWidth)
    }

    // create an array of the distance to each of the previously created checkpoints based
    // on the current scroll distance.
    let distances = []
    _.forEach(positions, (position) => {
      distances.push(Math.abs(Math.abs(position) - x))
    })
    // Find which point is closest to the current position
    let index = _.findIndex(distances, (item) => {
      return item === _.min(distances)
    })
    // Return which index we're closest to.
    debug && console.log('<<<IMAGE CAROUSEL - Not sure if we should changeIndex', index, this.state.index)
    if (index !== this.state.index) {
      this.changeIndex(index)
    }
  }

  changeIndex = (index) => {
    this.setState(
      (prevState) => {
        return { ...prevState, index, prevIndex: prevState.index }
      },
      () => {
        const { prevIndex, index } = this.state
        _.invoke(this.videos[prevIndex], 'pauseAsync')
        _.invoke(this.videos[index], 'playAsync')
        this.props.onIndexChange(index)
      },
    )
  }

  handleContentChange = () => {
    const { autoScroll } = this.props
    const { length, index, images } = this.state
    debug && console.log('<<<IMAGE CAROUSEL - handleContentChange', length, this.state.images.length, index)
    this.setState({ length: images.length }, () => {
      if (index < images.length - 1 && autoScroll) {
        this.scroller.scrollToEnd()
      } else if (length > images.length && index > images.length - 1) {
        if (images.length !== 1) {
          this.scroller.scrollToEnd()
        } else {
          this.scroller.scrollTo({ x: 0, y: 0, animated: true })
        }
      }
    })
  }

  updateIndexLength() {
    this.handleContentChange()
  }
  componentWillUnmount() {
    this.props.removeVideoRef(this.videoRef)
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.images, this.props.images)) {
      this.updateIndexLength()
      const images = this.setImages()
      this.setState((prev) => {
        return {
          ...prev,
          images
        }
      })
    }
  }

  render() {
    const { screenInfo, style } = this.props
    const { length, index, showImageViewer } = this.state
    //debug && console.log('<<<IMAGE CAROUSEL - length', length)
    return (
      <View style={styles.contentContainer}>
        <ScrollView
          ref={(ref) => {
            this.scroller = ref
          }}
          scrollEventThrottle={30}
          onScroll={this.handleScroll}
          pagingEnabled={OS !== 'web'}
          horizontal
          onContentSizeChange={this.handleContentChange}
          showsHorizontalScrollIndicator={OS === 'web'}
        >
          {this.getImages()}
        </ScrollView>
        <TabIndicator
          index={index}
          length={length}
          containerStyle={[styles.tabIndicatorContainer]}
        />
        {showImageViewer && this.imageModalViewer()}
      </View>
    )
  }
}

const styles = stylus({
  container: {
    backgroundColor: '#222',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  contentContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  tabIndicatorContainer: {
    height: 24,
    marginTop: -24,
  },
  imageOptionsContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    // backgroundColor: 'green',
  },
  thumbnailBackground: {
    backgroundColor: 'black'
  },
  videoBackground: {
    backgroundColor: 'black'
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    ios: {
      top: 10,
    },
    iphonex: {
      top: 30,
    },
    right: 10,
    padding: 2,
    zIndex: 999
  },
})

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
  videos: state.video,
})
const mapDispatchToProps = (dispatch) => ({
  setVideoRef: (ref) => dispatch({type: SET_VIDEO_REF, payload: ref}),
  removeVideoRef: (ref) => dispatch({type: REMOVE_VIDEO_REF, payload: ref})
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true },
)(ImageCarousel)
