import { gql } from '../config/connected'

export default {
  mutations: {},
  queries: {
    stripeAuthLinks: gql`
      query {
        stripeAuthLinks
      }
    `,
    appVersionCheck: gql`
      query ($platform: String!) {
        appVersionCheck(platform: $platform)
      }
    `,
    maxNoticeLength: gql`
      query {
        maxNoticeLength
      }
    `,
    maxNumberOfCategories: gql`
      query {
        maxNumberOfCategories
      }
    `,
  }
}
