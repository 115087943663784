import React from 'react'
import { compose, graphql } from '../../config/connected'
import schema from '../../schema/conversation'
import { updateCachedQueryValue } from '../../utility/apollo'

import _ from 'lodash'
import produce from 'immer'

function withPublishMessage(Composed) {
  return (compose(
    graphql(schema.mutations.createMessage, {
      name: 'createMessage',
    }),
  )(
    class WithPublishMessage extends React.Component {
      publishMessage = async(messageData) => {
        const { conversationMembership, imageFile, user, text } = messageData
        const membershipId = conversationMembership.id
        const conversation = conversationMembership.conversation

        let fileUpload = null
        if (imageFile) {
          fileUpload = {
            name: imageFile.id,
            width: imageFile.width,
            height: imageFile.height,
            mime: imageFile.mime,
          }
        }

        const variables = {
          text,
          membershipId,
        }
        if (fileUpload) {
          variables.upload = fileUpload
        }

        await this.props.createMessage({
          variables,
          update: (store, { data: { createMessage } }) => {
            // update messages
            updateCachedQueryValue(store, {
              key: 'messages',
              query: schema.queries.messages,
              variables: { membershipId },
              nextValue: (messages = []) => {
                return produce(messages, draft => {
                  draft.unshift(createMessage)
                  draft = _.uniqBy(draft, 'id')
                })               
              },
            })
            // update membership
            updateCachedQueryValue(store, {
              key: 'conversationMembership',
              query: schema.queries.conversationMembership,
              variables: { id: membershipId },
              nextValue: (membership) => {
                return produce(membership, draft => {
                  draft.conversation.lastMessage = createMessage
                  draft.lastTouch = new Date().toISOString()
                })               
              },
            })
            // update conversation list
            updateCachedQueryValue(store, {
              key: 'conversationMemberships',
              query: schema.queries.conversationMemberships,
              nextValue: (allMemberships = []) => {
                return produce(allMemberships, draft => {
                  const index = _.findIndex(draft, { id: membershipId })              
                  const existing = draft.splice(index, 1)
                  if (existing.length > 0) draft.unshift(existing[0])
                })               
              },
            })
          },
        })
      }

      render() {
        return <Composed
          {...this.props}
          publishMessage={this.publishMessage}
        />
      }
    }
  ))
}

export default withPublishMessage
