import Product from '../../screens/Product'
import Shop from '../../screens/Shop'
import EditShop from '../../screens/Shop/EditShop'
import EditFieldFullscreen from '../../screens/Shop/EditFieldFullscreen'
import CreateProduct from '../../screens/Product/create/CreateProduct'
import EditProduct from '../../screens/Product/edit/EditProduct'
import Cart from '../../screens/Shopping/Cart'
import Checkout from '../../screens/Shopping/Checkout'
import OrderConfirmation from '../../screens/OrderConfirmation'
import OrderHistory from '../../screens/OrderHistory'
import OrderDetails from '../../screens/OrderDetails'
import PaymentMethods from '../../screens/Payments/PaymentMethods'
import StripeConnect from '../../screens/Payments/StripeConnect'
import OnboardingShop from '../../screens/Signup/Shop/OnboardingShop'

import { ROUTE_SHOP_ORDER_HISTORY, ROUTE_USER_ORDER_HISTORY, ROUTE_SHOP_ORDER_DETAILS, ROUTE_USER_ORDER_DETAILS, ROUTE_PAYMENT_METHODS, ROUTE_CHOOSE_PAYMENT_METHOD } from '../../config/constants'
import AddressesList from '../../screens/AddresesList/AddressesList'
import EditUserAddress from '../../screens/EditUserAddress/EditUserAddress'

export const ecommerceRoutes = {
  Cart: {
    screen: Cart,
    path: '',
    title: 'Cart',
  },
  Checkout: {
    screen: Checkout,
    path: 'checkout/:checkoutId',
    title: 'Checkout',
    
  },
  OrderConfirmation: {
    screen: OrderConfirmation,
    path: 'orders/:checkoutId/confirmation',
  },
  Product: {
    screen: Product,
    path: 'product/:id',
    
  },
  CreateProduct: {
    screen: CreateProduct,
    path: 'product/create/:shopId',
    
  },
  EditProduct: {
    screen: EditProduct,
    path: 'product/edit/:id',
    
  },
  Shop: {
    screen: Shop,
    path: 'shop/:id',
    
  },
  EditShop: {
    screen: EditShop,
    path: 'shop/edit/:id',
    
  },
  EditFieldFullscreen: {
    screen: EditFieldFullscreen,
    path: 'shop/edit/:id/:fieldName',
    
  },
  OnboardingShop: {
    screen: OnboardingShop,
    path: 'setup-shop',
    title: '',
    
  },
  StripeConnect: {
    screen: StripeConnect,
    path: 'payments/stripe-connect',
    
  },
  [ROUTE_USER_ORDER_HISTORY]: {
    screen: OrderHistory,
    path: 'users/:userId/order-history',
    title: '',
    
  },
  [ROUTE_SHOP_ORDER_HISTORY]: {
    screen: OrderHistory,
    path: 'shops/:userId/order-history',
    title: '',
    
  },
  [ROUTE_USER_ORDER_DETAILS]: {
    screen: OrderDetails,
    path: 'users/:userId/orders-details/:id',
    
  },
  [ROUTE_SHOP_ORDER_DETAILS]: {
    screen: OrderDetails,
    path: 'shops/:userId/orders-details/:id',
    
  },
  [ROUTE_PAYMENT_METHODS]: {
    screen: PaymentMethods,
    path: 'payments/methods',
  },
  [ROUTE_CHOOSE_PAYMENT_METHOD]: {
    screen: PaymentMethods,
    path: 'payments/choose-method',
  },
}
