import { gql } from '../config/connected'
import { userAddressFragment } from './fragments'

export default {
  queries: {
    isUserAddressRestricted: gql`
      query($address: QueryInputUserAddress!) {
        isUserAddressRestricted(address: $address)
      }
    `
  },
  mutations: {
    updateUserAddress: gql`
        mutation UpdateUserAddress($id: ID!, $input: InputUserAddress!, $restrictToLocale: Boolean){
            updateUserAddress(id: $id, input: $input, restrictToLocale: $restrictToLocale){
                ...UserAddressFragment
            }
        }
        ${userAddressFragment}
    `,
    addUserAddress: gql`
        mutation AddUserAddress($input: InputUserAddress!, $restrictToLocale: Boolean){
            addUserAddress(input: $input, restrictToLocale: $restrictToLocale){
                ...UserAddressFragment
            }
        }
        ${userAddressFragment}
    `,
    deleteUserAddress: gql`
        mutation DeleteUserAddress($id: ID!){
            deleteUserAddress(id: $id){
                na
            }
        }
    `,
  },
}
