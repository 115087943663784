import emailValidator from 'email-validator'
import equal from 'fast-deep-equal'
import i18n from 'i18n-js'
import produce from 'immer'
import _ from 'lodash'
import React from 'react'
import {
  ActivityIndicator,
  // TextInput,
  AsyncStorage, Platform, View
} from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import Brand from '../../components/Brand'
import LayoutAnimation from '../../components/LayoutAnimation/'
import AuthenticationLayout from '../../components/simple/AuthenticationLayout'
import Button from '../../components/simple/Button'
import ErrorMessage from '../../components/simple/ErrorMessage'
import branch from '../../config/branch'
import colors from '../../config/colors'
import { compose, connect, graphql, _get } from '../../config/connected'
import { ADDRESS_FIELD_ADDRESS1, ADDRESS_FIELD_ADDRESS2, ADDRESS_FIELD_CITY, ADDRESS_FIELD_COUNTRY, ADDRESS_FIELD_REGION, ADDRESS_FIELD_ZIP } from '../../config/constants'
import countries from '../../config/countries'
import environment from '../../config/environment'
import { getFormattedUsername, getFormattedInviteCode, getFeatureFlag, getHomeRoute } from '../../config/helpers'
import { stylus } from '../../config/styles'
import validate, { bindableFieldErrorFunc, bindableFieldErrorsFunc, isAlphaNumeric, isValidAddress, isValidName, isZip } from '../../config/validate'
import { resetWebsocket } from '../../containers/withApollo'
import { query as currentUserQuery } from '../../containers/withCurrentUser'
import alert from '../../utility/alert'
import { updateCachedQueryValue } from '../../utility/apollo'
import guessCountry from '../../utility/guessCountry'
import AcceptedInvitation from './AcceptedInvitation'
import AddAddress from './AddAddress'
import AddInterests from './AddInterests'
import AddPhone from './AddPhone'
import AddProducer from './AddProducer'
import AddProfileImage from './AddProfileImage'
import ExtraLink from './ExtraLink'
import Finish from './Finish'
import Footer from './Footer'
import InviteCode from './InviteCode'
import schema from './schema'
import Terms from './Terms'
import VerifyPhone from './VerifyPhone'
import Welcome from './Welcome'
import YourInfo from './YourInfo'
import { ANALYTICS_LOGIN, ANALYTICS_SIGNUP } from '../../reducers/analytics'
import Constants from 'expo-constants'

const fieldErrorsConfig = {
  [ADDRESS_FIELD_ADDRESS1]: {
    validate: isValidAddress,
    errorName: ADDRESS_FIELD_ADDRESS1,
  },
  [ADDRESS_FIELD_ADDRESS2]: {
    validate: isValidAddress,
    errorName: ADDRESS_FIELD_ADDRESS2,
    optional: true,
  },
  [ADDRESS_FIELD_CITY]: {
    validate: isAlphaNumeric,
    errorName: ADDRESS_FIELD_CITY,
  },
  [ADDRESS_FIELD_REGION]: {
    validate: isAlphaNumeric,
    errorName: ADDRESS_FIELD_REGION,
  },
  [ADDRESS_FIELD_COUNTRY]: {
    validate: isValidName,
    errorName: ADDRESS_FIELD_COUNTRY,
  },
  [ADDRESS_FIELD_ZIP]: {
    validate: isZip,
    errorName: ADDRESS_FIELD_ZIP,
  },
}

class Start extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 'start',
      // page: 'addAddress', // change this if testing a certain page
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      phonePrefix: '+39',
      confirmationCode: '',
      inviteCode: '',
      inviter: null,
      username: '',
      acceptTerms: false,
      profileImage: null,
      address: {
        address1: '',
        address2: '',
        city: '',
        region: '',
        zip: '',
        country: guessCountry(),
      },
      locale: 'IT',
      editingUsername: false,
      isProducer: false,
      isFoodPro: false,
      producerInterests: [],
      productInterests: [],
      seed: null,
      errors: {
        address: {},
      },
      loading: false,
    }
    this.inputs = []
    this.addressFieldError = bindableFieldErrorFunc(
      fieldErrorsConfig,
      'state.address',
      this.removeAddressError,
      this.setAddressError,
      'errors.order.cart',
      'address.fields',
    ).bind(this)
    this.addressFieldErrors = bindableFieldErrorsFunc(
      this.addressFieldError,
      'state.address',
      'address.fields',
    ).bind(this)

    this.isSubmitting = false
  }

  componentDidMount() {
    AsyncStorage.removeItem('USER_TOKEN')
    AsyncStorage.removeItem('USER_ID')
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !equal(this.state, nextState)
  }

  setError = (field, errorMessage = undefined, options, onSetState) => {
    if (field && errorMessage) {
      this.setState((prevState) => {
        const nextState = produce(prevState, (draft) => {
          _.set(draft, `errors.${field}`, i18n.t(errorMessage, options))
        })
        return nextState
      }, onSetState)
    }
  }

  removeError = (field, onSetState) => {
    if (field && _.get(this.state.errors, field)) {
      this.setState((prevState) => {
        const nextState = produce(prevState, (draft) => {
          _.unset(draft, `errors.${field}`)
        })
        return nextState
      }, onSetState)
    }
  }

  setAddressError = (field, errorMessage, options, onSetState) => {
    this.setError(`address.${field}`, errorMessage, options, onSetState)
  }

  removeAddressError = (field, onSetState) => {
    this.removeError(`address.${field}`, onSetState)
  }

  getMessage = (id, ...rest) => {
    if (rest) {
      return i18n.t(id, ...rest)
    }
    return i18n.t(id)
  }

  render() {
    const { layout, platform, screenInfo } = this.props
    return (
      <>
        <AuthenticationLayout
          style={[styles.container]}
          innerRef={(ref) => (this.scroller = ref)}
        >
          {this.renderBrand()}
          {this.form()}
          {this.error()}
          {this.submitButton()}
          {this.loading()}
          {this.renderExtraButton()}
          {this.renderExtraSection()}
        </AuthenticationLayout>
        {this.renderFooter()}
      </>
    )
  }

  loading() {
    if (this.state.loading) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size='large' />
        </View>
      )
    }
    return null
  }

  logo() {}

  changeUsername() {
    const { editingUsername } = this.state
    this.animate()
    this.setState({ editingUsername: !editingUsername })
  }

  form() {
    const { page } = this.state
    if (environment.environment === 'development') {
      // console.log({ page })
    }
    return {
      start: () => this.renderStart(),
      // login
      chooseLoginMethod: () => this.renderChooseLogin(),
      requestLoginCode: () => this.renderRequestLoginCode(),
      login: () => this.renderLogin(),
      // signup
      chooseMethod: () => this.renderFacebook(),
      addPhone: () => this.renderAddPhone(),
      verifyPhone: () => this.renderVerifyPhone(),
      inviteCode: () => this.renderInviteCode(),
      acceptedInvitation: () => this.renderAcceptedInvitation(),
      yourInfo: () => this.renderYourInfo(),
      welcome: () => this.renderWelcome(),
      terms: () => this.renderTerms(),
      addProfile: () => this.renderAddProfile(),
      addAddress: () => this.renderAddAddress(),
      addInterests: () => this.renderAddInterests(),
      addProducer: () => this.renderAddProducer(),
      finish: () => this.renderFinish(),
    }[page]()
  }

  submitDisabled() {
    const { page } = this.state
    const fn = {
      terms: () => {
        return !this.state.acceptTerms
      },
      welcome: () => {
        return (
          this.state.username.length < 4 ||
          !this.state.acceptTerms ||
          this.state.errors.username
        )
      },
      inviteCode: () => {
        return !this.state.inviteCode.length || !!this.state.errors.inviteCode
      },
      addAddress: () => {
        return !!this.state.errors.addressInvalid
      },
      verifyPhone: () => {
        return _get(this.state, 'confirmationCode', '').length !== 6
      },
      login: () => {
        return _get(this.state, 'confirmationCode', '').length !== 6
      },
      start: () => true,
      signup: () => true,
      acceptedInvitation: () => true
    }[page]
    return fn && fn()
  }
  submitButton() {
    const { page } = this.state
    if (['addProfile'].includes(page)) {
      return null
    }
    const backgroundColor = ['chooseMethod', 'chooseLoginMethod'].includes(page)
      ? colors.disabled
      : colors.primary

    return (
      <Button
        onPress={this.onSubmit.bind(this)}
        label={this.submitLabel()}
        disabled={this.submitDisabled()}
        style={[styles.submitButton, { backgroundColor }]}
        labelStyle={styles.submitButtonText}
      />
    )
  }

  submitLabel() {
    const { page } = this.state
    return this.getMessage(
      {
        start: 'common.createAccount',
        chooseLoginMethod: 'common.loginWithFacebook',
        requestLoginCode: 'common.next',
        login: 'common.login',
        chooseMethod: 'common.connectFacebook',
        addPhone: 'common.next',
        inviteCode: 'common.accept',
        acceptedInvitation: 'common.signup',
        verifyPhone: 'common.next',
        signup: 'onboardingUser.signup',
        confirmation: 'common.next',
        yourInfo: 'common.next',
        welcome: 'common.next',
        terms: 'onboardingUser.terms.submitLabel',
        addProfile: 'onboardingUser.profile.submitLabel',
        addAddress: 'common.next',
        addInterests: 'common.next',
        addProducer: 'common.next',
        finish: 'onboardingUser.finish.submit',
      }[page],
    )
  }

  async onSubmit() {
    const { goToHome } = this.props
    const { page, profileImageFile } = this.state

    this.setState({ loading: true })

    switch (page) {
      case 'start':
        branch({
          features: {
            inviteCode: () => this.goToPage('inviteCode'),
            signupOptions: () => this.goToPage('chooseMethod')
          },
          other: () => this.goToPage('addPhone'),
        })
        break
      case 'chooseLoginMethod':
        // this.loginFacebook()
        break
      case 'requestLoginCode':
        this.requestLoginCode()
        break
      case 'login':
        if (await this.login()) {
          this.props.navigation.navigate('Network')
          // this.props.goToHome()
        }

        break
      case 'chooseMethod':
        // this.loginFacebook()
        break
      case 'addPhone':
        if (await this.validatePhone()) {
          this.goToPage('verifyPhone')
        }
        break
      case 'inviteCode':
        if (await this.verifyInviteCode()) {
          this.goToPage('acceptedInvitation')
        }
        break
      case 'acceptedInvitation':
        this.goToPage('addPhone')
        break
      case 'verifyPhone':
        if (await this.verifyPhone()) {
          this.goToPage('yourInfo')
        }
        break
      case 'yourInfo':
        this.verifyNameAndEmail()
        break
      case 'welcome':
        this.verifyUsername()
        break
      case 'terms':
        this.acceptTerms()
        break
      case 'addProfile':
        this.goToPage('addAddress')
        break
      case 'addAddress':
        this.validateAddress()
        break
      case 'addProducer':
        this.goToPage('addInterests')

        break
      case 'addInterests':
        // this.validateInterests()
        if (await this.submitUser()) {
          this.goToPage('finish')
        }
        break
      case 'finish':
        await this.login(true) // Set firsttime login to true.
        await this.props.finishOnboarding()
        this.props.goToHome()
        break
    }

    this.setState({ loading: false })
  }

  renderExtraButton() {
    const { page } = this.state
    const pageExtraButtons = {
      start: 'common.login',
      chooseLoginMethod: 'onboardingUser.smsLogin',
      inviteCode: 'common.back',
      acceptedInvitation: 'common.back',
      requestLoginCode: 'common.back',
      login: 'common.back',
      chooseMethod: 'onboardingUser.smsSignup',
      addPhone: 'common.back',
      signup: 'common.back',
      welcome: 'common.changeUsername',
      addProfile: 'common.skip',
      addAddress: 'common.skip',
      addProducer: 'common.skip',
      addInterests: 'common.skip',
    }
    const isActive = page in pageExtraButtons

    if (isActive) {
      return this.extraButton()
    } else {
      return null
    }
  }

  extraButton() {
    const label = this.getExtraButtonLabel()
    if (!label) return null
    return <ExtraLink onPress={this.onExtraButton.bind(this)} t={label} />
  }

  getExtraButtonLabel() {
    const { page } = this.state
    const pageExtraButtonLabels = {
      start: 'common.login',
      chooseLoginMethod: 'onboardingUser.smsLogin',
      requestLoginCode: 'common.back',
      addPhone: 'common.back',
      inviteCode: 'common.back',
      acceptedInvitation: 'common.back',
      verifyPhone: 'common.back',
      chooseMethod: 'onboardingUser.smsSignup',
      login: 'common.back',
      signup: 'common.back',
      welcome: 'common.changeUsername',
      // yourInfo: null,
      addProfile: 'common.skip',
      addAddress: 'common.skip',
      addInterests: 'common.skip',
      addProducer: 'common.skip',
    }
    const isActive = page in pageExtraButtonLabels

    if (isActive) {
      return pageExtraButtonLabels[page]
    } else {
      return false
    }
  }

  async onExtraButton() {
    const { page } = this.state

    switch (page) {
      case 'start':
        this.goToPage('requestLoginCode')
        break
      case 'chooseLoginMethod':
        this.goToPage('requestLoginCode')
        break
      case 'requestLoginCode':
        this.goBack()
        break
      case 'inviteCode':
        this.goToPage('start')
        break
      case 'acceptedInvitation':
        this.goToPage('inviteCode')
        break
      case 'login':
        this.goToPage('requestLoginCode')
        break
      case 'chooseMethod':
        this.goToPage('addPhone')
        break
      case 'addPhone':
        branch({
          features: {
            inviteCode: () => this.goToPage('acceptedInvitation'),
            signupOptions: () => this.goToPage('chooseMethod'),
          },
          other:() => this.goToPage('start'),
        })
        break
      case 'verifyPhone':
        this.goToPage('addPhone')
        break
      case 'signup':
        this.goBack()
        break
      case 'welcome':
        this.changeUsername()
        break
      case 'addProfile':
        this.goToPage('addAddress')
        break
      case 'addAddress':
        this.setState({
          address: {
            address1: '',
            address2: '',
            city: '',
            region: '',
            zip: '',
            country: '',
          },
        })
        this.goToPage('addProducer')
        break
      case 'addProducer':
        this.goToPage('addInterests')
        break
      case 'addInterests':
        this.setState({
          producerInterests: [],
          productInterests: []
        }, async () => {
          if (await this.submitUser()) {
            this.goToPage('finish')
          }
        })
        break
      default:
        break
    }
  }

  renderExtraSection() {
    const { page } = this.state

    switch (page) {
      case 'welcome':
        return this.renderTerms()
      default:
        return null
    }
  }

  loginFacebook() {
    alert('PLACEHOLDER', 'Coming Soon', [
      {
        text: 'onError',
        onPress: () => {
          this.animate()
          this.setState({ error: 'Failed to login facebooook.' })
        },
      },
      {
        text: 'onSuccess',
        onPress: () => {
          this.setState({ error: null })
          this.goToPage('addPhone')
        },
      },
    ])
  }

  goBack() {
    this.setState({
      page: 'start',
      error: null,
    })
  }

  goToPage(page) {
    this.setState({
      page,
      error: null,
    })
  }

  renderFooter() {
    const { page } = this.state
    const { screenInfo } = this.props

    const loginActive = [
      'chooseMethod',
      'signup',
      'addPhone',
      'verifyPhone',
    ].includes(page)
    const signupActive = false // ['chooseLoginMethod', 'requestLoginCode', 'loginSms', 'login'].includes(page)

    if (loginActive || signupActive) {
      const signupStep = getFeatureFlag('signupOptions') ? 'start' : 'addPhone'
      let onPress = signupActive ? () => this.goToPage(signupStep) : () => this.goToPage('requestLoginCode')
      const messageTransKey = signupActive ? 'onboardingUser.needAccount' : 'onboardingUser.alreadyMember'
      const linkTransKey = signupActive ? 'common.signup' : 'common.login'
      const footerProps = { onPress, messageTransKey, linkTransKey }
      return <Footer {...footerProps} />
    }
    return null
  }

  renderBrand() {
    const { page } = this.state
    const { screenInfo } = this.props

    // add the name of the page you would like the brand to display on here.
    const visiblePages = ['start', 'chooseMethod', 'chooseLoginMethod']
    if (visiblePages.includes(page)) {
      return (
        <View style={styles.logoContainer}>
          <Brand width={screenInfo.width / 2} vertical />
        </View>
      )
    } else return null
  }

  renderWelcome() {
    const { username, editingUsername } = this.state
    return (
      <Welcome
        username={username}
        editingUsername={editingUsername}
        onChange={this.onChange}
        setError={this.setError}
        removeError={this.removeError}
        error={this.state.errors.username}
      />
    )
  }

  renderTerms() {
    return (
      <Terms
        style={{ alignSelf: 'flex-end', marginTop: 0 }}
        onChange={this.onChange}
        state={this.state}
        getMessage={this.getMessage}
      />
    )
  }

  renderAddProfile() {
    const { profileImagePreview } = this.state
    return (
      <AddProfileImage
        profileImagePreview={profileImagePreview}
        onChange={this.onChangeProfileImage}
        getMessage={this.getMessage}
        screenInfo={this.props.screenInfo}
        onSubmit={() => this.goToPage('addAddress')}
      />
    )
  }

  onChangeProfileImage = (file) => {
    this.setState({
      profileImageFile: file,
      profileImagePreview: { uri: file.uri },
      profileImage: file.id,
    })
    this.props.queueUpload(file)
  }

  renderAddAddress() {
    return (
      <AddAddress
        placeholdersPath={'onboardingUser.address'}
        titlePath={'onboardingUser.address.title'}
        subtextPath={'onboardingUser.address.text'}
        state={this.state.address}
        errors={this.state.errors.address}
        onChange={this.onAddressFieldChange}
        getMessage={this.getMessage}
        screenInfo={this.props.screenInfo}
      />
    )
  }

  renderAddInterests() {
    const { producerInterests, productInterests } = this.state
    return (
      <AddInterests
        producerInterests={producerInterests}
        productInterests={productInterests}
        onChange={this.onChange}
      />
    )
  }

  renderAddProducer() {
    return (
      <AddProducer
        state={this.state}
        onChange={this.onChange}
        getMessage={this.getMessage}
      />
    )
  }

  handleChange = (value, target) => {
    this.setState({ [target]: value })
  }

  renderYourInfo() {
    const { screenInfo } = this.props
    return (
      <YourInfo
        screenInfo={screenInfo}
        getMessage={this.getMessage}
        onChange={this.onChange}
        state={this.state}
      />
    )
  }

  renderInviteCode() {
    return (
      <InviteCode
        getMessage={this.getMessage}
        onChange={this.onChange}
        setError={this.setError}
        removeError={this.removeError}
        inviteCode={this.state.inviteCode}
        error={this.state.errors.inviteCode}
      />
    )
  }

  renderAcceptedInvitation() {
    return (
      <AcceptedInvitation state={this.state} getMessage={this.getMessage} />
    )
  }

  renderVerifyPhone() {
    const { screenInfo } = this.props
    const { phonePrefix, phone } = this.state
    const formattedPhone = [phonePrefix, phone].join(' ')
    return (
      <VerifyPhone
        screenInfo={screenInfo}
        getMessage={this.getMessage}
        onChange={this.onChange}
        state={this.state}
        formattedPhone={formattedPhone}
        goBack={() => this.goToPage('addPhone')}
      />
    )
  }

  renderAddPhone() {
    const { screenInfo } = this.props
    return (
      <AddPhone
        screenInfo={screenInfo}
        getMessage={this.getMessage}
        onChange={this.onChange}
        state={this.state}
      />
    )
  }

  renderRequestLoginCode() {
    const { screenInfo } = this.props
    return (
      <AddPhone
        screenInfo={screenInfo}
        getMessage={this.getMessage}
        onChange={this.onChange}
        state={this.state}
      />
    )
  }

  handleSubmitPhone = () => {
    const { phone, locale } = this.state
    let error = this.validate(
      {
        phone: {
          type: 'phone',
          countryCode: countries[locale].code,
        },
      },
      {
        phone,
      },
    )

    if (error) {
      this.animate()
      this.setState({ error })
    } else {
      this.goToPage('confirmation')
    }
  }

  renderFinish() {
    const { screenInfo } = this.props
    return <Finish screenInfo={screenInfo} />
  }

  renderStart() {
    return <View style={styles.startContainer} />
  }
  renderFacebook() {
    return <View />
  }
  renderChooseLogin() {
    return <View />
  }
  next(inputName) {
    this.inputs[inputName].focus()
  }

  renderLogin() {
    const { screenInfo } = this.props
    const { phonePrefix, phone } = this.state
    const formattedPhone = [phonePrefix, phone].join(' ')
    return (
      <VerifyPhone
        screenInfo={screenInfo}
        getMessage={this.getMessage}
        onChange={this.onChange}
        state={this.state}
        formattedPhone={formattedPhone}
        goBack={() => this.goToPage('requestLoginCode')}
      />
    )
  }

  error() {
    const { error, page } = this.state
    if (page === 'addAddress') return null // The address page has error handling built into the fields.
    if (!error) return null
    return <ErrorMessage t={error} style={styles.error} />
  }

  validate(schema, record) {
    const errors = validate(schema, record)
    if (errors.length) {
      return errors[0].message
    }
    return null
  }

  validateSignUpPhone = async (phone) => {
    const { validateSignUpPhone } = this.props
    const validationResponse = await validateSignUpPhone({variables: {phone}})
    return validationResponse.data.validateSignUpPhone
  }

  validatePhone = async () => {
    const { userByPhone, createVerification } = this.props
    const { phonePrefix, phone } = this.state
    const fullNumber = [phonePrefix, phone].join('')

    const validForSignUp = await this.validateSignUpPhone(fullNumber)
    if (!validForSignUp) {
      this.setState({
        error: 'errors.phone.invalidForSignUp',
      })
      return false
    }

    const data = await userByPhone.refetch({ phone: fullNumber })
    const phoneNumberInUse = !!data.data.userByPhone
    const inDevelopment = process.env.NODE_ENV !== 'production'

    if (fullNumber.length <= 3) {
      this.setState({
        error: 'errors.phone.invalid',
      })
      return false
    } else if (phoneNumberInUse) {
      this.setState({
        error: 'errors.phone.taken',
      })
      return false
    } else {
      try {
        const verification = await createVerification({
          variables: { phone: fullNumber, locale: i18n.locale, forExistingUser: false },
        })
        // Automatically add the data into state if we're not in production
        // TODO: Use this for now. foodbarrio-staging is somehow in production mode
        // if (inDevelopment) {
        // alert('verification::', Object.keys(verification))
        this.setState({
          confirmationCode: verification.data.createVerification.code,
          seed: verification.data.createVerification.seed,
        })
        // }
        return true
      } catch (err) {
        const message = this.getErrorTranslationKey(err)
        return this.setState({
          error: message,
        })
      }
    }
  }

  requestLoginCode = async () => {
    const { phonePrefix, phone } = this.state
    const fullNumber = [phonePrefix, phone].join('')
    if (fullNumber.length <= 3) {
      return this.setState({
        error: 'errors.login.invalidPhone',
      })
    }
    try {
      const result = await this.props.createVerification({
        variables: {
          phone: fullNumber,
          forExistingUser: true,
        },
      })
      const { createVerification } = result.data
      const { seed, code } = createVerification
      this.setState({
        seed,
        confirmationCode: code || '',
        page: 'login', // TODO: Was previously set to 'verifyPhone' which is used for Sign Up. This does not work for logging in. Reverting back original which is 'login'
      })
    } catch (e) {
      const message = this.getErrorTranslationKey(e)
      return this.setState({
        error: message,
      })
    }
  }

  verifyInviteCode = async () => {
    const { inviteCode } = this.state
    let formattedInviteCode = getFormattedInviteCode(inviteCode)
    const result = await this.props.userByAlias.refetch({
      alias: formattedInviteCode,
    })
    const inviter = result.data.userByAlias
    if (inviter) {
      this.setState({ inviter })
    } else {
      alert({
        title: this.getMessage('onboardingUser.inviteCode.invalidCodeTitle'),
        message: this.getMessage(
          'onboardingUser.inviteCode.invalidCodeSubtext',
        ),
      })
    }
    return inviter
  }

  verifyPhone = async () => {
    const { validateVerification } = this.props
    const { seed, confirmationCode, phone, phonePrefix } = this.state

    if (!confirmationCode) {
      return this.setState({
        error: 'errors.confirmationCode.required',
      })
    }
    // alert({ title: '::Phone::', message: phonePrefix + phone })

    let validation

    try {
      validation = await validateVerification({
        variables: {
          phone: phonePrefix + phone,
          code: confirmationCode,
        },
      })
    } catch (e) {
      this.setState({ error: this.getErrorTranslationKey(e) })
      return false
    }

    // If no errors arise,and the data we get back matches what we sent, go ahead and say that the validation was successful.
    return true
  }

  login = async (firstTime = false) => {
    const { seed, confirmationCode, phone, phonePrefix } = this.state
    if (!confirmationCode) {
      this.setState({
        error: 'errors.confirmationCode.required',
      })
      return false
    }
    try {
      const result = await this.props.login({
        variables: {
          seed,
          code: confirmationCode,
          phone: [phonePrefix, phone].join(''),
        },
        update: (store, { data }) => {
          updateCachedQueryValue(store, {
            query: currentUserQuery,
            variables: {},
            nextValue: (currentData) => data.login,
          })
        },
      })
      const { login } = result.data
      const { id, token } = login
      resetWebsocket(token)
      await AsyncStorage.multiSet([['USER_TOKEN', token], ['USER_ID', id]])
      this.props.loggedIn({ id, firstTime })
      return true
    } catch (e) {
      this.setState({
        error: this.getErrorTranslationKey(e),
      })
      return false
    }
  }

  verifyNameAndEmail = async () => {
    const { userByEmail } = this.props
    const { firstName, lastName, email } = this.state

    if (firstName.match(/Foodbarrio/i) !== null) {
      return this.setState({
        error: 'errors.firstName.wrong',
      })
    }

    if (lastName.match(/Foodbarrio/i) !== null) {
      return this.setState({
        error: 'errors.lastName.wrong',
      })
    }

    if (!firstName) {
      return this.setState({
        error: 'errors.firstName.required',
      })
    }
    if (!lastName) {
      return this.setState({
        error: 'errors.lastName.required',
      })
    }
    if (!emailValidator.validate(email)) {
      return this.setState({
        error: 'errors.email.invalid',
      })
    }
    const result = await userByEmail.refetch({ email })
    const emailIsInUse = result.data.userByEmail

    if (emailIsInUse) {
      return this.setState({ error: 'errors.email.taken' })
    }
    const suggestedUsernameResult = await this.props.suggestUsername({
      variables: { firstName, lastName },
    })
    const username = suggestedUsernameResult.data.suggestUsername
    this.setState({
      username,
      error: null,
      page: 'welcome',
    })
  }

  validateAddress = async () => {
    const message = this.addressFieldErrors(undefined, true)
    if (message) {
      return false
    } else {
      this.setState({ page: 'addInterests' })
      return true
    }
  }

  verifyUsername = async () => {
    const { userByAlias } = this.props
    const { username } = this.state
    const usernameSchema = {
      username: {
        type: 'username',
        allowEmpty: false,
      },
    }

    if ((username || '').match(/Foodbarrio/i)) {
      this.setState({ error: 'errors.username.taken' })
      return false
    }

    if (this.didChangeUsername) {
      const error = this.validate(usernameSchema, { username })
      if (error) {
        this.setState({ error })
        return false
      }
    }

    const result = await userByAlias.refetch({ alias: username })
    const usernameIsInUse = result.data.userByAlias

    if (usernameIsInUse) {
      this.setState({ error: 'errors.username.taken' })
      return false
    }

    this.setState({ error: null, page: 'addProfile' })
    return true
  }

  finalizeAndRequestCode = () => {
    this.requestLoginCode()
    this.setState({ page: 'verifyPhone' })
  }

  submitUser = async () => {
    if (!this.isSubmitting) {
      this.isSubmitting = true

      const { createNewUser, analyticsSignup } = this.props
      const {
        email,
        phone,
        phonePrefix,
        username,
        password,
        locale,
        profileImage,
        lastName,
        firstName,
        coverPhoto,
        displayName,
        isProducer,
        isFoodPro,
        seed,
        confirmationCode,
        address: { address1, address2, city, region, zip, country },
        productInterests,
        producerInterests,
        inviter,
      } = this.state

      const newUserData = {
        email,
        phone: phonePrefix + phone,
        username,
        password,
        locale,
        profileImage,
        displayName: firstName + ' ' + lastName,
        isProducer,
        isFoodPro,
        verificationData: {
          code: confirmationCode,
          seed,
          phone: phonePrefix + phone,
        },
        address1,
        address2,
        city,
        region,
        zip,
        country,
        firstName,
        lastName,
        interests: _.map(productInterests, (item) => item.value),
        inviterId: _.get(inviter, 'id'),
      }

      try {
        const resp = await createNewUser({ variables: newUserData })
        analyticsSignup(resp.data.createNewUser.id, inviter)
      } catch (e) {
        this.setState({ error: this.getErrorTranslationKey(e) })
        this.isSubmitting = false
        return false
      }
      this.isSubmitting = false
      return true
    }
    return false
  }

  // validateInterests = async () => {
  //   const { producerInterests, productInterests } = this.state

  //   // Copy the interests objects
  //   let producerMut = {},
  //     productMut = {}
  //   Object.assign(producerMut, producerInterests)
  //   Object.assign(productMut, productInterests)

  //   // Reduce each interests object into an array of just the "value" key.
  //   producerMut = _.reduce(
  //     producerMut,
  //     (acc, val) => {
  //       return [...acc, val.value]
  //     },
  //     [],
  //   )
  //   productMut = _.reduce(
  //     productMut,
  //     (acc, val) => {
  //       return [...acc, val.value]
  //     },
  //     [],
  //   )
  //   console.log('productMut', productMut)
  //   this.setState({
  //     producerInterests: producerMut,
  //     productInterests: productMut,
  //   })
  // }

  acceptTerms = () => {
    const { acceptTerms } = this.state
    if (acceptTerms) {
      this.setState({
        page: 'addProfile',
      })
    } else {
      this.setState({
        error: 'errors.acceptTerms.required',
      })
    }
  }

  handleError(e) {
    AsyncStorage.removeItem('USER_TOKEN')
    // this.animate()
    const message = e.message.replace('GraphQL error: ', '')
    this.setState({
      error: message,
      loading: false,
    })
  }

  getErrorTranslationKey(exception) {
    return _.get(exception, 'graphQLErrors.0.message') || 'errors.common.network'
  }

  onChange = (value, name, onSetState) => {
    this.animate()
    let newValue = value

    if (name === 'code') {
      newValue = value.replace(/[^0-9]/g, '')
    }
    if (name === 'phone') {
      newValue = value.replace(/[^0-9]/g, '')
    }
    if (name === 'phonePrefix') {
      newValue = '+' + value.replace(/[^0-9]/g, '')
    }
    if (name === 'username' && Platform.OS !== 'android') {
      newValue = getFormattedUsername(newValue)
      this.didChangeUsername = true
    }

    if (name === 'inviteCode' && Platform.OS !== 'android') {
      newValue = value
    }

    this.setState((prevState) => {
      const nextState = produce(prevState, (draft) => {
        _.set(draft, name, newValue)
        _.set(draft, 'error', null)
      })
      return nextState
    }, onSetState)
  }

  onAddressFieldChange = (value, name) => {
    const error = this.addressFieldError(value, name, false)
    const isAndroid = Platform.OS === 'android'

    this.setState((prevState) => {
      const nextState = produce(prevState, (draft) => {
        let addressHasError = false
        for (const field in draft.errors.address) {
          if (draft.errors.address[field] && name.indexOf(field) === -1) {
            addressHasError = true
            break
          }
        }
        if (error) {
          _.set(draft, `errors.address.${name}`, error)
        } else {
          _.unset(draft, `errors.address.${name}`)
        }
        const hasInvalidAddress = error || addressHasError
        if (hasInvalidAddress) {
          const invalidAddressError = i18n.t('common.error')
          _.set(draft, 'errors.addressInvalid', invalidAddressError)
        } else {
          _.unset(draft, 'errors.addressInvalid')
        }
        if ((!isAndroid && !error) || isAndroid) {
          _.set(draft, `address.${name}`, value)
        }
      })
      return nextState
    })
  }
  animate() {
    LayoutAnimation &&
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({
  loggedIn: ({ token, id, firstTime }) => {
    dispatch({
      type: ANALYTICS_LOGIN,
      data: {
        currentUserId: id,
      },
    })
    return dispatch({
      type: 'LOGIN',
      token,
      id,
      firstTime,
    })
  },
  afterLogin: ({ firstTime }) =>
    dispatch({
      type: 'AFTER_LOGIN',
      firstTime,
    }),
  goToHome: () => {
    const routeName = getHomeRoute()
    NavigationActions.goTo({ routeName })
  },
  queueUpload: (file) => {
    dispatch({
      type: 'Upload/ADD',
      file,
    })
  },
  analyticsSignup: (userId, inviter) => {
    dispatch({
      type: ANALYTICS_SIGNUP,
      data: {
        appId: Constants.installationId,
        userId,
        inviter: {
          id: _.get(inviter, 'id'),
        },
      },
    })
  },
})


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(schema.userByAlias, {
    name: 'userByAlias',
    options: {
      variables: { alias: '' },
    },
  }),
  graphql(schema.userByEmail, {
    name: 'userByEmail',
    options: {
      variables: { email: '' },
    },
  }),
  graphql(schema.userByPhone, {
    name: 'userByPhone',
    options: {
      variables: { phone: '' },
    },
  }),
  graphql(schema.suggestUsername, {
    name: 'suggestUsername',
  }),
  graphql(schema.requestLoginCode, {
    name: 'requestLoginCode',
  }),
  graphql(schema.login, {
    name: 'login',
  }),
  graphql(schema.validateSignUpPhone, {
    name: 'validateSignUpPhone',
  }),
  graphql(schema.validateVerification, {
    name: 'validateVerification',
  }),
  graphql(schema.createVerification, {
    name: 'createVerification',
  }),
  graphql(schema.finishOnboarding, {
    name: 'finishOnboarding',
  }),
  graphql(schema.createNewUser, {
    name: 'createNewUser',
  }),
)(Start)

const styles = stylus({
  container: {
    paddingTop: 40,
    flexGrow: 1,
  },
  startContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  join: {
    fontSize: 30,
    fontWeight: '200',
    color: '#666',
  },
  submit: {
    marginTop: 30,
  },
  error: {
    marginTop: 10,
    textAlign: 'center',
  },
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: 'rgba(255,255,255,0.9)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  phoneRow: {
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexBasis: 'auto',
  },
  submitButton: {
    marginTop: 30,
    width: '100%',
  },
  submitButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
})
