
import { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'

export default stylus({
  container: {
    flex: 1,
  },
  innerContainer: {
    paddingTop: 24,
    portrait: {
      paddingTop: 20,
    },
  },
  tabBody: {
    padding: 6,
  },
  textTitle: {
    textAlign: 'center',
    paddingVertical: 10,
    fontWeight: 'bold',
    fontSize: 16,
  },
  shopListItemContainer: {
    // flexDirection: 'row',
    marginVertical: 5,
    paddingHorizontal: 6,
    width: '100%',
    borderColor: 'white',
    borderWidth: sizes.px,
    borderBottomColor: colors.emptyArea,
  },
  detailsContainer: {
    flexDirection: 'column',
    paddingLeft: 10,
    flexShrink: 1,
  },
  button: {
    height: 35,
    padding: 2,
  },
  buttonShop: {
    marginVertical: 3,
    backgroundColor: colors.primary,
  },
  picker: {
    paddingHorizontal: 10
  }
})
