import React, { Component } from 'react'
import { AsyncStorage } from 'react-native'
import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { WebSocketLink } from 'apollo-link-ws'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink, split } from 'apollo-link'
import { ApolloProvider } from 'react-apollo'
import { getMainDefinition } from 'apollo-utilities'
import { setContext } from 'apollo-link-context'

import _ from 'lodash'
import environment from '../config/environment'
import { logout } from '../utility/apollo'

const debug = false

// NOTE: this "token" variable acts as a cache for the JWT that is set on login and cleared on logout`
let token = null
export function getAuthToken() {
  return token
}

const wsEndpoint = environment.graphqlEndpoint.replace(/^http/, 'ws')
const wsLink = new WebSocketLink({ 
  uri: wsEndpoint, 
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({ token }),
  }
})

export function resetWebsocket(newToken) {
  // console.log('resetWebsocket')
  token = newToken

  // Close socket connection which will also unregister subscriptions on the server-side.
  wsLink.subscriptionClient.close()

  // Reconnect to the server.
  wsLink.subscriptionClient.connect()

  // Reregister all subscriptions (uses non public api).
  // See: https://github.com/apollographql/subscriptions-transport-ws/issues/171
  // Object.keys(wsClient.operations).forEach((id) => {
  //   wsClient.sendMessage(id, MessageTypes.GQL_START, wsClient.operations[id].options)
  // })
}

function dataIdFromObject(result) {
  if (result.__typename) {
    if (result.id !== undefined) {
      return `${result.__typename}:${result.id}`
    }
  }
  return null
}

const errorLink = onError(({ response, operation, graphQLErrors, networkError }) => {
  debug && console.log('<<<APOLLO LINK ERROR', graphQLErrors, networkError.toString(), JSON.stringify(response))
  if (_.get(networkError, 'statusCode') === 401) {
    console.log('<<<RECEIVED UNAUTHORIZED NETWORK RESPONSE - LOGGING OUT')
    logout()
  }
})

const formAuthHeaders = (headers, token) => {
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
}
const authLink = setContext(async (_, { headers }) => {
  if (token) {
    return formAuthHeaders(headers, token )
  }
  token = await AsyncStorage.getItem('USER_TOKEN')
  return formAuthHeaders(headers, token)
})
const httpLink = createHttpLink({ uri: environment.graphqlEndpoint })
const mainLink = ApolloLink.from([errorLink, authLink, httpLink])

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  mainLink
)

export const client = new ApolloClient({
  name: 'react-web-client',
  link: ApolloLink.from([link]),
  cache: new InMemoryCache({ dataIdFromObject })
})

export default withApollo = (Composed) =>
  class extends Component {
    displayName = 'ApolloProvider'
    state = {
      client: null,
    }
    render() {
      return (
        <ApolloProvider client={client}>
          <Composed {...this.props} />
        </ApolloProvider>
      )
    }
  }
