import i18n from 'i18n-js'
import _ from 'lodash'
import React, { Component } from 'react'; //eslint-disable-next-line
import { Text, View } from 'react-native'
import ElegantInputWithTagging from '../../../../components/ElegantInputWithTagging'
import ImageCarousel from '../../../../components/ImageCarousel'
import Button from '../../../../components/simple/Button'
import Divider from '../../../../components/simple/Divider'
import T from '../../../../components/T'
import { TaggingContext } from '../../../../components/Tagging/withTagging'
import colors from '../../../../config/colors'
import { stylus } from '../../../../config/styles'

class ItemDetails extends Component {
  canSubmit = () => {
    const { errors = {} } = this.props
    if (typeof errors === 'object') {
      return this.props.canSubmit
    } else {
      return this.props.canSubmit
    }
  }

  renderErrors = () => {
    const { errors } = this.props
    return _.map(errors, (error, index) => {
      return (
        <Text key={index} style={styles.error}>
          {error}
        </Text>
      )
    })
  }

  render() {
    const {
      goToNext,
      handleFieldChange,
      description,
      ingredients,
      itemImages,
      handleSubmit,
      uploads,
      errors,
      animate,
      showLoader,
    } = this.props

    return (
      <View style={styles.container} onLayout={animate}>
        <ImageCarousel images={itemImages} playMuted={true} playOnce={true} navigation={this.props.navigation}/>
        <T t='shop.add.titles.itemDetails' style={styles.titles.header} />
        <View style={styles.padding}>
          {/* <ElegantInput
            error={errors.description}
            multiline
            value={description}
            label={i18n.t('shop.add.fields.description')}
            onChange={(value) => {
              handleFieldChange(value, 'description')
            }}
            onBlur={(value) => {
              handleFieldChange(value, 'description', true)
            }}
          /> */}

          <TaggingContext.Consumer>
            {(context = {}) => {
              const { taggingHook = () => {} } = context
              return (
                <>
                  {/* <Text>Test</Text> */}
                  <ElegantInputWithTagging
                    label={i18n.t('shop.add.fields.description')}
                    defaultContent={this.props.description}
                    error={errors.description}
                    taggingHook={taggingHook}
                    onChange={(value) => {
                      handleFieldChange(value, 'description')
                    }}
                  />
                  <ElegantInputWithTagging
                    label={i18n.t('shop.add.fields.ingredients')}
                    defaultContent={this.props.ingredients}
                    error={errors.ingredients}
                    taggingHook={taggingHook}
                    onChange={(value) => {
                      handleFieldChange(value, 'ingredients')
                    }}
                  />
                </>
              )
            }
          }
          </TaggingContext.Consumer>
          {/* <ElegantInput
            error={errors.ingredients}
            multiline
            value={ingredients}
            label={i18n.t('shop.add.fields.ingredients')}
            onChange={(value) => {
              handleFieldChange(value, 'ingredients')
            }}
            onBlur={(value) => {
              handleFieldChange(value, 'ingredients', true)
            }}
          /> */}

          <Divider hidden />
          <Button
            disabled={!this.canSubmit()}
            showLoader={showLoader}
            style={styles.button.primary}
            label={i18n.t('common.done')}
            onPress={() => {
              handleSubmit()
            }}
          />
          <Divider hidden />
          <Text style={styles.error}>{this.renderErrors()}</Text>
          <Divider hidden />
        </View>
      </View>
    )
  }
}

export default ItemDetails

const styles = stylus({
  container: {
    width: '100%',
    // backgroundColor: 'green',
  },
  titles: {
    header: {
      textAlign: 'center',
      fontSize: 24,
      fontWeight: 'bold',
      marginTop: 20,
    },
    sub: {
      fontSize: 16,
      textAlign: 'center',
      // fontWeight: 'bold',
    },
  },
  button: {
    margin: {
      marginTop: 20,
    },
    primary: {
      backgroundColor: colors.primary,
    },
  },
  padding: {
    paddingHorizontal: 20,
  },
  error: {
    textAlign: 'center',
    // alignSelf: 'center',
    color: 'red',
  },
})
