let colors = {
  fullScreenBackground: false,
  // top bar bg
  topNavigationBackground: '#FAFAFA',
  // top header center text color
  headerTitle: '#000',

  // buttons / text
  vino: '#c00000',
  liked: '#e74c3c',
  primaryDark: '#f99c33',
  facebook: '#3B5998',
  primary: '#FEBD2B',
  primaryCooler: '#E9AD44',
  primaryContrast: '#448EE9',
  taggingText: '#1A88E5',
  lightGray: '#D3D3D3',
  emptyArea: '#EEE',
  error: '#ff2c55',
  tagLabel: '#F0F0F0',
  tagBackground: '#C0C0C0',
  text: {
    main: '#000',
    mainLighter: '#333',
    soft: '#555',
    content: '#000',
    secondary: '#999',
    light: '#AAA',
    invert: 'white',
    link: '#0f70ff',
  },
  button: {
    background: '#D1D3D4',
    disabled: '#E6E7E8',
  },

  input: {
    border: '#999',
    // input placeholder text
    placeholder: '#BBB',
    // text input color
    text: '#333',
    tint: '#E9AD44',
    base: 'rgba(0, 0, 0, .38)',
    backgroundFill: '#EEE',
    textArea: 'white',
    textAreaBorder: '#DDD',
    error: '#d50000',
  },

  icon: {
    strong: '#000',
    disabled: '#DDD',
  },

  // refactor
  active: '#EEE',
  black: '#000',
  contrast: '#00e092',

  card: {
    background: '#FFF',
    border: '#DDD',
    shadow: '#000',
  },

  // main navigation color for back-button, etc
  navigation: '#000',

  // generics
  green: '#00a651',
  red: '#BE1E2D',
  orange: '#fead7b',
  disabled: '#CCC',
  postUploadingBar: '#CCC',

  // web
  webScrollbarTrack: 'transparent',
  webScrollbarThumb: '#ccc',

  inactive: '#000',
  glowingSelection: 'rgb(0,122,255)',
  androidCardBorder: '#ccc',
  unseenBackground: 'rgba(0,130,255,0.035)',

  mainBackground: 'white',
  landscape: {
    inactive: '#333',
    activeText: '#E9AD44',
    mainBackground: 'white',
    leftBackground: 'white',
    stackHeaderBackground: 'white',
    tabLabelActive: 'black',
    tabLabelInactive: '#666',
    leftGradient: {
      first: '#f8f8f8',
      second: '#FFFFFF',
    },
    topGradient: {
      first: '#EEE',
      second: '#F0F0F0',
      third: '#FFF',
    },
  },
  landscapeTopBar: {
    text: '#333',
    icon: 'rgba(0,0,0,0.7)',
  },

  actions: {
    photo: '#E9AD44',
    location: '#ff2c55',
    with: '#C0E75B',
    stripe: '#44a9e5',
    selectDefault: '#88bbfd'
  },
  smile: '#FFCA60',
  thinLine: '#DDD',
  thinLineSoft: '#EEE',
  chatButtons: '#999',

  // components
  mainTabs: {
    background: '#FFF',
    active: '#000',
    inactive: '#000',
    borderTop: 'rgba(0,0,0,0.3)',
  },
  checkbox: {
    checked: '#E9AD44',
    unchecked: '#d9dadb',
  },
  tabView: {
    tabLabelBackground: '#FEA315',
    activeText: 'black',
  },
  starRatings: '#FEA315',
  gradientButton: {
    firstBackground: '#E9AD44',
    secondBackground: '#ae00ff',
    text: 'white',
    invert: {
      firstBackground: '#EEE',
      secondBackground: '#FFF',
      text: '#E9AD44',
    },
  },
  listItem: {
    chevron: '#CCC',
  },
  post: {
    actionItem: '#777',
  },
  sendButton: {
    enabled: '#000',
    disabled: '#DDD',
  },
  toast: {
    background: 'rgba(0, 0, 0, 0.8)',
    text: 'white',
  },
  logo: {
    black: '#2b2a29'
  }
}

const darkMode = {
  fullScreenBackground: true,
  topNavigationBackground: 'transparent',
  headerTitle: '#FFF',
  mainBackground: '#0c0071',
  navigation: '#FFF',
  unreadBackground: 'rgba(255, 255, 255, 0.05)',
  primary: 'fuchsia',
  primaryContrast: '#1ff216',
  emptyArea: 'rgba(255, 255, 255, 0.1)',
  tagLabel: 'rgba(255, 255, 255, 0.2)',
  text: {
    main: '#FFF',
    mainLighter: 'rgba(255, 255, 255, 0.9)',
    soft: 'rgba(255, 255, 255, 0.75)',
    secondary: 'rgba(255, 255, 255, 0.6)',
    content: 'rgba(255, 255, 255, 0.8)',
    light: 'rgba(255, 255, 255, 0.3)',
    invert: '#060323',
    link: '#0f70ff',
  },
  thinLine: 'rgba(255, 255, 255, 0.3)',
  thinLineSoft: 'rgba(255, 255, 255, 0.1)',
  icon: {
    strong: 'rgba(255, 255, 255, 0.8)',
    disabled: 'rgba(255, 255, 255, 0.2)',
  },
  input: {
    border: 'rgba(255, 255, 255, 0.5)',
    // input placeholder text
    placeholder: 'rgba(255, 255, 255, 0.3)',
    // text input color
    text: 'rgba(255, 255, 255, 0.9)',
    tint: 'fuchsia',
    base: 'rgba(255, 255, 255, .38)',
    backgroundFill: 'rgba(0, 0, 0, 0.3)',
    textArea: 'rgba(0, 0, 0, 0.3)',
    textAreaBorder: 'rgba(0, 0, 0, 0.4)',
  },
  // web
  webScrollbarTrack: 'transparent',
  webScrollbarThumb: 'rgba(255, 255, 255, 0.3)',

  card: {
    background: 'transparent',
    border: 'rgba(0, 0, 0, 0.25)',
  },

  landscape: {
    inactive: '#333',
    activeText: '#E9AD44',
    mainBackground: '#0c0071',
    leftBackground: 'rgba(0, 0, 0, 0.3)',
    stackHeaderBackground: 'rgba(0, 0, 0, 0)',
    tabLabelActive: 'white',
    tabLabelInactive: 'rgba(255, 255, 255, 0.6)',
    leftGradient: {
      first: 'rgba(0, 0, 0, 0)',
      second: 'rgba(0, 0, 0, 0.1)',
    },
    topGradient: {
      first: 'rgba(0, 0, 0, 0.2)',
      second: 'rgba(0, 0, 0, 0.0)',
      third: 'rgba(0, 0, 0, 0.2)',
    },
  },
  // components
  mainTabs: {
    background: '#0a0875',
    active: '#FFF',
    inactive: 'rgba(255, 255, 255, 0.7)',
    borderTop: 'rgba(255,255,255,0.2)',
  },
  chatButtons: 'rgba(255, 255, 255, 0.5)',
  sendButton: {
    enabled: 'rgba(255, 255, 255, 0.2)',
    disabled: 'rgba(0, 0, 0, 0.3)',
  },
  toast: {
    background: 'rgba(0, 0, 0, 0.8)',
    text: 'white',
  },
}

const dark = false
if (dark) {
  colors = {
    ...colors,
    ...darkMode,
  }
}

export default colors
