import React, { useMemo, useState } from 'react'
import { ActivityIndicator, View, Text } from 'react-native'
import { connect } from 'react-redux'
import { compose, hoistStatics } from 'recompose'
import { graphql } from 'react-apollo'
import { union, uniqBy, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import productSchema from '../../schema/product'
import ProductCarousel from '../../components/ProductCarousel/ProductCarousel'

const INITIAL_PAGE_SIZE = 10
const SUBSEQUENT_PAGE_SIZE = 10
const INITIAL_OFFSET = 0

const RelatedProductsByShop = ({ productsByUserId, shop, exceptProduct, screenInfo }) => {
  const [offset, setOffset] = useState(INITIAL_OFFSET)
  const [noMore, setNoMore] = useState(false)

  
  const items = useMemo(() => {
    if (!productsByUserId.productsByUserId) {
      return []
    }
    return orderBy(
      productsByUserId.productsByUserId.filter(({ id }) => id !== exceptProduct),
      ['rating', 'createdAt'],
      ['desc', 'desc'],
    )
  },[productsByUserId.productsByUserId])

  function isLoading()  {
    return productsByUserId.networkStatus < 7
  }

  function loadMore() {
    if (isLoading()) return
    if (noMore) return
    if (productsByUserId.productsByUserId.length < offset) {
      setNoMore(true)
      return
    }
    const newOffset = offset + SUBSEQUENT_PAGE_SIZE
    
    setOffset(newOffset)

    productsByUserId.fetchMore({
      variables: {
        offset: newOffset
      }, 
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        
        const newData = uniqBy(
          union(
            prev.productsByUserId,
            fetchMoreResult.productsByUserId
          ),
          'id',
        )
        return { productsByUserId: newData }
      },
    })
  }

  return (
    <View>
      <Text style={[styles.bodyTitle, styles.bodyPadding]}>
        {i18n.t('product.otherItemsLabel', {
          shopName: shop.displayName,
        })}
      </Text>
      {items && items.length > 0 ? (
        <ProductCarousel
          size='large'
          onPress={() => {}}
          style={{ height: 250 }}
          showPrice
          shopName={shop.displayName}
          showShop={false} //for items
          imageSize={screenInfo.width / 2.75}
          items={items}
          onEndReachedThreshold={0.4}
          onEndReached={loadMore}
          ListFooterComponent={() =>
            isLoading() && (
              <View style={styles.footerContainer}>
                <ActivityIndicator size="small" />
              </View>
            )
          }
        />
      ) : (
        <Text style={[styles.notAvailable, { paddingVertical: 50 }]}>
          {i18n.t('product.otherItemsNotAvailable')}
        </Text>
      )}
    </View>
  )
}  

const styles = stylus({
  bodyPadding: {
    paddingHorizontal: 16,
  },
  bodyTitle: {
    fontWeight: '600',
    fontSize: 18,
    marginBottom: 5,
  },
  notAvailable: {
    color: colors.text.light,
    fontSize: 22,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  footerContainer: {
    flex: 1,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center'
  }
})


RelatedProductsByShop.propTypes = {
  productsByUserId: PropTypes.any,
  shop: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }),
  exceptProduct: PropTypes.string,
  screenInfo: PropTypes.shape({
    width: PropTypes.number
  })
}

PropTypes.defaultProps = {
  exceptProduct: '',
}

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo
})

const enchanced = compose(
  connect(mapStateToProps),
  graphql(productSchema.queries.productsByUserId, {
    name: 'productsByUserId',
    options: (props) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: props.shop.id,
        limit: INITIAL_PAGE_SIZE,
        offset: INITIAL_OFFSET,
        onlyAvailable: true,
      }
    }),
  }),
)

export default hoistStatics(enchanced)(RelatedProductsByShop)
