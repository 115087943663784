import React, { Component, useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import PropTypes from 'prop-types'
import moment from 'moment'
import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import NamedImage from '../../components/simple/NamedImage'
import TagText from '../../components/Tagging/TagText'
import UserTitle from '../UserTitle/UserTitle'
import ViewMoreText from 'react-native-view-more-text'
import i18n from 'i18n-js'
import { pure } from 'recompose'
import LikeButton from '../LikeButton/LikeButton'
import { graphql } from '../../config/connected'
import CommentSchema from '../../schema/comment'
import PostSchema from '../../schema/post'
import _ from 'lodash'
import NavigationActions from '../../utility/navigationActions'
import { connect } from 'react-redux'
import { compose } from 'redux'
import MoreOptions from '../simple/MoreOptions'
import { ROUTE_REPORT_CONTENT_COMMENT } from '../../config/constants'
import withCurrentUser from '../../containers/withCurrentUser'
import confirm from '../../utility/confirm'

const ViewMore = ({ onPress }) => (
  <Text style={styles.expandButton}
    onPress={onPress}>{i18n.t('comment.viewMore')}</Text>)

ViewMore.propTypes = {
  onPress: PropTypes.func,
}

const CommentAge = ({ comment, style }) => {
  const date = moment(comment.createdAt)

  if (moment().diff(date, 'day') === 0) {
    const [seconds, setSeconds] = useState(0)

    useEffect(() => {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1)
      }, 10000)
      return () => clearInterval(interval)
    }, [])
  }

  return (
    <Text style={[styles.age, style]}>
      {date.fromNow()}
    </Text>
  )
}

CommentAge.propTypes = {
  comment: PropTypes.any,
  style: PropTypes.any,
}

const ReadMore = pure(ViewMoreText)

class Comment extends Component {
  state = {
    deleted: false,
  }

  renderViewMore(onPress) {
    return (
      <Text style={styles.expandButton} onPress={onPress}>{i18n.t('comment.viewMore')}</Text>
    )
  }

  renderViewLess() {
    return null
  }

  goToProfile = () => {
    const { comment } = this.props
    const { user } = comment
    this.props.goToProfile({
      id: user.id,
      pageTitle: user.username,
    })
  }

  _handleCommentLike = () => {
    const { comment, likeComment, unlikeComment } = this.props
    if (comment.iLike === true) {
      unlikeComment({
        variables: {
          commentId: comment.id,
        },
        optimisticResponse: {
          'unlikeComment': {
            ...comment,
            likesCount: comment.likesCount - 1,
            iLike: false,
          },
        },
      })
    } else {
      likeComment({
        variables: {
          commentId: comment.id,
        },
        optimisticResponse: {
          'likeComment': {
            ...comment,
            likesCount: comment.likesCount + 1,
            iLike: true,
          },
        },

      })
    }
  }

  _handleGoToLikes = () => {
    this.props.goToLikes(this.props.comment.id)
  }

  handleDelete = async () => {
    const { comment, deleteComment } = this.props

    const confirmed = await confirm({
      title: i18n.t('common.delete'), 
      message: i18n.t('comment.deleteConfirmation'),
    })

    if (!confirmed) return

    await deleteComment({
      variables: {
        commentId: comment.id,
      },
      update: (store, { data: { deleteComment } }) => {
        const { commentId, postId } = deleteComment
        // update comments
        const data = store.readQuery({
          query: CommentSchema.queries.comments,
          variables: { postId },
        })
        const dataFiltered = data.comments.filter(comment => comment.id !== commentId)
        store.writeQuery({
          query: CommentSchema.queries.comments,
          variables: { postId },
          data: dataFiltered,
        })

        // update post comment count
        const postData = store.readQuery({
          query: PostSchema.queries.post,
          variables: { id: postId },
        })
        postData.post.commentCount--
        store.writeQuery({
          query: PostSchema.queries.post,
          variables: { id: postId },
          data: postData,
        })

        this.setState({
          deleted: true
        })
      },
    })
  }

  renderContent = (content) => {
    const { ghostText } = this.props
    if (ghostText) {
      return (
        <Text style={styles.content}>
          <TagText content={content}/>
          {' '}
          <Text style={styles.ghostContent}>{ghostText}</Text>
        </Text>
      )
    }
    return <TagText style={styles.content} content={content}/>
  }

  render() {
    const { comment, currentUser, ghostText} = this.props
    const { user, content } = comment
    const { title, profileImage, username, organization, organizationId } = user
    const type = _.get(comment, '__typename')
    const moreOptions = []
    const isAdmin = _.get(currentUser, 'currentUser.isAdmin')

    if (comment.deletedAt || this.state.deleted) {
      return null
    }

    if (comment.userId !== _.get(currentUser, 'currentUser.id')) {
      moreOptions.push({ label: i18n.t('common.report'), action: this.props.goToReportContent })
    }
    if(isAdmin) {
      moreOptions.push({ label: `${i18n.t('common.delete')}`, action: this.handleDelete })
    }

    return (
      <View style={styles.container}>
        <TouchableOpacity onPress={this.goToProfile}>
          <NamedImage
            style={styles.profileImage}
            name={profileImage}
            width={32}
            height={32}
            fit='crop'
            crop='faces'
            resizeMode='cover'
            resizeMethod='scale'
            placeholder='profile'
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.textContainer}
          activeOpacity={0.7}
        >
          <TouchableOpacity onPress={this.goToProfile}>
            <Text style={styles.username} numberOfLines={1}>
              {username}
            </Text>
            <UserTitle
              title={title}
              organization={organization}
              organizationId={organizationId}
              size={12}
              style={styles.userTitleContainer}
            />
          </TouchableOpacity>
          { this.props.expanded ?
            this.renderContent(content)
            :
            <ReadMore
              key={_.get(content, 'value', '').length}
              numberOfLines={2}
              renderViewMore={this.renderViewMore}
              renderViewLess={this.renderViewLess}
              textStyle={styles.content}
            >
              {this.renderContent(content)}
            </ReadMore>
          }

          <View style={{ flex: 1, flexDirection: 'row' }}>
            <CommentAge comment={this.props.comment}/>
            {type === 'Comment' && comment.likesCount > 0 &&
            <TouchableOpacity onPress={this._handleGoToLikes}>
              <Text
                style={styles.age}> | {i18n.t('post.likes', { count: comment.likesCount })}
              </Text>
            </TouchableOpacity>
            }
          </View>
        </TouchableOpacity>
        {type === 'Comment' &&
        <LikeButton
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          iLike={comment.iLike}
          style={{ alignSelf: 'flex-start', marginTop: 6 }}
          onPress={this._handleCommentLike}
          options={{ unlikedColor: colors.text.secondary }}
        />}
        {type === 'Comment' && isAdmin &&
        <View style={{ marginTop: 4, marginLeft: 6, flexDirection: 'column', alignSelf: 'flex-start' }}>
          <MoreOptions
            content={this.props.comment}
            options={moreOptions}
            containerSizeOffset={4}
          />
        </View>
        }
      </View>
    )
  }
}

Comment.propTypes = {
  ghostText: PropTypes.string,
  currentUser: PropTypes.object,
  goToProfile: PropTypes.func,
  goToLikes: PropTypes.func,
  goToReportContent: PropTypes.func,
  followUser: PropTypes.func,
  likeComment: PropTypes.func,
  unlikeComment: PropTypes.func,
  deleteComment: PropTypes.func,
  expanded: PropTypes.bool,
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.date,
    content: PropTypes.object.isRequired,
    iLike: PropTypes.bool,
    likesCount: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.any,
      title: PropTypes.any,
      profileImage: PropTypes.any,
      username: PropTypes.any,
      organization: PropTypes.any,
      organizationId: PropTypes.any,
    }),
  }),
}

const styles = stylus({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingRight: 10,
    marginTop: 9,
    marginBottom: 3,
  },
  profileImage: {
    width: 32,
    height: 32,
    borderRadius: 16,
    marginRight: 11,
    marginLeft: 12,
  },
  textContainer: {
    flex: 1,
  },
  username: {
    color: colors.text.main,
    fontWeight: '600',
    fontSize: 14,
    marginBottom: 2
  },
  userTitleContainer: {
    marginBottom: 2,
  },
  age: {
    paddingTop: 4,
    color: colors.text.secondary,
    fontSize: 10,
  },
  content: {
    fontSize: 14,
    color: colors.text.content,
  },
  ghostContent: {
    color: colors.text.secondary,
  },
  titleBullet: {
    color: '#777',
    fontWeight: '300',
  },
  expandButton: {
    marginTop: 3,
    color: colors.taggingText,
  },
})

const mapDispatchToProps = (dispatch) => ({
  goToReportContent: (content) => {
    NavigationActions.navigate({
      routeName: ROUTE_REPORT_CONTENT_COMMENT,
      params: { objectId: content.id },
    })
  },
  goToLikes: (id) =>
    NavigationActions.navigate({
      routeName: 'CommentLikesViewer',
      params: { id },
    })
})

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  graphql(CommentSchema.mutations.likeComment, { name: 'likeComment' }),
  graphql(CommentSchema.mutations.unlikeComment, { name: 'unlikeComment' }),
  graphql(CommentSchema.mutations.deleteComment, { name: 'deleteComment' }),
  withCurrentUser
)(Comment)
