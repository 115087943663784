import React from 'react'
import { View, Image, Text, TouchableOpacity } from 'react-native'
import Ratings from '../simple/Ratings'
import PropTypes from 'prop-types'
import Icons from '@expo/vector-icons/Ionicons'
import Icon from '../../config/icon'
import { stylus } from '../../config/styles'
import { getPrice, unitPlural } from '../../config/helpers'
import _ from 'lodash'
import NamedImage from '../simple/NamedImage'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import { _get, connect } from '../../config/connected'
import NavigationActions from '../../utility/navigationActions'
import i18n from 'i18n-js'

const SIZE = 50

@connect(
  () => ({}),
  (dispatch) => ({
    goToProduct: (id) => NavigationActions.navigate({ routeName: 'Product', params: { id } }),
  }),
)
class ProductListView extends React.Component {
  static propTypes = {
    product: PropTypes.shape({
      uploads: PropTypes.any,
      name: PropTypes.string,
      rating: PropTypes.number,
      ratings: PropTypes.number,
      shopName: PropTypes.string,
      price: PropTypes.number,
      priceDiscount: PropTypes.number,
      unitType: PropTypes.string,
      unitQuantity: PropTypes.number,
    }),
    goToProduct: PropTypes.func.isRequired,
  }

  goToProduct = () => {
    this.props.goToProduct(this.props.product.id)
  }

  render() {
    const {
      uploads,
      name,
      rating,
      ratings,
      shopName,
      price,
      priceDiscount,
      id,
      unitType,
      unitQuantity,
    } = this.props.product

    const { product, hideShop } = this.props

    const { goToProduct, width } = this.props

    const image = _.find(uploads, i => i.mime !== 'mp4' && i.mime !== 'mov' ) || {
      name: '',
      mime: '',
      width: 5,
      height: 5,
    }
    const unitProduct = unitPlural(product)

    return (
      <TouchableOpacity style={[styles.container]} onPress={this.goToProduct}>
        <View style={[styles.imageBorder]}>
          <View style={styles.imageRadius}>
            <NamedImage
              name={image.name}
              width={SIZE}
              height={SIZE}
              style={styles.image}
              fit='crop'
              crop='entropy'
              resizeMethod='scale'
              resizeMode='cover'
            />
          </View>
        </View>
        <View style={[styles.content]}>
          <Text style={styles.title} numberOfLines={2}>
            {name}
          </Text>
          {!!unitProduct && (
            <Text style={styles.title}>
              {unitProduct}
            </Text>
          )}
          {!hideShop && <Text style={styles.shopName}>{shopName}</Text>}
          <Text style={styles.price}>{getPrice(price)}</Text>
          <Ratings
            rating={product.rating}
            ratings={product.ratingCount}
          />
        </View>
      </TouchableOpacity>
    )
  }
}

const styles = stylus({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 14,
    paddingVertical: 6,
  },
  imageBorder: {
    borderWidth: 1,
    borderColor: colors.primaryDark,
    padding: 4,
    borderRadius: (SIZE + 10) / 2,
    width: SIZE + 10,
    height: SIZE + 10,
  },
  imageRadius: {
    width: SIZE,
    height: SIZE,
    borderRadius: SIZE / 2,
    overflow: 'hidden',
  },
  image: {
    width: SIZE,
    height: SIZE,
    borderRadius: SIZE / 2,
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    paddingVertical: 6,
    marginLeft: 14,
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
  },
  title: {
    fontWeight: 'bold',
    color: colors.text.main,
    fontSize: 14,
  },
  shopName: {
    fontSize: 13,
    color: colors.text.secondary,
  },
  bookmark: {
    position: 'absolute',
    backgroundColor: 'transparent',
  },
  price: {
    fontWeight: 'bold',
    fontSize: 11,
    color: colors.text.main,
  },
})

export default ProductListView
