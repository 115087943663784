export default {
  kg: {
    it: 'kg',
    en: 'kg',
    plural_it: 'kg',
    plural_en: 'kg',
    name_it: 'chilogrammo',
    name_en: 'kilogram',
  },
  g: {
    it: 'gr',
    en: 'gr',
    plural_it: 'gr',
    plural_en: 'gr',
    name_it: 'grammo',
    name_en: 'gram',
  },
  l: {
    it: 'L',
    en: 'L',
    plural_it: 'L',
    plural_en: 'L',
    name_it: 'litro',
    name_en: 'liter',
  },
  ml: {
    it: 'ml',
    en: 'ml',
    plural_it: 'ml',
    plural_en: 'ml',
    name_it: 'millilitro',
    name_en: 'milliliter',
  },
  // unit: {
  //   en: 'ct',
  //   it: 'unità',
  //   plural_it: 'unità',
  //   plural_en: 'ct',
  //   name_it: 'unità',
  //   name_en: 'count',
  // }
}