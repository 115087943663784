import React from 'react'
import { Text, TouchableOpacity, View, ActivityIndicator, Image } from 'react-native'

import Icons from '@expo/vector-icons/Ionicons'
import { Video, Audio } from 'expo-av'

import { stylus } from '../../../config/styles'
import { _get, compose, connect } from '../../../config/connected'
import environment from '../../../config/environment'
import Gradient from '../../simple/Gradient'
import Button from '../../simple/Button'
import colors from '../../../config/colors'
import Ratings from '../../simple/Ratings'
import NamedImage from '../../simple/NamedImage'
import i18n from 'i18n-js'
import T from '../../T'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { graphql } from 'react-apollo'
import conversationSchema from '../../../schema/conversation'
import NavigationActions from '../../../utility/navigationActions'
import withActionSheet from '../../../containers/ActionSheet/withActionSheet'
import userSchema from '../../../schema/user'
import FollowersWidget from '../../FollowersWidget'
import { getFeatureFlag, resolveVideoThumbnail } from '../../../config/helpers'
import withCurrentUser from '../../../containers/withCurrentUser'
import { getFullOffset } from '../../../utility/coverHeight'
import nameToUrl from '../../../config/nameToUrl'
import { getLayout } from '../../../routing/selectLayout'

const ratio = 1.618
const debug = false
@withCurrentUser
@graphql(userSchema.mutations.followUser, { name: 'followUser' })
@graphql(userSchema.mutations.unfollowUser, { name: 'unfollowUser' })
@withActionSheet
class ShopCover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoReady: false,
      imageReady: false,
      thumbReady: false,
      videoLoaded: false,
      videoMute: false,
      showReplayIcon: false,
    }
    this.videoMute = false
    this.videoRef = React.createRef()
    this.isPressed = false
    this.imageTimeout = null
    this.videoTimeout = null
  }

  componentDidMount() {
    if (getLayout() !== 'web/portrait' && getLayout() !== 'web/landscape') {
      this.willFocusListener = this.props.forwardedNavigation.addListener('willFocus', () => {
        const { shop } = this.props
        if (shop) {
          const { coverPhoto } = shop
          const isVideo =  this.checkIsCoverVideo(coverPhoto)
          if (isVideo) {
            this.fetchCDNVideo(this.resolveCDNUri(coverPhoto))
          }
        }
      })
    }
    this.didFocusListener = this.props.forwardedNavigation.addListener('didFocus', () => {
      this.setState({ isFocused: true })
    })

    this.didBlurListener = this.props.forwardedNavigation.addListener('didBlur', () => {
      this.setState({ isFocused: false })
    })

   
    Audio.setAudioModeAsync({
      allowsRecordingIOS: false,
      interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: true,
      interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
      playThroughEarpieceAndroid: false,
    })
  }

  componentWillUnmount() {
    this.willFocusListener &&  this.willFocusListener.remove()
    this.didFocusListener && this.didFocusListener.remove()
    this.didBlurListener && this.didBlurListener.remove()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)) {
      return true
    }
    return false
  }
  componentDidUpdate(prevProps, prevState) {
    const { shop: currShop, currentTab } = this.props
    const { shop: prevShop } = prevProps
    const { isFocused } = this.state
    const { isFocused: prevIsFocused } = prevState
    const currCoverPhoto = _get(this.props, 'shop.coverPhoto', '')
    const prevCoverPhoto = _get(prevProps, 'shop.coverPhoto', '')
    const containsVideoIndicator = ['.mp4', '.mov', 'thumbnail-'].some(indicator => _.invoke(currCoverPhoto, 'includes', indicator))
    if(prevIsFocused !== isFocused) {
      this.setState({
        imageReady: false,
        thumbReady: false,
        videoReady: false,
        videoLoaded: false,
      })
    }
    if (containsVideoIndicator) {
      if ((currShop && prevShop && currCoverPhoto !==  prevCoverPhoto) || (currShop && !this.state.videoReady)) {
        this.fetchCDNVideo(this.resolveCDNUri(currCoverPhoto))
      }

      if (this.videoRef.current) {
        if (this.state.videoReady && (!isFocused || currentTab !== 'Shop' || currentTab !== null)) {
          this.isPressed = false
          this.videoRef.current.stopAsync()
        }
        if (this.state.videoReady && isFocused && (currentTab === 'Shop' || currentTab === null)) {
          this.videoRef.current.playAsync()
        }
      }
    }
  }

  _goToChat = async (userId) => {
    const currentUserId = _get(this.props.currentUser, 'currentUser.id', '')
    const result = await this.props.startPrivateConversation({
      variables: {
        userIds: [currentUserId, userId],
      },
    })
    const membershipId = _.get(result, 'data.startPrivateConversation.id')
    const conversationId = _.get(
      result,
      'data.startPrivateConversation.conversation.id',
    )
    const memberships = _.get(
      result,
      'data.startPrivateConversation.conversation.memberships',
    )
    const users = _.map(memberships, 'user')
    NavigationActions.navigate({
      routeName: 'Conversation',
      params: {
        id: membershipId,
        cuid: currentUserId,
        users,
      },
    })
  }

  renderActionButtons = () => {
    const { isOwner } = this.props
    const buttonPrimaryStyle = styles.buttonPrimary
    const ownerId = _.get(this.props, 'shop.producerDetails.ownership.userId')
    const followType = _get(this.props, 'shop.iFollowType', '')
    const isFollowDisabled = !getFeatureFlag('followShop')
    let elements = []

    if (!isOwner) {
      switch (followType) {
        case 'SHOP_WITH_NOTIFICATIONS':
        case 'SHOP':
          elements.push(
            <Button
              disabled={isFollowDisabled}
              key={2}
              disabledStyle={{ backgroundColor: colors.disabled }}
              label={i18n.t('common.following')}
              labelStyle={styles.buttonLabel}
              style={buttonPrimaryStyle}
              onPress={() => {
                this.props.unfollowUser({
                  variables: {
                    id: this.props.shop.id,
                  },
                })
              }}
            />,
          )
          break
        default:
          elements.push(
            <Button
              key={2}
              disabled={isFollowDisabled}
              childrenAfterLabel
              disabledStyle={{ backgroundColor: colors.disabled }}
              label={i18n.t('common.follow')}
              labelStyle={styles.buttonLabel}
              style={buttonPrimaryStyle}
              onPress={() => {
                this.props.followUser({
                  variables: {
                    id: this.props.shop.id,
                    type: 'SHOP',
                  },
                })
              }}
            />,
          )
          break
      }
      elements.push(
        <Button
          key={3}
          disabledStyle={{ backgroundColor: colors.disabled }}
          label={i18n.t('common.chat')}
          labelStyle={styles.buttonLabel}
          style={buttonPrimaryStyle}
          onPress={() => {
            this._goToChat(ownerId)
          }}
        />,
      )
    }

    return elements
  }

  resolveCDNUri = fileName => {
    if (!fileName) {
      return
    }
    const videoName = fileName.includes('thumbnail-') ? fileName.replace('thumbnail-', '') : fileName
    return `https://${environment.videoCDNId}.cloudfront.net/assets/${videoName.split('.')[0]}/HLS/${videoName.split('.')[0]}.m3u8`
  }

  fetchCDNVideo = async uri => {
    const { videoReady } = this.state

    if (!uri && videoReady) {
      return
    }
    let response = await fetch(uri, {
      headers: {
        'Cache-Control': 'no-cache'
      }
    })

    if (!response.ok && response.status !== 200) {
      if (this.videoTimeout) {
        clearTimeout(this.videoTimeout)
      }
      this.videoTimeout = setTimeout(() => {
        this.fetchCDNVideo(uri)
      }, 3000)
    } else {
      if (this.videoTimeout) {
        clearTimeout(this.videoTimeout)
      }
      this.setState({
        videoReady: true
      })
    }
  }
  fetchImage = async (uri, isThumb) => {
    const { thumbReady, imageReady } = this.state
    const isReady = isThumb ? thumbReady : imageReady
    if(!uri || isReady) {
      return
    }
    const response = await fetch(uri, {
      headers: {
        'Cache-Control': 'no-cache'
      }
    })

    if (!response.ok && response.status !== 200) {
      if (this.imageTimeout) {
        clearTimeout(this.imageTimeout)
      }
      this.imageTimeout = setTimeout(() => {
        this.fetchImage(uri, isThumb)
      }, 3000)
    }else {
      if (this.imageTimeout) {
        clearTimeout(this.imageTimeout)
      }
      const newImageState = isThumb ? {
        thumbReady: true,
      } : {
        imageReady: true
      }
      this.setState(newImageState)
    }

  }
  toggleMute = () => {
    this.videoMute = !this.videoMute
    this.videoRef.current.setIsMutedAsync(this.videoMute)
    this.forceUpdate()
  }

  renderMuteButton = () => {
    return (
      <TouchableOpacity
        onPress={this.toggleMute}
        style={styles.videoMuteView}
      >
        <Icons name={this.videoMute ? 'ios-volume-off' : 'ios-volume-high'} style={[styles.gradientText, {lineHeight: 50}]} size={24} />
      </TouchableOpacity>
    )
  }

  replayVideo = () => {
    this.videoRef.current.replayAsync()
    this.isPressed = true
    this.setState({
      showReplayIcon: false
    })
  }

  renderReplayButton = () => {
    return (
      <TouchableOpacity
        style={styles.videoReplayView}
        onPress={this.replayVideo}
      >
        <Icons name={'ios-refresh-circle'} style={[styles.gradientText, {lineHeight: 50}]} size={24} />
      </TouchableOpacity>
    )
  }
  onPlaybackStatusUpdate = (status) => {
    if (status.didJustFinish && !status.isLooping) {
      if (this.videoRef) {
        this.videoRef.current.replayAsync()
      }
    }
  }

  checkIsCoverVideo = shopCover => {
    return ['.mp4', '.mov'].some(indicator => _.invoke(shopCover, 'includes', indicator))
  }
  getPostCoverUri = (width, shopCover, height) => {
    return nameToUrl({
      name: shopCover,
      width,
      height,
      fit: 'crop',
    })
  }
  renderVideo = (shop, screenInfo, coverHeight, coverPhoto, opacity) => {
    const { videoReady, thumbReady } = this.state
    const uri = this.resolveCDNUri(coverPhoto)
    const width = screenInfo.contentWidth
    const height = coverHeight
    const posterUri = this.getPostCoverUri(width, resolveVideoThumbnail(coverPhoto), height, )
    if (!videoReady && !thumbReady) {
      const isThumb = true
      this.fetchImage(posterUri, isThumb)
    }
    if (!videoReady && thumbReady) {
      return (
        <Image
          source={{
            uri: posterUri,
            headers: {
              'Cache-Control': 'no-cache'
            }
          }}
          style={{
            width,
            height,
            opacity,
          }}
          resizeMethod='scale'
          resizeMode='cover'
        />
      )
    } else if (!videoReady && !thumbReady) {
      return (
        <ActivityIndicator
          size='large'
          style={{ position: 'absolute', alignSelf: 'center', top: '50%' }}
        />
      )
    }
    return (
      <Video
        source={{ uri }}
        useNativeControls={false}
        isMuted={this.state.videoMute}
        shouldPlay={true}
        resizeMode="cover"
        onEnd={() => {
          this.videoRef.current.seek(0)
        }}
        onLoad={status => {
          const { isLoaded } = status
          if (isLoaded) {
            this.setState({videoLoaded: isLoaded})
          }
        }}
        posterSource={{uri: posterUri}}
        onPlaybackStatusUpdate={this.onPlaybackStatusUpdate}
        style={{
          width,
          height,
        }}
        ref={this.videoRef}
        usePoster={true}
      />
    )
  }

  renderImage = (shop, screenInfo, coverHeight, coverPhoto, opacity) => {
    let { imageReady } = this.state
    const width = screenInfo.contentWidth
    const height = coverHeight

    // AZ1495 - May or may not need imageReady for ShopCover.
    // Only really needed if the owner/admin, submits the shop edit to cover photo
    // and then immediately closes the app. After which he/she then re-opens it before the upload has finished.
    // if (imageReady) {
    return (
      <NamedImage
        name={coverPhoto}
        style={{ height, width }}
        width={width}
        height={height}
        resizeMethod='scale'
        resizeMode='cover'
        opacity={opacity}
      />
    )
    // }
    // const posterUri = this.getPostCoverUri(width, shop.coverPhoto, height)
    // this.fetchImage(posterUri)
    // return (
    //   <ActivityIndicator
    //     size='large'
    //     style={{ position: 'absolute', alignSelf: 'center', top: '50%' }}
    //   />
    // )
  }

  render() {
    const {
      screenInfo,
      shop,
      isOwner,
      goToFollowersWidgetListViewer,
      isAdmin,
      pendingShopCover
    } = this.props

    const topHeight = 88
    let coverHeight = screenInfo.height - getFullOffset(topHeight, screenInfo)
    if (screenInfo.layout === 'landscape') {
      coverHeight = screenInfo.height / 2
    }
    const followersCount = _get(shop, 'followersCount', 0)

    let coverPhoto = _.get(shop, 'coverPhoto')
    let opacity = 1
    if (pendingShopCover && coverPhoto !== pendingShopCover) {
      debug && console.log('<<<SHOP COVER - USING PENDING SHOP COVER')
      coverPhoto = pendingShopCover
      opacity = 0.5
    }

    let loading = true
    let isVideo = false
    if (!shop) {
      loading = true
    } else {
      loading = false
      isVideo = this.checkIsCoverVideo(coverPhoto)
    }

    return (
      <>
        <View style={(styles.container, { height: coverHeight })}>
          {
            !loading ? (
              (!isVideo) ? (
                this.renderImage(shop, screenInfo, coverHeight, coverPhoto, opacity)
              ) : (
                  <>
                    {this.renderVideo(shop, screenInfo, coverHeight, coverPhoto, opacity)}
                  </>
              )
            ) : null
          }
          <View style={styles.gradientContainer}>
            <Gradient
              first={'rgba(0, 0, 0, 0)'}
              middle={'rgba(0, 0, 0, 0.7)'}
              second={'rgba(0,0,0,0.85)'}
              style={[
                styles.gradient,
                {
                  height: 240,
                  width: screenInfo.contentWidth,
                },
              ]}
              vertical
            />
            <View
              style={{
                position: 'absolute',
                height: coverHeight,
                left: 0,
                right: 0,
                bottom: 0,
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingBottom: 20,
              }}
            >
              <View style={{ alignItems: 'center' }}>
                <Text style={[styles.gradientText, styles.title]}>
                  {_get(shop, 'displayName', '')}
                </Text>
                <View style={styles.gradientItemContainer}>
                  <Icons name='ios-pin' style={styles.gradientText} size={16} />
                  <Text style={[styles.gradientText, styles.subtext]}>
                    {` ${_get(shop, 'address.city', '')}, ${_get(
                      shop,
                      'address.country',
                      '',
                    )}`}
                    {!!_get(shop, 'producerDetails.established', false) && (
                      <Text style={[styles.gradientText, styles.subtext]}>
                        {' | '}
                        <Text style={[styles.gradientText, { fontSize: 14 }]}>
                          <T t='shop.tabs.shop.est' />
                          {_get(shop, 'producerDetails.established', '')}
                        </Text>
                      </Text>
                    )}
                  </Text>
                </View>
                <Ratings
                  ratings={_get(shop, 'producerDetails.ratingCount', null)}
                  rating={_get(shop, 'producerDetails.rating', null)}
                  style={[styles.gradientText]}
                  ratingsStyle={styles.ratingsText}
                />
                <FollowersWidget
                  style={{ color: 'white' }}
                  followers={shop && shop.last3Followers}
                  followersCount={followersCount}
                  onPress={() =>
                    goToFollowersWidgetListViewer(
                      this.props.forwardedNavigation.state.params.id,
                    )
                  }
                />
                <View style={styles.gradientItemContainer}>
                  {this.renderActionButtons()}
                </View>
              </View>
            </View>
          </View>
        </View>
        {/*(isOwner || isAdmin) && !_get(shop, 'producerDetails.approved', true) && (
          <TouchableOpacity
            style={{
              top: 0,
              left: 0,
              position: 'absolute',
              height: 50,
              width: '100%',
              backgroundColor: colors.vino,
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              paddingHorizontal: 30,
            }}
          >
            <IconsMCI
              name='bell-outline'
              color={colors.text.invert}
              size={30}
            />
            <T
              style={{
                color: colors.text.invert,
                alignContent: 'center',
                fontSize: 16,
              }}
              t='shop.tabs.shop.exampleShopHint'
            />
          </TouchableOpacity>
        )*/}
        {(this.state.videoReady && this.state.videoLoaded) && this.renderMuteButton()}
        {this.state.showReplayIcon && !this.isPressed && this.renderReplayButton()}
      </>
    )
  }
}

const styles = stylus({
  container: {
    flex: 1,
    backgroundColor: 'red',
    justifyContent: 'flex-start',
    position: 'relative'
  },
  gradient: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  gradientContainer: {
    position: 'absolute',
    bottom: 0,
    flex: 1,
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    // position: 'absolute',
    // padding: 16,
  },
  gradientText: {
    backgroundColor: 'transparent',
    color: 'white',
    lineHeight: 22,
  },
  title: {
    fontSize: 24,
    lineHeight: 30,
    fontWeight: 'bold',
    paddingBottom: 10,
  },
  subtext: {
    lineHeight: 26,
    fontSize: 18,
  },
  buttonPrimary: {
    margin: 6,
    flex: 1,
    flexDirection: 'row',
    backgroundColor: colors.primary,
    paddingVertical: 6,
    paddingHorizontal: 30,
  },
  buttonLabel: {
    fontSize: 15,
  },
  gradientItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  ratingsText: {
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: 14,
  },
  videoMuteView: {
    top: 20,
    right: 0,
    position: 'absolute',
    height: 50,
    width: 50,
    backgroundColor: '#000000',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 100,

  },
  videoReplayView: {
    top: 90,
    right: 0,
    position: 'absolute',
    height: 50,
    width: 50,
    backgroundColor: '#000000',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 100,

  }
})

const mapStateToProps = (state, ownProps) => ({
  screenInfo: state.screenInfo,
  navigator: state.navigation,
  uploads: state.uploads,
  // currentUser: state.currentUser,
})

ShopCover.propTypes = {
  isOwner: PropTypes.bool,
  goToEditShop: PropTypes.func,
  follow: PropTypes.func,
  unfollow: PropTypes.func,
  currentUser: PropTypes.object,
  forwardedNavigation: PropTypes.object,
  startPrivateConversation: PropTypes.func,
  uploads: PropTypes.object,
  pendingShopCover: PropTypes.string,
}

const mapDispatchToProps = (dispatch) => ({
  goToFollowersWidgetListViewer: (id) => {
    NavigationActions.navigate({
      routeName: 'FollowersWidgetListViewer',
      params: { id },
    })
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(conversationSchema.mutations.startPrivateConversation, {
    name: 'startPrivateConversation',
  }),
)(ShopCover)
