import React, { Component } from 'react'
// import withTagging from '../../components/Tagging/withTagging'
import _ from 'lodash'

import ElegantInput from '../../components/simple/ElegantInput'

// @thing[stuff]
class Test extends Component {
  state = {
    value: '',
    tags: [],
    selection: { start: 0, end: 0 },
  }

  handleChange = (newString) => {
    this.setState({ value: newString }, () => {
      if (this.props.onChange) {
        this.props.onChange({ tags: this.state.tags, value: this.state.value })
      }
    })
    this.verifyTags(newString)
  }

  verifyTags = (str) => {
    const { tags } = this.state
    var newTags = tags,
      removalIndecies = []

    _.forEach(tags, (item, index) => {
      let hasTag = false
      if (str.indexOf(item.value) !== -1) {
        hasTag = true
      }

      if (!hasTag) {
        removalIndecies.unshift(index)
      }
    })

    _.forEach(removalIndecies, (value) => {
      newTags.splice(value, 1)
    })

    this.setState({ tags: newTags })
  }

  handleKeypress = ({ nativeEvent: { key } }) => {
    if (
      key === '@' &&
      this.state.value[this.state.selection.start - 1] === ' '
    ) {
      this.props.taggingHook(this.handleAddTag)
    }
  }

  handleAddTag = (tagObject) => {
    const { tags, value, selection } = this.state

    if (!tagObject) {
      this.inputRef.input.focus()
      return
    }

    this.setState({
      tags: [...tags, tagObject],
      value: `${value.slice(0, selection.start)}${tagObject.value}${value.slice(
        selection.start,
      )}`,
    })
    this.inputRef.input.focus()
  }

  handleSelect = ({ nativeEvent: { selection } }) => {
    this.setState({ selection })
  }

  render() {
    return (
      <ElegantInput
        label={this.props.label || ''}
        value={this.state.value}
        reference={(ref) => (this.inputRef = ref)}
        onChange={this.handleChange}
        multiline
        onSelectionChange={this.handleSelect}
        onKeyPress={this.handleKeypress}
      />
    )
  }
}

export default Test
