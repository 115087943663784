import { gql } from '../config/connected'

const notificationFragment = gql`
    fragment NotificationFragment on Notification {
        id
        image
        createdAt
        type
        unseen
        counter
        data
    }
`

export default {
  queries: {
    myNotifications: gql`
        query($last: Date, $limit: Int) {
            myNotifications(last: $last, limit: $limit) @connection(key: "myNotifications") {
                ...NotificationFragment
            }
        }
        ${notificationFragment}
    `,
  },
  mutations: {
    notificationSeen: gql`
        mutation($id: String!) {
            notificationSeen(id: $id)
        }
    `,
    clearUnseenBadge: gql`
        mutation {
            clearUnseenBadge
        }
    `,
  },
  subscriptions: {
    notificationAdded: gql`
        subscription {
            notificationAdded {
                ...NotificationFragment
                unseenBadge {
                   unseenNotifications
                }
            }
        }
        ${notificationFragment}
    `,
  },
}
