import * as Yup from 'yup'
import i18n from 'i18n-js'
import {
  createYupValidationMethod,
  isAlphaNumeric,
  isValidAddress,
  isValidName,
  isValidTitle,
  isZip,
} from '../config/validate'
import _ from 'lodash'

const COUNTRIES_WITH_REQUIRED_FISCAL_DATA = ['IT']

Yup.addMethod(Yup.string, 'isZip', createYupValidationMethod('isZip', isZip))
Yup.addMethod(Yup.string, 'isAlphaNumeric', createYupValidationMethod('isAlphaNumeric', isAlphaNumeric))
Yup.addMethod(Yup.string, 'isValidAddress', createYupValidationMethod('isValidAddress', isValidAddress))
Yup.addMethod(Yup.string, 'isValidTitle', createYupValidationMethod('isValidTitle', isValidTitle))
Yup.addMethod(Yup.string, 'isValidName', createYupValidationMethod('isValidName', isValidName))

const getErrorMsg = (fieldName) => i18n.t(`errors.order.cart.${fieldName}`)
const getErrorMsgbyPath = (kind) => (field) => i18n.t(`errors.${field.path}.${kind}`)
const getCouponErrorMsg = (kind) => (field) => i18n.t(`errors.coupon.${field.path}.${kind}`)

const firstNameSchema = Yup
  .string()
  .matches(/^(?!foodbarrio).*$/i, getErrorMsgbyPath('wrong'))
  .required(getErrorMsgbyPath('required'))

const lastNameSchema = Yup
  .string()
  .matches(/^(?!foodbarrio).*$/i, getErrorMsgbyPath('wrong'))
  .required(getErrorMsgbyPath('required'))

const taxSchema = Yup.string()//.matches(/^[0-9]*$/, getErrorMsgbyPath('invalid'))
const vatSchema = Yup.string()//.matches(/^[0-9]*$/, getErrorMsgbyPath('invalid'))

export const AddressSchema = (userCountry) => Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  isBilling: Yup.boolean().default(false),
  taxCode: taxSchema
    .when(['isBilling', 'country'],
      {
        is: (isBilling, country) => {
          return COUNTRIES_WITH_REQUIRED_FISCAL_DATA.includes(userCountry) && isBilling 
        },
        then: taxSchema.when('vatNumber', {
          is: (vatNumber) => _.isEmpty(vatNumber),
          then: taxSchema.required(getErrorMsgbyPath('required')),
        }),
      },
    ),
  vatNumber: vatSchema
    .when(['isBilling', 'country'],
      {
        is: (isBilling, country) => {
          return COUNTRIES_WITH_REQUIRED_FISCAL_DATA.includes(userCountry) && isBilling 
        },
        then: vatSchema.when('taxCode', {
          is: (taxCode) => _.isEmpty(taxCode),
          then: vatSchema.required(getErrorMsgbyPath('required')),
        }),
      },
    ),
  address1: Yup
    .string()
    .isValidAddress(() => getErrorMsg('address1'))
    .required(() => i18n.t('errors.required')),
  address2: Yup
    .string()
    .isValidAddress(() => getErrorMsg('address2'))
    .trim(),
  city: Yup
    .string()
    .required(() => i18n.t('errors.required')),
  region: Yup
    .string()
    .required(() => i18n.t('errors.required')),
  zip: Yup
    .string()
    .isZip(() => getErrorMsg('zip'))
    .required(() => i18n.t('errors.required')),
  country: Yup
    .string()
    .isValidName(() => getErrorMsg('country'))
    .required(() => i18n.t('errors.required')),
  email: Yup.string().email(() => i18n.t('errors.email.invalid')),
  phone: Yup.string().matches(/[0-9+\s-()]/, () => i18n.t('errors.phone.invalid')).required(() => i18n.t('errors.required')),
})


export const EditProfileSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  username: Yup
    .string()
    .matches(/^(?!foodbarrio).*$/i, getErrorMsgbyPath('taken'))
    .matches(/^[a-z0-9._]+/i, getErrorMsgbyPath('invalid'))
    .matches(/^[^.]/, getErrorMsgbyPath('noStartDot'))
    .required(getErrorMsgbyPath('required')),
  phone: Yup
    .string()
    .matches(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im, getErrorMsgbyPath('invalid'))
    .required(getErrorMsgbyPath('required')),
  email: Yup
    .string()
    .email(getErrorMsgbyPath('invalid'))
    .required(getErrorMsgbyPath('required')),
  bio: Yup
    .string()
    // .required(getErrorMsgbyPath('required')),
})

export const CouponSchema = Yup.object().shape({
  name: Yup
    .string()
    .required(getCouponErrorMsg('required')),
  percent: Yup
    .number()
    .required(getCouponErrorMsg('required'))
    .positive(getCouponErrorMsg('positive'))
    .max(100, getCouponErrorMsg('max')),
  checkoutAmountLimit: Yup
    .number()
    .required(getCouponErrorMsg('required'))
    .positive(getCouponErrorMsg('positive')),
  redemptionLimitPerUser: Yup
    .number()
    .required(getCouponErrorMsg('required'))
    .positive(getCouponErrorMsg('positive'))
    .integer(getCouponErrorMsg('integer')),
  redemptionLimitPerCoupon: Yup
    .number()
    .required(getCouponErrorMsg('required'))
    .positive(getCouponErrorMsg('positive'))
    .integer(getCouponErrorMsg('integer')),
  description: Yup
    .string()
    .required(getCouponErrorMsg('required'))
})
