import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import colors from '../../config/colors'
import NamedImage from '../../components/simple/NamedImage'

const BORDER_WIDTH = 2

class ProfileImage extends React.Component {
  render() {
    const {
      uri,
      online,
      style,
      size = 52,
      name,
    } = this.props

    const container = {
      width: size,
      height: size,
    }
    const containerOnline = {
      width: size + BORDER_WIDTH * 2,
      height: size + BORDER_WIDTH * 2,
      marginLeft: -BORDER_WIDTH,
      marginRight: -BORDER_WIDTH,
    }
    const image = {
      borderRadius: size / 2 + (online ? BORDER_WIDTH : 0),
      flex: 1,
    }
    // console.log({container, containerOnline, image, online})

    return (
      <TouchableOpacity
        style={[container, online && containerOnline, style]}
        onPress={this.props.onPress}
      >
        <NamedImage
          style={[image, online && styles.online, { width: size, height: size, borderRadius: size / 2}]}
          name={name}
          placeholder='profile'
          resizeMode='cover'
          width={size}
          height={size}
          fit='crop'
          crop='faces'
          resizeMethod='scale'
        />
      </TouchableOpacity>
    )
  }
}

export default ProfileImage

const styles = StyleSheet.create({
  container: {
    width: 52,
    height: 52,
  },
  online: {
    borderWidth: BORDER_WIDTH,
    borderColor: colors.active,
    shadowColor: colors.active,
    shadowOpacity: 0.3,
    shadowRadius: 6,
  },
})
