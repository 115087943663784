import React from 'react'
import { Text, View, Image, Dimensions, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'

import NavigationActions from '../../utility/navigationActions'
import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'
import _ from 'lodash'
import moment from 'moment'
import Icons from '@expo/vector-icons/Ionicons'
import NamedImage from '../../components/simple/NamedImage'

import {
  connect,
  graphql,
  withApollo,
  compose,
  gql,
  _get,
} from '../../config/connected'
import { resolveVideoThumbnail } from '../../config/helpers'

// import Attachment from './Attachment'
// import Activity from './Activity'
// import Comments from './Comments'
// import Interactions from './Interactions'
// import PostCommenting from './PostCommenting'

const screen = Dimensions.get('screen')

class ShopProfileBanner extends React.Component {
  static propTypes = {
    profileSize: PropTypes.number,
    shop: PropTypes.shape({
      shopName: PropTypes.string,
      profileImage: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    profileSize: 60,
  }

  render() {
    const { shop, profileSize, goToShop, style } = this.props

    const address = _get(shop, 'address', {
      address1: '',
      city: '',
      region: '',
      country: '',
      zip: '',
    })
    const coverPhoto = _get(shop, 'coverPhoto', null)
    const isVideoCover = ['.mp4', '.mov'].some(indicator => _.invoke(coverPhoto, 'includes', indicator))

    return (
      <TouchableOpacity
        style={[styles.container, style]}
        onPress={() => goToShop(shop)}
      >
        <NamedImage
          style={styles.profileImage}
          name={isVideoCover ? resolveVideoThumbnail(coverPhoto) : coverPhoto}
          width={44}
          height={44}
          fit='crop'
          crop='entropy'
          resizeMode='cover'
          resizeMethod='scale'
        />
        <View style={styles.textContainer}>
          <Text style={styles.shopName}>{_get(shop, 'displayName', '')}</Text>
          <Text style={styles.location}>{`${address.city}, ${
            address.region
          }`}</Text>
        </View>
        {/* <TouchableOpacity style={styles.more}>
          <Icons name='md-more' size={22} color={colors.black} />
        </TouchableOpacity> */}
      </TouchableOpacity>
    )
  }
}

const styles = stylus({
  container: {
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  profileImage: {
    width: 44,
    height: 44,
    borderRadius: 22,
  },
  textContainer: {
    flex: 1,
    paddingLeft: 10,
  },
  shopName: {
    fontWeight: '600',
    fontSize: 14,
  },
  location: {
    color: colors.text.secondary,
    fontSize: 13,
  },
  more: {
    paddingHorizontal: 10,
  },
})

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({
  goToShop: ({ id, displayName }) => {
    NavigationActions.navigate({ routeName: 'Shop', params: { id, pageTitle: displayName } })
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ShopProfileBanner)
