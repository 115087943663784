import React from 'react'
import {
  Text,
  View,
  Animated,
  TouchableOpacity,
  ScrollView,
} from 'react-native'
import PropTypes from 'prop-types'

import shared, { stylus } from '../../config/styles'
import colors from '../../config/colors'
import {
  connect,
  graphql,
  withApollo,
  compose,
  gql,
} from '../../config/connected'
import _ from 'lodash'

import Tab from './Tab'

const debug = false

class TabView extends React.Component {
  state = {
    activeTab:
      (this.props.children.length &&
        _.first(this.props.children).props.label) ||
      this.props.children.props.label,
    length: this.props.children.length,
    indicatorPosition: new Animated.Value(0),
    indicatorWidth: new Animated.Value(0),
    layout: [],
    containerWidth: 0,
    containterWidthSet: false,
    visibleTabs: [0],
    viewingInitialTab: false
  }

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
    size: PropTypes.number,
    contentTop: PropTypes.bool,
    titleStyle: PropTypes.object,
    onTabPress: PropTypes.func,
    initialTab: PropTypes.string,
    getCurrentTab: PropTypes.func,
  }

  componentDidUpdate(prevProps, prevState) {
    // Need to animate to the correct initial tab if it's different
    if (this.state.viewingInitialTab && (prevProps.initialTab !== this.props.initialTab || prevProps.time !== this.props.time)) {
      this.setState({ viewingInitialTab: false })
    }
    if (this.state.scrollContainerWidthSet && this.props.initialTab && !this.state.viewingInitialTab && Object.keys(this.state.layout || {}).length === this.props.children.length) {
      debug && console.log('<<<GO TO INITIAL TAB')
      this.setState({ viewingInitialTab: true })
      const firstTab = _.first(this.props.children).props.label
      const { initialTab } = this.props
      if (firstTab !== initialTab) {
        debug && console.log('<<<HANDLE TAB PRESS')
        debug && console.log('<<<INITIAL TAB', initialTab)
        this.handleTabPress(null, initialTab)
      }
    }
  }

  setActiveTab = (label) => {
    this.props.getCurrentTab && this.props.getCurrentTab(label)
    this.setState({ activeTab: label })
  }

  setContainerWidth = (e) => {
    const containerWidth = e.nativeEvent.layout.width
    this.setState({ containerWidth })
  }

  setScrollContainerWidth = (width, height) => {
    debug && console.log('<<<TAB VIEW SCROLL CONTAINER CONTENT WIDTH', width)
    if (!this.state.containerWidth) {
      return
    }
    if (Math.floor(width) === Math.floor(this.props.children.length * this.state.containerWidth)) {
      debug && console.log('SCROLL CONTAINTER RENDERED')
      this.setState({ scrollContainerWidthSet: true })
    }
  }

  renderLabels = () => {
    const { children, size, titleStyle } = this.props
    const { activeTab } = this.state
    const textTab = { fontSize: size }
    let labels = []

    React.Children.forEach(children, (child, idx) => {
      labels.push(
        <TouchableOpacity
          key={'opacity' + idx}
          onLayout={(e) => this.updateLayout(e, child.props.label, idx)}
          onPress={(e) => {
            this.handleTabPress(e, child.props.label)
          }}
        >
          <Text
            key={idx}
            style={[
              styles.textTab,
              textTab,
              titleStyle,
              activeTab === child.props.label && styles.textTabActive,
            ]}
          >
            {child.props.label}
          </Text>
        </TouchableOpacity>,
      )
    })

    return labels
  }

  updateLayout = (e, label, idx) => {
    const offset = 20
    let { layout } = this.state

    layout[label] = {
      x: e.nativeEvent.layout.x - offset / 2,
      width: e.nativeEvent.layout.width + offset,
      index: idx,
    }
    this.setState({ layout }, this.setIndicatorPosition)
  }

  /**
   * Handles pressing a tab and switching to the pressed view. Uses the label of the tab selected to access
   *
   * this.state.layout.LABELHERE.index
   *
   * Which is saved when the items are layed out. This is done via an object due to onLayout callback
   * firing off asyncronously making it impossible, or at least hard, to use an ordered array to track
   * which index referes to which tab in the ScrollView containing all of the tabs.
   */
  handleTabPress = (e, label) => {
    const { onTabPress } = this.props
    const targetIndex = this.state.layout[label].index,
      { containerWidth } = this.state
    this.setIndicatorPosition(label)
    onTabPress && onTabPress(label)

    debug && console.log('<<<TARGET x CONTAINER WIDTH', targetIndex, containerWidth)

    this.ScrollContainer.scrollTo({
      x: targetIndex * containerWidth,
      y: 0,
      animated: true,
    })
    this.onScrollEndCallbackTargetOffset = targetIndex * containerWidth;
  }

  setIndicatorPosition = (override) => {
    const { activeTab, layout, length } = this.state
    const DURATION = 150

    // If we're still building our reference of tabs, dont bother updating
    if (Object.keys(layout).length !== length) {
      return
    }

    // override allows us to target a specific tab to skip to instead of having to infer which tab
    // is active based on the scrollview's position.
    if (override) {
      this.setActiveTab(override)
      Animated.timing(this.state.indicatorPosition, {
        toValue: layout[override].x,
        duration: DURATION,
      }).start()

      Animated.timing(this.state.indicatorWidth, {
        toValue: layout[override].width,
        duration: DURATION,
      }).start()

      return
    }

    Animated.timing(this.state.indicatorPosition, {
      toValue: layout[activeTab].x,
      duration: DURATION,
    }).start()

    Animated.timing(this.state.indicatorWidth, {
      toValue: layout[activeTab].width,
      duration: DURATION,
    }).start()
  }

  getDistances = (x, tabs) => {
    const { containerWidth } = this.state
    let positions = []

    // Create array of absolute position of each image based on 100% of the screen width
    for (let i = 0; i < tabs.length; i++) {
      positions.push(i * containerWidth)
    }

    // create an array of the distance to each of the previously created checkpoints based
    // on the current scroll distance(x).
    let distances = []
    _.forEach(positions, (position) => {
      distances.push(Math.abs(Math.abs(position) - x))
    })

    return distances
  }

  getClosestTab = (x) => {
    const { children } = this.props

    // get an array of the distance between x and the nearest child in the scrollview.
    let distances = this.getDistances(x, children)

    // Find which point is closest to the current position
    let index = _.findIndex(distances, (item) => {
      return item === _.min(distances)
    })

    return index
  }

  getClosestTwoTabs = (x) => {
    const { children } = this.props
    let distances = this.getDistances(x, children),
      indices = []

    // find closest index to the current x position, save it.
    let a = _.findIndex(distances, (item) => {
      return item === _.min(distances)
    })
    indices.push(a)

    // pull that number out of the array, but keep it's place in the array in use as to not
    // shuffle the indices of the array.
    distances[a] = NaN

    // find the closest index to x, without using the first index.
    let b = _.findIndex(distances, (item) => {
      return item === _.min(distances)
    })
    indices.push(b)

    return indices
  }

  handleScrollEnd = (e) => {
    const { children, onAnimate } = this.props

    let index = this.getClosestTab(e.nativeEvent.contentOffset.x)

    // Return which index we're closest to. Including the "current" tab
    this.setIndicatorPosition(children[index].props.label)
    onAnimate && onAnimate()
    this.setState({ visibleTabs: [index] })
  }

  handleScroll = (e) => {
    const { onAnimate, onScroll } = this.props
    onScroll && onScroll(e)
    const x = e.nativeEvent.contentOffset.x,
    { visibleTabs } = this.state
    if (x === this.onScrollEndCallbackTargetOffset) { // check if end of scrollTo call
      let index = this.getClosestTab(e.nativeEvent.contentOffset.x)
      this.setState({ visibleTabs: [index] })
    } else {
      let newVisibleTabs = this.getClosestTwoTabs(x)
      if (!_.isEqual(visibleTabs, newVisibleTabs)) {
        onAnimate && onAnimate()
        this.setState({ visibleTabs: newVisibleTabs })
      }
    }
  }

  getDisplayType = (label) => {
    const { visibleTabs, layout, length } = this.state

    if (layout.length !== length) return

    if (visibleTabs.includes(layout[label].index)) {
      return 'flex'
    } else {
      return 'none'
    }
  }

  renderContent = () => {
    const { containerWidth } = this.state
    const { children, screenInfo } = this.props
    return (
      <ScrollView
        ref={(ScrollContainer) => {
          this.ScrollContainer = ScrollContainer
        }}
        scrollEventThrottle={30}
        onMomentumScrollEnd={this.handleScrollEnd}
        onScroll={this.handleScroll}
        pagingEnabled
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={[styles.tabContainer]}
        onContentSizeChange={this.setScrollContainerWidth}
      >
        {React.Children.map(children, (child) => {
          const childLabel = child.props.label
          const { visibleTabs, layout, length } = this.state
          if (
            Object.keys(layout).length === length &&
            !visibleTabs.includes(layout[childLabel].index)
          ) {
            return React.cloneElement(child, {
              containerWidth,
              style: {
                ...child.props.style,
                height: 1,
              },
            })
          }

          return React.cloneElement(child, {
            containerWidth,
            style: {
              ...child.props.style,
            },
          })
        })}
      </ScrollView>
    )
  }

  render() {
    const {
      indicatorPosition,
      indicatorWidth,
      containerWidth,
      length,
    } = this.state
    const {
      screenInfo,
      children,
      contentTop,
      containerStyle,
      titleContainerStyle,
    } = this.props
    return (
      <View
        style={[styles.container, containerStyle]}
        onLayout={this.setContainerWidth}
      >
        {contentTop && this.renderContent()}
        <View style={[styles.labelsContainer, titleContainerStyle]}>
          {this.renderLabels()}
        </View>
        <View>
          <Animated.View
            style={[
              styles.tabIndicator,
              {
                width: indicatorWidth,
                top: 1,
                marginTop: -3,
                left: indicatorPosition,
              },
            ]}
          />
        </View>
        {this.props.persistent && this.props.persistent}
        {!contentTop && this.renderContent()}
      </View>
    )
  }
}

TabView.Tab = Tab

const styles = stylus({
  container: {
    flex: 1,
  },
  labelsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  textTab: {
    paddingVertical: 7,
  },
  textTabActive: {
    color: colors.tabView.activeText,
    fontWeight: 'bold',
  },
  divider: {
    flex: 1,
    height: 1,
    backgroundColor: colors.inactive,
  },
  tabIndicator: {
    height: 3,
    backgroundColor: colors.tabView.tabLabelBackground,
  },
  tabContainer: {
    flexDirection: 'row',
  },
})

const mapStateToProps = (state) => ({
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { withRef: true },
  ),
)(TabView)
