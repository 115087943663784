import { PropTypes } from 'prop-types'
import React, { Component } from 'react'
import {
  Animated,
  Dimensions,
  Keyboard,
  StyleSheet,
  TextInput,
  UIManager,
  Platform,
} from 'react-native'
import { stylus } from '../config/styles'
const { State: TextInputState } = TextInput

export default class KeyboardShift extends Component {
  state = {
    shift: new Animated.Value(0),
  }
  static defaultProps = {
    shouldShift: true,
    extraShiftValue: 0,
  }
  shiftValue = 0

  UNSAFE_componentWillMount() {
    this.keyboardDidShowSub = Keyboard.addListener(
      'keyboardDidShow',
      this.handleKeyboardDidShow,
    )
    if (Platform.OS === 'android') {
      this.keyboardDidHideSub = Keyboard.addListener(
        'keyboardDidHide',
        this.handleKeyboardDidHide,
      )
    } else {
      this.keyboardDidHideSub = Keyboard.addListener(
        'keyboardWillHide',
        this.handleKeyboardDidHide,
      )
    }
  }

  componentWillUnmount() {
    this.keyboardDidShowSub.remove()
    this.keyboardDidHideSub.remove()
  }

  render() {
    const { children, style, fluid } = this.props
    const { shift, shiftValue } = this.state
    return (
      <Animated.View
        style={[
          fluid ? styles.fluid : styles.container,
          style,
          { transform: [{ translateY: shift }] },
        ]}
      >
        {children}
      </Animated.View>
    )
  }

  handleKeyboardDidShow = (event) => {
    if (Platform.OS === 'web') {
      return
    }
    // console.log('<<<Keyboard Did Show')
    this.keyboardHeight = event.endCoordinates.height
    this.adjust()
    this.interval = setInterval(this.adjust, 250)
  }

  adjust = () => {
    const { shouldShift, extraShiftValue } = this.props
    if (!shouldShift) {
      // console.log('<<<Should not shift')
      return
    }
    const currentlyFocusedField = TextInputState.currentlyFocusedField()
    if (currentlyFocusedField === this.currentlyFocusedField) {
      // console.log('<<<Should not shift same field')
      return
    }
    if (!currentlyFocusedField) {
      // console.log('<<<Should not shift no focused field')
      return
    }
    //console.log('<<<KEYBOARD SHIFT - Shifting Content')
    this.currentlyFocusedField = currentlyFocusedField
    const { height: windowHeight } = Dimensions.get('window')
    UIManager.measure(
      currentlyFocusedField,
      (originX, originY, width, height, pageX, pageY) => {
        const fieldHeight = height
        const fieldTop = pageY
        const gap =
          windowHeight - this.keyboardHeight - (fieldTop + fieldHeight) - extraShiftValue
        // console.log(
        //   'Window Height', windowHeight,
        //   '\nKeyboard Height', this.keyboardHeight,
        //   '\nField Top', fieldTop,
        //   '\nField Height', fieldHeight,
        //   '\nGap', gap
        // )  
        if (gap >= -30) {
          this.shiftValue = Math.min(gap + this.shiftValue - 12, 0)
          Animated.timing(this.state.shift, {
            toValue: this.shiftValue,
            duration: 250,
            useNativeDriver: true,
          }).start()
          return
        }
        this.shiftValue = gap + this.shiftValue
        // this.shiftValue = 0
        Animated.timing(this.state.shift, {
          toValue: this.shiftValue,
          duration: 200,
          useNativeDriver: true,
        }).start()
      },
    )
  }

  handleKeyboardDidHide = () => {
    clearInterval(this.interval)
    this.currentlyFocusedField = null
    Animated.timing(this.state.shift, {
      toValue: 0,
      duration: 180,
      useNativeDriver: true,
    }).start()
    this.shiftValue = 0
  }
}

const styles = stylus({
  container: {
    native: {
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
  fluid: {},
})
