import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import Scroller from '../../components/Scroller'
import PageTitle from '../../components/simple/PageTitle'
import Action from '../../components/simple/Action'
import ProfileImage from '../../components/Post/ProfileImage'
import ListItem from '../../components/simple/ListItem'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import {
  connect,
  graphql,
  withApollo,
  compose,
  gql,
} from '../../config/connected'
import MobileBackButton from '../../components/simple/MobileBackButton'
import NavigationActions from '../../utility/navigationActions'
import BigStatistic from '../../components/simple/BigStatistic'
import Statistic from '../../components/simple/Statistic'
import Metric from '../../components/simple/Metric'
import format from '../../config/format'
import Linking from '../../config/nativeLink'
import T from '../../components/T'
import i18n from 'i18n-js'

import info from '../../config/environment'

class Support extends React.Component {
  render() {
    return (
      <View style={styles.wrapper}>
        <Scroller>{this.content()}</Scroller>
      </View>
    )
  }
  content() {
    return (
      <View style={styles.container}>
        <T
          t='settings.content.support.body'
          style={[styles.p, { marginTop: 30 }]}
        />
        <Metric label={i18n.t('settings.content.support.email.title')}>
          <Text
            accessibilityRole='link'
            href={`mailto:${info.companySupportEmail}`}
            style={styles.link}
            onPress={() =>
              Linking.openURL(`mailto:${info.companySupportEmail}`)
            }
          >
            {info.companySupportEmail}
          </Text>
        </Metric>
      </View>
    )
  }
}

Support.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('settings.content.support.title'),
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

export default Support

const styles = stylus({
  wrapper: {
    flex: 1,
  },
  container: {
    flex: 1,
    paddingHorizontal: 24,
    marginBottom: 24,
    portrait: {
      paddingHorizontal: 20,
    },
  },
  title: {
    fontSize: 17,
    color: colors.text.main,
    fontWeight: 'bold',
    paddingVertical: 8,
    portrait: {
      marginTop: 8,
    },
  },
  p: {
    paddingVertical: 8,
    fontSize: 15,
    color: colors.text.content,
    fontWeight: 'normal',
    maxWidth: 600,
  },
  link: {
    fontSize: 17,
    color: colors.primary,
  },
})
