import Icons from '@expo/vector-icons/Ionicons'
import i18n from 'i18n-js'
import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'
import SendButton from '../../components/simple/SendButton'
import TextArea from '../../components/TextArea'
import branch from '../../config/branch'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'
import { removeUploadsFromCache } from '../../config/helpers'
import NamedImage from '../simple/NamedImage'
import AddButton from './AddButton'


import produce from 'immer'


class ChatInput extends React.Component {
  static defaultProps = {
    defaultValue: ''
  }
  constructor(props) {
    super(props)
    this.state = {
      focused: false,
      imageFile: null,
      with: null,
      location: null,
      value: props.defaultValue
    }
  }

  componentDidMount = () => {
    if (this.props.onBack) {
      this.props.onBack(this.onBack)
    }
  }

  onBack = () => {
    if (this.state.imageFile) {
      removeUploadsFromCache([this.state.imageFile])
    }
  }

  render() {
    const { style, disabled } = this.props
    if (disabled) {
      return null
    }
    const sendDisabled =
      this.state.value.length === 0 && this.state.imageFile === null

    return (
      <View style={[styles.container, style]}>
        <AddButton onChange={this.setImageFile} />
        {this.input()}
        <SendButton
          disabled={sendDisabled}
          onPress={this.send}
          style={styles.send}
        />
      </View>
    )
    // <AddEmoji />
  }
  input() {
    const { imageFile } = this.state
    const { screenInfo } = this.props

    if (imageFile) {
      const { width, height, uri } = imageFile
      const maxHeight = 270
      let scaledWidth = screenInfo.contentWidth * 0.65
      let scaledHeight = (scaledWidth / width) * height
      if (scaledHeight > maxHeight) {
        const ratio = maxHeight / scaledHeight
        scaledWidth *= ratio
        scaledHeight *= ratio
      }
      return (
        <View style={styles.imageFileWrapper}>
          <View>
            <NamedImage
              style={{
                width: scaledWidth,
                height: scaledHeight,
              }}
              width={scaledWidth}
              height={scaledHeight}
              uri={uri}
              crop='entropy'
              resizeMethod='scale'
              resizeMode='contain'
              asBackground
            >
              <TouchableOpacity
                style={styles.imageFileCancelPlacement}
                onPress={this.reset}
              >
                <Icons
                  name='md-close-circle'
                  size={30}
                  color={colors.icon.strong}
                  style={styles.cancelIcon}
                />
              </TouchableOpacity>
            </NamedImage>
          </View>
        </View>
      )
    }
    return (
      <View style={styles.inputWrapper}>
        <TextArea
          placeholder={i18n.t('common.chatInputPlaceHolder')}
          returnKeyType={branch({ android: 'none', ios: 'default' })}
          onChangeText={this.onChangeText}
          nativeStyle={styles.nativeInputStyle}
          webStyle={styles.webInputStyle}
          minHeight={34}
          value={this.state.value}
          ref={(ref) => (this._input = ref)}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
        />
      </View>
    )
  }
  causeBlur() {
    this._input.blur()
  }
  onFocus = () => {
    // this.animate()
    this.setState({
      focused: true,
    })
  }
  onBlur = () => {
    // this.animate()
    this.setState({
      focused: false,
    })
  }
  setImageFile = (file) => {
    const nextState = produce(this.state, (draft) => {
      draft.imageFile = file
    })
    this.props.queueUpload(file)
    this.setState(nextState)
  }
  addCheckIn() {}
  addMentions() {}
  send = () => {
    const { value, imageFile } = this.state
    const { send } = this.props
    send({
      text: value,
      imageFile,
    })
    this.reset(false)
  }
  reset = (removeUpload = true) => {
    if (this._input) {
      this._input.clear()
    }
    const uploadToRemove = this.state.imageFile ? { ...this.state.imageFile } : null
    this.setState({
      imageFile: null,
      with: null,
      location: null,
      value: '',
    }, () => {
      if (removeUpload && uploadToRemove) {
        removeUploadsFromCache([uploadToRemove])
      }
    })
  }
  onChangeText = (value) => {
    this.setState({
      value,
    })
  }
}

const mapStateToProps = (state) => ({
  layout: state.screenInfo.layout,
  platform: state.screenInfo.platform,
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({
  queueUpload: (file) => {
    dispatch({
      type: 'Upload/ADD',
      file,
    })
  },
  removeUpload: (file) => {
    dispatch({
      type:  'Upload/REMOVE',
      file,
    })
  },
  dispatch,
  // onResize: () => dispatch({ type: 'SCREEN_RESIZE' }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChatInput)

const styles = stylus({
  container: {
    // borderTopColor: '#F0F0F0',
    borderTopColor: colors.thinLine,
    borderTopWidth: sizes.px,
    paddingHorizontal: 0,
    flexDirection: 'row',
    alignItems: 'flex-end',
    // backgroundColor: '#FAFAFA',
    // backgroundColor: 'orange',
    paddingVertical: 1,
    landscape: {
      paddingVertical: 12,
    },
    tablet: {
      // overflow: 'hidden',
      // ...shared.card,
      // borderBottomLeftRadius: 0,
      // borderBottomRightRadius: 0,
    },
    iphonex: {
      paddingBottom: 26,
      paddingTop: 3,
    },
  },
  inputWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    // paddingVertical: 5,
    // backgroundColor: 'orange',
    // ios: {
    //   borderRadius: 19,
    // },
  },
  imageFileWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingVertical: 12,
  },
  imageFileCancelPlacement: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  cancelIcon: {
    textShadowColor: '#FFF',
    textShadowRadius: 3,
    textShadowOffset: { width: 0, height: 0.5 },
    paddingHorizontal: 6,
    paddingVertical: 4,
  },
  nativeInputStyle: {
    flex: 1,
    paddingRight: 11,
    color: colors.input.text,
    ios: {
      paddingTop: 6,
      paddingBottom: 6,
    },
    android: {
      paddingTop: 0,
      paddingBottom: 4,
    },
    marginTop: 0,
    fontSize: 15,
    // backgroundColor: 'orange',
  },
  webInputStyle: {
    flex: 1,
    background: 'transparent',
    padding: '9px 8px 15px 6px',
    color: colors.input.text,
  },
  send: {
    marginRight: 10,
    marginBottom: 7,
    ios: {
      marginBottom: 6.5,
    },
  },
})
