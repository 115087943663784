import React from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  Platform,
  View,
  TouchableOpacity,
} from 'react-native'

import Scroller from '../../components/Scroller'
import PageTitle from '../../components/simple/PageTitle'
import Action from '../../components/simple/Action'
import ProfileImage from '../../components/Post/ProfileImage'
import ListItem from '../../components/simple/ListItem'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import Icons from '@expo/vector-icons/Ionicons'
import Ago from '../simple/Ago'
import Image from '../Image'
import shared, { stylus } from '../../config/styles'
import _ from 'lodash'
import MessageImage from './MessageImage'
import debounce from 'debounce'
import UserTitle from '../UserTitle/UserTitle'
import MoreOptions from '../simple/MoreOptions'
import i18n from 'i18n-js'

class Message extends React.Component {
  constructor(props) {
    super(props)
    this.state = { messageContentWidth: 0, contentWidthSet: false }
  }
  render() {
    const { message, onPress, landscape, currentUser } = this.props

    const online = false
    const title = _.get(message, 'user.title')
    const organization = _.get(message, 'user.organization')
    const organizationId = _.get(message, 'user.organizationId')
    const moreOptions = []
    const isNotMessageSender = _.get(message, 'user.id') !== _.get(currentUser, 'id')
    if (isNotMessageSender) {
      moreOptions.push({ label: i18n.t('common.report'), action: this.props.goToReportContent })
    }
    return (
      <View style={styles.container}>
        <ProfileImage
          name={_.get(message, 'user.profileImage')}
          online={online}
          style={styles.profileImage}
          size={landscape ? 42 : 48}
          onPress={this.goToProfile}
        />
        <View style={styles.content} onLayout={this.onContentLayoutChange}>
          <View style={styles.contentTop}>
            <TouchableOpacity onPress={this.goToProfile}>
              <Text style={styles.name} numberOfLines={1}>
                {_.get(message, 'user.displayName', 'Unknown')}
              </Text>
            </TouchableOpacity>
            <View style={styles.when} numberOfLines={1}>
              <Ago style={styles.whenText} time={message.createdAt} />
            </View>
            { _.get(currentUser, 'isAdmin') ? 
              <MoreOptions
                content={message}
                options={moreOptions}
              />
              : null }
            
          </View>
          <UserTitle title={title} organization={organization} organizationId={organizationId} size={14} />
          {this.messageText()}
          {this.image()}
        </View>
      </View>
    )
  }

  messageText() {
    const { message } = this.props
    if (!message.text) {
      return null
    }
    return (
      <Text style={[styles.message]}>
        {message.text}
      </Text>
    )
  }

  hasImage() {
    return this.props.message.upload !== null
  }

  image() {
    if (!this.hasImage()) {
      return
    }
    const {
      message: { upload },
      pending,
    } = this.props
    return <MessageImage image={upload} pending={pending} />
  }

  goToProfile = () => {
    const user = this.props.message.user
    this.props.goToProfile({
      id: user.id,
      pageTitle: user.username,
    })
  }
}

export default Message

const styles = stylus({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    paddingLeft: 14,
    landscape: {
      paddingLeft: 18,
    },
  },
  profileImage: {
    marginTop: 14,
  },
  content: {
    marginLeft: 14,
    flex: 1,
    // alignItems: 'flex-start',
    // justifyContent: 'center',
    borderTopColor: colors.thinLineSoft,
    paddingVertical: 18,
    borderTopWidth: sizes.px,
    paddingRight: 14,
    landscape: {
      marginLeft: 18,
    },
  },
  contentTop: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    fontSize: 16,
    lineHeight: 21,
    color: colors.text.main,
    fontWeight: '500',
  },
  message: {
    fontSize: 15,
    color: colors.text.soft,
    fontWeight: '400',
    paddingRight: 10,
  },
  when: {
    // alignItems: 'flex-end',
    // alignSelf: 'stretch',
    flex: 1,
    marginLeft: 10,
  },
  whenText: {
    fontSize: 13,
    paddingTop: 1,
    // lineHeight: 18,
    color: colors.text.secondary,
    fontWeight: '300',
  },
})
