import React from 'react'
import { Text, View, Image, Dimensions, TouchableOpacity } from 'react-native'

import PropTypes from 'prop-types'
import NavigationActions from '../../utility/navigationActions'
import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'
import { connect } from 'react-redux'
import _ from 'lodash'
import Icons from '@expo/vector-icons/Ionicons'
import NamedImage from '../../components/simple/NamedImage'
import Ratings from '../simple/Ratings'
import Price from '../simple/Price'
import { _get } from '../../config/connected'
import { getPrice, getFeatureFlag, unitPlural } from '../../config/helpers'
import i18n from 'i18n-js'

@connect(
  () => ({}),
  (dispatch) => ({
    goToProduct: (id) => NavigationActions.navigate({ routeName: 'Product', params: { id } }),
  }),
)
class ProductCarouselItemVertical extends React.Component {
  static propTypes = {
    containerStyle: PropTypes.object,
    item: PropTypes.object.isRequired,
    imageSize: PropTypes.number.isRequired,
  }

  static defaultProps = {
    containerStyle: {},
    showPrice: true,
    showShop: true,
    showRatings: true,
    textStyles: {
      title: {},
      shop: {},
      price: {},
    },
    imageSize: 75,
  }

  renderPrice = () => {
    const {item} = this.props
    const {price, priceDiscount, isInStock = false, isDiscontinued} = item

    if (isDiscontinued || !isInStock) {
      return (
        <Text style={[styles.outOfStock]}>
          {i18n.t('product.notAvailable')}
        </Text>
      )
    }

    let hasPriceDiscount = !!priceDiscount && priceDiscount < price
    return (
      <Price
        hasPriceDiscount={hasPriceDiscount}
        price={price}
        priceDiscount={priceDiscount}
      />
    )
  }

  render() {
    const {
      item,
      goToProduct,
      imageSize,
      showPrice,
      showShop,
      showRatings,
      textStyles,
      imageStyles,
      hasBorder,
      containerStyle
    } = this.props

    const sizeReduction = hasBorder ? 10 : 0
    const size = imageSize - sizeReduction
    const {price, priceDiscount, rating, ratingCount } = item
    let hasPriceDiscount = !!priceDiscount && priceDiscount < price
    const coverPhoto = _.find(item.uploads, i => i.mime !== 'mp4' && i.mime !== 'mov')
    const unitProduct = unitPlural(item)
    const shopName = _get(item, 'profile.displayName', '')

    let score
    if (getFeatureFlag('scores')) {
      score = _get(item, 'score') 
    }
    return (
      <TouchableOpacity
        onPress={() => goToProduct(item.id)}
        style={[styles.container, { width: imageSize }, containerStyle]}
      >
        <View style={[styles.imageContainer, imageStyles]}>
          <NamedImage
            name={coverPhoto ? coverPhoto.name : null}
            width={size}
            height={size}
            style={{
              width: size,
              height: size,
              borderRadius: 4 // hasBorder ? size / 2 : 1,
            }}
            fit='crop'
            resizeMethod='scale'
            resizeMode='cover'
          />
        </View>
        <View style={styles.content}>
          <Text
            style={[styles.title, textStyles.title]}
            numberOfLines={textStyles.titleLines || 1}
          >
            {score}{score !== undefined ? ' - ' : ''}{_get(item, 'name', '')}
          </Text>
          {!!unitProduct && (
            <Text style={styles.unitTitle}>
              {unitProduct}
            </Text>
          )}
          {showShop && !!shopName && (
            <Text style={[styles.shopName, textStyles.shop]} numberOfLines={1}>
              {shopName}
            </Text>
          )}
          {showRatings && !!ratingCount && !!rating && (
            <Ratings
              size={textStyles.ratingsSize}
              rating={rating}
              ratings={ratingCount}
            />
          )}
          {showPrice && this.renderPrice()}
        </View>
      </TouchableOpacity>
    )
  }
}

const styles = stylus({
  container: {
    marginLeft: 14,
  },
  imageContainer: {
    overflow: 'hidden',
    borderRadius: 4//9999,
  },
  content: {
    // flex: 1,
    // justifyContent: 'space-between',
    // paddingLeft: 6,
    paddingVertical: 10,
    paddingHorizontal: 4,
    // alignItems: 'center',
  },
  title: {
    fontWeight: '600',
    fontSize: 14,
    color: colors.text.main,
  },
  unitTitle: {
    fontSize: 13,
  },
  shopName: {
    fontSize: 13,
    color: colors.text.main,
  },
  bookmark: {
    position: 'absolute',
    backgroundColor: 'transparent',
  },
  ratingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  outOfStock: {
    color: colors.text.secondary,
  }
})

export default ProductCarouselItemVertical
