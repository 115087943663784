import colors from '../../../../config/colors'

export default function () {

  const css = /*css*/`
  .example.example5 {
    background-color: #fff;
  }
  
  .example.example5 * {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  
  .example.example5 form {
  }
  
  #example5-paymentRequest {
    max-width: 500px;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .example.example5 fieldset {
    border: 1px solid ${colors.lightGray};
    padding: 15px 10px;
    margin: 0 5px;
    border-radius: 6px;
  }
  
  .example.example5 fieldset legend {
    margin: 0 auto;
    padding: 0 10px;
    text-align: center;
    font-weight: 700;
    color: ${colors.text.main};
    background-color: #fff;
  }
  
  .example.example5 fieldset legend + * {
    clear: both;
  }
  
  .example.example5 .card-only {
    display: block;
  }
  .example.example5 .payment-request-available {
    display: none;
  }
  
  .example.example5 .row {
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 10px;
  }
  
  .example.example5 .field {
    position: relative;
    width: 100%;
  }
  
  .example.example5 .field + .field {
    margin-left: 10px;
  }
  
  .example.example5 label {
    width: 100%;
    color: ${colors.text.main};
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .example.example5 .input {
    width: 100%;
    color: ${colors.text.main};
    background: transparent;
    padding: 5px 0 6px 0;
    border-bottom: 1px solid ${colors.lightGray};
    transition: border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .example.example5 .input::-webkit-input-placeholder {
    color: ${colors.text.secondary};
  }
  
  .example.example5 .input::-moz-placeholder {
    color: ${colors.text.secondary};
  }
  
  .example.example5 .input:-ms-input-placeholder {
    color: ${colors.text.secondary};
  }
  
  .example.example5 .input.StripeElement--focus,
  .example.example5 .input:focus {
    border-color: ${colors.text.main};
  }
  .example.example5 .input.StripeElement--invalid {
    border-color: ${colors.error};
  }
  
  .example.example5 input:-webkit-autofill,
  .example.example5 select:-webkit-autofill {
    -webkit-text-fill-color: ${colors.primary};
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }
  
  .example.example5 .StripeElement--webkit-autofill {
    background: transparent !important;
  }
  
  .example.example5 input,
  .example.example5 button,
  .example.example5 select {
    -webkit-animation: 1ms void-animation-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
    border-radius: 0;
  }
  
  .example.example5 select.input,
  .example.example5 select:-webkit-autofill {
    background-image: url('data:image/svg+xml;utf8,<svg width="10px" height="5px" viewBox="0 0 10 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="#fff" d="M5.35355339,4.64644661 L9.14644661,0.853553391 L9.14644661,0.853553391 C9.34170876,0.658291245 9.34170876,0.341708755 9.14644661,0.146446609 C9.05267842,0.0526784202 8.92550146,-2.43597394e-17 8.79289322,0 L1.20710678,0 L1.20710678,0 C0.930964406,5.07265313e-17 0.707106781,0.223857625 0.707106781,0.5 C0.707106781,0.632608245 0.759785201,0.759785201 0.853553391,0.853553391 L4.64644661,4.64644661 L4.64644661,4.64644661 C4.84170876,4.84170876 5.15829124,4.84170876 5.35355339,4.64644661 Z" id="shape"></path></svg>');
    background-position: 100%;
    background-size: 10px 5px;
    background-repeat: no-repeat;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
  }
  
  .example.example5 button {
    display: block;
    width: 100%;
    height: 40px;
    margin: 20px 0 0;
    background-color: ${colors.text.soft};
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
  }
  
  .example.example5 button:active {
    opacity: 0.75;    
  }
  
  .example.example5 .error svg .base {
    fill: ${colors.error};
  }
  
  .example.example5 .error svg .glyph {
    fill: #fff;
  }
  
  .example.example5 .error .message {
    color: ${colors.error};
  }
  
  .example.example5 .success .icon .border {
    stroke: ${colors.primary};
  }
  
  .example.example5 .success .icon .checkmark {
    stroke: ${colors.text.main};
  }
  
  .example.example5 .success .title {
    color: ${colors.text.main};
  }
  
  .example.example5 .success .message {
    color: ${colors.text.main};
  }
  
  .example.example5 .success .reset path {
    fill: #fff;
  }
  `
  return `
    <style>
      ${css}
    </style>
  `
}
