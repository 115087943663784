import React from 'react'
import { PropTypes } from 'prop-types'
import {
  StyleSheet,
  TouchableOpacity,
} from 'react-native'

import Icons from '@expo/vector-icons/Ionicons'
import colors from '../../config/colors'
import withActionSheet from '../../containers/ActionSheet/withActionSheet'
import i18n from 'i18n-js'
import _ from 'lodash'
import { getFeatureFlag } from '../../config/helpers'

@withActionSheet
class MoreOptions extends React.Component {

  static propTypes = {
    showActionSheetWithOptions: PropTypes.func.isRequired,
    style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    size: PropTypes.number.isRequired,
    containerSizeOffset: PropTypes.number.isRequired,
    content: PropTypes.object,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      isCancelOption: PropTypes.bool,
    })).isRequired
  }

  static defaultProps = {
    size: 22,
    containerSizeOffset: 0
  }

  render() {
    const { options, size, containerSizeOffset } = this.props
    // NOTE: getFeatureFlag portion of condition is temporary until AZ620 and it's related sub tasks have been QA approved and moved into UAT
    // WARNING: Do not attempt to utilize MoreOptions for other content action use cases at this time till as mentioned previously AZ620 is merged into UAT
    if (!getFeatureFlag('reportContent') || options.length === 0 || (options.length === 1 && options[0].isCancelOption)) {
      return null // don't render anything if there's no option or the only option is cancel
    }
    const { style } = this.props
    return (
      <TouchableOpacity style={[styles.area, style, { width: size - containerSizeOffset, height: size - containerSizeOffset }]} onPress={this.showActionSheet}>
        <Icons name='md-more' size={size} color={colors.black} />
      </TouchableOpacity>
    )
  }

  showActionSheet = () => {
    const { content, options } = this.props
    this.openContentActionSheet(content, options)
  }

  openContentActionSheet(content, optionsSpec) {
    const options = optionsSpec.map((spec) => spec.label)
    let cancelButtonIndex = null
    optionsSpec.forEach((spec, index) => {
      if (spec.isCancelOption) {
        cancelButtonIndex = index
      }
    })
    if (cancelButtonIndex === null) {
      const label = this.getMessage('common.cancel')
      options.push(label)
      optionsSpec.push({ label, action: _.identity, isCancelOption: true })
      cancelButtonIndex= options.length - 1
    }
    this.props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        optionsSpec[buttonIndex].action(content)
      },
    )
  }

  getMessage(transPath) {
    return i18n.t(transPath)
  }
}

export default MoreOptions

const styles = StyleSheet.create({
  area: {
    // position: 'absolute',
    // right: 0,
    // top: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
