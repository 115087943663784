import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import T from '../../components/T'

export default ({ onPress, messageTransKey, linkTransKey}) => {
  return (
    <View style={styles.container}>
      <T
        style={styles.mainText}
        t={messageTransKey}
      />
      <TouchableOpacity onPress={onPress}>
        <T
          style={styles.link}
          t={linkTransKey}
        />
      </TouchableOpacity>
    </View>
  )
}

const styles = stylus({
  container: {
    bottom: 0,
    left: 0,
    right: 0,
    borderTopColor: colors.thinLine,
    borderTopWidth: sizes.px,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  mainText: {
    fontSize: 14,
    color: colors.text.main,
  },
  link: {
    marginLeft: 8,
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primary,
    textDecorationLine: 'underline',
  },
})
