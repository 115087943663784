import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import PropTypes from 'prop-types'
import _ from 'lodash'

import NavigationActions from '../../utility/navigationActions'
import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import { connect } from 'react-redux'
import NamedImage from '../../components/simple/NamedImage'
import Ratings from '../simple/Ratings'

import ZocialIcon from '@expo/vector-icons/Zocial'
import MDCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons'
import { resolveVideoThumbnail } from '../../config/helpers'

class ShopHeader extends React.Component {
  static propTypes = {
    height: PropTypes.number,
    style: PropTypes.object,
    imageWrapperStyle: PropTypes.object,
    nameStyle: PropTypes.object,
    locationStyle: PropTypes.object,
    detailsStyle: PropTypes.object,
    imageSize: PropTypes.number,
    address: PropTypes.object,
    paymentTypes: PropTypes.shape({
      cash: PropTypes.bool,
      transfer: PropTypes.bool,
      stripe: PropTypes.bool
    }),
    children: PropTypes.func,
    rating: PropTypes.number,
    ratings: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      profileImage: PropTypes.string,
    }).isRequired
  }

  static defaultProps = {
    imageSize: 44
  }

  goToShop = () => {
    this.props.goToShop(this.props.user)
  }

  paymentTypes(paymentTypes) {
    const icons = []
    for (const key in paymentTypes) {
      const hasPaymentType = paymentTypes[key]
      if (!hasPaymentType) {
        continue
      }
      switch(key) {
        case 'cash':
          icons.push(<MDCommunityIcon key={'cash'} style={styles.margin} name='cash-multiple' color={colors.black} size={16} />)
          break
        case 'transfer':
          icons.push(<MDCommunityIcon key={'transfer'} style={styles.margin} name='bank' color={colors.black} size={16} />)
          break
        case 'stripe':
          icons.push(<ZocialIcon key={'stripe'} style={styles.margin} name='stripe' color={colors.black} size={16} />)
          break
      }
    }
    return <View style={styles.iconSet}>{icons}</View>
  }


  render() {
    const { height, address, paymentTypes, rating, ratings, style, imageWrapperStyle, nameStyle, locationStyle, detailsStyle, imageSize, user} = this.props
    const { displayName, profileImage, coverPhoto } = user
    const rootStyle = {}
    if (height) {
      rootStyle.flexBasis = height
    }
    const isVideoCover = ['.mp4', '.mov'].some(indicator => _.invoke(coverPhoto, 'includes', indicator))
    return (
      <View style={rootStyle}>
        <TouchableOpacity
          style={[styles.container, styles.row, style]}
          onPress={this.goToShop}
        >
          <View style={[styles.profileImageWrapper, imageWrapperStyle]}>
            <NamedImage
              style={[styles.profileImage, { height: imageSize, width: imageSize }]}
              name={isVideoCover ? resolveVideoThumbnail(coverPhoto) : profileImage || coverPhoto}
              width={imageSize}
              height={imageSize}
              fit='crop'
              crop='faces'
              resizeMode='cover'
              resizeMethod='scale'
              placeholder={'profile'}
            />
          </View>
          <View style={[styles.column, detailsStyle ]}>
            <Text numberOfLines={1} style={[styles.name, nameStyle]}>{displayName}</Text>
            {!address ? null :
              <Text style={[styles.location, locationStyle]}>{`${address.city}, ${address.region}`}</Text>
            }
            <View style={rating && paymentTypes && styles.row}>
              {!rating ? null :
                <Ratings
                  ratingsStyle={styles.smallMargin}
                  containerStyle={styles.ratingsContainer}
                  rating={rating}
                  ratings={ratings}
                  showRatings={ratings !== undefined}
                />
              }

              { !rating || !paymentTypes ? null :
                <Text style={[styles.divider, styles.margin]}>|</Text>
              }

              { paymentTypes ? null :
                this.paymentTypes(paymentTypes)
              }
              {this.props.children}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}

const styles = stylus({
  container: {
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  row: {
    flex: 1,
    flexBasis: 'auto',
    flexDirection: 'row'
  },
  column: {
    flex: 1,
    flexBasis: 'auto',
    flexDirection: 'column',
  },
  profileImage: {
    borderRadius: 22,
  },
  profileImageWrapper: {
    paddingHorizontal: 8,
  },
  name: {
    color: colors.text.main,
    fontWeight: '600',
    fontSize: 18,
  },
  location: {
    color: colors.text.main,
    fontSize: 16,
  },
  divider: {
    color: colors.text.secondary,
    fontSize: 16,
  },
  bullet: {
    color: colors.text.main,
    fontSize: 16,
    fontWeight: '500',
  },
  margin: {
    marginRight: 8,
  },
  smallMargin: {
    marginRight: 4,
  },
  iconSet: {
    flex: 1,
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
  }
})

const mapDispatchToProps = (dispatch) => ({
  goToShop: ({ id, displayName}) => {
    NavigationActions.navigate({ routeName: 'Shop', params: { id, pageTitle: displayName } })
  },
})

export default connect(
  null,
  mapDispatchToProps,
)(ShopHeader)
