import React from 'react'
import { FlatList, Platform, View } from 'react-native'
import Scroller from '../../components/Scroller'
import { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import NotificationItem from './NotificationItem'
import i18n from 'i18n-js'
import { connect, graphql } from '../../config/connected'
import schema from '../../schema/notification'
import _ from 'lodash'
import { client } from '../../containers/withApollo'
import NavigationActions from '../../utility/navigationActions'
import Empty from './Empty'
import { Notifications as ExpoNotifications } from 'expo'
import { query as currentUserQuery } from '../../containers/withCurrentUser'
import { NOTIFICATIONS_SET_REFRESH_FUNCTION } from '../../reducers/notifications'


@graphql(schema.queries.myNotifications, {
  name: 'myNotifications',
  options: (props) => ({
    fetchPolicy: 'cache-and-network',
    variables: {},
  }),
})
@connect(
  ({ screenInfo, notifications }) => ({ screenInfo, notifications }),
  (dispatch) => ({
    navigate: (options) => NavigationActions.navigate(options),
    setRefreshNotificationsFunction: (func) =>
      dispatch({
        type: NOTIFICATIONS_SET_REFRESH_FUNCTION,
        payload: { refreshNotifications: func },
      }),
  }),
)
class Notifications extends React.Component {
  state = {
    refreshing: false,
  }

  listRef = null

  componentDidMount() {
    this.props.setRefreshNotificationsFunction(() => {
      const listRef = this.listRef || {}
      if (listRef.scrollToIndex) {
        try {
          listRef.scrollToIndex({
            index: 0,
            animated: false,
          })
        } catch(e) {
          console.log('<<<NOTIFICATIONS scrollToIndex error -', e)
        }
      } else if (listRef.scrollTo) {
        listRef.scrollTo({ x: 0, y: 0, animated: false })
      }
      this.refresh()
    })
  }
  setListRef = (ref) => {
    this.listRef = ref
  }
  loadMore = () => {
    if (this.loadingMore || this.noMore) {
      return
    }
    this.loadingMore = true
    const { myNotifications } = this.props
    const last = _.chain(myNotifications.myNotifications).last().get('createdAt').value()

    myNotifications.fetchMore({
      variables: { last, limit: 16 },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.loadingMore = false
        if (!fetchMoreResult || fetchMoreResult.myNotifications.length === 0) {
          this.noMore = true
          return previousResult
        }
        const rawNotifications = previousResult.myNotifications.concat(fetchMoreResult.myNotifications)
        const newMyNotifications = _.uniqBy(rawNotifications, 'id')
        return {
          myNotifications: newMyNotifications
        }
      },
    })
  }
  refresh = async () => {
    const { refreshing } = this.state
    if (refreshing) {
      return
    }
    // this is just to smooth things
    this.setState({ refreshing: true })
    this.noMore = false
    const refetch = _.get(this.props, 'myNotifications.refetch', () => {})
    try {
      await refetch()
    } catch (e) {
      console.log('<<<NOTIFICATIONS - refresh error', e)
    }
    this.setState({ refreshing: false })
  }

  getItems = () => {
    return _.uniqBy(_.get(this.props, 'myNotifications.myNotifications', []), 'id')
  }

  renderItem = ({ item, index }) => {
    return (
      <NotificationItem
        notification={item}
        key={item.id}
        navigate={this.props.navigate}
        notificationSeen={this.notificationSeen}
      />
    )
  }

  render() {
    return Platform.OS === 'web' ? this.renderWeb() : this.renderNative()
  }

  renderWeb() {
    const { screenInfo } = this.props
    const items = this.getItems()
    return (
      <Scroller loadMore={this.loadMore} innerRef={this.setListRef}>
        {items.length > 0 ? (
          items.map((item) => this.renderItem({ item, index: item.id }))
        ) : (
          <Empty />
        )}
      </Scroller>
    )
  }

  renderNative() {
    const { screenInfo } = this.props
    const { paddingLeft, paddingRight } = screenInfo
    return (
      <>
        <FlatList
          ref={this.setListRef}
          data={this.getItems()}
          renderItem={this.renderItem}
          keyboardDismissMode='on-drag'
          ListEmptyComponent={Empty}
          refreshing={
            this.state.refreshing ||
          this.props.myNotifications.networkStatus === 4
          }
          onRefresh={this.refresh}
          ListFooterComponent={<View style={styles.bottomPadding}/>}
          keyExtractor={(item, index) => {
            return item.id
          }}
          onEndReached={this.loadMore}
          contentContainerStyle={{
            paddingLeft,
            paddingRight,
          }}
        />
      </>
    )
  }
  notificationSeen = (id) => {
    return client.mutate({
      mutation: schema.mutations.notificationSeen,
      variables: { id },
      update: (store, { data }) => {
        const myNotifications = store.readQuery({
          query: schema.queries.myNotifications,
        })
        if (myNotifications.myNotifications) {
          const record = _.find(myNotifications.myNotifications, { id })
          if (record) {
            record.unseen = false
          }
          store.writeQuery({
            query: schema.queries.myNotifications,
            data: myNotifications,
          })
        }
        // update unseenBadge
        if (Platform.OS === 'ios') {
          try {
            ExpoNotifications && ExpoNotifications.setBadgeNumberAsync(0)
          } catch (e) {
            console.log('<<<NOTIFICATIONS - Clear Badge Number Error', e)
          }
        }
     
        const currentUser = store.readQuery({ query: currentUserQuery })
        if (currentUser.currentUser) {
          currentUser.currentUser.unseenBadge.unseenNotifications = 0
          store.writeQuery({
            query: currentUserQuery,
            data: currentUser,
          })
        }
      },
    })
  }
}

Notifications.navigationOptions = (props) => {
  return { headerTitle: i18n.t('common.notifications') }
}

export default Notifications

const styles = stylus({
  container: {
    flex: 1,
  },
  bottomPadding: {
    height: sizes.tabBarHeight,
    iphonex: {
      height: sizes.iphonexTabBarHeight,
    },
  },
})
