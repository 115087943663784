import React from 'react'
import { View, Text, Image } from 'react-native'
import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import T from '../../components/T'
import TextInput from '../../components/simple/TextInput'
import UploadButton from '../../components/simple/UploadButton'
import Icons from '@expo/vector-icons/Ionicons'
import Icon from '../../config/icon'
import Button from '../../components/simple/Button'

const AddProfileImage = ({
  profileImagePreview,
  onChange,
  onSubmit,
  getMessage,
}) => {
  return (
    <View style={styles.container}>
      {profileImagePreview ? (
        <Image source={profileImagePreview} style={styles.profileImage} />
      ) : (
        <View style={styles.circle}>
          <Icon
            name='profile-outline'
            size={42}
            color={colors.text.secondary}
          />
        </View>
      )}
      <T style={styles.title} t='onboardingUser.profile.title' />
      <T style={styles.subtext} t='onboardingUser.profile.text' />
      {profileImagePreview ? (
        <Button
          onPress={onSubmit}
          label={getMessage('common.next')}
          style={styles.button}
          labelStyle={styles.buttonText}
        />
      ) : (
        <UploadButton style={styles.button} onChange={onChange}>
          <T
            style={[styles.buttonText, styles.uploadButtonText]}
            t={
              profileImagePreview
                ? 'common.next'
                : 'onboardingUser.profile.addPhoto'
            }
          />
        </UploadButton>
      )}
    </View>
  )
}

export default AddProfileImage

const imageSize = 100

const styles = stylus({
  container: {
    alignItems: 'center',
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginTop: 20,
    color: colors.text.main,
  },
  subtext: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '300',
    color: colors.text.secondary,
  },
  placeholderImage: {
    marginVertical: 10,
  },
  circle: {
    width: imageSize,
    height: imageSize,
    borderRadius: imageSize / 2,
    borderColor: colors.text.secondary,
    borderWidth: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  profileImage: {
    width: imageSize,
    height: imageSize,
    borderRadius: imageSize / 2,
  },
  button: {
    marginTop: 20,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
    alignSelf: 'stretch',
    web: {
      width: '100%',
      flex: 1,
      display: 'flex',
    },
  },
  buttonText: {
    fontSize: 16,
    fontWeight: '600',
    color: 'white',
    backgroundColor: 'transparent',
  },
  uploadButtonText: {
    padding: 10,
  },
})
