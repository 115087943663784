import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { View, KeyboardAvoidingView, Platform } from 'react-native'
import { compose, hoistStatics } from 'recompose'
import { graphql } from 'react-apollo'
import i18n from 'i18n-js'
import _ from 'lodash'
import update from 'immutability-helper'
import { HeaderBackButton } from 'react-navigation-stack'
import couponSchema from '../../schema/coupon'
import CouponForm from './CouponForm'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'
import KeyboardShift from '../../containers/KeyboardShift'
import Scroller from '../../components/Scroller/Scroller'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import NavigationActions from '../../utility/navigationActions'
import shared, { stylus } from '../../config/styles'
import { getKeyboardPaddingStyle } from '../../config/helpers'
import branch from '../../config/branch'
import alert from '../../utility/alert'

const getAlertErrorConfig = (e) => {
  const transKey = _.get(e, 'graphQLErrors.0.message') || 'errors.common.network'
  const message = i18n.t(transKey)
  const title = i18n.t('common.error')
  return { title, message}
}

const CreateCoupon = props => {
  const coupon = props.navigation.getParam('coupon') || {}
  const keyboardPaddingStyle = getKeyboardPaddingStyle(props.keyboardInfo)

  const navigateToList = () => {
    NavigationActions.goTo({
      routeName: 'AdminCoupons',
      params: {
        doRefetch: true
      }
    })
  }

  const onSubmit = useCallback(_.once((values) => {
    if (!_.chain(values).get('id').isEmpty().value()) {
      props.updateCoupon({
        variables: {
          id: values.id,
          input: update(values, { $unset: ['id'] }),
        },
      }).then(() => {
        navigateToList()
      }).catch((e) => {
        const alertConfig = getAlertErrorConfig(e)
        alert(alertConfig).then(() => {
          navigateToList()
        })
      })
    } else {
      props.createCoupon({
        variables: {
          input: update(values, { $unset: ['id'] }),
        },
      }).then(() => {
        navigateToList()
      }).catch((e) => {
        const alertConfig = getAlertErrorConfig(e)
        alert(alertConfig).then(() => {
          navigateToList()
        })
      })
    }
  }), [])

  return <KeyboardAvoidingView
    style={styles.outerContainer}
    behavior={Platform.OS === 'android' ? undefined : 'padding'}
    keyboardVerticalOffset={60}
  >
    <Scroller keyboardShouldPersistTaps='always' keyboardDismissMode='none' showsVerticalScrollIndicator={false}>
      <KeyboardShift fluid extraShiftValue={branch({ apk: sizes.tabBarHeight, other: 0 })}>
        <View style={[styles.container, keyboardPaddingStyle]}>
          <CouponForm coupon={coupon} onSubmit={onSubmit} />
        </View>
      </KeyboardShift>
    </Scroller>
  </KeyboardAvoidingView>
}

CreateCoupon.propTypes = {
}
CreateCoupon.navigationOptions = ({ navigation }) => {
  return {
    title: i18n.t(`settings.sectionLinks.${(navigation.state.params) ? 'editCoupon': 'createCoupon' }`),
    headerLeft: () => (<HeaderBackButton onPress={() => {
      NavigationActions.back()
    }} tintColor={colors.black} />),
  }
}

const enhanced = compose(
  WithKeyboardInfo,
  graphql(couponSchema.mutations.createCoupon, {
    name: 'createCoupon',
  }),
  graphql(couponSchema.mutations.updateCoupon, {
    name: 'updateCoupon',
  }),
)
export default hoistStatics(enhanced)(CreateCoupon)

const styles = stylus({
  outerContainer: {
    flex: 1,
    paddingHorizontal: 10,
    native: {
      width: '100%',
      height: '100%',
    },
    web: {
      position: 'relative',
    },
  },
  container: {
    flex: 1,
    alignSelf: 'stretch',
    landscape: {
      ...shared.card,
      marginTop: 31,
    },
  },
  input: {
    width: '100%',
    // marginVertical: 10,
  },

  headerContainer: {
    marginVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },

  title: {
    fontWeight: 'bold',
    color: colors.text.main,
  },

  shippingAddressContainer: {
    marginTop: 15,
  },

  button: {
    backgroundColor: colors.primary,
    marginBottom: 5,
    marginTop: 20,
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  countryContainer: {
    alignSelf: 'stretch',
    marginBottom: 10,
  },
  pickerLabel: {
    marginTop: 20,
    color: colors.input.base,
    fontSize: 12,
    web: {
      marginBottom: 6,
    },
  },
  picker: {
    web: {
      width: '100%',
      color: colors.input.text,
    },
  },
})
