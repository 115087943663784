import React from 'react'
import { Text, View, TouchableOpacity, Keyboard, KeyboardAvoidingView, Platform } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import NavigationActions from '../../utility/navigationActions'

import ElegantInput from '../../components/simple/ElegantInput'
import Modal from '../../components/simple/Modal'
import Button from '../../components/simple/Button'
import MobileBackButton from '../../components/simple/MobileBackButton'
import Scroller from '../../components/Scroller'
import { Status } from '../../components/simple/Status'
import OrderItem from './OrderItem'
import OrderTotals from '../Shopping/Order/OrderTotals'
import Order from '../OrderHistory/Order'
import ShippingAddress from '../Shopping/Checkout/ShippingAddress'
import PaymentMethods from '../Shopping/Order/PaymentMethods'
import ChatWithUser from '../../components/ChatWithUser/ChatWithUser'
import PaymentMethod from '../Payments/PaymentMethods/PaymentMethod'
import Coupon from '../../components/Coupon'
import AddressView from '../../components/Address/AddressView'
import TextSelectable from '../../components/simple/TextSelectable'

import sharedStyles, { stylus } from '../../config/styles'
import colors from '../../config/colors'

import ordersSchema from '../../schema/orders'
import { compose, connect, graphql } from '../../config/connected'
import { ROUTE_SHOP_ORDER_DETAILS } from '../../config/constants'
import { getFeatureFlag, mergeNavOptionsForFeedLogo, routeForShop, getTotalPrices } from '../../config/helpers'
import { updateOrdersInCacheFunc } from './helpers'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'

import i18n from 'i18n-js'
import _ from 'lodash'

@WithKeyboardInfo
class OrderDetails extends React.Component {
  state = {
    scrolledToItems: false,
    courier: '',
    trackingNumber: '',
    partialFulfillmentItems: [],
    editing: '',
    formIsVisible: false,
    formSubmitLoading: false,
  }

  _inputRefs = {}

  goToItems = ({ y }) => {
    if (!this.myScroll || this.state.scrolledToItems ) return
    if (!_.get(this.props.navigation, 'state.params.goToItems', false)) return
    if (!_.get(this.props.orderByIdHandler, 'orderById.orderItems.length', 0) > 0) return

    this.myScroll.scrollTo({ x: 0, y, animated: true })

    this.setState({ scrolledToItems: true })
  }

  handleCloseForm = () => {
    this.setState({
      formIsVisible: false,
      formSubmitLoading: false,
      partialFulfillmentItems: [],
      courier: '',
      trackingNumber: '',
      editing: '',
    })  
  }

  renderFulfillmentForm() {
    const { courier, trackingNumber, formIsVisible, editing, formSubmitLoading } = this.state
    return (
      <Modal
        animationType='slide'
        visible={formIsVisible}
        onRequestClose={this.handleCloseForm}
      >
        <KeyboardAvoidingView
          style={styles.modalContainer}
          behavior={Platform.OS === 'android' ? undefined : 'padding'}
        >
          <Text style={styles.modalTitle}>{i18n.t('order.orderDetails.fulfillmentTitle')}</Text>

          <ElegantInput
            label={i18n.t('order.orderDetails.courier')}
            value={courier}
            onChange={(courier) => this.setState({ courier })}
            returnKeyType="next"
            onSubmitEditing={() => {
              _.invoke(this._inputRefs, 'trackingNumber.focus')
            }}
          />
          <ElegantInput
            reference={input => {
              this._inputRefs['trackingNumber'] = input
            }}
            label={i18n.t('order.orderDetails.trackingNumber')}
            value={trackingNumber}
            maxLength={40}
            onChange={(trackingNumber) => this.setState({ trackingNumber })}
            returnKeyType="done"
            onSubmitEditing={() => {
              Keyboard.dismiss()
            }}
          />
          <View style={styles.modalButtonContainer}>
            <Button
              style={styles.modalButtonCancel}
              label={i18n.t('common.cancel')}
              onPress={() => {
                if (!this.formSubmitLoading) {
                  this.handleCloseForm()
                }
              }}
            />
            <Button
              style={styles.modalButtonConfirm}
              label={i18n.t('common.confirm')}
              onPress={() => {
                this.setState({ formSubmitLoading: true })
                if (!!editing) {
                  return this.editFulfillment()
                }
                return this.fulfillOrder()
              }}
              showLoader={formSubmitLoading}
              disabled={!courier || !trackingNumber || formSubmitLoading}
            />
          </View>
        </KeyboardAvoidingView>
      </Modal>
    )
  }

  renderFulfillments(orderFulfillments, forShop) {
    return (
      <View style={styles.shippingAddressContainer}>
        <Text style={[sharedStyles.h6, styles.paymentMethodTitle]}>
          {i18n.t('order.orderDetails.fulfillmentTitle')}
        </Text>
        {orderFulfillments.map(fulfillment => (
          <View key={fulfillment.id} style={styles.fulfillmentItem}>
            <View style={{ flex: 1 }}>
              <Text>{i18n.t('order.orderDetails.courier')}: {fulfillment.courier}</Text>
              <TextSelectable
                style={{
                  textDecorationLine: 'underline'
                }}
                copyText={forShop ? null : fulfillment.trackingNumber}
              >
                {i18n.t('order.orderDetails.trackingNumber')}: {fulfillment.trackingNumber}
              </TextSelectable>
            </View>


            {forShop && (
              <TouchableOpacity
                style={{ width: 30, justifyContent: 'center', alignItems: 'center' }}
                onPress={() => {
                  this.setState({
                    courier: fulfillment.courier,
                    trackingNumber: fulfillment.trackingNumber,
                    editing: fulfillment.id,
                    formIsVisible: true,
                    formSubmitLoading: false,
                  })
                }}
                hitSlop={{top: 10, bottom: 10, left: 10, right: 10}}
              >
                <MaterialCommunityIcons
                  name="square-edit-outline"
                  size={20}
                  color="black"
                />
              </TouchableOpacity>
            )}
          </View>
        ))}
      </View>
    )
  }

  render() {
    const { orderByIdHandler, navigation } = this.props
    const { courier, trackingNumber, partialFulfillmentItems } = this.state
    const route = navigation.state.routeName
    const forShop = routeForShop(route, ROUTE_SHOP_ORDER_DETAILS)
    if (orderByIdHandler.loading) {
      return <Status message={i18n.t('order.orderDetails.status.loading')} />
    }
    const order = orderByIdHandler.orderById
    if (!order) {
      return (
        <Status message={i18n.t('order.orderDetails.status.noOrderItems')} />
      )
    }
    const { status, orderNumber, user, shop, shopId, coupon, orderItems, address, stripePaymentIntent = {}, billingAddress, producerDetails, paymentDetails, credit, orderFulfillments } = order

    const originalShop = { ...producerDetails, ...paymentDetails }

    if (!orderItems.length) {
      return (
        <Status message={i18n.t('order.orderDetails.status.noOrderItems')} />
      )
    }
    const sortedOrderItems = _.orderBy(orderItems, ['createdAt'], ['desc']).map(orderItem => {
      // Following is for providing original shop payment details on the original product details.
      // This is so that getTotalPrices will calculating totals appropriately in OrderTotals.
      _.set(orderItem, 'productDetails.profile.id', shopId)
      _.set(orderItem, 'productDetails.profile.producerDetails', originalShop)
      // _.set(orderItem, 'courier', courier)
      // _.set(orderItem, 'trackingNumber', trackingNumber)
      return orderItem
    })
    const orderFulfilledByProducer = status === 'fulfilled'
    const entity = forShop ? user : shop
    const chatWithUser = forShop
      ? user
      : _.get(shop, 'producerDetails.ownership.owner', {})
    const entityType = forShop ? 'customer' : 'shop'
    const navigationAction = forShop
      ? () => this.props.goToProfile(user)
      : () => this.props.goToShop(shop)
    const props = {
      style: styles.order,
      entity: forShop
        ? i18n.t('order.order.entity.customer')
        : i18n.t('order.order.entity.shop'),
      entityIdentifier: forShop ? entity.username : entity.displayName,
      entityImage: entity.profileImage || entity.coverPhoto,
      order,
      navigateOnImage: true,
      navigationAction,
    }
    const orderNumberText = `${i18n.t('order.order.orderNumber')}${orderNumber}`
    const latestCharge = _.get(stripePaymentIntent, 'charges.data.[0]', {})
    const paymentMethod = latestCharge.payment_method_details
    const paymentMethodId = latestCharge.payment_method

    const totals = getTotalPrices(
      orderItems,
      (item) => item.productDetails,
      undefined,
      coupon
    )
    const rawFees = _.get(latestCharge, 'balance_transaction.fee_details', [])
    const exchangeRate = _.get(latestCharge, 'balance_transaction.exchange_rate', 1) || 1
    const fees = rawFees.map(fee => ({
      ...fee,
      amount: (fee.amount / exchangeRate) / 100,
      description: i18n.t(`order.orderDetails.${fee.type}`)
    }))
    if (!fees.find(fee => fee.type === 'application_fee')) {
      fees.push({
        amount: 0,
        description: i18n.t('order.orderDetails.application_fee'),
        type: 'application_fee'
      })
    }
    const refundTotal = _.get(latestCharge, 'refunds.data', []).reduce((total, refund) => total + refund.amount, 0) / 100

    //console.log('Charges', stripePaymentIntent.charges.data)
    //console.log('<<<ORDER DETAILS EXCHANGE RATE - ', exchangeRate)
    //console.log('<<<ORDER DETAILS FEES - ', fees)
    //console.log('<<<Address', address)

    return (
      <View style={{ flex: 1 }}>
        <Scroller
          innerRef={(ref) => this.myScroll = ref}
        >
          <View style={[styles.contentContainer]}>
            <Text style={[styles.title, sharedStyles.h4]}>
              {i18n.t('order.orderDetails.title')}
            </Text>
            <View style={[styles.row, styles.orderDetailsHeader]}>
              <Text style={[styles.orderNumberText]}>{orderNumberText}</Text>
              {/* TODO: Replace button with ChatWithUser */}
              <ChatWithUser
                label={i18n.t(`order.orderDetails.message.${entityType}`)}
                labelStyle={styles.buttonLabel}
                containerStyle={styles.button}
                user={chatWithUser}
                navigation={navigation}
                defaultMessage={orderNumberText}
              />
            </View>
          </View>
          <Order {...props} />
          <View style={[styles.contentContainer]}>
            <AddressView
              address={address}
              style={styles.shippingAddressContainer}
              title={i18n.t('order.checkout.addressTitle')}
              titleStyle={sharedStyles.h6}
            />
            <AddressView
              address={_.isEmpty(billingAddress) ? address : billingAddress}
              style={styles.shippingAddressContainer}
              title={i18n.t('order.checkout.billingAddressTitle')}
              titleStyle={sharedStyles.h6}
            />
            {orderFulfillments.length > 0 && (
              this.renderFulfillments(orderFulfillments, forShop)
            )}
            <OrderTotals
              key={`totals-for-shop-id-${shop.id}`}
              items={sortedOrderItems}
              fees={forShop ? fees : []}
              credit={forShop ? credit : undefined}
              coupon={coupon}
              refundText={i18n.t('order.orderDetails.refund')}
              refund={refundTotal}
              refundAsSeparateTotal={!forShop}
              shop={shop}
              title={i18n.t('order.orderDetails.paymentDetailsTitle')}
              totalTitle={i18n.t('order.checkout.orderTotal')}
              netTitle={i18n.t('order.orderDetails.netTitle')}
              creditTitle={i18n.t('order.orderDetails.creditTitle')}
              style={styles.shopTotalsContainer}
              titleStyle={sharedStyles.h6}
              showTitle
              showSubtotal
              showCouponPercentage
            />
            {!getFeatureFlag('stripe') && !forShop ? (
              <PaymentMethods
                style={styles.paymentMethodsContainer}
                title={i18n.t('order.checkout.paymentMethodTitle')}
                titleStyle={sharedStyles.h6}
                shop={shop}
              />
            ) : null}
            {getFeatureFlag('stripe') && paymentMethod && paymentMethodId ? (
              <View>
                <Text style={[sharedStyles.h6, styles.paymentMethodTitle]}>
                  {i18n.t('order.checkout.paymentMethodTitle')}
                </Text>
                <PaymentMethod
                  key={paymentMethodId}
                  id={paymentMethodId}
                  style={styles.paymentMethodContainer}
                  brand={paymentMethod.card.brand}
                  last4={paymentMethod.card.last4}
                  expirationMonth={paymentMethod.card.exp_month}
                  expirationYear={paymentMethod.card.exp_year}
                />
              </View>
            ): null }
            {coupon ? (
              <Coupon
                couponName={coupon.name}
                couponAmount={totals.couponSavings}
              />
            ): null }
            <Text style={[styles.itemsTitle, sharedStyles.h6]}>
              {i18n.t('order.orderDetails.items')}
            </Text>
          </View>
          <View onLayout={({ nativeEvent }) => this.goToItems(nativeEvent.layout)}>
            {sortedOrderItems.map((orderItem) => (
              <OrderItem
                key={orderItem.id}
                orderItem={orderItem}
                navigation={navigation}
                partialFulfillmentItems={partialFulfillmentItems}
                addToPartialFulfillment={this.addToPartialFulfillment}
                removeFromPartialFulfillment={this.removeFromPartialFulfillment}
              />
            ))}
          </View>
        </Scroller>
        {forShop && !orderFulfilledByProducer && (
          <View style={[styles.fulfillOrderContainer]}>
            <Button
              style={[styles.fulfillAction, partialFulfillmentItems.length > 0 ? { backgroundColor: colors.primary } : null]}
              disabledStyle={styles.disabledFulfillAction}
              label={
                i18n.t('order.orderDetails.producerFulfill', { count: partialFulfillmentItems.length})
              }
              disabled={orderFulfilledByProducer}
              labelStyle={styles.fulfillActionText}
              onPress={() => this.setState({ 
                formIsVisible: true,
                formSubmitLoading: false, 
              })}
            />
          </View>
        )}
        {this.renderFulfillmentForm()}
      </View>
    )
  }

  addToPartialFulfillment = (orderItemId) => {
    const { partialFulfillmentItems } = this.state
    this.setState({
      partialFulfillmentItems: [...partialFulfillmentItems, orderItemId]
    })
  }

  removeFromPartialFulfillment = (orderItemId) => {
    const { partialFulfillmentItems } = this.state
    this.setState({
      partialFulfillmentItems: partialFulfillmentItems.filter(itemId => itemId !== orderItemId)
    })
  }

  editFulfillment = async () => {
    const { courier, trackingNumber, editing } = this.state

    const { navigation, userId } = this.props
    const producerId = navigation.state.params.userId
    const orderId = navigation.state.params.id

    const variables = { 
      userId: producerId, 
      userType: 'producer', 
      orderId,
      fulfillmentId: editing,
      courier,
      trackingNumber
    }

    try {
      await this.props.updateOrderFulfillment({
        variables,
        update: updateOrdersInCacheFunc(userId, producerId),
      })
      this.handleCloseForm()
    } catch (e) {
      this.setState({ formSubmitLoading: false })
      console.log(e)
    }
  }

  fulfillOrder = async () => {
    const { courier, trackingNumber, partialFulfillmentItems} = this.state

    const { orderByIdHandler, navigation, userId } = this.props
    const producerId = navigation.state.params.userId
    const order = orderByIdHandler.orderById
    const { id: orderId, orderItems } = order
    const ids = partialFulfillmentItems.length > 0 ? partialFulfillmentItems : orderItems
      .filter((orderItem) => !orderItem.fulfilledAt)
      .map((orderItem) => orderItem.id)

    const variables = { userId: producerId, userType: 'producer', orderId, ids, courier, trackingNumber }
    try {
      await this.props.markOrderItemsFulfilled({
        variables,
        update: updateOrdersInCacheFunc(userId, producerId),
      })
      this.handleCloseForm()
    } catch (e) {
      this.setState({ formSubmitLoading: false })
      console.log(e)
    }
  }
}

OrderDetails.navigationOptions = (props) => {
  return mergeNavOptionsForFeedLogo({
    headerLeft: () => (
      <MobileBackButton
        label={i18n.t('common.back')}
        onPress={() => NavigationActions.back()}
      />
    ),
  })
}

const mapStateToProps = (state) => ({
  userId: state.currentUser.id,
  screenInfo: state.screenInfo,
})

const mapDispatchToProps = (dispatch) => ({
  goToShop: ({ id, displayName }) => {
    NavigationActions.navigate({ routeName: 'Shop', params: { id, pageTitle: displayName } })
  },
  goToProfile: ({ id, username, profileImage }) => {
    NavigationActions.navigate({
      routeName: 'Profile',
      params: { id, pageTitle: username, profileImage },
    })
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(ordersSchema.queries.orderById, {
    name: 'orderByIdHandler',
    options({ navigation }) {
      const { id } = navigation.state.params
      return { fetchPolicy: 'cache-and-network', variables: { id } }
    },
  }),
  graphql(ordersSchema.mutations.markOrderItemsFulfilled, {
    name: 'markOrderItemsFulfilled',
  }),
  graphql(ordersSchema.mutations.updateOrderFulfillment, {
    name: 'updateOrderFulfillment',
  }),
)(OrderDetails)

const styles = stylus({
  contentContainer: {
    paddingHorizontal: 15,
  },
  title: {
    color: colors.text.main,
    textAlign: 'center',
    fontWeight: '600',
    marginVertical: 10,
  },
  itemsTitle: {
    color: colors.text.main,
    fontWeight: '600',
    marginBottom: 15,
    marginTop: 15,
  },
  orderDetailsHeader: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  orderNumberText: {
    fontSize: 14,
    fontWeight: '600',
    color: colors.text.main,
  },
  order: {
    marginBottom: 15,
  },
  shippingAddressContainer: {
    marginBottom: 15,
  },
  shopTotalsContainer: {
    marginBottom: 15,
  },
  paymentMethodsContainer: {
  },
  paymentMethodContainer: {
    flex: 1,
  },
  paymentMethodTitle: {
    fontWeight: 'bold',
    color: colors.text.main,
  },
  paymentMethodText: {
    fontSize: 15,
  },
  fulfillAction: {
    backgroundColor: 'red',
    flex: 1,
  },
  fulfillActionText: {
    fontSize: 15,
  },
  disabledFulfillAction: {
    backgroundColor: 'green',
  },
  fulfillOrderContainer: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    paddingTop: 10,
    paddingBottom: 15,
    iphonex: {
      paddingBottom: 35,
    },
    backgroundColor: '#F7F7F7',
    // alignItems: 'center',
  },
  fulfillmentItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
  },
  button: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
    borderRadius: 5,
  },
  buttonLabel: {
    fontWeight: '600',
    color: 'white',
    backgroundColor: 'transparent',
    fontSize: 13,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 15,
  },
  modalButtonContainer: {
    marginTop: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalButtonCancel: {
    backgroundColor: colors.red,
    width: '48%',
  },
  modalButtonConfirm: {
    backgroundColor: colors.primary,
    width: '48%',
  },
  modalTitle: {
    fontSize: 16,
    color: colors.text.main,
    textAlign: 'center',
    marginBottom: 30,
  },
})
