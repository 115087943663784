import produce from 'immer'
import _ from 'lodash'

export default function navigation(state = { backStates: [], forwardStates: [], isReady: false }, action) {
  const { type, backState, forwardState, tabName } = action
  switch (type) {
    case 'ROOT_NAVIGATOR_REF_SET':
      return { ...state, isReady: true }
    case 'PUSH_BACK_STATE':
      return produce(state, draft => {
        draft.backStates.push(backState)
      })
    case 'POP_BACK_STATE': {
      return produce(state, draft => {
        draft.backStates.pop()
      })
    }
    case 'RESET_BACK_STATES':
      return produce(state, draft => {
        draft.backStates = []
      })
    case 'PUSH_FORWARD_STATE':
      return produce(state, draft => {
        draft.forwardStates.push(forwardState)
      })
    case 'POP_FORWARD_STATE':
      return produce(state, draft => {
        draft.forwardStates.pop()
      })
    case 'RESET_FORWARD_STATES':
      return produce(state, draft => {
        draft.forwardStates = []
      })
    default:
      return state
  }
}
