import { gql } from '../config/connected'
import { stripeInfo } from './payment'
import { userProfileFragment, checkoutSessionFragment, stripePaymentIntentFragment } from './fragments'

const fileUpload = `
  name
  mime
  width
  height
`

const address = `
  address1
  address2
  city
  region
  zip
  country
`

const userFields = `
  id
  phone
  displayName
  username
  email
  profileImage
  address {
    ${address}
  }
`

export const product = `
  id
  userId
  uploads {
    ${fileUpload}
  }
  name
  categories{
    allergens
  }
  rating
  ratingCount
  stock
  quantityUnits
  price
  priceDiscount
  isDiscontinued
  isInStock
  description
  ingredients
  deliveryLocationsOnly
  unitType
  unitQuantity
  profile {
    id
    displayName
    profileImage
    coverPhoto
    status
    address {
      city
      region
    }
    producerDetails {
      id
      ownership {
        id
        owner {
          id
          displayName
          profileImage
        }
      }
      paymentTypes {
        cash
        transfer
        stripe
      }
      legalName
      standardShippingFee
      freeShippingThreshold
      minimumOrderAmount
      category
      rating
      stripeConnected
      stripeUserId
      deliveryLocations
    }
  }
`
const cartItem = `
  id
  userId
  quantity
  createdAt
  productId
  checkoutId
  product {
   ${product}
  }
  stripeInfo {
    ${stripeInfo}
  }
  stripePaymentIntent {
    ...StripePaymentIntentFragment
  }
`

export default {
  queries: {
    userById: gql`
      query($id: String!) {
        userById(id: $id) {
          ...UserFragment
        }
      }
      ${userProfileFragment}
    `,
    cartItemsByUserId: gql`
      query($userId: String!) {
        cartItemsByUserId(userId: $userId) {
          ${cartItem}
        }
      }
      ${stripePaymentIntentFragment}
    `,
    savedItemsByUserId: gql`
      query($userId: String!) {
        savedItemsByUserId(userId: $userId) {
          ${cartItem}
        }
      }
      ${stripePaymentIntentFragment}
    `,
  },
  mutations: {
    createCartItem: gql`
      mutation($userId: String!, $productId: String!) {
        createCartItem(userId: $userId, productId: $productId) {
          ${cartItem}
        }
      }
      ${stripePaymentIntentFragment}
    `,
    deleteCartItem: gql`
      mutation($id: String!) {
        deleteCartItem(id: $id) {
          ${cartItem}
        }
      }
      ${stripePaymentIntentFragment}
    `,
    updateCartItemQuantity: gql`
      mutation($id: String!, $quantity: Float!) {
        updateCartItemQuantity(id: $id, quantity: $quantity) {
          ${cartItem}
        }
      }
      ${stripePaymentIntentFragment}
    `,
    saveCartItemForLater: gql`
      mutation($id: String!) {
        saveCartItemForLater(id: $id) {
          ${cartItem}
        }
      }
      ${stripePaymentIntentFragment}
    `,
    updateAddress: gql`
      mutation($address1: String, $address2: String, $city: String, $region: String, $zip: String, $country: String) {
        updateAddress(address1: $address1, address2: $address2, city: $city, region: $region, zip: $zip, country: $country) {
          ${userFields}
        }
      }
      ${stripePaymentIntentFragment}
    `,
    continueStripePaymentSession: gql`
      mutation($currency: String!, $locale: String!, $shipping: StripeShippingInput, $billing: Json) {
        continueStripePaymentSession(currency: $currency, locale: $locale, shipping: $shipping, billing: $billing) {
          ...CheckoutSessionFragment
        }
      }
      ${checkoutSessionFragment}
    `
  },
}
