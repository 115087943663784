import React from 'react'
import { Animated, Text, TouchableOpacity, View, Platform } from 'react-native'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import Icons from '@expo/vector-icons/Ionicons'
import Action from '../../components/simple/Action'

import i18n from 'i18n-js'

export const defaultInitialHeight = 150
const isWeb = Platform.OS === 'web'

class ExpandingSection extends React.Component {
  state = {
    open: this.props.defaultOpen || false,
    alwaysOpen: false,
    initialAnimationDone: false,
    height: new Animated.Value(0),
    heightOpen: 0,
    heightClosed: 0,
    targetHeight: 0,
  }

  static propTypes = {
    children: PropTypes.any,
    size: PropTypes.number,
    label: PropTypes.string,
    style: PropTypes.any,
    defaultOpen: PropTypes.bool,
    alwaysOpen: PropTypes.bool,
    initialHeight: PropTypes.number
  }

  static defaultProps = {
    size: 20,
    initialHeight: 0,
    alwaysOpen: false,
  }

  resetDidSetHeightOpen() {
    this.didSetHeightOpen = false
    this.setState({ initialAnimationDone: false })
  }

  toggle = () => {
    const { open, heightOpen, heightClosed } = this.state
    const combined = heightClosed + heightOpen
    const initial = open ? combined : heightClosed,
      target = open ? heightClosed : combined

    if (isWeb) {
      this.setState({ targetHeight: target, open: !open })
    } else {
      Animated.timing(this.state.height, {
        fromValue: initial,
        toValue: target,
        duration: 200,
      }).start(() => {
        this.setState({ open: !open })
      })
    }
  }

  setHeightOpen = (e) => {
    if (!this.didSetHeightOpen) {
      // this seems to fire multiple times with wrong height on all but first time
      this.didSetHeightOpen = true
      const heightOpen = e.nativeEvent.layout.height

      this.setState((prevState) => ({
        ...prevState,
        heightOpen,
      }))
    }
  }

  setHeightClosed = (e) => {
    if (!this.heightClosedSet) {
      this.heightClosedSet = true
      const heightClosed = e.nativeEvent.layout.height
      this.setState((prevState) => ({
        ...prevState,
        heightClosed,
      }))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.alwaysOpen && this.props.alwaysOpen) {
      this.setState({ alwaysOpen: true, open: true })
    } else if (this.state.heightOpen && this.state.heightClosed && (
      (!this.state.initialAnimationDone && prevState.initialAnimationDone) || 
      (!this.state.initialAnimationDone && !prevState.initialAnimationDone)
    )) {
      
      if (isWeb) {
        if (!this.props.defaultOpen && !this.state.open) {
          this.setState({ targetHeight: this.state.heightClosed, initialAnimationDone: true })
        } else  {
          this.setState({ targetHeight: this.state.heightOpen + this.state.heightClosed, initialAnimationDone: true })
        }
      } else {
        if (!this.props.defaultOpen && !this.state.open) {
          this.state.height.setValue(this.state.heightClosed)
          this.setState({ initialAnimationDone: true })
        } else {
          // Was having issues without layout due to sizing if height value is set directly
          // work around until a more performant method is found
          // TODO: Optimize for when defaultOpen prop is true.
          Animated.timing(this.state.height, {
            fromValue: this.state.height,
            toValue: this.state.heightOpen,
            duration: 10,
          }).start(() => {
            this.state.height.setValue(this.state.heightOpen + this.state.heightClosed)
            this.setState({ initialAnimationDone: true })
          })
        }
      }
    }
  }

  render() {
    const { label, size, initialHeight } = this.props
    const { height, open } = this.state
    
    const containerHeight = _.get(height, '_value', 0) > 0 ? { height } : {}
    let childrenContainerStyle = { opacity: open ? 1 : 0 }
    if (isWeb) {
      childrenContainerStyle = { paddingBottom: 0 }
    }
    let containerStyle = {}
    if (isWeb && this.state.targetHeight) {
      let computedInitialHeight = 0
      // initial height applys only to web where the sections are considered closed(i.e. collapsed)
      if (!open) {
        computedInitialHeight = this.state.heightOpen < defaultInitialHeight ? this.state.heightOpen : 0 // use content height if less than default
        computedInitialHeight = !computedInitialHeight && initialHeight ? initialHeight : computedInitialHeight // initial height for special circumstances(i.e. guarantee to see initial image followed by few lines of text)
        computedInitialHeight = !computedInitialHeight && this.state.heightOpen >= defaultInitialHeight ? defaultInitialHeight : computedInitialHeight // use default if content higher
        if (computedInitialHeight === this.state.heightOpen && this.state.heightOpen !== 0) {
          this.setState({ alwaysOpen: true, open: true, initialAnimationDone: false })
        }
      }
      const computedHeight = this.state.targetHeight + computedInitialHeight
      containerStyle = { flexBasis: computedHeight, height: computedHeight }
    }
    const Container = isWeb ? View : Animated.View
    return (
      <React.Fragment>
        <Container style={[styles.container, containerHeight, containerStyle, this.props.style]}>
          <TouchableOpacity onPress={this.toggle} style={styles.contentLabel}>
            <Text
              style={[styles.label, { fontSize: size }]}
              onLayout={this.setHeightClosed}
            >
              {label}
            </Text>
            <View style={styles.icon}>
              <Icons
                size={size * 1.2}
                name={open ? 'ios-arrow-up' : 'ios-arrow-down'}
                color={colors.text.secondary}
              />
            </View>
          </TouchableOpacity>
          <View style={[styles.body, childrenContainerStyle]} onLayout={this.setHeightOpen}>
            {this.props.children}
          </View>
          { isWeb && !open ? <View style={styles.withLinearGradient} /> : null }
        </Container>
        <View style={[styles.sectionFooter]} >
          { isWeb && !open ? (
            <View style={styles.moreActionContainer}>
              <Action
                style={styles.moreAction}
                labelStyle={styles.actionLabel}
                color={colors.primary}
                label={i18n.t('common.more') + '...'}
                onPress={this.toggle}
              />
            </View>
          ) : null }
          <View style={styles.dividerEnd} />
        </View>
      </React.Fragment>
    )
  }
}

const styles = stylus({
  container: {
    flex: 1,
    overflow: 'hidden',
    paddingLeft: 16,
    backgroundColor: 'white',
  },
  contentLabel: {
    flexDirection: 'row',
    paddingRight: 8,
  },
  divider: {
    width: '100%',
    height: sizes.px,
    backgroundColor: colors.inactive,
  },
  dividerEnd: {
    borderBottomColor: colors.thinLine,
    borderBottomWidth: sizes.px,
    height: 1,
  },
  body: {
    paddingRight: 16,
    paddingBottom: 16,
    overflow: 'hidden',
  },
  label: {
    paddingVertical: 12,
  },
  icon: {
    flex: 1,
    paddingHorizontal: 10,
    alignItems: 'flex-end',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  sectionFooter: {
    width: '100%',
  },
  withLinearGradient: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    background: 'linear-gradient(to bottom, transparent, white)',
    height: 75,
  },
  moreAction: {
    marginVertical: 5,
  },
  moreActionContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  actionLabel: {
    fontSize: 16
  }
})

export default ExpandingSection
