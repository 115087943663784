import React, { Component } from 'react'
import {
  Platform,
  View,
  Text,
  FlatList,
  TouchableOpacity,
  StatusBar,
} from 'react-native'
import Icons from '@expo/vector-icons/Ionicons'
import Octicons from '@expo/vector-icons/Octicons'
import Constants from 'expo-constants'
// import MapView from 'react-native-maps'
import MapView from '../../components/MapView'
import NavigationActions from '../../utility/navigationActions'

import config from '../../config/environment'
import { Status } from '../../components/simple/Status'
import ObjectSearchCriteria from '../ObjectSearch/ObjectSearchCriteria'
import TextInputFB from '../../components/simple/TextInput'
import ProductResult, {
  maxResultHeight,
  maxResultWidth,
} from '../ObjectSearch/results/ProductResult'
import Button from '../../components/simple/Button'
import ProducerResult from '../ObjectSearch/results/ProducerResult'
import ProducerMapMarker from './ProducerMapMarker'
import Modal from '../../components/simple/Modal'
import withAlgoliaSearch from '../../containers/withAlgoliaSearch'

import i18n from 'i18n-js'
import sharedStyles, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import _ from 'lodash'
import produce from 'immer'
import { connect } from '../../config/connected'
import branch from '../../config/branch'
import services from '../../utility/services'

const ITEM_HEIGHT = 142 // taken from inspector

import {
  distance,
  getZoomLevelForLngDelta,
  getRadiusFromRegion,
  getDistanceFromRightEdge,
  getLatitudeDelta,
  getLongitudeDelta,
} from '../../utility/geo'
import {
  SEARCH_OBJECT_PRODUCTS,
  SEARCH_OBJECT_PRODUCERS,
} from '../../config/constants'
import {
  SEARCH_MANAGER_PRODUCTS,
  ProductSearchManager,
  LOAD_PRODUCERS_PRODUCTS,
  LOAD_PRODUCER_PRODUCTS,
  PRODUCT_FILTER_PRODUCER_ID,
  PRODUCT_FILTER_PRODUCER_IDS,
} from '../ObjectSearch/config/ProductSearchManager'
import {
  SEARCH_MANAGER_PRODUCERS,
  ProducerSearchManager,
  LOAD_MAP_PRODUCERS,
  PRODUCER_FILTER_REGION,
  PRODUCER_FILTER_RADIUS,
  LOAD_SEARCH_PRODUCERS,
  PRODUCER_FILTER_TAGS,
} from '../ObjectSearch/config/ProducerSearchManager'
import {
  SEARCH_SET_STATE,
  SEARCH_FORM_VIEW,
  SEARCH_MAP_VIEW,
  SEARCH_TRANSFER_STATE,
} from '../../reducers/search'
const DEFAULT_DELTA = { latitudeDelta: 5.757888864416664, longitudeDelta: 6.454650834202766 }

const mapStateToProps = (state) => {
  return {
    screenInfo: state.screenInfo,
    search: state.search,
    // navigation: state.navigation
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    goBack: () => NavigationActions.back(),
    dispatch,
    goToProduct: ({ id }) => {
      NavigationActions.navigate({ routeName: 'Product', params: { id } })
    },
    setSearchState: (data) => {
      dispatch({
        type: SEARCH_SET_STATE,
        forView: SEARCH_FORM_VIEW,
        data,
      })
    },
    transferSearchStateFor: (searchObjectType) => dispatch({ type: SEARCH_TRANSFER_STATE, searchObjectType }),
  }
}

const initialDistanceFromRight = 14.142135623730951

const statusBarHeight = branch({
  ios: sizes.iosStatusBarHeight,
  android: 0,
  iphonex: sizes.TOP_BAR_HEIGHT,
})
const tabBarHeight = branch({
  other: sizes.tabBarHeight,
  iphonex: sizes.iphonexTabBarHeight,
})

@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@withAlgoliaSearch
class Maps extends Component {
  constructor(props) {
    super(props)
    const { navigation } = props
    const { objectType } = navigation.state.params

    const state = {
      objectType,
      criteriaModalVisible: false,
      markerResultsModalVisible: false,
      markerResultsModalWasVisible: false,
      markerPressCoordinate: null,
      movingToMarker: false,
      firstRegionChange: true,
      initialRegion: null,
      initialRegionSearchStarted: false,
      initialRegionSearchCompleted: false,
      mapReady: false,
      region: null,
      markers: [],
      noMoreResults: false,
      haveScrolled: false,
      loading: false,
      activeMarkerId: null,
      promptForSearch: false,
      errorMessage: '',
    }

    // state.region = region
    this.mapRef = null
    this.markerRefs = {}
    this.resultsRef = null
    this.state = state
    this.productSearchManager = new ProductSearchManager(this)
    this.producerSearchManager = new ProducerSearchManager(this, { pageSizeOverride: config.map.maxProducers }) // override for producer page amount until we have map clustering
    this.mapReadyCallback = () => {}
    this.lastFocusedItemId = null
    this.viewabilityConfig = {
      minimumViewTime: 100,
      waitForInteraction: true,
      itemVisiblePercentThreshold: 95,
    }
  }

  async componentDidMount() {
    const env = branch({
      android: 'Android',
      ios: 'iOS',
      iphonex: 'iPhone X',
    })
    //console.log(env, `Height: ${this.props.screenInfo.height}`)
    const { search, transferSearchStateFor } = this.props
    const searchState = _.get(search, this.state.objectType, {})
    if (this.stateToImport()) {
      const productsSearchState = searchState[SEARCH_MANAGER_PRODUCTS]
      const producersSearchState = searchState[SEARCH_MANAGER_PRODUCERS]
      if (
        this.state.objectType === SEARCH_OBJECT_PRODUCTS &&
        productsSearchState
      ) {
        await this.productSearchManager.importSearchState(productsSearchState)
      }
      if (producersSearchState) {
        await this.producerSearchManager.importSearchState(producersSearchState)
      }
      const results = this.getResults()
      let region = this.regionToImport()
      if (!region && results.length) {
        // console.log('<<<MAPS - No region. But there is a result to work with')
        const firstResult = _.first(results)
        const markerId = this.getMarkerIdFromResult(firstResult)
        let marker = this.getMarker(markerId)
        if (!marker) {
          const response = await this.getMapSearchManager()
            .getPrimaryIndex()
            .search({
              filters: `objectID:'${markerId}'`,
            })
          marker = _.get(response, 'hits.0')
        }
        if (marker && marker._geoloc) {
          const { lat: latitude, lng: longitude } = marker._geoloc
          // const deltas = this.getDeltas(latitude, initialDistanceFromRight)
          region = Object.assign({ latitude, longitude }, DEFAULT_DELTA)
        }
      }

      transferSearchStateFor(this.state.objectType)
      if (region) {
        // console.log('<<<MAPS - Using pre-determined region')
        const nextState = (state, props) => {
          return produce(state, (draft) => {
            draft.region = region
            draft.initialRegion = region
          })
        }
        this.setState(nextState)
      } else {
        // console.log('<<<MAPS - No region to go off of. Using user location')
        this.getLocationAsync()
      }
    } else {
      // console.log('<<<MAPS - No state to import. Using user location')
      this.getLocationAsync()
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      initialRegion,
      initialRegionSearchStarted,
      markerResultsModalWasVisible,
    } = this.state

    if (initialRegion && !initialRegionSearchStarted) {
      const nextState = (state, props) => {
        return produce(state, (draft) => {
          draft.initialRegionSearchStarted = true
        })
      }
      this.setState(nextState, this.redoSearch)
    }

    // TODO: This needs to be done for route state changes
    if (markerResultsModalWasVisible) {
      const nextState = (state, props) => {
        return produce(state, (draft) => {
          draft.markerResultsModalVisible = true
          draft.markerResultsModalWasVisible = false
        })
      }
      this.setState(nextState)
    }
  }

  stateToImport() {
    const { search } = this.props
    const searchState = _.get(search, this.state.objectType, {})
    return (
      searchState.forView === SEARCH_MAP_VIEW && !searchState.stateTransferred
    )
  }

  regionToImport() {
    const { objectType } = this.state
    const searchState = _.get(
      this.props.search,
      `${objectType}.${SEARCH_MANAGER_PRODUCERS}`,
      {},
    )
    return _.get(searchState, 'filters.region', false)
  }

  async exportState() {
    const { objectType } = this.state
    const searchType = this.getFilterSearchType()
    let stateToExport = {}
    if (
      this.forProducers() &&
      this.producerSearchManager.getFilterCriteriaFor(searchType)
    ) {
      stateToExport = {
        [objectType]: {
          [SEARCH_MANAGER_PRODUCERS]: this.producerSearchManager.exportSearchState(
            searchType,
          ),
        },
      }
    } else if (
      this.forProducts() &&
      this.productSearchManager.getCriteriaCountFor(searchType)
    ) {
      const productState = this.productSearchManager.exportSearchState(
        searchType,
      )
      const producerState = this.producerSearchManager.exportSearchState(
        this.getMapSearchType(),
      )
      stateToExport = {
        [objectType]: {
          [SEARCH_MANAGER_PRODUCTS]: productState,
          [SEARCH_MANAGER_PRODUCERS]: producerState,
        },
      }
    }

    this.props.setSearchState(stateToExport)
  }

  /* #region - GETTERS */
  getLocationAsync = async () => {
    //NOTE: Defaults are only necessary for Android emulator. Set to center of Spokane for development
    let latitude = 47.658779
    let longitude = -117.426048
    const location = await services.getUserLocationAsync(true)
    if (location) {
      let {
        coords: { longitude: lng, latitude: lat },
      } = location
      latitude = lat
      longitude = lng
    }
    const region = Object.assign({ latitude, longitude }, DEFAULT_DELTA)
    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.region = region
        draft.initialRegion = region
      })
    }
    this.setState(nextState)
  }

  getDeltas(latitude, distanceFromRight) {
    const width = this.getContentWidth()
    const height = this.getMapHeight()
    const heightToWidthRatio = height / width
    const distanceFromTop = distanceFromRight * heightToWidthRatio
    const latitudeDelta = getLatitudeDelta(distanceFromTop)
    const longitudeDelta = getLongitudeDelta(latitude, distanceFromRight)
    return { longitudeDelta, latitudeDelta }
  }

  getContentWidth() {
    return this.props.screenInfo.contentWidth
  }

  getResultsHeight() {
    if (this.forProducers()) {
      return 80
    } else if (this.forProducts()) {
      return maxResultHeight(this.getContentWidth()) + 20 // gives a little more headroom for the results
    }
  }

  getMarkerResultsHeight() {
    return this.getResultsHeight() + tabBarHeight
  }

  getMarkerResultsOffset() {
    return this.props.screenInfo.height - this.getMarkerResultsHeight()
  }

  getMapHeight() {
    return (
      this.props.screenInfo.height -
      statusBarHeight -
      tabBarHeight -
      this.getResultsHeight()
    )
  }

  getFilterSearchType() {
    if (this.forProducers()) {
      return LOAD_MAP_PRODUCERS
    } else if (this.forProducts()) {
      return LOAD_PRODUCERS_PRODUCTS
    }
  }

  getFilterSearchManager = () => {
    const { objectType } = this.state
    if (objectType === SEARCH_OBJECT_PRODUCERS) {
      return this.producerSearchManager
    } else if (objectType === SEARCH_OBJECT_PRODUCTS) {
      return this.productSearchManager
    }
  }

  getMapSearchType = () => {
    return LOAD_MAP_PRODUCERS
  }

  getMapSearchManager = () => {
    return this.producerSearchManager
  }

  getMarkers() {
    return this.getMapSearchManager().getResultsFor(this.getMapSearchType())
  }

  getMarker(identifier) {
    return this.getMarkers().find((marker) => marker.id === identifier)
  }

  getActiveMarker(identifier) {
    return this.getMarker(this.state.activeMarkerId)
  }

  getMarkerIndex(identifier) {
    return this.getMarkers().findIndex((marker) => marker.id === identifier)
  }

  getResults() {
    if (this.state.objectType === SEARCH_OBJECT_PRODUCERS) {
      return this.getMapSearchManager().getResultsFor(this.getMapSearchType())
    } else if (this.state.objectType === SEARCH_OBJECT_PRODUCTS) {
      return this.getFilterSearchManager().getResultsFor(
        this.getFilterSearchType(),
      )
    }
  }

  async getMarkerResults(markerId) {
    const { algoliaSearch } = this.props
    const manager = this.getFilterSearchManager()
    await manager.setFilter(PRODUCT_FILTER_PRODUCER_ID, markerId)
    return manager.loadMoreResultsFor(LOAD_PRODUCER_PRODUCTS, { algoliaSearch })
  }

  getNumOfInitialResultsToRender() {
    if (this.forProducers()) {
      return 3
    } else if (this.forProducts()) {
      return 2
    }
  }

  getMarkerIdFromResult(resource) {
    if (this.forProducts()) {
      return resource.shopId
    } else if (this.forProducers()) {
      return resource.id
    }
  }

  compareMarkerIdToResult(id, result) {
    if (this.forProducts()) {
      return result.shopId === id
    } else if (this.forProducers()) {
      return result.id === id
    }
  }

  forProducers() {
    return this.state.objectType === SEARCH_OBJECT_PRODUCERS
  }

  forProducts() {
    return this.state.objectType === SEARCH_OBJECT_PRODUCTS
  }
  /* #endregion */

  /* #region - FILTER MODAL */

  filterModal = () => {
    const searchType = this.getFilterSearchType()
    const searchManager = this.getFilterSearchManager()
    const filters = searchManager.getCurrentFiltersFor(searchType)
    const SearchInput = (
      <TextInputFB
        value={filters.query}
        style={styles.searchInputWrapper}
        inputStyle={styles.searchInput}
        placeholder={i18n.t(`search.placeholders.${this.state.objectType}`)}
        onChange={this.onSearchTextChange}
        onClear={this.onSearchTextClear}
      />
    )
    return (
      <ObjectSearchCriteria
        searchInput={SearchInput}
        isVisible={this.state.criteriaModalVisible}
        setVisibility={this.setVisibility}
        objectSearchManager={searchManager}
        searchType={searchType}
        search={this.modalFilterSearch}
      />
    )
  }

  setVisibility = (visibility) => {
    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.criteriaModalVisible = visibility
      })
    }
    this.setState(nextState)
  }

  onSearchTextChange = async (value) => {
    this.getFilterSearchManager().setFilter('query', value)
  }

  onSearchTextClear = () => {
    this.onSearchTextChange('')
  }

  showMarkerResultsModal = () => {
    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.markerResultsModalVisible = true
      })
    }
    this.setState(nextState)
  }

  hideMarkerResultsModal = () => {
    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.markerResultsModalVisible = false
      })
    }
    this.setState(nextState)
  }

  markerResultsModal = () => {
    const { markerResultsModalVisible } = this.state
    const results = this.productSearchManager.getResultsFor(
      LOAD_PRODUCER_PRODUCTS,
    )
    const isVisible = markerResultsModalVisible && results.length > 0
    return (
      <Modal
        transparent={true}
        animationType='slide'
        visible={isVisible}
        onRequestClose={this.onMarkerResultsModalClose}
      >
        {Platform.OS === 'android' ? (
          <StatusBar backgroundColor='rgba(0,0,0,0.5)' />
        ) : null}
        {this.markerResults()}
      </Modal>
    )
  }
  onMarkerResultsModalClose = () => {
    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.markerResultsModalVisible = false
        draft.wereVisisble = false
      })
    }
    this.setState(nextState)
  }
  /* #endregion */

  /* #region - MAP */
  map = () => {
    const { initialRegion } = this.state
    return (
      <MapView
        minZoomLevel={2}
        showsUserLocation
        showsMyLocationButton
        loadingEnabled
        toolbarEnabled
        provider='google'
        style={{
          flex: 1,
          flexBasis: this.getMapHeight(),
        }}
        initialRegion={initialRegion}
        onRegionChangeComplete={this.onRegionChangeComplete}
        ref={this.setMapRef}
        onPress={this.onMapPress}
        onMapReady={this.onMapReady}
      >
        {this.renderMapMarkers()}
      </MapView>
    )
  }

  setMapRef = (ref) => {
    this.mapRef = ref
  }

  renderMapMarkers = () => {
    // console.log('<<<RENDERING MAP MARKERS')
    this.markerRefs = {}
    const markers = this.getMarkers()
    return markers
      .map((marker) => {
        const { _geoloc, ...producer } = marker
        if (!_geoloc) {
          return null
        }
        const { lng: longitude, lat: latitude } = _geoloc
        const active = producer.id === this.state.activeMarkerId

        if (!longitude || !latitude) return

        const optionalProps = {}
        if (Platform.OS === 'android') {
          optionalProps.onPress = (e) => {
            this.onMarkerPress(e, producer.id)
          }
        }
        return (
          <MapView.Marker
            {...optionalProps}
            ref={(ref) => this.setMarkerRef(ref, producer.id)}
            key={`${producer.id}`}
            coordinate={{ longitude, latitude }}
          >
            <ProducerMapMarker producer={producer} active={active} />
          </MapView.Marker>
        )
      })
      .filter((marker) => marker !== null)
  }

  setMarkerRef = (ref, id) => {
    this.markerRefs[id] = ref
  }

  onMapReady = () => {
    //console.log('<<<MAP READY')
    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.mapReady = true
      })
    }
    this.setState(nextState, () => this.mapReadyCallback())
  }

  onRegionChangeComplete = (newRegion) => {
    // console.log('<<<NEW REGION', newRegion)
    if (this.previousTime) {
      //console.log(new Date() - this.previousTime)
    }
    this.previousTime = new Date()
    let { initialRegion, firstRegionChange, markerPressCoordinate } = this.state

    //1. Must be valid region. Sometimes geo coordinates given are greater/less than 180/-180
    if (!this.isValidRegion(newRegion)) {
      return
    }

    if (firstRegionChange) {
      //console.log('<<<FIRST REGION CHANGE')
      const nextState = (state, props) => {
        return produce(state, (draft) => {
          draft.firstRegionChange = false
        })
      }
      this.setState(nextState)
      return
    }

    let promptForSearch = false
    const searchType = this.getMapSearchType()
    const filters = this.getMapSearchManager().getCurrentFiltersFor(searchType)
    const previousSearchedRegion = filters.region || initialRegion
    const {
      longitudeDelta: previousLngDelta,
      latitudeDelta: previousLatDelta,
      ...previousPoint
    } = previousSearchedRegion
    const {
      longitudeDelta: newLngDelta,
      latitudeDelta: newLatDelta,
      ...newPoint
    } = newRegion

    // 5. Don't attempt to determine redo search if this region change is from moving to marker
    if (!this.isMovingToMarker()) {
      //console.log('<<<DETERMINING REDO SEARCH')
      if (this.zoomed(previousLngDelta, newLngDelta)) {
        //console.log('<<<REDO SEARCH : ZOOM LEVEL CHANGED')
        promptForSearch = true
      } else if (
        this.crossedPanThreshold(
          previousSearchedRegion,
          previousPoint,
          newPoint,
        )
      ) {
        //console.log('<<<REDO SEARCH : PAN THRESHOLD CROSSED')
        promptForSearch = true
      }
    }

    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.region = newRegion
        draft.promptForSearch = promptForSearch
        if (
          draft.markerPressCoordinate &&
          this.samePoint(draft.markerPressCoordinate, newRegion)
        ) {
          //console.log('<<<MAP - FINISHED MOVING TO MARKER COORDINATE')
          draft.markerPressCoordinate = undefined
        }
        if (draft.movingToMarker) {
          draft.movingToMarker = false
        }
      })
    }
    this.setState(nextState)
  }

  isMovingToMarker = (state = this.state) => {
    return this.state.markerPressCoordinate || this.state.movingToMarker
  }

  isValidRegion(region) {
    return (
      region.longitude <= 180 &&
      region.longitude >= -180 &&
      region.latitude <= 90 &&
      region.latitude >= -90
    )
  }

  zoomed(previousLngDelta, newLngDelta) {
    const previousZoomLevel = getZoomLevelForLngDelta(previousLngDelta)
    const newZoomLevel = getZoomLevelForLngDelta(newLngDelta)
    //console.log('<<<ZOOM DIFFERENCE', previousZoomLevel, ' vs ', newZoomLevel)
    return Math.floor(previousZoomLevel) !== Math.floor(newZoomLevel)
  }

  samePoint(point1, point2) {
    const oneMillionth = 0.000001
    return (
      Math.abs(point1.latitude - point2.latitude) <= oneMillionth &&
      Math.abs(point1.longitude - point2.longitude) <= oneMillionth
    )
  }

  crossedPanThreshold(region, previousPoint, newPoint) {
    const radius = getDistanceFromRightEdge(region)
    const panDistance = distance(previousPoint, newPoint, 'K')
    // console.log('<<< RADIUS IN KM FOR PAN THRESHOLD CHECK', radius)
    return panDistance > radius
  }

  onMarkerPress = (e, identifier) => {
    if (Platform.OS === 'android') {
      e.stopPropagation()
      //console.log('<<<Android pressed on marker', identifier)
      this.markerPressedAction(identifier, e.nativeEvent.coordinate)
    }
  }

  onMapPress = async (e) => {
    e.stopPropagation()
    if (e.nativeEvent.action === 'marker-press' && Platform.OS === 'ios') {
      //console.log('<<<iOS pressed on marker')
      // NOTE : Relying on work around to get the 'identifier' prop value that was originally given to the pressed MapMarker
      // https://github.com/0react-native-community/react-native-maps/issues/218
      const identifier = _.get(e, '_targetInst.return.key')
      if (!identifier) {
        //console.log('<<<KEY NOT FOUND')
        return
      }
      this.markerPressedAction(identifier, e.nativeEvent.coordinate)
    } else {
      //console.log('<<<Pressed elsewhere on map')
      await this.productSearchManager.clearResultsFor(LOAD_PRODUCER_PRODUCTS)
      this.hideMarkerResultsModal()
    }
  }

  // Either scroll to result in carousel. Or show results for marker.
  // Before moving to marker on the map
  markerPressedAction = async (identifier, markerPressCoordinate) => {
    let scrollToResult = this.forProducers()
    if (!scrollToResult) {
      scrollToResult = await this.shouldScrollToResultForProducts(identifier)
    }
    // console.log('<<<MARKER PRESS COORDINATE', markerPressCoordinate)
    if (scrollToResult) {
      const index = this.getResults().findIndex((result) =>
        this.compareMarkerIdToResult(identifier, result),
      )
      if (index > -1 || index < this.getResults().length) {
        //console.log('<<<Result To Scroll To', index)
        this.resultsRef.scrollToIndex({
          index,
          viewOffset: 0,
          viewPosition: 0,
        })
      }
    } else if (this.forProducts()) {
      this.showMarkerResultsModal()
    }

    this.moveToMarker(identifier, markerPressCoordinate)
  }

  shouldScrollToResultForProducts = async (identifier) => {
    const markerResults = await this.getMarkerResults(identifier)
    // console.log('<<<NUM OF RESULTS FOR MARKER ', markerResults.length)
    return markerResults.length === 1
  }

  moveToMarker(identifier, markerPressCoordinate = undefined) {
    //console.log('<<<MOVING TO MARKER', identifier)
    const marker = this.getMarkers().find((marker) => marker.id === identifier)
    if (!marker) {
      return // marker not found. don't attempt move.
    }

    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.activeMarkerId = identifier
        if (markerPressCoordinate) {
          draft.markerPressCoordinate = markerPressCoordinate
        }
        draft.movingToMarker = true
      })
    }

    this.setState(nextState, () => {
      if (!marker._geoloc) {
        return // _geoloc not defined for some reason. Dont do anything.
      }
      const { lat: latitude, lng: longitude } = marker._geoloc
      const { region } = this.state
      const distanceFromRight = getDistanceFromRightEdge(region)
      const deltas = this.getDeltas(latitude, distanceFromRight)
      const nextRegion = Object.assign({ latitude, longitude }, deltas)
      this.animateToRegion(nextRegion)
    })
  }

  animateToRegion = (region) => {
    // console.log('<<<MAP - ANIMATE TO', region)
    requestAnimationFrame(() => {
      this.mapRef.animateToRegion(region, 300)
    })
  }
  /* #endregion */

  /* #region - RESULTS */
  markerResults = () => {
    let results = this.productSearchManager.getResultsFor(
      LOAD_PRODUCER_PRODUCTS,
    )
    const producer = this.getActiveMarker()
    const shopName = (producer && producer.displayName) || 'Shop Name'
    return (
      <View>
        <View
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            height: this.getMarkerResultsOffset(),
          }}
        >
          <Text />
        </View>
        <View
          style={[
            {
              backgroundColor: 'white',
              height: this.getMarkerResultsHeight(),
              width: this.getContentWidth(),
            },
            false && {
              top: this.getMarkerResultsOffset(),
              position: 'absolute',
            },
          ]}
        >
          <View style={[styles.modalHeader]}>
            <TouchableOpacity onPress={this.onMarkerResultsModalClose}>
              <Icons style={styles.icon} name='ios-close' size={50} />
            </TouchableOpacity>
            <Text numberOfLines={1} style={[styles.titleText]}>
              {i18n.t('search.map.from') + shopName}
            </Text>
            <TouchableOpacity onPress={() => {}}>
              <Text />
            </TouchableOpacity>
          </View>
          <FlatList
            initialNumToRender={this.getNumOfInitialResultsToRender()}
            style={[{ height: this.getResultsHeight() }]}
            horizontal
            alwaysBounceHorizontal={true}
            alwaysBounceVertical={false}
            showsHorizontalScrollIndicator={false}
            data={results}
            renderItem={this.renderMarkerCarouselResult}
            keyboardDismissMode='on-drag'
            keyExtractor={(item, index) => {
              return item.id
            }}
            contentContainerStyle={[styles.markerResultsContentContainer]}
            onEndReachedThreshold={0.4}
            onEndReached={this.onMarkerResultsEndReached}
            getItemLayout={this.getResultLayout}
          />
        </View>
      </View>
    )
  }

  results = () => {
    // console.log('<<<MAP - Rendering Results')
    let results = this.getResults()
    const optionalProps = {}
    if (this.forProducts()) {
      optionalProps.getItemLayout = this.getResultLayout
    }
    // const flexBasis = results.length ? this.getResultsHeight() : 0
    // const custom = { flexBasis }
    // if (!flexBasis) {
    //   custom.flex = 0
    // }

    const containerStyles = [styles.resultsContentContainer]
    if (results.length === 0) {
      containerStyles.push(styles.emptyResultsContentContainer)
    }

    const custom = { flexBasis: this.getResultsHeight() }
    return (
      <FlatList
        initialNumToRender={this.getNumOfInitialResultsToRender()}
        style={[styles.results, custom]}
        horizontal
        overScrollMode={'always'}
        bounces={true}
        onScrollBeginDrag={this.onScrollBeginDrag}
        onScrollEndDrag={this.onScrollEndDrag}
        alwaysBounceHorizontal={true}
        alwaysBounceVertical={false}
        showsHorizontalScrollIndicator={false}
        ref={this.setResultsRef}
        data={results}
        renderItem={this.renderCarouselResult}
        keyboardDismissMode='on-drag'
        keyExtractor={(item, index) => {
          return item.id
        }}
        getItemLayout={(data, index) => (
          { length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index }
        )}
        contentContainerStyle={[containerStyles]}
        onEndReachedThreshold={0.4}
        onEndReached={this.onEndReached}
        viewabilityConfig={this.viewabilityConfig}
        onViewableItemsChanged={this.onViewableItemsChanged}
        ListEmptyComponent={this.statusMessage}
        {...optionalProps}
      />
    )
  }

  onScrollBeginDrag = () => {
    //console.log('<<< CAROUSEL - Scroll Drag Begins')
    if (!this.state.noMoreResults && !this.state.haveScrolled) {
      const nextState = (state, props) => {
        return produce(state, (draft) => {
          draft.haveScrolled = true
        })
      }
      this.setState(nextState)
    }
  }

  onScrollEndDrag = (e) => {
    //console.log('<<<CAROUSEL - Scroll Drag Ends Velocity', _.get(e, 'nativeEvent.velocity.x'))
    // console.log('<<<CAROUSEL - Scroll Drag Ends Event', _.get(e, 'nativeEvent'))
    if (
      this.state.noMoreResults &&
      !this.state.haveScrolled &&
      this.getResults().length === 2
    ) {
      //console.log('<<<CAROUSEL - Move to previous or next result')
      let velocity = _.get(e, 'nativeEvent.velocity.x', 0)
      // for some reason the velocity is backwards on Android
      // swiping from the right is negative and left is positive
      if (Platform.OS === 'android') {
        velocity *= -1
      }
      const results = this.getResults()
      let result = undefined
      if (velocity === 0) {
        return
      } else if (velocity < 0) {
        result = _.first(results)
      } else {
        result = _.last(results)
      }

      if (result.id === this.lastFocusedItemId) {
        return // dont do it again
      }
      const markerId = this.getMarkerIdFromResult(result)
      this.lastFocusedItemId = result.id
      this.moveToMarker(markerId)
    }
  }

  haveResults = () => {
    const results = this.getResults()
    //console.log('Num of results:', results.length)
    return results.length !== 0
  }

  getResultLayout = (data, index) => {
    const width = maxResultWidth(this.getContentWidth()) + 15
    return {
      length: width,
      offset: width * index,
      index,
    }
  }

  renderCarouselResult = ({ item, index }) => {
    // console.log('rendering carousel result')
    const { length } = this.getResults()
    return this.renderResult({ item, index, length })
  }

  renderMarkerCarouselResult = ({ item, index }) => {
    // console.log('rendering marker carousel result')
    const { length } = this.productSearchManager.getResultsFor(
      LOAD_PRODUCER_PRODUCTS,
    )
    return this.renderResult({ item, index, length })
  }

  renderResult = ({ item: result, index, length }) => {
    const maxWidth = this.getContentWidth()
    const item = JSON.parse(JSON.stringify(result))
    const active =
      this.state.activeMarkerId === this.getMarkerIdFromResult(result)
    if (item.shopName) {
      // For products
      item.profile = { displayName: item.shopName }
    }
    if (this.forProducts()) {
      const dynamicProps = {}
      if (index === 0) {
        dynamicProps.marginLeft = 15
      }
      return (
        <ProductResult
          key={item.id}
          active={active}
          product={item}
          onPress={this.goToProduct}
          contentMaxWidth={maxWidth}
          marginRight={15}
          {...dynamicProps}
        />
      )
    } else if (this.forProducers()) {
      const { address } = item
      let detailsStyle = {
        height: this.getResultsHeight(),
        justifyContent: 'center',
        ...styles.producerDetails,
      }
      if (active) {
        detailsStyle = { ...detailsStyle, ...styles.producerActive }
      }
      const style = {}
      if (index === length - 1) {
        style.marginRight = 50
      }
      return (
        <View key={item.id} style={style}>
          <ProducerResult
            height={this.getResultsHeight()}
            style={styles.producer}
            user={item}
            rating={item.rating}
            ratings={item.ratings}
            address={address}
            nameStyle={styles.producerName}
            locationStyle={styles.producerLocation}
            imageWrapperStyle={{
              height: this.getResultsHeight(),
              justifyContent: 'center',
            }}
            detailsStyle={detailsStyle}
            imageSize={46}
          />
        </View>
      )
    }
  }

  goToProduct = (product) => {
    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.markerResultsModalVisible = false
        // TODO: Enable when we have handling in place for coming back to Map view of search from object page
        //draft.markerResultsModalWasVisible = true
      })
    }
    this.setState(nextState, () => this.props.goToProduct(product))
  }

  setResultsRef = (ref) => {
    this.resultsRef = ref
  }

  onViewableItemsChanged = ({ viewableItems, changed }) => {
    // console.log('Viewable Items Changed', viewableItems)
    if (!this.isMovingToMarker() && viewableItems.length) {
      if (this.forProducers() && this.getResults().length <= 2) {
        return // in this case we don't want a boomerang effect on ios where we go the last item and then return to the first
      }
      const item = viewableItems[0].item
      const markerId = this.getMarkerIdFromResult(item)

      if (
        markerId !== this.state.activeMarkerId &&
        item.id !== this.lastFocusedItemId
      ) {
        //console.log('<<<MAP - Moving to new marker on viewable items change')
        this.moveToMarker(markerId)
      }

      this.lastFocusedItemId = item.id
    }
  }

  onEndReached = async () => {
    // console.log('<<<CAROUSEL - END OF RESULtS ')
    const { algoliaSearch } = this.props
    const manager = this.getFilterSearchManager()
    const searchType = this.getFilterSearchType()
    await manager.loadMoreResultsFor(searchType, { algoliaSearch })
    if (!manager.loadMoreFor(searchType)) {
      //console.log('<<<CAROUSEL - NO MORE RESULTS')
      const nextState = (state, props) => {
        return produce(state, (draft) => {
          draft.noMoreResults = true
        })
      }
      this.setState(nextState)
    }
  }

  onMarkerResultsEndReached = () => {
    const { algoliaSearch } = this.props
    const manager = this.getFilterSearchManager()
    manager.loadMoreResultsFor(LOAD_PRODUCER_PRODUCTS, { algoliaSearch })
  }
  /* #endregion */

  /* #region - ACTIONS */
  actions = () => {
    const numOfFilters = this.getFilterSearchManager().getCriteriaCountFor(
      this.getFilterSearchType(),
    )
    const bottom = this.getResultsHeight() + tabBarHeight + 15
    return (
      <React.Fragment>
        {!this.state.markerResultsModalVisible && (
          <View
            pointerEvents='box-none'
            style={[styles.filtersActionContainer, { bottom }]}
          >
            <TouchableOpacity
              style={styles.filtersAction}
              onPress={this.showCriteria}
            >
              <Text style={[styles.filtersActionText]}>
                {i18n.t('search.filters.general.title')}
              </Text>
              {numOfFilters === 0 ? (
                <Octicons
                  name='settings'
                  color={colors.text.main}
                  size={18}
                  style={[styles.filtersActionIcon]}
                />
              ) : (
                <View style={styles.selectedFiltersContainer}>
                  <Text style={styles.selectedFiltersText}>{numOfFilters}</Text>
                </View>
              )}
            </TouchableOpacity>
          </View>
        )}
        {!this.state.markerResultsModalVisible && (
          <TouchableOpacity style={[styles.closeAction]} onPress={this.goBack}>
            <Icons name='ios-close' size={50} color={colors.text.main} />
          </TouchableOpacity>
        )}
        {this.state.promptForSearch && !this.state.markerResultsModalVisible && (
          <View pointerEvents='box-none' style={styles.searchActionContainer}>
            {
              <Button
                label={
                  !this.state.loading
                    ? i18n.t('search.map.redoSearch')
                    : `${i18n.t('common.loading')}...`
                }
                labelStyle={styles.searchActionText}
                style={styles.searchAction}
                onPress={this.redoSearch}
              />
            }
          </View>
        )}
      </React.Fragment>
    )
  }

  // For Producer - Map Markers and Results are the same
  // For Products - Map Markers and Results are different. Must call getResults in addition.
  redoSearch = async () => {
    const { algoliaSearch } = this.props
    if (this.state.loading) {
      return // Don't do anything on the loading of new search results
    }
    this.setState({ loading: true })
    // NOTE: Always done so that results are always constrained to map view
    await this.setSearchedRegion()
    const mapManager = this.getMapSearchManager()
    // Producers - Just do a search for markers which are shown on map and results
    if (this.forProducers()) {
      await this.searchFor(this.getMapSearchType(), mapManager)
      // Products - Do search for map markers(i.e. producers) and search for results(i.e. products)
    } else if (this.forProducts()) {
      // 1. Refresh the marker results
      const markerResults = await mapManager.loadMoreResultsFor(this.getMapSearchType(), { algoliaSearch })
      // 2. NOTE: always done so that the results shown below the map are constrained to producer markers in the map view
      const ids = markerResults.map((marker) => marker.id)
      const filterManager = this.getFilterSearchManager()
      await filterManager.setFilter(PRODUCT_FILTER_PRODUCER_IDS, ids)
      // Don't search any further if no markers
      if (ids.length) {
        // 3. Perform search for products
        await this.filterSearch()
        // Clear the results since there shouldn't be any for the area
      } else {
        //console.log('<<<MAP - NO MARKERS. CLEARING RESULTS.')
        filterManager.clearResultsFor(this.getFilterSearchType())
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve()
          }, 500)
        })
      }
    }

    if (this.getResults().length) {
      setTimeout(() => {
        this.resultsRef && this.resultsRef.scrollToOffset({
          offset: 0,
        })
      }, 500)
    }

    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.loading = false
        draft.promptForSearch = false
        if (!this.state.initialRegionSearchCompleted) {
          //console.log('<<<MAP - FIRST REGION SEARCH COMPLETED')
          draft.initialRegionSearchCompleted = true
        }
      })
    }
    this.setState(nextState)
  }

  setSearchedRegion = async (region = this.state.region) => {
    await this.getMapSearchManager().setFilter(PRODUCER_FILTER_REGION, region)
    const radiusInMeters = getRadiusFromRegion(region)
    return this.getMapSearchManager().setFilter(
      PRODUCER_FILTER_RADIUS,
      radiusInMeters,
    )
  }

  modalFilterSearch = async () => {
    const results = await this.filterSearch()

    if (results.length) {
      setTimeout(() => {
        this.resultsRef.scrollToOffset({
          offset: 0,
        })
      }, 500)
    }

    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.loading = false
      })
    }
    this.setState(nextState)
  }

  filterSearch = async () => {
    this.setState({ loading: true })
    const manager = this.getFilterSearchManager()
    return this.searchFor(this.getFilterSearchType(), manager)
  }

  searchFor = async (searchType, manager) => {
    const { algoliaSearch } = this.props
    return new Promise((resolve, reject) => {
      const nextState = (state, props) => {
        return produce(state, (draft) => {
          draft.noMoreResults = false
          draft.haveScrolled = false
        })
      }
      this.setState(nextState, async () => {
        //console.log('SEARCHING FOR', searchType)
        // TODO: This hackery is for sorting the products/producers by createdAtDesc. Just like on the Form view as the initial sorting if no sort has been selected.
        const defaultSort =
          !this.state.initialRegionSearchCompleted && !manager.getCurrentSort()
            ? 'createdAtDesc'
            : undefined
        let results = await manager.loadMoreResultsFor(searchType, { defaultSort, algoliaSearch })
        if (results.length) {
          //console.log('<<<MAP Moving to new marker on search')
          const markerId = this.getMarkerIdFromResult(results[0])
          const move = () => this.moveToMarker(markerId)
          move()
        }
        resolve(results)
      })
    })
  }

  showCriteria = () => {
    this.setVisibility(true)
  }

  goBack = () => {
    this.exportState()
    this.props.goBack()
  }
  /* #endregion */

  statusMessage = () => {
    const filterCount = this.getFilterSearchManager().getCriteriaCountFor(
      this.getFilterSearchType(),
    )
    let message =
      i18n.t('search.misc.notFound', {
        subject: i18n.t(`search.tabs.${this.state.objectType}`),
      }) +
      '\n' +
      i18n.t('search.misc.changeFilter1') +
      '\n' +
      i18n.t('search.misc.changeFilter2')

    // if (filterCount) {
    //   message += ' or adjust filter settings'
    // }
    message += '.'
    return <Status style={styles.status} message={message} />
  }
  render() {
    const { initialRegion } = this.state
    if (!initialRegion) {
      return null
    }
    return (
      <View style={[styles.container, sharedStyles.tabbedScreen]}>
        {this.filterModal()}
        {this.map()}
        {this.results()}
        {this.markerResultsModal()}
        {this.actions()}
      </View>
    )
  }
}
Maps.navigationOptions = () => {
  return {
    header: () => null,
  }
}

export default Maps

/* #region - STYLES */

const styles = stylus({
  container: { flex: 1 },
  closeAction: {
    backgroundColor: 'rgba(0,0,0,0)',
    position: 'absolute',
    top: statusBarHeight,
    right: 10,
  },
  searchActionLabel: {
    fontSize: 14,
    color: 'white',
    fontWeight: '400',
  },
  searchAction: {
    borderRadius: 10,
    padding: 9,
    paddingHorizontal: 10,
    backgroundColor: colors.primary,
  },
  searchActionContainer: {
    position: 'absolute',
    top: statusBarHeight + 30,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
  },
  filtersActionText: {
    fontSize: 12,
    color: colors.text.main,
    fontWeight: '400',
    marginRight: 5,
  },
  filtersAction: {
    padding: 7,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 10,
  },
  filtersActionContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  filtersActionIcon: {
    transform: [{ rotateZ: '90deg' }],
  },
  selectedFiltersContainer: {
    backgroundColor: colors.primary,
    borderRadius: 8,
    width: 16,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedFiltersText: {
    marginTop: -1,
    color: 'white',
    fontSize: 9,
    fontWeight: 'bold',
  },
  status: {
    paddingHorizontal: 20,
  },
  results: {
    flex: 1,
  },
  markerResultsContentContainer: {
    alignSelf: 'flex-start',
  },
  icon: {
    paddingTop: 2,
    color: colors.text.main,
  },
  modalHeader: {
    paddingHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleText: {
    fontSize: 18,
    paddingHorizontal: 5,
    fontWeight: '600',
    color: colors.text.main,
  },
  resultsContentContainer: {
    alignSelf: 'center',
    borderColor: null,
    borderWidth: 0,
    paddingBottom: 0,
    android: {
      paddingBottom: 0,
    },
    iphonex: {
      paddingBottom: 0,
    },
  },
  emptyResultsContentContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  focusedResult: {
    borderColor: colors.primary,
    borderWidth: 3,
    borderStyle: 'solid',
  },

  // Producer Result
  producer: {
    marginBottom: 5,
    paddingHorizontal: 0,
  },
  producerName: {
    fontWeight: '500',
    color: colors.text.main,
    fontSize: 15,
  },
  producerLocation: {
    fontSize: 14,
    color: colors.text.secondary,
    marginBottom: 2,
  },
  producerDetails: {
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
    paddingBottom: 10,
  },
  producerActive: {
    borderBottomWidth: 2,
    borderBottomColor: colors.primary,
  },
})
/* #endregion */
