import React from 'react'
import { TouchableOpacity, View } from 'react-native'

import PropTypes from 'prop-types'
import colors from '../../config/colors'
import Icon from '../../config/icon'
import withPostActions from '../../containers/withPostActions'
import { compose, connect } from '../../config/connected'
import LikeButton from '../LikeButton/LikeButton'
import { ANALYTICS_LIKES_POST } from '../../reducers/analytics'

class PostActionBar extends React.PureComponent {
  static propTypes = {
    post: PropTypes.object,
    goToComments: PropTypes.func,
    unlikePost: PropTypes.func,
    likePost: PropTypes.func,
    unsavePost: PropTypes.func,
    savePost: PropTypes.func,
    analyticsLikesPost: PropTypes.func,
  }

  onLike = () => {
    const { post } = this.props
    if (post.iLike) {
      this.props.unlikePost(post)
    } else {
      this.props.likePost(post)
      this.props.analyticsLikesPost(post.type)
    }
  }

  onSave = () => {
    const { post } = this.props
    if (post.iSave) {
      this.props.unsavePost(post)
    } else {
      this.props.savePost(post)
    }
  }

  render() {
    const { post, style } = this.props
    // const { post: iLike, iSave } = this.props
    const iconSize = 32

    const { iLike, iSave } = post

    return (
      <View style={[styles.container, style]}>
        <LikeButton
          style={styles.item}
          onPress={this.onLike}
          name={'like'}
          iconSize={iconSize}
          iLike={iLike}/>
        <TouchableOpacity
          style={styles.item}
          onPress={this.props.goToComments}
          name='chat'
        >
          <Icon
            name='chat-outline'
            size={iconSize}
            color={colors.icon.strong}
          />
        </TouchableOpacity>
        {/* <TouchableOpacity style={styles.item} onPress={null} name='share'>
          <Icon
            name='send-outline'
            size={iconSize}
            color={colors.icon.strong}
          />
        </TouchableOpacity> */}
        {/* <TouchableOpacity
          style={styles.bookmark}
          onPress={this.onSave}
          name='bookmark'
        >
          <Icon
            name={iSave ? 'save' : 'save-outline'}
            size={iconSize}
            color={colors.icon.strong}
          />
        </TouchableOpacity> */}
      </View>
    )
  }
}
const styles = {
  container: {
    flexDirection: 'row',
    paddingLeft: 12,
    paddingRight: 10,
  },
  item: {
    marginRight: 6,
  },
  bookmark: {
    marginLeft: 'auto',
  },
}


export default compose(
  connect(null, (dispatch) => ({
    analyticsLikesPost: (type, taggedProducts) => {
      dispatch({
        type: ANALYTICS_LIKES_POST,
        data: {
          type,
          taggedProducts,
        },
      })
    },
  })),
  withPostActions,
)(PostActionBar)
