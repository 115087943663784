import PropTypes from 'prop-types'
import React from 'react'
import { StyleSheet } from 'react-native'
import TextInput from '../../components/simple/TextInput'

class Search extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    style: PropTypes.object,
  }

  state = {
    value: '',
    focused: false,
  }

  onChange = (value) => {
    // console.log(vent)
    this.setState((prev) => {
      return { ...prev, value }
    })
  }

  onClear = () => {
    this.setState({ value: '' })
  }

  render() {
    const { placeholder, ...rest } = this.props
    const { value } = this.state

    return (
      // <View style={[styles.container, style]}>
      // <View style={styles.inputWrapper}>
      //  <Icons name="ios-search" size={26} color="#666" />
      <TextInput
        thin
        placeholder={placeholder}
        maxLength={40}
        value={value}
        onChange={this.onChange}
        onClear={this.onClear}
        // onFocus={this.onFocus.bind(this)}
        // onBlur={this.onBlur.bind(this)}
        // ref={ref => (this.input = ref)}
        underlineColorAndroid='transparent'
        iconName='ios-search'
        size={14}
        {...rest}
      />
      //    <TouchableOpacity onPress={this.clear.bind(this)}>
      //      <Icons
      //        name="md-close-circle"
      //        size={26}
      //        color="rgba(0,0,0,0.2)"
      //        style={this.clearIconStyle()}
      //      />
      //    </TouchableOpacity>
      //  </View>
      //  <Action
      //    onPress={this.cancel.bind(this)}
      //    label={this.cancelLabel()}
      //    style={focused || text.length ? styles.cancel : styles.cancelHidden}
      //  />
      // </View>
    )
  }
}

export default Search

const styles = StyleSheet.create({
  container: {
    // paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputWrapper: {
    height: 46,
    borderRadius: 6,
    backgroundColor: 'rgba(0,0,0,0.06)',
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  input: {
    color: '#333',
    fontSize: 20,
    paddingLeft: 10,
    flex: 1,
  },
  cancel: {
    paddingLeft: 12,
  },
  cancelHidden: {
    width: 0,
  },
})
