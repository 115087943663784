import React from 'react'
import { View, Text, ActivityIndicator } from 'react-native'
import NavigationActions from '../../../utility/navigationActions'

import Button from '../../../components/simple/Button'
import { Status } from '../../../components/simple/Status'
import MobileBackButton from '../../../components/simple/MobileBackButton'
import Scroller from '../../../components/Scroller/Scroller'
import StripeElementsCardModal from '../../../components/Stripe/StripeElementsCard/StripeElementsCardModal'
import PaymentMethod from './PaymentMethod'
import confirm from '../../../utility/confirm'

import { stylus } from '../../../config/styles'
import colors from '../../../config/colors'
import sizes from '../../../config/sizes'

import i18n from 'i18n-js'
import _ from 'lodash'
import { graphql, connect } from '../../../config/connected'
import paymentSchema from '../../../schema/payment'
import { updateCachedQueryValue } from '../../../utility/apollo'
import { ROUTE_PAYMENT_METHODS, ROUTE_CHOOSE_PAYMENT_METHOD } from '../../../config/constants'
import { ANALYTICS_CREDIT_CARD_REMOVAL, ANALYTICS_PAYMENT_METHOD_PAGE_LOAD } from '../../../reducers/analytics'
import PropTypes from 'prop-types'

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  analyticsPaymentMethodPageLoad: () => {
    dispatch({
      type: ANALYTICS_PAYMENT_METHOD_PAGE_LOAD,
      data: {},
    })
  },
  analyticsCreditCardRemoval: () => {
    dispatch({
      type: ANALYTICS_CREDIT_CARD_REMOVAL,
      data: {},
    })
  },
})
@connect(null, mapDispatchToProps)
@graphql(paymentSchema.queries.listStripeCustomerPaymentMethods, {
  name: 'listStripeCustomerPaymentMethodsHandler',
})
@graphql(paymentSchema.mutations.deleteStripePaymentMethod, {
  name: 'deleteStripePaymentMethod',
})
@graphql(paymentSchema.mutations.makeDefaultStripePaymentMethod, {
  name: 'makeDefaultStripePaymentMethod',
})
class PaymentMethods extends React.Component {
  constructor(props) {
    super(props)
    this.state = { modalVisible: false, selectedPaymentMethodId: null }
  }

  componentDidMount() {
    this.props.analyticsPaymentMethodPageLoad()
  }

  modal() {
    return (
      <StripeElementsCardModal
        isVisible={this.state.modalVisible}
        closeAction={this.closeModal}
      />
    )
  }

  content() {
    const route = _.get(this.props, 'navigation.state.routeName')
    if (this.props.listStripeCustomerPaymentMethodsHandler.loading) {
      return (
        <View style={[styles.statusContainer]}>
          <ActivityIndicator size="large" color={colors.primary} />
          {/* <Status message={i18n.t('payment.paymentMethods.status.loadingItems')} /> */}
        </View>
      )
    }
    const { listStripeCustomerPaymentMethods: paymentMethods } = this.props.listStripeCustomerPaymentMethodsHandler
    if (!paymentMethods || !paymentMethods.length) {
      return (
        <View style={[styles.statusContainer]}>
          <Status message={i18n.t('payment.paymentMethods.status.noItems')} style={{backgroundColor: 'transparent'}} />
        </View>
      )
    }

    const paymentMethodElements = [...paymentMethods].sort((paymentMethod1, paymentMethod2) => {
      if (paymentMethod1.default) {
        return -1
      } else if (paymentMethod2.default) {
        return 1
      }
      return 0
    }).map((paymentMethod) => {
      const ChooseButton = (
        <Button
          style={styles.editAction}
          label={i18n.t('payment.paymentMethods.useThisCard')}
          labelStyle={styles.buttonLabel}
          onPress={() => this.choosePaymentMethod(paymentMethod.id)}
        />
      )
      const ManagementButtons = [
        /* TODO: If we were to allow editing */
        /* <Button
            style={styles.editAction}
            label={i18n.t('common.edit')}
            labelStyle={styles.buttonLabel}
            onPress={this.editPaymentMethod}
          /> */
        !paymentMethod.default &&
        <Button
          key='makeDefaultButton'
          style={styles.makeDefaultAction}
          label={i18n.t('payment.paymentMethods.makeDefault')}
          labelStyle={styles.buttonLabel}
          onPress={() => this.makeDefaultPaymentMethod(paymentMethod.id)}
        />,
        <Button
          key='deleteButton'
          style={styles.deleteAction}
          label={i18n.t('common.delete')}
          labelStyle={[styles.buttonLabel, styles.deleteActionLabel]}
          onPress={() => this.deletePaymentMethod(paymentMethod.id)}
        />
      ]
      return(
        <PaymentMethod
          key={paymentMethod.id}
          id={paymentMethod.id}
          style={styles.paymentMethodContainer}
          actionColumnStyle={route === ROUTE_CHOOSE_PAYMENT_METHOD ? styles.useThisCardActionColumn : null}
          brand={paymentMethod.card.brand}
          last4={paymentMethod.card.last4}
          expirationMonth={paymentMethod.card.exp_month}
          expirationYear={paymentMethod.card.exp_year}
          nameOnCard={paymentMethod.billing_details.name}
          fundingType={paymentMethod.card.funding}
          defaultCard={paymentMethod.default}
        >
          { route === ROUTE_PAYMENT_METHODS ? ManagementButtons : null }
          { route === ROUTE_CHOOSE_PAYMENT_METHOD ? ChooseButton : null}
        </PaymentMethod>
      )
    })

    return (
      <Scroller contentContainerStyle={[styles.contentContainer]}>
        { paymentMethodElements }
      </Scroller>
    )
  }

  render() {
    const route = _.get(this.props, 'navigation.state.routeName')
    return (
      <View style={[styles.container]}>
        { this.modal() }
        { this.content() }
        { route === ROUTE_PAYMENT_METHODS ?
          <View style={[styles.callToActionContainer, styles.footer]}>
            <View style={styles.callToActionTextContainer}/>
            <Button
              onPress={this.addCreditCard}
              label={i18n.t('payment.paymentMethods.addCreditCard')}
              labelStyle={styles.addLabel}
              style={styles.addButton}
            />
          </View>
          : null }

      </View>
    )
  }

  closeModal = () => {
    this.setState({ modalVisible: false })
  }

  deletePaymentMethod = async(paymentMethodId) => {
    const shouldDeletePaymentMethod = await confirm({
      title: i18n.t('payment.paymentMethods.deletePaymentMethod'),
      message: i18n.t('payment.paymentMethods.deletePaymentMethodWarning'),
    })
    if (!shouldDeletePaymentMethod) {
      return
    }
    await this.props.deleteStripePaymentMethod({
      variables: { paymentMethodId },
      update: this.removePaymentMethodFromCache
    })
    this.forceUpdate()
    this.props.analyticsCreditCardRemoval()
  }

  editPaymentMethod = (id) => {
    this.setState({ modalVisible: true, selectedPaymentMethodId: id })
  }

  choosePaymentMethod = async(paymentMethodId) => {
    const { navigation: { dispatch } } = this.props
    await this.makeDefaultPaymentMethod(paymentMethodId)
    NavigationActions.back()
  }

  makeDefaultPaymentMethod = async(paymentMethodId) => {
    await this.props.makeDefaultStripePaymentMethod({
      variables: { paymentMethodId },
      update: this.updatePaymentMethodInCache
    })
  }

  updatePaymentMethodInCache = (store, { data }) => {
    const { id } = data.makeDefaultStripePaymentMethod
    updateCachedQueryValue(store, {
      query: paymentSchema.queries.listStripeCustomerPaymentMethods,
      variables: {},
      nextValue: (paymentMethods = []) => {
        paymentMethods.forEach((paymentMethod) => paymentMethod.default = false)
        const paymentMethodIndex = paymentMethods.findIndex(paymentMethod => paymentMethod.id ===  id)
        if (paymentMethodIndex !== -1) {
          _.set(paymentMethods, `[${paymentMethodIndex}].default`, true)
        }
        return paymentMethods
      }
    })
    updateCachedQueryValue(store, {
      query: paymentSchema.queries.defaultStripePaymentMethod,
      variables: {},
      nextValue: () => {
        return data.makeDefaultStripePaymentMethod
      }
    })
  }

  removePaymentMethodFromCache = (store, { data }) => {
    const { deletedId, newDefaultId } = data.deleteStripePaymentMethod
    let defaultPaymentMethod = null
    updateCachedQueryValue(store, {
      query: paymentSchema.queries.listStripeCustomerPaymentMethods,
      variables: {},
      nextValue: (paymentMethods = []) => {
        const deletedPaymentMethodIndex = paymentMethods.findIndex(paymentMethod => paymentMethod.id === deletedId)
        if (deletedPaymentMethodIndex !== -1) {
          paymentMethods.splice(deletedPaymentMethodIndex, 1)
        }
        if (newDefaultId) {
          const defaultPaymentMethodIndex = paymentMethods.findIndex(paymentMethod => paymentMethod.id === newDefaultId)
          if (defaultPaymentMethodIndex !== -1) {
            defaultPaymentMethod = paymentMethods[defaultPaymentMethodIndex]
            defaultPaymentMethod.default = true
          }
        }
        return paymentMethods
      }
    })

    updateCachedQueryValue(store, {
      query: paymentSchema.queries.defaultStripePaymentMethod,
      variables: {},
      nextValue: (paymentMethod) => {
        if (paymentMethod && paymentMethod.id === deletedId) {
          if (defaultPaymentMethod) {
            return defaultPaymentMethod
          }
          return null
        }
        return paymentMethod
      }
    })
  }

  addCreditCard = () => {
    this.setState({ modalVisible: true })
  }
}

PaymentMethods.propTypes = {
  analyticsPaymentMethodPageLoad: PropTypes.func,
  analyticsCreditCardRemoval: PropTypes.func,
  deleteStripePaymentMethod: PropTypes.func,
  makeDefaultStripePaymentMethod: PropTypes.func,
  navigation: PropTypes.any,
  listStripeCustomerPaymentMethodsHandler: PropTypes.any,
}

PaymentMethods.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('payment.paymentMethods.title'),
    headerTitleStyle: { alignSelf: 'center', textAlign: 'center' },
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
    headerRight: () => (<View />)

  }
}

const styles = stylus({
  container: {
    flex: 1
  },
  contentContainer: {
  },
  statusContainer: {
    flex: 1,
    paddingHorizontal: 20,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  paymentMethodContainer: {
    // paddingHorizontal: 20,
    paddingTop: 5,
    paddingBottom: 10,
    borderBottomWidth: 2 * sizes.px,
    borderBottomColor: colors.thinLine,
  },
  callToActionContainer: {
    paddingTop: 10,
    // backgroundColor: '#F7F7F7',
    alignItems: 'center',
    paddingBottom: sizes.tabBarHeight,
    iphonex: {
      paddingBottom: sizes.iphonexTabBarHeight
    }
  },
  callToActionTextContainer: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  addButton: {
    backgroundColor: colors.text.soft,
    marginBottom: 5,
  },
  addLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
  buttonLabel: {
    fontSize: 16
  },
  editAction: {
    alignSelf: 'center',
    backgroundColor: colors.primary,
    padding: 5,
    marginTop: 3,
  },
  deleteAction: {
    backgroundColor: 'transparent',
    padding: 0,
    paddingRight: 12,
    alignItems: 'flex-end'
  },
  deleteActionLabel:{
    color: 'red',
    fontWeight: 'normal'
  },
  makeDefaultAction: {
    backgroundColor: colors.actions.selectDefault,
    padding: 5,
    marginTop: 3
  },
  useThisCardActionColumn: {
    justifyContent: 'center',
  },
})

export default PaymentMethods
