import React from 'react'
import PropTypes from 'prop-types'
import { View, Text, Platform } from 'react-native'
import shared, { stylus } from '../../../config/styles'
import colors from '../../../config/colors'
import countries from '../../../config/countries'
import guessCountry from '../../../utility/guessCountry'
import { updateCachedQueryValue } from '../../../utility/apollo'
import { graphql } from '../../../config/connected'
import schema from '../../../schema/cart'
import alert from '../../../utility/alert'
import AddressView from '../../../components/Address/AddressView'
import AddressForm from '../../../components/Address/AddressForm'

import _ from 'lodash'
import i18n from 'i18n-js'
import produce from 'immer'


const isAndroid = Platform.OS === 'android'

function getDefaultAddress(addresses) {
  return _.chain(addresses).filter({ isDefault: true }).first().value() || {}
}

@graphql(schema.mutations.updateAddress, {
  name: 'updateAddress',
})
class ShippingAddress extends React.Component {
  constructor(props) {
    super(props)
    const { user } = props
    this.state = {
      errors: {},
      editAddress: false,
    }

    const address = props.address || getDefaultAddress(user)

    if (!address) {
      this.state.address = {
        recipient: user.displayName,
        address1: '',
        address2: '',
        city: '',
        region: '',
        zip: '',
        country: guessCountry(),
        email: user.email,
        phone: user.phone
      }
      this.state.hasAddress = false
    } else {
      const recipient = address.recipient || `${address.firstName} ${address.lastName}`
      let originalAddress = {
        recipient,
        ...address,
      }
      this.state.originalAddress = originalAddress
      this.state.address = {...originalAddress}
      this.state.hasAddress = true
    }
    // this.fieldError = bindableFieldErrorFunc(
    //   fieldErrorsConfig,
    //   'state.address',
    //   this.removeError,
    //   this.setError,
    //   'errors.order.cart',
    //   'address.fields',
    // ).bind(this)
    // this.fieldErrors = bindableFieldErrorsFunc(
    //   this.fieldError,
    //   'state.address',
    //   'address.fields',
    // ).bind(this)
  }

  editAddress = () => {
    this.setState({ editAddress: true })
  }

  cancelEdit = () => {
    const nextState = produce(this.state, (draft) => {
      draft.editAddress = false
      draft.address = { ...draft.originalAddress }
    })
    this.setState(nextState)
  }

  setError = (field, errorMessage = undefined, options) => {
    const { errors } = this.state

    let newErrors = errors

    if (field && errorMessage) {
      newErrors[field] = i18n.t(errorMessage, options)
      this.setState({ errors: newErrors })
    }
  }

  removeError = (field) => {
    const { errors } = this.state
    let newErrors = errors

    if (field && newErrors[field]) {
      delete newErrors[field]
      this.setState({ errors: newErrors })
    }
  }

  updateAddress = async (values) => {
    const { onSave, user } = this.props
    // const message = this.fieldErrors(undefined, true)
    // if (message) {
    //   alert({ title: 'Errors', message })
    //   return
    // }
    let nextState = produce(this.state, (draft) => {
      draft.editAddress = false
      draft.originalAddress = { ...draft.address }
      draft.address = {...draft.address, ...values}
    })
    if (!this.state.hasAddress) {
      try {
        const {
          address1,
          address2,
          city,
          region,
          zip,
          country,
          phone,
          email,
        } = values
        const result = await this.props.updateAddress({
          variables: {
            address1,
            address2,
            city,
            region,
            zip,
            country,
            phone,
            email,
          },
          update: (store, { data }) => {
            updateCachedQueryValue(store, {
              query: schema.queries.userById,
              variables: { id: user.id },
              nextValue: () => data.updateAddress, // this is actually an updated user
            })
          },
        })
        nextState = produce(this.state, (draft) => {
          draft.editAddress = false
          draft.hasAddress = true
          draft.address = {...draft.address, ...values}
        })
      } catch (e) {
        const message = _.get(e, 'graphQLErrors.0.message') || e.message
        nextState = produce(this.state, (draft) => {
          draft.errors.general = message
          draft.address = {...draft.address, ...values}
        })
      }
    }
    onSave(nextState.address)
    this.setState(nextState)
  }

  getMessage = (id, ...rest) => {
    if (rest) {
      return i18n.t(id, ...rest)
    }
    return i18n.t(id)
  }

  onChange = (value, target) => {
    const error = this.fieldError(value, target)
    this.setState((prevState) => {
      const nextState = produce(prevState, draft => {

        if (error) {
          _.set(draft, `errors.${target}`, error)
        } else {
          _.unset(draft, `errors.${target}`)
        }
        if ((!isAndroid && !error) || isAndroid) {
          _.set(draft, `address.${target}`, value)
        }
      })
      return nextState
    })
  }

  viewAddress() {
    const { address } = this.state
    return <AddressView address={address} />
  }

  enterAddress() {
    const { user } = this.props
    const { address, errors, hasAddress } = this.state
    let newErrors = { ...errors }
    if (!newErrors) {
      newErrors = {}
    }

    const subtext = hasAddress
      ? i18n.t('order.cart.editAddressSubtext')
      : i18n.t('onboardingUser.address.text')
    return (
      <View style={[styles.container]}>
        <Text style={styles.subtext}>{subtext}</Text>
        <AddressForm
          address={address}
          onSubmit={this.updateAddress}
        />
      </View>
    )
  }

  render() {
    const { style, titleStyle, disableEditMode } = this.props
    const { hasAddress, editAddress } = this.state
    const enterAddress = (!hasAddress || editAddress) && !disableEditMode
    return (
      <View style={[style]}>
        <View style={[styles.headerContainer]}>
          <Text style={[shared.h5, styles.title, titleStyle]}>
            {i18n.t('order.checkout.addressTitle')}
          </Text>
          {!disableEditMode && hasAddress && !editAddress && (
            <Text onPress={this.editAddress} style={styles.edit}>
              {' '}
              ({i18n.t('order.checkout.editAddress')})
            </Text>
          )}
          {!disableEditMode && hasAddress && editAddress && (
            <Text onPress={this.cancelEdit} style={styles.cancelEdit}>
              {' '}
              ({i18n.t('common.cancel')})
            </Text>
          )}
        </View>
        {enterAddress ? this.enterAddress() : this.viewAddress()}
      </View>
    )
  }
}

ShippingAddress.propTypes = {
  address: PropTypes.object,
  disableEditMode: PropTypes.bool,
  style: PropTypes.object,
  titleStyle: PropTypes.object,
  user: PropTypes.object,

  updateAddress: PropTypes.func,
}

export default ShippingAddress

const styles = stylus({
  container: {
    alignSelf: 'stretch',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    color: colors.text.main,
  },
  edit: {
    fontSize: 13,
    color: colors.text.main,
  },
  cancelEdit: {
    fontSize: 13,
    color: 'red',
  },
  subtext: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '300',
    color: colors.text.secondary,
    marginBottom: 10,
  },
  input: {
    width: '100%',
    // marginVertical: 10,
  },
  pickerLabel: {
    marginTop: 20,
    color: colors.input.base,
    fontSize: 12,
    web: {
      marginBottom: 6,
    },
  },
  picker: {
    web: {
      width: '100%',
      color: colors.input.text,
    },
  },
  countryContainer: {
    alignSelf: 'stretch',
    marginBottom: 10
  },
  button: {
    backgroundColor: colors.primary,
    marginBottom: 5,
    marginTop: 20,
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '700',
  },
})
