import React, { Component } from 'react'

const WithSetProps = (Composed) =>
  (class extends Component {    
    constructor(props) {
      super(props)
      this.state = {}
    }
  
    setProps = (props) => {
      this.setState(props)
    }

    render() {
      return (
        <Composed
          {...this.state}
          {...this.props}
          setProps={this.setProps}
        />
      )
    }
  })

export default WithSetProps
