import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import { _get, compose, connect } from '../../config/connected'

import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import { PropTypes } from 'prop-types'
import NamedImage from '../simple/NamedImage'
import _ from 'lodash'
import i18n from 'i18n-js'
import withCurrentUser from '../../containers/withCurrentUser'
import B from '../simple/BoldText'

const profileImageHeight = 30


function FollowerIcons({ followers }) {
  return (followers || []).map((user, index) => (
    <NamedImage
      key={user.id}
      style={{
        zIndex: -index,
        marginRight: -12,
        width: profileImageHeight,
        height: profileImageHeight,
        borderWidth: 3,
        borderColor: '#000',
        borderRadius: profileImageHeight / 2,
      }}
      name={user.profileImage}
      placeholder='profile'
      width={50}
      height={50}
      resizeMode='cover'
    />
  ))
}

const FollowersWidget = ({
  followers = [],
  onPress = () => {},
  style,
  followersCount = 0,
}) => {

  if (!followersCount || followersCount === 0) {
    return null
  }

  const firstFollowerName = _get(followers, '[0].username')
  const and = followersCount > 0 ? ` ${i18n.t('followers.followersWidget.shop.and')} ` : ''
  // NOTE: ending only applies if there's more than one follower since we already reference the first follower at the beginning of the text
  const other = followersCount > 1 ? <B>{i18n.t('followers.followersWidget.shop.ending', { count: followersCount - 1 })}</B> : ''

  return (
    <TouchableOpacity onPress={() => onPress()}>
      <View
        style={{
          paddingHorizontal: 14,
          marginVertical: 10,
          flexDirection: 'row',
          maxWidth: '100%',
        }}
      >
        <View style={styles.followersContainer}>
          <FollowerIcons followers={followers}/>
        </View>
        <View style={{ flex: 0, flexShrink: 1 }}>
          <Text style={[styles.container, style]}>
            {firstFollowerName && (
              <Text>
                <B>{firstFollowerName}</B> {i18n.t('followers.followersWidget.shop.followedBy')}
                {and}
                {other}
              </Text>
            )}
            {firstFollowerName === undefined && (
              <B>
                {i18n.t('followers.followersWidget.shop.simple', { count: followersCount })}
              </B>
            )}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}

FollowersWidget.propTypes = {
  style: PropTypes.object,
  followers: PropTypes.array,
  onPress: PropTypes.func,
  followersCount: PropTypes.number,
}

export default FollowersWidget

const styles = stylus({
  followersContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 0,
    paddingRight: 6,
  },
  container: {
    marginTop: 8,
    marginBottom: 8,
    paddingRight: 12,
    fontSize: 13,
    paddingLeft: 16,
    color: colors.text.main,
    // flexWrap: 'wrap',
  },
})
