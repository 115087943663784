export const ANALYTICS_POST_LIKE_TAPPED = 'analytics/POST_LIKE_TAPPED'
export const ANALYTICS_TAG_TAPPED = 'analytics/TAG_TAPPED'
export const ANALYTICS_TAG_POST_CREATED = 'analytics/POST_CREATED'
export const ANALYTICS_TAG_POST_EDITED = 'analytics/POST_EDITED'
export const ANALYTICS_TAG_RECIPE_CREATED = 'analytics/RECIPE_CREATED'
export const ANALYTICS_TAG_RECIPE_EDITED = 'analytics/RECIPE_EDITED'
export const ANALYTICS_APP_BECOME_ACTIVE = 'analytics/APP_BECOME_ACTIVE'
export const ANALYTICS_APP_BECOME_INACTIVE = 'analytics/APP_BECOME_INACTIVE'
export const ANALYTICS_APP_BECOME_BACKGROUND = 'analytics/APP_BECOME_BACKGROUND'
export const ANALYTICS_SIGNUP = 'analytics/SIGNUP'
export const ANALYTICS_LOGOUT = 'analytics/LOGOUT'
export const ANALYTICS_LOGIN = 'analytics/LOGIN'
export const ANALYTICS_ADDED_TO_CART_EVENT = 'analytics/ADDED_TO_CART_EVENT'
export const ANALYTICS_REMOVED_FROM_CART_EVENT = 'analytics/REMOVED_FROM_CART_EVENT'
export const ANALYTICS_PAYMENT_METHOD_PAGE_LOAD = 'analytics/PAYMENT_METHOD_PAGE_LOAD'
export const ANALYTICS_CARTS_WITH_PRODUCTS_CREATED = 'analytics/CARTS_WITH_PRODUCTS_CREATED'
export const ANALYTICS_CARTS_WITH_PRODUCTS_CLOSED = 'analytics/CARTS_WITH_PRODUCTS_CLOSED'
export const ANALYTICS_CREDIT_CARD_INSERTION = 'analytics/CREDIT_CARD_INSERTION'
export const ANALYTICS_CREDIT_CARD_REMOVAL = 'analytics/CREDIT_CARD_REMOVAL'
export const ANALYTICS_PROFILE_EDIT = 'analytics/PROFILE_EDIT'
export const ANALYTICS_PROCEED_TO_CHECKOUT = 'analytics/PROCEED_TO_CHECKOUT'
export const ANALYTICS_BUY_NOW = 'analytics/BUY_NOW'
export const ANALYTICS_ALGOLIA_SEARCH = 'analytics/ALGOLIA_SEARCH'

export const ANALYTICS_PRODUCT_PAGE_VISIT = 'analytics/PRODUCT_PAGE_VISIT'
export const ANALYTICS_PUBLISH_POST_WITH_PRODUCTS_TAGGED = 'analytics/PUBLISH_POST_WITH_PRODUCTS_TAGGED'
export const ANALYTICS_LIKES_POST = 'analytics/LIKES_POST'
// export const ANALYTICS_LIKE_PRODUCER = 'analytics/LIKE_PRODUCER' feature is not implemented
export const ANALYTICS_FOLLOWING_SHOP = 'analytics/FOLLOWING_SHOP'
// export const ANALYTICS_PRODUCT_REMOVED = 'analytics/PRODUCT_REMOVED'
export const ANALYTICS_SHOP_EDITED = 'analytics/SHOP_EDITED'
export const ANALYTICS_PRODUCT_EDITED = 'analytics/PRODUCT_EDITED'
export const ANALYTICS_SHOP_STATUS_CHANGED = 'analytics/SHOP_STATUS_CHANGED'

export const  ANALYTICS_NOTICE_RECEIVED = 'analytics/NOTICE_RECEIVED'
export const  ANALYTICS_NOTICE_CLOSED = 'analytics/NOTICE_CLOSED'
