import { gql } from '../config/connected'

const reviewProperties = `
  id
  userId
  objectId
  objectType
  createdAt
  content
  rating
  user {
    id
    profileImage
    coverPhoto
    displayName
    username
    title
    organization
    organizationId
  }
`

export default {
  queries: {
    productsReviews: gql`
      query($productId: String!, $limit: Int, $offset: Int) {
        productsReviews(productId: $productId, limit: $limit, offset: $offset) @connection(key: "productsReviews", filter: ["productId"]){
          ${reviewProperties}
          product {
            id
            name
            uploads {
              mime
              width
              height
              name
            }
          }
        }
      }
    `,
    userReview: gql`
      query($userId: String!, $objectType: ReviewObjectType!, $objectId: String!) {
        userReview(userId: $userId, objectType: $objectType, objectId: $objectId) {
          ${reviewProperties}
        }
      }
    `,
    productById: gql`
      query($id: String!) {
        productById(id: $id) {
          id
          name
          rating
          ratingCount
          uploads {
            name
            width
            height
            mime
          }
          profile {
            id
            displayName
            coverPhoto
            profileImage
            address {
              city
              country
              region
            }
          }
        }
      }
    `,
    reviewsByUser: gql`
      query($userId: String!, $last: Date, $limit: Int, $offset: Int) {
        reviewsByUser(userId: $userId, last: $last, limit: $limit, offset: $offset) {
          ${reviewProperties}
          ... on ProductReview {
            product {
              id
              name
              rating
              ratingCount
              uploads {
                mime
                width
                height
                name
              }
              profile{
                id
                displayName
              }
            }
          }
        }
      }
    `,
    reviewsByShopId: gql`
      query($shopId: String!, $limit: Int, $offset: Int) {
        reviewsByShopId(shopId: $shopId, objectType: "product", offset: $offset, limit: $limit) @connection(key: "reviewsByShopId", filter: ["shopId", "objectType"]) {
          ${reviewProperties}
          ... on ProductReview {
            product {
              id
              name
              rating
              ratingCount
              uploads {
                mime
                width
                height
                name
              }
              profile{
                id
                displayName
                status
              }
            }
          }
        }
      }
    `,
  },
  mutations: {
    createProductReview: gql`
      mutation($content: Json!, $rating: Int!, $productId: String!) {
        createProductReview(
          objectId: $productId
          rating: $rating
          content: $content
        ) {
          ${reviewProperties}
          user {
            username
            id
          }
        }
      }
    `,
    createReview: gql`
      mutation($objectType: String!, $objectId: String!, $rating: Int!, $content: Json!) {
        createReview(objectType: $objectType, objectId: $objectId, rating: $rating, content: $content) {
          ${reviewProperties}
        }
      }
    `,
    updateReview: gql`
      mutation($id: String!, $rating: Int!, $content: Json!) {
        updateReview(id: $id, rating: $rating, content: $content) {
          ${reviewProperties}
        }
      }
    `,
  },
}
