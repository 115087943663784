import React, { Component } from 'react'
import { Text, View, TouchableOpacity} from 'react-native'

import ProductCarousel from '../ProductCarousel'
import i18n from 'i18n-js'
import { stylus } from '../../config/styles'
import colors from '../../config/colors'
import Divider from '../simple/Divider'
import { connect, graphql, compose } from '../../config/connected'
import schema from '../../screens/Product/schema'
import { Status } from '../simple/Status'
import alert from '../../utility/alert'
import moment from 'moment'
import _ from 'lodash'

class TrendingItems extends Component {
  componentDidMount() {
    this.props.innerRef(this)
  }

  onSuggestedTipPress = async () => {
    const shouldGoToProfile = await alert({
      title: i18n.t('feed.suggestedProducts.tip'),
      message: i18n.t('feed.suggestedProducts.tipDescription'),
      buttons: [
        { text: 'OK', onPressValue: false },
        { text: i18n.t('feed.suggestedProducts.openProfile'), onPressValue: true },
      ],
      options: { cancelable: false },
    })
    if (shouldGoToProfile) {
      this.props.goToProfile()
    }
  }

  refetchTrendingProducts = () => {
    this.props.trendingProductsHandler.refetch()
  }

  refetchSuggestedProducts = () => {
    this.props.suggestedProducts.refetch()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(! _.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)) {
      return true
    }
    return false
  }

  render() {
    const {
      screenInfo,
      imageWidth,
      trendingProductsHandler,
      lastVisitedProducts,
      suggestedProducts
    } = this.props
    const showStatus =
      trendingProductsHandler.loading ||
      trendingProductsHandler.error ||
      (trendingProductsHandler.trendingProducts &&
        !trendingProductsHandler.trendingProducts.length)
    const statusMessage = i18n.t('feed.loadingMessage', {
      message: trendingProductsHandler.loading
        ? i18n.t('feed.trendingStatus.loading')
        : i18n.t('feed.trendingStatus.unavailable'),
    })
    if (showStatus) {
      return <Status message={statusMessage} />
    }
    return (
      <View style={styles.container}>
        <Text style={[styles.header, styles.trendingHeader]}>
          {i18n.t('feed.trendingTitle')}
        </Text>
        <ProductCarousel
          items={trendingProductsHandler.trendingProducts}
          imageSize={imageWidth}
          showPrice
          imageStyles={[
            styles.carouselImageStyles,
            { borderRadius: 4 }, // imageWidth / 2
          ]}
          contentContainerStyle={{ paddingRight: 16 }}
          hasBorder
        />
        <Text style={[styles.header, styles.trendingHeader, {marginTop: 12}]}>
          {i18n.t('feed.lastVisitedTitle')}
        </Text>
        <ProductCarousel
          items={lastVisitedProducts.lastVisitedProducts}
          imageSize={imageWidth}
          showPrice
          imageStyles={[
            styles.carouselImageStyles,
            { borderRadius: 4 }, // imageWidth / 2
          ]}
          contentContainerStyle={{ paddingRight: 16 }}
          hasBorder
        />
        <Text style={[styles.header, styles.trendingHeader, {marginTop: 12, marginBottom: 4}]}>
          {i18n.t('feed.suggestedProducts.title')}
        </Text>
        <TouchableOpacity style={styles.suggestedTipContainer} onPress={this.onSuggestedTipPress}>
          <Text style={styles.suggestedTip}>
            {i18n.t('feed.suggestedProducts.tip')}
          </Text>
        </TouchableOpacity>
        <ProductCarousel
          items={suggestedProducts.suggestedProducts}
          imageSize={imageWidth}
          showPrice
          imageStyles={[
            styles.carouselImageStyles,
            { borderRadius: 4 }, // imageWidth / 2
          ]}
          contentContainerStyle={{ paddingRight: 16 }}
          hasBorder
        />
        <Divider margin='none' />
        <Text style={[styles.header, styles.headerMargin, styles.newsTitle]}>
          {i18n.t('feed.newsTitle')}
        </Text>
      </View>
    )
  }
}

TrendingItems.defaultProps = {
  goToProfile: () => {}
}

export default compose(
  graphql(schema.queries.trendingProducts, {
    name: 'trendingProductsHandler',
    options() {
      return {
        notifyOnNetworkStatusChange: true, 
        variables: { limit: 20 },
        pollInterval: moment.duration(24, 'hours').asMilliseconds()
      }
    },
  }),
  graphql(schema.queries.lastVisitedProducts, {
    name: 'lastVisitedProducts',
    options() {
      return { variables: { limit: 6 } }
    },
  }),
  graphql(schema.queries.suggestedProducts, {
    name: 'suggestedProducts',
    options() {
      return { variables: { limit: 6 } }
    },
  }),
)(TrendingItems)

const styles = stylus({
  container: {
    paddingTop: 10,
  },
  header: {
    color: colors.text.main,
    fontSize: 15,
    fontWeight: '600',
    paddingLeft: 12,
  },
  trendingHeader: {
    fontSize: 17,
    marginBottom: 6,
  },
  headerMargin: {
    marginTop: 12,
    marginBottom: 18,
    fontSize: 20
  },
  suggestedTipContainer: {
    paddingLeft: 12,
    marginBottom: 6,
  },
  suggestedTip: {
    color: colors.taggingText
  },
  carouselTextStyles: {
    title: { fontSize: 13 },
    price: {},
    shop: { fontSize: 10 },
    ratingsSize: 14,
  },
  carouselImageStyles: {
    // borderWidth: 1,
    // padding: 4,
    // borderColor: '#CCC',
  },
})
