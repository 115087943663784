import React, { Component } from 'react'
import { KeyboardAvoidingView, View, Platform } from 'react-native'
import NavigationActions from '../../../utility/navigationActions'
import { stylus } from '../../../config/styles'

import MobileBackButton from '../../../components/simple/MobileBackButton'

import i18n from 'i18n-js'
import _ from 'lodash'
import produce from 'immer'

import Screens from './Screens'
import branch from '../../../config/branch'
import { getKeyboardPaddingStyle } from '../../../config/helpers'
import guessCountry from '../../../utility/guessCountry'
import WithKeyboardInfo from '../../../containers/withKeyboardInfo'

const isAndroid = Platform.OS === 'android'

@WithKeyboardInfo
class OnboardingShop extends Component {
  state = {
    page: 'Start',
    prevPage: 'FinancialInfo',
    isServices: false,
    VAT: '',
    euVAT: '',
    legalName: '',
    companyPhoneLandPrefix: '+39',
    companyPhoneLand: '',
    companyPhoneMobilePrefix: '+39',
    companyPhoneMobile: '',
    role: '',
    ibanNumber: '',
    bankAccountHolder: '',
    standardShippingFee: '',
    minimumOrderAmount: '',
    freeShippingThreshold: '',
    cash: false,
    transfer: false,
    stripe: false,
    shopName: '',
    email: '',
    founded: '',
    address: '',
    city: '',
    region: '',
    zip: '',
    state: '',
    country: guessCountry(),
    website: '',
    agreedToTerms: false,
    errors: {},
    geo: {}
  }

  handleFieldChange = (value, target, error) => {
    this.setState((prevState) => {
      const nextState = produce(prevState, draft => {
        if (error) {
          _.set(draft, `errors.${target}`, error)
        } else {
          _.unset(draft, `errors.${target}`)
        }
        if ((!isAndroid && !error) || isAndroid) {
          _.set(draft, target, value)
        }
      })
      return nextState
    })
  }

  goToPage = (page) => {
    this.setState((prevState) => {
      return {
        page,
        prevPage: prevState.page,
      }
    })
  }

  getCurrentPage = () => {
    const { page } = this.state
    return Screens[page]
  }

  setError = (field, errorMessage = undefined, options) => {
    const { errors } = this.state

    let newErrors = errors

    if (field && errorMessage) {
      newErrors[field] = i18n.t(errorMessage, options)
      this.handleFieldChange(newErrors, 'errors')
    }
  }

  removeError = (field) => {
    const { errors } = this.state
    let newErrors = errors

    if (field && newErrors[field]) {
      delete newErrors[field]
      this.handleFieldChange(newErrors, 'errors')
    }
  }

  handleLocationSelect = (location) => {
    const {latitude: lat, longitude: lng, address, formattedAddress} = location
    this.setState({geo: {lat, lng}, formattedAddress, ...address})
  }

  getPageProps = () => {
    const {
      page,
      prevPage,
      VAT,
      euVAT,
      legalName,
      companyPhoneLandPrefix,
      companyPhoneLand,
      companyPhoneMobilePrefix,
      companyPhoneMobile,
      cash,
      transfer,
      stripe,
      ibanNumber,
      bankAccountHolder,
      standardShippingFee,
      freeShippingThreshold,
      minimumOrderAmount,
      shopName,
      email,
      founded,
      address,
      city,
      region,
      country,
      state,
      zip,
      formattedAddress,
      website,
      role,
      agreedToTerms,
      errors,
      geo,
    } = this.state

    const { navigation } = this.props
    switch (page) {
      case 'Start':
        return {
          goToPage: this.goToPage,
        }
      case 'FinancialInfo':
        return {
          errors,
          goToPage: this.goToPage,
          handleFieldChange: this.handleFieldChange,
          setError: this.setError,
          removeError: this.removeError,
          financialInfo: {
            VAT,
            euVAT,
            legalName,
            companyPhoneLandPrefix,
            companyPhoneLand,
            companyPhoneMobilePrefix,
            companyPhoneMobile,
            ibanNumber,
            bankAccountHolder,
            role,
            cash,
            transfer,
            stripe,
          },
        }
      case 'Details':
        return {
          errors,
          goToPage: this.goToPage,
          handleFieldChange: this.handleFieldChange,
          setError: this.setError,
          removeError: this.removeError,
          onLocationSelect: this.handleLocationSelect,
          shopInfo: {
            geo,
            shopName,
            email,
            founded,
            address,
            city,
            region,
            country,
            zip,
            state,
            formattedAddress,
            website,
            standardShippingFee,
            freeShippingThreshold,
            minimumOrderAmount,
          },
        }
      case 'FullTerms':
        return {
          prevPage,
          goToPage: this.goToPage,
        }
      case 'Review':
        return {
          errors,
          goToPage: this.goToPage,
          handleFieldChange: this.handleFieldChange,
          onLocationSelect: this.handleLocationSelect,
          agreedToTerms,
          setError: this.setError,
          removeError: this.removeError,
          financialInfo: {
            VAT,
            euVAT,
            legalName,
            companyPhoneLandPrefix,
            companyPhoneLand,
            companyPhoneMobilePrefix,
            companyPhoneMobile,
            ibanNumber,
            bankAccountHolder,
            role,
            cash,
            transfer,
            stripe,
          },
          shopInfo: {
            geo,
            shopName,
            email,
            founded,
            address,
            city,
            region,
            country,
            zip,
            state,
            formattedAddress,
            website,
            standardShippingFee,
            freeShippingThreshold,
            minimumOrderAmount
          },
        }
      case 'Finish':
        return {
          navigation,
          route,
        }
      default:
        return {}
    }
  }

  render() {
    const Page = this.getCurrentPage()
    const { keyboardInfo } = this.props
    const keyboardPaddingStyle = getKeyboardPaddingStyle(keyboardInfo)
    return (
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={branch({ ios: 'padding', other: null })}
        contentContainerStyle={{ flex: 1 }}
        keyboardVerticalOffset={branch({
          iphone: 72,
          android: 81,
          other: 0,
          iphonex: 94,
        })}
      >
        <View style={[styles.container]}>
          <Page {...this.getPageProps()} />
        </View>
        <View style={[keyboardPaddingStyle]}/>
      </KeyboardAvoidingView>
    )
  }
}

OnboardingShop.navigationOptions = (props) => {
  return {
    headerLeft: () => (
      <MobileBackButton
        label={i18n.t('common.back')}
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

export default OnboardingShop

const styles = stylus({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  text: { color: 'red', fontSize: 50 },
  input: {
    width: '100%',
  },
  submitButton: {
    // marginTop: 30,
    // width: '100%',
  },
  submitButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
})
