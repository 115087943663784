
import ChatLandscape from '../../screens/ChatLandscape'
import ConversationListPortrait from '../../screens/ConversationListPortrait'
import NewConversation from '../../screens/NewConversation'
import ConversationPortrait from '../../screens/ConversationPortrait'

import { getLayout } from '../selectLayout'

const routes = {
  NewConversation: {
    screen: NewConversation,
    path: 'new-message',
    
  },
  Conversation: {
    screen: ConversationPortrait,
    path: 'conversations/:id',
  },
  Conversations: {
    screen: ConversationListPortrait,
    path: 'chat',
    
  }
}

// For now for AZ1403 we are using Portrait layout Conversations screen. Too much work needed for Landscape layout.
// const layout = getLayout()
// if (layout.indexOf('landscape') === -1) {
//   routes.Conversations = {
//     screen: ConversationListPortrait,
//     path: 'chat',
//     
//   }
// } else {
//   routes.Conversations = {
//     screen: ChatLandscape,
//     path: 'conversations/:id?',
//     
//   }
// }

export const chatRoutes = routes
