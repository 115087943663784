import React from 'react'
import { Text, View } from 'react-native'
import Scroller from '../../components/Scroller'
import colors from '../../config/colors'
import { stylus } from '../../config/styles'
import MobileBackButton from '../../components/simple/MobileBackButton'
import NavigationActions from '../../utility/navigationActions'
import Metric from '../../components/simple/Metric'
import Linking from '../../config/nativeLink'
import T from '../../components/T'
import i18n from 'i18n-js'
import info from '../../config/environment'

class About extends React.Component {
  render() {
    return (
      <View style={styles.wrapper}>
        <Scroller>{this.content()}</Scroller>
      </View>
    )
  }
  content() {
    const CompanyWebsiteUrl = (
      <Text
        accessibilityRole='link'
        href={info.companyWebsiteUrl}
        target='_blank'
        style={styles.link}
        onPress={() => Linking.openURL(info.companyWebsiteUrl)}
      >
        {info.companyWebsiteDisplay}
      </Text>
    )
    const privacyPolicyLink = i18n.t('legal.links.user.privacyPolicy')
    console.log(privacyPolicyLink)
    return (
      <View style={styles.container}>
        <Metric
          label={i18n.t('settings.content.aboutUs.generalInquiries.title')}
        >
          <Text
            accessibilityRole='link'
            href={`mailto:${info.companySupportEmail}`}
            style={styles.link}
            onPress={() =>
              Linking.openURL(`mailto:${info.companySupportEmail}`)
            }
          >
            {info.companySupportEmail}
          </Text>
        </Metric>

        <Metric
          label={i18n.t(
            'settings.content.aboutUs.accountAndSalesInquiries.title',
          ).toUpperCase()}
        >
          <T
            accessibilityRole='link'
            target='_blank'
            href={privacyPolicyLink}
            t='settings.content.aboutUs.accountAndSalesInquiries.title'
            style={styles.link}
            onPress={() => Linking.openURL(privacyPolicyLink)}
          />
        </Metric>

        <T t='settings.content.aboutUs.ourStory.title' style={styles.title} />
        <T t='settings.content.aboutUs.ourStory.body' style={styles.p} />
        {/* <Text style={styles.title}>Our Story</Text> */}
        {/* <Text style={styles.p}>Some textual content here</Text> */}
        <T t='settings.content.aboutUs.ourMission.title' style={styles.title} />
        <T t='settings.content.aboutUs.ourMission.body' style={styles.p} />
        {/* <Text style={styles.title}>Our Mission</Text> */}
        {/* <Text style={styles.p}>Some textual content here</Text> */}
        <T t='settings.content.aboutUs.ourValues.title' style={styles.title} />
        <Text style={styles.p}>
          <T t='settings.content.aboutUs.ourValues.body' />
          {' '}
          { CompanyWebsiteUrl }
        </Text>
        {/* <Text style={styles.title}>Our Values</Text> */}
        {/* <Text style={styles.p}>Some textual content here</Text> */}
        <Text style={styles.p}>
          <T t='settings.content.aboutUs.body' />
          { CompanyWebsiteUrl }
        </Text>
      </View>
    )
  }
}

About.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('settings.sectionLinks.aboutUs'),
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

export default About

const styles = stylus({
  wrapper: {
    flex: 1,
  },
  container: {
    flex: 1,
    paddingHorizontal: 24,
    marginBottom: 24,
    paddingTop: 24,
    portrait: {
      paddingTop: 30,
      paddingHorizontal: 20,
    },
  },
  title: {
    fontSize: 17,
    color: colors.text.main,
    fontWeight: 'bold',
    paddingVertical: 8,
    portrait: {
      marginTop: 8,
    },
  },
  p: {
    paddingVertical: 8,
    fontSize: 15,
    color: colors.text.content,
    fontWeight: 'normal',
    maxWidth: 600,
  },
  bold: {
    fontWeight: 'bold',
  },
  link: {
    fontSize: 17,
    color: colors.primary,
  },
})
