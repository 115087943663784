import React, { Component } from 'react'
import _ from 'lodash'
import { Platform } from 'react-native'
import ElegantInput from '../simple/ElegantInput'
import TextArea from '../../components/TextArea'

class ElegantInputWithTagging extends Component {
  state = {
    value: this.props.defaultValue || '',
    tags: this.props.defaultTags || [],
    selection: { start: 0, end: 0 },
  }

  componentDidMount() {
    if (this.props.defaultContent) {
      const { defaultContent } = this.props
      this.setState({
        tags: defaultContent.tags || [],
        value: defaultContent.value || '',
      })
    }
  }

  reference = (ref) => {
    if (ref) {
      this.inputRef = ref
    }
    this.props.reference && this.props.reference(ref)
  }

  handleChange = (data) => {
    const { asTextArea } = this.props

    let value = data
    
    if (asTextArea) {
      // Text area calls handleChange with a synthetic event value, thus
      // it must be handled differently than a normal <TextInput/> component
      value = data.nativeEvent.text
      if (this.props.truncate !== undefined) {
        value = _.truncate(value, this.props.truncate)
      }
    }
    const modifiedState = { value }
    this.setState(modifiedState, this.handleOnChange)
    this.verifyTags(value)
  }

  verifyTags = (str) => {
    const { tags } = this.state
    var newTags = tags.slice(),
      removalIndecies = []

    _.forEach(tags, (item, index) => {
      let hasTag = false
      if (str.indexOf(item.value) !== -1) {
        hasTag = true
      }

      if (!hasTag) {
        removalIndecies.unshift(index)
      }
    })

    _.forEach(removalIndecies, (value) => {
      newTags.splice(value, 1)
    })

    this.setState({ tags: newTags })
  }

  handleKeypress = ({ nativeEvent: { key } }) => {
    const OS = Platform.OS

    // android handles text input cursor position differently for some reason, thus index -2 is needed.
    const lastChar =
      OS === 'android'
        ? this.state.value[this.state.selection.start - 2]
        : this.state.value[this.state.selection.start - 1]
    const validLastChar = [' ', '', '\n']

    if (key === '@' && (validLastChar.includes(lastChar) || !lastChar)) {
      this.inputRef.input.blur()
      this.props.taggingHook(this.handleAddTag)
    }
  }

  handleOnChange = () => {
    const { tags, value } = this.state
    // alert('test')
    if (this.props.onChange) {
      this.props.onChange({ tags, value })
    }
  }

  handleAddTag = (tagObject) => {
    const { tags, value, selection } = this.state
    const focusInput = _.get(this.inputRef, 'input.focus') ||  (() => {})
    // const isShop = tagObject.value.includes('#')

    if (!tagObject) {
      focusInput()
      return
    }

    const formattedTagObject = { ...tagObject }
    const indexOfSpace = formattedTagObject.value.lastIndexOf(' ')
    if (
      indexOfSpace >= 0 &&
      indexOfSpace + ' '.length >= formattedTagObject.value.length
    ) {
      formattedTagObject.value = formattedTagObject.value.substring(
        0,
        indexOfSpace,
      )
    }

    this.setState(
      {
        tags: [...tags, formattedTagObject],
        value: `${value.slice(0, selection.start - 1)}${
          tagObject.value
        }${value.slice(selection.start)}`,
      },
      this.handleOnChange,
    )
    focusInput()
  }

  handleSelect = ({ nativeEvent: { selection } }) => {
    this.setState({ selection })
  }

  render() {
    const OS = Platform.OS
    const {
      asTextArea,
      // keyboardType = 'email-address',
      keyboardType = OS === 'ios' ? 'twitter' : 'email-address',
      ...rest
    } = this.props
    if (asTextArea) {
      return (
        <TextArea
          keyboardType={keyboardType}
          label={this.props.label || ''}
          value={this.state.value}
          {...rest}
          ref={this.reference}
          stateChangeHandler='onChange'
          // onChangeText={this.handleChange}
          onClear={() => {
            this.setState({ value: '', tags: '' })
          }}
          onChange={this.handleChange}
          onSelectionChange={this.handleSelect}
          onKeyPress={this.handleKeypress}
        />
      )
    } else {
      return (
        <ElegantInput
          keyboardType={keyboardType}
          label={this.props.label || ''}
          value={this.state.value}
          scrollEnabled={false}
          {...rest}
          reference={this.reference}
          onChange={this.handleChange}
          multiline
          onSelectionChange={this.handleSelect}
          onKeyPress={this.handleKeypress}
        />
      )
    }
  }
}

export default ElegantInputWithTagging
