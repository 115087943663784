// const algoliasearch = require('algoliasearch/reactnative')(environment.algolia.project, environment.algolia.token)
import AlgoliaSearchHelper from 'algoliasearch-helper'
import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import LayoutAnimation from '../../components/LayoutAnimation'
import MobileBackButton from '../../components/simple/MobileBackButton'
import { compose, connect } from '../../config/connected'
import Results from './Results'
import _ from 'lodash'

class PostTags extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: null,
    }
  }
  componentDidMount() {
    const helper = (this.helper = AlgoliaSearchHelper(
      algoliasearch,
      'PostTags',
    ))
    helper.on('result', (results) => {
      // console.log('post search results', results)
      this.setState({ results })
    })
    const tag = this.props.navigation.state.params.tag
    helper.setQuery(tag).search()
  }
  render() {
    return <View style={styles.container}>{this.content()}</View>
  }
  content() {
    const { results } = this.state
    if (results) {
      return this.results()
    }
    return <ActivityIndicator size='large' />
  }
  loadMore() {}
  results() {
    const { results } = this.state
    postIds = _.map(results.hits, 'id')
    console.log({ postIds })

    return <Results postIds={postIds} />
  }
  animate() {
    LayoutAnimation &&
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  goToProfile: ({ id, username, profileImage }) =>
    NavigationActions.navigate({
      routeName: 'Profile',
      params: { id, pageTitle: username, profileImage },
    })
  ,
})

PostTags.navigationOptions = (props) => {
  const { navigation } = props
  const params = _.get(navigation, 'state.params') || {}
  return {
    headerTitle: '#' + params.tag,
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PostTags)

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1,
  },
})
