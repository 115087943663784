import React from 'react'
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from 'react-native'

import NamedImage from '../../components/simple/NamedImage'
import colors from '../../config/colors'
import shared, { stylus } from '../../config/styles'
import * as ImagePicker from 'expo-image-picker'

import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { compose } from 'recompose'
import gql from 'graphql-tag'

class ProfileImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const {
      coverDimensions,
      profileImage,
      loading,
      pullup,
      localImage,
      onPress,
      imageSize = 118,
    } = this.props
    return (
      <View
        style={[
          styles.container,
          {
            width: imageSize + 8,
            height: imageSize + 8,
            borderRadius: (imageSize + 8) / 2,
          },
          pullup && {
            left: (coverDimensions.width - (imageSize + 8)) / 2,
            top: coverDimensions.height - (imageSize + 8) / 2,
            position: 'absolute',
          },
        ]}
      >
        <NamedImage
          style={[
            styles.image,
            {
              width: imageSize,
              height: imageSize,
              borderRadius: imageSize / 2,
            },
          ]}
          resizeMode='cover'
          name={localImage ? localImage.id : profileImage}
          opacity={localImage ? 0.5 : 1 }
          placeholder='profile'
          width={imageSize}
          height={imageSize}
          loading={loading}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
})

const mapDispatchToProps = (dispatch) => ({})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ProfileImage)

const styles = stylus({
  container: {
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 9,
    ios: {
      shadowOpacity: 0.15,
      shadowRadius: 7,
    },
    android: {
      elevation: 3,
      overflow: 'hidden',
    },
    backgroundColor: 'white',
  },
  image: {
    marginTop: 4,
    marginLeft: 4,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
