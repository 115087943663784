import i18n from 'i18n-js'
import produce from 'immer'
import _ from 'lodash'
import React, { Component } from 'react'
import { KeyboardAvoidingView, Platform, ScrollView, Text, View, BackHandler } from 'react-native'
import NavigationActions from '../../../utility/navigationActions'
import LayoutAnimation from '../../../components/LayoutAnimation'
import MobileBackButton from '../../../components/simple/MobileBackButton'
import { _get, connect, graphql } from '../../../config/connected'
import {
  fromLocalePriceToRealNumber,
  fromRealNumberToLocalePrice,
  getKeyboardPaddingStyle,
  getNumber,
  removeUploadsFromCache,
} from '../../../config/helpers'
import sizes from '../../../config/sizes'
import { stylus } from '../../../config/styles'
import { isNumeric, isValidTitle } from '../../../config/validate'
import WithKeyboardInfo from '../../../containers/withKeyboardInfo'
import productSchema from '../../../schema/product'
import configSchema from '../../../schema/config'
import screens from './screens'
import colors from '../../../config/colors'
import PropTypes from 'prop-types'
import { CONTENT_IS_PENDING } from '../../../reducers/publishContent'

const isAndroid = Platform.OS === 'android'

const UNIT_QUANTITY_MIN_VALUE = 0.01
const UNIT_QUANTITY_MAX_VALUE = 10000

const REQUIRED_FIELDS = [
  'itemImages',
  'title',
  'price',
  'categories',
  'description',
  'ingredients',
  'unitQuantity',
  'unitType'
]

const styles = stylus({
  container: {
    flex: 1,
    marginBottom: sizes.tabBarHeight,
    iphonex: {
      marginBottom: sizes.iphonexTabBarHeight,
    },
    web: {
      height: 500,
    },
  },
  contents: {
    justifyContent: 'flex-end',
    // flex: 1,
    // backgroundColor: 'red',
  },
})

const CopyNotice = () => (
  <View style={{
    position: 'absolute',
    margin: 10,
    padding: 10,
    borderRadius: 5,
    zIndex: 100,
    backgroundColor: 'white',
  }}>
    <Text style={{color: colors.taggingText}}>
      {i18n.t('product.copyNotice')}
    </Text>
  </View>
)

const navigationOptionsCallbacks = {
  goBack:  () => {}
}
/**
 * Has two modes based on the props passed via navigation.
 * If a productId is passed in navigation, this component switches to edit logic and does not create new products.
 *
 * If passed a shopId in navigation params, it will create a new product under that shop ID when supmitted.
 * If both a shopId and a productId are passed it will resolve to edit mode.
 */

@WithKeyboardInfo
@graphql(productSchema.queries.productById, {
  name: 'productHandler',
  skip: (props) => {
    return !_get(props, 'navigation.state.params.productId', false)
  },
  options: (props) => {
    return {
      variables: {
        id: _get(props, 'navigation.state.params.productId', false),
      },
      fetchPolicy: 'network-only',
    }
  },
})
@graphql(configSchema.queries.maxNumberOfCategories, {
  name: 'configHandler',
  options: {
    fetchPolicy: 'cache-first',
  }
})
@connect(
  ({ screenInfo, uploads }) => ({ screenInfo, uploads }),
  (dispatch) => ({
    dispatch,
    queueUpload(file) {
      dispatch({ type: 'Upload/ADD', file })
    },
  }),
)
class CreateProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: !!props.navigation.state.params.productId,
      copy: !!props.navigation.state.params.copy,
      screen: 'UploadImages',
      itemImages: [],
      itemAwards: [],
      pendingUploads: [],
      awardDetails: '',
      title: '',
      categories: [],
      allergens: [],
      freeFrom: [],
      diet: [],
      properties: [],
      price: '',
      priceDiscount: '',
      quantity: '1',
      format: ' ',
      description: '',
      ingredients: '',
      deliveryLocations: '',
      errors: {},
      isDiscontinued: false,
      isInStock: true,
      canSubmit: true,
      showLoader: false,
      deliveryLocationsOnly: false,
      unitQuantity: '',
      unitType: '',
      files: [],
    }
    if (Platform.OS === 'android') {
      this.watchBackButton()
    }
  }

  watchBackButton() {
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      this._handleGoBack()
      return true
    })
  }

  _handleGoBack = () => {
    removeUploadsFromCache(this.state.pendingUploads)
    NavigationActions.back()
  }

  componentDidMount() {
    navigationOptionsCallbacks.goBack = this._handleGoBack
  }

  componentWillUnmount() {
    this.backHandler && this.backHandler.remove()
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.navigation.state.params.productId ||
      !this.props.productHandler
    ) {
      return
    }

    const prevProductHandler = prevProps.productHandler
    const { productHandler } = this.props

    if (prevProductHandler.loading !== productHandler.loading) {
      this.mapProductToState()
    }
  }

  mapProductToState = () => {
    const {
      uploads,
      awards,
      categories: { allergens, categories, freeFrom, diet },
      description,
      ingredients,
      name,
      price,
      priceDiscount: _priceDiscount,
      shopDiscount,
      quantityUnits,
      stock,
      isDiscontinued,
      isInStock,
      deliveryLocationsOnly,
      unitQuantity,
      unitType,
      profile
    } = this.props.productHandler.productById

    const priceDiscount = _.get(shopDiscount,'originalPriceDiscount', _priceDiscount)

    this.initialData = {
      itemImages: uploads,
      title: name,
      categories: _.map(categories, (item) => {
        return { value: item, label: i18n.t(`categories.producer.${item}`) }
      }),
      allergens: _.map(allergens, (item) => {
        return { value: item, label: i18n.t(`categories.allergens.${item}`) }
      }),
      freeFrom: _.map(freeFrom, (item) => {
        return { value: item, label: i18n.t(`categories.freeFrom.${item}`) }
      }),
      diet: _.map(diet, (item) => {
        return { value: item, label: i18n.t(`categories.diet.${item}`) }
      }),
      quantity: stock.toString(),
      format: quantityUnits.toString(),
      price: fromRealNumberToLocalePrice(price),
      priceDiscount: fromRealNumberToLocalePrice(priceDiscount),
      isDiscontinued,
      isInStock,
      description,
      ingredients,
      itemAwards: awards.images,
      awardDetails: awards.description,
      deliveryLocations:_.get(profile, 'producerDetails.deliveryLocations') || '',
      deliveryLocationsOnly,
      unitQuantity: unitQuantity ? getNumber(unitQuantity) : '',
      unitType: unitType || '',
      shopDiscount,
    }

    this.setState({
      ...this.initialData,
    })
  }

  validateField = (
    value,
    target,
    supressErrors = false,
    checkAsRequired = false
  ) => {
    let noErrors = true
    const isEmpty = _.isEmpty(value)
    switch (target) {
      case 'title': // Validated as only alphanumeric
        if (checkAsRequired) {
          noErrors = isValidTitle(value.toString()) && !isEmpty
        } else {
          noErrors = isValidTitle(value.toString())
        }
        break
      // Conditional validation, must only exist if a price exists.
      case 'priceDiscount':
        if (checkAsRequired && !isEmpty) {
          if (this.state.price.toString().length === 0) {
            noErrors = false
          } else {
            noErrors =
              this.isPriceDiscountValid(value.toString()) &&
              !isEmpty &&
              !!this.state.price &&
              fromLocalePriceToRealNumber(value) >= 0.01
          }
        } else {
          noErrors = this.isPriceDiscountValid(value.toString())
        }
        break
      case 'price': // Validated as numbers.
        if (checkAsRequired) {
          noErrors = isNumeric(value.toString()) && !isEmpty  && fromLocalePriceToRealNumber(value) >= 0.01
        } else {
          noErrors = isNumeric(value.toString())
        }
        break
      case 'itemImages':
      case 'categories':
        if (checkAsRequired) {
          noErrors = !isEmpty
        }
        break
      //No verification needed for these fields, you're allowed to put anything in them that you would like.
      case 'deliveryLocationsOnly':
      case 'description':
      case 'format':
      case 'quantity':
      case 'itemAwards':
      case 'ingredients':
      case 'awardDetails':
      case 'allergens':
      case 'freeFrom':
      case 'diet':
      case 'isInStock':
      case 'isDiscontinued':
        noErrors = true
        break
      case 'unitQuantity':
        if (checkAsRequired) {
          noErrors = isNumeric(value.toString()) 
            && !isEmpty 
            && fromLocalePriceToRealNumber(value) >= UNIT_QUANTITY_MIN_VALUE 
            && fromLocalePriceToRealNumber(value) <= UNIT_QUANTITY_MAX_VALUE
        } else {
          noErrors = isNumeric(value.toString()) 
            && (isEmpty 
            || fromLocalePriceToRealNumber(value) >= UNIT_QUANTITY_MIN_VALUE 
            && fromLocalePriceToRealNumber(value) <= UNIT_QUANTITY_MAX_VALUE)
        }
        break
      case 'unitType':
      default:
        console.warn(
          `CreateProduct: Unknown field submitted for validation: ${target}`,
        )
        break
    }

    if (!noErrors && !supressErrors) {
      this.addError(target, checkAsRequired)
      return noErrors
    }

    return noErrors
  }

  isPriceDiscountValid(value) {
    if (!isNumeric(value)) {
      return false
    }
    const valueReal = fromLocalePriceToRealNumber(value)
    const priceReal = fromLocalePriceToRealNumber(this.state.price)
    return !(valueReal && valueReal >= priceReal)

  }

  validateMultiple = (targets) => {
    let noErrors = true

    _.forEach(targets, (fieldName) => {
      const value = this.state[fieldName]
      // Never check price discount as required, it follows
      // special validation logic.
      const isValid = this.validateField(
        value,
        fieldName,
        false,
        true,
      )
      const isNotEmpty = !_.isEmpty(value)
      const canBeSubmitted = !!(isValid && isNotEmpty)
      
      if (fieldName === 'priceDiscount' && !isNotEmpty) {
        return noErrors
      }

      if (!canBeSubmitted) {
        if (isValid) {
          this.addError(fieldName, true)
        }
        noErrors = false
      }
    })

    return noErrors
  }

  handleFieldChange = (value, target, overrideValue = false, checkAsRequired) => {
    const finalValue = overrideValue ? this.state[target] : value
    
    const isValid = this.validateField(finalValue, target, true, checkAsRequired)
    this.setState((prevState) => {
      const nextState = produce(prevState, draft => {
        let iosForceSet = false

        if (isValid) {
          _.unset(draft, `errors.${target}`)
        } else {
          _.set(draft, `errors.${target}`, i18n.t(`errors.createProduct.${target}`))

          if (target === 'priceDiscount') {
            if (isNumeric(finalValue) && fromLocalePriceToRealNumber(finalValue) >= 0.01) {
              iosForceSet = true
              _.set(draft, `errors.${target}`, i18n.t('errors.createProduct.invalidDiscount'))
            }
          }
          
          if (target === 'unitQuantity') {
            if (isNumeric(finalValue)) {
              const number = fromLocalePriceToRealNumber(finalValue)

              if (number < UNIT_QUANTITY_MIN_VALUE) {
                iosForceSet = true
                _.set(draft, `errors.${target}`, i18n.t('errors.createProduct.unitQuantity_min', { 
                  min: i18n.toNumber(UNIT_QUANTITY_MIN_VALUE, { precision: 2 })
                }))
              }
              if (number > UNIT_QUANTITY_MAX_VALUE) {
                iosForceSet = true
                _.set(draft, `errors.${target}`, i18n.t('errors.createProduct.unitQuantity_max', {
                  max: i18n.toNumber(UNIT_QUANTITY_MAX_VALUE, { precision: 0 })
                }))
              }
            }
          }
        }
        if ((!isAndroid && isValid) || isAndroid || iosForceSet) {
          _.set(draft, target, finalValue)
        }
      })
      return nextState
    })
  }

  addUpload = (setName, file) => {
    const nextState = produce(this.state, draft => {
      draft[setName].push(file)
      draft.pendingUploads.push(file)
      if (file.file.type == 'video') {
        draft.files.push(file)
      } else {
        this.props.queueUpload(file)
      }
    })
    this.setState(nextState)
  }

  addImage = (file) => {
    this.addUpload('itemImages', file)
  }

  addAward = (file) => {
    this.addUpload('itemAwards', file)
  }

  filterOutUpload = (files, id) => {
    return _.filter(files, file => file.name ? !file.name.includes(id) : !file.id.includes(id))
  }

  removeFile = (setName, originalId) => {
    const id = originalId.replace('thumbnail-', '').split('.')[0]
    const uploadsToRemove =  _.filter(this.state.pendingUploads, file => file.name ? file.name.includes(id) : file.id.includes(id))
    const nextState = produce(this.state, draft => {
      draft[setName] = this.filterOutUpload(draft[setName], id)
      draft.pendingUploads = this.filterOutUpload(draft.pendingUploads, id)
      draft.files = this.filterOutUpload(draft.files, id)
    })
    this.setState(nextState, () => {
      removeUploadsFromCache(uploadsToRemove)
    })
  }

  removeImage = (id) => {
    this.removeFile('itemImages', id)
  }

  removeAward = (id) => {
    this.removeFile('itemAwards', id)
  }

  handleUploadFiles = () => {
    const { files } = this.state
    if (files.length) {
      files.forEach(file => {
        this.props.queueUpload(file)
      })
    }
  }

  setGoBack = (screen) => {
    switch (screen) {
      case 'UploadImages':
        this._handleGoBack = () => NavigationActions.back()
        break
      case 'UploadAwards':
        this._handleGoBack = () => {
          this.setState({ screen: 'UploadImages' })
          this.setGoBack('UploadImages')
        }
        break
      case 'ItemInformation':
        this._handleGoBack = () => {
          this.setState({ screen: 'UploadAwards' })
          this.setGoBack('UploadAwards')
        }
        break
      case 'ItemDetails':
        this._handleGoBack = () => {
          this.setState({ screen: 'ItemInformation' })
          this.setGoBack('ItemInformation')
        }
        break
      default:
        this._handleGoBack = () => {
          removeUploadsFromCache(this.state.pendingUploads)
          NavigationActions.back()
        }
        break
    }
    navigationOptionsCallbacks.goBack = this._handleGoBack
  }

  goToNext = () => {
    const { screen } = this.state
    let target, noErrors

    switch (screen) {
      case 'UploadImages':
        target = 'UploadAwards'
        noErrors = this.validateMultiple(['itemImages'])
        break
      case 'UploadAwards':
        target = 'ItemInformation'
        // noErrors = this.validateMultiple([''])
        noErrors = true // Awards is allowed to be empty, thus cannot throw errors.
        break
      case 'ItemInformation':
        target = 'ItemDetails'
        noErrors = this.validateMultiple([
          'title',
          'price',
          'priceDiscount',
          'categories',
          'unitQuantity',
          'unitType',
        ])
        break
      default:
        target = 'UploadImages'
        noErrors = true
        break
    }

    if (!noErrors) {
      return
    }

    this.setGoBack(target)
    this.setState({ screen: target })
  }

  isUploading = () => {
    const { uploads } = this.props
    let returnVal = false
    _.forEach(uploads, (item) => {
      if (item.state === 'uploading') {
        returnVal = true
      }
    })
    return returnVal
  }

  getScreenProps = () => {
    const { uploads, navigation } = this.props
    const {
      screen,
      itemImages,
      itemAwards,
      awardDetails,
      title,
      price,
      priceDiscount,
      quantity,
      format,
      description,
      ingredients,
      allergens,
      freeFrom,
      diet,
      categories,
      properties,
      errors,
      canSubmit,
      editing,
      isInStock,
      isDiscontinued,
      showLoader,
      deliveryLocations,
      deliveryLocationsOnly,
      unitQuantity,
      unitType,
      shopDiscount,
    } = this.state
    const {
      goToNext,
      addAward,
      addImage,
      removeImage,
      removeAward,
      handleFieldChange,
      handleSubmit,
      animate,
      addError,
      isUploading,
    } = this

    switch (screen) {
      case 'UploadImages':
        return {
          itemImages,
          addImage,
          goToNext,
          uploads,
          removeImage,
          errors,
          animate,
          editing,
          isUploading,
          screen,
          navigation,
        }
      case 'UploadAwards':
        return {
          addAward,
          goToNext,
          uploads,
          handleFieldChange,
          removeAward,
          itemAwards,
          awardDetails,
          errors,
          animate,
          editing,
          navigation,
        }
      case 'ItemInformation':
        return {
          goToNext,
          handleFieldChange,
          title,
          unitQuantity,
          unitType,
          price,
          priceDiscount,
          quantity,
          format,
          allergens,
          freeFrom,
          diet,
          categories,
          properties,
          itemImages,
          errors,
          animate,
          editing,
          isInStock,
          isDiscontinued,
          deliveryLocations,
          deliveryLocationsOnly,
          addError,
          handleSubmit,
          shopDiscount,
          navigation,
        }
      case 'ItemDetails':
        return {
          goToNext,
          handleFieldChange,
          handleSubmit,
          description,
          itemImages,
          ingredients,
          canSubmit,
          errors,
          animate,
          editing,
          showLoader,
          navigation,
        }
      default:
        return {}
    }
  }

  addError = (target, asRequired = false) => {
    const { errors, priceDiscount, unitQuantity  } = this.state
    let message = ''
    if (asRequired) {
      message = i18n.t('errors.createProduct.required', {
        fieldName: i18n.t(`shop.add.fields.${target}`),
      })
    } else {
      message = i18n.t(`errors.createProduct.${target}`)
    }

    if (target === 'priceDiscount') {
      if (isNumeric(priceDiscount) && fromLocalePriceToRealNumber(priceDiscount) >= 0.01) {
        message = i18n.t('errors.createProduct.invalidDiscount')
      }
    }

    if (target === 'unitQuantity') {
      const number = fromLocalePriceToRealNumber(unitQuantity)

      if (isNumeric(unitQuantity)) {
        if (number < UNIT_QUANTITY_MIN_VALUE) {
          message = i18n.t('errors.createProduct.unitQuantity_min', {
            min: i18n.toNumber(UNIT_QUANTITY_MIN_VALUE, { precision: 2 })
          })
        }
        if (number > UNIT_QUANTITY_MAX_VALUE) {
          message = i18n.t('errors.createProduct.unitQuantity_max', { 
            max: i18n.toNumber(UNIT_QUANTITY_MAX_VALUE, { precision: 0 })
          })
        }
      }
    }

    this.setState((state) => ({
      errors: { ...state.errors, [target]: message },
    }))
  }

  removeError = (target) => {
    const { errors } = this.state
    let newErrors = {}
    Object.assign(newErrors, errors)

    if (newErrors[target]) {
      delete newErrors[target]
    }
    this.setState({ errors: newErrors })
  }

  generateUploads = () => {
    const { itemImages } = this.state
    var data = []
    _.forEach(itemImages, (item) => {
      data.push({
        name: item.id || item.name,
        mime: item.mime,
        width: item.width,
        height: item.height,
      })
    })
    return data
  }

  generateAwards = () => {
    const { itemAwards, awardDetails } = this.state
    const scrubbedAwardDetails =
      awardDetails[0] === '\\' && awardDetails[1] === 'n'
        ? '\\' + awardDetails
        : awardDetails
    let data = []
    _.forEach(itemAwards, (item) => {
      data.push({
        name: item.id || item.name,
        mime: item.mime,
        width: item.width,
        height: item.height,
      })
    })

    return { images: data, description: scrubbedAwardDetails }
  }

  getCategoryForUpload = (category) => {
    const data = this.state[category]

    return _.map(data, (item) => {
      return item.value
    })
  }

  getCategories = () => {
    return {
      allergens: this.getCategoryForUpload('allergens'),
      categories: this.getCategoryForUpload('categories'),
      freeFrom: this.getCategoryForUpload('freeFrom'),
      diet: this.getCategoryForUpload('diet'),
    }
  }

  handleSubmit = async () => {
    // Exit the function if any of the fields are invalid.
    // Validate all also sets any error messages it needs to.
    if (!this.validateMultiple(REQUIRED_FIELDS)) {
      return
    }
    this.handleUploadFiles()
    this.setState({ canSubmit: false, showLoader: true }, this.dispatchPublishContent)
  }

  dispatchPublishContent = () => {
    const { productHandler, dispatch } = this.props
    const {
      copy,
      title,
      price,
      priceDiscount,
      quantity,
      format,
      ingredients,
      description,
      isInStock,
      isDiscontinued,
      deliveryLocationsOnly,
      unitQuantity,
      unitType,
    } = this.state

    let shopId,
      asEdit = false,
      productId
    if (productHandler && !copy) {
      productId = productHandler.productById.id
      shopId = productHandler.productById.profile.id
      asEdit = true
    } else {
      shopId = this.props.navigation.state.params.shopId
    }

    const payload = {
      uploads: this.generateUploads(),
      name: title,
      categories: this.getCategories(),
      stock: parseFloat(quantity),
      quantityUnits: format,
      isDiscontinued,
      isInStock,
      price: fromLocalePriceToRealNumber(price),
      priceDiscount: fromLocalePriceToRealNumber(priceDiscount),
      description,
      ingredients,
      awards: this.generateAwards(),
      deliveryLocationsOnly,
      unitQuantity: +parseFloat(unitQuantity.replace(',','.')).toFixed(2),
      unitType,
    }

    if (asEdit) {
      payload.id = productId
    } else {
      payload.userId = shopId
    }

    const keys = Object.keys(this.initialData || {})
    const editedData = _.pick(this.state, keys)

    this.props.dispatch({
      type: CONTENT_IS_PENDING,
      contentType: 'product',
      pendingUploads: this.state.pendingUploads.filter(file => !!this.props.uploads[file.id] && this.props.uploads[file.id].state !== 'complete').map(file => this.props.uploads[file.id]),
      allUploads: [...this.state.pendingUploads],
      productId,
      shopId,
      payload,
      initialData: this.initialData || {},
      editedData,
    })

    NavigationActions.back()
  }

  animate() {
    LayoutAnimation &&
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }

  render() {
    const { screen } = this.state
    const CurrentScreen = screens[screen]
    const screenProps = this.getScreenProps()
    const { screenInfo, keyboardInfo } = this.props
    const maxNumberOfCategories = this.props.configHandler?.maxNumberOfCategories || 1
    const keyboardPaddingStyle = getKeyboardPaddingStyle(keyboardInfo)

    return (
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'android' ? 'height' : 'padding'}
        keyboardVerticalOffset={Platform.OS === 'android' ? null : 16}
      >
        {this.state.copy && <CopyNotice/>}
        <ScrollView
          onLayout={this.animate}
          style={styles.container}
          contentContainerStyle={styles.contents}
        >
          <CurrentScreen {...screenProps}  {...{maxNumberOfCategories}} />
          <View style={[keyboardPaddingStyle]} />
        </ScrollView>
      </KeyboardAvoidingView>
    )
  }
}

CreateProduct.navigationOptions = ({ navigation }) => {
  return {
    headerTitle: () => null,
    headerLeft: () => (
      <MobileBackButton
        onPress={() => navigationOptionsCallbacks.goBack()}
      />
    ),
  }
}

CreateProduct.propTypes = {
  navigation: PropTypes.any,
  updateProduct: PropTypes.any,
  createProduct: PropTypes.any,
  productHandler: PropTypes.any,
  dispatch: PropTypes.any,
  queueUpload: PropTypes.func,
  analyticsProductEdited: PropTypes.func,
}

export default CreateProduct
