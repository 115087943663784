import React, { useState, useEffect, memo } from 'react'
import { View, StyleSheet, TouchableOpacity, AsyncStorage } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import i18n from 'i18n-js'
import TextTicker from 'react-native-text-ticker'

import PropTypes from 'prop-types'
import { get } from 'lodash'

import colors from '../../config/colors'
import { compose, connect, graphql } from '../../config/connected'
import withCurrentUser, { query as currentUserQuery } from '../../containers/withCurrentUser'
import schema from '../../schema/notice'

import { ANALYTICS_NOTICE_RECEIVED, ANALYTICS_NOTICE_CLOSED } from '../../reducers/analytics'


const NoticeBar = ({ currentUser, closeNotice, analyticsNoticeReceived, analyticsNoticeClosed }) => {
  const [notice, setNotice] = useState(null)

  function handleClose() {
    return closeNotice({
      update: (store) => {
        analyticsNoticeClosed(notice)

        const currentUser = store.readQuery({ query: currentUserQuery })
        if (currentUser.currentUser) {
          currentUser.currentUser.lastNotice = null
          store.writeQuery({
            query: currentUserQuery,
            data: currentUser,
          })
        }
      },
    })
  }

  async function noticeReceived({ id, name }) {
    const noticeId = await AsyncStorage.getItem('LAST_NOTICE_ID')

    if (noticeId !== id) {
      analyticsNoticeReceived({ id, name })
      await AsyncStorage.setItem('LAST_NOTICE_ID', id)
    }
  }

  useEffect(() => {
    const lastNotice = get(currentUser, 'currentUser.lastNotice')
    setNotice(lastNotice)
    if (lastNotice) {
      noticeReceived(lastNotice)
    }
  }, [currentUser.currentUser])

  if (!notice) return null

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={handleClose}
    >
      <View style={styles.messageContainer}>
        <TextTicker
          style={styles.message}
          loop
          repeatSpacer={100}
        >
          {notice.message[i18n.locale]}
        </TextTicker>
      </View>
      <MaterialCommunityIcons
        style={styles.close}
        name="window-close"
        size={24}
        color='#fff'
      />
    </TouchableOpacity>
  )
}

NoticeBar.propTypes = {
  currentUser: PropTypes.any,
  analyticsNoticeReceived: PropTypes.func,
  analyticsNoticeClosed: PropTypes.func,
  closeNotice: PropTypes.func
}

const styles = StyleSheet.create({
  container: {
    height: 30, 
    backgroundColor: colors.red,

    flexDirection: 'row',
    alignItems: 'center',

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  close: {
    position: 'absolute',
    right: 5,
  },
  message: {
    color: '#fff',
  },
  messageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 30
  }
})

const mapDispatchToProps = (dispatch) => ({
  analyticsNoticeReceived: ({ id, name }) => {
    dispatch({
      type: ANALYTICS_NOTICE_RECEIVED,
      data: {
        notice: { id, name },
      },
    })
  },
  analyticsNoticeClosed: ({ id, name }) => {
    dispatch({
      type: ANALYTICS_NOTICE_CLOSED,
      data: {
        notice: { id, name },
      },
    })
  },
})

const composed = compose(
  withCurrentUser,
  connect(null, mapDispatchToProps),
  graphql(schema.mutations.closeNotice, {
    name: 'closeNotice',
  }),
)

export default memo(
  composed(NoticeBar)
)