import React from 'react'
import PropTypes from 'prop-types'
import { Text, View, TouchableOpacity, Platform, FlatList, ActivityIndicator, Keyboard, ScrollView, BackHandler } from 'react-native'
import MDIcons from '@expo/vector-icons/MaterialCommunityIcons'
import NamedImage from '../../components/simple/NamedImage'
import Icons from '@expo/vector-icons/Octicons'
import Ionicons from '@expo/vector-icons/Ionicons'
import Feather from '@expo/vector-icons/Feather'
import TextInputFB from '../../components/simple/TextInput'
import { ProductGridView } from '../../components/Product'
import MobileBackButton from '../../components/simple/MobileBackButton'
import Button from '../../components/simple/Button'
import Scroller from '../../components/Scroller'
import ObjectSearchCriteria from './ObjectSearchCriteria'
import ProductResult from './results/ProductResult'
import ProducerResult from './results/ProducerResult'
import PeopleResult from './results/PeopleResult'
import RecipeResult from './results/RecipeResult'
import withKeyboardInfo from '../../containers/withKeyboardInfo'
import withAlgoliaSearch from '../../containers/withAlgoliaSearch'
import { SEARCH_SET_RESET_FUNCTION } from '../../reducers/search'

import sharedStyles, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import normalize from '../../utility/normalize'
import services from '../../utility/services'

import i18n from 'i18n-js'
import produce from 'immer'
import debounce from 'debounce-promise'
import _ from 'lodash'

import { connect, graphql } from '../../config/connected'
import shopSchema from '../../schema/shop'
import postSchema from '../../schema/post'
import { client } from '../../containers/withApollo'

import {
  SEARCH_OBJECT_PRODUCTS,
  SEARCH_OBJECT_PRODUCERS,
  SEARCH_OBJECT_PEOPLE,
  SEARCH_OBJECT_RECIPES,
  SEARCH_OBJECTS,
} from '../../config/constants'

import NavigationActions from '../../utility/navigationActions'
import {
  ProductSearchManager,
  LOAD_SEARCH_PRODUCTS,
  LOAD_PRODUCERS_PRODUCTS,
  SEARCH_MANAGER_PRODUCTS,
  PRODUCT_FILTER_PRODUCER_IDS,
} from './config/ProductSearchManager'
import { Status } from '../../components/simple/Status'
import {
  ProducerSearchManager,
  LOAD_SEARCH_PRODUCERS,
  LOAD_MAP_PRODUCERS,
  SEARCH_MANAGER_PRODUCERS,
  PRODUCER_FILTER_REGION,
  PRODUCER_FILTER_RADIUS,
} from './config/ProducerSearchManager'
import {
  PeopleSearchManager,
  LOAD_SEARCH_PEOPLE,
  SEARCH_MANAGER_PEOPLE,
  PEOPLE_SORT_CREATEDAT_DESC,
} from './config/PeopleSearchManager'
import {
  RecipeSearchManager,
  LOAD_SEARCH_RECIPES,
  SEARCH_MANAGER_RECIPES,
} from './config/RecipeSearchManager'
import {
  SEARCH_SET_STATE,
  SEARCH_MAP_VIEW,
  SEARCH_FORM_VIEW,
  SEARCH_TRANSFER_STATE,
  SEARCH_CLEAR_REGION,
  SEARCH_REFRESH_TYPE_COMPLETE,
} from '../../reducers/search'
import { ANALYTICS_ALGOLIA_SEARCH } from '../../reducers/analytics'
import { unitPlural } from '../../config/helpers'

const containerPaddingLeft = 10
const containerPaddingRight = 10
const autocompleteBlockTop = Platform.OS === 'android' ? 70 : 88

const mapStateToProps = (state, ownProps) => {
  const routeName = _.get(ownProps, 'navigation.state.routeName')
  const extraProps = {
    screenInfo: state.screenInfo,
    search: state.search,
    searchUpdated: state.search.searchUpdated,
  }
  if (routeName === 'AdminUsers') {
    extraProps.searchObjects = [SEARCH_OBJECT_PEOPLE]
    extraProps.forAdmin = true
    extraProps.activeObjectType = SEARCH_OBJECT_PEOPLE
  }
  return extraProps
}

const mapDispatchToProps = (dispatch) => ({
  goToHome: () => {
    NavigationActions.back()
  },
  goToProductMap: () => {
    NavigationActions.navigate({
      routeName: 'Maps',
      params: { objectType: SEARCH_OBJECT_PRODUCTS },
    })
  },
  goToProducerMap: () => {
    NavigationActions.navigate({
      routeName: 'Maps',
      params: { objectType: SEARCH_OBJECT_PRODUCERS },
    })
  },
  goToProduct: ({ id }) => {
    NavigationActions.navigate({ routeName: 'Product', params: { id } })
  },
  goToProfile: ({ id, username, profileImage }) => {
    NavigationActions.navigate({
      routeName: 'Profile',
      params: { id, pageTitle: username, profileImage },
    })
  },
  goToRecipe: ({ id }) => {
    NavigationActions.navigate({
      routeName: 'RecipeDetails',
      params: { id },
    })
  },
  setSearchState: (data) => {
    dispatch({
      type: SEARCH_SET_STATE,
      forView: SEARCH_MAP_VIEW,
      data,
    })
  },
  transferSearchStateFor: (searchObjectType) =>
    dispatch({ type: SEARCH_TRANSFER_STATE, searchObjectType }),
  clearRegion: (searchObjectType) => {
    dispatch({
      type: SEARCH_CLEAR_REGION,
      searchObjectType,
    })
  },
  setSearchResetFunction: (func) => {
    dispatch({
      type: SEARCH_SET_RESET_FUNCTION,
      payload: { resetFunc: func },
    })
  },
  analyticsAlgoliaSearch: (data) => {
    dispatch({
      type: ANALYTICS_ALGOLIA_SEARCH,
      data,
    })
  },
  dispatch,
})
@graphql(shopSchema.queries.shopsByIds, {
  name: 'shopsByIds',
  options: {
    variables: {
      ids: [],
    },
  }
})
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@withKeyboardInfo
@withAlgoliaSearch
class ObjectSearch extends React.Component {
  tabPresses = {}

  static propTypes = {
    searchObjects: PropTypes.arrayOf(PropTypes.oneOf(SEARCH_OBJECTS)),
    activeObjectType: PropTypes.oneOf(SEARCH_OBJECTS),
    forAdmin: PropTypes.bool,
    analyticsAlgoliaSearch: PropTypes.func,
    shopsByIds: PropTypes.object,
  }

  static defaultProps = {
    searchObjects: SEARCH_OBJECTS,
    activeObjectType: SEARCH_OBJECT_PRODUCTS,
    forAdmin: false,
  }

  constructor(props) {
    super(props)
    const { searchObjects, activeObjectType, algoliaSearch } = props
    this.resultsScrollerRef = null
    this.tabConfigs = {}
    this.searchQuery = ''
    const tabs = {}
    searchObjects.forEach((objectType) => {
      tabs[objectType] = {}
      tabs[objectType].initialSearchDone = false
      const searchType = this.getSearchType(objectType)
      const objectSearchManager = this.getObjectSearchManager(objectType)
      this.tabConfigs[objectType] = {
        onPress: () => {
          this.onUnselectCategory()
          this.setActiveObjectType(objectType)
        },
        searchType,
        objectSearchManager,
        search: debounce(
          async() =>{
            await objectSearchManager.loadMoreResultsFor(searchType, { defaultSort: 'createdAtDesc', algoliaSearch })
            const searchParams = _.get(this.state, `${searchType}.searchParams`)
            const nbHits = _.get(this.state, `${searchType}.nbHits`)
            this.props.analyticsAlgoliaSearch({ searchType, searchParams, nbHits })
          },
          100,
        ),
      }
    })
    this.state = {
      tabs,
      activeObjectType,
      criteriaModalVisible: false,
      showResults: null,
      loading: false,
      autocompleteResults: [],
    }
    this.showAutocomplete = false;
    this.normalizedShopsById = {byId: {}, allIds: []}
  }

  hideAutocomplete = () => {
    this.showAutocomplete = false
    this.setState({
      autocompleteResults: [],
    })
    Keyboard.dismiss()
  }
  
  componentDidMount() {
    this.initialTabSearch()
    this.props.setSearchResetFunction(this.resetSearch)

    this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.hideAutocomplete);
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      this.hideAutocomplete,
    )
  }  

  componentWillUnmount() {
    this.backHandler.remove()
    this.keyboardDidHideListener.remove()
  }

  resetSearch = () => {
    const { searchObjects, activeObjectType, algoliaSearch } = this.props
    const tabs = {}
    searchObjects.forEach((objectType) => {
      tabs[objectType] = {}
      tabs[objectType].initialSearchDone = false
      const searchType = this.getSearchType(objectType)
      const objectSearchManager = this.getObjectSearchManager(objectType)
      this.tabConfigs[objectType] = {
        onPress: () => {
          this.onUnselectCategory()
          this.setActiveObjectType(objectType)
        },
        searchType,
        objectSearchManager,
        search: debounce(
          () => objectSearchManager.loadMoreResultsFor(searchType, { defaultSort: 'createdAtDesc', algoliaSearch }),
          100,
        ),
      }
    })

    this.setState({
      tabs,
      activeObjectType,
      criteriaModalVisible: false,
    })

    _.forEach(this.tabConfigs, ({ objectSearchManager }) => {
      objectSearchManager.setFilter('query', '')
    })
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { tabs, activeObjectType, ProductSearchManager } = this.state
    const { transferSearchStateFor } = this.props
    const tabState = tabs[activeObjectType]
    if (activeObjectType === SEARCH_OBJECT_PRODUCTS || tabState.initialSearchDone) {
      const { objectSearchManager } = this.tabConfigs[activeObjectType]
      if (this.stateToImport()) {
        const searchState = this.getStateToImport()
        transferSearchStateFor(activeObjectType)
        await objectSearchManager.importSearchState(searchState)
      }
      await this.refreshTypes()
    } else {
      this.initialTabSearch()
    }
    if (ProductSearchManager) {
      const {ProductSearchManager: oldManager}= prevState
      let results = _.get(ProductSearchManager, 'searchTypes.load_producers_products.results', [])
      let oldResults = _.get(oldManager, 'searchTypes.load_producers_products.results', [])
      if (activeObjectType === SEARCH_OBJECT_PRODUCTS && oldResults !== results) {
        this.fetchShops(results)
      }
    }

  }



  fetchShops = (items) => {
    const { shopsByIds } = this.props
    let shops = {}
    items.forEach(product => shops[product.shopId] = true)
    let shopIds = Object.keys(shops)
    shopsByIds.fetchMore({
      variables: { ids: shopIds },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.normalizedShopsById = normalize(fetchMoreResult.shopsByIds)
        return {
          shopsByIds: fetchMoreResult.shopsByIds,
        }
      },
    })
  }

  async initialTabSearch() {
    const { tabs, activeObjectType } = this.state
    if (activeObjectType === SEARCH_OBJECT_PRODUCTS || tabs[activeObjectType].initialSearchDone) {
      return
    }
    const config = this.tabConfigs[activeObjectType]
    await config.search()
    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.tabs[activeObjectType].initialSearchDone = true
      })
    }
    this.setState(nextState)
  }

  getStateToImport() {
    const { search } = this.props
    const { activeObjectType } = this.state
    const searchManager = this.getObjectSearchManagerType(activeObjectType)
    return _.get(search, `${activeObjectType}.${searchManager}`, {})
  }

  stateToImport() {
    const { search } = this.props
    const searchState = _.get(search, this.state.activeObjectType, {})
    return (
      searchState.forView === SEARCH_FORM_VIEW && !searchState.stateTransferred
    )
  }

  async refreshTypes() {
    const { search, dispatch } = this.props
    for (const objectType in search) {
      const refresh = _.get(search, `${objectType}.refresh`, false)
      if (refresh) {
        console.log('<<<REFRESHING', objectType)
        dispatch({
          type: SEARCH_REFRESH_TYPE_COMPLETE,
          searchObjectType: objectType,
        })
        this.refreshType(objectType)
      }
    }
  }

  refreshType = async (objectType, sort) => {
    const { algoliaSearch } = this.props
    const searchType = this.getSearchType(objectType)
    const manager = this.getObjectSearchManager(objectType)
    await manager.clearResultsFor(searchType)
    await manager.loadMoreResultsFor(searchType, { defaultSort: sort, algoliaSearch })
  }

  async exportState() {
    const { activeObjectType } = this.state
    const { objectSearchManager } = this.tabConfigs[activeObjectType]
    const searchType = this.getSearchType(activeObjectType)
    const stateToExport = objectSearchManager.exportSearchState(searchType)
    const searchManagerType = this.getObjectSearchManagerType(activeObjectType)
    this.props.setSearchState({
      [activeObjectType]: {
        [searchManagerType]: stateToExport,
      },
    })
  }

  hasRegionData() {
    const { activeObjectType } = this.state
    const { objectSearchManager } = this.tabConfigs[activeObjectType]
    if (activeObjectType === SEARCH_OBJECT_PRODUCTS) {
      return objectSearchManager.getFilter(PRODUCT_FILTER_PRODUCER_IDS)
    } else {
      return objectSearchManager.getFilter(PRODUCER_FILTER_REGION)
    }
  }

  clearRegion = async () => {
    const { activeObjectType } = this.state
    const { objectSearchManager, search } = this.tabConfigs[activeObjectType]
    if (activeObjectType === SEARCH_OBJECT_PRODUCTS) {
      await objectSearchManager.unsetFilter(PRODUCT_FILTER_PRODUCER_IDS)
    } else {
      await objectSearchManager.unsetFilter(PRODUCER_FILTER_REGION)
      await objectSearchManager.unsetFilter(PRODUCER_FILTER_RADIUS)
    }
    this.props.clearRegion(activeObjectType)
    this.setState({loading: true}, async () => {
      search()
      this.setState({loading: false})
    })
  }

  getState = () => {
    return this.state
  }

  getObjectSearchManager(objectType) {
    const { algoliaSearch } = this.props
    switch (objectType) {
      case SEARCH_OBJECT_PRODUCTS:
        return new ProductSearchManager(this)
      case SEARCH_OBJECT_PRODUCERS:
        return new ProducerSearchManager(this)
      case SEARCH_OBJECT_PEOPLE:
        return new PeopleSearchManager(this)
      case SEARCH_OBJECT_RECIPES:
        return new RecipeSearchManager(this)
    }
  }

  getObjectSearchManagerType(objectType) {
    switch (objectType) {
      case SEARCH_OBJECT_PRODUCTS:
        return SEARCH_MANAGER_PRODUCTS
      case SEARCH_OBJECT_PRODUCERS:
        return SEARCH_MANAGER_PRODUCERS
      case SEARCH_OBJECT_PEOPLE:
        return SEARCH_MANAGER_PEOPLE
      case SEARCH_OBJECT_RECIPES:
        return SEARCH_MANAGER_RECIPES
    }
  }

  getSearchType(objectType) {
    switch (objectType) {
      case SEARCH_OBJECT_PRODUCTS:
        return LOAD_PRODUCERS_PRODUCTS
      case SEARCH_OBJECT_PRODUCERS:
        return LOAD_MAP_PRODUCERS
      case SEARCH_OBJECT_PEOPLE:
        return LOAD_SEARCH_PEOPLE
      case SEARCH_OBJECT_RECIPES:
        return LOAD_SEARCH_RECIPES
    }
  }

  tabs = () => {
    const { searchObjects } = this.props
    if (searchObjects.length === 1) {
      return null // NOTE: No need for tabs if the search is only configured for one search object type
    }
    return (
      <View style={[styles.tabs]}>
        {Object.keys(this.tabConfigs).map((objectType) => {
          const { onPress } = this.tabConfigs[objectType]
          return (
            <TouchableOpacity
              style={[
                objectType === this.state.activeObjectType && styles.activeTab,
              ]}
              key={objectType}
              onPress={onPress}
            >
              <Text>{i18n.t(`search.tabs.${objectType}`)}</Text>
            </TouchableOpacity>
          )
        })}
      </View>
    )
  }

  renderItem = ({item: resource, index}) => {
    const { goToProduct, goToProfile, screenInfo, forAdmin } = this.props
    const maxWidth =
      screenInfo.contentWidth - (containerPaddingLeft + containerPaddingRight)
    const item = JSON.parse(JSON.stringify(resource))
    if (item.shopName) {
      // For products
      item.profile = { displayName: resource.shopName }
      item.ratingCount = item.ratings
    }
    const objectType = this.state.activeObjectType

    if (objectType === SEARCH_OBJECT_PRODUCTS) {
      let shipCost = this.normalizedShopsById.byId[item.shopId]
      if (shipCost) {
        item.profile.standardShippingFee = shipCost.standardShippingFee
      }
      const width = screenInfo.contentWidth - 100
      return (
        <ProductGridView
          key={item.id}
          style={{ paddingVertical: 15 }}
          sizeMult={2}
          width={width}
          product={item}
          shopName={`${i18n.t('common.by')} ${item.shopName}`}
          shopShipCost={!!shipCost}
        />
      )
    } else if (objectType === SEARCH_OBJECT_PRODUCERS) {
      const { address } = item
      return (
        <View key={item.id}>
          <ProducerResult
            style={styles.producer}
            user={item}
            rating={item.rating}
            ratings={item.ratings}
            address={address}
            nameStyle={styles.producerName}
            locationStyle={styles.producerLocation}
            detailsStyle={styles.producerDetails}
            imageSize={46}
          />
        </View>
      )
    } else if (objectType === SEARCH_OBJECT_PEOPLE) {
      return (
        <PeopleResult
          forAdmin={forAdmin}
          key={item.id}
          profileOnPress={goToProfile}
          adminActionOnPress={() =>
            this.refreshType(SEARCH_OBJECT_PEOPLE, PEOPLE_SORT_CREATEDAT_DESC)
          }
          profile={item}
        />
      )
    } else if (objectType === SEARCH_OBJECT_RECIPES) {
      const { uploads, story } = item
      const post = { id: item.id, recipe: item, story, uploads }
      return (
        <RecipeResult
          key={item.id}
          post={post}
          onPress={this.goToRecipe}
          maxWidth={maxWidth}
        />
      )
    }
    return null
  }

  goToRecipe = async (post) => {
    const { id } = post
    const apolloQuery = {
      query: postSchema.queries.post,
      variables: { id },
    }
    const response = await client.query(apolloQuery)
    if (!response.data.post) {
      const title = i18n.t('search.misc.notAvailable', {
        subject: i18n.t('search.subject.recipe'),
      })
      const message = i18n.t('search.misc.refreshingResults', {
        subject: i18n.t('search.tabs.recipes'),
      })
      await alert({ message, title })
      await this.refreshType(SEARCH_OBJECT_RECIPES)
      return
    }
    this.props.goToRecipe(post)
  }

  mapViewAction = async () => {
    this.exportState()
    const result = await services.getUserLocationAsync()
    if (result === -1) {
      return
    }
    if (this.state.activeObjectType === SEARCH_OBJECT_PRODUCTS) {
      this.props.goToProductMap()
    } else {
      this.props.goToProducerMap()
    }
  }

  onSelectCategory = (category) => {
    let field = 'tags.' + category
    const {objectSearchManager, search} = this.getSearchManagerData()
    objectSearchManager.setFilter(field, true)
    this.setState({loading: true}, async () => {
      await search()
      this.setState({loading: false})
    })
    this.setState({showResults: true})
  }

  onUnselectCategory = async () => {
    const { activeObjectType } = this.state
    const {objectSearchManager, searchType} = this.getSearchManagerData()
    // objectSearchManager.unsetFilter(field)
    objectSearchManager.unsetFilter('query')
    this.searchQuery = ''
    if (activeObjectType === SEARCH_OBJECT_PRODUCTS) {
      await objectSearchManager.clearCriteriaFor(searchType)
      await objectSearchManager.clearPreviousCriteria(searchType)
      await objectSearchManager.clearResultsFor(searchType)
    }
    if (_.get(this.resultsScrollerRef, 'scrollToOffset')) {
      this.resultsScrollerRef.scrollToOffset({ offset: 0, animated: false })
    }
    this.setState({
      showResults: false,
      autocompleteResults: [],
    })
  }

  getSearchManagerData = () => {
    const { activeObjectType } = this.state
    const { objectSearchManager, searchType, search } = this.tabConfigs[
      activeObjectType
    ]
    return { objectSearchManager, searchType, search }
  }
  getCategories = () => {
    const {objectSearchManager, searchType} = this.getSearchManagerData()
    const criteria = objectSearchManager.getCriteriaFor(searchType)
    const { filters: {tags} } = criteria
    return tags
  }


  status() {
    const { activeObjectType, loading } = this.state

    const { objectSearchManager, searchType } = this.tabConfigs[
      activeObjectType
    ]
    let message = i18n.t('search.misc.notFound', {
      subject: i18n.t(`search.tabs.${this.state.activeObjectType}`),
    })
    const criteriaCount = objectSearchManager.getCriteriaCountFor(searchType)
    if (criteriaCount) {
      message += '\n' + i18n.t('search.misc.changeFilter1')
    }
    if (this.hasRegionData()) {
      message += i18n.t('search.misc.changeFilter2')
    }

    const executionsFor = objectSearchManager.getExecutionsFor(searchType)
    // console.log('executionsFor', executionsFor)
    if (searchType === LOAD_SEARCH_PEOPLE && (executionsFor === 0)) {
      return null
    }

    return (
      <View style={[styles.statusContainer]}>
        <Status style={styles.status} message={message} />
      </View>
    )
  }

  renderCategoryItem = ({item}) => {
    const {screenInfo} = this.props
    let categories = this.getCategories()
    const label = categories.keyLabelPairs[item]
    let imagedName = `categories/${item}.jpg`
    const width = (screenInfo.contentWidth - 100) * 0.3
    return (
      <TouchableOpacity
        style={styles.categoryItemContainer}
        onPress={() => this.onSelectCategory(item)}
      >
        <NamedImage
          style={[styles.categoryIcon, {width, height: width}]}
          name={imagedName}
          isAsset
          width={width}
          height={width}
          resizeMode='cover'
        />
        <Text style={styles.categoryLabel}>{label}</Text>
      </TouchableOpacity>
    )
  }

  renderCategories = () => {
    let categories = this.getCategories()
    if (!_.isEmpty(categories)) {
      return (
        <FlatList
          data={Object.keys(categories.keyLabelPairs)}
          renderItem={this.renderCategoryItem}
          ref={this.setResultsScrollerRef}
          keyExtractor={item => item}
          style={{marginBottom: 35}}
        />
      )
    }
  }

  renderResultsHeader = (results) => {
    const { activeObjectType, showResults } = this.state
    const {objectSearchManager, searchType} = this.getSearchManagerData()
    const criteriaCount = objectSearchManager.getCriteriaCountFor(searchType)
    let isProductsTab = activeObjectType === SEARCH_OBJECT_PRODUCTS
    let productsShown = isProductsTab && showResults

    let Content = null

    if (productsShown) {
      Content = (
        <TouchableOpacity
          style={styles.backIcon}
          onPress={this.onUnselectCategory}
        >
          <Ionicons
            name={'ios-arrow-back'}
            size={27}
            color={colors.black}
            style={styles.forward}
          />
        </TouchableOpacity>
      )
    } else if (isProductsTab) {
      Content =  <Text style={styles.categoriesHeader}>{i18n.t('search.misc.allCategories')}</Text>
    }
    return (
      <View style={[styles.resultsHeader, isProductsTab && {justifyContent: 'space-between'}]}>
        {Content}
        <TouchableOpacity
          style={styles.filtersButton}
          onPress={this.showCriteria}
        >
          <Text style={styles.filtersButtonText}>
            {i18n.t('search.filters.general.title')}
            {criteriaCount  > 0 && ` (${criteriaCount})`}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderResults = (results) => {
    const { forAdmin } = this.props
    const { activeObjectType, showResults } = this.state
    if (this.searchQuery === '' && activeObjectType === SEARCH_OBJECT_PRODUCTS && !showResults) {
      return this.renderCategories()
    }
    const { loading } = this.state
    if (loading) {
      return <ActivityIndicator size='large' />
    }

    return (
      <FlatList
        contentContainerStyle={styles.resultsListContentContainer}
        data={results}
        ListEmptyComponent={this.status()}
        keyboardShouldPersistTaps={'always'}
        keyExtractor={item => item.id}
        renderItem={this.renderItem}
        ref={this.setResultsScrollerRef}
        onEndReachedThreshold={0.4}
        onEndReached={this.loadMore}
      />
    )
  }


  renderSearchBar = ({main = true} = {}) => {
    const { activeObjectType } = this.state
    const { objectSearchManager, searchType } = this.tabConfigs[
      activeObjectType
    ]
    const { autocompleteResults, criteriaModalVisible } = this.state
    const filters = objectSearchManager.getCurrentFiltersFor(searchType)
    const {tags = {}} = filters
    let selectedCategory = Object.keys(tags)[0]
    if (selectedCategory && activeObjectType === 'products') {
      let categories = this.getCategories()
      const categorylabel = categories.keyLabelPairs[selectedCategory]
      placeholder = i18n.t('search.placeholders.productsWithCategory', {category: categorylabel})
    }
    let placeholder = i18n.t(`search.placeholders.${activeObjectType}`)
    return (
      <>
      <TextInputFB
        iconName={main ? 'ios-arrow-back' : 'ios-search'}
        iconStyle={styles.searchBarIcon}
        placeholder={placeholder}
        size={10}
        thin
        value={filters.query}
        style={[
          styles.searchInputWrapper,
          (filters.query && autocompleteResults.length && !criteriaModalVisible)? styles.searchWrapperAutocomplete : null
        ]}
        inputStyle={styles.searchInput}
        underlineColorAndroid='transparent'
        onPress={main ? this.props.goToHome : null}
        onSubmitEditing={this.onSubmitEditing}
        onFocus={() => {
          this.showAutocomplete = true;
        }}
        onBlur={() => this.hideAutocomplete()}
        onChange={async (value) => {
          this.onTabSearchChange(value)
          if(value === '') {
            this.setState(prevState => ({
              autocompleteResults: [],
            }))
            return
          }
          const { activeObjectType } = this.state
          const { objectSearchManager, searchType, search } = this.tabConfigs[
            activeObjectType
          ]
          !criteriaModalVisible && await search()
          if(!this.showAutocomplete) {
            this.setState(prevState => ({
              ...prevState,
              autocompleteResults: [],
            }))
            return
          }
          const results = objectSearchManager.getResultsFor(searchType)
          const highlightResult = results.map((item) => {
            return Object.keys(item._highlightResult).map(function(key) {
              return [{...item._highlightResult[key], name: key, response: item}]
            })
          })
          const hightlights = highlightResult.reduce((a, b) => a.concat(...b), [])
          const uniqueValueHighlights = _.uniqBy(hightlights, 'value')

          const autocompleteResults = uniqueValueHighlights.map(hightlight => {
            const localHightlight = !hightlight.value ? hightlight.state : hightlight
            const { response, name } = hightlight
            
            if(localHightlight.matchLevel === 'full') {
              const { value } = localHightlight
              const arrayNames = value.split(/<[a-zA-Z0-9]*>([^<.*>;]*)<\/[a-zA-Z0-9]*>/gmi)

              if (searchType === LOAD_PRODUCERS_PRODUCTS) {
                const unitProduct = unitPlural(response /*as product*/, true)

                if (unitProduct) {
                  arrayNames.push(unitProduct)
                }
              }

              return {
                arrayNames,
                name,
                response,
                id: Math.random()
              }
            }
          }).filter((item) => {
            if(item) {
              return item
            }
          })
          this.setState(prevState => ({
            ...prevState,
            autocompleteResults,
          }))
        }}
        onClear={this.onTabSearchClear}
      />
      {
        (filters.query && autocompleteResults.length && this.showAutocomplete && !this.state.criteriaModalVisible) ? <FlatList
          style={styles.autocompleteBlock}
          data={autocompleteResults}
          keyboardShouldPersistTaps="always"
          renderItem={({item}) => {
            return (
              <View style={styles.autocompleteItemView}>
                <TouchableOpacity
                  style={styles.autocompleteItemHighlight}
                  onPress={async () => {
                    Keyboard.dismiss()
                    await this.onTabSearchChange(item.name === 'address' ? item.response.address['state'] : item.response[item.name])
                    this.search(true)
                    this.setState(prevState => ({
                      autocompleteResults: [],
                      showResults: true
                    }))
                  }}
                >
                  {
                    <Text
                      ellipsizeMode={'tail'}
                      numberOfLines={1}
                    >
                      {item.arrayNames.map((words, i) => {
                        return (
                          <Text
                            key={i}
                            style={[
                              styles.autocompleteItem,
                              filters.query.toLowerCase().indexOf(words.toLowerCase()) >= 0 ? styles.autocompleteItemBold : ''
                            ]}>{words}
                          </Text>
                        )
                      })}
                    </Text>
                  }
                </TouchableOpacity>
              </View>
            )
          }}
          keyExtractor={item => item.id}
        /> : <></>
      }
    </>
    )
  }

  tabContent = () => {
    const { activeObjectType } = this.state
    const { objectSearchManager, searchType, search } = this.tabConfigs[
      activeObjectType
    ]
    const results = objectSearchManager.getResultsFor(searchType)
    return (
      <View style={{flex: 1, position: 'relative'}}>
        <ScrollView style={[styles.contentContainer]} keyboardShouldPersistTaps="always">
          <ObjectSearchCriteria
            searchInput={this.renderSearchBar({main: false})}
            isVisible={this.state.criteriaModalVisible}
            setVisibility={this.setVisibility}
            objectSearchManager={objectSearchManager}
            searchType={searchType}
            search={this.onFilterScreenSearch}
          />
          <View style={styles.searchWrapper}>
            {this.hasRegionData() && (
              <TouchableOpacity
                style={[styles.chosenLabel]}
                onPress={this.clearRegion}
              >
                <Ionicons
                  name='md-close'
                  style={styles.chosenLabelIcon}
                  color={colors.text.invert}
                  size={16}
                />
                <Text style={[styles.chosenLabelName]}>
                  Selected Map Area Filter
                </Text>
              </TouchableOpacity>
            )}
          </View>
          {this.renderResultsHeader(results)}
          {this.renderResults(results)}

        </ScrollView>
        {((activeObjectType === SEARCH_OBJECT_PRODUCTS ||
          activeObjectType === SEARCH_OBJECT_PRODUCERS)) && (
          <Button
            childrenAfterLabel={false}
            label={i18n.t('search.buttons.map')}
            labelStyle={{fontSize: 16}}
            style={styles.mapButton}
            onPress={this.mapViewAction}
          >
            <Feather
              name='map'
              style={styles.chosenLabelIcon}
              color={colors.text.invert}
              size={16}
            />
          </Button>
        )}
      </View>
    )
  }

  setResultsScrollerRef = (ref) => ( ref && (this.resultsScrollerRef = ref))

  renderActionIcon(active) {
    return <MDIcons name={'map-marker'} size={26} color='white' />
  }

  render() {
    const { keyboardInfo } = this.props
    const rootStyles = [styles.container]
    if (keyboardInfo.visible && Platform.OS === 'android') {
      rootStyles.push(sharedStyles.noTabScreen)
    } else {
      rootStyles.push(styles.tabbedScreen)
    }
    return (
      <View style={rootStyles}>
        {this.renderSearchBar()}
        {this.tabs()}
        {this.tabContent()}
      </View>
    )
  }

  showCriteria = () => {
    this.setVisibility(true)
  }

  setActiveObjectType(activeObjectType) {
    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.activeObjectType = activeObjectType
      })
    }
    this.setState(nextState)
  }

  onSubmitEditing = async (event) => {
    const {
      nativeEvent: { text, eventCount, target },
    } = event
    this.searchQuery = text
    await this.onTabSearchChange(text, 'submit')
    if (!this.state.criteriaModalVisible) {
      this.search(true)
      this.showAutocomplete = false;
      this.setState({
        showResults: true,
        autocompleteResults: [],
      })
    }
  }

  onTabSearchChange = async (value) => {
    const { activeObjectType } = this.state
    const { objectSearchManager } = this.tabConfigs[activeObjectType]
    return objectSearchManager.setFilter('query', value)
  }

  onTabSearchClear = async () => {
    await this.onTabSearchChange('', 'submit')
    const { activeObjectType } = this.state
    const { objectSearchManager, searchType } = this.tabConfigs[activeObjectType]
    this.searchQuery = ''
    if (!this.state.criteriaModalVisible) {
      await objectSearchManager.clearResultsFor(searchType)
      // objectSearchManager.clearCriteriaFor(searchType)
      this.search(true)
    }
  }

  search = async (onTextChange = false) => {
    const { activeObjectType } = this.state
    const { search } = this.tabConfigs[activeObjectType]
    this.setState({loading: true}, async () => {
      await search()
      this.setState({loading: false})
    })
    // reset scroll position since we are starting over with the results.
    // Scroll only if we are showing results, not categories.
    if (onTextChange) {
      const scrollTo = _.get(this.resultsScrollerRef, 'scrollTo') || (() => {})
      scrollTo({ y: 0, animated: false })
    }
  }
  loadMore = async () => {
    console.log('loadMore')
    const { activeObjectType } = this.state
    const { search } = this.tabConfigs[activeObjectType]
    this.setState({loadingMore: true}, async () => {
      await search()
      this.setState({loadingMore: false})
    })
  }

  onFilterScreenSearch = () => {
    this.search(true)
    this.setState({showResults: true})
  }

  setVisibility = (visibility) => {
    const nextState = (state, props) => {
      return produce(state, (draft) => {
        draft.criteriaModalVisible = visibility
      })
    }
    this.setState(nextState)
  }
}

ObjectSearch.navigationOptions = (props) => {
  const routeName = _.get(props, 'navigation.state.routeName')
  const dispatch = _.get(props, 'navigation.dispatch')
  const dynamicPairs = {}
  if (routeName === 'AdminUsers') {
    dynamicPairs.headerTitle = i18n.t('admin.manageUsers.title')
    dynamicPairs.headerLeft = () => (
      <MobileBackButton
        label='Back'
        onPress={() => NavigationActions.back()}
      />
    )
  } else {
    dynamicPairs.header = () => null
  }
  return {
    ...dynamicPairs,
  }
}

const styles = stylus({
  tabbedScreen: {
    web: {
      paddingTop: 15,
    },
    iphonex: {
      paddingTop: sizes.TOP_BAR_HEIGHT,
      paddingBottom: sizes.iphonexTabBarHeight,
    },
    android: {
      paddingTop: sizes.iosStatusBarHeight
    },
    paddingTop: sizes.tabBarHeight,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: containerPaddingLeft,
    paddingRight: containerPaddingRight,
    paddingTop: 25
  },
  tabs: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingVertical: 10,
    position: 'relative',
    zIndex: 1,
  },
  activeTab: {
    paddingBottom: 5,
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.text.main,
  },
  contentContainer: {
    flex: 1,
    position: 'relative',
    marginBottom: 50
  },
  input: {
    flex: 8,
    height: 40,
    paddingHorizontal: 10,
    borderRadius: 5,
    fontSize: 18,
    textAlign: 'left',
    color: colors.text.main,
    backgroundColor: colors.input.backgroundFill,
    web: {
      width: '100%',
    },
    marginRight: 20,
  },
  searchWrapper: {
    position: 'relative',
    zIndex: 15
  },
  searchInputWrapper: {
    // flex: 1,
    alignSelf: 'stretch',
    height: 44,
    web: {
      width: '100%',
    },
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.20,
    shadowRadius: 1,
    borderRadius: 25,
    elevation: 4,
    backgroundColor: 'white',
    marginHorizontal: 5,
  },
  searchInput: {
    flex: 2,
    height: 37,
    fontSize: 15,
    textAlign: 'left',
    color: colors.text.main,
  },
  searchBarIcon: {
    color: colors.text.main,
  },
  filterIcon: {
    transform: [{ rotateZ: '90deg' }],
  },

  filtersButton: {
    flexDirection: 'row',
  },
  filtersButtonText: {
    fontSize: 16,
    color: colors.taggingText
  },

  statusContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  status: {
    flex: 1,
    paddingHorizontal: 20,
  },

  resultsHeader: {
    height: 30,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 15
  },

  // Categories
  backIcon: {
    paddingRight: 15
  },
  categoriesHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  categoryItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10
  },
  categoryLabel: {
    fontSize: 16
  },
  categoryIcon: {
    width: 50,
    height: 50,
    borderRadius: 5,
    marginRight: 15
  },

  resultsListContentContainer: {
    borderColor: null,
    borderWidth: 0,
    alignSelf: 'stretch',
    paddingBottom: sizes.tabBarHeight,
    iphonex: {
      paddingBottom: sizes.iphonexTabBarHeight,
    },
  },

  //Product Results
  productResultsContainer: {
    flexGrow: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },

  //Producer Result
  producer: {
    marginBottom: 5,
    paddingHorizontal: 0,
  },
  producerName: {
    fontWeight: '500',
    color: colors.text.main,
    fontSize: 15,
  },
  producerLocation: {
    fontSize: 14,
    color: colors.text.secondary,
    marginBottom: 2,
  },
  producerDetails: {
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
    paddingBottom: 10,
  },

  // Region Tag/Badge
  chosenLabel: {
    borderRadius: 4,
    backgroundColor: colors.primary,
    paddingHorizontal: 7,
    paddingVertical: 5,
    marginRight: 20,
    marginVertical: 3,
    flexDirection: 'row',
    alignItems: 'center',
  },
  chosenLabelIcon: { marginRight: 5, top: 1 },
  chosenLabelSelected: {
    backgroundColor: colors.primary,
  },
  chosenLabelName: {
    color: colors.text.invert,
    fontSize: 14,
  },
  // Filters Badge Number
  iconIndicator: {
    backgroundColor: colors.primary,
    borderRadius: 8,
    width: 16,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconIndicatorText: {
    fontSize: 14,
    // fontWeight: 'bold',
  },
  mapButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.primary,
    position: 'absolute',
    bottom: 50,
    alignSelf: 'center',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 2,
    shadowOpacity: 0.24,
    elevation: 3,
    borderRadius: 20
  },
  autocompleteItemHighlight: {
    flex: 1,
    padding: 10,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  autocompleteItem: {
    color: '#000000',
  },
  autocompleteItemBold: {
    fontWeight: 'bold',
  },
  autocompleteItemView: {
    flex: 1,
    borderBottomColor: colors.thinLine,
    borderBottomWidth: 1,
  },
  autocompleteBlock: {
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    fontSize: 18,
    textAlign: 'left',
    color: colors.text.main,
    backgroundColor: '#ffffff',
    borderColor:  colors.thinLine,
    borderWidth: sizes.px,
    borderTopWidth: 0,
    marginHorizontal: 15,
    position: 'absolute',
    top: autocompleteBlockTop,
    left: 0,
    flex: 1,
    flexDirection: 'column',
    right: 0,
    zIndex: 15,
    elevation: 15,
    maxHeight: 300,
    paddingBottom: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.20,
    shadowRadius: 1,
  },
  searchWrapperAutocomplete: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    shadowOpacity: 0,
  }
})

export default ObjectSearch
