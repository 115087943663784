import React, { useEffect } from 'react'
import { View, ActivityIndicator } from 'react-native'
import PropTypes from 'prop-types'

import NavigationActions from '../../utility/navigationActions'
import { performLogout } from '../../utility/apollo'

const Logout = ({ userId }) => {
  useEffect(() => {
    performLogout(userId)
      .then(() => {
        NavigationActions.goTo({ routeName: 'Start'}, true)
      }).catch((err) => {
        NavigationActions.goTo({ routeName: 'Start'}, true)
      })
  }, [])
  
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <ActivityIndicator size="large" />
    </View>
  )
}

Logout.propTypes = {
  userId: PropTypes.string
}

Logout.navigationOptions = () => ({
  headerLeft: () => {},
  tabBarVisible: false,
})

export default Logout
