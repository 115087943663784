import React from 'react'
import { StyleSheet, View } from 'react-native'

import Action from '../../components/simple/Action'
import _ from 'lodash'

import { colors, sizes } from '../../config'
import {
  connect,
  graphql,
  withApollo,
  compose,
  gql,
} from '../../config/connected'
import NavigationActions from '../../utility/navigationActions'
import { StackActions } from 'react-navigation'
// import sh from 'shorthash'
import schema from '../../schema/conversation'

class StartButton extends React.Component {
  render() {
    const {
      newConversation: { users },
    } = this.props
    return (
      <Action
        disabled={users.length === 0}
        label='Start'
        onPress={this.start}
        style={{ paddingRight: 12 }}
      />
    )
  }
  start = async () => {
    const {
      navigation: { dispatch },
      currentUser,
      newConversation: { users },
    } = this.props
    const userIds = _.map(users, 'id').concat(currentUser.id)

    const result = await this.props.startPrivateConversation({
      variables: {
        userIds,
      },
      update: (store, { data }) => {
        const newMembership = data.startPrivateConversation
        console.log({ newMembership })
        // update conversation list
        const membershipsData = store.readQuery({
          query: schema.queries.conversationMemberships,
        })
        const allMemberships = membershipsData.conversationMemberships
        const existing = _.find(allMemberships, { id: newMembership.id })
        if (existing) {
          // do nothing
        } else {
          allMemberships.unshift(newMembership)
          store.writeQuery({
            query: schema.queries.conversationMemberships,
            data: membershipsData,
          })
        }
      },
    })
    console.log({ result })
    const membershipId = _.get(result, 'data.startPrivateConversation.id')
    const conversationId = _.get(
      result,
      'data.startPrivateConversation.conversation.id',
    )
    // const shortcode = sh.unique(userIds.sort().join(''))
    this.props.reset()
    if (this.props.landscape) {
      this.props.navigation.setParams({ id, users })
    } else {
      NavigationActions.dispatch(StackActions.replace({
        routeName: 'Conversation',
        params: {
          id: membershipId,
          users,
        },
      }))
    }
  }
}

const mapStateToProps = (state) => ({
  newConversation: state.newConversation,
  currentUser: state.currentUser,
})
const mapDispatchToProps = (dispatch) => ({
  reset: () => {
    dispatch({ type: 'NewConversation/RESET' })
  },
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(schema.mutations.startPrivateConversation, {
    name: 'startPrivateConversation',
  }),
)(StartButton)
