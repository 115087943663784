import React from 'react'
import PropTypes from 'prop-types'
import hoistStatics from 'hoist-non-react-statics'
import { ActivityIndicator, SectionList, View, Text } from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import MobileBackButton from '../../components/simple/MobileBackButton'
import Switch from '../../components/simple/Switch'
import ListItem from '../../components/simple/ListItem'
import { withFormik } from 'formik'
import colors from '../../config/colors'
import i18n from 'i18n-js'
import _ from 'lodash'
import { compose, connect, graphql } from '../../config/connected'
import { branch, renderComponent } from 'recompose'
import branchSwitch from '../../config/branch'
import userSchema from '../../schema/user'

const PushNotificationsSettings = props => {
  let {
    values,
    handleChange,
    setFieldValue,
  } = props

  const thumbColor = branchSwitch({
    android: colors.primary,
    ios: null,
    web: 'white'
  })

  return (
    <View style={{ flex: 1 }}>
      <SectionList
        contentContainerStyle={{ paddingRight: 15 }}
        renderItem={({ item, index }) => {
          return (<View key={index} style={{ flexDirection: 'row', alignItems: 'center' }}>
            <ListItem style={{ flex: 1 }} title={i18n.t(`settings.pushNotifications.${item}`)} onPress={() => {
              const newValue = !values[item]
              setFieldValue(item, newValue)
            }}/>
            <Switch
              style={{ marginRight: 5 }}
              trackColor={{ false: colors.active, true: colors.primary }}
              thumbColor={thumbColor}
              value={values[item]}
              onValueChange={(value) => {
                setFieldValue(item, value)
              }}
            />
          </View>)
        }}
        renderSectionHeader={({ section: { header } }) => header}
        sections={[
          {
            header: (
              <ListItem style={{ flex: 1 }} title={i18n.t('settings.pushNotifications.header')} titleStyle={{ fontWeight: '700' }}/>
            ), data: Object.keys(values),
          },
        ]}
        keyExtractor={(item, index) => item + index}
      />
    </View>
  )
}

PushNotificationsSettings.navigationOptions = (props) => {
  return {
    headerTitle: i18n.t('settings.sectionLinks.pushNotifications'),
    headerLeft: (
      <MobileBackButton
        label={i18n.t('common.back')}
        onPress={() => NavigationActions.back()}
      />
    ),
  }
}

PushNotificationsSettings.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

const mapStateToProps = (state) => ({
  userId: state.currentUser.id,
})

const renderWhileLoading = (component, propName = 'data') =>
  branch(
    props => props[propName] && props[propName].loading,
    renderComponent(component),
  )

const Loading = () => <ActivityIndicator style={{ marginTop: 10 }} size="large" color="#000"/>


const composed = compose(
  connect(
    mapStateToProps,
  ),
  graphql(userSchema.queries.notificationSettings, {
    name: 'userSetting',
    options: ({ userId }) => {
      return {
        variables: {
          userId,
        },
      }
    },
  }),
  renderWhileLoading(Loading, 'userSetting'),
  graphql(userSchema.mutations.updateNotificationSettings, { name: 'updateNotificationSettings' }),
  withFormik({
    validateOnChange: true,
    validate: (values, { updateNotificationSettings, userId }) => {
      const reversed = _.mapValues(values, v => !v)
      updateNotificationSettings({
        variables: {
          pushSettings: reversed,
        },
        optimisticResponse: {
          updateNotificationSettings: {
            id: userId,
            notificationSettings: {
              push: {
                ...values,
              },
              __typename: 'NotificationsSettings',
            },
            __typename: 'User',
          },
        },
      })
      return {}
    },
    mapPropsToValues: (props) => {
      const path = 'userSetting.userById.notificationSettings.push'
      const values = {
        // 'pauseAll': _.get(props, `${path}.pauseAll`, false),
        'newFollower': _.get(props, `${path}.newFollower`, true),
        'recipes': _.get(props, `${path}.recipes`, true),
        'userTagging': _.get(props, `${path}.userTagging`, true),
      }
      return _.mapValues(values, v => !v)
    },
  }),
)(PushNotificationsSettings)

export default hoistStatics(composed, PushNotificationsSettings)
