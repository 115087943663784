import Icon from '@expo/vector-icons/Ionicons'
import i18n from 'i18n-js'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import {
  ActivityIndicator,
  Animated,
  FlatList,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Share,
  RefreshControl,
} from 'react-native'
import ActionButton from 'react-native-action-button'
import NavigationActions from '../../utility/navigationActions'
import LayoutAnimation from '../../components/LayoutAnimation'
import PostFB from '../../components/PostFB'
import PostRecipe from '../../components/PostFB/PostRecipe'
import PendingContent from '../../components/PostFB/PendingContent'
import { ProductGridView } from '../../components/Product'
import Review from '../../components/Review'
import Scroller from '../../components/Scroller'
import SearchComponent from '../../components/Search'
import { ShopCover } from '../../components/Shop'
import Divider from '../../components/simple/Divider'
import MobileBackButton from '../../components/simple/MobileBackButton'
import PaymentTypes from '../../components/simple/PaymentTypes'
import T from '../../components/T'
import TabView from '../../components/TabView'
import TagText from '../../components/Tagging/TagText'
import UserHeaderFB from '../../components/UserHeaderFB'
import colors from '../../config/colors'
import { connect, graphql, _get } from '../../config/connected'
import { getPrice, getKeyboardPaddingStyle, getContentLink, getPendingContent } from '../../config/helpers'
import sizes from '../../config/sizes'
import { stylus } from '../../config/styles'
import withPreventDoubleClick from '../../containers/withPreventDoubleClick'
import postSchema from '../../schema/post'
import reviewsSchema from '../../schema/reviews'
import shopSchema from '../../schema/shop'
import userSchema from '../../schema/user'
import ShopDescription from './ShopDescription'
import { ROUTE_REPORT_CONTENT_ORGANIZATION_PROFILE } from '../../config/constants'
import branch from '../../config/branch'
import MoreOptions from '../../components/simple/MoreOptions'
import withCurrentUser from '../../containers/withCurrentUser'
import withKeyboardInfo from '../../containers/withKeyboardInfo'
import { AddToCartConsumer } from '../../containers/AddToCart'
import DraggableFlatList from 'react-native-draggable-flatlist'
import { updateCachedQueryValue } from '../../utility/apollo'
import { client } from '../../containers/withApollo'
import alert from '../../utility/alert'
import equal from 'fast-deep-equal'

import produce from 'immer'

const debug = false
const POST_LIMIT_INCREMENT = 5
const REVIEW_LIMIT_INCREMENT = 10
const PRODUCT_LIMIT_INCREMENT = 5
let getShop = () => {}

@withCurrentUser
@connect(
  ({ screenInfo, publishContent }, ownProps) => ({ 
    screenInfo,
    pendingShopEdit: getPendingContent(publishContent, 'producer', _.get(ownProps, 'navigation.state.params.id'), 'shop.id')
  }),
  (dispatch) => ({
    goToProduct: (id) => NavigationActions.navigate({ routeName: 'Product', params: { id } }),
    goToProfile: ({ id, pageTitle }) => {
      NavigationActions.navigate({
        routeName: 'Profile',
        params: { id, pageTitle },
      })
    },
    createProduct: (shopId) =>
      NavigationActions.navigate({
        routeName: 'CreateProduct',
        params: { shopId },
      })
    ,
  }),
)
@graphql(postSchema.queries.postsByMentionType, {
  name: 'postsHandler',
  options: (props) => ({
    variables: {
      id: props.navigation.state.params.id,
      type: 'shopMention',
      offset: 0,
      limit: POST_LIMIT_INCREMENT,
    },
    fetchPolicy: 'cache-and-network',
  }),
})
@graphql(userSchema.queries.getShop, {
  options: (props) => ({
    variables: {
      id: props.navigation.state.params.id,
    },
    fetchPolicy: 'cache-and-network',
  }),
  name: 'shop',
})
@graphql(shopSchema.queries.getEmployees, {
  name: 'employeesHandler',
  options: (props) => ({
    variables: {
      id: props.navigation.state.params.id,
    },
    fetchPolicy: 'cache-and-network',
  }),
})
@graphql(shopSchema.queries.myShopId, { name: 'myShopId', options: { fetchPolicy: 'cache-and-network' } })
@graphql(shopSchema.queries.productsByUserId, {
  name: 'productsHandler',
  options: (props) => ({
    variables: {
      userId: props.navigation.state.params.id,
      offset: 0,
      limit: PRODUCT_LIMIT_INCREMENT,
    },
    fetchPolicy: 'cache-and-network',
  }),
})
@graphql(reviewsSchema.queries.reviewsByShopId, {
  name: 'reviewsHandler',
  options: (props) => ({
    variables: {
      shopId: props.navigation.state.params.id,
      offset: 0,
      limit: REVIEW_LIMIT_INCREMENT,
    },
    fetchPolicy: 'cache-and-network',
  }),
})
@graphql(shopSchema.mutations.updateProductPositions, {
  name: 'positionHandler'
})

@withKeyboardInfo
class Shop extends React.Component {
  state = {
    shopFilter: '',
    storiesLength: 4,
    descriptionExpanded: false,
    FABActive: false,
    FABOpacity: new Animated.Value(0.0),
    data: {
      productsByUserId: {
        fetching: false,
        allLoaded: false,
      },
      reviewsByShopId: {
        fetching: false,
        allLoaded: false,
      },
      postsByMentionType: {
        fetching: false,
        allLoaded: false,
      }
    },
    orderModal: false,
    shopDiscount: null,
  }
  currentTab = null

  static propTypes = {
    navigation: PropTypes.shape({
      state: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string.isRequired, // id of shop must be passed in navigation params. Everything falls apart if you dont.
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }

  tabViewRef = React.createRef()

  constructor(props) {
    super(props)
    getShop = this.getShop
  }

  componentDidUpdate(prevProps, prevState) {
    const prevPageTitle = _.get(prevProps, 'shop.userById.displayName')
    const currPageTitle = _.get(this.props, 'shop.userById.displayName')
    const navPageTitle = _.get(this.props, 'navigation.state.params.pageTitle')
    if (currPageTitle && currPageTitle !== prevPageTitle && currPageTitle !== navPageTitle) {
      this.props.navigation.setParams({ pageTitle: currPageTitle })
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      shopDiscount: this.props.shop?.userById?.producerDetails?.shopDiscount
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    const shopDiscountUpdated = !equal(
      this.state.shopDiscount,
      nextProps.shop?.userById?.producerDetails?.shopDiscount
    )
    if (shopDiscountUpdated) {
      this.setState({
        shopDiscount: nextProps.shop?.userById?.producerDetails?.shopDiscount
      })
      this.props.productsHandler.refetch()
    }
  }

  getShop = () => {
    const { shop } = this.props
    if (!shop.loading) {
      return shop.userById
    }
    return null
  }

  _goToProfile = ({ id, username }) => {
    this.props.goToProfile({ id, pageTitle: username })
  }

  renderShopEmployees = () => {
    const data = this.props.employeesHandler.userById
    // TODO: Add support for multiple employees.
    const displayName = _get(
      data,
      'producerDetails.ownership.owner.displayName',
      '',
    )
    const id = _get(data, 'producerDetails.ownership.owner.id', '')
    const profileImage = _get(
      data,
      'producerDetails.ownership.owner.profileImage',
      '',
    )
    const role = _get(data, 'producerDetails.ownership.role', '')

    const PreventDoubleClick = withPreventDoubleClick(({ onPress }) => (
      <TouchableOpacity onPress={onPress}>
        <UserHeaderFB
          role={role}
          profileImage={profileImage}
          displayName={displayName}
          key={1}
          style={{ paddingHorizontal: 16, paddingVertical: 8 }}
        />
      </TouchableOpacity>
    ))
    return (
      <PreventDoubleClick
        onPress={() => {
          this._goToProfile({ username: displayName, id })
        }}
      />
    )
  }

  isAdmin = () => {
    return _get(this.props.currentUser, 'currentUser.isAdmin', false)
  }

  setShopFilter = (shopFilter) => {
    this.setState({ shopFilter })
  }

  clearShopFilter = () => {
    this.setState({ shopFilter: '' })
  }

  _keyExtractor = (item) => item.id

  getPosts = () => {
    return _get(this, 'props.postsHandler.postsByMentionType', [])
  }

  renderPost = ({ item }) => {
    // const posts = this.getPosts()

    if (item.type === 'post') {
      return <PostFB user={item.user} post={item} navigation={this.props.navigation}/>
    } else {
      return <PostRecipe post={item} navigation={this.props.navigation}/>
    }
  }

  goToEditShop = () => {
    if (this.props.pendingShopEdit) {
      alert({
        message: i18n.t('publishContent.alert.pendingContent')
      })
      return
    }
    const { navigation } = this.props
    const { dispatch } = navigation
    const shop = this.props.shop.userById
    // NavigationActions.back()
    NavigationActions.navigate({
      routeName: 'EditShop',
      params: {
        ...navigation.state.params,
        shop,
        refreshShopData: this.props.shop.refetch,
        refreshProducts: this.props.productsHandler.refetch,
        reApply: false,
      },
    })
  }

  // playAddToCart = () => {}

  isOwner = () => {
    const { myShopId, navigation } = this.props
    return (
      _.get(myShopId, 'currentUser.ownedProfile.profile.id', 0) ===
      _.get(navigation, 'state.params.id', -1)
    )
  }

  getProducts = () => {
    const { productsHandler } = this.props

    if (productsHandler.loading) {
      return []
    }

    // console.log('::product handler::', productsHandler)
    return productsHandler.productsByUserId
  }

  renderProducts = (item, drag, isActive) => {
    const { screenInfo } = this.props
    const { shopFilter } = this.state
    const isOwner = this.isOwner()
    const isAdmin = this.isAdmin()
    const width = screenInfo.contentWidth - 100
    const isDraggable =  !shopFilter && !item.isDiscontinued && item.isInStock

    if (
      (shopFilter === '' ||
        item.name.toUpperCase().includes(shopFilter.toUpperCase())) &&
      !item.isDiscontinued
    ) {

      return (
        <AddToCartConsumer key={item.id}>
          {(onAddToCart) => {
            return (
              <View style={[{ paddingHorizontal: 10 }, isActive ? styles.elevation : {}]}>
                <ProductGridView
                  key={item.id}
                  style={{ paddingVertical: 15 }}
                  sizeMult={2}
                  width={width}
                  product={item}
                  isOwner={isOwner}
                  hideShop
                  showAddToCart={!isOwner}
                  onAddToCart={onAddToCart}
                  drag={isDraggable ? drag : null}
                  showOrder={isAdmin}
                />
              </View>
            )
          }}
        </AddToCartConsumer>
      )

    } else {
      return <View/>
    }
  }

  renderReviews = (review, index) => {
    const { reviewsHandler } = this.props

    if(review) {
      return <Review review={review} key={index} showImage />
    }
  }

  toggleStory = (override = false) => {
    if (override === true) {
      this.setState({ storiesLength: 9999 })
      return
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        storiesLength: prevState.storiesLength === 4 ? 9999 : 4,
      }
    })
  }

  toggleDesctiption = () => {
    this.animate()
    this.setState({
      descriptionExpanded: !this.state.descriptionExpanded,
    })
  }

  handleFieldChange = (value, target) => {
    this.setState({ [target]: value })
  }

  handleShopScroll = (offsetY) => {
    // offset derived from tabbarHeight + FAB size
    const { FABActive } = this.state
    const offsetDistance = 104
    //const offsetY = e.nativeEvent.contentOffset.y
    const fadeDuration = 100
    if (offsetY > offsetDistance && !FABActive) {
      // if we're over the offset and the FAB isnt active, make it active and animate the fade in.
      this.setState({ FABActive: true })

      Animated.timing(this.state.FABOpacity, {
        duration: fadeDuration,
        toValue: 1,
      }).start()
    } else if (offsetY <= offsetDistance && FABActive) {
      Animated.timing(this.state.FABOpacity, {
        duration: fadeDuration,
        toValue: 0,
      }).start(({ finished }) => {
        if (finished) {
          this.setState({ FABActive: false })
        }
      })
    }
  }

  handleDragEnd = async ({ data: newOrder, from, to}) => {
    if (from === to) return

    if (from < to) {
      const aboveItem = newOrder[to - 1]
      if (aboveItem.isDiscontinued || !aboveItem.isInStock) {
        return
      }
    }

    const { positionHandler, productsHandler } = this.props
    const shopId = this.props.navigation.state.params.id

    const backupQuery = [...productsHandler.productsByUserId]

    updateCachedQueryValue(client, {
      query: shopSchema.queries.productsByUserId,
      variables: { userId: shopId },
      nextValue: () => newOrder
    })

    try {
      const { data } = await positionHandler({ 
        variables: {
          shopId,
          id: productsHandler.productsByUserId[from].id,
          from: from+1,
          to: to+1,
        },
      })
      const newOrderAndPosition = newOrder.map(product => {
        const updatedProduct = data.updateProductPositions.find(p => p.id === product.id)
  
        return {
          ...product,
          position: updatedProduct ? updatedProduct.position : product.position
        }
      })

      updateCachedQueryValue(client, {
        query: shopSchema.queries.productsByUserId,
        variables: { userId: shopId },
        nextValue: () => newOrderAndPosition
      })

    } catch (err) {
      updateCachedQueryValue(client, {
        query: shopSchema.queries.productsByUserId,
        variables: { userId: shopId },
        nextValue: () => backupQuery
      })
    }



  }

  getFarmingMethodText = (shop) => {
    const method = _get(shop, 'producerDetails.method', null)
    if (method) {
      return i18n.t(`categories.method.${method}`)
    }
    return null
  }

  handleScrollToStories = () => {
    this.toggleStory(true)
    this.tabViewRef.current
      .getWrappedInstance()
      .handleTabPress(null, i18n.t('shop.tabTitles.posts'))
  }

  fetchData = async (dataHandler, dataKey, limit, extraVariables = {}) => {
    const dataState = this.state.data[dataKey]
    const { fetching, allLoaded } = dataState
    if(!fetching && !allLoaded) {
      const nextState = produce(this.state, (draft) => { draft.data[dataKey].fetching = true } )
      this.setState(nextState)
      await dataHandler.fetchMore({
        variables: {
          id: this.props.navigation.state.params.id,
          offset: dataHandler[dataKey].length,
          limit,
          ...extraVariables
        },
        updateQuery: (previous, { fetchMoreResult }) => {
          const fetchedData = _.get(fetchMoreResult, dataKey)
          const previousData = _.get(previous, dataKey)
          const newData = _.differenceBy(fetchedData, previousData, 'id')
          const data =  _.concat(previousData, newData)

          const nextDataState = { fetching: false }
          let nextDataResult = { [dataKey]: data }
          if (fetchedData === 0 || previousData.length === data.length) {
            nextDataResult = previous
            nextDataState.allLoaded = true
          }
          const nextState = produce(this.state, (draft) => { draft.data[dataKey] = nextDataState } )
          this.setState(nextState)
          return nextDataResult
        }
      })
    }
  }

  fetchMoreProducts = async (info) => {
    this.fetchData(this.props.productsHandler, 'productsByUserId', PRODUCT_LIMIT_INCREMENT)
  }

  getProductLayout = (data, index) => {
    const productHeight = this.getProductHeight()
    //console.log('<<<GET PRODUCT LAYOUT', productHeight)
    return { length: productHeight, offset: productHeight * index, index }
  }

  // based on ProductGridView sizing logic and passed style
  getProductHeight = () => {
    const width = 50
    const sizeMult = 2
    const paddingVertical = 30
    return (width * sizeMult) + paddingVertical + 1 // for bottom border width
  }

  fetchMoreReviews = async () => {
    this.fetchData(this.props.reviewsHandler, 'reviewsByShopId', REVIEW_LIMIT_INCREMENT)
  }

  fetchMorePosts = async () => {
    this.fetchData(this.props.postsHandler, 'postsByMentionType', POST_LIMIT_INCREMENT, {  type: 'shopMention' })
  }

  refresh = (handler, prop) => {
    this.setState({ data: { ...this.state.data , [prop]: { allLoaded: false, fetching: true } } })
    this.props[handler].refetch()
    setTimeout(() => {
      this.setState({ data: { ...this.state.data , [prop]: { allLoaded: false, fetching: false } } })
    }, 800)
  }


  renderNoShopFound = () => {
    return (
      <View
        style={{
          width: '100%',
          paddingVertical: 25,
          backgroundColor: colors.lightGray,
        }}
      >
        <Text
          style={{
            color: colors.text.mainLighter,
            fontSize: 22,
            fontWeight: 'bold',
            paddingHorizontal: 32,
            textAlign: 'center',
          }}
        >
          {i18n.t('shop.headers.notFound')}
        </Text>
      </View>
    )
  }

  getCurrentTab = (activeTab) => {
    this.currentTab = activeTab
    this.forceUpdate()
  }

  filterPendingContent = (content) => {
    const shop = this.getShop()
    return _.get(content, 'shopId') === _.get(shop, 'id')
  }

  render() {
    const loading = this.props.shop.loading
    const isOwner = this.isOwner()
    const shop = this.getShop()
    const isAdmin = this.isAdmin()
    const { screenInfo, createProduct, reviewsHandler, productsHandler, keyboardInfo } = this.props

    if (!shop && !loading) {
      return this.renderNoShopFound()
    }

    // FAB always active on landscape
    const FABActive = this.state.FABActive || screenInfo.layout === 'landscape'
    const FABOpacity =
      screenInfo.layout === 'landscape' ? 1 : this.state.FABOpacity

    // console.log('<<<<<<<producerDetails.description.value',_get(shop, 'producerDetails.description.value'));

    const minimumOrderAmount = _get(
      shop,
      'producerDetails.minimumOrderAmount',
      0,
    )
    const minimumOrderAmountPrice = getPrice(minimumOrderAmount)
    const keyboardPaddingStyle = getKeyboardPaddingStyle(keyboardInfo)
    const Container = Platform.OS === 'web' ? ScrollView : View

    const useDraggable = isAdmin || isOwner
    const FlatListComponent = useDraggable ? DraggableFlatList : FlatList 
    const refreshControl = useDraggable && Platform.OS === 'android' ? null :  (
      <RefreshControl
        refreshing={
          this.state.data.productsByUserId.fetching || this.props.productsHandler.networkStatus === 4
        }
        onRefresh={() => this.refresh('productsHandler', 'productsByUserId')}
      />
    )

    return (
      <Container style={{ flex: 1, width: screenInfo.contentWidth }}>
        <TabView
          ref={this.tabViewRef}
          size={15}
          titleStyle={styles.tabTitles}
          containerStyle={styles.container}
          titleContainerStyle={styles.tabTitleContainer}
          getCurrentTab={this.getCurrentTab}
        >
          <TabView.Tab label={i18n.t('shop.tabs.shop.tabTitle')}>
            <KeyboardAvoidingView
              style={{ flex: 1 }}
              behavior={Platform.OS === 'ios' ? 'padding' : null}
              enabled
              contentContainerStyle={{
                flex: 1,
              }}
              keyboardVerticalOffset={Platform.OS === 'ios' ? 124 : 0}
            >
              <View style={styles.productsContainer}>
                <FlatListComponent
                  getItemLayout={this.getProductLayout}
                  initialNumToRender={PRODUCT_LIMIT_INCREMENT}
                  data={productsHandler.productsByUserId || [] }
                  renderItem={({item, drag, isActive}) => this.renderProducts(item, drag, isActive)}
                  keyExtractor={this._keyExtractor}
                  onScroll={(e) => this.handleShopScroll(e.nativeEvent.contentOffset.y)}
                  onScrollOffsetChange={offset => this.handleShopScroll(offset)}
                  onEndReachedThreshold={0.5}
                  onEndReached={this.fetchMoreProducts}
                  onDragEnd={this.handleDragEnd}
                  contentContainerStyle={[styles.storiesContentContainer]}
                  animationConfig={{
                    delay: 20000
                  }}
                  refreshControl={refreshControl}
                  ListEmptyComponent={
                    <Text style={styles.description}>
                      {this.isOwner() || this.isAdmin()
                        ? i18n.t('shop.tabs.shop.noProductsOwner')
                        : i18n.t('shop.tabs.shop.noProductsVisitor')}
                    </Text>
                  }
                  ListHeaderComponent={
                    <View style={styles.shopTab}>
                      <ShopCover
                        goToEditShop={this.goToEditShop}
                        isOwner={isOwner}
                        forwardedNavigation={this.props.navigation}
                        shop={shop}
                        isAdmin={isAdmin}
                        currentTab={this.currentTab}
                        pendingShopCover={_.get(this.props.pendingShopEdit, 'payload.coverPhoto')}
                      />
                      <ShopDescription
                        handleScrollToStories={this.handleScrollToStories}
                        shop={shop}
                      />
                      <Text style={styles.shopHeader}>
                        {i18n.t('shop.tabs.shop.searchTitle')}
                      </Text>
                      <View style={{paddingHorizontal: 10}}>
                        <SearchComponent
                          value={this.state.shopFilter}
                          autoCapitalize='none'
                          onClear={() => {
                            this.setState({ shopFilter: '' })
                          }}
                          placeholder={i18n.t('shop.tabs.shop.searchPlaceholder')}
                          style={{ marginTop: 12, marginBottom: 16 }}
                          onChange={(value) => {
                            this.handleFieldChange(value, 'shopFilter')
                          }}
                        />
                        <PendingContent types={['product']} filter={this.filterPendingContent} />
                      </View>
                    </View>
                  }
                />
              </View>
              <View style={keyboardPaddingStyle} />
            </KeyboardAvoidingView>
            {isOwner || isAdmin ? (
              <View
                pointerEvents='box-none'
                style={{
                  position: 'absolute',
                  width: screenInfo.contentWidth,
                  height: screenInfo.height - 114,
                  top: 0,
                  left: 0,
                  opacity: FABOpacity,
                }}
              >
                {FABActive || true ? (
                  <ActionButton
                    size={54}
                    spacing={10}
                    buttonColor={colors.taggingText}
                    offsetY={branch({ other: sizes.tabBarHeight, iphonex: sizes.iphonexTabBarHeight}) + 10}
                    btnOutRange='white'
                    renderIcon={active => {
                      return <Icon name='md-add' size={35} color={active ? 'black' : 'white'} />
                    }}
                  >
                    <ActionButton.Item
                      buttonColor={colors.taggingText}
                      title={i18n.t('shop.tabs.shop.editShop')}
                      onPress={this.goToEditShop}
                    >
                      <Icon name='ios-list' size={35} color='white' />
                    </ActionButton.Item>
                    <ActionButton.Item
                      buttonColor={colors.taggingText}
                      title={i18n.t('shop.tabs.shop.addProduct')}
                      onPress={() => {
                        createProduct(this.props.navigation.state.params.id)
                      }}
                    >
                      <Icon name='ios-pricetag' size={30} color='white' />
                    </ActionButton.Item>
                  </ActionButton>
                ) : null}
              </View>
            ) : null}
          </TabView.Tab>
          <TabView.Tab label={i18n.t('shop.tabs.reviews.tabTitle')}>
            <View style={[styles.shopTab]}>
              <FlatList
                initialNumToRender={REVIEW_LIMIT_INCREMENT}
                data={reviewsHandler.reviewsByShopId}
                renderItem={({item, index}) => this.renderReviews(item, index)}
                keyExtractor={this._keyExtractor}
                contentContainerStyle={styles.storiesContentContainer}
                onEndReachedThreshold={0.5}
                onEndReached={this.fetchMoreReviews}
                refreshing={
                  this.state.data.reviewsByShopId.fetching || this.props.reviewsHandler.networkStatus === 4
                }
                onRefresh={() => this.refresh('reviewsHandler', 'reviewsByShopId')}
                ListEmptyComponent={
                  <T
                    style={{
                      paddingTop: 100,
                      color: colors.text.light,
                      fontSize: 22,
                      alignSelf: 'center',
                      fontWeight: 'bold',
                    }}
                    t='shop.tabs.reviews.emptyReviews'
                  />
                }
                ListFooterComponent={
                  this.state.data.reviewsByShopId.fetching && <ActivityIndicator size='large' style={{ marginVertical: 20 }} />
                }
              ></FlatList>
            </View>
          </TabView.Tab>
          <TabView.Tab label={i18n.t('shop.tabs.posts.tabTitle')}>
            <FlatList
              initialNumToRender={POST_LIMIT_INCREMENT}
              data={this.getPosts()}
              contentContainerStyle={styles.storiesContentContainer}
              keyExtractor={this._keyExtractor}
              renderItem={this.renderPost}
              onEndReachedThreshold={0.4}
              onEndReached={this.fetchMorePosts}
              refreshing={
                this.state.data.postsByMentionType.fetching || this.props.postsHandler.networkStatus === 4
              }
              onRefresh={() => this.refresh('postsHandler', 'postsByMentionType')}
              ListEmptyComponent={
                <Text
                  style={{
                    paddingTop: 10,
                    color: colors.text.light,
                    fontSize: 22,
                    alignSelf: 'center',
                    fontWeight: 'bold',
                  }}
                  key={'noPosts'}
                >
                  {i18n.t('shop.tabs.reviews.emptyPosts')}
                </Text>
              }
              ListHeaderComponent={
                <View style={styles.shopTab}>
                  <Text
                    style={[
                      styles.shopHeader,
                      styles.center,
                      { paddingTop: 20, marginBottom: 8 },
                    ]}
                  >
                    {i18n.t('shop.tabs.posts.employeesTitle')}
                  </Text>
                  {this.renderShopEmployees()}
                  <Divider right />
                  <Text
                    style={[
                      styles.shopHeader,
                      styles.center,
                      { paddingBottom: 14 },
                    ]}
                  >
                    {i18n.t('shop.tabs.posts.storyTitle')}
                  </Text>
                  <View style={styles.shopDetailsContainer}>
                    <Text style={styles.shopDetailsText}>
                      <Text style={styles.shopDetailsTextBold}>
                        {i18n.t('shop.tabs.posts.farmingMethod')}
                      </Text>
                      {this.getFarmingMethodText(shop)}
                    </Text>
                    <Text style={styles.shopDetailsText}>
                      <Text style={styles.shopDetailsTextBold}>
                        {i18n.t('shop.tabs.posts.address')}
                      </Text>
                      {`${_get(shop, 'address.address1', '')}, ${_get(
                        shop,
                        'address.city',
                        '',
                      )}, ${_get(shop, 'address.country', '')}`}
                    </Text>
                    <Text style={styles.shopDetailsText}>
                      <Text style={styles.shopDetailsTextBold}>
                        {i18n.t('common.phoneLine')}
                      </Text>
                      {`: ${_get(shop, 'producerDetails.companyPhoneLand', '')}`}
                    </Text>
                    <Text style={styles.shopDetailsText}>
                      <Text style={styles.shopDetailsTextBold}>
                        {i18n.t('common.cellular')}
                      </Text>
                      {`: ${_get(shop, 'producerDetails.companyPhoneMobile', '')}`}
                    </Text>
                  </View>
                  {/* <FreeText
                  numberOfLines={storiesLength}
                  style={styles.description}
                  content={_get(shop, 'producerDetails.description.value', '')}
                /> */}
                  <TagText
                    style={styles.description}
                    content={_get(shop, 'producerDetails.description', null)}
                  />
                  <TouchableOpacity onPress={this.toggleStory}>
                    {
                      <Text style={styles.shopTabViewMore}>
                        {i18n.t('shop.tabs.posts.viewMore')}
                      </Text>
                    }
                  </TouchableOpacity>
                  <Divider right />
                  <Text
                    style={[
                      styles.shopHeader,
                      styles.center,
                      { marginBottom: 8 },
                    ]}
                  >
                    {i18n.t('shop.tabs.posts.postsTitle')}
                  </Text>
                </View>
              }
              ListFooterComponent={
                this.state.data.postsByMentionType.fetching && <ActivityIndicator size='large' style={{ marginVertical: 20 }} />
              }
            >
            </FlatList>
          </TabView.Tab>
          <TabView.Tab label={i18n.t('shop.tabs.policies.tabTitle')}>
            <Scroller contentContainerStyle={styles.tabScroller}>
              <View style={styles.shopTabPolicies}>
                <Text style={[styles.shopHeaderHuge, styles.margin]}>
                  {i18n.t('shop.tabs.policies.policiesTitle')}
                </Text>
                <Divider />
                <Text style={[styles.shopHeader]}>
                  {i18n.t('shop.tabs.policies.paymentTitle')}
                </Text>
                <PaymentTypes
                  cash={_get(shop, 'producerDetails.paymentTypes.cash', false)}
                  transfer={_get(
                    shop,
                    'producerDetails.paymentTypes.transfer',
                    false,
                  )}
                />
                {/* {_get(shop, 'producerDetails.paymentTypes.transfer', false) && [
                  _get(shop, 'producerDetails.ibanNumber') && (
                    <Text style={[styles.policiesText]} key='ibanNumber'>
                      <Text style={[styles.policiesLabelText]}>
                        {i18n.t('shop.tabs.policies.ibanNumber')}:{' '}
                      </Text>
                      {_get(shop, 'producerDetails.ibanNumber')}
                    </Text>
                  ),
                  _get(shop, 'producerDetails.bankAccountHolder') && (
                    <Text style={[styles.policiesText]} key='bankAccountHolder'>
                      <Text style={[styles.policiesLabelText]}>
                        {i18n.t('shop.tabs.policies.bankAccountHolder')}:{' '}
                      </Text>
                      {_get(shop, 'producerDetails.bankAccountHolder')}
                    </Text>
                  ),
                ]} */}
                <Text>
                  {_get(shop, 'producerDetails.policies.payment', '')}
                </Text>

                <Divider />
                <Text style={[styles.shopHeader]}>
                  {i18n.t('shop.tabs.policies.shippingTitle')}
                </Text>
                <Text style={[styles.policiesText]}>
                  <Text style={[styles.policiesLabelText]}>
                    {i18n.t('shop.tabs.policies.standardShippingFee')}:{' '}
                  </Text>
                  {getPrice(_get(shop, 'producerDetails.standardShippingFee'))}
                </Text>
                {_get(shop, 'producerDetails.freeShippingThreshold') ? (
                  <Text style={[styles.policiesText]}>
                    <Text style={[styles.policiesLabelText]}>
                      {i18n.t('shop.tabs.policies.freeShippingThreshold')}:{' '}
                    </Text>
                    {getPrice(
                      _get(shop, 'producerDetails.freeShippingThreshold'),
                    )}
                  </Text>
                ) : null}
                {minimumOrderAmount > 0 && (
                  <Text style={[styles.policiesText]}>
                    <Text style={[styles.policiesLabelText]}>
                      {i18n.t('shop.tabs.policies.minimumOrderAmount')}:{' '}
                    </Text>
                    {minimumOrderAmountPrice}
                  </Text>
                )}
                <Text style={styles.policiesText}>
                  {'\n'}
                  <TagText
                    content={_get(
                      shop,
                      'producerDetails.policies.shipping',
                      '',
                    )}
                  />
                </Text>

                <Divider />
                <Text style={[styles.shopHeader]}>
                  {i18n.t('shop.tabs.policies.returnExchangeTitle')}
                </Text>
                <Text style={styles.policiesText}>
                  <TagText
                    content={_get(shop, 'producerDetails.policies.returns', '')}
                  />
                </Text>
              </View>
            </Scroller>
          </TabView.Tab>
        </TabView>
      </Container>
    )
  }
  loadMore() {}
  animate() {
    LayoutAnimation &&
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }
}

Shop.navigationOptions = (props) => {
  const { navigation, myShopId } = props
  const params = _.get(navigation, 'state.params') || {}
  const { id = -1, pageTitle = '' } = params
  const shop = getShop()
  const myShop =
    _.get(myShopId, 'currentUser.ownedProfile.profile.id', 0) ===
    _.get(navigation, 'state.params.id', -1)

  const shareShop = () => {
    const id = _.get(props, 'navigation.state.params.id')
    const contentLink = getContentLink('organizationProfile', id)
    const message = i18n.t('sharing.shop', {
      shopName: shop.displayName,
      link: contentLink
    })
    Share.share({ message })
  }

  const moreOptions = [{
    label: i18n.t('common.share'),
    action: shareShop,
  }]

  if (!myShop) {
    const goToReportContent = (content) =>
      NavigationActions.navigate({
        routeName: ROUTE_REPORT_CONTENT_ORGANIZATION_PROFILE,
        params: { objectId: content.id },
      })
    moreOptions.push({
      label: i18n.t('common.report'),
      action: goToReportContent,
    })
  }

  return {
    headerTitle: _.truncate(pageTitle, { length: 30 }),
    headerLeft: () => {
      return (
        <MobileBackButton
          label='Back'
          onPress={() => NavigationActions.back()}
        />
      )
    },
    headerRight: () => {
      return (
        <MoreOptions
          content={{ id }}
          options={moreOptions}
        />
      )
    },
  }
}

export default Shop

const styles = stylus({
  container: {
    flexBasis: 'auto',
  },
  tabTitleContainer: {
    backgroundColor: '#f8f8f8',
  },
  shopTab: {
    flex: 1,
  },
  shopTabPolicies: {
    flex: 1,
    paddingTop: 24,
    alignItems: 'center',
    paddingBottom: sizes.tabBarHeight + 5,
  },
  policiesLabelText: {
    fontWeight: 'bold',
  },
  policiesText: {
    alignSelf: 'flex-start',
    paddingHorizontal: 16,
  },
  description: {
    fontSize: 16,
    paddingTop: 20,
    paddingHorizontal: 16,
    color: colors.text.main,
  },
  shopTabViewMore: {
    color: colors.primary,
    fontSize: 15,
    textDecorationLine: 'underline',
    marginLeft: 16,
    alignSelf: 'center',
    // margin: 20,
  },
  padding: {
    paddingHorizontal: 16,
  },
  margin: {
    marginVertical: 20,
  },
  marginTop: {
    marginTop: 20,
  },
  shopHeader: {
    fontSize: 20,
    marginVertical: 6,
    fontWeight: '600',
    paddingHorizontal: 16,
    color: colors.text.main,
  },
  shopHeaderHuge: {
    fontSize: 26,
    fontWeight: 'bold',
  },
  center: {
    alignSelf: 'center',
  },
  shopDetailsContainer: {
    alignSelf: 'flex-start',
    paddingHorizontal: 16,
  },
  shopDetailsText: {
    lineHeight: 24,
    color: colors.text.main,
  },
  shopDetailsTextBold: {
    fontWeight: 'bold',
  },
  productGrid: {
    flexDirection: 'row',
    backgroundColor: 'teal',
  },
  tabTitles: {
    paddingVertical: 10,
  },
  productsContainer: {
    flex: 1
  },
  tabScroller: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  storiesContentContainer: {
    paddingBottom: sizes.tabBarHeight + 5,
    iphonex: {
      paddingBottom: sizes.iphonexTabBarHeight + 5,
    },
  },
  bottomPadding: {
    height: sizes.tabBarHeight,
    iphonex: {
      height: sizes.iphonexTabBarHeight,
    },
  },
  elevation: {
    backgroundColor: colors.card.background,
    elevation: 10,
    shadowColor: colors.card.shadow,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
  }
})
