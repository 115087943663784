import React, { Component } from 'react'
import debounce from 'debounce'
import { Dimensions, Platform } from 'react-native'


import Constants from 'expo-constants'

import sizes from './sizes'

const {
  PHONE_WIDTH,
  TABLET_WIDTH,
  DESKTOP_WIDTH,
  CONTAINER_WIDTH,
  LEFT_BAR_WIDTH,
  CONTENT_WIDTH,
  MINIMUM_PADDING,
  TOP_BAR_HEIGHT,
} = sizes

export default function screenInfo() {
  let { width, height } = Dimensions.get('window')
  const platform = Platform.OS
  if (platform === 'web') {
    height = window.innerHeight
    width = window.innerWidth
  }
  if (platform === 'android') {
    height -= Constants.statusBarHeight
  }
  let device = 'desktop'
  let layout = 'landscape'
  if (width <= PHONE_WIDTH) {
    device = 'phone'
    layout = 'portrait'
  } else if (width <= TABLET_WIDTH) {
    device = 'tablet'
  }

  let contentWidth = width
  let outerPadding = 0
  // remainder = width - CONTAINER_WIDTH
  // if (remainder > 0) {
  //  innerWidth = CONTAINER_WIDTH
  //  outerPadding = Math.round(remainder / 2)
  // }
  let leftBarWidth = 0
  let contentTopPadding = 0
  let topBarHeight = 0
  let gutterWidth = 0
  let rightBarWidth = 0
  let paddingLeft = 0
  let paddingRight = 0

  if (layout === 'landscape') {
    topBarHeight = TOP_BAR_HEIGHT
    if (platform === 'ios') {
      topBarHeight += 12
    }

    leftBarWidth = Math.round(width * LEFT_BAR_WIDTH)
    contentWidth = Math.min(CONTENT_WIDTH, width - leftBarWidth)
    // contentTopPadding = 31
    // contentWidth = innerWidth - leftBarWidth
  }

  if (width > TABLET_WIDTH) {
    const contentPadding = (width - contentWidth - leftBarWidth) / 2
    paddingLeft = contentPadding
    paddingRight = contentPadding
    if (paddingLeft > MINIMUM_PADDING) {
      const difference = paddingLeft - MINIMUM_PADDING
      paddingLeft -= difference
      paddingRight += difference
    }
    gutterWidth = paddingLeft - outerPadding - leftBarWidth
    rightBarWidth = paddingRight - outerPadding
  }

  // console.log({
  //  width,
  //  height,
  //  device,
  //  layout,
  //  platform,
  //  paddingLeft,
  //  paddingRight,
  //  leftBarWidth,
  //  rightBarWidth,
  //  outerPadding,
  //  topBarHeight,
  //  gutterWidth,
  //  contentWidth,
  // })

  return {
    width,
    height,
    device,
    layout,
    platform,
    paddingLeft,
    paddingRight,
    leftBarWidth,
    rightBarWidth,
    outerPadding,
    topBarHeight,
    gutterWidth,
    contentWidth,
    contentTopPadding,
  }
}
