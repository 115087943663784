import React from 'react'
import { View, Platform, Text } from 'react-native'
import TextInput from '../ReturnKeyCompatibilityTextInput/ReturnKeyCompatibilityTextInput'


import shared, { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'

import ErrorMessage from './ErrorMessage'

const Input = ({
  label,
  containerStyle,
  inputStyle,
  onChange,
  reference,
  placeholder,
  value,
  error,
  first,
  last,
  ...rest
}) => (
  <View
    style={[
      styles.container,
      containerStyle,
      first && styles.first,
      last && styles.last,
    ]}
  >
    <Text style={styles.label}>{label}</Text>
    <View style={[styles.right, last && styles.rightLast]}>
      <TextInput
        {...rest}
        style={[styles.input, inputStyle]}
        value={value}
        onChangeText={onChange}
        underlineColorAndroid='transparent'
        placeholder={placeholder || label}
        placeholderTextColor={colors.input.placeholder}
        ref={reference}
      />
      { error ? <ErrorMessage style={styles.error} message={error} /> : null }
    </View>
  </View>
)

export default Input

const styles = stylus({
  container: {
    alignSelf: 'stretch',
    alignItems: 'center',
    flexDirection: 'row',
    // paddingRight: 12,
    // paddingVertical: 12,
    // portrait: {
    //   marginLeft: 20,
    // },
  },
  first: {
    borderTopWidth: sizes.px,
    borderTopColor: colors.thinLine,
  },
  last: {
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
  },
  label: {
    width: 110,
    color: colors.text.mainLighter,
    paddingLeft: 14,
    fontSize: 16,
  },
  right: {
    borderBottomWidth: sizes.px,
    borderBottomColor: colors.thinLine,
    flex: 1,
  },
  error: {
    marginTop: 0,
    borderTopWidth: sizes.px,
    borderTopColor: colors.thinLine,
    flex: 1,
    paddingVertical: 5,
  },
  rightLast: {
    borderBottomWidth: 0,
  },
  input: {
    fontSize: 16,
    height: 44,
    color: colors.text.main,
  },
})
