import produce from 'immer'
import _ from 'lodash'
import {
  SEARCH_OBJECT_PRODUCTS,
  SEARCH_OBJECT_PRODUCERS,
} from '../config/constants'
import { SEARCH_MANAGER_PRODUCTS } from '../screens/ObjectSearch/config/ProductSearchManager'
import { SEARCH_MANAGER_PRODUCERS } from '../screens/ObjectSearch/config/ProducerSearchManager'

export const SEARCH_FORM_VIEW = 'FormView'
export const SEARCH_MAP_VIEW = 'MapView'

export const SEARCH_SET_STATE = 'Search/SET_STATE'
export const SEARCH_SET_RESET_FUNCTION = 'Search/SET_RESET_FUNCTION'
export const SEARCH_TRANSFER_STATE = 'Search/TRANSFER_STATE'
export const SEARCH_CLEAR_REGION = 'Search/CLEAR_REGION'
export const SEARCH_REFRESH_TYPE = 'Search/REFRESH_TYPE'
export const SEARCH_REFRESH_TYPE_COMPLETE = 'Search/REFRESH_TYPE_COMPLETE'

export default function search(state = { searchUpdated: false }, action) {
  const { type, forView, searchObjectType, data } = action
  if (type === SEARCH_SET_STATE) {
    const nextState = produce(state, (draft) => {
      for (const objectType in data) {
        const managers = data[objectType]
        for (const manager in managers) {
          const searchState = managers[manager]
          _.set(draft, `${objectType}.${manager}`, searchState)
        }
        _.set(draft, `${objectType}.forView`, forView)
        _.set(draft, `${objectType}.stateTransferred`, false)
      }
      _.set(draft, 'searchUpdated', true)
    })
    return nextState
  } else if (type === SEARCH_TRANSFER_STATE) {
    const nextState = produce(state, (draft) => {
      _.set(draft, `${searchObjectType}.stateTransferred`, true)
      _.set(draft, 'searchUpdated', false)
    })
    return nextState
  } else if (type === SEARCH_CLEAR_REGION) {
    const nextState = produce(state, (draft) => {
      if (searchObjectType === SEARCH_OBJECT_PRODUCTS) {
        _.unset(
          draft,
          `${SEARCH_OBJECT_PRODUCTS}.${SEARCH_MANAGER_PRODUCTS}.filters.producersIds`,
        )
        _.unset(
          draft,
          `${SEARCH_OBJECT_PRODUCTS}.${SEARCH_MANAGER_PRODUCERS}.filters.region`,
        )
        _.unset(
          draft,
          `${SEARCH_OBJECT_PRODUCTS}.${SEARCH_MANAGER_PRODUCERS}.filters.radius`,
        )
      } else if (searchObjectType === SEARCH_OBJECT_PRODUCERS) {
        _.unset(
          draft,
          `${SEARCH_OBJECT_PRODUCERS}.${SEARCH_MANAGER_PRODUCERS}.filters.region`,
        )
        _.unset(
          draft,
          `${SEARCH_OBJECT_PRODUCERS}.${SEARCH_MANAGER_PRODUCERS}.filters.radius`,
        )
      }
    })
    return nextState
  } else if (type === SEARCH_REFRESH_TYPE) {
    console.log('REFRESHING TYPE', searchObjectType)
    const nextState = produce(state, (draft) => {
      _.set(draft, `${searchObjectType}.refresh`, true)
    })
    return nextState
  } else if (type === SEARCH_REFRESH_TYPE_COMPLETE) {
    console.log('REFRESHING TYPE COMPLETE', searchObjectType)
    const nextState = produce(state, (draft) => {
      _.set(draft, `${searchObjectType}.refresh`, false)
    })
    return nextState
  } else if (type === SEARCH_SET_RESET_FUNCTION) {
    const nextState = produce(state, (draft) => {
      _.set(draft, 'resetSearch', action.payload.resetFunc)
    })
    return nextState
  }
  return state
}
