import React from 'react'
import { Image, View, ImageBackground } from 'react-native'
import PropTypes from 'prop-types'
import { stylus } from '../../config/styles'
import defaultPlaceholder from '../../assets/images/placeholder-image.png'
import profilePlaceholder from '../../assets/images/placeholder-profile.png'
import nameToUrl from '../../config/nameToUrl'
import { connect, compose } from '../../config/connected'
import { isArray } from '../../config/helpers'

import _ from 'lodash'

const mapStateToProps = ({ uploads }, ownProps )=> {
  return {
    upload: uploads && uploads[ownProps.name]
  }
}

const NamedImage = compose(
  connect(mapStateToProps)
)(
  function NamedImage({
    style,
    width,
    height,
    maxHeight,
    quality,
    name,
    uri,
    asset,
    placeholder,
    loading,
    fit,
    crop,
    resizeMode,
    asBackground,
    children,
    isAsset,
    opacity,
    upload,
    ...rest
  }) {

    let finalStyle = [{ opacity }]
    if (style) {
      let extraStyles = [style]
      if (isArray(style)) {
        extraStyles = style
      }
      finalStyle = [...extraStyles, ...finalStyle]
    }

    const derivedUri = uri ? uri : _.get(upload, 'uri')

    const finalUri = derivedUri
      ? derivedUri
      : name
        ? nameToUrl({
          name,
          width,
          height,
          maxHeight,
          quality,
          fit,
          crop,
          isAsset
        })
        : null

    const source = asset ? asset : (finalUri ? { uri: finalUri } : null)

    const ImageComponent = asBackground ? ImageBackground : Image
    if (source) {
      return (
        <ImageComponent
          key={name}
          style={finalStyle}
          resizeMode={resizeMode}
          source={source}
          {...rest}
        >
          {asBackground ? children : null}
        </ImageComponent>
      )
    }
    if (placeholder) {
      return (
        <ImageComponent
          key='placeholder'
          style={finalStyle}
          resizeMode={resizeMode}
          source={
            placeholder === 'profile' ? profilePlaceholder : defaultPlaceholder
          }
          {...rest}
        >
          {asBackground ? children : null}
        </ImageComponent>
      )
    }
    return <View style={[styles.loading, finalStyle]} />
  }
)

NamedImage.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  resizeMode: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  maxHeight: PropTypes.number,
  quality: PropTypes.number,
  name: PropTypes.string,
  uri: PropTypes.string,
  asset: PropTypes.any,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  fit: PropTypes.string,
  crop: PropTypes.string,
  asBackground: PropTypes.bool,
  children: PropTypes.any,
  upload: PropTypes.object,
  isAsset: PropTypes.bool,
  opacity: PropTypes.number,
}
NamedImage.defaultProps = {
  isAsset: false,
  opacity: 1,
}

export default NamedImage

const styles = stylus({
  container: {
    flexDirection: 'row',
  },
  loading: {
    backgroundColor: '#EEE',
  },
})
