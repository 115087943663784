import { gql } from '../config/connected'

const alias = `
id
entityId
entityTable
alias
createdAt
`

export default {
  mutations: {
    createAlias: gql`
      mutation ($entityTable: AliasEntityTableEnum!, $entityId: String!, $alias: String!) {
        createAlias(entityTable: $entityTable, entityId: $entityId, alias: $alias) {
          ${alias}
        }
      }
    `,
    deleteAlias: gql`
      mutation ($id: String!) {
        deleteAlias(id: $id) {
          ${alias}
        }
      } 
    `
  },
  queries: {
    aliases: gql`
      query ($entityTable: AliasEntityTableEnum!, $entityId: String) {
        aliases(entityTable: $entityTable, entityId: $entityId) {
          ${alias}
        }
      }
    `
  }
}
