class LayoutAnimation {
  static Types = {}
  static Properties = {}
  static Presets = {}

  static easeInEaseOut() {
    // DO NOTHING
  }

  static linear() {
    // DO NOTHING
  }

  static spring() {
    // DO NOTHING
  }

  static configureNext(config, onAnimationDidEnd) {
    // DO NOTHING
  }
  
  static create(duration, type, creationProp) {
    // DO NOTHING
  }

  static checkConfig(config, location, name) {
    // DO NOTHING
  }
}

export default LayoutAnimation