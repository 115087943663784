import React from 'react'
import { Text, View, Button, Image, Platform } from 'react-native'

import Action from '../../components/simple/Action'
import Icons from '@expo/vector-icons/Ionicons'
import colors from '../../config/colors'
import sizes from '../../config/sizes'
import shared, { stylus } from '../../config/styles'

import {
  connect,
  graphql,
  withApollo,
  compose,
  gql,
  _get,
} from '../../config/connected'

import ProfileActions from './ProfileActions'
import CoverPhoto from './CoverPhoto'
import ProfileImage from './ProfileImage'
import Statistics from './Statistics'
import PostFB from '../../components/PostFB'
import PostRecipe from '../../components/PostFB/PostRecipe'
import UserTitle from '../../components/UserTitle/UserTitle'

import _ from 'lodash'
import { client } from '../../containers/withApollo'
import i18n from 'i18n-js'
import MoreOptions from '../../components/simple/MoreOptions'

const ASPECT_RATIOS = {
  landscape: 0.4,
  portrait: 0.48,
}

const INITIAL_PAGE_SIZE = 10
const SUBSEQUENT_PAGE_SIZE = 15

class TopInstaStyle extends React.Component {
  render() {
    const {
      user,
      loading,
      name,
      username,
      bio,
      isMe,
      currentUser,
      screenInfo,
      profileId,
      profileQuery,
      goToFollowing,
      goToFollowers,
      goToReportContent,
      localImage,
      pendingProfileEdit,
    } = this.props

    const { layout, contentWidth, platform } = screenInfo
    const moreOptions = []
    if (!isMe) {
      moreOptions.push({ label: i18n.t('common.report'), action: goToReportContent })
    }

    const coverDimensions = {
      width: contentWidth,
      height: Math.ceil(contentWidth * ASPECT_RATIOS[layout]),
    }
    return (
      <View style={styles.container}>
        <View style={styles.topRow}>
          <ProfileImage
            profileImage={_.get(user, 'profileImage')}
            containerWidth={contentWidth}
            coverDimensions={coverDimensions}
            allowEdit={isMe}
            localImage={localImage}
            imageSize={92}
          />
          <View style={[styles.topContent]}>
            <Statistics
              followers={_.get(user, 'followersCount', ' – ')}
              following={_.get(user, 'followingCount', ' – ')}
              posts={_.get(user, 'postCount', ' – ')}
              goToFollowers={goToFollowers}
              goToFollowing={goToFollowing}
            >
              <MoreOptions options={moreOptions} content={user} />
            </Statistics>
            <ProfileActions
              isMe={isMe}
              currentUser={currentUser}
              profileQuery={profileQuery}
              profileId={profileId}
              pendingProfileEdit={pendingProfileEdit}
            />
          </View>
        </View>
        <View style={styles.userInfo}>
          <Text style={styles.name}>{name}</Text>
          <Text style={styles.username}>{username}</Text>
          {loading ? null : (
            <UserTitle
              title={_get(user, 'title', '')}
              organization={_get(user, 'organization', '')}
              organizationId={_get(user, 'organizationId', '')}
              size={14}
            />
          )}
          {this.bio({ bio, loading })}
        </View>
      </View>
    )
  }
  location({ location, loading }) {
    if (loading || location) {
      return <Text style={styles.subtitle}>{location || ' – '}</Text>
    }
  }

  bio({ bio, loading }) {
    if (!bio) {
      return null
    }
    return (
      <Text style={styles.bio} numberOfLines={10}>
        {loading ? '...' : bio}
      </Text>
    )
  }
}

export default TopInstaStyle
const styles = stylus({
  container: {
    flex: 1,
  },
  topRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  topContent: {
    flex: 1,
  },
  name: {
    fontSize: 18,
    color: colors.text.main,
    fontWeight: '600',
  },
  username: {
    fontSize: 16,
    color: colors.text.secondary,
    fontWeight: '400',
  },
  userInfo: {
    paddingHorizontal: 14,
  },
  bio: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    // paddingHorizontal: 15,
    marginTop: 4,
    fontSize: 14,
    color: colors.text.content,
    marginBottom: 5,
  },
})
