import { gql } from '../../config/connected'
import Ratings from '../../components/simple/Ratings'

const user = `
  id
  phone
  displayName
  username
  email
  profileImage
`

const address = `
  address1
  address2
  city
  region
  zip
  country
`

const details = `
  description
  legalName
  established
  paymentTypes{
    transfer
    cash
    stripe
  }
  website
`

const fileUpload = `
  name
  mime
  width
  height
`
const product = `id
  userId
  name
  uploads {
    ${fileUpload}
  }
  name
  categories {
    allergens
  }
  rating
  ratingCount
  stock
  quantityUnits
  price
  priceDiscount
  isDiscontinued
  isInStock
  description
  ingredients
  unitQuantity
  unitType
  profile {
    id
    displayName
    profileImage
    address {
      city
      region
    }
    producerDetails {
      id
      paymentTypes {
        cash  
        transfer
        stripe
      }
      category
      rating
      stripeConnected
    }
  }
`
const cartItem = `
  id
  userId
  quantity
  createdAt
  product {
    ${product}
  }
`

export default {
  queries: {
    trendingProducts: gql`
      query($limit: Int!) {
        trendingProducts(limit: $limit) {
          id
          name
          price
          priceDiscount
          rating
          ratingCount
          isInStock
          isDiscontinued
          uploads {
            ${fileUpload}
          }
          profile {
            id
            displayName
          }
          score
          unitQuantity
          unitType
        }
      }
    `,
    lastVisitedProducts: gql`
      query($limit: Int) {
        lastVisitedProducts(limit: $limit) {
          id
          name
          price
          priceDiscount
          rating
          ratingCount
          uploads {
            ${fileUpload}
          }
          profile {
            id
            displayName
          }
          isInStock
          isDiscontinued
          unitQuantity
          unitType
        }
      }
    `,
    suggestedProducts: gql`
      query($limit: Int) {
        suggestedProducts(limit: $limit) {
          id
          name
          price
          priceDiscount
          rating
          ratingCount
          uploads {
            ${fileUpload}
          }
          profile {
            id
            displayName
          }
          score
          isInStock
          isDiscontinued
          unitQuantity
          unitType
        }
      }
    `,
    productHandler: gql`
      query($id: String!) {
        productById(id: $id) {
          ${product}
      }
      }
    `,
    otherProductsHandler: gql`
      query($userId: String!) {
        productsByUserId(userId: $userId) {
          id
          uploads {
           ${fileUpload}
          }
          rating
          ratingCount
          name
          stock
          categories{
            allergens
          }
          price
          priceDiscount
          unitQuantity
          unitType
        }
      }
    `,
    cartItemsByUserId: gql`
      query($userId: String!) {
        cartItemsByUserId(userId: $userId) {
          id
          productId
          quantity
        }
      }
    `,
  },
  mutations: {
    createCartItem: gql`
      mutation($userId: String!, $productId: String!) {
        createCartItem(userId: $userId, productId: $productId) {
          ${cartItem}
        }
      }
    `,
    deleteCartItem: gql`
      mutation($id: String!) {
        deleteCartItem(id: $id) {
          ${cartItem}
        }
      }
    `,
    updateCartItemQuantity: gql`
      mutation($id: String!, $quantity: Float!) {
        updateCartItemQuantity(id: $id, quantity: $quantity) {
          ${cartItem}
        }
      }
    `,
  },
}
