import React from 'react'
import {
  KeyboardAvoidingView,
  Picker,
  Platform,
  ScrollView,
  Text,
  View,
  ActivityIndicator,
  BackHandler,
} from 'react-native'
import NavigationActions from '../../utility/navigationActions'
import { Video, Audio } from 'expo-av'
import alert from '../../utility/alert'
import { _get, connect, graphql } from '../../config/connected'
import environment from '../../config/environment'

import { stylus } from '../../config/styles'
import sizes from '../../config/sizes'
import colors from '../../config/colors'
import LocationView from '../../components/LocationView'

import ElegantInput from '../../components/simple/ElegantInput'
import ElegantDatePicker from '../../components/simple/ElegantDatePicker'
import Button from '../../components/simple/Button'
import { categories } from '../../localization/en-US/categories.json'
import countries from '../../config/countries'
import FBPicker from '../../components/simple/Picker'
import CountryPicker from '../../components/CountryPicker'
import StripeConnectButton from '../../components/Stripe/StripeConnectButton'
import Icons from '@expo/vector-icons/Ionicons'
import Divider from '../../components/simple/Divider'
import UploadButton from '../../components/simple/UploadButton'
import UploadProgress from '../../components/simple/UploadProgress'
import NamedImage from '../../components/simple/NamedImage'
import MobileBackButton from '../../components/simple/MobileBackButton'
import DeleteButton from '../../components/simple/DeleteButton'
import Switch from '../../components/simple/Switch'
import i18n from 'i18n-js'
import PropTypes from 'prop-types'

import _ from 'lodash'
import produce from 'immer'
import rfdc from 'rfdc'
const clone = rfdc({ proto: false })

import user from '../../schema/user'
import schema from '../../schema/shop'
import startSchema from '../Start/schema'
import {
  fromLocalePriceToRealNumber,
  fromRealNumberToLocalePrice,
  getKeyboardPaddingStyle,
  jsonParseKeyValues,
  getPhonePrefix,
  getPhoneNoPrefix,
  removeUploadsFromCache,
} from '../../config/helpers'
import services from '../../utility/services'
import branch from '../../config/branch'
import connectActionSheet from '../../containers/ActionSheet/withActionSheet'
import WithKeyboardInfo from '../../containers/withKeyboardInfo'
import withCurrentUser from '../../containers/withCurrentUser'
import { client } from '../../containers/withApollo'
import { getLayout } from '../../routing/selectLayout'

import {
  bindableFieldErrorFunc,
  bindableFieldErrorsFunc,
  isNumeric,
  isPhone,
  isPhonePrefix,
  isValidIbanNumber,
  isValidTitle,
  isWebsite,
  isYear,
} from '../../config/validate'
import {
  SHOP_FIELD_BANK_ACCOUNT_HOLDER,
  SHOP_FIELD_COMPANY_PHONE_LAND_PREFIX,
  SHOP_FIELD_COMPANY_PHONE_LAND,
  SHOP_FIELD_COMPANY_PHONE_MOBILE_PREFIX,
  SHOP_FIELD_COMPANY_PHONE_MOBILE,
  SHOP_FIELD_DISPLAY_NAME,
  SHOP_FIELD_EMAIL,
  SHOP_FIELD_EU_VAT,
  SHOP_FIELD_FOUNDED,
  SHOP_FIELD_FREE_SHIPPING_THRESHOLD,
  SHOP_FIELD_IBAN_NUMBER,
  SHOP_FIELD_LEGAL_NAME,
  SHOP_FIELD_MINIMUM_ORDER_AMOUNT,
  SHOP_FIELD_STANDARD_SHIPPING_FEE,
  SHOP_FIELD_VAT,
  SHOP_FIELD_WEBSITE,
  OBJECT_STATUSES,
} from '../../config/constants'
import emailValidator from 'email-validator'
import { ANALYTICS_SHOP_EDITED, ANALYTICS_SHOP_STATUS_CHANGED } from '../../reducers/analytics'
import { CONTENT_IS_PENDING } from '../../reducers/publishContent'
import { getFullOffset } from '../../utility/coverHeight'
import moment from 'moment'

const fieldErrorsConfig = {
  [SHOP_FIELD_DISPLAY_NAME]: {
    validate: isValidTitle,
    errorName: SHOP_FIELD_DISPLAY_NAME,
    optional: false,
  },
  [SHOP_FIELD_LEGAL_NAME]: {
    validate: () => true,
    errorName: SHOP_FIELD_LEGAL_NAME,
    optional: false,
  },
  [SHOP_FIELD_FOUNDED]: {
    validate: isYear,
    errorName: SHOP_FIELD_FOUNDED,
    optional: true,
  },
  // [SHOP_FIELD_ADDRESS]: {
  //   validate: isValidAddress,
  //   errorName: SHOP_FIELD_ADDRESS,
  // },
  // [SHOP_FIELD_CITY]: {
  //   validate: isValidAddress,
  //   errorName: SHOP_FIELD_CITY,
  // },
  // [SHOP_FIELD_REGION]: {
  //   validate: isValidAddress,
  //   errorName: SHOP_FIELD_REGION,
  // },
  // [SHOP_FIELD_COUNTRY]: {
  //   validate: isValidName,
  //   errorName: SHOP_FIELD_COUNTRY,
  // },
  [SHOP_FIELD_WEBSITE]: {
    validate: isWebsite,
    errorName: SHOP_FIELD_WEBSITE,
    optional: true,
    onlyOnSubmit: true,
  },
  [SHOP_FIELD_EMAIL]: {
    validate: (value) => {
      return emailValidator.validate(value)
    },
    errorName: SHOP_FIELD_EMAIL,
    optional: false,
    onlyOnSubmit: true,
  },
  [SHOP_FIELD_BANK_ACCOUNT_HOLDER]: {
    requirementsMet: (fields) => fields.transfer,
    validate: isValidTitle,
    errorName: SHOP_FIELD_BANK_ACCOUNT_HOLDER,
  },
  [SHOP_FIELD_IBAN_NUMBER]: {
    requirementsMet: (fields) => fields.transfer,
    validate: isValidIbanNumber,
    errorName: SHOP_FIELD_IBAN_NUMBER,
    onlyOnSubmit: true,
  },
  [SHOP_FIELD_MINIMUM_ORDER_AMOUNT]: {
    validate: isNumeric,
    errorName: SHOP_FIELD_FREE_SHIPPING_THRESHOLD,
    optional: true,
  },
  [SHOP_FIELD_COMPANY_PHONE_LAND_PREFIX]: {
    validate: isPhonePrefix,
    errorName: "invalidPrefix",
    optional: false,
    onlyOnSubmit: true,
  },
  [SHOP_FIELD_COMPANY_PHONE_LAND]: {
    validate: isPhone,
    errorName: "invalidPhone",
    optional: false,
    onlyOnSubmit: true,
  },
  [SHOP_FIELD_COMPANY_PHONE_MOBILE_PREFIX]: {
    validate: isPhonePrefix,
    errorName: "invalidPrefix",
    optional: true,
    onlyOnSubmit: true,
  },
  [SHOP_FIELD_COMPANY_PHONE_MOBILE]: {
    validate: isPhone,
    errorName: "invalidPhone",
    optional: true,
    onlyOnSubmit: true,
  },
  [SHOP_FIELD_EU_VAT]: {
    validate: () => true,
    errorName: SHOP_FIELD_EU_VAT,
    optional: true,
  },
  [SHOP_FIELD_VAT]: {
    validate: () => true,
    errorName: SHOP_FIELD_VAT,
    optional: false,
  },
  shopDiscountPercent: {
    validate: (value) => !value || isNumeric(value) && parseInt(value) < 100 ,
    errorName: 'shopDiscountPercent',
    optional: true,
  }
}

let defaultAddress = {
  address: '',
  city: '',
  region: '',
  country: '',
  zip: '',
  state: ''
}

const isAndroid = Platform.OS === 'android'
const isWeb = Platform.OS === 'web'
const countryWhiteList = ['IT', 'DE']

const navigationOptionsCallbacks = {
  back: () => {}
}

@withCurrentUser
@connectActionSheet
@WithKeyboardInfo
@graphql(startSchema.userByEmail, {
  name: 'userByEmail',
  options: {
    variables: { email: '' },
  },
})
@connect(
  ({ screenInfo, uploads }) => ({ screenInfo, uploads }),
  (dispatch) => ({
    dispatch,
    queueUpload(file) {
      dispatch({ type: 'Upload/ADD', file })
    },
  }),
)
class EditShop extends React.PureComponent {
  _inputRefs = {}

  constructor(props) {
    super(props)

    this.fields = {
      text: {
        displayName: {},
        legalName: {},
        founded: {
          keyboardType: 'number-pad',
        },
        // address: {
        //   textContentType: 'fullStreetAddress',
        // },
        // city: {
        //   textContentType: 'addressCity',
        // },
        // region: {
        //   textContentType: 'addressState',
        // },
        // country: {
        //   textContentType: 'countryName',
        // },
        website: {
          autoCapitalize: 'none',
          textContentType: 'URL',
        },
        email: {
          autoCapitalize: 'none',
          textContentType: 'emailAddress',
        },
        ibanNumber: {},
        bankAccountHolder: {},
        standardShippingFeeCountryData: {
          keyboardType: 'decimal-pad',
          Prefix: (fieldName, index) => this.countryPrefix(fieldName, index, styles.textInputPrefix),
          suffix,
          isMain: true,
          handleFieldChange: this.handleCountryDataChangeHOF('value'),
          getValue: () => this.getCountryDataValue('standardShippingFeeCountryData', 'value'),
        },
        freeShippingThresholdCountryData: {
          keyboardType: 'decimal-pad',
          Prefix: (fieldName, index) => this.countryPrefix(fieldName, index, styles.textInputPrefix),
          suffix,
          isMain: true,
          handleFieldChange: this.handleCountryDataChangeHOF('value'),
          getValue: () => this.getCountryDataValue('freeShippingThresholdCountryData', 'value'),
        },
        [SHOP_FIELD_MINIMUM_ORDER_AMOUNT]: {
          keyboardType: 'decimal-pad',
          suffix,
        },
        [SHOP_FIELD_COMPANY_PHONE_LAND_PREFIX]: {
          keyboardType: 'phone-pad',
          maxLength: 3,
          editable: true // TODO: can be switched to false, in case we want to force only +39 Italian codes in DB
        },
        [SHOP_FIELD_COMPANY_PHONE_LAND]: {
          keyboardType: 'phone-pad',
        },
        [SHOP_FIELD_COMPANY_PHONE_MOBILE_PREFIX]: {
          keyboardType: 'phone-pad',
          maxLength: 3,
          editable: true // TODO: can be switched to false, in case we want to force only +39 Italian codes in DB
        },
        [SHOP_FIELD_COMPANY_PHONE_MOBILE]: {
          keyboardType: 'phone-pad',
        },
        [SHOP_FIELD_VAT]: {},
        [SHOP_FIELD_EU_VAT]: {},
      },
      boolean: {
        cash: {
          title: i18n.t('shop.edit.fields.cash'),
        },
        transfer: {
          title: i18n.t('shop.edit.fields.transfer'),
        },
        stripe: {
          title: i18n.t('shop.edit.fields.stripe'),
        },
      },
      enum: {
        country: {
          title: i18n.t('shop.edit.fields.country'),
        },
        // NOTE: Mapped to DB "category" field for easier translations
        // producer: {
        //   label: i18n.t('shop.edit.fields.category'),
        // },
        method: {
          title: i18n.t('shop.edit.fields.method'),
        },
      },
    }

    const freeShippingThresholdCountryData = clone(_get(this.props,'navigation.state.params.shop.producerDetails.freeShippingThresholdCountryData', { IT: { value: '', currency: {} }}))
    const standardShippingFeeCountryData = clone(_get(this.props,'navigation.state.params.shop.producerDetails.standardShippingFeeCountryData', { IT: { value: '', currency: {} }}))
    const policiesCountryData = clone(_get(this.props,'navigation.state.params.shop.producerDetails.policiesCountryData', { IT: { returns: { value: '', tags: [] }, shipping: { value: '', tags: [] } } }))

    this.initialData = {
      coverPhoto: _get(this.props, 'navigation.state.params.shop.coverPhoto', ''),
      displayName: _get(
        this.props,
        'navigation.state.params.shop.displayName',
        '',
      ),
      legalName: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.legalName',
        '',
      ),
      founded: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.established',
        '',
      ).toString(),
      address: _get(
        this.props,
        'navigation.state.params.shop.address.address1',
        '',
      ),
      city: _get(this.props, 'navigation.state.params.shop.address.city', ''),
      region: _get(this.props, 'navigation.state.params.shop.address.region', ''),
      zip: _get(this.props, 'navigation.state.params.shop.address.zip', ''),
      state: _get(this.props, 'navigation.state.params.shop.address.state', ''),
      country: _get(
        this.props,
        'navigation.state.params.shop.address.country',
        '',
      ),
      website: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.website',
        '',
      ),
      email: _get(
        this.props,
        'navigation.state.params.shop.email',
        '',
      ).replace('+foodbarrio', ''),
      ibanNumber: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.ibanNumber',
        '',
      ),
      bankAccountHolder: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.bankAccountHolder',
        '',
      ),
      standardShippingFee: fromRealNumberToLocalePrice(
        _get(
          this.props,
          'navigation.state.params.shop.producerDetails.standardShippingFee',
          '',
        ),
      ),
      standardShippingFeeCountryData: this.transformRealNumberToLocalePrice(standardShippingFeeCountryData),
      freeShippingThreshold: fromRealNumberToLocalePrice(
        _get(
          this.props,
          'navigation.state.params.shop.producerDetails.freeShippingThreshold',
          '',
        ),
      ),
      freeShippingThresholdCountryData: this.transformRealNumberToLocalePrice(freeShippingThresholdCountryData),
      minimumOrderAmount: fromRealNumberToLocalePrice(
        _get(
          this.props,
          'navigation.state.params.shop.producerDetails.minimumOrderAmount',
          '',
        ),
      ),
      //Note: Remaped from DB name of "category" to MVC name "producer"
      // for easier integration with internationalization
      producer: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.category',
        null,
      ),
      method: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.method',
        '',
      ).toString(),
      description: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.description',
        '',
      ),
      returns: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.policies.returns',
        '',
      ),
      shipping: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.policies.shipping',
        '',
      ),
      policiesCountryData,
      cash: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.paymentTypes.cash',
        false,
      ),
      transfer: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.paymentTypes.transfer',
        false,
      ),
      stripe: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.paymentTypes.stripe',
        false,
      ),
      stripeUserId: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.stripeUserId',
        false,
      ),
      stripeConnected: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.stripeConnected',
        false,
      ),
      companyPhoneLandPrefix: getPhonePrefix(
        _get(
          this.props,
          'navigation.state.params.shop.producerDetails.companyPhoneLand',
          '',)
      ),
      companyPhoneLand: getPhoneNoPrefix(
        _get(
          this.props,
          'navigation.state.params.shop.producerDetails.companyPhoneLand',
          '',)
      ),
      companyPhoneMobilePrefix: getPhonePrefix(
        _get(
          this.props,
          'navigation.state.params.shop.producerDetails.companyPhoneMobile',
          '',)
      ),
      companyPhoneMobile: getPhoneNoPrefix(
        _get(
          this.props,
          'navigation.state.params.shop.producerDetails.companyPhoneMobile',
          '',)
      ),
      euVAT: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.euVAT',
        '',
      ),
      VAT: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.VAT',
        '',
      ),

      geo: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.coordinates',
        '',
      ),
      deliveryLocations: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.deliveryLocations',
        '',
      ),
      deliveryLocationsEnabled: !!(_get(
        this.props,
        'navigation.state.params.shop.producerDetails.deliveryLocations',
        '',
      )),
      shopDiscountPercent: _get(
        this.props,
        'navigation.state.params.shop.producerDetails.shopDiscount.percent',
        '',
      ).toString(),
      shopDiscountStartDate: defaultDate(_get(
        this.props,
        'navigation.state.params.shop.producerDetails.shopDiscount.startDate',
        null,
      )),
      shopDiscountEndDate: defaultDate(_get(
        this.props,
        'navigation.state.params.shop.producerDetails.shopDiscount.endDate',
        null,
      )),
      shopDiscountEnabled: !!(_get(
        this.props,
        'navigation.state.params.shop.producerDetails.shopDiscount.percent',
        '',
      )),
      visibleInGermany: _get(this.props, 'navigation.state.params.shop.producerDetails.countriesVisibleIn', []).indexOf('DE') !== -1,
    }

    this.state = {
      visibleMapModal: false,
      videoLoaded: false,
      showVideoLoader: false,
      pendingUploads: [],
      selectedCountry: 'IT',
      ...this.initialData,
      errors: {},
      files: [],
    }

    this.fieldError = bindableFieldErrorFunc(
      fieldErrorsConfig,
      'state',
      undefined,
      undefined,
      'errors.shop.edit',
      'onboardingShop.fields',
    ).bind(this)
    this.fieldErrors = bindableFieldErrorsFunc(
      this.fieldError,
      'state',
      'onboardingShop.fields',
    ).bind(this)

    if (Platform.OS === 'android') {
      this.watchBackButton()
    }
  }

  watchBackButton() {
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      this.back()
      return true
    })
  }

  componentDidMount() {
    navigationOptionsCallbacks.back = this.back

    console.log('<<<EDIT SHOP - SUBSCRIBING TO SCREEN DID FOCUS')
    if (getLayout() !== 'web/portrait') {
      this.didFocusSubscription = this.props.navigation.addListener('didFocus', this.onScreenFocus)
    }

    Audio.setAudioModeAsync({
      allowsRecordingIOS: false,
      interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: true,
      interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
      playThroughEarpieceAndroid: false,
    })
  }

  back = () => {
    removeUploadsFromCache(this.state.pendingUploads)
    NavigationActions.back()
  }

  onScreenFocus = (payload) => {
    const { stripeConnected } = this.state
    const cachedQuery = client.readQuery({
      query: user.queries.getShop,
      variables:  { id: this.getShop().id }
    })
    let queryStripeConnected = _.get(cachedQuery, 'userById.producerDetails.stripeConnected')
    if (queryStripeConnected === undefined) {
      return // do nothing if Stripe Connect/Shop screens have not been visited. Both of which make the getShop Query request.
    }
    if (stripeConnected !== queryStripeConnected) {
      console.log('<<<EDIT SHOP - ON SCREEN FOCUS DETECTED STRIPE CONNECT STATUS CHANGE')
      this.setState({ stripeConnected: queryStripeConnected })
    }
  }

  componentWillUnmount() {
    this.backHandler && this.backHandler.remove()
    this.didFocusSubscription && this.didFocusSubscription.remove()
  }

  setGeneralError = (value) => {
    const { errors } = this.state
    const newErrors = { ...errors, general: value }
    this.setState({ errors: newErrors })
  }


  setError = (field, errorMessage = undefined, options, callback) => {
    const { errors } = this.state

    let newErrors = errors

    if (field && errorMessage) {
      newErrors[field] = i18n.t(errorMessage, options)
      this.handleFieldChange(newErrors, 'errors', callback)
    }
  }

  removeError = (target) => {
    const { errors } = this.state
    let newErrors = {}
    Object.assign(newErrors, errors)
    if (newErrors[target]) {
      delete newErrors[target]
    }

    this.setState({ errors: newErrors })
  }

  handleFieldChange = (value, target, callback = () => undefined) => {
    const error = this.fieldError(value, target)

    this.setState((prevState) => {
      const nextState = produce(prevState, (draft) => {
        if (error) {
          _.set(draft, `errors.${target}`, error)
        } else {
          _.unset(draft, `errors.${target}`)
        }
        if ((!isAndroid && !error) || isAndroid) {
          _.set(draft, target, value)
        }
      })
      return nextState
    }, callback)
  }

  deliveryLocationsToggle = (value) => {
    const { deliveryLocations } = this.state
    this.handleFieldChange(value, 'deliveryLocationsEnabled')
    if (!value && deliveryLocations) {
      this.handleFieldChange('', 'deliveryLocations')
    }
  }

  visibleInGermanyToggle = (value) => {
    let { countriesVisibleIn } = this.state
    this.handleFieldChange(value, 'visibleInGermany')
    if (!value) {
      countriesVisibleIn = countriesVisibleIn.filter(country => country !== 'DE')
    } else if (value) {
      countriesVisibleIn = [...countriesVisibleIn, 'DE']
    }
    this.handleFieldChange(countriesVisibleIn, 'countriesVisibleIn')
  }

  shopDiscountToggle = (value) => {
    const { shopDiscountPercent, shopDiscountStartDate, shopDiscountEndDate } = this.state
    this.handleFieldChange(value, 'shopDiscountEnabled')
    if (!value) {
      !!shopDiscountPercent && this.handleFieldChange('', 'shopDiscountPercent')
      !!shopDiscountStartDate && this.handleFieldChange(defaultDate(null), 'shopDiscountStartDate')
      !!shopDiscountEndDate && this.handleFieldChange(defaultDate(null), 'shopDiscountEndDate')
    }
  }

  handleUploadFiles = () => {
    const { files } = this.state
    if (files.length) {
      files.forEach(file => {
        this.props.queueUpload(file)
        this.setState({
          targetID: file.id,
          file,
        })
      })
    }
  }
  handleSubmit = async () => {
    const { userByEmail} = this.props
    const id = this.props.navigation.state.params.id
    let message = this.fieldErrors()
    let countries = ['IT']
    if (this.state.visibleInGermany) {
      countries.push('DE')
    }
    const countryDataErrorMessage = this.countryDataValuesNotEmpty('standardShippingFeeCountryData', 'value', countries)
    message += (message ? '\n' : '') + countryDataErrorMessage
    if (message) {
      alert({ title: i18n.t('common.error'), message })
      return
    }

    const currentEmail = _get(
      this.props,
      'navigation.state.params.shop.email',
      '',
    )
    const newMail = this.state[SHOP_FIELD_EMAIL]
    const unicEmail = `${newMail.replace('@', '+foodbarrio@')}`
    if (newMail !== currentEmail) {
      const emailResult = await userByEmail.refetch({ email: unicEmail, })
      const userWithEmail = _.get(emailResult, 'data.userByEmail')

      if (userWithEmail && userWithEmail.id !== id) {
        this.setError(SHOP_FIELD_EMAIL, 'errors.email.taken', {}, () => {
          alert({ title: i18n.t('common.error'), message: this.state.errors[SHOP_FIELD_EMAIL] })
        })
        return
      }
    }

    this.handleUploadFiles()

    const shop = this.getShop()
    const geo = !_.isEmpty(this.state.geo) ? _.pick(this.state.geo,['lat','lng']) : undefined
    const shopDiscount = this.state.shopDiscountEnabled && this.state.shopDiscountPercent ? {
      percent: Number.parseFloat(this.state.shopDiscountPercent),
      startDate:  moment(this.state.shopDiscountStartDate)
        .format('YYYY-MM-DD'),
      endDate: moment(this.state.shopDiscountEndDate)
        .format('YYYY-MM-DD'),
    } : null
    const payload =  {
      id,
      coverPhoto: _.get(this.state.pendingUploads.find(file => !file.id.includes('thumbnail-')), 'id', shop.coverPhoto),
      description: this.state.description || {},
      [SHOP_FIELD_DISPLAY_NAME]: this.state[SHOP_FIELD_DISPLAY_NAME],
      [SHOP_FIELD_LEGAL_NAME]: this.state[SHOP_FIELD_LEGAL_NAME],
      policiesCountryData: this.state.policiesCountryData,
      established: parseInt(this.state.founded),
      category: this.state.producer,
      method: this.state.method,
      website: this.state.website,
      payment_cash: this.state.cash,
      payment_transfer: this.state.transfer,
      payment_stripe: this.state.stripe,
      address1: this.state.address,
      city: this.state.city,
      region: this.state.region,
      country: this.state.country,
      zip: this.state.zip,
      state: this.state.state,
      geo,
      coordinates: geo,
      [SHOP_FIELD_BANK_ACCOUNT_HOLDER]: this.state[
        SHOP_FIELD_BANK_ACCOUNT_HOLDER
      ],
      [SHOP_FIELD_EMAIL]: unicEmail,
      [SHOP_FIELD_IBAN_NUMBER]: this.state[SHOP_FIELD_IBAN_NUMBER],
      freeShippingThresholdCountryData: this.transformLocalePriceToRealNumber(this.state.freeShippingThresholdCountryData),
      standardShippingFeeCountryData: this.transformLocalePriceToRealNumber(this.state.standardShippingFeeCountryData),
      [SHOP_FIELD_MINIMUM_ORDER_AMOUNT]: fromLocalePriceToRealNumber(
        this.state[SHOP_FIELD_MINIMUM_ORDER_AMOUNT],
      ),
      [SHOP_FIELD_COMPANY_PHONE_LAND]: this.state[SHOP_FIELD_COMPANY_PHONE_LAND_PREFIX] + this.state[SHOP_FIELD_COMPANY_PHONE_LAND],
      [SHOP_FIELD_COMPANY_PHONE_MOBILE]: this.state[SHOP_FIELD_COMPANY_PHONE_MOBILE_PREFIX] + this.state[SHOP_FIELD_COMPANY_PHONE_MOBILE],
      [SHOP_FIELD_EU_VAT]: this.state[SHOP_FIELD_EU_VAT],
      [SHOP_FIELD_VAT]: this.state[SHOP_FIELD_VAT],
      deliveryLocations: this.state.deliveryLocations,
      shopDiscount,
    }
    this.props.dispatch({
      type: CONTENT_IS_PENDING,
      contentType: 'producer',
      pendingUploads: this.state.pendingUploads.filter(file => !!this.props.uploads[file.id] && this.props.uploads[file.id].state !== 'complete').map(file => this.props.uploads[file.id]),
      allUploads: [...this.state.pendingUploads],
      shop,
      payload,
    })
    NavigationActions.back()
  }

  _focusTheField = (id) => {
    _.invoke(this._inputRefs, `${id}.focus`)
  }

  mapTextFields = () => {
    const fields = this.fields
    let index = -1
    const textFields = []
    const keys = Object.keys(fields.text)
    for (const key in fields.text) {
      const fieldProps = fields.text[key]
      index++
      const requirementsMet = _.get(fieldErrorsConfig, `${key}.requirementsMet`)
      if (requirementsMet && !requirementsMet(this.state)) {
        continue
      }

      const nextKey = keys[index + 1]
      const requirementsMetNext = _.get(
        fieldErrorsConfig,
        `${nextKey}.requirementsMet`,
      )
      const onSubmitEditing =
        nextKey === undefined && requirementsMetNext
          ? null
          : {
            onSubmitEditing: () => {
              this._focusTheField(nextKey)
            },
          }
      const isPrefix = _.includes(key, 'Prefix')
      const isPhone = _.includes(key, 'Phone') && !isPrefix
      const handleFieldChange = fieldProps.handleFieldChange || this.handleFieldChange
      const value = fieldProps.getValue ? fieldProps.getValue() : this.state[key]
      let containerStyle = styles.inputWidth.other
      if (isPrefix) {
        containerStyle = styles.inputWidth.prefix
      } else if (fieldProps.isMain || isPhone) {
        containerStyle = styles.inputWidth.phone
      }

      if (fieldProps.Prefix) {
        textFields.push(fieldProps.Prefix(key, index))
      }

      textFields.push(
        <ElegantInput
          containerStyle={containerStyle}
          placeholder={isPrefix ? '+39' : ''}
          blurOnSubmit={false}
          error={this.state.errors[key]}
          label={isPrefix ? '' : i18n.t(`shop.edit.fields.${key}`, { country: this.state.selectedCountry })}
          key={fieldProps.key || '' + index}
          reference={(input) => {
            this._inputRefs[key] = input
          }}
          returnKeyType={nextKey === undefined ? 'done' : 'next'}
          value={value}
          {...onSubmitEditing}
          onChange={(value) => {
            handleFieldChange(value, key)
          }}
          {...fieldProps}
        />
      )
    }
    return textFields
  }

  mapPickers = () => {
    const fields = this.fields
    let index = -1

    const pickerItems = { country: countries, method: categories.method }

    return _.map(fields.enum, (fieldProps, key) => {
      index++
      const isCountry = key === 'country'
      return (
        <FBPicker
          label={fields.enum[key].title || ''}
          key={index}
          onValueChange={(value) => this.handleFieldChange(value, key)}
          displayValue={
            isCountry
              ? countries[this.state.country].name
              : (this.state.method &&
                  i18n.t(`categories.method.${this.state.method}`)) ||
                null
          }
          selectedValue={this.state[key]}
        >
          {_.map(pickerItems[key], (itemText, itemKey) => {
            return (
              <Picker.Item
                label={itemText.name || i18n.t(`categories.method.${itemKey}`)}
                value={itemKey}
                key={'item' + index}
              />
            )
          })}
        </FBPicker>
      )
    })
  }

  goToEditProfileField = (fieldName, handleFieldChange, currentValue, prefix) => {
    const id = this.props.navigation.state.params.id

    NavigationActions.navigate({
      routeName: 'EditFieldFullscreen',
      params: {
        id,
        fieldName,
        handleFieldChange: handleFieldChange || this.handleFieldChange,
        currentValue: currentValue ? currentValue  : this.state[fieldName],
        prefix: prefix ? prefix : () => null,
      },
    })
  }

  getShop = () => {
    return this.props.navigation.state.params.shop
  }

  getErrorText = () => {
    let errors = _.map(this.state.errors, (item) => item)
    if (errors[0]) return errors[0]
    return null
  }

  getFormattedAddress = () => {
    const { address, city, region, country, zip, state } = this.state

    return [address, city, region, state, zip, country].filter(Boolean).join(', ')
  }

  removeLastSelection = () => {
    const uploadsToRemove = [...this.state.pendingUploads]
    removeUploadsFromCache(uploadsToRemove)
    return { files: [], pendingUploads: [] }
  }

  handleUpload = (file) => {
    const isVideo = file.file.type === 'video'
    let adjustedState = { files: this.state.files, pendingUploads: this.state.pendingUploads}
    if (!isVideo && this.state.pendingUploads.length) {
      adjustedState = this.removeLastSelection()
    }
    if (!isVideo) {
      this.props.queueUpload(file)
    }

    const nextState = produce(this.state, draft => {
      adjustedState.pendingUploads.push(file)
      draft.pendingUploads = adjustedState.pendingUploads
      if (isVideo) {
        adjustedState.files.push(file)
        draft.files = adjustedState.files
      }
      draft.targetID = file.id,
      draft.file = file
    })
    this.setState(nextState)
  }

  handleDeleteFile = () => {
    const uploads = [...this.state.pendingUploads]
    const nextState = produce(this.state, draft => {
      draft.pendingUploads = []
      draft.files = []
      draft.targetID = null
      draft.file = null
    })
    this.setState(nextState, () => {
      removeUploadsFromCache(uploads)
    })
  }

  handleUploadComplete = () => {
    this.props.navigation.state.params.refreshShopData()
  }

  onLocationSelect = (location) => {
    console.log('onLocationSelect', location)
    const { address, latitude: lat, longitude: lng } = location
    this.setState({
      ...defaultAddress,
      ...address,
      geo: { lat, lng },
      visibleMapModal: false,
    })
  }

  onMapButtonPress = async () => {
    const result = await services.getUserLocationAsync()
    if (result === -1) {
      return
    }
    this.setState({ visibleMapModal: true })
  }

  onCloseMapPress = () => {
    this.setState({ visibleMapModal: false })
  }

  renderMapModal = () => {
    const { visibleMapModal, geo } = this.state
    if (visibleMapModal) {
      return (
        <LocationView
          apiKey={environment.google.geoKey}
          initialLocation={{
            latitude: geo.lat,
            longitude: geo.lng,
          }}
          address={this.getFormattedAddress()}
          visible={visibleMapModal}
          onClosePress={this.onCloseMapPress}
          onLocationSelect={this.onLocationSelect}
        />
      )
    }
    return null
  }

  resolveCDNUri = fileName => {
    const videoName = fileName.replace('thumbnail-', '')
    return `https://${environment.videoCDNId}.cloudfront.net/assets/${videoName.split('.')[0]}/HLS/${videoName.split('.')[0]}.m3u8`
  }
  showVideoLoader = () => {
    this.setState({videoLoaded: false, showVideoLoader: true})
  }
  hideVideoLoader = () => {
    this.setState({videoLoaded: true, showVideoLoader: false})
  }
  renderVideo = (file, coverPhoto, sizeStyles) => {
    const uri = file ? file.file.parentVideoURI : this.resolveCDNUri(coverPhoto)
    return (
      <View style={{position: 'relative'}}>
        <Video
          source={{ uri: uri }}
          useNativeControls={true}
          isMuted={false}
          shouldPlay={true}
          isLooping
          resizeMode="cover"
          style={{...sizeStyles}}
        />
      </View>
    )
  }

  renderCover = (file, coverPhoto) => {
    const { screenInfo } = this.props
    const { showVideoLoader, videoLoaded } = this.state
    const isVideoCover = !file && (['.mp4', '.mov', 'thumbnail-'].some(indicator => _.invoke(coverPhoto, 'includes', indicator)) )
    const topHeight = 146
    let coverHeight = screenInfo.height - getFullOffset(topHeight, screenInfo)
    if (screenInfo.layout === 'landscape') {
      coverHeight = screenInfo.height / 2
    }
    const sizeStyles = {
      width: screenInfo.contentWidth,
      height: coverHeight,
    }

    const mediaId = _.get(file, 'id')    

    return (
      <View style={{position: 'relative'}}>
        { mediaId ? <UploadProgress targetID={mediaId} key={mediaId} /> : null }
        {(file && (file.id.includes('thumbnail-') || file.id.includes('.mov') || file.id.includes('.mp4'))) || isVideoCover ? (
          <View>
            {this.renderVideo(file, coverPhoto, sizeStyles)}
            {file && <DeleteButton data={file} onDelete={this.handleDeleteFile} top={Platform.OS === 'ios' ? 40 : 15} />}
          </View>
        ) : (
          <View>
            <NamedImage
              name={file ? file.id : coverPhoto}
              uri={file ? file.uri : null }
              style={{...sizeStyles}}
              reizeMode='cover'
              resizeMethod='scale'
            />
            {file && <DeleteButton data={file} onDelete={this.handleDeleteFile}/>}
          </View>
        )}
        {(showVideoLoader && !videoLoaded) && (
          <ActivityIndicator
            size='large'
            style={{
              position: 'absolute',
              flex: 1,
              width: screenInfo.contentWidth,
              height: screenInfo.height,
            }}
          />
        )}
      </View>
    )
  }

  isAdmin = () => {
    return _get(this.props.currentUser, 'currentUser.isAdmin', false)
  }

  render() {
    const { screenInfo, keyboardInfo, navigation } = this.props
    const keyboardPaddingStyle = getKeyboardPaddingStyle(keyboardInfo)
    const {
      cash,
      transfer,
      coverPhoto,
      file,
      targetID,
      stripeConnected,
      stripeUserId,
      pendingUploads,
      deliveryLocations,
      deliveryLocationsEnabled,
      shopDiscountEnabled,
      shopDiscountPercent,
      shopDiscountStartDate,
      shopDiscountEndDate,
      visibleInGermany,
    } = this.state

    const reApply = _.get(navigation, 'state.params.reApply', false)
    const isAdmin = this.isAdmin()

    let localFile = null
    if (pendingUploads.length === 1) {
      localFile = pendingUploads[0]
    } else if (pendingUploads.length === 2) {
      localFile = pendingUploads.filter(file => file.mime === 'video/mp4')[0]
    }
    const currentFile = localFile ? localFile : file
    const currentCoverPhoto = localFile ? localFile.uri : coverPhoto
    let formattedAddress = this.getFormattedAddress()

    const thumbColor = branch({
      android: colors.primary,
      ios: null,
      web: 'white'
    })

    return (
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={branch({ android: null, other: 'padding' })}
        keyboardVerticalOffset={
          branch({
            other: sizes.tabBarHeight,
            iphonex: sizes.iphonexTabBarHeight,
          }) + 8
        }
      >
        <ScrollView
          style={styles.container}
          contentContainerStyle={styles.content}
        >
          {/* <KeyboardShift
          fluid
          extraShiftValue={branch({ apk: sizes.tabBarHeight, other: 0 })}
        > */}
          <View style={styles.padded}>
            <Text style={styles.header}>
              {i18n.t('shop.edit.titles.media')}
            </Text>
            <Text style={styles.subheader}>
              {i18n.t('shop.edit.titles.mediaSub')}
            </Text>
          </View>

          {this.renderCover(currentFile, currentCoverPhoto)}

          <UploadProgress
            targetID={targetID}
            disabled
            onComplete={this.handleUploadComplete}
          />
          <View style={styles.padded}>
            <UploadButton onChange={this.handleUpload} showLoader={this.showVideoLoader} hideLoader={this.hideVideoLoader} allowVideo={true}>
              <Button
                disabled
                label={i18n.t('shop.edit.buttons.addPhotoVideo')}
                disabledStyle={styles.button}
                // Disabled just to act as dummy allowing click-through to the actual upload button
                onPress={null}
              />
            </UploadButton>
            <Text style={styles.header}>
              {i18n.t('shop.edit.titles.payment')}
            </Text>
            <View>
              <StripeConnectButton stripeConnected={stripeConnected} />
              {stripeUserId && (
                <Text style={styles.stripeId}>Stripe ID: {stripeUserId}</Text>
              )}
            </View>
            {/* <StripeConnect /> */}
            <Text style={styles.header}>
              {i18n.t('shop.edit.titles.details')}
            </Text>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Switch
                style={{ marginRight: 5 }}
                trackColor={{ false: colors.active, true: colors.primary }}
                thumbColor={thumbColor}
                value={deliveryLocationsEnabled}
                onValueChange={this.deliveryLocationsToggle}
              />
              <Text>{i18n.t('deliveryLocations.productCreation.settingToggle')}</Text>
            </View>
            { deliveryLocationsEnabled ? (
              <View>
                <ElegantInput
                  error={this.state.errors.deliveryLocations}
                  multiline
                  value={deliveryLocations}
                  returnKeyType={'next'}
                  label={i18n.t('deliveryLocations.productCreation.whereQuestion')}
                  reference={(input) => {
                    this._inputRefs['deliveryLocations'] = input
                  }}
                  onChange={(value) => {
                    this.handleFieldChange(value, 'deliveryLocations')
                  }}
                />
                <Text style={styles.footnote}>
                  {i18n.t('deliveryLocations.productCreation.whereQuestionFootnote')}
                </Text>
              </View>
            ) : null }
            <View style={{ marginTop: 5 , flexDirection: 'row', alignItems: 'center' }}>
              <Switch
                disabled={!isAdmin}
                style={{ marginRight: 5 }}
                trackColor={{ false: colors.active, true: colors.primary }}
                thumbColor={thumbColor}
                value={visibleInGermany}
                onValueChange={this.visibleInGermanyToggle}
              />
              <Text>{i18n.t('shop.edit.fields.visibleInGermany')}</Text>
            </View>
            <ElegantInput
              containerStyle={{ marginTop: 16 }}
              editable={false}
              label={i18n.t('onboardingShop.details.address')}
              multiline
              value={formattedAddress}
            />
            <Button
              label={i18n.t('onboardingShop.details.editShopAddress')}
              style={[styles.button, { marginTop: 0 }]}
              onPress={this.onMapButtonPress}
            />

            <View style={styles.rowContainer}>{this.mapTextFields()}</View>

            <Divider />

            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Switch
                style={{ marginRight: 5 }}
                trackColor={{ false: colors.active, true: colors.primary }}
                thumbColor={thumbColor}
                value={shopDiscountEnabled}
                onValueChange={this.shopDiscountToggle}
              />
              <Text>{i18n.t('shop.edit.titles.discount')}</Text>
            </View>            

            {shopDiscountEnabled && (
              <View style={{ zIndex: 10}}>
                <ElegantInput
                  keyboardType={'number-pad'}
                  key={'shopDiscountPercent'}
                  style={styles.input}
                  label={i18n.t('shop.edit.fields.discount.percent')}
                  returnKeyType='next'
                  value={shopDiscountPercent}
                  onChange={(value) => {
                    this.handleFieldChange(value, 'shopDiscountPercent')
                  }}
                  error={this.state.errors.shopDiscountPercent}
                  autoCorrect={false}
                />
      
                <ElegantDatePicker
                  key={'shopDiscountStartDate'}
                  style={styles.input}
                  label={i18n.t('shop.edit.fields.discount.startDate')}
                  returnKeyType='next'
                  value={shopDiscountStartDate}
                  minimumDate={new Date()}
                  onChange={(value) => {
                    if (value > shopDiscountEndDate) {
                      this.handleFieldChange(moment(value).toDate(), 'shopDiscountEndDate')
                    }
                    this.handleFieldChange(value, 'shopDiscountStartDate')
                  }}
                  error={this.state.errors.shopDiscountStartDate}
                  autoCorrect={false}                
                />

                <ElegantDatePicker
                  key={'shopDiscountEndDate'}
                  style={styles.input}
                  label={i18n.t('shop.edit.fields.discount.endDate')}
                  returnKeyType='next'
                  value={shopDiscountEndDate}
                  minimumDate={shopDiscountStartDate}
                  onChange={(value) => {
                    if (value < shopDiscountStartDate) {
                      this.handleFieldChange(moment(value).startOf('day').toDate(), 'shopDiscountStartDate')
                    }
                    this.handleFieldChange(value, 'shopDiscountEndDate')
                  }}
                  error={this.state.errors.shopDiscountEndDate}
                  autoCorrect={false}
                />

              </View>
            )}

            {/* <View>{this.mapPickers()}</View> */}
            <EditFieldButton
              label={i18n.t('shop.edit.titles.editField', {
                fieldName: i18n.t('shop.edit.fields.description'),
              })}
              active={_.get(this.state, 'description.value', '') !== ''}
              icon='ios-arrow-forward'
              onPress={() => {
                this.goToEditProfileField('description')
              }}
              containerStyle={{ marginTop: 20 }}
            />
            <View style={styles.countryDataActionContainer}>
              {this.countryPrefix('returns', 0, styles.buttonPrefix)}
              <EditFieldButton
                label={i18n.t('shop.edit.titles.editField', {
                  fieldName: i18n.t('shop.edit.fields.returns'),
                })}
                active={this.getCountryDataValue('policiesCountryData', 'returns.value') !== ''}
                icon='ios-arrow-forward'
                onPress={() => {
                  this.goToEditProfileField('returns', this.handleReturnsCountryDataChange, this.getCountryDataValue('policiesCountryData', 'returns'))
                }}
              />
            </View>
            <View style={styles.countryDataActionContainer}>
              {this.countryPrefix('shipping', 0, styles.buttonPrefix)}
              <EditFieldButton
                label={i18n.t('shop.edit.titles.editField', {
                  fieldName: i18n.t('shop.edit.fields.shipping'),
                })}
                active={this.getCountryDataValue('policiesCountryData', 'shipping.value') !== ''}
                icon='ios-arrow-forward'
                onPress={() => {
                  this.goToEditProfileField('shipping', this.handleShippingCountryDataChange, this.getCountryDataValue('policiesCountryData', 'shipping'))
                }}
              />
            </View>
            <Divider />
            <Text style={styles.error}>{this.state.errors.general}</Text>
            <Button
              label={i18n.t(`shop.edit.buttons.${reApply ? 'reApply' : 'publish'}`)}
              style={styles.button}
              onPress={this.handleSubmit}
            />
            <Button
              label={i18n.t('common.cancel')}
              style={styles.cancel}
              onPress={() => {
                NavigationActions.back()
              }}
            />
            <View style={[keyboardPaddingStyle]} />
          </View>
          {/* </KeyboardShift> */}
        </ScrollView>
        {this.renderMapModal()}
      </KeyboardAvoidingView>
    )
  }

  countryPrefix = (fieldName, index = 0, prefixStyle) => (
    <CountryPicker key={`countryPicker-${fieldName}-${index}`} style={[styles.inputWidth.prefix, prefixStyle]} onCountryChange={this.onCountryChange} selectedCountry={this.state.selectedCountry} countryWhiteList={countryWhiteList} />
  )

  handleCountryDataChangeHOF = (innerCountryField) => {
    return (value, field) => {
      const nextState = produce(this.state, draft => {
        const path = this.getCountryDataValuePath(field, innerCountryField)
        _.set(draft, path, value)
      })
      this.setState(nextState)
    }
  }

  handleShippingCountryDataChange = (value) => this.handleCountryDataChangeHOF('shipping')(value, 'policiesCountryData')
  handleReturnsCountryDataChange = (value) => this.handleCountryDataChangeHOF('returns')(value, 'policiesCountryData')

  getCountryDataValuePath = (field, innerCountryField, country = this.state.selectedCountry) => {
    let pathToValue = `${field}.${country}`
    if (innerCountryField) {
      pathToValue += `.${innerCountryField}`
    }
    return pathToValue
  }
  getCountryDataValue = (field, innerCountryField, country) => {
    const path = this.getCountryDataValuePath(field, innerCountryField, country)
    return _get(this.state, path, '')
  }

  countryDataValuesNotEmpty = (field, innerCountryField, countries) => {
    const isRequiredErrors = []
    for (const country of countries) {
      const fieldName = i18n.t(`shop.edit.fields.${field}`, { country })
      const value = this.getCountryDataValue(field, innerCountryField, country)
      const truthy = !!value
      if (!truthy) {
        isRequiredErrors.push(' - ' + i18n.t('errors.shop.edit.required', { fieldName }))
      }
    }

    if (isRequiredErrors.length === 0) {
      return ''
    }
    return isRequiredErrors.join('\n')
  }

  transformValueTo = (countryData, transform) => {
    return produce(countryData, draft => {
      for(const country in draft) {
        const data = clone(countryData[country])
        data.value = transform(data.value)
        draft[country] = data
      }
    })
  }

  transformRealNumberToLocalePrice = (countryData) => {
    return this.transformValueTo(countryData, fromRealNumberToLocalePrice)
  }

  transformLocalePriceToRealNumber = (countryData) => {
    return this.transformValueTo(countryData, fromLocalePriceToRealNumber)
  }

  onCountryChange = (selectedCountry) => {
    this.setState({ selectedCountry })
  }
}
// EditShop.navigationOptions = (props) => {
//   return { headerTitle: i18n.t('shop.headers.edit') }
// }

EditShop.propTypes = {
  analyticsShopEdited: PropTypes.func,
  analyticsShopStatusChanged: PropTypes.func,
  updateShopStatus: PropTypes.func,
}

EditShop.navigationOptions = (props) => {
  if (isWeb) {
    const params = _.get(props, 'navigation.state.params', {})
    _.set(props, 'navigation.state.params', jsonParseKeyValues(params, ['shop']))
  }

  return {
    headerTitle: i18n.t('shop.headers.edit'),
    headerLeft: () => (
      <MobileBackButton
        label='Back'
        onPress={() => navigationOptionsCallbacks.back() }
      />
    ),
  }
}

export default EditShop

const EditFieldButton = (props) => {
  const { label, icon, onPress, active, containerStyle } = props
  const style = {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  }
  if (active) {
    style.backgroundColor = colors.primary
  }
  return (
    <Button label={label} style={[style, containerStyle]} onPress={onPress}>
      <Icons
        style={{ position: 'absolute', right: 0, marginRight: 10 }}
        name={icon}
        size={20}
        color='rgba(0,0,0,0.5)'
      />
    </Button>
  )
}

const defaultDate = (string) => {
  if (!string) {
    return moment().toDate()
  }
  return moment(string).toDate()
}

const suffix = ({tintColor}) => (
  <Text style={{ color: tintColor, fontSize: 20, fontWeight: 'bold'}}>€</Text>
)


const styles = stylus({
  container: {
    flex: 1,
  },
  rowContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  inputWidth: {
    prefix: { width: '15%' },
    phone: { width: '80%' },
    other: { width: '100%' }
  },
  content: {
    flexGrow: 1,
    // paddingHorizontal: 30,
    paddingBottom: sizes.tabBarHeight,
    iphonex: {
      paddingBottom: sizes.iphonexTabBarHeight,
    },
    // alignItems: 'center',
  },
  padded: {
    paddingHorizontal: 30,
  },
  button: {
    marginVertical: 10,
    backgroundColor: colors.primary,
  },
  cancel: {
    marginVertical: 10,
  },
  header: {
    marginVertical: 10,
    fontSize: 22,
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  subheader: {
    marginVertical: 10,
    color: colors.text.light,
    alignSelf: 'center',
  },
  label: {
    fontSize: 17,
  },
  editFieldContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    // alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
    paddingTop: 5,
    paddingBottom: 3,
    fontSize: 14,
    borderRadius: 5,
    backgroundColor: colors.button.background,
    // borderBottomWidth: sizes.px,
    // borderBottomColor: colors.input.border,
  },
  editFieldText: {
    // fontSize: 17,
    fontWeight: 'bold',
    color: 'white',
  },
  editFieldIcon: {},
  error: {
    textAlign: 'center',
    color: 'red',
  },
  stripeId: {
    alignSelf: 'stretch',
    textAlign: 'center',
    marginTop: 8,
    color: colors.green,
  },
  footnote: {
    marginLeft: 5,
    color: colors.text.secondary,
    fontSize: 14,
  },
  countryDataActionContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: 20,
  },
  textInputPrefix: {
    height: 50,
    paddingTop: 20,
  },
  buttonPrefix: {
    height: 37,
    marginRight: 5,
  },
})
