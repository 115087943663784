export default function hydrated(state = {}, action) {
  switch (action.type) {
    case 'HYDRATE':
      const { item } = action
      return {
        ...state,
        [item]: true,
      }
    default:
      return state
  }
}
